import { MatTabGroup } from '@angular/material/tabs';
import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import i18next from 'node_modules/i18next';
import { InscripcionService } from 'src/app/_services/inscripcion.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SeleccionDeMateriasDTO } from 'src/app/models/Inscripcion/SeleccionDeMateriasDTO.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { GruposDisponiblesParaMateriaDTO } from 'src/app/models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { ConfirmationDialogServiceService } from 'src/app/services/confirmation-dialog-service.service';
import { MatRadioButton } from '@angular/material/radio';
import { ExisteMateriaSeriada } from 'src/app/models/customEntities/existeMateriaSeriada.mode';
import { InscripcionCosto } from 'src/app/_models/inscripcion-costo';
import { ValidarCostoInscripcionDto } from 'src/app/_models/validarCostoInscripcionDto';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { ColegiaturaCosto } from 'src/app/_models/colegiatura-costo';
import { I18NextPipe } from 'angular-i18next';
import { FormControl, FormGroup } from '@angular/forms';
import { BlockService } from '@app/_services/block.service';
import { Block } from '../../../_models/block';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-seleccion-materias-candidato',
  templateUrl: './seleccion-materias-candidato.component.html',
  styleUrls: ['./seleccion-materias-candidato.component.scss']
})
export class SeleccionMateriasCandidatoComponent implements OnInit, OnDestroy {
  constructor(public nuevoIngresoService: NuevoIngresoService,
    public inscripcion: InscripcionService,
    public snackService: SnackService,
    private confirmacionDialogService: ConfirmationDialogServiceService,
    private admisionesService: AdmisionesService,
    private traductorService: TraductorService,
    private i18nextPipe: I18NextPipe,
    public blockService: BlockService) {

  }
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //#region  Propiedades
  public identificador: string;
  private campusId: number;
  private nivelProgramaId: number;
  private idPrograma: number;
  public idPeriodo: number;
  private curentLanguaje: string;
  private idAlumno: number;
  private idPeriodoIngreso: number;
  private tipoAlumno: string = 'NI';
  private estatusAcadico: number;
  public materiasSeriadas: string;
  public bloqueForm: FormGroup;
  public bloqueList: Block[] = [];
  public infoCargado: boolean = false;
  public dataSource: MatTableDataSource<any>;
  selected: number;


  public materiasDisponibles = new Array<SeleccionDeMateriasDTO>();
  private materiasDisponiblesEnBaseDeDatos = new Array<SeleccionDeMateriasDTO>();
  public materiaDisponibleSeleccionada = new SeleccionDeMateriasDTO();

  public gruposDisponibles = Array<GruposDisponiblesParaMateriaDTO>();
  public gruposDisponiblesBloque = Array<SeleccionDeMateriasDTO>();
  private gruposDisponiblesEnBaseDeDatos = Array<GruposDisponiblesParaMateriaDTO>();

  private gruposInscritosLocalmenteTemporal = new Array<GruposDisponiblesParaMateriaDTO>();
  private gruposDesuscritoLocalmenteTemporal = new Array<GruposDisponiblesParaMateriaDTO>();

  public gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
  public gruposInscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();

  public alumnoNoCubrioCuentaExistente: boolean;

  private cambiosTemporales: boolean;
  public activarTablaDeMateriasDelPeriodo = false;
  public activarTablaDeGruposDeLaMateria = false;

  private radioButtonCheckStaus: boolean;
  public existeSeriacionDeMaterias: boolean;

  public cantidadDeCreditosAntesDeBaja: number;
  public cantidadDeMateriasAntesDeBaja: number;

  public existeInscripcionCosto: boolean = false;
  public existeCuotaColegiatura: boolean = false;


  displayedColumns: string[] = ['clave', 'materia', 'creditos', 'ciclo', 'inscrita'];

  public tabSeleccionadoIndex: FormControl = new FormControl();

  //#endregion

  //#region Suscribers
  private datosUsuarioSubscription: Subscription;
  //#endregion

  //#region Ciclo de vida del componente
  ngOnInit(): void {
    this.tabSeleccionadoIndex.setValue(0);
    this.curentLanguaje = i18next.language;
    this.datosUsuarioSubscription = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$().subscribe(data => {
      if (data) {
        this.identificador = data.identificador;
        this.idPeriodo = data.alumno[0].priodoIngresoNavigation.periodoId;
        this.idPrograma = data.alumno[0].programaId;
        this.idPeriodoIngreso = data.alumno[0].priodoIngreso;
        this.idAlumno = data.alumno[0].alumnoId;
        this.campusId = data.alumno[0].campusId;
        this.nivelProgramaId = data.alumno[0].programa.nivelId;
        this.estatusAcadico = data.alumno[0].estatusAcademico;

        this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.campusId, this.nivelProgramaId, this.idPeriodoIngreso, this.idPrograma)
          .subscribe((cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
            if (cuotaColegiaturaRegistrada.success) {
              if (cuotaColegiaturaRegistrada.data !== null) {
                this.validarCuotaInscripcion();
                this.consultarMateriasProgramasParaElPlanDeEstudios();
                this.existeCuotaColegiatura = true;
              } else {
                if (cuotaColegiaturaRegistrada.message !== null) {
                  let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
                  this.snackService.mostrarSnackBack(mensaje);
                }
              }
            } else {
              this.snackService.mostrarSnackBack(cuotaColegiaturaRegistrada.message);
            }
          });

        this.inicializarBloqueForm();
        this.validarExisteAlumnoEnBloque();
      }



    });
    this.nuevoIngresoService.getSeriacionSubjet().subscribe((existeSeriacion: ExisteMateriaSeriada) => {
      this.existeSeriacionDeMaterias = existeSeriacion.existeMateriaSeriada;
      this.materiasSeriadas = existeSeriacion.materiasSeriadas;
    });


    if (this.admisionesService.invocarMetodoValidarCuotaInscripcionSubscription == undefined) {
      this.admisionesService.invocarMetodoValidarCuotaInscripcionSubscription = this.admisionesService.invocarValidarCuotaInscripcion.subscribe(() => {
        this.ValidarInscripcionPagada();
      })
      this.admisionesService.invocarMetodoValidarCuotaInscripcionSubscription == undefined
    }
  }


  public inicializarBloqueForm() {
    this.bloqueForm = new FormGroup({
      nombreBloque: new FormControl(0),
    });
    this.obtenerBloques();
  }

  get nombreBloque() { return this.bloqueForm.get('nombreBloque'); }



  public validarExisteAlumnoEnBloque() {
    this.blockService.ValidarExisteAlumnoEnBloque(this.idPeriodoIngreso, this.campusId, this.idAlumno).subscribe(response => {
      if (response.success) {
        this.tabSeleccionadoIndex.setValue(1);

        this.bloqueForm.get('nombreBloque').setValue(response.data);
        this.nombreBloque.updateValueAndValidity();
        this.onNombreBloqueSelected(response.data);
      } else {
        this.tabSeleccionadoIndex.setValue(0);
      }
    })
  }



  public obtenerBloques() {

    this.blockService.obtenerBlocksSeleccionMaterias(this.campusId, this.idPeriodoIngreso).subscribe(response => {
      if (response.success) {
        this.bloqueList = response.data.filter(x => x.periodoId == this.idPeriodo);
      }
    })

  }




  ngOnDestroy(): void {
    this.datosUsuarioSubscription.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  //#endregion

  //#region  Métodos

  /**
   * Metodo para validar si existe una cuota de inscricion y determinar si aparece o no el step de inscripcion
   */
  public validarCuotaInscripcion(): void {
    let datauser: ValidarCostoInscripcionDto = {
      campusId: this.campusId,
      periodoId: this.idPeriodo,
      programaId: this.idPrograma,
      tipoAlumno: 'NI'
    };
    this.subscription.add(
      this.admisionesService.ValidarExisteCuotaInscripcion(datauser).subscribe(
        (response: ApiResponse<boolean>) => {
          if (response.success) {
            this.existeInscripcionCosto = response.data;
          } else {
            this.snackService.mostrarSnackBack(response.message);
          }
        })
    );
  }

  private consultarMateriasProgramasParaElPlanDeEstudios(): void {
    this.alumnoNoCubrioCuentaExistente = false;
    this.nuevoIngresoService.ExisteInscripcionCosto(this.campusId, this.nivelProgramaId, this.idPeriodoIngreso, this.idPrograma, this.tipoAlumno).subscribe(
      (InscripcionCosto: ApiResponse<InscripcionCosto>) => {
        if (InscripcionCosto.success) {
          this.ValidarInscripcionPagada();
        }
      });
    this.cargarMaterias();
  }

  public ValidarInscripcionPagada(): void {
    this.subscription.add(
      this.nuevoIngresoService.ValidarInscripcionPagada(this.idAlumno, this.idPeriodoIngreso).subscribe(
        (inscripcionPagada) => {
          if (!inscripcionPagada) {
            this.alumnoNoCubrioCuentaExistente = true;
          } else {
            this.alumnoNoCubrioCuentaExistente = false;
          }
        })
    );
  }

  /**
   * Consulta en el api las materias del plan de estudio
   * que han sido programadas para el periodo en el que el alumno se inscribe
   */
  private cargarMaterias(): void {
    this.inscripcion.ObtenerMateriasProgramadasParaElPlanDeEstudiosEnPeriodoEspecifico(this.idAlumno, this.idPrograma, this.idPeriodo, this.identificador).subscribe((materiasDisponiblesApiResponse: ApiResponse<Array<SeleccionDeMateriasDTO>>) => {
      if (materiasDisponiblesApiResponse.success) {
        this.materiasDisponibles = materiasDisponiblesApiResponse.data;
        this.materiasDisponiblesEnBaseDeDatos = materiasDisponiblesApiResponse.data.map(m => Object.assign({}, m));
        this.activarTablaDeMateriasDelPeriodo = true;
        this.cantidadDeCreditosAntesDeBaja = this.materiasDisponibles.filter(m => m.inscrito).reduce((a, b) => a + b.creditos, 0);
        this.cantidadDeMateriasAntesDeBaja = this.materiasDisponibles.filter(m => m.inscrito).length;
      }
      else {
        this.snackService.mostrarSnackBack(materiasDisponiblesApiResponse.message);
      }
    });
  }
  /**
   * Busca entre todos los grupos registrados localmente si alguno hace match con
   * la materia seleccionada. Si es así, lo retorna, en caso contrario regresa NULL
   * @param claveMateria
   * @returns Objeto GrupoDisponible si lo encuentra, en caso contrario retorna un Null
   */
  private grupoRegistradoLocalmente(claveMateria: string): GruposDisponiblesParaMateriaDTO {
    return this.gruposInscritosLocalmente.find(g => g.claveMateria == claveMateria);
  }
  /**
  * Este método valida si se han inscrito grupos de forma local.
  * Si es así, preselecciona nuevamente el grupo escodigo a la hora de cargar los datos
  * @param claveMateria
  */
  private preSeleccionarGrupo(claveMateria: string): void {
    const grupoEncontradoLocalmente = this.grupoRegistradoLocalmente(claveMateria);
    if (this.gruposInscritosLocalmente.length <= 0 || !grupoEncontradoLocalmente)
      return;
    this.gruposDisponibles.find(g => g.grupo == grupoEncontradoLocalmente.grupo).cursando = true;
  }
  /**
   * Busca entre todos los grupos desuscritos localmente si alguno hace match con
   * la materia seleccionada. Si es así, lo retorna, en caso contrario regresa NULL
   * @param claveMateria
   * @returns Objeto GrupoDisponible si lo encuentra, en caso contrario retorna un Null
   */
  private grupoDesuscritoLocalmente(claveMateria: string): GruposDisponiblesParaMateriaDTO {
    return this.gruposDesuscritosLocalmente.find(g => g.claveMateria == claveMateria);
  }
  /*
  * Este método valida si se han desuscrito eñ grupos de forma local.
  * Si es así, deselecciona el grupo a la hora de cargar los datos
  * @param claveMateria
  */
  private deseleccionarGrupoLocalmente(claveMateria: string): void {
    const grupoEncontradoLocalmente = this.grupoDesuscritoLocalmente(claveMateria);
    if (this.gruposDesuscritosLocalmente.length <= 0 || !grupoEncontradoLocalmente)
      return;
    this.gruposDisponibles.find(g => g.grupo == grupoEncontradoLocalmente.grupo).cursando = false;
  }
  /**
   * Obtiene un listado de grupos que ofertan la materia seleccionada
   * @param materia
   */
  public obtenerGruposParaLaMateria(materia: SeleccionDeMateriasDTO): void {
    this.materiaDisponibleSeleccionada = this.materiasDisponiblesEnBaseDeDatos.find(m => m.clave == materia.clave);

    this.activarTablaDeGruposDeLaMateria = true;
    this.activarTablaDeMateriasDelPeriodo = false;

    this.inscripcion.ObtenerGruposDeUnaMateriaEnPeriodoEspecifico(this.materiaDisponibleSeleccionada.idMateria, this.idPeriodo, this.identificador, this.curentLanguaje).subscribe((apiGruposReponse: ApiResponse<Array<GruposDisponiblesParaMateriaDTO>>) => {
      if (apiGruposReponse.success) {
        this.gruposDisponibles = apiGruposReponse.data;
        this.gruposDisponiblesEnBaseDeDatos = apiGruposReponse.data.map(g => Object.assign({}, g));
        this.cambiosTemporales = false;
        //Copia del estado anterior de grupos
        this.gruposDesuscritoLocalmenteTemporal = this.gruposDesuscritosLocalmente.map(g => Object.assign({}, g));
        this.gruposInscritosLocalmenteTemporal = this.gruposInscritosLocalmente.map(g => Object.assign({}, g));

        this.preSeleccionarGrupo(materia.clave);
        this.deseleccionarGrupoLocalmente(materia.clave);
      }
    });
  }

  /**
 * Ayuda con el rendimiento de la app en los NgFor cuando haya cambios
 * @param index
 * @returns
 */
  public trackByIndex(index: number): number {
    return index;
  }
  /**
   * Cambia el estado de las variables banderaas para ocultas la tabla en la que se muestran los grupo disponinibles
   */
  public ocultarTablaSeleccionDeGrupos(): void {
    this.activarTablaDeMateriasDelPeriodo = true;
    this.activarTablaDeGruposDeLaMateria = false;
  }

  /**
   * Oculta la tabla actual en la que se ven los grupos que impoarten la materia indicada
   * y muestra nuevamente la tabla en la que se muestran las materias programadas para el plan académico
   */
  public regresaraTablaMateriasObtenidas(): void {
    if (this.cambiosTemporales) {
      this.confirmacionDialogService.abrirModalDescartarCambios('Quieres confirmar su cambio de selección', 'Si vuelve a la pantalla anterior sin confirmar sus cambios los perderá.').afterClosed().subscribe((descartarCambios: boolean) => {
        if (descartarCambios) {
          this.gruposDesuscritosLocalmente = this.gruposDesuscritoLocalmenteTemporal.map(g => Object.assign({}, g));
          this.gruposInscritosLocalmente = this.gruposInscritosLocalmenteTemporal.map(g => Object.assign({}, g));
          this.ocultarTablaSeleccionDeGrupos();
        }
      });
    }
    else {
      this.ocultarTablaSeleccionDeGrupos();
    }
  }

  /**
   * Retorna el grupo que se tiene almacenado en la base de datos
   * como el grupo en el que se cursa dicha materia
   * @returns Un objeto de tipo Grupo
   */
  private obtenerGrupoInscritoEnLaBaseDeDatos(): GruposDisponiblesParaMateriaDTO {
    return this.gruposDisponiblesEnBaseDeDatos.find(g => g.cursando);
  }
  /**
   * Busca entre los grupos Inscritos localmente alguno que haga match con la clave de materia
   * del grupo seleccionado, y si existe, lo elimina.
   * @param claveDeLaMateria
   */
  private eliminarGrupoDeLosGruposInscritosLocalmente(claveDeLaMateria: string): void {
    const indexGrupoAEliminarLocalmente = this.gruposInscritosLocalmente.findIndex(g => g.claveMateria == claveDeLaMateria);
    if (indexGrupoAEliminarLocalmente < 0)
      return;
    this.gruposInscritosLocalmente.splice(indexGrupoAEliminarLocalmente, 1);
  }
  /**
   * Busca entre los grupos que se han desuscrito de forma local con base a la clave materia indicada
   * Si lo encuentra lo elimina
   * @param claveDeLaMateria
   */
  private eliminarGrupoDeLosGruposDesuscritosLocalmente(claveDeLaMateria: string): void {
    const indexGrupoAEliminarLocalmente = this.gruposDesuscritosLocalmente.findIndex(g => g.claveMateria == claveDeLaMateria);
    if (indexGrupoAEliminarLocalmente < 0)
      return;
    this.gruposDesuscritosLocalmente.splice(indexGrupoAEliminarLocalmente, 1);
  }
  /**
   * Agrega al arreglo local el grupo indicado, lo cual indica que dicho grupo se debe
   * desuscribir en el API
   * @param grupo
   */
  private desuscribirGrupoLocalmente(grupo: GruposDisponiblesParaMateriaDTO): void {
    this.gruposDesuscritosLocalmente.push(grupo);
  }
  /**
   * Agrega el grupo recibio como parámetro al arreglo de grupos que están inscritos de forma local
   * @param grupo
   */
  private inscribirGrupoLocalmente(grupo: GruposDisponiblesParaMateriaDTO): void {
    this.gruposInscritosLocalmente.push(grupo);
  }
  /**
   * Actualiza localmente el estado de la suscripción de la materia al estado que se le indica
   */
  private actualizarEstadoDeMateriaEnLaVista(claveMateria: string, estadoInscrito: boolean): void {
    this.materiasDisponibles.find(m => m.clave == claveMateria).inscrito = estadoInscrito;
  }
  /**
   * Si el radio buton está marcado en la vista, el método lo desmarca y guarda en pantalla la nueva selección del usuario
   * @param event
   * @param grupoRadioButton
   */
  public cambiarSeleccionDeGrupo(event: MouseEvent, grupoRadioButton: MatRadioButton, grupo: GruposDisponiblesParaMateriaDTO): void {
    if (this.materiaDisponibleSeleccionada.tieneFalificacionFinal) return;

    if (grupo.cupoDisponible <= 0) {
      event.preventDefault();
      grupoRadioButton.checked = false;
      return;
    }

    this.radioButtonCheckStaus = grupoRadioButton.checked;
    const grupoInscritoEnLaBaseDedatos = this.obtenerGrupoInscritoEnLaBaseDeDatos();
    if (this.materiaDisponibleSeleccionada.inscrito) {
      const grupoInscritoEnLaBaseDedatos = this.obtenerGrupoInscritoEnLaBaseDeDatos();
      this.eliminarGrupoDeLosGruposInscritosLocalmente(grupo.claveMateria);
      this.eliminarGrupoDeLosGruposDesuscritosLocalmente(grupo.claveMateria);
      if (grupoRadioButton.checked) {
        this.cambiosTemporales = grupoInscritoEnLaBaseDedatos.grupo == grupo.grupo;
        event.preventDefault();
        grupoRadioButton.checked = false;
        this.desuscribirGrupoLocalmente(grupoInscritoEnLaBaseDedatos);
      }
      else {
        this.cambiosTemporales = grupoInscritoEnLaBaseDedatos.grupo != grupo.grupo;
        if (grupoInscritoEnLaBaseDedatos.grupo != grupo.grupo) {
          this.inscribirGrupoLocalmente(grupo);
          this.desuscribirGrupoLocalmente(grupoInscritoEnLaBaseDedatos);
        }
      }
    }
    else {
      this.cambiosTemporales = !grupoRadioButton.checked;
      this.eliminarGrupoDeLosGruposInscritosLocalmente(grupo.claveMateria);
      if (grupoRadioButton.checked) {
        event.preventDefault();
        grupoRadioButton.checked = false;
      }
      else {
        this.inscribirGrupoLocalmente(grupo);
      }
    }
  }
  /**
   * Guarda todos los cambios temporales con los cambios
   *  permanentes que se agregarán a a la base de datos
   */
  public guardarCambiosTemporalmente(): void {
    this.actualizarEstadoDeMateriaEnLaVista(this.materiaDisponibleSeleccionada.clave, !this.radioButtonCheckStaus);
    this.nuevoIngresoService.cambiosRealziadosEnSeleccionDeMaterias(this.cambiosHechos);
    if (this.cambiosTemporales) {
      this.cambiosTemporales = false;
      this.regresaraTablaMateriasObtenidas();
    }
    else {
      this.regresaraTablaMateriasObtenidas();
    }
  }

  /**
  * Este método se ejecuta una vez se han guardado los cambios en la base de datos
  * Reiniciando el mecanismo para regresarlos a su estado inicial
  */
  public resetSeleccionDeMaterias(): void {
    this.gruposInscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
    this.gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
    this.cambiosTemporales = false;
    this.consultarMateriasProgramasParaElPlanDeEstudios();
  }
  //#endregion

  //#region  Gets
  get cambiosHechos(): boolean {
    return this.gruposInscritosLocalmente.length > 0 || this.gruposDesuscritosLocalmente.length > 0;
  }

  get cantidadCambios(): number {
    return this.gruposInscritosLocalmente.length + this.gruposDesuscritosLocalmente.length;
  }

  public get isSoloGruposInscritosPendientes(): boolean {
    return this.gruposInscritosLocalmente.length > 0 && this.gruposDesuscritosLocalmente.length <= 0;
  }

  public get isSoloGruposDesuscritosPendientes(): boolean {
    return this.gruposDesuscritosLocalmente.length > 0 && this.gruposInscritosLocalmente.length <= 0;
  }

  public get isGruposDesuscritosEInscritosPendientes(): boolean {
    return this.gruposInscritosLocalmente.length > 0 && this.gruposDesuscritosLocalmente.length > 0;
  }

  public get alumnoCubrioRequisitos(): boolean {
    return this.estatusAcadico == 3 || this.estatusAcadico == 8;
  }
  //#endregion

  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  public onNombreBloqueSelected(blockId: number) {

    if (blockId != 0) {
      this.blockService.obtenerMateriasBloque(blockId, this.idAlumno).subscribe(response => {
        if (response.success) {

          this.gruposDisponiblesBloque = response.data;
          this.dataSource = new MatTableDataSource(this.gruposDisponiblesBloque);
          this.infoCargado = true;

        }
      })
    } else {
      this.dataSource = new MatTableDataSource();

    }



  }

}
