import { FiltroAdminAcademicoAlumnoDto } from './../../_models/FiltroAdminAcademicoAlumnoDto';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { UtileriasService } from '../../_services/utilerias.service';
import { Usuario } from '../../_models/usuario';
import { SolicitudApoyoModalComponent } from './apoyo-financiero-solicitud-modal/solicitud-apoyo-modal.component';
import { SolicitudApoyoFinancieroService } from '../../_services/solicitud-apoyo-financiero.service';
import { ApoyoEliminarAsignarSolicitudComponent } from './apoyo-financiero-solicitud-modal/apoyo-financiero-eliminar-asignar-solicitud/apoyo-eliminar-asignar-solicitud.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Periodo } from '../../_models/periodo';
import { Programa, ProgramaActivo } from '../../_models/programa';
import { Campus } from '../../_models/campus';
import { PeriodoService } from '../../_services/periodo.service';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { CampusService } from '../../_services/campus.service';
import { MatOption } from '@angular/material/core';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SolicitudApoyoFinanciero } from 'src/app/_models/solicitud-apoyo-financiero';
import { EnviarCorreoComponent } from 'src/app/_components/enviar-correo/enviar-correo.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { Documento } from 'src/app/_models/documento';
import { DocumentoService } from 'src/app/_services/documento.service';
import { SeguimientoApoyoFinancieroDto } from '../../_models/SeguimientoApoyoFinancieroDto';

@Component({
  selector: 'app-solicitud-apoyo-financiero',
  templateUrl: './solicitud-apoyo-financiero.component.html',
  styleUrls: ['./solicitud-apoyo-financiero.component.scss']
})
export class SolicitudApoyoFinancieroComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  displayedColumns: string[] = ['select', 'nombre', 'periodo', 'programa', 'campus', 'tipoApoyoFinanciero', 'alumnoInscrito', 'documentos', 'revisado', 'asignacion'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<Usuario>(true, []);
  public infoCargado: boolean = false;
  public numSolicitudSeleccionado: number = 0;
  public datafilter: SeguimientoApoyoFinancieroDto[] = [];
  private arrOriginal: SeguimientoApoyoFinancieroDto[] = [];
  public Periodos: Periodo[] = [];
  public Programas: ProgramaActivo[] = [];
  public ArCampus: Campus[] = [];
  private periodosactivos: number[] = [];
  public readonly CLAVE_PROCESO = "APOYO";
  public documentoList: Documento[] = [];
  public documentoListRequeridos: Documento[] = [];
  private Subscription: Subscription = new Subscription();
  public datosCorreo: SeguimientoApoyoFinancieroDto[] = [];
  private subscripcionAlumnos: Subscription;
  public filtroForm: FormGroup;
  public periodoF = new FormControl();
  public idnombre = new FormControl();
  public programasF = new FormControl();
  public campusF = new FormControl();
  public timer: any;
  public valorInicial = 100;
  public arregloFiltroModificado: any[] = [];

  constructor(public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private solicitudApoyoFinancieroService: SolicitudApoyoFinancieroService,
    private periodoService: PeriodoService,
    private programaService: ProgramaAcademicoService,
    private campusService: CampusService,
    private documentoService: DocumentoService,
    private dialog: MatDialog,
    private generadorExcel: GeneracionExcelServiceService) { }

  async ngOnInit(): Promise<void> {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    await this.inicializarForm().then((_) => {
      this.LlenarFiltros();
    });
    this.documentoService.ObtenerDocumentosProceso(this.CLAVE_PROCESO).subscribe(documentos => {
      this.documentoList = documentos.data;
      this.documentoListRequeridos = documentos.data.filter(x => x.esRequerido === true);
    });
    if (this.solicitudApoyoFinancieroService.obtenerSolicitudesSubscription == undefined) {
      this.solicitudApoyoFinancieroService.obtenerSolicitudesSubscription = this.solicitudApoyoFinancieroService.invocarObtenerSolicitudes.subscribe(() => {
        this.LlenarFiltros();
      });
    }
  }

  /**
   * Inicializa el formulario y setea los campos usados
   */
  public inicializarForm(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.filtroForm = new FormGroup({
        periodoF: new FormControl([]),
        idnombre: new FormControl(),
        programasF: new FormControl([]),
        campusF: new FormControl([])
      });
      this.periodoF.setValue(["-1"]);
      this.programasF.setValue(["-1"]);
      this.campusF.setValue(["-1"]);
      resolve();
    });
  }

  /**
   * Hace un llenado de todos los filtros, ingresa a las funciones de obtencion de datos para su carga
   */
  public LlenarFiltros(): void {
    this.obtenerPeriodo();
  }

  /**
  * Obtiene los campus del periodo
  */
  public obtenerPeriodo(): void {
    let today = new Date();
    this.periodoService.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        this.Periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio < today && fin > today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if (this.periodosactivos.length > 0) {
          this.periodoF.setValue([...this.periodosactivos]);
          this.filtroForm.patchValue({
            periodoF: this.periodosactivos
          });
        }
        this.obtenerProgramas();
      });
  }

  /**
     * Obtiene los campus del programa y setea su valor en todos
     */
  public obtenerProgramas(): void {
    this.programaService.obtenerProgramasActivos().subscribe(
      (programas: ApiResponse<ProgramaActivo[]>) => {
        this.Programas = programas.data;
        this.programasF.setValue([...this.Programas.map(item => item.programaId), 0]);
        this.filtroForm.patchValue({
          programasF: this.Programas.map(item => item.programaId)
        });
        this.obtenerCampus();
      }
    );
  }

  /**
   * Obtiene los campus del catalogo y setea su valor en todos
   */
  public obtenerCampus(): void {
    this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.ArCampus = campus;
        this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
        this.filtroForm.patchValue({
          campusF: this.ArCampus.map(item => item.campusId)
        });
        this.filtrar();
      });
  }

  /**
   * Metodo para buscar los filtros
   */
  public buscarFiltros(): void {
    this.filtrar();
  }

  /**
   * Metodo que envia laa informacion de los filtros al back
   */
  public filtrar() {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('programasF').value,
      campus: this.filtroForm.get('campusF').value
    };
    this.cargarInscripciones(1, this.paginator.pageSize, filtro);
  }

  /**
   * Metodo que detecta el cambio de pagina y llama el servicio para traer la siguiente informacion
   * @param event
   */
  public onPaginateChange(event: any) {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('programasF').value,
      campus: this.filtroForm.get('campusF').value
    };
    this.cargarInscripciones(event.pageIndex + 1, event.pageSize, filtro)
  }

  /**
   * Obtiene el catalogo de inscripciones para mostrarlos en la tabla principal, agrega una nueva propiedas llamada nombre completo
   * carga los datos a la tabla principal y agrega los datos a un arreglo original
   * @param pagina
   * @param registrosPagina
   * @param filtro
   */
  public cargarInscripciones(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): void {
    this.subscripcionAlumnos = this.solicitudApoyoFinancieroService.obtenerSolicitudesApoyoFinanciero(pagina, registrosPagina, filtro).subscribe(
      (solicitudes: ApiResponse<any>) => {
        if (solicitudes.data) {
          this.arrOriginal = solicitudes.data.alumnosApoyo;
          this.datafilter = solicitudes.data.alumnosApoyo;
          this.dataSource = new MatTableDataSource(this.datafilter);
          this.valorInicial = solicitudes.data.cantidadAlumnos;
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargado = true;
        } else {
          this.dataSource = new MatTableDataSource([]);
          this.arrOriginal = [];
          this.infoCargado = true;
        }
      }
    );
  }

  /**
   * Si solo un dato del select Periodo esta seleccionado busca solo esa informacion y la
   * muestra en la tabla principal
   */
  toggleAllSelectionPeriodo(): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.filtroForm.patchValue({
        periodoF: this.Periodos.map(item => item.periodoId)
      });
      this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.filtroForm.patchValue({
        periodoF: []
      });
      this.periodoF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Programa esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionPrograma(): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.filtroForm.patchValue({
        programasF: this.Programas.map(item => item.programaId)
      });
      this.programasF.patchValue([...this.Programas.map(item => item.programaId), 0]);
    } else {
      this.filtroForm.patchValue({
        programasF: []
      });
      this.programasF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Campus esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionCampus(): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.filtroForm.patchValue({
        campusF: this.ArCampus.map(item => item.campusId)
      });
      this.campusF.patchValue([...this.ArCampus.map(item => item.campusId), 0]);
    } else {
      this.filtroForm.patchValue({
        campusF: []
      });
      this.campusF.patchValue([]);
    }
  }

  /**
     * Verifica si el campo Todos del select Periodo esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param periodoId
     */
  tosslePerOnePeriodo(periodoId: number): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
    }
    if (this.periodoF.value.length == this.Periodos.length) {
      this.allPeriodo.select();
    }
    const periodosSeleccionados = this.filtroForm.get('periodoF').value;
    const existePeriodo = periodosSeleccionados.find(x => x === periodoId);
    if (!existePeriodo) {
      const periodosAnteriores = periodosSeleccionados;
      periodosAnteriores.push(periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosAnteriores
      });
    } else {
      const periodosFiltrados = periodosSeleccionados.filter(x => x !== periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Programa esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param programaId
     * @returns
  */
  tosslePerOnePrograma(programaId: number): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
    }
    if (this.programasF.value.length == this.Programas.length) {
      this.allPrograma.select();
    }
    const programasSeleccionados = this.filtroForm.get('programasF').value;
    const existePrograma = programasSeleccionados.find(x => x === programaId);
    if (!existePrograma) {
      const programasAnteriores = programasSeleccionados;
      programasAnteriores.push(programaId);
      this.filtroForm.patchValue({
        programasF: programasAnteriores
      });
    } else {
      const programasFiltrados = programasSeleccionados.filter(x => x !== programaId);
      this.filtroForm.patchValue({
        programasF: programasFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param campusId
     * @returns
  */
  tosslePerOneCampus(campusId: number): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.allCampus.deselect();
    }
    if (this.campusF.value.length == this.ArCampus.length) {
      this.allCampus.select();
    }
    const campusSeleccionados = this.filtroForm.get('campusF').value;
    const existeCampus = campusSeleccionados.find(x => x === campusId);
    if (!existeCampus) {
      const campusAnteriores = campusSeleccionados;
      campusAnteriores.push(campusId);
      this.filtroForm.patchValue({
        campusF: campusAnteriores
      });
    } else {
      const campusFiltrados = campusSeleccionados.filter(x => x !== campusId);
      this.filtroForm.patchValue({
        campusF: campusFiltrados
      });
    }
  }

  /**
   * Metodo que inicia un timer cadaa vez que es llamado
   */
  public iniciarTimer() {
    this.timer = window.setTimeout(
      () => {
        this.filtrar()
      }, 400);
  }

  /**
   * Metodo que reinia el timer cada vez que es llamado
   */
  public reiniciarTimer() {
    clearTimeout(this.timer);
    this.iniciarTimer();
  }

  /**
   * Limpia el arreglo de filtro, llena nuevamente los filtros e inicia el formulario, y muestra la tabla sin filtrado
   */
  public LimpiarFiltros(): void {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arrOriginal);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Vacia el arreglo
   */
  public vaciarArregloModificado(): void {
    this.arregloFiltroModificado.length = 0;
  }

  /**
   * Enviar correo a alumnos seleccionados
   * @param btnDataTable
   * @param elemento
   */
  public enviarCorreoUsuarios(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
        datos.correo = elemento[0].usuario.correo;
      } else {
        elemento.forEach(obj => {
          obj.correo = obj.usuario.correo;
        });
        var datos = elemento;
      }
    }
    const dialogo = this.dialog.open(EnviarCorreoComponent, {
      data: datos,
      panelClass: "dialogo-enviar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  /**
   * Editar la solicitud que realizo el alumno
   * @param solicitud
   * @param solicitudCargaDoc
   * @param tabRequerido
   */
  public editarSolicitud(solicitud: SeguimientoApoyoFinancieroDto, solicitudCargaDoc: boolean, tabRequerido: string) {
    this.solicitudApoyoFinancieroService.obtenerSolicitudesApoyoFinancieroByUsuario(solicitud.usuarioId).subscribe((solicitudUsuario: ApiResponse<SolicitudApoyoFinanciero>) => {
      solicitudUsuario.data.nombreCompleto = solicitud.nombreCompleto;
      solicitudUsuario.data.solicitudCargaDoc = solicitudCargaDoc;
      solicitudUsuario.data.tabRequerido = tabRequerido;
      this.util.abrirDialogoLateral(SolicitudApoyoModalComponent, solicitudUsuario.data);
    });
  }

  /**
   * Eliminar o asignar una solicitud
   * @param tipoAccion
   * @param elemento
   */
  public solicitudEliminarAsignar(tipoAccion: string, elemento: Usuario[]): void {
    let datos = {
      tipoAccion: tipoAccion,
      elementos: elemento
    }
    this.util.abrirDialogoLateral(ApoyoEliminarAsignarSolicitudComponent, datos);
  }

  /**
   * Crear solicitud beca
   */
  public crearSolicitud(): void {
    this.util.abrirDialogoLateral(SolicitudApoyoModalComponent);
  }

  /**
   * Metodo select
   */
  isAllSelected() {
    this.numSolicitudSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numSolicitudSeleccionado === numRows;
  }

  /**
   * Metodo select
   */
  checkboxLabel(row?: Usuario): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.numSolicitudSeleccionado = this.selection.selected.length;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioID + 1}`;
  }

  /**
   * Metodo select
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Descarga de Excel
   */
  public descargarExcel(elemento?: Usuario[]) {
    let IdAlumnos: Array<number>;
    if (elemento != null && elemento.length > 0) {
      IdAlumnos = elemento.map(m => m.usuarioId);
    } else {
      IdAlumnos = null;
    }

    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('programasF').value,
      campus: this.filtroForm.get('campusF').value,

      usuariosId: IdAlumnos
    };

    this.Subscription.add(
      this.solicitudApoyoFinancieroService.descargarExcel(filtro).subscribe((blob: Blob) => {
        this.generadorExcel.GenerarExcel(blob, "Apoyo Financiero");
      }));
  }

  /**
   * Metodo al salir del componente
   */
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
    if (this.subscripcionAlumnos) {
      this.subscripcionAlumnos.unsubscribe();
    }
  }
}
