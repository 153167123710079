import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule } from 'angular-i18next';
import { ChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CountdownModule } from 'ngx-countdown';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxEchartsModule } from 'ngx-echarts';
import { MomentModule } from 'ngx-moment';
import * as echarts from 'echarts';
import { AtributosRoutingModule } from './atributos-routing.module';
import { AtributosSeguimientoComponent } from './atributos-seguimiento/atributos-seguimiento.component';
import { AtributosModalComponent } from './atributos-modal/atributos-modal.component';
import { AtributosModalEliminarComponent } from './atributos-modal-eliminar/atributos-modal-eliminar.component';


@NgModule({
  declarations: [
    AtributosSeguimientoComponent,
    AtributosModalComponent,
    AtributosModalEliminarComponent
  ],
  imports: [
    CommonModule,
    AtributosRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    DragDropModule,
    FullCalendarModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MomentModule,
    CKEditorModule,
    CountdownModule,
    MatTabsModule,
    NgbModule,
    NgbPaginationModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    ChartsModule,
    I18NextModule.forRoot(),
    MatRadioModule,
    MatCardModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatExpansionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    MatCheckboxModule,
    MatDividerModule,
    MatStepperModule,
    MatListModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatNativeDateModule,
    ClipboardModule,
    MatRippleModule,
    MatInputModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatChipsModule,
    PdfViewerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTreeModule,
  ]
})
export class AtributosModule { }
