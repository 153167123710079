import { EgresoService } from './../../_services/egreso.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TablaEgresoDTO } from 'src/app/_models/TablaEgresoDTO';
import { SnackService } from 'src/app/services/snack-service.service';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-confirmacion-estatus',
  templateUrl: './modal-confirmacion-estatus.component.html',
  styleUrls: ['./modal-confirmacion-estatus.component.scss']
})
export class ModalConfirmacionEstatusComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmacionEstatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public EgresoService : EgresoService,
    private snackServiceService: SnackService,
  ) { }

  //#region  Propiedades
  public opcion: number;
  //#endregion
  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion
  //#region  Mat Table
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [    
    'id', 
    'nombre',    
    'estatus'];
  //#endregion
  //#region Ciclo de vida
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.data);
    this.opcion = this.data.opcion;
  }
  //#endregion
  
  public CerrarModal()
  {
    this.dialogRef.close();
  }

  //#region  Btn Guardar
  public guardar(): void{
    
    switch (this.opcion) {
      case 1:        
        this.ActualizarEstatusAlumno();
        break;
      case 2:        
        this.ActualizasEstatusCandidatoEgreso();
        break;
      case 3:        
        this.ActualizasEstatusEgreso();
        break;
      default:
        break;
    }
  }
  //#endregion

  //#region  Actualizar Estatus Alumno
  public ActualizarEstatusAlumno() {
    let IdAlumnos 
    if (this.data.data != null && this.data.data.length > 0) {      
       IdAlumnos = this.data.data.map(m => m.alumnoId.toString());
    }      
    this.Subscription.add(
      this.EgresoService.ActualizarEstatusAlumno(IdAlumnos).subscribe((response: SimpleResponse) => {   
        if (response.success) {
          
          this.EgresoService.invocarMetodoObtenerContenido();        
          this.snackServiceService.mostrarSnackBack(response.message);
        } else {
          this.snackServiceService.mostrarSnackBack(
            "Solo cambia a los candidatos seleccionados que tengan estatus de candidato a egreso.  Error: " + response.message);          
        }
      }));
  }
  //#endregion 
  //#region  Actualizar Estatus Candidato Egreso
  public ActualizasEstatusCandidatoEgreso() {
    let IdAlumnos 
    if (this.data.data != null && this.data.data.length > 0) {      
       IdAlumnos = this.data.data.map(m => m.alumnoId.toString());
        
    this.Subscription.add(
      this.EgresoService.ActualizarEstatusCandidatoEgreso(IdAlumnos).subscribe((response: SimpleResponse) => {   
        if (response.success) {
          
          this.EgresoService.invocarMetodoObtenerContenido();        
          this.snackServiceService.mostrarSnackBack(response.message);
        } else {
          this.snackServiceService.mostrarSnackBack(
            "Solo cambia a los candidatos seleccionados que tengan estatus de Alumno.   Error: " + response.message);   
        }
      }));
    }  
  }
  //#endregion 
  //#region  Actualizar Estatus  Egreso
  public ActualizasEstatusEgreso() {
    let IdAlumnos 
    if (this.data.data != null && this.data.data.length > 0) {      
       IdAlumnos = this.data.data.map(m => m.alumnoId.toString());
    }      
    this.Subscription.add(
      this.EgresoService.ActualizarEstatusEgreso(IdAlumnos).subscribe((response: SimpleResponse) => {   
        if (response.success) {
          
          this.EgresoService.invocarMetodoObtenerContenido();        
          this.snackServiceService.mostrarSnackBack(response.message);
        } else {
          this.snackServiceService.mostrarSnackBack(
            "Solo cambia a los candidatos seleccionados que tengan estatus de candidato a egreso.   Error: " + response.message);
        }
      }));
  }
  //#endregion 
}
