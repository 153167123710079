import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import { Periodo } from '@app/_models/periodo';
import { ApiResponse } from '../models/api/ApiRespose.model';

@Injectable({
  providedIn: "root"
})
export class PeriodoService {

  invocarObtenerPeriodos = new EventEmitter();
  invMetodoObtenerPeriodosSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  //Get Periodos
  public obtenerPeriodos(): Observable<Periodo[]> {
    return this.httpClient.get<Periodo[]>(`${environment.enves[baseUrl].apiUrl}/Periodo`);
  }
  //Get Periodos
  public obtenerPeriodosActivos(): Observable<Periodo[]> {
    return this.httpClient.get<Periodo[]>(`${environment.enves[baseUrl].apiUrl}/Periodo/GetPeriodosActivos`);
  }
  //Get Periodos
  public obtenerPeriodosById(idPeriodo: number): Observable<Periodo[]> {
    return this.httpClient.get<Periodo[]>(`${environment.enves[baseUrl].apiUrl}/GetPeriodobyId/${idPeriodo}`);
  }

  //Get Periodo Fecha Inicio Descendente
  public ObtenerPeriodoFechaInicio(): Observable<Periodo[]> {
    return this.httpClient.get<Periodo[]>(`${environment.enves[baseUrl].apiUrl}/Periodo/FechaInicio/Descendente`);
  }
  //Get Periodo-grupo
  public obtenerPeriodosByProfesorId(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.enves[baseUrl].apiUrl}/Periodo/GetPeriodosByProfesorId`);
  }

  //Post Periodo
  public generarPeriodo(periodo: Periodo): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Periodo`, periodo);
  }

  //Put Periodo
  public modificarPeriodo(periodo: Periodo): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Periodo`, periodo);
  }

  //Delete Periodo
  public eliminarPeriodo(periodoId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Periodo?periodoId=` + periodoId);
  }

  //Invocar Metodo obtenerPeriodos
  public invocarMetodoObtenerPeriodos() {
    this.invocarObtenerPeriodos.emit();
  }

  //Get Periodos By Usuario
  public obtenerPeriodosByUsuario(usuarioId: number): Observable<Periodo[]> {
    return this.httpClient.get<Periodo[]>(`${environment.enves[baseUrl].apiUrl}/Periodo/GetPeriodoByUsuarioId?usuarioId=` + usuarioId);
  }

  //Get Periodos By AlumnoId
  public obtenerPeriodosByAlumno(alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Periodo/GetPeriodosByAlumnoId?alumnoId=` + alumnoId);
  }

  //Obtener periodos por Grupos
  public obtenerPeriodosByGrupos(): Observable<Periodo[]> {
    return this.httpClient.get<Periodo[]>(`${environment.enves[baseUrl].apiUrl}/Periodo/GetByGrupos`);
  }
  //Obtener periodos igual o mayores al periodo Ingreso del alumno
  public obtenerPeriodosByPeriodoIngresoId(institucionId: string, periodoIngresoId: number): Observable<ApiResponse<Periodo[]>> {
    return this.httpClient.get<ApiResponse<Periodo[]>>(`${environment.enves[baseUrl].apiUrl}/Periodo/obtenerPeriodosByPeriodoIngresoId?institucionId=${institucionId}&periodoIngresoId=${periodoIngresoId}`);
  }

  //Obtienen el ultimo periodo
  public obtenerUltimoPeriodo(alumnoId: any): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Periodo/ObtenerUltimoPeriodo/${alumnoId}`);
  }

  //Descargar Excel
  public descargarExcel(datos) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Periodo/DescargarExcelPeriodo`, JSON.stringify(datos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }
}
