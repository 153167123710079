import { FORM_VALID_MODEL } from './../../../../../core.domain/models/user/form-valid-model';
import { GrupoSanguineoService } from './../../../../../../_services/grupo-sanguineo.service';
import { GrupoSanguineo } from './../../../../../../_models/grupo-sanguineo';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MessageErrors } from '../../../../../core.application/functions/messageErros.function';
import { MEDICAL_INFORMATION } from '../../../../../core.application/validators/user-data/medical-information.validators';
import { Subscription } from 'rxjs';
import { MEDICAL_INFORMATION_MODEL } from '../../../../../core.domain/models/user/medical-information.model';


@Component({
  selector: 'app-medical-information-form',
  templateUrl: './medical-information-form.component.html',
  styleUrls: ['./medical-information-form.component.scss']
})
export class MedicalInformationFormComponent implements OnInit, OnDestroy, OnChanges {

  //#region  Input, Output & ViewChild
  @Input() data: MEDICAL_INFORMATION_MODEL;

  @Input() cancelledFields: string[] = [];
  @Input() disabledledFields: string[] = [];

  @Output() formValidity: EventEmitter<FORM_VALID_MODEL> = new EventEmitter(); 
  @Output() formData = new EventEmitter<MEDICAL_INFORMATION_MODEL>();
  //#endregion
  
  //#region Subscriptions
  private Subscription: Subscription = new Subscription();
  //#endregion
  
  //#region Properties
  public tiposDeSangre: Array<GrupoSanguineo>;
  public medicalInformationForm: FormGroup = this.formBuilder.group(MEDICAL_INFORMATION);
  public ErrorMsg: any;
  private status: boolean = false;
  
  public  deletedInputs = {
    grupoSanguineoId  : false,
    alergias          : false,
    enfermedades      : false    
  };
  //#endregion

  constructor(
    private formBuilder: FormBuilder,
    private _grupoSanguineoService: GrupoSanguineoService,
  ) { 
    this.setErrors();
  }

  ngOnInit(): void {
    this.subscribeToForm();
    this._getBloodGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] !== undefined) {
      this._setData();
    }
  }
  
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#region setData
  private _setData():void
  {    
    this.medicalInformationForm.setValue(this.data);
    // this.medicalInformationForm.get('grupoSanguineoId')?.setValue(this.data.grupoSanguineoId);
    // this.medicalInformationForm.get('alergias')?.setValue(this.data.alergias);
    // this.medicalInformationForm.get('enfermedades')?.setValue(this.data.enfermedades);
    
  }
  //#endregion

  //#region Methods
  private _getBloodGroup(): void {
    this.Subscription.add(
      this._grupoSanguineoService.obtenerGrupoSanguineo().subscribe((tiposDeSangre: Array<GrupoSanguineo>) => {
        this.tiposDeSangre = tiposDeSangre;
      })
    );
  }
  //#endregion

  //#region Helpers
  public setErrors(): void {
    this.ErrorMsg = MessageErrors.setErrorMessage(this.medicalInformationForm);
  }

  /**
   * If the cancelledFields array has a length greater than 0, then for each field in the
   * cancelledFields array, if the medicalInformationForm has a control with the name of the field, then clear the
   * validators for that control and add the field to the deletedInputs object.
   */
   _deleteCancelledFields(): void {
    if (this.cancelledFields.length > 0) {
      this.cancelledFields.forEach(field => {
        if (this.medicalInformationForm.get(field)) {
          this.medicalInformationForm.get(field)?.clearValidators();
          this.deletedInputs[field] = true;
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  /**
   * _disabledCancelledFields() is a function that disables and clears validators for fields that are
   * not required
   */
  _disabledCancelledFields(): void {
    if (this.disabledledFields.length > 0) {
      this.disabledledFields.forEach(field => {
        if (this.medicalInformationForm.get(field)) {
          this.medicalInformationForm.get(field)?.clearValidators();
          this.medicalInformationForm.get(field)?.disable();
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  private subscribeToForm(): void {
    this.medicalInformationForm.markAllAsTouched();
    this._Emit();
    this.Subscription.add(
      this.medicalInformationForm.statusChanges
      .subscribe(status => {
        this.setErrors();
        this.status = status === 'VALID';
        this._Emit();
      })
    );
  }

  private _Emit():void
  {
    this.formValidity.emit(
      new FORM_VALID_MODEL(
        5,
        'MedicalInformationFormComponent',
        this.status
      )
    );
    this.formData.emit(
      new MEDICAL_INFORMATION_MODEL(
        this.medicalInformationForm.get('grupoSanguineoId')?.value,
        this.medicalInformationForm.get('alergias')?.value,
        this.medicalInformationForm.get('enfermedades')?.value
      )
    );
  }
  //#endregion

}