import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { Lead } from '../_models/lead';

@Injectable({
  providedIn: 'root'
})
export class LeadImportService {
  private boolCambioPendiente$ = new Subject<boolean>();
  private datosColumnas$ = new Subject<any>();
  private datosCargados$ = new Subject<any>();
  private columnasUsuario$ = new Subject<any>();
  constructor(private httpClient: HttpClient) { }

  public agregarImports(leadImport: any[]): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/LeadImport`, leadImport);
  }

  /** Define si hay cambios pendientes en todo el componente */
  public definirCambios(boolCambio: boolean) {
    this.boolCambioPendiente$.next(boolCambio);
  }

  public obtenerCambios(): Observable<boolean> {
    return this.boolCambioPendiente$.asObservable();
  }

  /**
   * Define las columnas que acomodo el usuario y pasarlas al otro componente
   * @param columnasOrdenadas 
   */
  public definirColumnas(columnasOrdenadas: string[]) {
    this.datosColumnas$.next(columnasOrdenadas);
  }

  public obtenerColumnas(): Observable<any> {
    return this.datosColumnas$.asObservable();
  }

  /**
   * Define si la respuesta al momento de cargar los archivos es correcta 
   * @param datosCargados
  */
  public definirDatosCargadosCorrectos(datosCargados: boolean) {
    this.datosCargados$.next(datosCargados);
  }

  public obtenerDatoCargado(): Observable<any> {
    return this.datosCargados$.asObservable();
  }

  /**
   * Define las columnas que el usaurio quiere que tengan un valor fijo 
   * @param columnasConValorFijo
  */
  public definircolumnasValorFijo(columnasConValorFijo: any) {
    this.columnasUsuario$.next(columnasConValorFijo);
  }

  public obtenerColumnasValorFijo(): Observable<any> {
    return this.columnasUsuario$.asObservable();
  }
}
