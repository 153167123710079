import { EventEmitter, Injectable } from '@angular/core';
import { environment, baseUrl } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { Profesor } from '../_models/profesor';
import { Salon } from '../_models/salon';
import { tap } from 'rxjs/operators';
import { Grupo } from '../_models/grupo';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { GrupoDTO } from '../_models/GrupoDTO.model';

@Injectable({
  providedIn: 'root'
})
export class GrupoService {

  invocarRefresh = new EventEmitter();
  invMetodoObtenerRefreshSubscription: Subscription;
  private _refresh$ = new Subject<void>();
  private datosCampusPeriodo$ = new Subject<any>();
  private datosMateria$ = new Subject<any>();
  private datosBloque$ = new Subject<any>();
  private datosDuracion$ = new Subject<any>();
  private boolCambioPendiente$ = new Subject<boolean>();
  private datosPrograma$ = new Subject<any>();
  private setFechas$ = new Subject<any>();


  invocarSetFechas = new EventEmitter();
  invMetodoSetFechasSubscription: Subscription;

  invocarGuardarGrupo = new EventEmitter();
  invMetodoSubscription: Subscription;

  invocarObtenerGrupos = new EventEmitter();
  invMetodoObtenerGruposSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  get refresh$() {
    return this._refresh$;
  }

  //Get Grupos
  public obtenerGrupos(idioma: string): Observable<ApiResponse<Array<GrupoDTO>>> {
    return this.httpClient.get<ApiResponse<any[]>>(`${environment.enves[baseUrl].apiUrl}/Grupo?idioma=${idioma}`);
  }

  //Get grupo by id
  public obtenerGrupoById(GrupoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Grupo/GetGrupoById/${GrupoId}`);
  }

  //Post Grupo
  public guardarGrupo(grupo): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Grupo`, grupo);
  }

  //Put Grupo
  public editarGrupo(grupo): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Grupo`, grupo);
  }

  //Delete Grupo
  public eliminarGrupo(grupoId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Grupo/?grupoId=` + grupoId);
  }

  //Post Horario
  public guardarHorario(horario: Array<any>): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Horario`, horario);
  }

  //Put Horario
  public editarHorario(horario: Array<any>): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Horario`, horario);
  }

  //Delete Horario
  public eliminarHorarioSeccion(horarioId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.enves[baseUrl].apiUrl}/Horario?horarioId=` + horarioId);
  }
  //Delete HorarioList
  public EliminarSeccionesRange(horario: Array<number>): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Horario/EliminarSeccionesRange`, horario);
  }

  //Get Profesores
  public obtenerProfesor(): Observable<Profesor[]> {
    return this.httpClient.get<Profesor[]>(`${environment.enves[baseUrl].apiUrl}/Profesor`);
  }

  //Get Salones
  public obtenerSalon(CampusId: number = 0, EsActivo: boolean = false): Observable<Salon[]> {
    return this.httpClient.get<Salon[]>(`${environment.enves[baseUrl].apiUrl}/Salon?CampusId=${CampusId}&&EsActivo=${EsActivo}`);
  }

  // Set datos grupo campus periodo Observable
  public definirDatosCampusPeriodo(datosGrupo: any) {
    this.datosCampusPeriodo$.next(datosGrupo);
  }

  //Get datos grupo campus periodo Observable
  public obtenerDatosCampusPeriodo$(): Observable<any> {
    return this.datosCampusPeriodo$.asObservable();
  }

  // Set datos grupo campus periodo Observable
  public definirDatosMateria(datosGrupo: any) {
    this.datosMateria$.next(datosGrupo);
  }

  //Get datos grupo campus periodo Observable
  public obtenerDatosMateria$(): Observable<any> {
    return this.datosMateria$.asObservable();
  }

  // Set datos grupo duracion Observable
  public definirDatosDuracion(datosGrupo: any) {
    this.datosDuracion$.next(datosGrupo);
  }

  //Get datos grupo duracion Observable
  public obtenerDatosDuracion$(): Observable<any> {
    return this.datosDuracion$.asObservable();
  }

  // Set bool cambio Observable
  public definirBoolCambio(boolCambio: boolean) {
    this.boolCambioPendiente$.next(boolCambio);
  }

  //Get bool cambio Observable
  public obtenerBoolCambio$(): Observable<boolean> {
    return this.boolCambioPendiente$.asObservable();
  }
  //get Fechas
  public obtenerSetFechas$(): Observable<any> {
    return this.setFechas$.asObservable();
  }
//#region  Bloque Get Set
  // Set datos grupo campus periodo Observable
  public definirDatosBloque(datosBloque: any) {
    this.datosBloque$.next(datosBloque);
  }

  //Get datos grupo campus periodo Observable
  public obtenerDatosBloque$(): Observable<any> {
    return this.datosBloque$.asObservable();
  }
//#endregion

  //Invocar Metodo guardarGrupo
  invocarMetodoGruardarGrupo() {
    this.invocarGuardarGrupo.emit();
  }
  refreshData

  //Invocar Metodo obtenerGrupos
  invocarMetodoObtenerGrupos() {
    this.invocarObtenerGrupos.emit();
  }

  //Invocar Metodo Refresh
  invocarMetodoObtenerRefresh() {
    this.invocarRefresh.emit();
  }
  //Invocar Metodo para setear fechas
  public invocarMetodoSetFechas() {
    this.invocarSetFechas.emit();
  }
  // Set datos grupo duracion Observable
  public definirDatosPrograma(datosPrograma: any) {
    this.datosPrograma$.next(datosPrograma);
  }
  //Get datos grupo campus periodo Observable
  public obtenerDatosPrograma$(): Observable<any> {
    return this.datosPrograma$.asObservable();
  }

  public validarEmpalmeProfesor(profesorId: number, horarios): Observable<ApiResponse<boolean>> {
    return this.httpClient.post<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/Horario/ProfesorEmpalme?profesorId=${profesorId}`, horarios);
  }

  public validarEmpalmeSalon(horarios): Observable<ApiResponse<boolean>> {
    return this.httpClient.post<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/Horario/SalonEmpalme`, horarios);
  }

  //Download Excel
  public descargarExcel(grupos: any) {
    // return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/Candidato/DownloadExcel`, arrays);
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Grupo/DownloadExcel`, JSON.stringify(grupos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }
}
