import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment, baseUrl } from '@environments/environment';
import { Correo } from '../_models/correo';
import { AlumnoGrupoParcial, AsistenciaConsultar, RecursoAlumnoArchivo } from '../_models/ensenanzaAprendizajeAlumno'
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { InfoPrograma } from '../_models/infoPrograma';
import { CuestionarioArea, CuestionarioRespuestaDto, ListaAlumnosGrupos } from '../_models/ensenanza-aprendizaje-profesor';
import { RecursoAdjunto } from '../_models/recurso-adjunto';
import { Recurso } from '../_models/recurso';
import { PeriodoDto } from '../_models/periodo';
import { ListaMateriasOferta } from '../_models/lista-materias-oferta';
import { TipoRecurso } from '../_models/tipo-recurso';
@Injectable({
  providedIn: 'root'
})
export class EnsenanzaAprendizajeAlumnoService {
  public alumnoId$: number;
  public claveMateria$: string;
  public profesorId$: number;

  private informacionGrupo$ = new BehaviorSubject<any>(null);
  private modalExistente$ = new BehaviorSubject<boolean>(false);

  updateCargarDetalleExamen = new EventEmitter()


  public modulo: any[];
  constructor(private httpClient: HttpClient) { }


  updateDetalleExamen(recurso: any) {
    this.updateCargarDetalleExamen.emit(recurso);
  }


  //Get Periodos
  public obtenerPeriodosUsuario(usuarioId: number): Observable<ApiResponse<PeriodoDto[]>> {
    return this.httpClient.get<ApiResponse<PeriodoDto[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetPeriodosByAlumnoId/${usuarioId}`);
  }
  //Get Periodos
  public obtenerGrupos(periodosId: string, usuarioId: number, idioma: string): Observable<ApiResponse<ListaMateriasOferta[]>> {
    return this.httpClient.get<ApiResponse<ListaMateriasOferta[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetMateriasAlumno/${periodosId}/${usuarioId}/${idioma}`);
  }

  //Get GetMateriaAlumnoByGrupoId
  public obtenerGrupoByGrupoId(grupoId: number, idioma: string): Observable<ApiResponse<ListaMateriasOferta>> {
    return this.httpClient.get<ApiResponse<ListaMateriasOferta>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetMateriaAlumnoByGrupoId/${grupoId}/${idioma}`);
  }
  public GetMateriaAlumnoByGrupoId(grupoId: number, idioma: string, usuarioId: number): Observable<ApiResponse<ListaMateriasOferta>> {
    return this.httpClient.get<ApiResponse<ListaMateriasOferta>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetMateriaAlumnoByGrupoId/${grupoId}/${usuarioId}/${idioma}`);
  }

  //Get Periodos
  public obtenerGruposAll(usuarioId: number, idioma: string): Observable<ApiResponse<ListaMateriasOferta[]>> {
    return this.httpClient.get<ApiResponse<ListaMateriasOferta[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetMateriasAlumnoAll/${usuarioId}/${idioma}`);
  }

  /**
   * Obtiene el contenido que se vera en el grupo
   * @param grupoId
   * @returns
   */
  public obtenerContenido(grupoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetParcialesContenido/${grupoId}`);
  }

  /**
   * Obtiene los recursos que tendra el grupo
   * @param recursoAdjuntoId
   * @returns
   */
  public obtenerRecursoAdjunto(recursoAdjuntoId): Observable<ApiResponse<RecursoAdjunto>> {
    return this.httpClient.get<ApiResponse<RecursoAdjunto>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetRecursoAdjunto/${recursoAdjuntoId}`);
  }
  //Get Parciales
  public obtenerParciales(alumnoId: number, grupoId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetNumeroParciales/${alumnoId}/${grupoId}`);
  }
  //Get Recursos
  public obtenerRecursos(grupoId: number, alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetRecursos/${grupoId}/${alumnoId}`);
  }

  //Get Recursos byID
  public obtenerRecursoByRecursoId(recursoId: number, alumnoId: number): Observable<ApiResponse<Recurso>> {
    return this.httpClient.get<ApiResponse<Recurso>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/RecursoByRecursoId/${recursoId}/${alumnoId}`);
  }

  //Get TipoContenido
  public obtenerTipoContenido(): Observable<ApiResponse<TipoRecurso[]>> {
    return this.httpClient.get<ApiResponse<TipoRecurso[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetTipoRecursos/`);
  }

  //Get Recurso Tarea Alumno
  public obtenerRecursoTareaAlumno(recursoId: number, alumnoId: number): Observable<ApiResponse<Recurso[]>> {
    return this.httpClient.get<ApiResponse<Recurso[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetRecursoTareaAlumno/${recursoId}/${alumnoId}`);
  }

  // Set idPrograma Observable
  public definirProfesorId(profesorId: number) {
    this.profesorId$ = profesorId;
  }

  // Set idPrograma Observable
  public definirClaveMateria(claveMateria: string) {
    this.claveMateria$ = claveMateria;
  }

  //Post Enviar correo a alumnos
  public contactarProfesor(correos: Correo): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/ContactarProfesor`, correos);
  }

  // Set alumnoId Observable
  public definirAlumno(alumnoId: number) {
    this.alumnoId$ = alumnoId;
  }
  // Set modulo Observable
  public definirModulo(modulos: any[]) {
    this.modulo = modulos;
  }
  //Get Recursos Alumnos
  public obtenerRecursoAlumno(alumnoId: number, recursoId: number): Observable<ApiResponse<RecursoAlumnoArchivo[]>> {
    return this.httpClient.get<ApiResponse<RecursoAlumnoArchivo[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetRecursosAlumnoArchivos/${alumnoId}/${recursoId}`);
  }
  //POST Estudios Previos
  public agregarRecursos(RecusosAlumno: RecursoAlumnoArchivo[]): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/PostRecursoAlumnoArchivo/`, RecusosAlumno);
  }
  //POST Estudios Previos
  public eliminarRecursoAlumno(alumnoId: number, recursoAlumnoArchivoId: number): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/DeleteRecursoAlumnoArchivo/${alumnoId}/${recursoAlumnoArchivoId}`);
  }

  //Get Archivos
  public obtenerArchivos(grupoId: number): Observable<ApiResponse<RecursoAdjunto[]>> {
    return this.httpClient.get<ApiResponse<RecursoAdjunto[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetArchivos/${grupoId}`);
  }

  //Get Alumnos
  public obtenerAlumnos(grupoId: number): Observable<ApiResponse<ListaAlumnosGrupos[]>> {
    return this.httpClient.get<ApiResponse<ListaAlumnosGrupos[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetAlumnos/${grupoId}`);
  }
  //Post Enviar correo a alumnos
  public enviarCorreos(correos: Correo): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/EnviarCorreos`, correos);
  }
  //Get Calificaciones
  public obtenerCalificaciones(alumnoId: number, grupoId: number): Observable<ApiResponse<AlumnoGrupoParcial[]>> {
    return this.httpClient.get<ApiResponse<AlumnoGrupoParcial[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetCalificaciones/${alumnoId}/${grupoId}`);
  }
  //Get Calificaciones
  public obtenerConsultaAsistencia(campusId: number, periodoId: number, alumnoId: number, grupoId: number): Observable<ApiResponse<AsistenciaConsultar[]>> {
    return this.httpClient.get<ApiResponse<AsistenciaConsultar[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetAsistenciasConsultar/${campusId}/${periodoId}/${alumnoId}/${grupoId}`);
  }
  //Get Calificaciones
  public obtenerConsultaCalificaciones(alumnoId: number, grupoId: number): Observable<ApiResponse<Recurso[]>> {
    return this.httpClient.get<ApiResponse<Recurso[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetCalificacionesConsultar/${alumnoId}/${grupoId}`);
  }
  //Download Excel
  public descargarExcel(Calificaciones) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/DownloadExcel`, JSON.stringify(Calificaciones),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }
  //Get Calificaciones
  public GetProgramasMigrupo(grupoId: number): Observable<ApiResponse<InfoPrograma[]>> {
    return this.httpClient.get<ApiResponse<InfoPrograma[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetProgramasAlumnos/${grupoId}`);
  }

  public ObtenerCalificacionMinima(alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<InfoPrograma[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/ObtenerCalificacionMinimaPrograma/${alumnoId}`);
  }
  //Get detalleEx
  public GetDetalleExamen(recursoId: number, alumnoId: number): Observable<ApiResponse<Recurso[]>> {
    return this.httpClient.get<ApiResponse<Recurso[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetDetalleExamen/${recursoId}/${alumnoId}`);
  }
  //Get detalleEnc
  public GetDetalleEncuesta(recursoId: number, alumnoId: number): Observable<ApiResponse<Recurso[]>> {
    return this.httpClient.get<ApiResponse<Recurso[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetDetalleEncuesta/${recursoId}/${alumnoId}`);
  }
  //Get Examen
  public GetExamenContestar(recursoId: number): Observable<ApiResponse<CuestionarioArea[]>> {
    return this.httpClient.get<ApiResponse<CuestionarioArea[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetExamenContestar/${recursoId}`);
  }

  //Get Examen
  public GetEncuestaContestar(recursoId: number): Observable<ApiResponse<CuestionarioArea[]>> {
    return this.httpClient.get<ApiResponse<CuestionarioArea[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetEncuestaContestar/${recursoId}`);
  }

  //Post Responder Examen
  public responderExamen(cuestionarioAreas: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/PostResponderExamen`, cuestionarioAreas);
  }

  //Post Responder Encuesta
  public responderEncuesta(cuestionarioAreas: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/PostResponderEncuesta`, cuestionarioAreas);
  }


  //Get GetExamenAbierto
  public GetExamenAbierto(recursoId: number): Observable<SimpleResponse> {
    return this.httpClient.get<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetExamenAbierto/${recursoId}`);
  }

  //Get GetConsultarResultadoExamen
  public GetConsultarResultadoExamen(recursoId: number, alumnoId: number): Observable<ApiResponse<CuestionarioRespuestaDto[]>> {
    return this.httpClient.get<ApiResponse<CuestionarioRespuestaDto[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetConsultarResultadoExamen/${recursoId}/${alumnoId}`);
  }

  //Get GetRespuestasEncuesta
  public GetRespuestasEncuesta(recursoId: number, alumnoId: number): Observable<ApiResponse<CuestionarioRespuestaDto[]>> {
    return this.httpClient.get<ApiResponse<CuestionarioRespuestaDto[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetRespuestasEncuesta/${recursoId}/${alumnoId}`);
  }

  public obtenerReintentos(alumnoId: number, recursoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/ObtenerNumeroReintentos/${alumnoId}/${recursoId}`);
  }

  /**
    * Get recursos que esten publicados
    * @param grupoId
    * @returns
    */
  public obtenerContenidoAlumno(grupoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetParcialesContenido/${grupoId}`);
  }

  /**
  * Get recursos que esten publicados
  * @param grupoId
  * @returns
  */
  public contenidoCompletado(grupoId: number, recursoId: number, alumnoId: number, completado: boolean): Observable<SimpleResponse> {
    return this.httpClient.get<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/ContenidoCompletado/${grupoId}/${recursoId}/${alumnoId}/${completado}`);
  }

  /**
   * Servicio para settear la informacion del grupo en el subject
   * @param infoGrupo
   */
  public SetInfoGrupo(infoGrupo: any) {
    this.informacionGrupo$.next(infoGrupo);
  }

  /**
   * Servicio para obtener la informacion del grupo como un obs
   * @returns
   */
  public GetInfoGrupo$(): Observable<any> {
    return this.informacionGrupo$.asObservable();
  }

  /**
 * Servicio para settear la informacion del grupo en el subject
 * @param existe
 */
  public SetModalExistente(existe: boolean) {
    this.modalExistente$.next(existe);
  }

  /**
   * Servicio para obtener la informacion del grupo como un obs
   * @returns
   */
  public GetModalExistente$(): Observable<any> {
    return this.modalExistente$.asObservable();
  }

}
