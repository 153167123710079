import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, Subject } from 'rxjs';
import { Departamento } from '../_models/departamento';
import { environment,baseUrl} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DepartamentoService {

 invocarObtenerDepartamento = new EventEmitter();
 invMetodoObtenerDepartamentoSubscription: Subscription;

 constructor(private httpClient: HttpClient) { }

 //Get Departamento
 public obtenerDepartamentos(): Observable<any> {
  return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Departamento`);
 }

 public agregarDepartamentos(departamento: Departamento): Observable<number> {
  return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Departamento`, departamento);
 }

 public modificarDepartamento(departamento: Departamento): Observable<boolean> {
   return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Departamento`, departamento);
 }

 public eliminarDepartamento(departamentoId: string): Observable<any> {
   return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Departamento?departamentoId=` + departamentoId);
 }

 public downloadExcelDepartamento(departamento: any) {
  return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Departamento/DownloadExcel`, JSON.stringify(departamento),
  {
    responseType: "blob",
    headers: {
      'Content-Type': 'application/json',
    }
  });
 }

 invocarMetodoObtenerDepartamento() {
   this.invocarObtenerDepartamento.emit();
 }
}
