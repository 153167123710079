import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { NuevoIngresoAsignarBloqueModalComponent } from './../nuevo-ingreso/nuevo-ingreso-asignar-bloque-modal/nuevo-ingreso-asignar-bloque-modal.component';
import { BlockService } from './../_services/block.service';
import { BlockLabelDto } from './../_models/BlockLabelDto';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { Campus } from '../_models/campus';
import { Alumno, EstatusAcademico } from '../_models/inscripcion';
import { FiltroAdminAcademicoAlumnoDto } from '../_models/FiltroAdminAcademicoAlumnoDto';
import { Periodo } from '../_models/periodo';
import { InscripcionService } from '../_services/inscripcion.service';
import { CampusService } from '../_services/campus.service';
import { PeriodoService } from '../_services/periodo.service';
import { UtileriasService } from '../_services/utilerias.service';
import { MatPaginatorIntl, MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ModalEnviarCorreoComponent } from './modal-enviar-correo/modal-enviar-correo.component';
import { AdminAcademicaService } from '../_services/admin-academica.service';
import { AvanceAcademicoModalComponent } from './avance-academico-modal/avance-academico-modal.component';
import { GeneracionExcelServiceService } from '../services/generacion-excel-service.service';
import { SeguimientoCarteraService } from '../_services/seguimiento-cartera.service';
import { AttributesService } from '../_services/attributes.service';
import { MatOption } from '@angular/material/core';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { EstadoCuentaDto } from '../_models/estado-cuenta-dto';
import { ProgramaAcademicoService } from '../_services/programa-academico.service';
import { ProgramaActivo } from '../_models/programa';
import { EstatusAcademicos } from '../_models/estatus-academico.enum';
import { Block } from '@app/_models/block';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18NextPipe } from 'angular-i18next';
import { AttributesDto } from '../_models/AttributesDto';

@Component({
  selector: 'app-administracion-academica',
  templateUrl: './administracion-academica.component.html',
  styleUrls: ['./administracion-academica.component.scss']
})
export class AdministracionAcademicaComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allBloque') private allBloque: MatOption;
  @ViewChild('allAtritbuto') private allAtritbuto: MatOption;
  public CampusId = new FormControl();
  public ProgramaId = new FormControl();
  public periodoF = new FormControl();
  public bloqueF = new FormControl();
  public atributoF = new FormControl();
  public estatusAdmitido = EstatusAcademicos.Admitido;
  public valorInicial = 100;
  displayedColumns: string[] = [
    'select',
    'id',
    'nombre',
    'periodo',
    'programa',
    'campus',
    'tipoAlumno',
    'ciclo',
    'promedio',
    'documentos',
    'estatus',
    'blockName'];
  public dataSource: MatTableDataSource<any>;
  public filtroForm: FormGroup;
  public selection = new SelectionModel<any>(true, []);
  public numIngresoSeleccionado: number = 0;
  public datafilter: any;
  public arregloFiltroOriginal: any[];
  public subscriptionCampus: Subscription;
  public campusList: Campus[] = [];
  public estatusList: EstatusAcademico[] = [];
  public Programas: ProgramaActivo[] = [];
  public Periodos: Periodo[];
  public arregloFiltroModificado: any[] = [];
  private periodosactivos: any[] = [];
  public periodoSeleccionado: number = 0;
  public campusSeleccionado: number = 0;
  public programaSeleccionado: number = 0;
  public statusSeleccionado: number = 0;
  public tipoAlumnoSeleccionado: number = 0;
  public tipoInscripcion: string = "NI";
  public tipoAl: number = 0;
  public ExcelSubscription: Subscription;
  public timer: any;
  public BlockLabel: BlockLabelDto[];
  public attributes: AttributesDto[];
  public Block: Block;
  public existenAtributos: boolean = false;

  constructor(
    public inscripcionService: InscripcionService,
    public adminAcademicaService: AdminAcademicaService,
    private campusService: CampusService,
    public programaservice: ProgramaAcademicoService,
    private periodoService: PeriodoService,
    public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    public generadorExcel: GeneracionExcelServiceService,
    public seguimientoService: SeguimientoCarteraService,
    private _BlockService: BlockService,
    private snackBar: MatSnackBar,
    private i18nextPipe: I18NextPipe,
    public nuevoIngresoService: NuevoIngresoService,
    public attributesService: AttributesService) { }

  async ngOnInit(): Promise<void> {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    await this.inicializarForm().then((_) => {
      this.LlenarFiltros();
    });
    if (this.adminAcademicaService.actualizarTablaSubcription == undefined) {
      this.adminAcademicaService.actualizarTablaSubcription = this.adminAcademicaService.actualizarTabla.subscribe(() => {
        this.LlenarFiltros();
      });
    }
  }

  /**
   * Metodo que inicializa el form para los filtros
   */
  public inicializarForm(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.filtroForm = new FormGroup({
        tipoAlumno: new FormControl(0),
        periodoF: new FormControl([]),
        idnombre: new FormControl(),
        ProgramaId: new FormControl([]),
        CampusId: new FormControl([]),
        estatus: new FormControl(0),
        bloqueF: new FormControl([]),
        atributoF: new FormControl([])
      });
      this.CampusId.setValue(["-1"]);
      this.ProgramaId.setValue(["-1"]);
      this.periodoF.setValue(["-1"]);
      this.bloqueF.setValue(["-1"]);
      this.atributoF.setValue(["-1"]);
      resolve();
    });
  }

  /**
   * Metodo paraa obtener y llenar los filtros
   */
  public LlenarFiltros() {
    this.obtenerPeriodo();
  }

  /**
   * Metodo para obtener los periodos
   */
  public obtenerPeriodo() {
    let today = new Date();
    this.periodoService.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        this.Periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio <= today && fin >= today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if (this.periodosactivos.length > 0) {
          this.periodoF.setValue([...this.periodosactivos]);

        }
        this.obtenerProgramas();
      });
  }

  /**
   * Metodo para obtener los programas
   */
  public obtenerProgramas() {
    this.programaservice.obtenerProgramasActivos().subscribe(
      (programas: ApiResponse<ProgramaActivo[]>) => {
        this.Programas = programas.data;
        this.ProgramaId.setValue([...this.Programas.map(item => item.programaId), 0]);
        this.filtroForm.patchValue({
          ProgramaId: this.Programas.map(item => item.programaId)
        });
        this.obtenerCampus();
      }
    );
  }

  /**
   * Metodo para obtener los campus
   */
  public obtenerCampus() {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe((campus: Campus[]) => {
      this.campusList = campus;
      this.CampusId.setValue([...this.campusList.map(item => item.campusId), 0]);
      this.filtroForm.patchValue({
        CampusId: this.campusList.map(item => item.campusId)
      });
      this.obtenerEstatus();
    });
  }

  /**
   * Metodo para obtener los estatus
   */
  public obtenerEstatus() {
    this.inscripcionService.GetEstatusAcademicos().subscribe((status: ApiResponse<EstatusAcademico[]>) => {
      this.estatusList = status.data;
      this.obtenerBloques();
    });
  }

  /**
 * Metodo que consulta los bloques
 */
  public obtenerBloques() {
    this._BlockService.GetBlockLabelDto().subscribe((respuesta: ApiResponse<BlockLabelDto[]>) => {
      this.BlockLabel = respuesta.data;
      this.obtenerAtributos();
    })
  }

  /**
  * Metodo que consulta los atributos
  */
  public obtenerAtributos() {
    this.attributesService.attributesInstitucion().subscribe((respuesta: ApiResponse<AttributesDto[]>) => {
      this.attributes = respuesta.data;
      this.existenAtributos = this.attributes.length > 0;
      this.filtrar();
    })
  }

  /**
   * Metodo que envia laa informacion de los filtros al back
   */
  public filtrar() {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      tipoAlumnoId: this.filtroForm.get('tipoAlumno').value,
      programas: this.filtroForm.get('ProgramaId').value,
      campus: this.filtroForm.get('CampusId').value,
      estatus: this.filtroForm.get('estatus').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value
    };
    console.log("SILLEGUE asasasas")
    this.cargarInscripciones(0, 1, this.paginator.pageSize, filtro);
  }

  /**
   * Metodo para buscar los filtros
   */
  public buscarFiltros(): void {
    this.filtrar();
  }

  /**
   * Metodo que inicia un timer cadaa vez que es llamado
   */
  public iniciarTimer() {
    this.timer = window.setTimeout(
      () => {
        this.filtrar()
      }, 400);
  }

  /**
   * Metodo que reinia el timer cada vez que es llamado
   */
  public reiniciarTimer() {
    clearTimeout(this.timer);
    this.iniciarTimer();
  }

  /**
   * Metodo para seleccionar toodos los periodos
   */
  toggleAllSelectionPeriodo() {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.filtroForm.patchValue({
        periodoF: this.Periodos.map(item => item.periodoId)
      });
      this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.filtroForm.patchValue({
        periodoF: []
      });
      this.periodoF.patchValue([]);
    }
  }

  /**
   * Metodo para seleccionar toodos los programas
   */
  toggleAllSelectionPrograma() {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.filtroForm.patchValue({
        ProgramaId: this.Programas.map(item => item.programaId)
      });
      this.ProgramaId.patchValue([...this.Programas.map(item => item.programaId), 0]);
    } else {
      this.filtroForm.patchValue({
        ProgramaId: []
      });
      this.ProgramaId.patchValue([]);
    }
  }

  /**
   * Metodo paraa seleccionar todos los campus
   */
  toggleAllSelectionCampus() {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.filtroForm.patchValue({
        CampusId: this.campusList.map(item => item.campusId)
      });
      this.CampusId.patchValue([...this.campusList.map(item => item.campusId), 0]);
    } else {
      this.filtroForm.patchValue({
        CampusId: []
      });
      this.CampusId.patchValue([]);
    }
  }

  /**
 * Metodo cuando se seleccionan todos los bloques
 */
  toggleAllSelectionBloque() {
    // this.reiniciarTimer();
    if (this.allBloque.selected) {
      this.filtroForm.patchValue({
        bloqueF: this.BlockLabel.map(item => item.blockLabelId)
      });
      this.bloqueF.patchValue([...this.BlockLabel.map(item => item.blockLabelId), 0]);
    } else {
      this.filtroForm.patchValue({
        bloqueF: []
      });
      this.bloqueF.patchValue([]);
    }
  }

  /**
* Metodo cuando se seleccionan todos los atributos
*/
  toggleAllSelectionAtributo() {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.filtroForm.patchValue({
        atributoF: this.attributes.map(item => item.attributeId)
      });
      this.atributoF.patchValue([...this.attributes.map(item => item.attributeId), 0]);
    } else {
      this.filtroForm.patchValue({
        atributoF: []
      });
      this.atributoF.patchValue([]);
    }
  }

  /**
   * Metodo para traer solo los periodos seleccionados
   * @param periodoId
   * @returns
   */
  tosslePerOnePeriodo(periodoId: number) {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
    }
    if (this.periodoF.value.length == this.Periodos.length) {
      this.allPeriodo.select();
    }
    const periodosSeleccionados = this.filtroForm.get('periodoF').value;
    const existePeriodo = periodosSeleccionados.find(x => x === periodoId);
    if (!existePeriodo) {
      const periodosAnteriores = periodosSeleccionados;
      periodosAnteriores.push(periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosAnteriores
      });
    } else {
      const periodosFiltrados = periodosSeleccionados.filter(x => x !== periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosFiltrados
      });
    }
  }

  /**
   * Metodo para traaaer los programas seleccionados
   * @param programaId
   * @returns
   */
  tosslePerOnePrograma(programaId: number) {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
    }
    if (this.ProgramaId.value.length == this.Programas.length) {
      this.allPrograma.select();
    }
    const programasSeleccionados = this.filtroForm.get('ProgramaId').value;
    const existePrograma = programasSeleccionados.find(x => x === programaId);
    if (!existePrograma) {
      const programasAnteriores = programasSeleccionados;
      programasAnteriores.push(programaId);
      this.filtroForm.patchValue({
        ProgramaId: programasAnteriores
      });
    } else {
      const programasFiltrados = programasSeleccionados.filter(x => x !== programaId);
      this.filtroForm.patchValue({
        ProgramaId: programasFiltrados
      });
    }
  }

  /**
   * Metodo para traer los campus seleccionados
   * @param campusId
   * @returns
   */
  tosslePerOneCampus(campusId: number) {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.allCampus.deselect();
    }
    if (this.CampusId.value.length == this.campusList.length) {
      this.allCampus.select();
    }
    const campusSeleccionados = this.filtroForm.get('CampusId').value;
    const existeCampus = campusSeleccionados.find(x => x === campusId);
    if (!existeCampus) {
      const campusAnteriores = campusSeleccionados;
      campusAnteriores.push(campusId);
      this.filtroForm.patchValue({
        CampusId: campusAnteriores
      });
    } else {
      const campusFiltrados = campusSeleccionados.filter(x => x !== campusId);
      this.filtroForm.patchValue({
        CampusId: campusFiltrados
      });
    }
  }

  /**
 * Verifica si el campo Todos del select Bloque esta seleccionados, si lo esta
 * quita la seleccion de todos los campos, sino los selecciona todos
 * @param bloqueId
 * @returns
*/
  tosslePerOneBloque(bloqueId: number) {
    // this.reiniciarTimer();
    if (this.allBloque.selected) {
      this.allBloque.deselect();
    }
    if (this.bloqueF.value.length == this.BlockLabel.length) {
      this.allBloque.select();
    }
    const bloqueSeleccionados = this.filtroForm.get('bloqueF').value;
    const existeBloque = bloqueSeleccionados.find(x => x === bloqueId);
    if (!existeBloque) {
      const bloqueAnteriores = bloqueSeleccionados;
      bloqueAnteriores.push(bloqueId);
      this.filtroForm.patchValue({
        bloqueF: bloqueAnteriores
      });
    } else {
      const bloqueFiltrados = bloqueSeleccionados.filter(x => x !== bloqueId);
      this.filtroForm.patchValue({
        bloqueF: bloqueFiltrados
      });
    }
  }

  /**
 * Verifica si el campo Todos del select atributo esta seleccionados, si lo esta
 * quita la seleccion de todos los campos, sino los selecciona todos
 * @param AtributoId
 * @returns
*/
tosslePerOneAtributo(AtributoId: number) {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.allAtritbuto.deselect();
    }
    if (this.atributoF.value.length == this.attributes.length) {
      this.allAtritbuto.select();
    }
    const AtributoSeleccionados = this.filtroForm.get('atributoF').value;
    const existeAtributo = AtributoSeleccionados.find(x => x === AtributoId);
    if (!existeAtributo) {
      const AtributoAnteriores = AtributoSeleccionados;
      AtributoAnteriores.push(AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoAnteriores
      });
    } else {
      const AtributoFiltrados = AtributoSeleccionados.filter(x => x !== AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoFiltrados
      });
    }
  }

  /**
   * Metodo para filtrar por nombre o id
   * @param filterValue
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase();
    if (filterValue != "") {
      this.dataSource.filter = filterValue;
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        var nombre = data.usuario.nombre + " " + data.usuario.apellidoPaterno + " " + data.usuario.apellidoMaterno;
        return nombre.toLowerCase().includes(filter) || data.usuario.identificador.toString().includes(filter);
      };
      this.dataSource.paginator = this.paginator;
    }
  }

  /**
   * Metodo para limpiar los filtros
   */
  public LimpiarFiltros() {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Metodo para reiniciar el arreglo modificado
   */
  public vaciarArregloModificado() {
    this.arregloFiltroModificado.length = 0;
  }

  /**
   * Ejecuta el servicio que consume la Api para consultar los alumnos inscritos
   */
  public cargarInscripciones(tipoAlumno: number, pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto) {
    this.adminAcademicaService.getInscripciones(tipoAlumno, pagina, registrosPagina, filtro).subscribe((inscripcionAlumno: ApiResponse<any>) => {
      if (inscripcionAlumno.data) {
        this.arregloFiltroOriginal = inscripcionAlumno.data.alumnos;
        this.datafilter = inscripcionAlumno.data.alumnos;
        this.dataSource = new MatTableDataSource(this.datafilter);
        this.valorInicial = inscripcionAlumno.data.cantidadAlumnos;
        this.selection = new SelectionModel<any>(true, []);
      } else {
        this.dataSource = new MatTableDataSource([]);
        this.arregloFiltroOriginal = [];
      }
    }, (err => {
      this.dataSource = new MatTableDataSource([]);
      this.arregloFiltroOriginal = [];
    }))
  }

  /**
   * Valida si se ha seleccionado todos los rows
   * @returns boolean
   */
  isAllSelected() {
    if (this.datafilter) {
      this.numIngresoSeleccionado = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numIngresoSeleccionado === numRows;
    }
  }

  /**
   * Marca todas los rows de la tabla
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Determina qué texto se mostrará en el Label con base a si se ha enviado on o un parámetro
   * @param row
   * @returns
   */
  checkboxLabel(row?: Alumno): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.alumnoId + 1}`;
  }

  /**
   * Abre el modal para enviar correo
   * @param btnDataTable
   * @param elemento
   */
  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    let datos;
    if (btnDataTable) {
      datos = elemento;
    }
    else {
      if (elemento.length === 1) {
        datos = elemento[0];
      }
      else {
        datos = elemento;
      }
    }
    this.dialog.open(ModalEnviarCorreoComponent, { data: datos, disableClose: true });
  }

  /**
   * Metodo paraa hacer el cambio de calificacion
   * @param btnDataTable
   * @param elemento
   */
  public AbrirApartadoCambioCalf(btnDataTable: boolean, elemento?: any): void {
    let datos;
    if (btnDataTable) {
      datos = elemento;
    }
    else {
      if (elemento.length === 1) {
        datos = elemento[0];
      }
      else {
        datos = elemento;
      }
    }
    this.AbrirModalLateral(datos, 6);
  }

  /**
   * Metodo para aabrir modaal de acreditaciones
   * @param btnDataTable
   * @param elemento
   */
  public AbrirApartadoAcreditaciones(btnDataTable: boolean, elemento?: any): void {
    let datos;
    if (btnDataTable) {
      datos = elemento;
    }
    else {
      if (elemento.length === 1) {
        datos = elemento[0];
      }
      else {
        datos = elemento;
      }
    }
    this.AbrirModalLateral(datos, 5);
  }

  /**
   * Ejecuta el método del api que descarga la información que hay en pantalla
   */
  public descargarExcel(elemento?: any) {
    let alumnos;
    if (elemento != null && elemento.length > 0) {
      alumnos = elemento;
    } else {
      alumnos = null;
    }


    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      tipoAlumnoId: this.filtroForm.get('tipoAlumno').value,
      programas: this.filtroForm.get('ProgramaId').value,
      campus: this.filtroForm.get('CampusId').value,
      estatus: this.filtroForm.get('estatus').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value,

      alumnos: alumnos
    };


    this.ExcelSubscription = this.adminAcademicaService.descargarExcelSeguimiento(filtro).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "AdministracionAcademicaAlumnos");
      this.ExcelSubscription.unsubscribe();
    })
  }

  /**
   * Abre el modal lateral con la información requerida
   */
  public AbrirModalLateral(Alumno: Alumno, indexTap: number) {
    //Se crea el objeto con la data base del objeto cleckeado y el número del tap requerido
    const data = {
      alumno: {
        ...Alumno
      },
      indexTap
    }
    if (indexTap == 0) {
      this.buscarInformacionUsuario(Alumno.alumnoId);
    }
    this.util.abrirDialogoLateral(AvanceAcademicoModalComponent, data);
  }

  /**
   * Obtiene la información del usuario y la envía al servicio
   * @param usuarioId
   */
  public buscarInformacionUsuario(usuarioId: number) {
    this.seguimientoService.obtenerSeguimientoUsuarioById(usuarioId).subscribe(
      (seguimientoUsuario: ApiResponse<EstadoCuentaDto[]>) => {
        this.seguimientoService.setDatosSeguimientoUsuario$(seguimientoUsuario.data);
      });
  }

  /**
   * Metodo que detecta el cambio de pagina y llama el servicio para traer la siguiente informacion
   * @param event
   */
  public onPaginateChange(event: any) {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      tipoAlumnoId: this.filtroForm.get('tipoAlumno').value,
      programas: this.filtroForm.get('ProgramaId').value,
      campus: this.filtroForm.get('CampusId').value,
      estatus: this.filtroForm.get('estatus').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value
    };
    this.cargarInscripciones(0, event.pageIndex + 1, event.pageSize, filtro)
  }

  /**
   * Metodo para hacer la asignaacion de bloques
   * @param blockLabel
   * @param alumnos
   */
  public asignarBloque(blockLabel: BlockLabelDto, alumnos: any[]): void {
    if (this.verificarCampus(alumnos)) {
      this.existeBloque(blockLabel, alumnos);
    } else {
      this.snackBar.open(this.translate("_nuevosIngresos.mismo-campus"), "OK", { duration: 5000 });
    }
  }

  /**
   * Metodo que verifica el campus
   * @param alumnos
   * @returns
   */
  private verificarCampus(alumnos: any[]): boolean {
    const primerAlumno = alumnos[0];
    for (let index = 0; index < alumnos.length; index++) {
      const alumno = alumnos[index];
      if (alumno.campusId != primerAlumno.campusId) {
        return false;
      }
    }
    return true;
  }

  /**
  * Metodo para validaar si existe un bloque
  * @param blockLabel
  * @param alumnos
  */
  private existeBloque(blockLabel: BlockLabelDto, alumnos: any[]): void {
    const primerAlumno = alumnos[0];
    this._BlockService.GetBlockByPeriodoCampus(blockLabel.blockLabelId, primerAlumno.blockPeriodoId, primerAlumno.campusId).subscribe((respuesta: ApiResponse<Block>) => {
      this.Block = respuesta.data;
      this.AbrirModalBloque(blockLabel, alumnos, respuesta.data, respuesta.success);
    });
  }

  /**
 * Metodo paraa abrir el modal lateral del bloque
 * @param blockLabel
 * @param alumnos
 * @param block
 * @param existe
 */
  private AbrirModalBloque(blockLabel: BlockLabelDto, alumnos: any[], block: Block, existe: boolean) {
    alumnos = alumnos.map(obj => ({
      ...obj,
      programaClave: obj.programa.clave,
      identificador: obj.usuario.identificador
    }));
    let content = {
      alumnos: alumnos,
      blockLabel: blockLabel,
      block: block,
      existe: existe,
      nuevoIngreso: false
    }
    const dialogRef = this.dialog.open(NuevoIngresoAsignarBloqueModalComponent, { data: content, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.selection.clear();
    });
  }

  /**
   * Metodo paraa haacer traaducciones
   * @param text
   * @returns
   */
  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  ngOnDestroy(): void {
    if (this.ExcelSubscription != null) {
      this.ExcelSubscription.unsubscribe();
    }
  }
}
