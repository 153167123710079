import { TraductorService } from 'src/app/_services/traductor.service';
import { ContenidoParcial, Respuesta } from './../../../_models/ensenanza-aprendizaje-profesor';
import { I18NextPipe } from 'angular-i18next';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SnackService } from 'src/app/services/snack-service.service';
import { InfoPorRevisarDto, InformacionGrupoAsistencia } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { EnsenanzaAprendizajeService } from '../../../_services/ensenanza-aprendizaje.service';
import { UtileriasService } from '../../../_services/utilerias.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { EscalaCalificacionService } from '@app/_services/escala-calificacion.service';
@Component({
  selector: 'app-contenido-calificaciones',
  templateUrl: './contenido-calificaciones.component.html',
  styleUrls: ['./contenido-calificaciones.component.scss']
})
export class ContenidoCalificacionesComponent implements OnInit {

  @Input() grupoID: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('dialogoListaEscalaModal') dialogoListaEscalaModal: TemplateRef<any>;
  @ViewChild('paginatorLista') paginatorLista: MatPaginator;
  public infoCargadoLista: boolean = false;
  public dataSourceListaEscala: MatTableDataSource<any>;
  public dialogoRefListaEscalaModal: any;
  selectionListaEscala = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['select', 'id', 'nombre', 'procesoGraduacion', 'calificacion', 'faltas', 'avance', 'status'];
  displayedColumnsEscala: string[] = ['calificacion2', 'valor2'];
  // displayedColumns: string[] = ['select', 'id', 'nombre', 'procesoGraduacion', 'calificacion', 'faltas'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public filtroForm: FormGroup;
  public listaParciales: any;
  public datafilter: InfoPorRevisarDto[] = [];
  public informacionGrupo: InformacionGrupoAsistencia;
  public listaAlumnos: any;
  public infoCargada = false;
  public numAlumnosSeleccionado: number = 0;
  public dialogoRefAdver: any;
  public infoTablaCargada: boolean = false;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public readonly PRIMER_PARCIAL: number = 1;
  public ParcialActual: number;
  public controls: FormArray;
  public usuarioSesion: any;
  existeParcialFinal: boolean = false;
  ultimoParcial: number = 0;
  contenido: ContenidoParcial[] = [];
  contenidoGrupoParcial: any[] = [];

  constructor(private paginador: MatPaginatorIntl,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    public utileriaService: UtileriasService,
    private dialog: MatDialog,
    private snackService: SnackService,
    private i18nextPipe: I18NextPipe,
    private traductorService: TraductorService,
    private escalaService: EscalaCalificacionService) { }

  ngOnInit(): void {
    this.obtenerUsuarioSesion();
    this.inicializarForm();
    this.obtenerInfoGrupo(this.grupoID);
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    if (this.ensenanzaAprendizajeService.metodoObtenerParcial1Subscription == undefined) {
      this.ensenanzaAprendizajeService.metodoObtenerParcial1Subscription = this.ensenanzaAprendizajeService.invocarObtenerParcial1Emit.subscribe(() => {
        this.obtenerAlumnosByGrupo(this.grupoID, this.ParcialActual, this.informacionGrupo.escalaCalificacionId);
        this.filtroForm.get('parcial').setValue(this.ParcialActual);
      });
    }

    if (this.ensenanzaAprendizajeService.invocarObtenerPorRevisarSubscription == undefined) {
      this.ensenanzaAprendizajeService.invocarObtenerPorRevisarSubscription = this.ensenanzaAprendizajeService.invocarObtenerPorRevisar.subscribe(() => {
        this.obtenerInfoGrupo(this.grupoID);
      });
      this.ensenanzaAprendizajeService.invocarObtenerPorRevisarSubscription = null;

    }

  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      parcial: new FormControl(0),
      graduacion: new FormControl(0)
    });

    this.filtroForm.get('parcial').valueChanges.subscribe(val => {
      if (val == -1) {
        this.obtenerAlumnosByGrupoFinal(this.grupoID, this.ultimoParcial, this.informacionGrupo.escalaCalificacionId)
      } else {
        this.obtenerAlumnosByGrupo(this.grupoID, val, this.informacionGrupo.escalaCalificacionId);
      }

    });
  }

  public formTabla() {
    const toGroups = this.listaAlumnos.map(entity => {
      return new FormGroup({
        calificacionNoNumericaNombre: new FormControl(entity.calificacionNoNumericaNombre, Validators.required),
        faltas: new FormControl(entity.faltas, Validators.required)
      }, { updateOn: "blur" });
    });
    this.controls = new FormArray(toGroups);
  }

  public updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      // this.update(index, field, control.value);
    }
  }

  public getControl(index, fieldName) {
    const a = this.controls.at(index).get(fieldName) as FormControl;
    return this.controls.at(index).get(fieldName) as FormControl;
  }

  public update(value, alumnoId, field) {
    this.listaAlumnos = this.listaAlumnos.map((e) => {
      if (alumnoId === e.alumnoId) {
        return {
          ...e,
          [field]: value
        };
      }
      return e;
    });
  }


  public obtenerAlumnosByGrupoFinal(grupoId: number, parcial: number, escalaCalificacionId: number) {
    this.ensenanzaAprendizajeService.obtenerAlumnosCalificacionesFinal(grupoId, parcial, escalaCalificacionId).subscribe(
      (alumnos: any) => {
        this.listaAlumnos = alumnos.result;
        console.log("this.listaAlumnos", this.listaAlumnos);
        this.dataSource = new MatTableDataSource(this.listaAlumnos);
        this.dataSource.paginator = this.paginator;
        this.selection = new SelectionModel<any>(true, []);
        this.datafilter = this.listaAlumnos;
        this.formTabla();
        this.infoTablaCargada = true;
      });
  }

  /**
   * Metodo para obtener la informacion de los alumnos registrados en el grupo, faltas calificaciones.
   * @param grupoId
   * @param parcial
   * @param escalaCalificacionId
   */
  public obtenerAlumnosByGrupo(grupoId: number, parcial: number, escalaCalificacionId: number) {
    this.ensenanzaAprendizajeService.obtenerAlumnosCalificaciones(grupoId, parcial, escalaCalificacionId).subscribe(
      (Respuesta: ApiResponse<any>) => {
        if (Respuesta.success) {
          this.listaAlumnos = Respuesta.data;
          this.dataSource = new MatTableDataSource(this.listaAlumnos);
          this.dataSource.paginator = this.paginator;
          this.selection = new SelectionModel<any>(true, []);
          this.datafilter = this.listaAlumnos;
          this.formTabla();
          this.infoTablaCargada = true;
          console.log("this.listaAlumnos", this.listaAlumnos);
        } else {
          let mensaje: string = this.traductorService.translate(Respuesta.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
  }

  public modalEscala() {
    this.escalaService.obtenerEscalaCalificacionesByEscala(this.listaAlumnos[0].grupoId).subscribe(respuesta => {
      this.dataSourceListaEscala = new MatTableDataSource(respuesta);
      this.selectionListaEscala = new SelectionModel<any>(true, []);
      this.infoCargadoLista = true;
      setTimeout(() => {
        this.dataSourceListaEscala.paginator = this.paginatorLista;
      }, 100);
    })
    this.dialogoRefListaEscalaModal = this.dialog.open(this.dialogoListaEscalaModal, {
      panelClass: "dialogo-agregar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  public cerrarModalEscala() {
    this.dialogoRefListaEscalaModal.close();
  }

  public obtenerInfoGrupo(grupoId: number): void {
    this.ensenanzaAprendizajeService.obtenerInfoGrupoAsistencia(grupoId).subscribe(
      (info: InformacionGrupoAsistencia) => {
        if (info !== null) {
          this.informacionGrupo = info;
          this.informacionGrupo.nombreProfesor = `${this.informacionGrupo?.profesor?.nombre ?? ''} ${this.informacionGrupo?.profesor?.apellidoPaterno ?? ''} ${this.informacionGrupo?.profesor?.apellidoMaterno ?? ''}`;
          this.obtenerAlumnosByGrupo(grupoId, this.PRIMER_PARCIAL, info.escalaCalificacionId);
          this.cargarContenido();
        } else {

        }
      });
  }

  //#region  MultiLenguaje

  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  //#endregion
  //#region  Cargar contenidos
  public cargarContenido() {
    this.ensenanzaAprendizajeService.obtenerContenido(this.grupoID).subscribe((res: ApiResponse<any>) => {
      if (res.data) {
        this.contenidoGrupoParcial = res.data.grupoParcial;
        this.cargarParciales(res.data.clave);
      }
    })
  }
  //#endregion
  public cargarParciales(clave: string) {
    this.contenido.length = 0;
    this.ensenanzaAprendizajeService.obtenerParcialesProfesor(clave, this.grupoID).subscribe(parciales => {
      if (parciales.exito) {
        if (parciales.dataParcialFinal) {
          this.existeParcialFinal = true;
        }

        parciales.dataParciales.forEach(parcial => {

          let parcialText = '_tableroProfesor.parcial';
          if (!!this.existeParcialFinal && parcial == parciales.dataParciales.length) {
            parcialText = '_tableroProfesor.parcial-final';
          };

          if (this.contenidoGrupoParcial.length > 0) {
            let nuevoParcial = this.contenidoGrupoParcial.find(object => {
              return object.parcial == parcial
            });
            if (nuevoParcial) {
              let parcialobj = {
                parcial: nuevoParcial.parcial,
                ponderacion: nuevoParcial.ponderacion,
                nombre: nuevoParcial.nombre,
              }
              if (!parcialobj?.nombre) {
                parcialobj.nombre = this.translate(parcialText)// + ' ' + parcial;
              }
              this.contenido.push(parcialobj);
            } else {
              let _parcialObj = {
                parcial: parcial,
                ponderacion: 0,
                nombre: this.translate(parcialText)// + ' ' + parcial
              }
              this.contenido.push(_parcialObj);
            }
          }
          else {
            let _parcialObj = {
              parcial: parcial,
              ponderacion: 0,
              nombre: this.translate(parcialText) + ' ' + parcial
            }
            this.contenido.push(_parcialObj);
          }
        });
        this.filtroForm.get('parcial').setValue(this.contenido[0].parcial);
        this.infoCargada = true;
      }
    });
  }

  public filtrar() {
    var parcial = this.filtroForm['value'].parcial;
    var graduacion = this.filtroForm['value'].graduacion;
    let filtroModificado: any = [];
    filtroModificado = this.listaAlumnos;
    if (parcial != 0) {
      filtroModificado = filtroModificado.filter(row => row.parcial === parcial);
    }
    if (graduacion != 0) {
      filtroModificado = filtroModificado.filter(row => row.graduacion === graduacion);
    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
  }

  public limpiar() {
    this.inicializarForm();
    this.dataSource = new MatTableDataSource(this.datafilter);
    this.dataSource.paginator = this.paginator;
  }

  public abrirModalConfirmacion() {
    this.ParcialActual = this.filtroForm.get('parcial').value;
    this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, {
      panelClass: "dialogo-agregar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  public confirmarCalificaciones() {
    let seleccionados = this.selection.selected;
    let seleccionadorActualizados: any[] = [];
    seleccionados.forEach(element => {
      let alumnoEncontrado = this.listaAlumnos.find(x => x.alumnoId === element.alumnoId);
      seleccionadorActualizados.push(alumnoEncontrado);
    });
    console.log("seleccionadorActualizados", seleccionadorActualizados)
    let datosCalAlumnos = seleccionadorActualizados.map(obj =>
    ({
      ...obj,
      usuarioSesion: this.usuarioSesion.usuarioId,
      escalaCalificacionId: this.informacionGrupo.escalaCalificacionId
    })
    );
    if (this.filtroForm.get('parcial').value == -1) {
      this.ensenanzaAprendizajeService.enviarCalificacionesAumnosFinal(datosCalAlumnos).subscribe(
        (res) => {
          this.dialogoRefAdver.close();
          this.obtenerAlumnosByGrupoFinal(this.grupoID, this.ParcialActual, this.informacionGrupo.escalaCalificacionId);
          let mensaje: string = this.traductorService.translate(res.result.result);
          this.snackService.mostrarSnackBack(mensaje);
        }
      );
    }
    else {
      this.ensenanzaAprendizajeService.enviarCalificacionesAumnos(datosCalAlumnos).subscribe(
        (res) => {
          this.dialogoRefAdver.close();
          this.obtenerAlumnosByGrupo(this.grupoID, this.ParcialActual, this.informacionGrupo.escalaCalificacionId);
          let mensaje: string = this.traductorService.translate(res.result.result);
          this.snackService.mostrarSnackBack(mensaje);
        }
      );
    }
  }

  public obtenerUsuarioSesion() {
    this.ensenanzaAprendizajeService.obtenerUsuarioConectado().subscribe(
      (usuario) => {
        this.usuarioSesion = usuario;
      }
    )
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.datafilter.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    if (this.datafilter) {
      this.numAlumnosSeleccionado = this.selection.selected.length;
      const numRows = this.datafilter.length;
      return this.numAlumnosSeleccionado === numRows;
    }
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }

  public cerrarModal() {
    this.dialogoRefAdver.close();
  }

}
