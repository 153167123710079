import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { TipoContrato } from '../_models/tipo-contrato';

@Injectable({
  providedIn: 'root'
})
export class TipoContratoService {

  constructor(private httpClient: HttpClient) { }

  public obtenerTipoContrato(): Observable<TipoContrato[]> {
    return this.httpClient.get<TipoContrato[]>(`${environment.enves[baseUrl].apiUrl}/TipoContrato`);
  }
}
