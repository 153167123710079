import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { RequestTypeDTO, RequestCategoryDTO } from 'src/app/_models/solicitud-dto';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { MatDialog } from '@angular/material/dialog';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { SolicitudTipoModalEdicionComponent } from './solicitud-tipo-modal-edicion/solicitud-tipo-modal-edicion.component';
import { SolicitudTipoModalEliminarComponent } from './solicitud-tipo-modal-eliminar/solicitud-tipo-modal-eliminar.component';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-solicitud-tipo',
  templateUrl: './solicitud-tipo.component.html',
  styleUrls: ['./solicitud-tipo.component.scss']
})
export class SolicitudTipoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'clave', 'nombre', 'categoria', 'descripcion', 'correo', 'estatus'];
  @ViewChild('allCategoria') private allCategoria: MatOption;
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  
  public ExcelSubscription: Subscription;
  public requestType: any;
  public infoCargado: boolean = false;
  public numRequestTypeSeleccionado: number = 0;
  public claveName = new FormControl();
  public categoriaF = new FormControl();
  public estatusF = new FormControl();
  private arrOriginal: RequestTypeDTO[] = [];
  public arrCategorias: RequestCategoryDTO[] = [];
  public filtroForm: FormGroup;
  
  constructor(private solicitudService: SolicitudService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private util: UtileriasService) { }

  ngOnInit(): void {
    if(this.solicitudService.invMetodoObtenerTipoSubscription == undefined) {
      this.solicitudService.invMetodoObtenerTipoSubscription = this.solicitudService.invocarObtenerTipos.subscribe(() => {
        this.obtenerTiposSolicitud();
        this.limpiarFiltros();
      });
    }
      this.paginador.itemsPerPageLabel = "Registros por página";
      this.paginador.nextPageLabel = "Página siguiente";
      this.paginador.previousPageLabel = "Página anterior";
      this.paginador.firstPageLabel = "Primera página";
      this.paginador.lastPageLabel = "Última página";
      this.paginador.getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
          return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
    this.obtenerTiposSolicitud();
    this.llenarFiltros();
    this.inicializarForm();
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      claveName: new FormControl(''),
      categoriaF: new FormControl([]),
      estatusF: new FormControl([])
    });
  }
  
  public filtro() {
    let claveNombre: string = this.claveName.value == null ? '' : this.claveName.value;
    let categoria: any[] = this.categoriaF.value == null ?  [] : this.categoriaF.value;
    let estatusF: any = this.estatusF.value == null ? 0 : this.estatusF.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    if(claveNombre.length > 0 || claveNombre !== ''){
      filtroModificado = filtroModificado.filter(f => { 
        return f.code.toLowerCase().search(claveNombre.toLocaleLowerCase()) > -1 || 
        f.name.toLowerCase().search(claveNombre.toLocaleLowerCase()) > -1; 
      });
    }
    if(categoria.length > 0){
      filtroModificado = filtroModificado.filter(f => { return categoria.indexOf(f.requestCategoryId) > -1 });
    }
    if(estatusF !== 0) {
      if(estatusF == 1) {
        filtroModificado = filtroModificado.filter(r => { return r.status == true });
      } else {
        filtroModificado = filtroModificado.filter(r => { return r.status == false });
      }
    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
  }

  tosslePerOneCategoria(all) {
    if (this.allCategoria.selected) {
      this.allCategoria.deselect();

    }
    if (this.categoriaF.value.length == this.arrCategorias.length) { this.allCategoria.select(); }
    this.filtro();
  }

  toggleAllSelectionCategoria() {
    if (this.allCategoria.selected) {
      this.categoriaF.patchValue([...this.arrCategorias.map(item => item.requestCategoryId), 0]);
    } else {
      this.categoriaF.patchValue([]);
    }
    this.filtro();
  }

  public llenarFiltros() {
    this.solicitudService.obtenerSolicitudCategoria().subscribe(
      (categorias: ApiResponse<RequestCategoryDTO[]>) => {
        this.arrCategorias = categorias.data;
        this.categoriaF.setValue([...this.arrCategorias.map(item => item.requestCategoryId), 0]);
        this.filtro();
    });
  }

  limpiarFiltros() {
    this.categoriaF.patchValue([...this.arrCategorias.map(item => item.requestCategoryId), 0]);
    this.estatusF.patchValue(0);
    this.claveName.setValue('');
    this.obtenerTiposSolicitud();
  }

  public obtenerTiposSolicitud() {
    this.solicitudService.obtenerSolicitudTipo().subscribe((tipos: ApiResponse<RequestTypeDTO[]>) => {
      this.dataSource = new MatTableDataSource(tipos.data);
      this.selection = new SelectionModel<any>(true, []);
      this.dataSource.paginator = this.paginator;
      this.infoCargado = true;
      this.arrOriginal = [...tipos.data];
    });
  }

  public crear(): void {
    let datos: RequestTypeDTO = {
      requestTypeId: 0,
      requestCategoryId: 0,
      code: '',
      name: '',
      description: '',
      email: '',
      status: false,
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(SolicitudTipoModalEdicionComponent, datos);
    dialogo.afterClosed().subscribe((result) => {
      this.requestType = result;
    });
  }

  public editar(elemento?: any): void {
    let datos: RequestTypeDTO = {
      requestTypeId: elemento.requestTypeId,
      requestCategoryId: elemento.requestCategoryId,
      code: elemento.code,
      name: elemento.name,
      description: elemento.description,
      email: elemento.email,
      status: elemento.status,
      isLetterCategory: elemento.isLetterCategory,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(SolicitudTipoModalEdicionComponent, datos);
  }

  public descargarExcel() {
    this.ExcelSubscription = this.solicitudService.downloadExcelTipo(this.arrOriginal).subscribe((res: Blob) => {
      let filename: string = "Request-Type.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        let a: HTMLAnchorElement = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        let blob: Blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  public borrar(btnDataTable: boolean, elemento?: any) {
    if(btnDataTable) {
      var datos = elemento;
    } else {
      if(elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogoEliminar = this.dialog.open(SolicitudTipoModalEliminarComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  isAllSelected() {
    this.numRequestTypeSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numRequestTypeSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row))
  }
}
