import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, ObservableLike } from 'rxjs';
import { environment, baseUrl} from '@environments/environment';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { RequestCategoryDTO, RequestTypeDTO, SolicitudAdminDto } from '../_models/solicitud-dto';
import { SimpleResponse } from '../models/api/SimpleResponse.model';

@Injectable({
  providedIn: 'root'
})
export class SolicitudService {
  invocarObtenerCategorias = new EventEmitter();
  invMetodoObtenerCategoriaSubscription: Subscription;

  invocarObtenerTipos = new EventEmitter();
  invMetodoObtenerTipoSubscription: Subscription;

  invocarObtenerSolicitudes = new EventEmitter();
  invMetodoObtenerSolicitudesSubscription: Subscription;

  invocarObtenerSolicitudesAdmin = new EventEmitter();
  invMetodoObtenerSolicitudesAdminSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  //#region SOLICITUD CATEGORIA
  public obtenerSolicitudCategoria(): Observable<ApiResponse<RequestCategoryDTO[]>> {
    return this.httpClient.get<ApiResponse<RequestCategoryDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Solicitud`);
  }

  public obtenerCategoriasActivas(): Observable<ApiResponse<RequestCategoryDTO[]>> {
    return this.httpClient.get<ApiResponse<RequestCategoryDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Solicitud/RequestCategoryActive`);
  }

  public agregarSolicitudCategoria(categoria: RequestCategoryDTO): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Solicitud`, categoria);
  }

  public modificarSolicitudCategoria(categoria: RequestCategoryDTO): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Solicitud`, categoria);
  }

  public eliminarSolicitudCategoria(requestCategoryId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Solicitud?requestCategoryId=` + requestCategoryId);
  }

  public downloadExcelCategoria(categorias: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Solicitud/DownloadExcelCategoria`, JSON.stringify(categorias),
    {
      responseType: "blob",
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }
  
  invocarMetodoObtenerCategoria() {
    this.invocarObtenerCategorias.emit();
  }

  //#endregion

  //#region SOLICITUD TIPO
  public obtenerSolicitudTipo(): Observable<ApiResponse<RequestTypeDTO[]>> {
    return this.httpClient.get<ApiResponse<RequestTypeDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Solicitud/ObtenerTipoSolicitud`);
  }

  public obtenerSolicitudPorCategoria(categoriaId: number): Observable<ApiResponse<RequestTypeDTO[]>> {
    return this.httpClient.get<ApiResponse<RequestTypeDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Solicitud/RequestTypeActive/${categoriaId}`);
  }

  public obtenerDescripcionSolicitud(typeId: number): Observable<ApiResponse<RequestTypeDTO>> {
    return this.httpClient.get<ApiResponse<RequestTypeDTO>>(`${environment.enves[baseUrl].apiUrl}/Solicitud/RequestTypeDescription/${typeId}`);
  }

  public agregarSolicitudTipo(tipos: RequestTypeDTO): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Solicitud/GuardarTipoSolicitud`, tipos);
  }

  public modificarSolicitudTipo(tipos: RequestTypeDTO): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Solicitud/ModificarTipoSolicitud`, tipos);
  }

  public eliminarSolicitudTipo(requestTypeId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Solicitud/BorrarTipoSolicitud?requestTypeId=` + requestTypeId);
  }

  public downloadExcelTipo(tipos: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Solicitud/DownloadExcelTipo`, JSON.stringify(tipos),
    {
      responseType: "blob",
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  invocarMetodoObtenerTipo() {
    this.invocarObtenerTipos.emit();
  }
  //#endregion

  //#region SOLICITUD ALUMNO
  public obtenerMisSolicitudes(usuarioId: number): Observable<ApiResponse<SolicitudAdminDto[]>> {
    return this.httpClient.get<ApiResponse<SolicitudAdminDto[]>>(`${environment.enves[baseUrl].apiUrl}/Solicitud/GetRequest/${usuarioId}`);
  }

  public agregarSolicitud(solicitud) : Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Solicitud/PostRequest`, solicitud);
  }

  public modificarSolicitud(solicitud) : Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Solicitud/PutRequest`, solicitud);
  }

  public downloadExcelRequest(solicitudes) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Solicitud/DownloadExcelRequest`, JSON.stringify(solicitudes),
    {
      responseType: "blob",
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  invocarMetodoObtenerSolicitudes() {
    this.invocarObtenerSolicitudes.emit();
  }
  //#endregion

  //#region SOLICITUD ADMIN
  public obtenerSolicitudes(): Observable<ApiResponse<SolicitudAdminDto[]>> {
    return this.httpClient.get<ApiResponse<SolicitudAdminDto[]>>(`${environment.enves[baseUrl].apiUrl}/Solicitud/SolicitudesDeAlumnos`);
  }

  invocarMetodoObtenerSolicitudesAdmin() {
    this.invocarObtenerSolicitudesAdmin.emit();
  }
  //#endregion

  //#region ARCHIVOS SOLICITUDES

  public obtenerDocumentosSolicitud(requestId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Solicitud/ObtenerDocumentosSolicitud/${requestId}`);
  }

  public obtenerArchivo(requestFileId): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Solicitud/DescargaArchivo/${requestFileId}`);
  }

  public documentosSolicitud(path): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Solicitud/DocumentosSolicitud/${path}`);
  };

  public registrarDocumentos(documentos): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Solicitud/PostDocumentos`, documentos);
  };

  public deleteDocumentoById(path: string): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Solicitud/DeleteDocumentoById/${path}`);
  }

  public descargarPdfDirecto(requestTypeId: number, usuarioId?: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.enves[baseUrl].apiUrl}/Solicitud/DescargarPDF/${requestTypeId}/${usuarioId}`);
  }

  public descargarPdfAsBlob(requestTypeId: number, usuarioId?: number) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Solicitud/DescargarPDFFile/${requestTypeId}/${usuarioId}`, null,
      {
        responseType: "blob",
      });
  }
  //#endregion
}
