import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Direccion } from '../../../../_models/direccion';
import { NuevoIngreso } from '../../../../_models/nuevo-ingreso';
import { Profesor } from '../../../../_models/profesor';
import { Usuario } from '../../../../_models/usuario';
import { ProfesorService } from '../../../../_services/profesor.service';
import { RegistrarUsuarioService } from '../../../../_services/registrar-usuario.service';
import { UsuarioService } from '../../../../_services/usuario.service';

@Component({
  selector: 'app-form-cuenta2',
  templateUrl: './form-cuenta.component.html',
  styleUrls: ['./form-cuenta.component.scss']
})
export class FormCuentaComponent implements OnInit{

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public profesorForm: FormGroup;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public errorActualizaProfesor: string = "";
  public correoValue: string = "";
  public objValidacionCancelar: Profesor = null;
  public dialogoRefAdver: any;
  public tipoAccion: number;
  public cuentaGenerada: boolean = false;
  public errorCrearCuenta: string = "";
  public nuevoIngreso: NuevoIngreso;
  public nombreRegistro: string = '';
  public datosRegistro: any;
  public idRegistro: number = 0;
  public boolCambio: boolean = false;
  public direccion: Direccion;
  //public datosUsuario: Usuario;
  public usuarioID: number = 0;


  constructor(public dialogRef: MatDialogRef<FormCuentaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Usuario,
    private profesorService: ProfesorService,
    private usuarioService: UsuarioService,
    private registrarUsuarioService: RegistrarUsuarioService
  ) { }


  //Validators.pattern(/^(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/) Numero, letra minuscula, letra mayuscula, minimo 8 - maximo 30
  //Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}') Letra minuscula, letra mayuscula, numero, caracter especial, minimo 8
  //Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,15}$/) Letra minuscula, letra mayuscula, numero?, caracter especial, conjunto de todos los caracteres, minimo 8 - maximo 15 letras


  ngOnInit(): void {

  }


  public cerrarModalValidacion() {
    this.dialogRef.close();
  }

  public recibirInfo() {
    this.inicializarForm();
  }

  public inicializarForm() {
    this.profesorForm = new FormGroup({
      correo: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      nombre: new FormControl('', [Validators.required]),
      apellidoPaterno: new FormControl('', [Validators.required]),
      apellidoMaterno: new FormControl(''),
      fechaNacimiento: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_]){8,15}$/)]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8)]),
      programaAcademico: new FormControl(1),
      ciclo: new FormControl(1)
    });

    this.profesorForm.valueChanges.subscribe(() => {
      this.profesorService.definirDatosUsuarioBusqueda(this.profesorForm.valid);
    });

  }


  get correo() { return this.profesorForm.get('correo'); }
  get nombre() { return this.profesorForm.get('nombre'); }
  get apellidoPaterno() { return this.profesorForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.profesorForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.profesorForm.get('fechaNacimiento'); }
  get password() { return this.profesorForm.get('password'); }
  get passwordConfirm() { return this.profesorForm.get('passwordConfirm'); }
  get programaAcademico() { return this.profesorForm.get('passwordConfirm'); }
  get ciclo() { return this.profesorForm.get('ciclo'); }


  public enviar(): void {
    this.errorCrearCuenta = "";

    this.registrarUsuarioService.registrarUsuarioProfesor(
      this.profesorForm.get("correo").value,
      this.profesorForm.get("nombre").value,
      this.profesorForm.get("segundoNombre").value,
      this.profesorForm.get("apellidoPaterno").value,
      this.profesorForm.get("apellidoMaterno").value,
      this.profesorForm.get("fechaNacimiento").value,
      this.profesorForm.get("password").value,
      this.profesorForm.get("programaAcademico").value,
      this.profesorForm.get("ciclo").value
    ).subscribe(guardado => {
      if (!!guardado) {
        this.cuentaGenerada = true;
        this.usuarioService.obtenerUsuarioPorCorreo(this.correo.value).subscribe(() => {
        });
      } else {
        this.errorCrearCuenta = "Falta información para poder crear la cuenta, favor de registrarla.";
      }
    });
  }

  public enviarDatosRegistro() {
    this.errorCrearCuenta = "";

    this.registrarUsuarioService.registrarUsuarioProfesor(
      this.profesorForm.get("correo").value,
      this.profesorForm.get("nombre").value,
      this.profesorForm.get("segundoNombre").value,
      this.profesorForm.get("apellidoPaterno").value,
      this.profesorForm.get("apellidoMaterno").value,
      this.profesorForm.get("fechaNacimiento").value,
      this.profesorForm.get("password").value,
      this.profesorForm.get("programaAcademico").value,
      this.profesorForm.get("ciclo").value
    ).subscribe(guardado => {
      if (!!guardado) {
        this.cuentaGenerada = true;

      } else {
        this.errorCrearCuenta = "Falta información para poder crear la cuenta, por favor de registrarla.";
      }
    })
  }

  public Siguiente() {

  }
}
