import { Validators } from "@angular/forms";

export const FILTERS_CONTROLS = {
  periodo                   :   [, {updateOn: 'change' }],
  alumno                    :   [, {updateOn: 'change' }],
  campus                    :   [, {updateOn: 'change' }],
  materia                   :   [, {updateOn: 'change' }],
  calificacionFinal         :   [, {updateOn: 'change' }],
  numeroFaltas              :   [, {updateOn: 'change' }],
  extraordinario            :   [, {updateOn: 'change' }],  
};
