import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { AdministracionOpcion } from './../../_models/AdministracionOpcion';
import { InstitucionService } from 'src/app/_services/institucion.service';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { NavigationService } from '../../_services/navigation.service';
import { MsalService } from '@azure/msal-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { MatStepper, MatVerticalStepper } from '@angular/material/stepper';
import { StepperService } from 'src/app/_services/stepper-services.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';
import { EstatusUsuarioEnum } from '../../_models/estatusUsuario.enum';
import { UserRolPermissionDto } from '@app/_models/UserRolPermissionDto';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class SidebarMenuComponent implements OnInit {
  public EstatusAcademicoCandidatoEgresoId: number = 10;
  public respuestaRol: any;
  public infoInstitucionSer$: Observable<any>;
  public infoInstitucionSer: any = null;
  public administracionOpcion: AdministracionOpcion = null;
  public readonly EDUCACION_CONTINUA: number = 2;
  public userPermision: UserRolPermissionDto;
  //#region  Constrcutor
  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private msalService: MsalService,
    private snackBar: MatSnackBar,
    private stepperService: StepperService,
    private _EnsenanzaAprendizajeService: EnsenanzaAprendizajeService,
    private admisionesService: AdmisionesService,
    private institucionService: InstitucionService,
    private snackService: SnackService) {


    this.infoInstitucionSer$ = this.admisionesService.getInstitucion$();
    this.infoInstitucionSer$.subscribe(
      (info: any) => {
        this.infoInstitucionSer = info;
        console.log("INFO ", this.infoInstitucionSer)
      });

    this.authenticationService.currentUser.subscribe(user => {
      this.isAdmin = false;
      this.IsProfesor = false;
      this.isAlumno = false;
      let cuenta = this.msalService.instance.getActiveAccount();
      this.isAuthenticated = !!cuenta;
      this.userName = "";
      if (this.isAuthenticated) {
        //this.isAdmin = user.IsAdmin;
        //this.IsProfesor = user.IsProfesor;
        //this.isAlumno = user.IsAlumno;
        this.institucionService.obtenerOpcionesBoton().subscribe((response: ApiResponse<AdministracionOpcion>) => {
          if (response.success) {
            this.administracionOpcion = response.data;            
          } else {
            this.snackService.mostrarSnackBack(response.message);
          }
        });
        this.userName = cuenta.name;
        this.identificador = cuenta.username.substring(0, cuenta.username.indexOf('@'));
        sessionStorage.setItem('identificador', this.identificador);
        
        // this.authenticationService.obtainPermission(this.identificador).subscribe((response: ApiResponse<UserRolPermissionDto>) => {
        //   this.userPermision = response.data;
        //   let permisos = JSON.stringify(this.userPermision);
        //   sessionStorage.setItem('userPermission', permisos);
        //   console.log("this.userPermision", this.userPermision)
        // });
        this.authenticationService.obtenerDatosRol(this.identificador).subscribe(data => {
          this.usuarioRol = data;
          this.infoCargada = true;
        });
      }
    });
    this.stepperService.getSubjetPaso().subscribe((avanzar: boolean) => {
      if (avanzar) {
        this.stepper.next();
      }
      else {
        this.stepper.previous();
      }
    });
  }
  //#endregion

  //#region Propiedades
  message: boolean;
  isExpanded = false;
  public isAdmin: boolean = false;
  public IsProfesor: boolean = false;
  public isAlumno: boolean = false;
  public userName: string = "";
  public isAuthenticated: boolean = false;
  public identificador: string;
  public usuarioRol: any;
  public infoCargada: boolean = false;
  @ViewChild('stepper') stepper: MatVerticalStepper;
  //#endregion

  ngOnInit(): void {
  }

  newMessage() {
    this._EnsenanzaAprendizajeService.invocarMetodoTableroCursos();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public logout() {
    this.navigationService.isToggledMenu.next(false);
    this.authenticationService.logout();
    this.router.navigate(['/logout']);
  }

  public get isCurentRouteReinscription(): boolean {
    return this.router.url == '/reinscripcion';
  }

  public redirigirDashboard() {
    this.admisionesService.validarPagoColegiatura().subscribe((respuesta: any) => {
      this.respuestaRol = respuesta;
      if (!respuesta.noExisteAlumno && (respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.candidato || respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.prospecto || respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.admitido)) {
        if (this.infoInstitucionSer.academicManagementModelId == 1) {
          this.router.navigate(['/']);
          setTimeout(() => {
            this.router.navigate(['/admisiones']);
          }, 100);
        }
      } else if (!respuesta.noExisteAlumno) {
        this.router.navigate(['/dashboard-alumno']);
      } else {
        this.router.navigate(['/']);
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 100);
      }
    });
  }
}
