import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EChartsOption } from 'echarts';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SnackService } from '../services/snack-service.service';
import { Campaign } from '../_models';
import { CampaignService, FunnelStageService, LeadService } from '../_services';
import { UtileriasService } from '../_services/utilerias.service';

//#region Inerfaces
interface datafunnel {
  name: string;
  value: number;
  leads: number [];
}

interface dataPie1i {
  name: string;
  leadId: string;
  value: number;
  leads: string [];
  itemStyle: {
    color: string
  };
}

interface dataPie2i {
  name: string;
  interactionSegment: string;
  value: number;
  leadId: string;
  leads: string [];
}

interface dataBarChart {
  name: string;
  value: number;
  leadId: string;
  leads: string [];
}

interface Calification {
  value: number;
}
//#endregion


@Component({
  selector: 'app-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.scss'],
})
export class FunnelComponent implements OnInit {

  //#region Table


  public arregloFiltroOriginal: any[] = [];
  public datafilter: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [   
    'nombre',
    'campaña',
    'etapa',
    'ultimocontacto',
    'calificacion',
    'entrada',
    'programa',
    'telefono',
  ];
  public dataSource: MatTableDataSource<any>;
  public calificationList: Calification[] = [
    { value: 0 },
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
  ];



  public async getLeadsTable(): Promise<void> {
    let leads = await this.leadService.getLeads().toPromise();
    if (leads.success) {
     
      for (let i = 0; i < leads.data.length; i++) {
        if (leads.data[i].interactionCreationDate == '0001-01-01T00:00:00') {
          leads.data[i].interactionCreationDate = '';
        } else {
          leads.data[i].interactionCreationDate = this.util.formatearFecha(leads.data[i].interactionCreationDate);
        }
      }

      //this.dataSource = new MatTableDataSource(leads.data);
      this.arregloFiltroOriginal = [...leads.data];
      //this.dataSource.paginator = this.paginator;
      this.datafilter = leads.data;
      
    } else {
      this.dataSource = new MatTableDataSource([]);
      this.arregloFiltroOriginal = [];
    }
  }






  public filtroTabla(leadId : any[]) {    
  
    //let leadId: number = 11;    
    let filtroModificado: any = [];
    filtroModificado = [...this.arregloFiltroOriginal];

    filtroModificado = filtroModificado.filter((f) => {
      return leadId.indexOf(f.leadId ? f.leadId : -1) > -1;
    });
    //filtroModificado = filtroModificado.filter(row => row.leadId == leadId);  
    //Entrada
  
    //filtros desactivados
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;  
  }


























  //#endregion

  //#region Propiedades
  chartOptions: EChartsOption;
  option: EChartsOption;
  option2: EChartsOption;
  option3: EChartsOption;
  funnelopc: EChartsOption;
  leadData: any;

  datafunnel: datafunnel[] = [];
  dataBarChart: dataBarChart[] = [];
  totalLeads: number = 0;
  public dataPie1: dataPie1i[] = [];
  public dataPie2: dataPie2i[] = [];

  public campaignList: Campaign[] = [];
  public funnelStageList: any;
  public funnelStagefilt: any;
  campaignF = new FormControl(0);

  displayedNamesPie1: string[] = ['Sin interaccion', '1 semana', '2 semanas', '3 semanas', 'más de 3 semanas'];

  private colorPalette = ['#91CC75', '#FAC858', '#FC8452', '#FF0000'];

  public chartOption: number = 0;
  //#endregion

  //#region Constructor Oninit
  constructor(
    private readonly campaignService: CampaignService,
    private readonly util: UtileriasService,
    private readonly leadService: LeadService,
    private readonly funnelService: FunnelStageService,
    private snackService: SnackService
  ) {}

 
  ngOnInit(): void {
    this.getCampaigns();
    //this.getLeads();
    this.getFunnels();
    this.getLeadsTable();
    //this.getLeadsbyInteractions('');
    
  }
  //#endregion
  
  //#region Chart Functions
 loadChart(): void {
  this.totalLeads = 0;
  this.datafunnel = [];    
  this.dataPie1 = [];
  this.dataPie2 = [];   
  this.dataBarChart = []; 
  this.setDataFormat();
  this.setOptions();
}

setDataFormat(): void {
 
  this.funnelStagefilt.sort((a, b) => (a.order || 0) - (b.order || 0));
  for (let i = 0; i < this.funnelStagefilt.length; i++) {
    let data: datafunnel = {
      name: '', value: 0,
      leads: []
    };
    data.name = this.funnelStagefilt[i].description || "N/A";
    data.value = this.funnelStagefilt[i].leads.length;
    for (let j = 0; j < this.funnelStagefilt[i].leads.length; j++) {
      data.leads.push(this.funnelStagefilt[i].leads[j].leadId);
    }
    this.totalLeads = this.totalLeads + this.funnelStagefilt[i].leads.length;
    this.datafunnel.push(data);
  }
  for (let i = 0; i < this.funnelStagefilt.length; i++) {
    let data: dataBarChart = {
      name: '', value: 0,
      leads: [],
      leadId: '',
    };
    data.name = this.funnelStagefilt[i].description;
    data.value = this.funnelStagefilt[i].average;
    for (let j = 0; j < this.funnelStagefilt[i].leads.length; j++) {
      data.leads.push(this.funnelStagefilt[i].leads[j].leadId);
    }
    this.dataBarChart.push(data);
  }  
  //#region Fechas Pie 1 
  for (let i = 0; i < this.funnelStagefilt.length; i++) {
    for (let j = 0; j < this.funnelStagefilt[i].leads.length; j++) {
    
      if (this.funnelStagefilt[i].leads[j].weeks != 0) {          
        let data: dataPie1i = {
          name: '', value: 0, leadId: '', itemStyle: { color: '' },
          leads: []
        };
        data.name = this.funnelStagefilt[i].leads[j].segment;          
        data.value = this.funnelStagefilt[i].leads[j].weeks;
        data.leadId = this.funnelStagefilt[i].leads[j].leadId;
        data.itemStyle.color = this.funnelStagefilt[i].leads[j].itemStyle; 
        data.leads = this.funnelStagefilt[i].leads[j].leadId.toString();
        //this.totalLeads = this.totalLeads + this.funnelStagefilt[i].leads.length;
        this.dataPie1.push(data);    
      }
         
    }
  }     
  this.dataPie1 = this.dataPie1.reduce((acumulador, valorActual) => {
    const elementoYaExiste = acumulador.find(elemento => elemento.name === valorActual.name);
    if (elementoYaExiste) {
      return acumulador.map((elemento) => {
        if (elemento.name === valorActual.name) {
          return {
            ...elemento,
            value: elemento.value + valorActual.value,
            leads: elemento.leads+ "," + valorActual.leads
          }
        }
  
        return elemento;
      });
    }    
    return [...acumulador, valorActual];
  }, []);
  //#endregion
 

  //#region  Interacciones Pie 2
  for (let i = 0; i < this.funnelStagefilt.length; i++) {
    for (let j = 0; j < this.funnelStagefilt[i].leads.length; j++) {
                  
        let data: dataPie2i = {
          name: '', value: 0,
          interactionSegment: '',
          leadId: '',
          leads: []
        };
        data.name = this.funnelStagefilt[i].leads[j].interactionSegment;
        data.value = this.funnelStagefilt[i].leads[j].interactions;  
        data.leadId = this.funnelStagefilt[i].leads[j].leadId;
        data.leads = this.funnelStagefilt[i].leads[j].leadId.toString();        
        //this.totalLeads = this.totalLeads + this.funnelStagefilt[i].leads.length;
        this.dataPie2.push(data);    
                
    }
  }   
  
  this.dataPie2 = this.dataPie2.reduce((acumulador, valorActual) => {
    const elementoYaExiste = acumulador.find(elemento => elemento.name === valorActual.name);
    if (elementoYaExiste) {
      return acumulador.map((elemento) => {
        if (elemento.name === valorActual.name) {          
          return {
            ...elemento,
            value: elemento.value + valorActual.value,
            leads: elemento.leads+ "," + valorActual.leads
          }
        }
  
        return elemento;
      });
    }    
    return [...acumulador, valorActual];
  }, []);

  //#endregion
}

 //#endregion  

  //#region Mostrar tabla 
onChartClickFunel(e, option): void {
  
  this.chartOption = option;
  this.filtroTabla(e.data.leads);
}
onChartClick(e, option): void {
  this.chartOption = option;
  let id= e.data.leads.split(',').map(x=>+x)
  this.filtroTabla(id);
}
volver(volver){
  this.chartOption = volver;
}
//#endregion

  //#region Chart Configurations
 setOptions(): void {
  this.option = {     
    title: {
      text: 'Leads',
      subtext: 'by last contact date in weeks',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '30',
      orient: 'vertical',
      left: 'left',
      data: this.dataPie1.map((x) => x.name),
    },
    series: [
      {
        //name: 'Leads por Interacciones',
        type: 'pie',
        radius: '50%',
        data: this.dataPie1,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        startAngle: 45,
      },
    ],
    //color: this.colorPalette,
  };

  this.option2 = {
    title: {
      text: 'Leads',
      subtext: 'by number of contacts',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '30',
      orient: 'vertical',
      left: 'left',
      data: this.dataPie2.map((x) => x.name),
    },
    series: [
      {
        //name: 'Leads por Interacciones',
        type: 'pie',
        radius: '50%',
        data: this.dataPie2,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        startAngle: 45,
      },
    ],
    //color: this.colorPalette,
  };

  this.option3 = {
    title: {
      text: 'Leads',
      subtext: 'by the average time it takes in days for the lead to move from stages.',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
   
    xAxis: {
        type: 'category',
        data: this.dataBarChart.map((x) => x.name),//['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
        type: 'value'
    },
    series: [{
        data: this.dataBarChart,//[120, 200, 150, 80, 70, 110, 130],
        type: 'bar',
        stack: 'stack',
    }],
     //color: this.colorPalette,
};

  this.funnelopc = {
    title: {
      text: 'Funnel',
      //textAlign: 'center',
      padding: [10, 0],
      left: 'center',
      // subtext: 'subFunnel',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {d}% <br/> Leads : {c}',
    },
    // toolbox: {
    //   feature: {
    //     dataView: { readOnly: false },
    //     restore: {},
    //     saveAsImage: {},
    //   },
    // },
    legend: {
      top: '30',
      data: this.funnelStagefilt.map((x) => x.description || "N/A"),
      orient: 'vertical',
      left: 'left',
    },

    series: [
      {
        name: 'Stage',
        type: 'funnel',
        //left: '10%',
        top: 80,
        //x2: 80,
        bottom: 60,
        width: '60%',
        // height: {totalHeight} - y - y2,
        min: 0,
        max: this.totalLeads,
        minSize: '0%',
        maxSize: '100%',
        sort: 'none',
        gap: 2,
        label: {
          show: true,
          position: 'inside',
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: 'solid',
          },
        },
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1,
        },
        emphasis: {
          label: {
            fontSize: 20,
          },
        },
        data: this.datafunnel,
      },
    ],
  };

}

 //#endregion
  
//#region Test


//#endregion

  //#region Filtros
  filtro(): void {
    let campaign: number[] = this.campaignF.value == null ? null : [this.campaignF.value];

    let filtroModificado: any = [];
    filtroModificado = [...this.funnelStageList];

    if (campaign[0] != 0) {
     
      let ids: any;
      var resp = filtroModificado.map((element) => {

        return {...element, leads: element.leads.map((leads) => {return {...leads, campaigns:leads.campaigns.filter((campaigns) => campaigns.campaignId === campaign[0]) }})}

      })

      var resp2 = resp.map((element) => {

        return {...element, leads: element.leads.filter((leads) => leads.campaigns.length > 0)}

      })
     

      filtroModificado = resp2;
      //Campaña
  /*     filtroModificado = filtroModificado.filter((f) => {
        let r: Array<any> = [];
        ids =
          f.leads.length > 0
            ? (ids = f.leads.map((x) => {
                x.campaigns.length > 0
                  ? x.campaigns.map((y) => {
                      r.push(y.campaignId);
                      return r;
                    })
                  : 0;
                return 0;
              }))
            : -1;

        let result: boolean = false;
        if (ids == -1) {
          return (result = campaign.indexOf(ids) > -1);
        } else {
          r.forEach((e) => {
            if (!result) {
              result = campaign.indexOf(e ? e : 0) > -1;
            }
          });
        }
        return result;

        //return campaign.indexOf(f.campaignId ? f.campaignId : 0) > -1;
      }); */
      //Leads
  /*     for (let i = 0; i < filtroModificado.length; i++) {
        filtroModificado[i].leads = filtroModificado[i].leads.filter((f) => {
          ids =
            f.campaigns.length > 0
              ? (ids = f.campaigns.map((x) => {
                  return x.campaignId;
                }))
              : -1;

          let result: boolean = false;
          if (ids == -1) {
            return (result = campaign.indexOf(ids) > -1);
          } else {
            ids.forEach((e) => {
              if (!result) {
                result = campaign.indexOf(e ? e : 0) > -1;
              }
            });
          }
          return result;
        });
      } */
      this.funnelStagefilt = filtroModificado;
      //this.getLeadsbyInteractions(campaign[0].toString());
      this.loadChart();
    } else {
      this.getFunnels();
      //this.getLeadsbyInteractions('');
    }
  }
  //#endregion

  //#region Get's
  public getCampaigns(): void {
    this.campaignService.getCampaigns().subscribe((campaigns: ApiResponse<Array<Campaign>>) => {
      this.campaignList = campaigns.data;
    });
  }

  public async getLeads(): Promise<void> {
    let leads = await this.leadService.getLeads().toPromise();
    if (leads.success) {
      for (let i = 0; i < leads.data.length; i++) {
        if (leads.data[i].interactionCreationDate == '0001-01-01T00:00:00') {
          leads.data[i].interactionCreationDate = '';
        } else {
          leads.data[i].interactionCreationDate = this.util.formatearFecha(leads.data[i].interactionCreationDate);
        }
      }
      this.leadData = leads.data;
      //this.dataSource = new MatTableDataSource(leads.data);
      // this.arregloFiltroOriginal = [...leads.data];
      // this.dataSource.paginator = this.paginator;
      // this.datafilter = leads.data;
      
    } else {
      this.snackService.mostrarSnackBack('Ocurrio un error.');
    }
  }

  public getFunnels(): void {
    this.funnelService.getFunnelStage().subscribe((funnels: ApiResponse<Array<any>>) => {
      this.funnelStageList = [...funnels.data];
      this.funnelStagefilt = funnels.data;
      
      this.loadChart();
    });
  }

  public async getLeadsbyInteractions(campaignId: string): Promise<void> {
    const leads = await this.funnelService.getLeadsbyInteractions(campaignId).toPromise();
    if (leads.data) {
      this.leadData = leads.data;
      
      this.loadChart();
    }
  }

  //#endregion
}
