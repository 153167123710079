export class User {

    usuarioId:                                  number;
    institucionId:                              string;
    correo:                                     string;
    identificador:                              string;
    estatusCuentaId:                            number;
    nombre:                                     string;
    segundoNombre:                              string;
    apellidoPaterno:                            string;
    apellidoMaterno:                            string;
    fotografia:                                 string;
    prefijo:                                    string;

    biografia:                                  Biografia;
    datoFiscal:                                 DatoFiscal;
    ocupacion:                                  Ocupacion;
    direccion:                                  Direccion[];
    estudioPrevio:                              EstudioPrevio[];
    personaRelacionadaUsuario:                  PersonaRelacionadaUsuario[];
    telefono:                                   Telefono[];
    constructor(
        usuarioId:                                  number,
        institucionId:                              string,
        correo:                                     string,
        identificador:                              string,
        estatusCuentaId:                            number,
        nombre:                                     string,
        segundoNombre:                              string,
        apellidoPaterno:                            string,
        apellidoMaterno:                            string,
        fotografia:                                 string,
        prefijo:                                    string,
        biografia:                                  Biografia,
        datoFiscal:                                 DatoFiscal,
        ocupacion:                                  Ocupacion,
        direccion:                                  Direccion[],
        estudioPrevio:                              EstudioPrevio[],
        personaRelacionadaUsuario:                  PersonaRelacionadaUsuario[],
        telefono:                                   Telefono[]
    ){

        this.usuarioId                  = usuarioId;
        this.institucionId              = institucionId;
        this.correo                     = correo;
        this.identificador              = identificador;
        this.estatusCuentaId            = estatusCuentaId;
        this.nombre                     = nombre;
        this.segundoNombre              = segundoNombre;
        this.apellidoPaterno            = apellidoPaterno;
        this.apellidoMaterno            = apellidoMaterno;
        this.fotografia                 = fotografia;
        this.prefijo                    = prefijo;
        this.biografia                  = biografia;
        this.datoFiscal                 = datoFiscal;
        this.ocupacion                  = ocupacion;
        this.direccion                  = direccion;
        this.estudioPrevio              = estudioPrevio;
        this.personaRelacionadaUsuario  = personaRelacionadaUsuario;
        this.telefono                   = telefono;
    }

    static mapBackEnd(data: User): User {
   
        return new User(
            data.usuarioId,
            data.institucionId,
            data.correo,
            data.identificador,
            data.estatusCuentaId,
            data.nombre,
            data.segundoNombre,
            data.apellidoPaterno,
            data.apellidoMaterno,
            data.fotografia,
            data.prefijo,
            data.biografia,
            data.datoFiscal,
            data.ocupacion,
            data.direccion,
            data.estudioPrevio,
            data.personaRelacionadaUsuario,
            data.telefono
        );
    }

}

export class Biografia {
    usuarioId:                   number;
    generoId:                    number;
    legalGeneroId:               number;
    fechaNacimiento:             Date;
    entidadNacimiento:           number;
    ciudadNacimiento:            string;
    esFinado:                    boolean;
    ciudadaniaPaisId:            number;
    estadoCivilId:               number;
    curp:                        string;
    nss:                         string;
    grupoSanguineoId:            number;
    alergias:                    string;
    enfermedades:                string;    
    entidadNacimientoNavigation: Entidad;
    constructor(
        usuarioId:                   number,
        generoId:                    number,
        legalGeneroId:               number,
        fechaNacimiento:             Date,
        entidadNacimiento:           number,
        ciudadNacimiento:            string,
        esFinado:                    boolean,
        ciudadaniaPaisId:            number,
        estadoCivilId:               number,
        curp:                        string,
        nss:                         string,
        grupoSanguineoId:            number,
        alergias:                    string,
        enfermedades:                string,        
        entidadNacimientoNavigation: Entidad,
    ){
        this.usuarioId                      = usuarioId,
        this.generoId                       = generoId,
        this.legalGeneroId                  = legalGeneroId,
        this.fechaNacimiento                = fechaNacimiento,
        this.entidadNacimiento              = entidadNacimiento,
        this.ciudadNacimiento               = ciudadNacimiento,
        this.esFinado                       = esFinado,
        this.ciudadaniaPaisId               = ciudadaniaPaisId,
        this.estadoCivilId                  = estadoCivilId,
        this.curp                           = curp,
        this.nss                            = nss,
        this.grupoSanguineoId               = grupoSanguineoId,
        this.alergias                       = alergias,
        this.enfermedades                   = enfermedades,       
        this.entidadNacimientoNavigation    = entidadNacimientoNavigation
    }

    static mapBackEnd(data: Biografia): Biografia {
   
        return new Biografia(
            data.usuarioId,
            data.generoId,
            data.legalGeneroId,
            data.fechaNacimiento,
            data.entidadNacimiento,
            data.ciudadNacimiento,
            data.esFinado,
            data.ciudadaniaPaisId,
            data.estadoCivilId,
            data.curp,
            data.nss,
            data.grupoSanguineoId,
            data.alergias,
            data.enfermedades,           
            data.entidadNacimientoNavigation
        );
    }
}

export class Entidad {
    entidadFederativaId:                number;
    paisId:                             number;
    clave:                              null | string;
    nombre:                             null | string;
    constructor(
        entidadFederativaId:                number,
        paisId:                             number,
        clave:                              null | string,
        nombre:                             null | string,
    ){
        this.entidadFederativaId    =   entidadFederativaId,
        this.paisId                 =   paisId,
        this.clave                  =   clave,
        this.nombre                 =   nombre
    }

    static mapBackEnd(data: Entidad): Entidad {
   
        return new Entidad(
            data.entidadFederativaId,
            data.paisId             ,
            data.clave              ,
            data.nombre             
        );
    }
}

export class DatoFiscal {
    usuarioId:           number;
    tipoFacturacionId:   number;
    rfc:                 string;
    nombre:              string;
    apellidoPaterno:     string;
    apellidoMaterno:     string;
    razonSocial:         string;
    esIgualPersonal:     boolean;
    entidadFederativaId: number;
    ciudad:              string;
    calle:               string;
    numeroExterior:      string;
    numeroInterior:      string;
    colonia:             string;
    codigoPostal:        string;
    entidadFederativa:   Entidad;

    constructor(
        usuarioId:           number,
        tipoFacturacionId:   number,
        rfc:                 string,
        nombre:              string,
        apellidoPaterno:     string,
        apellidoMaterno:     string,
        razonSocial:         string,
        esIgualPersonal:     boolean,
        entidadFederativaId: number,
        ciudad:              string,
        calle:               string,
        numeroExterior:      string,
        numeroInterior:      string,
        colonia:             string,
        codigoPostal:        string,
        entidadFederativa:   Entidad
    ){
        this.usuarioId              =   usuarioId;
        this.tipoFacturacionId      =   tipoFacturacionId;
        this.rfc                    =   rfc;
        this.nombre                 =   nombre;
        this.apellidoPaterno        =   apellidoPaterno;
        this.apellidoMaterno        =   apellidoMaterno;
        this.razonSocial            =   razonSocial;
        this.esIgualPersonal        =   esIgualPersonal;
        this.entidadFederativaId    =   entidadFederativaId;
        this.ciudad                 =   ciudad;
        this.calle                  =   calle;
        this.numeroExterior         =   numeroExterior;
        this.numeroInterior         =   numeroInterior;
        this.colonia                =   colonia;
        this.codigoPostal           =   codigoPostal;
        this.entidadFederativa      =   entidadFederativa;
    }

    static mapBackEnd(data: DatoFiscal): DatoFiscal {
   
        return new DatoFiscal(
            data.usuarioId,
            data.tipoFacturacionId,
            data.rfc,
            data.nombre,
            data.apellidoPaterno,
            data.apellidoMaterno,
            data.razonSocial,
            data.esIgualPersonal,
            data.entidadFederativaId,
            data.ciudad,
            data.calle,
            data.numeroExterior,
            data.numeroInterior,
            data.colonia,
            data.codigoPostal,
            data.entidadFederativa  
        );
    }
}

export class Direccion {
    direccionId:         number;
    usuarioId:           number;
    tipoDireccionId:     number;
    entidadFederativaId: number;
    ciudad:              string;
    calle:               string;
    numeroExterior:      string;
    numeroInterior:      string;
    colonia:             string;
    codigoPostal:        string;
    latitute:            number;
    longitude:           number;
    entidadFederativa:   Entidad;

    constructor(
        direccionId:         number,
        usuarioId:           number,
        tipoDireccionId:     number,
        entidadFederativaId: number,
        ciudad:              string,
        calle:               string,
        numeroExterior:      string,
        numeroInterior:      string,
        colonia:             string,
        codigoPostal:        string,
        latitute:            number,
        longitude:           number,
        entidadFederativa:   Entidad
    ){
        this.direccionId            =   direccionId;
        this.usuarioId              =   usuarioId;
        this.tipoDireccionId        =   tipoDireccionId;
        this.entidadFederativaId    =   entidadFederativaId;
        this.ciudad                 =   ciudad;
        this.calle                  =   calle;
        this.numeroExterior         =   numeroExterior;
        this.numeroInterior         =   numeroInterior;
        this.colonia                =   colonia;
        this.codigoPostal           =   codigoPostal;
        this.latitute               =   latitute;
        this.longitude              =   longitude;
        this.entidadFederativa      =   entidadFederativa;
    }

    static mapBackEnd(data: Direccion): Direccion {
   
        return new Direccion(
            data.direccionId,
            data.usuarioId,
            data.tipoDireccionId,
            data.entidadFederativaId,
            data.ciudad,
            data.calle,
            data.numeroExterior,
            data.numeroInterior,
            data.colonia,
            data.codigoPostal,
            data.latitute,
            data.longitude,
            data.entidadFederativa
        );
    }
}

export class EstudioPrevio {
    estudioPrevioId:     number;
    usuarioId:           number;
    nivelId:             number;
    escuela:             string;
    programa:            string;
    promedio:            number;
    fechaIngreso:        Date;
    fechaEgreso:         Date;

    constructor(
        estudioPrevioId:     number,
        usuarioId:           number,
        nivelId:             number,
        escuela:             string,
        programa:            string,
        promedio:            number,
        fechaIngreso:        Date,
        fechaEgreso:         Date
    ){
        this.estudioPrevioId    =   estudioPrevioId;
        this.usuarioId          =   usuarioId;
        this.nivelId            =   nivelId;
        this.escuela            =   escuela;
        this.programa           =   programa;
        this.promedio           =   promedio;
        this.fechaIngreso       =   fechaIngreso;
        this.fechaEgreso        =   fechaEgreso;
    }

    static mapBackEnd(data: EstudioPrevio): EstudioPrevio {
   
        return new EstudioPrevio(
            data.estudioPrevioId,
            data.usuarioId,
            data.nivelId,
            data.escuela,
            data.programa,
            data.promedio,
            data.fechaIngreso,
            data.fechaEgreso
        );
    }
}

export class Ocupacion {
    usuarioId:           number;
    tipoEmpresaId:       number;
    tipoPuestoEmpresaId: number;

    constructor(
        usuarioId:           number,
        tipoEmpresaId:       number,
        tipoPuestoEmpresaId: number
    ){
        this.usuarioId              =   usuarioId;
        this.tipoEmpresaId          =   tipoEmpresaId;
        this.tipoPuestoEmpresaId    =   tipoPuestoEmpresaId;
    }
    
    static mapBackEnd(data: Ocupacion): Ocupacion {
   
        return new Ocupacion(
            data.usuarioId,
            data.tipoEmpresaId,
            data.tipoPuestoEmpresaId
        );
    }
}

export class PersonaRelacionadaUsuario {
    personaRelacionadaId: number;
    usuarioId:            number;
    tipoRelacionId:       number;
    nombreCompleto:       string;
    telefono:             string;
    correoElectronico:    string;
    personaUsuarioId:     number;

    constructor(
        personaRelacionadaId: number,
        usuarioId:            number,
        tipoRelacionId:       number,
        nombreCompleto:       string,
        telefono:             string,
        correoElectronico:    string,
        personaUsuarioId:     number
    ){
        this.personaRelacionadaId   =   personaRelacionadaId;
        this.usuarioId              =   usuarioId;
        this.tipoRelacionId         =   tipoRelacionId;
        this.nombreCompleto         =   nombreCompleto;
        this.telefono               =   telefono;
        this.correoElectronico      =   correoElectronico;
        this.personaUsuarioId       =   personaUsuarioId;
    }

    static mapBackEnd(data: PersonaRelacionadaUsuario): PersonaRelacionadaUsuario {
   
        return new PersonaRelacionadaUsuario(
            data.personaRelacionadaId,
            data.usuarioId,
            data.tipoRelacionId,
            data.nombreCompleto,
            data.telefono,
            data.correoElectronico,
            data.personaUsuarioId
        );
    }
}

export class Telefono {
    telefonoId:          number;
    usuarioId:           number;
    tipoTelefonoId:      number;
    paisId:              number;
    numero:              string;
    extension:           string;
    
    constructor(
        telefonoId:          number,
        usuarioId:           number,
        tipoTelefonoId:      number,
        paisId:              number,
        numero:              string,
        extension:           string
    ){
        this.telefonoId     =   telefonoId;
        this.usuarioId      =   usuarioId;
        this.tipoTelefonoId =   tipoTelefonoId;
        this.paisId         =   paisId;
        this.numero         =   numero;
        this.extension      =   extension;
    }

    static mapBackEnd(data: Telefono): Telefono {
   
        return new Telefono(
            data.telefonoId,
            data.usuarioId,
            data.tipoTelefonoId,
            data.paisId,
            data.numero,
            data.extension
        );
    }
}
