import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Entry, Usuario } from 'src/app/_models';
import { LeadEntryService } from 'src/app/_services';
import { UsuarioService } from 'src/app/_services/usuario.service';

@Component({
  selector: 'app-general-inforCamp-tab',
  templateUrl: './general-information-tab.component.html',
  styleUrls: ['./general-information-tab.component.scss'],
})
export class GeneralInfoCampaignTabComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private leadEntryService: LeadEntryService,
    private usuarioService: UsuarioService
  ) {}

  public generalForm: FormGroup;
  public entryList: Entry[] = [];
  public userList: Usuario[] = [];
  responsibleF = new FormControl();
  entryF = new FormControl('', [Validators.required]);

  @ViewChild('allEntry') private allEntry: MatOption;

  ngOnInit(): void {
    console.log('gral info campaign ini');
    this.getEntrys();
    this.obtenerUsuarios();
    this.inicializarForm();
  }

  public inicializarForm() {
    this.generalForm = this.fb.group({
      name: [this.data ? (this.data.name ? this.data.name : '') : '', [Validators.required]],
      description: [this.data ? this.data.description : '', [Validators.required]],
      startDate: this.data ? this.data.startDate : '',
      endDate: this.data ? this.data.endDate : '',
      campaignResponsibleId: [this.data ? this.data.responsible : -1, [Validators.required]],
      campaignLeadEntryIds: this.entryF,
    });

    //this.entryF.setValue(['-1']);
    this.responsibleF.setValue(['-1']);
  }

  public async getEntrys(): Promise<void> {
    if (this.data && this.data.entry.sourceId) {
      this.entryList = this.data.entry.sourceId;
    } else if (this.entryList.length == 0) {
      let entry = await this.leadEntryService.getEntrys().toPromise();
      this.entryList = entry.data;
    }
    //this.entryF.patchValue([...this.entryList.map((item) => item.sourceId)]);
  }

  public async obtenerUsuarios(): Promise<void> {
    if (this.data && this.data.CampaignStudentType.StudentTypeId) {
      this.userList = this.data.CampaignStudentType.StudentTypeId;
    } else if (this.userList.length == 0) {
      let usuarios = await this.usuarioService.obtenerUsuariosAdmins().toPromise();
      if (usuarios.data) {
        console.log('Ver Data', usuarios);
        this.userList = usuarios.data;
      }
    }
  }

  toggleAllSelectionEntry() {
    if (this.allEntry.selected) {
      this.entryF.patchValue([...this.entryList.map((item) => item.sourceId), 0]);
    } else {
      this.entryF.patchValue([]);
    }
  }

  tosslePerOneEntry() {
    if (this.allEntry.selected) {
      this.allEntry.deselect();
      return false;
    }
    if (this.entryF.value.length == this.entryList.length) {
      this.allEntry.select();
    }
  }
}
