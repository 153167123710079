import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { LeadImportDto } from 'src/app/_models/lead';
import { ExcelReaderService } from 'src/app/_services/excel-reader.service';
import { LeadImportService } from 'src/app/_services/lead-import.service';

@Component({
  selector: 'app-leads-import-modal-file',
  templateUrl: './leads-import-modal-file.component.html',
  styleUrls: ['./leads-import-modal-file.component.scss']
})
export class LeadsImportModalFileComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public file: File;
  public infoCargado: boolean = false;
  public archivoCargado: boolean = false;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['name', 'medio', 'apellidoP', 'apellidoM', 'email', 'phone'];
  public datosCargados: any;
  public columnasValorFijo: any[] = [];
  public datosConValorFijo: LeadImportDto[] = [];

  constructor(public excelService: ExcelReaderService,
    private leadImportService: LeadImportService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    private snackService: SnackService) { }

  ngOnInit(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
  
    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  ngAfterViewInit() {
    this.obtenerColumnasConValorFijo();
  }

  public obtenerColumnasConValorFijo() {
    this.leadImportService.obtenerColumnasValorFijo().subscribe((respuesta) => {
      this.columnasValorFijo = respuesta;
    });
  }

  /**
   * Funcion que lee el archivo de Excel para despues pasarlo al servicio, y obtener el arreglo de datos
   * @param event 
   */
  async importExcel(event) {
    this.file = (event.target as HTMLInputElement).files[0];
    const result = await this.excelService.xlsxToJSON(this.file);
    this.datosCargados = result;
    
    for(let i = 0; i < this.columnasValorFijo.length; i++){
      var nuevoArr = this.datosCargados.map((object1: LeadImportDto) => ({
        ...object1,
          ...this.columnasValorFijo[i]
      })
      )
    }

    this.datosConValorFijo = nuevoArr;

    this.dataSource = new MatTableDataSource(this.datosConValorFijo);
    this.dataSource.paginator = this.paginator;
    this.infoCargado = true;
  }

  /**
   * Funcion que pasa al siguiente componente mandando la respuesta que obtuvo al cargar los datos
   * a fin de conocer el estatus de la importacion
   */
  public siguiente() {
    this.leadImportService.agregarImports(this.datosConValorFijo).subscribe((response: SimpleResponse) => {
      if(response.success) {
        this.snackService.mostrarSnackBack('Se cargaron los datos correctamente.');
        this.leadImportService.definirDatosCargadosCorrectos(response.success);
        this.archivoCargado = true;
      } else {
        this.snackService.mostrarSnackBack(response.message);
        this.archivoCargado = false;
      }
    });
  }
}
