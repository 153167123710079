import { I18NextPipe } from 'angular-i18next';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { BlockGroupService } from './../../../_services/block-group.service';
import { AsignacionMasivaBloqueDto } from './../../../_models/AsignacionMasivaBloqueDto';
import { SnackService } from 'src/app/services/snack-service.service';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { BlockLabelDto } from '../../../_models/BlockLabelDto';
import { GrupoDTO } from '../../../_models/GrupoDTO.model';
import { Block } from 'src/app/_models/block';

@Component({
  selector: 'app-asignar-bloque-modal',
  templateUrl: './asignar-bloque-modal.component.html',
  styleUrls: ['./asignar-bloque-modal.component.scss']
})
export class AsignarBloqueModalComponent implements OnInit, AfterViewInit, OnDestroy {



   //#region Lifecycle
   constructor(
    public dialogRef: MatDialogRef<AsignarBloqueModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {grupos: GrupoDTO[], blockLabel: BlockLabelDto, block: Block, existe: boolean},
    private _BlockGroupService: BlockGroupService,
    private snackServiceService: SnackService,
    private i18nextPipe: I18NextPipe,
    private dialog: MatDialog
  ) { }

   ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.grupos);
  }

  ngAfterViewInit(): void
  {
    if (this.data.existe) {
      this.PeriodoClave = this.data.block.periodoClave;
      this.PeriodoNombre = this.data.block.periodoNombre;
      this.PeriodoId = this.data.block.periodoId;
      this.CampusId = this.data.block.campusId;
      this.CampusNombre = this.data.block.campusNombre;
      this.BloqueNombre = this.data.block.bloqueNombre;
      this.BlockLabelId = this.data.block.blockLabelId;
      this.Capacidad = this.data.block.capacidad;
      this.NumeroGrupos = this.data.block.numeroGrupos;
      this.Existe = this.data.existe;
    } else {
      this.PeriodoClave = this.data.grupos[0].periodoClave;
      this.PeriodoNombre = this.data.grupos[0].periodoNombre;
      this.PeriodoId = this.data.grupos[0].periodoId;
      this.CampusNombre = this.data.grupos[0].campusNombre;
      this.CampusId = this.data.grupos[0].campusId;
      this.BloqueNombre = this.data.blockLabel.name;
      this.BlockLabelId = this.data.blockLabel.blockLabelId;
      this.Capacidad = this.data.blockLabel.capacity;
      this.NumeroGrupos = 0;
      this.Existe = this.data.existe;
    }

    this.verificarCapacidad();
    if (this.NumeroGrupos === this.Capacidad) {
      //this.snackServiceService.mostrarSnackBack(this.translate("_grupos.sin-cupo"));
    }
  }

  ngOnDestroy(): void
  {

  }
  //#endregion

  //#region  Propiedades
  public PeriodoClave: string;
  public PeriodoId: number;
  public PeriodoNombre: string;
  public CampusId: number;
  public CampusNombre: string;
  public BloqueNombre: string;
  public BlockLabelId: number;
  public Capacidad: number;
  public NumeroGrupos: number;
  public Existe: boolean;
  public AsignacionMasivaBloqueDto: AsignacionMasivaBloqueDto;

  public dialogoRefAdver: any;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  //#endregion

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region  Mat Table
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'grupo',
    'materia',
    'frecuencia',
    'horario'
  ];
  //#endregion

  //#region Modal Close
    public CerrarModal()
    {
      this.dialogRef.close();
    }
    //#endregion

  //#region Translate Method
  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }
  //#endregion

  //#region Methods

  private verificarCapacidad(): void
  {
    this.data.grupos.every(grupo => {
      if (this.Capacidad != grupo.capacidad) {
        //this.capacidadDiferente = true;
        this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
        return false;
      }
    });
  }
  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close();
    } else {
      this.dialogoRefAdver.close();
    }
  }

  public guardar(): void
  {

    this.AsignacionMasivaBloqueDto = new AsignacionMasivaBloqueDto();

    this.AsignacionMasivaBloqueDto.existe = this.Existe;
    this.AsignacionMasivaBloqueDto.blockId = this.data.block?.blockId;
    this.AsignacionMasivaBloqueDto.blockLabelId = this.BlockLabelId;
    this.AsignacionMasivaBloqueDto.periodoId = this.PeriodoId;
    this.AsignacionMasivaBloqueDto.campusId = this.CampusId;

    this.AsignacionMasivaBloqueDto.grupos = this.data.grupos.map(grupo => grupo.grupoId);


    this.Subscription.add(
      this._BlockGroupService.AsignacionMasivaBloqueDto(this.AsignacionMasivaBloqueDto).subscribe(
        (respuesta : SimpleResponse) => {
          if (respuesta.success) {
            this.snackServiceService.mostrarSnackBack(this.translate("_grupos.exito-asignacion-masiva"));
            this.CerrarModal();
          } else {
            this.snackServiceService.mostrarSnackBack(respuesta.message);
          }
        }
      )
    );
  }
  //#endregion
}
