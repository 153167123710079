import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Campus } from '../../../../_models/campus';
import { Periodo } from '../../../../_models/periodo';
import { CampusService } from '../../../../_services/campus.service';
import { GrupoService } from '../../../../_services/grupo.service';
import { PeriodoService } from '../../../../_services/periodo.service';

@Component({
  selector: 'app-form-campus-periodo',
  templateUrl: './form-campus-periodo.component.html',
  styleUrls: ['./form-campus-periodo.component.scss']
})
export class FormCampusPeriodoComponent implements OnInit, OnDestroy {

  public formCampusPeriodo: FormGroup;
  public campusList: Campus[] = [];
  public periodoList: Periodo[] = [];
  public subscriptionCampus: Subscription;
  public subscriptionPeriodo: Subscription;

  constructor(private campusService: CampusService,
    private periodoService: PeriodoService,
    private grupoService: GrupoService) { }

  ngOnInit(): void {
   // this.obtenerCampus();
   // this.obtenerPeriodo();
    this.inicializarForm();
    this.obtenerCampusOrderBy();
    this.obtenerPeriodoDes();
  }

  public inicializarForm() {
    this.formCampusPeriodo = new FormGroup({
      campus: new FormControl(0, [Validators.required, this.validateId]),
      periodo: new FormControl(0, [Validators.required, this.validateId])
    });

    this.formCampusPeriodo.valueChanges.subscribe(val => {
      this.grupoService.definirDatosCampusPeriodo(val);
      this.grupoService.definirBoolCambio(true);
      this.grupoService.invocarMetodoSetFechas();
    });
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.formCampusPeriodo.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El "+controlName+" es requerido";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  //Obtener Campus OrderBy
  public obtenerCampusOrderBy() {
    this.subscriptionCampus = this.campusService.otenerCampusOrderBy().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      });
  }

  //Obtener Periodo Fecha Inicio Descendente
  public obtenerPeriodoDes() {
    this.subscriptionPeriodo = this.periodoService.ObtenerPeriodoFechaInicio().subscribe(
      (periodo: Periodo[]) => {
        this.periodoList = periodo;
      });
  }

 /* public obtenerCampus() {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      }
    );
  }*/

  /*public obtenerPeriodo() {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoList = periodo;
        console.log("Periodo", this.periodoList);
      });
  }*/

  ngOnDestroy() {
    if (this.subscriptionCampus) {
      this.subscriptionCampus.unsubscribe;
    }
    if (this.subscriptionPeriodo) {
      this.subscriptionPeriodo.unsubscribe;
    }
    if (this.subscriptionCampus) {
      this.subscriptionCampus.unsubscribe;
    }
    if(this.grupoService.invMetodoSetFechasSubscription){
      this.grupoService.invMetodoSetFechasSubscription.unsubscribe();
      this.grupoService.invMetodoSetFechasSubscription = undefined; 
    }
  }

}
