import { ValidateId } from './../../functions/validate-id-function';
import { Validators } from "@angular/forms";

export const PREVIOUS_STUDIES_INFORAMTION = {

    usuarioId   :(0),
    nivelId     : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
    escuela     : [, { validators: [Validators.required], updateOn: 'change' }],
    promedio    : [, { validators: [Validators.required, Validators.pattern(/^[1-9]+/)], updateOn: 'change' }],
   
};