import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingScreenService } from '@app/_services/loading-screen.service';
import { RegistroService } from '@app/_services/registro.service';

@Component({
  selector: 'app-activacion',
  templateUrl: './activacion.component.html',
  styleUrls: ['./activacion.component.scss']
})
export class ActivacionComponent implements OnInit {

  public cargado: boolean = false;
  public activada: boolean = false;
  public expirada: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private loadingService: LoadingScreenService,
    private registroService: RegistroService
  ) { }

  ngOnInit(): void {
    this.loadingService.startLoading();

    let token: string = this._route.snapshot.paramMap.get('token');

    this.registroService.activaCuenta(token).subscribe(
      activado => {
        this.cargado = true;
        if (activado) {
          this.activada = true;
        } else {
          this.expirada = true;
        };
      }
    );
  }

}
