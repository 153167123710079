import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import { PersonaRelacionada } from '../_models/persona-relacionada';

@Injectable({
  providedIn: "root"
})

export class EmergenciaService {

  constructor(private httpClient: HttpClient) {
  }

  //Get PersonaRelacionada
  public obtenerPersona(): Observable<PersonaRelacionada[]> {
    return this.httpClient.get<PersonaRelacionada[]>(`${environment.enves[baseUrl].apiUrl}/Emergencia`);
  }

  public GetPersonaRelacionadaByProfesorId(usuarioId : number): Observable<any> {
    return this.httpClient.get<PersonaRelacionada>(`${environment.enves[baseUrl].apiUrl}/Emergencia/GetPersonaRelacionadaByProfesorId?usuarioId=` + usuarioId );
  }

  public GetPersonaRelacionadaEmergenciaByProfesorId(usuarioId : number): Observable<any> {
    return this.httpClient.get<PersonaRelacionada>(`${environment.enves[baseUrl].apiUrl}/Emergencia/GetPersonaEmergenciaByProfesorId?usuarioId=` + usuarioId );
  }


  //Post PersonaRelacionada
  public agregarPersona(persona: PersonaRelacionada): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Emergencia`, persona);
  }

  //Post PersonaEmergencia
  public agregarPersonaEmergencia(persona: PersonaRelacionada): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Emergencia/PersonaEmergencia`, persona);
  }


  //Put PersonaRelacionada
  public modificarPersona(persona: PersonaRelacionada): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Emergencia`, persona);
  }

  //Put PersonaRelacionada
  public modificarPersonaEmergencia(persona: PersonaRelacionada): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Emergencia/modificarPersonaEmergencia`, persona);
  }

}
