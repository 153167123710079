import { Component, OnInit, Inject, TemplateRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Biografia } from '../../../../_models/biografia';
import { EntidadFederativa } from '../../../../_models/entidad-federativa';
import { EstadoCivil } from '../../../../_models/estado-civil';
import { Genero } from '../../../../_models/genero';
import { GrupoSanguineo } from '../../../../_models/grupo-sanguineo';
import { Nivel } from '../../../../_models/nivel';
import { Pais } from '../../../../_models/pais';
import { AccionEnum } from '../../../../_models/accion.enum';
import { Profesor } from '../../../../_models/profesor';
import { TipoRelacion } from '../../../../_models/tipo-relacion';
import { BiografiaService } from '../../../../_services/biografia.service';
import { DireccionService } from '../../../../_services/direccion.service';
import { EstadoCivilService } from '../../../../_services/estado-civil.service';
import { GeneroService } from '../../../../_services/genero.service';
import { GrupoSanguineoService } from '../../../../_services/grupo-sanguineo.service';
import { NivelService } from '../../../../_services/nivel.service';
import { ProfesorService } from '../../../../_services/profesor.service';
import { TipoRelacionService } from '../../../../_services/tipo-relacion.service';
import { UtileriasService } from '../../../../_services/utilerias.service';
import { TipoTelefono } from '../../../../_models/tipo-telefono';
import { TipoDireccion } from '../../../../_models/tipo-direccion';
import { Institucion } from 'src/app/_models/institucion';
import { InstitucionService } from 'src/app/_services/institucion.service';

@Component({
  selector: 'app-datos-biograficos',
  templateUrl: './datos-biograficos.component.html',
  styleUrls: ['./datos-biograficos.component.scss']
})
export class DatosBiograficosComponent implements OnInit {
  @Input() datosUsuarioChild: any;
  @Input() usuarioId: number;
  @Output() CorreoOcupadoBandera: EventEmitter<any> = new EventEmitter();
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>
  public errorActualizaProfesor: string = "";
  public objValidacionCancelar: Profesor = null;
  public dialogRefAdver: any;
  public biografiaForm: FormGroup;
  public tipoAccion: number;
  public boolCambio: boolean = false;
  public NSSencontrado: boolean = false;
  public CURPencontrado: boolean = false;
  public CorreoOcupado: boolean = false;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public renderizarForm: boolean = false;
  public tipoAccionDireccion: number;
  public tipoAccionTelefono: number;
  public tipoAccionBiografia: number;
  public tipoAccionEstudiosPrevios: number;
  public tipoAccionFotografia: number;
  public institucion: Institucion;
  public curpNeed: boolean = false;

  //subscripcion
  public subscripcionGenero: Subscription;
  public subscripcionEstadoCivil: Subscription;
  public subscripcionEntidadFederativa: Subscription;
  public subscripcionGrupoSanguineo: Subscription;
  public subscripcionNivel: Subscription;
  public subscripcionFamiliar: Subscription;
  public subscriptionPais: Subscription;
  public subscriptionTipoTelefono: Subscription;
  public subscriptionTipoDireccion: Subscription;
  //Lista
  public sexoList: Genero[] = [];
  public generoList: Genero[] = [];
  public estadoList: EstadoCivil[] = [];
  public entidadList: EntidadFederativa[] = [];
  public ciudadList: Pais[] = [];
  public paisList: Pais[] = [];
  public sanguineoList: GrupoSanguineo[] = [];
  public nivelList: Nivel[] = [];
  public familiarList: TipoRelacion[] = [];
  public tipoDireccionList: TipoDireccion[] = [];
  public tipoTelefonoList: TipoTelefono[] = [];
  public tipoAccionFamiliarPapa: any;
  public tipoAccionFamiliarMama: any;
  public tipoAccionFamiliarConyuge: any;
  public tipoAccionFamiliarEmergencia: any;


  constructor(public dialogProfesorRef: MatDialogRef<DatosBiograficosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Biografia,
    private profesorService: ProfesorService,
    private dialog: MatDialog,
    private util: UtileriasService,
    private nivelService: NivelService,
    private generoService: GeneroService,
    private estadoCivilService: EstadoCivilService,
    private direccionService: DireccionService,
    private grupoSanguineoService: GrupoSanguineoService,
    private tipoRelacionService: TipoRelacionService,
    private biografiaService: BiografiaService,
    private institucionService: InstitucionService) {
  }

  //Validar si el Numero de Seguro Social ya esta asociado
  public NSSInsert(nss: string) {
    if (nss.trim().length == 11) {
      let usuarioId = this.usuarioId;
      if (!usuarioId) {
        usuarioId = 0;
      }
      this.biografiaService.obtenerBiografiaByNss(nss, usuarioId).subscribe(
        biografia => {
          if (biografia == null) {
            return this.NSSencontrado = false;
          } else {
            return this.NSSencontrado = true;
          }
        });
    }
  }

  public getTipoDireccion() {
    this.subscriptionTipoDireccion = this.direccionService.obtenerTipoDireccion().subscribe(
      (tipoDireccion: TipoDireccion[]) => {
        this.tipoDireccionList = tipoDireccion;
      });
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.biografiaForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  public correoDisponible(event) {
    if (!this.correo.hasError('email')) {
      const correo = event;
      let usuarioId = this.datosUsuarioBusqueda.usuarioId;

      this.profesorService.obtenerCorreoOcupado(correo, usuarioId).subscribe(ocupado => {

        this.CorreoOcupado = ocupado;
        if (this.CorreoOcupado) {
          // this.profesorService.definirFormularioValidoNuevoProfesor(this.biografiaForm.valid);
          this.CorreoOcupadoBandera.emit(this.CorreoOcupado);
          // this.profesorService.definirFormularioValidoNuevoProfesor(!this.CorreoOcupado);

        } else {
          // this.profesorService.definirFormularioValidoNuevoProfesor(this.biografiaForm.valid);
          this.CorreoOcupadoBandera.emit(this.CorreoOcupado);
          // this.profesorService.definirFormularioValidoNuevoProfesor(!this.CorreoOcupado);
        }
      })
    }


  }

  //Validar si el CURP ya esta asociado
  public CURPInsert(curp: string) {
    if (curp.trim().length == 18) {
      let usuarioId = this.usuarioId;
      if (!usuarioId) {
        usuarioId = 0;
      }

      this.biografiaService.obtenerBiografiaByCurp(curp, usuarioId).subscribe(
        biografia => {
          if (biografia == null) {
            return this.CURPencontrado = false;
          } else {
            return this.CURPencontrado = true;
          }
        });
    }
  }

  public renderizarFormulario() {
    if (this.datosUsuarioBusqueda.direccion.length >= 1) {
      this.tipoAccionDireccion = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDireccion = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.fotografia != null) {
      this.tipoAccionFotografia = AccionEnum.EDITAR;
    } else {
      this.tipoAccionFotografia = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.telefono.length >= 1) {
      this.tipoAccionTelefono = AccionEnum.EDITAR;
    } else {
      this.tipoAccionTelefono = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.biografia != null) {
      this.tipoAccionBiografia = AccionEnum.EDITAR;
    } else {
      this.tipoAccionBiografia = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.estudioPrevio.length >= 1) {
      this.tipoAccionEstudiosPrevios = AccionEnum.EDITAR;
    } else {
      this.tipoAccionEstudiosPrevios = AccionEnum.CREAR;
    }

    this.inicializarForm();
  }



  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.profesorService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
    //this.getTipoNivel();
    this.getTipoGenero();
    this.getTipoSanguineo();
    this.getTipoCivil();
    this.getTipoSexo();
    this.getRelacion();
    this.getPais();
    this.getTipoTelefono();
    this.getTipoDireccion();
  }
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }
  public inicializarForm() {

    this.biografiaForm = new FormGroup({
      fotografia: new FormControl(this.tipoAccionFotografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.fotografia),
      fotografiaFile: new FormControl(this.datosUsuarioBusqueda.fotografia !== null ? this.datosUsuarioBusqueda.fotografia : ''),
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      //DATOS CUENTA
      nombre: new FormControl(this.datosUsuarioBusqueda.nombre, [Validators.required]),
      segundoNombre: new FormControl(this.datosUsuarioBusqueda.segundoNombre),
      apellidoPaterno: new FormControl(this.datosUsuarioBusqueda.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(this.datosUsuarioBusqueda.apellidoMaterno),
      fechaNacimiento: new FormControl(this.util.formatearFecha(this.datosUsuarioBusqueda.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD'), [Validators.required]),
      //DATOS DE CONTACTO
      tipoDireccionId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.direccion[0].tipoDireccionId, [Validators.required, this.validateId]),
      paisContacto: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : 484, [Validators.required]),
      entidadFederativaId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.direccion[0].entidadFederativaId, [Validators.required]),
      ciudad: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].ciudad, [Validators.required]),
      codigoPostal: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].codigoPostal, [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      calle: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].calle, [Validators.required]),
      numeroExterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].numeroExterior, [Validators.required]),
      numeroInterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].numeroInterior),
      colonia: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].colonia, [Validators.required]),
      tipoTelefonoId: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.telefono[0].tipoTelefonoId, [Validators.required]),
      numero: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.telefono[0].numero, [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      extension: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.telefono[0].extension),
      correo: new FormControl(this.datosUsuarioBusqueda.correo, [Validators.required, Validators.email]),
      //DATOS BIOGRAFICOS
      legalGeneroId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.legalGeneroId, [Validators.required]),
      generoId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.generoId),
      ciudadaniaPaisId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.ciudadaniaPaisId, [Validators.required]),
      estadoCivilId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.estadoCivilId),
      nss: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.nss, [Validators.required]),
      curp: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.curp, [Validators.pattern(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/)]),
      pais: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : 484, [Validators.required]),
      entidadNacimiento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 19 : this.datosUsuarioBusqueda.biografia.entidadNacimiento, [Validators.required]),
      ciudadNacimiento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.ciudadNacimiento, [Validators.required]),
      //Biografia
      grupoSanguineoId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? null : this.datosUsuarioBusqueda.biografia.grupoSanguineoId),
      alergias: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.alergias),
      enfermedades: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.enfermedades),
    });

    this.onPaisSelected(484);
    this.obtenerInstitucion();
    this.renderizarForm = true;

    // if (this.curpNeed) {
    //   this.curp.setValidators([Validators.required]);
    //   this.curp.updateValueAndValidity();
    // } else {
    //   this.curp.setValidators(null);
    //   this.curp.updateValueAndValidity();
    // }

    this.biografiaForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
      console.log("this.biografiaForm.valid",this.biografiaForm.valid);
      this.profesorService.definirFormularioValidoNuevoProfesor(this.biografiaForm.valid);
      this.profesorService.setChangesProfesor(true);
    });
  }

  //Datos Cuenta
  get nombre() { return this.biografiaForm.get('nombre'); }
  get segundoNombre() { return this.biografiaForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.biografiaForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.biografiaForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.biografiaForm.get('fechaNacimiento'); }

  //Datos Contacto
  get tipoDireccionId() { return this.biografiaForm.get('tipoDireccionId'); }
  get paisContacto() { return this.biografiaForm.get('paisContacto'); }
  get entidadFederativaId() { return this.biografiaForm.get('entidadFederativaId'); }
  get ciudad() { return this.biografiaForm.get('ciudad'); }
  get codigoPostal() { return this.biografiaForm.get('codigoPostal'); }
  get calle() { return this.biografiaForm.get('calle'); }
  get numeroExterior() { return this.biografiaForm.get('numeroExterior'); }
  get numeroInterior() { return this.biografiaForm.get('numeroInterior'); }
  get colonia() { return this.biografiaForm.get('colonia'); }
  get tipoTelefonoId() { return this.biografiaForm.get('tipoTelefonoId'); }
  get correo() { return this.biografiaForm.get('correo'); }
  //Datos Biograficos
  get legalGeneroId() { return this.biografiaForm.get('legalGeneroId'); }
  get generoId() { return this.biografiaForm.get('generoId'); }
  get ciudadaniaPaisId() { return this.biografiaForm.get('ciudadaniaPaisId'); }
  get estadoCivilId() { return this.biografiaForm.get('estadoCivilId'); }
  get nss() { return this.biografiaForm.get('nss'); }
  get curp() { return this.biografiaForm.get('curp'); }
  get pais() { return this.biografiaForm.get('pais'); }
  get entidadNacimiento() { return this.biografiaForm.get('entidadNacimiento'); }
  get ciudadNacimiento() { return this.biografiaForm.get('ciudadNacimiento'); }
  //Biografia
  get grupoSanguineoId() { return this.biografiaForm.get('grupoSanguineoId'); }
  get alergias() { return this.biografiaForm.get('alergias'); }
  get enfermedades() { return this.biografiaForm.get('enfermedades'); }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogProfesorRef.close(null);
    }
  }

  public obtenerInstitucion() {
    this.institucionService.obtenerInstitucion().subscribe(
      institucion => {
        this.institucion = institucion;
        if (institucion.pais === 484) {
          this.curpNeed = true;
          this.curp.setValidators([Validators.required]);
          this.curp.updateValueAndValidity();
          this.boolCambio = false;
          this.profesorService.setChangesProfesor(false);
        } else {
          this.curpNeed = false;
          this.curp.setValidators(null);
          this.curp.updateValueAndValidity();
          this.boolCambio = false;
          this.profesorService.setChangesProfesor(false);
        }
      }
    );
  }

  public getPais() {
    this.subscriptionPais = this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisList = pais;
      });
  }

  public onPaisSelected(pais: number) {
    this.entidadList = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.entidadList = estados;
        })
    }
  }



  public getTipoGenero() {
    this.generoService.obtenerGenero().subscribe(
      (tipoGenero: Genero[]) => {
        this.generoList = tipoGenero;
      }
    );
  }
  public getTipoSexo() {
    this.generoService.obtenerGenero().subscribe(
      (tipoSexo: Genero[]) => {
        this.sexoList = tipoSexo;
      }
    );
  }

  public getTipoNivel() {
    this.nivelService.getNivel().subscribe(
      (tiposNivel: Nivel[]) => {
        this.nivelList = tiposNivel;
      }
    );
  }
  public getTipoSanguineo() {
    this.grupoSanguineoService.obtenerGrupoSanguineo().subscribe(
      (tipoSanguineo: GrupoSanguineo[]) => {
        this.sanguineoList = tipoSanguineo;
      }
    );
  }

  public getTipoCivil() {
    this.estadoCivilService.obtenerEstadoCivil().subscribe(
      (tipoCivil: EstadoCivil[]) => {
        this.estadoList = tipoCivil;
      }
    );
  }

  public getRelacion() {
    this.tipoRelacionService.getTipoRelacion().subscribe(
      (tipoRelacion: TipoRelacion[]) => {
        this.familiarList = tipoRelacion;
      }
    );
  }

  public getTipoTelefono() {
    this.subscriptionTipoTelefono = this.direccionService.obtenerTipoTelefono().subscribe(
      (tipoTelefono: TipoTelefono[]) => {
        this.tipoTelefonoList = tipoTelefono;
      });
  }
  // public getTipoDireccion() {
  //   this.subscriptionTipoDireccion = this.direccionService.obtenerTipoDireccion().subscribe(
  //     (tipoDireccion: TipoDireccion[]) => {
  //       this.tipoDireccionList = tipoDireccion;
  //     });
  // }
}
