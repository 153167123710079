import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment, baseUrl } from '@environments/environment';
import { BlockLabel } from '../_models/blockLabel';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { Subscription } from 'rxjs/internal/Subscription';


@Injectable({
  providedIn: 'root'
})
export class BlockLabelService {

  invocarObtenerNombreBloque = new EventEmitter();
  invMetodoObtenerNombreBloqueSubscription: Subscription;




  constructor(private httpClient: HttpClient) { }

  public obtenerBlockLabels(): Observable<ApiResponse<BlockLabel[]>> {
    return this.httpClient.get<ApiResponse<BlockLabel[]>>(`${environment.enves[baseUrl].apiUrl}/BlockLabel`);
  }


  /**
     * Convierte los datos obtenidos de los filtros a un archivo Excel 
     * @param elemento 
     * @returns Archivo en formato excel con los datos filtrados
     */
  public descargarExcel(elemento: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/BlockLabel/DownloadExcel`, JSON.stringify(elemento),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }


  public eliminarBlockLabel(blockLabelId: string): Observable<ApiResponse<any>> {
    return this.httpClient.delete<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/BlockLabel/BorrarNombreGrupo?blockLabelId=` + blockLabelId);
  }

  public agregarNombreBloque(nombreBloque: BlockLabel): Observable<ApiResponse<number>> {
    return this.httpClient.post<ApiResponse<number>>(`${environment.enves[baseUrl].apiUrl}/BlockLabel/GuardarNombreBloque`, nombreBloque);
  }

  public modificarNombreBloque(nombreBloque: BlockLabel): Observable<ApiResponse<boolean>> {
    return this.httpClient.put<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/BlockLabel/ModificarNombreBloque`, nombreBloque);
  }



  invocarMetodoObtenerNombreBloque() {
    this.invocarObtenerNombreBloque.emit();
  }


}
