import { Injectable } from "@angular/core";
import { I18NextPipe } from "angular-i18next";

@Injectable({
    providedIn: 'root'
})
export class TraductorService {

    constructor(private i18nextPipe: I18NextPipe) { }

    /**
     * Metodo para traducir textos en el ts
     * @param text 
     * @returns 
     */
    public translate(text: string) {
        return this.i18nextPipe.transform(text, { format: 'cap' });
    }
    
}