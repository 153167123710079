/**
 * Modelo para registrar los datos de un aula
 */

export class Aula {

    /**
     * Identificador del salon donde se impartira la materia
     */
    salonId: number;

    /**
     * Identificador del edificio al que pertenecera el aula
     */
    edificioId: number;

    /**
     * Identificador del campus al que pertenecera el aula
     */
    campusId:number;

    /**
     * Clave del salon
     */
    clave: string;

    /**
     * Nombre del salon
     */
    nombre: string;

    /**
     * Estatus que tiene el salon
     */
    estatus: boolean;

    /**
     * Nombre del campus donde se encuentra el aula
     */
    campusNombre:string;

    /**
     * Fecha en la que se inserto el registro
     */
    fechaCreacion: Date;

    /**
     * Usuario que creo el registro
     */
    usuarioCreacion: number;

    /**
     * Ultima fecha de modificacion del registro
     */
    fechaModificacion: Date | null;

    /**
     * Usuario que modifico el registro
     */
    usuarioModificacion: number | null;

    /**
     * Indica el tipo de accion que tendran los datos
     */
    tipoAccion?: number;
}

