import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { aceptarDialogo } from 'src/app/models/customEntities/aceptarDialogo.model';

@Component({
  selector: 'app-acept-dialog',
  templateUrl: './acept-dialog.component.html',
  styleUrls: ['./acept-dialog.component.scss']
})
export class AceptDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AceptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) textos: aceptarDialogo) {
      this.textos = textos;
  }


  //#region 
  public textos: aceptarDialogo;
  //#endregion
  ngOnInit(): void {
  }

  /**
   * Retorna una respuesta positiva al mensaje de confirmación
   */
  public aceptar(): void {
    this.dialogRef.close(true);
  }
  /**
   * Retorna una respuesta negativa al mensaje de confirmación
   */
  public cancelar(): void {
    this.dialogRef.close(false);
  }

}
