import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { ConfirmationDialogServiceService } from 'src/app/services/confirmation-dialog-service.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { CampaignService } from 'src/app/_services';

@Component({
  selector: 'app-delete-campaign',
  templateUrl: './delete-campaign.component.html',
  styleUrls: ['./delete-campaign.component.scss'],
})
export class DeleteCampaignComponent implements OnInit {
  public boolIsArray: boolean = false;
  public numSelectedCampaign: number;
  public campaignIds: string = '';
  public deleted: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly campaingService: CampaignService,
    private snackService: SnackService,
    private confirmacionDialogService: ConfirmationDialogServiceService
  ) {}

  ngOnInit(): void {
    console.log('ini delete');
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numSelectedCampaign = this.data.length;
    }
  }

  public async warning(): Promise<void> {
    let leads: number = 0;
    if (!this.boolIsArray) {
      leads = this.data.campaignLead.length;
    } else {
      this.data.forEach((element) => {
        leads = leads + element.campaignLead.length;
      });
    }
    if (leads > 0) {
      const result = await this.confirmacionDialogService
        .abrirModalDescartarCambios('La campaña actualmente tiene leads, ¿Desea continuar')
        .afterClosed()
        .toPromise();
      if (result) {
        this.deleteCampaign();
      } else {
        this.cerrarModal();
      }
    } else {
      this.deleteCampaign();
    }
  }

  public deleteCampaign(): void {
    if (this.boolIsArray) {
      let arrayIds = this.data.map((c) => c.campaignId.toString());
      this.campaignIds = arrayIds.toString();
    } else {
      this.campaignIds = this.data.campaignId.toString();
    }
    this.campaingService.deleteCampaign(this.campaignIds).subscribe(
      (response: ApiResponse<any>) => {
        if (response.success) {
          this.deleted = true;
          this.snackService.mostrarSnackBack('Se ha eliminado la información.');
          this.dialogRef.close(this.deleted);
        } else {
          this.snackService.mostrarSnackBack('La información no pudo ser eliminada.');
        }
      },
      (err) => {
        this.snackService.mostrarSnackBack('La información no pudo ser eliminada.');
      }
    );
  }

  public cerrarModal(): void {
    this.dialogRef.close(this.deleted);
  }
}
