import { baseUrl } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Campus } from 'src/app/_models/campus';
import { Subscription } from 'rxjs';
import { Periodo } from 'src/app/_models/periodo';
import { Programa } from 'src/app/_models/programa';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { SelectValidator } from 'src/app/validator/SelectValidator';
import { CampusProgramaDTO } from 'src/app/_models/CampusProgramaDTO';

@Component({
  selector: 'app-datos-programas-admision-express',
  templateUrl: './datos-programas-admision-express.component.html',
  styleUrls: ['./datos-programas-admision-express.component.scss']
})
export class DatosProgramasAdmisionExpressComponent implements OnInit {
  //Subscripciones
  private Subscription: Subscription = new Subscription();
  //Variables Catalogos
  public campusList: CampusProgramaDTO[] = [];
  public periodoList: Periodo[] = [];
  public programaList: Programa[] = [];
  //Variable Formulario
  public datosProgramaForm: FormGroup;
  //Variable tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;
  constructor(private admisionesService: AdmisionesService,) { }

  ngOnInit(): void {
    this.InicializarDatosProgramaForm();
    this.ObtenerCampusByUrl();
  }

  public InicializarDatosProgramaForm() {
    this.datosProgramaForm = new FormGroup({
      campus: new FormControl(0, [Validators.required, this.ValidateId]),
      programaAcademico: new FormControl(0, [Validators.required, this.ValidateId]),
      periodo: new FormControl(0, [Validators.required, this.ValidateId])
    });
  }

  private ValidateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }


  /**
   * Variables para validaciones FormGroup
   */
  get campus() { return this.datosProgramaForm.get('campus'); }
  get programaAcademico() { return this.datosProgramaForm.get('programaAcademico'); }
  get periodo() { return this.datosProgramaForm.get('periodo'); }


  /**
    * Metodo para traer el mensaje de error y mostrarlo en el html
    * @param controlName 
    * @returns 
    */
  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.datosProgramaForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "campo-requerido";
    }
    return error;
  }



  /**
   * Metodo para obtener el catalogo de campus
   */
  public ObtenerCampusByUrl() {
    this.Subscription.add(
      this.admisionesService.ObtenerCampusByUrl(this.tenantId).subscribe(
        (campus: CampusProgramaDTO[]) => {
          this.campusList = campus;
        })
    );
  }

  /**
   * Metodo que se activa cuando el select selecciona un campus diferente
   * @param campus 
   */
  public OnCampusSelected(campus: number) {
    this.programaList = [];
    if (!!campus) {
      this.Subscription.add(
        this.admisionesService.ObtenerProgramasByUrl(this.tenantId, campus).subscribe(
          (programas: Programa[]) => {
            this.programaList = programas;
          })
      );
    }
  }

  /**
   * Metodo para obtener el catalogo de periodos
   */
  public OnProgramaSelected() {
    if (this.programaAcademico.value !== 0 && this.campus.value !== 0) {
      this.Subscription.add(
        this.admisionesService.ObtenerPeriodoByUrl(this.tenantId, this.programaAcademico.value, this.campus.value).subscribe(
          (periodo: Periodo[]) => {
            this.periodoList = periodo;
          })
      );
    }
  }



  /**
   * Metodo que se activa cuando se cierra el componente
   */
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }


}
