import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextosUndoChanges } from 'src/app/models/customEntities/textosUndoChanes.mode';

@Component({
  selector: 'app-undo-changes-confirmation-dialog',
  templateUrl: './undo-changes-confirmation-dialog.component.html',
  styleUrls: ['./undo-changes-confirmation-dialog.component.scss']
})
export class UndoChangesConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UndoChangesConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  textos: TextosUndoChanges){
      this.textos = textos;
     }

  ngOnInit(): void {
  }
  public textos:TextosUndoChanges;
    /**
   * Retorna una respuesta positiva al mensaje de confirmación
   */
     public descartar(): void {
      this.dialogRef.close(true);
    }
    /**
     * Retorna una respuesta negativa al mensaje de confirmación
     */
    public cancelar(): void {    
      this.dialogRef.close(false);
    }

}
