import { BlockGroup } from './../../../_models/blockGroup';
import { SnackService } from './../../../services/snack-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Block } from '../../../_models/block';
import { RegistrarBloqueComponent } from './registrar-bloque/registrar-bloque.component';
import { BlockService } from '../../../_services/block.service';
import { EdicionBloqueComponent } from './edicion-bloque/edicion-bloque.component';
import { BlockGroupService } from 'src/app/_services/block-group.service';
import { BlockStudent } from '@app/_models/blockStudent';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { TraductorService } from '@app/_services/traductor.service';

@Component({
  selector: 'app-oferta-cademica-bloque-modal',
  templateUrl: './oferta-cademica-bloque-modal.component.html',
  styleUrls: ['./oferta-cademica-bloque-modal.component.scss']
})
export class OfertaCademicaBloqueModalComponent implements OnInit {
  //Componente hijo contenido
  @ViewChild('dialogoAgregarGrupoModal') dialogoAgregarGrupoModal: TemplateRef<any>;
  @ViewChild('dialogoAgregarAlumnosBloque') dialogoAgregarAlumnosBloque: TemplateRef<'dialogoAgregarAlumnosBloque'>;
  @ViewChild('dialogoCerrarModalCargo') dialogoCerrarModalCargo: TemplateRef<'dialogoCerrarModalCargo'>;

  public dialogoRefCerrarModalAgregarAlumnosBloque: MatDialogRef<any>;
  public dialogoRefCerrarModalCargo: MatDialogRef<any>;

  public permiteParcialidades: boolean = false;

  @ViewChild(RegistrarBloqueComponent) registroBloque: RegistrarBloqueComponent;
  @ViewChild(EdicionBloqueComponent) edicionBloque: EdicionBloqueComponent;

  public dialogoRefAgregarGrupoModal: any;



  public esNuevoBloque: boolean = true;



  constructor(public dialogRef: MatDialogRef<OfertaCademicaBloqueModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Block,
    public blockService: BlockService,
    private snackService: SnackService,
    private dialog: MatDialog,
    private blockGroupService: BlockGroupService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    if (this.data) {
      this.esNuevoBloque = false;
    }
  }


  public cerrarModal() {
    this.dialogRef.close(this.data);
  }
  public cerrarAgregarGruposModal() {
    this.dialogoRefAgregarGrupoModal.close(this.data);
  }


  public regisrarNuevoBloque() {
    let nuevoBloqueForm = Object.assign(this.registroBloque.nuevoBloqueForm.value);
    let bloque: Block = {
      blockLabelId: nuevoBloqueForm.nombreBloque,
      periodoId: nuevoBloqueForm.periodo,
      campusId: nuevoBloqueForm.campus
    }

    this.blockService.agregarBloque(bloque).subscribe(
      (response) => {
        if (response.success) {
          this.blockService.invocarMetodoObtenerBloque();
          this.data = response.data;
          this.snackService.mostrarSnackBack(response.message);
          this.abrirModalAgregarGrupos();
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      }, error => {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la categoría de solicitud. Por favor, intente de nuevo más tarde.');
      }
    );





  }

  public guardarGruposAlumnos() {

    let blockId = this.edicionBloque.data.blockId;
    switch (this.edicionBloque.tabSeleccionadoIndex.value) {
      //TAB Grupos
      case 0: {

        let grupos = this.edicionBloque.edicionBloqueGrupo;

        let blockGroups: BlockGroup[] = [];


        if (grupos.arrTemporales.length > 0) {

          grupos.arrTemporales.forEach(element => {
            let newBlockGroup = new BlockGroup();
            newBlockGroup.blockId = blockId;
            newBlockGroup.grupoId = element.grupoId;
            blockGroups.push(newBlockGroup);
          });

          this.blockGroupService.PostListBlockGroup(blockGroups).subscribe(response => {
            if (response.success) {
              this.snackService.mostrarSnackBack(response.message);
              this.blockService.invocarMetodoObtenerBloqueGrupo();
              this.blockService.invocarMetodoObtenerBloque();
            } else {
              this.snackService.mostrarSnackBack(response.message);
            }
          })
        } else {
          this.snackService.mostrarSnackBack("No se han agregado nuevos grupos para guardar");
        }
        break;
      }
      //TAB Alumnos
      case 1: {

        let alumnos = this.edicionBloque.edicionBloqueAlumno;

        if (alumnos.arrTemporales.length > 0) {

          if (this.edicionBloque.data.disponibles >= alumnos.arrTemporales.length) {

            this.abrirModalAgregarAlumnosBloque();
          } else {
            this.snackService.mostrarSnackBack("El numero de alumnos a inscribir es mayor al disponible en el bloque");
          }
        }
        break;
      }
    }





  }


  public guardarAlumnos() {
    let blockId = this.edicionBloque.data.blockId;

    let alumnos = this.edicionBloque.edicionBloqueAlumno;
    let blockStudents: BlockStudent[] = [];

    alumnos.arrTemporales.forEach(element => {
      let newBlockStudent = new BlockStudent();
      newBlockStudent.blockId = blockId;
      newBlockStudent.studentId = element.studentId;
      blockStudents.push(newBlockStudent);
    });

    this.blockService.PostListBlockStudent(blockStudents).subscribe(response => {
      if (response.success) {
        this.snackService.mostrarSnackBack(response.message);

        this.blockService.invocarMetodoObtenerBloqueAlumno();
        this.blockService.invocarMetodoObtenerBloque();
        this.cerarModalAgregarAlumnosBloque();
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    })
  }


  public Inscribir() {

    this.cerarModalAgregarAlumnosBloque();

    let blockId = this.edicionBloque.data.blockId;
    let periodoId = this.edicionBloque.data.periodoId;

    let alumnos = this.edicionBloque.edicionBloqueAlumno;

    alumnos.arrTemporales.forEach(element => {
      element.blockId = blockId;
      element.periodoId = periodoId;
    });


    this.blockService.ExisteColegiaturaCostoDisponibleAlumno(alumnos.arrTemporales).subscribe(response => {
      if (response.success) {

        this.permiteParcialidades = response.data;

        this.blockService.InscribirAGruposBloque(alumnos.arrTemporales).subscribe(response => {
          if (response.success) {

            this.abrirModalTipoCargo();
          } else {
            let mensaje: string = this.traductorService.translate(response.message);
            this.snackService.mostrarSnackBack(mensaje);
          }

        })
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }


    });

  }


  public cargoPorExibicion() {
    let alumnos = this.edicionBloque.edicionBloqueAlumno;
    let blockId = this.edicionBloque.data.blockId;
    let periodoId = this.edicionBloque.data.periodoId;
    alumnos.arrTemporales.forEach(element => {
      element.blockId = blockId;
      element.periodoId = periodoId;
    });


    this.blockService.CargoExcibicionBloque(alumnos.arrTemporales).subscribe((cargoExcibicion: SimpleResponse) => {
      if (cargoExcibicion.success) {
        let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
        this.blockService.invocarMetodoObtenerBloqueAlumno();
        this.blockService.invocarMetodoObtenerBloque();
        this.snackService.mostrarSnackBack(mensaje);
      }
      else {
        let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
        this.snackService.mostrarSnackBack(mensaje);
      }
    });

    this.cerarModalCerrarModalCargo();

  }


  public cargoPorParcialidades() {

    let alumnos = this.edicionBloque.edicionBloqueAlumno;
    let blockId = this.edicionBloque.data.blockId;
    let periodoId = this.edicionBloque.data.periodoId;
    alumnos.arrTemporales.forEach(element => {
      element.blockId = blockId;
      element.periodoId = periodoId;
    });

    this.blockService.CargoParcialidadesBloque(alumnos.arrTemporales).subscribe((cargoParcialidades: SimpleResponse) => {
      if (cargoParcialidades.success) {
        let mensaje: string = this.traductorService.translate(cargoParcialidades.message);
        this.blockService.invocarMetodoObtenerBloqueAlumno();
        this.blockService.invocarMetodoObtenerBloque();
        this.snackService.mostrarSnackBack(mensaje);
      }
      else {
        let mensaje: string = this.traductorService.translate(cargoParcialidades.message);
        this.snackService.mostrarSnackBack(mensaje);
      }
    });

    this.cerarModalCerrarModalCargo();
  }



  public abrirModalTipoCargo() {
    this.dialogoRefCerrarModalCargo = this.dialog.open(this.dialogoCerrarModalCargo, { disableClose: true });
  }




  public cerarModalAgregarAlumnosBloque() {
    this.dialogoRefCerrarModalAgregarAlumnosBloque.close();
  }
  public cerarModalCerrarModalCargo() {
    this.dialogoRefCerrarModalCargo.close();
  }
  public abrirModalAgregarAlumnosBloque() {
    this.dialogoRefCerrarModalAgregarAlumnosBloque = this.dialog.open(this.dialogoAgregarAlumnosBloque, { disableClose: true });
  }

  public abrirModalAgregarGrupos() {
    this.dialogoRefAgregarGrupoModal = this.dialog.open(this.dialogoAgregarGrupoModal, { disableClose: true });
  }

  public agregarGrupos(agregar: boolean) {
    if (agregar) {
      this.esNuevoBloque = false;
    } else {
      this.cerrarModal();
    }
    this.cerrarAgregarGruposModal();
  }




}
