/**
 * modelo para registrar los datos de una materia
 */

export class AreaMateria {

  /**
   * Area a la que pertenece la materia
   */
  areaMateriaId: number;

  /**
   * Clave de la materia 
   */
  clave: string;

  /**
   * Nombre de la materia
   */
  nombre: string;
  /**
   * tipo de accion para determinar si es crear o editar 
   */
  tipoAccion?: number;
}
