import { TraductorService } from 'src/app/_services/traductor.service';
import { filter } from 'rxjs/operators';
import { AvanceCursoModalComponent } from './../avance-curso-modal/avance-curso-modal.component';
import { UtileriasService } from './../../_services/utilerias.service';
import { FiltroAdminAcademicoAlumnoDto } from './../../_models/FiltroAdminAcademicoAlumnoDto';
import { AvanceCursoService } from './../../_services/avance-curso.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiResponse } from '../../models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from '../../services/generacion-excel-service.service';
import { EnviarCorreoComponent } from '../../_components/enviar-correo/enviar-correo.component';
import { Campus } from '../../_models/campus';
import { Periodo } from '../../_models/periodo';
import { Programa, ProgramaActivo } from '../../_models/programa';
import { CampusService } from '../../_services/campus.service';
import { NuevoIngresoService } from '../../_services/nuevo-ingreso.service';
import { AvanceCursoInformacionAlumno } from '../../_models/AvanceCursoInformacionAlumno';
import { SnackService } from '@app/services/snack-service.service';

@Component({
  selector: 'app-avance-curso-seguimiento',
  templateUrl: './avance-curso-seguimiento.component.html',
  styleUrls: ['./avance-curso-seguimiento.component.scss']
})
export class AvanceCursoSeguimientoComponent implements OnInit {

  public datafilter: any[] = [];
  public numAlumnosSeleccionados: number = 0;
  public selection = new SelectionModel<any>(true, []);
  public displayedColumns: string[] = ['select', 'id', 'nombre', 'periodo', 'programa', 'campus', 'total', 'terminadas', 'pendienteCal', 'pendienteEntrega', 'calificacion','fechaInicio', 'estatus'];
  public dataSource: MatTableDataSource<any>;
  public filtroForm: FormGroup;
  public idNombre = new FormControl();
  public CampusF = new FormControl();
  public ProgramaF = new FormControl();
  public periodoF = new FormControl();
  public estatusF = new FormControl();
  public periodos: Periodo[] = [];
  public programas: ProgramaActivo[] = [];
  public arCampus: Campus[] = [];
  public valorInicial = 0;
  public arregloFiltroModificado: any[] = [];
  public arregloFiltroOriginal: any[] = [];
  public infoCargada: boolean = false;
  public estatus: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allEstatus') private allEstatus: MatOption;

  constructor(private generadorExcel: GeneracionExcelServiceService,
    private dialog: MatDialog,
    private paginador: MatPaginatorIntl,
    private nuevoIngresoService: NuevoIngresoService,
    private campuservice: CampusService,
    private avanceCursoService: AvanceCursoService,
    public util: UtileriasService,
    private snackServiceService: SnackService,
    private traductorService: TraductorService) { }

  async ngOnInit(): Promise<void> {
    if (this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription === undefined) {
      this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription = this.nuevoIngresoService.invocarObtenerCandidatos.subscribe(() => {
        this.LlenarFiltros();
      });
      this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription === undefined;
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    await this.inicializarForm().then((_) => {
      this.LlenarFiltros();
    });
  }

  /**
   * Inicializa el formulario y setea los campos usados
   */
  public inicializarForm(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.filtroForm = new FormGroup({
        periodoF: new FormControl([]),
        idnombre: new FormControl(),
        ProgramaF: new FormControl([]),
        CampusF: new FormControl([]),
        estatusF: new FormControl([])
      });
      this.CampusF.setValue(["-1"]);
      this.ProgramaF.setValue(["-1"]);
      this.periodoF.setValue(["-1"]);
      this.estatusF.setValue(["-1"]);
      resolve();
    });
  }

  /**
   * Hace un llenado de todos los filtros, ingresa a las funciones de obtencion de datos para su carga
   */
  public LlenarFiltros(): void {
    this.obtenerPeriodo();
  }

  /**
 * Obtiene los campus del periodo
 */
  public obtenerPeriodo(): void {
    this.nuevoIngresoService.getPeriodo().subscribe(
      (periodos: Periodo[]) => {
        this.periodos = periodos;
        this.periodoF.setValue([...this.periodos.map(item => item.periodoId), 0]);
        this.filtroForm.patchValue({
          periodoF: this.periodos.map(item => item.periodoId)
        });
        this.obtenerProgramas();
      });
  }

  /**
   * Obtiene los campus del programa y setea su valor en todos
   */
  public obtenerProgramas(): void {
    this.nuevoIngresoService.getPrograma().subscribe(
      (programas: Programa[]) => {
        this.programas = programas;
        this.ProgramaF.setValue([...this.programas.map(item => item.programaId), 0]);
        this.filtroForm.patchValue({
          ProgramaF: this.programas.map(item => item.programaId)
        });
        this.obtenerCampus();
      });
  }

  /**
   * Obtiene los campus del catalogo y setea su valor en todos
   */
  public obtenerCampus(): void {
    this.campuservice.obtenerCampus().subscribe((campus: Campus[]) => {
      this.arCampus = campus;
      this.CampusF.setValue([...this.arCampus.map(item => item.campusId), 0]);
      this.filtroForm.patchValue({
        CampusF: this.arCampus.map(item => item.campusId)
      });
      this.obtenerEstatus();
    });
  }

  /**
   * Obtiene los campus del estatus academico
   */
  public obtenerEstatus(): void {
    this.estatus = [
      {
        estatusId: 1,
        clave: "PENDIENTE",
        nombre: "Pendiente"
      },
      {
        estatusId: 2,
        clave: "TERMINADO",
        nombre: "Terminado"
      },
    ];
    this.estatusF.setValue([...this.estatus.map(item => item.estatusId), 0]);
    this.filtroForm.patchValue({
      estatusF: this.estatus.map(item => item.estatusId)
    });
    this.filtrar();
  }

  /**
   * Metodo que envia la informacion de los filtros al back
   */
  public filtrar() {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatusAr: this.filtroForm.get('estatusF').value
    };
    this.obtenerAvanceCurso(1, this.paginator.pageSize, filtro);
  }

  /**
   * Metodo que detecta el cambio de pagina y llama el servicio para traer la siguiente informacion
   * @param event
   */
  public onPaginateChange(event: any) {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatusAr: this.filtroForm.get('estatusF').value
    };
    this.obtenerAvanceCurso(event.pageIndex + 1, event.pageSize, filtro);
  }

  /**
   * Obtiene el catalogo de inscripciones para mostrarlos en la tabla principal, agrega una nueva propiedas llamada nombre completo
   * carga los datos a la tabla principal y agrega los datos a un arreglo original
   * @param pagina
   * @param registrosPagina
   * @param filtro
   */
  public obtenerAvanceCurso(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto) {
    this.avanceCursoService.avanceCursoAlumnos(pagina, registrosPagina, filtro).subscribe(
      (avanceCurso: ApiResponse<any>) => {
        if (avanceCurso.data.alumnosAvanceCurso.length > 0) {
          this.arregloFiltroOriginal = avanceCurso.data.alumnosAvanceCurso;
          this.datafilter = avanceCurso.data.alumnosAvanceCurso;
          this.dataSource = new MatTableDataSource(this.datafilter);
          this.valorInicial = avanceCurso.data.cantidadAlumnos;
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargada = true;
        } else {
          this.dataSource = new MatTableDataSource([]);
          this.arregloFiltroOriginal = [];
          this.datafilter = [];
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargada = true;
        }
      });
  }

  /**
   * Metodo que sirve para generar la constancia de los alumnos seleccionados
   * @param Alumnos
   */
  public generarConstancia(Alumnos: Array<any>): void {

     var AlumnosNoAprobados = Alumnos.filter(x => x.cursoAprobado === false);

     if (AlumnosNoAprobados.length > 0) {
      let mensaje: string = this.traductorService.translate('_avance-curso.generar-constancia');
      this.snackServiceService.mostrarSnackBack(mensaje);
     }else {


     Alumnos = Alumnos.filter(x => x.cursoAprobado === true);

     this.avanceCursoService.descargarConstancia(Alumnos).subscribe((blob: Blob) => {

      if (blob.type === "text/plain") {
        let mensaje: string = this.traductorService.translate('_avance-curso.sin-constancia');
        this.snackServiceService.mostrarSnackBack(mensaje);
      } else {
        if (Alumnos.length > 1) {
          this.generadorExcel.descargarConstancia(blob, "Constancias", true);
        } else {
          this.generadorExcel.descargarConstancia(blob, `Constancia ${Alumnos[0].nombreCompleto}`, false);
        }
      }

     });
     }
  }

  /**
   * Metodo que abre el modal con el detalle del cuso
   * @param element
   */
  public detalleAvanceCurso(element: any, tabSeleccionado: number): void {
    this.avanceCursoService.informacionAvanceCursoByAlumnoId(element.alumnoId, element.grupoId).subscribe(
      (datosAlumno: ApiResponse<AvanceCursoInformacionAlumno>) => {
        datosAlumno.data.tabSeleccionado = tabSeleccionado;
        this.cargarContenido(element.alumnoId, element.grupoId, datosAlumno.data);
      }
    );
  }

  /**
   * Trae la informacion del grupo
   * @param grupoId
   */
  public cargarContenido(alumnoId: number, grupoId: number, datos: AvanceCursoInformacionAlumno) {
    this.avanceCursoService.obtenerContenidoAlumno(alumnoId, grupoId).subscribe((respuestaContenido: ApiResponse<any>) => {
      let datosCompletos: any = {
        datosPersonales: datos,
        contenidoCurso: respuestaContenido.data
      }
      this.util.abrirDialogoLateral(AvanceCursoModalComponent, datosCompletos);
    });
  }

  /**
   * Metodo para hacer la descarga de excel de alumnos seleccionados
   * @param elemento
   */
  public descargarExcel(elemento?: any[]): void {
    let Alumnos: any[] = [];
    if (elemento != null && elemento.length > 0) {
      for (let index = 0; index < elemento.length; index++) {
        let alumnoEnvio: any = {
          alumnoId: elemento[index].alumnoId,
          grupoId: elemento[index].grupoId
        }
        Alumnos.push(alumnoEnvio);
      }
    } else {
      for (let index = 0; index < this.datafilter.length; index++) {
        let alumnoEnvio: any = {
          alumnoId: this.datafilter[index].alumnoId,
          grupoId: this.datafilter[index].grupoId
        }
        Alumnos.push(alumnoEnvio);
      }
    }
    this.avanceCursoService.descargarExcel(Alumnos).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "AvacneCurso");
    });
  }

  /**
   * Metodo para enviar correo a los alumnos seleccionados
   * @param btnDataTable
   * @param elemento
   */
  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogo = this.dialog.open(EnviarCorreoComponent, {
      data: datos,
      panelClass: "dialogo-enviar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  /**
   * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
   * seleccionados para modificar el check
   * @returns boolean para conocer si todos estan seleccionados
   */
  isAllSelected(): boolean {
    if (this.datafilter) {
      this.numAlumnosSeleccionados = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numAlumnosSeleccionados === numRows;
    }
  }

  /**
   * Funcion para el check de la tabla
   * @param row
   * @returns
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }

  /**
   * Funcion para el check de la tabla
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Limpia el arreglo de filtro, llena nuevamente los filtros e inicia el formulario, y muestra la tabla sin filtrado
   */
  public LimpiarFiltros(): void {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Vacia el arreglo
   */
  public vaciarArregloModificado(): void {
    this.arregloFiltroModificado.length = 0;
  }

  /**
* Si solo un dato del select Periodo esta seleccionado busca solo esa informacion y la
* muestra en la tabla principal
*/
  toggleAllSelectionPeriodo(): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.filtroForm.patchValue({
        periodoF: this.periodos.map(item => item.periodoId)
      });
      this.periodoF.patchValue([...this.periodos.map(item => item.periodoId), 0]);
    } else {
      this.filtroForm.patchValue({
        periodoF: []
      });
      this.periodoF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Programa esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionPrograma(): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.filtroForm.patchValue({
        ProgramaF: this.programas.map(item => item.programaId)
      });
      this.ProgramaF.patchValue([...this.programas.map(item => item.programaId), 0]);
    } else {
      this.filtroForm.patchValue({
        ProgramaF: []
      });
      this.ProgramaF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Campus esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionCampus(): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.filtroForm.patchValue({
        CampusF: this.arCampus.map(item => item.campusId)
      });
      this.CampusF.patchValue([...this.arCampus.map(item => item.campusId), 0]);
    } else {
      this.filtroForm.patchValue({
        CampusF: []
      });
      this.CampusF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select estatus esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionEstatus(): void {
    // this.reiniciarTimer();
    if (this.allEstatus.selected) {
      this.filtroForm.patchValue({
        estatusF: this.estatus.map(item => item.estatusId)
      });
      this.estatusF.patchValue([...this.estatus.map(item => item.estatusId), 0]);
    } else {
      this.filtroForm.patchValue({
        estatusF: []
      });
      this.estatusF.patchValue([]);
    }
  }

  /**
  * Verifica si el campo Todos del select Periodo esta seleccionados, si lo esta
  * quita la seleccion de todos los campos, sino los selecciona todos
  * @param periodoId
  */
  tosslePerOnePeriodo(periodoId: number): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
    }
    if (this.periodoF.value.length == this.periodos.length) {
      this.allPeriodo.select();
    }
    const periodosSeleccionados = this.filtroForm.get('periodoF').value;
    const existePeriodo = periodosSeleccionados.find(x => x === periodoId);
    if (!existePeriodo) {
      const periodosAnteriores = periodosSeleccionados;
      periodosAnteriores.push(periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosAnteriores
      });
    } else {
      const periodosFiltrados = periodosSeleccionados.filter(x => x !== periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Programa esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param programaId
     * @returns
  */
  tosslePerOnePrograma(programaId: number): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
    }
    if (this.ProgramaF.value.length == this.programas.length) {
      this.allPrograma.select();
    }
    const programasSeleccionados = this.filtroForm.get('ProgramaF').value;
    const existePrograma = programasSeleccionados.find(x => x === programaId);
    if (!existePrograma) {
      const programasAnteriores = programasSeleccionados;
      programasAnteriores.push(programaId);
      this.filtroForm.patchValue({
        ProgramaF: programasAnteriores
      });
    } else {
      const programasFiltrados = programasSeleccionados.filter(x => x !== programaId);
      this.filtroForm.patchValue({
        ProgramaF: programasFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param campusId
     * @returns
  */
  tosslePerOneCampus(campusId: number): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.allCampus.deselect();
    }
    if (this.CampusF.value.length == this.arCampus.length) {
      this.allCampus.select();
    }
    const campusSeleccionados = this.filtroForm.get('CampusF').value;
    const existeCampus = campusSeleccionados.find(x => x === campusId);
    if (!existeCampus) {
      const campusAnteriores = campusSeleccionados;
      campusAnteriores.push(campusId);
      this.filtroForm.patchValue({
        CampusF: campusAnteriores
      });
    } else {
      const campusFiltrados = campusSeleccionados.filter(x => x !== campusId);
      this.filtroForm.patchValue({
        CampusF: campusFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param campusId
     * @returns
  */
  tosslePerOneEstatus(estatusId: number): void {
    // this.reiniciarTimer();
    if (this.allEstatus.selected) {
      this.allEstatus.deselect();
    }
    if (this.estatusF.value.length == this.estatus.length) {
      this.allEstatus.select();
    }
    const EstatusSeleccionados = this.filtroForm.get('estatusF').value;
    const existeEstatus = EstatusSeleccionados.find(x => x === estatusId);
    if (!existeEstatus) {
      const estatusAnteriores = EstatusSeleccionados;
      estatusAnteriores.push(estatusId);
      this.filtroForm.patchValue({
        estatusF: estatusAnteriores
      });
    } else {
      const estatusFiltrados = EstatusSeleccionados.filter(x => x !== estatusId);
      this.filtroForm.patchValue({
        estatusF: estatusFiltrados
      });
    }
  }

  /**
   * Metodo para buscar los filtros
   */
  public buscarFiltros(): void {
    this.filtrar();
  }

}
