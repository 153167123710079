import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EnsenanzaAprendizajeAlumnoService } from '../_services/ensenanzaAprendizajeAlumno.service'
import { Subscription, Observable } from 'rxjs';
import { Periodo, PeriodoDto } from '../_models/periodo';
import { AuthenticationService } from '../_services/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { ListaMateriasOferta } from '../_models/lista-materias-oferta';
import { I18NextPipe } from 'angular-i18next';
import i18next from 'i18next';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ensenanza-aprendizaje-alumno',
  templateUrl: './ensenanza-aprendizaje-alumno.component.html',
  styleUrls: ['./ensenanza-aprendizaje-alumno.component.scss']
})
export class EnsenanzaAprendizajeAlumnoComponent implements OnInit {
  public filtroForm: FormGroup;
  public usuario: any;
  Periodos: Periodo[];
  periodoF = new FormControl();
  public datosUsuarioBusqueda$: Observable<any>;
  public identificador: string;
  public isAuthenticated: boolean = false;
  public grupoList: ListaMateriasOferta[] = [];
  public grupoListInicial: ListaMateriasOferta[] = [];
  private periodosactivos: number[] = [];
  FlagGrupo: boolean = false;
  filtroactual: any;

  public showMenu: number;
  private curentLanguaje: string;
  //inicializar valor del matselected

  datosUsuarioSubscription: Subscription;
  constructor(public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    public authenticationService: AuthenticationService,
    private msalService: MsalService,
    private i18nextPipe: I18NextPipe,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.curentLanguaje = i18next.language;
    this.FlagGrupo = false;
    this.getUser();
    this.inicializarForm();
  }

  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }


  showHorario(clave: string, ig: number) {

    if (this.showMenu === ig) {
      this.showMenu = -1;
    }
    else {
      this.showMenu = ig;
    }
  }

  public getUser() {
    this.authenticationService.currentUser.subscribe(user => {
      let cuenta = this.msalService.instance.getActiveAccount();
      this.isAuthenticated = !!cuenta;
      if (this.isAuthenticated) {
        this.identificador = cuenta.username.substring(0, cuenta.username.indexOf('@'));
        this.datosUsuarioSubscription = this.authenticationService.obtenerDatosUsuario(this.identificador).subscribe(data => {
          this.usuario = data;
          if (!!this.usuario) {
            this.obtenerPeriodos();
            this.EnsenanzaAprendizajeAlumnoService.definirAlumno(this.usuario.alumno[0].alumnoId);
            this.LlenarFiltros();
          }
        })

      }
    })
  }
  public LlenarFiltros() {
    let today = new Date();
    this.EnsenanzaAprendizajeAlumnoService.obtenerPeriodosUsuario(this.usuario.usuarioId).subscribe(
      (periodos: ApiResponse<PeriodoDto[]>) => {
        this.Periodos = periodos.data;
        for (let x of periodos.data) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio < today && fin > today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if (this.Periodos.length > 0) {
          this.filtroForm.patchValue({
            periodoF: this.Periodos[0].periodoId,
          })
          this.filtroactual = this.filtroForm.get('periodoF').value;
        }
        this.filtrar();
      });
  }
  public inicializarForm() {
    this.filtroForm = new FormGroup({
      periodoF: new FormControl(null),
    });
  }
  public obtenerPeriodos() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerPeriodosUsuario(this.usuario.usuarioId).subscribe(
      (periodo: ApiResponse<PeriodoDto[]>) => {
        this.Periodos = periodo.data;
      });
  }

  public obtenerGruposAll(usuarioId: number) {
    this.FlagGrupo = false;
    this.EnsenanzaAprendizajeAlumnoService.obtenerGruposAll(usuarioId, this.curentLanguaje).subscribe(
      (grupos: ApiResponse<ListaMateriasOferta[]>) => {
        this.grupoList = grupos.data;
        this.grupoListInicial = grupos.data;
      });
  }

  public filtrarGrupos(periodos) {
    this.FlagGrupo = false;
    this.EnsenanzaAprendizajeAlumnoService.obtenerGrupos(periodos, this.usuario.usuarioId, this.curentLanguaje).subscribe(
      (grupos: ApiResponse<ListaMateriasOferta[]>) => {
        this.grupoList = grupos.data;
      });
  }
  public filtrar() {
    if (this.filtroactual > 0) {
      this.filtrarGrupos(this.filtroactual.toString());
    } else {
      this.grupoList = [];
    }

  }
  Grupo: number;
  nombreMateria: string = "";
  GrupoSelect: any[];
  public goContenido(grupo) {
    this.nombreMateria = grupo.nombre;
    this.Grupo = grupo.grupoId;
    this.GrupoSelect = grupo;
    const infoGrupo: any = {
      nombreMateria: this.nombreMateria,
      Grupo: this.Grupo,
      GrupoSelect: this.GrupoSelect
    };
    console.log('grupo', grupo);
    this.EnsenanzaAprendizajeAlumnoService.definirAlumno(grupo.alumnoId);
    this.EnsenanzaAprendizajeAlumnoService.SetInfoGrupo(infoGrupo);
    this.router.navigate([`/detalle-grupo-alumno/grupo/${this.Grupo}/opcion/micurso`]);
  }
}

