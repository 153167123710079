import { ValidateId } from './../../functions/validate-id-function';
import { Validators } from "@angular/forms";

export const BIOGRAPHICAL_INFORMATION = {

    legalGeneroId       : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
    generoId            : [0, { updateOn: 'change' }],
    ciudadaniaId        : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
    estadoCivilId       : [0, { updateOn: 'change' }],
    curp                : [,  { validators: [Validators.required, Validators.pattern(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/)], updateOn: 'change' }],
    ciudadaniaPaisId    : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
    entidadNacimiento   : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
    ciudadNacimiento    : [ , { validators: [Validators.required], updateOn: 'change' }]

};