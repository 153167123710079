import { UtileriasService } from './../../_services/utilerias.service';
import { OfertaAcademicaNombreBloqueModalEliminarComponent } from './oferta-academica-nombre-bloque-modal-eliminar/oferta-academica-nombre-bloque-modal-eliminar.component';
import { BlockLabelService } from './../../_services/block-label.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Campus } from '../../_models/campus';
import { Periodo } from '../../_models/periodo';
import { CampusService } from '../../_services/campus.service';
import { PeriodoService } from '../../_services/periodo.service';
import { MatTableDataSource } from '@angular/material/table';
import { BlockLabel } from '../../_models/blockLabel';
import { ApiResponse } from '../../models/api/ApiRespose.model';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { MatDialog } from '@angular/material/dialog';
import { OfertaAcademicaNombreBloqueModalEdicionComponent } from './oferta-academica-nombre-bloque-modal-edicion/oferta-academica-nombre-bloque-modal-edicion.component';
import { AccionEnum } from 'src/app/_models/accion.enum';
@Component({
  selector: 'app-oferta-academica-nombre-bloque',
  templateUrl: './oferta-academica-nombre-bloque.component.html',
  styleUrls: ['./oferta-academica-nombre-bloque.component.scss']
})
export class OfertaAcademicaNombreBloqueComponent implements OnInit {
  public dataSource: MatTableDataSource<BlockLabel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<BlockLabel>(true, []);
  displayedColumns: string[] = ['select', 'clave', 'nombre', 'capacidad', 'estatus'];
  public arrFiltrado: any[] = [];
  public numBloqueSeleccionado: any;
  public numselectedAlumnos: any;
  public infoCargado: boolean = false;
  public datafilter: BlockLabel[] = [];
  private arrOriginal: BlockLabel[] = [];
  public arregloFiltroOriginal: BlockLabel[] = [];
  public arregloFiltroModificado: any[] = [];
  public numBlockLabelSeleccionado: number = 0;
  public filtroForm: FormGroup;
  public estatusF = new FormControl();
  public claveNombre = new FormControl();



  constructor(
    public periodoService: PeriodoService,
    public blockLabelService: BlockLabelService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    private generadorExcel: GeneracionExcelServiceService,
    private dialog: MatDialog,
    private util: UtileriasService
  ) { }

  ngOnInit(): void {
    if (this.blockLabelService.invMetodoObtenerNombreBloqueSubscription == undefined) {
      this.blockLabelService.invMetodoObtenerNombreBloqueSubscription = this.blockLabelService.invocarObtenerNombreBloque.subscribe(() => {
        this.obtenerBlockLabels();
        this.LimpiarFiltros();
      });
    }

    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };



    this.LimpiarFiltros();
  }


  public filtro(): void {
    let estatus: any = this.estatusF.value == null ? 0 : this.estatusF.value;
    let nombre: string = this.claveNombre.value == null ? "" : this.claveNombre.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    this.arrFiltrado = filtroModificado;
    if (estatus !== -1) {

      filtroModificado = filtroModificado.filter(f => { return f.status === estatus });
    }

    if (nombre.length > 0 || nombre !== '') {
      filtroModificado = filtroModificado.filter(el => {
        return el.name.toLowerCase().search(el.name.toLowerCase()) > -1 || el.code.toString().search(nombre) > -1;
      })
    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.datafilter = filtroModificado;
  }

  /**
    * Funcion que busca de la tabla mediante el nombre o el identificador del alumno
    * @param filterValue 
    */
  buscarNombreClave(filterValue: string): void {
    filterValue = filterValue.toLowerCase();
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    if (filterValue != "") {
      filtroModificado = filtroModificado.filter(el => {
        return el.name.toLowerCase().search(filterValue) > -1 || el.code.toString().toLowerCase().search(filterValue) > -1;
      })


    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.datafilter = filtroModificado;
  }

  public obtenerBlockLabels() {
    this.blockLabelService.obtenerBlockLabels().subscribe(
      (response: ApiResponse<BlockLabel[]>) => {
        if (response.success) {
          let blockLabelsEncontrados: BlockLabel[] = response.data;
          this.arrOriginal = [...blockLabelsEncontrados];
          this.dataSource = new MatTableDataSource(blockLabelsEncontrados);
          this.datafilter = blockLabelsEncontrados;
          this.dataSource.paginator = this.paginator;
          this.arregloFiltroOriginal = response.data;
          this.infoCargado = true;
          this.selection = new SelectionModel<any>(true, []);
        }
      })
  }


  /**
     * Valida si se ha seleccionado todos los rows
     * @returns boolean
     */
  isAllSelected() {
    this.numBlockLabelSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numBlockLabelSeleccionado === numRows;
  }


  /**
   * Marca todas los rows de la tabla
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }



  /**
   * Determina qué texto se mostrará en el Label con base a si se ha enviado on o un parámetro
   * @param row
   * @returns
   */
  checkboxLabel(row?: BlockLabel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.blockLabelId + 1}`;
  }


  //Refrescar button
  public refrescarDatos() {
    this.vaciarArregloModificado();
    // this.profesorService.invocarMetodoObtenerProfesor();
  }

  public vaciarArregloModificado() {
    //empty your array
    this.arregloFiltroModificado.length = 0;
  }


  /**
    * Funcion que limpia o vuelve a los select a su estado original 
    */
  public LimpiarFiltros(): void {
    this.dataSource = new MatTableDataSource(this.arrFiltrado);
    this.dataSource.paginator = this.paginator;
    this.estatusF.setValue(-1);
    this.claveNombre.setValue("");
    this.datafilter = this.arrFiltrado;
    this.obtenerBlockLabels();
  }


  public borrar(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogoEliminar = this.dialog.open(OfertaAcademicaNombreBloqueModalEliminarComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });

  }


  /**
     * Funcion que obtiene los registros filtrados de la tabla principal y los pasa al back
     * para convertir esos datos a un archivo Excel
     * @param elemento 
     */
  public descargarExcel(elemento?: BlockLabel[]): void {
    let IdBlockLabel;
    if (elemento != null && elemento.length > 0) {
      IdBlockLabel = elemento.map(m => m.blockLabelId.toString());
    } else {
      IdBlockLabel = this.datafilter.map(m => m.blockLabelId.toString());
    }
    this.blockLabelService.descargarExcel(IdBlockLabel).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "Nombre de bloque");
    });
  }


  public editar(elemento?: any): void {
    let datos: BlockLabel = {
      blockLabelId: elemento.blockLabelId,
      institucionId: elemento.institucionId,
      code: elemento.code,
      name: elemento.name,
      status: elemento.status,
      capacity: elemento.capacity,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(OfertaAcademicaNombreBloqueModalEdicionComponent, datos);
  }

  public crear(): void {
    let datos: BlockLabel = {
      blockLabelId: 0,
      institucionId: '',
      code: '',
      name: '',
      status: false,
      capacity: 0,
      tipoAccion: AccionEnum.CREAR
    }



    const dialogo = this.util.abrirDialogoLateral(OfertaAcademicaNombreBloqueModalEdicionComponent, datos);
    dialogo.afterClosed().subscribe((result) => {
    });
  }
}
