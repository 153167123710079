export class CalificacionNoNumerica {
    /**
     * Identificador de la calificacion
     */
    calificacionNoNumericaID:   number;

    /**
     * Nombre de la calificacion
     */
    nombre:       string;

    /**
     * Valor numerico de la calificacion no numerica
     */
    valorNumerico: number;
  
    constructor(_calificacionNoNumerica: Object) {
        Object.assign(this, _calificacionNoNumerica);
    }
    
}
