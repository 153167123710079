import { StatusChangeService } from './../../../../../_services/status-change.service';
import { CampusService } from 'src/app/_services/campus.service';
import { Periodo } from 'src/app/_models/periodo';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from '../../../../../services/snack-service.service';
import { ProgramaDatosGeneralesDto } from 'src/app/_models/ProgramaDatosGeneralesDto';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Subscription } from 'rxjs';
import { AdmisionesDTO } from 'src/app/_models/AdmisionesDTO';
import { CampusProgramaDTO } from 'src/app/_models/CampusProgramaDTO';
import { StudentChangeService } from 'src/app/_services/student-change.service';
import { StudentChangeDTO, StudentChangePost } from 'src/app/_models/StudentChangeDTO';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';

@Component({
  selector: 'app-cambiar-datos-programa-modal',
  templateUrl: './cambiar-datos-programa-modal.component.html',
  styleUrls: ['./cambiar-datos-programa-modal.component.scss']
})
export class CambiarDatosProgramaModalComponent implements OnInit {

  //#region Observable
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Propiedades
  public myForm: FormGroup;
  public ProgramaDatosGenerales: ProgramaDatosGeneralesDto[];
  public ProgramaSeleccionado: ProgramaDatosGeneralesDto;
  public Periodos: Periodo[];
  public CampusProgramas: CampusProgramaDTO[];
  public StudentChangePost: StudentChangePost;
  public MateriasInscritasSinCalFinal: number;
  //#endregion

  //#region Ciclo de Vida
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdmisionesDTO,
    public dialogRef: MatDialogRef<CambiarDatosProgramaModalComponent>,
    private formBuilder: FormBuilder,
    private SnackServiceService: SnackService,
    private ProgramaAcademicoService: ProgramaAcademicoService,
    private PeriodoService: PeriodoService,
    private CampusService:CampusService,
    private studentChangeService: StudentChangeService,
    private _StatusChangeService: StatusChangeService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.GetPeriodos();
    this.GetProgramasByInstitucion();
    this.ObtenerCampusByProgramaAcademico(this.data.nombre);
    this.GetMateriasInscritasSinCalFinalByAlumnoID();
  }
  //#endregion

  //#region Metodos
  public GetPeriodos(): void{
    this.Subscription.add(
      this.PeriodoService.obtenerPeriodos().subscribe((Periodos: Periodo[]) => {
        this.Periodos = Periodos;
        //this.myForm.controls['periodoF'].setValue(this.data.periodoID);
      })
    );
  }
  public GetProgramasByInstitucion(): void{
    this.Subscription.add(
      this.ProgramaAcademicoService.GetProgramasByInstitucion().subscribe((ProgramaDatosGenerales: ApiResponse<ProgramaDatosGeneralesDto[]>) => {
        this.ProgramaDatosGenerales = ProgramaDatosGenerales.data;
        //this.myForm.controls['programaAcademicoF'].setValue(this.data.nombre);
      })
    );
  }

  public ObtenerCampusByProgramaAcademico(programaNombre: string): void{
    this.Subscription.add(
      this.CampusService.ObtenerCampusByProgramaAcademico(programaNombre).subscribe((CampusProgramaDTO: ApiResponse<CampusProgramaDTO[]>) => {
        this.CampusProgramas = CampusProgramaDTO.data;
        //this.myForm.controls['CampusProgramasF'].setValue(this.data.campusID);
      })
    );
  }

  public CambioProgramaAcademico(StudentChangePost: StudentChangePost): void{
    this.Subscription.add(
      this.studentChangeService.CambioProgramaAcademico(StudentChangePost).subscribe((SimpleResponse: SimpleResponse) => {
       if (SimpleResponse.success) {
        this.SnackServiceService.mostrarSnackBack("El cambio de programa académico se realizo con éxito");
        this.dialogRef.close(StudentChangePost.programaID);
       }else{
        this.SnackServiceService.mostrarSnackBack(SimpleResponse.message);
        console.log(SimpleResponse.message);
       }
      })
    );
  }

public GetMateriasInscritasSinCalFinalByAlumnoID(): void
{
  this.Subscription.add(
    this._StatusChangeService.GetMateriasInscritasSinCalFinalByAlumnoID(this.data.alumnoID).subscribe(
      (MateriasInscritasSinCalFinal : ApiResponse<number>) => {
        this.MateriasInscritasSinCalFinal = MateriasInscritasSinCalFinal.data;
      }
    )
  );
}
  //#endregion

  //#region Metodos Extras
  public SeleccionarPeriodo(): void
  {

  }
  public SeleccionarProgramaAcademico(): void
  {
    this.ObtenerCampusByProgramaAcademico(this.myForm.controls['programaAcademicoF'].value);
  }
  public SeleccionarCampusProgramas(): void
  {

  }
  //#endregion

  //#region  Formulario

  private buildForm(): void {
    this.myForm = this.formBuilder.group({
      periodoF: [this.data.periodoID ,[Validators.required, this.validateId]],
      programaAcademicoF: [this.data.nombre ,[Validators.required]],
      CampusProgramasF: [this.data.campusID ,[Validators.required, this.validateId]]
    }
    );

  }
  public clearForm(): void {
    this.buildForm();
  }

  public onSubmit($event: Event): void {
    if (this.myForm.valid) {

      if (this.MateriasInscritasSinCalFinal === 0) {

        this.StudentChangePost = new StudentChangePost();
        this.StudentChangePost.alumnoID = this.data.alumnoID;
        this.StudentChangePost.periodoID = this.myForm.value.periodoF;

        let programaID = this.CampusProgramas.find(x => x.campusID === this.myForm.value.CampusProgramasF).programaID;

        this.StudentChangePost.campusID = this.myForm.value.CampusProgramasF;
        this.StudentChangePost.programaID = programaID;

        //console.log(this.StudentChangePost);
        this.CambioProgramaAcademico(this.StudentChangePost);

      } else {
        this.SnackServiceService.mostrarSnackBack("No se puede realizar el cambio de programa porque actualmente el alumno está cursando materias de su programa inscrito.");
      }

    } else {
      this.myForm.markAllAsTouched();
      this.SnackServiceService.mostrarSnackBack("favor de llenar todos los campos requeridos");
    }
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }
  public CerrarModal():void {
    this.dialogRef.close();
  }
  //#endregion
}
