import { SimpleResponse } from './../../models/api/SimpleResponse.model';
import { UtileriasService } from './../../_services/utilerias.service';
import { AccionEnum } from './../../_models/accion.enum';
import { SnackService } from './../../services/snack-service.service';
import { ApiResponse } from './../../models/api/ApiRespose.model';
import { CuponService } from './../../_services/cupon.service';
import { CouponDto } from './../../_models/CouponDto';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TraductorService } from '@app/_services/traductor.service';

@Component({
  selector: 'app-modal-cupon',
  templateUrl: './modal-cupon.component.html',
  styleUrls: ['./modal-cupon.component.scss']
})
export class ModalCuponComponent implements OnInit, OnDestroy {

  //Variable subcripcion
  private subscription: Subscription = new Subscription();
  //Variable tipo catalogo cupon
  public tipoCupon: any[] = [];
  //Variables Formulario cupon
  public cuponForm: FormGroup;
  public boolCambio = false;
  //Variables modal cambios pendientes
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public dialogoRefAdver: any;

  constructor(public dialogRef: MatDialogRef<ModalCuponComponent>,
    @Inject(MAT_DIALOG_DATA) public dataCupon: CouponDto,
    private cuponService: CuponService,
    private snackService: SnackService,
    private traductorService: TraductorService,
    public utileriaService: UtileriasService,
    private dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    await this.catalogoTipoCupon().then((_) => {
      this.inicializarForm();
    });
  }

  /**
   * Metodo para traer el catalogo de tipo cupon
   * @returns 
   */
  public catalogoTipoCupon(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.subscription.add(
        this.cuponService.tipoCupon().subscribe((respuesta: ApiResponse<any[]>) => {
          if (respuesta.success) {
            this.tipoCupon = respuesta.data;
            resolve();
          } else {
            this.snackService.mostrarSnackBack("Ocurrio un error al traer el tipo de catalogo.");
            reject();
          }
        })
      )
    });
  }

  /**
   * Metodo que inicializa el formulario para editar o dar de alta un cupon
   */
  public inicializarForm(): void {
    this.cuponForm = new FormGroup({
      couponId: new FormControl(this.dataCupon?.couponId),
      code: new FormControl(this.dataCupon?.code, [Validators.required]),
      name: new FormControl(this.dataCupon?.name, [Validators.required]),
      description: new FormControl(this.dataCupon?.description),
      startDate: new FormControl(this.dataCupon?.startDate === null ? null : this.utileriaService.formatearFecha(this.dataCupon?.startDate, 'YYYY[-]MM[-]DD')),
      endDate: new FormControl(this.dataCupon?.endDate === null ? null : this.utileriaService.formatearFecha(this.dataCupon?.endDate, 'YYYY[-]MM[-]DD')),
      totalUses: new FormControl(this.dataCupon?.totalUses),
      usesPerPerson: new FormControl(this.dataCupon?.usesPerPerson),
      couponTypeId: new FormControl(this.dataCupon.tipoAccion === AccionEnum.CREAR ? 0 : this.dataCupon.couponTypeId, [Validators.required, this.validateId]),
      value: new FormControl(this.dataCupon?.value, [Validators.required]),
      status: new FormControl(this.dataCupon.tipoAccion === AccionEnum.CREAR ? true : this.dataCupon?.status),
    });
    this.cuponForm.valueChanges.subscribe(_ => {
      this.boolCambio = true;
    });
  }
  get code() { return this.cuponForm.get('code'); }
  get name() { return this.cuponForm.get('name'); }
  get description() { return this.cuponForm.get('description'); }
  get startDate() { return this.cuponForm.get('startDate'); }
  get endDate() { return this.cuponForm.get('endDate'); }
  get totalUses() { return this.cuponForm.get('totalUses'); }
  get usesPerPerson() { return this.cuponForm.get('usesPerPerson'); }
  get couponTypeId() { return this.cuponForm.get('couponTypeId'); }
  get value() { return this.cuponForm.get('value'); }
  get status() { return this.cuponForm.get('status'); }

  /**
   * Metodo para editar o crear un cupon
   */
  public enviarCupon(): void {
    let dataCupon: CouponDto = Object.assign(this.cuponForm.value);
    switch (this.dataCupon.tipoAccion) {
      case AccionEnum.CREAR:
        dataCupon.tipoAccion = AccionEnum.CREAR;
        this.subscription.add(
          this.cuponService.cuponEditarCrear(dataCupon).subscribe((response: SimpleResponse) => {
            if (response.success) {
              this.boolCambio = false;
              this.cuponService.invocarMetodoObtenerCupones();
              setTimeout(() => {
                let mensaje: string = this.traductorService.translate(response.message);
                this.snackService.mostrarSnackBack(mensaje);
              }, 1000);
            } else {
              var splitted: string[] = response.message.split("-", 3);
              if (splitted.length === 3) {
                let mensaje1: string = this.traductorService.translate(splitted[0]);
                let mensaje2: string = this.traductorService.translate(splitted[2]);
                this.snackService.mostrarSnackBack(`${mensaje1} ${splitted[1]}, ${mensaje2}`);
              } else {
                this.snackService.mostrarSnackBack(response.message);
              }
            }
          })
        );
        break;
      case AccionEnum.EDITAR:
        dataCupon.tipoAccion = AccionEnum.EDITAR;
        this.subscription.add(
          this.cuponService.cuponEditarCrear(dataCupon).subscribe((response: SimpleResponse) => {
            if (response.success) {
              this.boolCambio = false;
              this.cuponService.invocarMetodoObtenerCupones();
              setTimeout(() => {
                let mensaje: string = this.traductorService.translate(response.message);
                this.snackService.mostrarSnackBack(mensaje);
              }, 1000);
            } else {
              var splitted: string[] = response.message.split("-", 3);
              if (splitted.length === 3) {
                let mensaje1: string = this.traductorService.translate(splitted[0]);
                let mensaje2: string = this.traductorService.translate(splitted[2]);
                this.snackService.mostrarSnackBack(`${mensaje1} ${splitted[1]}, ${mensaje2}`);
              } else {
                this.snackService.mostrarSnackBack(response.message);
              }
            }
          })
        );
        break;
    }
  }

  /**
   * Metodo para validar el valor del control
   * @param control 
   * @returns 
   */
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Manda el mensaje de error
   * @param controlName 
   * @returns 
   */
  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.cuponForm.get(controlName);
    if (control.touched && control.errors != null) {
      let mensaje: string = this.traductorService.translate("_cupon.validar-tipo");
      error = mensaje;
    }
    return error;
  }

  /**
   * Metodo para validar la fechas de inicio y fin del cupon
   * @param form 
   * @param nombreControl1 
   * @param nombreControl2 
   * @returns 
   */
  public validarFechas = function (form: FormGroup, nombreControl1: string, nombreControl2: string): boolean {
    if (form.get(nombreControl1).value == "Fecha inválida" || form.get(nombreControl1).value == "" && form.get(nombreControl2).value == "Fecha inválida" || form.get(nombreControl2).value == "") {
      return false;
    }
    if (form.get(nombreControl1).value !== null && form.get(nombreControl2).value !== null) {
      return form.get(nombreControl2).value <= form.get(nombreControl1).value;
    }
    return false;
  }

  /**
   * Metodo para validar los usos totales del cupon
   * @param form 
   * @param nombreControl1 
   * @param nombreControl2 
   * @returns 
   */
  public validarUsosTotales = function (form: FormGroup, nombreControl1: string, nombreControl2: string): boolean {
    if (form.get(nombreControl1).value !== null && form.get(nombreControl2).value !== null) {
      return form.get(nombreControl1).value < form.get(nombreControl2).value;
    }
    return false;
  }

  /**
   * Metodo para cerrar el modal
   */
  public cerrarModalCupon(): void {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Modal de cambios pendientes
   * @param descartar 
   */
  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close();
    } else {
      this.dialogoRefAdver.close();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
