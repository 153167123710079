import { Component, OnInit, Inject, TemplateRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Biografia } from '../../../../_models/biografia';
import { EntidadFederativa } from '../../../../_models/entidad-federativa';
import { EstadoCivil } from '../../../../_models/estado-civil';
import { Genero } from '../../../../_models/genero';
import { GrupoSanguineo } from '../../../../_models/grupo-sanguineo';
import { Nivel } from '../../../../_models/nivel';
import { Pais } from '../../../../_models/pais';
import { PersonaRelacionada } from '../../../../_models/persona-relacionada';
import { Profesor } from '../../../../_models/profesor';
import { TipoRelacion } from '../../../../_models/tipo-relacion';
import { BiografiaService } from '../../../../_services/biografia.service';
import { DireccionService } from '../../../../_services/direccion.service';
import { EmergenciaService } from '../../../../_services/emergencia.service';
import { EstadoCivilService } from '../../../../_services/estado-civil.service';
import { GeneroService } from '../../../../_services/genero.service';
import { GrupoSanguineoService } from '../../../../_services/grupo-sanguineo.service';
import { NivelService } from '../../../../_services/nivel.service';
import { TipoRelacionService } from '../../../../_services/tipo-relacion.service';
import { EstudiosPrevios } from '../../../../_models/estudios-previos';
import { EstudiosPreviosService } from '../../../../_services/estudios-previos.service';


@Component({
  selector: 'app-form-datos-biograficos',
  templateUrl: './form-datos-biograficos.component.html',
  styleUrls: ['./form-datos-biograficos.component.scss']
})
export class FormDatosBiograficosComponent implements OnInit {
  @Input() datosUsuarioChild: any;
  @Input() usuarioId: number;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>
  public errorActualizaProfesor: string = "";
  public objValidacionCancelar: Profesor = null;
  public dialogRefAdver: any;
  public biografiaForm: FormGroup;
  public tipoAccion: number;
  public boolCambio: boolean = false;
  public NSSencontrado: boolean = false;
  public CURPencontrado: boolean = false;

  //subscripcion
  public subscripcionGenero: Subscription;
  public subscripcionEstadoCivil: Subscription;
  public subscripcionEntidadFederativa: Subscription;
  public subscripcionGrupoSanguineo: Subscription;
  public subscripcionNivel: Subscription;
  public subscripcionFamiliar: Subscription;
  //Lista
  public sexoList: Genero[] = [];
  public generoList: Genero[] = [];
  public estadoList: EstadoCivil[] = [];
  public entidadList: EntidadFederativa[] = [];
  public ciudadList: Pais[] = [];
  public paisList: Pais[] = [];
  public sanguineoList: GrupoSanguineo[] = [];
  public nivelList: Nivel[] = [];
  public familiarList: TipoRelacion[] = [];

  constructor(public dialogProfesorRef: MatDialogRef<FormDatosBiograficosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Biografia,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private nivelService: NivelService,
    private generoService: GeneroService,
    private estadoCivilService: EstadoCivilService,
    private direccionService: DireccionService,
    private grupoSanguineoService: GrupoSanguineoService,
    private tipoRelacionService: TipoRelacionService,
    private biografiaService: BiografiaService,
    private emergenciaService: EmergenciaService,
    private estudiosService: EstudiosPreviosService
  ) {
    this.inicializarForm();
    this.getTipoNivel();
    this.getTipoGenero();
    this.getTipoSanguineo();
    this.getTipoCivil();
    this.getTipoSexo();
    this.getRelacion();
    this.direccionService.obtenerPaises().subscribe(
      paisList => {
        this.paisList = paisList;
        if (this.data.pais) {
          this.onPaisSelected(this.data.pais);
        }
        this.biografiaForm.patchValue(this.data);
        this.biografiaForm.markAsUntouched();
      }
    );
  }

  public onPaisSelected(pais: number) {
    this.entidadList = [];
    if (!!pais) {
      this.subscripcionEntidadFederativa = this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        (tipoEstado: EntidadFederativa[]) => {
          this.entidadList = tipoEstado;
        }
      );
    }
  }

  //Validar si el Numero de Seguro Social ya esta asociado
  public NSSInsert(nss: string) {
    if (nss.trim().length == 11) {
      let usuarioId = this.usuarioId;
      if(!usuarioId){
        usuarioId = 0;
      }

      this.biografiaService.obtenerBiografiaByNss(nss, usuarioId).subscribe(
        biografia => {
          if (biografia == null) {
            return this.NSSencontrado = false;
          } else {
            return this.NSSencontrado = true;
          }
      });
    }
  }

  //Validar si el CURP ya esta asociado
  public CURPInsert(curp: string) {
    if (curp.trim().length == 18) {
      let usuarioId = this.usuarioId;
      if(!usuarioId){
        usuarioId = 0;
      }

      this.biografiaService.obtenerBiografiaByCurp(curp, usuarioId).subscribe(
        biografia => {
          if (biografia == null) {
            return this.CURPencontrado = false;
          } else {
            return this.CURPencontrado = true;
          }
      });
    }
  }

  ngOnInit(): void {
    this.inicializarForm();
    this.getTipoNivel();
    this.getTipoGenero();
    this.getTipoSanguineo();
    this.getTipoCivil();
    this.getTipoSexo();
    this.getRelacion();

    if (this.usuarioId) {


        this.biografiaService.obtenerBiografiaByProfesorId(this.usuarioId).subscribe(datosUsuarioBiografia => {
        this.biografiaForm.get('usuarioId').setValue(datosUsuarioBiografia.usuarioId);
        this.biografiaForm.get('legalGeneroId').setValue(datosUsuarioBiografia.legalGeneroId);
        this.biografiaForm.get('generoId').setValue(datosUsuarioBiografia.generoId);
        this.biografiaForm.get('ciudadaniaPaisId').setValue(datosUsuarioBiografia.ciudadaniaPaisId);
        this.biografiaForm.get('estadoCivilId').setValue(datosUsuarioBiografia.estadoCivilId);
        this.biografiaForm.get('ciudadNacimiento').setValue(datosUsuarioBiografia.ciudadNacimiento);
        this.biografiaForm.get('curp').setValue(datosUsuarioBiografia.curp);
        this.biografiaForm.get('nss').setValue(datosUsuarioBiografia.nss);
        this.biografiaForm.get('pais').setValue(datosUsuarioBiografia.pais);
        this.biografiaForm.get('entidadNacimiento').setValue(datosUsuarioBiografia.entidadNacimiento);

        this.biografiaForm.get('grupoSanguineoId').setValue(datosUsuarioBiografia.grupoSanguineoId);
        this.biografiaForm.get('alergias').setValue(datosUsuarioBiografia.alergias);
        this.biografiaForm.get('enfermedades').setValue(datosUsuarioBiografia.enfermedades);


        this.biografiaForm.get('nivel').setValue(datosUsuarioBiografia.nivel);
        this.biografiaForm.get('academico').setValue(datosUsuarioBiografia.academico);
        this.biografiaForm.get('promedio').setValue(datosUsuarioBiografia.promedio);

        this.direccionService.obtenerEntidadFederativa(datosUsuarioBiografia.entidadNacimiento).subscribe(datosEntidadFederativa => {

          this.biografiaForm.get('pais').setValue(datosEntidadFederativa.paisId);
          this.onPaisSelected(datosEntidadFederativa.paisId);
        });




      });

      this.emergenciaService.GetPersonaRelacionadaByProfesorId(this.usuarioId).subscribe(datosUsuarioPersonaRelacionada => {
        this.biografiaForm.get('familiar').setValue(datosUsuarioPersonaRelacionada.tipoRelacionId);
        this.biografiaForm.get('nombreCompletoFamiliar').setValue(datosUsuarioPersonaRelacionada.nombreCompleto);
        this.biografiaForm.get('correo').setValue(datosUsuarioPersonaRelacionada.correoElectronico);
        this.biografiaForm.get('telefono').setValue(datosUsuarioPersonaRelacionada.telefono);
      });

      this.estudiosService.GetEstudiosPreviosByUsuarioId(this.usuarioId).subscribe(datosUsuarioEstudios => {
        this.biografiaForm.get('nivel').setValue(datosUsuarioEstudios.nivelId);
        this.biografiaForm.get('academico').setValue(datosUsuarioEstudios.escuela);
        this.biografiaForm.get('promedio').setValue(datosUsuarioEstudios.promedio);
      });

      this.emergenciaService.GetPersonaRelacionadaEmergenciaByProfesorId(this.usuarioId).subscribe(datosUsuarioEmergencia=>{
        this.biografiaForm.get('nombreCompletoContacto').setValue(datosUsuarioEmergencia.nombreCompleto);
        this.biografiaForm.get('telefonoContacto').setValue(datosUsuarioEmergencia.telefono);
        this.biografiaForm.get('correoContacto').setValue(datosUsuarioEmergencia.correoElectronico);
        
      })

    }



  }

  public inicializarForm() {
    this.biografiaForm = new FormGroup({
      //agregar UsuarioID
      usuarioID: new FormControl(null),
      tipoDireccionId: new FormControl(1),
      paisContacto: new FormControl(null, [Validators.required]),
      entidadFederativaId: new FormControl(null, [Validators.required]),
      ciudad: new FormControl('', [Validators.required]),
      codigoPostal: new FormControl('', [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      calle: new FormControl('', [Validators.required]),
      numeroExterior: new FormControl('', [Validators.required]),
      colonia: new FormControl('', [Validators.required]),
      //Biografia
      usuarioId: new FormControl(null, [Validators.required]),
      legalGeneroId: new FormControl(null, [Validators.required]),
      generoId: new FormControl(null, [Validators.required]),
      ciudadaniaPaisId: new FormControl(null, [Validators.required]),
      estadoCivilId: new FormControl(null, [Validators.required]),
      nss: new FormControl(null, [Validators.required]),
      curp: new FormControl(null, [Validators.required]),
      // fechaNacimiento: new FormControl(null, [Validators.required]),
      pais: new FormControl(''),
      entidadNacimiento: new FormControl(''),
      ciudadNacimiento: new FormControl(null, [Validators.required]),
      // Persona Relacionada
      familiar: new FormControl(null, [Validators.required]),
      // nombreFamiliar: new FormControl(null, [Validators.required]),
      // apPaterno: new FormControl(null, [Validators.required]),
      // apMaterno: new FormControl(null, [Validators.required]),
      nombreCompletoFamiliar: new FormControl(null, [Validators.required]),
      telefono: new FormControl(null, [Validators.required]),
      correo: new FormControl(null, [Validators.required, Validators.email]),

      // Persona Relacionada Emergencia

      // nombreContacto: new FormControl(null, [Validators.required]),
      // aPaternoContacto: new FormControl(null, [Validators.required]),
      // apMaternoContacto: new FormControl(null, [Validators.required]),

      nombreCompletoContacto: new FormControl(null, [Validators.required]),
      telefonoContacto: new FormControl(null, [Validators.required]),
      correoContacto: new FormControl(null, [Validators.required, Validators.email]),
      //Biografia
      grupoSanguineoId: new FormControl(null, [Validators.required]),
      alergias: new FormControl('', [Validators.required]),
      enfermedades: new FormControl('', [Validators.required]),
      //Estudios Previos
      nivel: new FormControl(null, [Validators.required]),
      academico: new FormControl(null, [Validators.required]),
      promedio: new FormControl(null, [Validators.required])
    });

    this.biografiaForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
    })
  }

  get tipoDireccionId() { return this.biografiaForm.get('tipoDireccionId'); }
  get paisContacto() { return this.biografiaForm.get('paisContacto'); }
  get entidadFederativaId() { return this.biografiaForm.get('entidadFederativaId'); }
  get ciudad() { return this.biografiaForm.get('ciudad'); }
  get codigoPostal() { return this.biografiaForm.get('codigoPostal'); }
  get calle() { return this.biografiaForm.get('calle'); }
  get numeroExterior() { return this.biografiaForm.get('numeroExterior'); }
  get colonia() { return this.biografiaForm.get('colonia'); }

  get legalGeneroId() { return this.biografiaForm.get('legalGeneroId'); }
  get generoId() { return this.biografiaForm.get('generoId'); }
  get ciudadaniaPaisId() { return this.biografiaForm.get('ciudadaniaPaisId'); }
  get estadoCivilId() { return this.biografiaForm.get('estadoCivilId'); }
  get nss() { return this.biografiaForm.get('nss'); }
  get curp() { return this.biografiaForm.get('curp'); }
  get pais() { return this.biografiaForm.get('pais'); }
  get entidadNacimiento() { return this.biografiaForm.get('entidadNacimiento'); }
  get ciudadNacimiento() { return this.biografiaForm.get('ciudadNacimiento'); }
  //get fechaNacimiento() { return this.biografiaForm.get('fechaNacimiento');}
  // Persona Relacionada
  get familiar() { return this.biografiaForm.get('familiar'); }
  // get nombreFamiliar() { return this.biografiaForm.get('nombreFamiliar'); }
  // get apPaterno() { return this.biografiaForm.get('apPaterno'); }
  // get apMaterno() { return this.biografiaForm.get('apMaterno'); }
  get nombreCompletoFamiliar() { return this.biografiaForm.get('nombreCompletoFamiliar'); }
  get telefono() { return this.biografiaForm.get('telefono'); }
  get correo() { return this.biografiaForm.get('correo'); }

  // get nombreContacto() { return this.biografiaForm.get('nombreContacto'); }
  // get aPaternoContacto() { return this.biografiaForm.get('aPaternoContacto'); }
  // get apMaternoContacto() { return this.biografiaForm.get('apMaternoContacto'); }
  get nombreCompletoContacto() { return this.biografiaForm.get('nombreCompletoContacto'); }
  get telefonoContacto() { return this.biografiaForm.get('telefonoContacto'); }
  get correoContacto() { return this.biografiaForm.get('correoContacto'); }

  //Biografia
  get grupoSanguineoId() { return this.biografiaForm.get('grupoSanguineoId'); }
  get alergias() { return this.biografiaForm.get('alergias'); }
  get enfermedades() { return this.biografiaForm.get('enfermedades'); }

  //Otros
  get nivel() { return this.biografiaForm.get('nivel'); }
  get academico() { return this.biografiaForm.get('academico'); }
  get promedio() { return this.biografiaForm.get('promedio'); }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogProfesorRef.close(null);
    }
  }

  public enviar(): void {
    let biografia: Biografia = new Biografia();
    let persona: PersonaRelacionada = new PersonaRelacionada();

    const estudiosPrevios: EstudiosPrevios = new EstudiosPrevios();
    const personaEmergencia: PersonaRelacionada = new PersonaRelacionada();
    biografia.usuarioId = this.datosUsuarioChild.usuarioId;
    biografia.legalGeneroId = this.legalGeneroId.value;
    biografia.generoId = this.generoId.value;
    //biografia.FechaNacimiento = this.fechaNacimiento.value;
    biografia.ciudadaniaPaisId = this.ciudadaniaPaisId.value;
    biografia.estadoCivilId = this.estadoCivilId.value;
    biografia.nss = this.nss.value;
    biografia.curp = this.curp.value;
    biografia.entidadNacimiento = this.entidadNacimiento.value;
    biografia.ciudadNacimiento = this.ciudadNacimiento.value;
    biografia.alergias = this.alergias.value;
    biografia.grupoSanguineoId = this.grupoSanguineoId.value;
    biografia.enfermedades = this.enfermedades.value;

    // Datos de Persona Familiar
    // usuarioID
    persona.usuarioId = this.datosUsuarioChild.usuarioId;
    persona.tipoRelacionId = this.familiar.value;
    // persona.NombreCompleto = this.nombreContacto.value + ' ' + this.aPaternoContacto.value + ' ' + this.apMaternoContacto.value;
    persona.telefono = this.telefono.value;
    persona.correoElectronico = this.correo.value;

    // Datos de Persona Emergencia
    personaEmergencia.usuarioId = this.usuarioId;
    persona.tipoRelacionId = +'4';
    personaEmergencia.nombreCompleto = this.nombreCompletoContacto.value;
    personaEmergencia.telefono = this.telefonoContacto.value;
    personaEmergencia.correoElectronico = this.correoContacto.value;




    // Datos Estudios Previos:
    estudiosPrevios.usuarioId = this.usuarioId;
    estudiosPrevios.nivelId = this.nivel.value;
    estudiosPrevios.escuela = this.academico.value;
    estudiosPrevios.promedio = +this.promedio.value;


    this.biografiaService.modificarBiografia(biografia).subscribe(
      _biografia => {
        if (_biografia) {
          this.emergenciaService.agregarPersona(persona).subscribe(
            _persona => {
              if (_persona !== 0) {
                this.estudiosService.modificarEstudiosPrevios(estudiosPrevios).subscribe(_estudio => {
                  if (_estudio) {



                    if (personaEmergencia.nombreCompleto !== '') {
                      this.emergenciaService.modificarPersonaEmergencia(personaEmergencia).subscribe(_personaEmergencia =>{
                        if (_personaEmergencia) {
                          this.snackBar.open('Se ha guardado la información.', 'OK', { duration: 5000 });
                        }
                      })
                    } else {
                      this.snackBar.open('Se ha guardado la información.', 'OK', { duration: 5000 });
                    }
                  } else {
                    this.snackBar.
                      open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo más tarde.",
                        "OK", { duration: 5000 });
                  }
                });
              } else {
                this.snackBar.open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
              }
            })
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      });
  }


  public editar(): void {
    let biografia: Biografia = new Biografia();
    let persona: PersonaRelacionada = new PersonaRelacionada();
    const estudiosPrevios: EstudiosPrevios = new EstudiosPrevios();
    const personaEmergencia: PersonaRelacionada = new PersonaRelacionada();
    
    // Agregar usuarioID
    // Datos de Biografia

    biografia.usuarioId = this.usuarioId;
    biografia.legalGeneroId = this.legalGeneroId.value;
    biografia.generoId = this.generoId.value;
    // biografia.FechaNacimiento = this.fechaNacimiento.value;
    biografia.ciudadaniaPaisId = this.ciudadaniaPaisId.value;
    biografia.estadoCivilId = this.estadoCivilId.value;
    biografia.nss = this.nss.value;
    biografia.curp = this.curp.value;
    biografia.entidadNacimiento = this.entidadNacimiento.value;
    biografia.ciudadNacimiento = this.ciudadNacimiento.value;
    biografia.alergias = this.alergias.value;
    biografia.grupoSanguineoId = this.grupoSanguineoId.value;
    biografia.enfermedades = this.enfermedades.value;

    // Datos de Persona Familiar
    // usuarioID
    persona.usuarioId = this.usuarioId;
    persona.tipoRelacionId = this.familiar.value;
    // persona.NombreCompleto = this.nombreContacto.value + ' ' + this.aPaternoContacto.value + ' ' + this.apMaternoContacto.value;
    persona.nombreCompleto = this.nombreCompletoFamiliar.value;
    persona.telefono = this.telefono.value;
    persona.correoElectronico = this.correo.value;

    // Datos de Persona Emergencia
    personaEmergencia.usuarioId = this.usuarioId;
    persona.tipoRelacionId = +'4';
    personaEmergencia.nombreCompleto = this.nombreCompletoContacto.value;
    personaEmergencia.telefono = this.telefonoContacto.value;
    personaEmergencia.correoElectronico = this.correoContacto.value;


    // Datos Estudios Previos:
    estudiosPrevios.usuarioId = this.usuarioId;
    estudiosPrevios.nivelId = this.nivel.value;
    estudiosPrevios.escuela = this.academico.value;
    estudiosPrevios.promedio = +this.promedio.value;



    this.biografiaService.modificarBiografia(biografia).subscribe(
      _biografia => {
        if (_biografia) {
          this.emergenciaService.modificarPersona(persona).subscribe(
            _persona => {
              if (_persona) {
                this.estudiosService.modificarEstudiosPrevios(estudiosPrevios).subscribe(_estudio => {
                  if (_estudio) {

                    if (personaEmergencia.nombreCompleto !== '') {
                      this.emergenciaService.modificarPersonaEmergencia(personaEmergencia).subscribe(_personaEmergencia =>{
                        if (_personaEmergencia) {
                          this.snackBar.open('Se ha guardado la información.', 'OK', { duration: 5000 });
                        }
                      })
                    } else {
                      this.snackBar.open('Se ha guardado la información.', 'OK', { duration: 5000 });
                    }



                  } else {
                    this.snackBar.
                      open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo más tarde.",
                        "OK", { duration: 5000 });
                  }
                });
              } else {
                this.snackBar.open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
              }
            });
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      });
  }
  public Siguiente() {

  }

  //Llenar los select
  //OnDestroy para quitar la suscripción de los select

  public getTipoGenero() {
    this.generoService.obtenerGenero().subscribe(
      (tipoGenero: Genero[]) => {
        this.generoList = tipoGenero;
      }
    );
  }
  public getTipoSexo() {
    this.generoService.obtenerGenero().subscribe(
      (tipoSexo: Genero[]) => {
        this.sexoList = tipoSexo;
      }
    );
  }

  public getTipoNivel() {
    this.nivelService.getNivel().subscribe(
      (tiposNivel: Nivel[]) => {
        this.nivelList = tiposNivel;
      }
    );
  }
  public getTipoSanguineo() {
    this.grupoSanguineoService.obtenerGrupoSanguineo().subscribe(
      (tipoSanguineo: GrupoSanguineo[]) => {
        this.sanguineoList = tipoSanguineo;
      }
    );
  }

  public getTipoCivil() {
    this.estadoCivilService.obtenerEstadoCivil().subscribe(
      (tipoCivil: EstadoCivil[]) => {
        this.estadoList = tipoCivil;
      }
    );
  }

  public getRelacion() {
    this.tipoRelacionService.getTipoRelacion().subscribe(
      (tipoRelacion: TipoRelacion[]) => {
        this.familiarList = tipoRelacion;
      }
    );
  }
}
