import { TraductorService } from './../../../_services/traductor.service';
import { ColegiaturaCosto } from './../../../_models/colegiatura-costo';
import { ResumenEstadoCuentaDto } from './../../../_models/resumenEstadoCuentaDto';
import { Programa } from 'src/app/_models/programa';
import { ApiResponse } from './../../../models/api/ApiRespose.model';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { SnackService } from 'src/app/services/snack-service.service';
import { EstadoCuentaDto } from 'src/app/_models/estado-cuenta-dto';
import { ResumenDatos } from 'src/app/_models/inscripcion-costo';
import { CorreoSeguimiento, SeguimientoCartera } from 'src/app/_models/seguimiento-cartera';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { EnviarCorreoCandidatoComponent } from '../../../nuevo-ingreso/nuevo-ingreso-modal/enviar-correo-candidato/enviar-correo-candidato.component';
import { SeguimientoCarteraService } from '../../../_services/seguimiento-cartera.service';
import { UtileriasService } from '../../../_services/utilerias.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-estado-cuenta-cartera',
  templateUrl: './estado-cuenta-cartera.component.html',
  styleUrls: ['./estado-cuenta-cartera.component.scss']
})
export class EstadoCuentaCarteraComponent implements OnInit, OnDestroy {

  //DATOS INICIALES
  public subscriptionDatosEstadoCuenta: Subscription;
  public datosEstadoCuentaUsuario$: Observable<EstadoCuentaDto[]>;
  public datosEstadoCuentaUsuario: EstadoCuentaDto[];
  public subscriptionDatosSeguimiento: Subscription;
  public datosSeguimientoUsuario$: Observable<UsuarioIngreso>;
  public datosSeguimientoUsuario: UsuarioIngreso;
  public subscriptionDatosResumen: Subscription;
  public datosResumenUsuario$: Observable<ResumenDatos>;
  public datosResumenUsuario: ResumenDatos;
  public datosAlumno$: Observable<any>;
  public datosAlumno: any;
  public subscriptionDatosAlumno: Subscription;
  public subscriptionDatosPeriodo: Subscription;
  public datosPeriodoUsuario$: Observable<UsuarioIngreso[]>;
  public datosPeriodoUsuario: UsuarioIngreso[];
  public datosProgramaUsuario: any[];
  //VARIABLES PAGINADOR TABLA
  public movimientosIniciales: EstadoCuentaDto[];
  public movimientosPorVer: EstadoCuentaDto[];
  public movimientosModificados: EstadoCuentaDto[];
  public banderaPaginador: boolean = true;
  public data: any;
  //VARIABLE INFO CARGADA
  public infoCargada: boolean = false;
  //PERIODO SELECCIONADO
  public periodoIdFiltrado: number;
  public carteraTramite: boolean = false;
  //FORMULARIO
  public carteraForm: FormGroup;
  public vencimientoColegiatura: Date;

  constructor(private seguimientoService: SeguimientoCarteraService,
    private utileriaService: UtileriasService,
    private dialog: MatDialog,
    private snackService: SnackService,
    private traductorService: TraductorService) { }

  /**
   * Inicia el componente, junto con los datos del usuario y las suscripciones
   */
  ngOnInit(): void {
    //  this.datosPeriodoUsuario$ = this.seguimientoService.getDatosPeriodoUsuario$();
    // this.subscriptionDatosPeriodo = this.datosPeriodoUsuario$.subscribe((datosPeriodo: UsuarioIngreso[]) => {
    //   this.datosPeriodoUsuario = datosPeriodo;
    //   this.periodoIdFiltrado = datosPeriodo[0].periodoId;
    // });
    this.datosAlumno$ = this.seguimientoService.getDatosAlumno$();
    this.subscriptionDatosAlumno = this.datosAlumno$.subscribe((resumen: any) => {
      this.datosAlumno = resumen;
      console.log("resumen", resumen);
    });
    this.datosSeguimientoUsuario$ = this.seguimientoService.getDatosSeguimientoUsuario$();
    this.subscriptionDatosSeguimiento = this.datosSeguimientoUsuario$.subscribe((datosSeguimiento: UsuarioIngreso) => {
      this.datosSeguimientoUsuario = datosSeguimiento;
      console.log("this.datosSeguimientoUsuario", this.datosSeguimientoUsuario);
      this.obtenerProgramasByUsuario(this.datosSeguimientoUsuario.usuarioId);
      this.InicializarForm();
    });
    this.datosEstadoCuentaUsuario$ = this.seguimientoService.getDatosEstadoCuentaUsuario$();
    this.subscriptionDatosEstadoCuenta = this.datosEstadoCuentaUsuario$.subscribe((datosEstadoCuenta: EstadoCuentaDto[]) => {
      this.datosEstadoCuentaUsuario = datosEstadoCuenta;
      console.log("this.datosEstadoCuentaUsuario", this.datosEstadoCuentaUsuario);
      this.movimientosIniciales = this.datosEstadoCuentaUsuario.slice(0, 4);
      this.movimientosModificados = this.movimientosIniciales;
      this.movimientosPorVer = this.datosEstadoCuentaUsuario.slice(4);
    });
    this.datosResumenUsuario$ = this.seguimientoService.getDatosResumenUsuario$();
    this.subscriptionDatosResumen = this.datosResumenUsuario$.subscribe((resumen: ResumenDatos) => {
      this.datosResumenUsuario = resumen;
      console.log("datosResumenUsuario", resumen);
      this.infoCargada = true;
    });
  }

  /**
 * Metodo que incializa el formulario para hacer el registro de nueva cuenta
 */
  public InicializarForm() {
    this.carteraForm = new FormGroup({
      programa: new FormControl(),
      periodo: new FormControl()
    });
  }

  /**
   * Funcion para enviar un correo al usuario y avisar de un estatus de pago, se abre un modal para eso
   */
  public enviarCorreoUsuario(): void {
    var datos: CorreoSeguimiento = {
      correo: this.datosSeguimientoUsuario.correo
    };
    this.dialog.open(EnviarCorreoCandidatoComponent, { data: datos, disableClose: true });
  }

  ExcelSubscription: Subscription;
  /**
   * Funcion que obtiene todos los datos de la tabla principal y los convierte en un archivo de Excel para su descarga
   */
  public descargarExcelMovimientos(): void {
    this.ExcelSubscription = this.seguimientoService.descargarExcelMovimientos(this.datosEstadoCuentaUsuario).subscribe(
      (res: Blob) => {
        var filename = "Estado.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
          window.navigator.msSaveOrOpenBlob(res, filename);
        } else {
          var a = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          var blob = new Blob([res], { type: 'application/xlsx' });
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        }
        this.ExcelSubscription.unsubscribe();
      }
    );
  }

  /**
   * Obtiene todos los movimientos
   * @param bandera
   */
  public mostrarMovimientos(bandera: boolean) {
    if (bandera) {
      this.movimientosModificados = this.movimientosModificados.concat(this.movimientosPorVer);
      this.banderaPaginador = false;
    } else {
      this.movimientosModificados = this.movimientosModificados.slice(0, 5);
      this.banderaPaginador = true;
    }
  }

  /**
   * Metodo para traer los promgramas por el usuarioid
   * @param usuarioId 
   */
  public obtenerProgramasByUsuario(usuarioId: number): void {
    this.seguimientoService.obteneProgramasUsuario(usuarioId).subscribe((response: ApiResponse<any>) => {
      this.datosProgramaUsuario = response.data;
      const programa: number = this.datosAlumno.id_Programa;
      this.carteraForm.patchValue({
        programa: programa
      });
      this.seguimientoService.obtenePeriodosAlumno(this.datosAlumno.alumnoId).subscribe((Response: ApiResponse<any>) => {
        this.datosPeriodoUsuario = Response.data;
        const periodo: number = this.datosAlumno.id_Periodo;
        this.carteraForm.patchValue({
          periodo: periodo
        });
        this.seguimientoService.obtenerEstadoCuentaByUsuarioId(this.datosSeguimientoUsuario.usuarioId, periodo, this.datosAlumno.alumnoId).subscribe(
          (estadoCuenta: EstadoCuentaDto[]) => {
            this.seguimientoService.setDatosEstadoCuentaUsuario$(estadoCuenta);
          });
      });
    });
  }

  /**
   * Verifica el valor seleccionado de programa, si se cambia el valor entra en la funcion y cambia todos los movimientos segun ese programa
   * @param elemento
   */
  public changePrograma(elemento: any): void {
    const Alumno: any = this.datosSeguimientoUsuario.alumno.find(x => x.programaId == elemento);
    this.seguimientoService.obtenePeriodosAlumno(Alumno.alumnoId).subscribe((Response: ApiResponse<any>) => {
      this.datosPeriodoUsuario = Response.data;
      const AlumnoSeleccionadoMostrar = this.datosSeguimientoUsuario.alumno.find(x => x.alumnoId === Alumno.alumnoId);
      this.seguimientoService.setDatosMostrar$(AlumnoSeleccionadoMostrar);
      this.seguimientoService.setAlumnoIdSeleccionado$(Alumno.alumnoId);
      this.seguimientoService.obtenerEstadoCuentaByUsuarioId(this.datosSeguimientoUsuario.usuarioId, this.datosPeriodoUsuario[0].periodoId, Alumno.alumnoId).subscribe(
        (estadoCuenta: EstadoCuentaDto[]) => {
          this.seguimientoService.setDatosEstadoCuentaUsuario$(estadoCuenta);
          this.seguimientoService.obtenerResumenEstadoCuentaByUsuarioId(Alumno.alumnoId).subscribe(
            (resumen: ApiResponse<ResumenEstadoCuentaDto>) => {
              if (resumen.data.saldoVencido > 0) {
                let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-1');
                this.seguimientoService.setDatoEstatus$(mensaje);
              } else {
                let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-2');	
                this.seguimientoService.setDatoEstatus$(mensaje);
              }
              this.seguimientoService.setDatosResumenUsuario$(resumen.data);
              this.seguimientoService.obtenerFechaVencimiento(this.datosAlumno.id_Programa, this.datosAlumno.claveCampus, this.periodoIdFiltrado).subscribe(
                (datos: ColegiaturaCosto[]) => {
                  if (datos != null) {
                    this.vencimientoColegiatura = datos[0].fechaVencimiento;
                  } else {
                    this.vencimientoColegiatura = datos[0].fechaVencimiento;
                  }
                }
              );
            });
        });
    });
  }

  /**
   * Verifica el valor seleccionado de Periodo, si se cambia el valor entra en la funcion y cambia todos los movimientos segun ese periodo
   * @param elemento
   */
  public changePeriodo(elemento: number): void {
    this.seguimientoService.obtenerEstadoCuentaByUsuarioId(this.datosSeguimientoUsuario.usuarioId, elemento, this.datosAlumno.alumnoId).subscribe(
      (estadoCuenta: EstadoCuentaDto[]) => {
        this.seguimientoService.setDatosEstadoCuentaUsuario$(estadoCuenta);
      });
  }

  /**
   * Agrega un nuevo movimiento cambiando al usuario a la pantalla movimientos
   */
  public nuevoMovimiento(): void {
    this.seguimientoService.invocarMetodoNuevoMovimiento();
  }

  /**
   * Se manda a llamar cuando se cierra el componente y se desuscribe de todos los select elegidos
   */
  ngOnDestroy(): void {
    if (this.subscriptionDatosEstadoCuenta) {
      this.subscriptionDatosEstadoCuenta.unsubscribe();
    }
    if (this.subscriptionDatosSeguimiento) {
      this.subscriptionDatosSeguimiento.unsubscribe();
    }
    if (this.subscriptionDatosResumen) {
      this.subscriptionDatosResumen.unsubscribe();
    }
    if (this.subscriptionDatosPeriodo) {
      this.subscriptionDatosPeriodo.unsubscribe();
    }
    if (this.subscriptionDatosAlumno) {
      this.subscriptionDatosAlumno.unsubscribe();
    }
  }
}
