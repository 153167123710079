import { Component, OnInit, NgModule, ViewChild, TemplateRef, Inject, Input } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormBuilder, ReactiveFormsModule, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccionEnum } from '../../../../_models/accion.enum';
import { Profesor } from '../../../../_models/profesor';
import { ProfesorService } from '../../../../_services/profesor.service';
import { Nivel } from '../../../../_models/nivel';
import { NivelService } from '../../../../_services/nivel.service';
import { UtileriasService } from '../../../../_services/utilerias.service';

@Component({
  selector: 'app-formacion-academica-profesor',
  templateUrl: './formacion-academica-profesor.component.html',
  styleUrls: ['./formacion-academica-profesor.component.scss']
})
export class FormacionAcademicaProfesorComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public dialogRefAdver: any;
  public formacionForm: FormGroup;
  public boolCambio = false;
  public tipoAccionEstudios: number;
  public tipoAccionLicenciatura: any;
  public tipoAccionMaestria: any;
  public tipoAccionDoctorado: any;
  public nivelList: Nivel[] = [];
  public renderizarForm: boolean = false;
  
  constructor(public dialogProfesorRef: MatDialogRef<FormacionAcademicaProfesorComponent>,
    //@Inject(MAT_DIALOG_DATA) public data: Profesor,
    private profesorService: ProfesorService,
    private nivelService: NivelService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private util: UtileriasService,
    private fb: FormBuilder
  ) { }
  
  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.profesorService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
    this.getTipoNivel();
  }

  public renderizarFormulario() {
    if (this.datosUsuarioBusqueda.estudioPrevio.length >= 1) {
      this.tipoAccionEstudios = AccionEnum.EDITAR;
      this.tipoAccionMaestria = this.datosUsuarioBusqueda.estudioPrevio.find(x => x.nivelId === 3) === undefined ? null : this.datosUsuarioBusqueda.estudioPrevio.find(x => x.nivelId === 3);
      this.tipoAccionLicenciatura = this.datosUsuarioBusqueda.estudioPrevio.find(x => x.nivelId === 5) === undefined ? null : this.datosUsuarioBusqueda.estudioPrevio.find(x => x.nivelId === 5);
      this.tipoAccionDoctorado = this.datosUsuarioBusqueda.estudioPrevio.find(x => x.nivelId === 4) === undefined ? null : this.datosUsuarioBusqueda.estudioPrevio.find(x => x.nivelId === 4);
    } else {
      this.tipoAccionEstudios = AccionEnum.CREAR;
      this.tipoAccionMaestria = null;
      this.tipoAccionLicenciatura = null;
      this.tipoAccionDoctorado = null;
    }
    this.crearFormulario();
  }

  crearFormulario() {
    this.formacionForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),

      institucionLic: new FormControl(this.tipoAccionLicenciatura === null ? '' : this.tipoAccionLicenciatura.escuela),
      programaLic: new FormControl(this.tipoAccionLicenciatura === null ? '' : this.tipoAccionLicenciatura.programa),
      promedioLic: new FormControl(this.tipoAccionLicenciatura === null ? '' : this.tipoAccionLicenciatura.promedio, [Validators.pattern(/^[1-9]+/)]),

      institucionMaestria: new FormControl(this.tipoAccionMaestria === null ? '' : this.tipoAccionMaestria.escuela),
      programaMaestria: new FormControl(this.tipoAccionMaestria === null ? '' : this.tipoAccionMaestria.programa),
      promedioMaestria: new FormControl(this.tipoAccionMaestria === null ? '' : this.tipoAccionMaestria.promedio, [Validators.pattern(/^[1-9]+/)]),

      institucionDoctorado: new FormControl(this.tipoAccionDoctorado === null ? '' : this.tipoAccionDoctorado.escuela),
      programaDoctorado: new FormControl(this.tipoAccionDoctorado === null ? '' : this.tipoAccionDoctorado.programa),
      promedioDoctorado: new FormControl(this.tipoAccionDoctorado === null ? '' : this.tipoAccionDoctorado.promedio, [Validators.pattern(/^[1-9]+/)]),
    });
    this.renderizarForm = true;
    this.formacionForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
      this.profesorService.definirFormularioValidoNuevoProfesor(this.formacionForm.valid);
      this.profesorService.setChangesFormacionAcademica(true);
    });


  }

  get institucionLic () { return this.formacionForm.get('institucionLic');}
  get programaLic() { return this.formacionForm.get('programaLic'); }
  get promedioLic() { return this.formacionForm.get('promedioLic'); }

  get institucionMaestria() { return this.formacionForm.get('institucionMaestria'); }
  get programaMaestria() { return this.formacionForm.get('programaMaestria'); }
  get promedioMaestria() { return this.formacionForm.get('promedioMaestria'); }

  get institucionDoctorado() { return this.formacionForm.get('institucionDoctorado'); }
  get programaDoctorado() { return this.formacionForm.get('programaDoctorado'); }
  get promedioDoctorado() { return this.formacionForm.get('promedioDoctorado'); }

  public getTipoNivel() {
    this.nivelService.getNivel().subscribe(
      (tiposNivel: Nivel[]) => {
        this.nivelList = tiposNivel;
      }
    );
  }
}
