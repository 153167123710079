import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admision-alumno',
  templateUrl: './admision-alumno.component.html',
  styleUrls: ['./admision-alumno.component.scss']
})
export class AdmisionAlumnoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
