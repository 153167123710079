import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { EntidadFederativa } from 'src/app/_models/entidad-federativa';
import { EstadoCivil } from 'src/app/_models/estado-civil';
import { Genero } from 'src/app/_models/genero';
import { GrupoSanguineo } from 'src/app/_models/grupo-sanguineo';
import { Institucion } from 'src/app/_models/institucion';
import { Nivel } from 'src/app/_models/nivel';
import { Pais } from 'src/app/_models/pais';
import { RazaDto } from 'src/app/_models/RazaDto';
import { EstatusMigratorioDto } from 'src/app/_models/EstatusMigratorioDto';
import { TipoDireccion } from 'src/app/_models/tipo-direccion';
import { TipoEmpresa } from 'src/app/_models/tipo-empresa';
import { TipoPuestoEmpresa } from 'src/app/_models/tipo-puesto-empresa';
import { TipoTelefono } from 'src/app/_models/tipo-telefono';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { BiografiaService } from 'src/app/_services/biografia.service';
import { DireccionService } from 'src/app/_services/direccion.service';
import { EmpresaService } from 'src/app/_services/empresa.service';
import { EstadoCivilService } from 'src/app/_services/estado-civil.service';
import { GeneroService } from 'src/app/_services/genero.service';
import { GrupoSanguineoService } from 'src/app/_services/grupo-sanguineo.service';
import { InstitucionService } from 'src/app/_services/institucion.service';
import { NivelService } from 'src/app/_services/nivel.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { RazaService } from 'src/app/_services/raza.service';
import { EstatusMigratorioService } from 'src/app/_services/estatus-migratorio.service';
import { I18NextPipe } from 'angular-i18next';
import { DatosFamilia } from 'src/app/_models/usuario';
import { TipoAdmisionEnum } from 'src/app/_models/tipoAdmision.enum';
import { SubprocesosService } from '@app/_services/subprocesos.service';
import { TipoDocumentoService } from '@app/_services/tipo-documento.service';
import { SubProcesoDto } from '@app/_models/SubProcesoDto';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { TipoDocumentoDto } from '@app/_models/TipoDocumentoDto';
import { TipoRelacionService } from '@app/_services/tipo-relacion.service';
import { TipoRelacion } from '@app/_models/tipo-relacion';
@Component({
  selector: 'app-datos-personales-admision',
  templateUrl: './datos-personales-admision.component.html',
  styleUrls: ['./datos-personales-admision.component.scss']
})
export class DatosPersonalesAdmisionComponent implements OnInit, OnDestroy {

  //Variable renderizar componente
  public datosPersonalesRenderizar: boolean = false;
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variables Obs
  public infoUsuario$: Observable<any>;
  public infoUsuario: any = null;
  //Variable Formulario
  public datosPersonalesForm: FormGroup;
  //Variable cambio formulario
  public cambioFormDatosPersonalesAdmision: boolean = false;
  //Variables Catalogos
  public tipoDireccionList: TipoDireccion[] = [];
  public paisList: Pais[] = [];
  public paisListBiografia: Pais[] = [];
  public estadoList: EntidadFederativa[] = [];
  public estadoListBiografia: EntidadFederativa[] = [];
  public tipoTelefonoList: TipoTelefono[] = [];
  public sexoList: Genero[] = [];
  public generoList: Genero[] = [];
  public estadoCivilList: EstadoCivil[];
  public sanguineoList: GrupoSanguineo[] = [];
  public nivelList: Nivel[] = [];
  public tipoEmpresaList: TipoEmpresa[] = [];
  public tipoPuestoEmpresaList: TipoPuestoEmpresa[] = [];
  public subProcesoList: SubProcesoDto[] = [];
  public CURPencontrado: boolean = false;
  //Variables validacion menor de edad
  public usuarioMenorEdad$: Observable<any>;
  public usuarioMenorEdad: boolean;
  //Variables persona relacionada
  public tipoAccionFamiliarPapa: any;
  public tipoAccionFamiliarMama: any;
  public tipoAccionFamiliarTutor: any;
  public tipoAccionFamiliarEmergencia: any;
  public tipoAccionFamiliarObli: any;
  //Vairbales validacion curp si es mexico
  public institucion: Institucion;
  public curpNeed: boolean = false;
  public existeVariosProcesos: boolean = false;
  public familiaContactoArray: DatosFamilia[] = [];
  public razaList: RazaDto[] = [];
  public estatusMigratorioList: EstatusMigratorioDto[] = [];
  public tipoDocumentoList: TipoDocumentoDto[] = [];
  public familiarList: TipoRelacion[] = [];

  constructor(
    private admisionesService: AdmisionesService,
    private utileriasService: UtileriasService,
    public nuevoIngresoService: NuevoIngresoService,
    private direccionService: DireccionService,
    private grupoSanguineoService: GrupoSanguineoService,
    private nivelService: NivelService,
    private generoService: GeneroService,
    private estadoCivilService: EstadoCivilService,
    private empresaService: EmpresaService,
    private biografiaService: BiografiaService,
    private institucionService: InstitucionService,
    private i18nextPipe: I18NextPipe,
    private subprocesosService: SubprocesosService,
    private razaService: RazaService,
    private estatusMigratorioService: EstatusMigratorioService,
    private tipoDocumentoService: TipoDocumentoService,
    private tipoRelacionService: TipoRelacionService
  ) { }

  ngOnInit(): void {
    this.usuarioMenorEdad$ = this.nuevoIngresoService.obtenerUsuarioMenorEdad$();
    this.usuarioMenorEdad$.subscribe(menorEdad => this.usuarioMenorEdad = menorEdad);
    this.getTipoDireccion();
    this.getPais();
    this.getTipoTelefono();
    this.getTipoSexo();
    this.getTipoCivil();
    this.getTipoSanguineo();
    this.getTipoNivel();
    this.getPaisBiografia();
    this.getTipoEmpresa();
    this.getTipoPuesto();
    this.getSubProcesos();
    this.getRaza();
    this.getEstatusMigratorio();
    this.getTipoDocumento();
    this.getTipoRelacion();
  }

  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }


  /**
   * Metodo para renderizar la vista segun el step selecccionado
   */
  public RenderizarDatosPersonales() {
    this.infoUsuario$ = this.admisionesService.GetInfoUsuario$();
    this.subscription.add(
      this.infoUsuario$.subscribe(
        (info: any) => {
          this.infoUsuario = info;
          console.log("DATA QUE NECESITO", this.infoUsuario)
          if (this.infoUsuario.alumnoPersonaRelacionada.length >= 1) {
            this.tipoAccionFamiliarPapa = this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId === 1) === undefined ? null : this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId === 1);
            this.tipoAccionFamiliarMama = this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId === 2) === undefined ? null : this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId === 2);
            this.tipoAccionFamiliarTutor = this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId === 3) === undefined ? null : this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId === 3);
            this.tipoAccionFamiliarEmergencia = this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId === 4) === undefined ? null : this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId === 4);
            this.tipoAccionFamiliarObli = this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId !== 4 && x.tipoRelacionId !== 3 && x.tipoRelacionId !== 2 && x.tipoRelacionId !== 1) === undefined ? null : this.infoUsuario.alumnoPersonaRelacionada.find(x => x.tipoRelacionId !== 4 && x.tipoRelacionId !== 3 && x.tipoRelacionId !== 2 && x.tipoRelacionId !== 1);
          } else {
            this.tipoAccionFamiliarPapa = null;
            this.tipoAccionFamiliarMama = null;
            this.tipoAccionFamiliarTutor = null;
            this.tipoAccionFamiliarEmergencia = null;
            this.tipoAccionFamiliarObli = null;
          }
          this.datosPersonalesRenderizar = true;
          this.inicializarForm();

        })
    );
  }

  /**
   * Inicializar formulario datos personales
   */
  public inicializarForm() {
    this.datosPersonalesForm = new FormGroup({
      //Datos personales
      usuarioId: new FormControl(this.infoUsuario.usuarioId),
      nombre: new FormControl(this.infoUsuario.nombre, [Validators.required]),
      segundoNombre: new FormControl(this.infoUsuario.segundoNombre),
      apellidoPaterno: new FormControl(this.infoUsuario.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(this.infoUsuario.apellidoMaterno),
      fechaNacimiento: new FormControl(this.utileriasService.formatearFecha(this.infoUsuario.fechaNacimiento, 'YYYY[-]MM[-]DD'), [Validators.required]),
      //Datos de Contacto
      tipoDireccionId: new FormControl(this.infoUsuario.alumnoDireccion.length === 0 ? 0 : this.infoUsuario.alumnoDireccion[0].tipoDireccionId, [Validators.required]),
      paisId: new FormControl(this.infoUsuario.paisId === null ? 0 : this.infoUsuario.paisId, [Validators.required, this.validateId]),
      entidadFederativaId: new FormControl(this.infoUsuario.alumnoDireccion.length === 0 ? 0 : this.infoUsuario.alumnoDireccion[0].entidadFederativaId, [Validators.required, this.validateId]),
      calle: new FormControl(this.infoUsuario.alumnoDireccion.length === 0 ? '' : this.infoUsuario.alumnoDireccion[0].calle, [Validators.required]),
      ciudad: new FormControl(this.infoUsuario.alumnoDireccion.length === 0 ? '' : this.infoUsuario.alumnoDireccion[0].ciudad, [Validators.required]),
      numeroExterior: new FormControl(this.infoUsuario.alumnoDireccion.length === 0 ? '' : this.infoUsuario.alumnoDireccion[0].numeroExterior, [Validators.required]),
      numeroInterior: new FormControl(this.infoUsuario.alumnoDireccion.length === 0 ? '' : this.infoUsuario.alumnoDireccion[0].numeroInterior),
      colonia: new FormControl(this.infoUsuario.alumnoDireccion.length === 0 ? '' : this.infoUsuario.alumnoDireccion[0].colonia, [Validators.required]),
      codigoPostal: new FormControl(this.infoUsuario.alumnoDireccion.length === 0 ? '' : this.infoUsuario.alumnoDireccion[0].codigoPostal, [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      // numeroMovil: new FormControl(this.infoUsuario.alumnoTelefono.length === 0 ? '' : this.infoUsuario.alumnoTelefono[0].numero, [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),

      numero: new FormControl(this.infoUsuario.alumnoTelefono.length === 0 ? '' : this.infoUsuario.alumnoTelefono[0].numero, [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      tipoTelefonoId: new FormControl(this.infoUsuario.alumnoTelefono.length === 0 ? '' : this.infoUsuario.alumnoTelefono[0].tipoTelefonoId, [Validators.required, this.validateId]),
      extension: new FormControl(this.infoUsuario.alumnoTelefono.length === 0 ? '' : this.infoUsuario.alumnoTelefono[0].extension),

      otroNumero: new FormControl(this.infoUsuario.alumnoTelefono.length > 1 ? this.infoUsuario.alumnoTelefono[1].numero : '', [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      otroTipoTelefonoId: new FormControl(this.infoUsuario.alumnoTelefono.length > 1 ? this.infoUsuario.alumnoTelefono[1].tipoTelefonoId : '', [Validators.required, this.validateId]),
      otroExtension: new FormControl(this.infoUsuario.alumnoTelefono.length > 1 ? this.infoUsuario.alumnoTelefono[1].extension : ''),
      correo: new FormControl(this.infoUsuario.correo, [Validators.required, Validators.email]),
      //Datos Biograficos
      legalGeneroId: new FormControl(this.infoUsuario.alumnoBiografia.legalGeneroId === null ? 0 : this.infoUsuario.alumnoBiografia.legalGeneroId),
      generoId: new FormControl(this.infoUsuario.alumnoBiografia.generoId === null ? 0 : this.infoUsuario.alumnoBiografia.generoId),
      ciudadania: new FormControl(this.infoUsuario.alumnoBiografia.ciudadaniaPaisId === null ? 0 : this.infoUsuario.alumnoBiografia.ciudadaniaPaisId),
      estadoCivilId: new FormControl(this.infoUsuario.alumnoBiografia.estadoCivilId === null ? 0 : this.infoUsuario.alumnoBiografia.estadoCivilId),
      paisNacimiento: new FormControl(this.infoUsuario.alumnoBiografia.ciudadaniaPaisId === null ? 0 : this.infoUsuario.alumnoBiografia.ciudadaniaPaisId),
      entidadNacimiento: new FormControl(this.infoUsuario.alumnoBiografia.entidadNacimiento === null ? 0 : this.infoUsuario.alumnoBiografia.entidadNacimiento),
      ciudadNacimiento: new FormControl(this.infoUsuario.alumnoBiografia.ciudadNacimiento === null ? '' : this.infoUsuario.alumnoBiografia.ciudadNacimiento),
      raza: new FormControl(this.infoUsuario.alumnoBiografia.razaId === null ? 0 : this.infoUsuario.alumnoBiografia.razaId),
      estatusMigratorio: new FormControl(this.infoUsuario.alumnoBiografia.estatusMigratorioId === null ? 0 : this.infoUsuario.alumnoBiografia.estatusMigratorioId),
      tipoDocumento: new FormControl(this.infoUsuario.alumnoBiografia.tipoDocumentoId === null ? 0 : this.infoUsuario.alumnoBiografia.tipoDocumentoId, [Validators.required, this.validateId]),
      curp: new FormControl(this.infoUsuario.alumnoBiografia.curp === null ? '' : this.infoUsuario.alumnoBiografia.curp),
      paisDocumento: new FormControl(this.infoUsuario.alumnoBiografia.paisDocumentoId === null ? 0 : this.infoUsuario.alumnoBiografia.paisDocumentoId, [Validators.required, this.validateId]),
      hasCriminalRecord: new FormControl(this.infoUsuario.alumnoBiografia.hasCriminalRecord === null ? null : this.infoUsuario.alumnoBiografia.hasCriminalRecord, [Validators.required]),
      hasAcademicDisciplinaryRecord: new FormControl(this.infoUsuario.alumnoBiografia.hasAcademicDisciplinaryRecord === null ? null : this.infoUsuario.alumnoBiografia.hasAcademicDisciplinaryRecord, [Validators.required]),
      //Datos Medicos
      grupoSanguineoId: new FormControl(this.infoUsuario.alumnoBiografia.grupoSanguineoId === null ? 0 : this.infoUsuario.alumnoBiografia.grupoSanguineoId),
      alergias: new FormControl(this.infoUsuario.alumnoBiografia.alergias === null ? '' : this.infoUsuario.alumnoBiografia.alergias),
      enfermedades: new FormControl(this.infoUsuario.alumnoBiografia.enfermedades === null ? '' : this.infoUsuario.alumnoBiografia.enfermedades),
      //discapacidad: new FormControl(false),

      //Datos Familiares
      nombreCompletoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.nombreCompleto),
      telefonoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.correoElectronico, [Validators.email]),
      nombreCompletoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.nombreCompleto),
      telefonoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.correoElectronico, [Validators.email]),
      nombreCompletoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.nombreCompleto),
      telefonoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.correoElectronico, [Validators.email]),
      nombreContacto: new FormControl(this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.nombreCompleto),
      telefonoContacto: new FormControl(this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correoContacto: new FormControl(this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.correoElectronico, [Validators.email]),

      tipoRelacion: new FormControl(this.tipoAccionFamiliarObli == null ? 0 : this.tipoAccionFamiliarObli.tipoRelacionId),
      nombreContactoObli: new FormControl(this.tipoAccionFamiliarObli == null ? '' : this.tipoAccionFamiliarObli.nombreCompleto),
      telefonoContactoObli: new FormControl(this.tipoAccionFamiliarObli == null ? '' : this.tipoAccionFamiliarObli.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correoContactoObli: new FormControl(this.tipoAccionFamiliarObli == null ? '' : this.tipoAccionFamiliarObli.correoElectronico, [Validators.email]),
      //Estudios Previos
      nivel: new FormControl(this.infoUsuario.alumnoEstudioPrevio.length === 0 ? 0 : this.infoUsuario.alumnoEstudioPrevio[0].nivelId),
      academico: new FormControl(this.infoUsuario.alumnoEstudioPrevio.length === 0 ? '' : this.infoUsuario.alumnoEstudioPrevio[0].escuela),
      promedio: new FormControl(this.infoUsuario.alumnoEstudioPrevio.length === 0 ? '' : this.infoUsuario.alumnoEstudioPrevio[0].promedio, [Validators.pattern(/^[1-9]+/)]),
      tipoCandidato: new FormControl(this.infoUsuario.alumnoRegistrado[0].tipoCandidato === 0 ? null : this.infoUsuario.alumnoRegistrado[0].tipoCandidato, [Validators.required]),
      //Datos de Facturacion
      rfc: new FormControl(this.infoUsuario.alumnoDatoFiscal.rfc === null ? '' : this.infoUsuario.alumnoDatoFiscal.rfc),
      razonSocial: new FormControl(this.infoUsuario.alumnoDatoFiscal.razonSocial === null ? '' : this.infoUsuario.alumnoDatoFiscal.razonSocial),
      tipoDireccionFacturacionId: new FormControl(this.infoUsuario.alumnoDatoFiscal.tipoDireccionId === null ? 0 : this.infoUsuario.alumnoDatoFiscal.tipoDireccionId),
      paisFacturacionId: new FormControl(this.infoUsuario.alumnoBiografia.ciudadaniaPaisId === null ? 0 : this.infoUsuario.alumnoBiografia.ciudadaniaPaisId),
      entidadFederativaFacturacionId: new FormControl(this.infoUsuario.alumnoDatoFiscal.entidadFederativaId === null ? 0 : this.infoUsuario.alumnoDatoFiscal.entidadFederativaId),
      calleFacturacion: new FormControl(this.infoUsuario.alumnoDatoFiscal.calle === null ? '' : this.infoUsuario.alumnoDatoFiscal.calle),
      ciudadFacturacion: new FormControl(this.infoUsuario.alumnoDatoFiscal.ciudad === null ? '' : this.infoUsuario.alumnoDatoFiscal.ciudad),
      numeroExteriorFacturacion: new FormControl(this.infoUsuario.alumnoDatoFiscal.numeroExterior === null ? '' : this.infoUsuario.alumnoDatoFiscal.numeroExterior),
      numeroInteriorFacturacion: new FormControl(this.infoUsuario.alumnoDatoFiscal.numeroInterior === null ? '' : this.infoUsuario.alumnoDatoFiscal.numeroInterior),
      coloniaFacturacion: new FormControl(this.infoUsuario.alumnoDatoFiscal.colonia === null ? '' : this.infoUsuario.alumnoDatoFiscal.colonia),
      codigoPostalFacturacion: new FormControl(this.infoUsuario.alumnoDatoFiscal.codigoPostal === null ? '' : this.infoUsuario.alumnoDatoFiscal.codigoPostal, [Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      //Ocupacion
      tipoEmpresaId: new FormControl(this.infoUsuario.alumnoOcupacion.tipoEmpresaId === null ? 0 : this.infoUsuario.alumnoOcupacion.tipoEmpresaId),
      tipoPuestoEmpresaId: new FormControl(this.infoUsuario.alumnoOcupacion.tipoPuestoEmpresaId === null ? 0 : this.infoUsuario.alumnoOcupacion.tipoPuestoEmpresaId)
    });
    this.datosPersonalesForm.valueChanges.subscribe(() => {
      this.cambioFormDatosPersonalesAdmision = true;
    });
    this.obtenerInstitucion();
    let menorEdad = this.calcularEdad(this.infoUsuario.fechaNacimiento);
    this.nuevoIngresoService.definirUsuarioMenorEdad(menorEdad);
    if (menorEdad) {
      this.nombreCompletoTutor.setValidators([Validators.required]);
      this.telefonoTutor.setValidators([Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]);
      this.correoElectronicoTutor.setValidators([Validators.required, Validators.email]);
    } else {
      this.nombreCompletoTutor.setValidators(null);
      this.telefonoTutor.setValidators(null);
      this.correoElectronicoTutor.setValidators(null);
    }
    this.nombreCompletoTutor.updateValueAndValidity();
    this.telefonoTutor.updateValueAndValidity();
    this.correoElectronicoTutor.updateValueAndValidity();
    this.cambioFormDatosPersonalesAdmision = false;

    this.fechaNacimiento.valueChanges.subscribe(checked => {
      let menorEdad = this.calcularEdad(checked);
      if (menorEdad) {
        this.nombreCompletoTutor.setValidators([Validators.required]);
        this.telefonoTutor.setValidators([Validators.required]);
        this.correoElectronicoTutor.setValidators([Validators.required, Validators.email]);
      } else {
        this.nombreCompletoTutor.setValidators(null);
        this.telefonoTutor.setValidators(null);
        this.correoElectronicoTutor.setValidators(null);
      }
      this.nombreCompletoTutor.updateValueAndValidity();
      this.telefonoTutor.updateValueAndValidity();
      this.correoElectronicoTutor.updateValueAndValidity();
    });

    if (this.infoUsuario.alumnoRegistrado[0].programa.tipoAdmisionId === TipoAdmisionEnum.MANUAL) {
      this.legalGeneroId.setValidators([Validators.required, this.validateId]);
      this.ciudadania.setValidators([Validators.required, this.validateId]);
      this.paisNacimiento.setValidators([Validators.required, this.validateId]);
      this.entidadNacimiento.setValidators([Validators.required, this.validateId]);
      this.ciudadNacimiento.setValidators([Validators.required]);
      this.nivel.setValidators([Validators.required, this.validateId]);
      this.academico.setValidators([Validators.required]);
      this.promedio.setValidators([Validators.required]);
    } else {
      this.legalGeneroId.setValidators(null);
      this.ciudadania.setValidators(null);
      this.paisNacimiento.setValidators(null);
      this.entidadNacimiento.setValidators(null);
      this.ciudadNacimiento.setValidators(null);
      this.nivel.setValidators(null);
      this.academico.setValidators(null);
      this.promedio.setValidators(null);
    }
    this.legalGeneroId.updateValueAndValidity();
    this.ciudadania.updateValueAndValidity();
    this.paisNacimiento.updateValueAndValidity();
    this.entidadNacimiento.updateValueAndValidity();
    this.ciudadNacimiento.updateValueAndValidity();
    this.nivel.updateValueAndValidity();
    this.academico.updateValueAndValidity();
    this.promedio.updateValueAndValidity();
    this.cambioFormDatosPersonalesAdmision = false;

    this.onPaisSelected(this.datosPersonalesForm.controls['paisId'].value);
    this.onPaisSelectedBiografia(this.datosPersonalesForm.controls['paisNacimiento'].value);

    // this.datosPersonalesForm.valueChanges.subscribe(val => {
    //   this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.datosPersonalesForm.valid);
    //   this.nuevoIngresoService.definirCambioNombreForms("Datos Personales");
    //   this.nuevoIngresoService.setChangesPersonalData(true);
    // });
  }

  //Datos personales
  get nombre() { return this.datosPersonalesForm.get('nombre'); }
  get segundoNombre() { return this.datosPersonalesForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.datosPersonalesForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.datosPersonalesForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.datosPersonalesForm.get('fechaNacimiento'); }
  //Datos de Contacto
  get tipoDireccionId() { return this.datosPersonalesForm.get('tipoDireccionId'); }
  get paisId() { return this.datosPersonalesForm.get('paisId'); }
  get entidadFederativaId() { return this.datosPersonalesForm.get('entidadFederativaId'); }
  get tipoTelefonoId() { return this.datosPersonalesForm.get('tipoTelefonoId'); }
  get ciudad() { return this.datosPersonalesForm.get('ciudad'); }
  get calle() { return this.datosPersonalesForm.get('calle'); }
  get numeroExterior() { return this.datosPersonalesForm.get('numeroExterior'); }
  get numeroInterior() { return this.datosPersonalesForm.get('numeroInterior'); }
  get colonia() { return this.datosPersonalesForm.get('colonia'); }
  get codigoPostal() { return this.datosPersonalesForm.get('codigoPostal'); }
  get numero() { return this.datosPersonalesForm.get('numero'); }
  get extension() { return this.datosPersonalesForm.get('extension'); }
  get otroTipoTelefonoId() { return this.datosPersonalesForm.get('otroTipoTelefonoId'); }
  get otroNumero() { return this.datosPersonalesForm.get('otroNumero'); }
  get otroExtension() { return this.datosPersonalesForm.get('otroExtension'); }
  get correo() { return this.datosPersonalesForm.get('correo'); }
  //Datos Biograficos
  get legalGeneroId() { return this.datosPersonalesForm.get('legalGeneroId'); }
  get ciudadania() { return this.datosPersonalesForm.get('ciudadania'); }
  get curp() { return this.datosPersonalesForm.get('curp'); }
  get paisNacimiento() { return this.datosPersonalesForm.get('paisNacimiento'); }
  get entidadNacimiento() { return this.datosPersonalesForm.get('entidadNacimiento'); }
  get ciudadNacimiento() { return this.datosPersonalesForm.get('ciudadNacimiento'); }
  get tipoDocumento() { return this.datosPersonalesForm.get('tipoDocumento'); }
  get estatusMigratorio() { return this.datosPersonalesForm.get('estatusMigratorio'); }
  get raza() { return this.datosPersonalesForm.get('raza'); }
  get paisDocumento() { return this.datosPersonalesForm.get('paisDocumento'); }
  get hasCriminalRecord() { return this.datosPersonalesForm.get('hasCriminalRecord'); }
  get hasAcademicDisciplinaryRecord() { return this.datosPersonalesForm.get('hasAcademicDisciplinaryRecord'); }
  //Datos Medicos
  get grupoSanguineoId() { return this.datosPersonalesForm.get('grupoSanguineoId'); }
  get alergias() { return this.datosPersonalesForm.get('alergias'); }
  get enfermedades() { return this.datosPersonalesForm.get('enfermedades'); }
  get discapacidad() { return this.datosPersonalesForm.get('discapacidad'); }
  //Datos Familiares
  get nombreCompletoMadre() { return this.datosPersonalesForm.get('nombreCompletoMadre'); }
  get telefonoMadre() { return this.datosPersonalesForm.get('telefonoMadre'); }
  get correoElectronicoMadre() { return this.datosPersonalesForm.get('correoElectronicoMadre'); }
  get nombreCompletoPadre() { return this.datosPersonalesForm.get('nombreCompletoPadre'); }
  get telefonoPadre() { return this.datosPersonalesForm.get('telefonoPadre'); }
  get correoElectronicoPadre() { return this.datosPersonalesForm.get('correoElectronicoPadre'); }
  get nombreCompletoTutor() { return this.datosPersonalesForm.get('nombreCompletoTutor'); }
  get telefonoTutor() { return this.datosPersonalesForm.get('telefonoTutor'); }
  get correoElectronicoTutor() { return this.datosPersonalesForm.get('correoElectronicoTutor'); }


  get tipoRelacion() { return this.datosPersonalesForm.get('tipoRelacion'); }
  get nombreContactoObli() { return this.datosPersonalesForm.get('nombreContactoObli'); }
  get telefonoContactoObli() { return this.datosPersonalesForm.get('telefonoContactoObli'); }
  get correoContactoObli() { return this.datosPersonalesForm.get('correoContactoObli'); }

  get nombreContacto() { return this.datosPersonalesForm.get('nombreContacto'); }
  get telefonoContacto() { return this.datosPersonalesForm.get('telefonoContacto'); }
  get correoContacto() { return this.datosPersonalesForm.get('correoContacto'); }
  //Estudios Previos
  get nivel() { return this.datosPersonalesForm.get('nivel'); }
  get academico() { return this.datosPersonalesForm.get('academico'); }
  get promedio() { return this.datosPersonalesForm.get('promedio'); }
  get tipoCandidato() { return this.datosPersonalesForm.get('tipoCandidato'); }
  //Datos Facturacion
  get tipoDireccionFacturacionId() { return this.datosPersonalesForm.get('tipoDireccionFacturacionId'); }
  get paisFacturacionId() { return this.datosPersonalesForm.get('paisFacturacionId'); }
  get entidadFederativaFacturacionId() { return this.datosPersonalesForm.get('entidadFederativaFacturacionId'); }
  get ciudadFacturacion() { return this.datosPersonalesForm.get('ciudadFacturacion'); }
  get calleFacturacion() { return this.datosPersonalesForm.get('calleFacturacion'); }
  get numeroExteriorFacturacion() { return this.datosPersonalesForm.get('numeroExteriorFacturacion'); }
  get numeroInteriorFacturacion() { return this.datosPersonalesForm.get('numeroInteriorFacturacion'); }
  get coloniaFacturacion() { return this.datosPersonalesForm.get('coloniaFacturacion'); }
  get codigoPostalFacturacion() { return this.datosPersonalesForm.get('codigoPostalFacturacion'); }
  //Datos Fiscales
  get tipoEmpresa() { return this.datosPersonalesForm.get('tipoEmpresaId'); }
  get puesto() { return this.datosPersonalesForm.get('tipoPuestoEmpresaId'); }


  public paisSelect(paisId: number, field: string) {
    if (field === "paisDocumento") {
      let campoCiudadania = this.datosPersonalesForm.get('ciudadania')?.value;
      if (campoCiudadania === 0) {
        this.datosPersonalesForm.patchValue({
          ciudadania: paisId
        });
      }
    }
    else {
      let campoPaisDocumento = this.datosPersonalesForm.get('paisDocumento')?.value;
      if (campoPaisDocumento === 0) {
        this.datosPersonalesForm.patchValue({
          paisDocumento: paisId
        });
      }
    }
  }

  /**
   * Metodo para agregar array de informacion de contacto de emergencia
   * en informacion medica
   */
  public agregarContactoMadreArray() {
    if (this.nombreCompletoMadre.value == "") {
      this.familiaContactoArray.forEach((item, index) => {
        if (item.TipoRelacionId === 2) this.familiaContactoArray.splice(index, 1);
      });
    } else {
      let objectMadre = this.familiaContactoArray.find(e => e.TipoRelacionId === 2);
      if (!(objectMadre == undefined)) {
        this.familiaContactoArray.find(e => e.TipoRelacionId === 2).NombreCompleto = this.nombreCompletoMadre.value;
        this.familiaContactoArray.find(e => e.TipoRelacionId === 2).Telefono = this.telefonoMadre.value;
        this.familiaContactoArray.find(e => e.TipoRelacionId === 2).CorreoElectronico = this.correoElectronicoMadre.value;
      } else {
        let madre: DatosFamilia = {
          NombreCompleto: this.nombreCompletoMadre.value,
          TipoRelacionId: 2,
          Telefono: this.telefonoMadre.value,
          CorreoElectronico: this.correoElectronicoMadre.value,
          UsuarioId: 0
        }
        this.familiaContactoArray.push(madre);
      }
    }


  }




  /**
   * Metodo para hacer el calculo y determinar si es mayor o menor de edad
   * @param fecha 
   * @returns 
   */
  public calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    if (edad < 18) {
      return true
    } else {
      return false;
    }
  }

  /**
   * Metodo para validad campos diferentes a 0
   * @param control 
   * @returns 
   */
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Metodos para validar campo vacio
   * @param controlName 
   * @returns 
   */
  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido.";
    }
    return error;
  }

  /**
   * Metodos para validar campo vacio
   * @param controlName 
   * @returns 
   */
  public getErrorFormSexo(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Sexo Legal es requerido.";
    }
    return error;
  }

  /**
   * Metodos para validar campo vacio
   * @param controlName 
   * @returns 
   */
  public getErrorFormGenero(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Género es requerido.";
    }
    return error;
  }

  /**
   * Metodos para validar campo vacio
   * @param controlName 
   * @returns 
   */
  public getErrorFormCiudad(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Ciudadanía es requerido.";
    }
    return error;
  }

  /**
   * Metodos para validar campo vacio
   * @param controlName 
   * @returns 
   */
  public getErrorFormCivil(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Estado Civil es requerido.";
    }
    return error;
  }

  /**
   * Metodos para validar campo vacio
   * @param controlName 
   * @returns 
   */
  public getErrorFormPais(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "País es requerido.";
    }
    return error;
  }

  /**
 * Metodos para validar campo vacio
 * @param controlName 
 * @returns 
 */
  public getErrorFormTipoDocumento(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Tipo de documento es requerido.";
    }
    return error;
  }

  /**
   * Metodos para validar campo vacio
   * @param controlName 
   * @returns 
   */
  public getErrorFormEstado(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Estado es requerido.";
    }
    return error;
  }

  /**
   * Metodo para obtener catalogo de tipodireccion
   */
  public getTipoDireccion() {
    this.subscription.add(
      this.direccionService.obtenerTipoDireccion().subscribe(
        (tipoDireccion: TipoDireccion[]) => {
          this.tipoDireccionList = tipoDireccion;
        })
    );
  }

  /**
   * Metodo para obtener catalogo pais
   */
  public getPais() {
    this.subscription.add(
      this.direccionService.obtenerPaises().subscribe(
        (pais: Pais[]) => {
          this.paisList = pais;
        })
    );
  }

  /**
   * Metodo que se ejecuta cuando un pais es seleccionado
   * @param pais 
   */
  public onPaisSelected(pais: number) {
    this.estadoList = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estadoList = estados;
        })
    }
  }

  /**
   * Metodo para traer catalogo tipo telefono
   */
  public getTipoTelefono() {
    this.subscription.add(
      this.direccionService.obtenerTipoTelefono().subscribe(
        (tipoTelefono: TipoTelefono[]) => {
          this.tipoTelefonoList = tipoTelefono;
        })
    );
  }

  /**
   * Metodo para traer catalogo tipo sexo
   */
  public getTipoSexo() {
    this.subscription.add(
      this.generoService.obtenerGenero().subscribe(
        (tipoSexo: Genero[]) => {
          this.sexoList = tipoSexo;
        })
    );
  }

  /**
   * Metodo para traer catalogo tiposanguineo
   */
  public getTipoSanguineo() {
    this.subscription.add(
      this.grupoSanguineoService.obtenerGrupoSanguineo().subscribe(
        (tipoSanguineo: GrupoSanguineo[]) => {
          this.sanguineoList = tipoSanguineo;
        })
    );
  }

  /**
   * Metodo para traer catalogo tipo civil
   */
  public getTipoCivil() {
    this.subscription.add(
      this.estadoCivilService.obtenerEstadoCivil().subscribe(
        (tipoCivil: EstadoCivil[]) => {
          this.estadoCivilList = tipoCivil;
        })
    );
  }

  /**
   * Metodo para traer catalogo de pais
   */
  public getPaisBiografia() {
    this.subscription.add(
      this.direccionService.obtenerPaises().subscribe(
        (pais: Pais[]) => {
          this.paisListBiografia = pais;
        })
    );
  }

  /**
   * Metodo para traer entidad federativa cuando se seleccione un pais
   * @param pais 
   */
  public onPaisSelectedBiografia(pais: number) {
    this.estadoListBiografia = [];
    if (!!pais) {
      this.subscription.add(
        this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
          estados => {
            this.estadoListBiografia = estados;
          })
      );
    }
  }

  /**
   * Metodo para traer catalogo tipo nivel
   */
  public getTipoNivel() {
    this.subscription.add(
      this.nivelService.getNivel().subscribe(
        (tiposNivel: Nivel[]) => {
          this.nivelList = tiposNivel;
        })
    );
  }

  /**
   * Metodo para traer catalogo tipo empresa
   */
  public getTipoEmpresa() {
    this.subscription.add(
      this.empresaService.obtenerTipoEmpresa().subscribe(
        (tipoEmpresa: TipoEmpresa[]) => {
          this.tipoEmpresaList = tipoEmpresa;
        })
    );
  }

  /**
   * Metodo para traer catalogo tipo puesto
   */
  public getTipoPuesto() {
    this.subscription.add(
      this.empresaService.obtenerTipoPuestoEmpresa().subscribe(
        (tipoPuestoEmpresa: TipoPuestoEmpresa[]) => {
          this.tipoPuestoEmpresaList = tipoPuestoEmpresa;
        })
    );
  }

  /**
   * Metodo para traer catalogo de subprocesos por institucion
   */
  public getSubProcesos() {
    this.subscription.add(
      this.subprocesosService.subProcesoInstitucion("NUEVO").subscribe(
        (response: ApiResponse<SubProcesoDto[]>) => {
          this.subProcesoList = response.data;
          if (this.subProcesoList.length <= 1) {
            this.existeVariosProcesos = false;
          } else {
            this.existeVariosProcesos = true;
          }
        })
    );
  }

  /**
 * Metodo para traer catalogo de raza
 */
  public getRaza() {
    this.subscription.add(
      this.razaService.obtenerRaza().subscribe(
        (response: ApiResponse<RazaDto[]>) => {
          this.razaList = response.data;
        })
    );
  }

  /**
* Metodo para traer catalogo destatusmigratorio
*/
  public getEstatusMigratorio() {
    this.subscription.add(
      this.estatusMigratorioService.obtenerEstatusMigratorio().subscribe(
        (response: ApiResponse<EstatusMigratorioDto[]>) => {
          this.estatusMigratorioList = response.data;
        })
    );
  }

  /**
* Metodo para traer catalogo tipo documento
*/
  public getTipoDocumento() {
    this.subscription.add(
      this.tipoDocumentoService.obtenerTipoDocumento().subscribe(
        (response: ApiResponse<TipoDocumentoDto[]>) => {
          this.tipoDocumentoList = response.data;
        })
    );
  }

  public getTipoRelacion() {
    this.tipoRelacionService.getTipoRelacion().subscribe(
      (tipoRelacion: TipoRelacion[]) => {
        this.familiarList = tipoRelacion.filter(x => x.clave !== 'PA' && x.clave !== 'MA' && x.clave !== 'TU' && x.clave !== 'EM');
      }
    );
  }

  /**
   * Metodo para encontrar un curp duplicado o ya existente
   * @param curp 
   */
  public CURPInsert(curp: string) {
    if (curp.trim().length == 18) {
      let usuarioId = this.infoUsuario.usuarioId;
      if (!usuarioId) {
        usuarioId = 0;
      }
      this.subscription.add(
        this.biografiaService.obtenerBiografiaByCurp(curp, usuarioId).subscribe(
          biografia => {
            if (biografia == null) {
              return this.CURPencontrado = false;
            } else {
              return this.CURPencontrado = true;
            }
          })
      );
    }
  }

  /**
   * Metodo para saber si el curp debe ser obligatorio
   */
  public obtenerInstitucion() {
    this.institucionService.obtenerInstitucion().subscribe(
      institucion => {
        this.institucion = institucion;
        if (institucion.pais === 484) {
          if (this.infoUsuario.alumnoRegistrado[0].programa.tipoAdmisionId === TipoAdmisionEnum.MANUAL) {
            this.curpNeed = true;
            this.curp.setValidators([Validators.required]);
          } else {
            this.curpNeed = false;
            this.curp.setValidators(null);
          }
          this.curp.updateValueAndValidity();
          this.cambioFormDatosPersonalesAdmision = false;
        } else {
          this.curpNeed = false;
          this.curp.setValidators(null);
          this.curp.updateValueAndValidity();
          this.cambioFormDatosPersonalesAdmision = false;
        }
      }
    );
  }

  /**
   * Metodo que se activa cuando se cierra el componente
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}