import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, Subject } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import { AreaMateria } from '../_models/area-materia';

@Injectable({
    providedIn: 'root'
})
export class AreaMateriaService {

    invocarObtenerAreaMateria = new EventEmitter();
    invMetodoObtenerAreaMateriaSubscription: Subscription;

    constructor(private httpClient: HttpClient) { }

    public agregarAreaMateria(departamento: AreaMateria): Observable<number> {
        return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/AreaMateria`, departamento);
    }

    public modificarAreaMateria(departamento: AreaMateria): Observable<boolean> {
        return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/AreaMateria`, departamento);
    }

    public eliminarAreaMateriaId(areaMateriaId: string): Observable<any> {
        return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/AreaMateria?areaMateriaId=` + areaMateriaId);
    }

    public downloadExcelAreaMateria(areaMateria: any) {
        return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/AreaMateria/DownloadExcel`, JSON.stringify(areaMateria),
            {
                responseType: "blob",
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    }

    invocarMetodoObtenerAreaMateria() {
        this.invocarObtenerAreaMateria.emit();
    }
}
