import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { AccionEnum } from '../../../../_models/accion.enum';
import { Campus } from '../../../../_models/campus';
import { Empresa } from '../../../../_models/empresa';
import { Periodo } from '../../../../_models/periodo';
import { Programa } from '../../../../_models/programa';
import { TipoApoyoFinanciero } from '../../../../_models/tipo-apoyo-financiero';
import { CampusService } from '../../../../_services/campus.service';
import { EmpresaService } from '../../../../_services/empresa.service';
import { NuevoIngresoService } from '../../../../_services/nuevo-ingreso.service';
import { PeriodoService } from '../../../../_services/periodo.service';
import { SolicitudApoyoFinancieroService } from '../../../../_services/solicitud-apoyo-financiero.service';
import { TipoApoyoFinancieroService } from '../../../../_services/tipo-apoyo-financiero.service';
import { CampusProgramaDTO } from 'src/app/_models/CampusProgramaDTO';

@Component({
  selector: 'app-nuevo-solicitante',
  templateUrl: './nuevo-solicitante.component.html',
  styleUrls: ['./nuevo-solicitante.component.scss']
})
export class NuevoSolicitanteComponent implements OnInit, OnDestroy {

  public nuevoSolicitudForm: FormGroup;
  public nuevoSolicitudUsuarioForm: FormGroup;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public renderizarFormulario: boolean = false;
  public correoBusqueda: string = '';
  public subscritpionCampus: Subscription;
  public subscriptionPeriodo: Subscription;
  public subscriptionPrograma: Subscription;
  public subscriptionApoyoFinanciero: Subscription;
  public subscriptionEmpresa: Subscription;
  public campusList: CampusProgramaDTO[] = [];
  public periodoList: Periodo[] = [];
  public programaList: Programa[] = [];
  public apoyoList: TipoApoyoFinanciero[] = [];
  public empresaList: Empresa[] = [];
  public tipoAccion: number;
  public correoRegistro: string;
  public formularioProfesor: boolean = false;
  public nombreCompletoUsuario: string;
  public passwordTipoTexto: boolean = false;
  public passwordCTipoTexto: boolean = false;
  public sinConvenio: boolean = false;

  constructor(public dialogRef: MatDialogRef<NuevoSolicitanteComponent>,
    private campusService: CampusService,
    private periodoService: PeriodoService,
    private solicitudApoyoFinancieroSerrvice: SolicitudApoyoFinancieroService,
    private apoyoService: TipoApoyoFinancieroService,
    private empresaService: EmpresaService,
    private nuevoIngresoService: NuevoIngresoService,
    private snackService: SnackService) { }

  ngOnInit(): void {
    this.getTipoCampus();
    this.getTipoPeriodo();
    this.getTipoApoyo();
    this.getTipoEmpresa();
    this.renderizarForm();
  }

  public renderizarForm() {
    this.inicializarForm();
  }

  public recibirInfo(correo: string) {
    this.tipoAccion = AccionEnum.CREAR;
    this.correoRegistro = correo;
    this.inicializarForm();
  }

  public recibirInfoProfesor(correo: string, profesor: boolean) {
    this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusquedaCorreo$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
    this.tipoAccion = AccionEnum.EDITAR;
    this.correoRegistro = correo;
    this.formularioProfesor = profesor;
    this.nombreCompletoUsuario = `${this.datosUsuarioBusqueda.nombre} ${this.datosUsuarioBusqueda.apellidoPaterno} ${this.datosUsuarioBusqueda.apellidoMaterno}`;
    this.inicializarFormProfesor();
  }

  public inicializarForm() {
    this.nuevoSolicitudForm = new FormGroup({
      correo: new FormControl('', [Validators.required, Validators.email]),
      nombre: new FormControl('', [Validators.required]),
      segundoNombre: new FormControl(''),
      apellidoPaterno: new FormControl('', [Validators.required]),
      apellidoMaterno: new FormControl(''),
      fechaNacimiento: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()?]/), this.parameters]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()?]/), this.parameters]),
      programaAcademico: new FormControl(0, [Validators.required, this.validateId]),
      periodo: new FormControl(0, [Validators.required, this.validateId]),
      campus: new FormControl(0, [Validators.required, this.validateId]),
      promedio: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]+/)]),
      tipoApoyoFinanciero: new FormControl(0, [Validators.required, this.validateId]),
      empresa: new FormControl(0),
    });
    this.renderizarFormulario = true;
    this.nuevoSolicitudForm.get("campus").valueChanges.subscribe(campus => {
      this.onCampusSelected(campus);
      this.nuevoSolicitudForm.patchValue({
        programaAcademico: 0
      });
    });
    this.nuevoSolicitudForm.valueChanges.subscribe(val => {
      this.solicitudApoyoFinancieroSerrvice.definirFormularioValidoNuevaSolicitud(this.nuevoSolicitudForm.valid);
    });
    let valorInicial = 0;
    if (valorInicial == 0) {
      this.sinConvenio = false;
      this.empresa.setValidators([Validators.required, this.validateId]);
      this.nuevoSolicitudForm.controls.empresa.enable();
    } else {
      this.sinConvenio = true;
      this.empresa.setValidators(null);
    }
    this.nuevoSolicitudForm.get("tipoApoyoFinanciero").valueChanges.subscribe(apyoConvenio => {
      let vaor = this.apoyoList.find(x => x.tipoApoyoFinancieroId == apyoConvenio);
      if (vaor !== undefined) {
        if (vaor.convenio) {
          this.sinConvenio = false;
          this.empresa.setValidators([Validators.required, this.validateId]);
          this.nuevoSolicitudForm.patchValue({
            empresa: 0
          });
        } else {
          this.sinConvenio = true;
          this.empresa.setValidators(null);
          this.nuevoSolicitudForm.patchValue({
            empresa: 0
          });
        }
        this.empresa.updateValueAndValidity();
      }
    });
  }

  public inicializarFormProfesor() {
    this.nuevoSolicitudUsuarioForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      nombreCompleto: new FormControl(this.nombreCompletoUsuario),
      correoUsuario: new FormControl(this.correoRegistro),
      programaAcademicoUsuario: new FormControl(0, [Validators.required, this.validateId]),
      periodoUsuario: new FormControl(0, [Validators.required, this.validateId]),
      campusUsuario: new FormControl(0, [Validators.required, this.validateId]),
    });
    this.renderizarFormulario = true;

    this.nuevoSolicitudUsuarioForm.valueChanges.subscribe(val => {
      this.solicitudApoyoFinancieroSerrvice.definirFormularioValidoNuevaSolicitud(this.nuevoSolicitudUsuarioForm.valid);
    });
  }

  get correo() { return this.nuevoSolicitudForm.get('correo'); }
  get nombre() { return this.nuevoSolicitudForm.get('nombre'); }
  get segundoNombre() { return this.nuevoSolicitudForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.nuevoSolicitudForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.nuevoSolicitudForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.nuevoSolicitudForm.get('fechaNacimiento'); }
  get password() { return this.nuevoSolicitudForm.get('password'); }
  get passwordConfirm() { return this.nuevoSolicitudForm.get('passwordConfirm'); }
  get programaAcademico() { return this.nuevoSolicitudForm.get('programaAcademico'); }
  get periodo() { return this.nuevoSolicitudForm.get('periodo'); }
  get campus() { return this.nuevoSolicitudForm.get('campus'); }
  get promedio() { return this.nuevoSolicitudForm.get('promedio'); }
  get tipoApoyoFinanciero() { return this.nuevoSolicitudForm.get('tipoApoyoFinanciero'); }
  get empresa() { return this.nuevoSolicitudForm.get('empresa'); }

  get nombreCompleto() { return this.nuevoSolicitudUsuarioForm.get('nombreCompleto'); }
  get correoUsuario() { return this.nuevoSolicitudUsuarioForm.get('correoUsuario'); }
  get programaAcademicoUsuario() { return this.nuevoSolicitudUsuarioForm.get('programaAcademicoUsuario'); }
  get campusUsuario() { return this.nuevoSolicitudUsuarioForm.get('campusUsuario'); }
  get periodoUsuario() { return this.nuevoSolicitudUsuarioForm.get('periodoUsuario'); }

  private parameters(control: AbstractControl): { [key: string]: any } | null {
    let regExp = /[ÑÁÉÍÓÚñáéíóú]/.test(control.value);
    return regExp ? { 'password': { value: control.value } } : null;
  }

  public mostrarContrasena() {
    this.passwordTipoTexto = !this.passwordTipoTexto;
  }

  public mostrarContrasenaC() {
    this.passwordCTipoTexto = !this.passwordCTipoTexto;
  }

  public getErrorFormCampus(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Campus es requerido.";
    }
    return error;
  }

  public getErrorFormPrograma(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Programa Académico es requerido.";
    }
    return error;
  }

  public getErrorFormPeriodo(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Período es requerido.";
    }
    return error;
  }

  public getErrorFormUsuario(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudUsuarioForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  public getErrorFormTipoApoyo(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Tipo Apoyo Financiero es requerido.";
    }
    return error;
  }

  public getErrorFormEmpresa(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Empresa es requerido.";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public validarContrasena = function (nombreControl1: string, nombreControl2: string) {
    return this.nuevoSolicitudForm.get(nombreControl1).value === this.nuevoSolicitudForm.get(nombreControl2).value;
  }

  public getTipoCampus() {
    this.subscritpionCampus = this.campusService.obtenerCampusconProgramaActivo().subscribe(
      (campus: ApiResponse<CampusProgramaDTO[]>) => {
        this.campusList = campus.data;
      });
  }

  public getTipoPeriodo() {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoList = periodo;
      });
  }

  public onCampusSelected(campus: number) {
    this.programaList = [];
    if (!!campus) {
      this.campusService.obtenerProgramas(campus).subscribe(
        programas => {
          this.programaList = programas;
        })
    }
  }

  public getTipoApoyo() {
    this.subscriptionApoyoFinanciero = this.apoyoService.obtenerTipoApoyoFinanciero().subscribe(
      (apoyo: TipoApoyoFinanciero[]) => {
        this.apoyoList = apoyo;
      });
  }

  public getTipoEmpresa() {
    this.subscriptionEmpresa = this.empresaService.obtenerEmpresa().subscribe(
      (empresa: Empresa[]) => {
        this.empresaList = empresa;
      });
  }

  /**
   * Metodo para validar si el correo es valido o no
   * @param event 
   */
  public validarCorreo(event: any): void {
    if (this.correo.valid) {
      this.nuevoIngresoService.validarCorreo(event).subscribe((respuesta: ApiResponse<boolean>) => {
        if (respuesta.success) {
          if (respuesta.data) {
            this.nuevoIngresoService.buscarUsuarioByCorreo();
          }
        } else {
          this.snackService.mostrarSnackBack(respuesta.message);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscritpionCampus) {
      this.subscritpionCampus.unsubscribe();
    }
    if (this.subscriptionPeriodo) {
      this.subscriptionPeriodo.unsubscribe();
    }
    if (this.subscriptionApoyoFinanciero) {
      this.subscriptionApoyoFinanciero.unsubscribe();
    }
    if (this.subscriptionEmpresa) {
      this.subscriptionEmpresa.unsubscribe();
    }
  }

}
