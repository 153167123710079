import { TraductorService } from './../../_services/traductor.service';
import { SnackService } from './../../services/snack-service.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdministracionAcademicaComponent } from '../administracion-academica.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InscripcionService } from 'src/app/_services/inscripcion.service';
import { Correo } from 'src/app/_models/correo';

@Component({
  selector: 'app-modal-enviar-correo',
  templateUrl: './modal-enviar-correo.component.html',
  styleUrls: ['./modal-enviar-correo.component.scss']
})
export class ModalEnviarCorreoComponent implements OnInit {
  public boolIsArray: boolean = false;
  public correos: string = '';
  public correoForm: FormGroup;
  public boolCambio: boolean = false;
  public numSeleccionados: number = 0;
  public formularioValido: boolean = false;

  constructor(public dialogRef: MatDialogRef<AdministracionAcademicaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    public inscripcionService: InscripcionService,
    private snackService: SnackService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
    this.InicializarForm();
    console.log("data", this.data);

  }
  //#region  Get
  get Asunto() {
    return this.correoForm.get('Asunto');
  }

  get CuerpoDelMensaje() {
    return this.correoForm.get('CuerpoDelMensaje');
  }
  //#endregion

  //#region  Métodos
  public InicializarForm() {
    this.correoForm = new FormGroup({
      Asunto: new FormControl('', Validators.required),
      CuerpoDelMensaje: new FormControl('', Validators.required),
    });
  }
  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numSeleccionados = this.data.length;
    }
  }
  /**
   * Cierra el modal actual
   */
  public cerrarModal() {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }
  /**
   * Método que ejecuta la llamada del Api apra enviar correo electrónico
   */
  enviarCorreo(): void {
    if (this.boolIsArray) {
      const arrayCorreos = this.data.map(u => (u.usuario.correo.toString() ?? u.correo.toString()));
      this.correos = arrayCorreos.toString();
    }
    else {
      this.correos = this.data.usuario.correo.toString() ?? this.data.correo.toString();
    }
    const estructuraCorreo = new Correo();
    estructuraCorreo.CorreosDestinatario = this.correos;
    estructuraCorreo.Asunto = this.correoForm.get('Asunto').value;
    estructuraCorreo.CuerpoDelMensaje = this.correoForm.get('CuerpoDelMensaje').value;

    this.inscripcionService.enviarCorreos(estructuraCorreo).subscribe(correoEnviado => {
      if (correoEnviado) {
        let mensaje: string = this.traductorService.translate('correoEnviado');
        this.snackService.mostrarSnackBack(mensaje);
      } else {
        let mensaje: string = this.traductorService.translate('errorEnvio');
        this.snackService.mostrarSnackBack(mensaje);
      }
    });
  }
  //#endregion
}
