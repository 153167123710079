import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import i18next from 'i18next';
import * as moment from 'moment';
import 'moment/locale/es';
import { NgDialogAnimationService } from 'ng-dialog-animation';

@Injectable({
  providedIn: 'root',
})
export class UtileriasService {
  constructor(public dialog: NgDialogAnimationService) { }

  public formatearFecha(fecha: Date, formato: string = 'LL'): string {
    moment.locale(i18next.language);
    return moment(fecha).format(formato);
  }

  public abrirDialogoLateral(componente: any, datos?: any) {
    return this.dialog.open(componente, {
      data: datos,
      panelClass: 'dialogo-lateral',
      position: {
        top: '0',
        right: '0',
        bottom: '0',
      },
      animation: { to: 'left' },
      height: '100vh',
      width: '800px',
      maxHeight: '100vh',
      maxWidth: '800px',
      disableClose: true,
    });
  }

  /**
   * Metodo para abrir el modal de zuma
   * @param componente
   * @param datos
   * @returns
   */
  public abrirModalPagoZuma(componente: any, datos?: any): MatDialogRef<unknown, any> {
    return this.dialog.open(componente, {
      data: datos,
      panelClass: 'dialogo-pago',
      disableClose: true,
    });
  }

  /**
   * Metodo para abrir el modal cuando se registro el usuario con las instrucciones de
   * incio de sesion
   * @param componente
   * @param datos
   * @returns
   */
  public abrirModalRegistro(componente: any, datos?: any): MatDialogRef<unknown, any> {
    return this.dialog.open(componente, {
      data: datos,
      panelClass: 'dialogo-registro',
      disableClose: true,
    });
  }

  /**
   * Metodo para abrir el modal cuando se registro el usuario con las instrucciones de
   * incio de sesion
   * @param componente
   * @param datos
   * @returns
   */
  public abrirModalMatricula(componente: any, datos?: any): MatDialogRef<unknown, any> {
    return this.dialog.open(componente, {
      data: datos,
      panelClass: 'dialogo-matricula',
      disableClose: true,
    });
  }


   /**
   * Metodo para abrir el modal de zuma
   * @param componente
   * @param datos
   * @returns
   */
    public abrirModalPagoPayPal(componente: any, datos?: any): MatDialogRef<unknown, any> {
      return this.dialog.open(componente, {
        data: datos,
        panelClass: 'dialogo-pago',
        disableClose: true,
      });
    }
}
