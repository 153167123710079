import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService} from '@app/_services/authentication.service';
import { NavigationService } from '@app/_services/navigation.service';
import { LoadingScreenService } from '@app/_services/loading-screen.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  returnUrl: string = "";
  error: boolean = false;
  errorMessage: string = "";
  loading: boolean = false;

  public loginForm: FormGroup = new FormGroup({
    usuario: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private authenticationService: AuthenticationService,
    private loadingScreenService: LoadingScreenService
  ) { }

  ngOnInit(): void {
    this.router.navigate(["/dashboard"]);
  }

  public logIn(): void {
    console.log("loggin...");

    this.loadingScreenService.startLoading();
    this.error = false;
    this.errorMessage = "";

    this.authenticationService.login(
    ).pipe(first())
      .subscribe(
        data => {
          this.navigationService.isToggledMenu.next(true);

          this.router.navigate([this.returnUrl]);
          this.loadingScreenService.stopLoading();
        },
        error => {
          this.error = true;
          this.errorMessage = "Usuario o contraseña inválidos.";
          this.loadingScreenService.stopLoading();
        });
  }

}
