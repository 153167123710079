import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Campus } from 'src/app/_models/campus';
import { ConfiguracionReembolso } from 'src/app/_models/configuracion-reembolso';
import { Nivel } from 'src/app/_models/nivel';
import { Periodo } from 'src/app/_models/periodo';
import { Programa } from 'src/app/_models/programa';
import { CampusService } from 'src/app/_services/campus.service';
import { ConfiguracionCuotaService } from 'src/app/_services/configuracion-cuota.service';
import { NivelService } from 'src/app/_services/nivel.service';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-modal-reembolsos',
  templateUrl: './modal-reembolsos.component.html',
  styleUrls: ['./modal-reembolsos.component.scss']
})
export class ModalReembolsosComponent implements OnInit {
  public arrCampus: Campus[];
  public arrNivel: Nivel[];
  public arrPeriodo: Periodo[];
  public arrPrograma: Programa[];
  public agregar: boolean = false;
  public formCampos: FormGroup;
  public isFalse:boolean = true;
  
  constructor(
    private cuota: ConfiguracionCuotaService,
    private campusservice: CampusService,
    private nivelservice: NivelService,
    private periodoservice: PeriodoService,
    private programaservice: ProgramaAcademicoService,
    private snackBar: MatSnackBar,
    private utileria: UtileriasService,
    public dialogRef: MatDialogRef<ModalReembolsosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.llenarCampos();
  }

  llenarCampos(){
    if(this.data != null){
      this.agregar = true;
    }
    this.campusservice.obtenerCampus().subscribe((camp: Campus[])=>{
      this.arrCampus = camp;
    })
    this.nivelservice.getNivel().subscribe((objNivel: Nivel[])=>{
      this.arrNivel = objNivel;
    })
    this.periodoservice.obtenerPeriodos().subscribe((objPeriodo: Periodo[]) => {
      this.arrPeriodo = objPeriodo;
    })
    if(this.agregar){
      this.programaservice.obtenerProgramas().subscribe((objPrograma: Programa[]) => {
        this.arrPrograma = objPrograma;
      })
    }
    this.limpiar();
  }

  obtenerProgramas(){
    this.programaservice.obtenerProgramasCampus(this.formCampos.value.campus).subscribe((objPrograma: Programa[]) => {
      this.arrPrograma = objPrograma;
    })
  }

  guardar(){
    let datos:  ConfiguracionReembolso = new ConfiguracionReembolso();
    datos.PeriodoId = parseInt(this.formCampos.value.periodo);
    datos.CampusId= parseInt(this.formCampos.value.campus);
    datos.NivelId= parseInt(this.formCampos.value.nivel);
    datos.FechaInicio= this.formCampos.value.fechaInicio;
    datos.FechaFin= this.formCampos.value.fechaFin;
    datos.Porcentaje = parseFloat(this.formCampos.value.porcentaje);
    datos.UsuarioModificacion = -1
  
    if(!this.agregar){
      this.cuota.postConfiguracionReembolso(datos).subscribe(datosEnviados => {
        if (datosEnviados) {
          this.snackBar.open('Se ha guardado el reembolso correctamente.', 'OK', { duration: 5000 });
          this.cuota.metodoObtenerReembolso();
        } else {
          this.snackBar.open('Ha ocurrido un error al guardar el reembolso. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
        }
      });  
    }else{
      this.cuota.editartConfiguracionReembolso(datos).subscribe(datosEnviados => {
        if (datosEnviados) {
          this.snackBar.open('Se ha editado el porcentaje del reembolso correctamente.', 'OK', { duration: 5000 });
          this.cuota.metodoObtenerReembolso();
        } else {
          this.snackBar.open('Ha ocurrido un error al editar el porcentaje del reembolso. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
        }
      });
    }
  }
  
  limpiar(){
    this.formCampos = new FormGroup({
      periodo: new FormControl(this.data?.periodoId, [Validators.required]),
      campus: new FormControl(this.data?.campusId, [Validators.required]),
      nivel: new FormControl(this.data?.nivelId, [Validators.required]),
      //programa: new FormControl(this.data?.programaId, [Validators.required]),
      fechaInicio: new FormControl(this.utileria.formatearFecha(this.data?.fechaInicio, 'YYYY[-]MM[-]DD'), [Validators.required]),
      fechaFin: new FormControl(this.utileria.formatearFecha(this.data?.fechaFin, 'YYYY[-]MM[-]DD'), [Validators.required]),
      porcentaje: new FormControl(this.data?.porcentaje, [Validators.required, Validators.pattern(/^[0-9\.]/)]),
    })
  }

}
