import { TraductorService } from './../../../_services/traductor.service';
import { ContenidoParcial } from './../../../_models/ensenanza-aprendizaje-profesor';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { I18NextPipe } from 'angular-i18next';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ModalDetalleComponent } from '../modal-detalle/modal-detalle.component';
import { ModalExamenEncuestaComponent } from '../por-hacer/modal-examen-encuesta/modal-examen-encuesta.component';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { DatePipe } from '@angular/common';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Recurso } from 'src/app/_models/recurso';
import { TipoRecurso } from 'src/app/_models/tipo-recurso';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-por-hacer',
  templateUrl: './por-hacer.component.html',
  styleUrls: ['./por-hacer.component.scss'],
  providers: [DatePipe]
})
export class PorHacerComponent implements OnInit {
  public filtroForm: FormGroup;
  public alumnoId: number;
  public ejecutarModalExEn = true;
  @Input() grupoID: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  listParciales: any[];
  listContenidos: any[];
  listGrupoData: MatTableDataSource<any>;
  displayedColumns: string[] = ['parcial', 'tipoRecurso', 'titulo', 'puntuacion', 'fechaLimite'];
  public recursoSelccionado: any;
  public ultimoParcial: any;
  existeParcialFinal = false;
  contenido: ContenidoParcial[] = [];
  contenidoGrupoParcial: any[] = [];
  //Variable validar modal
  public existeModal$: Observable<boolean>;
  public existeModalDetalle: boolean = false;

  constructor(public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    public util: UtileriasService, private datePipe: DatePipe,
    private ensenanzaServ: EnsenanzaAprendizajeService,
    private i18nextPipe: I18NextPipe,
    private activatedRoute: ActivatedRoute,
    private traductorService: TraductorService,
    private router: Router,) { }

  ngOnInit(): void {
    let mensaje: string = this.traductorService.translate("_tableroAlumno.mensajeSecuencuial");
    this.mensajeSecuencial = mensaje;
    this.alumnoId = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;
    this.cargarContenidoP();
    this.cargarTipoContenido();
    this.cargarContenido();
    this.inicializarForm();
    this.existeModal$ = this.EnsenanzaAprendizajeAlumnoService.GetModalExistente$();
    this.existeModal$.subscribe(
      (existe: any) => {
        this.existeModalDetalle = existe;
      });
    const recursoIdParam: string = this.activatedRoute.snapshot.paramMap.get('recursoId');
    if (recursoIdParam !== null) {
      if (!this.existeModalDetalle) {
        const recursoId: number = +recursoIdParam;
        this.EnsenanzaAprendizajeAlumnoService.obtenerRecursoByRecursoId(recursoId, this.alumnoId).subscribe((response: ApiResponse<Recurso>) => {
          this.verDetalle(response.data);
        });
      }
    }
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      Parcial: new FormControl(-1),
      tipoRecurso: new FormControl(0)
    });
  }

  //#region Get Parciales
  //#region  MultiLenguaje

  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  //#endregion
  //#region  Cargar contenidos
  public cargarContenidoP() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerContenido(this.grupoID).subscribe((res: ApiResponse<any>) => {
      if (res.data) {
        this.contenidoGrupoParcial = res.data.grupoParcial;
        this.cargarParcialesP(res.data.clave);
      }
    })
  }
  //#endregion
  public cargarParcialesP(clave: string) {
    this.ensenanzaServ.obtenerParcialesProfesor(clave, this.grupoID).subscribe(parciales => {
      if (parciales.exito) {
        if (parciales.dataParcialFinal) {
          this.existeParcialFinal = true;
          this.listParciales = parciales.dataParciales;
          this.ultimoParcial = this.listParciales[this.listParciales.length - 1];
        }

        parciales.dataParciales.forEach(parcial => {

          let parcialText = '_tableroProfesor.parcial';
          if (!!this.existeParcialFinal && parcial == parciales.dataParciales.length) {
            parcialText = '_tableroProfesor.parcial-final';
          };

          if (this.contenidoGrupoParcial.length > 0) {
            let nuevoParcial = this.contenidoGrupoParcial.find(object => {
              return object.parcial == parcial
            });
            if (nuevoParcial) {
              let parcialobj = {
                parcial: nuevoParcial.parcial,
                ponderacion: nuevoParcial.ponderacion,
                nombre: nuevoParcial.nombre,
              }
              if (!parcialobj?.nombre) {
                parcialobj.nombre = this.translate(parcialText)// + ' ' + parcial;
              }
              this.contenido.push(parcialobj);
            } else {
              let _parcialObj = {
                parcial: parcial,
                ponderacion: 0,
                nombre: this.translate(parcialText)// + ' ' + parcial
              }
              this.contenido.push(_parcialObj);
            }
          }
          else {
            let _parcialObj = {
              parcial: parcial,
              ponderacion: 0,
              nombre: this.translate(parcialText) + ' ' + parcial
            }
            this.contenido.push(_parcialObj);
          }
        });
      }
    });
  }
  //#endregion

  public cargarTipoContenido() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerTipoContenido().subscribe((contenido: ApiResponse<TipoRecurso[]>) => {
      if (!!contenido.data) {
        let tipoRecursos = contenido.data.filter(x => x.nombre !== 'Contenido');
        this.listContenidos = tipoRecursos;
      }
    })
  }
  listaRecusos: any[];
  listModulo: any[];
  listRecursoOriginal: any[];
  listaRecursoTareaHechas: any[];
  public datafilter: any;
  TareaHecha: any[];


  fechaVencida: boolean = false;
  public validarFecha(fechaEntrega) {
    var fechaHoy1 = new Date();
    let fechaHoy = this.datePipe.transform(fechaHoy1, 'yyyy-MM-dd');
    if (fechaHoy <= fechaEntrega) {
      this.fechaVencida = true;
    }
  }

  //Variables actividades secuenciales
  public activityOrderType: number = 0;
  public mensajeSecuencial:string = "";

  public cargarContenido() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerRecursos(this.grupoID, this.alumnoId).subscribe((recursos: ApiResponse<any>) => {
      var fechaHoy1 = new Date();
      let fechaHoy = this.datePipe.transform(fechaHoy1, 'yyyy-MM-dd');
      if (recursos.data) {
        this.activityOrderType = recursos.data[0]?.activityOrderTypeId ?? 0;
        this.listaRecusos = recursos.data;
        this.listModulo = this.EnsenanzaAprendizajeAlumnoService.modulo;
      }
      this.TareaHecha = recursos.data.filter(tarea => tarea.recursoAlumno.length === 0);
      this.listRecursoOriginal = this.TareaHecha;
      this.listGrupoData = new MatTableDataSource(this.TareaHecha);
      this.listGrupoData.paginator = this.paginator;
      console.log("this.TareaHecha", this.TareaHecha)
      this.datafilter = recursos.data;
    })
  }

  public filtrar() {
    var parcial = this.filtroForm['value'].Parcial;
    var tipoRecursoAl = this.filtroForm['value'].tipoRecurso;
    if (parcial != -1 && tipoRecursoAl == 0) {
      var filtro = this.listRecursoOriginal.filter(row => row.parcial === parcial);

    } else if (parcial == -1 && tipoRecursoAl != 0) {
      var filtro = this.listRecursoOriginal.filter(row => row.tipoRecursoId === tipoRecursoAl);
    } else if (parcial != -1 && tipoRecursoAl != 0) {
      var filtro = this.listRecursoOriginal.filter(row => row.parcial === parcial && row.tipoRecursoId === tipoRecursoAl);
    } else {
      var filtro = this.listRecursoOriginal;

    }
    this.listGrupoData = new MatTableDataSource(filtro);
    this.listGrupoData.paginator = this.paginator;
  }
  public verDetalle(recurso) {
    const opcionIdParam: string = this.activatedRoute.snapshot.paramMap.get('opcionId');
    if (opcionIdParam === "porhacer") {
      if (recurso.tipoRecursoId == 3 || recurso.tipoRecursoId == 4) {
        recurso.porHacer = true;
        recurso.entregar = true;
        recurso.calificacion = false;
        recurso.grupoId = this.grupoID;
        recurso.ultimoParcial = this.ultimoParcial;
        recurso.existeParcialFinal = this.existeParcialFinal;
        if (this.ejecutarModalExEn) {
          this.EnsenanzaAprendizajeAlumnoService.updateCargarDetalleExamen.subscribe(val => {
            this.verDetalle(val);
          });
        }
        this.ejecutarModalExEn = false;
        this.util.abrirDialogoLateral(ModalExamenEncuestaComponent, recurso);
      } else {
        recurso.entregar = true;
        recurso.calificacion = false;
        recurso.grupoId = this.grupoID;
        recurso.ultimoParcial = this.ultimoParcial;
        recurso.existeParcialFinal = this.existeParcialFinal;
        recurso.soloDetalle = false;
        this.util.abrirDialogoLateral(ModalDetalleComponent, recurso);
      }
    }
  }

  public limpiar() {
    this.inicializarForm();
    this.listGrupoData = new MatTableDataSource(this.listRecursoOriginal);
    this.listGrupoData.paginator = this.paginator;
  }
}
