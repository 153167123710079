import { Component, Inject, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { AttributesDto } from '@app/_models/AttributesDto';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { AttributesService } from '@app/_services/attributes.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { TraductorService } from './../../_services/traductor.service';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';

@Component({
  selector: 'app-atributos-modal',
  templateUrl: './atributos-modal.component.html',
  styleUrls: ['./atributos-modal.component.scss']
})
export class AtributosModalComponent implements OnInit {

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public edicionAtributoForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<AtributosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AttributesDto,
    private attributeService: AttributesService,
    private dialog: MatDialog,
    private snackService: SnackService,
    private traductorService: TraductorService,) { }

  ngOnInit(): void {
    this.boolCambio = false;
    this.inicializarForm();
  }

  /**
   * Metodo para inicializar el formulario
   */
  public inicializarForm() {
    this.edicionAtributoForm = new FormGroup({
      attributeId: new FormControl(this.data?.attributeId),
      code: new FormControl(this.data?.code, [Validators.required]),
      name: new FormControl(this.data?.name, [Validators.required])
    });
    this.edicionAtributoForm.valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    })
  }

  get code() { return this.edicionAtributoForm.get('code'); }
  get name() { return this.edicionAtributoForm.get('name'); }

  /**
   * Metodo para enviar la informacion del modal
   * @returns 
   */
  public enviar(): void {
    switch (this.data.tipoAccion) {
      case AccionEnum.CREAR:
        this.data = Object.assign(this.edicionAtributoForm.value);
        this.data.tipoAccion = AccionEnum.CREAR;
        this.attributeService.atributoAgregarEditar(this.data).subscribe((response: SimpleResponse) => {
          if (response.success) {
            this.attributeService.metodoObtenerAtributo();
            let mensaje: string = this.traductorService.translate('guardadoCorrecto');
            this.snackService.mostrarSnackBack(mensaje);
            this.boolCambio = false;
            this.cambiarVistaEditar(response.message);
          } else {
            let mensaje: string = this.traductorService.translate(response.message);
            this.snackService.mostrarSnackBack(mensaje);
          }
        });
        break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionAtributoForm.value);
        this.data.tipoAccion = AccionEnum.EDITAR;
        this.attributeService.atributoAgregarEditar(this.data).subscribe((response: SimpleResponse) => {
          if (response.success) {
            this.attributeService.metodoObtenerAtributo();
            let mensaje: string = this.traductorService.translate('guardadoCorrecto');
            this.snackService.mostrarSnackBack(mensaje);
            this.boolCambio = false;
          } else {
            let mensaje: string = this.traductorService.translate(response.message);
            this.snackService.mostrarSnackBack(mensaje);
          }
        });
        break;
    }
  }

  /**
   * Modal validacion cambios
   */
  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  /**
   * Metodo cerrar modal advertencia
   * @param descartar 
   */
  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  /**
   * Metodo para cambiar de la vista crear a editar
   * @param attributeId 
   */
  public cambiarVistaEditar(attributeId: string) {
    this.boolCambio = false;
    this.data.attributeId = +attributeId;
    this.data = Object.assign(this.edicionAtributoForm.value);
    this.data.tipoAccion = AccionEnum.EDITAR;
    this.inicializarForm();
  }

}
