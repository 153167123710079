import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-tipo-de-pago',
  templateUrl: './modal-tipo-de-pago.component.html',
  styleUrls: ['./modal-tipo-de-pago.component.scss']
})
export class ModalTipoDePagoComponent implements OnInit {

  //#regions  Constrcutor
  constructor(public dialogRef: MatDialogRef<ModalTipoDePagoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: boolean) {
    this.permiteParcialidades = data;
  }
  //#endregion

  //#region  Propiedades
  public permiteParcialidades: boolean;
  //#endregion

  //#region Métodos  
  ngOnInit(): void {
  }
  /**
   * Retorna un true que significa que se pagará en parcialidades
   */
  public parcialidades(): void {
    this.dialogRef.close(true);
  }
  /**
   * Retorna un false que significa que no se pagará en mensualdiades (O sea que se
   * pagará en una exhibición)
   */
  public unaExhibicion(): void {
    this.dialogRef.close(false);
  }
  //#endregion
}
