import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ModalReembolsosComponent } from '../configuracion-cuotas/configuracion-reembolsos/modal-reembolsos/modal-reembolsos.component';
import { AccionEnum } from '../_models/accion.enum';
import { Campus } from '../_models/campus';
import { Edificio } from '../_models/edificio';
import { CampusService } from '../_services/campus.service';
import { EdificioService } from '../_services/edificio.service';
import { UtileriasService } from '../_services/utilerias.service';
import { EdificioEliminarComponent } from './edificio-eliminar/edificio-eliminar.component';
import { ModalEdificioComponent } from './modal-edificio/modal-edificio.component';

@Component({
  selector: 'app-edificio',
  templateUrl: './edificio.component.html',
  styleUrls: ['./edificio.component.scss']
})
export class EdificioComponent implements OnInit {
  displayedColumns: string[] = ['select', 'clave', 'nombre', 'campus'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allCampus') private allCampus:MatOption;
  selection = new SelectionModel<any>(true, []);
  public edificio: Edificio;
  public numSeleccionado: number;
  public arrOriginal: any[] = [];

  public ArCampus: Campus[] = [];
  public campusF = new FormControl();
  public idnombre = new FormControl();
  public arrFiltrado: any[] = [];

  constructor(
    public util: UtileriasService,
    private edificioService: EdificioService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    private campusService: CampusService,
  ) { }

  ngOnInit(): void {
    if(this.edificioService.obtenerEdificioSubscription == undefined){
      this.edificioService.obtenerEdificioSubscription = this.edificioService.invocarObtenerEdificio.subscribe(()=>{
        this.cargarEdificio();
      })
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    this.cargarEdificio();
    this.LlenarFiltros();
    this.LimpiarFiltros();
  }

  public LlenarFiltros() {
    this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.ArCampus = campus;
        this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
        this.filtro();
      })
      this.edificioService.getEdificio().subscribe((edif: any[]) => {
        this.arrOriginal = edif;
        this.filtro();
      })
  }

  public filtro() {
    let nombre:string = this.idnombre.value == null?"":this.idnombre.value;
    let campus: any[] = this.campusF.value == null?[]:this.campusF.value;
    let arrFiltrado: any[] = [...this.arrOriginal];
    if (nombre.length > 0 || nombre !== '') {
      arrFiltrado = arrFiltrado.filter(el => {
        return el.nombre.toLowerCase().search(nombre.toLowerCase()) > -1 || el.clave.toString().search(nombre) > -1;
      })
    }
    if (campus.length > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return campus.indexOf(f.campusId) > -1 });
    }
    this.dataSource = new MatTableDataSource(arrFiltrado);
    this.dataSource.paginator = this.paginator;

  }
  tosslePerOneCampus(all){ 
    if (this.allCampus.selected) {  
      this.allCampus.deselect();
      return false;
    }
    if(this.campusF.value.length == this.ArCampus.length){this.allCampus.select();}
    this.filtro();
  }
  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.campusF.patchValue([...this.ArCampus.map(item => item.campusId), 0]);
    } else {
      this.campusF.patchValue([]);
    }
    this.filtro();
   }

   LimpiarFiltros() {
    this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
    this.idnombre.setValue("");
    this.dataSource = new MatTableDataSource(this.arrFiltrado);
    this.dataSource.paginator = this.paginator;
    this.cargarEdificio();
  }

  public cargarEdificio() {
    this.edificioService.getEdificio().subscribe(data=>{
      this.arrOriginal=data;
      this.dataSource = new MatTableDataSource(data);
       this.dataSource.paginator = this.paginator;
       this.selection = new SelectionModel<any>(true, []);
       this.filtro();
    })
  }
  

  openEdificio(elemento: any = null) {
      this.util.abrirDialogoLateral(ModalEdificioComponent, elemento);
  }
  indice:any;
  obtenerPosicion(index:number){
    this.indice = index; 
  }

  Editar(elemento?) {
    let datos: any = {
      EdificioId: elemento.edificioId,
      Clave: elemento.clave,
      CampusId: elemento.campusId,
      CampusNombre: elemento.campusNombre,
      Nombre: elemento.nombre,
      FechaCreacion: elemento.FechaCreacion,
      UsuarioCreacion: elemento.UsuarioCreacion,
      FechaModificacion: elemento.FechaModificacion,
      UsuarioModificacion: elemento.UsuarioModificacion,
      tipoAccion: AccionEnum.EDITAR
    }
    const dialogo = this.util.abrirDialogoLateral(ModalEdificioComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.edificio = result;
      }
    });
  }

  Eliminar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    }else {
      if (elemento.length === 1){
        var datos = elemento[0];
      } else{
        var datos = elemento;
      }
    }
    const dialogo = this.util.abrirDialogoLateral(EdificioEliminarComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.edificio = result;
      }
    });
  }
  
  isAllSelected() {
    this.numSeleccionado = this.selection.selected.length;
    const numRows = this.arrOriginal.length;
    return this.numSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.UsuarioId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() :
      this.arrOriginal.forEach(row => this.selection.select(row));

  }
}
