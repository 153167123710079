import { TraductorService } from './../../_services/traductor.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { IntegracionDto } from 'src/app/_models/integracionDto';
import { IntentoPagoZumaDTO } from 'src/app/_models/IntentoPagoZumaDTO.model';
import { ResumenEstadoCuentaDto } from 'src/app/_models/resumenEstadoCuentaDto';
import { PagoZuma } from 'src/app/_models/Zuma/LinkPago/PagoZuma';
import { PagoZumaResponse } from 'src/app/_models/Zuma/LinkPago/PagoZumaResponse';
import { IntegracionService } from 'src/app/_services/integracion.service';
import { PagoLineaZumaService } from 'src/app/_services/pago-linea-zuma.service';
import { SeguimientoCarteraService } from 'src/app/_services/seguimiento-cartera.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { PagoZumaModalComponent } from '../pago-zuma-modal/pago-zuma-modal.component';

@Component({
  selector: 'app-pago-materias',
  templateUrl: './pago-materias.component.html',
  styleUrls: ['./pago-materias.component.scss']
})
export class PagoMateriasComponent implements OnInit, OnDestroy {

  //Input usuarioID
  @Input() public usuarioId: number = 0;
  @Input() public institucionId: string = '';
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variable resumen cartera
  public datosResumenUsuario: ResumenEstadoCuentaDto;
  //Fecha actual
  public fechaActual = new Date();
  //Variables cargos pendientes
  public cargosPendientes: any[] = [];
  //Variables tabla movimientos materia
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'fechaVencimiento', 'descripcion', 'cargo', 'abono', 'saldo'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public numMovimientosSeleccionado: number = 0;
  //Variable suma total selecionados
  public sumaTotal: number = 0;
  //Variable mensaje valor no coincide
  public showMensajeError: boolean = false;
  //Bandera renderizar html
  public infoCargada: boolean = false;
  //Cargos seleccionados por ser colegiatura
  public cargosSeleccionados: any[] = [];
  //Variable para controlar el boton de pagar
  public metodoPagoDisponible: boolean = false;
  //Variables Subcripcion eventemmiter
  public informacionInvocarMetodo: any;

  constructor(
    private seguimientoService: SeguimientoCarteraService,
    public utileriaService: UtileriasService,
    public pagoLineaZumaService: PagoLineaZumaService,
    private snackService: SnackService,
    private utileriasService: UtileriasService,
    private integracionService: IntegracionService,
    private traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.ObtenerDatosIntegracion();
    if (this.pagoLineaZumaService.invocarMetodoSubscription == undefined) {
      this.pagoLineaZumaService.invocarMetodoSubscription = this.pagoLineaZumaService.invocarMetodo.subscribe((event) => {
        this.guardarIntentoPago(event.pagoZuma, event.url);
      });
    }
  }

  /**
   * Metodo para obtener los metodos de pago configurados
   */
  public ObtenerDatosIntegracion(): void {
    this.subscription.add(
      this.integracionService.ObtenerDatosIntegracion().subscribe((response: ApiResponse<IntegracionDto[]>) => {
        if (response.success) {
          if (response.data.length > 0) {
            this.metodoPagoDisponible = true;
          } else {
            this.metodoPagoDisponible = false;
          }
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      })
    );
  }

  /**
   * Metodo que obtiene el resumen de cartera
   */
  public async obtenerResumenMateria(): Promise<void> {
    let apiPagosResponse = await this.seguimientoService.obtenerResumenMateriasByUsuarioId(this.usuarioId).toPromise();
    if (apiPagosResponse.success) {
      this.datosResumenUsuario = apiPagosResponse.data;
      await this.obtenerDatosPagoMateria();
    }
    else {
      this.snackService.mostrarSnackBack(apiPagosResponse.message);
    }
  }

  /**
   * Metodo que obtiene los movimientos de materia
   */
  public async obtenerDatosPagoMateria(): Promise<void> {
    let apiDatosPago = await this.seguimientoService.obtenerCargosMateriasByUsuarioId(this.usuarioId).toPromise();
    if (apiDatosPago.success) {
      if (apiDatosPago.data.length > 0) {
        this.sumaTotal = 0;
        this.cargosSeleccionados = [];
        this.cargosPendientes = apiDatosPago.data;
        this.dataSource = new MatTableDataSource(this.cargosPendientes);
        let filtro = this.cargosPendientes.find((cargo: any) => cargo.tipoMovimiento === 1);
        this.cargosSeleccionados.push(filtro);
        this.selection = new SelectionModel<any>(true, this.cargosSeleccionados);
        let sumaCreditosTotal = this.cargosSeleccionados.reduce((prev, curr) => prev + curr.saldoCargo, 0);
        this.sumaTotal = sumaCreditosTotal;
      }
      this.infoCargada = true;
    }
    else {
      this.snackService.mostrarSnackBack(apiDatosPago.message);
    }
  }

  /**
   * Metodo que agrega el saldo que se va abonar a los cargos pendientes, tambien valida que el valor ingresado no sea mayor al salgo cargo
   * @param valorSaldoAbono 
   * @param element 
   * @param i 
   */
  public enviarSaldoAbono(valorSaldoAbono, element, i): void {
    let valorSaldoNum = +valorSaldoAbono;
    let cargo = this.cargosPendientes.find(x => x.cargoId === element.cargoId);
    cargo.saldoAbono = valorSaldoNum;
    if (cargo.saldoAbono > cargo.saldoActual) {
      this.showMensajeError = true;
    } else {
      this.showMensajeError = false;
    }
    let sumaCargosObligatorios = this.cargosSeleccionados.reduce((prev, curr) => prev + curr.saldoCargo, 0);
    let sumaCreditosTotal = this.cargosPendientes.reduce((prev, curr) => prev + curr.saldoAbono, 0);
    this.sumaTotal = sumaCreditosTotal + sumaCargosObligatorios;
  }

  /**
   * Metodo que hara la accion de pagar con servicios de ZUMA
   */
  public registrarMovimiento(): void {
    let pagoZuma: PagoZuma = {
      reference: `Pago de Inscripción_${0}_${this.usuarioId}_${0}_${this.institucionId}_${0}_${0}`,
      amount: this.sumaTotal,
      promotion: "1",
      productCode: "AWPE_RPVT_XUG",
      expire: 1,
      sendLink: false,
      email: this.datosResumenUsuario.correo,
      paymentChannel: "ZUMA_API"
    }
    this.utileriasService.abrirModalPagoZuma(PagoZumaModalComponent, pagoZuma);
  }

  /**
   * Metodo que sirve para guardar el intento pago junto con una consulta que obtiene el cargo para enviarlo
   */
  public guardarIntentoPago(pagoZuma: PagoZuma, respuestaPagoZuma: PagoZumaResponse): void {
    let cargosPagar = this.selection.selected.map(obj => ({
      ...obj,
      motorPagoId: 1
    }));
    let PagoIntento: IntentoPagoZumaDTO = {
      cargos: cargosPagar,
      pagoZuma: pagoZuma,
      pagoZumaResponse: respuestaPagoZuma
    }
    this.subscription.add(
      this.pagoLineaZumaService.PostIntentoPago(PagoIntento).subscribe((respuesta: SimpleResponse) => {
        if (respuesta.success) {
          console.log("CORRECTO");
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    );
  }

  /**
   * Metodo del checkbox del mattable
   * @returns 
   */
  public isAllSelected(): boolean {
    this.numMovimientosSeleccionado = this.selection.selected.length;
    const numRows = this.cargosPendientes.length;
    return this.numMovimientosSeleccionado === numRows;
  }

  /**
   * Metodo del checkbox del mattable
   * @param row 
   * @returns 
   */
  public checkboxLabel(row?: any): string {
    if (!row) {
      let sumaCreditosTotal = this.selection.selected.reduce((prev, curr) => prev + curr.saldoCargo, 0);
      this.sumaTotal = sumaCreditosTotal;
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.movimientoId + 1}`;
  }

  /**
   * Metodo del checkbox del mattable
   */
  public masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.cargosPendientes.forEach(row => this.selection.select(row));
    this.selection = new SelectionModel<any>(true, this.cargosSeleccionados);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
