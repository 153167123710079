import { PREVIOUS_INFORMATION_MODEL } from './../../../../../core.domain/models/user/previous-studies-information.model';
import { MessageErrors } from './../../../../../core.application/functions/messageErros.function';
import { PREVIOUS_STUDIES_INFORAMTION } from './../../../../../core.application/validators/user-data/previous-studies-information.validators';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NivelService } from '@app/_services/nivel.service';
import { Nivel } from '@app/_models';
import { Subscription } from 'rxjs';
import { FORM_VALID_MODEL } from '@app/clean-architecture/core.domain/models/user/form-valid-model';

@Component({
  selector: 'app-previous-studies-information-form',
  templateUrl: './previous-studies-information-form.component.html',
  styleUrls: ['./previous-studies-information-form.component.scss']
})
export class PreviousStudiesInformationFormComponent implements OnInit, OnDestroy, OnChanges {
  
  //#region  Input, Output & ViewChild 
  @Input() data: PREVIOUS_INFORMATION_MODEL;

  @Input() cancelledFields: string[] = [];
  @Input() disabledledFields: string[] = [];

  @Output() formValidity: EventEmitter<FORM_VALID_MODEL> = new EventEmitter(); 
  @Output() formData = new EventEmitter<PREVIOUS_INFORMATION_MODEL>();
  //#endregion

  //#region Subscriptions
  private Subscription: Subscription = new Subscription();
  //#endregion
  
  //#region Properties
  public nivelesAcademicos: Array<Nivel>;
  public previouesStudiesInformationForm: FormGroup = this.formBuilder.group(PREVIOUS_STUDIES_INFORAMTION);
  public ErrorMsg: any;
  private status: boolean = false;

  public  deletedInputs = {
    usuarioId   : false,
    nivelId     : false,
    escuela     : false,
    promedio    : false
  };
  //#endregion

  constructor(
    private formBuilder: FormBuilder,
    private _nivelService: NivelService,
  ) { 
    this.setErrors();
  }

  ngOnInit(): void {
    this.subscribeToForm();
    this._getAcademicLevels();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] !== undefined) {
      this._setData();
    }
  }

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#region setData
  private _setData():void
  {    
    this.previouesStudiesInformationForm.setValue(this.data);
    // this.previouesStudiesInformationForm.get('usuarioId')?.setValue(this.data.usuarioId);
    // this.previouesStudiesInformationForm.get('nivelId')?.setValue(this.data.nivelId);
    // this.previouesStudiesInformationForm.get('escuela')?.setValue(this.data.escuela);
    // this.previouesStudiesInformationForm.get('promedio')?.setValue(this.data.promedio);
    
  }
  //#endregion

  //#region Methods
  public _getAcademicLevels(): void {
   this.Subscription.add(
    this._nivelService.getNivel().subscribe((nivelesAcademicos: Array<Nivel>) => {
      this.nivelesAcademicos = nivelesAcademicos;
    })
   );
  }
  //#endregion

  //#region Helpers
  public setErrors(): void {
    this.ErrorMsg = MessageErrors.setErrorMessage(this.previouesStudiesInformationForm);
  }

  /**
   * If the cancelledFields array has a length greater than 0, then for each field in the
   * cancelledFields array, if the previouesStudiesInformationForm has a control with the name of the field, then clear the
   * validators for that control and add the field to the deletedInputs object.
   */
   _deleteCancelledFields(): void {
    if (this.cancelledFields.length > 0) {
      this.cancelledFields.forEach(field => {
        if (this.previouesStudiesInformationForm.get(field)) {
          this.previouesStudiesInformationForm.get(field)?.clearValidators();
          this.deletedInputs[field] = true;
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  /**
   * _disabledCancelledFields() is a function that disables and clears validators for fields that are
   * not required
   */
  _disabledCancelledFields(): void {
    if (this.disabledledFields.length > 0) {
      this.disabledledFields.forEach(field => {
        if (this.previouesStudiesInformationForm.get(field)) {
          this.previouesStudiesInformationForm.get(field)?.clearValidators();
          this.previouesStudiesInformationForm.get(field)?.disable();
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  private subscribeToForm(): void {
    this.previouesStudiesInformationForm.markAllAsTouched();
    this._Emit();
    this.Subscription.add(
      this.previouesStudiesInformationForm.statusChanges
      .subscribe(status => {
        this.setErrors();
        this.status = status === 'VALID';
        this._Emit();
      })
    );
  }

  private _Emit():void
  {
    this.formValidity.emit(
      new FORM_VALID_MODEL(
        6,
        'PreviousStudiesInformationFormComponent',
        this.status
      )
    );
    this.formData.emit(
      new PREVIOUS_INFORMATION_MODEL(
        this.previouesStudiesInformationForm.get('usuarioId')?.value,
        this.previouesStudiesInformationForm.get('nivelId')?.value,
        this.previouesStudiesInformationForm.get('escuela')?.value,
        this.previouesStudiesInformationForm.get('promedio')?.value
      )
    );
  }
  //#endregion

}