import { SimpleResponse } from './../models/api/SimpleResponse.model';
import { AdministracionOpcion } from './../_models/AdministracionOpcion';
import { InstitucionEmailAccount } from './../_models/institucionEmailAccount';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Institucion } from '@app/_models/institucion';
import { AcademicManagementModel } from '../_models/AcademicManagementModel';

@Injectable({
  providedIn: 'root'
})
export class InstitucionService {

  constructor(private httpClient: HttpClient) { }

  public obtenerInstitucion(): Observable<Institucion> {
    return this.httpClient.get<Institucion>(`${environment.enves[baseUrl].apiUrl}/Institucion`);
  }

  /**
   * Metodo para traer la administracion de opciones del alumno
   * @returns 
   */
  public obtenerOpcionesBoton(): Observable<ApiResponse<AdministracionOpcion>> {
    return this.httpClient.get<ApiResponse<AdministracionOpcion>>(`${environment.enves[baseUrl].apiUrl}/Institucion/ObtenerOpcionesBoton`);
  }

  public modificarInstitucion(institucion: Institucion): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Institucion`, institucion);
  }

  public obtenerDatosInstitucionCargaInicial(url: string): Observable<Institucion> {
    return this.httpClient.get<Institucion>(`${environment.enves[baseUrl].apiUrl}/Home/` + url);
  }

  public enviarCorreoPrueba(datosEnvioCorreo: InstitucionEmailAccount): Observable<ApiResponse<InstitucionEmailAccount>> {
    return this.httpClient.post<ApiResponse<InstitucionEmailAccount>>(`${environment.enves[baseUrl].apiUrl}/InstitutionEmailAccount/PruebaEnvioCorreo`, datosEnvioCorreo);
  }

  public guardarConfiguracionCorreo(datosConfiguracionCorreo: Institucion): Observable<ApiResponse<Institucion>> {
    return this.httpClient.post<ApiResponse<Institucion>>(`${environment.enves[baseUrl].apiUrl}/InstitutionEmailAccount/`, datosConfiguracionCorreo);
  }
  public guardarIdentificadores(datosConfiguracionCorreo: Institucion): Observable<ApiResponse<Institucion>> {
    return this.httpClient.post<ApiResponse<Institucion>>(`${environment.enves[baseUrl].apiUrl}/Institucion/PostIdentificadores`, datosConfiguracionCorreo);
  }

  public getAcademicManagementModel(): Observable<ApiResponse<AcademicManagementModel[]>> {
    return this.httpClient.get<ApiResponse<AcademicManagementModel[]>>(`${environment.enves[baseUrl].apiUrl}/Institucion/getAcademicManagementModel`);
  }
  public UpdategetAcademicManagementModelId(institucion: Institucion): Observable<ApiResponse<Institucion>> {
    return this.httpClient.post<ApiResponse<Institucion>>(`${environment.enves[baseUrl].apiUrl}/Institucion/UpdategetAcademicManagementModelId`, institucion);
  }

  /**
   * Metodo para administrar las opciones de botones disponibles
   * @param adminBotones 
   * @returns 
   */
  public administrarOpciones(adminBotones: AdministracionOpcion): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Institucion/AdministrarOpciones`, adminBotones);
  }

  public agregarEstilos(estilos) : Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Institucion/UpdateEstilos`, estilos);
  }

  public obtenerEstilos(): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Institucion/EstilosInstitucion`);
  }
}
