import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FiltersComponent } from './filters.component';
import { MaterialModule } from '../../modules/material/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [
    FiltersComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,    
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    I18NextModule.forRoot(),
  ],
  exports: [FiltersComponent],
})
export class FiltersModule { }
