export class ServicePackageSeguimientoDto {
    servicePackageId: number;
    code: string;
    name: string;
    status: number;
    cantidadServicios: number;
    nuevoPaquete: boolean;
    serviciosPaquete: ServiciosPaquete[];
}
export class ServiciosPaquete {
    servicioId: number;
    code: string;
    name: string;
    description: string;
    status: boolean;
}
export class DetallePaqueteServicio {
    servicioId: number;
    categoriaId: number;
    nombre: string;
    costo: number;
    aplicaServicio: boolean;
    razon: string;
    tieneParcialidades: boolean;
    mumeroParcialidades: number;
    pagoParcialidades: boolean;
}