import { TableActions } from './../../../core.application/constants/table-actions.model';
import { GenericTableRow } from '../../../core.domain/class/generic.table.row.class'

export class AuthorizeRegistrationSubject extends GenericTableRow {
  clave:         string;
  nombre:        string;


  constructor(
    clave:         string,
    nombre:        string
  ) {
    super({
        clave,
        nombre
    }, 
    [
      {property: 'nombre', cssClass:'text-left'},
    ]
    );

  }

  static mapBackEnd(data: any): AuthorizeRegistrationSubject {
   
    return new AuthorizeRegistrationSubject(
      data.clave,
      data.nombre
    );
    
  }

  
  objectSetup(genericObject: any): void {

    this.clave          =  genericObject.clave;
    this.nombre         =  genericObject.nombre;
  }  
  
}
