import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepperService {

  constructor() { }
  //#region  Propiedades
  private paso$ = new Subject<boolean>();
  //#endregion

  //#region  Métodos
  /**
   * Retorna la intancia actual del subjec para poder suscribirse desde varios componentes
   * @returns 
   */
  public getSubjetPaso(): Subject<boolean>{
    return this.paso$;
  }
  /**
   * Notifica a los componentes suscritos sobre el cambio y hace que el Stepper
   * avance
   * @param cambiar 
   */
  public siguientePaso(): void{
    this.paso$.next(true);
  }
 /**
   * Notifica a los componentes suscritos sobre el cambio y hace que el Stepper
   * retroceda
   * @param cambiar 
   */
  public anteriorPaso(): void{
    this.paso$.next(false);
  }
  //#endregion

}
