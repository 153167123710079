import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable } from 'rxjs';
import { TipoReconocimientoService } from '../../_services/tipo-reconocimiento.service';
import { CampusService } from '../../_services/campus.service';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { UtileriasService } from '../../_services/utilerias.service';
import { TipoReconocimiento } from '../../_models/tipo-reconocimiento';
import { Programa } from '../../_models/programa';
import { Campus } from '../../_models/campus';

@Component({
  selector: 'app-programa-informacion-acuerdo',
  templateUrl: './programa-informacion-acuerdo.component.html',
  styleUrls: ['./programa-informacion-acuerdo.component.scss']
})
export class ProgramaInformacionAcuerdoComponent implements OnInit, OnDestroy {

  @Input() datosPrograma: Programa;
  public errorPrograma: string = "";
  public tipoReconocimientoList: TipoReconocimiento[] = [];
  private subscripcionTipoReconocimiento: Subscription;
  public datosInformacionAcuerdo: any;
  public informacionAcuerdoForm: FormGroup;
  public idPrograma$: Observable<number>;
  public idPrograma: number;
  public idProgramaSubscription: Subscription;

  constructor(private tipoReconocimientoService: TipoReconocimientoService,
    private snackBar: MatSnackBar,
    private campusService: CampusService,
    private programaAcademicoService: ProgramaAcademicoService,
    private utileriasService: UtileriasService) { }

  ngOnInit(): void {
    this.idPrograma$ = this.programaAcademicoService.obtenerIdPrograma$();
    this.idProgramaSubscription = this.idPrograma$.subscribe(id => this.idPrograma = id);
    this.inicializarForm();
    this.getTipoReconocimiento();
  }

  public inicializarForm() {
    this.informacionAcuerdoForm = new FormGroup({
      tipoReconocimientoId: new FormControl(this.datosPrograma.tipoReconocimientoId === 0 ? 1 : this.datosPrograma.tipoReconocimientoId, [Validators.required]),
      numeroAcuerdo: new FormControl(this.datosPrograma.numeroAcuerdo === '' ? '' : this.datosPrograma.numeroAcuerdo, [Validators.required]),
      fechaAcuerdo: new FormControl(this.datosPrograma.fechaAcuerdo === null ? null : this.utileriasService.formatearFecha(this.datosPrograma.fechaAcuerdo, 'YYYY[-]MM[-]DD'), [Validators.required])
    });

    this.informacionAcuerdoForm.valueChanges.subscribe(val => {
      this.programaAcademicoService.definirBoolCambio(true);
    });
  }

  get tipoReconocimientoId() { return this.informacionAcuerdoForm.get('tipoReconocimientoId'); }
  get numeroAcuerdo() { return this.informacionAcuerdoForm.get('numeroAcuerdo'); }
  get fechaAcuerdo() { return this.informacionAcuerdoForm.get('fechaAcuerdo'); }

  public getTipoReconocimiento() {
    this.subscripcionTipoReconocimiento = this.tipoReconocimientoService.getTipoReconocimiento().subscribe(
      (tiposReconocimiento: TipoReconocimiento[]) => {
        this.tipoReconocimientoList = tiposReconocimiento;
      }
    );
  }

  public enviarInformacionAcuerdo() {
    this.datosInformacionAcuerdo = Object.assign(this.informacionAcuerdoForm.value);
    this.datosInformacionAcuerdo.idPrograma = this.datosPrograma.programaId === 0 ? this.idPrograma : this.datosPrograma.programaId;
    this.datosInformacionAcuerdo.tabSeccion = "IA";
    this.programaAcademicoService.modificarProgramaInformacionAcuerdo(this.datosInformacionAcuerdo).subscribe(
      editado => {
        if (editado) {
          this.programaAcademicoService.definirBoolCambio(false);
          this.programaAcademicoService.invocarMetodo();
          this.snackBar.open("Se ha modificado la información.", "OK", { duration: 5000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al modificar el programa. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al modificar el programa. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

  ngOnDestroy() {
    if (this.idProgramaSubscription) {
      this.idProgramaSubscription.unsubscribe();
    }
    if (this.subscripcionTipoReconocimiento) {
      this.subscripcionTipoReconocimiento.unsubscribe();
    }
  }

}
