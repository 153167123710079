import { CuestionarioRespuesta } from './../../../../_models/cuestionario-respuesta';
import { Component, OnInit, Inject, ViewChild, TemplateRef, enableProdMode } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { EnsenanzaAprendizajeAlumnoService } from '../../../../_services/ensenanzaAprendizajeAlumno.service';
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalExamenEncuestaComponent } from '../modal-examen-encuesta/modal-examen-encuesta.component';
import * as customBuild from '../../../../../app/_librarries/ckeditorCustom/build/ckeditor';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { CuestionarioArea } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { CuestionarioRespuestaDto, InfoPorRevisarDto } from '@app/_models/ensenanza-aprendizaje-profesor';
import { EnsenanzaAprendizajeService } from '../../../../_services/ensenanza-aprendizaje.service';
import { CalificacionNoNumerica } from '@app/_models/calificacion-no-numerica';
import { runInThisContext } from 'vm';
@Component({
  selector: 'app-modal-contestar-ex-en',
  templateUrl: './modal-contestar-ex-en.component.html',
  styleUrls: ['./modal-contestar-ex-en.component.scss']
})
export class ModalContestarExEnComponent implements OnInit {
  public alumnoId: number;
  public seccionActual: any;
  public cronometro: number = 10;
  public dialogoRefAdver: any;
  public refreshIntervalId: any
  public preguntasAbiertas: any[] = [];
  public selectedIndex: number = 0;
  public respuestasCalificaciones: any[] = [];
  public respuestaPublicaEncuesta: boolean = false;

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalContestarExEnComponent>,
    public dialogRefModal: MatDialogRef<ModalExamenEncuestaComponent>,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
  ) {
  }
  secciones: any[];
  seccionesCalificacion: any[];
  seccionNueva: any;
  renderizar = false;
  public Editor = customBuild;
  public formulario: FormGroup;

  ngOnInit(): void {
    this.formulario = new FormGroup({
    });
    this.alumnoId = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;

    if (this.data.tipoRecursoId == 3) {
      this.EnsenanzaAprendizajeAlumnoService.GetExamenContestar(this.data.recursoId).subscribe((ex: ApiResponse<CuestionarioArea[]>) => {
        if (ex.data) {
          this.secciones = ex.data;
          this.secciones[0].reintento = this.data.reintento;
          this.crearFormulario();
          // this.revisarFechaHora();
        } else {
          this.snackBar.open("El examen no esta en fechas habiles.", "OK", { duration: 3000 });
          this.dialogRef.close();
        }
      });
      this.revisarIntentos();
    }
    if (this.data.tipoRecursoId == 4) {
      this.EnsenanzaAprendizajeAlumnoService.GetEncuestaContestar(this.data.recursoId).subscribe((en: ApiResponse<CuestionarioArea[]>) => {
        if (en.data) {
          this.secciones = en.data;
          this.respuestaPublicaEncuesta = this.secciones[0].recurso.respuestaPublica;
          this.crearFormulario();
          // this.revisarFechaHora();
        } else {
          this.snackBar.open("El examen no esta en fechas habiles.", "OK", { duration: 3000 });
          this.dialogRef.close();
        }
      });
    }
  }

  public reintentosHechos: any[] = [];
  public sePuedeReintentar: boolean = false;
  public reintentosDisponibles: boolean = false;
  public numeroReintentosTotales: number = 0;
  public numeroReintentosDisponibles: any;

  public revisarIntentos() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerReintentos(this.data.alumnoId, this.data.recursoId).subscribe(
      (reintentos: ApiResponse<any>) => {
        this.reintentosHechos = reintentos.data;
        if (this.reintentosHechos != null) {
          this.numeroReintentosDisponibles = this.data.reintentos - this.reintentosHechos.length;
        } else {
          this.reintentosHechos = [];
          this.reintentosHechos.length = 0;
          this.numeroReintentosDisponibles = this.data.reintentos - this.reintentosHechos.length;
        }
      });
  }

  public revisarFechaHora() {
    let today = new Date();
    let fechaEntrega = new Date(this.data.fechaEntrega);
    if (true) {
      this.GuardarRespuestas();
      clearInterval(this.refreshIntervalId);
    }
  }

  public stringToHtml(titulo: string) {
    let xmlString = titulo;
    var doc = new DOMParser().parseFromString(xmlString, "text/html");
    return doc.body.innerHTML;
  }

  public calificacionDirecta: boolean = false;
  public isAbierta: number = 0;

  public crearFormulario() {
    this.secciones.forEach(element => {
      element.nombre = this.stringToHtml(element.nombre);
      element.cuestionarioPregunta.forEach(item => {
        var tipoPreguntaCuestionario = item.tipoPreguntaCuestionarioId;
        element.alumnoId = this.alumnoId;
        switch (tipoPreguntaCuestionario) {
          //Pregunta Abierta
          case 1:
            this.isAbierta = this.isAbierta + 1;
            let model = item.cuestionarioPreguntaId;
            this.preguntasAbiertas.push(model);
            this.preguntasAbiertas[item.cuestionarioPreguntaId] = "";
            item.titulo = this.stringToHtml(item.titulo);
            break;
          //Seleccion Unica
          case 2:
            this.formulario.addControl('unica' + item.cuestionarioPreguntaId, new FormControl(''));
            break;
          //Abierta Multiple
          case 3:
            item.cuestionarioRespuesta.forEach(val => {
              this.formulario.addControl('multiple' + val.cuestionarioRespuestaId, new FormControl(''));
            })
            break;
          //Escala
          case 4:
            this.formulario.addControl('escala' + item.cuestionarioPreguntaId, new FormControl(''));
            break;
        }
      });
    });

    if (this.isAbierta == 0) {
      console.log('calif directa');
    } else {
      console.log('calif maestro');
    }

    if (this.data.tipoRecursoId == 3) {
      this.cronometro = this.secciones[0].recurso.cronometro;
    }
  }

  get examenEncuesta(): FormArray {
    return this.formulario.get('encuestaExamen') as FormArray;
  }

  public cerrarModal() {
    this.GuardarRespuestas();
  }


  public terminarModal() {
    this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
  }

  public cerrarModalTerminarExamenEncuesta(terminado: boolean) {
    if (terminado) {
      //Este para cerrar modal de advertencia
      this.dialogoRefAdver.close();
      clearInterval(this.refreshIntervalId);
      this.GuardarRespuestas();
    } else {
      this.dialogoRefAdver.close();
    }
  }

  public GuardarRespuestas() {
    this.respuestasCalificaciones = [];
    this.secciones.forEach(element => {
      element.cuestionarioPregunta.forEach(item => {
        var tipoPreguntaCuestionario = item.tipoPreguntaCuestionarioId;
        element.alumnoId = this.alumnoId;
        switch (tipoPreguntaCuestionario) {
          //Pregunta Abierta
          case 1:
            let cuestionarioRespuestaAbierta = new CuestionarioRespuesta();
            cuestionarioRespuestaAbierta.cuestionarioPreguntaId = item.cuestionarioPreguntaId;
            cuestionarioRespuestaAbierta.cuestionarioPreguntaId = item.cuestionarioPreguntaId;
            var titulo = this.preguntasAbiertas[item.cuestionarioPreguntaId];
            cuestionarioRespuestaAbierta.titulo = titulo ? titulo : '';
            item.cuestionarioRespuesta.push(cuestionarioRespuestaAbierta);
            this.respuestasCalificaciones.push({
              cuestionarioPreguntaId: item.cuestionarioPreguntaId
            })
            break;
          //Seleccion Unica
          case 2:
            let cuestionarioRespuestaUnica = new CuestionarioRespuesta();
            cuestionarioRespuestaUnica.cuestionarioPreguntaId = item.cuestionarioPreguntaId;
            cuestionarioRespuestaUnica.cuestionarioRespuestaId = this.formulario.controls['unica' + item.cuestionarioPreguntaId].value ? this.formulario.controls['unica' + item.cuestionarioPreguntaId].value : 0;
            item.cuestionarioRespuesta.length = 0;
            item.cuestionarioRespuesta.push(cuestionarioRespuestaUnica)
            this.respuestasCalificaciones.push({
              cuestionarioPreguntaId: item.cuestionarioPreguntaId
            })
            break;
          //Abierta Multiple
          case 3:
            let arraySeleccionadas: any[] = [];
            item.cuestionarioRespuesta.forEach(respM => {
              var seEligio = this.formulario.controls['multiple' + respM.cuestionarioRespuestaId].value
              if (seEligio) {
                let cuestionarioRespuestaMultiple = new CuestionarioRespuesta();
                cuestionarioRespuestaMultiple.cuestionarioPreguntaId = item.cuestionarioPreguntaId;
                cuestionarioRespuestaMultiple.cuestionarioRespuestaId = respM.cuestionarioRespuestaId
                arraySeleccionadas.push(cuestionarioRespuestaMultiple);
              }
            })
            if (arraySeleccionadas.length > 0) {
              item.cuestionarioRespuesta.length = 0;
              item.cuestionarioRespuesta = arraySeleccionadas;
            } else {
              let cuestionarioRespuestaMultiple = new CuestionarioRespuesta();
              cuestionarioRespuestaMultiple.cuestionarioPreguntaId = item.cuestionarioPreguntaId;
              cuestionarioRespuestaMultiple.cuestionarioRespuestaId = 0;
              item.cuestionarioRespuesta.length = 0;
              arraySeleccionadas.push(cuestionarioRespuestaMultiple);
              item.cuestionarioRespuesta = arraySeleccionadas;
            }
            this.respuestasCalificaciones.push({
              cuestionarioPreguntaId: arraySeleccionadas[0].cuestionarioPreguntaId
            })
            break;
          //Escala
          case 4:
            let cuestionarioRespuestaEscala = new CuestionarioRespuesta();
            cuestionarioRespuestaEscala.cuestionarioPreguntaId = item.cuestionarioPreguntaId;
            cuestionarioRespuestaEscala.cuestionarioRespuestaId = this.formulario.controls['escala' + item.cuestionarioPreguntaId].value ? this.formulario.controls['escala' + item.cuestionarioPreguntaId].value : 0;
            item.cuestionarioRespuesta.length = 0;
            item.cuestionarioRespuesta.push(cuestionarioRespuestaEscala)
            this.respuestasCalificaciones.push({
              cuestionarioPreguntaId: item.cuestionarioPreguntaId
            })
            break;
        }
      });
    });

    if (this.data.tipoRecursoId == 3) {
      this.EnsenanzaAprendizajeAlumnoService.responderExamen(this.secciones).subscribe((value: SimpleResponse) => {
        if (value.success) {
          this.snackBar.open("Se han guardado las respuestas del examen.", "OK", { duration: 3000 });
          this.EnsenanzaAprendizajeAlumnoService.obtenerReintentos(this.data.alumnoId, this.data.recursoId).subscribe(
            (reintentos: ApiResponse<any>) => {
              this.reintentosHechos = reintentos.data;
              console.log('reintentos ultimos despues de guardar las respuestas', this.reintentosHechos);
              if (this.data.tipoRecursoId == 3 && this.isAbierta == 0) {
                this.enviarCalificacion();
                setTimeout(() => {
                  this.EnsenanzaAprendizajeAlumnoService.updateDetalleExamen(this.data)
                  this.dialogRef.close();
                }, 3000);
                ;
              } else {
                console.log('lo debe revisar el maestro antes de guardar la calificacion');
                setTimeout(() => {
                  this.EnsenanzaAprendizajeAlumnoService.updateDetalleExamen(this.data)
                  this.dialogRef.close();
                }, 3000);
                ;
              }
            });
        }
      })
    }

    if (this.data.tipoRecursoId == 4) {
      this.EnsenanzaAprendizajeAlumnoService.responderEncuesta(this.secciones).subscribe((value: SimpleResponse) => {
        if (value.success) {
          this.snackBar.open("Se han guardado las respuestas de la encuesta.", "OK", { duration: 3000 });
          setTimeout(() => {
            this.EnsenanzaAprendizajeAlumnoService.updateDetalleExamen(this.data)
            this.dialogRef.close();
          }, 3000);
          ;
        }
      })
    }
  }

  public puntosTotalObtenidos = 0;
  public ponderacionExamen = 0;
  public puntosObtenidosExamen = 0;
  public programa: any;
  public escalaCal: CalificacionNoNumerica[] = [];
  public informacionReintentos: any;
  public resource: any;
  public reintento: any;

  public enviarCalificacion() {
    this.EnsenanzaAprendizajeAlumnoService.GetConsultarResultadoExamen(this.data.recursoId, this.alumnoId).subscribe((areas: ApiResponse<CuestionarioRespuestaDto[]>) => {
      this.seccionesCalificacion = areas.data;
      this.seccionesCalificacion.forEach(element => {
        element.cuestionarioPreguntaDto.forEach(item => {
          this.puntosTotalObtenidos = this.puntosTotalObtenidos + item.puntosObtenidos;
        })
      })
      this.puntosTotalObtenidos = Math.round(this.puntosTotalObtenidos);
      this.ponderacionExamen = this.secciones[0].recurso.ponderacion;
      this.puntosObtenidosExamen = Math.round((this.puntosTotalObtenidos * this.ponderacionExamen) / 100);
      this.obtenerProgramayEscala();
    });
  }

  public obtenerProgramayEscala() {
    this.EnsenanzaAprendizajeAlumnoService.ObtenerCalificacionMinima(this.alumnoId).subscribe(
      (programa: ApiResponse<any>) => {
        this.programa = programa.data;
        this.ensenanzaAprendizajeService.obtenerEscalaCalificacionByPrograma(this.programa[0].programaId).subscribe(
          (escala: ApiResponse<CalificacionNoNumerica[]>) => {
            this.escalaCal = escala.data;
            this.datosCalificacion();
          })
      })
  }

  public datosCalificacion() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerReintentos(this.data.alumnoId, this.data.recursoId).subscribe(
      (reintentos: ApiResponse<any>) => {
        this.reintentosHechos = reintentos.data;
        console.log('reintentos ultimos despues de guardar las respuestas', this.reintentosHechos);
      });

    let ultimoIntentoId = this.reintentosHechos.length - 1;
    let ultimoArray = this.reintentosHechos[ultimoIntentoId];
    this.reintento = ultimoArray;
    //this.reintentosHechos
    //this.resource = this.reintentosHechos.filter(x => x.alumnoId == this.alumnoId);
    // this.informacionReintentos = this.resource[0].resourceStudentAttempt;

    // let ultimoId = this.informacionReintentos.length - 1;
    // let ultimoArray = this.informacionReintentos[ultimoId];
    // this.reintento = ultimoArray;

    console.log("CALIFICACIONES:", this.respuestasCalificaciones)
    if (this.reintento != null) {
      let datosCal = {
        recursoId: this.data.recursoId,
        alumnoId: this.alumnoId,
        calificacionGlobal: this.puntosTotalObtenidos,
        comentario: null,
        usuarioSesion: this.alumnoId,
        escalaCalificacionId: this.escalaCal[0].escalaCalificacionId,
        resourceId: this.reintento.resourceStudentAttemptId,
        calificaciones: this.respuestasCalificaciones
      }
      this.guardarCalificacion(datosCal);
    } else if (this.data.recursoAlumno.length == 0) {
      let datosCal = {
        recursoId: this.data.recursoId,
        alumnoId: this.alumnoId,
        calificacionGlobal: this.puntosTotalObtenidos,
        comentario: null,
        usuarioSesion: this.alumnoId,
        escalaCalificacionId: this.escalaCal[0].escalaCalificacionId,
        resourceId: null,
        calificaciones: this.respuestasCalificaciones
      }
      this.guardarCalificacion(datosCal);
    }
  }

  public guardarCalificacion(datosCal) {
    this.ensenanzaAprendizajeService.enviarCalificacion(datosCal).subscribe(
      (calificacionCapturada: SimpleResponse) => {
        if (calificacionCapturada.success) {
          this.ensenanzaAprendizajeService.metodoObtenerInfoRecurso();
        } else {
          console.log('La calificación no pudo ser capturada.');
        }
      }
    );
  }

  public handleEvent(event: any) {
    if (event.status == 3) {
      clearInterval(this.refreshIntervalId);
      this.dialogRef.close();
      this.GuardarRespuestas();
    }
  }

  preguntas: any[];
  nombreSeccion: string;
  mostrar: boolean = false;

  public filtrar(seccion) {
    this.seccionNueva = seccion;
    this.preguntas = seccion.cuestionarioPregunta;
    this.mostrar = true;
    this.nombreSeccion = seccion.nombre;
    this.renderizar = true;
  }

  clickMe(value: number) {
    if (value <= this.secciones.length && value >= 0) {
      this.selectedIndex = value;
    }
  }
}
