import { Biografia } from "./biografia";
import { Campus } from "./campus";
import { DatoFiscal } from "./dato-fiscal";
import { Direccion } from "./direccion";
import { EstudiosPrevios } from "./estudios-previos";
import { Ocupacion } from "./Ocupacion.model";
import { PeriodoIngresoNavigation } from "./PeriodoIngresoNavigation.model";
import { PersonaRelacionada } from "./persona-relacionada";
import { Programa } from "./programa";
import { Telefono } from "./Telefono.model";
import { Usuario } from "./usuario";

export class configuracionInscripcion {
    CargaInscripcionId:number;
    CampusId:number;
    NivelId:number;
    ProgramaId?:number;
    Ciclos:number;
    TipoCarga:string;
    CargaMaxima?:number;
    CargaMinima?:number;
    NoParciales?:number;
    FechaCreacion:string
    UsuarioCreacion:number;
    FechaModificacion:string;
    UsuarioModificacion?:number;
    personaRelacionadaUsuario: Array<PersonaRelacionada>;
}
export class Alumno{
    tipoCandidatoId: number;
    promedio: number;
    promedioPeriodo:number;
    alumnoId:number;
    usuarioId:number;
    programaId?:number;
    priodoIngreso?:number;
    campusId?:number;
    fechaIngreso :string;
    fechaEgreso?:string;
    fechaCreacion:string;
    usuarioCreacion:number;
    fechaModificacion:string;
    usuarioModificacion?:number;
    campus: Campus;
    correo: string;
    usuario: Usuario;
    estatusAcademico: EstatusAcademico;
    estatusAcademicoNavigation: EstatusAcademico;
    programa: Programa;
    direccion: Array<Direccion>;
    priodoIngresoNavigation: PeriodoIngresoNavigation;
    biografia: Biografia;
    estudioPrevio?: Array<EstudiosPrevios>;
    personaRelacionadaUsuario?: Array<PersonaRelacionada>;
    datoFiscal?: DatoFiscal;
    ocupacion?: Ocupacion;
    telefono?:Array<Telefono>;
    studentAttributes: any[];
    subProcesoAlumno: any;
}
export class EstatusAcademico{
    estatusAcademicoId:number;
    clave:string;
    nombre:string;
    FechaCreacion:string;
    UsuarioCreacion:number;
    FechaModificacion:string;
    UsuarioModificacion:number;
}
