import { CalendarInfoDTO } from "../models/Inscripcion/CalendarInfoDTO.model";

export class ListaMateriasOferta {
    //materia
    materiaOfertaId: number;
    materiaId: number;
    clave: string;
    nombre: string;
    tipoEquivalenciaId: number;
    fechaCreacion: Date;
    usuarioCreacion: number;
    fechaModificacion?: Date;
    usuarioModificacion?: number;
    //grupo
    grupoId: number;
    periodoId?: number;
    clave_gpo: string;
    profesorId?: number;
    esActivo?: boolean;
    capacidad?: number;
    fechaCreacion_gpo: Date;
    usuarioCreacion_gpo: number;
    fechaModificacion_gpo?: Date;
    usuarioModificacion_gpo?: number;

    //periodo
    periodoId_per: number;
    clave_per: string;
    tipoPeriodoId: number;
    nombre_per: string;
    fechaInicio: Date;
    fechaFin: Date;

    ///alumno
    alumnoId: number;
    grupoId_alumgp: number;
    materiaOfertaId_alumgpo: number;
    estatusInscripcionId: number;
    calificacionFinal?: number;
    calificacionNoNumericaId?: number;
    faltasFinal?: number;
    fechaCalificacionFinal?: Date;
    usuarioCalificacionFinal?: number;
    fechaCreacion_alumgpo: Date;
    usuarioCreacion_alumgpo: number;
    fechaModificacion_alumgpo?: Date;
    usuarioModificacion_alumgpo?: number;

    //horario
    horarioId: number;
    grupoId_hor?: number;
    salonId?: number;
    nombre_hor: string;
    fechaInicio_hor: Date;
    fechaFin_hor: Date;
    horarioRequerido?: boolean;
    horaInicio: string;
    horaFin: string;
    esLunes?: boolean;
    esMartes?: boolean;
    esMiercoles?: boolean;
    esJueves?: boolean;
    esViernes?: boolean;
    esSabado?: boolean;

    //salon
    salonId_salon: number;
    nombre_salon: string;

    //usuario 
    nombre_usuario: string;
    apellidoPaterno: string;
    apellidoMaterno: string;

    //alumno 
    campusId?: number;

    calendario: Array<CalendarInfoDTO>;

    activityOrdertypeId: number;
}
