import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TipoPeriodoService } from '../../_services/tipo-periodo.service';
import { TipoPeriodo } from '../../_models/tipo-periodo';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { Observable } from 'rxjs/Observable';
import { Programa } from '../../_models/programa';
import { EscalaCalificacionService } from '../../_services/escala-calificacion.service';
import { EscalaCalificacion } from '../../_models/escala-calificacion';
import { CalificacionNoNumerica } from '../../_models/calificacion-no-numerica';
import { TipoModalidadTitulacion } from 'src/app/_models/tipo-modalidad-titulacion';
import { MatOption } from '@angular/material/core';
import { ProgramaRequerimientoGenerales } from 'src/app/_models/programa-requerimientos-generales';
import { TipoModalidadTitulacionService } from 'src/app/_services/tipo-modalidad-titulacion.service';

@Component({
  selector: 'app-programa-requerimientos-generales',
  templateUrl: './programa-requerimientos-generales.component.html',
  styleUrls: ['./programa-requerimientos-generales.component.scss']
})
export class ProgramaRequerimientosGeneralesComponent implements OnInit, OnDestroy {
  @ViewChild('allTipoTitulacion') private allTipoTitulacion: MatOption;
  @Input() datosPrograma: Programa;
  public errorPrograma: string = "";
  public programaRequerimientosGeneralesForm: FormGroup;
  private subcripcionTipoPeriodo: Subscription;
  public tipoPeriodoList: TipoPeriodo[] = [];
  public escalaCalificacionList: EscalaCalificacion[];
  public CalificacionNoNumericaList: CalificacionNoNumerica[];
  public datosRequerimientosGenerales = new ProgramaRequerimientoGenerales();
  public idPrograma$: Observable<number>;
  public idPrograma: number;
  public idProgramaSubscription: Subscription;
  public valCalificacionMinima: Boolean;
  public arrayTipoTitulacion: TipoModalidadTitulacion[]=[];
  public arrayTipoTitulacionData: string[] = [];
  constructor(private tipoPeriodoService: TipoPeriodoService,
    private escalCalificacionService: EscalaCalificacionService,
    private snackBar: MatSnackBar,
    private programaAcademicoService: ProgramaAcademicoService,
    private tipoModalidadTitulacionService: TipoModalidadTitulacionService) {
      this.getTipoPeriodo();
     }

  ngOnInit(): void {
    this.idPrograma$ = this.programaAcademicoService.obtenerIdPrograma$();
    this.idProgramaSubscription = this.idPrograma$.subscribe(id => this.idPrograma = id);
    this.setTipoTitulacion();
    this.getEscalaCalificacion();
    this.getCalificacion();
  }

  public inicializarFrom() {
    this.programaRequerimientosGeneralesForm = new FormGroup({
      semanaCiclo: new FormControl(this.datosPrograma.semanaCiclo === 0 ? null : this.datosPrograma.semanaCiclo, [Validators.required, Validators.pattern(/^[1-9]+/)]),
      porcentajeRevalidacion: new FormControl(this.datosPrograma.porcentajeRevalidacion === 0 ? 0 : this.datosPrograma.porcentajeRevalidacion, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      escalaCalificacion: new FormControl(this.datosPrograma.escalaCalificacion === 0 ? null : this.datosPrograma.escalaCalificacion, [Validators.required]),
      calificacionMinima: new FormControl(this.datosPrograma.calificacionMinima === null ? null : this.datosPrograma.calificacionMinima, [Validators.required]),
      tipoPeriodoId: new FormControl(this.datosPrograma.tipoPeriodoId === 0 ? 1 : this.datosPrograma.tipoPeriodoId, [Validators.required]),
      tipoTitulacionSelect: new FormControl(0),
      authorizeRegistration: new FormControl(this.datosPrograma.authorizeRegistration ?? false),
    });
    
    this.tipoTitulacion.patchValue([...this.arrayTipoTitulacionData]);
    this.programaRequerimientosGeneralesForm.valueChanges.subscribe(val => {
      this.programaAcademicoService.definirBoolCambio(true);
    });
  }

  get tipoPeriodoId() { return this.programaRequerimientosGeneralesForm.get('tipoPeriodoId'); }
  get semanaCiclo() { return this.programaRequerimientosGeneralesForm.get('semanaCiclo'); }
  get porcentajeRevalidacion() { return this.programaRequerimientosGeneralesForm.get('porcentajeRevalidacion'); }
  get escalaCalificacion() { return this.programaRequerimientosGeneralesForm.get('escalaCalificacion'); }
  get calificacionMinima() { return this.programaRequerimientosGeneralesForm.get('calificacionMinima'); }
  get tipoTitulacion() { return this.programaRequerimientosGeneralesForm.get('tipoTitulacionSelect'); }
  get authorizeRegistration() { return this.programaRequerimientosGeneralesForm.get('authorizeRegistration'); }


  asignarDataTipoTitulacion() {

    if (this.datosPrograma.titulacionTesis) {
      this.arrayTipoTitulacionData.push('tesis');
    }
    if (this.datosPrograma.titulacionMateria) {
      this.arrayTipoTitulacionData.push('materias-posgrado');
    }
    if (this.datosPrograma.titulacionPromedio) {
      this.arrayTipoTitulacionData.push('promedio');
    }
    if (this.datosPrograma.titulacionExamen) {
      this.arrayTipoTitulacionData.push('examen');
    }
    if (this.datosPrograma.titulacionCredito) {
      this.arrayTipoTitulacionData.push('creditos');
    }

    this.inicializarFrom();

  }

  public getTipoPeriodo() {
    this.subcripcionTipoPeriodo = this.tipoPeriodoService.getTipoPeriodo().subscribe(
      (tiposPeriodos: TipoPeriodo[]) => {
        this.tipoPeriodoList = tiposPeriodos;
      }
    );
  }

  public setTipoTitulacion() {
    this.tipoModalidadTitulacionService.obtenerTiposTitulacion().subscribe(response => {
      if (response.success) {
        this.arrayTipoTitulacion = response.data;
        this.asignarDataTipoTitulacion();
      }
    })
  }

  public getEscalaCalificacion() {
    this.escalCalificacionService.obtenerEscalaCalificaciones().subscribe(
      (escalaCalificacion: EscalaCalificacion[]) => {
        this.escalaCalificacionList = escalaCalificacion;
      }
    )
  }

  public getCalificacion() {
    this.CalificacionNoNumericaList = [];
    let idEscala = 0;
    if (this.datosPrograma.escalaCalificacion !== 0) {
      idEscala = this.datosPrograma.escalaCalificacion
    }
    if (this.programaRequerimientosGeneralesForm.value.escalaCalificacion > 0) {
      idEscala = this.programaRequerimientosGeneralesForm.value.escalaCalificacion
    }
    if (idEscala !== 0) {
      this.escalCalificacionService.obtenerCalificacionesByEscalaCalificaciones(idEscala).subscribe(
        (calificacion: CalificacionNoNumerica[]) => {
          this.CalificacionNoNumericaList = calificacion;
          console.log("CALIIFFIICAACIION NO NUMERICA", calificacion);
        })
    }
  }

  public validarCalificacion() {
    let calificacion = this.programaRequerimientosGeneralesForm['value'].calificacionMinima
    this.valCalificacionMinima = this.CalificacionNoNumericaList.filter(x => x.valorNumerico === calificacion).length > 0 ? false : true;
  }

  public soloNumeros(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        console.log("validando", control);
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public enviarRequerimientosGenereales() {
    this.datosRequerimientosGenerales.tipoPeriodoId = this.tipoPeriodoId.value;
    this.datosRequerimientosGenerales.semanaCiclo = this.semanaCiclo.value;
    this.datosRequerimientosGenerales.porcentajeRevalidacion = this.porcentajeRevalidacion.value;
    this.datosRequerimientosGenerales.escalaCalificacion = this.escalaCalificacion.value;
    this.datosRequerimientosGenerales.calificacionMinima = this.calificacionMinima.value;
    this.setTipoTitulacionData();
    this.datosRequerimientosGenerales.IdPrograma = this.datosPrograma.programaId === 0 ? this.idPrograma : this.datosPrograma.programaId;
    this.datosRequerimientosGenerales.tabSeccion = "RG";
    this.datosRequerimientosGenerales.authorizeRegistration = this.authorizeRegistration?.value;
    
    
    console.log("this.datosRequerimientosGenerales", this.datosRequerimientosGenerales);
    this.programaAcademicoService.modificarProgramaRequerimientosGenerales(this.datosRequerimientosGenerales).subscribe(
      editado => {
        if (editado) {
          this.programaAcademicoService.definirBoolCambio(false);
          this.programaAcademicoService.invocarMetodo();
          this.snackBar.open("Se ha modificado la información.", "OK", { duration: 5000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al modificar el programa. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al modificar el programa. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

  setTipoTitulacionData() {
    let dataTipoTitulacion = this.tipoTitulacion.value;
    this.datosRequerimientosGenerales.titulacionTesis = dataTipoTitulacion.includes('tesis');
    this.datosRequerimientosGenerales.titulacionMateria = dataTipoTitulacion.includes('materias');
    this.datosRequerimientosGenerales.titulacionPromedio = dataTipoTitulacion.includes('promedio');
    this.datosRequerimientosGenerales.titulacionExamen = dataTipoTitulacion.includes('examen');
    this.datosRequerimientosGenerales.titulacionCredito = dataTipoTitulacion.includes('creditos');
  }

  ngOnDestroy(): void {
    if (this.subcripcionTipoPeriodo) {
      this.subcripcionTipoPeriodo.unsubscribe();
    }
    if (this.idProgramaSubscription) {
      this.idProgramaSubscription.unsubscribe();
    }
  }

}
