import { SimpleResponse } from './../models/api/SimpleResponse.model';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { baseUrl, environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';
import { TablaEgresoDTO } from '../_models/TablaEgresoDTO';
import { FiltroAdminAcademicoAlumnoDto } from '@app/_models/FiltroAdminAcademicoAlumnoDto';

@Injectable({
  providedIn: 'root'
})
export class EgresoService {

  constructor(private httpClient: HttpClient) { }
  
  invocarObtenerContenido = new EventEmitter();
  invocarObtenerContenidoSubscription: Subscription;
   //Invocar Metodo obtener info de Alumnos
   public invocarMetodoObtenerContenido() {
    this.invocarObtenerContenido.emit();
  }

  public ObtenerAlumnos(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Egreso/GetAlumnosEgreso/${pagina}/${registrosPagina}`, filtro);
  }

  public ObtenerAlumnoSeguimientoProgramaAcademico(usuarioId): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Egreso/GetSeguimientoAlumnoProgramaAcademico?usuarioId= ${usuarioId}`);
  }
  public ObtenerConfiguracionAcademica(campusId: number, nivelId: number, periodosId: string): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Egreso/ObtenerConfiguracionAcademicaDisponible?campusId=${campusId}&nivelId=${nivelId}&periodosId=${periodosId}`);
  }

  public ConfiguracionAcademicaValida(campusId: number, nivelId: number, periodoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Egreso/ConfiguracionAcademicaValida?campusId=${campusId}&nivelId=${nivelId}&periodoId=${periodoId}`);
  }

  public AsinarTipoTitulacionPeriodoAlumno(alumno: any): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Egreso/AsinarTipoTitulacionPeriodoAlumno`, alumno);
  }
  public AsinarTipoTitulacionPeriodoAdmin(alumno: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Egreso/AsinarTipoTitulacionPeriodoAdmin`, alumno);
  }

  public ActualizarEstatusCandidatoEgreso(alumnoId: string[]): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Egreso/ActualizarEstatusCandidatoEgreso`, alumnoId);
  }
  public ActualizarEstatusEgreso(alumnoId: string[]): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Egreso/ActualizarEstatusEgreso`, alumnoId);
  }
  public ActualizarEstatusAlumno(alumnoId: string[]): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Egreso/ActualizarEstatusAlumno`, alumnoId);
  }
  
  //Download Excel
  public descargarExcel(IdAlumnos: Array<string>) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Egreso/DownloadExcel`, JSON.stringify(IdAlumnos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

}
