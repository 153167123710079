import { ModalEnviarCorreoComponent } from './../administracion-academica/modal-enviar-correo/modal-enviar-correo.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneracionExcelServiceService } from './../services/generacion-excel-service.service';
import { SeguimientoAcademicoAlumnoModalComponent } from './seguimiento-academico-alumno-modal/seguimiento-academico-alumno-modal.component';
import { UtileriasService } from './../_services/utilerias.service';
import { SeguimientoAcademicoAlumnoService } from './../_services/seguimiento-academico-alumno.service';
import { SeguimientoAlumnoDTO } from './../_models/SeguimientoAlumnoDTO';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { MatPaginator } from '@angular/material/paginator';
import { Periodo } from '../_models/periodo';
import { Campus } from '../_models/campus';
import { ProgramaActivo } from '../_models/programa';
import { EstatusAcademico } from '../_models/inscripcion';
import { MatOption } from '@angular/material/core';
import { InscripcionService } from '../_services/inscripcion.service';
import { ProgramaAcademicoService } from '../_services/programa-academico.service';
import { PeriodoService } from '../_services/periodo.service';
import { CampusService } from '../_services/campus.service';
import { Ciclo } from '@app/_models/ciclo';
import { CicloService } from '@app/_services/ciclo.service';

@Component({
  selector: 'app-seguimiento-academico-alumno',
  templateUrl: './seguimiento-academico-alumno.component.html',
  styleUrls: ['./seguimiento-academico-alumno.component.scss']
})
export class SeguimientoAcademicoAlumnoComponent implements OnInit, OnDestroy {

  //#region Constructor
  constructor(
    private _SeguimientoAcademicoAlumnoService: SeguimientoAcademicoAlumnoService,
    public util: UtileriasService,
    private generadorExcel: GeneracionExcelServiceService,
    private periodoService: PeriodoService,
    public programaservice: ProgramaAcademicoService,
    private campusService: CampusService,
    private _CicloService: CicloService,
    public inscripcionService: InscripcionService,
    private dialog: MatDialog
    ) { }

  ngOnInit(): void {

    this.LlenarFiltros();
  }
  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Propiedades
  public SeguimientoAlumno: SeguimientoAlumnoDTO[];
  public numFilaSeleccionada: number = 0;

  //#region Propiedades Form
  public filtroForm: FormGroup;
  public today = new Date();
  public Periodos: Periodo[];
  public Programas: ProgramaActivo[] = [];
  public Campus: Campus[] = [];
  public Ciclos: Ciclo[] = [];
  public Estatus: EstatusAcademico[] = [];
  private periodosactivos: any[] = [];
  public numEgresoSeleccionado: number = 0;
  private arrOriginal: any[] = [];
  public datafilter: any;
  //#endregion
  //#endregion

  //#region ViewChild
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allCiclo') private allCiclo: MatOption;
  @ViewChild('allEstatus') private allEstatus: MatOption;
  //#endregion

  //#region MatTable
  public dataSource: MatTableDataSource<SeguimientoAlumnoDTO>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'select',
    'identificador',
    'nombre',
    'campusClave',
    'programaClave',
    'estatusAcademicoNombre',
    'tipoAlumno',
    'cicloClave',
    'promedioPrograma',
    'periodosInscritos',
    'periodoClave',
    'totalCursadasMat',
    'totalAcreditadasMat',
    'totalMatReprobadas',
    'totalInscritasMat',
    'totalAprobadasFueraPlan',
    'faltasPeriodo',
    'promedioPeriodo'
  ];
  //#endregion

  //#region Metodos
  public ObtenerAlumnos():void{
    this.Subscription.add(
      this._SeguimientoAcademicoAlumnoService.ObtenerAlumnos().subscribe(
        (respuesta: ApiResponse<SeguimientoAlumnoDTO[]>) => {
          if (respuesta.success) {
            this.arrOriginal = [...respuesta.data];
            this.SeguimientoAlumno = respuesta.data;
            this.dataSource = new MatTableDataSource(this.SeguimientoAlumno);
            this.dataSource.paginator = this.paginator;
            this.datafilter = this.SeguimientoAlumno;
            //this.filtro();
            } else {
            console.log(respuesta.message);
            }
        }
      )
    );
  }

  public AbrirModalLateral(Alumno: SeguimientoAlumnoDTO, indexTap: number) {
    //Se crea el objeto con la data base del objeto cleckeado y el número del tap requerido
    const data = {
      alumno: {
        ...Alumno
      },
      indexTap
    }
    this.util.abrirDialogoLateral(SeguimientoAcademicoAlumnoModalComponent, data);
  }
 //#endregion

   //#region Llenar filtros
   public LlenarFiltros(): void
   {
     this.inicializarForm();
     this.ObtenerPeriodos();
     this.ObtenerProgramas();
     this.ObtenerCampus();
     this.ObtenerCiclos();
     this.ObtenerEstatus();
     this.ObtenerAlumnos();
   }
   public ObtenerPeriodos(): void
   {
     this.Subscription.add(
       this.periodoService.obtenerPeriodos().subscribe(
         (periodos: Periodo[]) => {
           this.Periodos = periodos;
           for (let x of periodos) {
             let inicio = new Date(x.fechaInicio);
             let fin = new Date(x.fechaFin);
             if (inicio < this.today && fin > this.today) {
               this.periodosactivos.push(x.periodoId);
             }
           }
           if(this.periodosactivos.length > 0){
             this.periodoF.setValue([...this.periodosactivos]);
           }
         }
       )
     );

   }
   public ObtenerProgramas(): void
   {
     this.Subscription.add(
       this.programaservice.obtenerProgramasActivos().subscribe(
         (programas: ApiResponse<ProgramaActivo[]>) => {
           this.Programas = programas.data;
           this.ProgramaF.setValue([...this.Programas.map(item => item.programaId), 0]);
         }
       )
     );
   }
   public ObtenerCampus():void
   {
     this.Subscription.add(
       this.campusService.obtenerCampus().subscribe(
         (campus : Campus[]) => {
           this.Campus = campus;
           this.CampusF.setValue([...this.Campus.map(item => item.campusId), 0]);
         }
       )
     );
   }

   public ObtenerCiclos():void
   {
    this.Subscription.add(
      this._CicloService.obtenerCiclos().subscribe(
        (ciclos: ApiResponse<Ciclo[]>) => {
          this.Ciclos = ciclos.data;
          this.ciclosF.setValue([...this.Ciclos.map(item => item.cicloId), 0]);
        }
      ));
   }

   public ObtenerEstatus(): void
   {
     this.Subscription.add(
       this.inscripcionService.GetEstatusAcademicos().subscribe(
         (estatus : ApiResponse<EstatusAcademico[]>) => {
           this.Estatus = estatus.data;
           this.EstatusF.setValue([...this.Estatus.map(item => item.estatusAcademicoId), 0]);
         }
       )
     );
   }
   //#endregion

  //#region  FormControls
  periodoF = new FormControl();
  ProgramaF = new FormControl();
  CampusF = new FormControl();
  EstatusF = new FormControl();
  NombreF = new FormControl();
  MatCredPendientes = new FormControl();
  ciclosF = new FormControl();
  //#endregion

  //#region FormFiltros
  public inicializarForm() {
    this.filtroForm = new FormGroup({
      periodoF: new FormControl([]),
      ProgramaF: new FormControl([]),
      CampusF: new FormControl([]),
      EstatusF: new FormControl([]),
      MatCredPendientes: new FormControl()
    });
    this.periodoF.setValue(["-1"]);
    this.ProgramaF.setValue(["-1"]);
    this.CampusF.setValue(["-1"]);
    this.ciclosF.setValue(["-1"]);
    this.EstatusF.setValue(["-1"]);
    this.MatCredPendientes.setValue(null);
  }
  //#endregion

  //#region  Selecction

   masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }
  isAllSelected() {
    if (this.datafilter) {
    this.numFilaSeleccionada = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numFilaSeleccionada === numRows;
    }
  }

  checkboxLabel(row?: SeguimientoAlumnoDTO): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }
//#endregion

  //#region Filtrar
  public LimpiarFiltros() {
    this.inicializarForm();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arrOriginal);
    this.dataSource.paginator = this.paginator;
  }

  filtro(){

    let periodo: any[] = this.periodoF.value == null ? [] : this.periodoF.value;
    let campus: any[] = this.CampusF.value == null ? [] : this.CampusF.value;
    let ciclos: any[] = this.ciclosF.value == null ? [] : this.ciclosF.value;

    let estatusAdmicion: any[] = this.EstatusF.value == null ? [] : this.EstatusF.value;

    let programa: any[] = this.ProgramaF.value == null ? [] : this.ProgramaF.value;
    let nombre: string = this.NombreF.value == null ? "" : this.NombreF.value;

    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];


    filtroModificado = filtroModificado.filter(f => { return periodo.indexOf(f.periodoId) > -1 });
    filtroModificado = filtroModificado.filter(f => { return campus.indexOf(f.campusId) > -1 });
    filtroModificado = filtroModificado.filter(f => { return ciclos.indexOf(f.cicloId) > -1 });
    filtroModificado = filtroModificado.filter(f => { return estatusAdmicion.indexOf(f.estatusAcademicoId) > -1 });
    filtroModificado = filtroModificado.filter(f => { return programa.indexOf(f.programaId) > -1 });

    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.datafilter = filtroModificado;
    this.selection = new SelectionModel<any>(true, []);
  }

/************************************************* */
  toggleAllSelectionPeriodo() {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.filtro();
  }
  tosslePerOnePeriodo(all) {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if (this.periodoF.value.length == this.Periodos.length) { this.allPeriodo.select(); }
    this.filtro();
  }
/************************************************ */

  toggleAllSelectionPrograma() {
    if (this.allPrograma.selected) {
      this.ProgramaF.patchValue([...this.Programas.map(item => item.programaId), 0]);
    } else {
      this.ProgramaF.patchValue([]);
    }
    this.filtro();
  }
  tosslePerOnePrograma(all) {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.ProgramaF.value.length == this.Programas.length) { this.allPrograma.select(); }
    this.filtro();
  }
/************************************************ */
  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.CampusF.patchValue([...this.Campus.map(item => item.campusId), 0]);
    } else {
      this.CampusF.patchValue([]);
    }
    this.filtro();
  }
  tosslePerOneCampus(all) {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.CampusF.value.length == this.Campus.length) { this.allCampus.select(); }
    this.filtro();
  }
/************************************************ */
  toggleAllSelectionEstatus() {
    if (this.allEstatus.selected) {
      this.EstatusF.patchValue([...this.Estatus.map(item => item.estatusAcademicoId), 0]);
    } else {
      this.EstatusF.patchValue([]);
    }
    this.filtro();
  }
  tosslePerOneEstatus(all) {
    if (this.allEstatus.selected) {
      this.allEstatus.deselect();
      return false;
    }
    if (this.EstatusF.value.length == this.Estatus.length) { this.allEstatus.select(); }
    this.filtro();
  }

/************************************************ */
  //#region SelectionCiclo

  toggleAllSelectionCiclo() {
    if (this.allCiclo.selected) {
      this.ciclosF.patchValue([...this.Ciclos.map(item => item.cicloId), 0]);
    } else {
      this.ciclosF.patchValue([]);
    }
    this.filtro();
  }


  tosslePerOneCiclo(all) {
    if (this.allCiclo.selected) {
      this.allCiclo.deselect();
      return false;
    }
    if (this.ciclosF.value.length === this.Ciclos.length) { this.allCiclo.select(); }
    this.filtro();
  }
  //#endregion
/************************************************ */
buscarNombreId(filterValue: string) {
  filterValue = filterValue.toLowerCase();
  if(filterValue != ""){
    this.dataSource.filter = filterValue;
    this.dataSource.filterPredicate = function (data, filter: string): boolean {
      var nombre = data.nombre + " " + data.apellidoPaterno + " " + data.apellidoMaterno;
      return nombre.toLowerCase().includes(filter) || data.identificador.toString().includes(filter);
    };
    this.dataSource.paginator = this.paginator;
  }
}


  //#endregion

  //#region descargarExcel
  public descargarExcel(elemento?: any) {
    let Alumnos;
    if (elemento != null && elemento.length > 0) {
      Alumnos = elemento;
    }else
    {
      Alumnos = this.datafilter;
    }
    this.Subscription.add(
      this._SeguimientoAcademicoAlumnoService.descargarExcel(Alumnos).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "Seguimiento Alumno");
    }));
  }
  //#endregion

//#region Envio de correo
public enviarCorreo(btnDataTable: boolean, elemento?: Array<SeguimientoAlumnoDTO>): void {
  let datos;
  if (btnDataTable) {
    datos = elemento;
  }
  else {
    if (elemento.length === 1) {
      datos = elemento[0];
    }
    else {
      datos = elemento;
    }
  }

  this.dialog.open(ModalEnviarCorreoComponent, { data: datos, disableClose: true });
}

//#endregion
}
