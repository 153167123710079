import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { AttributesDto } from '@app/_models/AttributesDto';
import { AttributesService } from '@app/_services/attributes.service';
import { Subscription } from 'rxjs';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { UtileriasService } from '../../_services/utilerias.service';
import { AtributosModalEliminarComponent } from '../atributos-modal-eliminar/atributos-modal-eliminar.component';
import { AtributosModalComponent } from '../atributos-modal/atributos-modal.component';

@Component({
  selector: 'app-atributos-seguimiento',
  templateUrl: './atributos-seguimiento.component.html',
  styleUrls: ['./atributos-seguimiento.component.scss']
})
export class AtributosSeguimientoComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'Clave', 'Nombre'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  ExcelSubscription: Subscription;

  public servicio: any;
  public categoriaList: any[] = [];
  public valorSeleccionado: any[] = [];
  public infoCargado = false;
  public numAtributoSeleccionado = 0;
  public arrOriginal: any[] = [];
  public arrEstatus: any[] = [];
  public claveNombre = new FormControl();
  public filtroForm: FormGroup;

  constructor(private paginador: MatPaginatorIntl,
    private attributeService: AttributesService,
    public util: UtileriasService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    if (this.attributeService.obtenerAttributesSubscription === undefined) {
      this.attributeService.obtenerAttributesSubscription = this.attributeService.invocarObtenerAttributes.subscribe(() => {
        this.obtenerAtributos();
      })
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    this.obtenerAtributos();
    this.inicializarForm();
  }

  /**
   * Inicializa formulario de filtro
   */
  public inicializarForm() {
    this.filtroForm = new FormGroup({
      claveNombre: new FormControl('')
    });
  }

  /**
   * Metodo para hacer el filtro
   */
  public filtrado() {
    let claveName: string = this.claveNombre.value == null ? '' : this.claveNombre.value;
    let arrFiltrado: any = [];
    arrFiltrado = [...this.arrOriginal];
    if (claveName.length > 0 || claveName !== '') {
      arrFiltrado = arrFiltrado.filter(el => {
        return el.name.toLowerCase().search(claveName.toLowerCase()) > -1 || el.code.toString().search(claveName) > -1;
      });
    }
    this.dataSource = new MatTableDataSource(arrFiltrado);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Metodo para limpiar los filtros
   */
  public limpiarFiltros() {
    this.claveNombre.setValue('');
    this.filtrado();
  }

  /**
   * Metodo para obtener los atributos
   */
  public obtenerAtributos() {
    this.attributeService.attributesInstitucion().subscribe((response: ApiResponse<AttributesDto[]>) => {
      this.dataSource = new MatTableDataSource(response.data);
      this.selection = new SelectionModel<AttributesDto>(true, []);
      this.dataSource.paginator = this.paginator;
      this.infoCargado = true;
      this.arrOriginal = [...response.data];
    })
  }

  /**
   * Metodo para generar un nuevo atributo
   */
  public crear(): void {
    let datos: AttributesDto = {
      attributeId: 0,
      code: '',
      name: '',
      tipoAccion: AccionEnum.CREAR
    }
    this.util.abrirDialogoLateral(AtributosModalComponent, datos);
  }

  /**
   * Metodo para editar un atributo
   * @param elemento 
   */
  public editar(elemento?: AttributesDto): void {
    let datos: AttributesDto = {
      attributeId: elemento.attributeId,
      code: elemento.code,
      name: elemento.name,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(AtributosModalComponent, datos);
  }

  /**
   * Metodo para eliminar atributos
   * @param btnDataTable 
   * @param elemento 
   */
  public borrar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    this.dialog.open(AtributosModalEliminarComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  /**
   * Metodo para descargar excel
   */
  public descargarExcel() {
    this.ExcelSubscription = this.attributeService.downloadExcelAtributos(this.arrOriginal).subscribe((res: Blob) => {
      var filename = "Atributos.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  /**
   * Meotodo Paginado
   * @returns 
   */
  isAllSelected() {
    this.numAtributoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numAtributoSeleccionado === numRows;
  }

  /**
   * Metodo Paginado
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  /**
   * Metodo Paginado
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row))
  }

}
