export class AlumnoReingresoDTO {
    // Nivel al que pertenece el programa
    idNivel: number;
    // Nombre completo dela lumno
    nombreCompleto: string;
    // Identificador del alumno
    identificador: string;
    // Eststus académico del alumno (Aceptado, prospecto, etc)
    estatus: string;
    // Programa al que está inscrito el alumno
    programa: string;
    // Campus en el que estudia el alumno
    campus: string;
    // Periodo actual que cursa el alumno
    periodo: string;
    // Id Del periodo actual del alumno
    idPeriodo: number;
    //Id del campus al que pertenece el aluno
    idCampus: number;
    //Id del programa que cursa del alumno
    idPrograma: number;
    //Id el alumno
    idAlumno: number;
    //Id del usuario 
    idUsuario: number;
    //nombre del bloque si tiene alguno asociado
    blockName: string;

    institucionId: string;
    authorizeRegistration: string;
}