import { ReportsEmbedComponent } from './reports-embed/reports-embed.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'creditos-aprobados', component: ReportsEmbedComponent},
  { path: 'inscripciones', component: ReportsEmbedComponent},
  { path: 'total-enrollment', component: ReportsEmbedComponent},
  { path: 'ingresos', component: ReportsEmbedComponent},
  { path: 'inscritos-datos-generales', component: ReportsEmbedComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsEmbedRoutingModule { }
