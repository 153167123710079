import { SnackService } from './../../services/snack-service.service';
import { SimpleResponse } from './../../models/api/SimpleResponse.model';
import { Component, OnInit, ViewChild, Inject, OnDestroy, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { AccionEnum } from '../../_models/accion.enum';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Materia } from '../../_models/materia';
import { MateriaService } from '../../_services/materia.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AreaMateria } from '../../_models/area-materia';
import { TipoInstalacion } from '../../_models/tipo-instalacion';
import { TipoImparticion } from '../../_models/tipo-imparticion';
import { TipoMateria } from '../../_models/tipo-materia';
import { TipoEquivalencia } from '../../_models/tipo-equivalencia';
import { TipoDuracion } from '../../_models/tipo-duracion';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { MateriaOferta } from '../../_models/materia-oferta';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subscription, Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as customBuild from '../../../app/_librarries/ckeditorCustom/build/ckeditor';

@Component({
  selector: 'app-programa-academico-modal',
  templateUrl: './programa-academico-modal.component.html',
  styleUrls: ['./programa-academico-modal.component.scss']
})
export class ProgramaAcademicoModalComponent implements OnInit {

  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  @ViewChild('dialogAdverteniaMateria') dialogAdverteniaMateria: TemplateRef<any>;
  public dialogoRefAdver: any;
  public dialogoRefAdverMateria: any;
  public tipoDuracionList: TipoDuracion[] = [];
  public areaMateriaList: AreaMateria[] = [];
  public tipoInstalacionList: TipoInstalacion[] = [];
  public tipoImparticionList: TipoImparticion[] = [];
  public tipoMateriaList: TipoMateria[] = [];
  public materiaOfertaList: MateriaOferta[] = [];
  public tipoEquivalenciaList: TipoEquivalencia[] = [];
  public materiaForm: FormGroup;
  public materiaOfertaForm: FormGroup;
  public datosMateriaGuardada: Materia;
  public boolCambio: boolean = false;
  public boolDesabilitarTab: boolean = true;
  public tabSeleccionado: number = 0;
  public boolTabMateriasOferta: boolean = false;
  public boolAgregarMateriaOferta: boolean = false;
  public tipoAccion: number;
  public materiaOfertaClave: string;
  public materiaOfertaId: number;
  public readonly ACCION_CREAR: number = AccionEnum.CREAR;
  public readonly ACCION_EDITAR: number = AccionEnum.EDITAR;
  public arrayMaterias$: Observable<any>;
  public arrayMaterias: any;
  public arrayMateriasSeriacion: any;
  public arrayMateriasSubscription: Subscription;
  //Variables form descripcion
  public imagePath: any = null;
  public eventImage: any;
  public descripcionForm: FormGroup;
  public Editor = customBuild;
  public boolTabDescripcion: boolean = false;
  // Nuevo
  public columnasMateriaOferta: string[] = ['select', 'Clave', 'Nombre', 'TipoMateria'];
  public dataSourceMateriaOferta: MatTableDataSource<any>;
  selectionMateriaOferta = new SelectionModel<any>(true, []);
  public numMateriaSelected = 0;

  constructor(public dialogMateriaRef: MatDialogRef<ProgramaAcademicoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Materia,
    private materiaService: MateriaService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private programaAcademicoService: ProgramaAcademicoService,
    private changeDetector: ChangeDetectorRef,
    private snackService: SnackService,) { }

  ngOnInit(): void {
    this.materiaOfertaList = this.data.materiaOferta;
    this.dataSourceMateriaOferta = new MatTableDataSource(this.materiaOfertaList);
    this.selectionMateriaOferta = new SelectionModel<any>(true, []);
    this.arrayMaterias = this.data.materiasEnviar;
    this.arrayMaterias.sort((a, b) => (a.clave < b.clave) ? -1 : 1);
    // let sortedCompany = company.sort((a, b) => (a.name > b.name) ? -1 : 1);

    this.arrayMateriasSeriacion = this.data.seriacionMateria;
    this.validarTabEdicion();
    this.obtenerTipoDuracion();
    this.obtenerAreaMateria();
    this.obtenerTipoMateria();
    this.obtenerTipoInstalacion();
    this.obtenerTipoImparticion();
    this.obtenerTipoEquivalencia();
    this.inicializarForm();
  }

  public onChangeTab(event: MatTabChangeEvent) {
    let tab: number = event.index;    
    this.tabSeleccionado = tab;
    if (event.index === 2) {
      this.boolTabMateriasOferta = true;
    } else if (event.index !== 2) {
      this.boolTabMateriasOferta = false;
      this.boolAgregarMateriaOferta = false;
      this.boolCambio = false;
    }
  }

  public validarTabEdicion() {
    if (this.data.tipoAccion === AccionEnum.EDITAR) {
      this.boolDesabilitarTab = false;
      this.incializarFormDescripcion();
    }
  }

  public obtenerTipoDuracion() {
    this.materiaService.obtenerTipoDuracion().subscribe(
      (tipoDuracion: TipoDuracion[]) => {
        this.tipoDuracionList = tipoDuracion;
      });
  }

  public obtenerAreaMateria() {
    this.materiaService.obtenerAreaMateria().subscribe(
      (areaMateria: AreaMateria[]) => {
        this.areaMateriaList = areaMateria;
        if (this.data.areaMateriaId === 0) {
          this.materiaForm.get("areaMateriaId").setValue(this.areaMateriaList[0].areaMateriaId);
        }
      });
  }

  public obtenerTipoImparticion() {
    this.materiaService.obtenerTipoImparticion().subscribe(
      (tipoImparticion: TipoImparticion[]) => {
        this.tipoImparticionList = tipoImparticion;
      });
  }

  public obtenerTipoInstalacion() {
    this.materiaService.obtenerTipoInstalacion().subscribe(
      (tipoInstalacion: TipoInstalacion[]) => {
        this.tipoInstalacionList = tipoInstalacion;
      });
  }

  public obtenerTipoMateria() {
    this.materiaService.obtenerTipoMateria().subscribe(
      (tipoMateria: TipoMateria[]) => {
        this.tipoMateriaList = tipoMateria;
      });
  }

  public obtenerTipoEquivalencia() {
    this.materiaService.obtenerTipoEquivalencia().subscribe(
      (tipoEquivalencia: TipoEquivalencia[]) => {
        this.tipoEquivalenciaList = tipoEquivalencia;
      });
  }
  //seriacionMateria = new FormControl([]);
  public inicializarForm() {    
    this.materiaForm = new FormGroup({
      clave: new FormControl(this.data.clave === '' ? '' : this.data.clave, [Validators.required]),
      nombre: new FormControl(this.data.nombre === '' ? '' : this.data.nombre, [Validators.required, Validators.maxLength(150)]),
      seriacionMateria: new FormControl([]),
      creditos: new FormControl(this.data.creditos, [Validators.required, Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]),
      hfe: new FormControl(this.data.hfe, [Validators.required, Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]),
      hei: new FormControl(this.data.hei, [Validators.required, Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]),
      th: new FormControl(this.data.th, [Validators.required, Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]),
      duracion: new FormControl(this.data.duracion === 0 ? '' : this.data.duracion, [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      tipoDuracionId: new FormControl(this.data.tipoDuracionId === 0 ? 1 : this.data.tipoDuracionId, [Validators.required]),
      tipoInstalacionId: new FormControl(this.data.tipoInstalacionId === 0 ? 1 : this.data.tipoInstalacionId, [Validators.required]),
      tipoMateriaId: new FormControl(this.data.tipoMateriaId === 0 ? 1 : this.data.tipoMateriaId, [Validators.required]),
      areaMateriaId: new FormControl(this.data.areaMateriaId === 0 ? 1: this.data.areaMateriaId, [Validators.required]),
      tipoImparticionId: new FormControl(this.data.tipoImparticionId === 0 ? 1 : this.data.tipoImparticionId, [Validators.required])
    });
    this.materiaForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
    });
    if (this.arrayMateriasSeriacion != "" && this.arrayMateriasSeriacion != null) {
      let matSelected: any[] = [];
      for (var i = 0; i < this.arrayMateriasSeriacion.length; i++) {
        matSelected.push(this.arrayMateriasSeriacion[i].materiaSeriacionId);
      }
      this.materiaForm.patchValue({
        seriacionMateria: matSelected
      });
      //this.seriacionMateria.setValue([...matSelected, 0]);
      this.boolCambio = false;
    }
  }

  get clave() { return this.materiaForm.get('clave'); }
  get nombre() { return this.materiaForm.get('nombre'); }
  get seriacion() { return this.materiaForm.get('seriacion'); }
  get creditos() { return this.materiaForm.get('creditos'); }
  get hfe() { return this.materiaForm.get('hfe'); }
  get hei() { return this.materiaForm.get('hei'); }
  get th() { return this.materiaForm.get('th'); }
  get duracion() { return this.materiaForm.get('duracion'); }
  get tipoDuracionId() { return this.materiaForm.get('tipoDuracionId'); }
  get tipoInstalacionId() { return this.materiaForm.get('tipoInstalacionId'); }
  get tipoMateriaId() { return this.materiaForm.get('tipoMateriaId'); }
  get areaMateriaId() { return this.materiaForm.get('areaMateriaId'); }
  get tipoImparticionId() { return this.materiaForm.get('tipoImparticionId'); }

  public cambiarVistaEditar(materiaGuardada: Materia) {
    this.boolDesabilitarTab = false;
    this.data.tipoAccion = 2;
    this.data.clave = materiaGuardada.clave;
    this.data.materiaId = materiaGuardada.materiaId;
    this.incializarFormDescripcion();
  }

  /**
   * Metodo para inicalizar el formulario de descripcion
   */
  public incializarFormDescripcion(): void {
    this.imagePath = this.data.imagenMateria;
    this.descripcionForm = new FormGroup({
      materiaId: new FormControl(this.data.materiaId),
      descripcion: new FormControl(this.data.tipoAccion === AccionEnum.CREAR ? '' : this.data.descripcion),
      imagenMateria: new FormControl(''),
      fotografiaFile: new FormControl('')
    });
    this.boolTabDescripcion = true;
  }
  // get descripcion() { return this.descripcionForm.get('descripcion'); }

  public enviar(): void {
    switch (this.data.tipoAccion) {
      case AccionEnum.CREAR:
        let datosMateria = Object.assign(this.materiaForm.value);
        datosMateria.cicloId = this.data.cicloId;
        console.log("DATOS MATERIA", datosMateria);
        this.materiaService.agregarMateria(datosMateria).subscribe(
          materiaGuardada => {
            if (materiaGuardada != null) {
              if (materiaGuardada.tipoMateriaId === 1) {
                let datosMateria = {
                  materiaId: materiaGuardada.materiaId,
                  clave: materiaGuardada.clave,
                  nombre: materiaGuardada.nombre,
                  tipoEquivalenciaId: 1
                }
                this.materiaService.agregarMateriaOferta(datosMateria).subscribe(
                  materiaGuardada => {
                    if (materiaGuardada != null) {
                      this.boolCambio = false;
                      this.boolAgregarMateriaOferta = false;
                      this.materiaOfertaList.push(materiaGuardada);
                      this.programaAcademicoService.invocarMetodoCiclos();
                    } else {
                      this.snackBar.open("Ha ocurrido un error al guardar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                    }
                  },
                  error => {
                    console.log("Error", error);
                    this.snackBar.open("Ha ocurrido un error al guardar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                  }
                );
              }
              //this.programaAcademicoService.invocarMetodoObtenerCiclos();
              this.programaAcademicoService.invocarMetodoCiclos();
              this.boolCambio = false;
              this.cambiarVistaEditar(materiaGuardada);
              this.datosMateriaGuardada = materiaGuardada;
              this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
            } else {
              this.snackBar.open("Ha ocurrido un error al guardar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
            }
          },
          error => {
            console.log("Error", error);
            this.snackBar.open("Ha ocurrido un error al guardar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        );
        break;
      case AccionEnum.EDITAR:
        let datosEdicion = Object.assign(this.materiaForm.value);
        datosEdicion.materiaId = this.data.materiaId;
        datosEdicion.tipoAccion = 2;
        this.materiaService.modificarMateria(datosEdicion).subscribe(
          materiaGuardada => {
            if (materiaGuardada != null) {
              // this.programaAcademicoService.invocarMetodoCiclos();
              //this.programaAcademicoService.invocarMetodoObtenerCiclos();
              this.programaAcademicoService.invocarMetodoCiclos();
              this.boolCambio = false;
              this.datosMateriaGuardada = materiaGuardada;
              this.snackBar.open("Se ha modificado la información.", "OK", { duration: 5000 });
            } else {
              this.snackBar.open("Ha ocurrido un error al modificar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
            }
          },
          error => {
            console.log("Error", error);
            this.snackBar.open("Ha ocurrido un error al modificar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        );
        break;
    }
  }

  /**
   * Metodo paraa enviar el tab de descripcion
   */
  public enviarDescripcion(): void {
    let datosMateria = Object.assign(this.descripcionForm.value);
    this.materiaService.agregarDescripcionMateria(datosMateria).subscribe((response: SimpleResponse) => {
      if (response.success) {
        this.programaAcademicoService.invocarMetodoCiclos();
        this.snackService.mostrarSnackBack("Se ha modificado la información.");
      }
      else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.dialogMateriaRef.close(null);
    }
  }

  public findNombreTipoEquivalencia(tipoEquivalenciaId: number) {
    return this.tipoEquivalenciaList.find(id => id.tipoEquivalenciaId === tipoEquivalenciaId).nombre;
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      if (this.boolAgregarMateriaOferta) {
        this.boolAgregarMateriaOferta = false;
        this.dialogoRefAdver.close();
        this.boolCambio = false;
      } else {
        this.dialogoRefAdver.close();
        this.dialogMateriaRef.close(null);
        this.boolCambio = false;
      }
    } else {
      this.dialogoRefAdver.close();
    }
  }

  /**
   * Metodo que se activa cuando cambia el valor del input tipo file
   * @param event
   * @returns
   */
  public preview(event) {
    if (event.length === 0)
      return;
    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imagePath = reader.result;
    }
    this.eventImage = event;
  }

  /**
   * Metodo que se activa cuando cambia el valor del input tipo file
   * @param event
   */
  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.descripcionForm.patchValue({
        fotografiaFile: file.name
      });
      reader.onload = () => {
        this.changeDetector.markForCheck();
        this.descripcionForm.patchValue({
          imagenMateria: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  }


  //Funciones para crear, editar y eliminar la materia oferta
  public crearMateriaOferta(tipoAccion) {
    this.tipoAccion = tipoAccion;
    this.boolAgregarMateriaOferta = true;
    this.inicializarFromMateriaOferta();
  }

  public editarMateriaOferta(materiaOferta, tipoAccion) {
    this.materiaOfertaId = materiaOferta.materiaOfertaId;
    this.materiaOfertaClave = materiaOferta.clave;
    this.tipoAccion = tipoAccion;
    this.boolAgregarMateriaOferta = true;
    this.inicializarFromMateriaOferta(materiaOferta);
  }

  public eliminarMateriaOferta(materiaOfertaId) {
    this.dialogoRefAdverMateria = this.dialog.open(this.dialogAdverteniaMateria, { disableClose: true });
    this.materiaOfertaId = materiaOfertaId;
  }

  public borrar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    this.dialogoRefAdverMateria = this.dialog.open(this.dialogAdverteniaMateria, { disableClose: true });
    // const dialogo = this.util.abrirDialogoLateral(EliminarCandidatoComponent, datos);
    // dialogo.afterClosed().subscribe(result => {
    //  if (!!result) {
    //  }
    // });
  }

  public cerrarModalAdvertenciaMateria(borrar: boolean) {
    if (borrar) {
      this.materiaService.eliminarMateriaOferta(this.materiaOfertaId).subscribe(
        materiaEliminada => {
          if (materiaEliminada) {
            this.materiaOfertaList = this.materiaOfertaList.filter(x => x.materiaOfertaId !== this.materiaOfertaId);
            this.dataSourceMateriaOferta = new MatTableDataSource(this.materiaOfertaList);
            this.selectionMateriaOferta = new SelectionModel<any>(true, []);
            this.dialogoRefAdverMateria.close();
            this.programaAcademicoService.invocarMetodoObtenerCiclos();
            this.snackBar.open("Se ha eliminado la información.", "OK", { duration: 5000 });
          } else {
            this.snackBar.open("Ha ocurrido un error al guardar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        },
        error => {
          console.log("Error", error);
          this.snackBar.open("Ha ocurrido un error al guardar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      );
    } else {
      this.dialogoRefAdverMateria.close();
    }
  }

  //Fin de funciones de materia oferta
  public regresarMateria() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.boolAgregarMateriaOferta = false;
    }
  }

  public inicializarFromMateriaOferta(materiaOferta?: MateriaOferta) {
    this.materiaOfertaForm = new FormGroup({
      claveMO: new FormControl(materiaOferta == null ? '' : materiaOferta.clave, [Validators.required]),
      nombreOfertaMO: new FormControl(materiaOferta == null ? '' : materiaOferta.nombre, [Validators.required]),
      tipoEquivalenciaId: new FormControl(materiaOferta == null ? 1 : materiaOferta.tipoEquivalenciaId, [Validators.required]),
    });

    this.materiaOfertaForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
    });
  }

  get claveMO() { return this.materiaOfertaForm.get('claveMO'); }
  get nombreOfertaMO() { return this.materiaOfertaForm.get('nombreOfertaMO'); }
  get tipoEquivalenciaId() { return this.materiaOfertaForm.get('tipoEquivalenciaId'); }

  public enviarMateriaOferta(tipoAccion) {
    this.tipoAccion = tipoAccion;
    switch (this.tipoAccion) {
      case AccionEnum.CREAR:
        let datosMateria = {
          materiaId: this.data.materiaId,
          clave: this.materiaOfertaForm.get('claveMO').value,
          nombre: this.materiaOfertaForm.get('nombreOfertaMO').value,
          tipoEquivalenciaId: this.materiaOfertaForm.get('tipoEquivalenciaId').value
        }
        this.materiaService.agregarMateriaOferta(datosMateria).subscribe(
          materiaGuardada => {
            if (materiaGuardada != null) {
              this.boolCambio = false;
              this.boolAgregarMateriaOferta = false;
              this.materiaOfertaList.push(materiaGuardada);
              this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
            } else {
              this.snackBar.open("Ha ocurrido un error al guardar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
            }
          },
          error => {
            console.log("Error", error);
            this.snackBar.open("Ha ocurrido un error al guardar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        );
        break;
      case AccionEnum.EDITAR:
        let datosMateriaOfertaEdicion = {
          materiaOfertaId: this.materiaOfertaId,
          nombre: this.materiaOfertaForm.get('nombreOfertaMO').value,
          tipoEquivalenciaId: this.materiaOfertaForm.get('tipoEquivalenciaId').value
        }
        this.materiaService.modificarMateriaOferta(datosMateriaOfertaEdicion).subscribe(
          materiaOfertaEditada => {
            if (materiaOfertaEditada != null) {
              this.boolCambio = false;
              this.boolAgregarMateriaOferta = false;
              let index = this.materiaOfertaList.map(mat => {
                return mat.materiaOfertaId;
              }).indexOf(this.materiaOfertaId);
              if (index !== -1) { this.materiaOfertaList[index] = materiaOfertaEditada; }
              this.snackBar.open("Se ha modificado la información.", "OK", { duration: 5000 });
            } else {
              this.snackBar.open("Ha ocurrido un error al modificar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
            }
          },
          error => {
            console.log("Error", error);
            this.snackBar.open("Ha ocurrido un error al modificar la materia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        );
        break;
    }
  }

  isAllSelected() {
    const numSelected = this.selectionMateriaOferta.selected.length;
    const numRows = this.dataSourceMateriaOferta.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selectionMateriaOferta.clear() :
      this.dataSourceMateriaOferta.data.forEach(row => this.selectionMateriaOferta.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.numMateriaSelected = this.selectionMateriaOferta.selected.length;
    return `${this.selectionMateriaOferta.isSelected(row) ? 'deselect' : 'select'} row ${row.materiaOfertaId + 1}`;
  }
}
