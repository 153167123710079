import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable } from 'rxjs';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { Programa } from '../../_models/programa';
import { AccionEnum } from '../../_models/accion.enum';

@Component({
  selector: 'app-programa-perfil-egresado',
  templateUrl: './programa-perfil-egresado.component.html',
  styleUrls: ['./programa-perfil-egresado.component.scss']
})
export class ProgramaPerfilEgresadoComponent implements OnInit, OnDestroy {

  @Input() datosPrograma: Programa;
  public idPrograma$: Observable<number>;
  public idPrograma: number;
  public idProgramaSubscription: Subscription;
  public perfilForm: FormGroup;

  constructor(private programaAcademicoService: ProgramaAcademicoService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.idPrograma$ = this.programaAcademicoService.obtenerIdPrograma$();
    this.idProgramaSubscription = this.idPrograma$.subscribe(id => this.idPrograma = id);
    console.log("datosPrograma5", this.datosPrograma);
    this.inicializarForm();
  }

  public inicializarForm() {
    this.perfilForm = new FormGroup({
      perfilEgresado: new FormControl(this.datosPrograma.infoPrograma.perfilEgresado === '' ? '' : this.datosPrograma.infoPrograma.perfilEgresado),
      campoLaboral: new FormControl(this.datosPrograma.infoPrograma.campoLaboral === '' ? '' : this.datosPrograma.infoPrograma.campoLaboral)
    });

    this.perfilForm.valueChanges.subscribe(val => {
      this.programaAcademicoService.definirBoolCambio(true);
    });
  }

  get perfilEgresado() { return this.perfilForm.get('perfilEgresado'); }
  get campoLaboral() { return this.perfilForm.get('campoLaboral'); }

  public modificarDatosPerfil() {
    let datosPerfil = Object.assign(this.perfilForm.value);
    datosPerfil.tabSeccion = "IPE";
    if (this.datosPrograma.tipoAccion === AccionEnum.EDITAR) {
      datosPerfil.ProgramaId = this.datosPrograma.programaId;
    } else if (this.datosPrograma.tipoAccion === AccionEnum.CREAR) {
      datosPerfil.ProgramaId = this.idPrograma;
    }
    console.log("datosPerfilEDIT", datosPerfil);
    this.programaAcademicoService.modificarInfoProgramaPerfil(datosPerfil).subscribe(
      editado => {
        if (editado) {
          this.programaAcademicoService.definirBoolCambio(false);
          this.programaAcademicoService.invocarMetodo();
          this.snackBar.open("Se ha modificado la información.", "OK", { duration: 5000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar el perfil. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al guardar el perfil. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

  ngOnDestroy(): void {
    if (this.idProgramaSubscription) {
      this.idProgramaSubscription.unsubscribe();
    }
  }

}
