import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { ProgramaAcademicoService } from '../_services/programa-academico.service'
import { Subscription } from 'rxjs';
import { Programa } from '../_models/programa';
import { Nivel } from '../_models/nivel';
import { NivelService } from '../_services/nivel.service';
import { ProgramaAcademicoEdicionComponent } from './programa-academico-edicion/programa-academico-edicion.component';
import { UtileriasService } from '../_services/utilerias.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AccionEnum } from '../_models/accion.enum';
import { ProgramaModalEliminarComponent } from './programa-modal-eliminar/programa-modal-eliminar.component';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { element } from 'protractor';
import { Campus } from '../_models';
import { CampusService } from '../_services';

@Component({
  selector: 'app-programa-academico',
  templateUrl: './programa-academico.component.html',
  styleUrls: ['./programa-academico.component.scss']
})
export class ProgramaAcademicoComponent implements OnInit, OnDestroy {

  @ViewChild('input', { static: false })
  inputFiltro: ElementRef;
  @ViewChild('allNivel') private allNivel: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  private subcripcionProgramas: Subscription;
  public programa: Programa;
  public listaProgramaTodo: Programa[];
  public listaProgramaActivo: Programa[];
  public listaProgramaInactivo: Programa[];
  public numPeriodoSeleccionado: number = 0;
  public infoCargado: boolean = false;
  public filtroForm: FormGroup;
  public listaProgramaActual: Programa[];
  public datafilter: any;
  private arrOriginal: any[] = [];
  public listaNivel: Nivel[] = [];
  public campusList: Campus[] = [];
  public claveNombre = new FormControl();
  public campusF = new FormControl();
  public NivelF = new FormControl();
  public EstatusF = new FormControl();
  public EstatusC: any[] = [{ nombre: 'Activo', valor: 1 }, { nombre: 'Inactivo', valor: 2 }];

  constructor(private programaAcademicoService: ProgramaAcademicoService,
    private nivelService: NivelService,
    public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    private campusService: CampusService) { }

  listData: MatTableDataSource<Programa>;
  displayedColumns: string[] = ['select', 'Clave', 'Nombre', 'Edicion', 'Campus', 'Nivel', 'Modalidad', 'Estatus', 'Plan'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<Programa>(true, []);

  ngOnInit() {
    if (this.programaAcademicoService.invMetodoSubscription == undefined) {
      this.programaAcademicoService.invMetodoSubscription = this.programaAcademicoService.invocarObtenerProgramas.subscribe(() => {
        this.obtenerProgramas();
      });
    }
    this.LlenarFiltros();
    this.obtenerProgramas();
    this.obtenerCampus();
    this.obtenerNiveles();
    this.filtroForm = new FormGroup({
      nombre: new FormControl(''),
      campus: new FormControl(0),
      nivel: new FormControl(0)
    })
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  public obtenerProgramas() {
    this.subcripcionProgramas = this.programaAcademicoService.obtenerProgramas().subscribe(
      (programas: any[]) => {
        programas = programas.map(obj => ({
          ...obj,
          infoPrograma: obj.infoPrograma ? obj.infoPrograma : null
        }))
        this.listaProgramaActual = programas;
        this.listaProgramaTodo = programas;
        this.arrOriginal = [...programas];
        this.datafilter = programas;
        this.listaProgramaActivo = programas.filter(x => x.estatus === true);
        this.listaProgramaInactivo = programas.filter(x => x.estatus === false);
        this.selection = new SelectionModel<Programa>(true, []);
        this.listData = new MatTableDataSource(this.listaProgramaTodo);
        this.listData.paginator = this.paginator;
        this.listData.filterPredicate = function (data, filter: string): boolean {
          return data.clave.toLowerCase().includes(filter) || data.nombreCorto.toLowerCase().includes(filter) || data.edicion.toLowerCase().includes(filter) || data.nivel.nombre.toLowerCase().includes(filter) || data.modalidad.nombre.toLowerCase().includes(filter);
        };
        this.infoCargado = true;
        console.log("listData", this.listData.data)
      }
    );
  }

  public obtenerNiveles(){
    this.nivelService.getNivel().subscribe((nivel:Nivel[])=>{
      this.listaNivel = nivel;
      console.log("NIVEL", nivel)
    })
  }

  public obtenerCampus() {
    this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      }
    )
  }

  public crear(): void {
    let datos: Programa = {
      programaId: 0,
      clave: '',
      edicion: '',
      estatus: false,
      nombre: '',
      nombreCorto: '',
      nivelId: 0,
      modalidadId: 0,
      fechaVigencia: null,
      tipoPeriodoId: 0,
      tipoAdmisionId: 0,
      semanaCiclo: 0,
      porcentajeRevalidacion: 0,
      escalaCalificacion: 0,
      calificacionMinima: 0,
      titulacionTesis: false,
      titulacionMateria: false,
      titulacionPromedio: false,
      titulacionExamen: false,
      titulacionCredito: false,
      tipoReconocimientoId: 0,
      numeroAcuerdo: '',
      fechaAcuerdo: null,
      campusId: 0,
      infoPrograma: {
        objetivoGeneral: '',
        objetivoEspecifico: '',
        perfilEgresado: '',
        campoLaboral: ''
      },
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(ProgramaAcademicoEdicionComponent, datos);
  }

  public editar(elemento): void {
    let datos: Programa = {
      programaId: elemento.programaId,
      clave: elemento.clave,
      edicion: elemento.edicion,
      estatus: elemento.estatus,
      nombre: elemento.nombre,
      nombreCorto: elemento.nombreCorto,
      nivelId: elemento.nivelId,
      modalidadId: elemento.modalidadId,
      fechaVigencia: elemento.fechaVigencia,
      tipoPeriodoId: elemento.tipoPeriodoId === null ? 0 : elemento.tipoPeriodoId,
      semanaCiclo: elemento.semanaCiclo === null ? 0 : elemento.semanaCiclo,
      porcentajeRevalidacion: elemento.porcentajeRevalidacion === null ? 0 : elemento.porcentajeRevalidacion,
      escalaCalificacion: elemento.escalaCalificacion === null ? 0 : elemento.escalaCalificacion,
      calificacionMinima: elemento.calificacionMinima === null ? 0 : elemento.calificacionMinima,
      titulacionTesis: elemento.titulacionTesis === null ? false : elemento.titulacionTesis,
      titulacionMateria: elemento.titulacionMateria === null ? false : elemento.titulacionMateria,
      titulacionPromedio: elemento.titulacionPromedio === null ? false : elemento.titulacionPromedio,
      titulacionExamen: elemento.titulacionExamen === null ? false : elemento.titulacionExamen,
      titulacionCredito: elemento.titulacionCredito === null ? false : elemento.titulacionCredito,
      tipoReconocimientoId: elemento.tipoReconocimientoId === null ? 0 : elemento.tipoReconocimientoId,
      numeroAcuerdo: elemento.numeroAcuerdo === null ? '' : elemento.numeroAcuerdo,
      fechaAcuerdo: elemento.fechaAcuerdo,
      campusId: elemento.campusId === null ? 0 : elemento.campusId,
      tipoAdmisionId: elemento.tipoAdmisionId === null ? 0 : elemento.tipoAdmisionId,
      tipoAccion: AccionEnum.EDITAR
    }
    if (elemento.infoPrograma === null) {
      datos.infoPrograma = {
        objetivoGeneral: '',
        objetivoEspecifico: '',
        perfilEgresado: '',
        campoLaboral: ''
      }
    } else {
      datos.infoPrograma = {
        objetivoGeneral: elemento.infoPrograma.objetivoGeneral === null ? '' : elemento.infoPrograma.objetivoGeneral,
        objetivoEspecifico: elemento.infoPrograma.objetivoEspecifico === null ? '' : elemento.infoPrograma.objetivoEspecifico,
        perfilEgresado: elemento.infoPrograma.perfilEgresado === null ? '' : elemento.infoPrograma.perfilEgresado,
        campoLaboral: elemento.infoPrograma.campoLaboral === null ? '' : elemento.infoPrograma.campoLaboral
      }
    }
    const dialogo = this.util.abrirDialogoLateral(ProgramaAcademicoEdicionComponent, datos);
  }

  public borrar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogo = this.util.abrirDialogoLateral(ProgramaModalEliminarComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        //this.periodo = result;
      }
    });
  }

  public editarPlanEstudios(elemento: any, solicitud: number) {
    elemento.solicitudCargaDoc = solicitud;
    elemento.tipoAccion = 2;
    this.util.abrirDialogoLateral(ProgramaAcademicoEdicionComponent, elemento);
  }

  public onChangeActivo(value) {
    this.inputFiltro.nativeElement.value = "";
    this.numPeriodoSeleccionado = 0;
    this.selection = new SelectionModel<Programa>(true, []);
    if (value === 'Todos') {
      this.listaProgramaActual = [...this.listaProgramaTodo];
      this.listData = new MatTableDataSource(this.listaProgramaTodo);
      this.listData.paginator = this.paginator;
    } else if (value === 'Activo') {
      this.listaProgramaActual = [...this.listaProgramaActivo];
      this.listData = new MatTableDataSource(this.listaProgramaActivo);
      this.listData.paginator = this.paginator;
    } else if (value === 'Inactivo') {
      this.listaProgramaActual = [...this.listaProgramaInactivo];
      this.listData = new MatTableDataSource(this.listaProgramaInactivo);
      this.listData.paginator = this.paginator;
    }
  }

  /**
   * filtro
   */
  public filtro() {
    let nombre: string = this.filtroForm['value'].nombre;
    let nivel: number = this.filtroForm['value'].nivel;
    let arrFiltrado: any[] = [...this.listaProgramaActual];

    if (nivel > 0) {
      arrFiltrado = arrFiltrado.filter(f => { console.log(nivel); return nivel == f.nivelId });
    }
    if (nombre.length > 0) {
      arrFiltrado = arrFiltrado.filter(el => {
        return el.nombre.toLowerCase().search(nombre.toLowerCase()) > -1 || el.clave.toString().search(nombre) > -1;
      })
    }
    this.listData = new MatTableDataSource(arrFiltrado);
    this.listData.paginator = this.paginator;
  }

  //Llenar filtros
  public filtros() {
    let estatus: number = this.EstatusF.value == null ? 0 : this.EstatusF.value;
    let campus: Campus[] = this.campusF.value == null ? [] : this.campusF.value;
    let nombre: string = this.claveNombre.value == null ? "" : this.claveNombre.value;
    let nivel: any[] = this.NivelF.value == null ? [] : this.NivelF.value;
    let arrFiltrado: any = [];
    arrFiltrado = [...this.arrOriginal];

    if (estatus !== 0) {
      let estatusV = estatus === 1 ? true : false;
      arrFiltrado = arrFiltrado.filter(f => { return f.estatus === estatusV });
    }
    if (nombre.length > 0) {
      arrFiltrado = arrFiltrado.filter(el => {
        return el.nombreCorto.toLowerCase().search(nombre.toLowerCase()) > -1 || el.clave.toString().search(nombre) > -1;
      })
    }
    if (campus.length > 0) {
      arrFiltrado = arrFiltrado.filter(camp => { return campus.indexOf(camp.campusId) > -1 });
    }
    if (nivel.length > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return nivel.indexOf(f.nivelId) > -1 });
    }
    this.listData = new MatTableDataSource(arrFiltrado);
    this.listData.paginator = this.paginator;
  }

  public LlenarFiltros() {
    this.nivelService.getNivel().subscribe((nivel: Nivel[]) => {
      this.listaNivel = nivel;
      this.NivelF.setValue([...this.listaNivel.map(item => item.nivelId), 0]);
      this.filtros();
    });
    this.campusService.obtenerCampus().subscribe((campus: Campus[]) => {
      this.campusList = campus;
      this.campusF.setValue([...this.campusList.map(item => item.campusId), 0]);
      this.filtros();
    })
  }

  tosslePerOneCampus(all) {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.campusF.value.length == this.campusList.length) { this.allCampus.select(); }
    this.filtros();
  }

  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.campusF.patchValue([...this.campusList.map(item => item.campusId), 0]);
    } else {
      this.campusF.patchValue([]);
    }
    this.filtros();
  }

  tosslePerOneNivel(all) {
    if (this.allNivel.selected) {
      this.allNivel.deselect();
      return false;
    }
    if (this.NivelF.value.length == this.listaNivel.length) { this.allNivel.select(); }
    this.filtros();
  }

  toggleAllSelectionNivel() {
    if (this.allNivel.selected) {
      this.NivelF.patchValue([...this.listaNivel.map(item => item.nivelId), 0]);
    } else {
      this.NivelF.patchValue([]);
    }
    this.filtros();
  }

  // funcion para descargar el archivo de excel segun los datos filtrados
  ExcelSubscription: Subscription;
  public descargarExcel(): void {
    this.ExcelSubscription = this.programaAcademicoService.descargarExcel(this.arrOriginal).subscribe((res: Blob) => {
      let filename: string = "Programa Académico.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.listData.filter = filterValue;
    if (this.listData.paginator) {
      this.listData.paginator.firstPage();
    }
  }

  isAllSelected() {
    this.numPeriodoSeleccionado = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return this.numPeriodoSeleccionado === numRows;
  }

  checkboxLabel(row?: Programa): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.listData.data.forEach(row => this.selection.select(row));
  }

  ngOnDestroy(): void {
    if (this.subcripcionProgramas) {
      this.subcripcionProgramas.unsubscribe();
    }
  }

}
