import { ReportsEmbedModule } from './clean-architecture/UI/pages/reports-embed/reports-embed.module';
import { LanguageModule } from './clean-architecture/UI/common/content/language/language.module';
import { ServicioPaqueteModule } from './servicio-paquete/servicio-paquete.module';
import { EscolaresModule } from './reportes/escolares.module';
import { CuponModule } from './cupon/cupon.module';
import { ProgramaAcademicoSeguimientoComponent } from './administracion-academica/avance-academico-modal/programa-academico/programa-academico-seguimiento/programa-academico-seguimiento.component';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEsMx from '@angular/common/locales/es-MX';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { defaultInterpolationFormat, I18NextModule, I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import * as echarts from 'echarts';
import LanguageDetector from 'i18next-browser-languagedetector';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import HttpApi from 'i18next-http-backend';
import 'moment/locale/es';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CountdownModule } from 'ngx-countdown';
import { NgxEchartsModule } from 'ngx-echarts';
import { MomentModule } from 'ngx-moment';
import { baseUrl, environment } from 'src/environments/environment';
import { ActivacionComponent } from './activacion/activacion.component';
import { AdministracionAcademicaComponent } from './administracion-academica/administracion-academica.component';
import { AvanceAcademicoModalComponent } from './administracion-academica/avance-academico-modal/avance-academico-modal.component';
import { BoletaComponent } from './administracion-academica/avance-academico-modal/boleta/boleta.component';
import { CambioCalificacionesComponent } from './administracion-academica/avance-academico-modal/cambio-calificaciones/cambio-calificaciones.component';
import { ModalCambioCalificacionComponent } from './administracion-academica/avance-academico-modal/CambioCalificaciones/modal-cambio-calificacion/modal-cambio-calificacion.component';
import { DatosPersonalesAdminAcademicaComponent } from './administracion-academica/avance-academico-modal/datos-personales-admin-academica/datos-personales-admin-academica.component';
import { HistorialAcademicoComponent } from './administracion-academica/avance-academico-modal/historial-academico/historial-academico.component';
import { MasteriasReprobadasComponent } from './administracion-academica/avance-academico-modal/masterias-reprobadas/masterias-reprobadas.component';
import { ModalEnviarCorreoComponent } from './administracion-academica/modal-enviar-correo/modal-enviar-correo.component';
import { AdmisionAlumnoComponent } from './admisiones-alumno/admision-alumno/admision-alumno.component';
import { AdmisionContenidoComponent } from './admisiones-alumno/admision-alumno/admision-contenido/admision-contenido.component';
import { ApoyoFinancieroAdmisionComponent } from './admisiones-alumno/admision-alumno/admision-contenido/apoyo-financiero-admision/apoyo-financiero-admision.component';
import { DatosAdmisionComponent } from './admisiones-alumno/admision-alumno/admision-contenido/datos-admision/datos-admision.component';
import { DatosPersonalesAdmisionComponent } from './admisiones-alumno/admision-alumno/admision-contenido/datos-personales-admision/datos-personales-admision.component';
import { DocumentosAdmisionComponent } from './admisiones-alumno/admision-alumno/admision-contenido/documentos-admision/documentos-admision.component';
import { InscripcionAdmisionComponent } from './admisiones-alumno/admision-alumno/admision-contenido/inscripcion-admision/inscripcion-admision.component';
import { SeleccionMateriasAdmisionComponent } from './admisiones-alumno/admision-alumno/admision-contenido/seleccion-materias-admision/seleccion-materias-admision.component';
import { AdmisionEventosComponent } from './admisiones-alumno/admision-alumno/admision-eventos/admision-eventos.component';
import { AdmisionStepperComponent } from './admisiones-alumno/admision-alumno/admision-stepper/admision-stepper.component';
import { RegistrarSolicitanteComponent } from './admisiones-alumno/registrar-solicitante/registrar-solicitante.component';
import { AdmisionesComponent } from './admisiones/admisiones.component';
import { ConfiguracionApoyoFinancieroComponent } from './apoyo-financiero/apoyo-financiero-configuracion/configuracion-apoyo-financiero.component';
import { ApoyoDatosUsuarioComponent } from './apoyo-financiero/apoyo-financiero-solicitud/apoyo-financiero-solicitud-modal/apoyo-financiero-datos-usuario/apoyo-datos-usuario.component';
import { ApoyoEliminarAsignarSolicitudComponent } from './apoyo-financiero/apoyo-financiero-solicitud/apoyo-financiero-solicitud-modal/apoyo-financiero-eliminar-asignar-solicitud/apoyo-eliminar-asignar-solicitud.component';
import { NuevoSolicitanteComponent } from './apoyo-financiero/apoyo-financiero-solicitud/apoyo-financiero-solicitud-modal/apoyo-financiero-nuevo-solicitante/nuevo-solicitante.component';
import { SituacionEconomicaComponent } from './apoyo-financiero/apoyo-financiero-solicitud/apoyo-financiero-solicitud-modal/apoyo-financiero-situacion-economica/situacion-economica.component';
import { ApoyoFinancieroComponent } from './apoyo-financiero/apoyo-financiero-solicitud/apoyo-financiero-solicitud-modal/apoyo-financiero/apoyo-financiero.component';
import { SolicitudApoyoModalComponent } from './apoyo-financiero/apoyo-financiero-solicitud/apoyo-financiero-solicitud-modal/solicitud-apoyo-modal.component';
import { SolicitudApoyoFinancieroComponent } from './apoyo-financiero/apoyo-financiero-solicitud/solicitud-apoyo-financiero.component';
import { TipoApoyoFinancieroCrearEditarComponent } from './apoyo-financiero/apoyo-financiero-tipo/apoyo-financiero-tipo-crear-editar/tipo-apoyo-financiero-crear-editar.component';
import { TipoApoyoFinancieroEliminarComponent } from './apoyo-financiero/apoyo-financiero-tipo/apoyo-financiero-tipo-eliminar/tipo-apoyo-financiero-eliminar.component';
import { TipoApoyoFinancieroComponent } from './apoyo-financiero/apoyo-financiero-tipo/tipo-apoyo-financiero.component';
import { AppComponent } from './app.component';
import { AulaEliminarComponent } from './aula/aula-eliminar/aula-eliminar.component';
import { AulaComponent } from './aula/aula.component';
import { ModalAulaComponent } from './aula/modal-aula/modal-aula.component';
import { AcademicInformationTabComponent, GeneralInfoCampaignTabComponent } from './campaigns/campaign-detail-modal';
import { CampaignDetailModalComponent, DeleteCampaignComponent } from './campaigns/index';
import { CampusCreacionComponent } from './campus/campus-creacion/campus-creacion.component';
import { CampusEdicionComponent } from './campus/campus-edicion/campus-edicion.component';
import { CampusEliminarComponent } from './campus/campus-eliminar/campus-eliminar.component';
import { CampusComponent } from './campus/campus.component';
import { ConfiguracionColegiaturaComponent } from './configuracion-cuotas/configuracion-colegiatura/configuracion-colegiatura.component';
import { ModalColegiaturaComponent } from './configuracion-cuotas/configuracion-colegiatura/modal-colegiatura/modal-colegiatura.component';
import { ConfiguracionCuotasComponent } from './configuracion-cuotas/configuracion-cuotas.component';
import { ConfiguracionInscripcionComponent } from './configuracion-cuotas/configuracion-inscripcion/configuracion-inscripcion.component';
import { ModalInscripcionComponent } from './configuracion-cuotas/configuracion-inscripcion/modal-inscripcion/modal-inscripcion.component';
import { ConfiguracionPagosComponent } from './configuracion-cuotas/configuracion-pagos/configuracion-pagos.component';
import { ModalPagosComponent } from './configuracion-cuotas/configuracion-pagos/modal-pagos/modal-pagos.component';
import { ConfiguracionReembolsosComponent } from './configuracion-cuotas/configuracion-reembolsos/configuracion-reembolsos.component';
import { ModalReembolsosComponent } from './configuracion-cuotas/configuracion-reembolsos/modal-reembolsos/modal-reembolsos.component';
import { ConfiguracionServiciosComponent } from './configuracion-cuotas/configuracion-servicios/configuracion-servicios.component';
import { ModalServiciosComponent } from './configuracion-cuotas/configuracion-servicios/modal-servicios/modal-servicios.component';
import { ConfiguracionInicialComponent } from './configuracion-inicial/configuracion-inicial.component';
import { ConfiguracionUsuariosComponent } from './configuracion-usuarios/configuracion-usuarios.component';
import { ModalEliminarUsuarioComponent } from './configuracion-usuarios/modal-eliminar-usuario/modal-eliminar-usuario.component';
import { EnviarCorreoUsuariosComponent } from './configuracion-usuarios/usuarios-modal/enviar-correo-usuarios/enviar-correo-usuarios.component';
import { UsuarioCuentaNuevaComponent } from './configuracion-usuarios/usuarios-modal/usuario-cuenta-nueva/usuario-cuenta-nueva.component';
import { UsuariosModalComponent } from './configuracion-usuarios/usuarios-modal/usuarios-modal.component';
import { UsuariosRegistrarComponent } from './configuracion-usuarios/usuarios-modal/usuarios-registrar/usuarios-registrar.component';
import { ContenidoCursoComponent } from './contenido-curso/contenido-curso/contenido-curso.component';
import { ParcialesModalComponent } from './contenido-curso/contenido-curso/modals/parciales-modal/parciales-modal.component';
import { RecursoModalComponent } from './contenido-curso/contenido-curso/modals/recurso-modal/recurso-modal.component';
import { TemaModalComponent } from './contenido-curso/contenido-curso/modals/tema-modal/tema-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DepartamentoComponent } from './departamento/departamento.component';
import { ModalDepartamentoComponent } from './departamento/modal-departamento/modal-departamento.component';
import { ModalEliminarDepartamentoComponent } from './departamento/modal-eliminar-departamento/modal-eliminar-departamento.component';
import { EdificioEliminarComponent } from './edificio/edificio-eliminar/edificio-eliminar.component';
import { EdificioComponent } from './edificio/edificio.component';
import { ModalEdificioComponent } from './edificio/modal-edificio/modal-edificio.component';
import { EnsenanzaAprendizajeAlumnoComponent } from './ensenanza-aprendizaje-alumno/ensenanza-aprendizaje-alumno.component';
import { ArchivosComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/archivos/archivos.component';
import { CalificacionesComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/calificaciones/calificaciones.component';
import { ConsultarComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/consultar/consultar.component';
import { CorreoComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/correo/correo.component';
import { MiCursoComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/mi-curso/mi-curso.component';
import { MiGrupoComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/mi-grupo/mi-grupo.component';
import { ModalCorreoComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/mi-grupo/modal-correo/modal-correo.component';
import { ModalDetalleComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/modal-detalle/modal-detalle.component';
import { ModalContestarExEnComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/por-hacer/modal-contestar-ex-en/modal-contestar-ex-en.component';
import { ModalExamenEncuestaComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/por-hacer/modal-examen-encuesta/modal-examen-encuesta.component';
import { ModalResultadoExComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/por-hacer/modal-resultado-ex/modal-resultado-ex.component';
import { PorHacerComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/por-hacer/por-hacer.component';
import { ContenidoArchivosComponent } from './ensenanza-aprendizaje/contenido/contenido-archivos/contenido-archivos.component';
import { ContenidoCalificacionesComponent } from './ensenanza-aprendizaje/contenido/contenido-calificaciones/contenido-calificaciones.component';
import { EditModeDirective } from './ensenanza-aprendizaje/contenido/contenido-calificaciones/editable/edit-mode.directive';
import { EditableOnEnterDirective } from './ensenanza-aprendizaje/contenido/contenido-calificaciones/editable/edit-on-enter.directive';
import { EditableComponent } from './ensenanza-aprendizaje/contenido/contenido-calificaciones/editable/editable.component';
import { ViewModeDirective } from './ensenanza-aprendizaje/contenido/contenido-calificaciones/editable/view-mode.directive';
import { FocusableDirective } from './ensenanza-aprendizaje/contenido/contenido-calificaciones/focusable.directive';
import { ContenidoConsultarCorreoComponent } from './ensenanza-aprendizaje/contenido/contenido-consultar/contenido-consultar-correo/contenido-consultar-correo.component';
import { ConsultarDetalleModalComponent } from './ensenanza-aprendizaje/contenido/contenido-consultar/contenido-consultar-detalle/consultar-detalle-modal/consultar-detalle-modal.component';
import { ContenidoConsultarDetalleComponent } from './ensenanza-aprendizaje/contenido/contenido-consultar/contenido-consultar-detalle/contenido-consultar-detalle.component';
import { ContenidoConsultarComponent } from './ensenanza-aprendizaje/contenido/contenido-consultar/contenido-consultar.component';
import { ContenidoMiCursoComponent } from './ensenanza-aprendizaje/contenido/contenido-mi-curso/contenido-mi-curso.component';
import { ContenidoMiGrupoComponent } from './ensenanza-aprendizaje/contenido/contenido-mi-grupo/contenido-mi-grupo.component';
import { TomaAsistenciaComponent } from './ensenanza-aprendizaje/contenido/contenido-mi-grupo/toma-asistencia/toma-asistencia.component';
import { ContenidoPorRevisarComponent } from './ensenanza-aprendizaje/contenido/contenido-por-revisar/contenido-por-revisar.component';
import { PorRevisarDetalleComponent } from './ensenanza-aprendizaje/contenido/contenido-por-revisar/por-revisar-detalle/por-revisar-detalle.component';
import { PorRevisarModalComponent } from './ensenanza-aprendizaje/contenido/contenido-por-revisar/por-revisar-modal/por-revisar-modal.component';
import { EnsenanzaAprendizajeComponent } from './ensenanza-aprendizaje/ensenanza-aprendizaje.component';
import { ModalDetalleEliminarComponent } from './ensenanza-aprendizaje/modals-recursos/modal-detalle-eliminar/modal-detalle-eliminar.component';
import { ModalEnlaceAdjuntoComponent } from './ensenanza-aprendizaje/modals-recursos/modal-enlace-adjunto/modal-enlace-adjunto.component';
import { ModalImportarExamenComponent } from './ensenanza-aprendizaje/modals-recursos/modal-importar-examen/modal-importar-examen.component';
import { ModalRecursosComponent } from './ensenanza-aprendizaje/modals-recursos/modal-recursos.component';
import { ModalTareaComponent } from './ensenanza-aprendizaje/modals-recursos/modal-tarea/modal-tarea.component';
import { ModalTemaComponent } from './ensenanza-aprendizaje/modals-recursos/modal-tema/modal-tema.component';
import { FunnelComponent } from './funnel/funnel.component';
import { HomeComponent } from './home/home.component';
import { ConfiguracionCreacionEdicionComponent } from './inscripcion/configuraciones/configuracion-creacion-edicion/configuracion-creacion-edicion.component';
import { ConfiguracionEliminarComponent } from './inscripcion/configuraciones/configuracion-eliminar/configuracion-eliminar.component';
import { ConfiguracionesComponent } from './inscripcion/configuraciones/configuraciones.component';
import { SeguimientoInscripcionCorreoComponent } from './inscripcion/seguimiento/seguimiento-correo/seguimiento-correo.component';
import { DatosPersonalesInscripcionComponent } from './inscripcion/seguimiento/seguimiento-modal/datos-personales-inscripcion/datos-personales-inscripcion.component';
import { HorarioComponent } from './inscripcion/seguimiento/seguimiento-modal/horario/horario.component';
import { PagoSeguimientoComponent } from './inscripcion/seguimiento/seguimiento-modal/pago-seguimiento/pago-seguimiento.component';
import { SeguimientoModalComponent } from './inscripcion/seguimiento/seguimiento-modal/seguimiento-modal.component';
import { SeleccionMateriasComponent } from './inscripcion/seguimiento/seguimiento-modal/seleccion-materias/seleccion-materias.component';
import { SeguimientoComponent } from './inscripcion/seguimiento/seguimiento.component';
import { InstitucionEdicionComponent } from './institucion/institucion-edicion/institucion-edicion.component';
import { InstitucionComponent } from './institucion/institucion.component';
import { DeleteLeadComponent, LeadDetailModalComponent } from './leads';
import { LeadsImportModalColumnsComponent } from './leads-import/leads-import-modal/leads-import-modal-columns/leads-import-modal-columns.component';
import { LeadsImportModalFileComponent } from './leads-import/leads-import-modal/leads-import-modal-file/leads-import-modal-file.component';
import { LeadsImportModalResultComponent } from './leads-import/leads-import-modal/leads-import-modal-result/leads-import-modal-result.component';
import { LeadsImportModalSelectComponent } from './leads-import/leads-import-modal/leads-import-modal-select/leads-import-modal-select.component';
import { LeadsImportModalComponent } from './leads-import/leads-import-modal/leads-import-modal.component';
import { LeadsImportComponent } from './leads-import/leads-import.component';
import {
  GeneralInformationTabComponent,
  LeadAcademicInterestComponent,
  LeadInteractionsTabComponent,
  LeadPersonalInformationTabComponent,
} from './leads/lead-detail-modal';
import { LeadsComponent } from './leads/leads.component';
import { LoginComponent } from './login/login.component';
import { BecaApoyoFinancieroComponent } from './nuevo-ingreso/nuevo-ingreso-modal/beca-apoyo-financiero/beca-apoyo-financiero.component';
import { CargarDocumentosComponent } from './nuevo-ingreso/nuevo-ingreso-modal/cargar-documentos/cargar-documentos.component';
import { DatosCompletosCandidatoComponent } from './nuevo-ingreso/nuevo-ingreso-modal/datos-completos-candidato/datos-completos-candidato.component';
import { DatosUsuarioComponent } from './nuevo-ingreso/nuevo-ingreso-modal/datos-usuario/datos-usuario.component';
import { EliminarCandidatoComponent } from './nuevo-ingreso/nuevo-ingreso-modal/eliminar-candidato/eliminar-candidato.component';
import { EnviarCorreoCandidatoComponent } from './nuevo-ingreso/nuevo-ingreso-modal/enviar-correo-candidato/enviar-correo-candidato.component';
import { NuevoIngresoModalComponent } from './nuevo-ingreso/nuevo-ingreso-modal/nuevo-ingreso-modal.component';
import { ProgramaAcademicoRegistroComponent } from './nuevo-ingreso/nuevo-ingreso-modal/programa-academico-registro/programa-academico-registro.component';
import { RegistrarUsuarioComponent } from './nuevo-ingreso/nuevo-ingreso-modal/registrar-usuario/registrar-usuario.component';
import { SeleccionMateriasCandidatoComponent } from './nuevo-ingreso/nuevo-ingreso-modal/seleccion-materias-candidato/seleccion-materias-candidato.component';
import { NuevoIngresoComponent } from './nuevo-ingreso/nuevo-ingreso.component';
import { RegistroBecaComponent } from './nuevo-ingreso/registro-beca/registro-beca.component';
import { OfertaAcademicaBloqueComponent } from './oferta-academica/oferta-academica-bloque/oferta-academica-bloque.component';
import { OfertaAcademicaGrupoComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-grupo.component';
import { FormCampusPeriodoComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-modal/form-campus-periodo/form-campus-periodo.component';
import { FormDuracionComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-modal/form-duracion/form-duracion.component';
import { FormEdicionGrupoComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-modal/form-edicion-grupo/form-edicion-grupo.component';
import { FormFrecuenciaHorarioComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-modal/form-frecuencia-horario/form-frecuencia-horario.component';
import { FormMateriaComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-modal/form-materia/form-materia.component';
import { FormProfesorAulaComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-modal/form-profesor-aula/form-profesor-aula.component';
import { FormProgramaAcademicoComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-modal/form-programa-academico/form-programa-academico.component';
import { ModalGrupoEliminarComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-modal/modal-grupo-eliminar/modal-grupo-eliminar.component';
import { OfertaAcademicaModalComponent } from './oferta-academica/oferta-academica-grupo/oferta-academica-modal/oferta-academica-modal.component';
import { OfertaAcademicaProfesorComponent } from './oferta-academica/oferta-academica-profesor/oferta-academica-profesor.component';
import { CargaDocumentosProfesorComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-busqueda-correo/carga-documentos-profesor/carga-documentos-profesor.component';
import { DatosBiograficosComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-busqueda-correo/datos-biograficos/datos-biograficos.component';
import { DatosFamiliaresProfesorComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-busqueda-correo/datos-familiares-profesor/datos-familiares-profesor.component';
import { DatosLaboralesComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-busqueda-correo/datos-laborales/datos-laborales.component';
import { FormCuentaRegistrarComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-busqueda-correo/form-cuenta-registrar/form-cuenta-registrar.component';
import { FormCuentaComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-busqueda-correo/form-cuenta/form-cuenta.component';
import { FormacionAcademicaProfesorComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-busqueda-correo/formacion-academica-profesor/formacion-academica-profesor.component';
import { OfertaProfesorBusquedaCorreoComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-busqueda-correo/oferta-profesor-busqueda-correo.component';
import { OfertaProfesorCorreoComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-correo/oferta-profesor-correo.component';
import { OfertaProfesorEdicionModalComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-edicion-modal/oferta-profesor-edicion-modal.component';
import { OfertaProfesorEliminarComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-eliminar/oferta-profesor-eliminar.component';
import { FormCargaDocumentosComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-modal/form-carga-documentos/form-carga-documentos.component';
import { FormDatosBiograficosComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-modal/form-datos-biograficos/form-datos-biograficos.component';
import { FormDatosContactoComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-modal/form-datos-contacto/form-datos-contacto.component';
import { FormDatosFamiliaresComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-modal/form-datos-familiares/form-datos-familiares.component';
import { FormDatosLaboralesComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-modal/form-datos-laborales/form-datos-laborales.component';
import { FormDatosPersonalesComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-modal/form-datos-personales/form-datos-personales.component';
import { FormEstudiosPreviosComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-modal/form-estudios-previos/form-estudios-previos.component';
import { OfertaProfesorModalComponent } from './oferta-academica/oferta-academica-profesor/oferta-profesor-modal/oferta-profesor-modal.component';
import { OfertaAcademicaComponent } from './oferta-academica/oferta-academica.component';
import { PaginaConstruccionComponent } from './pagina-construccion/pagina-construccion.component';
import { PagoLineaTemporalComponent } from './pago-linea-temporal/pago-linea-temporal.component';
import { PeriodoCreacionComponent } from './periodo/periodo-creacion/periodo-creacion.component';
import { PeriodoDetalleComponent } from './periodo/periodo-detalle/periodo-detalle.component';
import { PeriodoEdicionComponent } from './periodo/periodo-edicion/periodo-edicion.component';
import { PeriodoEliminarComponent } from './periodo/periodo-eliminar/periodo-eliminar.component';
import { PeriodoComponent } from './periodo/periodo.component';
import { ProgramaAcademicoEdicionComponent } from './programa-academico/programa-academico-edicion/programa-academico-edicion.component';
import { ProgramaAcademicoModalAgregarCicloComponent } from './programa-academico/programa-academico-modal-agregar-ciclo/programa-academico-modal-agregar-ciclo.component';
import { ProgramaAcademicoModalEdicionCicloComponent } from './programa-academico/programa-academico-modal-edicion-ciclo/programa-academico-modal-edicion-ciclo.component';
import { ProgramaAcademicoModalComponent } from './programa-academico/programa-academico-modal/programa-academico-modal.component';
import { ProgramaAcademicoComponent } from './programa-academico/programa-academico.component';
import { ProgramaDatosGeneralesComponent } from './programa-academico/programa-datos-generales/programa-datos-generales.component';
import { ProgramaEliminarCiclosComponent } from './programa-academico/programa-eliminar-ciclos/programa-eliminar-ciclos.component';
import { ProgramaEliminarMateriasComponent } from './programa-academico/programa-eliminar-materias/programa-eliminar-materias.component';
import { ProgramaInformacionAcuerdoComponent } from './programa-academico/programa-informacion-acuerdo/programa-informacion-acuerdo.component';
import { ProgramaModalEliminarComponent } from './programa-academico/programa-modal-eliminar/programa-modal-eliminar.component';
import { ProgramaObjetivosComponent } from './programa-academico/programa-objetivos/programa-objetivos.component';
import { ProgramaPerfilEgresadoComponent } from './programa-academico/programa-perfil-egresado/programa-perfil-egresado.component';
import { ProgramaPlanEstudiosComponent } from './programa-academico/programa-plan-estudios/programa-plan-estudios.component';
import { ProgramaRequerimientosGeneralesComponent } from './programa-academico/programa-requerimientos-generales/programa-requerimientos-generales.component';
import { RegistroComponent } from './registro/registro.component';
import { ReingresoHorarioMateriasComponent } from './reinscripcion/reingreso-horario-materias/reingreso-horario-materias.component';
import { ReingresoInscripcionCompletaComponent } from './reinscripcion/reingreso-inscripcion-completa/reingreso-inscripcion-completa.component';
import { ReingresoPagoCuotaInscripcionComponent } from './reinscripcion/reingreso-pago-cuota-inscripcion/reingreso-pago-cuota-inscripcion.component';
import { ReinscripcionComponent } from './reinscripcion/reinscripcion.component';
import { DatosPersonalesCarteraComponent } from './seguimiento-cartera/detalle-cartera/datos-personales-cartera/datos-personales-cartera.component';
import { DetalleCarteraComponent } from './seguimiento-cartera/detalle-cartera/detalle-cartera.component';
import { EstadoCuentaCarteraComponent } from './seguimiento-cartera/detalle-cartera/estado-cuenta-cartera/estado-cuenta-cartera.component';
import { MovimientosCarteraComponent } from './seguimiento-cartera/detalle-cartera/movimientos-cartera/movimientos-cartera.component';
import { SeguimientoCarteraComponent } from './seguimiento-cartera/seguimiento-cartera.component';
import { SeguimientoCorreoComponent } from './seguimiento-cartera/seguimiento-correo/seguimiento-correo.component';
import { ModalEliminarServicioComponent } from './tipo-servicio/modal-eliminar-servicio/modal-eliminar-servicio.component';
import { ModalTipoServicioComponent } from './tipo-servicio/modal-tipo-servicio/modal-tipo-servicio.component';
import { TipoServicioComponent } from './tipo-servicio/tipo-servicio.component';
import { UsuarioEdicionComponent } from './usuario-edicion/usuario-edicion.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { AceptDialogComponent } from './_components/acept-dialog/acept-dialog.component';
import { WarningAlertComponent } from './_components/alerts/warning-alert/warning-alert.component';
import { ApoyoFinancieroDocumentoComponent } from './_components/apoyo-financiero-documento/apoyo-financiero-documento.component';
import { ConfirmDialogComponentComponent } from './_components/confirm-dialog-component/confirm-dialog-component.component';
import { LoadingScreenComponent } from './_components/loading-screen/loading-screen.component';
import { ChangePasswordComponent } from './_components/nav-menu/change-password/change-password.component';
import { ChangePasswordComponentN } from './_components/nav-menu-n/change-password/change-password.component';
import { NavMenuComponent } from './_components/nav-menu/nav-menu.component';
import { NavMenuNComponent } from './_components/nav-menu-n/nav-menu-n.component';
import { PagoMateriasComponent } from './_components/pago-materias/pago-materias.component';
import { PagoZumaModalComponent } from './_components/pago-zuma-modal/pago-zuma-modal.component';
import { SidebarMenuComponent } from './_components/sidebar-menu/sidebar-menu.component';
import { SidebarMenuComponentN } from './_components/sidebar-menu-n/sidebar-menu-n.component';
import { UndoChangesConfirmationDialogComponent } from './_components/undo-changes-confirmation-dialog/undo-changes-confirmation-dialog.component';
import { ErrorHandlerService } from './_services/error-handler.service';
import { InsightsMonitoringServiceService } from './_services/insights-monitoring-service.service';
import { ErrorInterceptor } from './_shared/error.interceptor';
import { JwtInterceptor } from './_shared/jwt.interceptor';
import { LoadingScreenInterceptor } from './_shared/loading.interceptor';
import { PaginatorI18n } from './_shared/paginator-i18n';
import { AvanceCursoModule } from './avance-curso/avance-curso.module'
import { AtributosModule } from './atributos/atributos.module'
import { MakeUpExamModule } from "../app/clean-architecture/UI/pages/make-up-exam/make-up-exam.module";
import { AppRoutingModule } from './app.routing';


FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  resourceTimeGridPlugin,
]);

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export const protectedResourceMap: [string, string[]][] = [
  [
    environment.enves[baseUrl].AzureAD.resources.link4CampusApi.resourceUri,
    [environment.enves[baseUrl].AzureAD.resources.link4CampusApi.resourceScope],
  ],
];
import { ModalMateriasSeleccionadasComponent } from './_components/modal-materias-seleccionadas/modal-materias-seleccionadas.component';
import { EgresoComponent } from './egreso/egreso/egreso.component';
import { EgresoAlumnoComponent } from './egreso-alumno/egreso-alumno.component';
import { ContenidoProgramaAcademicoComponent } from './egreso-alumno/contenido/contenido-programa-academico/contenido-programa-academico.component';
import { ContenidoDatosPersonalesComponent } from './egreso-alumno/contenido/contenido-datos-personales/contenido-datos-personales.component';
import { EnviarCorreoComponent } from './_components/enviar-correo/enviar-correo.component';
import { DetalleEgresoModalComponent } from './egreso/contenido/detalle-egreso-modal/detalle-egreso-modal.component';
import { ProgramaAcademicoEgresoComponent } from './egreso/contenido/detalle-egreso-modal/programa-academico-egreso/programa-academico-egreso.component';
import { ModalTipoDePagoComponent } from './_components/modal-tipo-de-pago/modal-tipo-de-pago.component';
import { ModalRegistroComponent } from './_components/modal-registro/modal-registro.component';
import { ModalConfirmacionEstatusComponent } from './egreso/modal-confirmacion-estatus/modal-confirmacion-estatus.component';
import { DatosPersonalesModalComponent } from './_components/datos-personales-modal/datos-personales-modal.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { LeadsSourceTypeComponent } from './leads-source-type/leads-source-type.component';
import { LeadsSourceModalComponent } from './leads-source-type/leads-source-modal/leads-source-modal.component';
import { LeadsSourceModalEliminarComponent } from './leads-source-type/leads-source-modal-eliminar/leads-source-modal-eliminar.component';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { FrecuenciaPipe } from './pipes/frecuencia.pipe';
import { HorarioPipe } from './pipes/horario.pipe';
import { SalonPipe } from './pipes/salon.pipe';
import { EstadoCuentaAlumnoComponent } from './estado-cuenta-alumno/estado-cuenta-alumno.component';
import { TranscriptComponent } from './transcript/transcript.component';
import { BoletaCalificacionComponent } from './boleta-calificacion/boleta-calificacion.component';
import { DocumentosAdminAcademicaComponent } from './administracion-academica/avance-academico-modal/documentos-admin-academica/documentos-admin-academica.component';
import { DocumentosAlumnoComponent } from './documentos-alumno/documentos-alumno.component';
import { AdmisionesExpressComponent } from './admisiones-express/admisiones-express.component';
import { DatosPersonalesAdmisionExpressComponent } from './admisiones-express/datos-personales-admision-express/datos-personales-admision-express.component';
import { DatosFamiliaresAdmisionExpressComponent } from './admisiones-express/datos-familiares-admision-express/datos-familiares-admision-express.component';
import { DatosProgramasAdmisionExpressComponent } from './admisiones-express/datos-programas-admision-express/datos-programas-admision-express.component';
import { DatosEstudiosAdmisionExpressComponent } from './admisiones-express/datos-estudios-admision-express/datos-estudios-admision-express.component';
import { DatosDocumentosAdmisionExpressComponent } from './admisiones-express/datos-documentos-admision-express/datos-documentos-admision-express.component';
import { AcreditacionesComponent } from './administracion-academica/avance-academico-modal/acreditaciones/acreditaciones.component';
import { TazaInscripcionComponent } from './reportes/taza-inscripcion/taza-inscripcion.component';
import { AlumnosInscritosComponent } from './reportes/alumnos-inscritos/alumnos-inscritos.component';
import { CambiarDatosProgramaModalComponent } from './administracion-academica/avance-academico-modal/programa-academico/programa-academico-seguimiento/cambiar-datos-programa-modal/cambiar-datos-programa-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EstatusComponent } from './administracion-academica/avance-academico-modal/estatus/estatus.component';
import { CambioEstatusModalComponent } from './administracion-academica/avance-academico-modal/estatus/cambio-estatus-modal/cambio-estatus-modal.component';
import { ModalConsultarExamenEncuestaComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/consultar/modal-consultar-examen-encuesta/modal-consultar-examen-encuesta/modal-consultar-examen-encuesta.component';
import { TramitesComponent } from './Tramites/tramites.component';
import { TramitesModalComponent } from './Tramites/tramites-modal/tramites-modal.component';
import { TramitesBoletaComponent } from './Tramites/tramites-modal/tramites-boleta/tramites-boleta.component';
import { TramitesBajaMateriaComponent } from './Tramites/tramites-modal/tramites-baja-materia/tramites-baja-materia.component';
import { SolicitudCategoriaComponent } from './solicitudes/solicitud-categoria/solicitud-categoria.component';
import { SolicitudTipoComponent } from './solicitudes/solicitud-tipo/solicitud-tipo.component';
import { SolicitudSeguimientoComponent } from './solicitudes/solicitud-seguimiento/solicitud-seguimiento.component';
import { SolicitudCategoriaModalEdicionComponent } from './solicitudes/solicitud-categoria/solicitud-categoria-modal-edicion/solicitud-categoria-modal-edicion.component';
import { SolicitudCategoriaModalEliminarComponent } from './solicitudes/solicitud-categoria/solicitud-categoria-modal-eliminar/solicitud-categoria-modal-eliminar.component';
import { SolicitudTipoModalEdicionComponent } from './solicitudes/solicitud-tipo/solicitud-tipo-modal-edicion/solicitud-tipo-modal-edicion.component';
import { SolicitudTipoModalEliminarComponent } from './solicitudes/solicitud-tipo/solicitud-tipo-modal-eliminar/solicitud-tipo-modal-eliminar.component';
import { SolicitudAlumnoComponent } from './solicitudes/solicitud-alumno/solicitud-alumno.component';
import { SolicitudAlumnoModalEdicionComponent } from './solicitudes/solicitud-alumno/solicitud-alumno-modal-edicion/solicitud-alumno-modal-edicion.component';
import { SolicitudSeguimientoModalComponent } from './solicitudes/solicitud-seguimiento/solicitud-seguimiento-modal/solicitud-seguimiento-modal.component';
import { ProfesorSeguimientoComponent } from './administracion-academica/profesor/profesor-seguimiento/profesor-seguimiento.component';
import { ProfesorModalComponent } from './administracion-academica/profesor/profesor-modal/profesor-modal.component';
import { SeguimientoAcademicoAlumnoComponent } from './seguimiento-academico-alumno/seguimiento-academico-alumno.component';
import { SeguimientoAcademicoAlumnoModalComponent } from './seguimiento-academico-alumno/seguimiento-academico-alumno-modal/seguimiento-academico-alumno-modal.component';
import { ConsultarCalSeguimientoAcadenicoAlumnoComponent } from './seguimiento-academico-alumno/consultar-cal-seguimiento-acadenico-alumno/consultar-cal-seguimiento-acadenico-alumno.component';
import { DashboardAlumnoComponent } from './dashboard-alumno/dashboard-alumno.component';
import { CartasComponent } from './cartas-constancias/cartas/cartas.component';
import { CartasModalEdicionComponent } from './cartas-constancias/cartas/cartas-modal-edicion/cartas-modal-edicion.component';
import { CartasModalEliminarComponent } from './cartas-constancias/cartas/cartas-modal-eliminar/cartas-modal-eliminar.component';
import { OfertaAcademicaNombreBloqueComponent } from './oferta-academica/oferta-academica-nombre-bloque/oferta-academica-nombre-bloque.component';
import { EnsenanzaTabsAlumnoComponent } from './ensenanza-aprendizaje-alumno/vista-contenido/ensenanza-tabs-alumno/ensenanza-tabs-alumno.component';
import { OfertaAcademicaNombreBloqueModalEliminarComponent } from './oferta-academica/oferta-academica-nombre-bloque/oferta-academica-nombre-bloque-modal-eliminar/oferta-academica-nombre-bloque-modal-eliminar.component';
import { OfertaAcademicaNombreBloqueModalEdicionComponent } from './oferta-academica/oferta-academica-nombre-bloque/oferta-academica-nombre-bloque-modal-edicion/oferta-academica-nombre-bloque-modal-edicion.component';
import { EnsenanzaTabsProfesorComponent } from './ensenanza-aprendizaje/contenido/ensenanza-tabs-profesor/ensenanza-tabs-profesor.component';
import { AreaMateriaComponent } from './area-materia/area-materia.component';
import { ModalAreaMateriaComponent } from './area-materia/modal-area-materia/modal-area-materia.component';
import { ModalEliminarAreaMateriaComponent } from './area-materia/modal-eliminar-area-materia/modal-eliminar-area-materia.component';
import { OfertaCademicaBloqueModalEliminarComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal-eliminar/oferta-cademica-bloque-modal-eliminar.component';
import { WizardBotonLoginComponent } from './wizard-boton-login/wizard-boton-login.component';
import { OfertaCademicaBloqueModalComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal/oferta-cademica-bloque-modal.component';
import { RegistrarBloqueComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal/registrar-bloque/registrar-bloque.component';
import { EdicionBloqueComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal/edicion-bloque/edicion-bloque.component';
import { EdicionBloqueGrupoComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal/edicion-bloque/edicion-bloque-grupo/edicion-bloque-grupo.component';
import { EdicionBloqueAlumnoComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal/edicion-bloque/edicion-bloque-alumno/edicion-bloque-alumno.component';
import { EdicionBloqueGrupoEliminarComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal/edicion-bloque/edicion-bloque-grupo/edicion-bloque-grupo-eliminar/edicion-bloque-grupo-eliminar.component';
import { EdicionBloqueAlumnoEliminarComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal/edicion-bloque/edicion-bloque-alumno/edicion-bloque-alumno-eliminar/edicion-bloque-alumno-eliminar.component';
import { EdicionBloqueGrupoAgregarComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal/edicion-bloque/edicion-bloque-grupo/edicion-bloque-grupo-agregar/edicion-bloque-grupo-agregar.component';
import { AsignarBloqueModalComponent } from './oferta-academica/oferta-academica-grupo/asignar-bloque-modal/asignar-bloque-modal.component';
import { EdicionBloqueAlumnoAgregarComponent } from './oferta-academica/oferta-academica-bloque/oferta-cademica-bloque-modal/edicion-bloque/edicion-bloque-alumno/edicion-bloque-alumno-agregar/edicion-bloque-alumno-agregar.component';
// import { CategoriasCursosComponent } from './compra-cursos/categorias-cursos/categorias-cursos.component';
// import { ResultadosCursosComponent } from './compra-cursos/resultados-cursos/resultados-cursos.component';
import { CompraCursosModule } from './compra-cursos/compra-cursos.module';
import { NuevoIngresoAsignarBloqueModalComponent } from './nuevo-ingreso/nuevo-ingreso-asignar-bloque-modal/nuevo-ingreso-asignar-bloque-modal.component';
import { SeguimientoAsignarBloqueModalComponent } from './inscripcion/seguimiento/seguimiento-asignar-bloque-modal/seguimiento-asignar-bloque-modal.component';
// import { CompraCursoFichaCursoComponent } from './compra-cursos/compra-curso-ficha-curso/compra-curso-ficha-curso.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { ProgramaAcademicoCoordinadorComponent } from './programa-academico/programa-academico-coordinador/programa-academico-coordinador.component';
import { GenericTableModule } from './clean-architecture/UI/common/components/generic-table/generic-table.module';
import { SignatureComponent } from './_components/signature/signature.component';
import { PruebaZohoComponent } from './prueba-zoho/prueba-zoho.component';
import { DeclineEnrollmentComponent } from './clean-architecture/UI/common/modals/decline-enrollment/decline-enrollment.component';


export function appInit(i18next: ITranslationService) {
  return () =>
    i18next
      .use(HttpApi)
      .use(LanguageDetector)
      .init({
        whitelist: ['es', 'en', 'l4CV'],
        fallbackLng: environment.enves[baseUrl].defaultLang || 'es',
        debug: true,
        returnEmptyString: false,
        ns: ['translation'],
        interpolation: {
          format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
        },
        backend: {
          loadPath: 'locales/{{lng}}.{{ns}}.json',
        },
        // lang detection plugin options
        detection: {
          // order and from where user language should be detected
          order: ['querystring', 'cookie'],
          // keys or params to lookup language from
          lookupCookie: 'lang',
          lookupQuerystring: 'lng',
          // cache user language on
          caches: ['localStorage', 'cookie'],
          // optional expire and domain for set cookie
          cookieMinutes: 10080, // 7 days
        },
      });
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

// function MSALConfigFactory(): Configuration {
//   return {
//     auth: {
//       clientId: environment.AzureAD.auth.clientId,
//       authority: environment.AzureAD.auth.authority,
//       validateAuthority: true,
//       redirectUri: environment.AzureAD.auth.redirectUri,
//       postLogoutRedirectUri: environment.AzureAD.auth.postLogoutRedirectUri,
//       navigateToLoginRequestUrl: true,
//     },
//     cache: {
//       cacheLocation: <CacheLocation>environment.AzureAD.cache.cacheLocation,
//       storeAuthStateInCookie: isIE, // set to true for IE 11
//     },
//   };
// }

// function MSALAngularConfigFactory(): MsalAngularConfiguration {
//   return {
//     popUp: false,
//     consentScopes: [
//       environment.AzureAD.resources.link4CampusApi.resourceScope,
//       ...environment.AzureAD.scopes.loginRequest
//     ],
//     unprotectedResources: [
//       'https://localhost:44375/api/Registro'
//     ],
//     protectedResourceMap,
//     extraQueryParameters: {}
//   };
// }

registerLocaleData(localeEsMx, 'es-Mx');

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.enves[baseUrl].AzureAD.auth.clientId,
      authority: environment.enves[baseUrl].AzureAD.auth.authority,
      postLogoutRedirectUri: environment.enves[baseUrl].AzureAD.auth.postLogoutRedirectUri,
      redirectUri: environment.enves[baseUrl].AzureAD.auth.redirectUri,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    }
  });
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Registro`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Home/GetDatosInstitucionCargaInicial`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Home/GetCampusByUrl`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Home/GetProgramasByUrl`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Home/GetPeriodoByUrl`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Home/RegistrarUsuarioExpress`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Admisiones/PostAdmisionUsuario`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Nivel`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Home/ObtenerDocumentosProcesoByUrl`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/CompraCurso/CursoById`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/CompraCurso/PostUsuarioCompraCurso`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Cupon/ValidarCuponSolicitado`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Usuario/Me`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Institucion/ObtenerOpcionesBoton`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/ConsultaDatos/ConsultaDatosConstancia`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Institucion/getLanguagesByInstitution`, null);
  protectedResourceMap.set(`${environment.enves[baseUrl].apiUrl}/Institucion/getLanguages`, null);
  protectedResourceMap.set(
    `${environment.enves[baseUrl].apiUrl}/**`,
    [environment.enves[baseUrl].AzureAD.resources.link4CampusApi.resourceScope],
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        'user.read',
        'openid',
        'profile',
        environment.enves[baseUrl].AzureAD.resources.link4CampusApi.resourceScope,
      ]
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FrecuenciaPipe,
    HorarioPipe,
    SalonPipe,
    NavMenuComponent,
    NavMenuNComponent,
    SidebarMenuComponent,
    SidebarMenuComponentN,
    HomeComponent,
    AdmisionesComponent,
    OfertaAcademicaComponent,
    RegistroComponent,
    LoginComponent,
    LoadingScreenComponent,
    UsuarioEdicionComponent,
    DashboardComponent,
    UsuariosComponent,
    ActivacionComponent,
    ConfiguracionInicialComponent,
    InstitucionComponent,
    PeriodoComponent,
    CampusComponent,
    ProgramaAcademicoComponent,
    PeriodoEdicionComponent,
    CampusEdicionComponent,
    InstitucionEdicionComponent,
    ProgramaAcademicoEdicionComponent,
    NuevoIngresoComponent,
    DatosUsuarioComponent,
    RegistroBecaComponent,
    PeriodoCreacionComponent,
    PeriodoDetalleComponent,
    PeriodoEliminarComponent,
    ProgramaDatosGeneralesComponent,
    ProgramaRequerimientosGeneralesComponent,
    ProgramaInformacionAcuerdoComponent,
    ProgramaObjetivosComponent,
    ProgramaPerfilEgresadoComponent,
    ProgramaPlanEstudiosComponent,
    ProgramaAcademicoModalComponent,
    CampusEliminarComponent,
    ProgramaModalEliminarComponent,
    ProgramaAcademicoModalAgregarCicloComponent,
    OfertaAcademicaGrupoComponent,
    OfertaAcademicaBloqueComponent,
    OfertaAcademicaProfesorComponent,
    OfertaAcademicaModalComponent,
    FormCampusPeriodoComponent,
    FormProgramaAcademicoComponent,
    FormMateriaComponent,
    FormDuracionComponent,
    FormFrecuenciaHorarioComponent,
    FormProfesorAulaComponent,
    FormEdicionGrupoComponent,
    ModalGrupoEliminarComponent,
    FormDatosBiograficosComponent,
    FormDatosContactoComponent,
    FormDatosFamiliaresComponent,
    FormDatosLaboralesComponent,
    FormDatosPersonalesComponent,
    FormEstudiosPreviosComponent,
    OfertaProfesorModalComponent,
    FormCargaDocumentosComponent,
    OfertaProfesorModalComponent,
    TipoApoyoFinancieroComponent,
    TipoApoyoFinancieroCrearEditarComponent,
    TipoApoyoFinancieroEliminarComponent,
    ConfiguracionApoyoFinancieroComponent,
    SolicitudApoyoFinancieroComponent,
    SolicitudApoyoModalComponent,
    NuevoSolicitanteComponent,
    ApoyoFinancieroComponent,
    SituacionEconomicaComponent,
    OfertaProfesorEdicionModalComponent,
    OfertaProfesorCorreoComponent,
    NuevoIngresoModalComponent,
    RegistrarUsuarioComponent,
    CargarDocumentosComponent,
    DatosCompletosCandidatoComponent,
    OfertaProfesorBusquedaCorreoComponent,
    FormCuentaComponent,
    FormCuentaRegistrarComponent,
    CargaDocumentosProfesorComponent,
    DatosBiograficosComponent,
    DatosLaboralesComponent,
    ApoyoDatosUsuarioComponent,
    BecaApoyoFinancieroComponent,
    CampusCreacionComponent,
    OfertaProfesorEliminarComponent,
    FormacionAcademicaProfesorComponent,
    EnviarCorreoCandidatoComponent,
    EliminarCandidatoComponent,
    ApoyoEliminarAsignarSolicitudComponent,
    ProgramaAcademicoRegistroComponent,
    DatosFamiliaresProfesorComponent,
    SeguimientoCarteraComponent,
    SeleccionMateriasCandidatoComponent,
    DetalleCarteraComponent,
    DatosPersonalesCarteraComponent,
    EstadoCuentaCarteraComponent,
    MovimientosCarteraComponent,
    EnsenanzaAprendizajeComponent,
    SeguimientoCorreoComponent,
    ConfiguracionesComponent,
    SeguimientoComponent,
    ConfiguracionCreacionEdicionComponent,
    ConfiguracionEliminarComponent,
    SeguimientoModalComponent,
    SeguimientoInscripcionCorreoComponent,
    ContenidoCursoComponent,
    TemaModalComponent,
    HorarioComponent,
    SeleccionMateriasComponent,
    PagoSeguimientoComponent,
    SeguimientoInscripcionCorreoComponent,
    ConfiguracionCuotasComponent,
    ConfiguracionServiciosComponent,
    ConfiguracionInscripcionComponent,
    ConfiguracionColegiaturaComponent,
    ModalColegiaturaComponent,
    ModalInscripcionComponent,
    ModalServiciosComponent,
    RecursoModalComponent,
    ParcialesModalComponent,
    ChangePasswordComponent,
    ChangePasswordComponentN,
    EnsenanzaAprendizajeAlumnoComponent,
    MiCursoComponent,
    MiGrupoComponent,
    ArchivosComponent,
    ModalDetalleComponent,
    ContenidoMiCursoComponent,
    PorHacerComponent,
    ContenidoMiGrupoComponent,
    ModalRecursosComponent,
    ModalTemaComponent,
    CorreoComponent,
    ModalTareaComponent,
    ConfiguracionPagosComponent,
    ModalPagosComponent,
    ModalCorreoComponent,
    CalificacionesComponent,
    ConsultarComponent,
    ModalImportarExamenComponent,
    ModalEnlaceAdjuntoComponent,
    ModalExamenEncuestaComponent,
    ModalContestarExEnComponent,
    TomaAsistenciaComponent,
    ContenidoPorRevisarComponent,
    PorRevisarDetalleComponent,
    PorRevisarModalComponent,
    ModalResultadoExComponent,
    ContenidoCalificacionesComponent,
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    FocusableDirective,
    EditableOnEnterDirective,
    ModalDetalleEliminarComponent,
    ContenidoConsultarComponent,
    ContenidoArchivosComponent,
    ContenidoConsultarCorreoComponent,
    ContenidoConsultarDetalleComponent,
    ConsultarDetalleModalComponent,
    DatosPersonalesInscripcionComponent,
    AdministracionAcademicaComponent,
    ProgramaEliminarMateriasComponent,
    ProgramaEliminarCiclosComponent,
    ModalEnviarCorreoComponent,
    ProgramaAcademicoModalEdicionCicloComponent,
    ModalEnviarCorreoComponent,
    AvanceAcademicoModalComponent,
    HistorialAcademicoComponent,
    MasteriasReprobadasComponent,
    BoletaComponent,
    DatosPersonalesAdminAcademicaComponent,
    ConfiguracionReembolsosComponent,
    ModalReembolsosComponent,
    TipoServicioComponent,
    ModalTipoServicioComponent,
    CambioCalificacionesComponent,
    EdificioComponent,
    ModalEdificioComponent,
    EdificioEliminarComponent,
    ConfirmDialogComponentComponent,
    ModalEliminarServicioComponent,
    ModalCambioCalificacionComponent,
    AulaComponent,
    ModalAulaComponent,
    AulaEliminarComponent,
    DepartamentoComponent,
    ModalDepartamentoComponent,
    ModalEliminarDepartamentoComponent,
    ConfiguracionUsuariosComponent,
    UsuariosModalComponent,
    UsuariosRegistrarComponent,
    EnviarCorreoUsuariosComponent,
    PaginaConstruccionComponent,
    UndoChangesConfirmationDialogComponent,
    UsuarioCuentaNuevaComponent,
    PagoLineaTemporalComponent,
    ModalEliminarUsuarioComponent,
    WarningAlertComponent,
    AdmisionAlumnoComponent,
    RegistrarSolicitanteComponent,
    AdmisionStepperComponent,
    AdmisionContenidoComponent,
    AdmisionEventosComponent,
    DatosAdmisionComponent,
    DatosPersonalesAdmisionComponent,
    DocumentosAdmisionComponent,
    ApoyoFinancieroDocumentoComponent,
    ApoyoFinancieroAdmisionComponent,
    InscripcionAdmisionComponent,
    SeleccionMateriasAdmisionComponent,
    ReinscripcionComponent,
    AceptDialogComponent,
    PagoMateriasComponent,
    ReingresoPagoCuotaInscripcionComponent,
    ReingresoHorarioMateriasComponent,
    PagoZumaModalComponent,
    ModalMateriasSeleccionadasComponent,
    EgresoComponent,
    EgresoAlumnoComponent,
    ContenidoProgramaAcademicoComponent,
    ContenidoDatosPersonalesComponent,
    EnviarCorreoComponent,
    DetalleEgresoModalComponent,
    ProgramaAcademicoEgresoComponent,
    ModalTipoDePagoComponent,
    ModalRegistroComponent,
    ModalConfirmacionEstatusComponent,
    DatosPersonalesModalComponent,
    ReingresoInscripcionCompletaComponent,
    CampaignsComponent,
    LeadsComponent,
    LeadDetailModalComponent,
    GeneralInformationTabComponent,
    LeadPersonalInformationTabComponent,
    LeadAcademicInterestComponent,
    LeadInteractionsTabComponent,
    DeleteLeadComponent,
    LeadInteractionsTabComponent,
    LeadsSourceTypeComponent,
    LeadsSourceModalComponent,
    LeadsSourceModalEliminarComponent,
    EstadoCuentaAlumnoComponent,
    LeadsImportComponent,
    LeadsImportModalComponent,
    LeadsImportModalColumnsComponent,
    LeadsImportModalSelectComponent,
    LeadsImportModalFileComponent,
    LeadsImportModalResultComponent,
    LeadsSourceModalEliminarComponent,
    CampaignDetailModalComponent,
    GeneralInfoCampaignTabComponent,
    AcademicInformationTabComponent,
    DeleteCampaignComponent,
    FunnelComponent,
    TranscriptComponent,
    BoletaCalificacionComponent,
    DocumentosAdminAcademicaComponent,
    DocumentosAlumnoComponent,
    AdmisionesExpressComponent,
    DatosPersonalesAdmisionExpressComponent,
    DatosFamiliaresAdmisionExpressComponent,
    DatosProgramasAdmisionExpressComponent,
    DatosEstudiosAdmisionExpressComponent,
    DatosDocumentosAdmisionExpressComponent,
    TazaInscripcionComponent,
    AlumnosInscritosComponent,
    ProgramaAcademicoSeguimientoComponent,
    CambiarDatosProgramaModalComponent,
    AcreditacionesComponent,
    EstatusComponent,
    CambioEstatusModalComponent,
    ModalConsultarExamenEncuestaComponent,
    TramitesComponent,
    TramitesModalComponent,
    TramitesBoletaComponent,
    TramitesBajaMateriaComponent,
    SolicitudCategoriaComponent,
    SolicitudTipoComponent,
    SolicitudSeguimientoComponent,
    SolicitudCategoriaModalEdicionComponent,
    SolicitudCategoriaModalEliminarComponent,
    SolicitudTipoModalEdicionComponent,
    SolicitudTipoModalEliminarComponent,
    SolicitudAlumnoComponent,
    SolicitudAlumnoModalEdicionComponent,
    SolicitudSeguimientoModalComponent,
    ProfesorSeguimientoComponent,
    ProfesorModalComponent,
    SeguimientoAcademicoAlumnoComponent,
    SeguimientoAcademicoAlumnoModalComponent,
    ConsultarCalSeguimientoAcadenicoAlumnoComponent,
    DashboardAlumnoComponent,
    CartasComponent,
    CartasModalEdicionComponent,
    CartasModalEliminarComponent,
    OfertaAcademicaNombreBloqueComponent,
    EnsenanzaTabsAlumnoComponent,
    OfertaAcademicaNombreBloqueModalEliminarComponent,
    OfertaAcademicaNombreBloqueModalEdicionComponent,
    EnsenanzaTabsProfesorComponent,
    AreaMateriaComponent,
    ModalAreaMateriaComponent,
    ModalEliminarAreaMateriaComponent,
    OfertaCademicaBloqueModalEliminarComponent,
    WizardBotonLoginComponent,
    OfertaCademicaBloqueModalComponent,
    RegistrarBloqueComponent,
    EdicionBloqueComponent,
    EdicionBloqueGrupoComponent,
    EdicionBloqueAlumnoComponent,
    EdicionBloqueGrupoEliminarComponent,
    EdicionBloqueAlumnoEliminarComponent,
    AsignarBloqueModalComponent,
    EdicionBloqueGrupoAgregarComponent,
    EdicionBloqueAlumnoAgregarComponent,
    NuevoIngresoAsignarBloqueModalComponent,
    SeguimientoAsignarBloqueModalComponent,
    ProgramaAcademicoCoordinadorComponent,
    SignatureComponent,
    PruebaZohoComponent,
    DeclineEnrollmentComponent
    // CategoriasCursosComponent,
    // ResultadosCursosComponent,
    // CompraCursoFichaCursoComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    DragDropModule,
    FullCalendarModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MomentModule,
    CKEditorModule,
    CountdownModule,
    MatTabsModule,
    NgbModule,
    NgxPayPalModule,
    NgbPaginationModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    ChartsModule,
    I18NextModule.forRoot(),
    MatRadioModule,
    MatCardModule,
    MatInputModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatCardModule,
    MatSliderModule,
    MatDialogModule,
    MatFormFieldModule,
    MatButtonModule,
    MatExpansionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatIconModule,
    MatCheckboxModule,
    MatDividerModule,
    MatStepperModule,
    MatListModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatNativeDateModule,
    ClipboardModule,
    MatRippleModule,
    MatInputModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatChipsModule,
    PdfViewerModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTreeModule,
    CompraCursosModule,
    CuponModule,
    NgxMatColorPickerModule,
    EscolaresModule,
    AvanceCursoModule,
    ServicioPaqueteModule,    
    MakeUpExamModule,
    ReportsEmbedModule,
    AtributosModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'admisiones2', component: AdmisionesComponent, canActivate: [MsalGuard] },
      //{ path: 'oferta-academica', component: OfertaAcademicaComponent },
      { path: 'oferta-academica-grupo', component: OfertaAcademicaGrupoComponent },
      { path: 'oferta-academica-bloque', component: OfertaAcademicaBloqueComponent },
      { path: 'oferta-academica-nombre-bloque', component: OfertaAcademicaNombreBloqueComponent },
      { path: 'oferta-academica-profesor', component: OfertaAcademicaProfesorComponent },
      { path: 'login', component: LoginComponent, canActivate: [MsalGuard] },
      { path: 'home', component: HomeComponent },
      { path: 'signout', component: HomeComponent },
      { path: 'registro', component: RegistroComponent },
      { path: 'activacion/:token', component: ActivacionComponent },
      { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard] },
      { path: 'institucion', component: InstitucionComponent, canActivate: [MsalGuard] },
      { path: 'periodo', component: PeriodoComponent, canActivate: [MsalGuard] },
      { path: 'campus', component: CampusComponent, canActivate: [MsalGuard] },
      { path: 'programa-academico', component: ProgramaAcademicoComponent, canActivate: [MsalGuard] },
      { path: 'usuario', component: UsuariosComponent, canActivate: [MsalGuard] },
      { path: 'usuario/perfil/:id', component: UsuarioEdicionComponent, canActivate: [MsalGuard] },
      { path: 'nuevo-ingreso', component: NuevoIngresoComponent, canActivate: [MsalGuard] },
      //{ path: 'calendario', component: DetalleBloqueCalendarioComponent, canActivate: [MsalGuard] },
      { path: 'tipo-apoyo-financiero', component: TipoApoyoFinancieroComponent, canActivate: [MsalGuard] },
      { path: 'solicitud-apoyo-financiero', component: SolicitudApoyoFinancieroComponent, canActivate: [MsalGuard] },
      { path: 'seguimiento-cartera', component: SeguimientoCarteraComponent, canActivate: [MsalGuard] },
      { path: 'ensenanza-aprendizaje', component: EnsenanzaAprendizajeComponent, canActivate: [MsalGuard] },
      { path: 'configuraciones', component: ConfiguracionesComponent },
      { path: 'inscripcion', component: SeguimientoComponent },
      { path: 'configuracion-servicios', component: ConfiguracionServiciosComponent },
      { path: 'configuracion-inscripcion', component: ConfiguracionInscripcionComponent },
      { path: 'configuracion-colegiatura', component: ConfiguracionColegiaturaComponent },
      { path: 'configuracion-pagos', component: ConfiguracionPagosComponent },
      { path: 'contenido-curso', component: ContenidoCursoComponent },
      { path: 'contenido-curso/:id', component: ContenidoCursoComponent, canActivate: [MsalGuard] },
      { path: 'ensenanza-aprendizaje-Alumno', component: EnsenanzaAprendizajeAlumnoComponent },
      { path: 'administracion-academica', component: AdministracionAcademicaComponent },
      { path: 'configuracion-reembolsos', component: ConfiguracionReembolsosComponent },
      { path: 'tipo-servicio', component: TipoServicioComponent },
      { path: 'edificio', component: EdificioComponent },
      { path: 'aula', component: AulaComponent },
      { path: 'configuracion-usuarios', component: ConfiguracionUsuariosComponent },
      { path: 'departamento', component: DepartamentoComponent },
      { path: 'pagina-construccion', component: PaginaConstruccionComponent },
      { path: 'pago-linea-temporal', component: PagoLineaTemporalComponent },
      { path: 'admisiones/registro', component: RegistrarSolicitanteComponent },
      { path: 'admisiones', component: AdmisionAlumnoComponent },
      { path: 'reinscripcion', component: ReinscripcionComponent },
      { path: 'egreso', component: EgresoComponent },
      { path: 'egreso-alumno', component: EgresoAlumnoComponent },
      { path: 'campaign', component: CampaignsComponent },
      { path: 'leads', component: LeadsComponent },
      { path: 'leads-source', component: LeadsSourceTypeComponent },
      { path: 'leads-import', component: LeadsImportModalComponent },
      { path: 'funnel', component: FunnelComponent },
      { path: 'estado-cuenta-alumno', component: EstadoCuentaAlumnoComponent },
      { path: 'transcript', component: TranscriptComponent },
      { path: 'boleta', component: BoletaCalificacionComponent },
      { path: 'documentos-alumno', component: DocumentosAlumnoComponent },
      { path: 'admisiones-express', component: AdmisionesExpressComponent },
      { path: 'taza-inscripcion', component: TazaInscripcionComponent },
      { path: 'alumnos-inscrito', component: AlumnosInscritosComponent },
      { path: 'tramites', component: TramitesComponent, canActivate: [MsalGuard] },
      { path: 'solicitud-categoria', component: SolicitudCategoriaComponent },
      { path: 'solicitud-tipo', component: SolicitudTipoComponent },
      { path: 'solicitudes', component: SolicitudSeguimientoComponent },
      { path: 'mis-solicitudes', component: SolicitudAlumnoComponent },
      { path: 'profesor-seguimiento', component: ProfesorSeguimientoComponent },
      { path: 'seguimiento-alumno', component: SeguimientoAcademicoAlumnoComponent },
      { path: 'dashboard-alumno', component: DashboardAlumnoComponent },
      { path: 'cartas', component: CartasComponent },
      { path: 'wizard-boton', component: WizardBotonLoginComponent },
      { path: 'detalle-grupo-alumno/grupo/:grupoId/opcion/:opcionId/recurso/:recursoId', component: EnsenanzaTabsAlumnoComponent },
      { path: 'detalle-grupo-alumno/grupo/:grupoId/opcion/:opcionId', component: EnsenanzaTabsAlumnoComponent },
      { path: 'detalle-grupo-alumno/grupo/:grupoId', component: EnsenanzaTabsAlumnoComponent },
      { path: 'detalle-grupo-profesor/grupo/:grupoId/opcion/:opcionId/recurso/:recursoId', component: EnsenanzaTabsProfesorComponent },
      { path: 'detalle-grupo-profesor/grupo/:grupoId/opcion/:opcionId', component: EnsenanzaTabsProfesorComponent },
      { path: 'detalle-grupo-profesor/grupo/:grupoId', component: EnsenanzaTabsProfesorComponent },
      { path: 'area-materia', component: AreaMateriaComponent },
      { path: 'prueba-zoho', component: PruebaZohoComponent },
      // { path: 'ficha-curso', component: CompraCursoFichaCursoComponent },
      // { path: 'categorias-cursos', component: CategoriasCursosComponent },
      // { path: 'resultados-cursos', component: ResultadosCursosComponent },
      // { path: 'resultados-cursos', component: ResultadosCursosComponent },
      {
        path: 'compra-cursos',
        loadChildren: './compra-cursos/compra-cursos.module#CompraCursosModule'
      },
      {
        path: 'avance-curso',
        loadChildren: './avance-curso/avance-curso.module#AvanceCursoModule'
      },
      {
        path: 'atributos',
        loadChildren: './atributos/atributos.module#AtributosModule'
      },
      {
        path: 'servicio-paquete',
        loadChildren: './servicio-paquete/ServicioPaqueteModule.module#ServicioPaqueteModule'
      },
      {
        path: 'reportes',
        loadChildren: './reportes/escolares.module#EscolaresModule'
      },
      {
        path: 'extraordinario',
        loadChildren: './clean-architecture/UI/pages/make-up-exam/make-up-exam.module#MakeUpExamModule'
      },
    ], { relativeLinkResolution: 'legacy' }),
    GenericTableModule,
    LanguageModule
  ],
  exports: [MatPaginatorModule, MatSortModule, MatTableModule, MatNativeDateModule, MatRippleModule, MatCheckboxModule, ClipboardModule,],
  entryComponents: [
    UsuarioEdicionComponent,
    InstitucionEdicionComponent,
    CampusEdicionComponent,
    PeriodoEdicionComponent,
    PeriodoCreacionComponent,
    ProgramaAcademicoEdicionComponent,
  ],
  providers: [
    I18N_PROVIDERS,
    CurrencyPipe,
    DatePipe,
    InsightsMonitoringServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useValue: new PaginatorI18n().ObtenerIdioma() },
    { provide: LOCALE_ID, useValue: 'es-Mx' },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
