import { GruposDisponiblesParaMateriaDTO } from "../models/Inscripcion/GruposDisponiblesParaMateriaDTO.model";

export class BajaDTO
{
  identiticador: string;
  idPeriodo: number;
  cantidadDeCreditosAntesDeBaja: number;
  cantidadDeMateriasAntesDeBaja: number;
  grupos: Array<GruposDisponiblesParaMateriaDTO>;
}
