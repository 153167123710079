import { SnackService } from './../../services/snack-service.service';
import { TraductorService } from './../../_services/traductor.service';
import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { AccionEnum } from '../../_models/accion.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { DireccionService } from '../../_services/direccion.service';
import { CampusService } from '../../_services/campus.service';
import { LoadingScreenService } from '../../_services/loading-screen.service';
import { Campus } from '../../_models/campus';
import { Pais } from '../../_models/pais';
import { EntidadFederativa } from '../../_models/entidad-federativa';
import { UtileriasService } from '../../_services/utilerias.service';
import { CampusEdicionComponent } from '../campus-edicion/campus-edicion.component';


@Component({
  selector: 'app-campus-creacion',
  templateUrl: './campus-creacion.component.html',
  styleUrls: ['./campus-creacion.component.scss']
})
export class CampusCreacionComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public errorActualizaCampus: string = "";
  public campusId: number = 0;
  public objValidacionCancelar: Campus = null;
  public infoCargado: boolean = false;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public creacionCampusForm: FormGroup;
  public campus: Campus;
  public campusList: Campus[] = [];
  public ClaveEncontrada: boolean = false;
  public tipoAccion: number = 3;

  constructor(public dialogRef: MatDialogRef<CampusCreacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Campus,
    private direccionService: DireccionService,
    private campusService: CampusService,
    private snackBar: MatSnackBar,
    private util: UtileriasService,
    private dialog: MatDialog,
    private snackService: SnackService,
    private traductorService: TraductorService
  ) {
    //this.obtenerCampus();
    //console.log("Data", this.data);
    //this.boolCambio = false;
    //this.inicializarForm();
    //this.campusId = this.data.campusId;
    //this.creacionCampusForm.markAsUntouched();
    this.direccionService.obtenerPaises().subscribe(
      paises => {
        this.paises = paises;

        if (!!this.data.pais) {
          this.onPaisSelected(this.data.pais);
        }
        this.creacionCampusForm.patchValue(this.data);
        this.creacionCampusForm.markAsUntouched();
      }
    );
  }

  public inicializarForm() {
    this.creacionCampusForm = new FormGroup({
      //campusId: new FormControl(0),
      clave: new FormControl('', [Validators.required]),
      nombre: new FormControl('', [Validators.required]),
      entidadFederativaId: new FormControl('', [Validators.required]),
      pais: new FormControl('', [Validators.required]),
      ciudad: new FormControl('', [Validators.required]),
      calle: new FormControl('', [Validators.required]),
      numeroExterior: new FormControl('', [Validators.required]),
      //numeroInterior: new FormControl('', [Validators.required]),
      colonia: new FormControl('', [Validators.required]),
      codigoPostal: new FormControl('', [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      telefono: new FormControl('', [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      //extension: new FormControl('', [Validators.required]),
      directorEscolar: new FormControl('', [Validators.required]),
      rectorCampus: new FormControl('', [Validators.required]),
    });

    this.creacionCampusForm.valueChanges.subscribe((cambios) => {
      this.boolCambio = true;
    });
    /*this.creacionCampusForm.get("nombre").valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });*/
  }

  //get campusId() { return this.creacionCampusForm.get('campusId'); }
  get clave() { return this.creacionCampusForm.get('clave'); }
  get nombre() { return this.creacionCampusForm.get('nombre'); }
  //get logo() { return this.creacionCampusForm.get('logo'); }
  get pais() { return this.creacionCampusForm.get('pais'); }
  get entidadFederativaId() { return this.creacionCampusForm.get('entidadFederativaId'); }
  get ciudad() { return this.creacionCampusForm.get('ciudad'); }
  get calle() { return this.creacionCampusForm.get('calle'); }
  get numeroExterior() { return this.creacionCampusForm.get('numeroExterior'); }
  //get numeroInterior() { return this.creacionCampusForm.get('numeroInterior'); }
  get colonia() { return this.creacionCampusForm.get('colonia'); }
  get codigoPostal() { return this.creacionCampusForm.get('codigoPostal'); }
  get telefono() { return this.creacionCampusForm.get('telefono'); }
  //get extension() { return this.creacionCampusForm.get('extension'); }
  get directorEscolar() { return this.creacionCampusForm.get('directorEscolar'); }
  get rectorCampus() { return this.creacionCampusForm.get('rectorCampus'); }

  get esNueva() { return !this.clave }

  public paises: Pais[] = [];
  public estados: EntidadFederativa[] = [];

  public onPaisSelected(pais: number) {
    console.log(pais);
    this.estados = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estados = estados;
        }
      );
    };
  }

  public claveInsert(clave: string) {
    let campusID = this.campusId;
    if (!campusID) {
      campusID = 0;
    }

    this.campusService.obtenerCampusByClave(campusID).subscribe(
      campus => {
        if (campus == null) {
          return this.ClaveEncontrada = false;
        } else {
          return this.ClaveEncontrada = true;

        }
      }
    )
  }

  public cambiarVistaEditar(idCampus: number) {
    this.boolCambio = false;
    this.data = Object.assign(this.creacionCampusForm.value);
    this.data.tipoAccion = 2;
    this.campusId = idCampus;
    /*
    this.util.abrirDialogoLateral(CampusEdicionComponent, this.data);
    this.dialogRef.close(null);
    */
  }

  public cambiarVistaEliminar() {
    this.data.tipoAccion = 4;
  }

  public obtenerCampus() {
    this.campusService.obtenerCampus().subscribe(
      (campuses: Campus[]) => {
        this.campusList = campuses;
      }
    )
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        console.log("validando", control);
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public enviar(): void {
    //this.obtenerObjCampusCreacion()
    switch (this.tipoAccion) {
      case AccionEnum.CREAR:
        if (this.creacionCampusForm.invalid || this.ClaveEncontrada) {
          this.validateAllFormFields(this.creacionCampusForm);
          this.snackBar.open("Verifique los datos.", "OK", { duration: 5000 });
          return;
        };
        this.data = Object.assign(this.creacionCampusForm.value);
        this.campusService.agregarCampus(this.data).subscribe(
          idCampus => {
            if (idCampus > 0) {
              this.campusService.invocarMetodoObtenerCampus();
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
              this.boolCambio = false;
              this.cambiarVistaEditar(idCampus);
              this.inicializarForm();
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          },
          error => {
            console.log("error", error);
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        );
        break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.creacionCampusForm.value);
        this.data.campusId = this.campusId;
        this.data.tipoAccion = 2;
        this.campusService.modificarCampus(this.data).subscribe(
          editado => {
            if (editado) {
              this.campusService.invocarMetodoObtenerCampus();
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
              this.boolCambio = false;
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          },
          error => {
            console.log("error", error);
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        );
        break;
    }
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }


  ngOnInit(): void {
    this.inicializarForm();
    this.obtenerCampus();
    this.creacionCampusForm.markAsUntouched();

  }

}
