import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Letter } from 'src/app/_models/letter';
import { SelectionModel } from '@angular/cdk/collections';
import { CartasService } from 'src/app/_services/cartas.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { CartasModalEdicionComponent } from './cartas-modal-edicion/cartas-modal-edicion.component';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { FormControl } from '@angular/forms';
import { CartasModalEliminarComponent } from './cartas-modal-eliminar/cartas-modal-eliminar.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cartas',
  templateUrl: './cartas.component.html',
  styleUrls: ['./cartas.component.scss']
})
export class CartasComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'clave', 'nombre', 'descripcion', 'fecha'];
  dataSource: MatTableDataSource<Letter>;
  selection = new SelectionModel<Letter>(true, []);
  public letter: any;
  public infoCargado: boolean = false;
  public numLetterSeleccionado: number = 0;
  private arrOriginal: Letter[] = [];
  public claveName = new FormControl();

  constructor(private letterService: CartasService,
    private paginador: MatPaginatorIntl,
    public util: UtileriasService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.letterService.invMetodoObtenerLettersSubscription == undefined) {
       this.letterService.invMetodoObtenerLettersSubscription = this.letterService.invocarObtenerLetters.subscribe((invoque) => {
        this.obtenerLetters();
        this.limpiarFiltro();
       });
      this.paginador.itemsPerPageLabel = "Registros por página";
      this.paginador.nextPageLabel = "Página siguiente";
      this.paginador.previousPageLabel = "Página anterior";
      this.paginador.firstPageLabel = "Primera página";
      this.paginador.lastPageLabel = "Última página";
      this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
    }
    this.obtenerLetters();
  }

  public obtenerLetters() {
    this.letterService.obtenerLetter().subscribe((letters: ApiResponse<Letter[]>) => {
      this.dataSource = new MatTableDataSource(letters.data);
      this.selection = new SelectionModel<Letter>(true, []);
      this.dataSource.paginator = this.paginator;
      this.infoCargado = true;
      this.arrOriginal = [...letters.data];
    });
  }

  public crear(): void {
    let datos: Letter = {
      letterId: 0,
      code: '',
      name: '',
      description: '',
      directDownload: false,
      content: '',
      creationDate: null,
      creationUserId: 0,
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(CartasModalEdicionComponent, datos);
    dialogo.afterClosed().subscribe((result) => {
      this.letter = result;
    });
  }

  public editar(elemento?: any): void {
    let datos: Letter = {
      letterId: elemento.letterId,
      code: elemento.code,
      name: elemento.name,
      description: elemento.description,
      directDownload: elemento.directDownload,
      content: elemento.content,
      creationDate: elemento.creationDate,
      creationUserId: elemento.creationUserId,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(CartasModalEdicionComponent, datos);
  }

  public borrar(btnDataTable: boolean, elemento?: any) {
    if(btnDataTable) {
      var datos = elemento;
    } else {
      if(elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    this.dialog.open(CartasModalEliminarComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  public filtro() {
    let claveNombre: string = this.claveName.value === null ? '' : this.claveName.value;
    let filtroModificado: Letter[] = [];
    filtroModificado = [...this.arrOriginal];
    filtroModificado = filtroModificado.filter(f => {
      return f.code.toLowerCase().search(claveNombre.toLowerCase()) > -1 ||
             f.name.toLowerCase().search(claveNombre.toLowerCase()) > -1;
    });
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
  }

  limpiarFiltro() {
    this.claveName.setValue('');
    this.obtenerLetters();
  }

  isAllSelected(): boolean {
    this.numLetterSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numLetterSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row))
  }
}
