import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SnackService } from '../services/snack-service.service';
import { Periodo } from '../_models';
import { ColegiaturaCosto } from '../_models/colegiatura-costo';
import { EstadoCuentaDto } from '../_models/estado-cuenta-dto';
import { ResumenEstadoCuentaDto } from '../_models/resumenEstadoCuentaDto';
import { SeguimientoCartera } from '../_models/seguimiento-cartera';
import { PeriodoService } from '../_services';
import { AuthenticationService } from '../_services/authentication.service';
import { EnsenanzaAprendizajeService } from '../_services/ensenanza-aprendizaje.service';
import { SeguimientoCarteraService } from '../_services/seguimiento-cartera.service';
import { UtileriasService } from '../_services/utilerias.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { IntegracionService } from '../_services/integracion.service';
import { IntegracionDto } from '../_models/integracionDto';
import { TraductorService } from '../_services/traductor.service';

@Component({
  selector: 'app-estado-cuenta-alumno',
  templateUrl: './estado-cuenta-alumno.component.html',
  styleUrls: ['./estado-cuenta-alumno.component.scss']
})
export class EstadoCuentaAlumnoComponent implements OnInit, OnDestroy {
  //DATOS INICIALES
  public datosEstadoCuentaUsuario: EstadoCuentaDto[] = [];
  public datosSeguimientoUsuario: EstadoCuentaDto[] = [];
  public datosResumenUsuario: ResumenEstadoCuentaDto;
  public subscriptionDatosPeriodo: Subscription;
  public datosPeriodoUsuario: Periodo[];
  //VARIABLES PAGINADOR TABLA
  public movimientosIniciales: EstadoCuentaDto[];
  public movimientosPorVer: EstadoCuentaDto[];
  public movimientosModificados: EstadoCuentaDto[];
  public banderaPaginador: boolean = true;
  //VARIABLE INFO CARGADA
  public infoCargada: boolean = false;
  public flagCargaDatos: boolean = true;
  //PERIODO SELECCIONADO
  public periodoIdFiltrado: number;
  public usuarioSesion: any;
  public estatusEstadoCuenta: string = '';
  public vencimientoColegiatura: Date;
  public datosCompletoUsuario: any;
  public datosColegiatura: ColegiaturaCosto[] = [];
  //VARIABLES TABLA MOVIMIENTOS
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'fechaVencimiento', 'descripcion', 'cargo', 'abono', 'saldo'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  //VARIABLES CHECKBOX TABLE
  public numMovimientosSeleccionado: number = 0;
  //VARIABLE DATOS DE CARGOS
  public cargosPendientes: any[] = [];
  public showTablaMovimientos: boolean = false;
  //Variables para las subcripciones
  public subscription: Subscription = new Subscription();
  //Variable para controlar el boton de pagar
  public metodoPagoDisponible: boolean = false;
  public identificadorAlumno: string = "";

  constructor(private seguimientoService: SeguimientoCarteraService,
    private utileriaService: UtileriasService,
    private snackService: SnackService,
    private enseñanzaAprendizajeService: EnsenanzaAprendizajeService,
    private periodoService: PeriodoService,
    public usuarioService: AuthenticationService,
    private paginador: MatPaginatorIntl,
    private integracionService: IntegracionService,
    private traductorService: TraductorService  
  ) {
  }

  ngOnInit(): void {
    this.ObtenerDatosIntegracion();
    if (!!this.usuarioService.isAuthenticated) {
      this.enseñanzaAprendizajeService.obtenerUsuarioConectado().subscribe((datos) => {
        this.identificadorAlumno = sessionStorage.getItem('identificador');
        this.usuarioService.obtenerDatosUsuario(datos.identificador).subscribe((data) => {

                    //PAGINADOR
                    this.paginador.itemsPerPageLabel = "Registros por página";
                    this.paginador.nextPageLabel = "Página siguiente";
                    this.paginador.previousPageLabel = "Página anterior";
                    this.paginador.firstPageLabel = "Primera página";
                    this.paginador.lastPageLabel = "Última página";
                    this.paginador.getRangeLabel = function (page, pageSize, length) {
                      if (length === 0 || pageSize === 0) {
                        return '1 de ' + length;
                      }
                      length = Math.max(length, 0);
                      const startIndex = page * pageSize;
                      const endIndex = startIndex < length ?
                        Math.min(startIndex + pageSize, length) :
                        startIndex + pageSize;
                      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
                    };


          this.datosCompletoUsuario = data;
          this.getTipoPeriodo(this.datosCompletoUsuario.usuarioId);
        });
      })
    }
  }

  /**
   * Metodo para obtener los metodos de pago configurados
   */
  public ObtenerDatosIntegracion(): void {
    this.subscription.add(
      this.integracionService.ObtenerDatosIntegracion().subscribe((response: ApiResponse<IntegracionDto[]>) => {
        if (response.success) {
          if (response.data.length > 0) {
            this.metodoPagoDisponible = true;
          } else {
            this.metodoPagoDisponible = false;
          }
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      })
    );
  }

  /**
   * Metodo para obtener el periodo del usuario
   * @param usuarioId
   */
  public getTipoPeriodo(usuarioId: number): void {
    this.subscriptionDatosPeriodo = this.periodoService.obtenerPeriodosByUsuario(usuarioId).subscribe(
      (periodo: Periodo[]) => {
        if (periodo.length > 0) {
          this.datosPeriodoUsuario = periodo;
          this.periodoIdFiltrado = periodo[0].periodoId;
          this.buscarInformacionDeUsuario(usuarioId, this.datosPeriodoUsuario[0].periodoId);
        } else {
          this.buscarInformacionDeUsuario(usuarioId);
        }
      }
    );
  }

  /**
   * Metodo para buscar la informacion del usuario
   * @param usuarioId
   * @param periodoId
   */
  public buscarInformacionDeUsuario(usuarioId: number, periodoId?: number): void {
    this.seguimientoService.obtenerSeguimientoUsuarioById(usuarioId).subscribe(
      (seguimientoUsuario: ApiResponse<EstadoCuentaDto[]>) => {
        this.datosSeguimientoUsuario = seguimientoUsuario.data;
        if (periodoId !== undefined) {
          this.buscarInfomacionEstadoCuenta(usuarioId, periodoId);
        } else {
          let estadoCuentaSinMovimientos = []
          this.datosEstadoCuentaUsuario = estadoCuentaSinMovimientos;
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-2');
          this.estatusEstadoCuenta = mensaje;
          let resumenSinMovimientos = {
            saldoPagar: 0,
            saldoTotal: 0,
            saldoVencido: 0,
            usuarioId: usuarioId
          }
          this.datosResumenUsuario = resumenSinMovimientos;
        }
      }
    );
  }

  /**
   * Metodo para buscar la informacion de estado de cuenta del usuario
   * @param usuarioId
   * @param periodoId
   */
  public buscarInfomacionEstadoCuenta(usuarioId: number, periodoId: number): void {
    this.seguimientoService.obtenerEstadoCuentaByUsuarioId(usuarioId, periodoId, this.datosCompletoUsuario.alumno[0].alumnoId).subscribe(
      (estadoCuenta: EstadoCuentaDto[]) => {
        this.datosEstadoCuentaUsuario = estadoCuenta;
        this.dataSource = new MatTableDataSource(this.datosEstadoCuentaUsuario);
        this.selection = new SelectionModel<EstadoCuentaDto>(true, []);
        this.seguimientoService.obtenerFechaVencimiento(this.datosCompletoUsuario.alumno[0].programaId, this.datosCompletoUsuario.alumno[0].campusId, this.periodoIdFiltrado).subscribe(
          (datos: ColegiaturaCosto[]) => {
            if (datos != null) {
              this.datosColegiatura = datos;
              this.vencimientoColegiatura = this.datosColegiatura[0].fechaVencimiento;
            } else {
              this.vencimientoColegiatura = this.datosEstadoCuentaUsuario[0].fechaVencimiento;
            }
          }
        );
        this.buscarInfomacionResumenEstadoCuenta(usuarioId);
      });
  }

  /**
   * Metodo para obtener los cuadros del balance de estadio de cuenta del usuario
   * @param usuarioId
   */
  public buscarInfomacionResumenEstadoCuenta(usuarioId: number): void {
    this.seguimientoService.obtenerResumenEstadoCuentaByUsuarioId(this.datosCompletoUsuario.alumno[0].alumnoId).subscribe(
      (resumen: ApiResponse<ResumenEstadoCuentaDto>) => {
        if (resumen.data.saldoVencido > 0) {
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-1');
          this.estatusEstadoCuenta = mensaje;
        } else {
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-2');
          this.estatusEstadoCuenta = mensaje;
        }
        this.datosResumenUsuario = resumen.data;

        if (this.datosResumenUsuario === null) {
          this.flagCargaDatos = false;
        }
        this.infoCargada = true;
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 100);
      });
  }
  ExcelSubscription: Subscription;
  /**
  * Funcion que obtiene todos los datos de la tabla principal y los convierte en un archivo de Excel para su descarga
  */
  public descargarExcelMovimientos(): void {
    this.ExcelSubscription = this.seguimientoService.descargarExcelMovimientos(this.datosEstadoCuentaUsuario).subscribe(
      (res: Blob) => {
        var filename = "Estado.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
          window.navigator.msSaveOrOpenBlob(res, filename);
        } else {
          var a = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          var blob = new Blob([res], { type: 'application/xlsx' });
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        }
        this.ExcelSubscription.unsubscribe();
      }
    );
  }

  /**
   * Verifica el valor seleccionado de Periodo, si se cambia el valor entra en la funcion y cambia todos los movimientos segun ese periodo
   * @param elemento
  */
  public changePeriodo(elemento: string): void {
    this.periodoIdFiltrado = this.datosPeriodoUsuario.find(x => x.nombre === elemento).periodoId;
    this.seguimientoService.obtenerEstadoCuentaByUsuarioId(this.datosCompletoUsuario.usuarioId, this.periodoIdFiltrado, this.datosCompletoUsuario.alumno[0].alumnoId).subscribe(
      (estadoCuenta: EstadoCuentaDto[]) => {
        this.datosEstadoCuentaUsuario = estadoCuenta;
        this.dataSource = new MatTableDataSource(this.datosEstadoCuentaUsuario);
        this.selection = new SelectionModel<EstadoCuentaDto>(true, []);
        this.dataSource.paginator = this.paginator;
        this.seguimientoService.obtenerFechaVencimiento(this.datosCompletoUsuario.alumno[0].programaId, this.datosCompletoUsuario.alumno[0].campusId, this.periodoIdFiltrado).subscribe(
          (datos: ColegiaturaCosto[]) => {
            if (datos != null) {
              this.datosColegiatura = datos;
              this.vencimientoColegiatura = this.datosColegiatura[0].fechaVencimiento;
            } else {
              this.vencimientoColegiatura = this.datosEstadoCuentaUsuario[0].fechaVencimiento;
            }
          }
        );
      });
  }

  /**
  * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
  * seleccionados para modificar el check
  * @returns boolean para conocer si todos estan seleccionados
  */
  isAllSelected(): boolean {
    this.numMovimientosSeleccionado = this.selection.selected.length;
    const numRows = this.datosEstadoCuentaUsuario.length;
    return this.numMovimientosSeleccionado === numRows;
  }

  /**
  * La etiqueta de la casilla de verificación de la fila pasada
  */
  checkboxLabel(row?: EstadoCuentaDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.movimientoId + 1}`;
  }

  /**
  * Selecciona todas las columnas si no lo estan, o bien limpia la seleccion
  */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.movimientosModificados.forEach(row => this.selection.select(row));
  }

  /**
  * Se manda a llamar cuando se cierra el componente y se desuscribe de todos los select elegidos
  */
  ngOnDestroy(): void {
    if (this.subscriptionDatosPeriodo) {
      this.subscriptionDatosPeriodo.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
