import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, throwError } from 'rxjs';
import { Documento } from 'src/app/_models/documento';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { baseUrl, environment } from 'src/environments/environment';

@Component({
  selector: 'app-datos-documentos-admision-express',
  templateUrl: './datos-documentos-admision-express.component.html',
  styleUrls: ['./datos-documentos-admision-express.component.scss']
})
export class DatosDocumentosAdmisionExpressComponent implements OnInit {
  //Variable Formulario
  public datosDocumentosForm: FormGroup;
  public pdf: ArrayBuffer;
  public mime: string;
  public imagen: string;
  public nombreDocumentoVisualizar: string;
  public idDocumentoVisualizar: number;
  public documentosCompletos: boolean = false;
  public claveProcesoAdmision = "NUEVO";
  //Variable tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;
  @ViewChild('modalVisorPDF') modalVisorPDF: TemplateRef<any>;
  public modalVisorPDFRef: any;

  public date: Date = new Date();
  public documentosProceso: Documento[] = [];
  // public DOCUMENTOS_SOLICITUD: any[] = [
  //   { "id": 1, "documentoNombre": "nombre-1", "nombreOriginal": "" },
  //   { "id": 2, "documentoNombre": "nombre-2", "nombreOriginal": "" },
  //   { "id": 6, "documentoNombre": "nombre-6", "nombreOriginal": "" },
  //   { "id": 7, "documentoNombre": "nombre-7", "nombreOriginal": "" }
  // ];


  constructor(private dialog: MatDialog,
    private admisionesService: AdmisionesService) { }

  ngOnInit(): void {
    this.obtenerDocumentosProceso();
  }

  /**
    * Metodo que convierte un base64 a arraybuffer para viasualizarlo
    * @param base64 
    * @returns 
    */
  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public onFileChange(event, control: number, cerrarModal: boolean) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.onload = () => {
        let documento = this.documentosProceso.find(x => x.documentoId === control);
        documento.nombreOriginal = file.name;
        documento.nombreFisico = reader.result;
        documento.fechaCreacion = this.date;
        documento.estatusDocumentoId = 1;
        this.validarDocumentosCompletos();
      }
      reader.readAsDataURL(file);
    }
    if (cerrarModal) {
      this.cerrarModalPdf();
    }
  }

  public visualizarPdf(control: any) {
    let archivo = this.documentosProceso.find(x => x.documentoId === control).nombreFisico;
    this.mime = archivo.split(",")[0].split(":")[1].split(";")[0];
    this.imagen = this.documentosProceso.find(x => x.documentoId === control).nombreFisico;
    this.pdf = this.base64ToArrayBuffer(archivo);
    let nombreDocumento = this.documentosProceso.find(x => x.documentoId === control).nombreOriginal;
    this.nombreDocumentoVisualizar = nombreDocumento.substr(0, nombreDocumento.lastIndexOf('.'))
    this.idDocumentoVisualizar = control;
    if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
      this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
    } else {
      const blob = new Blob([this.pdf], { type: this.mime });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    }
  }



  /**
  * Metodo para eliminar el documento del usuario
  * @param documentId 
  */
  public eliminarDocumento(documentId: number) {
    let documento = this.documentosProceso.find(x => x.documentoId === documentId);
    documento.nombreOriginal = ""
    documento.nombreFisico = "";
    this.documentosCompletos = false;
    this.cerrarModalPdf();
  }
  /**
   * Metodo que cierra el modal del visor del documento
   */
  public cerrarModalPdf() {
    this.modalVisorPDFRef.close();
  }


  validarDocumentosCompletos() {
    if (this.documentosProceso.filter(e => e.nombreOriginal === '').length > 0) {
      this.documentosCompletos = false;
    } else {
      this.documentosCompletos = true;
    }
  }

  public obtenerDocumentosProceso() {
    this.admisionesService.ObtenerDocumentosProcesoByUrl(this.tenantId, this.claveProcesoAdmision).subscribe(response => {
      if (response.success) {
        console.log(response.data);
        this.documentosProceso = response.data;
      }
    })
  }




}
