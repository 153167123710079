import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import * as customBuild from '../../../../../_librarries/ckeditorCustom/build/ckeditor';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { EnsenanzaAprendizajeAlumnoService } from '../../../../../_services/ensenanzaAprendizajeAlumno.service'
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { CuestionarioArea, CuestionarioRespuestaDto, RecursoAlumnoArchivo } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { CalificacionNoNumerica } from 'src/app/_models/calificacion-no-numerica';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
@Component({
  selector: 'app-consultar-detalle-modal',
  templateUrl: './consultar-detalle-modal.component.html',
  styleUrls: ['./consultar-detalle-modal.component.scss']
})
export class ConsultarDetalleModalComponent implements OnInit {
  public tipoRecursoNombre: string;
  public banderaRenderizarFoto: boolean = false;
  public base64Image: string;
  public imagePath: SafeResourceUrl;
  public imagenDisponible: boolean = false;
  public calificacionForm: FormGroup;
  public Editor = customBuild;
  public usuarioSesion: any;
  public recursoRevisado: boolean = true;
  public ponderacionValida: boolean = false;
  public escalaCal: CalificacionNoNumerica[] = [];
  public escalaMax: CalificacionNoNumerica;
  public escalaMin: CalificacionNoNumerica;
  public escalaCargada: boolean = false;

  //VARIABLES TAREA
  public tareaArchivo: RecursoAlumnoArchivo;
  public tareaRecurso: boolean = false;
  public sinArchivosAdjuntos: boolean = false;
  public base64Tarea: string;

  //VARIABLE EXAMEN
  public examenRecurso: CuestionarioArea[];
  public recursoTipoExamen: boolean = false;
  public formControlsAbierta: CuestionarioRespuestaDto[] = [];
  public sumaPonderacionOriginal: number;
  public examenSinResponder: boolean = false;
  public disabledVC: boolean = false;
  public disabledPonderacion: boolean = true;

  constructor(public dialogRevisionRef: MatDialogRef<ConsultarDetalleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public nuevoIngresoService: NuevoIngresoService,
    private sanitizer: DomSanitizer,
    public utileriaService: UtileriasService,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private snackService: SnackService) { }

  ngOnInit(): void {
    this.obtenerUsuarioSesion();
    this.obtenerEscalaCalificacionByPrograma(this.data.recursoSeleccionado.programaId);
    this.obtenerFotoPerfil();
    this.formularioCalificacion();
    this.identificarTipoRecurso();
  }

  public obtenerUsuarioSesion() {
    this.ensenanzaAprendizajeService.obtenerUsuarioConectado().subscribe(
      (usuario) => {
        this.usuarioSesion = usuario.usuarioId;
      }
    )
  }

  public obtenerEscalaCalificacionByPrograma(programaId: number) {
    this.ensenanzaAprendizajeService.obtenerEscalaCalificacionByPrograma(programaId).subscribe(
      (escala: ApiResponse<CalificacionNoNumerica[]>) => {
        this.escalaCal = escala.data;
        this.escalaMax = escala.data[escala.data.length - 1];
        this.escalaMin = escala.data[0];
        this.escalaCargada = true;
      }
    )
  }

  public identificarTipoRecurso() {
    if (this.data.recursoSeleccionado.recursoSeleccionado.tipoRecursoId === 2) {
      if (this.data.recursoSeleccionado.recursoSeleccionado.estatusRecurso === 'Sin Entregar') {
        this.sinArchivosAdjuntos = true;
      } else {        
        this.ensenanzaAprendizajeService.obtenerAlumnoArchivo(this.data.recursoSeleccionado.recursoSeleccionado.alumnoId, this.data.recursoSeleccionado.recursoSeleccionado.recursoId).subscribe(
          (archivo: ApiResponse<RecursoAlumnoArchivo>) => {
            if (archivo.data !== null) {
              this.tareaArchivo = archivo.data;
              this.tareaRecurso = true;
            } else {

            }
          }
        );
        if(this.data.recursoSeleccionado.estatusRecurso === 'Revisado'){        
          this.calificacionForm.controls['calificacionGlobal'].setValue(this.data.recursoSeleccionado.calificacion);
          this.calificacionForm.controls['comentario'].setValue(this.data.recursoSeleccionado.comentarios);
        }
      }
    }
    else if (this.data.recursoSeleccionado.tipoRecursoId === 3) {
      this.ensenanzaAprendizajeService.obtenerExamenEncuesta(this.data.recursoSeleccionado.recursoId).subscribe(
        (recursoExamen: ApiResponse<CuestionarioArea[]>) => {
          if (recursoExamen.data !== null) {
            this.examenRecurso = recursoExamen.data;
            if (this.data.recursoSeleccionado.estatusRecurso === 'Revisado') {
              this.recursoRevisado = false;
              this.recursoTipoExamen = true;
              this.calificacionForm.controls['calificacionGlobal'].setValue(this.data.recursoSeleccionado.calificacion);
              this.calificacionForm.controls['comentario'].setValue(this.data.recursoSeleccionado.comentarios);
              this.crearFormulario();
            } else if (this.data.recursoSeleccionado.estatusRecurso === 'Por Revisar') {
              this.recursoTipoExamen = true;
              this.crearFormulario();
            } else if (this.data.recursoSeleccionado.estatusRecurso === 'Sin Entregar') {
              this.examenSinResponder = true;
            }
          } else {

          }
        }
      );
    }
  }

  public crearFormulario() {
    this.examenRecurso.forEach(element => {
      element.cuestionarioPregunta.forEach(item => {
        var tipoPreguntaCuestionario = item.tipoPreguntaCuestionarioId;
        switch (tipoPreguntaCuestionario) {
          //Pregunta Abierta
          case 1:
            this.calificacionForm.addControl('abierta' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            this.calificacionForm.addControl('abiertaPonderacion' + item.cuestionarioPreguntaId, new FormControl({ value: '',disabled:true }));
            break;
          //Seleccion Unica
          case 2:
            this.calificacionForm.addControl('unicaAlumno' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            this.calificacionForm.addControl('unicaCorrecta' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            this.calificacionForm.addControl('unicaPonderacion' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            break;
          //Abierta Multiple
          case 3:
            item.cuestionarioRespuesta.forEach(val => {
              this.calificacionForm.addControl('multipleAlumno' + val.cuestionarioRespuestaId, new FormControl({ value: '', disabled: true }));
              this.calificacionForm.addControl('multipleCorrecta' + val.cuestionarioRespuestaId, new FormControl({ value: '', disabled: true }));
              this.calificacionForm.addControl('multiplePonderacion' + val.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            });
            break;
          //Escala
          case 4:
            this.calificacionForm.addControl('escalaAlumno' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            this.calificacionForm.addControl('escalaCorrecta' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            this.calificacionForm.addControl('escalaPonderacion' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            break;
        }
      });
    });
    this.AsignarRespuestasEncuesta();
  }

  public AsignarRespuestasEncuesta() {
    this.ensenanzaAprendizajeService.obtenerRespuestasAlumnoExamen(this.data.recursoSeleccionado.recursoId, this.data.recursoSeleccionado.alumnoId).subscribe(
      (respuestas: ApiResponse<CuestionarioRespuestaDto[]>) => {
        this.disabledVC = false;
        let arregloConRepetidos = respuestas.data;
        const seen = new Set();
        let sinRepetidos = arregloConRepetidos.filter(el => {
          const duplicate = seen.has(el.cuestionarioPreguntaId);
          seen.add(el.cuestionarioPreguntaId);
          return !duplicate;
        });
        this.formControlsAbierta = arregloConRepetidos.filter(x => x.tipoPreguntaCuestionarioId === 1);
        this.formControlsAbierta = this.formControlsAbierta.map(obj => ({
          ...obj,
          formControlNombre: 'abiertaPonderacion' + obj.cuestionarioPreguntaId
        }));
        respuestas.data.forEach(item => {
          switch (item.tipoPreguntaCuestionarioId) {
            case 1:
              this.calificacionForm.controls['abierta' + item.cuestionarioPreguntaId].setValue(item.titulo);
              this.calificacionForm.controls['abiertaPonderacion' + item.cuestionarioPreguntaId].setValue(0);
              break;
            case 2:
              this.calificacionForm.controls['unicaAlumno' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              this.calificacionForm.controls['unicaPonderacion' + item.cuestionarioPreguntaId].setValue(item.ponderacion);
              break;
            case 3:
              this.calificacionForm.controls['multipleAlumno' + item.cuestionarioRespuestaId].setValue(true);
              this.calificacionForm.controls['multiplePonderacion' + item.cuestionarioPreguntaId].setValue(item.ponderacion);
              break;
            case 4:
              this.calificacionForm.controls['escalaAlumno' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              this.calificacionForm.controls['escalaPonderacion' + item.cuestionarioPreguntaId].setValue(item.ponderacion);
              break;
          }
        });
      this.disabledVC = true;
    });
    this.ensenanzaAprendizajeService.obtenerRespuestasCorrectaExamen(this.data.recursoSeleccionado.recursoId, this.data.recursoSeleccionado.alumnoId).subscribe(
      (respuestas: ApiResponse<CuestionarioRespuestaDto[]>) => {
        this.disabledVC = false;
        respuestas.data.forEach(item => {
          switch (item.tipoPreguntaCuestionarioId) {
            case 2:
              this.calificacionForm.controls['unicaCorrecta' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              break;
            case 3:
              this.calificacionForm.controls['multipleCorrecta' + item.cuestionarioRespuestaId].setValue(true);
              break;
            case 4:
              this.calificacionForm.controls['escalaCorrecta' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              break;
          }
        });
      this.disabledVC = true;
    });
  }

  public formularioCalificacion() {
    this.calificacionForm = new FormGroup
      ({
        calificacionGlobal: new FormControl({ value: '', disabled: true }, [Validators.required]),
        comentario: new FormControl({ value: '', disabled: true }, [Validators.required])
      });

    this.calificacionForm.get('calificacionGlobal').valueChanges.pipe(
      filter(x => this.disabledPonderacion)
    ).subscribe(val => {
      this.sumaPonderacionOriginal = val;
    });

    this.calificacionForm.valueChanges.pipe(
      filter(x => this.disabledVC)
    ).subscribe(val => {
      this.disabledVC = false;
      this.disabledPonderacion = false;
      delete val.comentario;
      delete val.calificacionGlobal;
      let sumaParcial = 0;
      for (let key in val) {
        sumaParcial += val[key];
      }
      let sumaFinal = sumaParcial + this.sumaPonderacionOriginal
      this.calificacionForm.patchValue({
        calificacionGlobal: sumaFinal
      });
      this.disabledVC = true;
      this.disabledPonderacion = true;
    });
  }
  get calificacionGlobal() { return this.calificacionForm.get('calificacionGlobal'); }
  get comentario() { return this.calificacionForm.get('comentario'); }

  public obtenerFotoPerfil() {
    this.nuevoIngresoService.imagenUsuario(this.data.recursoSeleccionado.usuarioId).subscribe(
      (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
        if (datosUsuarioImagen.data.fotografia !== null) {
          this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
          this.banderaRenderizarFoto = true;
          this.imagenDisponible = true;
        } else {
          this.banderaRenderizarFoto = true;
          this.imagenDisponible = false;
        }
      }
    );
  }

  public descargarArchivo() {
    this.ensenanzaAprendizajeService.obtenerDocumentoRecurso(this.tareaArchivo.recursoAlumnoArchivoId).subscribe(
      (archivo: ApiResponse<RecursoAlumnoArchivo>) => {
        if (archivo.data.nombreFisico !== null) {
          var documento = this.base64ToArrayBuffer(archivo.data.nombreFisico);
          var mime = archivo.data.nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : "application/octect-stream";
          var a = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          var blob = new Blob([documento], { type: mime });
          a.href = window.URL.createObjectURL(blob);
          a.download = archivo.data.nombreOriginal;
          a.click();
        } else {

        }
      }
    );
  }

  public base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public enviarCalificacion(): void {
    let datosForm = { ...this.calificacionForm.value };
    let datosCal = {
      recursoId: this.data.recursoSeleccionado.recursoId,
      alumnoId: this.data.recursoSeleccionado.alumnoId,
      calificacionGlobal: datosForm.calificacionGlobal,
      comentario: datosForm.comentario,
      usuarioSesion: this.usuarioSesion,
      escalaCalificacionId: this.escalaCal[0].escalaCalificacionId
    }
    this.ensenanzaAprendizajeService.enviarCalificacion(datosCal).subscribe(
      (calificacionCapturada: SimpleResponse) => {
        if (calificacionCapturada.success) {
          this.ensenanzaAprendizajeService.metodoObtenerInfoRecurso();
          this.dialogRevisionRef.close();
          this.snackService.mostrarSnackBack('Se ha guardado la calificación.');
        } else {
          this.snackService.mostrarSnackBack('La calificación no pudo ser capturada.');
        }
      }
    );
  }

  public cerrarModal(): void {
    this.dialogRevisionRef.close();
  }

  public validarPonderacion = function (nombreControl1: string) {
    this.ponderacionValida = this.calificacionForm.get(nombreControl1).value <= this.escalaMax.valorNumerico && this.calificacionForm.get(nombreControl1).value >= this.escalaMin.valorNumerico;
    return this.ponderacionValida;
  }
}
