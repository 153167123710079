import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginatorIntl, MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { SnackService } from '../services/snack-service.service';
import { NuevoIngresoModalComponent } from '../nuevo-ingreso/nuevo-ingreso-modal/nuevo-ingreso-modal.component';
import { Usuario } from '../_models/usuario';
import { UsuarioService } from '../_services/usuario.service';
import { UtileriasService } from '../_services/utilerias.service';
import { UsuariosModalComponent } from './usuarios-modal/usuarios-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EnviarCorreoUsuariosComponent } from './usuarios-modal/enviar-correo-usuarios/enviar-correo-usuarios.component';
import { UsuariosRegistrarComponent } from './usuarios-modal/usuarios-registrar/usuarios-registrar.component';
import { RegistrarUsuarioComponent } from '../nuevo-ingreso/nuevo-ingreso-modal/registrar-usuario/registrar-usuario.component';
import { UsuarioCuentaNuevaComponent } from './usuarios-modal/usuario-cuenta-nueva/usuario-cuenta-nueva.component';
import { AccionEnum } from '../_models/accion.enum';
import { ModalEliminarUsuarioComponent } from './modal-eliminar-usuario/modal-eliminar-usuario.component';
import { MatDialog } from '@angular/material/dialog';
import { EnviarCorreoComponent } from '../_components/enviar-correo/enviar-correo.component';

@Component({
  selector: 'app-configuracion-usuarios',
  templateUrl: './configuracion-usuarios.component.html',
  styleUrls: ['./configuracion-usuarios.component.scss']
})
export class ConfiguracionUsuariosComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select','usuarioID', 'ID', 'nombre','correo','fechaNacimiento','estatus','perfil'];
  public dataSource: MatTableDataSource<Usuario>;
  selection = new SelectionModel<any>(true, []);
  public usuario: Usuario[] = [];
  public infoCargada: boolean = false;
  public numUsuarioSeleccionado: number = 0;
  public idnombre = new FormControl();
  public estatusF = new FormControl();
  public perfilF = new FormControl();
  public datafilter:any;
  private arrOriginal: any[] = [];
  public infoCargado = false;
  public filtroForm: FormGroup;
  public arrFiltrado: any[] =[];
  public PerfilC: any[] = [{ nombre: 'Administrador', valor: 1 }, { nombre: 'Alumno', valor: 2 }, { nombre: 'Profesor', valor: 3}, { nombre: 'Sin Perfil', valor: 4}];

  constructor(
    public util: UtileriasService,
    public usuarioService: UsuarioService,
    private paginador: MatPaginatorIntl,
    private snackBar: SnackService,
    private contador: MatPaginatorIntl,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if(this.usuarioService.invMetodoObtenerUsuariosSubscription == undefined) {
      this.usuarioService.invMetodoObtenerUsuariosSubscription = this.usuarioService.invocarObtenerUsuarios.subscribe(() =>{
        this.obtenerUsuarios();
      });
    }
    this.LimpiarFiltros();
    this.obtenerUsuarios();
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    this.inicializarForm();
  }

  public obtenerUsuarios() {
    this.usuarioService.obtenerUsuarios().subscribe(
      (usuarios: any)=>{
        this.dataSource = new MatTableDataSource(usuarios.data);
        this.dataSource.paginator = this.paginator;
        this.selection = new SelectionModel<any>(true, []);
        this.infoCargado = true;
        // this.infoCargada =true;
        this.arrOriginal = [...usuarios.data];
        this.filtro();
      }
    )
  }

  public crearUsuario(): void{
    this.util.abrirDialogoLateral(UsuariosModalComponent);
  }
  //UsuariosModalComponent
  public editarSolicitud(elemento?: Usuario): void {
    let datos: Usuario = {
      usuarioID: elemento.usuarioId,
      identificador: elemento.identificador,
      correo: elemento.correo,
      nombre: elemento.nombre,
      segundoNombre:elemento.segundoNombre,
      apellidoPaterno: elemento.apellidoPaterno,
      apellidoMaterno: elemento.apellidoMaterno,
      fechaNacimiento: elemento.fechaNacimiento,
      estatus: elemento.estatus,
      perfil: elemento.perfil,
      Roles: elemento.Roles,
      Oficinas: elemento.Oficinas,
      IsAdmin: elemento.IsAdmin,
      IsProfesor: elemento.IsProfesor,
      IsAlumno: elemento.IsAlumno,
      Token: elemento.Token,
      Expiracion: elemento.Expiracion,
      institucionId: elemento.institucionId,
      tipoAccion: AccionEnum.EDITAR,
    }
    const dialogo = this.util.abrirDialogoLateral(UsuariosModalComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result){
        this.usuario = result;
      }
    });
  }
  
  ExcelSubscription: Subscription;
public descargarExcel(): void {
  this.ExcelSubscription = this.usuarioService.downloadExcelUsuarios(this.arrOriginal).subscribe((res: Blob) => {
    var filename = "Consultar_Usuarios.xlsx";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(res, filename);
    } else {
      var a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      var blob = new Blob([res], { type: 'application/xlsx' });
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.click();
    }
    this.ExcelSubscription.unsubscribe();
  });
}

  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
        if (elemento.length === 1) {
          var datos = elemento[0];
        } else {
          var datos = elemento;
        }
      }
      const dialogo = this.dialog.open(EnviarCorreoComponent, {
        data: datos,
        panelClass: "dialogo-enviar",
        width: '50%',
        height: 'auto',
        maxHeight: '80vh',
        disableClose: true
      });
    }

  public borrar(btnDataTable: boolean, elemento?: any): void {
    if(btnDataTable) {
      var datos = elemento;
    } else {
      if(elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    // pendiente de agregar modal de eliminar
    const dialogEliminar = this.dialog.open(ModalEliminarUsuarioComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
    dialogEliminar.afterClosed().subscribe(result => {
      if(!!result){
        
      }
    });
  }

  isAllSelected() {
    this.numUsuarioSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numUsuarioSeleccionado === numRows;
  }


  checkboxLabel(row?: Usuario): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioID + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // applyFilter(filterValue: string) {
  //   filterValue = filterValue.toLowerCase();
  //   this.dataSource.filter = filterValue;
  //   this.dataSource.filterPredicate = function (data, filter: string): boolean {
  //    var nombre = data.nombre + " " + data.apellidoPaterno + " " + data.apellidoMaterno;
  //     return nombre.toLowerCase().includes(filter) || data.identificador.toString().includes(filter);
  //   };
  // }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  tipoInscripcion: string = "Activo";
    // Inicializar  los filtros
    public inicializarForm() {
      this.filtroForm = new FormGroup({
        idnombre: new FormControl(''),
        perfilF: new FormControl(0),
        estatusF: new FormControl([])
      });
    }
    //      let perfilC = perfil ==1?"Profesor":"Sin Perfil";
    //      filtroModificado = filtroModificado.filter(f => { return f.rolString.toLowerCase() === perfilC });
    //      let perfilV = perfil ==1?"Administrador":"Alumno":"Profesor":"Sin Perfil";
  public filtro() {
    let nombre: string = this.idnombre.value == null ? "" : this.idnombre.value;
    let estatus: any = this.estatusF.value == null ? 0 : this.estatusF.value;
    let perfil: number= this.perfilF.value == null?0:this.perfilF.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    this.arrFiltrado = filtroModificado;
    if (nombre.length > 0 || nombre !== '') {
      filtroModificado = filtroModificado.filter(el => {
        return el.nombreCompleto.toLowerCase().search(nombre.toLowerCase()) > -1 || el.usuarioId.toString().search(nombre) > -1;
      });
    }
    if (estatus == 1 || estatus == 2) {
      if (estatus == 1) {
        this.tipoInscripcion = "Activo";
        filtroModificado = this.arrFiltrado.filter(row => row.estatusCuentaId == true);
      } else {
        this.tipoInscripcion = "Inactivo";
        filtroModificado = this.arrFiltrado.filter(row => row.estatusCuentaId == false);
      }
    }
    if (perfil != 0) { 
      let perfilV: string;
      switch (perfil) {
        case 1: perfilV =  "administrador";
        break;
        case 2: perfilV = "alumno";
        break;
        case 3: perfilV = "profesor";
        break;
        case 4: perfilV = "sin perfil";
        break;
      }
      filtroModificado = filtroModificado.filter(f => { return f.rolString.toLowerCase() === perfilV });
    }

    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
  }

  public LimpiarFiltros() {
    this.idnombre.setValue("");
    this.perfilF.patchValue(0);
    this.estatusF.patchValue(0);
    this.filtro();
    this.obtenerUsuarios();
  }

}
