import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataTableColumn } from '../../../../core.domain/models/data-table/data-table-column.models';
import { DataTable } from '../../../../core.domain/models/data-table/data-table.models';
import { ButtonEvent } from '../../../../core.domain/models/data-table/button-event.model';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent implements OnInit, OnChanges {

  @Input() cancelledFields: string[] = [];
  @Input() public dataTable: DataTable<unknown>;
  @Input() public tableColumns: DataTableColumn[];
  @Input() public totalItems: Number = 100;
  @Input() initialLoad: boolean;
  @Input() showFilters: boolean = true;
  @Input() public localPagination: boolean = false;
  
  @Output() public actionEmitter: EventEmitter<{buttonName: string, data: number}> = new EventEmitter();
  @Output() public paginationEmitter: EventEmitter<PageEvent> = new EventEmitter();
  @Output() public formFilters: EventEmitter<any> = new EventEmitter(); 
  @Output() public rowSelectedEmitter: EventEmitter<any[]> = new EventEmitter(); 

    //#region Subscriptions
    private Subscription: Subscription = new Subscription();
    //#endregion
  
  constructor(private _paginator: MatPaginatorIntl) { }

  ngOnInit(): void {    
    this._initPaginator();    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataTable'] !== undefined) {           
    }
  }
  
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }
  
  /**
   * The function takes a ButtonEvent as an argument, and emits it to the parent component
   * @param {ButtonEvent}  - ButtonEvent - This is the event that is emitted from the
   * TableBodyComponent.
   */
  tableActionClicked($event: ButtonEvent): void
  {    
    this.actionEmitter.emit($event);
  }

   /**
   * When the pagination changes, get the new page of data from the server.
   * @param {any} event - any - this is the event that is triggered when the pagination is changed.
   */
    public onPaginateChange($event: PageEvent):void
    {
      this.paginationEmitter.emit($event);
    }

    public formFiltersEvent($event:any):void
    {
      this.formFilters.emit($event);
    }

    public getRowSelected($event: any):void{      
      this.rowSelectedEmitter.emit($event);
    }

  /**
   * It initializes the paginator with the labels in Spanish
   * @returns the range of the page.
   */
  private _initPaginator(){
    this._paginator.itemsPerPageLabel = "Registros por página";
    this._paginator.nextPageLabel = "Página siguiente";
    this._paginator.previousPageLabel = "Página anterior";
    this._paginator.firstPageLabel = "Primera página";
    this._paginator.lastPageLabel = "Última página";
    this._paginator.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }  

}
