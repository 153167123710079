export class CalendarEvents{
    ///Título a mostrar del evento 
    title: string;
    //Días de la semana en que se realiza el evento
    daysOfWeek: Array<number>;
    ///Hora de inicio del evento
    startTime: string;
    ///Hora finalización del evento
    endTime: string;
    //Si el evento se hace todos los días o np
    allDay: boolean;
    ///Primer día en la que se realiza un evento
    startRecur: Date;
    ///último día en que se realiza un evento
    endRecur: Date;
}