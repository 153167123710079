import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { EnviarCorreoComponent } from 'src/app/_components/enviar-correo/enviar-correo.component';
import { InfoPorRevisarDto, ListaAlumnosGrupos } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { ModalCorreoComponent } from '../../../../ensenanza-aprendizaje-alumno/vista-contenido/mi-grupo/modal-correo/modal-correo.component';
import { EnsenanzaAprendizajeService } from '../../../../_services/ensenanza-aprendizaje.service';
import { UtileriasService } from '../../../../_services/utilerias.service';
import { PorRevisarModalComponent } from '../por-revisar-modal/por-revisar-modal.component';
import * as customBuild from '../../../../../app/_librarries/ckeditorCustom/build/ckeditor';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-por-revisar-detalle',
  templateUrl: './por-revisar-detalle.component.html',
  styleUrls: ['./por-revisar-detalle.component.scss']
})
export class PorRevisarDetalleComponent implements OnInit {

  public Editor = customBuild;
  @Input() recursoSelccionado: InfoPorRevisarDto;
  @Input() ultimoParcial: number;
  @Input() existeParcialFinal: boolean;

  @Output() grupoId = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'id', 'nombre', 'fechaEntrega', 'estatus', 'calificacion'];
  displayedColumnsEncuesta: string[] = ['select', 'id', 'nombre', 'fechaEntrega', 'estatus'];
  dataSource: MatTableDataSource<ListaAlumnosGrupos>;
  selection = new SelectionModel<ListaAlumnosGrupos>(true, []);
  public filtroForm: FormGroup;
  public listaAlumnos: ListaAlumnosGrupos[] = [];
  public datafilter: ListaAlumnosGrupos[] = [];
  public numAlumnosSeleccionado: number = 0;
  public infoCargado: boolean = false;
  public tituloRecurso: string = '';
  public recursoTarea: boolean = false;
  public recursoExamen: boolean = false;
  public recursoEncuesta: boolean = false;

  constructor(public utileriaService: UtileriasService,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    private dialog: MatDialog,
    private snackService: SnackService,
    private paginador: MatPaginatorIntl,
    private activatedRoute: ActivatedRoute,
    private router: Router,) { }

  ngOnInit(): void {
    const grupoIdParam: string = this.activatedRoute.snapshot.paramMap.get('grupoId');
    this.router.navigate([`/detalle-grupo-profesor/grupo/${grupoIdParam}/opcion/porrevisar/recurso/${this.recursoSelccionado.recursoId}`]);
    this.tituloRecurso = this.recursoSelccionado.titulo;
    if (this.ensenanzaAprendizajeService.obtenerInfoGrupoSubscription == undefined) {
      this.ensenanzaAprendizajeService.obtenerInfoGrupoSubscription = this.ensenanzaAprendizajeService.invocarObtenerInfoGrupo.subscribe(() => {
        this.obtenerInfoGrupo(this.recursoSelccionado.grupoId, this.recursoSelccionado.recursoId);
      });
    }
    this.inicializarForm();
    this.obtenerInfoGrupo(this.recursoSelccionado.grupoId, this.recursoSelccionado.recursoId);
    if (this.recursoSelccionado.tipoRecursoId === 2) {
      this.recursoTarea = true;
    } else if (this.recursoSelccionado.tipoRecursoId === 3) {
      this.recursoExamen = true;
    } else if (this.recursoSelccionado.tipoRecursoId === 4) {
      this.recursoEncuesta = true;
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  public cerrarTarea(evento: any) {
    if (evento.target.checked) {
      this.ensenanzaAprendizajeService.cerrarTarea(true, this.recursoSelccionado.recursoId).subscribe(
        (cerrado: SimpleResponse) => {
          if (cerrado.success) {
            this.snackService.mostrarSnackBack('Se ha cerrado la tarea con exito.');
          } else {
            this.snackService.mostrarSnackBack('No se ha cerrado la tarea.');
          }
        });
    }
    else {
      this.ensenanzaAprendizajeService.cerrarTarea(false, this.recursoSelccionado.recursoId).subscribe(
        (cerrado: SimpleResponse) => {
          if (cerrado.success) {
            this.snackService.mostrarSnackBack('Se ha abierto la tarea con exito.');
          } else {
            this.snackService.mostrarSnackBack('No se ha abierto la tarea.');
          }
        });
    }
  }

  public regresarRecursos() {
    this.grupoId.emit(this.recursoSelccionado.grupoId);
    const grupoIdParam: string = this.activatedRoute.snapshot.paramMap.get('grupoId');
    this.router.navigate([`/detalle-grupo-profesor/grupo/${grupoIdParam}/opcion/porrevisar`]);
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      idnombre: new FormControl(),
      estatus: new FormControl(0),
    });
  }

  public obtenerInfoGrupo(grupoId: number, recursoId: number): void {
    this.ensenanzaAprendizajeService.obtenerAlumnosRecurso(grupoId, recursoId).subscribe(
      (alumnos: ApiResponse<ListaAlumnosGrupos[]>) => {
        if (alumnos.data !== null) {
          this.listaAlumnos = alumnos.data;
          this.dataSource = new MatTableDataSource(alumnos.data);
          this.dataSource.paginator = this.paginator;
          this.selection = new SelectionModel<ListaAlumnosGrupos>(true, []);
          this.datafilter = alumnos.data;
          this.infoCargado = true;
        } else {

        }
      });
  }

  public filtrar() {
    var estatus = this.filtroForm['value'].estatus;
    var alumno = this.filtroForm['value'].idnombre == null ? "" : this.filtroForm['value'].idnombre;
    let filtroModificado: any = [];
    filtroModificado = this.listaAlumnos
    if (estatus != 0) {
      if (estatus === 1) {
        filtroModificado = filtroModificado.filter(row => row.estatusRecurso === 'Por Revisar');
      } else if (estatus === 2) {
        filtroModificado = filtroModificado.filter(row => row.estatusRecurso === 'Revisado');
      } else if (estatus === 3) {
        filtroModificado = filtroModificado.filter(row => row.estatusRecurso === 'Sin Entregar');
      }
    }
    if (alumno.length > 0 || alumno !== '') {
      filtroModificado = filtroModificado.filter(row => {
        return row.nombre.toLowerCase().search(alumno.toLowerCase()) > -1 || row.usuarioId.toString().search(alumno) > -1;
      })
    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
  }

  public limpiar() {
    this.inicializarForm();
    this.dataSource = new MatTableDataSource(this.datafilter);
    this.dataSource.paginator = this.paginator;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.datafilter.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    if (this.datafilter) {
      this.numAlumnosSeleccionado = this.selection.selected.length;
      const numRows = this.datafilter.length;
      return this.numAlumnosSeleccionado === numRows;
    }
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }

  ExcelSubscription: Subscription;
  public descargarExcel(): void {
    this.ExcelSubscription = this.ensenanzaAprendizajeService.descargarExcelPorRevisar(this.listaAlumnos).subscribe((res: Blob) => {
      var filename = "Recursos.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  public descargarExcelEncuesta(): void {
    this.ensenanzaAprendizajeService.obtenerExcelEncuesta(this.recursoSelccionado.recursoId, this.recursoSelccionado.grupoId).subscribe((response: ApiResponse<any[]>) => {
      if (response.success) {
        console.log("DATA", response.data);
        this.ExcelSubscription = this.ensenanzaAprendizajeService.descargarExcelEncuesta(response.data).subscribe((res: Blob) => {
          var filename = "Recursos.xlsx";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(res, filename);
          } else {
            var a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            var blob = new Blob([res], { type: 'application/xlsx' });
            a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            a.click();
          }
          this.ExcelSubscription.unsubscribe();
        })
      } else {
        console.log("ERROR AL OBTENER ENCUESTA")
      }
    });
  }

  public enviarCorreo(elemento?: any): void {
    if (elemento.length === 1) {
      var datos = elemento[0];
    } else {
      var datos = elemento;
    }
    this.dialog.open(EnviarCorreoComponent, { data: datos, disableClose: true });
  }

  public abrirModalRevisionRecurso(elemento: ListaAlumnosGrupos) {
    if (elemento.estatusRecurso === 'Sin Entregar') {
      this.snackService.mostrarSnackBack('No se puede revisar porque no ha entregado.');
    }
    else {
      let datos = {
        recursoSeleccionado: Object.assign(this.recursoSelccionado, elemento),
        ultimoParcial: this.ultimoParcial,
        existeParcialFinal: this.existeParcialFinal
      }
      console.log("datos", datos);
      this.utileriaService.abrirDialogoLateral(PorRevisarModalComponent, datos);

    }
  }
}
