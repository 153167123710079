import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Profesor } from '../../../../_models/profesor';
import { ProfesorService } from '../../../../_services/profesor.service';
import { UsuarioService } from '../../../../_services/usuario.service';

@Component({
  selector: 'app-form-estudios-previos',
  templateUrl: './form-estudios-previos.component.html',
  styleUrls: ['./form-estudios-previos.component.scss']
})
export class FormEstudiosPreviosComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>
  public errorActualizaProfesor: string = "";
  public objValidacionCancelar: Profesor = null;
  public dialogRefAdver: any;
  public estudiosForm: FormGroup;
  public tipoAccion: number;

  constructor(public dialogProfesorRef: MatDialogRef<FormEstudiosPreviosComponent>,
    private profesorService: ProfesorService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioService,
  ) { }

  ngOnInit(): void {
    this.inicializarForm();
  }

  public inicializarForm() {
    this.estudiosForm = new FormGroup({
      nivel: new FormControl(),
      academico: new FormControl(),
      promedio: new FormControl()
    });
  }

  get nivel() { return this.estudiosForm.get('nivel'); }
  get academico() { return this.estudiosForm.get('academico'); }
  get promedio() { return this.estudiosForm.get('promedio'); }

  public cerrarModalValidacion() {

  }

}
