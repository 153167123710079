import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl, environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/api/ApiRespose.model';

@Injectable({
  providedIn: 'root',
})
export class FunnelStageService {
  constructor(private httpClient: HttpClient) {}

  //Get
  public getFunnelStage(): Observable<ApiResponse<Array<any>>> {
    return this.httpClient.get<ApiResponse<Array<any>>>(`${environment.enves[baseUrl].apiUrl}/FunnelStage`);
  }
  //Get
  public getLeadsbyInteractions(campaignId: string): Observable<ApiResponse<Array<any>>> {
    return this.httpClient.get<ApiResponse<Array<any>>>(
      `${environment.enves[baseUrl].apiUrl}/FunnelStage/funnel?campaignId=${campaignId}`
    );
  }
}
