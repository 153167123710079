import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatosPersonalesAdminAcademicaComponent } from 'src/app/administracion-academica/avance-academico-modal/datos-personales-admin-academica/datos-personales-admin-academica.component';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { ConfirmationDialogServiceService } from 'src/app/services/confirmation-dialog-service.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { Biografia } from 'src/app/_models/biografia';
import { DatoFiscal } from 'src/app/_models/dato-fiscal';
import { EstudiosPrevios } from 'src/app/_models/estudios-previos';
import { Ocupacion } from 'src/app/_models/Ocupacion.model';
import { PersonaRelacionada } from 'src/app/_models/persona-relacionada';
import { AdminAcademicaService } from 'src/app/_services/admin-academica.service';
import { BiografiaService } from 'src/app/_services/biografia.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { EgresoService } from 'src/app/_services/egreso.service';
import { ProgramaAcademicoEgresoComponent } from './programa-academico-egreso/programa-academico-egreso.component';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { AttributesService } from '../../../_services/attributes.service';
import { AttributesGuardarDto } from '../../../_models/AttributesDto';
import { TraductorService } from '../../../_services/traductor.service';

@Component({
  selector: 'app-detalle-egreso-modal',
  templateUrl: './detalle-egreso-modal.component.html',
  styleUrls: ['./detalle-egreso-modal.component.scss']
})
export class DetalleEgresoModalComponent implements OnInit {

  //#region  Constructor
  constructor(public dialogRef: MatDialogRef<DetalleEgresoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { alumno: any, indexTap: number },
    public nuevoIngresoService: NuevoIngresoService,
    private administracionAcademcica: AdminAcademicaService,
    private sanitizer: DomSanitizer,
    private snackServiceService: SnackService,
    private biografiaService: BiografiaService,
    private confirmacionService: ConfirmationDialogServiceService,
    private EgresoService: EgresoService,
    public attributesService: AttributesService,
    private snackService: SnackService,
    private traductorService: TraductorService) {
    this.alumno = data.alumno;
  }
  //#endregion
  //#region Ciclo de vida
  ngOnInit(): void {
    console.log("DATA", this.data)
    this.indexTap.setValue(this.data.indexTap);
    this.cargarImagen();
    this.administracionAcademcica.getSubjetEstadoFormularioDatosUsuario().subscribe((cambio: any) => {
      if (cambio.renderizado) {
        this.estadoFormularioDatos = cambio.estado;
        this.cambiosSinGuardar = false;
      } else {
        this.estadoFormularioDatos = cambio.estado;
        this.cambiosSinGuardar = true;
      }
    });
  }
  //#endregion
  //#region ViewChild
  @ViewChild(DatosPersonalesAdminAcademicaComponent) datosPersonalesComponent: DatosPersonalesAdminAcademicaComponent;
  @ViewChild(ProgramaAcademicoEgresoComponent) programaAcademico: ProgramaAcademicoEgresoComponent;
  //#endregion
  //#region Propiedades
  indexTap: FormControl = new FormControl();
  alumno: any;
  base64Image: string;
  imagePath: SafeResourceUrl;
  private cambiosSinGuardar: boolean;
  public estadoFormularioDatos: boolean;
  private errorAlActualizar: boolean;
  datosUsuarioFrom: any;
  //#endregion

  //#region  Métodos
  /** 
   * Cierra el modal actual que se ve en pantalla
  */
  public CerrarModal(): void {
    if (this.cambiosSinGuardar) {
      this.mostrarModalCambiosSinGuardar();
    }
    else {
      this.EgresoService.invocarMetodoObtenerContenido();
      this.dialogRef.close();
    }
  }
  /**
   * consume el servicio que consulta la imagen del usuario con base al Id del mismo
   */
  cargarImagen(): void {
    this.nuevoIngresoService.imagenUsuario(this.alumno.usuarioId).subscribe(
      (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
        if (datosUsuarioImagen.success) {
          this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
        }
      }
    );
  }
  /**
   * Envía al servicio los datos del formulario
   */
  public async guardar() {
    this.datosUsuarioFrom = this.datosPersonalesComponent.datosPeronalesForm.value;
    this.GuardarProgramaAcademico();
    this.guardarDatosEgreso();
    this.editarDatosPeronales();
    this.cambiosSinGuardar = false;
  }
  /**
   * Ejecuta el método del Api que cambia los datos del Usuario así como de la biografía
   */
  private editarDatosPeronales(): void {
    if (this.tabSeleccionado === 1) {
      let datosPersonales = {
        correo: this.datosUsuarioFrom.correo,
        nombre: this.datosUsuarioFrom.nombre,
        usuarioId: this.alumno.usuarioId,
        apellidoPaterno: this.datosUsuarioFrom.apellidoPaterno,
        apellidoMaterno: this.datosUsuarioFrom.apellidoMaterno,
        biografia: {
          fechaNacimiento: this.datosUsuarioFrom.fechaNacimiento
        }
      };
      this.nuevoIngresoService.editarDatosPersonales(datosPersonales).subscribe((exito: SimpleResponse) => {
        if (exito.success) {
          this.editarDatosDeContacto();
        }
        else {
          this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos personales. Por favor, intente de nuevo más tarde.');
          this.errorAlActualizar = true;
        }
      });
    }
  }
  /**
   * Ejecuta el método del api que edita los datos de contacto del usuario
   */
  private editarDatosDeContacto(): void {
    let datosContacto = {
      usuarioId: this.alumno.usuarioId,
      correo: this.datosUsuarioFrom.correo,
      direccion: [
        {
          tipoDireccionId: this.datosUsuarioFrom.tipoDireccion,
          paisId: this.datosUsuarioFrom.pais,
          entidadFederativaId: this.datosUsuarioFrom.entidadFederativa,
          calle: this.datosUsuarioFrom.calle,
          ciudad: this.datosUsuarioFrom.ciudad,
          numeroExterior: this.datosUsuarioFrom.numeroExterior,
          numeroInterior: this.datosUsuarioFrom.numeroInterior,
          colonia: this.datosUsuarioFrom.ciudad,
          codigoPostal: this.datosUsuarioFrom.codigoPostal
        }
      ],
      telefono: [
        {
          tipoTelefonoId: this.datosUsuarioFrom.tipoTelefono,
          numero: this.datosUsuarioFrom.telefono,
          paisId: this.datosUsuarioFrom.paisIdTelefono,
          extension: this.datosUsuarioFrom.extensionTelefono
        }
      ]
    }

    this.nuevoIngresoService.registrarDatosContacto(datosContacto).subscribe((datosActualizados: SimpleResponse) => {
      if (datosActualizados.success) {
        this.validarCurpIngresada();
      }
      else {
        this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos personales. Por favor, intente de nuevo más tarde.');
        this.errorAlActualizar = true;
      }
    });
  }
  /**
   * Ejecuta el método del api que que valida si la curp ingresada está disponible
   */
  private validarCurpIngresada(): void {
    this.biografiaService.obtenerBiografiaByCurp(this.datosUsuarioFrom.curp, this.alumno.usuarioId).subscribe((CurpEncontrada: Biografia) => {
      if (CurpEncontrada) {
        this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de biografia. El CURP ingresado ya esta registrado, ingrese un CURP diferente.');
        this.errorAlActualizar = true;
      }
      else {
        this.modificarBiografia();
      }
    });
  }
  /**
   * Ejecuta el método del api que modifica los datos biográficos del usuario
   */
  private modificarBiografia(): void {
    let datosBiografia: Biografia = new Biografia();
    datosBiografia.usuarioId = this.alumno.usuarioId;
    datosBiografia.legalGeneroId = this.datosUsuarioFrom.sexo;
    datosBiografia.generoId = this.datosUsuarioFrom.genero;
    datosBiografia.entidadNacimiento = this.datosUsuarioFrom.entidadFederativaNacimiento;
    datosBiografia.ciudadNacimiento = this.datosUsuarioFrom.ciudadNacimiento;
    datosBiografia.estadoCivilId = this.datosUsuarioFrom.estadoCivil;
    datosBiografia.curp = this.datosUsuarioFrom.curp;
    datosBiografia.grupoSanguineoId = this.datosUsuarioFrom.tipoDeSangre;
    datosBiografia.alergias = this.datosUsuarioFrom.alergias;
    datosBiografia.enfermedades = this.datosUsuarioFrom.enfermedades;
    datosBiografia.ciudadaniaPaisId = this.datosUsuarioFrom.paisNacimiento;
    this.nuevoIngresoService.modificarBiografia(datosBiografia).subscribe((datosActualizados: SimpleResponse) => {
      if (datosActualizados.success) {
        this.actualizarDatosPersonasRelacionadas();
      }
      else {
        this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de biografia.' +
          'Por favor, intente de nuevo más tarde.');
        this.errorAlActualizar = true;
      }
    });
  }
  /**
   *  Actualizar los datos de las personas relacionadas del usuario
   * Datos familiares: (Madre, padre, tutor, conyuge, etc)
   */
  private actualizarDatosPersonasRelacionadas(): void {
    let personasRelacionadas = new Array<PersonaRelacionada>();

    if (this.datosUsuarioFrom.nombrePadre) {
      let padre = new PersonaRelacionada();
      padre.usuarioId = this.alumno.usuarioId;
      padre.tipoRelacionId = this.datosUsuarioFrom.tipoRelacionPadre;
      padre.nombreCompleto = this.datosUsuarioFrom.nombrePadre;
      padre.telefono = this.datosUsuarioFrom.telefonoPadre;
      padre.correoElectronico = this.datosUsuarioFrom.correoPadre;
      personasRelacionadas.push(padre);
    }
    if (this.datosUsuarioFrom.nombrePadre) {
      let madre = new PersonaRelacionada();
      madre.usuarioId = this.alumno.usuarioId;
      madre.tipoRelacionId = this.datosUsuarioFrom.tipoRelacionMadre;
      madre.telefono = this.datosUsuarioFrom.telefonoMadre;
      madre.nombreCompleto = this.datosUsuarioFrom.nombreMadre;
      madre.correoElectronico = this.datosUsuarioFrom.correoMadre;
      personasRelacionadas.push(madre);
    }
    if (this.datosUsuarioFrom.nombreTutor) {
      let tutor = new PersonaRelacionada();
      tutor.usuarioId = this.alumno.usuarioId;
      tutor.tipoRelacionId = this.datosUsuarioFrom.tipoRelacionTutor;
      tutor.nombreCompleto = this.datosUsuarioFrom.nombreTutor;
      tutor.telefono = this.datosUsuarioFrom.telefonoTutor;
      tutor.correoElectronico = this.datosUsuarioFrom.correoTutor;
      personasRelacionadas.push(tutor);
    }
    this.nuevoIngresoService.registrarDatosFamilia(personasRelacionadas).subscribe((datosFamilairesActualziados: SimpleResponse) => {
      if (datosFamilairesActualziados.success) {
        this.actualizarDatosDeEmergencia();
      }
      else {
        this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de familiares.' +
          'Por favor, intente de nuevo más tarde.');
        this.errorAlActualizar = true;
      }
    });
  }

  private actualizarDatosDeEmergencia(): void {
    let datosEmergencia = new PersonaRelacionada();
    datosEmergencia.usuarioId = this.alumno.usuarioId;
    datosEmergencia.tipoRelacionId = this.datosUsuarioFrom.tipoRelacionEmergencia;
    datosEmergencia.nombreCompleto = this.datosUsuarioFrom.nombreEmergencia;
    datosEmergencia.telefono = this.datosUsuarioFrom.telefonoEmergencia;
    datosEmergencia.correoElectronico = this.datosUsuarioFrom.correoEmergencia;
    this.nuevoIngresoService.registrarDatosEmergencia(datosEmergencia).subscribe((datosActualizados: SimpleResponse) => {
      if (datosActualizados.success) {
        this.actualizarEstudiosPrevios();
      }
      else {
        this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de casos de emergencia' +
          'Por favor, intente de nuevo más tarde.');
        this.errorAlActualizar = true;
      }
    });
  }
  /**
   * Ejecuta el método del Api que actualiza los estuiso previso del usuarios
   */
  private actualizarEstudiosPrevios(): void {
    let datosEstudiosPrevios = new EstudiosPrevios();
    datosEstudiosPrevios.usuarioId = this.alumno.usuarioId;
    datosEstudiosPrevios.nivelId = this.datosUsuarioFrom.nivelAcademicoPrevio;
    datosEstudiosPrevios.escuela = this.datosUsuarioFrom.escuelaDeProcedencia;
    datosEstudiosPrevios.promedio = this.datosUsuarioFrom.promedioPrevio;
    this.nuevoIngresoService.agregarEstudiosPrevios(datosEstudiosPrevios).subscribe((datosActualizados: SimpleResponse) => {
      if (datosActualizados.success) {
        this.comprobarDisponibilidadRFC();
      }
      else {
        this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de estudios previos' +
          'Por favor, intente de nuevo más tarde.');
        this.errorAlActualizar = true;
      }
    });
  }
  /**
   * Comprueba que ele RFC ingresado no esté siendo usado por otro usuario
   */
  private comprobarDisponibilidadRFC(): void {
    this.nuevoIngresoService.GetDatoFiscalByRFC(this.datosUsuarioFrom.rfc, this.alumno.usuarioId).subscribe(
      (datoFiscalEncontrado: ApiResponse<DatoFiscal>) => {
        if (datoFiscalEncontrado.data) {
          this.errorAlActualizar = true;
          this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de facturacion. ' +
            'El RFC ingresado ya esta registrado, ingrese un RFC diferente.');
        }
        else {
          this.actualizarDatosFiscales();
        }
      });
  }
  /**
   * Ejecuta el método del Api que actualualiza los datos fiscales del usuario
   */
  private actualizarDatosFiscales(): void {
    let datosFiscales = new DatoFiscal();
    datosFiscales.usuarioId = this.alumno.usuarioId;
    datosFiscales.rfc = this.datosUsuarioFrom.rfc;
    datosFiscales.razonSocial = this.datosUsuarioFrom.nombreRazonSocial;
    datosFiscales.entidadFederativaId = this.datosUsuarioFrom.estadoFacturacion;
    datosFiscales.calle = this.datosUsuarioFrom.calleFacturacion;
    datosFiscales.ciudad = this.datosUsuarioFrom.ciudadFacturacion;
    datosFiscales.numeroExterior = this.datosUsuarioFrom.numeroExteriorFacturacion;
    datosFiscales.numeroInterior = this.datosUsuarioFrom.numeroInteriorFacturacion;
    datosFiscales.colonia = this.datosUsuarioFrom.coloniaFacturacion;
    datosFiscales.codigoPostal = this.datosUsuarioFrom.codigoPostalFacturacion;
    datosFiscales.nombre = this.datosUsuarioFrom.nombre;
    datosFiscales.apellidoPaterno = this.datosUsuarioFrom.apellidoPaterno;
    datosFiscales.apellidoMaterno = this.datosUsuarioFrom.apellidoMaterno;

    this.nuevoIngresoService.agregarDatosFiscal(datosFiscales).subscribe((datosFiscalesActualizados: SimpleResponse) => {
      if (datosFiscalesActualizados.success) {
        this.actualizarDatosDeOcupacion();
      }
      else {
        this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos fiscales. ' +
          'El RFC ingresado ya esta registrado, ingrese un RFC diferente.');
        this.errorAlActualizar = true;
      }
    });
  }

  private actualizarDatosDeOcupacion(): void {
    let ocupacion = new Ocupacion();
    ocupacion.usuarioId = this.alumno.usuarioId;
    ocupacion.tipoEmpresaId = this.datosUsuarioFrom.tipoDeEmpresaOcupacion;
    ocupacion.tipoPuestoEmpresaId = this.datosUsuarioFrom.tipoDePuestoOcupacion;
    this.nuevoIngresoService.registrarOcupacion(ocupacion).subscribe((datosOcupacionActualizados: SimpleResponse) => {
      if (datosOcupacionActualizados.success) {
        this.snackServiceService.mostrarSnackBack('Se han guardado los datos correctamente.');
        this.administracionAcademcica
      }
      else {
        this.snackServiceService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de ocupación. ' +
          'El RFC ingresado ya esta registrado, ingrese un RFC diferente.');
      }
    });
  }
  /**
   * Pone en pantalla el mensaje de descargar cambios
   */
  public mostrarModalCambiosSinGuardar(): void {
    this.confirmacionService.abrirModal(["Datos personales"]).afterClosed().subscribe((descargar: boolean) => {
      if (descargar) {
        this.dialogRef.close();
      }
    });
  }

  /**
  * Cambiar el index del objeto data cada vez que se cambia de tap en la vista y setea un nuevo valor
  * @param event 
  */
  onTabClick(event: MatTabChangeEvent) {
    this.indexTap.setValue(event.index);
    let tab: number = event.index;
    this.tabSeleccionado = tab;
  }
  //#endregion

  public tabSeleccionado: number = 0;
  //#region Guardar Programa Academico
  public GuardarProgramaAcademico() {
    if (this.tabSeleccionado === 0) {
      let programaAcademicoForm = Object.assign(this.programaAcademico.programaAcademicoForm.value);
      let programaAcademico: any = {
        usuarioId: programaAcademicoForm.usuarioId,
        alumnoId: this.programaAcademico.data.alumno.alumnoId,
        estatusAcademicoId: programaAcademicoForm.estatusAdmision,
        campus: {
          CampusId: this.programaAcademico.data.alumno.campusId,
        },
        programa: {
          ProgramaId: this.programaAcademico.data.alumno.programaId,
        },
        periodo: {
          periodoId: this.programaAcademico.data.alumno.periodoAdmisión,
        }
      }
      this.nuevoIngresoService.editarProgramaUsuario(programaAcademico).subscribe(
        (respuesta: SimpleResponse) => {
          if (respuesta.success) {
            // this.snackServiceService.mostrarSnackBack(respuesta.message);
            let atributosGuardar: AttributesGuardarDto = {
              alumnoId: this.data.alumno.alumnoId,
              attributes: this.programaAcademico.filtroForm.value.atributoF
            }
            this.attributesService.guardarAtributosAlumno(atributosGuardar).subscribe((response: SimpleResponse) => {
              if (response.success) {
                this.administracionAcademcica.actualizarDatosTabla();
                let mensaje: string = this.traductorService.translate('guardadoCorrecto');
                this.snackService.mostrarSnackBack(mensaje);
              } else {
                let mensaje: string = this.traductorService.translate('errorGuardar');
                this.snackService.mostrarSnackBack(mensaje);
              }
            });


          } else {
            this.snackServiceService.mostrarSnackBack(respuesta.message);
          }
        }
      )
    }
  }
  //#endregion


  //#region Guardar Datos Egreso
  public guardarDatosEgreso() {
    if (this.tabSeleccionado === 0) {
      let programaAcademicoForm = Object.assign(this.programaAcademico.programaAcademicoForm.value);
      let alumno: any = {
        alumnoId: this.programaAcademico.data.alumno.alumnoId,
        periodoEgresoId: programaAcademicoForm.periodoEgreso,
        tipoModalidadTitulacionId: programaAcademicoForm.modalidadTitulacion
      }

      this.EgresoService.AsinarTipoTitulacionPeriodoAdmin(alumno).subscribe(response => {
        if (response.success) {
          this.snackServiceService.mostrarSnackBack(response.message);
        } else {
          this.snackServiceService.mostrarSnackBack(response.message);
        }
      })
    }
  }
  //#endregion

}
