import { Grupos } from './../../../_models/ensenanzaAprendizajeAlumno';
import { EnsenanzaAprendizajeService } from '../../../_services/ensenanza-aprendizaje.service';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service'
import { Component, Input, OnInit } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { ContenidoParcial } from '../../../_models/ensenanza-aprendizaje-profesor';
import { ApiResponse } from '../../../models/api/ApiRespose.model';

@Component({
  selector: 'app-modal-contenido-curso',
  templateUrl: './modal-contenido-curso.component.html',
  styleUrls: ['./modal-contenido-curso.component.scss']
})
export class ModalContenidoCursoComponent implements OnInit {

  @Input() data: any;
  @Input() expanded: boolean = true;
  puntosBaseParcial: number;
  datosGrupo: any;
  grupo: Grupos[];
  modulos: any[] = [];
  grupoParcial: any[] = [];
  existeParcialFinal: boolean = false;
  public ultimoParcial: number;
  contenido: ContenidoParcial[] = [];
  public btnElemento: string = '';
  indiceModulo: number;
  expandir: boolean = false;

  constructor(private ensenanzaApredServ: EnsenanzaAprendizajeService,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private i18nextPipe: I18NextPipe,) { }

  ngOnInit(): void {
    this.cargarDatosGrupo();
    this.cargarParciales();
    this.grupo = this.data.contenidoCurso;
    this.grupoParcial = this.data.contenidoCurso.grupoParcial;
    this.modulos = this.data.contenidoCurso.modulo;
    console.log("dataaa", this.data);
  }

  public cargarParciales() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerParciales(this.data.datosPersonales.alumnoId, this.data.contenidoCurso.grupoId).subscribe(resp => {
      if (resp.exito) {
        if (resp.dataParcialFinal) {
          this.existeParcialFinal = true;
          this.ultimoParcial = this.grupoParcial[this.grupoParcial.length - 1].parcial;
        } else {
          this.existeParcialFinal = false;
        }

        resp.dataParciales.forEach(parcial => {

          let parcialText = '_tableroProfesor.parcial';
          if (!!this.existeParcialFinal && parcial == resp.dataParciales.length) {
            parcialText = '_tableroProfesor.parcial-final';
          };

          if (this.grupoParcial.length > 0) {
            let nuevoParcial = this.grupoParcial.find(object => {
              return object.parcial == parcial
            });
            if (nuevoParcial) {
              let parcialobj = {
                parcial: nuevoParcial.parcial,
                ponderacion: nuevoParcial.ponderacion,
                nombre: nuevoParcial.nombre,
              }
              if (!parcialobj?.nombre) {
                parcialobj.nombre = this.translate(parcialText) + ' ' + parcial;
              }
              this.contenido.push(parcialobj);
            } else {

              let _parcialObj = {
                parcial: parcial,
                ponderacion: 0,
                nombre: this.translate(parcialText) + ' ' + parcial
              }
              this.contenido.push(_parcialObj);
            }
          }
          else {
            let _parcialObj = {
              parcial: parcial,
              ponderacion: 0,
              nombre: this.translate(parcialText) + ' ' + parcial
            }
            this.contenido.push(_parcialObj);
          }
        });
      }
    })
  }

  public cargarDatosGrupo() {
    this.ensenanzaApredServ.getPonderacionGrupo(this.data.contenidoCurso.grupoId).subscribe((grupo: ApiResponse<any>) => {
      this.datosGrupo = grupo.data;
      this.puntosBaseParcial = this.datosGrupo.materiaOferta.materia.ciclo.programa.escalaCalificacionNavigation.calificacionNoNumerica[0].valorNumerico;
    })
  }

  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  public expandirTema(elemento, indiceModulo, expande) {
    this.btnElemento = elemento;
    this.indiceModulo = indiceModulo;
    this.expandir = expande;
  }

}
