import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogClose, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AccionEnum } from '../_models/accion.enum';
import { TipoServicio } from '../_models/tipo-servicio';
import { ConfiguracionCuotaService } from '../_services/configuracion-cuota.service';
import { UtileriasService } from '../_services/utilerias.service';
import { ModalTipoServicioComponent } from './modal-tipo-servicio/modal-tipo-servicio.component';
import { ModalEliminarServicioComponent } from './modal-eliminar-servicio/modal-eliminar-servicio.component';

@Component({


  selector: 'app-tipo-servicio',
  templateUrl: './tipo-servicio.component.html',
  styleUrls: ['./tipo-servicio.component.scss']
})
export class TipoServicioComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'Clave', 'Nombre', 'Categoria', 'Estatus'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  @ViewChild('allCategoria') private allCategoria: MatOption;

  public servicio: any;
  public categoriaList: any[] = [];
  public valorSeleccionado: any[] = [];
  public infoCargado = false;
  public numServicioSeleccionado = 0;
  public arrOriginal: any[] = [];
  public arrCategoria: any[] = [];
  public arrEstatus: any[] = [];
  public claveNombre = new FormControl();
  public categoriaF = new FormControl();
  public estatusF = new FormControl();
  public filtroForm: FormGroup;


  constructor(
    public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    private servicios: ConfiguracionCuotaService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { 

  }

  ngOnInit(): void {
    if(this.servicios.obtenerTipoServicioSubscription === undefined){
      this.servicios.obtenerTipoServicioSubscription = this.servicios.invocarObtenerTipoServicio.subscribe(() => {
        this.obtenerServicios();
      })
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
      this.paginador.nextPageLabel = "Página siguiente";
      this.paginador.previousPageLabel = "Página anterior";
      this.paginador.firstPageLabel = "Primera página";
      this.paginador.lastPageLabel = "Última página";
  
      this.contador.getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
          return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
      this.obtenerCategoria();
      this.obtenerServicios();
      this.llenarFiltros();
      this.inicializarForm();
  }
  // Inicio de los filtros
  public inicializarForm() {
    this.filtroForm = new FormGroup({
      claveNombre: new FormControl(''),
      categoriaF: new FormControl([]),
      estatusF: new FormControl([])
    });
  }

  public filtrado() {
    let claveName: string = this.claveNombre.value == null ? '' : this.claveNombre.value;
    let categoria: any[] = this.categoriaF.value == null ? [] : this.categoriaF.value;
    let estatusF: any = this.estatusF.value == null ? 0 : this.estatusF.value;
    let arrFiltrado: any = [];
    arrFiltrado = [...this.arrOriginal];
    if (claveName.length > 0 || claveName !== '') {
      arrFiltrado = arrFiltrado.filter(el => {
        return el.nombre.toLowerCase().search(claveName.toLowerCase()) > -1 || el.clave.toString().search(claveName) > -1;
      });
    }
    if (categoria.length > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return categoria.indexOf(f.categoriaId) > -1 });
    }
    if (estatusF == 0) {
      // arrFiltrado = this.arrOriginal.filter(r => r.estatus == estatus);
      arrFiltrado = arrFiltrado.filter(r => { return r.estatus == false || r.estatus == true } );
      
      //arrFiltrado = this.arrOriginal;
    }
    if (estatusF !== 0) {
      // arrFiltrado = this.arrOriginal.filter(r => r.estatus == estatus);
      if(estatusF == 1) {
        arrFiltrado = arrFiltrado.filter(r => { return r.estatus == true } );
      } else {
        arrFiltrado = arrFiltrado.filter(r => { return r.estatus == false } );
      }
    }
    this.dataSource = new MatTableDataSource(arrFiltrado);
    this.dataSource.paginator = this.paginator;
  }

  tosslePerOneCategoria(all) {
    if (this.allCategoria.selected) {
      this.allCategoria.deselect();
      
    }
    if (this.categoriaF.value.length == this.arrCategoria.length) { this.allCategoria.select(); }
    this.filtrado();
  }

  tossleAllSelectionCategoria() {
    if (this.allCategoria.selected) {
      this.categoriaF.patchValue([...this.arrCategoria.map(item => item.categoriaServicioId), 0]);
    } else {
      this.categoriaF.patchValue([]);
    }
    this.filtrado();
  }

  public llenarFiltros() {
    this.servicios.obtenerCategoria().subscribe(
      (categoria: any[]) => {
        this.arrCategoria = categoria;
        this.categoriaF.setValue([...this.arrCategoria.map(item => item.categoriaServicioId), 0]);
        this.filtrado();
    });
  }

  public limpiarFiltros() {
    this.claveNombre.setValue('');
    this.categoriaF.patchValue([...this.arrCategoria.map(item => item.categoriaServicioId), 0]);
    this.estatusF.patchValue(0);
    this.filtrado();
  }
  // Fin de los filtros

  public obtenerCategoria() {
    this.servicios.obtenerCategoria().subscribe(
      (categoria: any[]) => {
        this.categoriaList = categoria;
      }
    )
  }

  public obtenerServicios() {
    this.servicios.obtenerTiposServicios().subscribe(servicios => {
        this.dataSource = new MatTableDataSource(servicios.data);
        this.selection = new SelectionModel<any>(true, []);
        this.dataSource.paginator = this.paginator;
        this.infoCargado = true;
        this.arrOriginal = [...servicios.data];
      }
    )
  }

  public findCategoria(categoriaId: number) {
    return this.categoriaList.find(id => id.categoriaServicioId === categoriaId).nombre;
  }

  public crear(): void {
    let datos: TipoServicio = {
      servicioId: 0,
      categoriaId: 0,
      clave: '',
      nombre: '',
      descripcion: '',
      estatus: false,
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(ModalTipoServicioComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if(!!result){
        this.servicio = result;
      }
    })
  }

  public editar(elemento?: any): void {
    let datos: TipoServicio = {
      servicioId: elemento.servicioId,
      categoriaId: elemento.categoriaId,
      clave: elemento.clave,
      nombre: elemento.nombre,
      descripcion: elemento.descripcion,
      estatus: elemento.estatus,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(ModalTipoServicioComponent, datos);
  }

  ExcelSubscription: Subscription;
  public descargarExcel(){
    this.ExcelSubscription = this.servicios.downloadExcelTipoServicio(this.arrOriginal).subscribe((res: Blob) => {
      var filename = "Consultar-Tipo-Servicio.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  public borrar(btnDataTable: boolean, elemento?: any): void {
    if(btnDataTable) {
      var datos = elemento;
    } else {
      if(elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    // pendiente de agregar modal de eliminar
    const dialogEliminar = this.dialog.open(ModalEliminarServicioComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
    dialogEliminar.afterClosed().subscribe(result => {
      if(!!result){
        
      }
    });
  }

  isAllSelected() {
    this.numServicioSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numServicioSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() :  this.dataSource.data.forEach(row => this.selection.select(row))
  }
}
