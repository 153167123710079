import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import timeGridPlugin from '@fullcalendar/timegrid';
import { interval, Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { InscripcionService } from 'src/app/_services/inscripcion.service';

@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.scss']
})
export class HorarioComponent implements OnInit, AfterViewInit {
  public horarioSeleccionado$: Observable<any>;
  public horarioSeleccionado: any;
  calendarOptions: CalendarOptions;
  localeCalendario = esLocale;
  public eventos: any[] = [];
  public datosCalendario: any[] = [];
  subscription: Subscription;
  public segundaValidacion:boolean;
  @ViewChild('fullcalendar', { static: true }) fullcalendar: FullCalendarComponent;

  constructor(
    public inscripcionService: InscripcionService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.horarioSeleccionado$ = this.inscripcionService.obtenerHorarioSeleccionado$();
    this.horarioSeleccionado$.subscribe(datos => this.horarioSeleccionado = datos);
   
    this.segundaValidacion = false;
    const source = interval(1000);
    this.subscription = source.subscribe(val => this.renderCalendar());
  }

  /**
   * Renderiza el calendario, llenandolo de los datos obtenidos 
   */
  public renderCalendar(): void {
    if (this.horarioSeleccionado && !this.segundaValidacion) {
      const calendarApi = this.fullcalendar.getApi();
      calendarApi.changeView('timeGridWeek');
      this.segundaValidacion = true;
    }

  }

  /**
   * Desuscribe los campos
   */
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  /**
   * Obtiene el horario de los grupos despues de que se carga el componente
   */
  ngAfterViewInit(): void {
    this.ObtenerHorarioGrupos(this.data[0].alumnoId)
  }

  /**
   * Carga el componente que muestra el calendario
   */
  public async calendario(): Promise<void> {
    this.calendarOptions = {
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      resourceAreaWidth: '200px',
      height: 450,
      locale: esLocale,
      plugins: [timeGridPlugin],
      initialView: 'timeGridWeek',
      editable: false,
      events: this.eventos,
      eventColor: '#43b9fe',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      }
    };
  }

  /**
   * Obtiene el horario del alumno segun las materias que tenga
   * @param alumnoId 
   */
  public async ObtenerHorarioGrupos(alumnoId): Promise<void> {
    this.inscripcionService.getHorarioGrupos(alumnoId).subscribe((respuestaGrupos: ApiResponse<any>) => {
      this.datosCalendario = respuestaGrupos.data;
      this.eventos = this.getEvents();
      this.calendario();
      this.ngOnInit();
    })
  }

  /**
   * Obtiene los eventos y los agrega en un cuadro con el nombre de la amteria y los dias y horas que tenga
   * @returns
   */
  public getEvents(): any[] {
    let eventosProcesados: any[] = [];
    this.datosCalendario.forEach(alumnoGrupo => {
      alumnoGrupo.grupo.horario.forEach(horario => {
        let dias: number[] = [];
        if (horario.esLunes) {
          dias.push(1);
        };
        if (horario.esMartes) {
          dias.push(2);
        };
        if (horario.esMiercoles) {
          dias.push(3);
        };
        if (horario.esJueves) {
          dias.push(4);
        };
        if (horario.esViernes) {
          dias.push(5);
        };
        if (horario.esSabado) {
          dias.push(6);
        };
        if (horario.esDomingo) {
          dias.push(7);
        };
        eventosProcesados.push({
          id: horario.horarioId,
          title: alumnoGrupo.grupo.materiaOferta.clave,
          start: horario.fechaInicio + 'T' + horario.horaInicio + ":00",
          end: horario.fechaFin + horario.horaFin + ":00",
          daysOfWeek: dias,
          startRecur: horario.fechaInicio,
          endRecur: horario.fechaFin,
          startTime: horario.horaInicio,
          endTime: horario.horaFin,
          resourceId: horario.nombre + horario.grupoId,
          allDay: false
        })
      });
    });
    return eventosProcesados;
  }
}
