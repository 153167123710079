import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TipoApoyoFinanciero } from '../../_models/tipo-apoyo-financiero';
import { Empresa } from '../../_models/empresa';

@Component({
  selector: 'app-registro-beca',
  templateUrl: './registro-beca.component.html',
  styleUrls: ['./registro-beca.component.scss']
})
export class RegistroBecaComponent implements OnInit {
  
  public becaForm: FormGroup;
  public subscripcionBeca: Subscription;
  public subscripcionEmpresa: Subscription;
  public apoyoList: TipoApoyoFinanciero[] = [];
  public empresaList: Empresa[] = [];

  constructor() { }

  ngOnInit(): void {
    this.inicializaFrom();
  }

  public inicializaFrom() {
    this.becaForm = new FormGroup({
      tipoApoyo: new FormControl(''),
      empresa: new FormControl(''),
      motivo: new FormControl(''),
      ingreso: new FormControl('', [Validators.required]),
      cartaEmpresa: new FormControl('', [Validators.required]),
    });
  }
  //Get
  get tipoApoyo() { return this.becaForm.get('tipoApoyo'); }
  get empresa() { return this.becaForm.get('empresa'); }
  get motivo() { return this.becaForm.get('motivo'); }
  get ingreso() { return this.becaForm.get('ingreso'); }
  get cartaEmpresa() { return this.becaForm.get('cartaEmpresa'); }

  public onFileChange(event) {}

  public enviar() {}

  public Siguiente() {}
}
