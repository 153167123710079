import { Materia } from "./materia";

export class Ciclo {
  /**
   * Identificador unico del ciclo
   */
  cicloId: number;

  /**
   * Identificador del programa al que pertenecera el ciclo
   */
  programaId: number;

  /**
   * Clave del ciclo
   */
  clave: string;

  /**
   * Nombre del ciclo
   */
  nombre: string;

  /**
   * Define si el ciclo esta activo o inactivo
   */
  esOficial: boolean;

  /**
   * Indica el tipo de accion que tendra el registro
   */
  tipoAccion: number;

  /**
   * Arreglo de materias que tiene el ciclo
   */
  materia?: Materia[];
}
