import { Component, OnInit, ViewChild, Inject, TemplateRef, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NuevoIngreso } from '../../../_models/nuevo-ingreso';
import { NuevoIngresoService } from '../../../_services/nuevo-ingreso.service';
import { Subscription, Observable } from 'rxjs';
import { ProfesorService } from '../../../_services/profesor.service';
import { FormDatosBiograficosComponent } from './form-datos-biograficos/form-datos-biograficos.component';
import { FormDatosContactoComponent } from './form-datos-contacto/form-datos-contacto.component';

import { FormDatosFamiliaresComponent } from './form-datos-familiares/form-datos-familiares.component';
import { FormDatosLaboralesComponent } from './form-datos-laborales/form-datos-laborales.component';
import { FormDatosPersonalesComponent } from './form-datos-personales/form-datos-personales.component';
import { FormEstudiosPreviosComponent } from './form-estudios-previos/form-estudios-previos.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AccionEnum } from '../../../_models/accion.enum';
import { FormCargaDocumentosComponent } from './form-carga-documentos/form-carga-documentos.component';
import { UsuarioService } from '../../../_services/usuario.service';
import { Usuario } from '../../../_models/usuario';

@Component({
  selector: 'app-oferta-profesor-modal',
  templateUrl: './oferta-profesor-modal.component.html',
  styleUrls: ['./oferta-profesor-modal.component.scss']
})
export class OfertaProfesorModalComponent implements OnInit {

  //Hijos de parte de Profesor
  //@ViewChild(FormCuentaComponent) formCuenta: FormCuentaComponent;
  //@ViewChild(FormDatosPersonalesComponent) formDatosPersonales: FormDatosPersonalesComponent;
  @ViewChild(FormDatosContactoComponent) formDatosContacto: FormDatosContactoComponent;
  @ViewChild(FormDatosBiograficosComponent) formDatosBiograficos: FormDatosBiograficosComponent;
  //@ViewChild(FormDatosFamiliaresComponent) formDatosFamiliares: FormDatosFamiliaresComponent;
  @ViewChild(FormDatosLaboralesComponent) formDatosLaborales: FormDatosLaboralesComponent;
  //@ViewChild(FormEstudiosPreviosComponent) formEstudiosPrevios: FormEstudiosPreviosComponent;
  @ViewChild(FormCargaDocumentosComponent) formCargaDocumentos: FormCargaDocumentosComponent;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;

  //Variables a utilizar
  public boolDesabilitarTab: boolean = true;
  public datosProfesor: any;
  public stepSeleccionado: number = 0;
  public tabSeleccionadoIndex: number = 0;
  public tabCambio: boolean = false;
  public boolTabDisabled$: Observable<boolean>;
  public boolTabDisabledSubscription: Subscription;
  public boolCambioPendiente$: Observable<boolean>;
  public boolCambio: boolean = false;
  public boolCambioPendienteSubscription: Subscription;
  public dialogoRefAdver: any;
  public tipoAccion: number;
  public usuarioList: Usuario[] = [];
  public correo: string = "";
  public datosUsuarioParent: any;

  constructor(public dialogRef: MatDialogRef<OfertaProfesorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Usuario,
    private nuevoIngresoService: NuevoIngresoService,
    private profesorService: ProfesorService,
    private usuarioService: UsuarioService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    
  ) { }

  ngOnInit(): void {
    
  }

  //get cuenta() { return this.formCuenta}
  get contacto() { return this.formDatosContacto ? this.formDatosContacto.contactoForm : null;}
  get biografia() { return this.formDatosBiograficos ? this.formDatosBiograficos.biografiaForm : null;}
  get laborales() { return this.formDatosLaborales ? this.formDatosLaborales.laboralForm : null; }
  get documentos() { return this.formCargaDocumentos ? this.formCargaDocumentos.documentoForm : null;}

  public cerrarModal() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(this.data);
    }
  }

  public cambioStep(event) {

  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      if (this.tabCambio) {

      } else {
        this.dialogoRefAdver.close();
        this.dialogRef.close(this.data);
      }
    } else {
      if (this.tabCambio) {

      } else {
        this.dialogoRefAdver.close();
      }
    }
  }

  public enviar() {
    // this.formCuenta.enviarDatosRegistro();
    this.formDatosContacto.enviar();
    this.formDatosBiograficos.enviar();
    this.formDatosLaborales.enviar();
    /*
    switch (this.stepSeleccionado) {
      case 0: //Cuenta
        if (this.data.tipoAccion === AccionEnum.EDITAR) {

        } else {
          this.formCuenta.enviarDatosRegistro();
        }
        break;
      case 1: //Datos de Contacto
        this.formDatosContacto.enviar();
      case 2: //Datos Biograficos y Persona Relacionada
        this.formDatosBiograficos.enviar();
    }
    */
  }


  public obtenerDatosUsuario(data: any) {
    this.datosUsuarioParent = data;
  }
}
