export class ColegiaturaCostoDto{
    /**
     * Define si el pago sera por parcialidades
     */
    parcialidades: boolean;

    /**
     * Es el costo que tendrá la colegiatura
     */
    costo: number;

    /**
     * identificador del alumno
     */
    alumnoId: number;

    /**
     * identificador del campus al que pertenece el usuario
     */
    campusId: number;

    /**
     * Identificador del nivel al que pertenece el usuario
     */
    nivelId: number;

    /**
     * Identificador del periodo 
     */
    periodoId: number;

    /**
     * Clave del tipo de cobro
     */
    claveTipoCobro: string;

    /**
     * Dia de vencimiento del pago
     */
    diaVencimiento: number;

    /**
     * Fecha de vencimiento para hacer el pago
     */
    fechaVencimiento: Date;

    /**
     * Fecha de inicio del cargo
     */
    fechaInicio: Date;

    /**
     * Fecha fin del cargo
     */
    fechaFin: Date;

    /**
     * Cantidad agregada por impuestos
     */
    impuesto: number;

    /**
     * Cantidad agregada por intereses
     */
    intereses: number;

    /**
     * Define si el usaurio es de Nuevo Ingreso o de Reingreso
     */
    esNuevoIngreso:boolean;

    /**
     * Bandera que funciona para saber que fecha de vencimientos usar
     */
    diaTransaccion: boolean;
}