import { ErrorHandler, Injectable } from '@angular/core';
import { InsightsMonitoringServiceService } from './insights-monitoring-service.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private insightsMonitoringService: InsightsMonitoringServiceService) {
        super();
    }

    handleError(error: Error) {
        this.insightsMonitoringService.logException(error); // Manually log exception
    }
}
