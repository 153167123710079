import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog-component',
  templateUrl: './confirm-dialog-component.component.html',
  styleUrls: ['./confirm-dialog-component.component.scss']
})
export class ConfirmDialogComponentComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<string>) {
      this.mensaje = data;
      this.elementoUnico = this.mensaje.length <= 1;
    }

  public mensaje: Array<string>;
  public elementoUnico: boolean;
  ngOnInit(): void {
  }
  /**
   * Retorna una respuesta positiva al mensaje de confirmación
   */
  public descartar(): void {
    this.dialogRef.close(true);
  }
  /**
   * Retorna una respuesta negativa al mensaje de confirmación
   */
  public cancelar(): void {    
    this.dialogRef.close(false);
  }
}
