import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from '../../../../../models/api/ApiRespose.model';
import { CalificacionNoNumerica } from '../../../../../_models/calificacion-no-numerica';
import { CuestionarioArea, CuestionarioRespuestaDto, RecursoAlumnoArchivo } from '../../../../../_models/ensenanza-aprendizaje-profesor';
import { EnsenanzaAprendizajeService } from '../../../../../_services/ensenanza-aprendizaje.service';
import { EnsenanzaAprendizajeAlumnoService } from '../../../../../_services/ensenanzaAprendizajeAlumno.service';
import * as customBuild from '../../../../../../app/_librarries/ckeditorCustom/build/ckeditor';

@Component({
  selector: 'app-modal-consultar-examen-encuesta',
  templateUrl: './modal-consultar-examen-encuesta.component.html',
  styleUrls: ['./modal-consultar-examen-encuesta.component.scss']
})
export class ModalConsultarExamenEncuestaComponent implements OnInit {

  public Editor = customBuild;
  public calificacionForm: FormGroup;
  public escalaCal: CalificacionNoNumerica[] = [];
  public escalaMax: CalificacionNoNumerica;
  public escalaMin: CalificacionNoNumerica;
  public escalaCargada: boolean = false;
  public recursoRevisado: boolean = true;

  //VARIABLES TAREA
  public tareaArchivo: RecursoAlumnoArchivo;
  public tareaRecurso: boolean = false;
  public sinArchivosAdjuntos: boolean = false;
  public base64Tarea: string;

  //VARIABLE EXAMEN
  public examenRecurso: CuestionarioArea[] = [];
  public recursoTipoExamen: boolean = false;
  public formControlsAbierta: CuestionarioRespuestaDto[] = [];
  public sumaPonderacionOriginal: number;
  public sumaPonderacionPreguntas: number;
  public examenSinResponder: boolean = false;
  public disabledVC: boolean = false;
  public disabledPonderacion: boolean = true;

  public secciones: any[];
  public selectedIndex: number = 0;
  public calificacionFinal: string;

  constructor(public dialogResultadoRef: MatDialogRef<ModalConsultarExamenEncuestaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService) { }

  ngOnInit(): void {
    this.formularioCalificacion();
    this.identificarTipoRecurso();
    if (this.data.tipoRecursoId == 3) {
      this.EnsenanzaAprendizajeAlumnoService.GetExamenContestar(this.data.recursoId).subscribe((ex: ApiResponse<CuestionarioArea[]>) => {
        this.secciones = ex.data;

      });
    }
  }

  public formularioCalificacion() {
    this.calificacionForm = new FormGroup
      ({
      });
  }

  public identificarTipoRecurso() {
    if (this.data.tipoRecursoId === 2) {
      if (this.data.estatus === 'Sin Entregar') {
        this.sinArchivosAdjuntos = true;
      } else {
        this.ensenanzaAprendizajeService.obtenerAlumnoArchivo(this.data.recursoAlumno[0].alumnoId, this.data.recursoAlumno[0].recursoId).subscribe(
          (archivo: ApiResponse<RecursoAlumnoArchivo>) => {
            if (archivo.data !== null) {
              this.tareaArchivo = archivo.data;
              this.tareaRecurso = true;
            } else {

            }
          }
        );
      }
    }
    else if (this.data.tipoRecursoId === 3) {
      this.ensenanzaAprendizajeService.obtenerExamenEncuesta(this.data.recursoAlumno[0].recursoId).subscribe(
        (recursoExamen: ApiResponse<CuestionarioArea[]>) => {
          if (recursoExamen.data !== null) {
            this.examenRecurso = recursoExamen.data;
            console.log("this.examenRecurso",this.examenRecurso);
            if (this.data.estatus === 'Revisado') {
              this.recursoRevisado = false;
              this.recursoTipoExamen = true;
              this.crearFormulario();
            } else if (this.data.estatus === 'Por Revisar') {
              this.recursoTipoExamen = true;
              this.crearFormulario();
            } else if (this.data.estatus === 'Sin Entregar') {
              this.examenSinResponder = true;
            }
          } else {

          }
        }
      );
    }
    if (this.data.tipoRecursoId == 4) {
      this.EnsenanzaAprendizajeAlumnoService.GetEncuestaContestar(this.data.recursoId).subscribe((en: ApiResponse<CuestionarioArea[]>) => {
        this.secciones = en.data;
        this.crearFormularioEncuesta();
      })
    }
  }

  public crearFormularioEncuesta() {
    this.secciones.forEach(element => {
      element.cuestionarioPregunta.forEach(item => {
        var tipoPreguntaCuestionario = item.tipoPreguntaCuestionarioId;
        element.alumnoId = this.data.recursoAlumno[0].alumnoId;
        switch (tipoPreguntaCuestionario) {
          //Pregunta Abierta
          case 1:
            this.calificacionForm.addControl('abierta' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            break;

          //Seleccion Unica
          case 2:
            this.calificacionForm.addControl('unica' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            break;

          //Abierta Multiple
          case 3:
            item.cuestionarioRespuesta.forEach(val => {
              this.calificacionForm.addControl('multiple' + val.cuestionarioRespuestaId, new FormControl({ value: '', disabled: true }));
            })

            break;

          //Escala
          case 4:
            this.calificacionForm.addControl('escala' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            break;
        }
      });
    });


    this.AsignarRespuestasEncuestaEncuesta();

  }

  public AsignarRespuestasEncuestaEncuesta() {
    this.EnsenanzaAprendizajeAlumnoService.GetRespuestasEncuesta(this.data.recursoId, this.data.recursoAlumno[0].alumnoId).subscribe(
      (respuestas: ApiResponse<CuestionarioRespuestaDto[]>) => {
        respuestas.data.forEach(item => {
          switch (item.tipoPreguntaCuestionarioId) {

            //Pregunta Abierta
            case 1:
              this.secciones.forEach(element => {
                element.cuestionarioPregunta.forEach(itemSeccion => {
                  if (itemSeccion.cuestionarioPreguntaId == item.cuestionarioPreguntaId) {
                    itemSeccion.respPreguntaAbierta = this.stringToHtml(item.titulo);
                  }
                })
              });
              break;

            //Seleccion Unica
            case 2:
              this.calificacionForm.controls['unica' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              break;

            //Abierta Multiple
            case 3:
              this.calificacionForm.controls['multiple' + item.cuestionarioRespuestaId].setValue(true);
              break;

            //Escala
            case 4:
              this.calificacionForm.controls['escala' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              break;
          }
      });
    })
  }

  public stringToHtml(titulo: string) {

    let xmlString = titulo;
    let doc: Document = new DOMParser().parseFromString(xmlString, "text/html");
    return doc.body.innerHTML;
  }

  public crearFormulario() {
    this.examenRecurso.forEach(element => {
      element.cuestionarioPregunta.forEach(item => {
        var tipoPreguntaCuestionario = item.tipoPreguntaCuestionarioId;
        if (item.recursoAlumnoPregunta.length > 0 ) {
          switch (tipoPreguntaCuestionario) {
            //Pregunta Abierta
            case 1:
              this.calificacionForm.addControl('abierta' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
              this.calificacionForm.addControl('abiertaPonderacion' + item.cuestionarioPreguntaId, new FormControl({ value: '' }));
              break;
            //Seleccion Unica
            case 2:
              this.calificacionForm.addControl('unicaAlumno' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
              this.calificacionForm.addControl('unicaCorrecta' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
              this.calificacionForm.addControl('unicaPonderacion' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
              break;
            //Multiple
            case 3:
              item.cuestionarioRespuesta.forEach(val => {
                this.calificacionForm.addControl('multipleAlumno' + val.cuestionarioRespuestaId, new FormControl({ value: '', disabled: true }));
                this.calificacionForm.addControl('multipleCorrecta' + val.cuestionarioRespuestaId, new FormControl({ value: '', disabled: true }));
                this.calificacionForm.addControl('multiplePonderacion' + val.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
              });
              break;
            //Escala
            case 4:
              this.calificacionForm.addControl('escalaAlumno' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
              this.calificacionForm.addControl('escalaCorrecta' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
              this.calificacionForm.addControl('escalaPonderacion' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
              break;
          }
        }
      });
    });
    this.AsignarRespuestasEncuesta();
  }

  public respuestasAlumno: CuestionarioRespuestaDto[] = [];
  public AsignarRespuestasEncuesta() {
    this.ensenanzaAprendizajeService.obtenerRespuestasAlumnoExamen(this.data.recursoAlumno[0].recursoId, this.data.recursoAlumno[0].alumnoId).subscribe(
      (respuestas: ApiResponse<CuestionarioRespuestaDto[]>) => {

        this.respuestasAlumno = respuestas.data;
        this.disabledVC = false;
        let arregloConRepetidos = respuestas.data;

        const seen = new Set();
        let sinRepetidos = arregloConRepetidos.filter(el => {
          const duplicate = seen.has(el.cuestionarioPreguntaId);
          seen.add(el.cuestionarioPreguntaId);
          return !duplicate;
        });



        this.sumaPonderacionOriginal = respuestas.data[0].calificaion;//sinRepetidos.reduce((prev, curr) => prev + curr.ponderacion, 0);
        this.sumaPonderacionPreguntas = sinRepetidos.reduce((prev, curr) => prev + curr.ponderacionPregunta, 0);
        this.calificacionFinal = `${this.sumaPonderacionOriginal} (${ this.data.recursoAlumno[0]?.calificacionNoNumerica?.valorNumerico }/${this.sumaPonderacionPreguntas})`;
        this.formControlsAbierta = arregloConRepetidos.filter(x => x.tipoPreguntaCuestionarioId === 1);
        this.formControlsAbierta = this.formControlsAbierta.map(obj => ({
          ...obj,
          formControlNombre: 'abiertaPonderacion' + obj.cuestionarioPreguntaId
        }));
        respuestas.data.forEach(item => {
          switch (item.tipoPreguntaCuestionarioId) {
            case 1:
              this.calificacionForm.controls['abierta' + item.cuestionarioPreguntaId].setValue(item.titulo);
              this.calificacionForm.controls['abiertaPonderacion' + item.cuestionarioPreguntaId].setValue(0);
              break;
            case 2:
              this.calificacionForm.controls['unicaAlumno' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              this.calificacionForm.controls['unicaPonderacion' + item.cuestionarioPreguntaId].setValue(item.ponderacion);
              break;
            case 3:
              this.calificacionForm.controls['multipleAlumno' + item.cuestionarioRespuestaId].setValue(true);
              this.calificacionForm.controls['multiplePonderacion' + item.cuestionarioPreguntaId].setValue(item.ponderacion);
              break;
            case 4:
              this.calificacionForm.controls['escalaAlumno' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              this.calificacionForm.controls['escalaPonderacion' + item.cuestionarioPreguntaId].setValue(item.ponderacion);
              break;
          }
        });

        this.disabledVC = true;






        this.ensenanzaAprendizajeService.obtenerRespuestasCorrectaExamen(this.data.recursoAlumno[0].recursoId, this.data.recursoAlumno[0].alumnoId).subscribe(
          (respuestas: ApiResponse<CuestionarioRespuestaDto[]>) => {
            this.disabledVC = false;
            respuestas.data.forEach(item => {
              let Existe = false;

              this.respuestasAlumno.forEach(element => {
                if (item.cuestionarioPreguntaId == element.cuestionarioPreguntaId) {
                  Existe = true;

                } else {
                  Existe = false;

                }
              });
              if (Existe) {
                switch (item.tipoPreguntaCuestionarioId) {
                  case 2:
                    this.calificacionForm.controls['unicaCorrecta' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
                    break;
                  case 3:
                    this.calificacionForm.controls['multipleCorrecta' + item.cuestionarioRespuestaId].setValue(true);
                    break;
                  case 4:
                    this.calificacionForm.controls['escalaCorrecta' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
                    break;
                }
              }
            });
            this.disabledVC = true;
          });



      });

  }

  public cerrarModal(): void {
    this.dialogResultadoRef.close();
  }

  clickMe(value: number) {
    if (value <= this.secciones.length && value >= 0) {
      this.selectedIndex = value;
    }
  }

  public valorPuntos(form: string, Id: number): number {
    let formControlSeleccionado = `${form}${Id}`
    let res = this.calificacionForm.controls[formControlSeleccionado].value;
    return res;
  }

  public valorCorrecto(form: string, Id: number, ponderacionPregunta: number): boolean {
    let formControlSeleccionado = `${form}${Id}`
    let respuestaPonderacion = this.calificacionForm.controls[formControlSeleccionado].value;
    if (respuestaPonderacion === ponderacionPregunta) {
      return true;
    }
    if (ponderacionPregunta === 0) {
      return false;
    }
  }

}
