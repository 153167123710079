import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Ciclo } from '@app/_models/ciclo';
import { FiltrosBlockStudentDto } from '@app/_models/FiltrosBlockStudentDto';
import { Periodo } from '@app/_models/periodo';
import { CicloService } from '@app/_services/ciclo.service';
import { PeriodoService } from '@app/_services/periodo.service';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Programa, ProgramaActivo } from 'src/app/_models/programa';
import { BlockService } from 'src/app/_services/block.service';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';

@Component({
  selector: 'app-edicion-bloque-alumno-agregar',
  templateUrl: './edicion-bloque-alumno-agregar.component.html',
  styleUrls: ['./edicion-bloque-alumno-agregar.component.scss']
})
export class EdicionBloqueAlumnoAgregarComponent implements OnInit, OnDestroy {

  //#region  ViewChild
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allCiclo') private allCiclo: MatOption;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //#endregion

  //#region MatTable
  displayedColumns: string[] = ['select', 'id', 'nombre', 'periodo', 'programa', 'campus', 'ciclo'];
  listAlumnoData: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  //#endregion

  //#region  Propiedades
  public numAlumnoSeleccionado: number = 0;
  private arrOriginal: any[] = [];
  public datafilter: any;
  public arregloFiltroModificado: any[] = [];
  public arregloFiltroOriginal: any[] = [];

  public FiltrosBlockStudentDto : FiltrosBlockStudentDto;
  public nombreF = new FormControl();
  public periodoF = new FormControl();
  public programasF = new FormControl();
  public ciclosF = new FormControl();

  public Periodos: Periodo[] = [];
  public programas: ProgramaActivo[] = [];
  public ciclos: Ciclo[] = [];
  private periodosactivos: number[] = [];
  public infoCargado: boolean = false;
  //#endregion

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Constructor

  constructor(public dialogRef: MatDialogRef<EdicionBloqueAlumnoAgregarComponent>,
    private periodoService: PeriodoService,
    public programaservice: ProgramaAcademicoService,
    private _CicloService: CicloService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public blockService: BlockService,
  ) { }

  //#endregion

  //#region LifeCycle
  ngOnInit(): void {

  this.LlenarFiltros();
  }

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  //#region Methods
  public obtenerAlumnosAgregar() {

    this.Subscription.add(
      this.blockService.ObtenerAlumnosAnadir(this.FiltrosBlockStudentDto).subscribe(response => {

        let alumnos = response.data;
        this.arregloFiltroOriginal = alumnos;

        let result = this.data.alumnosId;

        if (result === null) {
          result = [];
        }

        let alumnosDisponibles = response.data.filter(function (objFromA) {
          return !result.find(function (objFromB) {
            return objFromA.studentId == objFromB
          })
        })

        this.arrOriginal = [...alumnosDisponibles];
        this.listAlumnoData = new MatTableDataSource(alumnosDisponibles);
        this.datafilter = this.arrOriginal;
        this.infoCargado = true;

        this.selection = new SelectionModel<any>(true, []);

        this.listAlumnoData.paginator = this.paginator;

      })
    );
  }
  public anadirAlumnosBloque(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }


    this.blockService.SetAlumnosABloque(datos);
    this.blockService.invocarMetodoAnadirBloqueAlumno();
    this.cerrarModal();

  }
  //#endregion

  //#region SelectionPeriodo
  toggleAllSelectionPeriodo() {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.filtro();
  }
  tosslePerOnePeriodo(all) {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      this.filtro();
      return false;
    }
    if (this.periodoF.value.length == this.Periodos.length) { this.allPeriodo.select(); }
    this.filtro();
  }
  //#endregion

  //#region SeleccionProgram
  toggleAllSelectionPrograma() {
    if (this.allPrograma.selected) {
      this.programasF.patchValue([...this.programas.map(item => item.programaId), 0]);
    } else {
      this.programasF.patchValue([]);
    }
    this.filtro();
  }


  tosslePerOnePrograma(all) {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.programasF.value.length === this.programas.length) { this.allPrograma.select(); }
    this.filtro();
  }
  //#endregion

  //#region SelectionCiclo

  toggleAllSelectionCiclo() {
    if (this.allCiclo.selected) {
      this.ciclosF.patchValue([...this.ciclos.map(item => item.cicloId), 0]);
    } else {
      this.ciclosF.patchValue([]);
    }
    this.filtro();
  }


  tosslePerOneCiclo(all) {
    if (this.allCiclo.selected) {
      this.allCiclo.deselect();
      return false;
    }
    if (this.ciclosF.value.length === this.ciclos.length) { this.allCiclo.select(); }
    this.filtro();
  }
  //#endregion

  //#region CheckBox
  isAllSelected() {
    this.numAlumnoSeleccionado = this.selection.selected.length;
    const numRows = this.listAlumnoData.data.length;
    return this.numAlumnoSeleccionado === numRows;
  }



  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.numAlumnoSeleccionado = this.selection.selected.length;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.student.alumnoId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.listAlumnoData.data.forEach(row => this.selection.select(row));
  }
  //#endregion

  //#region Filtros
    public LlenarFiltros() {

      this.Subscription.add(
        this.periodoService.obtenerPeriodos().subscribe(
          (periodos: Periodo[]) => {
            this.Periodos = periodos;
            this.periodoF.setValue([]);
          }
        ));

      this.Subscription.add(
        this.programaservice.obtenerProgramasActivos().subscribe(
          (programas: ApiResponse<ProgramaActivo[]>) => {
            this.programas = programas.data;
            this.programasF.setValue([]);

          }
        ));

      this.Subscription.add(
        this._CicloService.obtenerCiclos().subscribe(
          (ciclos: ApiResponse<Ciclo[]>) => {
            this.ciclos = ciclos.data;
            this.ciclosF.setValue([]);
          }
        ));

    }

    filtro() {

      let periodo: any[] = this.periodoF.value == null ? [] : this.periodoF.value;
      let programa: any[] = this.programasF.value == null ? [] : this.programasF.value;
      let ciclos: any[] = this.ciclosF.value == null ? [] : this.ciclosF.value;

      this.FiltrosBlockStudentDto = new FiltrosBlockStudentDto();
      this.FiltrosBlockStudentDto.campusId = this.data.campusId;
      this.FiltrosBlockStudentDto.periodoBlockId = this.data.periodoId;

      this.FiltrosBlockStudentDto.periodoId = [];
      this.FiltrosBlockStudentDto.periodoId =periodo;

      this.FiltrosBlockStudentDto.programaId = [];
      this.FiltrosBlockStudentDto.programaId = programa;

      this.FiltrosBlockStudentDto.ciclocId = [];
      this.FiltrosBlockStudentDto.ciclocId = ciclos;

      this.FiltrosBlockStudentDto.nombre = this.Nombre;

      this.obtenerAlumnosAgregar();
    }
    public Nombre: string;
    public buscarNombreOIdentificador(filterValue: string): void {
      filterValue = filterValue.toLowerCase();

       /*  this.FiltrosBlockStudentDto = new FiltrosBlockStudentDto();
        this.FiltrosBlockStudentDto.nombre =filterValue; */
        this.Nombre = filterValue;
       /*  this.FiltrosBlockStudentDto.campusId = this.data.campusId;
        this.FiltrosBlockStudentDto.periodoBlockId = this.data.periodoId;

        console.log("this.FiltrosBlockStudentDto 2",this.FiltrosBlockStudentDto); */
        this.filtro();


        /* this.listAlumnoData.filter = filterValue;
        this.listAlumnoData.filterPredicate = function (data, filter: string): boolean {
          var nombre = data.nombreCompleto;
          return nombre.toLowerCase().includes(filter) || data.student.usuario.identificador.toString().includes(filter);
        };
        this.listAlumnoData.paginator = this.paginator; */

    }

    limpiarFiltros() {
      this.listAlumnoData.paginator = this.paginator;
      this.periodoF.setValue([...this.periodosactivos]);
      this.programasF.setValue([...this.programas]);
      this.ciclosF.setValue([...this.ciclos]);
      this.nombreF.setValue("");
      this.filtro();
    }
  //#endregion

  //#region Modal
  public cerrarModal() {
    this.dialogRef.close();
  }
  //#endregion

}
