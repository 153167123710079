import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Genero } from '../_models/genero';

@Injectable({
  providedIn: 'root'
})
export class GeneroService {

  constructor(private httpClient: HttpClient) { }

  //Get Catalogo Genero
  public obtenerGenero(): Observable<Genero[]> {
    return this.httpClient.get<Genero[]>(`${environment.enves[baseUrl].apiUrl}/Genero`);
  }
}
