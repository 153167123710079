/**
 * Clones any object by value.
 *
 * @remarks Add any needed not supported type in this function implementation.
 *
 * @param obj The object to be copied
 *
 * @returns The copied object
 *
 */

 export const clone = (obj: any) => {
    let copy;
  
    // Add here any not handled type the project needs
  
    // Handle the 3 simple types, and null or undefined
    if (null === obj || 'object' !== typeof obj) return obj;
  
    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
  
    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = clone(obj[i]);
      }
      return copy;
    }
  
    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
      }
      return copy;
    }
  
    throw new Error('Object type not supported.');
  };
  