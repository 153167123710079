import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalTipoDePagoComponent } from '../_components/modal-tipo-de-pago/modal-tipo-de-pago.component';

@Injectable({
  providedIn: 'root'
})
export class TipoDePagoService {

  constructor(private dialog: MatDialog) { }
  /**
   * Retorna la respuesta que el usuario da al mensaje 
   * de qué tipo de Pago desea realizar.
   * En caso de ser pagos en Parcialidades retorna un true.
   * En caso de ser pago en una exhibición retorna un false.
   * @param aceptaMensualidades 
   * @returns 
   */
  public mostrarModalTipoDePago(aceptaMensualidades: boolean) {
    return this.dialog.open(ModalTipoDePagoComponent, {
      data: aceptaMensualidades,
      disableClose: true,
    });
  }
}
