import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { ApoyoFinancieroDocumentoComponent } from 'src/app/_components/apoyo-financiero-documento/apoyo-financiero-documento.component';
import { Empresa } from 'src/app/_models/empresa';
import { TipoApoyoFinanciero } from 'src/app/_models/tipo-apoyo-financiero';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { EmpresaService } from 'src/app/_services/empresa.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { SolicitudApoyoFinancieroService } from 'src/app/_services/solicitud-apoyo-financiero.service';
import { TipoApoyoFinancieroService } from 'src/app/_services/tipo-apoyo-financiero.service';
import { TraductorService } from '../../../../_services/traductor.service';

@Component({
  selector: 'app-apoyo-financiero-admision',
  templateUrl: './apoyo-financiero-admision.component.html',
  styleUrls: ['./apoyo-financiero-admision.component.scss']
})
export class ApoyoFinancieroAdmisionComponent implements OnInit, OnDestroy {

  //Variable renderizar componente
  public apoyoFinancieroRenderizar: boolean = false;
  //Variables formulario
  public apoyoFinancieroForm: FormGroup;
  public situacionEconomicaForm: FormGroup;
  //Variables Obs
  public infoUsuario$: Observable<any>;
  public infoUsuario: any = null;
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variables catalogos
  public apoyoList: TipoApoyoFinanciero[] = [];
  public empresaList: Empresa[] = [];
  //Variable Informacion apoyo
  public infoApoyo: any = null;
  //Variable componente documentos
  @ViewChild(ApoyoFinancieroDocumentoComponent) cargaDocumentos: ApoyoFinancieroDocumentoComponent;
  //Varible guardar apoyo
  public guardarApoyo: boolean = false;
  public guardarSituacionEconomica: boolean = false;

  constructor(
    private admisionesService: AdmisionesService,
    private apoyoService: TipoApoyoFinancieroService,
    private empresaService: EmpresaService,
    public nuevoIngresoService: NuevoIngresoService,
    private snackService: SnackService,
    private solicitudApoyoFinancieroService: SolicitudApoyoFinancieroService,
    private traductorService: TraductorService
  ) { }

  ngOnInit(): void {

  }

  public renderizarApoyoFianciero(): void {
    this.infoUsuario$ = this.admisionesService.GetInfoUsuario$();
    this.subscription.add(
      this.infoUsuario$.subscribe(
        (info: any) => {
          this.infoUsuario = info;
          this.apoyoFinancieroRenderizar = true;
          this.getTipoApoyo();
          this.getTipoEmpresa();
          this.obtenerApoyoFinanciero();
        })
    );
  }

  public obtenerApoyoFinanciero(): void {
    this.subscription.add(
      this.admisionesService.obtenerApoyoFinancieroAdmision(this.infoUsuario.usuarioId)
        .subscribe((apoyo: ApiResponse<any>) => {
          if (apoyo.success) {
            this.infoApoyo = apoyo.data;
            this.inicializarForm();
            setTimeout(() => {
              this.cargaDocumentos.renderizarFormulario(this.infoUsuario.usuarioId, this.infoApoyo.listaSolicitudApoyo.length === 0 ? null : this.infoApoyo.listaSolicitudApoyo[0].solicitudApoyoFinancieroId, this.infoUsuario.correo);
            }, 1000);
          } else {
            this.snackService.mostrarSnackBack(apoyo.message);
          }
        })
    );
  }

  public inicializarForm(): void {
    this.apoyoFinancieroForm = new FormGroup({
      usuarioId: new FormControl(this.infoUsuario.usuarioId),
      campusId: new FormControl(this.infoUsuario.alumnoRegistrado[0].campusId),
      priodoIngreso: new FormControl(this.infoUsuario.alumnoRegistrado[0].priodoIngreso),
      programaId: new FormControl(this.infoUsuario.alumnoRegistrado[0].programaId),
      tipoApoyoFinanciero: new FormControl({value: this.infoApoyo.listaSolicitudApoyo.length === 0 ? 0 : this.infoApoyo.listaSolicitudApoyo[0].tipoApoyoFinancieroId, disabled: (this.infoApoyo.listaSolicitudApoyo[0]?.estatusSolicitudApoyoId === 2) }, [Validators.required, this.validateId]),
      estatus: new FormControl({ value: this.infoApoyo.listaSolicitudApoyo[0]?.estatusSolicitudApoyoNombre, disabled:true }),
      empresa: new FormControl({ value: this.infoApoyo.listaSolicitudApoyo.length === 0 ? 0 : this.infoApoyo.listaSolicitudApoyo[0].empresaId, disabled: !this.infoApoyo.listaSolicitudApoyo[0]?.convenio }, [Validators.required, this.validateId]),
      promedio: new FormControl({ value: this.infoApoyo.listaSolicitudApoyo.length === 0 ? '' : this.infoApoyo.listaSolicitudApoyo[0].promedio, disabled: (this.infoApoyo.listaSolicitudApoyo[0]?.estatusSolicitudApoyoId === 2)}, [Validators.required, this.validateId]),
    });
    this.situacionEconomicaForm = new FormGroup({
      usuarioId: new FormControl(this.infoUsuario.usuarioId),
      numeroDependientesEconomicos: new FormControl(this.infoApoyo.situacionEconomica.numeroDependientesEconomicos === 0 ? 0 : this.infoApoyo.situacionEconomica.numeroDependientesEconomicos, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      numeroAutosPropios: new FormControl(this.infoApoyo.situacionEconomica.numeroAutosPropios === 0 ? 0 : this.infoApoyo.situacionEconomica.numeroAutosPropios, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      ingresoFamiliarTotal: new FormControl(this.infoApoyo.situacionEconomica.ingresoFamiliarTotal === 0 ? 0 : this.infoApoyo.situacionEconomica.ingresoFamiliarTotal, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      gastoMensualServicios: new FormControl(this.infoApoyo.situacionEconomica.gastoMensualServicios === 0 ? 0 : this.infoApoyo.situacionEconomica.gastoMensualServicios, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      gastoMensualVivienda: new FormControl(this.infoApoyo.situacionEconomica.gastoMensualVivienda === 0 ? 0 : this.infoApoyo.situacionEconomica.gastoMensualVivienda, [Validators.required, Validators.pattern(/^[0-9]+/)]),
    });
    this.apoyoFinancieroForm.valueChanges.subscribe((_) => {
      this.guardarApoyo = true;
    });
    this.situacionEconomicaForm.valueChanges.subscribe((_) => {
      this.guardarSituacionEconomica = true;
    });
    this.apoyoFinancieroForm.controls.tipoApoyoFinanciero.valueChanges.subscribe((s) => {
      let apoyofinanciero = this.apoyoList.find(f => f.tipoApoyoFinancieroId === s);
      if (!apoyofinanciero.convenio) {
        // this.apoyoFinancieroForm.controls.empresa.disable();
        // this.apoyoFinancieroForm.controls.empresa.setValue(0);
      } else {
        // this.apoyoFinancieroForm.controls.empresa.enable();
        // this.apoyoFinancieroForm.controls.empresa.setValue(0);
      }
    });
  }

  get numeroDependientesEconomicos() { return this.situacionEconomicaForm.get('numeroDependientesEconomicos'); }
  get numeroAutosPropios() { return this.situacionEconomicaForm.get('numeroAutosPropios'); }
  get ingresoFamiliarTotal() { return this.situacionEconomicaForm.get('ingresoFamiliarTotal'); }
  get gastoMensualServicios() { return this.situacionEconomicaForm.get('gastoMensualServicios'); }
  get gastoMensualVivienda() { return this.situacionEconomicaForm.get('gastoMensualVivienda'); }
  get tipoApoyoFinanciero() { return this.apoyoFinancieroForm.get('tipoApoyoFinanciero'); }
  get estatus() { return this.apoyoFinancieroForm.get('estatus'); }
  get empresa() { return this.apoyoFinancieroForm.get('empresa'); }
  get promedio() { return this.apoyoFinancieroForm.get('promedio'); }

  private validateId(control: AbstractControl): any {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.apoyoFinancieroForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  public getTipoApoyo(): void {
    this.subscription.add(
      this.apoyoService.obtenerTipoApoyoFinanciero().subscribe(
        (apoyo: TipoApoyoFinanciero[]) => {
          this.apoyoList = apoyo.filter(apoyo => apoyo.campusId == this.infoUsuario.alumnoRegistrado[0].campusId);
        })
    );
  }

  public getTipoEmpresa(): void {
    this.subscription.add(
      this.empresaService.obtenerEmpresa().subscribe(
        (empresa: Empresa[]) => {
          this.empresaList = empresa;
        })
    );
  }

  /**
   * Metodo que se ejecuta al cambiar del tab
   * @param event
   */
  public onChangeTab(event?: MatTabChangeEvent): void {
    if (this.guardarApoyo) {
      if (this.apoyoFinancieroForm.valid) {
        let apoyoFinancieroForm = Object.assign(this.apoyoFinancieroForm.value);
        let solicitudApoyoFinanciero: any = {
          usuarioId: apoyoFinancieroForm.usuarioId,
          campusId: apoyoFinancieroForm.campusId,
          periodoId: apoyoFinancieroForm.priodoIngreso,
          programaAcademicoId: apoyoFinancieroForm.programaId,
          promedio: apoyoFinancieroForm.promedio,
          tipoApoyoFinancieroId: apoyoFinancieroForm.tipoApoyoFinanciero,
          empresaId: apoyoFinancieroForm.empresa,
        }
        this.nuevoIngresoService.registrarApoyoFinanciero(solicitudApoyoFinanciero).subscribe(
          (respuesta: SimpleResponse) => {
            if (respuesta.success) {
              let mensaje: string = this.traductorService.translate('apoyoGuardado');
              this.snackService.mostrarSnackBack(mensaje);
              this.cargaDocumentos.obtenerSolicictudId(solicitudApoyoFinanciero.usuarioId);
              this.guardarApoyo = false;
            } else {
              this.snackService.mostrarSnackBack(respuesta.message);
              this.guardarApoyo = false;
            }
          }
        )
      }
    }
    if (this.guardarSituacionEconomica) {
      if (this.apoyoFinancieroForm.valid) {
        let situacionEconomicaForm = Object.assign(this.situacionEconomicaForm.value);
        this.solicitudApoyoFinancieroService.registrarSituacionEconomica(situacionEconomicaForm).subscribe(
          (situacionEconomica: SimpleResponse) => {
            if (situacionEconomica.success) {
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
              this.guardarSituacionEconomica = false;
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
              this.guardarSituacionEconomica = false;
            }
          }
        );
      }
    }
  }

  /**
   * Metodo para guardar la situacion economica
   */
  public guardarSituacionEconomicaUsuario() {
    let situacionEconomicaForm = Object.assign(this.situacionEconomicaForm.value);
    this.solicitudApoyoFinancieroService.registrarSituacionEconomica(situacionEconomicaForm).subscribe(
      (situacionEconomica: SimpleResponse) => {
        if (situacionEconomica.success) {
          let mensaje: string = this.traductorService.translate('guardadoCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
          this.guardarSituacionEconomica = false;
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
          this.guardarSituacionEconomica = false;
        }
      }
    );
  }

  /**
   * Metodo que se activa cuando se cierra el componente
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
