import { SnackService } from 'src/app/services/snack-service.service';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { MakeUpExamReasonService } from './../../../../infrastructure/services/make-up-exam-reason/make-up-exam-reason.service';
import { DataTableColumn } from './../../../../core.domain/models/data-table/data-table-column.models';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { TraductorService } from '@app/_services/traductor.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataTable } from '../../../../core.domain/models/data-table/data-table.models';
import { AlumnoGrupoMakeupExam } from '../../../../core.domain/models/make-up-exam/alumno-grupo-make-up-exam';

@Component({
  selector: 'app-mass-assignment-make-up-exam',
  templateUrl: './mass-assignment-make-up-exam.component.html',
  styleUrls: ['./mass-assignment-make-up-exam.component.scss']
})
export class MassAssignmentMakeUpExamComponent implements OnInit {

  //#region Table
  public AlumnoGrupoMakeupExam  : AlumnoGrupoMakeupExam[] = [];
  public dataTable: DataTable<any>;
  readonly COLUMN_TABLE: DataTableColumn[] = [
    { name: 'Identificador'       , prop: 'identificador'         ,flex: 10, },
    { name: 'Nombre'              , prop: 'nombreCompleto'        ,flex: 10, customColumClass: 'text-left'   },
    { name: 'Periodo'             , prop: 'periodoClave'          ,flex: 10, },    
    { name: 'Grupo'               , prop: 'grupoId'               ,flex: 10, },
    { name: 'Clave'               , prop: 'materiaClave'          ,flex: 10, },
    { name: 'Materia'             , prop: 'materiaNombre'         ,flex: 10, customColumClass: 'text-left'   },        
  ];
  //#endregion
  //#endregion
  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  constructor(public dialogRef: MatDialogRef<MassAssignmentMakeUpExamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackService: SnackService,
    private _traductorService: TraductorService,
    private _MakeUpExamReasonService  : MakeUpExamReasonService) { }

  ngOnInit(): void {    
    this.dataTable = {
      options: {
        hasCheckbox: false,
      },
      data: [...this.data.data]
    };
  }
  
  //#region Modal Close
  public CerrarModal() {
    this.dialogRef.close();
  }
  //#endregion

  public postAlumnoGrupoMakeupExam():void {
    this.data.data.forEach(element => {
      const data = new AlumnoGrupoMakeupExam(
        element.alumnoId,
        element.grupoId,
        this.data.makeUpExamReasonId
      );
      this.AlumnoGrupoMakeupExam.push(data);
    });
    this._MakeUpExamReasonService.postMassiveAlumnoGrupoMakeupExam(this.AlumnoGrupoMakeupExam).subscribe(
      (response: SimpleResponse) => {
        if (response.success) {
          let mensaje: string = this._traductorService.translate('guardadoCorrecto');
          this._snackService.mostrarSnackBack(mensaje);
        }else{
          let mensaje: string = this._traductorService.translate('errorGuardar');
          this._snackService.mostrarSnackBack(mensaje);
        }
    });    
  }
}
