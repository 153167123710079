export class CouponDto {
    couponId?: number;
    code: number;
    name: string;
    description: string;
    startDate?: Date;
    endDate?: Date;
    totalUses?: number;
    usesPerPerson?: number;
    couponTypeId: number;
    value: number;
    cuponTotalUses?: number;
    userCouponTotalUses?: number;
    lastUseDate?: Date;
    status: boolean;
    tipoAccion?: number;
}