import { I18NextPipe } from 'angular-i18next';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { ContenidoParcial, InfoPorRevisarDto, InformacionGrupoAsistencia } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { TipoRecurso } from 'src/app/_models/tipo-recurso';
import { EnsenanzaAprendizajeService } from '../../../_services/ensenanza-aprendizaje.service';
import { UtileriasService } from '../../../_services/utilerias.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-contenido-por-revisar',
  templateUrl: './contenido-por-revisar.component.html',
  styleUrls: ['./contenido-por-revisar.component.scss']
})
export class ContenidoPorRevisarComponent implements OnInit {

  @Input() grupoID: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['parcial', 'tipoRecurso', 'titulo', 'puntuacion', 'fechaLimite', 'revisados', 'porRevisar', 'sinEntregar'];
  dataSource: MatTableDataSource<InfoPorRevisarDto>;
  selection = new SelectionModel<InfoPorRevisarDto>(true, []);

  public filtroForm: FormGroup;
  public listaParciales: any;
  public listaTipoRecurso: TipoRecurso[] = [];
  public datafilter: InfoPorRevisarDto[] = [];
  public informacionGrupo: InformacionGrupoAsistencia;
  public informacionRecursos: InfoPorRevisarDto[] = [];
  public infoCargada = false;
  public contenidoPorRevisar = true;
  public recursoSelccionado: any;
  public ultimoParcial: number = 0;
  existeParcialFinal: boolean = false;
  contenido: ContenidoParcial[] = [];
  contenidoGrupoParcial: any[] = [];

  constructor(private paginador: MatPaginatorIntl,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    public utileriaService: UtileriasService,
    private i18nextPipe: I18NextPipe,
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    this.inicializarForm();
    this.obtenerInformacionRecursos(this.grupoID);
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '1 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };

    if (this.ensenanzaAprendizajeService.invocarObtenerPorRevisarSubscription == undefined) {
      this.ensenanzaAprendizajeService.invocarObtenerPorRevisarSubscription = this.ensenanzaAprendizajeService.invocarObtenerPorRevisar.subscribe(() => {
        this.informacionRecursos.length = 0;
        this.obtenerInformacionRecursos(this.grupoID);
      });
      this.ensenanzaAprendizajeService.invocarObtenerPorRevisarSubscription = null;
    }
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      parcial: new FormControl(0),
      tipoRecurso: new FormControl(0)
    });
  }

  public obtenerInformacionRecursos(grupoId: number): void {
    this.ensenanzaAprendizajeService.obtenerInformacionRecursos(grupoId).subscribe(
      (infoRecursos: ApiResponse<InfoPorRevisarDto[]>) => {
        if (infoRecursos.data !== null) {
          this.informacionRecursos = infoRecursos.data;
          this.dataSource = new MatTableDataSource(infoRecursos.data);
          this.dataSource.paginator = this.paginator;
          this.selection = new SelectionModel<InfoPorRevisarDto>(true, []);
          this.datafilter = infoRecursos.data;
          this.obtenerInfoGrupo(grupoId);
        } else {

        }
      });
  }

  public obtenerInfoGrupo(grupoId: number): void {
    this.ensenanzaAprendizajeService.obtenerInfoGrupoAsistencia(grupoId).subscribe(
      (info: InformacionGrupoAsistencia) => {
        if (info !== null) {
          this.informacionGrupo = info;
          this.cargarContenido();
        } else {

        }
      });
  }
  //#region  MultiLenguaje

  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  //#endregion
  //#region  Cargar contenidos
  public cargarContenido() {
    this.ensenanzaAprendizajeService.obtenerContenido(this.grupoID).subscribe((res: ApiResponse<any>) => {
      if (res.data) {
        this.contenidoGrupoParcial = res.data.grupoParcial;
        this.cargarParciales(res.data.clave);
      }
    })
  }
  //#endregion
  public cargarParciales(clave: string) {
    this.contenido.length = 0;
    this.ensenanzaAprendizajeService.obtenerParcialesProfesor(clave, this.grupoID).subscribe(parciales => {
      if (parciales.exito) {
        if (parciales.dataParcialFinal) {
          this.existeParcialFinal = true;
        }

        parciales.dataParciales.forEach(parcial => {

          let parcialText = '_tableroProfesor.parcial';
          if (!!this.existeParcialFinal && parcial == parciales.dataParciales.length) {
            parcialText = '_tableroProfesor.parcial-final';
          };

          if (this.contenidoGrupoParcial.length > 0) {
            let nuevoParcial = this.contenidoGrupoParcial.find(object => {
              return object.parcial == parcial
            });
            if (nuevoParcial) {
              let parcialobj = {
                parcial: nuevoParcial.parcial,
                ponderacion: nuevoParcial.ponderacion,
                nombre: nuevoParcial.nombre,
              }
              if (!parcialobj?.nombre) {
                parcialobj.nombre = this.translate(parcialText)// + ' ' + parcial;
              }
              this.contenido.push(parcialobj);
            } else {
              let _parcialObj = {
                parcial: parcial,
                ponderacion: 0,
                nombre: this.translate(parcialText)// + ' ' + parcial
              }
              this.contenido.push(_parcialObj);
            }
          }
          else {
            let _parcialObj = {
              parcial: parcial,
              ponderacion: 0,
              nombre: this.translate(parcialText) + ' ' + parcial
            }
            this.contenido.push(_parcialObj);
          }
        });

        /* this.listaParciales = parciales.dataParciales;
        this.listaParciales = this.listaParciales.map(obj =>
          ({
            nombre: 'Parcial ' + obj,
            idPacial: obj
          })
        );
        if(parciales.dataParcialFinal){
          this.existeParcialFinal =true;
          this.ultimoParcial = this.listaParciales[this.listaParciales.length -1].idPacial;
        }else{
          this.existeParcialFinal =false;
        }
        */
        this.obtenerTipoRecurso();
      }
    });
  }

  public obtenerTipoRecurso() {
    this.ensenanzaAprendizajeService.listaRecursos().subscribe((tipoRecurso: ApiResponse<TipoRecurso[]>) => {
      let tipoRecursos: TipoRecurso[] = tipoRecurso.data.filter(x => x.nombre !== 'Contenido');
      this.listaTipoRecurso = tipoRecursos;
      this.infoCargada = true;
      const opcionIdParam: string = this.activatedRoute.snapshot.paramMap.get('opcionId');
      if (opcionIdParam === "porrevisar") {
        const recursoIdParam: string = this.activatedRoute.snapshot.paramMap.get('recursoId');
        if (recursoIdParam !== null) {
          const recursoId: number = +recursoIdParam;
          let elementoSeleccionado = this.dataSource.data.find(x => x.recursoId == recursoId);
          this.abrirDetalleRecurso(elementoSeleccionado);
        }
      }
    });
  }

  public filtrar() {
    var parcial = this.filtroForm['value'].parcial;
    var tipoRecurso = this.filtroForm['value'].tipoRecurso;
    let filtroModificado: any = [];
    filtroModificado = this.informacionRecursos;
    if (parcial != 0) {
      filtroModificado = filtroModificado.filter(row => row.parcial === parcial);
    }
    if (tipoRecurso != 0) {
      filtroModificado = filtroModificado.filter(row => row.tipoRecursoId === tipoRecurso);
    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
  }

  public limpiar() {
    this.inicializarForm();
    this.dataSource = new MatTableDataSource(this.datafilter);
    this.dataSource.paginator = this.paginator;
  }

  public abrirDetalleRecurso(elemento: any) {
    this.contenidoPorRevisar = false;
    this.recursoSelccionado = elemento;
  }

  public abrirRecursos(grupoId: number) {
    this.contenidoPorRevisar = true;
    this.obtenerInformacionRecursos(grupoId);
  }
}
