export class AlumnoGrupoParcial{
    /**
     * Numero del parcial 
     */
    parcial: number;

    /**
     * Calificacion obtenida en ese parcial
     */
    calificacion: number;
}