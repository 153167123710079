import { Alumno } from 'src/app/_models';
export class Usuario {
  /**
   * Identificador unico del usuario
   */
  usuarioID: number;
  /**
   * Identificador unico del usuario
   */
  usuarioId?: number;

  /**
   * Identificador 
   */
  identificador: string;

  /**
   * Correo del usuario
   */
  Correo?: string;
  /**
   * Correo del usuario
   */
  correo?: string;

  /**
   * Nombre(s) del usuario
   */
  nombre: string;

  /**
   * Segundo nombre del usuario
   */
  segundoNombre: string;
  /**
   * Apellido paterno del usuario
   */
  apellidoPaterno: string;

  /**
   * Apellido materno del usuario
   */
  apellidoMaterno: string;

  /**
   * Fecha de nacimiento del usuario
   */
  fechaNacimiento: Date;

  /**
   * Estatus de la cuenta
   */
  estatus: boolean;

  /**
   * Perfil que tiene el usuario: alumno, profesor, etc.
   */
  perfil: string;

  /**
 * Perfil que tiene el usuario: alumno, profesor, etc.
 */
  alumno?: Alumno[];

  fotografia?: string | ArrayBuffer;

  /**
   * Roles que puede tener un usuario
   */
  Roles?: string[];

  /**
   * Oficinas o lugares donde se encuentra el usuario
   */
  Oficinas?: string[];

  /**
   * Define si es Administrador
   */
  IsAdmin?: boolean;

  /**
   * Define si es Profesor
   */
  IsProfesor?: boolean;

  /**
   * Define si es Alumno
   */
  IsAlumno?: boolean;

  /**
   * Clave de acceso que valida la pertenencia a la institucion
   */
  Token?: string;

  /**
   * Tiempo restante para ver la informacion
   */
  Expiracion?: number;

  /**
   * Define la accion que tendra el registro
   */
  tipoAccion?: number;

  /**
   * Identificador de la institucion a la que pertenece el alumno
   */
  institucionId?: string;
}

export class Imagen {
  /**
   * Identificador del usuario donde se guardara la imagen 
   */
  usuarioId: number;

  /**
   * Nombre de la fotografia (original)
   */
  fotografia: string;

  /**
   * Archivo de la fotograifa
   */
  fotografiaFile: any;
}

export class Registros {
  /**
   * Identificador del usuario a guardar la informacion
   */
  usuarioId: number;

  /**
   * Correo del usuario
   */
  correo: string;

  /**
   * Periodo al que pertenecera el usuario 
   */
  periodo: {
    periodoId: number;
  }

  /**
   * Campus al que pertenece el usuario
   */
  campus: {
    CampusId: number;
  }

  /**
   * Programa al que esta inscrito el alumno
   */
  programa: {
    ProgramaId: number;
  }
}

export class RegistroUsuario {
  /**
   * Correo del usuario
   */
  correo: string;

  /**
   * Nombre del usuario
   */
  nombre: string;
  /**
   *Segundo nombre del usuario
   */
  segundoNombre: string;

  /**
   * Apellido paterno del usuario
   */
  apellidoPaterno: string;

  /**
   * Apellido materno del usuario
   */
  apellidoMaterno?: string;

  /**
   * Constrase�a a guardar del usuario
   */
  password: any;

  /**
   * Fecha de nacimiento del usuario que se guardara en biografia
   */
  biografia: {
    fechaNacimiento: Date;
  }

  /**
   * Periodo actual en el que esta inscrito el usuario
   */
  periodo: {
    periodoId: number;
  }

  /**
   * Campus al que pertenece el usuario
   */
  campus: {
    CampusId: number;
  }

  /**
   * Programa al que esta inscrito el usuario
   */
  programa: {
    ProgramaId: any
  }
}

export class ProgramaAcademicoF {
  /**
   * Identificador del usuario
   */
  usuarioId: number;

  /**
   * Define el estatus academico del usuario
   */
  estatusAcademicoId: number;

  /**
   * Identificador personalizado
   */
  identificadorPersonalizadoValor?: string;

  /**
   * Booleano para saber si el identificador 
   * personaliza esta activado por la institucion
   */
  identificadorPersonalizadoBool?: boolean;

  alumnoId?: number;

  /**
   * Campus al que pertenece el usuario
   */
  campus: {
    CampusId: number;
  }

  /**
   * Programa al que esta inscrito el usuario 
   */
  programa: {
    ProgramaId: number;
  }

  /**
   * Periodo al que pertenece el programa
   */
  periodo: {
    periodoId: number;
  }

  formatoProgramaId: number;
  idiomaProgramaId: number;
  tipoCandidatoId: number;
}

export class DatosPersonales {
  /**
   * Identificador del usuario
   */
  usuarioId: number;

  /**
   * Nombre del usuario
   */
  nombre: string;
  /**
   * Segundo nombre del usuario
   */
  segundoNombre: string;

  /**
   * Apellido paterno del usuario
   */
  apellidoPaterno: string;

  /**
   * Apellido materno del usuario
   */
  apellidoMaterno: string;

  /**
   * Correo perteneciente al usuario
   */
  correo: string;

  /**
   * Fecha de nacimiento del usuario que se guardara en biografia
   */
  biografia: {
    fechaNacimiento: Date;
  }
}

export class DatosContactoF {
  /**
   * Correo del usuario
   */
  correo: string;

  /**
   * Identificador del usuario
   */
  usuarioId: number;

  /**
   * Datos a guardar en Direccion
   */
  direccion: [
    {
      /**
       * Identificador del tipo de direccion
       */
      tipoDireccionId: number;

      /**
       * Identificador del pais donde se encuentra el domicilio 
       */
      paisId?: number;

      /**
       * Estado donde se encuentra el domicilio
       */
      entidadFederativaId: number;

      /**
       * Calle del domicilio
       */
      calle: string;

      /**
       * Ciudad donde se encuentra el domicilio
       */
      ciudad: string;

      /**
       * Numero exterior del domicilio
       */
      numeroExterior: string;

      /**
       * Numero interior del domicilio
       */
      numeroInterior: string;

      /**
       * Colonia donde se encuentra el domicilio
       */
      colonia: string;

      /**
       * Codigo postal 
       */
      codigoPostal: string;
    }
  ]
  /**
   * Datos a guardar en Telefono
   */
  telefono: Telefono[];
}

export class Telefono {
  /**
 * Identificador del tipo de telefono
 */
  tipoTelefonoId: number;

  /**
   * Pais donde se encuentra el telefono
   */
  paisId: number;

  /**
   * Numero telefonico
   */
  numero: string;

  /**
   * Extension del numero telefonico
   */
  extension: string
}

export class DatosBiografia {
  /**
   * Identificador del usuario donde se guardaran los datos
   */
  UsuarioId: number;

  /**
   * Genero legal del usuario
   */
  LegalGeneroId: number;

  /**
   * Genero con el que se identifica el usuario
   */
  GeneroId: number;

  /**
   * Ciudadania del usuario
   */
  CiudadaniaPaisId: number;

  /**
   * Estado civil del usuario
   */
  EstadoCivilId: number;

  /**
   * Clave unica de registro poblacional
   */
  Curp: string;

  /**
   * Estado de nacimiento del usuario
   */
  EntidadNacimiento: number;

  /**
   * Ciudad de nacimiento del usuario
   */
  CiudadNacimiento: string;

  /**
   * Alergias con las que cuenta el usaurio
   */
  Alergias: string

  /**
   * Grupo sanguineo que tiene el usuario
   */
  GrupoSanguineoId: number;

  /**
   * Enfermedades que tiene el usuario
   */
  Enfermedades?: string;

  razaId?: number;
  estatusMigratorioId?: number;
  tipoDocumentoId?: number;
  paisDocumentoId?: number;
  hasCriminalRecord?: boolean;
  hasAcademicDisciplinaryRecord?: boolean;
}

export class DatosFamilia {
  /**
   * Identificador del usuario donde se guarda el registro
   */
  UsuarioId: number;

  /**
   * Tipo de relacion que tiene con la persona
   */
  TipoRelacionId: number;

  /**
   * Nombre del familiar 
   */
  NombreCompleto: string

  /**
   * Telefono del familiar
   */
  Telefono: number;

  /**
   * Correo de la persona relacionada
   */
  CorreoElectronico: string;
}

export class DatosFiscal {
  /**
   * Identificador del usuario donde se guarda la informacion
   */
  UsuarioId: number;

  /**
   * Registro federal de contribuyentes
   */
  Rfc: string;

  /**
   * Razon social a la que pertene la facturacion
   */
  RazonSocial: string;

  /**
   * Estado de la direccion donde se factura
   */
  EntidadFederativaId: number;

  /**
   * Calle de la direccion donde se factura
   */
  Calle: string;

  /**
   * Ciudad de facturacion
   */
  Ciudad: string;

  /**
   * Numero exterior del domicilio donde se factura
   */
  NumeroExterior: string;

  /**
   * Numero interior del domicilio donde se factura (opcional)
   */
  NumeroInterior?: string;

  /**
   * Colonia donde se factura
   */
  Colonia: string;

  /**
   * Codigo postal donde se encuentra el domicilio
   */
  CodigoPostal: string;

  /**
   * Nombre(s) de la persona a facturar
   */
  Nombre: string;

  /**
   * Apellido paterno de la persona
   */
  ApellidoPaterno: string;

  /**
   * Apellido materno de la persona
   */
  ApellidoMaterno?: string;
}

export class DatosOcupacion {
  /**
   * Identificador del usuario donde se guarda la informacion
   */
  UsuarioId: number;

  /**
   * Tipo de empresa en la que esta contratada
   */
  TipoEmpresaId: number;

  /**
   * Define el puesto que ocupa dentro de la empresa
   */
  TipoPuestoEmpresaId: number;
}

export class DatosPersonaRelacionada {
  /**
   * Correo de las personas relacionadas
   */
  correoElectronico: string;

  /**
   * Fecha de creacion del registro
   */
  fechaCreacion: Date;

  /**
   * Fecha de modificacion del registro
   */
  fechaModificacion: Date;

  /**
   * Nombre completo de la persona relacionada
   */
  nombreCompleto: string;

  /**
   * Identificador del registro de persona relacionada
   */
  personaRelacionadaId: number;

  /**
   * Persona
   */
  personaUsuario: any;

  /**
   * Identificador de la persona
   */
  personaUsuarioId: any;

  /**
   * Telefono movil o de casa de la persona relacionada
   */
  telefono: string;

  /**
   * Tipo de relacion
   */
  tipoRelacion: any;

  /**
   * Identificador del tipo de relacion
   */
  tipoRelacionId: number;

  /**
   * Usuario
   */
  usuario: any;

  /**
   * Usuario que creo el registro
   */
  usuarioCreacion: number;

  /**
   * Identificador del usuario al que esta relacionado el registro
   */
  usuarioId: number;

  /**
   * Usuario que modifica el registro 
   */
  usuarioModificacion: number;
}

export class DatosDocumentos {
  /**
   * Documentos
   */
  documento?: any;

  /**
   * Identificador del documento 
   */
  documentoId: number;

  /**
   * Nombre del documento 
   */
  documentoNombre: string;

  /**
   * Nombre del documento 
   */
  nombreDocumento?: string;

  /**
   * Estatus del documento: determina si esta revisado, rechazado, entre otros
   */
  estatusDocumento?: any;

  /**
   * Identificador del estatus de documento
   */
  estatusDocumentoId: number;

  /**
   * Fecha de creacion del registro
   */
  fechaCreacion?: Date;

  /**
   * Fecha de modificacion del
   */
  fechaModificacion?: Date;

  /**
   * Nombre fisico del archivo
   */
  nombreFisico: any;

  /**
   * Nombre original del archivo
   */
  nombreOriginal: string;

  /**
   * usuario que sube el archivo
   */
  usuario?: any

  /**
   * Usuario que creo el registro
   */
  usuarioCreacion?: number;

  /**
   * Identificador del usuario que subio el documento
   */
  usuarioDocumentoId?: number;

  /**
   * Identificador del usuario que sube el archivo
   */
  usuarioId: number;

  /**
   * Usuario que modifica el registro
   */
  usuarioModificacion?: number;

  /**
   * Propiedad para asignar la solicitud de apoyo del usuario
   */
  solicicutdApoyoFinancieroId?: number;
}