import { FORM_VALID_MODEL } from './../../../../../core.domain/models/user/form-valid-model';
import { BILLING_INFORMATION_MODEL } from './../../../../../core.domain/models/user/billing-information.model';
import { MessageErrors } from './../../../../../core.application/functions/messageErros.function';
import { DireccionService } from './../../../../../../_services/direccion.service';
import { TipoTelefono } from './../../../../../../_models/tipo-telefono';
import { TipoDireccion } from './../../../../../../_models/tipo-direccion';
import { Pais } from './../../../../../../_models/pais';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BILLING_INFORMATION } from '../../../../../core.application/validators/user-data/billing-information.validators';
import { EntidadFederativa } from '../../../../../../_models/entidad-federativa';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-billing-information-form',
  templateUrl: './billing-information-form.component.html',
  styleUrls: ['./billing-information-form.component.scss']
})
export class BillingInformationFormComponent implements OnInit, OnDestroy, OnChanges {
  //#region  Input, Output & ViewChild 
  @Input() data: BILLING_INFORMATION_MODEL;
  
  @Input() cancelledFields: string[] = [];
  @Input() disabledledFields: string[] = [];

  @Output() formValidity: EventEmitter<FORM_VALID_MODEL> = new EventEmitter(); 
  @Output() formData = new EventEmitter<BILLING_INFORMATION_MODEL>();
  //#endregion

  //#region Subscriptions
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Properties
  public billingInformationForm: FormGroup = this.formBuilder.group(BILLING_INFORMATION);
  public ErrorMsg: any;
  private status: boolean = false;

  public tipoDirecciones: Array<TipoDireccion>;
  public paises: Array<Pais>;
  public entidadesFederativas: Array<EntidadFederativa>;
  public tipoDeTelefonos: Array<TipoTelefono>;

  public  deletedInputs = {
    razonSocial           : false,
    rfc                   : false,
    paisId                : false,
    entidadFederativaId   : false,
    ciudad                : false,
    calle                 : false,
    numeroExterior        : false,
    numeroInterior        : false,
    colonia               : false,
    codigoPostal          : false,
    tipoTelefonoId        : false,
    telefono              : false,
    extension             : false
  };
  //#endregion

  constructor(
    private formBuilder: FormBuilder,
    private _direccionService: DireccionService,
  ) { 
    this.setErrors();
  }

  ngOnInit(): void {    
    this._getAllMethods();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] !== undefined) {
      this._setData();
    }
  }
  
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#region setData
  private _setData():void
  {    
    this.billingInformationForm.setValue(this.data);
    // this.billingInformationForm.get('razonSocial')?.setValue(this.data.razonSocial);
    // this.billingInformationForm.get('rfc')?.setValue(this.data.rfc);
    // this.billingInformationForm.get('paisId')?.setValue(this.data.paisId);
    // this.billingInformationForm.get('entidadFederativaId')?.setValue(this.data.entidadFederativaId);
    // this.billingInformationForm.get('ciudad')?.setValue(this.data.ciudad);
    // this.billingInformationForm.get('calle')?.setValue(this.data.calle);
    // this.billingInformationForm.get('numeroExterior')?.setValue(this.data.numeroExterior);
    // this.billingInformationForm.get('numeroInterior')?.setValue(this.data.numeroInterior);
    // this.billingInformationForm.get('colonia')?.setValue(this.data.colonia);
    // this.billingInformationForm.get('codigoPostal')?.setValue(this.data.codigoPostal);
  }
  //#endregion

  //#region Helpers
  /**
   * The function sets the error message for the form
   */
  public setErrors(): void {
    this.ErrorMsg = MessageErrors.setErrorMessage(this.billingInformationForm);
  }

  /**
   * If the cancelledFields array has a length greater than 0, then for each field in the
   * cancelledFields array, if the billingInformationForm has a control with the name of the field, then clear the
   * validators for that control and add the field to the deletedInputs object.
   */
   _deleteCancelledFields(): void {
    if (this.cancelledFields.length > 0) {
      this.cancelledFields.forEach(field => {
        if (this.billingInformationForm.get(field)) {
          this.billingInformationForm.get(field)?.clearValidators();
          this.deletedInputs[field] = true;
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  /**
   * _disabledCancelledFields() is a function that disables and clears validators for fields that are
   * not required
   */
  _disabledCancelledFields(): void {
    if (this.disabledledFields.length > 0) {
      this.disabledledFields.forEach(field => {
        if (this.billingInformationForm.get(field)) {
          this.billingInformationForm.get(field)?.clearValidators();
          this.billingInformationForm.get(field)?.disable();
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  private _subscribeToForm(): void {

    this.billingInformationForm.markAllAsTouched();
    this._Emit();
    this.Subscription.add(
      this.billingInformationForm.statusChanges
      .subscribe(status => {
        this.setErrors();
        this.status = status === 'VALID';
        this._Emit();
      })
    );

    this.Subscription.add(
      this.billingInformationForm
      .get('paisId')
      ?.valueChanges
      .subscribe(value => {
        this._getStatsByCountry(value);        
      })
    );
  }

  private _Emit():void
  {
    this.formValidity.emit(
      new FORM_VALID_MODEL(
        7,
        'BillingInformationFormComponent',
        this.status
      )
    );
    this.formData.emit(
      new BILLING_INFORMATION_MODEL(
        this.billingInformationForm.get('razonSocial')?.value,
        this.billingInformationForm.get('rfc')?.value,
        this.billingInformationForm.get('paisId')?.value,
        this.billingInformationForm.get('entidadFederativaId')?.value,
        this.billingInformationForm.get('ciudad')?.value,
        this.billingInformationForm.get('calle')?.value,
        this.billingInformationForm.get('numeroExterior')?.value,
        this.billingInformationForm.get('numeroInterior')?.value,
        this.billingInformationForm.get('colonia')?.value,
        this.billingInformationForm.get('codigoPostal')?.value
      )
    );
  }
  //#endregion


  //#region Methods

  private _getAllMethods():void
  {
    this._subscribeToForm();
    this._getDirectionsType();
    this._getCountries();
    this._deleteCancelledFields();
    this._disabledCancelledFields();
  }

  private _getDirectionsType(): void {
    this.Subscription.add(
      this._direccionService.obtenerTipoDireccion().subscribe(
        (tipoDirecciones: Array<TipoDireccion>) => {
          this.tipoDirecciones = tipoDirecciones;
        })
    );
  }

  private _getCountries(): void {
    this.Subscription.add(
      this._direccionService.obtenerPaises().subscribe(
        (paises: Array<Pais>) => {
          this.paises = paises;
        })
    );
  }

  private _getStatsByCountry(paisld: number): void {
    if (this.paisld?.valid) {
      this.Subscription.add(
        this._direccionService.obtenerEntidadesFederativas(paisld).subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
          this.entidadesFederativas = entidadesFederativas;
        })
      );
    }
  }

  //#endregion

  //#region Controls  

  get paisld() {
    return this.billingInformationForm.get('paisId');
  }
  //#endregion
}