import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmitterSaveActionService {

  constructor() { }

  public $emitSaveTab = new EventEmitter();

  emitirEvento(tabIndex: number) {
      this.$emitSaveTab.emit(tabIndex);
  }   
}
