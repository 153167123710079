import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resultados-cursos',
  templateUrl: './resultados-cursos.component.html',
  styleUrls: ['./resultados-cursos.component.scss']
})
export class ResultadosCursosComponent implements OnInit {

  //#region Properties
  public Titulo: string = 'Resultados';
  public typesOfShoes: string[] = [
    'Harry','Ross',
    'Bruce','Cook',
    'Carolyn','Morgan',
    'Albert','Walker',
    'Randy','Reed',
    'Larry','Barnes',
    'Lois','Wilson',
    'Jesse','Campbell',
    'Ernest','Rogers',
    'Theresa','Patterson',
    'Henry','Simmons',
    'Michelle','Perry',
    'Frank','Butler',
    'Shirley'
  ];
  //#endregion
  constructor() { }

  ngOnInit(): void {
  }

  //#region
  public onResultsChange(result: any):void
  {
    
  }
  //#endregion



}
