import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment,baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsitucionService {
  invocarObtenerFoto = new EventEmitter();
  invMetodoObtenerFotoSubscription: Subscription

  constructor(private httpClient: HttpClient) {}
    //Obtener informacion de la institucion
    public obtenerInformacionInstitucion(institucionId): Observable<any> {
      return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Home/GetDatosInstitucionCargaInicial/` + institucionId);
    }

    invocarMetodoObtenerFoto() {
      this.invocarObtenerFoto.emit();
    }
}
