import { ColegiaturaCosto } from './../../../_models/colegiatura-costo';
import { BajaDTO } from './../../../_models/BajaDTO.model';
import { GruposDisponiblesParaMateriaDTO } from './../../../models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { EgresoService } from 'src/app/_services/egreso.service';
import { TraductorService } from 'src/app/_services/traductor.service';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { ExisteMateriaSeriada } from 'src/app/models/customEntities/existeMateriaSeriada.mode';
import { ModalMaterias } from 'src/app/models/customEntities/modalMaterias';
import { MatetiasSeleccionadasService } from 'src/app/services/matetias-seleccionadas.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { PagoMateriasComponent } from 'src/app/_components/pago-materias/pago-materias.component';
import { CargaInscripcion } from 'src/app/_models/carga-inscripcion';
import { ColegiaturaCostoDto } from 'src/app/_models/ColegiaturaCosto.model';
import { DatoFiscal } from 'src/app/_models/dato-fiscal';
import { EstatusUsuarioEnum } from 'src/app/_models/estatusUsuario.enum';
import { EstudiosPrevios } from 'src/app/_models/estudios-previos';
import { GrupoDTO } from 'src/app/_models/GrupoDTO.model';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { BiografiaService } from 'src/app/_services/biografia.service';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { ApoyoFinancieroAdmisionComponent } from './apoyo-financiero-admision/apoyo-financiero-admision.component';
import { DatosAdmisionComponent } from './datos-admision/datos-admision.component';
import { DatosPersonalesAdmisionComponent } from './datos-personales-admision/datos-personales-admision.component';
import { DocumentosAdmisionComponent } from './documentos-admision/documentos-admision.component';
import { InscripcionAdmisionComponent } from './inscripcion-admision/inscripcion-admision.component';
import { SeleccionMateriasAdmisionComponent } from './seleccion-materias-admision/seleccion-materias-admision.component';
import { InsitucionService } from 'src/app/_services/insitucion.service';

@Component({
  selector: 'app-admision-contenido',
  templateUrl: './admision-contenido.component.html',
  styleUrls: ['./admision-contenido.component.scss']
})
export class AdmisionContenidoComponent implements OnInit, OnDestroy {

  //Componentes hijo contenido
  @ViewChild(DatosAdmisionComponent) datosAdmision: DatosAdmisionComponent;
  @ViewChild(DatosPersonalesAdmisionComponent) datosPersonalesAdmision: DatosPersonalesAdmisionComponent;
  @ViewChild(DocumentosAdmisionComponent) documentosAdmision: DocumentosAdmisionComponent;
  @ViewChild(ApoyoFinancieroAdmisionComponent) apoyoFinancieroAdmision: ApoyoFinancieroAdmisionComponent;
  @ViewChild(InscripcionAdmisionComponent) inscripcionAdmision: InscripcionAdmisionComponent;
  @ViewChild(SeleccionMateriasAdmisionComponent) seleccionMateriasAdmision: SeleccionMateriasAdmisionComponent;
  @ViewChild(PagoMateriasComponent) pagoMateriasAdmision: PagoMateriasComponent;
  //Variables componente hijo esconder
  public showDatosAdmision: boolean = true;
  public showDatosPersonalesAdmision: boolean = true;
  public showDocumentosAdmision: boolean = true;
  public showApoyoFinancieroAdmision: boolean = true;
  public showInscripcionAdmision: boolean = true;
  public showSeleccionMateriasAdmision: boolean = true;
  public showPagoMateriasAdmision: boolean = true;
  public showCargasMaterias: boolean = false;
  //Variable renderizar pago materias
  public renderizarPagoMaterias: boolean = false;
  //Variable Usuario
  public usuario: any = null;
  public informacionUsuario: any = null;
  //Variables Obs
  public stepAdmision$: Observable<number>;
  public stepAdmision: number = 0;
  public existeApoyo$: Observable<boolean>;
  public existeApoyo: boolean = false;
  public existeFirma$: Observable<boolean>;
  public existeFirma: boolean = false;
  public existeInscripcion$: Observable<boolean>;
  public existeInscripcion: boolean = false;
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variables imagen Usuario
  public imagenUsuarioForm: FormGroup;
  //Variables Foto perfil
  public imageOrigin: any;
  public imagePath: any;
  public eventImage: any;
  public base64Image: any;
  public dialogCambioImagenRef: any;
  @ViewChild('dialogCambioImagen') dialogCambioImagen: TemplateRef<any>;
  @ViewChild('file') inputFile: ElementRef;
  //Variable cargas
  public cargaAlumno: any = null;
  //Variable Estatus usuario
  public estatusAlumno: number = 0;
  //Varaiables para mensajes de estatus de documentacion o estatus
  public mostrarMensajeNoAdmitido: boolean = false;
  public mostrarMensajeFaltaInscripcion: boolean = false;
  //Vairbale inscripcion no pagada
  public pagoInscripcionSinRealizar: boolean = false;


  private colegiaturaCuota: ColegiaturaCostoDto;
  public permiteParcialidades: boolean = false;
  public cambiosHechosEnSeleccionDeMateria: boolean = false;
  public dialogoRefCerrarModalCargo: any;
  @ViewChild('dialogoCerrarModalCargo') dialogoCerrarModalCargo: TemplateRef<any>;

  public materiaAltaBaja: boolean = false;
  public gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
  public materiasBajaAltaAlumno: any;
  public bloqueSeleccionado: boolean = false;

  constructor(private admisionesService: AdmisionesService,
    private snackService: SnackService,
    private ensenanza: EnsenanzaAprendizajeService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    public nuevoIngresoService: NuevoIngresoService,
    private sanitizer: DomSanitizer,
    private biografiaService: BiografiaService,
    private materiasService: MatetiasSeleccionadasService,
    private egresoService: EgresoService,
    private traductorService: TraductorService,
    private institucionService: InsitucionService,
  ) { }

  ngOnInit(): void {
    /**
     * Setea la variable para saber si se esta en el modulo de admisiones
     */
    this.admisionesService.setModuloAdmisionesAlumno(true);
    /**
     * Subscripcion Usuario conectado
     */
    this.subscription.add(
      this.ensenanza.obtenerUsuarioConectado().subscribe(
        (usuario: any) => {
          this.usuario = usuario;
          this.GetInformacionUsuario();
          this.RenderizarFoto();
        })
    );
    /**
     * Subscripcion OBS step seleccionado
     */
    this.stepAdmision$ = this.admisionesService.GetStepAdmision$();
    this.subscription.add(
      this.stepAdmision$.subscribe((step: any) => {
        this.stepAdmision = step;
      })
    );
    /**
     * Subcripcion Obs cambio evento cambio de step
     */
    if (this.admisionesService.ChangeStepSubscription === undefined) {
      this.admisionesService.ChangeStepSubscription = this.admisionesService.ChangeStep.subscribe(() => {
        this.ChandeStep();
      });
    }

    this.existeFirma$ = this.admisionesService.getExisteFirma$();
    this.subscription.add(
      this.existeFirma$.subscribe(
        (existe: boolean) => {
          this.existeFirma = existe;
          console.log("FIRMA", existe)
        })
    );
  }


  public recibirBloqueSeleccionado(seleccionado: boolean) {
    this.bloqueSeleccionado = seleccionado;
  }


  /**
   * Metodo para renderizar el formulario segun el step
   */
  public ChandeStep() {
    this.existeApoyo$ = this.admisionesService.getExisteApoyo$();
    this.subscription.add(
      this.existeApoyo$.subscribe(
        (existe: boolean) => {
          this.existeApoyo = existe;
        })
    );
    this.existeInscripcion$ = this.admisionesService.getExisteInscripcion$();
    this.subscription.add(
      this.existeInscripcion$.subscribe(
        (existe: boolean) => {
          this.existeInscripcion = existe;
        })
    );
    this.datosAdmision.datosAdmisionRenderizar = false;
    this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
    this.documentosAdmision.documentosAdmisionRenderizar = false;
    this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
    this.showCargasMaterias = false;
    this.mostrarMensajeNoAdmitido = false;
    this.mostrarMensajeFaltaInscripcion = false;
    this.showInscripcionAdmision = true;
    this.showSeleccionMateriasAdmision = true;
    this.showPagoMateriasAdmision = true;
    this.showDatosAdmision = true;

    if (this.stepAdmision == null) {
      this.stepAdmision = 0;
    } else {
      switch (this.stepAdmision + 1) {
        case 1: {
          this.showDatosAdmision = false;
          this.showDocumentosAdmision = true;
          this.showApoyoFinancieroAdmision = true;
          this.showInscripcionAdmision = true;
          this.showSeleccionMateriasAdmision = true;
          this.showPagoMateriasAdmision = true;
          this.showDatosPersonalesAdmision = true;
          this.datosAdmision.datosAdmisionRenderizar = false;
          this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
          this.documentosAdmision.documentosAdmisionRenderizar = false;
          this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
          this.datosAdmision.RenderizarDatosAdmision();
          this.stepAdmision = this.stepAdmision + 1;
          break;
        }
        case 2: {
          this.showDatosAdmision = true;
          this.showDocumentosAdmision = true;
          this.showApoyoFinancieroAdmision = true;
          this.showInscripcionAdmision = true;
          this.showSeleccionMateriasAdmision = true;
          this.showPagoMateriasAdmision = true;
          this.showDatosPersonalesAdmision = false;
          this.datosAdmision.datosAdmisionRenderizar = false;
          this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
          this.documentosAdmision.documentosAdmisionRenderizar = false;
          this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
          this.datosPersonalesAdmision.RenderizarDatosPersonales();
          this.stepAdmision = this.stepAdmision + 1;
          break;
        }
        case 3: {
          this.showDatosAdmision = true;
          this.showDocumentosAdmision = false;
          this.showApoyoFinancieroAdmision = true;
          this.showInscripcionAdmision = true;
          this.showSeleccionMateriasAdmision = true;
          this.showPagoMateriasAdmision = true;
          this.showDatosPersonalesAdmision = true;
          this.datosAdmision.datosAdmisionRenderizar = false;
          this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
          this.documentosAdmision.documentosAdmisionRenderizar = false;
          this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
          this.documentosAdmision.RenderizarDocumentosAdmision();
          this.stepAdmision = this.stepAdmision + 1;
          break;
        }
        case 4: {
          if (this.existeApoyo) {
            this.showApoyoFinancieroAdmision = false;
            this.showDatosAdmision = true;
            this.showDocumentosAdmision = true;
            this.showInscripcionAdmision = true;
            this.showSeleccionMateriasAdmision = true;
            this.showPagoMateriasAdmision = true;
            this.showDatosPersonalesAdmision = true;
            this.datosAdmision.datosAdmisionRenderizar = false;
            this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
            this.documentosAdmision.documentosAdmisionRenderizar = false;
            this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
            this.apoyoFinancieroAdmision.renderizarApoyoFianciero();
            this.stepAdmision = this.stepAdmision + 1;
          } else {
            if (this.existeInscripcion) {
              this.showDatosAdmision = true;
              this.showDocumentosAdmision = true;
              this.showApoyoFinancieroAdmision = true;
              this.showInscripcionAdmision = false;
              this.showSeleccionMateriasAdmision = true;
              this.showPagoMateriasAdmision = true;
              this.showDatosPersonalesAdmision = true;
              this.datosAdmision.datosAdmisionRenderizar = false;
              this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
              this.documentosAdmision.documentosAdmisionRenderizar = false;
              this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
              this.inscripcionAdmision.renderizarInscripcion();
              this.stepAdmision = this.stepAdmision + 1;
            } else {
              this.showSeleccionMateriasAdmision = false;
              this.showCargasMaterias = true;
              this.showDatosAdmision = true;
              this.showDocumentosAdmision = true;
              this.showApoyoFinancieroAdmision = true;
              this.showInscripcionAdmision = true;
              this.showPagoMateriasAdmision = true;
              this.showDatosPersonalesAdmision = true;
              this.datosAdmision.datosAdmisionRenderizar = false;
              this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
              this.documentosAdmision.documentosAdmisionRenderizar = false;
              this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
              this.seleccionMateriasAdmision.renderizarSeleccionMaterias();
              this.stepAdmision = this.stepAdmision + 1;
            }
          }
          break;
        }
        case 5: {
          if (this.existeApoyo) {
            if (this.existeInscripcion) {
              this.showInscripcionAdmision = false;
              this.showDatosAdmision = true;
              this.showDocumentosAdmision = true;
              this.showApoyoFinancieroAdmision = true;
              this.showSeleccionMateriasAdmision = true;
              this.showPagoMateriasAdmision = true;
              this.showDatosPersonalesAdmision = true;
              this.datosAdmision.datosAdmisionRenderizar = false;
              this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
              this.documentosAdmision.documentosAdmisionRenderizar = false;
              this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
              if (this.estatusAlumno === EstatusUsuarioEnum.admitido || this.estatusAlumno === EstatusUsuarioEnum.alumno) {
                this.inscripcionAdmision.renderizarInscripcion();
                this.stepAdmision = this.stepAdmision + 1;
              } else {
                this.mostrarMensajeNoAdmitido = true;
              }
            } else {
              this.showSeleccionMateriasAdmision = false;
              this.showCargasMaterias = true;
              this.showDatosAdmision = true;
              this.showDocumentosAdmision = true;
              this.showApoyoFinancieroAdmision = true;
              this.showInscripcionAdmision = true;
              this.showPagoMateriasAdmision = true;
              this.showDatosPersonalesAdmision = true;
              this.datosAdmision.datosAdmisionRenderizar = false;
              this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
              this.documentosAdmision.documentosAdmisionRenderizar = false;
              this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
              this.seleccionMateriasAdmision.renderizarSeleccionMaterias();
              this.stepAdmision = this.stepAdmision + 1;
            }
          } else {
            if (this.existeInscripcion) {
              this.showSeleccionMateriasAdmision = false;
              this.showCargasMaterias = true;
              this.showDatosAdmision = true;
              this.showDocumentosAdmision = true;
              this.showApoyoFinancieroAdmision = true;
              this.showInscripcionAdmision = true;
              this.showPagoMateriasAdmision = true;
              this.showDatosPersonalesAdmision = true;
              this.datosAdmision.datosAdmisionRenderizar = false;
              this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
              this.documentosAdmision.documentosAdmisionRenderizar = false;
              this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
              this.seleccionMateriasAdmision.renderizarSeleccionMaterias();
              this.stepAdmision = this.stepAdmision + 1;
            } else {
              this.showPagoMateriasAdmision = false;
              this.showCargasMaterias = true;
              this.renderizarPagoMaterias = true;
              this.showDatosAdmision = true;
              this.showDocumentosAdmision = true;
              this.showApoyoFinancieroAdmision = true;
              this.showInscripcionAdmision = true;
              this.showSeleccionMateriasAdmision = true;
              this.showDatosPersonalesAdmision = true;
              this.datosAdmision.datosAdmisionRenderizar = false;
              this.datosPersonalesAdmision.datosPersonalesRenderizar = false;
              this.documentosAdmision.documentosAdmisionRenderizar = false;
              this.apoyoFinancieroAdmision.apoyoFinancieroRenderizar = false;
              this.pagoMateriasAdmision.obtenerResumenMateria();
              this.stepAdmision = this.stepAdmision + 1;
            }
          }
          break;
        }
        case 6: {
          if (this.existeApoyo) {
            if (this.existeInscripcion) {
              this.showSeleccionMateriasAdmision = false;
              this.showCargasMaterias = true;
              this.seleccionMateriasAdmision.renderizarSeleccionMaterias();
              this.stepAdmision = this.stepAdmision + 1;
            } else {
              this.showPagoMateriasAdmision = false;
              this.showCargasMaterias = true;
              this.renderizarPagoMaterias = true;
              this.pagoMateriasAdmision.obtenerResumenMateria();
              this.stepAdmision = this.stepAdmision + 1;
            }
          } else {
            if (this.existeInscripcion) {
              this.showPagoMateriasAdmision = false;
              this.showCargasMaterias = true;
              this.renderizarPagoMaterias = true;
              this.pagoMateriasAdmision.obtenerResumenMateria();
              this.stepAdmision = this.stepAdmision + 1;
            }
          }
          break;
        }
        case 7: {
          this.showPagoMateriasAdmision = false;
          this.showCargasMaterias = true;
          this.renderizarPagoMaterias = true;
          this.pagoMateriasAdmision.obtenerResumenMateria();
          this.stepAdmision = this.stepAdmision + 1;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  /**
   * Metodo para obtener la informacion del usuario
   */
  public GetInformacionUsuario(): void {
    this.subscription.add(
      this.admisionesService.GetInformacionUsuario(this.usuario.usuarioId).subscribe(
        (informacionUsuario: ApiResponse<any>) => {
          this.informacionUsuario = informacionUsuario.data;
          this.stepAdmision = this.informacionUsuario.alumnoRegistrado[0].stepAdmision;
          this.ChandeStep();
          this.validarPagoInscripcion(this.informacionUsuario.alumnoRegistrado[0].alumnoId, this.informacionUsuario.alumnoRegistrado[0].priodoIngreso);
          this.estatusAlumno = this.informacionUsuario.alumnoRegistrado[0].estatusAcademico;
          this.admisionesService.SetInformacionUsuario(informacionUsuario.data);
          this.obtenerCargas();
          if (this.informacionUsuario.fotografia !== null) {
            this.base64Image = 'data:image/png;base64,' + this.informacionUsuario.fotografia;
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
            this.imageOrigin = this.imagePath;
          } else {
            this.imagePath = null;
            this.imageOrigin = null;
          }
          this.admisionesService.invokeValidarApoyo();
        })
    );
  }

  /**
   * Metodo que trae un bool para saber si el usuario ya pago una inscipcion
   */
  public validarPagoInscripcion(alumnoId: number, periodoId: number) {
    this.subscription.add(
      this.nuevoIngresoService.ValidarInscripcionPagada(alumnoId, periodoId).subscribe((inscripcionPagada) => {
        if (!inscripcionPagada) {
          ///CAMBIAR A TRUE ESTA EN FALSE PARA QUE NO SE APLICQUE LA VALIDACION
          this.pagoInscripcionSinRealizar = true;
        }
      })
    );
  }

  /**
   * Metodo para obtener las cargas programa, campus, periodo
   */
  public obtenerCargas(): void {
    this.subscription.add(
      this.nuevoIngresoService.getCargas(this.informacionUsuario.alumnoRegistrado[0].campusId, this.informacionUsuario.alumnoRegistrado[0].programa.nivelId, this.informacionUsuario.alumnoRegistrado[0].programaId).subscribe(
        (respuesta: ApiResponse<CargaInscripcion>) => {
          this.cargaAlumno = respuesta.data;
        })
    );
  }

  /**
   * Metodo para crear formulario de imagen perfil
   */
  public RenderizarFoto() {
    this.imagenUsuarioForm = new FormGroup
      ({
        usuarioId: new FormControl(this.usuario.usuarioId),
        fotografia: new FormControl(''),
        fotografiaFile: new FormControl(''),
      });
  }
  get fotografia() { return this.imagenUsuarioForm.get('fotografia'); }

  /**
  * Metodo que invoca eventemitter para volver con el stepper
  */
  public GoBackStep() {
    this.admisionesService.InvokeBackStep();
  }

  /**
  * Metodo que invoca eventemitter para continuar con el stepper
  */
  public GoNextStep() {
    this.nuevoIngresoService.modificarStepAlumno(this.usuario.usuarioId, this.stepAdmision).subscribe(response => {
      if (response)
        this.saveStep();
    });

  }

  /**
   * Metodo para saber que step de informacion se debe guardar con el boton de continuar
   */
  public saveStep() {
    switch (this.stepAdmision - 1) {
      case 0: {
        if (this.datosAdmision.cambioFormDatosAdmision) {
          this.saveStepDatosAdmision();
        } else {
          this.admisionesService.InvokeNextStep();
        }
        break;
      }
      case 1: {
        if (this.datosPersonalesAdmision.cambioFormDatosPersonalesAdmision) {
          this.saveStepDatosPersonales();
        } else {
          this.admisionesService.InvokeNextStep();
        }
        break;
      }
      case 2: {
        if (this.existeApoyo) {
          if (this.apoyoFinancieroAdmision.guardarSituacionEconomica) {
            this.apoyoFinancieroAdmision.guardarSituacionEconomicaUsuario();
          } else {
            this.admisionesService.InvokeNextStep();
          }
        } else {
          if (this.existeInscripcion) {
            if (this.estatusAlumno === EstatusUsuarioEnum.admitido || this.estatusAlumno === EstatusUsuarioEnum.alumno) {
              this.apoyoFinancieroAdmision.onChangeTab();
              this.admisionesService.InvokeNextStep();
            } else {
              this.showDocumentosAdmision = true;
              this.mostrarMensajeNoAdmitido = true;
            }
          } else {
            if (this.estatusAlumno === EstatusUsuarioEnum.admitido || this.estatusAlumno === EstatusUsuarioEnum.alumno) {
              this.apoyoFinancieroAdmision.onChangeTab();
              this.admisionesService.InvokeNextStep();
            } else {
              this.showDocumentosAdmision = true;
              this.mostrarMensajeNoAdmitido = true;
            }
          }
        }
        break;
      }
      case 3: {
        if (this.existeApoyo) {
          if (this.apoyoFinancieroAdmision.guardarSituacionEconomica) {
            this.apoyoFinancieroAdmision.guardarSituacionEconomicaUsuario();
            this.admisionesService.InvokeNextStep();
          } else {
            this.admisionesService.InvokeNextStep();
          }
        } else {
          if (this.existeInscripcion) {
            if (!this.pagoInscripcionSinRealizar) {
              this.admisionesService.InvokeNextStep();
            } else {
              this.showInscripcionAdmision = true;
              this.mostrarMensajeFaltaInscripcion = true;
            }
          } else {
            if (this.seleccionMateriasAdmision.isSoloGruposDesuscritosPendientes || this.seleccionMateriasAdmision.isGruposDesuscritosEInscritosPendientes || this.seleccionMateriasAdmision.isSoloGruposInscritosPendientes) {
              this.cambiarPantallaPago();
            } else {
              if (this.seleccionMateriasAdmision.materiaExistente) {
                this.admisionesService.InvokeNextStep();
              } else {
                this.snackService.mostrarSnackBack("Es necesario seleccionar materias para poder avanzar al paso de pago");
              }
            }
          }
        }
        break;
      }
      case 4: {
        if (this.existeApoyo) {
          if (this.existeInscripcion) {
            if (!this.pagoInscripcionSinRealizar) {
              this.admisionesService.InvokeNextStep();
            } else {
              this.showInscripcionAdmision = true;
              this.mostrarMensajeFaltaInscripcion = true;
            }
          } else {
            if (this.seleccionMateriasAdmision.isSoloGruposDesuscritosPendientes || this.seleccionMateriasAdmision.isGruposDesuscritosEInscritosPendientes || this.seleccionMateriasAdmision.isSoloGruposInscritosPendientes) {
              this.cambiarPantallaPago();
            } else {
              if (this.seleccionMateriasAdmision.materiaExistente) {
                this.admisionesService.InvokeNextStep();
              } else {
                this.snackService.mostrarSnackBack("Es necesario seleccionar materias para poder avanzar al paso de pago");
              }
            }
          }
        } else {
          if (this.existeInscripcion) {
            this.admisionesService.InvokeNextStep();
          } else {
            if (this.seleccionMateriasAdmision.isSoloGruposDesuscritosPendientes || this.seleccionMateriasAdmision.isGruposDesuscritosEInscritosPendientes || this.seleccionMateriasAdmision.isSoloGruposInscritosPendientes) {
              this.cambiarPantallaPago();
            } else {
              if (this.seleccionMateriasAdmision.materiaExistente) {
                this.admisionesService.InvokeNextStep();
              } else {
                this.snackService.mostrarSnackBack("Es necesario seleccionar materias para poder avanzar al paso de pago");
              }
            }
          }
        }
        break;
      }
      case 5: {
        if (this.existeApoyo) {
          if (this.existeInscripcion) {
            if (this.seleccionMateriasAdmision.isSoloGruposDesuscritosPendientes || this.seleccionMateriasAdmision.isGruposDesuscritosEInscritosPendientes || this.seleccionMateriasAdmision.isSoloGruposInscritosPendientes) {
              this.cambiarPantallaPago();
            } else {
              if (this.seleccionMateriasAdmision.materiaExistente) {
                this.admisionesService.InvokeNextStep();
              } else {
                this.snackService.mostrarSnackBack("Es necesario seleccionar materias para poder avanzar al paso de pago");
              }
            }
          } else {
            this.admisionesService.InvokeNextStep();
          }
        } else {
          if (this.existeInscripcion) {
            this.admisionesService.InvokeNextStep();
          } else {
            this.admisionesService.InvokeNextStep();
          }
        }
        break;
      }
      case 6: {
        this.admisionesService.InvokeNextStep();
        break;
      }
      default: {
        break;
      }
    }
  }


  /**
   * Guarda los cambios hechos en respecto a las materias, en la base de datos
   */
  public guardarCambiosDeMaterias(): void {
    this.egresoService.ConfiguracionAcademicaValida(this.informacionUsuario.alumnoRegistrado[0].campusId, this.informacionUsuario.alumnoRegistrado[0].programa.nivelId, this.informacionUsuario.alumnoRegistrado[0].priodoIngreso).subscribe((response: ApiResponse<boolean>) => {
      if (response.success) {
        if (response.data) {
          if (this.seleccionMateriasAdmision.isSoloGruposDesuscritosPendientes) {
            this.gruposDesuscritosLocalmente = this.seleccionMateriasAdmision.gruposDesuscritosLocalmente;
            this.desuscribirMaterias();
          }
          if (this.seleccionMateriasAdmision.isSoloGruposInscritosPendientes) {
            this.inscribirMaterias();
          }
          if (this.bloqueSeleccionado) {
            this.inscribirMaterias();
          }
          if (this.seleccionMateriasAdmision.isGruposDesuscritosEInscritosPendientes) {
            this.gruposDesuscritosLocalmente = this.seleccionMateriasAdmision.gruposDesuscritosLocalmente;
            this.materiaAltaBaja = true;
            this.materiaAltaBajaAlumno();
          }
        } else {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
 * Desuscribe de un grupo a un alumno que ya está cursando una materia en un grupo siempre
 */

  private desuscribirMaterias() {
    const informacionDeBaja = new BajaDTO();
    informacionDeBaja.identiticador = this.seleccionMateriasAdmision.identificador;
    informacionDeBaja.idPeriodo = this.informacionUsuario.alumnoRegistrado[0].priodoIngreso;
    informacionDeBaja.grupos = this.gruposDesuscritosLocalmente;
    informacionDeBaja.cantidadDeCreditosAntesDeBaja = this.seleccionMateriasAdmision.cantidadDeCreditosAntesDeBaja;
    informacionDeBaja.cantidadDeMateriasAntesDeBaja = this.seleccionMateriasAdmision.cantidadDeMateriasAntesDeBaja;
    this.nuevoIngresoService.darDeBajaDeGrupos(informacionDeBaja).subscribe((materiasBaja: SimpleResponse) => {
      if (materiasBaja.success) {
        this.cambiosHechosEnSeleccionDeMateria = false;
        let mensaje: string = this.traductorService.translate(materiasBaja.message);
        this.snackService.mostrarSnackBack(mensaje);
      }
      else {
        this.snackService.mostrarSnackBack(materiasBaja.message);
      }
    });
  }

  /**
   * Inscribe a un nuevo grupo al alumno para la materia y periodo indicado
   */
  private inscribirMaterias(): void {
    this.colegiaturaCuota = new ColegiaturaCostoDto();
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.informacionUsuario.alumnoRegistrado[0].campusId, this.informacionUsuario.alumnoRegistrado[0].programa.nivelId, this.informacionUsuario.alumnoRegistrado[0].priodoIngreso, this.informacionUsuario.alumnoRegistrado[0].programaId).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<any>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data !== null) {
            if (cuotaColegiaturaRegistrada.data.parcialidades) {
              this.permiteParcialidades = true;
            }
            this.colegiaturaCuota.alumnoId = this.informacionUsuario.alumnoRegistrado[0].alumnoId;
            this.colegiaturaCuota.campusId = this.informacionUsuario.alumnoRegistrado[0].campusId;
            this.colegiaturaCuota.nivelId = this.informacionUsuario.alumnoRegistrado[0].programa.nivelId;
            this.colegiaturaCuota.periodoId = this.informacionUsuario.alumnoRegistrado[0].priodoIngreso;
            this.colegiaturaCuota.costo = cuotaColegiaturaRegistrada.data.costo;
            this.colegiaturaCuota.claveTipoCobro = cuotaColegiaturaRegistrada.data.claveTipoCobro;
            this.colegiaturaCuota.impuesto = cuotaColegiaturaRegistrada.data.impuesto;
            this.colegiaturaCuota.intereses = cuotaColegiaturaRegistrada.data.intereses;
            this.colegiaturaCuota.fechaInicio = cuotaColegiaturaRegistrada.data.fechaInicio;
            this.colegiaturaCuota.fechaFin = cuotaColegiaturaRegistrada.data.fechaFin;
            this.colegiaturaCuota.diaTransaccion = cuotaColegiaturaRegistrada.data.diaTransaccion;
            this.colegiaturaCuota.diaVencimiento = cuotaColegiaturaRegistrada.data.diaVencimiento;
            this.colegiaturaCuota.fechaVencimiento = cuotaColegiaturaRegistrada.data.fechaVencimiento;
            this.colegiaturaCuota.esNuevoIngreso = true;
            this.registrarMaterias();
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
        } else {
          this.snackService.mostrarSnackBack(cuotaColegiaturaRegistrada.message);
        }
      });
  }

  private registrarMaterias(): void {
    let grupos = this.seleccionMateriasAdmision.gruposInscritosLocalmente.map((grupo) => {
      let grupoApiDTO = new GrupoDTO();
      grupoApiDTO.claveMateria = grupo.claveMateria
      return grupoApiDTO
    });
    this.nuevoIngresoService.existeSeriacionDeMateriasEnLaInscripcion(this.informacionUsuario.alumnoRegistrado[0].alumnoId, grupos).subscribe((ExisteSeriacion: SimpleResponse) => {
      if (ExisteSeriacion.success) {
        let seriacion = new ExisteMateriaSeriada();
        seriacion.existeMateriaSeriada = true;
        seriacion.materiasSeriadas = ExisteSeriacion.message;
        this.nuevoIngresoService.existeSeriacionDeMaterias(seriacion);
      }
      this.nuevoIngresoService.inscribirAgrupos(this.informacionUsuario.alumnoRegistrado[0].alumnoId, this.informacionUsuario.identificador, this.seleccionMateriasAdmision.gruposInscritosLocalmente).subscribe((materiasInscritas: SimpleResponse) => {
        if (materiasInscritas.success) {
          this.seleccionMateriasAdmision.resetSeleccionDeMaterias();
          this.abrirModalTipoCargo();
        }
        else {
          this.snackService.mostrarSnackBack(materiasInscritas.message);
        }
      })
    });
  }


  public abrirModalTipoCargo() {
    this.dialogoRefCerrarModalCargo = this.dialog.open(this.dialogoCerrarModalCargo, { disableClose: true });
  }

  public cerarModalTipoCargo() {
    this.dialogoRefCerrarModalCargo.close();
  }

  /**
   * Metodo que guarda el step de datos admision
   */
  public saveStepDatosAdmision() {
    let datosForm = this.datosAdmision.datosAdmisionForm.value;
    let datosEnviar = {
      AlumnoId: this.informacionUsuario.alumnoRegistrado[0].alumnoId,
      CampusId: datosForm.campus,
      ProgramaId: datosForm.programaAcademico,
      PriodoIngreso: datosForm.periodo,
      formatoProgramaId: datosForm.formatoPrograma,
      idiomaProgramaId: datosForm.idiomaPrograma,
      ReValidarMaterias: datosForm.reValidarMaterias
    }
    this.subscription.add(
      this.admisionesService.actualizarDatosAdmision(datosEnviar).subscribe((respuesta: SimpleResponse) => {
        if (respuesta.success) {
          this.GetInformacionUsuario();
          this.admisionesService.InvokeNextStep();
          this.showDatosAdmision = true;
          this.datosAdmision.cambioFormDatosAdmision = false;
          setTimeout(() => {
            let mensaje: string = this.traductorService.translate('guardadoCorrecto');
            this.snackService.mostrarSnackBack(mensaje);
          }, 2000);
        } else {
          let mensaje: string = this.traductorService.translate('errorEditar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    );
  }

  public CargoPorExibicion() {
    if (this.materiaAltaBaja) {
      this.materiaAltaBaja = false;
      this.materiasBajaAltaAlumno.cargoExcibicion = true;
      this.nuevoIngresoService.AltaBajaMateriaAlumno(this.materiasBajaAltaAlumno).subscribe((response: SimpleResponse) => {
        if (response.success) {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.cambiosHechosEnSeleccionDeMateria = false;
          this.admisionesService.InvokeNextStep();
        }
        else {
          this.snackService.mostrarSnackBack(response.message);
        }
      });
    }
    else {
      this.nuevoIngresoService.CargoExcibicion(this.colegiaturaCuota).subscribe((cargoExcibicion: SimpleResponse) => {
        if (cargoExcibicion.success) {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.cambiosHechosEnSeleccionDeMateria = false;
          this.admisionesService.InvokeNextStep();
        }
        else {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    }
    this.dialogoRefCerrarModalCargo.close();
  }

  public CargoPorParcialidades() {
    if (this.materiaAltaBaja) {
      this.materiaAltaBaja = false;
      this.materiasBajaAltaAlumno.cargoExcibicion = false;
      this.nuevoIngresoService.AltaBajaMateriaAlumno(this.materiasBajaAltaAlumno).subscribe((response: SimpleResponse) => {
        if (response.success) {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.cambiosHechosEnSeleccionDeMateria = false;
          this.admisionesService.InvokeNextStep();
        }
        else {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    }
    else {
      this.nuevoIngresoService.CargoParcialidades(this.colegiaturaCuota).subscribe((cargoExcibicion: SimpleResponse) => {
        if (cargoExcibicion.success) {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.cambiosHechosEnSeleccionDeMateria = false;
          this.admisionesService.InvokeNextStep();
        }
        else {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    }
    this.dialogoRefCerrarModalCargo.close();
  }

  /**
   * Metodo que guarda el step de datos personales admision
   */
  public saveStepDatosPersonales() {
    let datosPersonalesForm = Object.assign(this.datosPersonalesAdmision.datosPersonalesForm.value);
    let datosPersonales: any = {
      usuarioId: datosPersonalesForm.usuarioId,
      nombre: datosPersonalesForm.nombre,
      segundoNombre: datosPersonalesForm.segundoNombre,
      apellidoPaterno: datosPersonalesForm.apellidoPaterno,
      apellidoMaterno: datosPersonalesForm.apellidoMaterno,
      correo: datosPersonalesForm.correo,
      biografia: {
        fechaNacimiento: datosPersonalesForm.fechaNacimiento
      }
    }
    this.nuevoIngresoService.obtenerCorreoOcupado(datosPersonalesForm.correo, datosPersonalesForm.usuarioId).subscribe(
      (respuestaCorreo: SimpleResponse) => {
        if (respuestaCorreo.success) {
          let mensaje: string = this.traductorService.translate('errorCorreo');
          this.snackService.mostrarSnackBack(mensaje);
        } else {
          this.nuevoIngresoService.editarDatosPersonales(datosPersonales).subscribe(
            datosPersonales => {
              if (datosPersonales) {
                let datosContactoForm = Object.assign(this.datosPersonalesAdmision.datosPersonalesForm.value);
                let datosContacto: any = {
                  correo: datosContactoForm.correo,
                  usuarioId: datosPersonalesForm.usuarioId,
                  direccion: [
                    {
                      tipoDireccionId: datosPersonalesForm.tipoDireccionId,
                      paisId: datosPersonalesForm.paisId,
                      entidadFederativaId: datosPersonalesForm.entidadFederativaId,
                      calle: datosPersonalesForm.calle,
                      ciudad: datosPersonalesForm.ciudad,
                      numeroExterior: datosPersonalesForm.numeroExterior,
                      numeroInterior: datosPersonalesForm.numeroInterior,
                      colonia: datosPersonalesForm.colonia,
                      codigoPostal: datosPersonalesForm.codigoPostal
                    }
                  ],
                  telefono: [
                    {
                      tipoTelefonoId: datosContactoForm.tipoTelefonoId,
                      paisId: datosContactoForm.paisId,
                      numero: datosContactoForm.numero,
                      extension: datosContactoForm.extension
                    },
                    {
                      tipoTelefonoId: datosContactoForm.otroTipoTelefonoId,
                      paisId: datosContactoForm.paisId,
                      numero: datosContactoForm.otroNumero,
                      extension: datosContactoForm.otroExtension
                    }
                  ]
                }
                this.nuevoIngresoService.registrarDatosContacto(datosContacto).subscribe(datoContacto => {
                  if (datoContacto) {
                    let datosBiografiaForm = Object.assign(this.datosPersonalesAdmision.datosPersonalesForm.value);
                    let datosBiografia: any = {
                      UsuarioId: datosBiografiaForm.usuarioId,
                      LegalGeneroId: datosBiografiaForm.legalGeneroId,
                      GeneroId: datosBiografiaForm.generoId > 0 ? datosBiografiaForm.generoId : null,
                      CiudadaniaPaisId: datosBiografiaForm.ciudadania,
                      EstadoCivilId: datosBiografiaForm.estadoCivilId > 0 ? datosBiografiaForm.estadoCivilId : null,
                      Curp: datosBiografiaForm.curp,
                      EntidadNacimiento: datosBiografiaForm.entidadNacimiento,
                      CiudadNacimiento: datosBiografiaForm.ciudadNacimiento,
                      Alergias: datosBiografiaForm.alergias,
                      GrupoSanguineoId: datosBiografiaForm.grupoSanguineoId > 0 ? datosBiografiaForm.grupoSanguineoId : null,
                      Enfermedades: datosBiografiaForm.enfermedades,

                      razaId: datosBiografiaForm.raza,
                      estatusMigratorioId: datosBiografiaForm.estatusMigratorio,
                      tipoDocumentoId: datosBiografiaForm.tipoDocumento,
                      paisDocumentoId: datosBiografiaForm.paisDocumento,
                      hasCriminalRecord: datosBiografiaForm.hasCriminalRecord,
                      hasAcademicDisciplinaryRecord: datosBiografiaForm.hasAcademicDisciplinaryRecord
                    }
                    this.biografiaService.obtenerBiografiaByCurp(datosBiografia.Curp, datosBiografia.UsuarioId).subscribe(
                      biografia => {
                        if (biografia != null) {
                          let mensaje: string = this.traductorService.translate('errorCRUP');
                          this.snackService.mostrarSnackBack(mensaje);
                        } else {
                          this.nuevoIngresoService.modificarBiografia(datosBiografia).subscribe(datoBiografia => {
                            if (datoBiografia) {
                              let datosFamiliaresForm = Object.assign(this.datosPersonalesAdmision.datosPersonalesForm.value);
                              let datosFamiliares: any[] = [];
                              if (datosFamiliaresForm.nombreCompletoPadre != '') {
                                let datosFamilia: any = {
                                  UsuarioId: datosFamiliaresForm.usuarioId,
                                  TipoRelacionId: 1,
                                  NombreCompleto: datosFamiliaresForm.nombreCompletoPadre,
                                  Telefono: datosFamiliaresForm.telefonoPadre,
                                  CorreoElectronico: datosFamiliaresForm.correoElectronicoPadre
                                }
                                datosFamiliares.push(datosFamilia);
                              }
                              if (datosFamiliaresForm.nombreCompletoMadre != '') {
                                let datosFamilia: any = {
                                  UsuarioId: datosFamiliaresForm.usuarioId,
                                  TipoRelacionId: 2,
                                  NombreCompleto: datosFamiliaresForm.nombreCompletoMadre,
                                  Telefono: datosFamiliaresForm.telefonoMadre,
                                  CorreoElectronico: datosFamiliaresForm.correoElectronicoMadre,
                                }
                                datosFamiliares.push(datosFamilia);
                              }
                              if (datosFamiliaresForm.nombreCompletoTutor != '') {
                                let datosFamilia: any = {
                                  UsuarioId: datosFamiliaresForm.usuarioId,
                                  TipoRelacionId: 3,
                                  NombreCompleto: datosFamiliaresForm.nombreCompletoTutor,
                                  Telefono: datosFamiliaresForm.telefonoTutor,
                                  CorreoElectronico: datosFamiliaresForm.correoElectronicoTutor
                                }
                                datosFamiliares.push(datosFamilia);
                              }
                              if (datosFamiliaresForm.nombreContactoObli != '') {
                                let datosFamilia: any = {
                                  UsuarioId: datosFamiliaresForm.usuarioId,
                                  TipoRelacionId: datosFamiliaresForm.tipoRelacion,
                                  NombreCompleto: datosFamiliaresForm.nombreContactoObli,
                                  Telefono: datosFamiliaresForm.telefonoContactoObli,
                                  CorreoElectronico: datosFamiliaresForm.correoContactoObli,
                                }
                                datosFamiliares.push(datosFamilia);
                              }
                              this.nuevoIngresoService.registrarDatosFamilia(datosFamiliares).subscribe(datoFamiliares => {
                                if (datoFamiliares) {
                                  let datosEmergenciaForm = Object.assign(this.datosPersonalesAdmision.datosPersonalesForm.value);
                                  let datosEmergencia: any = {
                                    UsuarioId: datosEmergenciaForm.usuarioId,
                                    TipoRelacionId: 4,
                                    NombreCompleto: datosEmergenciaForm.nombreContacto,
                                    Telefono: datosEmergenciaForm.telefonoContacto,
                                    CorreoElectronico: datosEmergenciaForm.correoContacto
                                  }
                                  this.nuevoIngresoService.registrarDatosEmergencia(datosEmergencia).subscribe(datoEmergencia => {
                                    if (datoEmergencia) {
                                      let datosEstudiosPreviosForm = Object.assign(this.datosPersonalesAdmision.datosPersonalesForm.value);
                                      let datosEstudiosPrevios: EstudiosPrevios = new EstudiosPrevios();
                                      datosEstudiosPrevios.usuarioId = datosEstudiosPreviosForm.usuarioId,
                                        datosEstudiosPrevios.nivelId = datosEstudiosPreviosForm.nivel,
                                        datosEstudiosPrevios.escuela = datosEstudiosPreviosForm.academico,
                                        datosEstudiosPrevios.promedio = +datosEstudiosPreviosForm.promedio,
                                        datosEstudiosPrevios.tipoCandidato = datosEstudiosPreviosForm.tipoCandidato,
                                        this.nuevoIngresoService.agregarEstudiosPrevios(datosEstudiosPrevios).subscribe(datoEstudioPrevio => {
                                          if (datoEstudioPrevio) {
                                            let datosDatoFiscalForm = Object.assign(this.datosPersonalesAdmision.datosPersonalesForm.value);
                                            let datosDatoFiscal: any = {
                                              UsuarioId: datosDatoFiscalForm.usuarioId,
                                              Rfc: datosDatoFiscalForm.rfc,
                                              RazonSocial: datosDatoFiscalForm.razonSocial,
                                              EntidadFederativaId: datosDatoFiscalForm.entidadFederativaFacturacionId,
                                              Calle: datosDatoFiscalForm.calleFacturacion,
                                              Ciudad: datosDatoFiscalForm.ciudadFacturacion,
                                              NumeroExterior: datosDatoFiscalForm.numeroExteriorFacturacion,
                                              NumeroInterior: datosDatoFiscalForm.numeroInteriorFacturacion,
                                              Colonia: datosDatoFiscalForm.coloniaFacturacion,
                                              CodigoPostal: datosDatoFiscalForm.codigoPostalFacturacion,
                                              Nombre: datosDatoFiscalForm.nombre,
                                              ApellidoPaterno: datosDatoFiscalForm.apellidoPaterno,
                                              ApellidoMaterno: datosDatoFiscalForm.apellidoMaterno
                                            }
                                            this.nuevoIngresoService.GetDatoFiscalByRFC(datosDatoFiscal.Rfc, datosDatoFiscal.UsuarioId).subscribe(
                                              (datoFiscal: ApiResponse<DatoFiscal>) => {
                                                if (datoFiscal.data != null) {
                                                  let mensaje: string = this.traductorService.translate('errorRFC');
                                                  this.snackService.mostrarSnackBack(mensaje);
                                                } else {
                                                  this.nuevoIngresoService.agregarDatosFiscal(datosDatoFiscal).subscribe((datoFiscal: SimpleResponse) => {
                                                    if (datoFiscal.success) {
                                                      let ocupacionForm = Object.assign(this.datosPersonalesAdmision.datosPersonalesForm.value);
                                                      let datosOcupacion: any = {
                                                        UsuarioId: ocupacionForm.usuarioId,
                                                        TipoEmpresaId: ocupacionForm.tipoEmpresaId,
                                                        TipoPuestoEmpresaId: ocupacionForm.tipoEmpresaId,
                                                      }
                                                      this.nuevoIngresoService.registrarOcupacion(datosOcupacion).subscribe(
                                                        ocupacion => {
                                                          if (ocupacion) {
                                                            this.GetInformacionUsuario();
                                                            this.admisionesService.InvokeNextStep();
                                                            this.showDatosPersonalesAdmision = true;
                                                            this.datosPersonalesAdmision.cambioFormDatosPersonalesAdmision = false;
                                                            let mensaje: string = this.traductorService.translate('guardadoCorrecto');
                                                            this.snackService.mostrarSnackBack(mensaje);
                                                          } else {
                                                            let mensaje: string = this.traductorService.translate('errorGuardar');
                                                            this.snackService.mostrarSnackBack(mensaje);
                                                          }
                                                        }
                                                      )
                                                    } else {
                                                      let mensaje: string = this.traductorService.translate('errorGuardar');
                                                      this.snackService.mostrarSnackBack(mensaje);
                                                    }
                                                  }
                                                  )
                                                }
                                              }
                                            )
                                          } else {
                                            let mensaje: string = this.traductorService.translate('errorGuardar');
                                            this.snackService.mostrarSnackBack(mensaje);
                                          }
                                        }
                                        )
                                    } else {
                                      let mensaje: string = this.traductorService.translate('errorGuardar');
                                      this.snackService.mostrarSnackBack(mensaje);
                                    }
                                  }
                                  )
                                } else {
                                  let mensaje: string = this.traductorService.translate('errorGuardar');
                                  this.snackService.mostrarSnackBack(mensaje);
                                }
                              }
                              )
                            } else {
                              let mensaje: string = this.traductorService.translate('errorGuardar');
                              this.snackService.mostrarSnackBack(mensaje);
                            }
                          }
                          )
                        }
                      });
                  }
                })
              } else {
                let mensaje: string = this.traductorService.translate('errorGuardar');
                this.snackService.mostrarSnackBack(mensaje);
              }
            }
          )
        }
      })
  }

  /**
   * Metodo que inicia el flujo del stepper
   */
  public iniciarStepper() {
    this.admisionesService.SetStepAdmision(1);
    this.showDatosAdmision = false;
    this.datosAdmision.RenderizarDatosAdmision();
  }

  /**
   * Metodo para abrir el modal de cambio de imagen
   */
  public imagenModal() {
    this.dialogCambioImagenRef = this.dialog.open(this.dialogCambioImagen, { disableClose: true });
  }

  /**
   * Metodo que se activa cuando cambia el valor del input tipo file
   * @param event
   * @returns
   */
  public preview(event) {
    if (event.length === 0)
      return;
    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imagePath = reader.result;
    }
    this.eventImage = event;
  }

  /**
   * Metodo que se activa cuando cambia el valor del input tipo file
   * @param event
   */
  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.onload = () => {
        this.informacionUsuario.fotografia = reader.result;
        this.changeDetector.markForCheck();
        this.imagenUsuarioForm.patchValue({
          fotografia: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  }

  /**
   * Metodo para cambiar la imagen cuando se guarda
   * @param event
   */
  public onFileChangePerfil(event) {
    const reader = new FileReader();
    if (event && event.length > 0) {
      this.imagePath = event;
      const [file] = event;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.readAsDataURL(event[0]);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
      }
      this.eventImage = event;
    }
  }

  /**
   * Metodo para guardar la imagen en base de datos y azure blod
   */
  public enviarImagen() {
    let imagenForm = Object.assign(this.imagenUsuarioForm.value);
    console.log(imagenForm);
    this.nuevoIngresoService.editarImagen(imagenForm).subscribe(
      (imagenData: SimpleResponse) => {
        if (imagenData.success) {
          this.institucionService.invocarMetodoObtenerFoto();
          let mensaje: string = this.traductorService.translate('guardadoCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      }
    )
  }

  /**
   * Metodo para cerrar el modal de cambio perfil
   * @param descartar booleano para saber si se guarda o se descarta
   */
  public cerrarModalImagen(descartar: boolean) {
    if (descartar) {
      this.dialogCambioImagenRef.close();
      this.imagePath = this.imageOrigin;
      this.inputFile.nativeElement.value = "";
    } else {
      this.dialogCambioImagenRef.close();
      this.onFileChangePerfil(this.eventImage);
      this.enviarImagen();
      this.imagePath = "";
    }
  }

  /**
   * Muestra el modal de pago
   */
  public cambiarPantallaPago(): void {
    this.materiasService.mostrarModalMaterias(this.seleccionMateriasAdmision.gruposInscritosLocalmente, this.seleccionMateriasAdmision.colegiaturaCosto.costo, this.seleccionMateriasAdmision.colegiaturaCosto.claveTipoCobro).afterClosed().subscribe((confirmo: boolean) => {
      if (confirmo) {
        this.guardarCambiosDeMaterias();
      }
    });
  }

  public materiaAltaBajaAlumno(): void {
    this.colegiaturaCuota = new ColegiaturaCostoDto();
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.informacionUsuario.alumnoRegistrado[0].campusId, this.informacionUsuario.alumnoRegistrado[0].programa.nivelId, this.informacionUsuario.alumnoRegistrado[0].priodoIngreso, this.informacionUsuario.alumnoRegistrado[0].programaId).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data.parcialidades) {
            this.permiteParcialidades = true;
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
          this.colegiaturaCuota.alumnoId = this.informacionUsuario.alumnoRegistrado[0].alumnoId;
          this.colegiaturaCuota.campusId = this.informacionUsuario.alumnoRegistrado[0].campusId;
          this.colegiaturaCuota.nivelId = this.informacionUsuario.alumnoRegistrado[0].programa.nivelId;
          this.colegiaturaCuota.periodoId = this.informacionUsuario.alumnoRegistrado[0].priodoIngreso;
          this.colegiaturaCuota.costo = cuotaColegiaturaRegistrada.data.costo;
          this.colegiaturaCuota.claveTipoCobro = cuotaColegiaturaRegistrada.data.claveTipoCobro;
          this.colegiaturaCuota.impuesto = cuotaColegiaturaRegistrada.data.impuesto;
          this.colegiaturaCuota.intereses = cuotaColegiaturaRegistrada.data.intereses;
          this.colegiaturaCuota.fechaInicio = cuotaColegiaturaRegistrada.data.fechaInicio;
          this.colegiaturaCuota.fechaFin = cuotaColegiaturaRegistrada.data.fechaFin;
          this.colegiaturaCuota.diaTransaccion = cuotaColegiaturaRegistrada.data.diaTransaccion;
          this.colegiaturaCuota.diaVencimiento = cuotaColegiaturaRegistrada.data.diaVencimiento;
          this.colegiaturaCuota.fechaVencimiento = cuotaColegiaturaRegistrada.data.fechaVencimiento;
          this.colegiaturaCuota.esNuevoIngreso = true;

          const informacionDeBaja = new BajaDTO();
          informacionDeBaja.identiticador = this.seleccionMateriasAdmision.identificador;
          informacionDeBaja.idPeriodo = this.informacionUsuario.alumnoRegistrado[0].priodoIngreso;
          informacionDeBaja.grupos = this.gruposDesuscritosLocalmente;
          informacionDeBaja.cantidadDeCreditosAntesDeBaja = this.seleccionMateriasAdmision.cantidadDeCreditosAntesDeBaja;
          informacionDeBaja.cantidadDeMateriasAntesDeBaja = this.seleccionMateriasAdmision.cantidadDeMateriasAntesDeBaja;

          this.materiasBajaAltaAlumno = {
            infoInscripcion: {
              identificador: this.seleccionMateriasAdmision.identificador,
              gruposInscritos: this.seleccionMateriasAdmision.gruposInscritosLocalmente
            },
            infoDesuscripcion: {
              informacionDeBaja: informacionDeBaja
            },
            infoColegiaturaCuota: {
              colegiaturaCuota: this.colegiaturaCuota
            }
          }
          this.seleccionMateriasAdmision.resetSeleccionDeMaterias();
          if (this.colegiaturaCuota.costo !== 0) {
            this.abrirModalTipoCargo();
          }
        }
        else {
          let mensaje: string = this.traductorService.translate("_nuevosIngresos.mensaje-son-cobro");
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
  }

  /**
   * Metodo que se activa cuando se cierra el componente
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    /**
    * Setea la variable para saber si se esta en el modulo de admisiones
    */
    this.admisionesService.setModuloAdmisionesAlumno(false);
  }

}
