import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service';
import { BlockLabelService } from 'src/app/_services/block-label.service';
import { ApiResponse } from '../../../models/api/ApiRespose.model';

@Component({
  selector: 'app-oferta-academica-nombre-bloque-modal-eliminar',
  templateUrl: './oferta-academica-nombre-bloque-modal-eliminar.component.html',
  styleUrls: ['./oferta-academica-nombre-bloque-modal-eliminar.component.scss']
})
export class OfertaAcademicaNombreBloqueModalEliminarComponent implements OnInit {
  public boolIsArray = false;
  public numBlockLabelSeleccionados = 0;
  public codeBlockLabel = '';

  constructor(public dialogRef: MatDialogRef<OfertaAcademicaNombreBloqueModalEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    public blockLabelService: BlockLabelService) { }

  ngOnInit(): void {
    this.validarDataRecibida();

  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numBlockLabelSeleccionados = this.data.length;
    }

  }


  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(d => (d.blockLabelId.toString()));
      this.codeBlockLabel = arrayClaves.toString();
    } else {
      this.codeBlockLabel = this.data.blockLabelId.toString();
    }


    this.blockLabelService.eliminarBlockLabel(this.codeBlockLabel).subscribe(
      (response: ApiResponse<any>) => {
        if (response.success) {
          this.blockLabelService.invocarMetodoObtenerNombreBloque();
          this.snackService.mostrarSnackBack(response.message);
          this.dialogRef.close(this.data);
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      }, error => {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar el nombre de bloque. Por favor, intente de nuevo más tarde.');
      }
    );



  }

}
