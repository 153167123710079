import { PaypalCredentials } from './../_models/Paypal/PaypalCredentials';
import { OrderCreate } from './../_models/Paypal/OrderCreate';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { baseUrl, environment } from './../../environments/environment';
import { SimpleResponse } from './../models/api/SimpleResponse.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagoPaypal } from '@app/_models/Paypal/PagoPaypal';

@Injectable({
  providedIn: 'root'
})
export class PagoLineaPaypalServiceService {

  constructor(private httpClient: HttpClient) { }

  //#region  ReferenciaPaypal
   /**
   * Servicio que genera un registro en la tabla ReferenciaPaypal
   * @param ReferenciaPaypal
   * @returns Retorma una simpleresponse
   */
    public EnviarReferenciaPaypal(ReferenciaPaypal: any): Observable<SimpleResponse> {
      return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Paypal/EnviarReferenciaPaypal`, ReferenciaPaypal);
    }
  //#endregion


  //#region  IntentoPago
   /**
   * Servicio que genera un registro en la tabla IntentoPago
   * @param PagoIntento
   * @returns Retorma una simpleresponse
   */
     public PostIntentoPago(PagoIntento: any): Observable<SimpleResponse> {
      return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Paypal/PostIntentoPago`, PagoIntento);
    }
  //#endregion


   //#region  CreateOrder
   /**
   * Servicio que genera una orden de compra a paypal
   * @param PagoCompletedFront
   * @returns Retorma una simpleresponse
   */
    public PagoCompletedFront(_PagoPaypal: any): Observable<SimpleResponse> {
      return this.httpClient.post<ApiResponse<SimpleResponse>>(`${environment.enves[baseUrl].apiUrl}/Paypal/PagoCompletedFront`, _PagoPaypal);
    }
  //#endregion


  //#region  CreateOrder
   /**
   * Servicio que genera una orden de compra a paypal
   * @param CreateOrder
   * @returns Retorma una simpleresponse
   */
    public CreateOrder(_PagoPaypal: PagoPaypal): Observable<ApiResponse<OrderCreate>> {
      return this.httpClient.post<ApiResponse<OrderCreate>>(`${environment.enves[baseUrl].apiUrl}/Paypal/CreateOrder`, _PagoPaypal);
    }
  //#endregion


  //#region  GetCredendialsPaypal
   /**
   * Servicio obtiene las credenciales y configuración de paypal
   * @param GetCredendialsPaypal
   * @returns Retorma una PaypalCredentials
   */
    public GetCredendialsPaypal(): Observable<ApiResponse<PaypalCredentials>> {
      return this.httpClient.get<ApiResponse<PaypalCredentials>>(`${environment.enves[baseUrl].apiUrl}/Paypal/GetCredendialsPaypal`);
    }
  //#endregion


  //#region  GetCredendialsPaypal
   /**
   * Metodo para verificar si la institución tiene registros en tabla integración
   * @param GetIntegracion
   * @returns Retorma un bool
   */
    public GetIntegracion(): Observable<ApiResponse<boolean>> {
      return this.httpClient.get<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/Paypal/GetIntegracion`);
    }
  //#endregion
}
