import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Alumno, EstatusAcademico } from '../_models/inscripcion'
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CampusService } from '../_services/campus.service';
import { Campus } from '../_models/campus';
import { InfoPrograma } from '../_models/infoPrograma';
import { InfoProgramaService } from '../_services/infoPrograma.service';
import { Periodo } from '../_models/periodo';
import { PeriodoService } from '../_services/periodo.service';
import { MatDialog } from '@angular/material/dialog';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { MatOption } from '@angular/material/core';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { EnviarCorreoComponent } from 'src/app/_components/enviar-correo/enviar-correo.component';
import { TramitesModalComponent } from 'src/app/Tramites/tramites-modal/tramites-modal.component';
import { InscripcionService } from '../_services/inscripcion.service';

@Component({
  selector: 'app-tramites',
  templateUrl: './tramites.component.html',
  styleUrls: ['./tramites.component.scss']
})
export class TramitesComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'ID', 'nombre', 'programa', 'campus', 'periodo', 'tipoAlumno', 'estatus'];
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public numIngresoSeleccionado: number = 0;
  public filtroForm: FormGroup;
  public datafilter: any;
  public arregloFiltroOriginal: any[] = [];
  public subscriptionCampus: Subscription;
  public campusList: Campus[] = [];
  public estatusList: EstatusAcademico[] = [];
  public programas: InfoPrograma[] = [];
  public periodos: Periodo[];
  public arregloFiltroModificado: any[] = [];
  private periodosactivos: any[] = [];
  public datosEliminar: [];
  public tipoDeAlumno: boolean;
  public idNombre = new FormControl();
  public CampusF = new FormControl();
  public ProgramaF = new FormControl();
  public periodoF = new FormControl();
  public estatusF = new FormControl();
  public tipoAlumnoF = new FormControl();
  public ExcelSubscription: Subscription;
  public tipoInscripcion: string = "NI";
  public tipoAl: number = 0;

  constructor(public inscripcionService: InscripcionService,
    private campusService: CampusService,
    public programaservice: InfoProgramaService,
    private periodoService: PeriodoService,
    public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private generadorExcel: GeneracionExcelServiceService) { }

  /**
   * Inicia el componente y las funciones de carga de los catalogos y el paginador de la tabla
   */
  ngOnInit(): void {
    this.cargarInscripciones(0);
    this.obtenerCampus();
    this.obtenerProgramas();
    this.obtenerPeriodo();
    this.obtenerEstatus();
    this.inicializarForm();
    this.LlenarFiltros();
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
  }

  /**
   * Inicializa el formulario y setea los campos usados
   */
  public inicializarForm(): void {
    this.filtroForm = new FormGroup({
      tipoAlumno: new FormControl(0),
      periodoF: new FormControl([]),
      idnombre: new FormControl(),
      ProgramaId: new FormControl([]),
      CampusId: new FormControl([]),
      estatus: new FormControl(0),
    });
    this.CampusF.setValue(["-1"]);
    this.ProgramaF.setValue(["-1"]);
    this.periodoF.setValue(["-1"]);
  }

  /**
    * Obtiene los campus del catalogo y setea su valor en todos
    */
  public obtenerCampus(): void {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe((campus: Campus[]) => {
      this.campusList = campus;
      this.CampusF.setValue([...this.campusList.map(item => item.campusId), 0]);
      this.filtro();
    });
  }

  /**
   * Obtiene los campus del programa y setea su valor en todos
   */
  public obtenerProgramas(): void {
    this.programaservice.obtenerInfoPrograma().subscribe((programas: InfoPrograma[]) => {
      this.programas = programas;
      this.ProgramaF.setValue([...this.programas.map(item => item.programaId), 0]);
      this.filtro();
    });
  }

  /**
   * Obtiene los campus del periodo
   */
  public obtenerPeriodo(): void {
    let today = new Date();
    this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.periodos = periodo;
      });
  }

  /**
   * Obtiene los campus del estatus academico
   */
  public obtenerEstatus(): void {
    this.inscripcionService.GetEstatusAcademicos().subscribe((status: ApiResponse<EstatusAcademico[]>) => {
      this.estatusList = status.data;
    });
  }

  /**
 * Obtiene el catalogo de inscripciones para mostrarlos en la tabla principal, agrega una nueva propiedas llamada nombre completo
 * carga los datos a la tabla principal y agrega los datos a un arreglo original
 * @param tipoAlumno 
 */
  public cargarInscripciones(tipoAlumno): void {
    this.inscripcionService.getInscripcionesTramite(tipoAlumno).subscribe((respuestaAlumno: ApiResponse<any>) => {
      respuestaAlumno.data = respuestaAlumno.data.map(obj => //es como un for, es como crear un nuevo arreglo
      ({
        ...obj, // con el ... le quita las propiedades y las deja en el arreglo, (opcional)
        nombreCompleto: obj.usuario.nombre + ' ' + obj.usuario.segundoNombre + ' ' + obj.usuario.apellidoPaterno + ' ' + obj.usuario.apellidoMaterno //nueva propiedad en el arreglo
      })
      )
      if (respuestaAlumno.data) {
        this.dataSource = new MatTableDataSource(respuestaAlumno.data);
        this.arregloFiltroOriginal = [...respuestaAlumno.data];
        this.dataSource.paginator = this.paginator;
        this.datafilter = respuestaAlumno.data;
      } else {
        this.dataSource = new MatTableDataSource([]);
        this.arregloFiltroOriginal = [];
      }
    }, (err => {
      this.dataSource = new MatTableDataSource([]);
      this.arregloFiltroOriginal = [];
    }))
  }

  /** 
   * Selecciona todas las columnas si no lo estan, o bien limpia la seleccion
   */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
   * seleccionados para modificar el check
   * @returns boolean para conocer si todos estan seleccionados
   */
  isAllSelected(): boolean {
    if (this.datafilter) {
      this.numIngresoSeleccionado = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numIngresoSeleccionado === numRows;
    }
  }

  /** 
   * La etiqueta de la casilla de verificación de la fila pasada
  */
  checkboxLabel(row?: Alumno): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.alumnoId + 1}`;
  }

  /**
    * Funcion que sirve para enviar correos y verifica si hay uno o varios segun la seleccion 
    * y abre el componente SeguimientoInscripcionCorreoComponent pasandole los datos a eliminar
    * @param btnDataTable 
    * @param elemento 
    */
  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      this.datosEliminar = elemento;
    } else {
      if (elemento.length === 1) {
        this.datosEliminar = elemento[0];
      } else {
        this.datosEliminar = elemento;
      }
    }
    this.dialog.open(EnviarCorreoComponent, { data: this.datosEliminar, disableClose: true });
  }


  /**
   * Funcion que obtiene los datos filtrados de la tabla principal y los convierte a un archivo de Excel para su descarga
   * @param elemento 
   */
  public descargarExcel(elemento?: any): void {
    let IdAlumnos;
    if (elemento != null && elemento.length > 0) {
      IdAlumnos = elemento.map(m => m.alumnoId.toString());
    } else {
      IdAlumnos = this.datafilter.map(m => m.alumnoId.toString());
    }
    console.log(IdAlumnos);
    this.inscripcionService.descargarExcel(IdAlumnos).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "Reinscripcion");
    });
  }


  /**
 * Vacia el arreglo 
 */
  public vaciarArregloModificado(): void {
    this.arregloFiltroModificado.length = 0;
  }

  public periodoSeleccionado: number = 0;
  public campusSeleccionado: number = 0;
  public programaSeleccionado: number = 0;
  public statusSeleccionado: number = 0;
  public tipoAlumnoSeleccionado: number = 0;

  /**
   * Seccion para los filtros que usa el formulario
   * filtra los datos segun el parametro que ingrese
   */
  public filtro(): void {
    let periodos: any[] = this.periodoF.value == null ? [] : this.periodoF.value;
    let alumno: string = this.idNombre.value == null ? "" : this.idNombre.value;
    let campus: any[] = this.CampusF.value == null ? [] : this.CampusF.value;
    let programa: any[] = this.ProgramaF.value == null ? [] : this.ProgramaF.value;
    let status: any = this.estatusF.value == null ? [] : this.estatusF.value;
    let tipoAlumno: number = this.tipoAlumnoF.value == null ? 0 : this.tipoAlumnoF.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arregloFiltroOriginal];
    filtroModificado = filtroModificado.filter(f => { return periodos.indexOf(f.priodoIngreso) > -1 });
    filtroModificado = filtroModificado.filter(f => { return f.nombreCompleto.toLowerCase().search(alumno.toLowerCase()) > -1 || f.usuario.identificador.toString().search(alumno) > -1 });
    filtroModificado = filtroModificado.filter(f => { return campus.indexOf(f.campusId) > -1 });
    filtroModificado = filtroModificado.filter(f => { return programa.indexOf(f.programaId) > -1 });

    if (status > 0) {
      filtroModificado = filtroModificado.filter(row => row.estatusAcademico == status);
      this.arregloFiltroModificado = this.arregloFiltroModificado.filter(row => row.estatusAcademico == status);
      this.statusSeleccionado = status;
    }

    if (tipoAlumno == 1 || tipoAlumno == 2) {
      if (tipoAlumno == 1) {
        this.tipoDeAlumno = false;
        this.tipoInscripcion = "NI";
      } else {
        this.tipoDeAlumno = true;
        this.tipoInscripcion = "RI";
      }
      filtroModificado = filtroModificado.filter(row => row.tipoAlumno == this.tipoDeAlumno);
      this.arregloFiltroModificado = this.arregloFiltroModificado.filter(row => row.tipoAlumno == this.tipoDeAlumno);
      this.tipoAlumnoSeleccionado = tipoAlumno;
    }

    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<any>(true, []);
  }

  /**
   * Hace un llenado de todos los filtros, ingresa a las funciones de obtencion de datos para su carga
   */
  public LlenarFiltros(): void {
    let today = new Date();
    this.periodoService.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        this.periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio < today && fin > today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if (this.periodosactivos.length > 0) {
          this.periodoF.setValue([...this.periodosactivos]);
        }
        this.filtro();
      });
    this.obtenerCampus();
    this.obtenerProgramas();
    this.obtenerPeriodo();
    this.obtenerEstatus();
  }

  /**
   * Limpia el arreglo de filtro, llena nuevamente los filtros e inicia el formulario, y muestra la tabla sin filtrado
   */
  public LimpiarFiltros(): void {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Obtiene la solicitud (seleccion), checa si es Nuevo Ingreso (NI) o Reingreso (RI), y la pestaña a la cual dirije
   * @param solicitud 
   * @param isCheck 
   * @param tabRequerido 
   */
  public inscribirMaterias(solicitud: any, isCheck: boolean, tabRequerido: string): void {
    if (isCheck) {
      if (!solicitud[0].tipoAlumno) {
        this.tipoInscripcion = "NI";
      } else {
        this.tipoInscripcion = "RI";
      }
      solicitud[0].tipoInscripcion = this.tipoInscripcion
      solicitud.tabRequerido = tabRequerido;
      this.util.abrirDialogoLateral(TramitesModalComponent, solicitud);
    } else {
      solicitud.tipoInscripcion = this.tipoInscripcion;
      let solicitudLista: any[] = [];
      solicitudLista.push(solicitud);
      solicitudLista = solicitudLista.map(obj =>
      ({
        ...obj,
        tabRequerido: tabRequerido
      })
      );
      this.util.abrirDialogoLateral(TramitesModalComponent, solicitudLista);
    }
  }

  /**
  * Si solo un dato del select Periodo esta seleccionado busca solo esa informacion y la 
  * muestra en la tabla principal
   */
  toggleAllSelectionPeriodo(): void {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.periodos.map(item => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.filtro();
  }

  /**
     * Si solo un dato del select Programa esta seleccionado busca solo esa informacion y la 
     * muestra en la tabla principal
  */
  toggleAllSelectionPrograma(): void {
    if (this.allPrograma.selected) {
      this.ProgramaF.patchValue([...this.programas.map(item => item.programaId), 0]);
    } else {
      this.ProgramaF.patchValue([]);
    }
    this.filtro();
  }

  /**
     * Si solo un dato del select Campus esta seleccionado busca solo esa informacion y la 
     * muestra en la tabla principal
  */
  toggleAllSelectionCampus(): void {
    if (this.allCampus.selected) {
      this.CampusF.patchValue([...this.campusList.map(item => item.campusId), 0]);
    } else {
      this.CampusF.patchValue([]);
    }
    this.filtro();
  }

  /**
     * Verifica si el campo Todos del select Periodo esta seleccionados, si lo esta 
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param all 
     * @returns 
  */
  tosslePerOnePeriodo(all): boolean {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if (this.periodoF.value.length == this.periodos.length) { this.allPeriodo.select(); }
    this.filtro();
  }

  /**
     * Verifica si el campo Todos del select Programa esta seleccionados, si lo esta 
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param all 
     * @returns 
  */
  tosslePerOnePrograma(all): boolean {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.ProgramaF.value.length == this.programas.length) { this.allPrograma.select(); }
    this.filtro();
  }

  /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta 
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param all 
     * @returns 
  */
  tosslePerOneCampus(all): boolean {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.CampusF.value.length == this.campusList.length) { this.allCampus.select(); }
    this.filtro();
  }

  /**
   * Al momento de cerrar el componente se desuscribe de la descarga de Excel
   */
  ngOnDestroy(): void {
    if (this.ExcelSubscription != null) {
      this.ExcelSubscription.unsubscribe();
    }
  }
}
