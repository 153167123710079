import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { Correo } from 'src/app/_models/correo';
import { InscripcionService } from 'src/app/_services/inscripcion.service';
import { SeguimientoComponent } from '../seguimiento.component';
@Component({
  selector: 'app-seguimiento-correo',
  templateUrl: './seguimiento-correo.component.html',
  styleUrls: ['./seguimiento-correo.component.scss']
})

export class SeguimientoInscripcionCorreoComponent implements OnInit {
  public boolIsArray: boolean = false;
  public correos: string = '';
  public correoForm: FormGroup;
  public boolCambio: boolean = false;
  public numSeleccionados: number = 0;
  public formularioValido: boolean = false;

  constructor(public dialogRef: MatDialogRef<SeguimientoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    public inscripcionService: InscripcionService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
    this.InicializarForm();    
  }

  get asunto() { return this.correoForm.get('asunto'); }
  get cuerpoDelMensaje() { return this.correoForm.get('cuerpoDelMensaje'); }

  /**
   * Inicia el formulario
   */
  public InicializarForm(): void {
    this.correoForm = new FormGroup({
      asunto: new FormControl('', Validators.required),
      cuerpoDelMensaje: new FormControl('', Validators.required),
    });
  }

  /**
   * Checa la longitud del arreglo o los datos que se le pasaron
   */
  public validarDataRecibida(): void {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numSeleccionados = this.data.length;
    }
  }

  /**
   * Funcion que envia a los correos mediante el servicio
   */
  public enviarCorreo(): void {
    if (this.boolIsArray) {
      const arrayCorreos = this.data.map(p => (p.usuario.correo.toString()));
      this.correos = arrayCorreos.toString();
    } else {
      this.correos = this.data.usuario.correo.toString();
    }
    const estructuraCorreo = new Correo();
    estructuraCorreo.CorreosDestinatario = this.correos;
    estructuraCorreo.Asunto = this.correoForm.get('asunto').value;
    estructuraCorreo.CuerpoDelMensaje = this.correoForm.get('cuerpoDelMensaje').value;
    this.inscripcionService.enviarCorreos(estructuraCorreo).subscribe((correoEnviado: SimpleResponse) => {
      if (correoEnviado.success) {
        this.snackService.mostrarSnackBack('Se ha enviado el correo con éxito.');
      } else {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al enviar el correo. Por favor, intente de nuevo más tarde.');
      }
    });
  }

  /**
   * Funcion que cierra el modal
   */
  public cerrarModal(): void {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }
}
