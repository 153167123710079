import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmitterSaveActionService } from '@app/clean-architecture/infrastructure/services/common/emitter-save-action.service';

@Component({
  selector: 'app-pagina-construccion',
  templateUrl: './pagina-construccion.component.html',
  styleUrls: ['./pagina-construccion.component.scss']
})
export class PaginaConstruccionComponent implements OnInit, OnChanges {

  public url: SafeResourceUrl;
  public URLEmbed: string = "";
  //#region Inputs, Outputs & Emiiers
  @Input() hello: string;
  @Input() tabIndex: number;
  @Output() formValid = new EventEmitter<boolean>();
  //#endregion
  //#region Properties
  private tabId: number = 0;
  //#endregion

  //#region LifeCycle
  constructor(public sanitizer: DomSanitizer,_EmitterSaveActionService : EmitterSaveActionService) {    
    _EmitterSaveActionService.$emitSaveTab.subscribe((tabIndex: Number) => {
      if (tabIndex === this.tabId) {        
        this.save();
      }
    });
   }

  ngOnInit(): void {
    
  }

  setUrl(){
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.URLEmbed);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabIndex']) {
      changes['tabIndex'].currentValue === this.tabId ? this.Emit() : null;
    }
  }
  //#endregion

 //#endregion Methods
  public Emit():void{
    this.formValid.emit(true)    
  }

  private save():void{
    console.log("Save page under construction");
  }
  //#endregion
}
