import { HeaderModalDetails } from './../../../../core.domain/models/header-modal-detail/header-modal-details.model';
import { EmitterSaveActionService } from './../../../../infrastructure/services/common/emitter-save-action.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaginaConstruccionComponent } from '../../../../../pagina-construccion/pagina-construccion.component';

@Component({
  selector: 'app-lateral-modal',
  templateUrl: './lateral-modal.component.html',
  styleUrls: ['./lateral-modal.component.scss']
})
export class LateralModalComponent implements OnInit {

  //#region Properties  
  public tabValid:boolean     = false;
  public tabGroup:string      = '';
  public selected:FormControl = new FormControl(0);

  public HeaderModalDetails:HeaderModalDetails
  //#endregion

  //#region LifeCycle
  constructor(
    @Inject(MAT_DIALOG_DATA) public getData: { data: any, tabIndex: number, tabGroup: string, headerModalDetails: HeaderModalDetails },
    private dialogRef: MatDialogRef<LateralModalComponent>, 
    private _EmitterSaveActionService : EmitterSaveActionService) { }

  ngOnInit(): void {    
    this.selected.setValue(this.getData.tabIndex);
    this.tabGroup = this.getData.tabGroup;
    this.HeaderModalDetails = new HeaderModalDetails(this.getData.headerModalDetails);
  }
  //#endregion

 //#region Actions
  /**
   * The function Save() is called when the user clicks the button "Save" in the component
   * "app-form-select-option"
   */
  public Save():void{    
    this._EmitterSaveActionService.emitirEvento(this.selected.value);
  }
  /**
   * The function closes the dialog box
   */
  public Close():void{
    this.dialogRef.close();
  }
 //#endregion
  
  //#region Methods
 /**
  * The function is called from the child component and it sets the value of the variable tabValid to
  * true or false
  * @param {boolean}  - boolean - This is the event that is passed from the child component.
  */
  public enableButtonSave($event: boolean):void{
    this.tabValid = $event;    
  }
  //#endregion

}
