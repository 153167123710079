import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Modalidad } from '../_models/modalidad';

@Injectable({
  providedIn: 'root'
})
export class ModalidadService {

  constructor(private httpClient: HttpClient) { }

  //Get Catalogo Tipo Modalidad
  public getModalidad(): Observable<Modalidad[]> {
    return this.httpClient.get<Modalidad[]>(`${environment.enves[baseUrl].apiUrl}/Modalidad`);
  }
}
