import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Periodo } from '../../../../_models/periodo';
import { Profesor } from '../../../../_models/profesor';
import { GrupoService } from '../../../../_services/grupo.service';
import { PeriodoService } from '../../../../_services/periodo.service';
import { UtileriasService } from '../../../../_services/utilerias.service';
import * as moment from 'moment';

@Component({
  selector: 'app-form-duracion',
  templateUrl: './form-duracion.component.html',
  styleUrls: ['./form-duracion.component.scss']
})
export class FormDuracionComponent implements OnInit, OnDestroy {
  public formDuracion: FormGroup;
  public datosCampusPeriodo$: Observable<any>;
  public obtenerDatosPeriodo: any;
  public datosMateria$: Observable<any>;
  public datosBloque$: Observable<any>;
  public setFechas$: Observable<any>;
  public subscriptionProfesor: Subscription;
  public profesorList: Profesor[] = [];
  public fechas: any;
  public subscriptionFecha: Subscription;

  constructor(private grupoService: GrupoService,
    private utileriasService: UtileriasService,
    private periodoService: PeriodoService,
    @Inject(MAT_DIALOG_DATA) public data: Periodo) {
    this.inicializaFrom();
  }

  ngOnInit(): void {
    this.grupoService.obtenerDatosMateria$().subscribe(mat=>{
    });
    this.datosCampusPeriodo$ = this.grupoService.obtenerDatosCampusPeriodo$();
    this.datosCampusPeriodo$.subscribe(datos =>{
      this.obtenerDatosPeriodo = datos;
    });
    this.datosMateria$ = this.grupoService.obtenerDatosMateria$();
    this.datosBloque$ = this.grupoService.obtenerDatosBloque$();
    this.obtenerProfesores();
    this.obtenerFecha();
    this.inicializaFrom();

    if (this.grupoService.invMetodoSetFechasSubscription == undefined) {
      this.grupoService.invMetodoSetFechasSubscription = this.grupoService.invocarSetFechas.subscribe(() => {
        this.setFechas();
      });
    }
  }

  get fechaInicio() { return this.formDuracion.get('fechaInicio'); }
  get fechaFin() { return this.formDuracion.get('fechaFin'); }
  get profesorId() { return this.formDuracion.get('profesorId'); }
  get horarioRequerido() { return this.formDuracion.get('horarioRequerido'); }

  public obtenerFecha() {
    this.subscriptionFecha = this.periodoService.obtenerPeriodos().subscribe(
      (fecha: Periodo[]) => {
        this.fechas = fecha;
      });
  }

  public obtenerProfesores() {
    this.subscriptionProfesor = this.grupoService.obtenerProfesor().subscribe(
      (profesor: Profesor[]) => {
        this.profesorList = profesor;
      });
  }

  public inicializaFrom() {
    this.formDuracion = new FormGroup({
      fechaInicio: new FormControl(null, [Validators.required]),
      fechaFin: new FormControl(null, [Validators.required]),
      profesorId: new FormControl(0),
      horarioRequerido: new FormControl(true)
    });

    this.formDuracion.valueChanges.subscribe(val => {
      this.grupoService.definirDatosDuracion(val);
    });
  }

  public setFechas(){
    this.formDuracion.patchValue({
      fechaInicio: this.utileriasService.formatearFecha(this.obtenerDatosPeriodo.periodo.fechaInicio, 'YYYY[-]MM[-]DD'),
      fechaFin:this.utileriasService.formatearFecha(this.obtenerDatosPeriodo.periodo.fechaFin, 'YYYY[-]MM[-]DD')
    })
  }

  public validarFechas = function (form: FormGroup, nombreControl1: string, nombreControl2: string) {
    if (form.get(nombreControl1).value !== null && form.get(nombreControl2).value !== null) {
      return form.get(nombreControl1).value <= form.get(nombreControl2).value;
    }
    return true;
  }

  public fechasDestinadas = function (form: FormGroup, nombreControl1: string, nombreControl2: string) {
    if (form.get(nombreControl1).value !== null && form.get(nombreControl2).value !== null) {

      let dateInput1 = new Date(form.get(nombreControl1).value+" 00:00:00");
      let dateInput2 = new Date(form.get(nombreControl2).value+" 00:00:00");
      // formularios
      let dateLeida1 = new Date(this.obtenerDatosPeriodo.periodo.fechaInicio);
      let dateLeida2 = new Date(this.obtenerDatosPeriodo.periodo.fechaFin);

      return (dateInput1 >= dateLeida1 && dateInput2 <= dateLeida2)
    }
    return false;
}

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.formDuracion.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  ngOnDestroy(): void {
    if (this.subscriptionProfesor) {
      this.subscriptionProfesor.unsubscribe();
    }
    if(this.grupoService.invMetodoSetFechasSubscription){
      this.grupoService.invMetodoSetFechasSubscription.unsubscribe();
      this.grupoService.invMetodoSetFechasSubscription = undefined;
    }
  }

}
