import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, baseUrl} from '@environments/environment';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { ReporteDTO, AlumnosInscritosDTO, ProgramaNivelDTO, AlumnoProgramaDTO } from '../_models/reporte-dto';
import { Alumno } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  constructor(private httpClient: HttpClient) { }

  public obtenerInscripcion(periodoId: string): Observable<ApiResponse<Alumno[]>> {
    return this.httpClient.get<ApiResponse<Alumno[]>>(`${environment.enves[baseUrl].apiUrl}/Reporte/GetInscripcion/${periodoId}`);
  }

  public obtenerAlumnosFiltrados(periodos): Observable<ApiResponse<ReporteDTO[]>> {
    return this.httpClient.post<ApiResponse<ReporteDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Reporte/GetInscripcionFiltro`, periodos);
  }
  
  public obtenerAlumnos(periodoId: number, fechaCorte: Date): Observable<ApiResponse<AlumnosInscritosDTO[]>> {
    return this.httpClient.get<ApiResponse<AlumnosInscritosDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Reporte/AlumnosInscritos/${periodoId}/${fechaCorte}`);
  }

  public obtenerProgramaNivel(nivelId: string, periodoId: number, fechaCorte: Date): Observable<ApiResponse<ProgramaNivelDTO[]>> {
    return this.httpClient.get<ApiResponse<ProgramaNivelDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Reporte/ProgramasPorNivel/${nivelId}/${periodoId}/${fechaCorte}`);
  }

  public obtenerProgramas(periodoId: number, fechaCorte: Date): Observable<ApiResponse<ProgramaNivelDTO[]>> {
    return this.httpClient.get<ApiResponse<ProgramaNivelDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Reporte/ProgramasGrafica/${periodoId}/${fechaCorte}`);
  }

  public obtenerAlumnosPorPrograma(nombrePrograma: string, periodoId: number, fechaCorte: Date): Observable<ApiResponse<AlumnoProgramaDTO[]>> {
    return this.httpClient.get<ApiResponse<AlumnoProgramaDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Reporte/AlumnoPorPrograma/${nombrePrograma}/${periodoId}/${fechaCorte}`);
  }

  public descargarAlumnosExcel(alumnosProgramas: AlumnoProgramaDTO[]) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Reporte/DescargarExcel`, JSON.stringify(alumnosProgramas),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  public descargarAlumnoPeriodo(alumnosProgramas: Alumno[]) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Reporte/DescargarExcelAlumnoPeriodo`, JSON.stringify(alumnosProgramas),
    {
      responseType: "blob",
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }
}
