import { GeneracionExcelServiceService } from './../../../../../services/generacion-excel-service.service';
import { MakeUpExamReasonModel } from './../../../../core.domain/models/make-up-exam/make-up-exam-reason.response';
import { MakeUpExamReasonService } from './../../../../infrastructure/services/make-up-exam-reason/make-up-exam-reason.service';
import { MassAssignmentMakeUpExamComponent } from './../../../common/modals/mass-assignment-make-up-exam/mass-assignment-make-up-exam.component';
import { MatDialog } from '@angular/material/dialog';
import { Usuario } from './../../../../../_models/usuario';
import { Alumno } from './../../../../../_models/inscripcion';
import { LateralModalComponent } from './../../../common/modals/lateral-modal/lateral-modal.component';
import { UtileriasService } from './../../../../../_services/utilerias.service';
import { ActionClickedMethods } from './../../../../core.domain/types/action-clicked-methods.types';
import { ButtonEvent } from './../../../../core.domain/models/data-table/button-event.model';
import { DataTableColumn } from './../../../../core.domain/models/data-table/data-table-column.models';
import { DataTable } from './../../../../core.domain/models/data-table/data-table.models';
import { Component, OnInit } from '@angular/core';
import { Mapping } from '../.../../../../../core.domain/class/mapping.class'
import { MakeUpExamResponse } from '../../../../core.domain/models/make-up-exam/make-up-exam.response';
import { GenericHttpService } from '@app/clean-architecture/infrastructure/services/generic-http.service';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { PageEvent } from '@angular/material/paginator';
import { HeaderModalDetails } from '../../../../core.domain/models/header-modal-detail/header-modal-details.model';
import { ModalEnviarCorreoComponent } from '@app/administracion-academica/modal-enviar-correo/modal-enviar-correo.component';
import { I18NextPipe } from 'angular-i18next';

type TableActions = 'userDataTab' | 'makeUpExamtab';

@Component({
  selector: 'app-make-up-exam-traking-table',
  templateUrl: './make-up-exam-traking-table.component.html',
  styleUrls: ['./make-up-exam-traking-table.component.scss']
})
export class MakeUpExamTrakingTableComponent implements OnInit {

  //#region Properties  
  public dataTable          : DataTable<any>;
  public MakeUpExamResponse : MakeUpExamResponse[];
  public filters            : any;
  private pageIndex         : number;
  private pageSize          : number;
  public initialLoad        = true;  
  public totalItems         = 100;
  public RowSelected        : any[] = [];
  public MakeUpExamReason       : MakeUpExamReasonModel[];

   /* A constant that is an object with two properties, makeUpExamtab and deleteRow. The values of these
  properties are the functions editAddress and deleteAddress. */
  readonly ACTION_CLICKED_METHODS: ActionClickedMethods<TableActions> = {
    userDataTab     : this.userDataTab,
    makeUpExamtab       : this.makeUpExamtab,    
  }
  //#endregion


  //#region Table
  readonly COLUMN_TABLE: DataTableColumn[] = [
    { name: 'Identificador'       , prop: 'identificador'         ,flex: 10, },
    { name: 'Nombre'              , prop: 'nombreCompleto'        ,flex: 10, customColumClass: 'text-left' },
    { name: 'Periodo'             , prop: 'periodoClave'          ,flex: 10, customColumClass: 'text-left' },
    { name: 'Campus'              , prop: 'campusClave'           ,flex: 10, },
    { name: 'Grupo'               , prop: 'grupoId'               ,flex: 10, },
    { name: 'Clave'               , prop: 'materiaClave'          ,flex: 10, customColumClass: 'text-left' },
    { name: 'Materia'             , prop: 'materiaNombre'         ,flex: 10, customColumClass: 'text-left' },
    { name: 'Profesor'            , prop: 'profesorIdentificador' ,flex: 10, customColumClass: 'text-left' },
    { name: 'Calificacion Final'  , prop: 'calificacionFinal'     ,flex: 10, },
    { name: 'Faltas'              , prop: 'numeroFaltas'          ,flex: 10, },
    { name: 'Extraordinario'      , prop: 'makeUpExamName'        ,flex: 10, }   
    
  ];
  //#endregion


  //#region LifeCycle
  constructor(
    private _GenericHttpService: GenericHttpService,
    private _util: UtileriasService,
    private _dialog: MatDialog,    
    private _i18nextPipe: I18NextPipe,
    private _MakeUpExamReasonService  : MakeUpExamReasonService,
    public _generadorExcel: GeneracionExcelServiceService
    ) { }

  ngOnInit(): void {
    this._getMakeUpExamReason(MakeUpExamReasonModel);
  } 
  //#endregion

  
  /**
   * get data from a service and then assign it to a variable type of MakeUpExamResponse[].
   * @param {T} _class - is the class name of the object that I want to get from the API.
   * @param {number} _page - number, _itemsPerPage: number, _filters: any
   * @param {number} _itemsPerPage - number = 10;
   * @param {any} _filters - {
   */
  public get<T extends Mapping>(_class: T, _page: number, _itemsPerPage: number, _filters: any):void {    
    
    this._GenericHttpService.getMakeUpExam(_class, _page, _itemsPerPage, _filters).subscribe((respuesta: ApiResponse<any[]>) => {
      this.MakeUpExamResponse = respuesta.data;
      if (this.MakeUpExamResponse.length > 0 ) {
        this.totalItems = this.MakeUpExamResponse[0].total;
      }else{
        this.totalItems = 0;
      }
      this.dataTable = {
        options: {
          hasCheckbox: true,
        },
        data: [...this.MakeUpExamResponse]
      };      
      this.initialLoad = false;      
    });    
  }

  public _getMakeUpExamReason<T extends Mapping>(_class: T):void {        
    this._MakeUpExamReasonService.getMakeUpExamReason(_class).subscribe((response: ApiResponse<any[]>) => {    
      this.MakeUpExamReason = response.data;    
    });    
  }


  //#region Events
    /**
   * Find the address that was clicked, then call the method that corresponds to the button that was
   * clicked, passing in the address that was clicked.
   * @param {ButtonEvent}  - ButtonEvent
   */
    public tableActionClicked($event: ButtonEvent): void {      
      const clickedName = this.MakeUpExamResponse.find(t => 
        t.identificador === $event.data.identificador &&
        t.alumnoId      === $event.data.alumnoId      &&
        t.grupoId       === $event.data.grupoId
        );
      const methodClicked = this.ACTION_CLICKED_METHODS[$event.buttonName].bind(this);
      methodClicked(clickedName);
    }


    /**
     * The function is called when the user clicks on the search button. The function receives an
     * object with the filters that the user has selected. The function then calls the get function
     * with the filters object as a parameter.
     * @param {any}  - any =&gt; this is the object that is being sent from the child component.
     */
    public formFiltersEvent($event: any):void{
      
      this.filters = {
        periodos: $event.periodo,
        identificador: $event.alumno,
        campus: $event.campus,
        materia: $event.materia,
        calificacionFinal:$event.calificacionFinal,
        numeroFaltas: $event.numeroFaltas,
        extraordinario: $event.extraordinario,
        makeUpExamDto: null
      };
      
      if (this.filters.periodo !== null && this.filters.campus != null && this.filters.extraordinario != null) {              
        this.get(MakeUpExamResponse, 1, 5, this.filters);
       }
    }

     /**
     * When the pagination changes, get the new _page of data from the server.
     * @param {any} event - any - this is the event that is triggered when the pagination is changed.
     */
    public onPaginateChange($event: PageEvent) {
      this.pageIndex  = $event.pageIndex;
      this.pageSize   = $event.pageSize;
      this.get(MakeUpExamResponse, $event.pageIndex + 1, $event.pageSize, this.filters);    
    }

    public getRowSelected($event: any):void{
      this.RowSelected = $event;      
    }
  //#endregion


  //#region Methods
  /**
   * This function takes a string as an argument and returns the string in the current language.
   * @param {string} text - The text to translate
   * @returns The translated text.
   */
  private translate(text: string) {
    return this._i18nextPipe.transform(text, { format: 'cap' });
  }
  /**
   * "This function is called when the user clicks on a row in the table, and it sets the data in the
   * modal to the data of the row that was clicked on."
   * </code>
   * @param {MakeUpExamResponse}  - MakeUpExamResponse
   */
  public userDataTab($event: MakeUpExamResponse): void{  
    this._setDataModal($event, 0);
  }

 /**
  * "This function is called when a user clicks on a button in the table. It then calls another
  * function that sets the data in the modal."
  * </code>
  * @param {MakeUpExamResponse}  - MakeUpExamResponse
  */
  public makeUpExamtab($event: MakeUpExamResponse): void {    
    this._setDataModal($event, 1);
  }  

  /**
   * It opens a modal with a header and a body. The header is a component and the body is a component.
   * The header component receives data from the parent component. The body component receives data
   * from the parent component. 
   * @param {any}  - is the data that I'm sending to the modal
   * @param {number} tabIndex - number = 0;
   */
  private _setDataModal($event: any, tabIndex: number):void
  {
    const setData = {
      data: {
        ...$event
      },
      tabIndex : tabIndex,
      tabGroup: 'Extraordinario',
      headerModalDetails: new HeaderModalDetails(
        {
          nombre         :   $event.nombreAlumno,
          identificador  :   $event.identificador,
          usuarioId      :   $event.usuarioId,
          fotografia     :   $event.fotografia,
          estatus        :   $event.estatus,
          programa       :   $event.programa,
          campus         :   $event.campusNombre,
          periodo        :   $event.periodoNombre
        }
      )
    }    
    const dialogo = this._util.abrirDialogoLateral(LateralModalComponent, setData);
    dialogo.afterClosed().subscribe(result => {
      
      this.get(MakeUpExamResponse, this.pageIndex, this.pageSize, this.filters);
      
    })
  }
  //#endregion   


  //#region Excel & Email
  public downloadExcel():void
  {        
    this.filters.makeUpExamDto = (this.RowSelected != null && this.RowSelected.length > 0) ? this.RowSelected : null;

    this._GenericHttpService.downloadExcel(this.filters).subscribe((blob: Blob) => {
      this._generadorExcel.GenerarExcel(blob, "Extraordinarios");      
    })
  }

  public enviarCorreo(): void {    
    var datos;
    if (this.RowSelected.length > 1) {
      datos = this.RowSelected;
    } else {
      datos = this.RowSelected[0];
    }    
    this._dialog.open(ModalEnviarCorreoComponent, { data: datos, disableClose: true });
  }

  public asignacionMasivaExtraordinario(makeUpExamReasonId: number): void {    
    const data = this.RowSelected.map(res => MakeUpExamResponse.mapBackEnd(res))
    const setData = {
      data: [...data],
      makeUpExamReasonId : makeUpExamReasonId,     
    }    
    const dialogo = this._dialog.open(MassAssignmentMakeUpExamComponent, { data: setData, disableClose: true });
    dialogo.afterClosed().subscribe(result => {
      
      this.get(MakeUpExamResponse, this.pageIndex, this.pageSize, this.filters);
     
    })
  }
  
  public openTab(tabIndex: number):void
  {
    const clickedName = this.MakeUpExamResponse.find(t => 
    t.identificador === this.RowSelected[0].identificador &&
    t.alumnoId      === this.RowSelected[0].alumnoId      &&
    t.grupoId       === this.RowSelected[0].grupoId
    );
    this._setDataModal(clickedName, tabIndex);
  }
  //#endregion
}
