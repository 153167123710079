import { CalendarInfoDTO } from "../models/Inscripcion/CalendarInfoDTO.model";

export class GrupoDTO {
    /// <summary>
    /// Id de la institucion a la cual pertenece el grupo
    /// </summary>
    institucionId: string;

    /// <summary>
    /// Id del grupo
    /// </summary>
    grupoId: number;

    /// <summary>
    /// Clave del grupo
    /// </summary>
    clave: string

    /// <summary>
    /// Materias de los grupos
    /// </summary>
    materiaMostrada: string;

    /// <summary>
    /// Periodo id al cual pertenece el grupo
    /// </summary>
    periodoId?: number;

    /// <summary>
    /// Clave del periodo
    /// </summary>
    periodoClave: string;

    /// <summary>
    /// Nombre del periodo segun el id
    /// </summary>
    periodoNombre: string;

    /// <summary>
    /// Id del programa
    /// </summary>
    programaId: number;

    /// <summary>
    /// Clave del programa
    /// </summary>
    programaClave: string;

    /// <summary>
    /// Nombre del programa segun el id
    /// </summary>
    programaNombre: string;

    /// <summary>
    /// Id del campus
    /// </summary>
    campusId: number;

    /// <summary>
    /// Clave del campus
    /// </summary>
    campusClave: string;

    /// <summary>
    /// Nombre del campus segun el id
    /// </summary>
    campusNombre: string;

    /// <summary>
    /// Id del profesor que lidera el grupo
    /// </summary>
    profesorId?: number;

    /// <summary>
    /// Nombre del profesor
    /// </summary>
    profesorNombre: string;

    /// <summary>
    /// Nombre del aula donde se impartiran las clases
    /// </summary>
    aulaNombre: string;
    /// <summary>
    /// Define si el grupo requiere de un horario
    /// </summary>
    requiereHorario: boolean;

    /// <summary>
    /// Define los dias que tendran clases
    /// </summary>
    horarioCompleto: string;

    /// <summary>
    /// Define el tipo de modalidad que tendra el grupo
    /// </summary>
    modalidad: string;

    /// <summary>
    /// Define si el grupo esta activo
    /// </summary>
    esActivo?: boolean;

    /// <summary>
    /// Define la capacidad del grupo
    /// </summary>
    capacidad?: number;

    /// <summary>
    /// Fecha en la que se creo el grupo
    /// </summary>
    fechaCreacion?: Date;

    /// <summary>
    /// Usuario que creo el grupo
    /// </summary>
    usuarioCreacion?: number;

    /// <summary>
    /// Fecha que se edito el grupo
    /// </summary>
    fechaModificacion?: Date;

    /// <summary>
    /// Usuario que edito el grupo
    /// </summary>
    usuarioModificacion?: number;

    /// <summary>
    /// Clave de la materia
    /// </summary>
    claveMateria: string;

    /// <summary>
    /// Materia inscrita
    /// </summary>
    materiaInscrita: boolean;

    /// <summary>
    /// Obtiene las materias que estan en oferta
    /// </summary>
    materiaOfertaId: number;

    /**
     * Representa las secciomes de un grupo
     */
    calendario: Array<CalendarInfoDTO>;

    blockId: number;
    blockLabelId: number;
    blockName: string;
    blockCode: string;
    blockPeriodoId: number;
    blockCampusId: number;


}
