import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnInit, ViewChild, TemplateRef, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestTypeDTO, SolicitudAdminDto, RequestCategoryDTO } from 'src/app/_models/solicitud-dto';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-solicitud-alumno-modal-edicion',
  templateUrl: './solicitud-alumno-modal-edicion.component.html',
  styleUrls: ['./solicitud-alumno-modal-edicion.component.scss']
})
export class SolicitudAlumnoModalEdicionComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public errorActualizaRequest: string = "";
  public requestID: number = 0;
  public dialogoRefAdver: any;
  public boolCambio = false;
  public edicionForm: FormGroup;
  public tipoRequestList: RequestTypeDTO[] = [];
  public categoryRequestList: RequestCategoryDTO[] = [];
  public descripcionList: RequestTypeDTO;
  public infoCargado: boolean = false;
  displayedColumns: string[] = ['select', 'nombre', 'fecha', 'descarga'];
  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(false, []);
  public documentos: any = [];
  public numDocumentoSeleccionado = 0;

  constructor(public dialogRef: MatDialogRef<SolicitudAlumnoModalEdicionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SolicitudAdminDto,
    private solicitudService: SolicitudService,
    private snackService: SnackService,
    private dialog: MatDialog,
    public util: UtileriasService,
    private traductorService: TraductorService
    ) { 
      this.boolCambio = false;
      this.inicializarForm();
    }

  ngOnInit(): void {
    this.obtenerCategorySolicitud();
    this.iniciarTiposolicitud();
    if(this.data.tipoAccion == AccionEnum.VER) {
      this.solicitudService.obtenerDescripcionSolicitud(this.edicionForm.get('requestCategoryId').value).subscribe((desc: ApiResponse<RequestTypeDTO>) => {
        this.descripcionList = desc.data;
      });
    }
    if(this.data.isLetterCategory && !this.data.directDownload) {
      this.obtenerDocumentos();
    }
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      requestId: new FormControl(this.data?.requestId),
      requestCategoryId: new FormControl(this.data?.requestCategoryId, [Validators.required]),
      requestTypeId: new FormControl(this.data?.requestTypeId, [Validators.required]),
      userId: new FormControl(this.data?.usuarioId),
      name: new FormControl(this.data?.nombre),
      comment: new FormControl(this.data?.comment),
      completed: new FormControl(this.data?.completed),
      institutionResponse: new FormControl(this.data?.institutionResponse),
      directDownload: new FormControl(this.data.directDownload)
    });
    this.edicionForm.valueChanges.subscribe((cambios) => {
      this.boolCambio = true;
    });
  }

  get requestId() { return this.edicionForm.get('requestId'); }
  get requestCategoryId() { return this.edicionForm.get('requestCategoryId'); }
  get requestTypeId() { return this.edicionForm.get('requestTypeId'); }
  get userId() { return this.edicionForm.get('userId'); }
  get name() { return this.edicionForm.get('name'); }
  get comment() { return this.edicionForm.get('comment'); }
  get completed() { return this.edicionForm.get('completed'); }
  get institutionResponse() { return this.edicionForm.get('institutionResponse'); }

  public enviar() {
    switch(this.data.tipoAccion) {
      case AccionEnum.CREAR:
        this.data = Object.assign(this.edicionForm.value);
        this.solicitudService.agregarSolicitud(this.data).subscribe(
          (reqId: SimpleResponse) => {
            if(reqId.success){
              this.solicitudService.invocarMetodoObtenerSolicitudes();             
              this.snackService.mostrarSnackBack('Se ha enviado la solicitud');
              this.boolCambio = false;
              this.inicializarForm();
              this.dialogRef.close(null);
            } else {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la solicitud. Por favor, intente de nuevo más tarde.');
            }
          }, error => {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la solicitud. Por favor, intente de nuevo más tarde.');
          }
        );
      break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionForm.value);
        this.data.tipoAccion = 2;
        this.solicitudService.modificarSolicitud(this.data).subscribe(
          (editado: SimpleResponse) => {
            if(editado.success) {
              this.solicitudService.invocarMetodoObtenerSolicitudes();
              this.snackService.mostrarSnackBack('Se ha modificado la información');
              this.boolCambio = false;
            } else {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al modificar la solicitud. Por favor, intente de nuevo más tarde.');
            }
          }, error => {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al modificar la solicitud. Por favor, intente de nuevo más tarde.');
          }
        );
      break;
    } 
  }

  public obtenerDescripcionSolicitud(typeId) {
    this.solicitudService.obtenerDescripcionSolicitud(typeId).subscribe((desc: ApiResponse<RequestTypeDTO>) => {
      this.descripcionList = desc.data;
    });
  }

  public obtenerTiposSolicitud(categoriaID: number) {
    this.tipoRequestList = [];
    this.solicitudService.obtenerSolicitudPorCategoria(categoriaID).subscribe((tipos: ApiResponse<RequestTypeDTO[]>) => {
      this.tipoRequestList = tipos.data;
    });
  }

  public iniciarTiposolicitud() {
    this.tipoRequestList = [];
    this.solicitudService.obtenerSolicitudPorCategoria(this.data.requestCategoryId).subscribe((tipos: ApiResponse<RequestTypeDTO[]>) => {
      this.tipoRequestList = tipos.data;
    });
  }

  public obtenerCategorySolicitud() {
    this.solicitudService.obtenerCategoriasActivas().subscribe((categories: ApiResponse<RequestCategoryDTO[]>) => {
      this.categoryRequestList = categories.data;
    });
  }

  public cerrarModalValidacion() {
    if(this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }
  //public PDFSubscription: Subscription;
  public descargarArchivoDirecto(requestTypeId: number, usuarioId?: number){
    // this.PDFSubscription = this.solicitudService.descargarPdfAsBlob(requestTypeId, usuarioId).subscribe(
    //   (res:Blob) => {
    //   let filename: string = "Periodos.xlsx";
    //     if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
    //       window.navigator.msSaveOrOpenBlob(res, filename);
    //     } else {
    //       let a: HTMLAnchorElement = document.createElement("a");
    //       a.style.display = "none";
    //       document.body.appendChild(a);
    //       let blob: Blob = new Blob([res], { type: 'application/xlsx' });
    //       a.href = window.URL.createObjectURL(blob);
    //       a.download = filename;
    //       a.click();
    //     }
    //     this.PDFSubscription.unsubscribe();
    // })
    this.solicitudService.descargarPdfAsBlob(requestTypeId, usuarioId).subscribe(
      (respuesta: Blob) => {
        if (!!respuesta) {
          let url: string = window.URL.createObjectURL(respuesta);
          let anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `${new Date().toISOString().replace(/[^0-9]/gi, "")}.pdf`;
          anchor.click();
          window.URL.revokeObjectURL(url);
          let mensaje: string = this.traductorService.translate('descargaArchivo');
          this.snackService.mostrarSnackBack(mensaje);
        } else {
          this.snackService.mostrarSnackBack("Ha ocurrido un error al descargar el archivo. Por favor intente de nuevo más tarde.");
        }
      }, (err: any) => {
        this.snackService.mostrarSnackBack("Ha ocurrido un error al descargar el archivo. Por favor intente de nuevo más tarde.");
      }
    )
  }

  public descargarArchivo(requestFileId: number) {
    this.solicitudService.obtenerArchivo(requestFileId).subscribe(
      (respuestaRecurso: ApiResponse<any>) => {
        var documento = this.base64ToArrayBuffer(respuestaRecurso.data.path);
        let mime: string = respuestaRecurso.data.name.indexOf(".pdf") >= 0 ? "application/pdf" : "application/octect-stream";
        let a: HTMLAnchorElement = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([documento], { type: mime });
        a.href = window.URL.createObjectURL(blob);
        a.download = respuestaRecurso.data.name;
        a.click();
      }
    )
  }

  base64ToArrayBuffer(base64): ArrayBufferLike {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public obtenerDocumentos() {
    this.solicitudService.obtenerDocumentosSolicitud(this.data.requestId).subscribe(
      (documentos) => { 
        if(documentos.success) {
          if(documentos.data.length > 0) {
            this.documentos = documentos.data;
            this.dataSource = new MatTableDataSource(documentos.data);
            this.selection = new SelectionModel(false, []);
            this.infoCargado = true;
          }
        }
      });
  }

  /**
   * Metodo interno del data table de angular material
   * @returns 
   */
  isAllSelected(): boolean {
    this.numDocumentoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numDocumentoSeleccionado === numRows;
  }

  /**
   * Metodo interno del data table de angular material
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.documentoId + 1}`;
  }

  /**
   * Metodo interno del data table de angular material
   */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row))
  }
}
