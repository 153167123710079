import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { FormatoProgramaDto } from '@app/_models/FormatoProgramaDto';
import { baseUrl, environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormatoProgramaService {

  constructor(private httpClient: HttpClient) { }

  /**
 * Metodo para obtener catalogo de formato programa
 * @returns 
 */
  public obtenerFormatoPrograma(): Observable<ApiResponse<FormatoProgramaDto[]>> {
    return this.httpClient.get<ApiResponse<FormatoProgramaDto[]>>(`${environment.enves[baseUrl].apiUrl}/FormatoPrograma/ObtenerFormatoPrograma`);
  }
}
