import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compra-cursos-compra-realizada',
  templateUrl: './compra-cursos-compra-realizada.component.html',
  styleUrls: ['./compra-cursos-compra-realizada.component.scss']
})
export class CompraCursosCompraRealizadaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
