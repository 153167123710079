/**
 * Autor Carlos Alberto
 * Fecha creación: 5/12/2021
 */
export class NullHelper{
    /**
     * Comprueba si el valor que se ha pasado es Undefined y en caso de ser así retorna el valor por defecto, pero si no lo es, retorna el valor en sí
     * @param parameter 
     * @param defaultValue 
     * @returns El valor correspondiente
     */
    public static IsUndefined<T>(parameter: T, defaultValue: T): T{
        return parameter == null || parameter === undefined ? defaultValue: parameter;
    }

    /**
     * Comprueba si una propiedad de tipo objeto existe, si es null, entonces retorna el valor por defecto
     * pero si la propiedad de tipo objeto tiene algo en memoria, evalúa dicha propiedad tipo objeto y valida si 
     * el atributo indicado existe, si existe lo retorna como valor, y en caso de no existir retorna el valor por defecto
     * @param objectPropertie 
     * @param propetieName 
     * @param defaultValue 
     * @returns 
     */
    public static IsPropertyUndefined<T, G>(objectPropertie: G, propetieName: string, defaultValue:T): T{
        if (objectPropertie === null){
            return defaultValue;
        }
        const propiedadesABuscar:Array<string> = propetieName.split(".");
        let value = objectPropertie[propiedadesABuscar[0]];
        
        for(let i = 1 ; i < propiedadesABuscar.length; i++){
            if(value === null)
                return defaultValue;
            value = value[propiedadesABuscar[i]];
        }
        return value === null || value === undefined ? defaultValue: value;
    }
}