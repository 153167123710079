export class ExisteMateriaSeriada{
    /// <summary>
    /// Define si la materia tiene seriacion
    /// </summary>
    existeMateriaSeriada: boolean;

    /// <summary>
    /// Obtiene el nombre de las materias que tiene seriacion 
    /// </summary>
    materiasSeriadas: string;
}