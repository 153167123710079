import { CONTACT_INFORMATION_MODEL } from '../../../../../core.domain/models/user/contact-information.model';
import { TipoTelefono } from 'src/app/_models/tipo-telefono';
import { EntidadFederativa } from '@app/_models/entidad-federativa';
import { Pais } from '@app/_models/pais';
import { DireccionService } from 'src/app/_services/direccion.service';
import { TipoDireccion } from 'src/app/_models/tipo-direccion';
import { MessageErrors } from '../../../../../core.application/functions/messageErros.function';
import { CONTACT_INFORMATION } from '../../../../../core.application/validators/user-data/contact-information.validators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { FORM_VALID_MODEL } from '@app/clean-architecture/core.domain/models/user/form-valid-model';

@Component({
  selector: 'app-contact-information-form',
  templateUrl: './contact-information-form.component.html',
  styleUrls: ['./contact-information-form.component.scss']
})
export class ContactInformationFormComponent implements OnInit, OnDestroy, OnChanges {

  //#region  Input, Output & ViewChild
  @Input() data: CONTACT_INFORMATION_MODEL;

  @Input() cancelledFields: string[] = [];
  @Input() disabledledFields: string[] = [];

  @Output() formValidity: EventEmitter<FORM_VALID_MODEL> = new EventEmitter(); 
  @Output() formData = new EventEmitter<CONTACT_INFORMATION_MODEL>();
  //#endregion

    //#region Subscriptions
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Properties
  public contactInformationForm: FormGroup = this.formBuilder.group(CONTACT_INFORMATION);
  public ErrorMsg: any;
  private status: boolean = false;

  public tipoDirecciones: Array<TipoDireccion>;
  public paises: Array<Pais>;
  public entidadesFederativas: Array<EntidadFederativa>;
  public tipoDeTelefonos: Array<TipoTelefono>;

  public  deletedInputs = {
    correo                : false,
    tipoDireccionId       : false,
    paisId                : false,
    entidadFederativaId   : false,
    ciudad                : false,
    calle                 : false,
    numeroExterior        : false,
    numeroInterior        : false,
    colonia               : false,
    codigoPostal          : false,
    tipoTelefonoId        : false,
    numero                : false,
    extension             : false
  };
  //#endregion

  constructor(
    private formBuilder: FormBuilder,
    private _direccionService: DireccionService,
  ) { 
    this.setErrors();
  }

  ngOnInit(): void {    
    this._getAllMethods();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] !== undefined) {
      this._setData();
    }
  }

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }


  //#region setData
  private _setData():void
  {    
    this.contactInformationForm.setValue(this.data);
    //this.contactInformationForm.markAllAsTouched();
    // this.contactInformationForm.get('correo')?.setValue(this.data.correo);
    // this.contactInformationForm.get('tipoDireccionId')?.setValue(this.data.tipoDireccionId);
    // this.contactInformationForm.get('paisId')?.setValue(this.data.paisId);
    // this.contactInformationForm.get('entidadFederativaId')?.setValue(this.data.entidadFederativaId);
    // this.contactInformationForm.get('ciudad')?.setValue(this.data.ciudad);
    // this.contactInformationForm.get('calle')?.setValue(this.data.calle);
    // this.contactInformationForm.get('numeroExterior')?.setValue(this.data.numeroExterior);
    // this.contactInformationForm.get('numeroInterior')?.setValue(this.data.numeroInterior);
    // this.contactInformationForm.get('colonia')?.setValue(this.data.colonia);
    // this.contactInformationForm.get('codigoPostal')?.setValue(this.data.codigoPostal);
    // this.contactInformationForm.get('tipoTelefonoId')?.setValue(this.data.tipoTelefonoId);
    // this.contactInformationForm.get('numero')?.setValue(this.data.numero);
    // this.contactInformationForm.get('extension')?.setValue(this.data.extension);
  }
  //#endregion

  //#region Helpers
  /**
   * The function sets the error message for the form
   */
  public setErrors(): void {
    this.ErrorMsg = MessageErrors.setErrorMessage(this.contactInformationForm);
  }

  /**
   * If the cancelledFields array has a length greater than 0, then for each field in the
   * cancelledFields array, if the contactInformationForm has a control with the name of the field, then clear the
   * validators for that control and add the field to the deletedInputs object.
   */
   _deleteCancelledFields(): void {
    if (this.cancelledFields.length > 0) {
      this.cancelledFields.forEach(field => {
        if (this.contactInformationForm.get(field)) {
          this.contactInformationForm.get(field)?.clearValidators();
          this.deletedInputs[field] = true;
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  /**
   * _disabledCancelledFields() is a function that disables and clears validators for fields that are
   * not required
   */
  _disabledCancelledFields(): void {
    if (this.disabledledFields.length > 0) {
      this.disabledledFields.forEach(field => {
        if (this.contactInformationForm.get(field)) {
          this.contactInformationForm.get(field)?.clearValidators();
          this.contactInformationForm.get(field)?.disable();
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  private _subscribeToForm(): void {

    this.contactInformationForm.markAllAsTouched();
    this._Emit();
    this.Subscription.add(
      this.contactInformationForm.statusChanges
      .subscribe(status => {
        this.setErrors();
        this.status = status === 'VALID';
        this._Emit();
      })
    );

    this.Subscription.add(
      this.contactInformationForm
      .get('paisId')
      ?.valueChanges
      .subscribe(value => {
        this._getStatsByCountry(value);        
      })
    );
  }

  private _Emit():void
  {
    this.formValidity.emit(
      new FORM_VALID_MODEL(
        2,
        'ContactInformationFormComponent',
        this.status
      )
    );
    this.formData.emit(
      new CONTACT_INFORMATION_MODEL(
        this.contactInformationForm.get('correo')?.value,
        this.contactInformationForm.get('tipoDireccionId')?.value,
        this.contactInformationForm.get('paisId')?.value,
        this.contactInformationForm.get('entidadFederativaId')?.value,
        this.contactInformationForm.get('ciudad')?.value,
        this.contactInformationForm.get('calle')?.value,
        this.contactInformationForm.get('numeroExterior')?.value,
        this.contactInformationForm.get('numeroInterior')?.value,
        this.contactInformationForm.get('colonia')?.value,
        this.contactInformationForm.get('codigoPostal')?.value,
        this.contactInformationForm.get('tipoTelefonoId')?.value,
        this.contactInformationForm.get('numero')?.value,
        this.contactInformationForm.get('extension')?.value
      )
    );
  }
  //#endregion


  //#region Methods

  private _getAllMethods():void
  {
    this._subscribeToForm();
    this._getDirectionsType();
    this._getCountries();
    this._getTelephoneType();
    this._deleteCancelledFields();
    this._disabledCancelledFields();
  }

  private _getDirectionsType(): void {
    this.Subscription.add(
      this._direccionService.obtenerTipoDireccion().subscribe(
        (tipoDirecciones: Array<TipoDireccion>) => {
          this.tipoDirecciones = tipoDirecciones;
        })
    );
  }

  private _getCountries(): void {
    this.Subscription.add(
      this._direccionService.obtenerPaises().subscribe(
        (paises: Array<Pais>) => {
          this.paises = paises;
        })
    );
  }

  private _getStatsByCountry(paisld: number): void {
    if (this.paisld?.valid) {
      this.Subscription.add(
        this._direccionService.obtenerEntidadesFederativas(paisld).subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
          this.entidadesFederativas = entidadesFederativas;
        })
      );
    }
  }

  private _getTelephoneType(): void {
    this.Subscription.add(
      this._direccionService.obtenerTipoTelefono().subscribe(
        (tipoDeTelefonos: Array<TipoTelefono>) => {
          this.tipoDeTelefonos = tipoDeTelefonos;
        })
    );
  }
  //#endregion

  //#region Controls  

  get paisld() {
    return this.contactInformationForm.get('paisId');
  }
  //#endregion
}