import { TraductorService } from './../../_services/traductor.service';
import { Component, EventEmitter, Output, ViewChild, TemplateRef, ChangeDetectorRef, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NavigationService } from '@app/_services/navigation.service';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { environment, baseUrl } from '../../../environments/environment';
import { InsitucionService } from 'src/app/_services/insitucion.service';
import { Title } from "@angular/platform-browser";
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { RegistrarSolicitanteComponent } from 'src/app/admisiones-alumno/registrar-solicitante/registrar-solicitante.component';
import { RedirectRequest } from '@azure/msal-browser';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { EstatusUsuarioEnum } from '../../_models/estatusUsuario.enum';
import { UserRolPermissionDto } from '../../_models/UserRolPermissionDto';
import { UsuarioService } from '@app/_services/usuario.service';
import { SnackService } from '@app/services/snack-service.service';
import { ApiResponse } from '../../models/api/ApiRespose.model';
import { LanguageService } from '@app/clean-architecture/infrastructure/services/language/language.service';
import { Mapping } from '@app/clean-architecture/core.domain/class/mapping.class';
import { Language } from '@app/clean-architecture/core.domain/models/language/language.model';
import i18next from 'node_modules/i18next';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  public tenantId: string = environment.enves[baseUrl].institucion;
  public infoInstitucion: any;
  language: string = 'en';
  languages: string[] = ['es', 'en'];

  @Output() toggleSidenav = new EventEmitter<void>();

  private returnUrl = '/';
  public isAuthenticated: boolean = false;
  public userName: string = "";
  public correoUser: string = "";
  public base64Image: any;
  public base64Logo: any;
  public imagePath: any;
  public imagePathLogo: any;
  public identificador: string;
  datosUsuarioSubscription: Subscription;
  public usuario: any;
  programaUsuario: string = "";

  public esAdmin: boolean = false;
  public IsProfesor: boolean = false;
  public isAlumno: boolean = false;
  public usuarioRol: any;
  public infoCargada: boolean = false;
  public respuestaRol: any;

  public infoInstitucionSer$: Observable<any>;
  public infoInstitucionSer: any = null;

  public colorBarra: any;
  public colorButtton: any;
  public userPermision: UserRolPermissionDto;

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;

  constructor(
    private _UsuarioService: UsuarioService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private msalService: MsalService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private _cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private institucionService: InsitucionService,
    private titleService: Title,
    public util: UtileriasService,
    private snackService: SnackService,
    private traductorService: TraductorService,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private admisionesService: AdmisionesService,
    private _LanguageService: LanguageService
  ) {
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {

        this.returnUrl = event.url;
      }
    });

    /**
     *
      this.authenticationService.currentUser.subscribe(user => {
      this.esAdmin = false;
      this.IsProfesor = false;
      this.isAlumno = false;
      let cuenta = this.msalService.instance.getActiveAccount();
      this.isAuthenticated = !!cuenta;
      this.userName = "";
      if (this.isAuthenticated) {
        this.userName = cuenta.name;

        this.identificador = cuenta.username.substring(0, cuenta.username.indexOf('@'));
        sessionStorage.setItem('identificador', this.identificador);
        this.authenticationService.obtenerDatosRol(this.identificador).subscribe(data => {
          this.usuarioRol = data;
          this.infoCargada = true;
        });
      }
    });

    */

    this.infoInstitucionSer$ = this.admisionesService.getInstitucion$();
    this.infoInstitucionSer$.subscribe(
      (info: any) => {
        this.infoInstitucionSer = info;
      });


    this.navigationService.isMobile.subscribe(data => {
      this.isMobile = data;
      return;
    });
    if (this.router.isActive('/signout', false)) {
      this.authenticationService.signout();
      this.router.navigate(['/']);
    }
    this.authenticationService.currentUser.subscribe(user => {
      let cuenta = this.msalService.instance.getActiveAccount();
      this.isAuthenticated = !!cuenta;
      this.esAdmin = false;
      this.IsProfesor = false;
      this.isAlumno = false;
      this.userName = "";
      if (this.isAuthenticated) {
        this.renderizarFoto();
        this.identificador = cuenta.username.substring(0, cuenta.username.indexOf('@'));
        sessionStorage.setItem('identificador', this.identificador);
        this.authenticationService.obtenerDatosRol(this.identificador).subscribe(data => {
          this.usuarioRol = data;
          this.infoCargada = true;
        });
        this.authenticationService.obtainPermission(this.identificador).subscribe((response: ApiResponse<UserRolPermissionDto>) => {
          this.userPermision = response.data;
          console.log("this.userPermision", this.userPermision)
          if (this.userPermision.roles.length > 0) {
            this.authenticationService.setUserRol(this.userPermision.roles[0].roleID);
            let permisos = JSON.stringify(this.userPermision);
            sessionStorage.setItem('userPermission', permisos);
          }
        });
        this.datosUsuarioSubscription = this.authenticationService.obtenerDatosUsuario(this.identificador).subscribe(data => {
          this.usuario = data;
          this.base64Image = 'data:image/png;base64,' + this.usuario.fotografia;
          localStorage.setItem('fotoPerfil', this.base64Image);
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
          this.admisionesService.validarPagoColegiatura().subscribe((respuesta: any) => {
            const cursoIdCarrito = localStorage.getItem('cursoIdCarrito');
            const rutaCarrito = localStorage.getItem('rutaCarrito');
            if (cursoIdCarrito !== null && rutaCarrito !== null) {
              this.router.navigate([`/${rutaCarrito}`]);
              localStorage.removeItem("cursoIdCarrito");
              localStorage.removeItem("rutaCarrito");
            } else {
              this.respuestaRol = respuesta;
              if (!respuesta.noExisteAlumno && (respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.candidato || respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.prospecto || respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.admitido)) {
                if (this.infoInstitucionSer.academicManagementModelId == 1) {
                  this.router.navigate(['/']);
                  setTimeout(() => {
                    this.router.navigate(['/admisiones']);
                  }, 100);
                }
              } else if (!respuesta.noExisteAlumno) {
                if (window.location.pathname === "/dashboard-alumno" || window.location.pathname === "/") {
                  this.router.navigate(['/dashboard-alumno']);
                }
              } else {
                if (window.location.pathname === "/dashboard" || window.location.pathname === "/") {
                  this.router.navigate(['/']);
                  setTimeout(() => {
                    this.router.navigate(['/dashboard']);
                  }, 100);
                }
              }
            }




          })
        })
        //this.userName = cuenta.name;
        this.Me();
        this.correoUser = cuenta.username;
      }
    })
  };

  ngOnInit(): void {        
    this.getLanguagesByInstitution(Language);
    this.obtenerInformacionInstitucion();
    this.i18NextService.events.initialized.subscribe((e) => {
      if (e) {
        this.updateState(this.i18NextService.language);
      }
    });
    if (this.institucionService.invMetodoObtenerFotoSubscription == undefined) {
      this.institucionService.invMetodoObtenerFotoSubscription = this.institucionService.invocarObtenerFoto.subscribe(() => {
        this.obtenerFoto();
      })
    }
  }

  changeLanguage(lang: string) {
    if (lang !== this.i18NextService.language) {
      this.i18NextService.changeLanguage(lang).then(x => {
        this.updateState(lang);
        this.setLanguage(lang);
        document.location.reload();
      });
    }
  }

  private updateState(lang: string) {
    this.language = lang;
  }

  public obtenerInformacionInstitucion() {
    this.institucionService.obtenerInformacionInstitucion(this.tenantId).subscribe(value => {
      this.infoInstitucion = value;
      this.colorBarra = this.infoInstitucion.colorBar;
      this.colorButtton = this.infoInstitucion.colorButton;
      document.documentElement.style.setProperty('--first-color', this.colorButtton);
      this.titleService.setTitle(this.infoInstitucion.nombre + " -Link4Campus");
      if (this.infoInstitucion.logoFile) {
        this.base64Logo = 'data:image/png;base64,' + this.infoInstitucion.logoFile;
        // this.imageUsuarioEditar = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Logo);
        this.imagePathLogo = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Logo);
      }
    })
  }

  public obtenerFoto() {
    this.datosUsuarioSubscription = this.authenticationService.obtenerDatosUsuario(this.identificador).subscribe(data => {
      this.usuario = data;
      this.base64Image = 'data:image/png;base64,' + this.usuario.fotografia;
      localStorage.setItem('fotoPerfil', this.base64Image);
      this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
    });
  }


  public isMobile: boolean = false;

  public isAdmin() {
    return this.authenticationService.currentUserValue.IsAdmin;
  }

  public toggleMenu() {
    this.navigationService.isToggledMenu.next(!this.navigationService.isToggledMenu.value);
  }

  public onProfile() {
    this.router.navigate(['/perfil']);
  }

  public passwordChange() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '320px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.snackBar.open("Se ha actualizado tu contraseña.", "OK", { duration: 5000 });
      };
    });
  }

  /**
   * Funcion para hacer el cambio de rol
   * @param rolId 
   */
  public rolSeleccionado(rolId: number): void {
    console.log("ROL", rolId)
    this.authenticationService.setUserRol(rolId);
  }

  public isLogin() {
    return this.route.component == "LoginComponent";
  }

  public login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  public logout() {

    this.navigationService.isToggledMenu.next(false);
    this.authenticationService.logout();
    this.router.navigate(['/home']);
  }
  public imagePath3;
  public imgURL: any;
  public message: string;
  public eventImage: any;
  public dialogoRefAdver: any;
  get fotografia() { return this.imagenUsuarioForm.get('fotografia'); }

  public imagenModal() {
    this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
  }
  public cerrarModalImagen(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();

    } else {
      this.dialogoRefAdver.close();
      this.onFileChangePerfil(this.eventImage);
      this.enviarImagen();
      this.imgURL = "";
    }
  }
  public imagePath2: any;
  public imagenUsuarioForm: FormGroup;

  public onFileChangePerfil(event) {
    const reader = new FileReader();
    if (event && event.length > 0) {
      this.imagePath2 = event;
      this.imagePath = event;
      const [file] = event;

      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });

      reader.readAsDataURL(event[0]);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
        this.imagePath2 = reader.result;
      }
      this.eventImage = event;

    }
  }
  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.onload = () => {
        this.usuario.fotografia = reader.result;
        this._cd.markForCheck();
        this.imagenUsuarioForm.patchValue({
          fotografia: reader.result
        });
      };
      reader.readAsDataURL(file);
    }

  }
  public enviarImagen() {
    let imagenForm = Object.assign(this.imagenUsuarioForm.value);
    let imagen: any = {
      Identificador: this.identificador,
      fotografia: imagenForm.fotografia,
      fotografiaFile: imagenForm.fotografiaFile,
    };
    this.authenticationService.editarImagen(imagen).subscribe(
      imagenData => {
        if (imagenData) {
          this.snackBar.open('Se ha guardado la imagen correctamente.', 'OK', { duration: 5000 });

        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      }
    )

  }
  preview(event) {
    if (event.length === 0)
      return;
    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath3 = event;
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.eventImage = event;
  }
  public isProspect: boolean = false;
  public renderizarFoto() {
    this.imagenUsuarioForm = new FormGroup
      ({
        Identificador: new FormControl(this.identificador),
        fotografia: new FormControl(''),
        fotografiaFile: new FormControl(''),
      });

  }

  public redirigirDashboard() {
    if (!this.respuestaRol.noExisteAlumno && (this.respuestaRol.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.candidato || this.respuestaRol.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.prospecto)) {
      if (this.infoInstitucionSer.academicManagementModelId == 1) {
        this.router.navigate(['/']);
        setTimeout(() => {
          this.router.navigate(['/admisiones']);
        }, 100);
      }
    } else if (!this.respuestaRol.noExisteAlumno) {
      this.router.navigate(['/dashboard-alumno']);
    } else {
      this.router.navigate(['/']);
      setTimeout(() => {
        this.router.navigate(['/dashboard']);
      }, 100);
    }
  }

  /**
   * Metodo que abre un modal para el registro de un nuevo usuario
   */
  public registrarUsuario(): void {
    this.util.abrirDialogoLateral(RegistrarSolicitanteComponent);
  }

  private async Me() {
    var _Usuario = await this._UsuarioService.Me();
    this.userName = `${_Usuario.nombre ?? ""} ${_Usuario.segundoNombre ?? ""} ${_Usuario.apellidoPaterno ?? ""} ${_Usuario.apellidoMaterno ?? ""}`;
  }
  ngOnDestroy() {
    if (this.datosUsuarioSubscription != null) { this.datosUsuarioSubscription.unsubscribe(); }

  }


  //#region Language  
  public LanguagesByInstitution: Language[] = [];

  public getLanguages<T extends Mapping>(_class: T):void {        
    this.Subscription.add(
      this._LanguageService.getLanguages(_class).subscribe((response: ApiResponse<any[]>) => {    
        this.LanguagesByInstitution = response.data;
      })
    ); 
  }

  public getLanguagesByInstitution<T extends Mapping>(_class: T):void {        
    this.Subscription.add(
      this._LanguageService.getLanguagesByInstitution(_class).subscribe((response: ApiResponse<any[]>) => {    
        this.LanguagesByInstitution = response.data;        
        if (this.LanguagesByInstitution.length === 0) {
          this.getLanguages(Language);          
        }else if (this.LanguagesByInstitution.length === 1) {
          const languageDefault = this.LanguagesByInstitution[0];
          if (languageDefault !== null) {            
            this.i18NextService.changeLanguage(languageDefault.keyCode).then(x => {
              this.updateState(languageDefault.keyCode);              
            });
          }
        }else if (this.LanguagesByInstitution.length > 1 && !this.LanguagesByInstitution[0].isAuth) {
          console.log("this.LanguagesByInstitution",this.LanguagesByInstitution);
          const languageDefault = this.LanguagesByInstitution.find(x => x.isDefault === true);
          if (languageDefault !== null && i18next.language !== languageDefault.keyCode && this.getLanguage() === null) {            
            this.i18NextService.changeLanguage(languageDefault.keyCode).then(x => {
              this.updateState(languageDefault.keyCode);
              document.location.reload();
            });
          }
        }{
          // const languageDefault = null;//this.LanguagesByInstitution.find(x => x.isDefault === true);
          // if (languageDefault !== null) {            
          //   this.i18NextService.changeLanguage(languageDefault.keyCode).then(x => {
          //     this.updateState(languageDefault.keyCode);              
          //   });
          // }
        }
        //console.log("getLanguage", this.getLanguage());
      })
    ); 
  }

  public setLanguage(_Language: string) {
    sessionStorage.setItem('Language', _Language);
  }
  public getLanguage(): string {
    return sessionStorage.getItem('Language');
  }
  //#endregion
}
