import { PageEvent } from '@angular/material/paginator';
import { DataTableColumn } from './../../../../../core.domain/models/data-table/data-table-column.models';
import { clone } from './../../../../../core.application/functions/clone.functions';
import { DataTable } from './../../../../../core.domain/models/data-table/data-table.models';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-table-body',
  templateUrl: './table-body.component.html',
  styleUrls: ['./table-body.component.scss']
})
export class TableBodyComponent implements OnChanges {

  @Input() public dataTable: DataTable<unknown>;
  @Input() public tableColumns: DataTableColumn[];
  @Input() public isLoading: boolean = false;
  @Input() public localPagination: boolean = false;

  @Output() public actionEmitter: EventEmitter<{buttonName: string, data: number}> = new EventEmitter();
  @Output() public rowSelectedEmitter: EventEmitter<any[]> = new EventEmitter();

  public innerDataTable   : DataTable<any>;
  public rows             : unknown[][] = [];
  public innerTableColumn : any[] = [];
  public rowSelected      : any[] = [];

  constructor() {       
  }

  ngOnChanges(changes: SimpleChanges): void {       
    /* The above code is checking if the dataTable has changed. If it has, it will set the isLoading to
    false, set the innerDataTable to the dataTable, set the rows to the getRows() function, and set
    the innerTableColumn to the tableColumns. */
    if (changes['dataTable']?.currentValue) {
      this.isLoading = false;
      this.innerDataTable = clone(this.dataTable);

      this.rows = [];
      this.innerTableColumn = [];

      const columnsNames = this.tableColumns.map(x  => x.prop);

      

      this.getRows().forEach((row: any) => {
        row = row.filter(r => columnsNames.includes(r[0]));        

        this.rows = [...this.rows, row];
      });

      this.rows[0].forEach((row: any) => {
        const prop = row[0];
        let col = this.tableColumns.filter(el2 => el2.prop === prop)
        this.innerTableColumn = [...this.innerTableColumn, col[0]];
      })
      this.innerTableColumn = this.innerTableColumn.filter(element => {
        return element !== undefined;
     });     
          
    }

    if (!this.localPagination) {
      this.page_size = 'all';
    }    
    
  }

  /**
   * It returns an array of arrays, where each inner array is a row of data from the table
   * @returns An array of arrays of key-value pairs.
   */
  getRows(): any[][] {    
    return this.innerDataTable.data.map(el => Object.entries(el.formattedTableRow));
  }


  /**
   * It emits an event with the button name and the data of the row that was clicked
   * @param {string} buttonName - The name of the button that was clicked.
   * @param {number} index - The index of the row that was clicked
   */
  clickedAction(buttonName: string, index: number): void {    
    this.actionEmitter.emit({ buttonName, data: this.innerDataTable.data[index] });
  }

  
 /**
  * For each row, for each column, set the isChecked property to the value of the checkbox that was
  * clicked.
  * @param {MatCheckboxChange} ev - MatCheckboxChange - this is the event that is triggered when the
  * checkbox is clicked.
  */
  public checkAllCheckBox(ev: MatCheckboxChange):void {
    //this.rows.forEach(x => x.forEach( (y: any) => y[1].isChecked = ev.checked))
    this.rows.forEach((x: any) => x[0][1].isChecked = ev.checked);
    this.getRowSelected();
  }

  /**
   * If every row has every checkbox checked, then return true, otherwise return false.
   * @returns The return value is a boolean.
   */
  public isAllCheckBoxChecked(): boolean {    
    //return this.rows.every(x => x.every((y: any) => y[1].isChecked));
    this.getRowSelected();
    return this.rows.every((x: any) => x[0][1].isChecked);
  }

 /**
  * The function filters the rows array and returns a new array of rows that have a checkbox in the
  * first column that is checked
  */
  public getRowSelected():void{
    let row = this.rows.filter((x: any)=> x[0][1].isChecked);
    if (row.length > 0) {      
      this.rowSelected = row.map((x: any)=> x[0][1].rawData);

      //const data = this.innerDataTable.data[index]
    }
    else
    {
      this.rowSelected = [];
    }
    this.rowSelectedEmitter.emit(this.rowSelected);
  }
  
  //#endregion

  //#region LocalPagination
  public page_size: number | string = 5;

  public page_number: number = 1;

  publicpageSizeOptions: number[] = [5, 10, 20, 50, 100];
  
  public handlePage(e: PageEvent):void
  {    
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }
  //#endregion
}
