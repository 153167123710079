import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-carga-documentos',
  templateUrl: './form-carga-documentos.component.html',
  styleUrls: ['./form-carga-documentos.component.scss']
})
export class FormCargaDocumentosComponent implements OnInit {
  public documentoForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.inicializaFrom();
  }

  public inicializaFrom() {
    this.documentoForm = new FormGroup({
      fotografia: new FormControl(null, [Validators.required]),
      comprobante: new FormControl(null, [Validators.required]),
      ine: new FormControl(null, [Validators.required]),
      estudios: new FormControl(null, [Validators.required])
    }
    );
  }

  get fotografia() { return this.documentoForm.get('fotografia'); }
  get comprobante() { return this.documentoForm.get('comprobante'); }
  get ine() { return this.documentoForm.get('ine'); }
  get estudios() { return this.documentoForm.get('estudios'); }

  public enviar() { }

  public Siguiente() { }

}
