// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const baseUrl = String(window.location.origin);

export const environment = {
  production: true,
  //apiUrl: "https://link4campus-demo-api.azurewebsites.net/api",
  // apiUrl: "https://localhost:44382/api",
  // institucion: "ea4f48c3-ccf9-4826-8729-e5f4ec007673",
  appInsights: {
    connectionString: 'InstrumentationKey=e153e5bd-74a3-41a5-a1d5-4606ae7fe7de;IngestionEndpoint=https://westus2-1.in.applicationinsights.azure.com/'
  },

  "enves": {
    "https://localhost:4200": {
      apiUrl: "https://localhost:44382/api",
      // institucion: "f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
      //original:
      //ea4f48c3-ccf9-4826-8729-e5f4ec007673
      institucion: "ea4f48c3-ccf9-4826-8729-e5f4ec007673",
      defaultLang: "en",
      "AzureAD": {
        "auth": {
          // "clientId": "2a85e1ad-5456-403c-8565-7add8f61845a",
          // "authority": "https://login.microsoftonline.com/f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
          // Original
          "clientId": "dcd35d7b-8e4e-48ce-b249-03b745a4c9f6",
          "authority": "https://login.microsoftonline.com/ea4f48c3-ccf9-4826-8729-e5f4ec007673",
          "validateAuthority": true,
          "redirectUri": "https://localhost:4200/",
          "postLogoutRedirectUri": "https://localhost:4200/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://localhost:4200",
            // "resourceScope": "api://97e84c98-0bcc-40ec-a549-2c8f9a1399f7/access_as_user"
            // Original
            "resourceScope": "api://dbe31fea-1430-4df4-b608-9aa1545791b4/access_as_user"
          }
        }
      }
    },
    "http://localhost:4200": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "7d5cb3e1-3415-4125-b089-a19eb60e1824",
      "AzureAD": {
        "auth": {
          "clientId": "cd7be8b7-fea8-4f11-93f3-7e38f3d420bf",
          "authority": "https://login.microsoftonline.com/7d5cb3e1-3415-4125-b089-a19eb60e1824",
          "validateAuthority": true,
          "redirectUri": "http://localhost:4200/",
          "postLogoutRedirectUri": "http://localhost:4200/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "http://localhost:4200",
            "resourceScope": "api://af20bcb2-6237-4b4f-88b9-c386f669db40/access_as_user"
          }
        }
      }
    },
    "https://dev.l4c.app": {
      apiUrl: "https://api.dev.link4c.com/api",
      institucion: "2C2FFCEC-EACB-496D-AB69-D46BE5B763F9",
      "AzureAD": {
        "auth": {
          "clientId": "f028e8c2-058e-4d41-a1d1-5e6eb9815a30",
          "authority": "https://login.microsoftonline.com/2C2FFCEC-EACB-496D-AB69-D46BE5B763F9",
          "validateAuthority": true,
          "redirectUri": "https://dev.l4c.app",
          "postLogoutRedirectUri": "https://dev.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://dev.l4c.app",
            "resourceScope": "api://4ce9cc44-b270-4f8f-a2f2-ece7938afc38/access_as_user"
          }
        }
      }
    },
    /*"https://localhost:4200": {
      apiUrl: "https://localhost:44382/api",
      institucion: "f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
      defaultLang: "en",
      "AzureAD": {
        "auth": {
          "clientId": "2a85e1ad-5456-403c-8565-7add8f61845a",
          "authority": "https://login.microsoftonline.com/f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
          "validateAuthority": true,
          "redirectUri": "https://localhost:4200",
          "postLogoutRedirectUri": "https://localhost:4200/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://localhost:4200",
            // "resourceScope": "api://97e84c98-0bcc-40ec-a549-2c8f9a1399f7/access_as_user"
            // Original
            "resourceScope": "api://dbe31fea-1430-4df4-b608-9aa1545791b4/access_as_user"
          }
        }
      }
    },
    /*"https://localhost:4200": {
      apiUrl: "https://localhost:44382/api",
      institucion: "f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
      defaultLang: "en",
      "AzureAD": {
        "auth": {
          "clientId": "2a85e1ad-5456-403c-8565-7add8f61845a",
          "authority": "https://login.microsoftonline.com/f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
          "validateAuthority": true,
          "redirectUri": "https://localhost:4200",
          "postLogoutRedirectUri": "https://localhost:4200/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://localhost:4200",
            "resourceScope": "api://97e84c98-0bcc-40ec-a549-2c8f9a1399f7/access_as_user"
          }
        }
      }
    },*/
    /*"https://localhost:4200": {
      apiUrl: "https://back-l4c-gsis-demo.azurewebsites.net/api",
      institucion: "f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
      defaultLang: "en",
      "AzureAD": {
        "auth": {
          "clientId": "2a85e1ad-5456-403c-8565-7add8f61845a",
          "authority": "https://login.microsoftonline.com/f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
          "validateAuthority": true,
          "redirectUri": "https://localhost:4200",
          "postLogoutRedirectUri": "https://localhost:4200/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://localhost:4200",
            "resourceScope": "api://97e84c98-0bcc-40ec-a549-2c8f9a1399f7/access_as_user"
          }
        }
      }
    },*/
    "https://qa.l4c.app": {
      apiUrl: "https://api.qa.l4c.app/api",
      institucion: "ea4f48c3-ccf9-4826-8729-e5f4ec007673",
      "AzureAD": {
        "auth": {
          "clientId": "d3589021-872a-454a-9c4d-3847102100e9",
          "authority": "https://login.microsoftonline.com/ea4f48c3-ccf9-4826-8729-e5f4ec007673",
          "validateAuthority": true,
          "redirectUri": "https://qa.l4c.app",
          "postLogoutRedirectUri": "https://qa.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://qa.l4c.app",
            "resourceScope": "api://dbe31fea-1430-4df4-b608-9aa1545791b4/access_as_user"
          }
        }
      }
    }
    ,
    "https://demo.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "57c93f09-6252-42b0-939a-3fda4315d682",
      "AzureAD": {
        "auth": {
          "clientId": "b7d6e12d-bf3e-4882-ae7a-ace83dcbb542",
          "authority": "https://login.microsoftonline.com/57c93f09-6252-42b0-939a-3fda4315d682",
          "validateAuthority": true,
          "redirectUri": "https://demo.l4c.app",
          "postLogoutRedirectUri": "https://demo.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://demo.l4c.app",
            "resourceScope": "api://bb7210ac-5989-4cf9-a97a-20934ff42320/access_as_user"
          }
        }
      }
    }
    ,
    "https://ucompy.l4c.app": {
      apiUrl: "https://api.l4cqa.l4c.app/api",
      institucion: "e76ebaf0-9cf1-42c6-b957-96356b5af000",
      "AzureAD": {
        "auth": {
          "clientId": "97ccc400-0baf-4dcb-9e8f-72c2556c728e",
          "authority": "https://login.microsoftonline.com/e76ebaf0-9cf1-42c6-b957-96356b5af000",
          "validateAuthority": true,
          "redirectUri": "https://ucompy.l4c.app/",
          "postLogoutRedirectUri": "https://ucompy.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://ucompy.l4c.app",
            "resourceScope": "api://e9d682c7-4736-484b-8d50-0d97d7ecf9ea/access_as_user"
          }
        }
      }
    }
    ,
    "https://umanmx.l4c.app": {
      apiUrl: "https://api.l4cqa.l4c.app/api",
      institucion: "0776d468-dc61-47e3-aa17-c2e21175502d",
      "AzureAD": {
        "auth": {
          "clientId": "d854b62c-1630-4f60-8df1-b945f4141432",
          "authority": "https://login.microsoftonline.com/0776d468-dc61-47e3-aa17-c2e21175502d",
          "validateAuthority": true,
          "redirectUri": "https://umanmx.l4c.app/",
          "postLogoutRedirectUri": "https://umanmx.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://umanmx.l4c.app",
            "resourceScope": "api://6c31e415-d111-4c78-9486-6b070082335d/access_as_user"
          }
        }
      }
    }
    ,
    "https://ucom.l4c.app": {
      apiUrl: "https://api.ucom.l4c.app/api",
      institucion: "366bbb6d-51aa-4297-bcf8-fc4e99e3acc6",
      "AzureAD": {
        "auth": {
          "clientId": "5bba7cc8-f76f-444c-a49e-17e6632d92af",
          "authority": "https://login.microsoftonline.com/366bbb6d-51aa-4297-bcf8-fc4e99e3acc6",
          "validateAuthority": true,
          "redirectUri": "https://ucom.l4c.app/",
          "postLogoutRedirectUri": "https://ucom.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://ucom.l4c.app",
            "resourceScope": "api://3113a2d1-6a44-4c6e-8c1e-f2503f7e10a0/access_as_user"
          }
        }
      }
    }
    ,
    "https://uman.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "eb095f85-ba01-49a1-91ec-24833a6371f7",
      "AzureAD": {
        "auth": {
          "clientId": "1e62d3f0-3af5-47b3-a549-1bb742ce5364",
          "authority": "https://login.microsoftonline.com/eb095f85-ba01-49a1-91ec-24833a6371f7",
          "validateAuthority": true,
          "redirectUri": "https://uman.l4c.app/",
          "postLogoutRedirectUri": "https://uman.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://uman.l4c.app",
            "resourceScope": "api://f6055616-92ca-48aa-bdcd-10c34fca5cbb/access_as_user"
          }
        }
      }
    }
    ,
    "https://g-sis.azurewebsites.net": {
      apiUrl: "https://back-l4c-gsis-demo.azurewebsites.net/api",
      institucion: "f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
      defaultLang: "en",
      "AzureAD": {
        "auth": {
          "clientId": "2a85e1ad-5456-403c-8565-7add8f61845a",
          "authority": "https://login.microsoftonline.com/f0e8b6fa-67fc-4c8a-90c5-a8ffddfc89a4",
          "validateAuthority": true,
          "redirectUri": "https://g-sis.azurewebsites.net",
          "postLogoutRedirectUri": "https://g-sis.azurewebsites.net/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://g-sis.azurewebsites.net",
            "resourceScope": "api://97e84c98-0bcc-40ec-a549-2c8f9a1399f7/access_as_user"
          }
        }
      }
    }
    ,
    "https://l4cqa.l4c.app": {
      apiUrl: "https://api.l4cqa.l4c.app/api",
      institucion: "662f8628-325c-4393-95e2-e1423982186d",
      "AzureAD": {
        "auth": {
          "clientId": "6cf19ac6-77ac-4908-9062-e52400e579ed",
          "authority": "https://login.microsoftonline.com/662f8628-325c-4393-95e2-e1423982186d",
          "validateAuthority": true,
          "redirectUri": "https://l4cqa.l4c.app/",
          "postLogoutRedirectUri": "https://l4cqa.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://l4cqa.l4c.app",
            "resourceScope": "api://967394c3-43f8-43b2-892c-026264163286/access_as_user"
          }
        }
      }
    },
    "https://dev-l4c-front.azurewebsites.net": {
      apiUrl: "https://api.dev.link4c.com/api",
      institucion: "ea4f48c3-ccf9-4826-8729-e5f4ec007673",
      defaultLang: "en",
      "AzureAD": {
        "auth": {
          "clientId": "af8a6f29-0a63-41cf-92c0-3a9d34310979",
          "authority": "https://login.microsoftonline.com/ea4f48c3-ccf9-4826-8729-e5f4ec007673",
          "validateAuthority": true,
          "redirectUri": "https://dev-l4c-front.azurewebsites.net/",
          "postLogoutRedirectUri": "https://dev-l4c-front.azurewebsites.net/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://dev-l4c-front.azurewebsites.net",
            "resourceScope": "api://dbe31fea-1430-4df4-b608-9aa1545791b4/access_as_user"
          }
        }
      }
    },
    "https://univ.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "7d5cb3e1-3415-4125-b089-a19eb60e1824",
      "AzureAD": {
        "auth": {
          "clientId": "cd7be8b7-fea8-4f11-93f3-7e38f3d420bf",
          "authority": "https://login.microsoftonline.com/7d5cb3e1-3415-4125-b089-a19eb60e1824",
          "validateAuthority": true,
          "redirectUri": "https://univ.l4c.app/",
          "postLogoutRedirectUri": "https://univ.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://univ.l4c.app",
            "resourceScope": "api://af20bcb2-6237-4b4f-88b9-c386f669db40/access_as_user"
          }
        }
      }
    },
    "https://renew.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "7d5cb3e1-3415-4125-b089-a19eb60e1824",
      "AzureAD": {
        "auth": {
          "clientId": "cd7be8b7-fea8-4f11-93f3-7e38f3d420bf",
          "authority": "https://login.microsoftonline.com/7d5cb3e1-3415-4125-b089-a19eb60e1824",
          "validateAuthority": true,
          "redirectUri": "https://renew.l4c.app/",
          "postLogoutRedirectUri": "https://renew.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://renew.l4c.app",
            "resourceScope": "api://af20bcb2-6237-4b4f-88b9-c386f669db40/access_as_user"
          }
        }
      }
    },
    "https://uel.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "bd0dfe85-f400-431f-aacb-0b551463dd2c",
      "AzureAD": {
        "auth": {
          "clientId": "f4e7526c-66a8-4515-a96c-742e106a8f96",
          "authority": "https://login.microsoftonline.com/bd0dfe85-f400-431f-aacb-0b551463dd2c",
          "validateAuthority": true,
          "redirectUri": "https://uel.l4c.app/",
          "postLogoutRedirectUri": "https://uel.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://uel.l4c.app",
            "resourceScope": "api://bed166e1-e68c-4dca-85d5-a24ea58ae4f5/access_as_user"
          }
        }
      }
    },
    "https://barry.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "fcd3d4cd-582b-42b8-91d9-0e685811a1c3",
      "AzureAD": {
        "auth": {
          "clientId": "179581b7-f546-44d5-b93e-ffc9b361e102",
          "authority": "https://login.microsoftonline.com/fcd3d4cd-582b-42b8-91d9-0e685811a1c3",
          "validateAuthority": true,
          "redirectUri": "https://barry.l4c.app/",
          "postLogoutRedirectUri": "https://barry.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://barry.l4c.app",
            "resourceScope": "api://818dd957-58c5-433f-9139-cf6f5fa8091c/access_as_user"
          }
        }
      }
    },
    "https://cvc.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "42802c29-52e4-4528-8c92-f4cd2df9c23d",
      "AzureAD": {
        "auth": {
          "clientId": "e711f9cc-d98f-4a7a-ac35-bad47c9db4f6",
          "authority": "https://login.microsoftonline.com/42802c29-52e4-4528-8c92-f4cd2df9c23d",
          "validateAuthority": true,
          "redirectUri": "https://cvc.l4c.app/",
          "postLogoutRedirectUri": "https://cvc.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://cvc.l4c.app",
            "resourceScope": "api://b4a362b3-ef19-409f-9cbd-b17b3575062d/access_as_user"
          }
        }
      }
    },
    "https://amn.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "aef80eb6-6c44-4855-9767-2229d4301803",
      "AzureAD": {
        "auth": {
          "clientId": "06c058ae-a6ac-46d0-87e5-9fca06902ad2",
          "authority": "https://login.microsoftonline.com/aef80eb6-6c44-4855-9767-2229d4301803",
          "validateAuthority": true,
          "redirectUri": "https://amn.l4c.app/",
          "postLogoutRedirectUri": "https://amn.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://amn.l4c.app",
            "resourceScope": "api://8d7e8e51-ccdd-484e-9045-4237240d59cb/access_as_user"
          }
        }
      }
    },
    "https://cppt.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "ace35c6b-9a49-4b73-a9c7-83b4072823ff",
      "AzureAD": {
        "auth": {
          "clientId": "ec6b2ccb-d752-420b-86a7-a7b9f00c97d3",
          "authority": "https://login.microsoftonline.com/ace35c6b-9a49-4b73-a9c7-83b4072823ff",
          "validateAuthority": true,
          "redirectUri": "https://cppt.l4c.app/",
          "postLogoutRedirectUri": "https://cppt.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://cppt.l4c.app",
            "resourceScope": "api://8df5026e-e423-45ae-bca9-8b5e9c40e41b/access_as_user"
          }
        }
      }
    },
    "https://jema.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "460b9aa4-d95f-4a8b-8c5a-d351237de777",
      "AzureAD": {
        "auth": {
          "clientId": "889c712e-9794-484f-af52-0886842dd928",
          "authority": "https://login.microsoftonline.com/460b9aa4-d95f-4a8b-8c5a-d351237de777",
          "validateAuthority": true,
          "redirectUri": "https://jema.l4c.app/",
          "postLogoutRedirectUri": "https://jema.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://jema.l4c.app",
            "resourceScope": "api://3b0cc6f9-65ae-42cb-b4c0-c9a7af61faef/access_as_user"
          }
        }
      }
    },
    "https://ceem.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "83331562-17e0-4e8f-96af-7c224ea70920",
      "AzureAD": {
        "auth": {
          "clientId": "a7a65d08-1403-4316-8bd1-875762208942",
          "authority": "https://login.microsoftonline.com/83331562-17e0-4e8f-96af-7c224ea70920",
          "validateAuthority": true,
          "redirectUri": "https://ceem.l4c.app/",
          "postLogoutRedirectUri": "https://ceem.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://ceem.l4c.app",
            "resourceScope": "api://f9b1dcd8-b58b-41ba-a6d9-03548dbd4514/access_as_user"
          }
        }
      }
    },
    "https://redibai.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "3da01ff9-5425-4b7e-b0c0-f3b193ebf393",
      "AzureAD": {
        "auth": {
          "clientId": "3e978da1-71d5-48e3-ac29-d5c6a3d06af7",
          "authority": "https://login.microsoftonline.com/3da01ff9-5425-4b7e-b0c0-f3b193ebf393",
          "validateAuthority": true,
          "redirectUri": "https://redibai.l4c.app/",
          "postLogoutRedirectUri": "https://redibai.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://redibai.l4c.app",
            "resourceScope": "api://cc30e88a-8ddf-4382-92eb-bf1d1038ede0/access_as_user"
          }
        }
      }
    },
    "https://skills.l4c.app": {
      apiUrl: "https://api.prod.l4c.app/api",
      institucion: "a547ce75-a296-442f-8c14-e0232cd77ba6",
      "AzureAD": {
        "auth": {
          "clientId": "48e88853-a3e5-4c2a-bbc8-f4ff7092f561",
          "authority": "https://login.microsoftonline.com/a547ce75-a296-442f-8c14-e0232cd77ba6",
          "validateAuthority": true,
          "redirectUri": "https://skills.l4c.app/",
          "postLogoutRedirectUri": "https://skills.l4c.app/signout",
          "navigateToLoginRequestUrl": true
        },
        "cache": {
          "cacheLocation": "localStorage"
        },
        "scopes": {
          "loginRequest": ["user.read", "openid", "profile", "Directory.AccessAsUser.All"]
        },
        "resources": {
          "link4CampusApi": {
            "resourceUri": "https://skills.l4c.app",
            "resourceScope": "api://e41aa8c4-be29-4944-91c2-5087e20efec0/access_as_user"
          }
        }
      }
    },
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
