import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable } from 'rxjs';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { Programa } from '../../_models/programa';
import { AccionEnum } from '../../_models/accion.enum';

@Component({
  selector: 'app-programa-objetivos',
  templateUrl: './programa-objetivos.component.html',
  styleUrls: ['./programa-objetivos.component.scss']
})
export class ProgramaObjetivosComponent implements OnInit, OnDestroy {

  @Input() datosPrograma: Programa;
  public idPrograma$: Observable<number>;
  public idPrograma: number;
  public idProgramaSubscription: Subscription;
  public objetivosForm: FormGroup;

  constructor(private programaAcademicoService: ProgramaAcademicoService,
    private snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.idPrograma$ = this.programaAcademicoService.obtenerIdPrograma$();
    this.idProgramaSubscription = this.idPrograma$.subscribe(id => this.idPrograma = id);
    console.log("datosObjetivos", this.datosPrograma);
    this.inicializarForm();
  }

  public inicializarForm() {
    this.objetivosForm = new FormGroup({
      objetivoGeneral: new FormControl(this.datosPrograma.infoPrograma.objetivoGeneral === '' ? '' : this.datosPrograma.infoPrograma.objetivoGeneral),
      objetivoEspecifico: new FormControl(this.datosPrograma.infoPrograma.objetivoEspecifico === '' ? '' : this.datosPrograma.infoPrograma.objetivoEspecifico)
    });

    this.objetivosForm.valueChanges.subscribe(val => {
      this.programaAcademicoService.definirBoolCambio(true);
    });
  }

  get objetivoGeneral() { return this.objetivosForm.get('objetivoGeneral'); }
  get objetivoEspecifico() { return this.objetivosForm.get('objetivoEspecifico'); }

  public modificarDatosObjetivos() {
    let datosObjetivos = Object.assign(this.objetivosForm.value);
    datosObjetivos.tabSeccion = "IO";
    if (this.datosPrograma.tipoAccion === AccionEnum.EDITAR) {
      datosObjetivos.ProgramaId = this.datosPrograma.programaId;
    } else if (this.datosPrograma.tipoAccion === AccionEnum.CREAR) {
      datosObjetivos.ProgramaId = this.idPrograma;
    }
    this.programaAcademicoService.modificarInfoProgramaObjetivos(datosObjetivos).subscribe(
      editado => {
        if (editado) {
          this.programaAcademicoService.definirBoolCambio(false);
          this.programaAcademicoService.invocarMetodo();
          this.snackBar.open("Se ha modificado la información.", "OK", { duration: 5000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar los objetivos. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al guardar los objetivos. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

  ngOnDestroy(): void {
    if (this.idProgramaSubscription) {
      this.idProgramaSubscription.unsubscribe();
    }
  }

}
