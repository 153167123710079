import { ServicePackageSeguimientoDto, ServiciosPaquete, DetallePaqueteServicio } from './../_models/ServicePackageSeguimientoDto';
import { AdminAcademicoAlumnoDto } from './../_models/AdminAcademicoAlumnoDto';
import { FiltroAdminAcademicoAlumnoDto } from '@app/_models/FiltroAdminAcademicoAlumnoDto';
import { SimpleResponse } from './../models/api/SimpleResponse.model';
import { environment, baseUrl } from '../../environments/environment';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServicioPaqueteService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Obtiene los paquetes de servicios por la institucion
   * @returns
   */
  public paquetesServicio(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): Observable<ApiResponse<AdminAcademicoAlumnoDto>> {
    return this.httpClient.post<ApiResponse<AdminAcademicoAlumnoDto>>(`${environment.enves[baseUrl].apiUrl}/ServicePackages/PaquetesServicio/${pagina}/${registrosPagina}`, filtro);
  }

  /**
   * Obtiene el detalle de los servicios por su id
   * @returns
   */
  public detallePaqueteServicio(campusId: number, nivelId: number, periodoId: number, programaId: number, alumnoId: number, servicePackageId: number): Observable<ApiResponse<DetallePaqueteServicio[]>> {
    return this.httpClient.get<ApiResponse<DetallePaqueteServicio[]>>(`${environment.enves[baseUrl].apiUrl}/ServicePackages/DetallePaqueteServicio/${campusId}/${nivelId}/${periodoId}/${programaId}/${alumnoId}/${servicePackageId}`);
  }

  /**
   * Obtiene los paquetes de servicios por la institucion
   * @returns
   */
  public enviarPaqueteCargo(campusId: number, programaId: number, periodoId: number, alumnoId: number, servicios: DetallePaqueteServicio[]): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/ServicePackages/EnviarPaqueteCargo/${campusId}/${programaId}/${periodoId}/${alumnoId}`, servicios);
  }

  /**
   * Obtiene los paquetes de servicios por la institucion
   * @returns
   */
  public getListaPaquetes(): Observable<ApiResponse<ServicePackageSeguimientoDto[]>> {
    return this.httpClient.get<ApiResponse<ServicePackageSeguimientoDto[]>>(`${environment.enves[baseUrl].apiUrl}/ServicePackages/GetListaPaquetes`);
  }

  /**
   * Metodo para traer el paquete de servicios por su id
   * @param servicioPaqueteId 
   * @returns 
   */
  public paquetesServicioById(ServicioPaqueteId: number): Observable<ApiResponse<ServicePackageSeguimientoDto>> {
    return this.httpClient.get<ApiResponse<ServicePackageSeguimientoDto>>(`${environment.enves[baseUrl].apiUrl}/ServicePackages/PaquetesServicioById/${ServicioPaqueteId}`);
  }

  /**
   * Metodo para crear un paquete de servicios nuevo
   * @param ServicioPaqueteId 
   * @returns 
   */
  public crearPaquetesServicio(dataGuardar: ServicePackageSeguimientoDto): Observable<ApiResponse<ServicePackageSeguimientoDto>> {
    return this.httpClient.post<ApiResponse<ServicePackageSeguimientoDto>>(`${environment.enves[baseUrl].apiUrl}/ServicePackages/CrearPaquetesServicio`, dataGuardar);
  }

  /**
   * Metodo para editar un paquete de servicios
   * @param ServicioPaqueteId 
   * @returns 
   */
  public editarPaquetesServicio(dataEditar: ServicePackageSeguimientoDto): Observable<ApiResponse<ServicePackageSeguimientoDto>> {
    return this.httpClient.put<ApiResponse<ServicePackageSeguimientoDto>>(`${environment.enves[baseUrl].apiUrl}/ServicePackages/EditarPaquetesServicio`, dataEditar);
  }

  /**
   * Metodo para obtener la lista de servicio para asignar al paquete
   * @returns 
   */
  public obtenerServiciosPaquete(): Observable<ApiResponse<ServiciosPaquete[]>> {
    return this.httpClient.get<ApiResponse<ServiciosPaquete[]>>(`${environment.enves[baseUrl].apiUrl}/ServicePackages/ObtenerServiciosPaquete`);
  }

  /**
   * Metodo para hacer una baja logica de un paquete de servicios
   * @param ServicioPaqueteId 
   * @returns 
   */
  public EliminarPaquetesServicio(ServicioPaqueteId: any): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/ServicePackages/EliminarPaquetesServicio/${ServicioPaqueteId}`);
  }

  /**
   * Descargar Excel
   * @param data
   * @returns
   */
  public descargarExcel(data: ServicePackageSeguimientoDto[]) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/ServicePackages/DownloadExcel`, JSON.stringify(data),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }
}
