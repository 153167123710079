export class InscripcionCosto {
  Campus: string;
  CampusId: number;
  Nivel: string;
  NivelId: number;
  PeriodoId: number;
  Periodo: string;
  ProgramaId: number;
  Programa: string;
  TipoAlumno: string;
  fechaInicio: Date;
  fechaFin: any;
  costo: number;
  Impuesto: number;
  Intereses: number;
  Parcialidades: number;
  costoFinal: number;
  UsuarioModificacion: number;
  impuesto: number;
  periodo: any;
}

export class ResumenDatos {
  correo: string;
  saldoPagar: number;
  saldoTotal: number;
  saldoVencido: number;
  usuarioId: number;
}

export class MontoInscripcion {
  PeriodoId: number;
  campusId: number;
  programaId: number;
  tipoAlumnoId: string;
}

export class MovimientosPagos {
  campus: number;
  fechaPago: string;
  formaCargo: boolean;
  formaPago: number;
  monto: string;
  motivo: string;
  nombreBanco: string;
  nombreTienda: string;
  periodo: number;
  programa: number;
  referenciaNumerica: string;
  tipoAlumno: number;
  tipoMovimiento: number;
  tipoServicio: number;
  usuarioId: number;
}

export class ServiciosPago {
  usuarioId: number;
  tipoCargoId: number;
  monto: number;
  descripcion: string;
  fechaVencimiento: Date;
  alumnoId: any;
  periodoId: any;
  formaCargo: any;
  parcialidades: any;
}

export class InscripcionPago {
  usuarioId: number;
  tipoCargoId: number;
  monto: number;
  descripcion: string;
  fechaVencimiento: Date;
  alumnoId: any;
  periodoId: any;
  PeriodoSeleccionadoInscripcion: any;
}