import { StudentCourseTransferDto } from './../../../_models/studentCourseTransferDto';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SeleccionDeMateriasDTO } from 'src/app/models/Inscripcion/SeleccionDeMateriasDTO.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { Alumno, Periodo } from 'src/app/_models';
import { PeriodoService } from 'src/app/_services';
import { AdminAcademicaService } from 'src/app/_services/admin-academica.service';
import { MatPaginator } from '@angular/material/paginator';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-acreditaciones',
  templateUrl: './acreditaciones.component.html',
  styleUrls: ['./acreditaciones.component.scss']
})
export class AcreditacionesComponent implements OnInit {


  displayedColumns: string[] = [
    'select',
    'periodo',
    'materia',
    'clave',
    'calificacion',
    'tipo',
    'fecha',
    'usuario'];
  public dataSource: MatTableDataSource<any>;
  tipoAcreditaciones = [{ "id": 1 }, { "id": 2 }, { "id": 3 }]
  public datosAcreditacionForm: FormGroup;
  public datosPlanForm: FormGroup;
  public datosExamenForm: FormGroup;
  public datosRevalidacionForm: FormGroup;
  public numAcreditacionesSeleccionado = 0;
  public esOtroPlan: boolean = false;
  public esExamen: boolean = false;
  public esRevalidacion: boolean = false;
  public programaId: number;
  public identificador: string;
  public datosAcreditacionEliminar;
  selection = new SelectionModel<any>(true, []);
  public materiasDisponiblesAcreditar = new Array<SeleccionDeMateriasDTO>();
  public materiasCursadaEnOtroPlan = new Array<SeleccionDeMateriasDTO>();
  public periodosDisponibles = new Array<Periodo>();
  public datosAcreditacion: StudentCourseTransferDto = null;
  public arregloOriginal: any[];
  public arregloFiltro: any[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('modalAgregarAcreditacion') modalAgregarAcreditacion: TemplateRef<any>;
  @ViewChild('modalEliminarAcreditacion') modalEliminarAcreditacion: TemplateRef<any>;
  @Input() data: { alumno: Alumno, indexTap: number };
  public modalAgregarAcreditacionRef: any;
  public modalEliminarAcreditacionRef: any;


  constructor(private dialog: MatDialog,
    private adminAcademicaService: AdminAcademicaService,
    private snackBar: SnackService,
    private periodoService: PeriodoService,
    private utileriasService: UtileriasService,
  ) { }

  ngOnInit(): void {
    this.identificador = this.data.alumno.usuario.identificador;
    this.programaId = this.data.alumno.programaId;
    this.obtenerAcreditacionesAlumno();

    if (this.adminAcademicaService.invocarObtenerAcreditacionesSubscription == undefined) {
      this.adminAcademicaService.invocarObtenerAcreditacionesSubscription = this.adminAcademicaService.invocarObtenerAcreditaciones.subscribe((programaId) => {
        this.programaId = programaId;
        this.obtenerAcreditacionesAlumno();
      });
      this.adminAcademicaService.invocarObtenerAcreditacionesSubscription = null;
    }
  }


  public inicializarFormAcreditacion() {
    this.datosAcreditacionForm = new FormGroup({
      studentCourseTransferId: new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.studentCourseTransferId : 0),
      materiaId: new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.creditedCourseId : 0, [Validators.required, this.ValidateId]),
      periodoId: new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.periodoId : 0, [Validators.required, this.ValidateId]),
      tipoAcreditacionId: new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.typeAccreditationId : 0, [Validators.required, this.ValidateId]),
    })
    this.seleccionarTipoAcreditacion(this.tipoAcreditacionId.value);
  }

  // this.utileriasService.formatearFecha(this.alumnoDatosBusqueda.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD')


  public inicializarFormPlan() {
    this.datosAcreditacionForm.addControl('materiaCursadaId', new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.materiaCursadaId : 0, [Validators.required, this.ValidateId]));
    this.datosAcreditacionForm.addControl('periodoCursadoId', new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.periodoCursadoId : "", Validators.required));
    this.datosAcreditacionForm.addControl('calificacion', new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.score : "", [Validators.required, Validators.pattern(/^[1-9]+/)]));
  }
  public inicializarFormExamen() {

    this.datosAcreditacionForm.addControl('certificador', new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.certifier : "", Validators.required));
    this.datosAcreditacionForm.addControl('examenNombre', new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.quizPresented : "", Validators.required));
    this.datosAcreditacionForm.addControl('puntuaje', new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.score : "", Validators.required));
    this.datosAcreditacionForm.addControl('fechaPresentacion', new FormControl(this.datosAcreditacion !== null ? this.utileriasService.formatearFecha(this.datosAcreditacion.datePresented, 'YYYY[-]MM[-]DD') : "", Validators.required));

  }
  public inicializarFormRevalidacion() {

    this.datosAcreditacionForm.addControl('institucionNombre', new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.institutionName : "", Validators.required));
    this.datosAcreditacionForm.addControl('materiaCursada', new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.subjectTransfer : "", Validators.required));
    this.datosAcreditacionForm.addControl('calificacionRevalidacion', new FormControl(this.datosAcreditacion !== null ? this.datosAcreditacion.score : "", Validators.required));
    this.datosAcreditacionForm.addControl('fechaCursada', new FormControl(this.datosAcreditacion !== null ? this.utileriasService.formatearFecha(this.datosAcreditacion.dateAttended, 'YYYY[-]MM[-]DD') : "", Validators.required));

  }



  abrirModalAgregarEditarAcreditacion(elemento?: any) {
    if (elemento !== undefined) {
      this.datosAcreditacion = elemento[0];
    }
    this.modalAgregarAcreditacionRef = this.dialog.open(this.modalAgregarAcreditacion, { disableClose: true });
    this.inicializarFormAcreditacion();
    this.ObtenerMateriasAcreditar();
    this.ObtenerPeriodosDisponibles();
  }

  public abrirModalEliminarcion(elemento?: any) {
    this.datosAcreditacionEliminar = elemento[0];
    this.modalEliminarAcreditacionRef = this.dialog.open(this.modalEliminarAcreditacion, { disableClose: true });
  }

  public CerrarModal(): void {
    this.datosAcreditacion = null;
    this.limpiarSeleccionTipoAcreditacion();
    this.limpiarDatosAcreditacionForm();
    this.modalAgregarAcreditacionRef.close();
  }

  public cerrarModalEliminacion(): void {
    this.modalEliminarAcreditacionRef.close();
  }


  /**
  * Metodo que se activa cuando el select selecciona un tipo de acreditacion
  * @param tipoAcreditacionId
  */
  public seleccionarTipoAcreditacion(tipoAcreditacionId: number) {
    this.limpiarSeleccionTipoAcreditacion();
    this.limpiarDatosAcreditacionForm();
    switch (tipoAcreditacionId) {
      case 1: {
        this.esOtroPlan = true;
        this.inicializarFormPlan();
        this.ObtenerMateriasCursadasEnOtroPlan();
        break;
      }
      case 2: {
        this.esExamen = true;
        this.inicializarFormExamen();
        break;
      }
      case 3: {
        this.esRevalidacion = true;
        this.inicializarFormRevalidacion();
        break;
      }
    }
  }


  public seleccionarMateriaCursada(idMateriaOferta: number) {
    if (idMateriaOferta !== 0) {
      const materiasCursada = this.materiasCursadaEnOtroPlan.find(element => element.idMateriaOferta == idMateriaOferta);
      this.periodoCursado.setValue(materiasCursada.nombrePeriodo);
      this.calificacion.setValue(materiasCursada.calificacionFinal);
    } else {
      this.periodoCursado.setValue("");
      this.calificacion.setValue("");
    }
  }


  public ObtenerMateriasCursadasEnOtroPlan() {
    this.adminAcademicaService.ObtenerMateriasCursadasEnOtroPlan(this.programaId, this.identificador, this.data.alumno.alumnoId).subscribe(response => {
      if (response) {
        this.removerMateriasAcreditadasEnOtroPlan(response.data);
      }
    })
  }


  public limpiarDatosAcreditacionForm() {
    this.datosAcreditacionForm.removeControl('materiaCursadaId');
    this.datosAcreditacionForm.removeControl('periodoCursadoId');
    this.datosAcreditacionForm.removeControl('calificacion');

    this.datosAcreditacionForm.removeControl('certificador');
    this.datosAcreditacionForm.removeControl('examenNombre');
    this.datosAcreditacionForm.removeControl('puntuaje');
    this.datosAcreditacionForm.removeControl('fechaPresentacion');

    this.datosAcreditacionForm.removeControl('institucionId');
    this.datosAcreditacionForm.removeControl('materiaCursada');
    this.datosAcreditacionForm.removeControl('calificacion');
    this.datosAcreditacionForm.removeControl('fechaCursada');
  }

  public limpiarSeleccionTipoAcreditacion() {
    this.esOtroPlan = false;
    this.esExamen = false;
    this.esRevalidacion = false;
  }

  /**
    * Metodo para traer el mensaje de error y mostrarlo en el html
    * @param controlName
    * @returns
    */
  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.datosAcreditacionForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  private ValidateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }



  public ObtenerMateriasAcreditar() {
    this.adminAcademicaService.ObtenerMateriasProgramadasAcreditar(this.programaId, this.identificador).subscribe(response => {
      if (response.success) {
        this.removerMateriasAcreditadas(response.data);
      }
    })
  }

  /**
   * Metodo que remueve las materias de la lista de materias por acreditar
   * @param responseData
   */
  public removerMateriasAcreditadas(responseData) {
    this.arregloFiltro = this.arregloOriginal;
    if (this.materiaId.value !== 0) {
      this.arregloFiltro = this.arregloFiltro.filter(obj => obj.creditedCourseId !== this.materiaId.value)
    }

    let result = this.arregloFiltro.map(a => a.creditedCourseId);

    this.materiasDisponiblesAcreditar = responseData.filter(function (objFromA) {
      return !result.find(function (objFromB) {
        return objFromA.idMateria === objFromB
      })
    })
  }


  public removerMateriasAcreditadasEnOtroPlan(responseData) {
    this.arregloFiltro = this.arregloOriginal;
    if (this.materiaId.value !== 0) {
      this.arregloFiltro = this.arregloFiltro.filter(obj => obj.materiaCursadaId !== this.materiaCursadaId.value)
    }

    let result = this.arregloFiltro.map(a => a.materiaCursadaId);

    this.materiasCursadaEnOtroPlan = responseData.filter(function (objFromA) {
      return !result.find(function (objFromB) {
        return objFromA.idMateriaOferta === objFromB
      })
    })
  }








  public obtenerAcreditacionesAlumno() {
    this.adminAcademicaService.ObtenerAcreditacionesAlumno(this.data.alumno.alumnoId).subscribe(response => {
      if (response.success) {
        //console.log("obtenerAcreditacionesAlumno", response);
        this.arregloOriginal = [...response.data];
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
    }, (err => {
      this.dataSource = new MatTableDataSource([]);
    }))
  }

  public ObtenerPeriodosDisponibles() {
    this.periodoService.obtenerPeriodosByPeriodoIngresoId(this.data.alumno.usuario.institucionId, this.data.alumno.priodoIngreso).subscribe(response => {
      if (response.success) {
        this.periodosDisponibles = response.data;
      }
    })
  }

  public guardarAcreditacion() {
    let acreditacionForm = Object.assign(this.datosAcreditacionForm.value);

    let datosAcreditacion: any = {
      studentCourseTransferId: acreditacionForm.studentCourseTransferId,
      alumnoId: this.data.alumno.alumnoId,
      creditedCourseId: acreditacionForm.materiaId,
      periodoId: acreditacionForm.periodoId,
      typeAccreditationId: acreditacionForm.tipoAcreditacionId
    }

    switch (datosAcreditacion.typeAccreditationId) {
      //Materia cursada en otro plan
      case 1: {
        let materiaCursada = this.materiasCursadaEnOtroPlan.find(element => element.idMateriaOferta == acreditacionForm.materiaCursadaId);


        datosAcreditacion.materiaCursadaId = acreditacionForm.materiaCursadaId;
        datosAcreditacion.periodoCursadoId = materiaCursada.periodoId;
        datosAcreditacion.score = acreditacionForm.calificacion;
        datosAcreditacion.materiaOfertaId = materiaCursada.idMateriaOferta;
        break;
      }
      //Examen
      case 2: {
        datosAcreditacion.certifier = acreditacionForm.certificador;
        datosAcreditacion.quizPresented = acreditacionForm.examenNombre;
        datosAcreditacion.datePresented = acreditacionForm.fechaPresentacion;
        datosAcreditacion.score = acreditacionForm.puntuaje;
        break;
      }
      //Revalidacion
      case 3: {
        datosAcreditacion.institutionName = acreditacionForm.institucionNombre;
        datosAcreditacion.SubjectTransfer = acreditacionForm.materiaCursada;
        datosAcreditacion.DateAttended = acreditacionForm.fechaCursada
        datosAcreditacion.score = acreditacionForm.calificacionRevalidacion;
        break;
      }
    }

    this.adminAcademicaService.PostAcreditacionAlumno(datosAcreditacion).subscribe(response => {
      if (response.success) {
        this.obtenerAcreditacionesAlumno();
        this.CerrarModal();
        this.resetearSelection()
        this.adminAcademicaService.invocarMetodoObtenerHistorial();
        this.snackBar.mostrarSnackBack(response.message);
      }
    })
  }

  public eliminarAcreditacion() {

    this.adminAcademicaService.deleteAcreditacionAlumno(this.datosAcreditacionEliminar.studentCourseTransferId).subscribe(response => {
      if (response.success) {
        this.obtenerAcreditacionesAlumno();
        this.resetearSelection()
        this.adminAcademicaService.invocarMetodoObtenerHistorial();
        this.cerrarModalEliminacion();
        this.snackBar.mostrarSnackBack(response.message);
      } else {
        this.snackBar.mostrarSnackBack(response.message);
      }
    })


  }


  get tipoAcreditacionId() { return this.datosAcreditacionForm.get('tipoAcreditacionId'); }
  get materiaId() { return this.datosAcreditacionForm.get('materiaId'); }
  get periodoCursado() { return this.datosAcreditacionForm.get('periodoCursadoId'); }
  get calificacion() { return this.datosAcreditacionForm.get('calificacion'); }
  get materiaCursadaId() { return this.datosAcreditacionForm.get('materiaCursadaId'); }



  /**
   * Determina qué texto se mostrará en el Label con base a si se ha enviado on o un parámetro
   * @param row
   * @returns
   */
  checkboxLabel(row?: any): string {
    this.numAcreditacionesSeleccionado = this.selection.selected.length;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.studentCourseTransferId + 1}`;
  }

  /**
   * Este metodo ayuda para resetear el selection para su correcto funcionamiento en la tabla con el checkbox
   */
  resetearSelection() {
    this.numAcreditacionesSeleccionado = 0;
    this.selection = new SelectionModel<any>(true, [])
  }
}
