import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Correo } from 'src/app/_models/correo';
import { ProfesorService } from 'src/app/_services/profesor.service';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service'


@Component({
  selector: 'app-correo',
  templateUrl: './correo.component.html',
  styleUrls: ['./correo.component.scss']
})
export class CorreoComponent implements OnInit {
  public boolIsArray: boolean = false;
  public correos: string = '';
  public correoForm: FormGroup;
  public boolCambio: boolean = false;
  public numSeleccionados: number = 0;
  public formularioValido: boolean = false;
  public profesorId$: number;
  public claveMateria$:string;
 

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  private snackBar: MatSnackBar,

  public dialogRef: MatDialogRef<CorreoComponent>,
  public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
  public profesorService: ProfesorService) { }

  ngOnInit(): void {
    // this.validarDataRecibida();
    this.profesorId$ = this.EnsenanzaAprendizajeAlumnoService.profesorId$;
    this.claveMateria$ = this.EnsenanzaAprendizajeAlumnoService.claveMateria$;
    this.InicializarForm();
  

  }

  get Asunto() { return this.correoForm.get('Asunto'); }
  get CuerpoDelMensaje() { return this.correoForm.get('CuerpoDelMensaje'); }

  public InicializarForm() {
    this.correoForm = new FormGroup({
      Asunto: new FormControl( this.data.grupoId + ' ' + this.claveMateria$ + ' ' +this.data.nombre , Validators.required),
      CuerpoDelMensaje: new FormControl('', Validators.required),
    });
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numSeleccionados = this.data.length;
    }
  }
  
  public enviarCorreo() {
    
    // if (this.boolIsArray) {
    //   const arrayCorreos = this.data.map(p => (p.usuario.correo.toString()));
    //   this.correos = arrayCorreos.toString();
    // } else {
    //   this.correos = this.data.usuario.correo.toString();
    // }

this.profesorService.obtenerProfesoresByUsuario(this.profesorId$).subscribe(profesor =>{
  if(profesor){

    const estructuraCorreo = new Correo();
    // estructuraCorreo.CorreosDestinatario = this.correos;
    estructuraCorreo.CorreosDestinatario = profesor.correo;
    estructuraCorreo.Asunto = this.correoForm.get('Asunto').value;
    estructuraCorreo.CuerpoDelMensaje = this.correoForm.get('CuerpoDelMensaje').value;
  
    this.EnsenanzaAprendizajeAlumnoService.contactarProfesor(estructuraCorreo).subscribe(correoEnviado => {
      if (correoEnviado) {
        this.snackBar.open('Se ha enviado el correo con éxito.', 'OK', { duration: 5000 });
      } else {
        this.snackBar.open('Ha ocurrido un error al enviar el correo. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    });
  }



})

 


  }




  public cerrarModal() {
    if (!this.boolCambio) {
      this.dialogRef.close();
    }
  }

}
