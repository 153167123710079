import { Validators } from "@angular/forms";
import { ValidateId } from "../../functions/validate-id-function";

export const BILLING_INFORMATION = {

    razonSocial         : [, { updateOn: 'change' }],
    rfc                 : [, { updateOn: 'change' }],
    paisId              : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
    entidadFederativaId : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
    ciudad              : [, { validators: [Validators.required], updateOn: 'change' }],
    calle               : [, { validators: [Validators.required], updateOn: 'change' }],
    numeroExterior      : [, { updateOn: 'change' }],
    numeroInterior      : [, { updateOn: 'change' }],
    colonia             : [, { validators: [Validators.required], updateOn: 'change' }],
    codigoPostal        : [, { updateOn: 'change' }],
   
};