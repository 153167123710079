import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EnsenanzaAprendizajeService } from '../_services/ensenanza-aprendizaje.service';
import { Subscription } from 'rxjs';
import { Materia } from '../_models/materia';
import { CalendarOptions } from '@fullcalendar/angular';
import { Periodo } from '../_models/periodo';
import { PeriodoService } from '../_services/periodo.service';
import { MatOption } from '@angular/material/core';
import { MateriaOferta } from '../_models/materia-oferta';
import { ListaMateriasOferta } from '../_models/lista-materias-oferta';
import { I18NextPipe } from 'angular-i18next';
import i18next from 'node_modules/i18next';
import { GruposDisponiblesParaMateriaDTO } from '../models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { groupBy } from 'rxjs/operators';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { Router } from '@angular/router';
import { EnsenanzaAprendizajeAlumnoService } from '../_services/ensenanzaAprendizajeAlumno.service'
@Component({
  selector: 'app-ensenanza-aprendizaje',
  templateUrl: './ensenanza-aprendizaje.component.html',
  styleUrls: ['./ensenanza-aprendizaje.component.scss']
})
export class EnsenanzaAprendizajeComponent implements OnInit {
  periodoF = new FormControl();
  Periodos: Periodo[];
  public suscriptionMaterias: Subscription;
  public suscriptionGrupos: Subscription;
  public suscriptionPeriodo: Subscription;
  public materiaList: Materia[] = [];
  public grupoList: ListaMateriasOferta[] = [];
  public filtroForm: FormGroup;
  private periodosactivos: number[] = [];
  private curentLanguaje: string;
  FlagGrupo: boolean = false;
  showMenu: number;
  listaActivada: boolean = false;
  flagMostrarContenido: boolean = false;
  calendarOptions: CalendarOptions = {
    contentHeight: '30px',
  }

  showHorario(clave: string, ig: number){

    if (this.showMenu === ig)
    {
      this.showMenu = -1;
    }
    else
    {
      this.showMenu = ig;
    }
    
  }
  //#region DTO para agrupar tarjetas  
  //#endregion


  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allMateria') private allMateria: MatOption;

  constructor(
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    private periodoService: PeriodoService,
    private i18nextPipe: I18NextPipe,
    private router: Router,
    private EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,) { }

  ngOnInit(): void {
    this.curentLanguaje = i18next.language;
    this.inicializarForm();
    this.LlenarFiltros();
    if (this.ensenanzaAprendizajeService.invocarMetodoTableroCursosSubscription == undefined) {
      this.ensenanzaAprendizajeService.invocarMetodoTableroCursosSubscription = this.ensenanzaAprendizajeService.invocarTableroCursos.subscribe(() => {
        this.flagMostrarContenido = false;
        this.backContenido();
      });
    }
  }

  /**
   * Metodo para traducir los textos de los tabs
   * @param text 
   * @returns 
   */
  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  LimpiarFiltros() {
    this.filtroForm.patchValue({
      periodoF: null,
      materiaF: null
    });
    this.grupoList = [];
    this.materiaList = [];
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      periodoF: new FormControl(null),
      materiaF: new FormControl(null)
    });
  }

  public LlenarFiltros() {
    let today = new Date();
    this.periodoService.obtenerPeriodosByProfesorId().subscribe(
      (periodos: Periodo[]) => {
        this.Periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio < today && fin >= today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if (this.periodosactivos.length > 0) {
          //this.periodoF.setValue([...this.periodosactivos]);
          this.filtroForm.patchValue({
            periodoF: this.periodosactivos,
          })
        }
        this.filtrar();
        this.materiaList.sort();
      });
  }

  tosslePerOnePeriodo(all): boolean { 
    if (this.allPeriodo.selected) {  
      this.allPeriodo.deselect();
      return false;
    }
    if(this.filtroForm.get('periodoF').value.length == this.Periodos.length){ this.allPeriodo.select(); }
    this.filtrar();
  }

  tosslePerOneMateria(all) {
    if(this.allMateria.selected) {
      this.allMateria.deselect();
      return false;
    } if(this.filtroForm.get('materiaF').value.length == this.materiaList.length) { this.allMateria.select(); }
    this.filtrar();
  }

  toggleAllSelectionPeriodo(): void {
    if (this.allPeriodo.selected) {
      this.filtroForm.get('periodoF').patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.filtroForm.get('periodoF').patchValue([]);
    }
    this.filtrar();
  }

  toggleAllSelectionMateria(): void {
    if (this.allMateria.selected) {
      this.filtroForm.get('materiaF').patchValue([...this.materiaList.map(item => item.materiaId), 0]);
    } else {
      this.filtroForm.get('materiaF').patchValue([]);
    }
    this.filtrar();
  }

  // este es el metodo para filtrar las cards una vez que se selecciona una materia
  public obtenerGrupoProfesor(clave: string, periodoId: string) {
    this.suscriptionGrupos = this.ensenanzaAprendizajeService.obtenerGruposProfesor(clave, periodoId, this.curentLanguaje).subscribe(
      (usuarioGrupos: ListaMateriasOferta[]) => {
        this.grupoList = usuarioGrupos;
      });
  }
  // este es el metodo para mostrar sin seleccionar materia
  public obtenerGrupoProfesorTodos() {
    this.suscriptionGrupos = this.ensenanzaAprendizajeService.obtenerMateriaOfertaSinFecha().subscribe(
      (usuarioGrupos: ListaMateriasOferta[]) => {
        this.grupoList = usuarioGrupos;   
      });
  }
  //hacer que filtre las cards de las materias por medio del filtro periodo
  obtenerCardsGruposProfesor(periodoId: number) {
    this.ensenanzaAprendizajeService.obtenerCardsGruposProfesor(periodoId).subscribe(res => {
      this.grupoList = res;
    })
  }
  //obtener cards de multiples periodos
  obtenerConjuntoPeriodos(periodoId: string) {
    this.ensenanzaAprendizajeService.obtenerConjuntoPeriodos(periodoId, this.curentLanguaje).subscribe(res => {
      this.grupoList = res;  
    })
  }  
  public obtenerMateriasOferta(idPeriodo: number) {
    //tengo dudas con los valores default 
    this.suscriptionMaterias = this.ensenanzaAprendizajeService.obtenerMateriaOfertaAgrupada(idPeriodo).subscribe(
      (materias: ApiResponse<Materia[]>) => {
        this.materiaList = materias.data;
        this.filtroForm.get('materiaF').setValue([...this.materiaList.map(item => item.clave), 0]);
        this.filtrar();
      }
    )
  }
  public filtrar() {
    let periodos = this.filtroForm['value'].periodoF; // se incrementa al pulsar en gobackcontenido
    let materiaOfertaClave = this.filtroForm['value'].materiaF;

    if (periodos != null && (materiaOfertaClave == null || materiaOfertaClave == undefined)) {
      this.obtenerMateriasOferta(periodos);
      this.materiaList.length = 0;
      this.obtenerConjuntoPeriodos(periodos); //obtener la lista de grupos
    }
    if (materiaOfertaClave != null) {
      this.grupoList = [];
      this.obtenerGrupoProfesor(materiaOfertaClave, periodos);
    }
    //lo necesito para agregar el filtrado de materias
    if (periodos.length == 0) {
      this.materiaList = [];
      this.grupoList = [];
    }
    if (materiaOfertaClave != null && periodos.length == 0) {
      this.filtroForm.setValue(null);
    }
    if (materiaOfertaClave != null && periodos != null) {
      //si pulso el periodo entonces setear el materiaOfertaClave en null
      this.filtroForm.get('periodoF').valueChanges.subscribe(x => {
        this.filtroForm.patchValue({
          materiaF: null
        })
        this.materiaList = [];
      })
    }
  }

  public obtenerPeriodo_2() {
    this.suscriptionPeriodo = this.periodoService.obtenerPeriodosByProfesorId().subscribe(
      (periodo: Periodo[]) => {
        this.Periodos = periodo;
      });
  }
  Grupo: any;
  nombreMateria: string = "";
  public goContenido(grupoId: any, nombreMateria: string) {
    this.flagMostrarContenido = true;
    this.nombreMateria = nombreMateria;
    this.Grupo = grupoId;
    this.grupoList = [];//vaciar la lista porque sino tambien se la lleva a Contenidos
    let infoGrupo: any = {
      nombreMateria: nombreMateria,
      Grupo: this.Grupo
    }
    this.EnsenanzaAprendizajeAlumnoService.SetInfoGrupo(infoGrupo);
    this.router.navigate([`/detalle-grupo-profesor/grupo/${this.Grupo.grupoId}/opcion/micurso`]);
  }

  back() {
    this.ensenanzaAprendizajeService.changeMessage(false);
  }
  public backContenido() {
    this.filtroForm.patchValue({
      periodoF: null
    })
    this.periodosactivos = [];
    this.LlenarFiltros();
  }
}

