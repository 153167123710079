import { Component, OnInit, Inject, TemplateRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccionEnum } from '../../../../_models/accion.enum';
import { Profesor } from '../../../../_models/profesor';
import { TipoContrato } from '../../../../_models/tipo-contrato';
import { ProfesorService } from '../../../../_services/profesor.service';
import { TipoContratoService } from '../../../../_services/tipo-contrato.service';
import { Departamento } from '../../../../_models/departamento';
import { DepartamentoService } from '../../../../_services/departamento.service';
import { DatoFiscalService } from '../../../../_services/dato-fiscal.service';
import { UtileriasService } from '../../../../_services/utilerias.service';

@Component({
  selector: 'app-datos-laborales',
  templateUrl: './datos-laborales.component.html',
  styleUrls: ['./datos-laborales.component.scss']
})
export class DatosLaboralesComponent implements OnInit {
  @Input() datosUsuarioChild: any;
  @Input() usuarioId: number;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public errorActualizaProfesor = '';
  public objValidacionCancelar: Profesor = null;
  public dialogRefAdver: any;
  public laboralForm: FormGroup;
  public boolCambio = false;
  public tipoAccionLaboral: number;
  public tipoAccionFiscal: number;
  public tipoContratoList: TipoContrato[] = [];
  public departamentosList: Departamento[] = [];
  public RFCencontrado: boolean = false;
  public renderizarForm: boolean = false;

  constructor(public dialogProfesorRef: MatDialogRef<DatosLaboralesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Profesor,
    private profesorService: ProfesorService,
    private departamentoService: DepartamentoService,
    private datoFiscalService: DatoFiscalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private tipoContratoService: TipoContratoService,
    private util: UtileriasService
  ) {
    //this.profesorId = this.data.UsuarioId;
  }

  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.profesorService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => {

      this.datosUsuarioBusqueda = datos
    });
    this.datosUsuarioBusqueda$ = null;
    this.getDepartamentos();
    this.getTipoContrato();
  }

  public renderizarFormulario() {
    if (this.datosUsuarioBusqueda?.profesor != null) {
      this.tipoAccionLaboral = AccionEnum.EDITAR;
    } else {
      this.tipoAccionLaboral = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda?.datoFiscal != null) {
      this.tipoAccionFiscal = AccionEnum.EDITAR;
    } else {
      this.tipoAccionFiscal = AccionEnum.CREAR;
    }
    this.inicializarForm();
  }

  public inicializarForm() {
    this.laboralForm = new FormGroup({
      usuarioID: new FormControl(this.datosUsuarioBusqueda?.usuarioId),
      TipoContratoId: new FormControl(this.tipoAccionLaboral === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.profesor.tipoContratoId, [Validators.required]),
      DepartamentoId: new FormControl(this.tipoAccionLaboral === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.profesor.departamentoId, [Validators.required]),
      //contratacion: new FormControl(null, [Validators.required]),
      esActivo: new FormControl(this.tipoAccionLaboral === AccionEnum.CREAR ? false : this.datosUsuarioBusqueda.profesor.esActivo, [Validators.required]),
      rfc: new FormControl(this.tipoAccionFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.rfc, [Validators.required,Validators.minLength(12)]),
      nombre: new FormControl(this.datosUsuarioBusqueda?.nombre),
      apellidoPaterno: new FormControl(this.datosUsuarioBusqueda.apellidoPaterno),
      apellidoMaterno: new FormControl(this.datosUsuarioBusqueda.apellidoMaterno),
      CuentaDeposito: new FormControl(this.tipoAccionLaboral === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.profesor.cuentaDeposito, [Validators.minLength(16)])
    });
    this.renderizarForm = true;
    this.laboralForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
      this.profesorService.definirFormularioValidoNuevoProfesor(this.laboralForm.valid);
      this.profesorService.setChangesContrato(true);
    });
  }

  get TipoContratoId() { return this.laboralForm.get('TipoContratoId'); }
  get DepartamentoId() { return this.laboralForm.get('DepartamentoId'); }
  get esActivo() { return this.laboralForm.get('estatus'); }
  get rfc() { return this.laboralForm.get('rfc'); }
  get CuentaDeposito() { return this.laboralForm.get('CuentaDeposito'); }

  public getTipoContrato() {
    this.tipoContratoService.obtenerTipoContrato().subscribe(
      (tipoContrato: TipoContrato[]) => {
        this.tipoContratoList = tipoContrato;
      }
    );
  }

  public getDepartamentos() {
    this.departamentoService.obtenerDepartamentos().subscribe(departamentos => {
        this.departamentosList = departamentos.data;
      }
    );
  }

  //Validar si el RFC ya esta relacionado tanto con palabras como con el usuario
  public RFCInsert(rfc: string) {
    if (rfc.trim().length == 13) {
      let usuarioId = this.usuarioId;
      if (!usuarioId) {
        usuarioId = 0;
      }

      this.datoFiscalService.GetDatoFiscalByRFC(rfc, usuarioId).subscribe(
        datoFiscal => {
          if (datoFiscal == null) {
            return this.RFCencontrado = false;
          } else {
            return this.RFCencontrado = true;
          }
        }
      )
    }
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogProfesorRef.close(null);
    }
  }
}
