import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { SolicitudAdminDto, RequestTypeDTO } from 'src/app/_models/solicitud-dto';
import { MatDialog } from '@angular/material/dialog';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SolicitudSeguimientoModalComponent } from './solicitud-seguimiento-modal/solicitud-seguimiento-modal.component';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-solicitud-seguimiento',
  templateUrl: './solicitud-seguimiento.component.html',
  styleUrls: ['./solicitud-seguimiento.component.scss']
})
export class SolicitudSeguimientoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allTipoSolicitud') private allTipoSolicitud: MatOption;
  /// agregar select si se ocupa para editar o descargar Excel
  displayedColumns: string[] = ['clave', 'id', 'nombre', 'tipo', 'comentario', 'fecha', 'estatus'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(false, []);
  public request: any;
  public infoCargado: boolean = false;
  public numRequestSeleccionado: number = 0;
  private arrOriginal: SolicitudAdminDto[] = [];
  public ExcelSubscription: Subscription;
  public claveName = new FormControl();
  public tipoSolicitudF = new FormControl();
  public estatusF = new FormControl();
  public tipoSolicitudList: RequestTypeDTO[] = [];
  public filtroForm: FormGroup;

  constructor(private solicitudService: SolicitudService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private util: UtileriasService,) { }

  ngOnInit(): void {
    if (this.solicitudService.invMetodoObtenerSolicitudesAdminSubscription == undefined){
      this.solicitudService.invMetodoObtenerSolicitudesAdminSubscription = this.solicitudService.invocarObtenerSolicitudesAdmin.subscribe(() => {
        this.obtenerSolicitudes();
        this.limpiarFiltro();
      });
    }
      this.paginador.itemsPerPageLabel = "Registros por página";
      this.paginador.nextPageLabel = "Página siguiente";
      this.paginador.previousPageLabel = "Página anterior";
      this.paginador.firstPageLabel = "Primera página";
      this.paginador.lastPageLabel = "Última página";
      this.paginador.getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
          return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
    this.obtenerSolicitudes();
    this.llenarFiltros();
    this.inicializarForm();
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      claveName: new FormControl(''),
      tipoSolicitudF: new FormControl([]),
      estatusF: new FormControl([])
    });
  }

  public filtro() {
    let claveNombre: string = this.claveName.value == null ? '' :  this.claveName.value;
    let tipoSolicitud: any[] = this.tipoSolicitudF.value == null ? [] : this.tipoSolicitudF.value;
    let estatusF: any = this.estatusF.value == null ? 0 : this.estatusF.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    if (claveNombre.length > 0 || claveNombre != '') {
      filtroModificado = filtroModificado.filter(f => {
        return f.usuarioId.toString().search(claveNombre) > -1 ||
        f.nombreCompleto.toLowerCase().search(claveNombre.toLocaleLowerCase()) > -1;
      });
    }
    if(tipoSolicitud.length > 0) {
      filtroModificado = filtroModificado.filter(f => { return tipoSolicitud.indexOf(f.requestTypeId) > -1 });
    }
    if(estatusF !==0){
      if(estatusF == 1) {
        filtroModificado = filtroModificado.filter(r => { return r.completed == 1 });
      } else if (estatusF == 2) {
        filtroModificado = filtroModificado.filter(r => { return r.completed == 2 });
      } else if (estatusF == 3) {
        filtroModificado = filtroModificado.filter(r => { return r.completed == 3 });
      }
    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
  }

  tosslePerOneType(all) {
    if(this.allTipoSolicitud.selected) {
      this.allTipoSolicitud.deselect();
    }
    if(this.tipoSolicitudF.value.length == this.tipoSolicitudList.length) { this.allTipoSolicitud.select(); }
    this.filtro();
  }

  toggleAllSelectionType() {
    if(this.allTipoSolicitud.selected) {
      this.tipoSolicitudF.patchValue([...this.tipoSolicitudList.map(item => item.requestTypeId), 0]);
    } else {
      this.tipoSolicitudF.patchValue([]);
    }
    this.filtro();
  }

  public llenarFiltros() {
    this.solicitudService.obtenerSolicitudTipo().subscribe(
      (tipos: ApiResponse<RequestTypeDTO[]>) => {
        this.tipoSolicitudList = tipos.data;
        this.tipoSolicitudF.setValue([...this.tipoSolicitudList.map(item => item.requestTypeId), 0]);
        this.filtro();
    });
  }

  public limpiarFiltro() {
    this.claveName.setValue('');
    this.tipoSolicitudF.patchValue([...this.tipoSolicitudList.map(item => item.requestTypeId), 0]);
    this.estatusF.patchValue(0);
    this.obtenerSolicitudes();
  }

  public obtenerSolicitudes() {
    this.solicitudService.obtenerSolicitudes().subscribe((solicitud: ApiResponse<SolicitudAdminDto[]>) => {
      this.dataSource = new MatTableDataSource(solicitud.data);
      this.selection = new SelectionModel<any>(true, []);
      this.dataSource.paginator = this.paginator;
      this.infoCargado = true;
      this.arrOriginal = [...solicitud.data];
      console.log(solicitud.data);
    });
  }

  public verDetalle(elemento?: any): void {
    let datos: SolicitudAdminDto = {
      requestId: elemento.requestId,
      code: elemento.code,
      requestCategoryId: elemento.requestCategoryId,
      requestCategoryName: elemento.requestCategoryName,
      isLetterCategory: elemento.isLetterCategory,
      requestTypeId: elemento.requestTypeId,
      requestTypeName: elemento.requestTypeName,
      directDownload: elemento.directDownload,
      usuarioId: elemento.usuarioId,
      nombre: elemento.nombreCompleto,
      comment: elemento.comment,
      completed: elemento.completed,
      completedDate: elemento.completedDate,
      correo: elemento.correo,
      programa:  elemento.programa,
      numero: elemento.numero,
      institutionResponse: elemento.institutionResponse,
      fechaCreacion: elemento.fechaCreacion,
      tipoAccion: AccionEnum.EDITAR
    }
    const dialogo = this.util.abrirDialogoLateral(SolicitudSeguimientoModalComponent, datos);
    dialogo.afterClosed().subscribe((result) => {
      this.request = result;
    });
  }

  isAllSelected() {
    this.numRequestSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numRequestSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row))
  }

  public descargarExcel() {
    this.ExcelSubscription = this.solicitudService.downloadExcelRequest(this.arrOriginal).subscribe((res: Blob) => {
      let filename: string = "Requests.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        let a: HTMLAnchorElement = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        let blob: Blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }
}
