export class BIOGRAPHICAL_INFORMATION_MODEL {

    legalGeneroId       :   number;
    generoId            :   number;
    ciudadaniaId        :   number;
    estadoCivilId       :   number;
    curp                :   string;
    ciudadaniaPaisId    :   number;
    entidadNacimiento   :   number;
    ciudadNacimiento    :   string;

    constructor(
        legalGeneroId       :   number,
        generoId            :   number,
        ciudadaniaId        :   number,
        estadoCivilId       :   number,
        curp                :   string,
        ciudadaniaPaisId    :   number,
        entidadNacimiento   :   number,
        ciudadNacimiento    :   string
    )
    {
        this.legalGeneroId       =  legalGeneroId    ;
        this.generoId            =  generoId         ;
        this.ciudadaniaId        =  ciudadaniaId     ;
        this.estadoCivilId       =  estadoCivilId    ;
        this.curp                =  curp             ;
        this.ciudadaniaPaisId    =  ciudadaniaPaisId ;
        this.entidadNacimiento   =  entidadNacimiento;
        this.ciudadNacimiento    =  ciudadNacimiento ;
    }
  
  };