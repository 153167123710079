import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Campus } from 'src/app/_models/campus';
import { ConfiguracionReembolso } from 'src/app/_models/configuracion-reembolso';
import { Nivel } from 'src/app/_models/nivel';
import { Periodo } from 'src/app/_models/periodo';
import { CampusService } from 'src/app/_services/campus.service';
import { ConfiguracionCuotaService } from 'src/app/_services/configuracion-cuota.service';
import { NivelService } from 'src/app/_services/nivel.service';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { ModalReembolsosComponent } from './modal-reembolsos/modal-reembolsos.component';

@Component({
  selector: 'app-configuracion-reembolsos',
  templateUrl: './configuracion-reembolsos.component.html',
  styleUrls: ['./configuracion-reembolsos.component.scss']
})
export class ConfiguracionReembolsosComponent implements OnInit {
  displayedColumns: string[] = ['select', 'campus', 'nivel', 'periodo', 'fechaInicio', 'fechaFin', 'porcentaje'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);
  @ViewChild('allNivel') private allNivel: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;

  public filtroForm: FormGroup;
  public numSeleccionado: number;
  public arrOriginal: any[] = [];
  public arrCampus: Campus[] = [];
  public arrNivel: Nivel[] = [];
  public arrPeriodo: Periodo[] = [];
  public infoCargado: boolean = false;

  constructor(
    public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private campusservice: CampusService,
    private nivelservice: NivelService,
    private periodoservice: PeriodoService,
    private servicios: ConfiguracionCuotaService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    if(this.servicios.obtenerColegiaturasSubscription == undefined){
      this.servicios.obtenerColegiaturasSubscription = this.servicios.invocarObtenerReembolso.subscribe(()=>{
        this.cargarReembolso();
      })
    }
    this.limpiarFiltros();
    this.llenarFiltros();
    this.cargarReembolso();
  }

  public cargarReembolso() {
    this.servicios.getConfiguracionReembolso().subscribe(data=>{
      this.arrOriginal=data;
      this.dataSource = new MatTableDataSource(data);
       this.dataSource.paginator = this.paginator;
       this.selection = new SelectionModel<any>(true, []);
    })
  }
  
  get nombreConfig() { return this.filtroForm.get('nombre'); }
  get campusConfig() { return this.filtroForm.get('campus'); }
  get nivelConfig() { return this.filtroForm.get('nivel'); }
  get fechaConfig() { return this.filtroForm.get('fecha'); }

  limpiarFiltros() {
    this.filtroForm = new FormGroup({
      campus: new FormControl([...this.arrCampus.map(item => item.campusId), 0]),
      nivel: new FormControl([...this.arrNivel.map(item => item.nivelId), 0]),
      periodo: new FormControl(0),

    })
  }

  llenarFiltros() {
    this.campusservice.obtenerCampus().subscribe((campus: Campus[]) => {
      this.arrCampus = campus;
      this.filtroForm.controls['campus'].patchValue([...this.arrCampus.map(item => item.campusId), 0]);
      this.filtro();
    });
    this.nivelservice.getNivel().subscribe((nivel: Nivel[]) => {
      this.arrNivel = nivel;
      this.filtroForm.controls['nivel'].patchValue([...this.arrNivel.map(item => item.nivelId), 0]);
      this.filtro();
    })
    this.periodoservice.obtenerPeriodos().subscribe((periodo: Periodo[]) => {
      this.arrPeriodo = periodo;
      this.filtro();
    })
    this.servicios.getConfiguracionReembolso().subscribe((reembolso: any[]) => {
      this.arrOriginal = reembolso;
      this.filtro();
    })
  }

  public filtro() {
    let campus: any[] = this.filtroForm['value'].campus;
    let nivel: any[] = this.filtroForm['value'].nivel;
    //let programa: any[] = this.filtroForm['value'].programa;
    let periodo: number = this.filtroForm['value'].periodo;
    let arrFiltrado: any[] = [...this.arrOriginal];

    if (nivel.length > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return nivel.indexOf(f.nivelId) > -1 });
    }
    if (campus.length > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return campus.indexOf(f.campusId) > -1 });
    }
    // if (programa.length > 0) {
    //   arrFiltrado = arrFiltrado.filter(f => { return programa.indexOf(f.programaId) > -1 });
    // }
    if (periodo > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return periodo == f.periodoId });
    }
    this.dataSource = new MatTableDataSource(arrFiltrado);

  }

  isAllSelected() {
    this.numSeleccionado = this.selection.selected.length;
    const numRows = this.arrOriginal.length;
    return this.numSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.UsuarioId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() :
      this.arrOriginal.forEach(row => this.selection.select(row));

  }

  tosslePerOneNivel(all) {
    if (this.allNivel.selected) {
      this.allNivel.deselect();
      return false;
    }
    if (this.filtroForm['value'].periodo.length == this.arrNivel.length) { this.allNivel.select(); }
    this.filtro();
  }

  tosslePerOneCampus(all) {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.filtroForm['value'].campus.length == this.arrCampus.length) { this.allCampus.select(); }
    this.filtro();
  }
  toggleAllSelectionNivel() {
    if (this.allNivel.selected) {
      this.filtroForm.controls['nivel'].patchValue([...this.arrNivel.map(item => item.nivelId), 0]);
    } else {
      this.filtroForm.controls['nivel'].patchValue([]);
    }
    this.filtro();
  }
  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.filtroForm.controls['campus'].patchValue([...this.arrCampus.map(item => item.campusId), 0]);
    } else {
      this.filtroForm.controls['campus'].patchValue([]);
    }
    this.filtro();
  }

  public openReembolso(elemento: any = null) {
    this.util.abrirDialogoLateral(ModalReembolsosComponent, elemento);
  }

  
  ExcelSubscription: Subscription;
  public downloadExcel(): void {
    this.ExcelSubscription = this.servicios.downloadExcelConfiguracionReembolso(this.arrOriginal).subscribe((res: Blob) => {
      var filename = "Consultar_Reembolso.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }
  
  // public exportExcelAsistencia () {
  //   this.servicios.downloadExcelConfiguracionReembolso().subscribe((reembolso: any[]) => {
  //     this.snackBar.open("Se ha descargado correctamente el excel.", "OK", { duration: 5000 });
  //   });
  // }

  public Eliminar() {
    let eliminado: any = this.selection.selected[0];
    let configuracionReembolso: ConfiguracionReembolso = new ConfiguracionReembolso();
    configuracionReembolso.CampusId = eliminado.campusId;
    configuracionReembolso.NivelId = eliminado.nivelId;
    configuracionReembolso.PeriodoId = eliminado.periodoId;
    configuracionReembolso.FechaInicio = eliminado.fechaInicio;
    configuracionReembolso.FechaFin = eliminado.fechaFin;
    configuracionReembolso.Porcentaje = eliminado.porcentaje;
    this.servicios.eliminarConfiguracionReembolso(configuracionReembolso).subscribe((dato: boolean) => {
      if (dato) {
        this.servicios.metodoObtenerReembolso();
        this.snackBar.open('Se ha eliminado el porcentaje de colegiatura correctamente.', 'OK', { duration: 5000 });
      } else {
        this.snackBar.open('Ha ocurrido un error al eliminar el porcentaje de colegiatura. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    })
  }
  

}
