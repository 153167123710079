import { SnackService } from './../../services/snack-service.service';
import { TraductorService } from './../../_services/traductor.service';
import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AccionEnum } from '../../_models/accion.enum';
import { AreaMateria } from 'src/app/_models/area-materia';
import { AreaMateriaService } from 'src/app/_services/area-materia-service';

@Component({
  selector: 'app-modal-area-materia',
  templateUrl: './modal-area-materia.component.html',
  styleUrls: ['./modal-area-materia.component.scss']
})
export class ModalAreaMateriaComponent implements OnInit {

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public errorActualizaDepartamento: string = "";
  public departamentoID: number = 0;
  public dialogoRefAdver: any;
  public boolCambio = false;
  public edicionForm: FormGroup;
  public departamento: AreaMateria;

  constructor(public dialogRef: MatDialogRef<ModalAreaMateriaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AreaMateria,
    private areaMateriaService: AreaMateriaService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private snackService: SnackService,
    private traductorService: TraductorService
  ) {
    this.boolCambio = false;
    this.inicializarForm();
  }

  ngOnInit(): void {
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      areaMateriaId: new FormControl(this.data?.areaMateriaId),
      clave: new FormControl(this.data?.clave, [Validators.required]),
      nombre: new FormControl(this.data?.nombre, [Validators.required])
    });
    this.edicionForm.valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
  }
  get clave() { return this.edicionForm.get('clave'); }
  get nombre() { return this.edicionForm.get('nombre'); }

  public cambiarVistaEditar(servicioId: number) {
    this.boolCambio = false;
    this.data = Object.assign(this.edicionForm.value);
    this.data.tipoAccion = 2;
    this.data.areaMateriaId = servicioId;
  }

  public enviar(): void {
    switch (this.data.tipoAccion) {
      case AccionEnum.CREAR:
        this.data = Object.assign(this.edicionForm.value);
        this.areaMateriaService.agregarAreaMateria(this.data).subscribe(
          idServicio => {
            if (idServicio > 0) {
              this.areaMateriaService.invocarMetodoObtenerAreaMateria();
              let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
              this.snackService.mostrarSnackBack(mensaje);
              this.boolCambio = false;
              this.cambiarVistaEditar(idServicio);
              this.inicializarForm();
            } else {
              let mensaje: string = this.traductorService.translate("_area-materia.guardado-incorrecto");
              this.snackService.mostrarSnackBack(mensaje);
              this.data.tipoAccion = 3;
            }
          }, error => {
            let mensaje: string = this.traductorService.translate("_area-materia.guardado-incorrecto");
            this.snackService.mostrarSnackBack(mensaje);
          }
        );
        break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionForm.value);
        this.data.tipoAccion = 2;
        this.areaMateriaService.modificarAreaMateria(this.data).subscribe(
          editado => {
            if (editado) {
              this.areaMateriaService.invocarMetodoObtenerAreaMateria();
              let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
              this.snackService.mostrarSnackBack(mensaje);
              this.boolCambio = false;
            } else {
              let mensaje: string = this.traductorService.translate("_area-materia.guardado-incorrecto");
              this.snackService.mostrarSnackBack(mensaje);
            }
          }, error => {
            let mensaje: string = this.traductorService.translate("_area-materia.guardado-incorrecto");
            this.snackService.mostrarSnackBack(mensaje);
          }
        );
        break;
    }
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }

}
