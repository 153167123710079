import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { aceptarDialogo } from '../models/customEntities/aceptarDialogo.model';
import { TextosUndoChanges } from '../models/customEntities/textosUndoChanes.mode';
import { ConfirmDialogComponentComponent } from '../_components/confirm-dialog-component/confirm-dialog-component.component';
import { UndoChangesConfirmationDialogComponent } from '../_components/undo-changes-confirmation-dialog/undo-changes-confirmation-dialog.component';
import { TraductorService } from '../_services/traductor.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogServiceService {

  constructor(public dialog: MatDialog,
    private traductorService: TraductorService) {
  }
  /**
   * Recice un array de string con los campos a mostrar en la vista,
   * así sea sólo un elemento recibe un array. Es usado para cuando
   * se quiere mostrar en qué componentes hay cambios pendientes.
   * @param texto
   * @returns
   */
  public abrirModal(texto: Array<string>) {
    return this.dialog.open(ConfirmDialogComponentComponent, {
      data: texto,
      disableClose: true,
      panelClass: 'full-width-dialog'
    });
  }
  /**
   * Abre un modal para indicar que una condición a la que el usuario puede aceptar o negar.
   * Recibe obligatoriamente un encabezado que es el texto que se mostrará entre ¿? y será el texto principal.
   * Recibe un parámetro opcional que es un texto descritivo para esclarecer alguna duda, sólo si es necesario.
   * Si los mensajes para los botones sí y no necesitan mostrar otro texto, se pueden enviar los parámetros que aparezcan
   * en ellos, si no se envía nada, por derecto aparecerá Sí y No
   * @param encabezado
   * @param textoDescriptivo
   */
  public abrirModalDescartarCambios(encabezado: string, textoDescriptivo?: string, textoSi = 'si', textoNo = 'no') {
    let mensajeSi: string = this.traductorService.translate('_botones.si');	
    let mensajeNo: string = this.traductorService.translate('_botones.no');	
    let textosDialogData = new TextosUndoChanges();
    textosDialogData.encabezado = `¿${encabezado}?`;
    textosDialogData.textoSi = mensajeSi;
    textosDialogData.textoNo = mensajeNo;
    if (textoDescriptivo) {
      textosDialogData.textoDescriptivo = textoDescriptivo;
    }
    return this.dialog.open(UndoChangesConfirmationDialogComponent, {
      data: textosDialogData,
      disableClose: true,
      panelClass: 'full-width-dialog'
    });
  }
  /**
   * Muestra en pantalal un modal para que el usuario acepte o declique una acción
   * @param encabezado
   * @param textoSi
   * @param textoNo
   * @returns
   */
  public aceptarDialog(encabezado: string, textoSi = 'si', textoNo = 'no'):Promise<boolean>{
    let mensajeSi: string = this.traductorService.translate('_botones.si');	
    let mensajeNo: string = this.traductorService.translate('_botones.no');	
    let textosDialogData = new aceptarDialogo();
    textosDialogData.encabezado = encabezado;
    textosDialogData.textoSi = mensajeSi;
    textosDialogData.textoNo = mensajeNo;
    return this.dialog.open(UndoChangesConfirmationDialogComponent, {
      data: textosDialogData,
      disableClose: true
    }).afterClosed().toPromise();
  }
}
