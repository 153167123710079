import { AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { SnackService } from '@app/services/snack-service.service';
import { AsignacionMasivaBloqueDto } from '@app/_models/AsignacionMasivaBloqueDto';
import { Block } from '@app/_models/block';
import { BlockLabelDto } from '@app/_models/BlockLabelDto';
import { BlockStudent } from '@app/_models/blockStudent';
import { BlockService } from '@app/_services/block.service';
import { TraductorService } from '@app/_services/traductor.service';
import { I18NextPipe } from 'angular-i18next';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-seguimiento-asignar-bloque-modal',
  templateUrl: './seguimiento-asignar-bloque-modal.component.html',
  styleUrls: ['./seguimiento-asignar-bloque-modal.component.scss']
})
export class SeguimientoAsignarBloqueModalComponent implements OnInit, AfterViewInit {
  @ViewChild('dialogoCerrarModalCargo') dialogoCerrarModalCargo: TemplateRef<'dialogoCerrarModalCargo'>;

  //#region  Propiedades
  public PeriodoClave: string;
  public PeriodoId: number;
  public PeriodoNombre: string;
  public CampusId: number;
  public CampusNombre: string;
  public BloqueNombre: string;
  public BlockLabelId: number;
  public Capacidad: number;
  public NumeroGrupos: number;
  public Existe: boolean;
  public permiteParcialidades: boolean = false;
  public listBlockStudent: BlockStudent[];
  public AsignacionMasivaBloqueDto: AsignacionMasivaBloqueDto;
  //#endregion

  public dialogoRefCerrarModalCargo: MatDialogRef<any>;


  //#region  Mat Table
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'id',
    'nombre',
    'programa',
    'ciclo'
  ];
  //#endregion
  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion





  constructor(@Inject(MAT_DIALOG_DATA) public data: { alumnos: any[], blockLabel: BlockLabelDto, block: Block, existe: boolean },
    private snackService: SnackService,
    private i18nextPipe: I18NextPipe,
    public dialogRef: MatDialogRef<SeguimientoAsignarBloqueModalComponent>,
    private _BlockService: BlockService,
    private traductorService: TraductorService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    console.log("this.data.alumnos", this.data.alumnos);
    this.dataSource = new MatTableDataSource(this.data.alumnos);

  }


  ngAfterViewInit(): void {
    if (this.data.existe) {
      this.PeriodoClave = this.data.block.periodoClave;
      this.PeriodoNombre = this.data.block.periodoNombre;
      this.PeriodoId = this.data.block.periodoId;
      this.CampusId = this.data.block.campusId;
      this.CampusNombre = this.data.block.campusNombre;
      this.BloqueNombre = this.data.block.bloqueNombre;
      this.BlockLabelId = this.data.block.blockLabelId;
      this.Capacidad = this.data.block.capacidad;
      this.NumeroGrupos = this.data.block.numeroGrupos;
      this.Existe = this.data.existe;
    } else {
      this.PeriodoClave = this.data.alumnos[0].priodoIngresoNavigation.clave;
      this.PeriodoNombre = this.data.alumnos[0].priodoIngresoNavigation.nombre;
      this.PeriodoId = this.data.alumnos[0].periodoId;
      this.CampusNombre = this.data.alumnos[0].campus.nombre;
      this.CampusId = this.data.alumnos[0].campusId;
      this.BloqueNombre = this.data.blockLabel.name;
      this.BlockLabelId = this.data.blockLabel.blockLabelId;
      this.Capacidad = this.data.blockLabel.capacity;
      this.NumeroGrupos = 0;
      this.Existe = this.data.existe;
    }
    if (this.NumeroGrupos === this.Capacidad) {
      this.snackService.mostrarSnackBack(this.translate("_grupos.sin-cupo"));
    }
  }


  //#region Translate Method
  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }
  //#endregion

  public guardar(): void {

    this.AsignacionMasivaBloqueDto = new AsignacionMasivaBloqueDto();

    this.AsignacionMasivaBloqueDto.existe = this.Existe;
    this.AsignacionMasivaBloqueDto.blockId = this.data.block?.blockId;
    this.AsignacionMasivaBloqueDto.blockLabelId = this.BlockLabelId;
    this.AsignacionMasivaBloqueDto.periodoId = this.PeriodoId;
    this.AsignacionMasivaBloqueDto.campusId = this.CampusId;

    this.AsignacionMasivaBloqueDto.alumnos = this.data.alumnos.map(alumno => alumno.alumnoId);


    this.Subscription.add(
      this._BlockService.AsignacionMasivaBloqueAlumno(this.AsignacionMasivaBloqueDto).subscribe(
        (respuesta: ApiResponse<BlockStudent[]>) => {
          if (respuesta.success) {
            this.snackService.mostrarSnackBack(this.translate("_grupos.exito-asignacion-masiva"));
            this.CerrarModal();
          } else {
            this.snackService.mostrarSnackBack(respuesta.message);
          }
        }
      )
    );
  }

  public Inscribir() {

    this.CerrarModal();

    this.AsignacionMasivaBloqueDto = new AsignacionMasivaBloqueDto();

    this.AsignacionMasivaBloqueDto.existe = this.Existe;
    this.AsignacionMasivaBloqueDto.blockId = this.data.block?.blockId;
    this.AsignacionMasivaBloqueDto.blockLabelId = this.BlockLabelId;
    this.AsignacionMasivaBloqueDto.periodoId = this.PeriodoId;
    this.AsignacionMasivaBloqueDto.campusId = this.CampusId;

    this.AsignacionMasivaBloqueDto.alumnos = this.data.alumnos.map(alumno => alumno.alumnoId);



    this._BlockService.AsignacionMasivaBloqueAlumno(this.AsignacionMasivaBloqueDto).subscribe(
      (respuesta: ApiResponse<BlockStudent[]>) => {
        if (respuesta.success) {

          this.listBlockStudent = respuesta.data;

          this._BlockService.ExisteColegiaturaCostoDisponibleAlumno(this.listBlockStudent).subscribe(response => {
            if (response.success) {

              this.permiteParcialidades = response.data;

              this._BlockService.InscribirAGruposBloque(this.listBlockStudent).subscribe(response => {
                if (response.success) {

                  this.abrirModalTipoCargo();
                } else {
                  let mensaje: string = this.traductorService.translate(response.message);
                  this.snackService.mostrarSnackBack(mensaje);
                }
              })
            } else {
              this.snackService.mostrarSnackBack(response.message);
            }
          });
        } else {
          this.snackService.mostrarSnackBack(respuesta.message);
        }
      }
    )
  }


  public abrirModalTipoCargo() {
    this.dialogoRefCerrarModalCargo = this.dialog.open(this.dialogoCerrarModalCargo, { disableClose: true });
  }


  public cerarModalCerrarModalCargo() {
    this.dialogoRefCerrarModalCargo.close();
  }

  //#region Modal Close
  public CerrarModal() {
    this.dialogRef.close();
  }
  //#endregion





}
