import { SnackService } from './../../services/snack-service.service';
import { TraductorService } from './../../_services/traductor.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CampusService } from '@app/_services/campus.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-campus-eliminar',
  templateUrl: './campus-eliminar.component.html',
  styleUrls: ['./campus-eliminar.component.scss']
})
export class CampusEliminarComponent implements OnInit {

  public numCampusSeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public claveCampus: string = '';

  constructor(public dialogRef: MatDialogRef<CampusEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private campusService: CampusService,
    private snackService: SnackService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numCampusSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(c => (c.campusId.toString()));
      this.claveCampus = arrayClaves.toString();
    } else {
      this.claveCampus = this.data.campusId.toString();
    }
    this.campusService.eliminarCampus(this.claveCampus).subscribe(
      eliminado => {
        if (eliminado) {
          this.campusService.invocarMetodoObtenerCampus();
          let mensaje: string = this.traductorService.translate('eliminarCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
          this.dialogRef.close(this.data);
        } else {
          let mensaje: string = this.traductorService.translate('errorCampusEliminar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      },
      error => {
        let mensaje: string = this.traductorService.translate('errorGuardar');
        this.snackService.mostrarSnackBack(mensaje);
      }
    );
  }

}
