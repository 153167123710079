import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { GenericHttpService } from '@app/clean-architecture/infrastructure/services/generic-http.service';
import { DataTable } from './../../../../core.domain/models/data-table/data-table.models';
import { DataTableColumn } from './../../../../core.domain/models/data-table/data-table-column.models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TraductorService } from './../../../../../_services/traductor.service';
import { SnackService } from './../../../../../services/snack-service.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-decline-enrollment',
  templateUrl: './decline-enrollment.component.html',
  styleUrls: ['./decline-enrollment.component.scss']
})
export class DeclineEnrollmentComponent implements OnInit {
   
  //#region Table  
  public RowSelected        : any[] = [];
  public dataTable: DataTable<any>;
  readonly COLUMN_TABLE: DataTableColumn[] = [
    { name: 'Clave'   , prop: 'clave'   ,flex: 30, },
    { name: 'Nombre'  , prop: 'nombre'  ,flex: 70, customColumClass: 'text-left'   },    
  ];
  //#endregion

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  constructor(public dialogRef: MatDialogRef<DeclineEnrollmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _GenericHttpService: GenericHttpService,
    private _snackService: SnackService,
    private _traductorService: TraductorService,
    ) { }

  ngOnInit(): void {    
    this.dataTable = {
      options: {
        hasCheckbox: true,
      },
      data: [...this.data.data]
    };
  }
  
  //#region Modal Close
  public CerrarModal() {
    this.dialogRef.close();
  }
  //#endregion

  public getRowSelected($event: any):void{
    this.RowSelected = $event;
    console.log("$event", $event);  
  }

  public DeclineEnrollment():void
  {
    this.Subscription.add(
      this._GenericHttpService.get<SimpleResponse>(`Inscripcion/DeclineEnrollment/${this.data.periodoId}/${this.data.alumnoId}`).subscribe(
        response => 
         {
          this._snackService.mostrarSnackBack("se ha guardado con éxito.");
          this.CerrarModal()
        }
      )
    );
  }
}

