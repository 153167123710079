import { Component, OnInit, Inject } from '@angular/core';
import { MateriaService } from '@app/_services/materia.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';

@Component({
  selector: 'app-programa-eliminar-materias',
  templateUrl: './programa-eliminar-materias.component.html',
  styleUrls: ['./programa-eliminar-materias.component.scss']
})
export class ProgramaEliminarMateriasComponent implements OnInit {
  public numMateriaSeleccionados = 0;
  public boolIsArray = false;
  public claveMateria = '';

  constructor( public dialogRef: MatDialogRef<ProgramaEliminarMateriasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private materiaService: MateriaService,
    private programaAcademicoService: ProgramaAcademicoService
  ) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numMateriaSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(c => (c.materiaId.toString()));
      this.claveMateria = arrayClaves.toString();
    } else {
      this.claveMateria = this.data.materiaId.toString();
    }
    this.materiaService.eliminarMateriasMultiples(this.claveMateria).subscribe(
    eliminado => {
      if (eliminado) {
        //this.programaAcademicoService.invocarMetodoObtenerCiclos();
        this.programaAcademicoService.invocarMetodoCiclos();
        this.dialogRef.close();
        this.snackBar.open('Se ha eliminado la materia.', 'OK', { duration: 5000 });
      } else {
        this.snackBar.open('Ha ocurrido un error al eliminar la materia. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    },
    error => {
    this.snackBar.open('Ha ocurrido un error al eliminar la materia. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
    });
  }
}
