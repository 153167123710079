export class TipoServicio {
    /**
     * Identificador del tipo de servicio
     */
    servicioId: number;

    /**
     * Identificador de la categoria a la que pertenece el tipo de servicio
     */
    categoriaId: number;

    /**
     * Clave del tipo de servicio
     */
    clave: string;

    /**
     * Nombre del tipo de servicio
     */
    nombre: string;

    /**
     * Descripcion del tipo de servicio
     */
    descripcion: string;

    /**
     * Estatus del tipo de servicio
     */
    estatus: boolean;

    /**
     * Tipo de accion que tendra el registro
     */
    tipoAccion?: number;
}
