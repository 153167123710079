import { Pipe, PipeTransform } from '@angular/core';
import i18next from 'node_modules/i18next';

@Pipe({
  name: 'horario'
})
export class HorarioPipe implements PipeTransform {

  transform(horaInicio: string, horaFin: string): string {
    if ((horaInicio == null || horaFin == null) ||
    (horaInicio.length == 0 || horaFin.length == 0) ||
    (horaInicio == '' || horaFin == ''))
      return i18next.t('_grupos.sin_horario');
    return `${horaInicio} - ${horaFin}`;
  }

}
