import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { CuestionarioArea } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { Recurso } from 'src/app/_models/recurso';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';

@Component({
  selector: 'app-modal-importar-examen',
  templateUrl: './modal-importar-examen.component.html',
  styleUrls: ['./modal-importar-examen.component.scss']
})
export class ModalImportarExamenComponent implements OnInit {
  public examenesRecursos: Recurso[] = [];
  public examenesRecursosCompleto: Recurso[] = [];
  public recursoId;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ensenanzaApredServ: EnsenanzaAprendizajeService,
    public dialogRef: MatDialogRef<ModalImportarExamenComponent>,
    private snackService: SnackService
  ) { }

  ngOnInit(): void {

    this.data.forEach(element => {
      element.selected = false;
    });

    this.examenesRecursos = this.data;
    this.examenesRecursosCompleto = this.data;

  }

  onChange(event) {
    this.recursoId = event.option._value;
    this.examenesRecursos.forEach(element => {
      if (element.recursoId == event.option._value) {
        element.selected = true;
      } else {
        element.selected = false;
      }

    });
  }

  onKey(value) {
    this.examenesRecursos = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.examenesRecursosCompleto.filter(option => option.nombre.toLowerCase().startsWith(filter));
  }

  public importarExamen() {
    this.ensenanzaApredServ.getEstructuraExamen(this.recursoId).subscribe((estructura: ApiResponse<CuestionarioArea[]>) => {
      if (estructura.data.length > 0) {
        this.ensenanzaApredServ.definirEstructuraExamen(estructura.data);
        this.ensenanzaApredServ.invocarMetodoObtenerEstructura();
        this.dialogRef.close()
      }else{
        this.snackService.mostrarSnackBack('No se encuentro ninguna estructura.');
      }
    })
  }
}
