import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { HistorialCalFinal } from 'src/app/_models/HistorialCalFinal';
import { MateriaCursada } from 'src/app/_models/materia-cursada';
import { Periodo } from 'src/app/_models/periodo';
import { AdminAcademicaService } from 'src/app/_services/admin-academica.service';
import { Alumno } from '../../../_models/inscripcion';
import { ModalCambioCalificacionComponent } from '../CambioCalificaciones/modal-cambio-calificacion/modal-cambio-calificacion.component';

@Component({
  selector: 'app-cambio-calificaciones',
  templateUrl: './cambio-calificaciones.component.html',
  styleUrls: ['./cambio-calificaciones.component.scss']
})
export class CambioCalificacionesComponent implements OnInit,OnDestroy {
  
  //#region Observable
  private Subscription: Subscription = new Subscription();
  //#endregion
  public Periodos: Periodo[];
  public periodoSeleccionado: number = 0;
  public MateriaCursadaSeleccionado: MateriaCursada ;
  public MateriasCursadas:Array<MateriaCursada>=[]; 
  public HistorialCalFinal:Array<HistorialCalFinal>;
  public IsHistorialCC: boolean = true;
  public FormValido: boolean = false;
  public nombreProfesor: string = "";
  @Input() data: {alumno: Alumno, indexTap:number};
  dataSource: MatTableDataSource<HistorialCalFinal>;
  displayedColumns: string[] = [
    'Sec',
    'Final',
    'Motivo',
    'Fecha',
    'Usuario'];

  constructor(
    private adminAcademiaService: AdminAcademicaService,
    private dialog: MatDialog
  ) { }
  
  ngOnInit(): void {
    this.ObtenerPeriodos();
    this.ObtenerMateriasCursadas();
  }
  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
  }

  public ValidarForm(){
    if (this.MateriaCursadaSeleccionado != null) {
      this.FormValido = true;
    }
  }
/************************************************************************************************************************/
  public ObtenerPeriodos(): void{
    this.Subscription.add(
      this.adminAcademiaService.obtenerPeriodosByAlumno(this.data.alumno.alumnoId).subscribe((Periodos: ApiResponse<any>) => {
        this.Periodos = Periodos.data;
      })
    );
  }

  public SeleccionarPeriodo(): void{  
    this.ObtenerMateriasCursadas();
  }
/************************************************************************************************************************/

  public ObtenerMateriasCursadas(): void{
    this.Subscription.add(
      this.adminAcademiaService.MateriasCursadasPorPeriodoYAlumno(this.data.alumno.alumnoId, this.periodoSeleccionado).subscribe((MateriaCursada) => {
        this.MateriasCursadas = MateriaCursada;
        if(this.MateriasCursadas!=null || this.MateriasCursadas!=undefined){          
        }
      })
    );
  }

  public SeleccionarMateriaCursada():void{    
    this.NombreProfesor();
    this.HistorialCalificacionFinal();
  }

  public NombreProfesor(): void{    
    this.Subscription.add(

      this.adminAcademiaService.NombreProfesor(
        this.MateriaCursadaSeleccionado.grupoId, 
        this.MateriaCursadaSeleccionado.periodoId, 
        this.MateriaCursadaSeleccionado.mOId
      ).subscribe((nombre) => {
        this.nombreProfesor = nombre.nombreCompleto;
      })
    );  
  }

  public AbrirModal(){   
    let data = [];
    data[0] = this.data.alumno.alumnoId;
    data[1] = this.MateriaCursadaSeleccionado.grupoId;
    data[2] = this.MateriaCursadaSeleccionado.mOId;
    data[3] = this.MateriaCursadaSeleccionado.periodoNombre;
    data[4] = this.MateriaCursadaSeleccionado.NombreConClave;
    data[5] = this.data.alumno.programaId;
    data[6] = this.data.alumno.usuario.institucionId;
    
    const dialogRef = this.dialog.open(ModalCambioCalificacionComponent, { data: data, disableClose: true });

    dialogRef.afterClosed().subscribe(result => {      
      this.HistorialCalificacionFinal();
    });
  }
/************************************************************************************************************************/


public HistorialCalificacionFinal(): void{
  this.Subscription.add(
    this.adminAcademiaService.HistorialCalificacionFinal(this.data.alumno.alumnoId, this.MateriaCursadaSeleccionado.mOId,this.MateriaCursadaSeleccionado.periodoId).subscribe((HistorialCalFinal) => {
      if(HistorialCalFinal.length > 0){                  
        this.IsHistorialCC = true;
        this.HistorialCalFinal = HistorialCalFinal;
        this.dataSource = new MatTableDataSource(this.HistorialCalFinal);
      }else{
        this.IsHistorialCC = false;
      }
    })
  );
}

}
