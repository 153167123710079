import { TraductorService } from './../../../../_services/traductor.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { SolicitudApoyoFinancieroService } from '../../../../_services/solicitud-apoyo-financiero.service';

@Component({
  selector: 'app-apoyo-eliminar-asignar-solicitud',
  templateUrl: './apoyo-eliminar-asignar-solicitud.component.html',
  styleUrls: ['./apoyo-eliminar-asignar-solicitud.component.scss']
})
export class ApoyoEliminarAsignarSolicitudComponent implements OnInit {

  public numSolicitudesSeleccionadas: number = 0;
  public clavesSolicitudes: string = '';
  public tipoAccion: string = '';
  public infoCargada: boolean = false;
  public mensajeMultipleSolicitud: string = '';
  public mensajeConfirmacion: string = '';
  public motivoCancelacionForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ApoyoEliminarAsignarSolicitudComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private solicitudApoyoFinancieroService: SolicitudApoyoFinancieroService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    this.numSolicitudesSeleccionadas = this.data.elementos.length;
    if (this.numSolicitudesSeleccionadas === 1) {
      this.mensajeMultipleSolicitud = 'solicitud';
      this.mensajeConfirmacion = 'la solicitud seleccionada';
    } else {
      this.mensajeMultipleSolicitud = 'solicitudes';
      this.mensajeConfirmacion = 'las solicitudes seleccionadas';
    }
    this.tipoAccion = this.data.tipoAccion;
    if (this.tipoAccion == 'ELIMINAR') {
      this.inicializarForm();
    }
  }

  public inicializarForm() {
    this.motivoCancelacionForm = new FormGroup({
      motivoCancelacion: new FormControl('', [Validators.required])
    });
  }

  get motivoCancelacion() { return this.motivoCancelacionForm.get('motivoCancelacion'); }

  public solicitudEliminarAsignar() {
    let motivo = "";
    this.tipoAccion = this.data.tipoAccion;
    if (this.tipoAccion == 'ELIMINAR') {
       motivo = this.motivoCancelacionForm.value;
    }
    let arrayClaves = this.data.elementos.map(s => (s.solicitudApoyoFinancieroId.toString()));
    this.clavesSolicitudes = arrayClaves.toString();
    this.solicitudApoyoFinancieroService.actualizarEstatusSolicitud(this.tipoAccion, this.clavesSolicitudes, motivo).subscribe(
      (respuesta: SimpleResponse) => {
        if (respuesta.success) {
          this.solicitudApoyoFinancieroService.metodoObtenerSolicitudes();
          let mensaje: string = this.traductorService.translate('guardadoCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
          this.dialogRef.close();
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      }
    );
  }

}
