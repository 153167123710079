export enum Municipios {
  Abasolo = 1,
  Agualeguas = 2,
  Aldamas = 3,
  Allende = 4,
  Anáhuac = 5,
  Apodaca = 6,
  Aramberri = 7,
  Bustamante = 8,
  'Cadereyta Jiménez' = 9,
  Carmen = 10,
  Cerralvo = 11,
  'Ciénega de Flores' = 12,
  China = 13,
  'Doctor Arroyo' = 14,
  'Doctor Coss' = 15,
  'Doctor González' = 16,
  Galeana = 17,
  García = 18,
  'San Pedro Garza García' = 19,
  'General Bravo' = 20,
  'General Escobedo' = 21,
  'General Terán' = 22,
  'General Treviño' = 23,
  'General Zaragoza' = 24,
  'General Zuazua' = 25,
  Guadalupe = 26,
  'Los Herreras' = 27,
  Higueras = 28,
  Hualahuises = 29,
  Iturbide = 30,
  Juárez = 31,
  'Lampazos de Naranjo' = 32,
  Linares = 33,
  Marín = 34,
  'Melchor Ocampo' = 35,
  'Mier y Noriega' = 36,
  Mina = 37,
  Montemorelos = 38,
  Monterrey = 39,
  Parás = 40,
  Pesquería = 41,
  'Los Ramones' = 42,
  Rayones = 43,
  'Sabinas Hidalgo' = 44,
  'Salinas Victoria' = 45,
  'San Nicolás de los Garza' = 46,
  Hidalgo = 47,
  'Santa Catarina' = 48,
  Santiago = 49,
  Vallecillo = 50,
  Villaldama = 51,
}
