export class ConfiguracionReembolso { 
    PeriodoId?:number;
    CampusId?:number;
    NivelId?:number;
    PeriodoNombre:string;
    CampusNombre:string;
    NivelNombre:string;
    FechaInicio:any;
    FechaFin:any;
    Porcentaje:number;
    UsuarioModificacion:number;

}
