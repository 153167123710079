import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EdificioService } from 'src/app/_services/edificio.service';

@Component({
  selector: 'app-edificio-eliminar',
  templateUrl: './edificio-eliminar.component.html',
  styleUrls: ['./edificio-eliminar.component.scss']
})
export class EdificioEliminarComponent implements OnInit {

  public numEdificiosSeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public claveEdificio = '';

  constructor(
    public dialogRef: MatDialogRef<EdificioEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private edificioService: EdificioService
  ) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numEdificiosSeleccionados = this.data.length;
    }
  }

    public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(e => (e.edificioId.toString()));
      this.claveEdificio = arrayClaves.toString();
    } else {
      this.claveEdificio = this.data.edificioId.toString();
    }
    this.edificioService.eliminarEdificio(this.claveEdificio).subscribe(
      eliminado => {
        if (eliminado) {
          this.edificioService.invocarMetodoObtenerEdificio();
          this.snackBar.open("Se ha eliminado la información.", "OK", { duration: 5000 });
          this.dialogRef.close(this.data);
        } else {
          this.snackBar.open("Ha ocurrido un error al eliminar el edificio. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        this.snackBar.open("Ha ocurrido un error al eliminar el edificio. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

}
