export class ColegiaturaCosto {
    /// <summary>
    /// El periodo que tiene registrado en la colegiatura
    /// </summary>
    periodoId?: number;

    /// <summary>
    /// EL programa al que esta ligado
    /// </summary>
    programaId?: number;

    /// <summary>
    /// El campus al que esta ligado
    /// </summary>
    campusId?: number;

    /// <summary>
    /// El nivel al que esta ligado
    /// </summary>
    nivelId?: number;

    /// <summary>
    /// El nombre del periodo ligado
    /// </summary>
    PeriodoNombre: string;

    /// <summary>
    /// El nombre del programa ligado
    /// </summary>
    ProgramaNombre: string;

    /// <summary>
    /// La clave del tipo de cobro
    /// </summary>
    claveTipoCobro: string;

    /// <summary>
    /// El nombre del campus ligado
    /// </summary>
    CampusNombre: string;

    /// <summary>
    /// El nombre del nivel ligado
    /// </summary>
    NivelNombre: string;

    /// <summary>
    /// Define el dia de vencimiento de la colegiatura
    /// </summary>
    diaVencimiento: number;

    /// <summary>
    /// Define la fecha de vencimiento de la colegiatura
    /// </summary>
    fechaVencimiento: any;

    /// <summary>
    /// Define la fecha de inicio de la colegiatura
    /// </summary>
    fechaInicio: any;

    /// <summary>
    /// Define la fecha fin de la colegiatura
    /// </summary>
    fechaFin: any;

    /// <summary>
    /// Define si el pago sera por parcialidades
    /// </summary>
    parcialidades: boolean;

    /// <summary>
    /// Es el costo que tendrá la colegiatura
    /// </summary>
    costo: number;

    /// <summary>
    /// Define los impuestos agregados a la colegiatura
    /// </summary>
    impuesto: number;

    /// <summary>
    /// Define los intereses agregados a la colegiatura
    /// </summary>
    intereses: number;

    /// <summary>
    /// Obtiene el usuario que modifico los datos
    /// </summary>
    UsuarioModificacion: number;

    /**
     * Bandera que funciona para saber que fecha de vencimientos usar
     */
    diaTransaccion: boolean;
}
