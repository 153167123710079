import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegistroService } from '@app/_services/registro.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {

  public cuentaGenerada: boolean = false;
  public errorCrearCuenta: string = "";

  public registroForm: FormGroup = new FormGroup({
    correo: new FormControl('', [Validators.required, Validators.email]),
    nombre: new FormControl('', [Validators.required]),
    apellidoPaterno: new FormControl('', [Validators.required]),
    apellidoMaterno: new FormControl(''),
    fechaNacimiento: new FormControl('', [Validators.required]),
    aceptaTerminos: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    passwordConfirm: new FormControl('', [Validators.required])
  });

  constructor(
    private registroService: RegistroService
  ) { }

  ngOnInit(): void {
  }

  public enviar(): void {
    this.errorCrearCuenta = "";

    this.registroService.registraCuenta(
      this.registroForm.get("correo").value,
      this.registroForm.get("nombre").value,
      this.registroForm.get("apellidoPaterno").value,
      this.registroForm.get("apellidoMaterno").value,
      this.registroForm.get("fechaNacimiento").value,
      this.registroForm.get("password").value
    ).subscribe(guardado => {
      if (!!guardado) {
        this.cuentaGenerada = true;
      } else {
        this.errorCrearCuenta = "Ocurrió un error al crear la cuenta. Por favor, intenta más tarde.";
      }
    });
  }

}
