import { MateriaOferta } from "./materia-oferta";

export class Materia {
  materiaId: number;
  cicloId: number;
  clave: string;
  nombre: string;
  seriacion: string;
  creditos: number;
  hfe: number;
  hei: number;
  th: number;
  duracion: number;
  tipoDuracionId: number;
  tipoInstalacionId: number;
  tipoMateriaId: number;
  areaMateriaId: number;
  tipoImparticionId: number;
  tipoAccion?: number;
  sumaCreditos?: number;
  materiaOferta?: MateriaOferta[];
  materiasEnviar?: any;
  seriacionMateria?: any;
  idPrograma: number;      
  clavePrograma: string;
  imagenMateria: string;
  descripcion: string;
}
