import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportesService } from 'src/app/_services/reportes.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { AlumnosInscritosDTO, ProgramaNivelDTO, AlumnoProgramaDTO } from 'src/app/_models/reporte-dto';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { FormControl, FormGroup } from '@angular/forms';
import { PeriodoService } from 'src/app/_services';
import { Periodo } from 'src/app/_models';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { Respuesta } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';

@Component({
  selector: 'app-alumnos-inscritos',
  templateUrl: './alumnos-inscritos.component.html',
  styleUrls: ['./alumnos-inscritos.component.scss']
})
export class AlumnosInscritosComponent implements OnInit {
  public alumnosInscritos: AlumnosInscritosDTO[] = []; /// Arreglo de alumnos inscritos por niveles
  public programasPorNivel: ProgramaNivelDTO[] = []; /// Arreglo de programas segun el nivel
  public alumnosPorPrograma: AlumnoProgramaDTO[] = []; /// Arreglo de alumnos que tiene el programa
  public programaNivel: ProgramaNivelDTO[] = []; /// Arreglo de programas sin filtro de nivel
  public alumnosPorProgramaSinNivel: AlumnoProgramaDTO[] = []; /// Arreglo de alumnos por programa sin filtro nivel

  public periodos: Periodo[] = [];
  public periodosPrograma: Periodo[] = [];
  public filtroForm: FormGroup;
  public filtroProgramaForm: FormGroup;

  //#region Variables para el listado de alumnos por programa
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'nombre', 'programa', 'campus', 'periodo', 'correo'];
  dataSource: MatTableDataSource<AlumnoProgramaDTO>;
  selection = new SelectionModel<AlumnoProgramaDTO>(true, []);
  //#endregion

  @ViewChild(MatPaginator) paginator2: MatPaginator;
  displayedColumns2: string[] = ['id', 'nombre', 'programa', 'campus', 'periodo', 'correo'];
  dataSourcePrograma: MatTableDataSource<AlumnoProgramaDTO>;
  selectionPrograma = new SelectionModel<AlumnoProgramaDTO>(true, []);

  /// Variables para mostrar las graficas
  public infoCargadoNivel: boolean = false;
  public infoCargadoProgramaNivel: boolean = false;
  public infoCargadoAlumno: boolean = false;
  public infoCargadoPrograma: boolean = false;
  public infoCargadoAlumno2: boolean = false;

  /// Variable para mostrar los tabs
  public nivelActivo: boolean = false;
  public programaActivo: boolean = false;
  public boolCambioNivel: boolean = false;
  public boolCambioProgrma: boolean = false;

  //#region Variables para la gráfica de niveles
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: "right",
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end' }
      }
    };
  public pieChartColors: Color[] = [{ backgroundColor: ['rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(0, 163, 51)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)', 'rgb(0,0,255)'] }];
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  //#endregion

  //#region Variables para gráfica de programas con filtro de nivel
  public pieChartProgramaNivelOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: "right",
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end' }
      }
    };
  public pieChartProgramaNivelColors: Color[] = [{ backgroundColor: ['rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(0, 163, 51)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)', 'rgb(0,0,255)'] }];
  public pieChartProgramaNivelLabels: Label[] = [];
  public pieChartProgramaNivelData: number[] = [];
  public pieChartProgramaNivelType: ChartType = 'pie';
  public pieChartProgramaNivelLegend = true;
  public pieChartProgramaNivelPlugins = [];
  //#endregion

  //#region Variable para la gráfica de programas sin filtro de nivel
  public pieChartProgramaOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: "right",
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end' }
      }
    };
  public pieChartProgramaColors: Color[] = [{ backgroundColor: ['rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(0, 163, 51)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(201, 203, 207)', 'rgb(0,0,255)'] }];
  public pieChartProgramaLabels: Label[] = [];
  public pieChartProgramaData: number[] = [];
  public pieChartProgramaType: ChartType = 'pie';
  public pieChartProgramaLegend = true;
  public pieChartProgramaPlugins = [];
  //#endregion

  constructor(private reporteService: ReportesService,
    private periodoService: PeriodoService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    public generadorExcel: GeneracionExcelServiceService,) { }

  ngOnInit(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    this.inicializarForm();
    this.inicializaFormPrograma();
    this.llenarFiltros();
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      periodo: new FormControl(0),
      fecha: new FormControl('')
    });
    this.filtroForm.valueChanges.subscribe((cambios) => {
      this.boolCambioNivel = true;
    });
  }

  get periodo() { return this.filtroForm.get('periodo'); }
  get fecha() { return this.filtroForm.get('fecha'); }

  public inicializaFormPrograma() {
    this.filtroProgramaForm = new FormGroup({
      periodoPrograma: new FormControl(0),
      fechaPrograma: new FormControl('')
    });
    this.filtroProgramaForm.valueChanges.subscribe((cambios) => {
      this.boolCambioProgrma = true;
    });
  }

  get periodoPrograma() { return this.filtroForm.get('periodoPrograma'); }
  get fechaPrograma() { return this.filtroForm.get('fechaPrograma'); }

  /**
   * Funcion que trae los datos de los alumnos inscritos por niveles
   * this.busquedaCorreoForm.get("correo").value
   */
  public obtenerDatos(): void {
    this.pieChartLabels = [];
    this.pieChartData = [];
    let periodoId = this.filtroForm.get("periodo").value;
    let fechaCorte = this.filtroForm.get("fecha").value;
    this.reporteService.obtenerAlumnos(periodoId, fechaCorte).subscribe(
      (res: ApiResponse<AlumnosInscritosDTO[]>) => {
        this.alumnosInscritos = res.data;
        this.pieChartData = [];
        this.infoCargadoProgramaNivel = false;
        this.infoCargadoAlumno = false;
        for(const i in this.alumnosInscritos) {
          if(this.alumnosInscritos[i].alumnosTotal != 0) {
            this.pieChartLabels.push(this.alumnosInscritos[i].nombreNivel);
            this.pieChartData.push(this.alumnosInscritos[i].alumnosTotal);
          }
        }

        if(this.pieChartData.length != 0) {
          this.infoCargadoNivel = true;
        } else {
          this.infoCargadoNivel = false;
        }
    });
  }

  /**
   * Funcion que trae todos los datos de los programas segun el nivel elegido
   */
  public obtenerProgramaPorNivel(event): void {
    let nombreNivel = event.active[0]._view.label;
    this.pieChartProgramaNivelLabels = [];
    this.pieChartProgramaNivelData = [];
    let periodoId = this.filtroForm.get("periodo").value;
    let fechaCorte = this.filtroForm.get("fecha").value;
    this.reporteService.obtenerProgramaNivel(nombreNivel, periodoId, fechaCorte).subscribe(
      (respuesta: ApiResponse<ProgramaNivelDTO[]>) => {
        this.programasPorNivel = respuesta.data;
        this.pieChartProgramaNivelData = [];
        this.infoCargadoNivel = false;
        this.infoCargadoProgramaNivel = true;
        this.infoCargadoAlumno = false;
        for(const i in this.programasPorNivel) {
          if(this.programasPorNivel[i].cantidadAlumnos != 0) {
            this.pieChartProgramaNivelLabels.push(this.programasPorNivel[i].nombrePrograma);
            this.pieChartProgramaNivelData.push(this.programasPorNivel[i].cantidadAlumnos);
          }
        }

        if(this.pieChartProgramaNivelData.length != 0) {
          this.infoCargadoProgramaNivel = true;
        } else {
          this.infoCargadoProgramaNivel = false;
        }
      }
    )
  }

  /**
   * Funcion que muestra la lista de alumnos segun el programa seleccionado
   */
  public obtenerAlumnosPorPrograma(event): void {
    let nombrePrograma = event.active[0]._view.label;
    let periodoId = this.filtroForm.get("periodo").value;
    let fechaCorte = this.filtroForm.get("fecha").value;
    this.reporteService.obtenerAlumnosPorPrograma(nombrePrograma, periodoId, fechaCorte).subscribe(
      (respuesta: ApiResponse<AlumnoProgramaDTO[]>) => {
        this.alumnosPorPrograma = respuesta.data;
        this.dataSource = new MatTableDataSource(respuesta.data);
        this.selection = new SelectionModel<AlumnoProgramaDTO>(true, []);
        this.dataSource.paginator = this.paginator;
        this.infoCargadoNivel = false;
        this.infoCargadoProgramaNivel = false;
        this.infoCargadoAlumno = true;
      }
    );
  }

  volverNivel() {
    this.infoCargadoNivel = true;
    this.infoCargadoProgramaNivel = false;
    this.infoCargadoAlumno = false;
    this.programasPorNivel = [];
  }

  volverPrograma() {
    this.infoCargadoNivel = false;
    this.infoCargadoProgramaNivel = true;
    this.infoCargadoAlumno = false;
    this.alumnosPorPrograma = [];
  }

  /**
   * Funcion que trae los datos de todos los programas sin algun filtro
   */
  public obtenerProgramaSinFiltro(): void {
    this.pieChartProgramaData = [];
    this.pieChartProgramaLabels = [];
    let periodoId = this.filtroProgramaForm.get("periodoPrograma").value;
    let fechaCorte = this.filtroProgramaForm.get("fechaPrograma").value;
    this.reporteService.obtenerProgramas(periodoId, fechaCorte).subscribe(
      (respuesta: ApiResponse<ProgramaNivelDTO[]>) => {
        this.programaNivel = respuesta.data;
        this.pieChartProgramaData = [];
        this.infoCargadoAlumno2 = false;
        for(const i in this.programaNivel) {
          if(this.programaNivel[i].cantidadAlumnos != 0) {
            this.pieChartProgramaLabels.push(this.programaNivel[i].nombrePrograma);
            this.pieChartProgramaData.push(this.programaNivel[i].cantidadAlumnos);
          }
        }

        if(this.pieChartProgramaData.length != 0) {
          this.infoCargadoPrograma = true;
        } else {
          this.infoCargadoPrograma = false;
        }
      }
    );
  }

  public obtenerProgramaSinNivel(event): void{
    let nombrePrograma = event.active[0]._view.label;
    let periodoId = this.filtroProgramaForm.get("periodoPrograma").value;
    let fechaCorte = this.filtroProgramaForm.get("fechaPrograma").value;
    this.reporteService.obtenerAlumnosPorPrograma(nombrePrograma, periodoId, fechaCorte).subscribe(
      (respuesta: ApiResponse<AlumnoProgramaDTO[]>) => {
        this.alumnosPorProgramaSinNivel = respuesta.data;
        this.dataSourcePrograma = new MatTableDataSource(respuesta.data);
        this.selectionPrograma = new SelectionModel<AlumnoProgramaDTO>(true, []);
        this.dataSourcePrograma.paginator = this.paginator2;
        this.infoCargadoPrograma = false;
        this.infoCargadoAlumno2 = true;
      }
    );
  }

  volverSinPrograma() {
    this.infoCargadoPrograma = true;
    this.infoCargadoAlumno2 = false;
    this.alumnosPorProgramaSinNivel = [];
  }

  onChangeTab(event) {
    switch (event.index) {
      case 1:
        this.programaActivo = true;
        break;
      default:
        this.programaActivo = false;
        break;
    }
  }

  public llenarFiltros() {
    this.periodoService.ObtenerPeriodoFechaInicio().subscribe(
      (periodos: Periodo[]) => { this.periodos = periodos; }
    );
  }

  ExcelSubscription: Subscription;
  public descargarExcelProgramas() {
    this.ExcelSubscription = this.reporteService.descargarAlumnosExcel(this.alumnosPorProgramaSinNivel).subscribe(
      (respuesta: Blob) => {
        let filename = `Alumnos-Programa${this.alumnosPorProgramaSinNivel[0].nombrePrograma}`;
        this.generadorExcel.GenerarExcel(respuesta, filename);
        this.ExcelSubscription.unsubscribe();
      });
  }

  public descargarExcelNiveles() {
    this.ExcelSubscription = this.reporteService.descargarAlumnosExcel(this.alumnosPorPrograma).subscribe(
      (respuesta: Blob) => {
        let filename = `Alumnos-Programa${this.alumnosPorPrograma[0].nombrePrograma}`;
        this.generadorExcel.GenerarExcel(respuesta, filename);
        this.ExcelSubscription.unsubscribe();
      });
  }
}
