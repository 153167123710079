import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { BlockService } from 'src/app/_services/block.service';

@Component({
  selector: 'app-oferta-cademica-bloque-modal-eliminar',
  templateUrl: './oferta-cademica-bloque-modal-eliminar.component.html',
  styleUrls: ['./oferta-cademica-bloque-modal-eliminar.component.scss']
})
export class OfertaCademicaBloqueModalEliminarComponent implements OnInit {
  public boolIsArray = false;
  public numBlockSeleccionados = 0;
  public codeBlock = '';
  constructor(public dialogRef: MatDialogRef<OfertaCademicaBloqueModalEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    public blockService: BlockService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numBlockSeleccionados = this.data.length;
    }

  }

  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(d => (d.blockId.toString()));
      this.codeBlock = arrayClaves.toString();
    } else {
      this.codeBlock = this.data.blockId.toString();
    }

    this.blockService.eliminarBlock(this.codeBlock).subscribe(
      (response: ApiResponse<any>) => {
        if (response.success) {
          this.blockService.invocarMetodoObtenerBloque();
          this.snackService.mostrarSnackBack(response.message);
          this.dialogRef.close(this.data);
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      }, error => {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar el bloque. Por favor, intente de nuevo más tarde.');
      }
    );

  }
}