import { GeneracionExcelServiceService } from '../../../services/generacion-excel-service.service';
import { ImprimirServiceService } from '../../../services/imprimir-service.service';
import { Boleta } from './../../../_models/boleta.model';
import { AlumnoGrupoParcial } from './../../../_models/alumnoGrupoParcial.model';
import { MatTableDataSource } from '@angular/material/table';
import { AdminAcademicaService } from './../../../_services/admin-academica.service';
import { ProgramaAcademicoService } from './../../../_services/programa-academico.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Periodo } from '../../../_models/periodo';
import { Subscription } from 'rxjs';
import { ApiResponse } from '../../../models/api/ApiRespose.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { TramitesBajaMateriaComponent } from '../tramites-baja-materia/tramites-baja-materia.component';

@Component({
  selector: 'app-tramites-boleta',
  templateUrl: './tramites-boleta.component.html',
  styleUrls: ['./tramites-boleta.component.scss']
})
export class TramitesBoletaComponent implements OnInit {

  @Input() data: any;
  public filtroForm: FormGroup;
  public periodoF = new FormControl();
  public cicloList: any = [];
  public Periodos: Periodo[];
  public filtroactual: any;
  public selected: any;
  public flagBoleta = false;
  public cicloListFusion: Array<any> = [];
  public Parciales: Array<string> = [];
  public materiasHistorial: Array<any> = [];
  public ExcelSubscription: Subscription;
  public columnasBoleta: string[] = ['select', 'grupo', 'clave', 'nombre'];
  public dataSourceBoleta: MatTableDataSource<any>;
  public selectionBoleta = new SelectionModel<any>(true, []);
  public numSeleccionado = 0;
  public infoCargado: boolean = false;

  constructor(public adminAcademicaService: AdminAcademicaService,
    public imprimirService: ImprimirServiceService,
    private generadorExcel: GeneracionExcelServiceService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.inicializarForm();
    this.LlenarFiltros();
    if (this.data[0].priodoIngreso != null || this.data[0].priodoIngreso != undefined) {
      this.selected = this.data[0].priodoIngreso;
      this.obtenerBoleta();
    }
  }

  /**
   * Inicializa el formulario del filtro
   */
  public inicializarForm() {
    this.filtroForm = new FormGroup({
      periodoF: new FormControl(null),
    });
  }

  /**
   * Metodo para filtrar 
   */
  filtrar() {
    this.obtenerBoleta();
  }

  /**
   * Llena el filtro del periodo
   */
  public LlenarFiltros() {
    this.adminAcademicaService.obtenerPeriodosByAlumno(this.data[0].alumnoId).subscribe(
      (periodos: ApiResponse<any>) => {
        this.Periodos = periodos.data;
      });
  }

  /**
   * Metodo para obtener las materias con calificaciones de alumno
   */
  public obtenerBoleta() {
    this.adminAcademicaService.GetBoleta(this.data[0].alumnoId, this.selected, this.data[0].campusId, this.data[0].programaId, this.data[0].programa.nivelId).subscribe(
      (respuestaBoleta: ApiResponse<any>) => {
        this.columnasBoleta = ['select', 'grupo', 'clave', 'nombre'];
        this.materiasHistorial = null;
        this.materiasHistorial = respuestaBoleta.data;
        if (this.materiasHistorial != null || this.materiasHistorial != undefined) {
          this.Parciales.length = 0;
          for (let i = 1; i <= this.materiasHistorial[0].parciales; i++) {
            this.Parciales.push(i.toString());
          }
          this.Parciales.forEach(parcial => {
            this.columnasBoleta.push(parcial);
          });
          this.columnasBoleta.push("final");
          this.materiasHistorial.forEach(boleta => {
            for (let i = boleta.alumnoGrupoParcial.length; i < this.materiasHistorial[0].parciales; i++) {
              let calificacionFalsa = new AlumnoGrupoParcial();
              calificacionFalsa.calificacion = -1;
              boleta.alumnoGrupoParcial.push(calificacionFalsa);
            }
          });
          this.flagBoleta = true;

          this.dataSourceBoleta = new MatTableDataSource(respuestaBoleta.data);
          this.selectionBoleta = new SelectionModel<any>(true, []);
          this.infoCargado = true;
        }
      });
  }

  /**
   * Descargar excel
   */
  public descargarExcel() {
    this.ExcelSubscription = this.adminAcademicaService.descargarSheetBoleta(this.materiasHistorial).subscribe((res: Blob) => {
      let filename = `HistorialAcademico${this.data[0].usuario.nombre}${this.data[0].usuario.apellidoPaterno}${this.data[0].usuario.apellidoMaterno}`;
      this.generadorExcel.GenerarExcel(res, filename);
      this.ExcelSubscription.unsubscribe();
    })
  }

  /**
   * Metododo paara imprimir
   */
  public imprimir() {
    const printContent: HTMLElement = document.getElementById("historial");
    let nombreAlumno = `${this.data[0].usuario.nombre}${this.data[0].usuario.apellidoMaterno}${this.data[0].usuario.apellidoMaterno}`
    this.imprimirService.Imprimir(printContent, nombreAlumno);
  }

  /**
    * Metodo interno del data table de angular material
    * @returns 
    */
  public isAllSelected(): boolean {
    this.numSeleccionado = this.selectionBoleta.selected.length;
    const numRows = this.dataSourceBoleta.data.length;
    return this.numSeleccionado === numRows;
  }

  /**
   * Metodo interno del data table de angular material
   * @param row 
   * @returns 
   */
  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionBoleta.isSelected(row) ? 'deselect' : 'select'} row ${row.documentoId + 1}`;
  }

  /**
   * Metodo interno del data table de angular material
   */
  public masterToggle(): void {
    this.isAllSelected() ? this.selectionBoleta.clear() : this.dataSourceBoleta.data.forEach(row => this.selectionBoleta.select(row))
  }

  /**
   * Metodo para abrir modal de baja de materias
   */
  public modalBajaMaterias(): void {
    let data = this.selectionBoleta.selected[0];
    const dialogRef = this.dialog.open(TramitesBajaMateriaComponent, { data: data, disableClose: true, height: '500px', width: '50vw' });
    dialogRef.afterClosed().subscribe(result => {
      this.obtenerBoleta();
    });
  }

}
