import { map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { Alumno } from '../_models/inscripcion';
import { baseUrl, environment } from 'src/environments/environment';
import { HistorialAcademico } from '../_models/HistorialAcademico.model';
import { MateriaCursada } from '../_models/materia-cursada';
import { HistorialCalFinal } from '../_models/HistorialCalFinal';
import { CalificacionNoNumerica } from '../_models/CalificacionNoNumerica';
import { TipoMotivoCalificacion } from '../_models/TipoMotivoCalificacion';
import { HistorialCalificacionFinalParametroDTO } from '../_models/HistorialCalificacionFinalParametroDTO';
import { NombreProfesor } from '../_models/NombreProfesor';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SeleccionDeMateriasDTO } from '../models/Inscripcion/SeleccionDeMateriasDTO.model';
import { AdmisionesDTO } from '../_models/AdmisionesDTO';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { FiltroAdminAcademicoAlumnoDto } from '@app/_models/FiltroAdminAcademicoAlumnoDto';
interface validarForm {
  renderizado: boolean;
  estado: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AdminAcademicaService {

  private formularioDatosUsuarioAdminAcademica$ = new Subject<validarForm>();
  public actualizarTabla = new EventEmitter();
  public actualizarTablaSubcription: Subscription;
  public actualizarHistorialAcademicaSubscription: Subscription;
  invocarObtenerHistorialAcademico = new EventEmitter();

  //#region Invocar Acreditaciones
  invocarObtenerAcreditaciones = new EventEmitter();
  invocarObtenerAcreditacionesSubscription: Subscription;
  //Invocar Metodo obtener info de Alumnos
  public invocarMetodoObtenerAcreditaciones(programaId: number) {
    this.invocarObtenerAcreditaciones.emit(programaId);
  }
  //#endregion



  constructor(private httpClient: HttpClient) { }


  //Invocar Metodo ObtenerHistorialAcademico
  invocarMetodoObtenerHistorial(programaId: number = 0) {
    this.invocarObtenerHistorialAcademico.emit(programaId);
  }


  /**
   * Obtiene todas la inscripciones segun el tipo de alumno NI: Nuevo Ingreso o RI: Reingreso
   * @param tipoAlumno
   * @returns
   */
  public getInscripciones(tipoAlumno: number, pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetAlumnos/${tipoAlumno}/${pagina}/${registrosPagina}`, filtro);
  }

  /**
   * Descarga el excel con los alumnos de admin academico
   * @param alumnos
   * @returns Observable con la respuesta de la Api
   */
  public descargarExcelSeguimiento(filtro: FiltroAdminAcademicoAlumnoDto) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/DownloadSheeDocumentAsistencias`, filtro,
      {
        responseType: "blob"
      });
  }

  /**
   * Descarga el archivo en blob que contiene el excel
   * @param alumnos
   * @returns
   */
  public descargarSheetDocumentHistorial(alumnos) {
    console.log("ALUMNOS", alumnos)
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/DownloadSheetDocumetHistorialAcademico`, JSON.stringify(alumnos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  public descargarSheetBoleta(alumnos) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/DownloadSheetBoleta`, JSON.stringify(alumnos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  /**
   * Descarga el archivo en blob que contiene el excel
   * @param alumnos
   * @returns
   */
  public descargarSheetDocumentReprobadas(alumnos) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/DownloadSheetDocumetMateriasReprobadas`, JSON.stringify(alumnos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  /**
   * Obtiene el historial academico del alumno
   * @param alumnoId
   * @param periodoId
   * @param cicloId
   * @returns
   */
  public getHistorialAcademico(alumnoId: number, periodoId: number, cicloId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetHistorialAcademico/${alumnoId}/${periodoId}?cicloId=` + cicloId);
  }

  /**
 * Obtiene el historial academico del alumno
 * @param identificador
 * @returns
 */
  public getPromedios(identificador: string): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetPromedios/${identificador}`);
  }


  /**
   * Obtiene todas la materias que el alumno ha reprobado :c
   * @param alumnoId
   * @returns
   */
  public GetListaMateriasReprobadas(alumnoId: number/*, periodoId:number*/): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetListaMateriasReprobadas/${alumnoId}`);
  }

  /**
   * Obtiene la boleta del alumno
   * @param alumnoId
   * @param periodoId
   * @param campusId
   * @param programaID
   * @param NivelID
   * @returns
   */
  public GetBoleta(alumnoId: number, periodoId: number, campusId: number, programaID: number, NivelID: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetBoleta/${alumnoId}/${periodoId}/${campusId}/${programaID}/${NivelID}`);
  }

  public MateriasCursadasPorPeriodoYAlumno(alumnoId: number, periodoId: number): Observable<MateriaCursada[]> {
    return this.httpClient.get<MateriaCursada[]>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetMateriasCursadasPorPeriodoYAlumno/${alumnoId}/${periodoId}`).
      pipe(
        map(respuesta => {
          return respuesta.map(MC => new MateriaCursada(MC));
        })
      );
  }

  /**
   * Obtiene la información del usuario indicado
   * @param idUsuario
   * @returns Observable con la data del usuaario
   */
  public ObtenerInformacionUsuario(idUsuario: string): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/ObtenerInformacionDelUsuario?IdentificadorUsuario=${idUsuario}`);
  }

  /**
   * Obtiene una lista con las materias inscritas de un alumno
   * @param alumnoId
   * @returns
   */
  //Get Periodos By AlumnoId
  public obtenerPeriodosByAlumno(alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetPeriodosByAlumnoId?alumnoId=` + alumnoId);
  }

  /**
   * Emite un resultado del estado del formulario a todos los suscriptores del Subjet
   */
  public formularioDatosUsuarioHaCambiado(isFormularioDatosUsuarioValid: validarForm): void {
    this.formularioDatosUsuarioAdminAcademica$.next(isFormularioDatosUsuarioValid);
  }

  public actualizarDatosTabla(): void {
    this.actualizarTabla.emit();
  }

  /**
   * Se suscribe al avento que está escuchando el cambio de estado del
   * Formulario de datos de usuario de administración académica
   * @returns Observable<Boolean>
   */
  public getSubjetEstadoFormularioDatosUsuario(): Subject<validarForm> {
    return this.formularioDatosUsuarioAdminAcademica$;
  }

  public GetUltimaCalificacionObtenida(alumnoId: number, grupoId: number, MOID: number): Observable<number> {
    return this.httpClient.get<number>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetUltimaCalificacionObtenida/${alumnoId}/${grupoId}/${MOID}`);
  }


  public NombreProfesor(grupoId: number, periodoId: number, MOID: number): Observable<NombreProfesor> {

    return this.httpClient.get<NombreProfesor>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetNombreProfesor/${grupoId}/${periodoId}/${MOID}`);
  }


  public HistorialCalificacionFinal(alumnoId: number, mateOferId: number, periodoId: number): Observable<HistorialCalFinal[]> {
    return this.httpClient.get<HistorialCalFinal[]>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/HistorialCalificacionFinal/${alumnoId}/${mateOferId}/${periodoId}`).
      pipe(
        map(respuesta => {
          return respuesta.map(HCF => new HistorialCalFinal(HCF));
        })
      );
  }

  public NuevaCalificacionFinal(ProgramaID: number): Observable<CalificacionNoNumerica[]> {
    return this.httpClient.get<CalificacionNoNumerica[]>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/NuevaCalificacionFinal/${ProgramaID}`).
      pipe(
        map(respuesta => {
          return respuesta.map(cnn => new CalificacionNoNumerica(cnn));
        })
      );
  }

  public TipoMotivoCalificacion(InstitucionID: string): Observable<TipoMotivoCalificacion[]> {
    return this.httpClient.get<TipoMotivoCalificacion[]>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/TipoMotivoCalificacion/${InstitucionID}`).
      pipe(
        map(respuesta => {
          return respuesta.map(tmc => new TipoMotivoCalificacion(tmc));
        })
      );
  }


  public GuardarHistorialCalFinal(HCF: HistorialCalificacionFinalParametroDTO): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GuardarHistorialCalFinal`, HCF);
  }

  public DocumentosUsuario(usuarioId: number, claveProceso: string): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/DocumentoById/${usuarioId}/${claveProceso}`);
  }


  public PostAcreditacionAlumno(acreditacionAlumno: any): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/PostAcreditacionAlumno`, acreditacionAlumno);
  }


  /**
 * Retorna un observable que contiene la respuesta en api. En caso de ser exitosa, la data que viene dentro
 * es una lista de materias programadas disponibles con base el plan de estudios del alumno
 * @param idPrograma
 * @param identiticador
 * @returns Observable
 */
  public ObtenerMateriasProgramadasAcreditar(idPrograma: number, identiticador: string): Observable<ApiResponse<Array<SeleccionDeMateriasDTO>>> {
    return this.httpClient.get<ApiResponse<Array<SeleccionDeMateriasDTO>>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/ObtenerMateriasProgramadasAcreditar?IdPrograma=${idPrograma}&Identiticador=${identiticador}`)
  }

  /**
 * Retorna un observable que contiene la respuesta en api. En caso de ser exitosa, la data que viene dentro
 * es una lista de materias programadas disponibles con base el plan de estudios del alumno
 * @param idPrograma
 * @param identiticador
 * @returns Observable
 */
  public ObtenerMateriasCursadasEnOtroPlan(idPrograma: number, identiticador: string, alumnoId: number): Observable<ApiResponse<Array<SeleccionDeMateriasDTO>>> {
    return this.httpClient.get<ApiResponse<Array<SeleccionDeMateriasDTO>>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/ObtenerMateriasCursadasEnOtroPlan?IdPrograma=${idPrograma}&Identiticador=${identiticador}&alumnoId=${alumnoId}`)
  }


  //#region ProgramaAcademico
  /**
  * Obtiene una lista con los programas de admisión que tenga el alumno.
  * @param alumnoId
  * @returns
  */
  public ObtenerProgramasAcedmicos(alumnoId: number): Observable<ApiResponse<AdmisionesDTO[]>> {
    return this.httpClient.get<ApiResponse<AdmisionesDTO[]>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/ObtenerProgramasAcedmicos/${alumnoId}`);
  }
  //#endregion

  //#region RecargarDatos del modal header

  invocarObtenerContenido = new EventEmitter();
  invocarObtenerContenidoSubscription: Subscription;
  //Invocar Metodo obtener info de Alumnos
  public invocarMetodoObtenerContenido() {
    this.invocarObtenerContenido.emit();
  }

  /**
   * Obtiene todas la inscripciones segun el tipo de alumno NI: Nuevo Ingreso o RI: Reingreso
   * @param alumnoId
   * @returns
   */
  public GetAlumnobyAlumno(alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetAlumnobyAlumno/` + alumnoId);
  }
  //#endregion


  public ObtenerAcreditacionesAlumno(alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetAcreditacionesAlumno/${alumnoId}`);
  }

  /**
    * Elimina una acreditacion con el studentCourseTransferId
    * @param studentCourseTransferId
    * @returns
    */
  public deleteAcreditacionAlumno(studentCourseTransferId: number): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/DeleteAcreditacionAlumno/${studentCourseTransferId}`);
  }


}
