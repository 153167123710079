export class CampaignDTO {
  //implements Deserializable
  campaignId?: number;
  name: string;
  startDate: Date;
  endDate: Date;
  description: string;
  leadTarget?: number;
  conversionTarget: number;
  expenseBudget: string;
  expectedIncome: string;
  campaignResponsibleId?: number;
  campaignLeadEntryIds: string;
  campaignTermIds: string;
  campaignCampusIds: string;
  campaignLevelIds: string;
  campaignProgramIds: string;
  campaignStudentTypeId: string;

  constructor() {
    this.campaignId = null;
    this.name = null;
    this.startDate = null;
    this.endDate = null;
    this.description = null;
    this.leadTarget = null;
    this.conversionTarget = null;
    this.expenseBudget = null;
    this.expectedIncome = null;
    this.campaignResponsibleId = null;
    this.campaignLeadEntryIds = null;
    this.campaignTermIds = null;
    this.campaignCampusIds = null;
    this.campaignLevelIds = null;
    this.campaignProgramIds = null;
    this.campaignStudentTypeId = null;
  }

  // deserialize(input: any): this {
  //   Object.keys(input).forEach((key) => {
  //     input[key] = input[key] ? input[key] : null;
  //   });
  //   Object.assign(this, input);
  //   return this;
  // }
}
