import { PaginatePipe } from './../../../../core.application/pipes/paginate-pipe.pipe';
import { FiltersModule } from './../../forms/filters/filters.module';
import { TableFooterComponent } from './table-footer/table-footer.component';
import { MaterialModule } from './../../modules/material/material.module';
import { TableBodyComponent } from './table-body/table-body.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GenericTableRoutingModule } from './generic-table-routing.module';
import { GenericTableComponent } from './generic-table.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    TableBodyComponent, 
    TableFooterComponent, 
    GenericTableComponent,
    PaginatePipe
  ],
  imports: [
    CommonModule,
    GenericTableRoutingModule,
    MaterialModule,
    FiltersModule,
    FormsModule    
  ], 
  exports: [
    TableBodyComponent, 
    TableFooterComponent, 
    GenericTableComponent, 
    PaginatePipe
  ]
})
export class GenericTableModule { }
