import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription, throwError } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { Documento } from 'src/app/_models/documento';
import { DatosDocumentos } from 'src/app/_models/usuario';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { DocumentoService } from 'src/app/_services/documento.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { TraductorService } from '../../../../_services/traductor.service';

@Component({
  selector: 'app-documentos-admision',
  templateUrl: './documentos-admision.component.html',
  styleUrls: ['./documentos-admision.component.scss']
})
export class DocumentosAdmisionComponent implements OnInit, OnDestroy {

  //Variable renderizar componente
  public documentosAdmisionRenderizar: boolean = false;
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variables Obs
  public infoUsuario$: Observable<any>;
  public infoUsuario: any = null;
  //Variable documentos usuario
  public documentosUsuario: any[] = [];
  displayedColumns: string[] = ['select', 'tipo', 'nombre', 'fecha'];
  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public documentos: any[] = [];
  public numDocumentoSeleccionado = 0;
  public documentoList: Documento[] = [];
  public tipoDocSelected: number;
  public date: Date = new Date();
  public tipoAccionComprobanteIdentidad: any;
  public tipoAccionCartaCompromiso: any;
  public tipoAccionIdentificacionFotografia: any;
  public tipoAccionEstudiosPrevios: any;
  public cargaDocumentoForm: FormGroup;
  public pdf: ArrayBuffer;
  public mime: string;
  public imagen: string;
  public cartaCompromiso: any;
  public comprobanteIdentidad: any;
  public identificacionFotografia: any;
  public estudiosPrevios: any;
  public comprobanteIdentidadCargado: boolean = false;
  public identificacionFotoCargado: boolean = false;
  public cartaCompromisoCargado: boolean = false;
  public estudiosPreviosCargado: boolean = false;
  public readonly CLAVE_PROCESO = "NUEVO";
  //Modal visor pdf
  @ViewChild('modalVisorPDF') modalVisorPDF: TemplateRef<any>;
  public modalVisorPDFRef: any;
  //Variables renderizar tabla
  public infoCargado: boolean = false;

  constructor(
    private admisionesService: AdmisionesService,
    private snackService: SnackService,
    private documentoService: DocumentoService,
    private dialog: MatDialog,
    public nuevoIngresoService: NuevoIngresoService,
    private utileriasService: UtileriasService,
    private traductorService: TraductorService
  ) { }

  ngOnInit(): void { }

  /**
   * Metodo para renderizar la vista segun el step selecccionado
   */
  public RenderizarDocumentosAdmision() {
    this.infoUsuario$ = this.admisionesService.GetInfoUsuario$();
    this.subscription.add(
      this.infoUsuario$.subscribe(
        (info: any) => {
          this.infoUsuario = info;
          console.log("this.infoUsuario DOCUMENTOS", this.infoUsuario)
          this.obtenerDocumentos();
        })
    );
  }

  /**
   * Metodo para traer los documentos del usuario
   */
  public obtenerDocumentos() {
    this.subscription.add(
      this.admisionesService.GetDocumentosAdmision(this.infoUsuario.usuarioId).subscribe((res: ApiResponse<Documento[]>) => {
        if (res.success) {
          this.documentosUsuario = res.data;
          this.generarArregloDocumentos();
        } else {
          this.snackService.mostrarSnackBack(res.message);
        }
      })
    );
  }

  /**
   * Metodo para generar un arreglo con los documentos del usuario
   */
  public generarArregloDocumentos() {
    this.documentoService.ObtenerDocumentosProcesoSubProceso(this.CLAVE_PROCESO, this.infoUsuario.alumnoRegistrado[0].alumnoId).subscribe(documentos => {
      this.documentoList = documentos.data;
      this.documentos = [];
      console.log(this.documentos);
      if (this.documentosUsuario.length > 0) {
        this.documentoList.forEach(documento => {
          let documentoArreglo: any = null;
          let documentoEncontrado: DatosDocumentos = null;
          documentoArreglo = this.documentosUsuario.filter(x => x.documentoId === documento.documentoId);
          if (documentoArreglo.length >= 1) {
            documentoArreglo = documentoArreglo.sort(this.compareDate);
            documentoEncontrado = documentoArreglo.find(x => x.nombreFisico);
            if (documentoEncontrado !== null) {
              documentoEncontrado
              documentoEncontrado.documentoNombre = documento.nombre;
              this.documentos.push(documentoEncontrado);
            }
          }
        });
        if (this.documentos.length > 0) {
          this.dataSource = new MatTableDataSource(this.documentos);
          this.selection = new SelectionModel<DatosDocumentos>(true, []);
          this.infoCargado = true;
        }
      }
      this.inicializarForm();
    });
  }

  /**
   * Metodo que crea el formulario para cargar documentos
   */
  public inicializarForm() {
    this.cargaDocumentoForm = new FormGroup({
      usuarioId: new FormControl(this.infoUsuario.usuarioId),
      tipoDocumento: new FormControl(0),
      docs: new FormControl(),
      docsEditar: new FormControl(),
    });
    this.documentosAdmisionRenderizar = true;
    this.cargaDocumentoForm.valueChanges.subscribe(val => {

    });
  }

  /**
   * Metodo para saber que tipo de documento se eligio del select
   * @param docId 
   */
  public docSelected(docId: number) {
    if (!!docId) {
      this.tipoDocSelected = docId;
    }
  }

  /**
   * Metodo para comparar las fechas del documento
   * @param emp1 
   * @param emp2 
   * @returns 
   */
  compareDate(emp1: any, emp2: any) {
    var emp1Date = new Date(emp1.date).getTime();
    var emp2Date = new Date(emp2.date).getTime();
    return emp1Date > emp2Date ? 1 : -1;
  }

  /**
 * Metodo para subir un documento con el select
 * @param event 
 */
  public onFileChange(event: any) {
    let documentos: any[] = [];
    let documentosEditar: any[] = [];
    const reader = new FileReader();
    const [file] = event.target.files;
    if (event.target.files && event.target.files.length) {
      let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === this.tipoDocSelected);
      reader.onload = () => {
        let documento: any = {
          usuarioId: this.infoUsuario.usuarioId,
          documentoId: tipoDocumentoSelecionado.documentoId,
          nombreOriginal: file.name,
          nombreFisico: reader.result,
          fechaCreacion: this.date,
          documentoNombre: tipoDocumentoSelecionado.nombre,
          estatusDocumentoId: 1
        }
        documentos.push(documento);
        if (this.dataSource === undefined || this.dataSource.data.length === 0) {
          this.dataSource = new MatTableDataSource(documentos);
          this.selection = new SelectionModel<any>(false, []);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentos;
          this.infoCargado = true;
        } else {
          documentosEditar = this.dataSource.data;
          documentosEditar = documentosEditar.filter(x => x.documentoId !== this.tipoDocSelected);
          this.dataSource = new MatTableDataSource(documentosEditar);
          this.selection = new SelectionModel<DatosDocumentos>(true, []);
          this.dataSource.data.push(documento);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentosEditar;
        }
        this.registrarDoc(documentos);
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * Metodo para editar un documento seleccionado
   * @param event 
   * @param tipoId 
   */
  public onFileChangeEditar(event, tipoDocumentoId: number) {
    let documentosEditar: DatosDocumentos[] = [];
    let documentosGuardar: any[] = [];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === tipoDocumentoId);
      reader.onload = () => {
        let documento = {
          usuarioId: this.infoUsuario.usuarioId,
          documentoId: tipoDocumentoSelecionado.documentoId,
          nombreOriginal: file.name,
          nombreFisico: reader.result,
          fechaCreacion: this.date,
          documentoNombre: tipoDocumentoSelecionado.nombre,
          estatusDocumentoId: 1
        }
        documentosEditar = this.documentos;
        documentosEditar = documentosEditar.filter(x => x.documentoId !== tipoDocumentoId);
        this.dataSource = new MatTableDataSource(documentosEditar);
        this.selection = new SelectionModel<DatosDocumentos>(true, []);
        this.dataSource.data.push(documento);
        this.dataSource._updateChangeSubscription();
        this.documentos = this.dataSource.data;
        documentosGuardar.push(documento);
        this.registrarDoc(documentosGuardar);
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * Metodo para registrar nuevo documento
   * @param docs 
   */
  public registrarDoc(docs: any) {
    if (docs != null) {
      this.nuevoIngresoService.registrarDocumentos(docs).subscribe(
        (respuestaDocumentos: SimpleResponse) => {
          if (respuestaDocumentos.success) {
            let mensaje: string = this.traductorService.translate('guardadoDocumentos');
            this.snackService.mostrarSnackBack(mensaje);
            this.dataSource._updateChangeSubscription();
          } else {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        }
      );
    }
  }

  /**
   * Metodo para eliminar el documento del usuario
   * @param documentId 
   */
  public eliminarDocumento(documentId: number) {
    if (documentId) {
      this.nuevoIngresoService.deleteDocumentoById(this.infoUsuario.usuarioId, documentId).subscribe(
        (respuestaEliminarDoc: SimpleResponse) => {
          if (respuestaEliminarDoc.success) {
            let documentosEliminar: DatosDocumentos[] = [];
            documentosEliminar = this.documentos;
            documentosEliminar = documentosEliminar.filter(x => x.documentoId !== documentId);
            this.dataSource = new MatTableDataSource(documentosEliminar);
            this.selection = new SelectionModel<DatosDocumentos>(true, []);
            this.documentos = documentosEliminar;
            if (this.documentos.length === 0) {
              this.infoCargado = false;
            }
            this.dataSource._updateChangeSubscription()
            let mensaje: string = this.traductorService.translate('eliminarCorrecto');
            this.snackService.mostrarSnackBack(mensaje);
          } else {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        })
    }
  }

  /**
   * Metodo para visualizar el documento selecionado
   * @param refDocumento 
   */
  public visualizarPdf(refDocumento: any) {
    if (refDocumento > 0) {
      this.nuevoIngresoService.GetDocumentosCandidatoByCorreo(this.infoUsuario.correo, refDocumento).subscribe(
        (datosUsuarioDocumento: ApiResponse<any>) => {
          if (datosUsuarioDocumento.success) {
            var documentos = datosUsuarioDocumento.data.usuarioDocumento.filter(x => x.documentoId === refDocumento);
            var documentoVisualizar = documentos.sort(this.compareDate);
            this.mime = documentoVisualizar[0].nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : /\.(png|jpeg|jpg|gif|tiff|bmp)$/.test(documentoVisualizar[0].nombreOriginal) ? "image/" + /\.(png|jpeg|jpg|gif|tiff|bmp)$/.exec(documentoVisualizar[0].nombreOriginal)[1] : "application/octect-stream";
            this.pdf = this.base64ToArrayBuffer(documentoVisualizar[0].nombreFisico);
            if (this.mime.indexOf("image") >= 0) {
              this.imagen = `data:${this.mime};base64,${documentoVisualizar[0].nombreFisico}`;
            }
            if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
              this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
            } else {
              const blob = new Blob([this.pdf], { type: this.mime });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            }
          }
        }
      );
    } else {
      let archivo = this.cargaDocumentoForm.controls[refDocumento].value;
      this.mime = archivo.split(",")[0].split(":")[1].split(";")[0];
      this.imagen = this.cargaDocumentoForm.controls[refDocumento].value;
      this.pdf = this.base64ToArrayBuffer(archivo);
      if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
        this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
      } else {
        const blob = new Blob([this.pdf], { type: this.mime });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    }
  }

  /**
   * Metodo que convierte un base64 a arraybuffer para viasualizarlo
   * @param base64 
   * @returns 
   */
  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  /**
   * Metodo que cierra el modal del visor del documento
   */
  public cerrarModalPdf() {
    this.modalVisorPDFRef.close();
  }

  /**
   * Metodo interno del mattable de angular material
   * @returns 
   */
  isAllSelected() {
    this.numDocumentoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numDocumentoSeleccionado === numRows;
  }

  /**
   * Metodo interno del mattable de angular material
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.documentoId + 1}`;
  }

  /**
   * Metodo interno del mattable de angular material
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row))
  }

  /**
   * Metodo que se activa cuando se cierra el componente
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
