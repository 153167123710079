import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InscripcionService } from '../../../_services/inscripcion.service'
import { configuracionInscripcion} from '../../../_models/inscripcion'
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';

@Component({
  selector: 'app-configuracion-eliminar',
  templateUrl: './configuracion-eliminar.component.html',
  styleUrls: ['./configuracion-eliminar.component.scss']
})
export class ConfiguracionEliminarComponent implements OnInit {
  public boolRecibido: boolean = false;
  public boolCambio: boolean = false;
  public usuarioId: number;
  public boolIsArray: boolean = false;
  public claveCandidato: string = '';
  public numAlumnoSeleccionado: number = 0;
  public datosConfiguracion :configuracionInscripcion[];
  public lista: any[] = [];
  constructor(public dialogRef: MatDialogRef<ConfiguracionEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public inscripcion: InscripcionService,
    private snackService: SnackService) { }

  /**
   * Inicia el componente y activa la funcion para validar los datos
   */
  ngOnInit(): void {
    this.validarDataRecibida();
  }

  /**
   * Valida si la informacion que recibe es un solo dato o varios
   */
  numResgistros:boolean = false;
  public validarDataRecibida(): void {
    this.numAlumnoSeleccionado = this.data.length;
    if (this.numAlumnoSeleccionado == undefined){
      this.numResgistros = true;
      this.lista.push(this.data);
    } else {
      this.numResgistros = false;
    }
  }

  /**
   * Función que elimina los candidatos 
   */
  public eliminarCandidato(): void {
    if (this.numAlumnoSeleccionado == undefined){
    this.datosConfiguracion = this.lista;
    } else {
      this.datosConfiguracion = this.data;
    }
    this.inscripcion.eliminarConfiguracion(this.datosConfiguracion).subscribe((respuestaEliminar: SimpleResponse)=>{
      if (respuestaEliminar.success) {
        this.dialogRef.close(this.data);
        this.snackService.mostrarSnackBack('Se ha eliminado la información.');
      } else {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar la configuración. Por favor, intente de nuevo más tarde.');
      }
    });
  }

  /**
   * Cierra el modal a menos que existan cambios pendientes
   */
   public cerrarModal(): void {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }
}
