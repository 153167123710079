import { Component, Inject, Input, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { Ciclo } from 'src/app/_models/ciclo';
import { Programa } from 'src/app/_models/programa';
import { CicloService } from 'src/app/_services/ciclo.service';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';

@Component({
  selector: 'app-programa-academico-modal-edicion-ciclo',
  templateUrl: './programa-academico-modal-edicion-ciclo.component.html',
  styleUrls: ['./programa-academico-modal-edicion-ciclo.component.scss']
})
export class ProgramaAcademicoModalEdicionCicloComponent implements OnInit {

  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  public cicloForm: FormGroup;
  public datosFormCiclo: Ciclo;
  public boolCambio: boolean = false;
  public dialogoRefAdver: any;
  public CLAVEencontrado: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Ciclo,
  @Inject(MAT_DIALOG_DATA) public datosCiclo: Ciclo,
    public dialogCicloRef: MatDialogRef<ProgramaAcademicoModalEdicionCicloComponent>,
    private dialog: MatDialog,
    private programaAcademicoService: ProgramaAcademicoService,
    private cicloService: CicloService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.inicializarFrom();
  }
  public inicializarFrom() {
    this.cicloForm = new FormGroup({
      clave: new FormControl(this.datosCiclo[0].clave, [Validators.required]),
      esOficial: new FormControl(this.datosCiclo[0].esOficial, [Validators.required]),
      cicloId: new FormControl(this.datosCiclo[0].cicloId),
      programaId: new FormControl(this.datosCiclo[0].programaId),
      nombre: new FormControl(this.datosCiclo[0].nombre, [Validators.required]),
      
    });
    this.cicloForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
    });
  }

  get clave() { return this.cicloForm.get('clave'); }
  get esOficial() { return this.cicloForm.get('esOficial'); }
  get cicloId() { return this.cicloForm.get('cicloId'); }
  get programaId() { return this.cicloForm.get('programaId'); }
  get nombre() { return this.cicloForm.get('nombre'); }

  public enviar() {
    this.data = Object.assign(this.cicloForm.value);
    // this.data.programaId = this.datosCiclo.programaId;
    this.cicloService.modificarCiclo(this.data).subscribe(
      editado => {
        if(editado) {
          this.snackBar.open('Se ha modificado la información.', 'OK', { duration: 5000 });
          this.programaAcademicoService.invocarMetodoObtenerCiclos();
          //this.programaAcademicoService.invocarMetodoCiclos();
          this.boolCambio = false;
        } else {
          this.snackBar.open('Ha ocurrido un error al modificar el ciclo. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
        }
      },
      error => {
        this.snackBar.open('Ha ocurrido un error al modificar el ciclo. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    );
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.dialogCicloRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogCicloRef.close(null);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  ngOnDestroy() {

  }

}

