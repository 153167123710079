import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service'
import { UsuarioService } from 'src/app/_services/usuario.service';

@Component({
  selector: 'app-modal-eliminar-usuario',
  templateUrl: './modal-eliminar-usuario.component.html',
  styleUrls: ['./modal-eliminar-usuario.component.scss']
})
export class ModalEliminarUsuarioComponent implements OnInit {

  public numUsuariosSeleccionados = 0;
  public boolIsArray = false;
  public clavesUsuarios = '';

  constructor(
    public dialogRef: MatDialogRef<ModalEliminarUsuarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usuarioService: UsuarioService,
    private snackBar: SnackService
  ) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida(){
    this.boolIsArray = Array.isArray(this.data);
    if(this.boolIsArray){
      this.numUsuariosSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if(this.boolIsArray) {
      let arrayClavses = this.data.map(x => (x.usuarioId.toString()));
      this.clavesUsuarios = arrayClavses.toString();
    }else {
      this.clavesUsuarios = this.data.usuarioId.toString();
    }
    this.usuarioService.eliminarUsuario(this.clavesUsuarios).subscribe(
      eliminado => {
        if(eliminado){
          this.dialogRef.close();
          this.snackBar.mostrarSnackBack('Se ha eliminado el usuario');
          this.usuarioService.invocarMetodoObtenerUsuarios();
        }else {
          this.snackBar.mostrarSnackBack('Ha ocurrido un error al eliminar el usuario/profesor. Por favor, intente de nuevo más tarde.');
        }
      },
      error => {
        this.snackBar.mostrarSnackBack('Ha ocurrido un error al eliminar el usuario/profesor. Por favor, intente de nuevo más tarde.');
      }
    );
  }

}
