import { SnackService } from 'src/app/services/snack-service.service';
import { AdministracionOpcion } from '@app/_models/AdministracionOpcion';
import { InsitucionService } from './../../../_services/insitucion.service';
import { InstitucionService } from './../../../_services/institucion.service';
import { baseUrl, environment } from './../../../../environments/environment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { I18NextPipe } from 'angular-i18next';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import i18next from 'i18next';
import { ListaMateriasOferta } from '../../../_models/lista-materias-oferta';
import { ApiResponse } from '../../../models/api/ApiRespose.model';
import { MsalService } from '@azure/msal-angular';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-ensenanza-tabs-alumno',
  templateUrl: './ensenanza-tabs-alumno.component.html',
  styleUrls: ['./ensenanza-tabs-alumno.component.scss']
})
export class EnsenanzaTabsAlumnoComponent implements OnInit, OnDestroy {

  // Subscripciones
  private subscription: Subscription = new Subscription();
  // Variables Obs
  public infoGrupo$: Observable<any>;
  public infoGrupo: any = null;
  // Variables informacion grupo
  public Grupo: any;
  public GrupoSelect: any;
  public nombreMateria = '';
  // Variable idioma
  private curentLanguaje: string;
  // Variable informacion cargada
  public infoCargada = false;
  // Variables autenticacion
  public isAuthenticated = false;
  public identificador: string;
  public datosUsuarioSubscription: Subscription;
  public usuario: any;
  public alumnoId: number;
  // Variable para manejar tabs
  public tabSeleccionadoIndex: FormControl = new FormControl();
  public tabSeleccionado = 0;
  //Tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;
  public lmsProviderId: number;
  //opciones botones
  public administracionOpcion: AdministracionOpcion = null;
  public academicModel: number;

  constructor(private i18nextPipe: I18NextPipe,
    public ensenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private activatedRoute: ActivatedRoute,
    public authenticationService: AuthenticationService,
    private msalService: MsalService,
    private router: Router,
    private institucionService: InsitucionService,
    private institucionService2: InstitucionService,
    private SnackService: SnackService) { }

  ngOnInit(): void {
    this.obtenerOpcionesBoton();
    this.obtenerInformacionInstitucion();
  }

  /**
  * Obtiene la informacion de la insitutcion
  */
  public obtenerInformacionInstitucion(): void {
    this.institucionService.obtenerInformacionInstitucion(this.tenantId).subscribe((response: any) => {
      this.lmsProviderId = response.lmsProviderId;
      this.academicModel = response.academicManagementModelId;
      this.obtenerAlumno().then(res => this.obtenerInfoGrupo());
    })
  }

    public obtenerOpcionesBoton(): void
  {
    this.institucionService2.obtenerOpcionesBoton().subscribe((response: ApiResponse<AdministracionOpcion>) => {
      if (response.success) {
        this.administracionOpcion = response.data;
      } else {
        this.SnackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
   * Metodo que obtiene el alumnoid y lo setea
   * @returns retorna una promesa
   */
  public obtenerAlumno(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.alumnoId = this.ensenanzaAprendizajeAlumnoService.alumnoId$;
      if (!this.alumnoId) {
        this.authenticationService.currentUser.subscribe(user => {
          const cuenta = this.msalService.instance.getActiveAccount();
          this.isAuthenticated = !!cuenta;
          if (this.isAuthenticated) {
            this.identificador = cuenta.username.substring(0, cuenta.username.indexOf('@'));
            this.datosUsuarioSubscription = this.authenticationService.obtenerDatosUsuario(this.identificador).subscribe(data => {
              this.usuario = data;
              if (!!this.usuario) {
                this.ensenanzaAprendizajeAlumnoService.definirAlumno(this.usuario.alumno[0].alumnoId);
                resolve();
              }
            });
          }
        });
      } else {
        resolve();
      }
    });
  }

  /**
   * Metodo que trae informacion del grupo
   */
  public obtenerInfoGrupo(): void {
    this.curentLanguaje = i18next.language;
    this.infoGrupo$ = this.ensenanzaAprendizajeAlumnoService.GetInfoGrupo$();
    this.subscription.add(
      this.infoGrupo$.subscribe(
        (info: any) => {
          if (info !== null) {
            this.infoGrupo = info;
            this.Grupo = info.Grupo;
            this.GrupoSelect = info.GrupoSelect;
            this.nombreMateria = info.nombreMateria;
            this.infoCargada = true;
            const opcionIdParam = this.activatedRoute.snapshot.paramMap.get('opcionId');
            switch (opcionIdParam) {
              case 'micurso':
                setTimeout(() => {
                  this.tabSeleccionadoIndex.setValue(0);
                }, 500);
                break;
              case 'migrupo':
                setTimeout(() => {
                  this.tabSeleccionadoIndex.setValue(1);
                }, 500);
                break;
              case 'archivosadjuntos':
                setTimeout(() => {
                  this.tabSeleccionadoIndex.setValue(2);
                }, 500);
                break;
              case 'porhacer':
                setTimeout(() => {
                  this.tabSeleccionadoIndex.setValue(3);
                }, 500);
                break;
              case 'consultar':
                setTimeout(() => {
                  this.tabSeleccionadoIndex.setValue(4);
                }, 500);
                break;
              case 'calificaciones':
                setTimeout(() => {
                  this.tabSeleccionadoIndex.setValue(5);
                }, 500);
                break;
            }
          } else {
            const grupoIdParam = this.activatedRoute.snapshot.paramMap.get('grupoId');
            const grupoId: number = +grupoIdParam;
            this.subscription.add(
              this.ensenanzaAprendizajeAlumnoService.obtenerGrupoByGrupoId(grupoId, this.curentLanguaje)
                .subscribe((response: ApiResponse<ListaMateriasOferta>) => {
                  this.Grupo = grupoId;
                  this.GrupoSelect = response.data;
                  this.nombreMateria = response.data.nombre;
                  const infoGrupo: any = {
                    nombreMateria: this.nombreMateria,
                    Grupo: this.Grupo,
                    GrupoSelect: this.GrupoSelect
                  };
                  this.infoGrupo = infoGrupo;
                  this.ensenanzaAprendizajeAlumnoService.SetInfoGrupo(infoGrupo);
                })
            );
          }
        })
    );
  }

  /**
   * Metodo que se ejecuta al cambiar los tabs de EA alumno
   * @param even
   */
  public onChangeTab(event: MatTabChangeEvent) {
    const recursoIdParam = this.activatedRoute.snapshot.paramMap.get('recursoId');
    if (recursoIdParam == null) {
      if (this.tabSeleccionado !== event.index) {
        switch (event.index) {
          case 0:
            this.router.navigate([`/detalle-grupo-alumno/grupo/${this.Grupo}/opcion/micurso`]);
            break;
          case 1:
            this.router.navigate([`/detalle-grupo-alumno/grupo/${this.Grupo}/opcion/migrupo`]);
            break;
          case 2:
            this.router.navigate([`/detalle-grupo-alumno/grupo/${this.Grupo}/opcion/archivosadjuntos`]);
            break;
          case 3:
            this.router.navigate([`/detalle-grupo-alumno/grupo/${this.Grupo}/opcion/porhacer`]);
            break;
          case 4:
            this.router.navigate([`/detalle-grupo-alumno/grupo/${this.Grupo}/opcion/consultar`]);
            break;
          case 5:
            this.router.navigate([`/detalle-grupo-alumno/grupo/${this.Grupo}/opcion/calificaciones`]);
            break;
        }
      }
    }
    this.tabSeleccionado = event.index;
  }

  /**
   * Metodo para hacer la traduccion de los tabs
   * @param text
   * @returns
   */
  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
