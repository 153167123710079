import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { TipoDocumentoDto } from '@app/_models/TipoDocumentoDto';
import { baseUrl, environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoDocumentoService {

  constructor(private httpClient: HttpClient) { }

  /**
 * Metodo para obtener catalogo de tipo documento
 * @returns 
 */
  public obtenerTipoDocumento(): Observable<ApiResponse<TipoDocumentoDto[]>> {
    return this.httpClient.get<ApiResponse<TipoDocumentoDto[]>>(`${environment.enves[baseUrl].apiUrl}/TipoDocumento/ObtenerTipoDocumento`);
  }
}
