import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { baseUrl, environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-registro',
  templateUrl: './modal-registro.component.html',
  styleUrls: ['./modal-registro.component.scss']
})
export class ModalRegistroComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalRegistroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private msalService: MsalService,
  ) { }

  ngOnInit(): void {
  }

  /**
   * Metodo cerrar el modal
   */
  public cerrarModal(): void {
    this.dialogRef.close();
  }

  /**
   * Metodo para inciar sesion
   */
  public iniciarSesion(): void {
    this.msalService.loginRedirect({
      scopes: [
        environment.enves[baseUrl].AzureAD.resources.link4CampusApi.resourceScope,
      ]
    });
  }

}
