import { ContactInformationFormModule } from './contact-information-form/contact-information-form.module';
import { OcupationInformationFormModule } from './ocupation-information-form/ocupation-information-form.module';
import { PreviousStudiesInformationFormModule } from './previous-studies-information-form/previous-studies-information-form.module';
import { BillingInformationFormModule } from './billing-information-form/billing-information-form.module';
import { MedicalInformationFormModule } from './medical-information-form/medical-information-form.module';
import { PersonalContactsInformationFormModule } from './personal-contacts-information-form/personal-contacts-information-form.module';
import { BiographicalInformationFormModule } from './biographical-information-form/biographical-information-form.module';
import { PersonalInformationFormModule } from './personal-information-form/personal-information-form.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDataFormComponent } from './user-data-form.component';




@NgModule({
  declarations: [
    UserDataFormComponent
  ],
  imports: [
    CommonModule,
    PersonalInformationFormModule,
    ContactInformationFormModule,
    BiographicalInformationFormModule,
    PersonalContactsInformationFormModule,
    MedicalInformationFormModule,
    BillingInformationFormModule,
    PreviousStudiesInformationFormModule,
    OcupationInformationFormModule
  ],
  exports: [
    UserDataFormComponent
  ]
})
export class UserDataFormModule { }
