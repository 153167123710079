import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './../../modules/material/material.module';
import { LanguageComponent } from './language.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { I18NextModule } from 'angular-i18next';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    LanguageComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    I18NextModule.forRoot(),
  ],
  exports: [
    LanguageComponent
  ]
})
export class LanguageModule { }
