import { InsitucionService } from './../../../_services/insitucion.service';
import { baseUrl, environment } from './../../../../environments/environment';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { I18NextPipe } from 'angular-i18next';
import { Observable, Subscription } from 'rxjs';
import { EnsenanzaAprendizajeService } from '../../../_services/ensenanza-aprendizaje.service';
import i18next from 'i18next';

@Component({
  selector: 'app-ensenanza-tabs-profesor',
  templateUrl: './ensenanza-tabs-profesor.component.html',
  styleUrls: ['./ensenanza-tabs-profesor.component.scss']
})
export class EnsenanzaTabsProfesorComponent implements OnInit {

  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variables Obs
  public infoGrupo$: Observable<any>;
  public infoGrupo: any = null;
  //Variables informacion grupo
  public Grupo: number;
  public GrupoSelect: any;
  public nombreMateria: string = "";
  //Variable idioma
  private curentLanguaje: string;
  //Variable informacion cargada
  public infoCargada: boolean = false;
  //Variables autenticacion
  public isAuthenticated: boolean = false;
  public identificador: string;
  public datosUsuarioSubscription: Subscription;
  public usuario: any;
  public alumnoId: number;
  //Variable para manejar tabs
  public tabSeleccionadoIndex: FormControl = new FormControl();
  public tabSeleccionado: number = 0;
  //Tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;
  public lmsProviderId: number;

  constructor(private i18nextPipe: I18NextPipe,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    private institucionService: InsitucionService) { }

  ngOnInit(): void {
    this.obtenerInformacionInstitucion();
  }

  /**
   * Obtiene la informacion de la insitutcion
   */
  public obtenerInformacionInstitucion(): void {
    this.institucionService.obtenerInformacionInstitucion(this.tenantId).subscribe((response: any) => {
      this.lmsProviderId = response.lmsProviderId;
      this.obtenerGrupo().then(res => this.redireccionGrupo());
    })
  }

  /**
   * Metodo que obtiene el grupo
   * @returns retorna una promesa
   */
  public obtenerGrupo(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.curentLanguaje = i18next.language;
      const grupoIdParam = this.activatedRoute.snapshot.paramMap.get('grupoId');
      this.Grupo = +grupoIdParam;
      this.ensenanzaAprendizajeService.obtenerGrupoProfesorByGrupoId(this.Grupo, this.curentLanguaje).subscribe((response: any) => {
        this.GrupoSelect = response;
        this.nombreMateria = response.nombre;
        resolve();
      })
    });
  }

  /**
   * Redirecciona aal tabs selccionado
   */
  public redireccionGrupo(): void {
    const opcionIdParam = this.activatedRoute.snapshot.paramMap.get('opcionId');
    switch (opcionIdParam) {
      case "micurso":
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(0);
        }, 500);
        break;
      case "migrupo":
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(1);
        }, 500);
        break;
      case "archivosadjuntos":
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(2);
        }, 500);
        break;
      case "porrevisar":
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(3);
        }, 500);
        break;
      case "consultar":
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(4);
        }, 500);
        break;
      case "calificaciones":
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(5);
        }, 500);
        break;
    }
    this.infoCargada = true;
  }

  /**
 * Metodo para hacer la traduccion de los tabs
 * @param text
 * @returns
 */
  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  /**
   * Metodo que se ejecuta al cambiar los tabs de EA alumno
   * @param event
   */
  public onChangeTab(event: MatTabChangeEvent) {
    const recursoIdParam = this.activatedRoute.snapshot.paramMap.get('recursoId');
    if (recursoIdParam == null) {
      if (this.tabSeleccionado !== event.index) {
        switch (event.index) {
          case 0:
            this.router.navigate([`/detalle-grupo-profesor/grupo/${this.Grupo}/opcion/micurso`]);
            break;
          case 1:
            this.router.navigate([`/detalle-grupo-profesor/grupo/${this.Grupo}/opcion/migrupo`]);
            break;
          case 2:
            this.router.navigate([`/detalle-grupo-profesor/grupo/${this.Grupo}/opcion/archivosadjuntos`]);
            break;
          case 3:
            this.router.navigate([`/detalle-grupo-profesor/grupo/${this.Grupo}/opcion/porrevisar`]);
            break;
          case 4:
            this.router.navigate([`/detalle-grupo-profesor/grupo/${this.Grupo}/opcion/consultar`]);
            break;
          case 5:
            this.router.navigate([`/detalle-grupo-profesor/grupo/${this.Grupo}/opcion/calificaciones`]);
            break;
        }
      }
    }
    this.tabSeleccionado = event.index;
  }

}
