import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Documento } from 'src/app/_models/documento';
import { DocumentoService } from 'src/app/_services/documento.service';
import { ProfesorService } from 'src/app/_services/profesor.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { DatosDocumentos } from 'src/app/_models/usuario';
import { SnackService } from 'src/app/services/snack-service.service';

@Component({
  selector: 'app-carga-documentos-profesor',
  templateUrl: './carga-documentos-profesor.component.html',
  styleUrls: ['./carga-documentos-profesor.component.scss']
})
export class CargaDocumentosProfesorComponent implements OnInit {
  @ViewChild('modalVisorPDF') modalVisorPDF: TemplateRef<any>;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;

  displayedColumns: string[] = ['select', 'tipo', 'nombre', 'fecha'];
  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public infoCargado = false;
  public documentos: any = [];
  public numDocumentoSeleccionado = 0;
  public documentoList: Documento[] = [];
  public tipoDocSelected: number;
  public date: Date = new Date();

  public pdf: ArrayBuffer;
  public modalVisorPDFRef: any;
  public mime: string;
  public imagen: string;

  public renderizarForm: boolean = false;
  public documentoForm: FormGroup;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public tipoAccionComprobanteIdentidad: any;
  public tipoAccionArchivoIne: any;
  public tipoAccionArchivoEstudios: any;
  public dialogoRefAdver: any;

  public comprobanteIdentidadCargado: boolean = false;
  public identificacionFotoCargado: boolean = false;
  public estudiosPreviosCargado: boolean = false;

  public cambiarComprobanteChange: boolean = false;
  public cambiarFotoChange: boolean = false;
  public cambiarEstudiosChange: boolean = false;

  public CLAVE_PROCESO = "PROFESOR";

  constructor(public dialogRef: MatDialogRef<CargaDocumentosProfesorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private profesorService: ProfesorService,
    private dialog: MatDialog,
    private utileriasService: UtileriasService,
    private snackBar: MatSnackBar,
    private snackService: SnackService,
    private documentoService: DocumentoService) { }

  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.profesorService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
    this.profesorService.updateTabs();
    this.documentoService.ObtenerDocumentosProceso(this.CLAVE_PROCESO).subscribe(documentos => this.documentoList = documentos.data);
  }

  renderizarFormulario() {
    this.documentoService.ObtenerDocumentosProcesoSubProcesoProfesor(this.CLAVE_PROCESO).subscribe(documentos => {
      this.documentoList = documentos.data;
      debugger
      if (this.datosUsuarioBusqueda.usuarioDocumento.length > 0) {
        this.documentoList.forEach(documento => {
          let documentoArreglo: any = null;
          let documentoEncontrado: any = null;
          documentoArreglo = this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === documento.documentoId);
          if (documentoArreglo.length >= 1) {
            documentoArreglo = documentoArreglo.sort(this.compareDate);
            documentoEncontrado = documentoArreglo.find(x => x.nombreFisico);
            if (documentoEncontrado !== null) {
              documentoEncontrado.documentoNombre = documento.nombre;
              this.documentos.push(documentoEncontrado);
            }
          }
        });
        if (this.documentos.length > 0) {
          console.log("DOC", this.documentos);
          this.dataSource = new MatTableDataSource(this.documentos);
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargado = true;
        }
      }
      this.inicializaFrom();
    });
  }

  compareDate(emp1: any, emp2: any) {
    var emp1Date = new Date(emp1.date).getTime();
    var emp2Date = new Date(emp2.date).getTime();
    return emp1Date > emp2Date ? 1 : -1;
  }

  public inicializaFrom() {
    this.documentoForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      tipoDocumento: new FormControl(),
      docs: new FormControl(),
      docsEditar: new FormControl(),
    }
    );
    this.renderizarForm = true;
    this.documentoForm.valueChanges.subscribe(val => {
      this.profesorService.definirFormularioValidoNuevoProfesor(this.documentoForm.valid);
    });
  }

  /**
   * Metodo para saber que tipo de documentos se selecciono
   * @param docId 
   */
  public docSelected(docId: number) {
    if (!!docId) {
      this.tipoDocSelected = docId;
    }
  }

  /**
   * Metodo para subir un documento con el select
   * @param event 
   */
  public onFileChange(event: any) {
    let documentos: any[] = [];
    let documentosEditar: any[] = [];
    const reader = new FileReader();
    const [file] = event.target.files;
    if (event.target.files && event.target.files.length) {
      let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === this.tipoDocSelected);
      reader.onload = () => {
        let documento: any = {
          usuarioId: this.datosUsuarioBusqueda.usuarioId,
          documentoId: tipoDocumentoSelecionado.documentoId,
          nombreOriginal: file.name,
          nombreFisico: reader.result,
          fechaCreacion: this.date,
          documentoNombre: tipoDocumentoSelecionado.nombre,
          estatusDocumentoId: 1
        }
        documentos.push(documento);
        if (this.dataSource === undefined || this.dataSource.data.length === 0) {
          this.dataSource = new MatTableDataSource(documentos);
          this.selection = new SelectionModel<any>(false, []);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentos;
          this.infoCargado = true;
        } else {
          documentosEditar = this.dataSource.data;
          documentosEditar = documentosEditar.filter(x => x.documentoId !== this.tipoDocSelected);
          this.dataSource = new MatTableDataSource(documentosEditar);
          this.selection = new SelectionModel<DatosDocumentos>(true, []);
          this.dataSource.data.push(documento);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentosEditar;
        }
        this.registrarDoc(documentos);
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * Metodo para editar un documento seleccionado
   * @param event 
   * @param tipoId 
   */
  public onFileChangeEditar(event, tipoDocumentoId: number) {
    let documentosEditar: DatosDocumentos[] = [];
    let documentosGuardar: any[] = [];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === tipoDocumentoId);
      reader.onload = () => {
        let documento = {
          usuarioId: this.datosUsuarioBusqueda.usuarioId,
          documentoId: tipoDocumentoSelecionado.documentoId,
          nombreOriginal: file.name,
          nombreFisico: reader.result,
          fechaCreacion: this.date,
          documentoNombre: tipoDocumentoSelecionado.nombre,
          estatusDocumentoId: 1
        }
        documentosEditar = this.documentos;
        documentosEditar = documentosEditar.filter(x => x.documentoId !== tipoDocumentoId);
        this.dataSource = new MatTableDataSource(documentosEditar);
        this.selection = new SelectionModel<DatosDocumentos>(true, []);
        this.dataSource.data.push(documento);
        this.dataSource._updateChangeSubscription();
        this.documentos = this.dataSource.data;
        documentosGuardar.push(documento);
        this.registrarDoc(documentosGuardar);
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * Metodo para subir un documento 
   * @param docs 
   */
  public registrarDoc(docs: Array<DatosDocumentos>) {
    if (docs != null) {
      this.profesorService.registrarDocumentos(docs).subscribe(
        cargaDocumentos => {
          if (cargaDocumentos) {
            this.profesorService.invocarMetodoObtenerProfesor();
            this.profesorService.updateTabs();
            this.profesorService.cleanFiltros();
            this.dataSource._updateChangeSubscription();
          } else {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los documentos. Por favor, intente de nuevo más tarde.');
          }
        }
      );
    }
  }

  /**
   * Metodo para eliminar un documento
   * @param documentId 
   */
  public eliminarDocumento(documentId: number) {
    if (documentId) {
      this.profesorService.deleteDocumentoById(this.datosUsuarioBusqueda.usuarioId, documentId).subscribe(documentDelete => {
        if (documentDelete) {
          let documentosEliminar: DatosDocumentos[] = [];
          documentosEliminar = this.documentos;
          documentosEliminar = documentosEliminar.filter(x => x.documentoId !== documentId);
          this.dataSource = new MatTableDataSource(documentosEliminar);
          this.selection = new SelectionModel<DatosDocumentos>(true, []);
          this.documentos = documentosEliminar;
          if (this.documentos.length === 0) {
            this.infoCargado = false;
          }
          this.dataSource._updateChangeSubscription();
          this.profesorService.invocarMetodoObtenerProfesor();
          this.profesorService.updateTabs();
          this.profesorService.cleanFiltros();
          this.snackService.mostrarSnackBack('Se ha eliminado el documento.');
        }
      });
    }
  }

  get fotografia() { return this.documentoForm.get('fotografia'); }
  get comprobante() { return this.documentoForm.get('comprobante'); }
  get ine() { return this.documentoForm.get('ine'); }
  get estudios() { return this.documentoForm.get('estudios'); }

  public visualizarPdf(refDocumento: any) {
    if (refDocumento > 0) {

      this.profesorService.GetDocumentosProfesorByCorreo(this.datosUsuarioBusqueda.correo, refDocumento).subscribe(
        datosUsuarioDocumento => {
          if (datosUsuarioDocumento) {
            var documentos = datosUsuarioDocumento.usuarioDocumento.filter(x => x.documentoId === refDocumento);
            var documentoVisualizar = documentos.sort(this.compareDate);

            this.mime = documentoVisualizar[0].nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : /\.(png|jpeg|jpg|gif|tiff|bmp)$/.test(documentoVisualizar[0].nombreOriginal) ? "image/" + /\.(png|jpeg|jpg|gif|tiff|bmp)$/.exec(documentoVisualizar[0].nombreOriginal)[1] : "application/octect-stream";

            this.pdf = this.base64ToArrayBuffer(documentoVisualizar[0].nombreFisico);
            //this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
            if (this.mime.indexOf("image") >= 0) {
              this.imagen = `data:${this.mime};base64,${documentoVisualizar[0].nombreFisico}`;
            }
            if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
              this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
            } else {
              const blob = new Blob([this.pdf], { type: this.mime });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            }
          }
        });
    } else {
      let archivo = this.documentoForm.controls[refDocumento].value;
      this.mime = archivo.split(",")[0].split(":")[1].split(";")[0];
      this.imagen = this.documentoForm.controls[refDocumento].value;
      this.pdf = this.base64ToArrayBuffer(archivo);
      if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
        this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
      } else {
        const blob = new Blob([this.pdf], { type: this.mime });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    }

  }

  public cerrarModalPdf() {
    this.modalVisorPDFRef.close();
  }


  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  isAllSelected() {
    this.numDocumentoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numDocumentoSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.documentoId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row))
  }



}
