import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Correo } from 'src/app/_models/correo';
import { ProfesorService } from '../../../_services/profesor.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';

@Component({
  selector: 'app-oferta-profesor-correo',
  templateUrl: './oferta-profesor-correo.component.html',
  styleUrls: ['./oferta-profesor-correo.component.scss']
})
export class OfertaProfesorCorreoComponent implements OnInit {
  public numProfesoresSeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public correosProfesores: string = '';
  public boolCambio: boolean = false;
  public correoProfesorForm: FormGroup;


  constructor(public dialogRef: MatDialogRef<OfertaProfesorCorreoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private profesorService: ProfesorService,
    private nuevoIngresoService: NuevoIngresoService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
    this.inicializarForm();
  }


  get Asunto() { return this.correoProfesorForm.get('Asunto'); }
  get CuerpoDelMensaje() { return this.correoProfesorForm.get('CuerpoDelMensaje'); }

  public inicializarForm() {
    this.correoProfesorForm = new FormGroup({
      Asunto: new FormControl('', [Validators.required]),
      CuerpoDelMensaje: new FormControl('', [Validators.required]),
    });
  }
  public enviarCorreo() {
    if (this.boolIsArray) {
      const arrayCorreos = this.data.map(p => (p.correo.toString()));
      this.correosProfesores = arrayCorreos.toString();
    } else {
      this.correosProfesores = this.data.correo.toString();
    }

    const estructuraCorreo = new Correo();

    estructuraCorreo.CorreosDestinatario = this.correosProfesores;
    estructuraCorreo.Asunto = this.correoProfesorForm.get('Asunto').value;
    estructuraCorreo.CuerpoDelMensaje = this.correoProfesorForm.get('CuerpoDelMensaje').value;

    this.profesorService.enviarCorreos(estructuraCorreo).subscribe(correoEnviado => {
      if (correoEnviado) {
        this.snackBar.open('Se ha enviado el correo con exito.', 'OK', { duration: 5000 });
      } else {
        this.snackBar.open('Ha ocurrido un error al enviar el correo. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    });


  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numProfesoresSeleccionados = this.data.length;
    }
  }
  public cerrarModal() {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }


}
