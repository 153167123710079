import { TraductorService } from './../../../../../_services/traductor.service';
import { SnackService } from './../../../../../services/snack-service.service';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { MakeUpExamReasonModel as MakeUpExamReasonModel } from './../../../../core.domain/models/make-up-exam/make-up-exam-reason.response';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MAKE_UP_EXAM_CONTROLS } from '../../../../core.application/validators/make-up-exam-form.validatos';
import { EmitterSaveActionService } from '../../../../infrastructure/services/common/emitter-save-action.service';
import { MakeUpExamReasonService } from '../../../../infrastructure/services/make-up-exam-reason/make-up-exam-reason.service';
import { ApiResponse } from '../../../../../models/api/ApiRespose.model';
import { Mapping } from '../../../../core.domain/class/mapping.class';
import { AlumnoGrupoMakeupExam } from '../../../../core.domain/models/make-up-exam/alumno-grupo-make-up-exam';

@Component({
  selector: 'app-make-up-exam-form',
  templateUrl: './make-up-exam-form.component.html',
  styleUrls: ['./make-up-exam-form.component.scss']
})
export class MakeUpExamFormComponent implements OnInit, OnChanges, OnDestroy {
//#region Inputs, Outputs & Emiiers
@Input() data: any;
@Input() tabId: number;
@Input() tabIndex: number;
@Output() formValid = new EventEmitter<boolean>();
//#endregion

//#region Observable
private Subscription: Subscription = new Subscription();
//#endregion

//#region Properties
private change: boolean = false;
//#endregion

//#region Properties

public MakeUpExamReason       : MakeUpExamReasonModel[];
public AlumnoGrupoMakeupExam  : AlumnoGrupoMakeupExam;
public Options: any[] = [{optionId: true, name: 'Si'}, {optionId: false, name: 'No'}];

public makeUpExamForm: FormGroup = this.formBuilder.group(MAKE_UP_EXAM_CONTROLS);
//#endregion

//#region LifeCycle
constructor(
  private _MakeUpExamReasonService  : MakeUpExamReasonService,
  private _EmitterSaveActionService : EmitterSaveActionService,
  private _snackService: SnackService,
  private _traductorService: TraductorService,
  private formBuilder: FormBuilder) {    
  this.Subscription.add(
    _EmitterSaveActionService.$emitSaveTab.subscribe((tabIndex: number) => {
      if (tabIndex === this.tabId) {        
        this.save();
      }
    })
  );
}

ngOnInit(): void {
  this._subscribeToForm();
  this._getMakeUpExamReason(MakeUpExamReasonModel);  
}

ngOnChanges(changes: SimpleChanges): void {
  if (changes['tabIndex']) {
    changes['tabIndex'].currentValue === this.tabId ? this.Emit() : null;
  }
  if (changes['data'] !== undefined) {
    this._setData();
  }
}

ngOnDestroy(): void {
  if ( this.Subscription ) {
    this.Subscription.unsubscribe();
  }
}
//#endregion


//#endregion Methods
  private _subscribeToForm(): void {
    this.Subscription.add(
      this.makeUpExamForm.valueChanges
        .subscribe(status => {
          if (status.options !== null && status.makeUpExamReason !== null) {

            if (status.options === false && status.makeUpExamReason !== 0) {
              this.makeUpExamForm.get('makeUpExamReason')?.setValue(0);
            }

            this.change = true;
            this.AlumnoGrupoMakeupExam = new AlumnoGrupoMakeupExam(
              this.data.alumnoId,
              this.data.grupoId,
              status.makeUpExamReason
            );            
            this.Emit();
          }else {
            this.change = false;
          }
        }
      )
    );
  }

  public Emit():void{
    this.formValid.emit(this.change);
  }

  private save():void
  {    
    if (this.makeUpExamForm.get('options')?.value !== false && this.makeUpExamForm.get('makeUpExamReason')?.value !== 0) {
      this.postAlumnoGrupoMakeupExam();
    } else if (this.makeUpExamForm.get('options')?.value === false && this.makeUpExamForm.get('makeUpExamReason')?.value === 0) {
      this.deleteAlumnoGrupoMakeupExam();
    }
  }
 //#endregion


 //#region setData
 private _setData():void
 {  
   this.makeUpExamForm.get('options')?.setValue(this.data.isMakeUpExam);   
   this.makeUpExamForm.get('makeUpExamReason')?.setValue(this.data.makeUpExamReasonId);
 }
 //#endregion


 //#region MethodsApi
 public _getMakeUpExamReason<T extends Mapping>(_class: T):void {        
    this._MakeUpExamReasonService.getMakeUpExamReason(_class).subscribe((response: ApiResponse<any[]>) => {    
      this.MakeUpExamReason = response.data;    
    });    
  }

private postAlumnoGrupoMakeupExam():void {        
    this._MakeUpExamReasonService.postAlumnoGrupoMakeupExam(this.AlumnoGrupoMakeupExam).subscribe(
      (response: SimpleResponse) => {
        if (response.success) {
          let mensaje: string = this._traductorService.translate('guardadoCorrecto');
          this._snackService.mostrarSnackBack(mensaje);
        }else{
          let mensaje: string = this._traductorService.translate('errorGuardar');
          this._snackService.mostrarSnackBack(mensaje);
        }
    });    
  }

  private deleteAlumnoGrupoMakeupExam():void {        
    this._MakeUpExamReasonService.deleteAlumnoGrupoMakeupExam(this.AlumnoGrupoMakeupExam).subscribe(
      (response: SimpleResponse) => {
        if (response.success) {
          let mensaje: string = this._traductorService.translate('guardadoCorrecto');
          this._snackService.mostrarSnackBack(mensaje);
        }else{
          let mensaje: string = this._traductorService.translate('errorGuardar');
          this._snackService.mostrarSnackBack(mensaje);
        }
    });    
  }
 //#endregion 

}
