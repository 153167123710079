import { ApiResponse } from './../models/api/ApiRespose.model';
import { ProfesotDTO } from './../_models/ProfesotDTO';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, Subject } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';

import { Profesor } from '../_models/profesor';
import { Correo } from '../_models/correo';

@Injectable({
  providedIn: 'root'
})
export class ProfesorService {
  private datosUsuarioBusqueda$ = new Subject<any>();
  private formValidoNuevoProfesor$ = new Subject<boolean>();

  private idProfesor$ = new Subject<number>();
  private boolTabDisabled$ = new Subject<boolean>();
  private boolCambioPendiente$ = new Subject<boolean>();

  private formCambio$ = new Subject<boolean>();
  private formCambioNombre$ = new Subject<string>();
  private changesContrato$ = new Subject<boolean>();
  private changesProfesor$ = new Subject<boolean>();
  private changesDocumentos$ = new Subject<boolean>();
  private changesFormacionAcademica$ = new Subject<boolean>();
  private changesFamiliares$ = new Subject<boolean>();

  private formValidoRegistroNuevoProfesor$ = new Subject<boolean>();

  invocarObtenerProfesor = new EventEmitter();
  updateTabsEmitter = new EventEmitter();
  refrescarDatosPrincipal = new EventEmitter();
  limpiarFiltros = new EventEmitter();
  invMetodoSubscription: Subscription;
  updateTabsSubscription: Subscription;
  limpiarFiltrosSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  //Get Profesor
  public obtenerProfesor(): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Profesor`);
  }
  //Get Profesor Inactivos
  public obtenerProfesorInactivos(): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Profesor/obtenerProfesorInactivos`);
  }

  //Get CorreoOcupado
  public obtenerCorreoOcupado(correo: string, usuarioId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Profesor/CorreoOcupado?correo=` + correo + `&usuarioId=` + usuarioId);
  }

  //Post Profesor
  public agregarProfesor(profesor: Profesor): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Profesor`, profesor);
  }

  //Put Profesor
  public modificarProfesor(profesor: Profesor): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Profesor`, profesor);
  }

  //Delete Profesor
  public eliminarProfesor(profesorId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Profesor?profesorId=` + profesorId);
  }

  //Delete ProfesorByUsuarioID
  public eliminarProfesorByUsuarioID(profesorId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Profesor/DeleteProfesorByUsuarioID?profesorId=` + profesorId);
  }
  //Obtener Profesores por Usuario ID
  public obtenerProfesoresByUsuario(usuarioId: number): Observable<any> {               //GetProfesorByUsuarioID
    return this.httpClient.get<Profesor>(`${environment.enves[baseUrl].apiUrl}/Profesor/GetProfesorByUsuarioID?usuarioId=` + usuarioId);
  }

  //Obtener Profesores por correo
  public obtenerUsuarioProfesor(correo: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Profesor/GetUsuarioProfesor?correo=` + correo);
  }

  //Post Enviar correo a profesores
  public enviarCorreos(correos: Correo): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Profesor/EnviarCorreos`, correos);
  }

  //Post Documentos
  public registrarDocumentos(documentos): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Profesor/PostDocumentos`, documentos);
  };

  //Post/Put Datos Familia
  public registrarDatosFamilia(datosFamilia): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/profesor/PostDatosFamilia`, datosFamilia);
  };

  //Post/Put Datos Emergencia
  public registrarDatosEmergencia(datosEmergencia): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/profesor/PostDatosEmergencia`, datosEmergencia);
  };

  //Post/Put Datos Laborales
  public registrarDatosLaborales(datosProfesor): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/profesor/PostDatosLaborales`, datosProfesor);
  }

  //Post/Put Datos Fiscal
  public registrarDatosFiscales(datoFiscal): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/profesor/PostDatosFiscales`, datoFiscal);
  }

  //Get Boolean Formulario Valido Observable
  public obtenerFormularioValidoNuevoProfesor$(): Observable<any> {
    return this.formValidoNuevoProfesor$.asObservable();
  }

  // Set Boolean Formulario Valido Observable
  public definirFormularioValidoNuevoProfesor(boolForm: boolean) {
    this.formValidoNuevoProfesor$.next(boolForm);
  }

  // Set Datos Usuario Buqueda Observable
  public definirDatosUsuarioBusqueda(datosUsuario: any) {
    this.datosUsuarioBusqueda$.next(datosUsuario);
  }


  //Get Datos Usuario Busqqueda Observable
  public obtenerDatosUsuarioBusqueda$(): Observable<any> {
    return this.datosUsuarioBusqueda$.asObservable();
  }

  //Invocar Metodo obtenerProfesor
  invocarMetodoObtenerProfesor() {
    this.invocarObtenerProfesor.emit();
  }

  //Descargar Documentos usuario
  public imagenUsuario(id): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/profesor/GetImagenUsuarioById/` + id);
  };


  //Put Datos Personales
  public editarImagen(imagenUsuario): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/profesor/PutImagenUsuario`, imagenUsuario);
  };

  //Descargar Documentos usuario
  public GetDocumentosProfesorByCorreo(correo, id): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/profesor/GetDocumentosProfesorByCorreo/` + correo + '/' + id);
  };

  //Delete ProfesorByUsuarioID
  public reactivarProfesorByUsuarioID(profesorId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/profesor/ReactivarProfesorByUsuarioID?profesorId=` + profesorId);
  }

  //Delete DocumentoProfesorByUsuarioID
  public deleteDocumentoById(usuarioId: number, documentId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/profesor/DeleteDocumentoById/` + usuarioId + '/' + documentId);
  }

  public updateTabs() {
    this.updateTabsEmitter.emit();
  }

  public updateFiltros() {
    this.refrescarDatosPrincipal.emit();
  }

  cleanFiltros() {
    this.limpiarFiltros.emit();
  }

  public obtenerFormularioValidoRegistroNuevoProfesor$(): Observable<any> {
    return this.formValidoRegistroNuevoProfesor$.asObservable();
  }

  // Set Boolean Formulario Valido Observable
  public definirFormularioValidoRegistroNuevoProfesor(boolForm: boolean) {
    this.formValidoRegistroNuevoProfesor$.next(boolForm);
  }

  //------OBSERVABLES MODAL BUTTON ---------------------//
  // Set Changes in Datos Contrato
  public setChangesContrato(change: boolean) {
    this.changesContrato$.next(change);
  }

  //Get changes Datos Contrato
  public getChangesContrato$(): Observable<boolean> {
    return this.changesContrato$.asObservable();
  }

  // Set Changes in personal data
  public setChangesProfesor(change: boolean) {
    this.changesProfesor$.next(change);
  }

  //Get changes in personal data
  public getChangesProfesor$(): Observable<boolean> {
    return this.changesProfesor$.asObservable();
  }

  // Set Changes in documents
  public setChangesDocumentos(change: boolean) {
    this.changesDocumentos$.next(change);
  }

  //Get changes in documents
  public getChangesDocumentos$(): Observable<boolean> {
    return this.changesDocumentos$.asObservable();
  }

  // Set Changes in formacion
  public setChangesFormacionAcademica(change: boolean) {
    this.changesFormacionAcademica$.next(change);
  }

  //Get changes in formacion
  public getChangesFormacionAcademica$(): Observable<boolean> {
    return this.changesFormacionAcademica$.asObservable();
  }

  //Set changes in familiares
  public setChangesFamiliares(change: boolean) {
    this.changesFamiliares$.next(change);
  }

  //Get changes in familiares
  public getChangesFamiliares$(): Observable<boolean> {
    return this.changesFamiliares$.asObservable();
  }

  //Download Excel
  public descargarExcel(profesores: Profesor) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Profesor/DownloadExcel`, JSON.stringify(profesores),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }



  //#region AdminAcademica Profesor Modulo
  public ObtenerProfesoresInfo(): Observable<ApiResponse<ProfesotDTO[]>> {
    return this.httpClient.get<ApiResponse<ProfesotDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Profesor/ObtenerProfesoresInfo`);
  }
  //#endregion

    //Download Excel
    public DownloadExcelProfesorInfo(UsuarioId: any) {
      return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Profesor/DownloadExcelProfesorInfo`, JSON.stringify(UsuarioId),
        {
          responseType: "blob",
          headers: {
            'Content-Type': 'application/json',
          }
        });
    }
}
