import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { PagoZumaModalComponent } from 'src/app/_components/pago-zuma-modal/pago-zuma-modal.component';
import { EstatusUsuarioEnum } from 'src/app/_models/estatusUsuario.enum';
import { InscripcionCosto } from 'src/app/_models/inscripcion-costo';
import { IntegracionDto } from 'src/app/_models/integracionDto';
import { IntentoPagoZumaDTO } from 'src/app/_models/IntentoPagoZumaDTO.model';
import { ValidarCostoInscripcionDto } from 'src/app/_models/validarCostoInscripcionDto';
import { PagoZuma } from 'src/app/_models/Zuma/LinkPago/PagoZuma';
import { PagoZumaResponse } from 'src/app/_models/Zuma/LinkPago/PagoZumaResponse';
import { ValidarInscripcionExistente } from 'src/app/_models/Zuma/validarInscripcionExistente';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { IntegracionService } from 'src/app/_services/integracion.service';
import { PagoLineaZumaService } from 'src/app/_services/pago-linea-zuma.service';
import { SeguimientoCarteraService } from 'src/app/_services/seguimiento-cartera.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { TraductorService } from '../../../../_services/traductor.service';

@Component({
  selector: 'app-inscripcion-admision',
  templateUrl: './inscripcion-admision.component.html',
  styleUrls: ['./inscripcion-admision.component.scss']
})
export class InscripcionAdmisionComponent implements OnInit, OnDestroy {

  //Variables Obs
  public infoUsuario$: Observable<any>;
  public infoUsuario: any = null;
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variables inscripcion costo
  public inscripcionCosto: InscripcionCosto = null;
  //Variable Fecha actual
  public fechaActual: Date = new Date();
  //Variable renderizar componente
  public inscripcionRenderizar: boolean = false;
  //Variable para controlar el boton de pagar
  public metodoPagoDisponible: boolean = false;
  //Variables Subcripcion eventemmiter
  public informacionInvocarMetodo: any;


  constructor(
    private admisionesService: AdmisionesService,
    private snackService: SnackService,
    private utileriasService: UtileriasService,
    private seguimientoCarteraService: SeguimientoCarteraService,
    public pagoLineaZumaService: PagoLineaZumaService,
    private integracionService: IntegracionService,
    private traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.ObtenerDatosIntegracion();
    if (this.pagoLineaZumaService.invocarMetodoSubscription == undefined) {
      this.pagoLineaZumaService.invocarMetodoSubscription = this.pagoLineaZumaService.invocarMetodo.subscribe((event) => {
        this.guardarIntentoPago(event.pagoZuma, event.url);
      });
    }
  }

  /**
   * Obtinene la informacion del usuario
   */
  public renderizarInscripcion(): void {
    this.infoUsuario$ = this.admisionesService.GetInfoUsuario$();
    this.subscription.add(
      this.infoUsuario$.subscribe(
        (info: any) => {
          this.infoUsuario = info;
          this.obtenerInscripcion();
        })
    );
  }

  /**
   * Metodo para obtener los metodos de pago configurados
   */
  public ObtenerDatosIntegracion(): void {
    this.subscription.add(
      this.integracionService.ObtenerDatosIntegracion().subscribe((response: ApiResponse<IntegracionDto[]>) => {
        if (response.success) {
          if (response.data.length > 0) {
            this.metodoPagoDisponible = true;
          } else {
            this.metodoPagoDisponible = false;
          }
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      })
    );
  }

  /**
   * obtiene la informacion de la inscripcion
   */
  public obtenerInscripcion(): void {
    let datauser: ValidarCostoInscripcionDto = {
      campusId: this.infoUsuario.alumnoRegistrado[0].campusId,
      periodoId: this.infoUsuario.alumnoRegistrado[0].priodoIngreso,
      programaId: this.infoUsuario.alumnoRegistrado[0].programaId,
      tipoAlumno: 'NI'
    };
    this.subscription.add(
      this.admisionesService.obtenerInscripcionCosto(datauser).subscribe(
        (response: ApiResponse<InscripcionCosto>) => {
          if (response.success) {
            if (response.data !== null) {
              this.inscripcionCosto = response.data;
              this.inscripcionRenderizar = true;
            } else {
              this.snackService.mostrarSnackBack("No existe una inscripción costo");
            }
          } else {
            this.snackService.mostrarSnackBack(response.message);
          }
        }
      )
    );
  }


  public validarCargoInscripcion(): void {
    this.subscription.add(
      this.seguimientoCarteraService.validarCargoInscripcion(this.infoUsuario.usuarioId, this.infoUsuario.alumnoRegistrado[0].alumnoId, this.infoUsuario.alumnoRegistrado[0].priodoIngreso).subscribe((respuesta: ApiResponse<ValidarInscripcionExistente>) => {
        if (respuesta.success) {
          if (!respuesta.data.existeCargoInscripcion) {
            this.guardarInscripcionAlumno();
          }
          if (respuesta.data.existeCargoInscripcion) {
            this.enviarPagoZuma();
          }
        } else {
          this.snackService.mostrarSnackBack(respuesta.message);
        }
      })
    );
  }

  /**
   * Metodo que abre el componente modal para hacer el pago con zuma
   */
  public guardarInscripcionAlumno(): void {
    let inscripcion: any = {
      usuarioId: this.infoUsuario.usuarioId,
      tipoCargoId: 2,
      monto: this.inscripcionCosto.costoFinal,
      descripcion: `Inscripción ${this.infoUsuario.alumnoRegistrado[0].priodoIngresoNavigation.nombre}`,
      fechaVencimiento: this.inscripcionCosto.fechaInicio,
      alumnoId: this.infoUsuario.alumnoRegistrado[0].alumnoId,
      periodoId: this.infoUsuario.alumnoRegistrado[0].priodoIngreso,
      PeriodoSeleccionadoInscripcion: this.infoUsuario.alumnoRegistrado[0].priodoIngreso
    }
    this.subscription.add(
      this.seguimientoCarteraService.enviarInscripcion(inscripcion).subscribe(
        (respuestaInscripcion: SimpleResponse) => {
          if (respuestaInscripcion.success) {
            this.enviarPagoZuma();
          } else {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        })
    );
  }

  /**
   * Metodo que llama el api de zuma y genera el folio para hacer el pago
   */
  public enviarPagoZuma(): void {
    let pagoZuma: PagoZuma = {
      reference: "Pago de Inscripción",
      amount: this.inscripcionCosto.costoFinal,
      promotion: "1",
      productCode: "AWPE_RPVT_XUG",
      expire: 1,
      sendLink: false,
      email: this.infoUsuario.correo,
      paymentChannel: "ZUMA_API"
    }
    this.utileriasService.abrirModalPagoZuma(PagoZumaModalComponent, pagoZuma);
  }

  /**
   * Metodo que sirve para guardar el intento pago junto con una consulta que obtiene el cargo para enviarlo
   */
  public guardarIntentoPago(pagoZuma: PagoZuma, respuestaPagoZuma: PagoZumaResponse): void {
    this.subscription.add(
      this.seguimientoCarteraService.obtenerCargoInscripcionPendiente(this.infoUsuario.usuarioId, this.infoUsuario.alumnoRegistrado[0].priodoIngreso, this.infoUsuario.alumnoRegistrado[0].alumnoId).subscribe((respuesta: ApiResponse<any>) => {
        if (respuesta.success) {
          respuesta.data.motorPagoId = 1;
          let cargo: any[] = [];
          cargo.push(respuesta.data);
          let PagoIntento: IntentoPagoZumaDTO = {
            cargos: cargo,
            pagoZuma: pagoZuma,
            pagoZumaResponse: respuestaPagoZuma
          }
          this.pagoLineaZumaService.PostIntentoPago(PagoIntento).subscribe((respuesta: SimpleResponse) => {
            if (respuesta.success) {
              console.log("CORRECTO");
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          });
        } else {
          let mensaje: string = this.traductorService.translate('errorCargoInscripcion');
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    );
  }

  /**
  * Se manda a llamar cuando se cierra el componente y se desuscribe de todos los select elegidos
  */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
