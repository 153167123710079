import { baseUrl, environment } from './../../environments/environment';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { AuthenticationService } from './authentication.service';
import { BYPASS_JW_TOKEN } from '@app/_shared/jwt.interceptor';

@Injectable({
  providedIn: 'root'
})
export class ConsultaDatosService {

  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  public ConsultaDatosConstancia(): Observable<ApiResponse<any>> {
    let TokenConstancia = this.authenticationService.getTokenConstancia()

    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/ConsultaDatos/ConsultaDatosConstancia`,
    {
      headers: new HttpHeaders()
      .set('Authorization', `Bearer ${TokenConstancia}`)
      .set('Constancia', `On`),
      context: new HttpContext().set(BYPASS_JW_TOKEN, true),
    });
  }
}
