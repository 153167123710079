import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EscolaresRoutingModule } from './escolares-routing.module';
import { MovimientosComponent } from './movimientos/movimientos.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { I18NextModule } from 'angular-i18next';
import { MomentModule } from 'ngx-moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  declarations: [
    MovimientosComponent
  ],
  imports: [
    CommonModule,
    EscolaresRoutingModule,

    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MomentModule,
    I18NextModule.forRoot(),
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatTableModule,
    MatNativeDateModule,
    MatRippleModule,
    MatInputModule,
    MatTooltipModule,
    NgbModule,
    MatDividerModule

  ]
})
export class EscolaresModule { }
