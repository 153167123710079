import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { Empresa } from 'src/app/_models/empresa';
import { TipoApoyoFinanciero } from 'src/app/_models/tipo-apoyo-financiero';
import { EmpresaService } from 'src/app/_services/empresa.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { TipoApoyoFinancieroService } from 'src/app/_services/tipo-apoyo-financiero.service';
import { MatTableDataSource } from '@angular/material/table';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { DatosDocumentos } from 'src/app/_models/usuario';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { EstatusSolicitudApoyoDTO } from 'src/app/_models/EstatusSolicitudApoyoDTO';

@Component({
  selector: 'app-beca-apoyo-financiero',
  templateUrl: './beca-apoyo-financiero.component.html',
  styleUrls: ['./beca-apoyo-financiero.component.scss']
})
export class BecaApoyoFinancieroComponent implements OnInit {

  displayedColumns: string[] = ['Tipo', 'Nombre', 'Fecha', 'Accion'];
  public dataSource: MatTableDataSource<DatosDocumentos>;
  public renderizarForm: boolean = false;
  public infoCargado = false;
  public cartaCompromiso: any;
  public comprobanteIdentidad: any;
  public identificacionFotografia: any;
  public estudiosPrevios: any;
  public comprobantePromedio: any;
  public comprobantePromedioCargado: boolean = false;
  public comprobanteIdentidadCargado: boolean = false;
  public identificacionFotoCargado: boolean = false;
  public cartaCompromisoCargado: boolean = false;
  public estudiosPreviosCargado: boolean = false;
  public tipoAccionComprobanteIdentidad: DatosDocumentos[] = [];
  public tipoAccionCartaCompromiso: DatosDocumentos[] = [];
  public tipoAccionIdentificacionFotografia: DatosDocumentos[] = [];
  public tipoAccionEstudiosPrevios: DatosDocumentos[] = [];

  public tipoAccion: AccionEnum;
  public formApoyoFinanciero: FormGroup;
  public subscritpionCampus: Subscription;
  public subscriptionPeriodo: Subscription;
  public subscriptionPrograma: Subscription;
  public subscriptionApoyoFinanciero: Subscription;
  public subscriptionEmpresa: Subscription;

  public tipoAccionComprobantePromedio: DatosDocumentos[] = [];
  public isChecked: boolean = false;
  public noEditarBeca: boolean = false;
  public boolCambio: boolean = false;
  public dialogoRefAdver: any;
  public documentos: any = [];
  public imagen: string;
  public modalVisorPDFRef: any;
  public pdf: ArrayBuffer;
  public mime: string;

  //Variables catalogos
  public apoyoList: TipoApoyoFinanciero[] = [];
  public EstatusSolicitudApoyoDTO: EstatusSolicitudApoyoDTO[] = [];
  public empresaList: Empresa[] = [];
  //Variables Obs
  public datosUsuarioBusqueda$: Observable<UsuarioIngreso>;
  public datosUsuarioBusqueda: UsuarioIngreso;

  //Subscripciones
  private subscription: Subscription = new Subscription();

  @ViewChild('modalVisorPDF') modalVisorPDF: TemplateRef<any>;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;

  constructor(
    private apoyoService: TipoApoyoFinancieroService,
    private empresaService: EmpresaService,
    public nuevoIngresoService: NuevoIngresoService,
    public dialogRef: MatDialogRef<BecaApoyoFinancieroComponent>,
    public util: UtileriasService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
    // this.datosUsuarioBusqueda$.subscribe(datos => {
    //   this.datosUsuarioBusqueda = datos;
    // });
    this.getTipoEmpresa();
    this.getTipoApoyo();
    this.GetestatusSolicitudApoyo();
  }

  public inicializarForm() {
    this.formApoyoFinanciero = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      campusId: new FormControl(this.datosUsuarioBusqueda.alumno[0].campusId),
      priodoIngreso: new FormControl(this.datosUsuarioBusqueda.alumno[0].priodoIngreso),
      programaId: new FormControl(this.datosUsuarioBusqueda.alumno[0].programaId),
      tipoApoyoFinanciero: new FormControl(this.datosUsuarioBusqueda.solicitudApoyoFinanciero.length == 0 ? 0 : this.datosUsuarioBusqueda.solicitudApoyoFinanciero[0].tipoApoyoFinancieroId, [Validators.required, this.validateId]),
      estatusSolicitudApoyo: new FormControl(this.datosUsuarioBusqueda.solicitudApoyoFinanciero.length == 0 ? 0 : this.datosUsuarioBusqueda.solicitudApoyoFinanciero[0].estatusSolicitudApoyoId, [Validators.required, this.validateId]),
      empresa: new FormControl({ value: this.datosUsuarioBusqueda.solicitudApoyoFinanciero.length == 0 ? 0 : this.datosUsuarioBusqueda.solicitudApoyoFinanciero[0].empresaId, disabled: !this.datosUsuarioBusqueda?.solicitudApoyoFinanciero[0]?.convenio }, [Validators.required, this.validateId]),
      promedio: new FormControl(this.datosUsuarioBusqueda.solicitudApoyoFinanciero.length == 0 ? 0 : this.datosUsuarioBusqueda.solicitudApoyoFinanciero[0].promedio, [Validators.required, this.validateId]),
    });
    this.renderizarForm = true;
    this.formApoyoFinanciero.valueChanges.subscribe(val => {
      this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.formApoyoFinanciero.valid);
      this.nuevoIngresoService.definirCambioNombreForms("Apoyo Financiero");
      this.nuevoIngresoService.setChangesFinancialSupport(true);
    });
    this.formApoyoFinanciero.controls.tipoApoyoFinanciero.valueChanges.subscribe((s) => {
      let apoyofinanciero = this.apoyoList.find(f => f.tipoApoyoFinancieroId === s);
      if (!apoyofinanciero.convenio) {
        this.formApoyoFinanciero.controls.empresa.disable();
      } else {
        this.formApoyoFinanciero.controls.empresa.enable();
      }
    });
  }


  public inicializarFormProfesor() {
    this.formApoyoFinanciero = new FormGroup({
      tipoApoyoFinanciero: new FormControl(this.tipoAccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.solicitudApoyoFinanciero[0].tipoApoyoFinancieroId, [Validators.required, this.validateId]),
      estatusSolicitudApoyo: new FormControl(this.tipoAccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.solicitudApoyoFinanciero[0].estatusSolicitudApoyoId, [Validators.required, this.validateId]),
      empresa: new FormControl(this.tipoAccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.solicitudApoyoFinanciero[0].empresaId, [Validators.required, this.validateId]),
      promedio: new FormControl(this.tipoAccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.solicitudApoyoFinanciero[0].promedio, [Validators.required, this.validateId]),
    });
    this.renderizarForm = true;
  }

  public renderizarFormulario() {
    if (this.datosUsuarioBusqueda.usuarioDocumento.length > 0) {
      this.tipoAccionComprobanteIdentidad = this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 1) ? this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 1) : null;
      if (this.tipoAccionComprobanteIdentidad.length >= 1) {
        this.comprobanteIdentidadCargado = true;
        this.comprobanteIdentidad = this.tipoAccionComprobanteIdentidad.sort(this.compareDate);
        this.tipoAccionComprobanteIdentidad = this.comprobanteIdentidad.find(x => x.nombreFisico);
        this.tipoAccionComprobanteIdentidad['documentoNombre'] = 'Acta de Nacimiento';
        this.documentos.push(this.tipoAccionComprobanteIdentidad);
      } else {
        this.tipoAccionComprobanteIdentidad = null;
      }

      this.tipoAccionComprobantePromedio = this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 2) ? this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 2) : null;
      if (this.tipoAccionComprobantePromedio.length >= 1) {
        this.comprobantePromedioCargado = true;
        this.comprobantePromedio = this.tipoAccionComprobantePromedio.sort(this.compareDate);
        this.tipoAccionComprobantePromedio = this.comprobantePromedio.find(x => x.nombreFisico);
        this.tipoAccionComprobantePromedio['documentoNombre'] = 'Comprobante Promedio';
        this.documentos.push(this.tipoAccionComprobantePromedio);
      } else {
        this.tipoAccionComprobantePromedio = null;
      }

      this.tipoAccionIdentificacionFotografia = this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 5) ? this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 5) : null;
      if (this.tipoAccionIdentificacionFotografia.length >= 1) {
        this.identificacionFotoCargado = true;
        this.identificacionFotografia = this.tipoAccionIdentificacionFotografia.sort(this.compareDate);
        this.tipoAccionIdentificacionFotografia = this.identificacionFotografia.find(x => x.nombreFisico);
        this.tipoAccionIdentificacionFotografia['documentoNombre'] = 'Comprobante Ingreso';
        this.documentos.push(this.tipoAccionIdentificacionFotografia);
      } else {
        this.tipoAccionIdentificacionFotografia = null;
      }

      this.tipoAccionCartaCompromiso = this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 3) ? this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 3) : null;
      if (this.tipoAccionCartaCompromiso.length >= 1) {
        this.cartaCompromisoCargado = true;
        this.cartaCompromiso = this.tipoAccionCartaCompromiso.sort(this.compareDate);
        this.tipoAccionCartaCompromiso = this.cartaCompromiso.find(x => x.nombreFisico);
        this.tipoAccionCartaCompromiso['documentoNombre'] = 'Carta compromiso';
        this.documentos.push(this.tipoAccionCartaCompromiso);
      } else {
        this.tipoAccionCartaCompromiso = null;
      }

      this.tipoAccionEstudiosPrevios = this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 4) ? this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === 4) : null;
      if (this.tipoAccionEstudiosPrevios.length >= 1) {
        this.estudiosPreviosCargado = true;
        this.estudiosPrevios = this.tipoAccionEstudiosPrevios.sort(this.compareDate);
        this.tipoAccionEstudiosPrevios = this.estudiosPrevios.find(x => x.nombreFisico);
        this.tipoAccionEstudiosPrevios['documentoNombre'] = 'Carta Empresa';
        this.documentos.push(this.tipoAccionEstudiosPrevios);
      } else {
        this.tipoAccionEstudiosPrevios = null;
      }
      this.dataSource = new MatTableDataSource(this.documentos);
      this.infoCargado = true;
    } else {
      this.tipoAccionCartaCompromiso = null;
      this.tipoAccionComprobanteIdentidad = null;
      this.tipoAccionIdentificacionFotografia = null;
      this.tipoAccionEstudiosPrevios = null;
    }
    this.inicializarForm();
  }


  get tipoApoyoFinanciero() { return this.formApoyoFinanciero.get('tipoApoyoFinanciero'); }
  get estatusSolicitudApoyo() { return this.formApoyoFinanciero.get('estatusSolicitudApoyo'); }
  get empresa() { return this.formApoyoFinanciero.get('empresa'); }
  get promedio() { return this.formApoyoFinanciero.get('promedio'); }
  get nombreArchivoComprobanteIdentidad() { return this.formApoyoFinanciero.get('nombreArchivoComprobanteIdentidad'); }
  get nombreArchivoIdentificacionFotografia() { return this.formApoyoFinanciero.get('nombreArchivoIdentificacionFotografia'); }
  get nombreArchivoCartaCompromiso() { return this.formApoyoFinanciero.get('nombreArchivoCartaCompromiso'); }
  get nombreArchivoEstudiosPrevios() { return this.formApoyoFinanciero.get('nombreArchivoEstudiosPrevios'); }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.formApoyoFinanciero.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Que en el combo solo se muestren los tipos de apoyo del campus del alumno
   */
  public getTipoApoyo() {
    this.subscription.add(
      this.apoyoService.obtenerTipoApoyoFinanciero().subscribe(
        (apoyo: TipoApoyoFinanciero[]) => {
          this.apoyoList = apoyo.filter(apoyo => apoyo.campusId == this.datosUsuarioBusqueda.alumno[0].campusId);
        })
    );
  }

  public GetestatusSolicitudApoyo() {
    this.subscription.add(
      this.apoyoService.GetestatusSolicitudApoyo().subscribe(
        (EstatusSolicitudApoyoResp: ApiResponse<EstatusSolicitudApoyoDTO[]>) => {
          if (EstatusSolicitudApoyoResp.success) {
            this.EstatusSolicitudApoyoDTO = EstatusSolicitudApoyoResp.data;
          }
        })
    );
  }


  public getTipoEmpresa() {
    this.subscriptionEmpresa = this.empresaService.obtenerEmpresa().subscribe(
      (empresa: Empresa[]) => {
        this.empresaList = empresa;
      });
  }

  public visualizarPDF(refDocumento: number) {
    if (refDocumento > 0) {
      this.nuevoIngresoService.GetDocumentosCandidatoByCorreo(this.datosUsuarioBusqueda.correo, refDocumento).subscribe(
        (datosUsuarioDocumento: ApiResponse<UsuarioIngreso>) => {
          if (datosUsuarioDocumento.success) {
            var documentos = datosUsuarioDocumento.data.usuarioDocumento.filter(x => x.documentoId === refDocumento);
            var documentoVisualizar = documentos.sort(this.compareDate);
            this.mime = documentoVisualizar[0].nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : /\.(png|jpeg|jpg|gif|tiff|bmp)$/.test(documentoVisualizar[0].nombreOriginal) ? "image/" + /\.(png|jpeg|jpg|gif|tiff|bmp)$/.exec(documentoVisualizar[0].nombreOriginal)[1] : "application/octect-stream";

            this.pdf = this.base64ToArrayBuffer(documentoVisualizar[0].nombreFisico);
            if (this.mime.indexOf("image") >= 0) {
              this.imagen = `data:${this.mime};base64,${documentoVisualizar[0].nombreFisico}`;
            }
            if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
              this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
            } else {
              const blob = new Blob([this.pdf], { type: this.mime });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            }
          }
        }
      );
    }
  }

  compareDate(emp1: any, emp2: any) {
    var emp1Date = new Date(emp1.date).getTime();
    var emp2Date = new Date(emp2.date).getTime();
    return emp1Date > emp2Date ? 1 : -1;
  }

  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  //MODAL ADVERTENCIA
  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close();
    } else {
      this.dialogoRefAdver.close();
    }
  }

  public cerrarModalPdf() {
    this.modalVisorPDFRef.close();
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscritpionCampus) {
      this.subscritpionCampus.unsubscribe();
    }
    if (this.subscriptionPeriodo) {
      this.subscriptionPeriodo.unsubscribe();
    }
    if (this.subscriptionPrograma) {
      this.subscriptionPrograma.unsubscribe();
    }
    if (this.subscriptionApoyoFinanciero) {
      this.subscriptionApoyoFinanciero.unsubscribe();
    }
    if (this.subscriptionEmpresa) {
      this.subscriptionEmpresa.unsubscribe();
    }
  }
}
