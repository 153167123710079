import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Campaign } from 'src/app/_models';
import { CampaignService, FunnelStageService } from 'src/app/_services';

@Component({
  selector: 'app-general-information-tab',
  templateUrl: './general-information-tab.component.html',
  styleUrls: ['./general-information-tab.component.scss'],
})
export class GeneralInformationTabComponent implements OnInit {
  constructor(
    private readonly campaignService: CampaignService,
    private readonly funnelService: FunnelStageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  @ViewChild('allCampaign') private allCampaign: MatOption;

  public generalInfoRender: boolean = false;
  private subscription: Subscription = new Subscription();
  public generalForm: FormGroup;
  public campaignList: Campaign[] = [];
  public funnelStageList: any[] = [];

  funnelsF = new FormControl();
  sourceF = new FormControl();
  campaignF = new FormControl();
  commentsF = new FormControl();

  Qualification: number = 0;

  ngOnInit(): void {
    this.getCampaigns();
    this.getFunnels();
    this.inicializarForm();
    //this.GeneralInfoRender();
  }

  public inicializarForm() {
    this.generalForm = this.fb.group({
      funnelsF: this.funnelsF, //[this.data ? (this.data.leadStage ? this.data.leadStage.funnelStageId : -1) : -1],
      sourceF: [this.data ? (this.data.source ? this.data.source : '') : ''],
      campaignF: this.campaignF, //[this.data ? (this.data.campaignLead.length > 0 ? this.data.campaignLead[0].campaignId : '') : ''],
      commentsF: [this.data ? this.data.comments : ''],
      qualificationF: [this.data ? this.data.qualification : 0],
    });

    this.funnelsF.setValue(this.data ? (this.data.leadStage ? this.data.leadStage.funnelStageId : -1) : -1);
  }

  //#region Get's
  public getCampaigns(): void {
    this.campaignService.getCampaigns().subscribe((campaigns: ApiResponse<Array<Campaign>>) => {
      this.campaignList = campaigns.data;
      if (this.data && this.data.campaignLead) {
        this.campaignF.patchValue([...this.data.campaignLead.map((item) => item.campaignId)]);
      }
    });
  }

  public getFunnels(): void {
    this.funnelService.getFunnelStage().subscribe((funnels: ApiResponse<Array<any>>) => {
      this.funnelStageList = funnels.data;
      if (this.data && this.data.leadStage) {
        //this.funnelsF.patchValue([...this.funnelStageList.map((item) => item.funnelStageId), -1]);
        this.funnelsF.patchValue([...this.data.leadStage.map((item) => item.funnelStageId), -1]);
      }
    });
  }
  //#endregion

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  toggleAllSelectionCampaign() {
    if (this.allCampaign.selected) {
      this.campaignF.patchValue([...this.campaignList.map((item) => item.campaignId), 0]);
    } else {
      this.campaignF.patchValue([]);
    }
  }

  tosslePerOneCampaign() {
    if (this.allCampaign.selected) {
      this.allCampaign.deselect();
      return false;
    }
    if (this.campaignF.value.length == this.campaignList.length) {
      this.allCampaign.select();
    }
  }
}
