import { Component, OnInit, Input, Inject } from '@angular/core';
import { EnsenanzaAprendizajeService } from '../../../_services/ensenanza-aprendizaje.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as customBuild from '../../../../app/_librarries/ckeditorCustom/build/ckeditor';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { TipoRecurso } from 'src/app/_models/tipo-recurso';
@Component({
  selector: 'app-modal-tarea',
  templateUrl: './modal-tarea.component.html',
  styleUrls: ['./modal-tarea.component.scss']
})
export class ModalTareaComponent implements OnInit {

  @Input() grupoID: number;
  contenido = false;
  tipoRecurso: Array<TipoRecurso> = [];
  modulos: any[] = [];
  public Editor = customBuild;
  constructor(
    private ensenanzaServ: EnsenanzaAprendizajeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit(): void {
    this.obtenerTipoRecurso();
    this.cargarContenido();
  }
  obtenerTipoRecurso() {
    this.ensenanzaServ.listaRecursos().subscribe((tipoRecurso: ApiResponse<TipoRecurso[]>) => {
      this.tipoRecurso = tipoRecurso.data;
    })
  }
  cargarContenido() {
    this.ensenanzaServ.obtenerContenido(this.data).subscribe((res: ApiResponse<any>) => {
      this.modulos = res.data.modulo;
    })
  }
  selected:any;
  onChange(event: string) {
    if(event == 'Tarea'){
      this.contenido = true;
    }
  }
}
