import { InfoPrograma } from "./infoPrograma";
import { Modalidad } from "./modalidad";
import { Nivel } from "./nivel";

export class Programa {
  /// <summary>
  /// Id del programa
  /// </summary>
  programaId: number;

  /// <summary>
  /// Clave del programa
  /// </summary>
  clave: string;

  /// <summary>
  /// Nombre de edicion del programa
  /// </summary>
  edicion: string;

  /// <summary>
  /// Define el estado del programa true = Activo, false = Inactivo
  /// </summary>
  estatus: boolean;

  /// <summary>
  /// Nombre completo del programa
  /// </summary>
  nombre: string;

  /// <summary>
  /// Nombre corto del programa
  /// </summary>
  nombreCorto: string;

  /// <summary>
  /// NivelId en el que se impartira el programa
  /// </summary>
  nivelId: number;

  /// <summary>
  /// Modelo Nivel del programa
  /// </summary>
  nivel?: Nivel;

  /// <summary>
  /// Tipo de modalidad en el que se impartira el programa
  /// </summary>
  modalidadId: number;

  /// <summary>
  /// Modelo de tipo modalidad del programa
  /// </summary>
  modalidad?: Modalidad;

  /// <summary>
  /// Fecha en la que vence el programa 
  /// </summary>
  fechaVigencia: any;

  /// <summary>
  /// Cantidad de ciclos que tendra el programa
  /// </summary>
  ciclos?: number;

  /// <summary>
  /// El tipo de periodo que tendra el programa
  /// </summary>
  tipoPeriodoId: number;

  /// <summary>
  /// Las semanas que dura cada ciclo
  /// </summary>
  semanaCiclo: number;

  /// <summary>
  /// 
  /// </summary>
  totalMateria?: number;

  /// <summary>
  /// Define el porcentaje que se permite para revalidar una materia
  /// </summary>
  porcentajeRevalidacion: number;

  /// <summary>
  /// Defina la escala de calificaciones
  /// </summary>
  escalaCalificacion: number;

  /// <summary>
  /// Define la calificacion minima que permite para cada materia
  /// </summary>
  calificacionMinima: number;

  /// <summary>
  /// Define si el programa permite la titulacion por tesis
  /// </summary>
  titulacionTesis: boolean;

  /// <summary>
  /// Define si el programa permite la titulacion por materias
  /// </summary>
  titulacionMateria: boolean;

  /// <summary>
  /// Define si el programa permite la titulacion por promedio
  /// </summary>
  titulacionPromedio: boolean;

  /// <summary>
  /// Define si el programa permite la titulacion por un examen
  /// </summary>
  titulacionExamen: boolean;

  /// <summary>
  /// Define si el programa permite la titulacion por creditos
  /// </summary>
  titulacionCredito: boolean;

  /// <summary>
  /// Tipo de reconocimiento que tendra el programa
  /// </summary>
  tipoReconocimientoId: number;

  /// <summary>
  /// Numero de acuerdo RVOE
  /// </summary>
  numeroAcuerdo: string;

  /// <summary>
  /// Fecha que obtuvo el RVOE
  /// </summary>
  fechaAcuerdo: any;

  /// <summary>
  /// Campus al que pertenece el programa
  /// </summary>
  campusId: number;

  /// <summary>
  /// Define si es edicion o creacion de programa
  /// </summary>
  tipoAccion?: number;

  /// <summary>
  /// Obtiene toda la informacion del programa
  /// </summary>
  infoPrograma?: InfoPrograma;

  tipoAdmisionId: number;

  authorizeRegistration?: boolean
}

export class ProgramaActivo {
  programaId: number;
  clave: string;
  nombre: string;
}