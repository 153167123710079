import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  constructor(private httpClient: HttpClient) { }

  public registraCuenta(
    correo: string,
    nombre: string,
    apellidoPaterno: string,
    apellidoMaterno: string,
    fechaNacimiento: Date,
    password: string
  ): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/registro`, {
      Correo: correo,
      Password: password,
      Nombre: nombre,
      ApellidoPaterno: apellidoPaterno,
      ApellidoMaterno: apellidoMaterno,
      FechaNacimiento: fechaNacimiento,
      Biografia: {
        FechaNacimiento: fechaNacimiento
      }
    });
  };

  public cuentaDisponible(
    correo: string
  ): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.enves[baseUrl].apiUrl}/registro/verificar/correo/${correo}/`);
  };

  public activaCuenta(
    token: string
  ): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.enves[baseUrl].apiUrl}/registro/activar/token/${token}/`);
  };
}
