import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EscalaCalificacion } from '../_models/escala-calificacion';
import { environment,baseUrl} from '@environments/environment';
import { CalificacionNoNumerica } from '../_models/calificacion-no-numerica';

@Injectable({
  providedIn: 'root'
})
export class EscalaCalificacionService {

  constructor(private httpClient: HttpClient) { }

  //Obtener EscalaCalificaciones
  public obtenerEscalaCalificaciones():Observable<EscalaCalificacion[]>{
    return this.httpClient.get<EscalaCalificacion[]>(`${environment.enves[baseUrl].apiUrl}/EscalaCalificacion`);
  }

  public obtenerEscalaCalificacionesByEscala(grupoId: number):Observable<CalificacionNoNumerica[]>{
    return this.httpClient.get<CalificacionNoNumerica[]>(`${environment.enves[baseUrl].apiUrl}/EscalaCalificacion/obtenerEscalaCalificacionesByEscala/${grupoId}`);
  }

  //Obtener Calificaciones By EscalaCalificaciones
  public obtenerCalificacionesByEscalaCalificaciones(escalaId:number):Observable<CalificacionNoNumerica[]>{
    return this.httpClient.get<CalificacionNoNumerica[]>(`${environment.enves[baseUrl].apiUrl}/EscalaCalificacion/CalificacionByEscala?escalaId=`+escalaId);
  }
}
