export class Entry
{
  /**
   * Identificador unico
   */
  sourceId?: number;

  /**
   * Nombre del entry
   */
  name: string;

  /**
   * Descripcion del entry
   */
  description: string;

  /**
   * Tipo de accion necesaria para el registro
   */
  tipoAccion?: number;

}
