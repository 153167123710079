import { SeguimientoCarteraService } from 'src/app/_services/seguimiento-cartera.service';
import { SimpleResponse } from './../../models/api/SimpleResponse.model';
import { EgresoService } from 'src/app/_services/egreso.service';
import { Component, OnInit, ViewChild, Inject, TemplateRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccionEnum } from '../../_models/accion.enum';
import { RegistrarUsuarioComponent } from './registrar-usuario/registrar-usuario.component';
import { NuevoIngresoService } from '../../_services/nuevo-ingreso.service';
import { EstudiosPrevios } from 'src/app/_models/estudios-previos';
import { CargarDocumentosComponent } from './cargar-documentos/cargar-documentos.component';
import { MovimientosCarteraComponent } from '../../seguimiento-cartera/detalle-cartera/movimientos-cartera/movimientos-cartera.component';
import { BecaApoyoFinancieroComponent } from './beca-apoyo-financiero/beca-apoyo-financiero.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BiografiaService } from 'src/app/_services/biografia.service';
import { ProgramaAcademicoRegistroComponent } from './programa-academico-registro/programa-academico-registro.component';
import { SeleccionMateriasCandidatoComponent } from './seleccion-materias-candidato/seleccion-materias-candidato.component';
import { UsuariosRegistrarComponent } from 'src/app/configuracion-usuarios/usuarios-modal/usuarios-registrar/usuarios-registrar.component';
import { SnackService } from 'src/app/services/snack-service.service';
import { ColegiaturaCostoDto } from 'src/app/_models/ColegiaturaCosto.model';
import { GrupoDTO } from 'src/app/_models/GrupoDTO.model';
import { ExisteMateriaSeriada } from 'src/app/models/customEntities/existeMateriaSeriada.mode';
import { Correo } from 'src/app/_models/correo';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { TableSharedServicesService } from 'src/app/_services/table-shared-services.service';
import { DatoFiscal } from 'src/app/_models/dato-fiscal';
import { CargaInscripcion } from 'src/app/_models/carga-inscripcion';
import { ColegiaturaCosto } from 'src/app/_models/colegiatura-costo';
import { SolicitudApoyo } from 'src/app/_models/solicitud-apoyo-financiero';
import { DatosBiografia, DatosContactoF, DatosFamilia, DatosFiscal, DatosOcupacion, DatosPersonales, Imagen, ProgramaAcademicoF, Registros, RegistroUsuario } from 'src/app/_models/usuario';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { DatosPersonalesModalComponent } from 'src/app/_components/datos-personales-modal/datos-personales-modal.component';
import { BajaDTO } from 'src/app/_models/BajaDTO.model';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { GruposDisponiblesParaMateriaDTO } from '../../models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { BlockStudent } from '@app/_models/blockStudent';
import { BlockService } from '@app/_services/block.service';
import { TraductorService } from '@app/_services/traductor.service';
import { I18NextPipe } from 'angular-i18next';
import { AttributesGuardarDto } from '../../_models/AttributesDto';
import { AttributesService } from '../../_services/attributes.service';
import { SolicitudApoyoFinancieroService } from '../../_services/solicitud-apoyo-financiero.service';
import { EstadoCuentaDto } from '../../_models/estado-cuenta-dto';

@Component({
  selector: 'app-nuevo-ingreso-modal',
  templateUrl: './nuevo-ingreso-modal.component.html',
  styleUrls: ['./nuevo-ingreso-modal.component.scss']
})

export class NuevoIngresoModalComponent implements OnInit, OnDestroy {
  @ViewChild(UsuariosRegistrarComponent) registroUsuario: UsuariosRegistrarComponent;
  @ViewChild(RegistrarUsuarioComponent) registrarUsuario: RegistrarUsuarioComponent;
  @ViewChild(ProgramaAcademicoRegistroComponent) programaAcademico: ProgramaAcademicoRegistroComponent;
  @ViewChild(CargarDocumentosComponent) cargaDocumentos: CargarDocumentosComponent;
  @ViewChild(BecaApoyoFinancieroComponent) apoyoFinanciero: BecaApoyoFinancieroComponent;
  @ViewChild(SeleccionMateriasCandidatoComponent) seleccionMaterias: SeleccionMateriasCandidatoComponent;
  @ViewChild(MovimientosCarteraComponent) movimientoCartera: MovimientosCarteraComponent;
  @ViewChild(DatosPersonalesModalComponent) datosPersonales: DatosPersonalesModalComponent;
  @ViewChild(RegistrarUsuarioComponent) solicitante: RegistrarUsuarioComponent;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  @ViewChild('dialogoCandidatoCancelado') dialogoCandidatoCancelado: TemplateRef<'dialogoCandidatoCancelado'>;
  @ViewChild('dialogoCerrarModal') dialogoCerrarModal: TemplateRef<'dialogoCerrarModal'>;
  @ViewChild('dialogoCerrarModalCargo') dialogoCerrarModalCargo: TemplateRef<'dialogoCerrarModalCargo'>;
  @ViewChild('dialogoCerrarPagoInscripcion') dialogoCerrarPagoInscripcion: TemplateRef<'dialogoCerrarPagoInscripcion'>;
  @ViewChild('dialogoAgregarAlumnosBloque') dialogoAgregarAlumnosBloque: TemplateRef<'dialogoAgregarAlumnosBloque'>;

  //Servicios
  public mostrarPeriodoPorModulo: boolean = false;
  @ViewChild('candidatoTabs', { static: false }) candidatoTabs: MatTabGroup;
  public subscription: Subscription = new Subscription();
  public datosUsuarioBusqueda$: Observable<UsuarioIngreso>;
  public usuarioMenorEdad$: Observable<boolean>;
  public datosUsuarioBusqueda: UsuarioIngreso;
  public usuarioMenorEdad: boolean;
  public menorEdad: boolean;
  public base64Image: string;
  public noEditarBeca: boolean = false;
  public tipoUsuarioEncontrado: string = "";
  public nombreUsuario: string = "";
  public busquedaCorreoForm: FormGroup;
  public imagenUsuarioForm: FormGroup;
  public correoBusqueda: string = '';
  public usuarioEncontrado: boolean = true;
  public ocultarNuevoUsuario: boolean = true;
  public mensajeUsuarioEncontrado: boolean = false;
  public mensajeUsuarioEncontradoEditar: boolean = false;
  public mensajeUsuarioNoEncontrado: boolean = false;
  public datosNuevoUsuario: any;
  public formValidoNuevaSolicitud$: Observable<boolean>;
  public cambioForm$: Observable<boolean>;
  public changesAcademicProgram$: Observable<boolean>;
  public changesPersonalData$: Observable<boolean>;
  public changesDocuments$: Observable<boolean>;
  public changesFinancialSupport$: Observable<boolean>;
  public changesSubjects$: Observable<boolean>;
  public changesPayment$: Observable<boolean>;
  public cambioNombreForm$: Observable<string>;
  public changesAcademicProgram: boolean = false;
  public changesPersonalData: boolean = false;
  public changesDocuments: boolean = false;
  public deleteDocuments: boolean = true;
  public changesFinancialSupport: boolean = false;
  public changesSubjects: boolean = false;
  public changesPayment: boolean = false;
  public formValidoNuevaSolicitud: boolean;
  public cambiosHechosEnSeleccionDeMateria: boolean = false;
  private suscriptionSeleccionMaterias: Subscription = new Subscription();
  public cambioForm: boolean;
  public boolDesabilitarTab: boolean = true;
  public tabSeleccionado: number = 0;
  public tabSeleccionadoIndex: FormControl = new FormControl();
  public tabCambio: boolean = false;
  public boolTabDisabled$: Observable<boolean>;
  public boolTabDisabledSubscription: Subscription;
  public boolCambioPendiente$: Observable<boolean>;
  public boolCambio: boolean = false;
  public boolCambioPendienteSubscription: Subscription;
  public dialogoRefAdver: MatDialogRef<any>;
  public dialogoRefAdverCancelado: MatDialogRef<any>;
  public dialogoRefCerrarModal: MatDialogRef<any>;
  public tipoAccion: number;
  public ocultarBuscador: boolean = true;
  public datosUsuarioActual: UsuarioIngreso;
  public imagePath: SafeResourceUrl;
  public imagePath2: SafeResourceUrl;
  public boolImagen: boolean = false;
  public imagePath3: SafeResourceUrl;
  public imgURL: string | ArrayBuffer;
  public message: string;
  public eventImage: FileList[];
  public registrarUsuarioBandera: boolean = false;
  public profesor: boolean = false;
  public reactivarCandidatoId: number;
  public editartabs: boolean = false;
  public CURPencontrado: boolean = false;
  public tabCambios: string = ""
  public tabCambiosPrograma: string = "";
  public tabCambiosDatos: string = "";
  public tabCambiosDocumentos: string = "";
  public tabCambiosApoyo: string = "";
  public tabCambiosMaterias: string = "";
  public tabCambiosPago: string = "";
  public tabCambiosOneTab: string = "";
  public cargasVisibles: boolean = false;
  public dialogoRefCerrarModalCargo: MatDialogRef<any>;
  public dialogoRefCerrarPagoInscripcion: MatDialogRef<any>;
  public permiteParcialidades: boolean = false;
  public cargaAlumno: CargaInscripcion;
  private colegiaturaCuota: ColegiaturaCostoDto;
  public identificadorPersonalizado: boolean = false;

  public dialogoRefCerrarModalAgregarAlumnosBloque: MatDialogRef<any>;

  public materiaAltaBaja: boolean = false;
  public gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
  public materiasBajaAltaAlumno: any;

  constructor(public dialogRef: MatDialogRef<NuevoIngresoModalComponent>,
    public nuevoIngresoService: NuevoIngresoService,
    @Inject(MAT_DIALOG_DATA) public data: UsuarioIngreso,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private _cd: ChangeDetectorRef,
    private biografiaService: BiografiaService,
    private snackService: SnackService,
    private tableShared: TableSharedServicesService,
    private utilService: UtileriasService,
    private egresoService: EgresoService,
    private traductorService: TraductorService,
    public blockService: BlockService,
    private seguimientoService: SeguimientoCarteraService,
    private i18nextPipe: I18NextPipe,
    public attributesService: AttributesService,
    private solicitudApoyoFinancieroSerrvice: SolicitudApoyoFinancieroService,
  ) {
  }


  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
    if (this.nuevoIngresoService.updateTabsSubscription != undefined) {
      this.nuevoIngresoService.updateTabsSubscription.unsubscribe();
    }
    if (this.nuevoIngresoService.buscarUsuarioSubscription != undefined) {
      this.nuevoIngresoService.buscarUsuarioSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.data !== undefined) {
      let dataResumen: any = {
        alumnoId: this.data.alumno[0].alumnoId,
        id: this.data.usuarioId,
        id_Campus: this.data.alumno[0].campusId,
        id_Periodo: this.data.alumno[0].priodoIngreso,
        id_Programa: this.data.alumno[0].programaId
      }
      this.seguimientoService.setDatosAlumno$(dataResumen);
      this.seguimientoService.setAlumnoIdSeleccionado$(this.data.alumno[0].alumnoId);
    }
    this.nuevoIngresoService.buscarUsuarioSubscription = this.nuevoIngresoService.buscarUsuario.subscribe(() => {
      this.enviarBusquedaCorreo();
    });
    this.subscription.add(
      this.nuevoIngresoService.obteneridentificadorPersonalizado().subscribe((respuesta: ApiResponse<boolean>) => {
        if (respuesta.success) {
          this.identificadorPersonalizado = respuesta.data;
        } else {
          this.identificadorPersonalizado = false;
        }
      })
    );

    this.suscriptionSeleccionMaterias = this.nuevoIngresoService.getCambiosMateriasSubjet().subscribe((cambiosHechos: boolean) => {
      this.cambiosHechosEnSeleccionDeMateria = cambiosHechos;
    });
    if (this.data) {
      this.usuarioEncontrado = false;
      this.editartabs = true;
      this.registrarUsuarioBandera = false;
      this.mensajeUsuarioEncontrado = true;
      this.nuevoIngresoService.getCargas(this.data.alumno[0].campusId, this.data.alumno[0].programa.nivelId, this.data.alumno[0].programaId).subscribe(
        (respuesta: ApiResponse<CargaInscripcion>) => {
          this.cargaAlumno = respuesta.data;
          if (this.cargaAlumno) {
            this.nuevoIngresoService.definirtipoCarga(this.cargaAlumno.tipoCarga);
          } else {
            this.nuevoIngresoService.definirtipoCarga(undefined);
          }
        })
      this.nuevoIngresoService.candidatoById(this.data.alumno[0].alumnoId, this.data.usuarioId).subscribe(
        (respuesta: ApiResponse<UsuarioIngreso>) => {
          this.datosUsuarioBusqueda = respuesta.data;
          this.nuevoIngresoService.definirDatosUsuarioBusqueda(this.datosUsuarioBusqueda);
          if (this.nuevoIngresoService.updateTabsSubscription == undefined) {
            this.nuevoIngresoService.updateTabsSubscription = this.nuevoIngresoService.updateTabsEmitter.subscribe(() => {
              this.nuevoIngresoService.candidatoById(this.data?.alumno[0].alumnoId, this.data?.usuarioId).subscribe(
                (respuestaCandidato: ApiResponse<UsuarioIngreso>) => {
                  this.datosUsuarioBusqueda = respuestaCandidato.data;
                  console.log("this.datosUsuarioBusqueda AL GUARDAR", this.datosUsuarioBusqueda)
                  this.nuevoIngresoService.definirDatosUsuarioBusqueda(this.datosUsuarioBusqueda);
                });
              this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
              this.datosUsuarioBusqueda$.subscribe(datos => { this.datosUsuarioBusqueda = datos; })
              this.renderizarFoto();
            });
          }

          this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
          this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);

          //---OBSERVABLES----//
          this.changesAcademicProgram$ = this.nuevoIngresoService.getChangesAcademicProgram$();
          this.changesAcademicProgram$.subscribe(bool => this.changesAcademicProgram = bool);

          this.changesPersonalData$ = this.nuevoIngresoService.getChangesPersonalData$();
          this.changesPersonalData$.subscribe(bool => this.changesPersonalData = bool);

          this.changesDocuments$ = this.nuevoIngresoService.getChangesDocuments$();
          this.changesDocuments$.subscribe(bool => this.changesDocuments = bool);

          this.changesFinancialSupport$ = this.nuevoIngresoService.getChangesFinancialSupport$();
          this.changesFinancialSupport$.subscribe(bool => this.changesFinancialSupport = bool);

          this.changesSubjects$ = this.nuevoIngresoService.getChangesSubjects$();
          this.changesSubjects$.subscribe(bool => this.changesSubjects = bool);

          this.changesPayment$ = this.nuevoIngresoService.getChangesPayment$();
          this.changesPayment$.subscribe(bool => this.changesPayment = bool);

        });
    }
    if (this.datosNuevoUsuario) {
      this.nuevoIngresoService.obtenerCandidatoCorreo(this.correoBusqueda).subscribe(
        usuarioBusuqueda => {
          this.datosUsuarioBusqueda = usuarioBusuqueda;
          this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
          this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos)
        });
    }
    this.formValidoNuevaSolicitud$ = this.nuevoIngresoService.obtenerFormularioValidoNuevaSolicitud$();
    this.formValidoNuevaSolicitud$.subscribe(bool => this.formValidoNuevaSolicitud = bool);
    this.inicializarForm();
  }

  ngAfterViewInit(): void {
    this.inicializarForm();
    this.inicializa();
  }


  public inicializarForm() {
    this.busquedaCorreoForm = new FormGroup
      ({
        correo: new FormControl('', [Validators.required, Validators.email])
      });
    this.busquedaCorreoForm.valueChanges.subscribe(val => {
      this.mensajeUsuarioEncontrado = false;
      this.mensajeUsuarioNoEncontrado = false;
      this.ocultarNuevoUsuario = true;
      this.usuarioEncontrado = true;
    });
  }

  public inicializa() {
    if (!this.data) {
      this.tipoAccion = AccionEnum.CREAR;
      this.ocultarNuevoUsuario = false;
      this.mensajeUsuarioNoEncontrado = true;
    } else {
      this.tipoAccion = AccionEnum.EDITAR;
      this.datosUsuarioActual = this.data;
      this.nuevoIngresoService.definirDatosUsuarioBusqueda(this.data);
      this.nuevoIngresoService.defineCandidatCycleId(this.datosUsuarioActual.alumno[0].programaId);

      this.nuevoIngresoService.defineStudentId(this.datosUsuarioActual.alumno[0].alumnoId);
      this.nuevoIngresoService.defineIdPeriodo(this.datosUsuarioActual.alumno[0].priodoIngreso)
      let document_1 = this.datosUsuarioActual.usuarioDocumento.findIndex(x => x.documentoId === 1) > -1;
      let document_3 = this.datosUsuarioActual.usuarioDocumento.findIndex(x => x.documentoId === 3) > -1;
      let document_6 = this.datosUsuarioActual.usuarioDocumento.findIndex(x => x.documentoId === 6) > -1;
      let document_7 = this.datosUsuarioActual.usuarioDocumento.findIndex(x => x.documentoId === 7) > -1;
      if (document_1 && document_3 && document_6 && document_7 && this.validarDatos(this.datosUsuarioActual)) {
        this.nuevoIngresoService.definirValidarDocumentos(true);
      } else {
        this.nuevoIngresoService.definirValidarDocumentos(false);
      }
      this.nuevoIngresoService.imagenUsuario(this.data.usuarioId).subscribe(
        (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
          if (datosUsuarioImagen.success) {
            this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
          }

        }
      );

      this.usuarioEncontrado = false;
      this.editartabs = true;
      this.registrarUsuarioBandera = false;
      this.mensajeUsuarioEncontrado = true;

      this.renderizarFoto();
      if (this.datosUsuarioActual.solicitudCargaDoc === 0) {
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(0);
        }, 500);
      } else if (this.datosUsuarioActual.solicitudCargaDoc === 1) {
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(1);
        }, 500);
      } else if (this.datosUsuarioActual.solicitudCargaDoc === 2) {
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(2);
        }, 500);
      } else if (this.datosUsuarioActual.solicitudCargaDoc === 3) {
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(3);
        }, 500);
      } else if (this.datosUsuarioActual.solicitudCargaDoc === 4) {
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(4);
        }, 500);
      } else if (this.datosUsuarioActual.solicitudCargaDoc === 5) {
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(5);
        }, 500);
      }

      this.renderizarFormulariosTabs();


      if (this.datosUsuarioActual.solicitudCargaDoc === 0) {
        this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.programaAcademico.programaAcademicoForm.valid);
      } else if (this.datosUsuarioActual.solicitudCargaDoc === 1) {
        this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.datosPersonales.datosPersonalesForm.valid);
      } else if (this.datosUsuarioActual.solicitudCargaDoc === 2) {
        this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.cargaDocumentos.cargaDocumentoForm.valid);
      } else if (this.datosUsuarioActual.solicitudCargaDoc === 3) {
        if (this.data.solicitudApoyoFinanciero.length >= 1) {
          if (this.data.solicitudApoyoFinanciero[0].estatusSolicitudApoyoId === 1) //EN PROCESO
          {
            let mensaje: string = this.traductorService.translate('usuarioBeca');
            this.snackService.mostrarSnackBack(mensaje);
            this.tipoAccion = AccionEnum.EDITAR;
          }
          if (this.data.solicitudApoyoFinanciero[0].estatusSolicitudApoyoId === 2) // ACEPTADA
          {
            let mensaje: string = this.traductorService.translate('usuarioBeca2');
            this.snackService.mostrarSnackBack(mensaje);
          }
        }
        this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.apoyoFinanciero.formApoyoFinanciero.valid);
      }
      this.formValidoNuevaSolicitud$ = this.nuevoIngresoService.obtenerFormularioValidoNuevaSolicitud$();
      this.formValidoNuevaSolicitud$.subscribe(bool => this.formValidoNuevaSolicitud = bool);
    }
  }

  get correo() { return this.busquedaCorreoForm.get('correo'); }
  get fotografia() { return this.imagenUsuarioForm.get('fotografia'); }

  preview(event) {
    if (event.length === 0)
      return;
    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath3 = event;
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.eventImage = event;
  }

  public imagenModal() {
    if (!this.boolImagen) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  public cerrarModalImagen(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
    } else {
      this.dialogoRefAdver.close();
      this.onFileChangePerfil(this.eventImage);
      this.enviarImagen();
      this.imgURL = "";
    }
  }

  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.onload = () => {
        this.datosUsuarioBusqueda.fotografia = reader.result;
        this._cd.markForCheck();
        this.imagenUsuarioForm.patchValue({
          fotografia: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  }

  public onFileChangePerfil(event) {
    const reader = new FileReader();
    if (event && event.length > 0) {
      this.imagePath2 = event;
      this.imagePath = event;
      const [file] = event;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.readAsDataURL(event[0]);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
        this.imagePath2 = reader.result;
      }
      this.eventImage = event;

    }
  }

  public enviarImagen() {
    let imagenForm = Object.assign(this.imagenUsuarioForm.value);
    let imagen: Imagen = {
      usuarioId: imagenForm.usuarioId,
      fotografia: imagenForm.fotografia,
      fotografiaFile: imagenForm.fotografiaFile,
    }
    this.nuevoIngresoService.editarImagen(imagen).subscribe(
      (respuesta: SimpleResponse) => {
        if (respuesta.success) {
          this.nuevoIngresoService.refrescarTabla()
          this.nuevoIngresoService.updateTabs()
          this.snackService.mostrarSnackBack(respuesta.message);
        } else {
          this.snackService.mostrarSnackBack(respuesta.message);
        }
      }
    )
  }

  public renderizarFoto() {
    this.imagenUsuarioForm = new FormGroup
      ({
        usuarioId: new FormControl(this.data.usuarioId),
        fotografia: new FormControl(''),
        fotografiaFile: new FormControl(''),
      });
  }

  public enviarBusquedaCorreo() {
    this.correoBusqueda = this.solicitante.correo.value;
    this.nuevoIngresoService.obtenerCandidatoCorreo(this.correoBusqueda).subscribe(
      usuarioBusuqueda => {
        console.log("usuarioBusuqueda", usuarioBusuqueda)
        if (usuarioBusuqueda != null) {
          if (usuarioBusuqueda.alumno.length >= 1) {
            let dataResumen: any = {
              alumnoId: usuarioBusuqueda.alumno[0].alumnoId,
              id: usuarioBusuqueda.usuarioId,
              id_Campus: usuarioBusuqueda.alumno[0].campusId,
              id_Periodo: usuarioBusuqueda.alumno[0].priodoIngreso,
              id_Programa: usuarioBusuqueda.alumno[0].programaId
            }
            usuarioBusuqueda.blockName = null;
            this.data = usuarioBusuqueda;
            this.seguimientoService.setDatosAlumno$(dataResumen);
            this.seguimientoService.setAlumnoIdSeleccionado$(this.data.alumno[0].alumnoId);
            // this.nuevoIngresoService.definirDatosUsuarioBusquedaCorreo(usuarioBusuqueda);
            this.solicitudApoyoFinancieroSerrvice.definirDatosUsuarioBusqueda(usuarioBusuqueda);
            this.mensajeUsuarioEncontrado = true;
            this.usuarioEncontrado = false;
            this.datosUsuarioBusqueda = usuarioBusuqueda;
            this.datosUsuarioActual = usuarioBusuqueda;
            this.renderizarFormulariosTabs();
            // this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.datosPersonales.datosPersonalesForm.valid);
            this.ocultarBuscador = true;
            this.ocultarNuevoUsuario = true;
            this.mensajeUsuarioNoEncontrado = false;
            this.base64Image = 'data:image/png;base64,' + usuarioBusuqueda.fotografia;
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
            this.renderizarFoto();
            if (!!usuarioBusuqueda) {
              if (!!usuarioBusuqueda.length) {
                for (let alumnoCandidato = 0; alumnoCandidato < usuarioBusuqueda[0].alumno.length; alumnoCandidato++) {
                  if (usuarioBusuqueda[0].alumno[alumnoCandidato].estatusAcademico == 1 && usuarioBusuqueda[0].alumno[alumnoCandidato].alumnoGrupo.length == 0 && usuarioBusuqueda[0].alumno[alumnoCandidato].estatusAcademico == 1) {
                    this.nombreUsuario = usuarioBusuqueda[0].nombre + " " + usuarioBusuqueda.apellidoPaterno + " " + usuarioBusuqueda.apellidoMaterno;
                    this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + this.nombreUsuario + " registrado para el programa " + usuarioBusuqueda[0].alumno[alumnoCandidato].programa.nombre + " y el período: " + usuarioBusuqueda.alumno[alumnoCandidato].priodoIngresoNavigation.nombre;
                  }
                  else if (usuarioBusuqueda[0].alumno[alumnoCandidato].alumnoGrupo.length >= 1) {
                    this.nombreUsuario = usuarioBusuqueda[0].nombre + " " + usuarioBusuqueda[0].apellidoPaterno + " " + usuarioBusuqueda[0].apellidoMaterno;
                    this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un alumno con el nombre de: " + this.nombreUsuario + " inscrito en el programa " + usuarioBusuqueda[0].alumno[alumnoCandidato].programa.nombre;
                  }
                  else if (usuarioBusuqueda[0].alumno[alumnoCandidato].estatusAcademico == 7) {
                    this.nombreUsuario = usuarioBusuqueda[0].nombre + " " + usuarioBusuqueda[0].apellidoPaterno + " " + usuarioBusuqueda[0].apellidoMaterno;
                    this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + this.nombreUsuario + " con estatus cancelado. ";
                  }
                  if (usuarioBusuqueda.alumno[0].estatusAcademico == 7) {

                  } else {
                    this.snackService.mostrarSnackBack(this.tipoUsuarioEncontrado);
                  }
                }
              } else {
                if (usuarioBusuqueda.alumno[0].estatusAcademico == 1 && usuarioBusuqueda.alumno[0].alumnoGrupo.length == 0 && usuarioBusuqueda.alumno[0].estatusAcademico == 1) {
                  this.nombreUsuario = usuarioBusuqueda.nombre + " " + usuarioBusuqueda.apellidoPaterno + " " + usuarioBusuqueda.apellidoMaterno;
                  this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + this.nombreUsuario + " registrado para el programa " + usuarioBusuqueda.alumno[0].programa.nombre + " y el período: " + usuarioBusuqueda.alumno[0].priodoIngresoNavigation.nombre;
                }
                else if (usuarioBusuqueda.alumno[0].alumnoGrupo.length >= 1) {
                  this.nombreUsuario = usuarioBusuqueda.nombre + " " + usuarioBusuqueda.apellidoPaterno + " " + usuarioBusuqueda.apellidoMaterno;
                  this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un alumno con el nombre de: " + this.nombreUsuario + " inscrito en el programa " + usuarioBusuqueda.alumno[0].programa.nombre;
                }
                else if (usuarioBusuqueda.alumno[0].estatusAcademico == 7) {
                  this.nombreUsuario = usuarioBusuqueda.nombre + " " + usuarioBusuqueda.apellidoPaterno + " " + usuarioBusuqueda.apellidoMaterno;
                  this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + this.nombreUsuario + " con estatus cancelado. ";
                }
                if (usuarioBusuqueda.alumno[0].estatusAcademico == 7) {

                } else {
                  this.snackService.mostrarSnackBack(this.tipoUsuarioEncontrado);
                }
              }
            }
          }

          if (usuarioBusuqueda.profesor != null && usuarioBusuqueda.alumno.length == 0) {
            this.profesor = true;
            this.ocultarBuscador = true;
            this.registrarUsuarioBandera = true;
            this.ocultarNuevoUsuario = false;
            this.mensajeUsuarioNoEncontrado = true;
            this.nuevoIngresoService.definirDatosUsuarioBusquedaCorreo(usuarioBusuqueda);
            this.nombreUsuario = usuarioBusuqueda.nombre + " " + usuarioBusuqueda.apellidoPaterno + " " + usuarioBusuqueda.apellidoMaterno;
            this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un profesor con el nombre de: " + this.nombreUsuario + " ¿desea continuar?";
            this.snackService.mostrarSnackBack(this.tipoUsuarioEncontrado);
            this.registrarUsuario.recibirInfoProfesor(this.correoBusqueda, true);
          }
        } else {
          this.nuevoIngresoService.definirDatosUsuarioBusqueda(usuarioBusuqueda);
          this.ocultarBuscador = true;
          this.snackService.mostrarSnackBack('No se encontró a un usuario registrado. Por favor llene el formulario para registrar al candidato.');
          this.registrarUsuario.recibirInfo(this.correoBusqueda);
          this.registrarUsuarioBandera = true;
          this.ocultarNuevoUsuario = false;
          this.mensajeUsuarioNoEncontrado = true;
        }
      }
    );
  }

  public reactivarCandidato() {
    this.reactivarCandidatoId = this.datosUsuarioBusqueda.usuarioId;
    this.nuevoIngresoService.reactivarCandidatoByUsuarioID(this.reactivarCandidatoId).subscribe(
      (respuestaActivar: SimpleResponse) => {
        if (respuestaActivar.success) {
          this.nuevoIngresoService.invocarMetodoObtenerCandidato();
          this.nuevoIngresoService.refrescarTabla()
          this.snackService.mostrarSnackBack('Se ha reactivado la cuenta.');
          this.dialogRef.close();
        } else {
          this.snackService.mostrarSnackBack('El profesor no pudo ser eliminado porque pertenece a un grupo.');
        }
      },
      error => {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al reactivar la cuenta. Por favor, intente de nuevo más tarde.');
      }
    );
  }

  public enviarRegistroUsuario() {
    if (this.profesor === true) {
      this.datosNuevoUsuario = Object.assign(this.registrarUsuario.nuevoSolicitudUsuarioForm.value);
      let registro: Registros = {
        usuarioId: this.datosNuevoUsuario.usuarioId,
        correo: this.datosNuevoUsuario.correoUsuario,
        periodo: {
          periodoId: this.datosNuevoUsuario.periodoUsuario
        },
        campus: {
          CampusId: this.datosNuevoUsuario.campusUsuario
        },
        programa: {
          ProgramaId: this.datosNuevoUsuario.programaAcademicoUsuario
        }
      };
      this.nuevoIngresoService.registrarProgramaUsuario(registro).subscribe(
        (respuesta: SimpleResponse) => {
          if (respuesta.success) {
            this.registrarUsuarioBandera = false;
            this.ocultarNuevoUsuario = false;
            this.obtenerInfoUsuario(this.datosNuevoUsuario.correoUsuario);
            this.nuevoIngresoService.refrescarTabla();
            this.nuevoIngresoService.updateTabs();
            this.ngOnInit();
            this.renderizarFoto();
            this.snackService.mostrarSnackBack('Se ha registrado el programa y período al usuario.');
            this.nuevoIngresoService.invocarMetodoPrograma();
          } else {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar el usuario. Por favor, intente de nuevo más tarde.');
          }
        }
      );

    }
    else {
      this.datosNuevoUsuario = Object.assign(this.registrarUsuario.nuevoSolicitudForm.value);
      let registro: RegistroUsuario = {
        correo: this.datosNuevoUsuario.correo,
        nombre: this.datosNuevoUsuario.nombre,
        segundoNombre: this.datosNuevoUsuario.segundoNombre,
        apellidoPaterno: this.datosNuevoUsuario.apellidoPaterno,
        apellidoMaterno: this.datosNuevoUsuario.apellidoMaterno,
        password: this.datosNuevoUsuario.password,
        biografia: {
          fechaNacimiento: this.datosNuevoUsuario.fechaNacimiento
        },
        periodo: {
          periodoId: this.datosNuevoUsuario.periodo
        },
        campus: {
          CampusId: this.datosNuevoUsuario.campus
        },
        programa: {
          ProgramaId: this.datosNuevoUsuario.programaAcademico
        }
      };
      if (this.datosNuevoUsuario.password === this.datosNuevoUsuario.passwordConfirm) {
        this.nuevoIngresoService.registrarNuevoCandidato(registro).subscribe(
          usuarioGuardado => {
            if (usuarioGuardado) {
              this.snackService.mostrarSnackBack('Se ha registrado el nuevo usuario.');
              this.registrarUsuarioBandera = false;
              this.ocultarNuevoUsuario = false;
              this.obtenerInfoUsuario(this.datosNuevoUsuario.correo);
              this.nuevoIngresoService.refrescarTabla();
              this.nuevoIngresoService.updateTabs();
              this.ngOnInit();
            } else {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar el usuario. Por favor, intente de nuevo más tarde.');
            }
          }
        );
      } else {
        this.snackService.mostrarSnackBack('Las contraseñas deben de ser iguales.');
      }
    }
  }

  public obtenerInfoUsuario(correo: string) {
    this.nuevoIngresoService.obtenerCandidatoCorreo(correo).subscribe(
      usuarioBusuqueda => {
        if (usuarioBusuqueda != null) {
          let dataResumen: any = {
            alumnoId: usuarioBusuqueda.alumno[0].alumnoId,
            id: usuarioBusuqueda.usuarioId,
            id_Campus: usuarioBusuqueda.alumno[0].campusId,
            id_Periodo: usuarioBusuqueda.alumno[0].priodoIngreso,
            id_Programa: usuarioBusuqueda.alumno[0].programaId
          }
          usuarioBusuqueda.blockName = null;
          this.data = usuarioBusuqueda;
          this.seguimientoService.setDatosAlumno$(dataResumen);
          this.seguimientoService.setAlumnoIdSeleccionado$(this.data.alumno[0].alumnoId);
          this.nuevoIngresoService.definirDatosUsuarioBusquedaCorreo(usuarioBusuqueda);
          this.usuarioEncontrado = false;
          this.ocultarNuevoUsuario = true;
          this.mensajeUsuarioEncontrado = true;
          this.mensajeUsuarioNoEncontrado = false;
          this.datosUsuarioActual = usuarioBusuqueda;
          this.nuevoIngresoService.candidatoById(this.datosUsuarioActual.alumno[0].alumnoId, this.datosUsuarioActual.usuarioId).subscribe(
            (respuesta: ApiResponse<UsuarioIngreso>) => {
              this.datosUsuarioBusqueda = respuesta.data;
            });
          this.renderizarFormulariosTabs();
          this.renderizarFoto();
        } else {

        }
      }
    );
  }

  public renderizarFormulariosTabs() {
    console.log("this.datosUsuarioActual;", this.datosUsuarioActual.usuarioId)
    this.buscarInformacionUsuario(this.datosUsuarioActual.usuarioId);
    this.programaAcademico.datosUsuarioBusqueda = this.datosUsuarioActual;
    this.programaAcademico.renderizarFormularioPrograma();
    this.datosPersonales.datosUsuarioBusqueda = this.datosUsuarioActual;
    this.datosPersonales.renderizarFormulario(1);
    this.cargaDocumentos.datosUsuarioBusqueda = this.datosUsuarioActual;
    this.cargaDocumentos.renderizarFormulario();
    this.apoyoFinanciero.datosUsuarioBusqueda = this.datosUsuarioActual;
    this.apoyoFinanciero.renderizarFormulario();
    //this.datosPersonalesModal.renderizarFormulario(1);
  }

  //DATOS SEGUIMIENTO
  public buscarInformacionUsuario(usuarioId: number) {
    this.seguimientoService.obtenerSeguimientoUsuarioById(usuarioId).subscribe(
      (seguimientoUsuario: ApiResponse<EstadoCuentaDto[]>) => {
        this.seguimientoService.setDatosSeguimientoUsuario$(seguimientoUsuario.data);
      });
  }

  public enviarFormulario() {
    if (this.tabSeleccionado === 0) {
      let programaAcademicoForm = Object.assign(this.programaAcademico.programaAcademicoForm.value);
      let programaAcademico: ProgramaAcademicoF = {
        usuarioId: programaAcademicoForm.usuarioId,
        alumnoId: this.data.alumno[0].alumnoId,
        estatusAcademicoId: programaAcademicoForm.estatusAdmision,
        identificadorPersonalizadoValor: programaAcademicoForm.matricula,
        identificadorPersonalizadoBool: this.identificadorPersonalizado,
        campus: {
          CampusId: programaAcademicoForm.campus,
        },
        programa: {
          ProgramaId: programaAcademicoForm.programaAcademico,
        },
        periodo: {
          periodoId: programaAcademicoForm.periodo,
        },
        formatoProgramaId: programaAcademicoForm.formatoPrograma,
        idiomaProgramaId: programaAcademicoForm.idiomaPrograma,
        tipoCandidatoId: programaAcademicoForm.tipoCandidato,
      }
      this.nuevoIngresoService.editarProgramaUsuario(programaAcademico).subscribe(
        (respuesta: SimpleResponse) => {
          if (respuesta.success) {
            this.changesAcademicProgram = false;

            //#region Correo
            // if (programaAcademicoForm.estatusAdmision != this.datosUsuarioBusqueda.alumno[0].estatusAcademico) {
            //   const estructuraCorreo = new Correo();
            //   estructuraCorreo.CorreosDestinatario = this.datosUsuarioBusqueda.correo;
            //   estructuraCorreo.Asunto = 'Cambio de estatus académico';
            //   estructuraCorreo.CuerpoDelMensaje = 'Tu estatus académico a cambiado.';
            //   this.nuevoIngresoService.enviarCorreos(estructuraCorreo).subscribe(
            //     (respuestaCorreo: SimpleResponse) => {
            //     }
            //   );
            // }
            //#endregion
            // this.snackService.mostrarSnackBack(respuesta.message);


            let atributosGuardar: AttributesGuardarDto = {
              alumnoId: this.data.alumno[0].alumnoId,
              attributes: this.programaAcademico.filtroForm.value.atributoF
            }
            this.attributesService.guardarAtributosAlumno(atributosGuardar).subscribe((response: SimpleResponse) => {
              if (response.success) {
                this.nuevoIngresoService.candidatoById(this.data.alumno[0].alumnoId, this.data.usuarioId).subscribe(
                  (respuesta: ApiResponse<UsuarioIngreso>) => {
                    this.datosUsuarioBusqueda = respuesta.data;
                    this.nuevoIngresoService.definirDatosUsuarioBusqueda(this.datosUsuarioBusqueda);
                    if (this.datosUsuarioBusqueda.alumno[0].tipoCandidatoId !== null) {
                      this.cargaDocumentos.existeSubProceso = true;
                    } else {
                      this.cargaDocumentos.existeSubProceso = false;
                    }
                    this.cargaDocumentos.datosUsuarioBusqueda = this.datosUsuarioBusqueda;
                    this.cargaDocumentos.renderizarFormulario();
                  });
                this.nuevoIngresoService.refrescarTabla()
                this.nuevoIngresoService.updateTabs();
                this.solicitudApoyoFinancieroSerrvice.metodoObtenerSolicitudes();
                let mensaje: string = this.traductorService.translate('guardadoCorrecto');
                this.snackService.mostrarSnackBack(mensaje);
              } else {
                let mensaje: string = this.traductorService.translate('errorGuardar');
                this.snackService.mostrarSnackBack(mensaje);
              }
            });

            //this.datosUsuarioBusqueda.alumno[0].estatusAcademicoNavigation.nombre = programaAcademico.estatusAcademicoId;
            this.cambioForm = false;
            this.nuevoIngresoService.defineCandidatCycleId(this.datosUsuarioBusqueda.alumno[0].programaId);
            this.nuevoIngresoService.defineStudentId(this.datosUsuarioBusqueda.alumno[0].alumnoId);
            this.nuevoIngresoService.defineIdPeriodo(this.datosUsuarioBusqueda.alumno[0].priodoIngreso);
            this.nuevoIngresoService.obtenerProgramaById(programaAcademico.programa.ProgramaId).subscribe(respuesta => {
              this.datosPersonales.obtenerInstitucion(respuesta.data.tipoAdmisionId);
              this.datosPersonales.aplicarValidaciones(respuesta.data.tipoAdmisionId);
            });
          } else {
            // let mensaje: string = this.traductorService.translate(respuesta.message);
            this.snackService.mostrarSnackBack(respuesta.message);
          }
        }
      )
    }
    if (this.tabSeleccionado === 1) {
      let datosPersonalesForm = Object.assign(this.datosPersonales.datosPersonalesForm.value);
      let datosPersonales: DatosPersonales = {
        usuarioId: datosPersonalesForm.usuarioId,
        nombre: datosPersonalesForm.nombre,
        segundoNombre: datosPersonalesForm.segundoNombre,
        apellidoPaterno: datosPersonalesForm.apellidoPaterno,
        apellidoMaterno: datosPersonalesForm.apellidoMaterno,
        correo: datosPersonalesForm.correo,
        biografia: {
          fechaNacimiento: datosPersonalesForm.fechaNacimiento
        }
      }
      this.nuevoIngresoService.obtenerCorreoOcupado(datosPersonalesForm.correo, datosPersonalesForm.usuarioId).subscribe(
        (correoOcupado: SimpleResponse) => {
          if (correoOcupado.success) {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos personales. El correo ya se encuentra ocupado, por favor introduzca otro correo.');
          } else {
            this.nuevoIngresoService.editarDatosPersonales(datosPersonales).subscribe(
              (respuesta: SimpleResponse) => {
                if (respuesta.success) {
                  let datosContactoForm = Object.assign(this.datosPersonales.datosPersonalesForm.value);
                  let datosContacto: DatosContactoF = {
                    correo: datosContactoForm.correo,
                    usuarioId: datosPersonalesForm.usuarioId,
                    direccion: [
                      {
                        tipoDireccionId: datosPersonalesForm.tipoDireccionId,
                        paisId: datosPersonalesForm.paisId,
                        entidadFederativaId: datosPersonalesForm.entidadFederativaId,
                        calle: datosPersonalesForm.calle,
                        ciudad: datosPersonalesForm.ciudad,
                        numeroExterior: datosPersonalesForm.numeroExterior,
                        numeroInterior: datosPersonalesForm.numeroInterior,
                        colonia: datosPersonalesForm.colonia,
                        codigoPostal: datosPersonalesForm.codigoPostal
                      }
                    ],
                    telefono: [
                      {
                        tipoTelefonoId: datosContactoForm.tipoTelefonoId,
                        paisId: datosContactoForm.paisId,
                        numero: datosContactoForm.numero,
                        extension: datosContactoForm.extension
                      },
                      {
                        tipoTelefonoId: datosContactoForm.otroTipoTelefonoId,
                        paisId: datosContactoForm.paisId,
                        numero: datosContactoForm.otroNumero,
                        extension: datosContactoForm.otroExtension
                      }
                    ]
                  }
                  this.nuevoIngresoService.registrarDatosContacto(datosContacto).subscribe(
                    (respuestaDatos: SimpleResponse) => {
                      if (respuestaDatos.success) {
                        let datosBiografiaForm = Object.assign(this.datosPersonales.datosPersonalesForm.value);
                        let datosBiografia: DatosBiografia = {
                          UsuarioId: datosBiografiaForm.usuarioId,
                          LegalGeneroId: datosBiografiaForm.legalGeneroId,
                          GeneroId: datosBiografiaForm.generoId > 0 ? datosBiografiaForm.generoId : null,
                          CiudadaniaPaisId: datosBiografiaForm.ciudadania,
                          EstadoCivilId: datosBiografiaForm.estadoCivilId > 0 ? datosBiografiaForm.estadoCivilId : null,
                          Curp: datosBiografiaForm.curp,
                          EntidadNacimiento: datosBiografiaForm.entidadNacimiento,
                          CiudadNacimiento: datosBiografiaForm.ciudadNacimiento,
                          Alergias: datosBiografiaForm.alergias,
                          GrupoSanguineoId: datosBiografiaForm.grupoSanguineoId > 0 ? datosBiografiaForm.grupoSanguineoId : null,
                          Enfermedades: datosBiografiaForm.enfermedades,

                          razaId: datosBiografiaForm.raza,
                          estatusMigratorioId: datosBiografiaForm.estatusMigratorio,
                          tipoDocumentoId: datosBiografiaForm.tipoDocumento,
                          paisDocumentoId: datosBiografiaForm.paisDocumento,
                          hasCriminalRecord: datosBiografiaForm.hasCriminalRecord,
                          hasAcademicDisciplinaryRecord: datosBiografiaForm.hasAcademicDisciplinaryRecord
                        }
                        this.biografiaService.obtenerBiografiaByCurp(datosBiografia.Curp, datosBiografia.UsuarioId).subscribe(
                          biografia => {
                            if (biografia != null) {
                              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de biografia. El CURP ingresado ya esta registrado, ingrese un CURP diferente.');
                            } else {
                              this.nuevoIngresoService.modificarBiografia(datosBiografia).subscribe(
                                (respuestaBiografia: SimpleResponse) => {
                                  if (respuestaBiografia.success) {
                                    let datosFamiliaresForm = Object.assign(this.datosPersonales.datosPersonalesForm.value);
                                    let datosFamiliares: DatosFamilia[] = [];
                                    if (datosFamiliaresForm.nombreCompletoPadre != '') {
                                      let datosFamilia: DatosFamilia = {
                                        UsuarioId: datosFamiliaresForm.usuarioId,
                                        TipoRelacionId: 1,
                                        NombreCompleto: datosFamiliaresForm.nombreCompletoPadre,
                                        Telefono: datosFamiliaresForm.telefonoPadre,
                                        CorreoElectronico: datosFamiliaresForm.correoElectronicoPadre
                                      }
                                      datosFamiliares.push(datosFamilia);
                                    }
                                    if (datosFamiliaresForm.nombreCompletoMadre != '') {
                                      let datosFamilia: DatosFamilia = {
                                        UsuarioId: datosFamiliaresForm.usuarioId,
                                        TipoRelacionId: 2,
                                        NombreCompleto: datosFamiliaresForm.nombreCompletoMadre,
                                        Telefono: datosFamiliaresForm.telefonoMadre,
                                        CorreoElectronico: datosFamiliaresForm.correoElectronicoMadre,
                                      }
                                      datosFamiliares.push(datosFamilia);
                                    }
                                    if (datosFamiliaresForm.nombreCompletoTutor != '') {
                                      let datosFamilia: DatosFamilia = {
                                        UsuarioId: datosFamiliaresForm.usuarioId,
                                        TipoRelacionId: 3,
                                        NombreCompleto: datosFamiliaresForm.nombreCompletoTutor,
                                        Telefono: datosFamiliaresForm.telefonoTutor,
                                        CorreoElectronico: datosFamiliaresForm.correoElectronicoTutor
                                      }
                                      datosFamiliares.push(datosFamilia);
                                    }
                                    if (datosFamiliaresForm.nombreContactoObli != '') {
                                      let datosFamilia: any = {
                                        UsuarioId: datosFamiliaresForm.usuarioId,
                                        TipoRelacionId: datosFamiliaresForm.tipoRelacion,
                                        NombreCompleto: datosFamiliaresForm.nombreContactoObli,
                                        Telefono: datosFamiliaresForm.telefonoContactoObli,
                                        CorreoElectronico: datosFamiliaresForm.correoContactoObli,
                                      }
                                      datosFamiliares.push(datosFamilia);
                                    }
                                    this.nuevoIngresoService.registrarDatosFamilia(datosFamiliares).subscribe(
                                      (respuestaFamilia: SimpleResponse) => {
                                        if (respuestaFamilia.success) {
                                          let datosEmergenciaForm = Object.assign(this.datosPersonales.datosPersonalesForm.value);
                                          let datosEmergencia: DatosFamilia = {
                                            UsuarioId: datosEmergenciaForm.usuarioId,
                                            TipoRelacionId: 4,
                                            NombreCompleto: datosEmergenciaForm.nombreContacto,
                                            Telefono: datosEmergenciaForm.telefonoContacto,
                                            CorreoElectronico: datosEmergenciaForm.correoContacto
                                          }
                                          this.nuevoIngresoService.registrarDatosEmergencia(datosEmergencia).subscribe(
                                            (respuestaDatoEmergencia: SimpleResponse) => {
                                              if (respuestaDatoEmergencia.success) {
                                                let datosEstudiosPreviosForm = Object.assign(this.datosPersonales.datosPersonalesForm.value);
                                                let datosEstudiosPrevios: EstudiosPrevios = new EstudiosPrevios();
                                                datosEstudiosPrevios.usuarioId = datosEstudiosPreviosForm.usuarioId,
                                                  datosEstudiosPrevios.nivelId = datosEstudiosPreviosForm.nivel,
                                                  datosEstudiosPrevios.escuela = datosEstudiosPreviosForm.academico,
                                                  datosEstudiosPrevios.promedio = +datosEstudiosPreviosForm.promedio,
                                                  this.nuevoIngresoService.agregarEstudiosPrevios(datosEstudiosPrevios).subscribe(
                                                    (respuestaEstudios: SimpleResponse) => {
                                                      if (respuestaEstudios.success) {
                                                        let datosDatoFiscalForm = Object.assign(this.datosPersonales.datosPersonalesForm.value);
                                                        let datosDatoFiscal: DatosFiscal = {
                                                          UsuarioId: datosDatoFiscalForm.usuarioId,
                                                          Rfc: datosDatoFiscalForm.rfc,
                                                          RazonSocial: datosDatoFiscalForm.razonSocial,
                                                          EntidadFederativaId: datosDatoFiscalForm.entidadFederativaFacturacionId,
                                                          Calle: datosDatoFiscalForm.calleFacturacion,
                                                          Ciudad: datosDatoFiscalForm.ciudadFacturacion,
                                                          NumeroExterior: datosDatoFiscalForm.numeroExteriorFacturacion,
                                                          NumeroInterior: datosDatoFiscalForm.numeroInteriorFacturacion,
                                                          Colonia: datosDatoFiscalForm.coloniaFacturacion,
                                                          CodigoPostal: datosDatoFiscalForm.codigoPostalFacturacion,
                                                          Nombre: datosDatoFiscalForm.nombre,
                                                          ApellidoPaterno: datosDatoFiscalForm.apellidoPaterno,
                                                          ApellidoMaterno: datosDatoFiscalForm.apellidoMaterno
                                                        }
                                                        this.nuevoIngresoService.GetDatoFiscalByRFC(datosDatoFiscal.Rfc, datosDatoFiscal.UsuarioId).subscribe(
                                                          (respuestaRFC: ApiResponse<DatoFiscal>) => {
                                                            if (respuestaRFC.data != null) {
                                                              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de facturacion. El RFC ingresado ya esta registrado, ingrese un RFC diferente.');
                                                            } else {
                                                              this.nuevoIngresoService.agregarDatosFiscal(datosDatoFiscal).subscribe(
                                                                (respuestaDatoFiscal: SimpleResponse) => {
                                                                  if (respuestaDatoFiscal.success) {
                                                                    let ocupacionForm = Object.assign(this.datosPersonales.datosPersonalesForm.value);
                                                                    let datosOcupacion: DatosOcupacion = {
                                                                      UsuarioId: ocupacionForm.usuarioId,
                                                                      TipoEmpresaId: ocupacionForm.tipoEmpresaId,
                                                                      TipoPuestoEmpresaId: ocupacionForm.tipoEmpresaId,
                                                                    }
                                                                    this.nuevoIngresoService.registrarOcupacion(datosOcupacion).subscribe(
                                                                      (respuestaOcupacion: SimpleResponse) => {
                                                                        if (respuestaOcupacion.success) {
                                                                          this.changesPersonalData = false;
                                                                          this.snackService.mostrarSnackBack('Se han guardado los datos correctamente.');
                                                                          this.nuevoIngresoService.refrescarTabla();
                                                                          this.nuevoIngresoService.updateTabs();
                                                                          this.ngOnInit();
                                                                        } else {
                                                                          this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la ocupación. Por favor, intente de nuevo más tarde.');
                                                                        }
                                                                      }
                                                                    )

                                                                  } else {
                                                                    this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos Fiscales. Por favor, intente de nuevo más tarde.');
                                                                  }
                                                                }
                                                              )
                                                            }
                                                          }
                                                        )

                                                      } else {
                                                        this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de estudios previos. Por favor, intente de nuevo más tarde.');
                                                      }
                                                    }
                                                  )

                                              } else {
                                                this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de Emergencia. Por favor, intente de nuevo más tarde.');
                                              }
                                            }
                                          )
                                        } else {
                                          this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos Familiares. Por favor, intente de nuevo más tarde.');
                                        }
                                      }
                                    )
                                  } else {
                                    this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de biografia. Por favor, intente de nuevo más tarde.');
                                  }
                                }
                              )
                            }
                          });
                      }
                    }
                  )
                } else {
                  this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos personales. Por favor, intente de nuevo más tarde.');
                }
              }
            )
          }
        })
    }
    if (this.tabSeleccionado === 2) {

    }
    if (this.tabSeleccionado === 3) {
      let apoyoFinancieroForm = Object.assign(this.apoyoFinanciero.formApoyoFinanciero.value);
      let apoyoSelecionadofiltrado = this.apoyoFinanciero.apoyoList.find(x => x.tipoApoyoFinancieroId == apoyoFinancieroForm.tipoApoyoFinanciero);
      let solicitudApoyoFinanciero: SolicitudApoyo = {
        usuarioId: apoyoFinancieroForm.usuarioId,
        campusId: apoyoFinancieroForm.campusId,
        periodoId: apoyoFinancieroForm.priodoIngreso,
        programaAcademicoId: apoyoFinancieroForm.programaId,
        promedio: apoyoFinancieroForm.promedio,
        tipoApoyoFinancieroId: apoyoFinancieroForm.tipoApoyoFinanciero,
        estatusSolicitudApoyoID: apoyoFinancieroForm.estatusSolicitudApoyo,
        porcentaje: apoyoSelecionadofiltrado.porcentaje,
        empresaId: apoyoFinancieroForm.empresa,
      }
      this.nuevoIngresoService.registrarApoyoFinanciero(solicitudApoyoFinanciero).subscribe(
        (respuesta: SimpleResponse) => {
          if (respuesta.success) {
            this.changesFinancialSupport = false;
            let mensaje: string = this.traductorService.translate('apoyoGuardado');
            this.snackService.mostrarSnackBack(mensaje);
            this.nuevoIngresoService.refrescarTabla()
            this.ngOnInit();
          } else {
            this.snackService.mostrarSnackBack(respuesta.message);
          }
        }
      )
    }
  }

  public cargoPorExibicion() {
    this.tableShared.updateTable();
    if (this.materiaAltaBaja) {
      this.materiaAltaBaja = false;
      this.materiasBajaAltaAlumno.cargoExcibicion = true;
      this.nuevoIngresoService.AltaBajaMateriaAlumno(this.materiasBajaAltaAlumno).subscribe((response: SimpleResponse) => {
        if (response.success) {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.seleccionMaterias.resetSeleccionDeMaterias();
          this.cambiosHechosEnSeleccionDeMateria = false;
        }
        else {
          this.snackService.mostrarSnackBack(response.message);
        }
      });
    }
    else {
      this.nuevoIngresoService.CargoExcibicion(this.colegiaturaCuota).subscribe((cargoExcibicion: SimpleResponse) => {
        if (cargoExcibicion.success) {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.cambiosHechosEnSeleccionDeMateria = false
          let blockId = this.seleccionMaterias.nombreBloque.value;
          this.seleccionMaterias.onNombreBloqueSelected(blockId);
        }
        else {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    }
    this.dialogoRefCerrarModalCargo.close();
  }

  public cargoPorParcialidades() {
    this.tableShared.updateTable();
    if (this.materiaAltaBaja) {
      this.materiaAltaBaja = false;
      this.materiasBajaAltaAlumno.cargoExcibicion = false;
      this.nuevoIngresoService.AltaBajaMateriaAlumno(this.materiasBajaAltaAlumno).subscribe((response: SimpleResponse) => {
        if (response.success) {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.seleccionMaterias.resetSeleccionDeMaterias();
          this.cambiosHechosEnSeleccionDeMateria = false;
        }
        else {
          this.snackService.mostrarSnackBack(response.message);
        }
      });
    }
    else {
      this.nuevoIngresoService.CargoParcialidades(this.colegiaturaCuota).subscribe((cargoExcibicion: SimpleResponse) => {
        if (cargoExcibicion.success) {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.cambiosHechosEnSeleccionDeMateria = false;
          let blockId = this.seleccionMaterias.nombreBloque.value;
          this.seleccionMaterias.onNombreBloqueSelected(blockId);
        }
        else {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    }
    this.dialogoRefCerrarModalCargo.close();
  }


  public curpInsert(curp: string) {
    if (curp.trim().length == 18) {
      let usuarioId = this.datosUsuarioBusqueda.usuarioId;
      this.biografiaService.obtenerBiografiaByCurp(curp, usuarioId).subscribe(
        biografia => {
          if (biografia == null) {
            return true;
          } else {
            return false;
          }
        });
    } else {
      return false;
    }
  }
  public onChangeTab(event: MatTabChangeEvent) {
    this.tabCambio = true;
    let tab: number = event.index;
    this.tabSeleccionado = tab;
    switch (this.tabSeleccionado) {
      case 0: {
        this.tabCambios = "Programa Academico";
        this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.programaAcademico?.programaAcademicoForm.valid);

        break;
      }
      case 1: {
        this.tabCambios = "Datos Personales";
        this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.datosPersonales?.datosPersonalesForm.valid);
        this.cargasVisibles = false;
        break;
      }
      case 2: {
        this.tabCambios = "Carga de documentos";
        this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.cargaDocumentos?.cargaDocumentoForm.valid);
        this.cargasVisibles = false;
        break;

      }
      case 3: {
        this.tabCambios = "Apoyo Financiero";
        this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.apoyoFinanciero.formApoyoFinanciero.valid);

        if (this.datosUsuarioBusqueda?.solicitudApoyoFinanciero?.length >= 1) {

          if (this.datosUsuarioBusqueda?.solicitudApoyoFinanciero[0].estatusSolicitudApoyoId === 1) //EN PROCESO
          {
            let mensaje: string = this.traductorService.translate('usuarioBeca');
            this.snackService.mostrarSnackBack(mensaje);
            this.tipoAccion = AccionEnum.EDITAR;
          }
          if (this.datosUsuarioBusqueda?.solicitudApoyoFinanciero[0].estatusSolicitudApoyoId === 2) // ACEPTADA
          {
            let mensaje: string = this.traductorService.translate('usuarioBeca2');
            this.snackService.mostrarSnackBack(mensaje);

          }
        }
        if (this.datosUsuarioBusqueda?.profesor != null) // ES UN PROFESOR
        {

        }
        this.cargasVisibles = false;
        break;
      }
      case 4: {
        this.cargasVisibles = true;
        let alumno = this.datosUsuarioActual.alumno[0];
      }
      case 5: {
        this.cargasVisibles = false;
        break;
      }
      default: {
        break;
      }
    }

  }

  public cerrarModal() {

    if (this.changesAcademicProgram || this.changesPersonalData || this.changesDocuments || this.changesFinancialSupport || this.changesSubjects || this.changesPayment) {
      if (this.changesAcademicProgram) {
        this.tabCambiosPrograma = " - Programa Academico";
      } else {
        this.tabCambiosPrograma = "";
      }
      if (this.changesPersonalData) {
        this.tabCambiosDatos = " - Datos Personales";
      } else {
        this.tabCambiosDatos = "";
      }
      if (this.changesDocuments) {
        this.tabCambiosDocumentos = " - Carga Documentos";
      } else {
        this.tabCambiosDocumentos = "";
      }
      if (this.changesFinancialSupport) {
        this.tabCambiosApoyo = " - Apoyo Financiero";
      } else {
        this.tabCambiosApoyo = "";
      }
      if (this.changesSubjects) {
        this.tabCambiosMaterias = " - Materias";
      } else {
        this.tabCambiosMaterias = "";
      }
      if (this.changesPayment) {
        this.tabCambiosPago = " - Pago";
      } else {
        this.tabCambiosPago = "";
      }


      this.dialogoRefCerrarModal = this.dialog.open(this.dialogoCerrarModal, { disableClose: true });
    } else {
      this.dialogRef.close(this.data);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      if (this.tabCambio) {

      } else {
        this.dialogoRefAdver.close();
        this.dialogRef.close(this.data);
      }
    } else {
      if (this.tabCambio) {

      } else {
        this.dialogoRefAdver.close();
      }
    }
  }

  public abrirModalAgregarAlumnosBloque() {
    this.dialogoRefCerrarModalAgregarAlumnosBloque = this.dialog.open(this.dialogoAgregarAlumnosBloque, { disableClose: true });
  }

  public cerarModalAgregarAlumnosBloque() {
    this.dialogoRefCerrarModalAgregarAlumnosBloque.close();
  }

  public cerrarModalAdvertenciaCancelado(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdverCancelado.close();
      this.dialogRef.close(this.data);
    } else {
      this.reactivarCandidato();
      this.dialogoRefAdverCancelado.close();
    }
  }

  public cerrarModalAdvertenciaCancelarCambios(descartar: boolean) {
    if (descartar) {
      this.dialogoRefCerrarModal.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefCerrarModal.close();
    }
  }

  public cerarModalPagoInscripcion() {
    this.dialogoRefCerrarPagoInscripcion.close();
  }
  public cancelarModalPagoInscripcion() {
    this.tabSeleccionadoIndex.setValue(1);
    this.dialogoRefCerrarPagoInscripcion.close();
  }

  public abrirModalTipoCargo() {
    this.dialogoRefCerrarModalCargo = this.dialog.open(this.dialogoCerrarModalCargo, { disableClose: true });
  }

  public cerarModalTipoCargo() {
    this.dialogoRefCerrarModalCargo.close();
  }
  cargaActualAl: string;
  public setCarga(carga) {
    this.cargaActualAl = carga;
  }

  public validarDatos(element: UsuarioIngreso) {
    let menorEdad = this.calcularEdad(element.biografia.fechaNacimiento);
    if (menorEdad) {
      return element.direccion.length >= 1 && element.telefono.length >= 1 && element.biografia.curp != null && element.personaRelacionadaUsuario.length >= 1 && element.estudioPrevio.length >= 1
    } else {
      return element.direccion.length >= 1 && element.telefono.length >= 1 && element.biografia.curp != null && element.estudioPrevio.length >= 1
    }
  }

  public calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    if (edad < 18) {
      return true
    } else {
      return false;
    }
  }

  /**
   * Guarda los cambios hechos en respecto a las materias, en la base de datos
   */
  public guardarCambiosDeMaterias(): void {
    this.egresoService.ConfiguracionAcademicaValida(this.datosUsuarioBusqueda.alumno[0].campusId, this.datosUsuarioBusqueda.alumno[0].programa.nivelId, this.datosUsuarioBusqueda.alumno[0].priodoIngreso).subscribe((response: ApiResponse<boolean>) => {
      if (response.success) {
        if (response.data) {
          if (this.seleccionMaterias.isSoloGruposDesuscritosPendientes) {
            this.gruposDesuscritosLocalmente = this.seleccionMaterias.gruposDesuscritosLocalmente;
            this.desuscribirMaterias();
          }
          if (this.seleccionMaterias.isSoloGruposInscritosPendientes) {
            this.inscribirMaterias();
          }
          if (this.seleccionMaterias.isGruposDesuscritosEInscritosPendientes) {
            this.gruposDesuscritosLocalmente = this.seleccionMaterias.gruposDesuscritosLocalmente;
            this.materiaAltaBaja = true;
            this.materiaAltaBajaAlumno();
          }
        } else {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
     * Inscribe a un nuevo grupo al alumno para la materia y periodo indicado
     */
  private inscribirMaterias(): void {
    this.colegiaturaCuota = new ColegiaturaCostoDto();
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.datosUsuarioBusqueda.alumno[0].programa.campusId, this.datosUsuarioBusqueda.alumno[0].programa.nivelId, this.datosUsuarioBusqueda.alumno[0].priodoIngresoNavigation.periodoId, this.datosUsuarioBusqueda.alumno[0].programa.programaId).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data.parcialidades) {
            this.permiteParcialidades = true;
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
          this.colegiaturaCuota.alumnoId = this.datosUsuarioBusqueda.alumno[0].alumnoId;
          this.colegiaturaCuota.campusId = this.datosUsuarioBusqueda.alumno[0].programa.campusId;
          this.colegiaturaCuota.nivelId = this.datosUsuarioBusqueda.alumno[0].programa.nivelId;
          this.colegiaturaCuota.periodoId = this.datosUsuarioBusqueda.alumno[0].priodoIngresoNavigation.periodoId;
          this.colegiaturaCuota.costo = cuotaColegiaturaRegistrada.data.costo;
          this.colegiaturaCuota.claveTipoCobro = cuotaColegiaturaRegistrada.data.claveTipoCobro;
          this.colegiaturaCuota.impuesto = cuotaColegiaturaRegistrada.data.impuesto;
          this.colegiaturaCuota.intereses = cuotaColegiaturaRegistrada.data.intereses;
          this.colegiaturaCuota.fechaInicio = cuotaColegiaturaRegistrada.data.fechaInicio;
          this.colegiaturaCuota.fechaFin = cuotaColegiaturaRegistrada.data.fechaFin;
          this.colegiaturaCuota.diaTransaccion = cuotaColegiaturaRegistrada.data.diaTransaccion;
          this.colegiaturaCuota.diaVencimiento = cuotaColegiaturaRegistrada.data.diaVencimiento;
          this.colegiaturaCuota.fechaVencimiento = cuotaColegiaturaRegistrada.data.fechaVencimiento;
          this.colegiaturaCuota.esNuevoIngreso = true;
          this.registrarMaterias();
        }
        else {
          this.snackService.mostrarSnackBack("No es posible realizar la selección, debido a que no hay una cuota de colegiatura registrada.");
        }
      });
  }

  private registrarMaterias(): void {
    let grupos = this.seleccionMaterias.gruposInscritosLocalmente.map((grupo) => {
      let grupoApiDTO = new GrupoDTO();
      grupoApiDTO.claveMateria = grupo.claveMateria
      return grupoApiDTO
    });
    this.nuevoIngresoService.existeSeriacionDeMateriasEnLaInscripcion(this.datosUsuarioBusqueda.alumno[0].alumnoId, grupos).subscribe(
      (ExisteSeriacion: SimpleResponse) => {
        if (ExisteSeriacion.success) {
          let seriacion = new ExisteMateriaSeriada();
          seriacion.existeMateriaSeriada = true;
          seriacion.materiasSeriadas = ExisteSeriacion.message;
          this.nuevoIngresoService.existeSeriacionDeMaterias(seriacion);
        }
        this.nuevoIngresoService.inscribirAgrupos(this.datosUsuarioBusqueda.alumno[0].alumnoId, this.datosUsuarioBusqueda.identificador, this.seleccionMaterias.gruposInscritosLocalmente).subscribe(
          (materiasInscritas: SimpleResponse) => {
            if (materiasInscritas.success) {
              this.seleccionMaterias.resetSeleccionDeMaterias();
              if (this.colegiaturaCuota.costo !== 0) {
                this.abrirModalTipoCargo();
              } else {
                let mensaje: string = this.traductorService.translate(materiasInscritas.message);
                this.snackService.mostrarSnackBack(mensaje);
              }
            }
            else {
              this.snackService.mostrarSnackBack(materiasInscritas.message);
            }
          })
      });
  }

  /**
   * Desuscribe de un grupo a un alumno que ya está cursando una materia en un grupo siempre
   */
  private desuscribirMaterias() {
    const informacionDeBaja = new BajaDTO();
    informacionDeBaja.identiticador = this.seleccionMaterias.identificador;
    informacionDeBaja.idPeriodo = this.seleccionMaterias.idPeriodo;
    informacionDeBaja.grupos = this.gruposDesuscritosLocalmente;
    informacionDeBaja.cantidadDeCreditosAntesDeBaja = this.seleccionMaterias.cantidadDeCreditosAntesDeBaja;
    informacionDeBaja.cantidadDeMateriasAntesDeBaja = this.seleccionMaterias.cantidadDeMateriasAntesDeBaja;
    this.nuevoIngresoService.darDeBajaDeGrupos(informacionDeBaja).subscribe((materiasBaja: SimpleResponse) => {
      if (materiasBaja.success) {
        this.cambiosHechosEnSeleccionDeMateria = false;
        this.seleccionMaterias.resetSeleccionDeMaterias();
        let mensaje: string = this.traductorService.translate(materiasBaja.message);
        this.snackService.mostrarSnackBack(mensaje);
      }
      else {
        this.snackService.mostrarSnackBack(materiasBaja.message);
      }
    });
  }

  /**
   * Metodo para dar de baja y alta aal mismo tiempo
   */
  public materiaAltaBajaAlumno(): void {
    this.colegiaturaCuota = new ColegiaturaCostoDto();
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.datosUsuarioBusqueda.alumno[0].programa.campusId, this.datosUsuarioBusqueda.alumno[0].programa.nivelId, this.datosUsuarioBusqueda.alumno[0].priodoIngresoNavigation.periodoId, this.datosUsuarioBusqueda.alumno[0].programa.programaId).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data.parcialidades) {
            this.permiteParcialidades = true;
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
          this.colegiaturaCuota.alumnoId = this.datosUsuarioBusqueda.alumno[0].alumnoId;
          this.colegiaturaCuota.campusId = this.datosUsuarioBusqueda.alumno[0].programa.campusId;
          this.colegiaturaCuota.nivelId = this.datosUsuarioBusqueda.alumno[0].programa.nivelId;
          this.colegiaturaCuota.periodoId = this.datosUsuarioBusqueda.alumno[0].priodoIngresoNavigation.periodoId;
          this.colegiaturaCuota.costo = cuotaColegiaturaRegistrada.data.costo;
          this.colegiaturaCuota.claveTipoCobro = cuotaColegiaturaRegistrada.data.claveTipoCobro;
          this.colegiaturaCuota.impuesto = cuotaColegiaturaRegistrada.data.impuesto;
          this.colegiaturaCuota.intereses = cuotaColegiaturaRegistrada.data.intereses;
          this.colegiaturaCuota.fechaInicio = cuotaColegiaturaRegistrada.data.fechaInicio;
          this.colegiaturaCuota.fechaFin = cuotaColegiaturaRegistrada.data.fechaFin;
          this.colegiaturaCuota.diaTransaccion = cuotaColegiaturaRegistrada.data.diaTransaccion;
          this.colegiaturaCuota.diaVencimiento = cuotaColegiaturaRegistrada.data.diaVencimiento;
          this.colegiaturaCuota.fechaVencimiento = cuotaColegiaturaRegistrada.data.fechaVencimiento;
          this.colegiaturaCuota.esNuevoIngreso = true;

          const informacionDeBaja = new BajaDTO();
          informacionDeBaja.identiticador = this.seleccionMaterias.identificador;
          informacionDeBaja.idPeriodo = this.seleccionMaterias.idPeriodo;
          informacionDeBaja.grupos = this.gruposDesuscritosLocalmente;
          informacionDeBaja.cantidadDeCreditosAntesDeBaja = this.seleccionMaterias.cantidadDeCreditosAntesDeBaja;
          informacionDeBaja.cantidadDeMateriasAntesDeBaja = this.seleccionMaterias.cantidadDeMateriasAntesDeBaja;

          this.materiasBajaAltaAlumno = {
            infoInscripcion: {
              identificador: this.datosUsuarioBusqueda.identificador,
              gruposInscritos: this.seleccionMaterias.gruposInscritosLocalmente
            },
            infoDesuscripcion: {
              informacionDeBaja: informacionDeBaja
            },
            infoColegiaturaCuota: {
              colegiaturaCuota: this.colegiaturaCuota
            }
          }
          this.seleccionMaterias.resetSeleccionDeMaterias();
          if (this.colegiaturaCuota.costo !== 0) {
            this.abrirModalTipoCargo();
          }
        }
        else {
          let mensaje: string = this.traductorService.translate("_nuevosIngresos.mensaje-son-cobro");
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
  }

  public guardarAlumno() {
    let blockId = this.seleccionMaterias.nombreBloque.value;
    let alumnoId = this.datosUsuarioBusqueda.alumno[0].alumnoId;

    let blockStudents: BlockStudent[] = [];
    let newBlockStudent = new BlockStudent();

    newBlockStudent.blockId = blockId;
    newBlockStudent.studentId = alumnoId;
    blockStudents.push(newBlockStudent);

    this.blockService.PostListBlockStudent(blockStudents).subscribe(response => {
      if (response.success) {
        this.snackService.mostrarSnackBack(response.message);

        this.blockService.invocarMetodoObtenerBloqueAlumno();
        this.cerarModalAgregarAlumnosBloque();
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    })
  }


  public Inscribir() {

    this.cerarModalAgregarAlumnosBloque();

    this.colegiaturaCuota = new ColegiaturaCostoDto();
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(
      this.datosUsuarioBusqueda.alumno[0].programa.campusId,
      this.datosUsuarioBusqueda.alumno[0].programa.nivelId,
      this.datosUsuarioBusqueda.alumno[0].priodoIngresoNavigation.periodoId,
      this.datosUsuarioBusqueda.alumno[0].programa.programaId
    ).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data.parcialidades) {
            this.permiteParcialidades = true;
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
          this.colegiaturaCuota.alumnoId = this.datosUsuarioBusqueda.alumno[0].alumnoId;
          this.colegiaturaCuota.campusId = this.datosUsuarioBusqueda.alumno[0].programa.campusId;
          this.colegiaturaCuota.nivelId = this.datosUsuarioBusqueda.alumno[0].programa.nivelId;
          this.colegiaturaCuota.periodoId = this.datosUsuarioBusqueda.alumno[0].priodoIngresoNavigation.periodoId;
          this.colegiaturaCuota.costo = cuotaColegiaturaRegistrada.data.costo;
          this.colegiaturaCuota.claveTipoCobro = cuotaColegiaturaRegistrada.data.claveTipoCobro;
          this.colegiaturaCuota.impuesto = cuotaColegiaturaRegistrada.data.impuesto;
          this.colegiaturaCuota.intereses = cuotaColegiaturaRegistrada.data.intereses;
          this.colegiaturaCuota.fechaInicio = cuotaColegiaturaRegistrada.data.fechaInicio;
          this.colegiaturaCuota.fechaFin = cuotaColegiaturaRegistrada.data.fechaFin;
          this.colegiaturaCuota.diaTransaccion = cuotaColegiaturaRegistrada.data.diaTransaccion;
          this.colegiaturaCuota.diaVencimiento = cuotaColegiaturaRegistrada.data.diaVencimiento;
          this.colegiaturaCuota.fechaVencimiento = cuotaColegiaturaRegistrada.data.fechaVencimiento;
          this.colegiaturaCuota.esNuevoIngreso = true;


          let blockId = this.seleccionMaterias.nombreBloque.value;
          let alumnoId = this.datosUsuarioBusqueda.alumno[0].alumnoId;

          let blockStudents: BlockStudent[] = [];
          let newBlockStudent = new BlockStudent();

          newBlockStudent.blockId = blockId;
          newBlockStudent.studentId = alumnoId;
          blockStudents.push(newBlockStudent);

          this.blockService.PostListBlockStudent(blockStudents).subscribe(response => {
            if (response.success) {
              this.blockService.InscribirAGruposBloqueAlumno(newBlockStudent).subscribe(response => {
                if (response.success) {
                  this.abrirModalTipoCargo();
                } else {
                  let mensaje: string = this.traductorService.translate(response.message);
                  this.snackService.mostrarSnackBack(mensaje);
                }
              })
            } else {
              this.snackService.mostrarSnackBack(response.message);
            }
          })

        }
        else {
          this.snackService.mostrarSnackBack("No es posible realizar la selección, debido a que no hay una cuota de colegiatura registrada.");
        }
      });


  }

  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

}
