import { Component, OnInit } from '@angular/core';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { TipoRecurso } from 'src/app/_models/tipo-recurso';
import { EnsenanzaAprendizajeService } from '../../../../_services/ensenanza-aprendizaje.service';
import * as customBuild from '../../../../_librarries/ckeditorCustom/build/ckeditor';

@Component({
  selector: 'app-recurso-modal',
  templateUrl: './recurso-modal.component.html',
  styleUrls: ['./recurso-modal.component.scss']
})
export class RecursoModalComponent implements OnInit {
  constructor(
    private ensenanzaServ: EnsenanzaAprendizajeService
  ) { }
  public Editor = customBuild;
  tipoRecurso: Array<TipoRecurso> = [];

  ngOnInit(): void {
    this.obtenerTipoRecurso();
  }

  obtenerTipoRecurso(){
    this.ensenanzaServ.listaRecursos().subscribe((tipoRecurso: ApiResponse<TipoRecurso[]>)=>{
      this.tipoRecurso = tipoRecurso.data;
    })
  }
}
