import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleResponse } from '../../models/api/SimpleResponse.model';
import { AttributesService } from '../../_services/attributes.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { TraductorService } from './../../_services/traductor.service';

@Component({
  selector: 'app-atributos-modal-eliminar',
  templateUrl: './atributos-modal-eliminar.component.html',
  styleUrls: ['./atributos-modal-eliminar.component.scss']
})
export class AtributosModalEliminarComponent implements OnInit {

  public numAtributosSeleccionados = 0;
  public boolIsArray = false;
  public claveAtributos = '';

  constructor(public dialogRef: MatDialogRef<AtributosModalEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private attributeService: AttributesService,
    private snackService: SnackService,
    private traductorService: TraductorService,) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  /**
   * Se valida si es uno o mas atributos
   */
  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numAtributosSeleccionados = this.data.length;
    }
    console.log('Data de eliminar', this.data);
  }

  /**
   * Metodo para eliminar los atributos selccionados
   */
  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(x => (x.attributeId.toString()));
      this.claveAtributos = arrayClaves.toString();
    } else {
      this.claveAtributos = this.data.attributeId.toString();
    }
    this.attributeService.eliminarAtributo(this.claveAtributos).subscribe((response: SimpleResponse) => {
      if (response.success) {
        this.dialogRef.close();
        this.attributeService.metodoObtenerAtributo();
        let mensaje: string = this.traductorService.translate('guardadoCorrecto');
        this.snackService.mostrarSnackBack(mensaje);
      } else {
        let mensaje: string = this.traductorService.translate(response.message);
        this.snackService.mostrarSnackBack(mensaje);
      }
    });
  }

}
