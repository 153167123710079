export class CargoDTO {
    /**
     * Identificador unico del usuario al que se le agrega el cargo
     */
    usuarioId: number;

    /**
     * Tipo de cargo que se le agregara al usuario
     */
    tipoCargoId: number;

    /**
     * Total de cargo
     */
    monto: number;

    /**
     * Descripcion del cargo
     */
    descripcion: string;

    /**
     * Fecha de vencimiento del pago 
     */
    fechaVencimiento: Date;

    /**
     * Identificador del alumno
     */
    alumnoId: number;

    /**
     * Periodo al que pertenece el usuario 
     */
    periodoId: number;

    /**
     * Forma en la que se pagara el cargo
     */
    formaCargo: boolean;

    /**
     * Define el numero de parcialidades que tendra para hacer el pago
     */
    parcialidades: number;

    /**
     * Periodo 
     */
    periodoSeleccionadoInscripcion: number;
}