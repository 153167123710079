import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-warning-alert',
  templateUrl: './warning-alert.component.html',
  styleUrls: ['./warning-alert.component.scss']
})
export class WarningAlertComponent{

  constructor() {}
  //#region Inputs
  @Input()
  public visible: boolean = true;
  @Input()
  public mensaje: string;
  @Input()
  public color: string;
  //#endregion

  //#region Métodos
  public cerrar(): void {
    this.visible = false;
  }
  //#endregion
}
