export enum EstatusUsuarioEnum {
    prospecto = 1,
    candidato = 2,
    admitido = 3,
    graduado = 4,
    baja = 5,
    suspedido = 6,
    cancelado = 7,
    alumno = 8, 
    noAdmitido = 9,
    candidatoEgreso = 10,
    egresado = 11,
    titulado = 12
}