import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { BlockService } from '@app/_services/block.service';
import { I18NextPipe } from 'angular-i18next';
import { Block } from '../../../../_models/block';
import { EdicionBloqueAlumnoComponent } from './edicion-bloque-alumno/edicion-bloque-alumno.component';
import { EdicionBloqueGrupoComponent } from './edicion-bloque-grupo/edicion-bloque-grupo.component';

@Component({
  selector: 'app-edicion-bloque',
  templateUrl: './edicion-bloque.component.html',
  styleUrls: ['./edicion-bloque.component.scss']
})
export class EdicionBloqueComponent implements OnInit {
  @ViewChild(EdicionBloqueGrupoComponent) edicionBloqueGrupo: EdicionBloqueGrupoComponent;
  @ViewChild(EdicionBloqueAlumnoComponent) edicionBloqueAlumno: EdicionBloqueAlumnoComponent;

  @Input() data: Block;
  public Block: Block;
  @Input() esNuevoBloque: boolean;
  public tabSeleccionadoIndex: FormControl = new FormControl(0);

  constructor(private i18nextPipe: I18NextPipe,public blockService: BlockService) { }

  ngOnInit(): void {

    if (this.blockService.invMetodoObtenerBloqueSubscription === undefined) {
      this.blockService.invMetodoObtenerBloqueSubscription = this.blockService.invocarObtenerBloque.subscribe(() => {
        this.GetBlockById();
      });
      this.blockService.invMetodoObtenerBloqueSubscription = undefined;
    }

    setTimeout(() => {
      this.tabSeleccionadoIndex.setValue(0);
    }, 500);
  }

  public GetBlockById() {
    this.blockService.GetBlockById(this.data.blockId).subscribe(
      (response: ApiResponse<Block>) => {
        if (response.success) {
          this.Block= response.data;
          this.data = this.Block;
        }
      })
  }
  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }


}
