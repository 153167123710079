import { Component, OnInit } from '@angular/core';
import { LeadImportService } from 'src/app/_services/lead-import.service';

@Component({
  selector: 'app-leads-import-modal-result',
  templateUrl: './leads-import-modal-result.component.html',
  styleUrls: ['./leads-import-modal-result.component.scss']
})
export class LeadsImportModalResultComponent implements OnInit {
  public infoCargado: boolean = false;
  constructor(private leadImportService: LeadImportService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.informacionCargada();
  }

  public informacionCargada() {
    this.leadImportService.obtenerDatoCargado().subscribe((respuesta: boolean) => {
      this.infoCargado = respuesta;
    });
  }

  public finalizar() {
    
  }
}
