import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImprimirServiceService {

  constructor() { 
  }
  css: string = "@page {size: landscape} .fw-bold{ font-weight: bold;} table{border-spacing:1;border-collapse:collapse;background:#fff;border-radius:6px;overflow:hidden;max-width:800px;width:100%;margin:0 auto;position:relative}table *{position:relative}table td,table th{padding-left:2px}table thead tr{height:20px;background:#43b9fe;font-size:12px}table tbody tr{height:20px;border-bottom:1px solid #e3f1d5}table tbody tr:last-child{border:0}table td,table th{text-align:left}table td.l,table th.l{text-align:right}table td.c,table th.c{text-align:center}table td.r,table th.r{text-align:center}@media screen and (max-width:34em){table{display:block}table td,table th,table tr,table>*{display:block}table thead{display:none}table tbody tr{height:auto;padding:2px 0}table tbody tr td{padding-left:40%;margin-bottom:2px}table tbody tr td:last-child{margin-bottom:0}table tbody tr td:before{position:absolute;font-weight:700;width:40%;left:10px;top:0}}body{font:400 14px Calibri,Arial;padding:4px}blockquote{color:#fff;text-align:center} .corto,.largo{text-align:start;overflow-wrap:anywhere}.corto{width:13%}.largo{width:33%}.encabezado{font-size:12px;color:#757575}.contenido-tabla{font-size:12px}.corto.alig-cred-per-cal{text-align:right;width:13%}" ;    
  /**
   * Abre una ventana del navegador para imprimir un documento
   * @param HTMLElement 
   * @param nombreArchivo 
   */
  public Imprimir(printContent:HTMLElement, nombreArchivo: string, encabezadoContent ? : string): void {
    if (!encabezadoContent){
      encabezadoContent = '';
    }
    const cuerpoDocumento:string = printContent.innerHTML;
    const content: string = `<!DOCTYPE html>
    <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${nombreArchivo}</title>
            <style type="text/css" media="print">
                ${this.css}
            </style>
        </head>
        <body>
        <div class="mx-5">
          ${encabezadoContent}
          ${cuerpoDocumento}
        </div>
        </body>
    </html>`;
    //window.open('your_url_here', 'Page Title Here', 'rel="noopener"');
    const WindowPrt:Window = window.open('', 'Imprimir', '_blank, rel="noopener"');
    WindowPrt.document.write(content);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
}
