import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service'
import { MatPaginator } from '@angular/material/paginator';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { AlumnoGrupoParcial } from 'src/app/_models/ensenanzaAprendizajeAlumno';

@Component({
  selector: 'app-calificaciones',
  templateUrl: './calificaciones.component.html',
  styleUrls: ['./calificaciones.component.scss']
})
export class CalificacionesComponent implements OnInit {
  @Input() grupo: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  grupoSelect;
  displayedColumns: string[] = ['parcial', 'calificacion', 'faltas', 'makeUpExamReason'];
  public dataSource: MatTableDataSource<AlumnoGrupoParcial>;
  constructor(
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,

  ) { }

  ngOnInit(): void {
    if (this.grupo) {
      this.grupoSelect = this.grupo;
    }
    this.cargarCalificaciones();
  }
  public cargarCalificaciones() {
    var idAlumno = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;
    this.EnsenanzaAprendizajeAlumnoService.obtenerCalificaciones(idAlumno, this.grupoSelect.grupoId).subscribe(
      (cal: ApiResponse<AlumnoGrupoParcial[]>) => {
        this.dataSource = new MatTableDataSource(cal.data);
        this.dataSource.paginator = this.paginator;
      })
  }

}
