import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';

const initDataKtAppSidebarMinimizeMobile: string = 'on';
const initDataKtAppSidebarMinimizeDesk: string = 'on';
// const initNavSideToggleFlag: boolean = true;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public isMobile: Observable<boolean>;

  public isToggledMenu: BehaviorSubject<boolean>;
  public ToggleMenuValue: Observable<boolean>;

  private $dataKtAppSidebarMinimizeMobile = new BehaviorSubject<string>(initDataKtAppSidebarMinimizeMobile);
  private $dataKtAppSidebarMinimizeDesk = new BehaviorSubject<string>(initDataKtAppSidebarMinimizeDesk);
  public navSideToggleFlag: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService,
  ) {
    this.isToggledMenu = new BehaviorSubject<boolean>(false);
    this.ToggleMenuValue = this.isToggledMenu.asObservable();
    this.isMobile = new Observable<boolean>(observer => {
      this.breakpointObserver.observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait
      ]).subscribe(result => {
        observer.next(result.matches);
      });
    });
    //this.broadcastService.
    this.isToggledMenu.next(this.authenticationService.isAuthenticated);
  }

  get selectedDataKtAppSidebarMinimizeMobile$():Observable<string>{
    return this.$dataKtAppSidebarMinimizeMobile.asObservable();
  }
  get selectedDataKtAppSidebarMinimizeDesk$():Observable<string>{
    return this.$dataKtAppSidebarMinimizeDesk.asObservable();
  }
  setDataKtAppSidebarMinimizeMobile(dataKtAppSidebarMinimizeMobile: string): void {
    this.$dataKtAppSidebarMinimizeMobile.next(dataKtAppSidebarMinimizeMobile);
  }
  setDataKtAppSidebarMinimizeDesk(dataKtAppSidebarMinimizeDesk: string): void {
    this.$dataKtAppSidebarMinimizeDesk.next(dataKtAppSidebarMinimizeDesk);
  }
  get selectedNavSideToggleFlag():boolean {
    return this.navSideToggleFlag;
  }
  setNavSideToggleFlag(navSideToggleFlag: boolean) {
    this.navSideToggleFlag = navSideToggleFlag;
  }

  }
