import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { Estados } from 'src/app/_models/estados.enum';
import { DatosFamilia, DatosPersonaRelacionada } from 'src/app/_models/usuario';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { AccionEnum } from '../../../_models/accion.enum';
import { EntidadFederativa } from '../../../_models/entidad-federativa';
import { Pais } from '../../../_models/pais';
import { TipoDireccion } from '../../../_models/tipo-direccion';
import { TipoTelefono } from '../../../_models/tipo-telefono';
import { DireccionService } from '../../../_services/direccion.service';
import { SeguimientoCarteraService } from '../../../_services/seguimiento-cartera.service';
import { UtileriasService } from '../../../_services/utilerias.service';

@Component({
  selector: 'app-datos-personales-cartera',
  templateUrl: './datos-personales-cartera.component.html',
  styleUrls: ['./datos-personales-cartera.component.scss']
})
export class DatosPersonalesCarteraComponent implements OnInit, OnDestroy {

  //DATOS INICIALES
  public subscriptionDatosSeguimiento: Subscription;
  public datosSeguimientoUsuario$: Observable<any>;
  public datosSeguimientoUsuario: UsuarioIngreso;
  //CATALOGOS
  public subscriptionTipoDireccion: Subscription;
  public tipoDireccionList: TipoDireccion[] = [];
  public subscriptionPais: Subscription;
  public paisList: Pais[] = [];
  public subscriptionEstado: Subscription;
  public estadoList: EntidadFederativa[] = [];
  public subscriptionTipoTelefono: Subscription;
  public tipoTelefonoList: TipoTelefono[] = [];
  //VARIABLES FORM VALIDACION
  public tipoAccionDireccion: number;
  public tipoAccionTelefono: number;
  public tipoAccionFamiliarPapa: DatosPersonaRelacionada;
  public tipoAccionFamiliarMama: DatosPersonaRelacionada;
  public tipoAccionFamiliarTutor: DatosPersonaRelacionada;
  public tipoAccionDatoFiscal: number;
  //VARIABLES FORM
  public datosPersonalesForm: FormGroup;
  public datosContactoForm: FormGroup;
  public datosFamiliaresForm: FormGroup;
  public datosFacturacionForm: FormGroup;
  //BANDERA RENDERIZAR HTML
  public renderizarForm: boolean = false;
  //BANDERA CAMBIOS FORM
  public datosContactoFormCambio: boolean = false;
  public datosFacturacionFormCambio: boolean = false;

  constructor(private seguimientoService: SeguimientoCarteraService,
    private direccionService: DireccionService,
    private utileriasService: UtileriasService
  ) { }

  /**
   * Se inicia el componente, se obtienen los datos del usuario actual, se cargan los select 
   */
  ngOnInit(): void {
    this.datosSeguimientoUsuario$ = this.seguimientoService.getDatosSeguimientoUsuario$();
    this.subscriptionDatosSeguimiento = this.datosSeguimientoUsuario$.subscribe((datosSeguimiento: UsuarioIngreso) => this.datosSeguimientoUsuario = datosSeguimiento);
    this.getTipoDireccion();
    this.getPais();
    this.getTipoTelefono();
  }

  /**
   * Obtiene el catalogo tipo de direccion y lo agrega al select
   */
  public getTipoDireccion(): void {
    this.subscriptionTipoDireccion = this.direccionService.obtenerTipoDireccion().subscribe(
      (tipoDireccion: TipoDireccion[]) => {
        this.tipoDireccionList = tipoDireccion;
      });
  }

  /**
   * Obtiene el catalogo Pais y lo agrega al select
   */
  public getPais(): void {
    this.subscriptionPais = this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisList = pais;
      });
  }

  /**
   * Segun el pais seleccionado del formControl Pais, se muestran las entidades federativas que corresponden a ese pais
   * @param pais 
   */
  public onPaisSelected(pais: number): void {
    this.estadoList = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        (estados: EntidadFederativa[]) => {
          this.estadoList = estados;
        })
    }
  }

  /**
   * Obtiene el catalogo Tipo de Telefono y lo agrega al select
   */
  public getTipoTelefono() {
    this.subscriptionTipoTelefono = this.direccionService.obtenerTipoTelefono().subscribe(
      (tipoTelefono: TipoTelefono[]) => {
        this.tipoTelefonoList = tipoTelefono;
      });
  }

  /**
   * Se renderiza el formulario, mediante el tipo de accion, si la Accion es Editar: se cargan los datos a las variables tipoAccion[], si la Accion es Crear: se dejan en blanco
   * Se busca en la direccion, el telefono, la persona realcionada y el Dato fiscal para conocer si hay registros
   */
  public renderizarFormulario() {
    if (this.datosSeguimientoUsuario.direccion.length >= 1) {
      this.tipoAccionDireccion = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDireccion = AccionEnum.CREAR;
    }
    if (this.datosSeguimientoUsuario.telefono.length >= 1) {
      this.tipoAccionTelefono = AccionEnum.EDITAR;
    } else {
      this.tipoAccionTelefono = AccionEnum.CREAR;
    }
    if (this.datosSeguimientoUsuario.personaRelacionadaUsuario.length >= 1) {
      this.tipoAccionFamiliarPapa = this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1) === undefined ? null : this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1);
      this.tipoAccionFamiliarMama = this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2) === undefined ? null : this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2);
      this.tipoAccionFamiliarTutor = this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3) === undefined ? null : this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3);
    } else {
      this.tipoAccionFamiliarPapa = null;
      this.tipoAccionFamiliarMama = null;
      this.tipoAccionFamiliarTutor = null;
    }
    if (this.datosSeguimientoUsuario.datoFiscal != null) {
      this.tipoAccionDatoFiscal = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDatoFiscal = AccionEnum.CREAR;
    }
    this.inicializarForm();
  }

  /**
   * Se cargan los formularios con los datos que pertenecen a dicho control y se validan los cambios que se hacen en el formulario
   * a fin de mandar un mensaje, si hay cambios pendientes
   */
  public inicializarForm(): void {
    this.datosPersonalesForm = new FormGroup({
      //DATOS PERSONALES
      usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
      nombre: new FormControl(this.datosSeguimientoUsuario.nombre),
      segundoNombre: new FormControl(this.datosSeguimientoUsuario.segundoNombre),
      apellidoPaterno: new FormControl(this.datosSeguimientoUsuario.apellidoPaterno),
      apellidoMaterno: new FormControl(this.datosSeguimientoUsuario.apellidoMaterno),
      fechaNacimiento: new FormControl(this.utileriasService.formatearFecha(this.datosSeguimientoUsuario.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD')),
    });
    this.datosContactoForm = new FormGroup({
      //DATOS CONTACTO
      usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
      tipoDireccionId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.direccion[0].tipoDireccionId, [Validators.required, this.validateId]),
      paisId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : 484, [Validators.required, this.validateId]),
      entidadFederativaId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.direccion[0].entidadFederativaId, [Validators.required, this.validateId]),
      calle: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].calle, [Validators.required]),
      ciudad: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].ciudad, [Validators.required]),
      numeroExterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].numeroExterior, [Validators.required]),
      numeroInterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].numeroInterior),
      colonia: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].colonia, [Validators.required]),
      codigoPostal: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].codigoPostal, [Validators.required]),
      tipoTelefonoId: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.telefono[0].tipoTelefonoId, [Validators.required, this.validateId]),
      numero: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.telefono[0].numero, [Validators.required]),
      extension: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.telefono[0].extension, [Validators.required]),
      correo: new FormControl(this.datosSeguimientoUsuario.correo, [Validators.required, Validators.email]),
    });
    this.datosFamiliaresForm = new FormGroup({
      //DATOS FAMILIARES
      usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
      nombreCompletoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.nombreCompleto),
      telefonoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.telefono),
      correoElectronicoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.correoElectronico),
      nombreCompletoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.nombreCompleto),
      telefonoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.telefono),
      correoElectronicoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.correoElectronico),
      nombreCompletoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.nombreCompleto),
      telefonoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.telefono),
      correoElectronicoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.correoElectronico),
    });
    this.datosFacturacionForm = new FormGroup({
      //DATOS FACTURACION
      usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
      rfc: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.rfc),
      razonSocial: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.razonSocial),
      tipoDireccionFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.datoFiscal.tipoDireccionId),
      paisFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : 484),
      entidadFederativaFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.datoFiscal.entidadFederativaId),
      calleFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.calle),
      ciudadFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.ciudad),
      numeroExteriorFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.numeroExterior),
      numeroInteriorFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.numeroInterior),
      coloniaFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.colonia),
      codigoPostalFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.codigoPostal),
    });
    this.renderizarForm = true;
    this.datosContactoForm.valueChanges.subscribe(() => {
      this.datosContactoFormCambio = true;
    });
    this.datosFacturacionForm.valueChanges.subscribe(() => {
      this.datosFacturacionFormCambio = true;
    });
    this.onPaisSelected(484);
  }

  /**
   * Obtiene los valores del formulario para su validacion posterior
   */
  //DATOS CONTACTO
  get tipoDireccionId() { return this.datosContactoForm.get('tipoDireccionId'); }
  get paisId() { return this.datosContactoForm.get('paisId'); }
  get entidadFederativaId() { return this.datosContactoForm.get('entidadFederativaId'); }
  get tipoTelefonoId() { return this.datosContactoForm.get('tipoTelefonoId'); }
  get ciudad() { return this.datosContactoForm.get('ciudad'); }
  get calle() { return this.datosContactoForm.get('calle'); }
  get numeroExterior() { return this.datosContactoForm.get('numeroExterior'); }
  get numeroInterior() { return this.datosContactoForm.get('numeroInterior'); }
  get colonia() { return this.datosContactoForm.get('colonia'); }
  get codigoPostal() { return this.datosContactoForm.get('codigoPostal'); }
  get numero() { return this.datosContactoForm.get('numero'); }
  get extension() { return this.datosContactoForm.get('extension'); }
  get correo() { return this.datosContactoForm.get('correo'); }
  //DATOS FGACTURACION
  get tipoDireccionFacturacionId() { return this.datosPersonalesForm.get('tipoDireccionFacturacionId'); }
  get paisFacturacionId() { return this.datosPersonalesForm.get('paisFacturacionId'); }
  get entidadFederativaFacturacionId() { return this.datosPersonalesForm.get('entidadFederativaFacturacionId'); }
  get ciudadFacturacion() { return this.datosPersonalesForm.get('ciudadFacturacion'); }
  get calleFacturacion() { return this.datosPersonalesForm.get('calleFacturacion'); }
  get numeroExteriorFacturacion() { return this.datosPersonalesForm.get('numeroExteriorFacturacion'); }
  get numeroInteriorFacturacion() { return this.datosPersonalesForm.get('numeroInteriorFacturacion'); }
  get coloniaFacturacion() { return this.datosPersonalesForm.get('coloniaFacturacion'); }
  get codigoPostalFacturacion() { return this.datosPersonalesForm.get('codigoPostalFacturacion'); }

    /**
   * Valida si el select esta con el valor 0 y mostrar un mensaje de error si lo muestra
   * @param control 
   * @returns 
   */
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Validacion que busca si un FormControl no tiene datos, fue tocado o tiene un error con el dato que espera
   * @param controlName 
   * @param formName 
   * @returns Un mensaje de advertencia
   */
  public getErrorForm(controlName: string, formName: FormGroup): string {
    let error = '';
    const control = formName.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido.";
    }
    return error;
  }

  /**
   * Se manda a llamar cuando se cierra el componente y se desuscribe de todos los select elegidos
   */
  ngOnDestroy(): void {
    if (this.subscriptionDatosSeguimiento) {
      this.subscriptionDatosSeguimiento.unsubscribe();
    }
    if (this.subscriptionTipoDireccion) {
      this.subscriptionTipoDireccion.unsubscribe();
    }
    if (this.subscriptionPais) {
      this.subscriptionPais.unsubscribe();
    }
    if (this.subscriptionEstado) {
      this.subscriptionEstado.unsubscribe();
    }
    if (this.subscriptionTipoTelefono) {
      this.subscriptionTipoTelefono.unsubscribe();
    }
  }

}
