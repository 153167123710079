import { PagoZumaModalComponent } from './../../_components/pago-zuma-modal/pago-zuma-modal.component';
import { UtileriasService } from './../../_services/utilerias.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PagoZuma } from '../../_models/Zuma/LinkPago/PagoZuma';
import { AuthenticationService } from '../../_services/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { baseUrl, environment } from 'src/environments/environment';

@Component({
  selector: 'app-compra-curso-ficha-curso',
  templateUrl: './compra-curso-ficha-curso.component.html',
  styleUrls: ['./compra-curso-ficha-curso.component.scss']
})
export class CompraCursoFichaCursoComponent implements OnInit {

  //Modal iniciar sesion
  @ViewChild('modalInicioSesion') modalInicioSesion: TemplateRef<any>;
  public modalInicioSesionRef: any;
  public isAuthenticated: boolean = false;


  constructor(private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private utileriasService: UtileriasService,
    private msalService: MsalService,) { }

  ngOnInit(): void {
    this.isAuthenticated = this.authenticationService.isAuthenticated;
  }

  public validarInicioSesion(): void {
    if (this.isAuthenticated) {
      this.enviarPagoZuma();
    } else {
      this.modalInicioSesionRef = this.dialog.open(this.modalInicioSesion, { disableClose: true });
    }
  }

  public enviarPagoZuma(): void {
    let pagoZuma: PagoZuma = {
      reference: "Pago de Curso",
      amount: 1300,
      promotion: "1",
      productCode: "AWPE_RPVT_XUG",
      expire: 1,
      sendLink: false,
      email: "",
      paymentChannel: "ZUMA_API"
    }
    this.utileriasService.abrirModalPagoZuma(PagoZumaModalComponent, pagoZuma);
  }

  /**
   * Metodo para inciar sesion
   */
  public iniciarSesion(): void {
    this.msalService.loginRedirect({
      scopes: [
        environment.enves[baseUrl].AzureAD.resources.link4CampusApi.resourceScope,
      ]
    });
  }

  public cerrarModalInicioSesion(): void {
    this.modalInicioSesionRef.close();
  }
}
