import { TraductorService } from './../../../../_services/traductor.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SeleccionDeMateriasDTO } from 'src/app/models/Inscripcion/SeleccionDeMateriasDTO.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { Campus } from 'src/app/_models/campus';
import { Periodo } from 'src/app/_models/periodo';
import { Programa } from 'src/app/_models/programa';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { InscripcionService } from 'src/app/_services/inscripcion.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { FormatoProgramaService } from 'src/app/_services/formato-programa.service';
import { IdiomaProgramaService } from 'src/app/_services/idioma-programa.service';
import { baseUrl, environment } from 'src/environments/environment';
import { CampusProgramaDTO } from 'src/app/_models/CampusProgramaDTO';
import { FormatoProgramaDto } from '@app/_models/FormatoProgramaDto';
import { IdiomaProgramaDto } from '@app/_models/IdiomaProgramaDto';

@Component({
  selector: 'app-datos-admision',
  templateUrl: './datos-admision.component.html',
  styleUrls: ['./datos-admision.component.scss']
})
export class DatosAdmisionComponent implements OnInit, OnDestroy {

  //Variable renderizar componente
  public datosAdmisionRenderizar: boolean = false;
  //Subscripciones
  private Subscription: Subscription = new Subscription();
  //Variables Catalogos
  public campusList: CampusProgramaDTO[] = [];
  public periodoList: Periodo[] = [];
  public programaList: Programa[] = [];
  public formatoProgramaList: FormatoProgramaDto[] = [];
  public idiomaProgramaList: IdiomaProgramaDto[] = [];
  //Variables Formulario
  public datosAdmisionForm: FormGroup;
  //Variables Obs
  public infoUsuario$: Observable<any>;
  public infoUsuario: any = null;
  //Variable cambio formulario
  public cambioFormDatosAdmision: boolean = false;
  //Variable tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;
  //Variable para validar cambio en datos admisión
  public NoPermitirCambioAdmision: boolean = false;

  constructor(
    private admisionesService: AdmisionesService,
    public nuevoIngresoService: NuevoIngresoService,
    public inscripcion: InscripcionService,
    private snackService: SnackService,
    private traductorService: TraductorService,
    private formatoProgramaService: FormatoProgramaService,
    private idiomaProgramaService: IdiomaProgramaService,
  ) { }

  ngOnInit(): void {
    this.ObtenerCampusByUrl();
    this.obtenerFormatoPrograma();
    this.obtenerIdiomaPrograma();
  }

  /**
 * Metodo para traer catalogo
*/
  public obtenerFormatoPrograma() {
    this.Subscription.add(
      this.formatoProgramaService.obtenerFormatoPrograma().subscribe((response: ApiResponse<FormatoProgramaDto[]>) => {
        this.formatoProgramaList = response.data;
      })
    );
  }

  /**
* Metodo para traer catalogo
*/
  public obtenerIdiomaPrograma() {
    this.Subscription.add(
      this.idiomaProgramaService.obtenerIdiomaPrograma().subscribe((response: ApiResponse<IdiomaProgramaDto[]>) => {
        this.idiomaProgramaList = response.data;
      })
    );
  }

  /**
   * Metodo para renderizar la vista segun el step selecccionado
   */
  public RenderizarDatosAdmision() {
    this.infoUsuario$ = this.admisionesService.GetInfoUsuario$();
    this.Subscription.add(
      this.infoUsuario$.subscribe(
        (info: any) => {
          this.infoUsuario = info;
          console.log("DATA QUE OCUPO", this.infoUsuario)
          this.datosAdmisionRenderizar = true;
          this.obtenerInformacionUsuario();
          this.InicializarForm();
        })
    );
  }

  /**
   * Obtiene informacion de alumno del usuario
   */
  public obtenerInformacionUsuario(): void {
    this.nuevoIngresoService.candidatoById(this.infoUsuario.alumnoRegistrado[0].alumnoId, this.infoUsuario.usuarioId).subscribe(alumnos => {
      this.cargarMaterias(alumnos.data.alumno[0].programaId, alumnos.data.alumno[0].priodoIngresoNavigation.periodoId, alumnos.data.identificador, alumnos.data.alumno[0].programa.nivelId, alumnos.data.alumno[0].campusId);
    });
  }

  /**
   * Metodo para validar si se puede cambiar los datos de admision
   * @param programaId 
   * @param periodoId 
   * @param identificador 
   * @param nivelId 
   * @param campusId 
   */
  public cargarMaterias(programaId: number, periodoId: number, identificador: string, nivelId: number, campusId: number): void {
    this.inscripcion.ObtenerMateriasNuevoIngreso(programaId, periodoId, identificador, nivelId, campusId).subscribe((materiasDisponiblesApiResponse: ApiResponse<Array<SeleccionDeMateriasDTO>>) => {
      if (materiasDisponiblesApiResponse.success) {
        let materiasInscritas = materiasDisponiblesApiResponse.data.filter(m => m.inscrito === true);
        if (materiasInscritas.length > 0) {
          this.NoPermitirCambioAdmision = true;
        } else {
          this.NoPermitirCambioAdmision = false;
        }
      }
      else {
        let mensaje: string = this.traductorService.translate(materiasDisponiblesApiResponse.message);
        this.snackService.mostrarSnackBack(mensaje);
      }
    });
  }

  /**
   * Metodo que incializa el formulario para hacer el registro de nueva cuenta
   */
  public InicializarForm() {
    this.datosAdmisionForm = new FormGroup({
      campus: new FormControl(this.infoUsuario.alumnoRegistrado[0].campusId, [Validators.required, this.ValidateId]),
      programaAcademico: new FormControl(this.infoUsuario.alumnoRegistrado[0].programaId, [Validators.required, this.ValidateId]),
      periodo: new FormControl(this.infoUsuario.alumnoRegistrado[0].priodoIngreso, [Validators.required, this.ValidateId]),
      formatoPrograma: new FormControl(this.infoUsuario.alumnoRegistrado[0].formatoProgramaId === null ? 0 : this.infoUsuario.alumnoRegistrado[0].formatoProgramaId),
      idiomaPrograma: new FormControl(this.infoUsuario.alumnoRegistrado[0].idiomaProgramaId === null ? 0 : this.infoUsuario.alumnoRegistrado[0].idiomaProgramaId),
      reValidarMaterias: new FormControl(false)
    });
    this.datosAdmisionForm.valueChanges.subscribe(() => {
      this.cambioFormDatosAdmision = true;
    });
    this.datosAdmisionForm.get("campus").valueChanges.subscribe(cambio => {
      this.datosAdmisionForm.patchValue({
        programaAcademico: 0,
        periodo: 0
      });
    });
    this.datosAdmisionForm.get("programaAcademico").valueChanges.subscribe(cambio => {
      this.datosAdmisionForm.patchValue({
        periodo: 0
      });
    });
    this.OnCampusSelected(this.infoUsuario.alumnoRegistrado[0].campusId);
    this.OnProgramaSelected(this.tenantId, this.infoUsuario.alumnoRegistrado[0].programaId, this.infoUsuario.alumnoRegistrado[0].campusId);
  }

  /**
   * Variables para validaciones FormGroup
   */
  get campus() { return this.datosAdmisionForm.get('campus'); }
  get programaAcademico() { return this.datosAdmisionForm.get('programaAcademico'); }
  get periodo() { return this.datosAdmisionForm.get('periodo'); }
  get reValidarMaterias() { return this.datosAdmisionForm.get('reValidarMaterias'); }

  /**
   * Metodo que valida si los select tiene algo seleccionado
   * @param control 
   * @returns 
   */
  private ValidateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Metodo para traer el mensaje de error y mostrarlo en el html
   * @param controlName 
   * @returns 
   */
  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.datosAdmisionForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  /**
   * Metodo para obtener el catalogo de campus
   */
  public ObtenerCampusByUrl() {
    this.Subscription.add(
      this.admisionesService.ObtenerCampusByUrl(this.tenantId).subscribe(
        (campus: CampusProgramaDTO[]) => {
          this.campusList = campus;
        })
    );
  }

  /**
   * Metodo para obtener el catalogo de periodos
   */
  public OnProgramaSelected(tenantId: string, programaId: number, campusId: number) {
    if (this.programaAcademico.value !== 0 && this.campus.value !== 0) {
      this.Subscription.add(
        this.admisionesService.ObtenerPeriodoByUrl(tenantId, programaId, campusId).subscribe(
          (periodo: Periodo[]) => {
            this.periodoList = periodo;
          })
      );
    }
  }

  /**
   * Metodo que se activa cuando el select selecciona un campus diferente
   * @param campus 
   */
  public OnCampusSelected(campus: number) {
    this.programaList = [];
    if (!!campus) {
      this.Subscription.add(
        this.admisionesService.ObtenerProgramasByUrl(this.tenantId, campus).subscribe(
          (programas: Programa[]) => {
            this.programaList = programas;
          })
      );
    }
  }

  /**
  * Metodo que se activa cuando se cierra el componente
  */
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

}
