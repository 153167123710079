import { FiltroAdminAcademicoAlumnoDto } from './../_models/FiltroAdminAcademicoAlumnoDto';
import { Injectable } from '@angular/core';
import { environment, baseUrl } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Correo } from '../_models/correo';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { SeleccionDeMateriasDTO } from '../models/Inscripcion/SeleccionDeMateriasDTO.model';
import { GruposDisponiblesParaMateriaDTO } from '../models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { EstatusAcademico } from '../_models/inscripcion';
@Injectable({
  providedIn: 'root'
})
export class InscripcionService {
  private horarioSeleccionado$ = new Subject<any>();

  constructor(private httpClient: HttpClient) { }

  /**
   * Obtiene todas las configuraciones del catalogo configuracion inscripcion
   * ApiResponse<List<CargaInscripcionDto>>
   */
  public getConfiguraciones(): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/ConfiguracionInscripcion`);
  }

  /**
   * Agrega una nueva configuracion al catalogo
   * @param datosConfiguracion datos del formulario
   * @returns Simple Response: true = guardado, false = error en servidor o datos
   */
  public postConfiguracion(datosConfiguracion): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/ConfiguracionInscripcion`, datosConfiguracion);
  };

  /**
   * Edita una configuracion previamente guardada en el catalogo
   * @param datosConfiguracion  datos del formulario
   * @returns Simple Response: true = guardado, false = error en servidor o datos
   */
  public editarConfiguracion(datosConfiguracion): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/ConfiguracionInscripcion`, datosConfiguracion);
  };

  /**
   * Elimina una configuracion del catalogo
   * @param datosConfiguracion datos de la configuracion
   * @returns Simple Response: true = guardado, false = error en servidor o datos
   */
  public eliminarConfiguracion(datosConfiguracion): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/ConfiguracionInscripcion/Delete`, datosConfiguracion);
  };

  /**
   * Obtiene todos los alumnos que pueden realizar la inscripcion y ya tiene un estatus 7
   * @param tipoAlumno
   * @returns lista de alumnos ApiResponse<List<AlumnoDto>>
   */
  public getInscripciones(tipoAlumno: number, pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/GetAlumnos/${tipoAlumno}/${pagina}/${registrosPagina}`, filtro);
  }

  /**
   * Obtiene todos los alumnos que pueden realizar la inscripcion y ya tiene un estatus 7
   * @param tipoAlumno
   * @returns lista de alumnos ApiResponse<List<AlumnoDto>>
   */
  public getInscripcionesTramite(tipoAlumno: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/GetAlumnosTrammite/${tipoAlumno}`);
  }


  //GetCheckCalificacion
  public checkCalificacionInStudentService(studentId, offertSubjectKeys): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.enves[baseUrl].apiUrl}/candidato/CheckCalificacionInStudent/${studentId}/${offertSubjectKeys}`);
  }

  /**
   * Obtiene una arreglo de alumnos los cuales pasa para convertir en un archivo Excel
   * @param IdAlumnos
   * @returns Archivo de tipo Excel con los datos agregados
   */
  public descargarExcel(filtro: FiltroAdminAcademicoAlumnoDto) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Inscripcion/DownloadExcel`, filtro,
      {
        responseType: "blob"
      });

  }

  /**
   * Obtiene todos los tipos de estatus academicos en el catalogo
   * @returns lista con los estatus academicos ApiResponse<List<EstatusAcademico>>
   */
  public GetEstatusAcademicos(): Observable<ApiResponse<EstatusAcademico[]>> {
    return this.httpClient.get<ApiResponse<EstatusAcademico[]>>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/GetEstatusAcademicos`);
  }

  /**
   * Obtiene los periodos a los que estuvo o esta inscrito el alumno a fin de realizar su inscripcion academica
   * @param alumnoId
   * @returns Regresa una lista con los periodos del alumno
   */
  public GetPeriodos(alumnoId): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/GetListadoPeriodosAlumno/${alumnoId}`);
  }

  /**
   * Envia un correo a los alumnos que seleccione el admin
   * @param correos
   * @returns True = se envio correctamente, false: error en servidor o datos
   */
  public enviarCorreos(correos: Correo): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/EnviarCorreos`, correos);
  }

  /**
   * Obtiene los grupos en los que esta inscrito el alumno y su horario
   * @param alumnoId
   * @returns lista con los horarios ApiResponse<List<AlumnoGrupoDto>>
   */
  public getHorarioGrupos(alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/ObtenerGruposHorario/${alumnoId}`);
  }

  /**
   * Manda al usuario a la pantalla de horario
   * @param datosUsuario
   */
  public definirHorarioSeleccionado(datosUsuario: any) {
    this.horarioSeleccionado$.next(datosUsuario);
  }

  /**
   * Actualiza los horarios conforme vayan seleccionando las materias
   * @returns Observable
   */
  public obtenerHorarioSeleccionado$(): Observable<any> {
    return this.horarioSeleccionado$.asObservable();
  }

  /**
 * Retorna un observable que contiene la respuesta en api. En caso de ser exitosa, la data que viene dentro
 * es una lista de materias programadas disponibles para el periodo indicado con base el plan de estudios del alumno
 * @param idPrograma
 * @param idPeriodo
 * @param identiticador
 * @returns Observable
 */
  public ObtenerMateriasProgramadasParaElPlanDeEstudiosEnPeriodoEspecifico(alumnoId: number, idPrograma: number, idPeriodo: number, identiticador: string): Observable<ApiResponse<Array<SeleccionDeMateriasDTO>>> {
    return this.httpClient.get<ApiResponse<Array<SeleccionDeMateriasDTO>>>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/ObtenerMateriasProgramadasParaElPlanDeEstudiosEnPeriodoEspecifico?alumnoId=${alumnoId}&IdPrograma=${idPrograma}&IdPeriodo=${idPeriodo}&Identiticador=${identiticador}`)
  }
  /**
   * Obtiene lso grupos que ofertan la materia indicada en el periodo especificado
   * @param idMateria
   * @param idPeriodo
   * @param identiticador
   * @param idioma
   * @returns
   */
  public ObtenerGruposDeUnaMateriaEnPeriodoEspecifico(idMateria: number, idPeriodo: number, identiticador: string, idioma: string): Observable<ApiResponse<Array<GruposDisponiblesParaMateriaDTO>>> {
    return this.httpClient.get<ApiResponse<Array<GruposDisponiblesParaMateriaDTO>>>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/ObtenerGruposDeUnaMateriaEnPeriodoEspecifico?IdMateria=${idMateria}&IdPeriodo=${idPeriodo}&Identiticador=${identiticador}&Idioma=${idioma}`);
  }

  /**
   * Retorna un observable que contiene la respuesta en api. En caso de ser exitosa, la data que viene dentro
   * es una lista de materias programadas disponibles para el periodo indicado con base el plan de estudios del alumno
   * @param idPrograma
   * @param idPeriodo
   * @param identiticador
   * @param nivelId
   * @param campusId
   * @returns
   */
  public ObtenerMateriasNuevoIngreso(idPrograma: number, idPeriodo: number, identiticador: string, nivelId: number, campusId: number): Observable<ApiResponse<Array<SeleccionDeMateriasDTO>>> {
    return this.httpClient.get<ApiResponse<Array<SeleccionDeMateriasDTO>>>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/ObtenerMateriasNuevoIngreso?IdPrograma=${idPrograma}&IdPeriodo=${idPeriodo}&Identiticador=${identiticador}&NivelId=${nivelId}&CampusId=${campusId}`)
  }

  /**
   * Descargar un excel de tipo de criterio de inscripcion
   */

  public descargarExcelInscripcion(datos) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/ConfiguracionInscripcion/DescargarExcelCargaInscripcion`, JSON.stringify(datos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }
}
