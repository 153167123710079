import { Component, OnInit, TemplateRef, ViewChild,Inject } from '@angular/core';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Campus } from '../../../_models/campus';
import { Subscription } from 'rxjs';
import { CampusService } from '../../../_services/campus.service';
import {NivelService} from '../../../_services/nivel.service'
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { Nivel } from '../../../_models/nivel';
import {configuracionInscripcion} from '../../../_models/inscripcion'
import { AccionEnum } from '../../../_models/accion.enum';
import {InscripcionService } from '../../../_services/inscripcion.service'
import { InfoProgramaService } from '../../../_services/infoPrograma.service';
import { InfoPrograma } from '../../../_models/infoPrograma';
import { Programa } from 'src/app/_models/programa';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { TraductorService } from '../../../_services/traductor.service';
@Component({
  selector: 'app-configuracion-creacion-edicion',
  templateUrl: './configuracion-creacion-edicion.component.html',
  styleUrls: ['./configuracion-creacion-edicion.component.scss']
})
export class ConfiguracionCreacionEdicionComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public creacionEdicionForm: FormGroup;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public campusList: Campus[] = [];
  public nivelList: Nivel[] = [];
  public subscriptionCampus: Subscription;
  public tipoAccion: number;
  public Programas: InfoPrograma[] = [];
  public configuracionId: number = 0;

  public datosConfiguracion :configuracionInscripcion;
  constructor(private dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfiguracionCreacionEdicionComponent>,
    private campusService: CampusService,
    private snackService: SnackService,
    private nivelService: NivelService,
    public inscripcion: InscripcionService,
    public programaservice: InfoProgramaService,
    public ProgramaService: ProgramaAcademicoService,
    private traductorService: TraductorService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data == undefined) {
      this.tipoAccion = AccionEnum.CREAR;
    } else {
      this.tipoAccion = AccionEnum.EDITAR;

    }}

    get CargaMaxima() { return this.creacionEdicionForm.get('CargaMaxima'); }
    get CargaMinima() { return this.creacionEdicionForm.get('CargaMinima'); }
    get Ciclos() { return this.creacionEdicionForm.get('Ciclos'); }
    get NoParciales() { return this.creacionEdicionForm.get('NoParciales'); }
    get AccumulatedQualification() { return this.creacionEdicionForm.get('AccumulatedQualification'); }
    get Sobrecarga() { return this.creacionEdicionForm.get('Sobrecarga'); }

  ngOnInit(): void {
    this.obtenerCampus();
    this.obtenerProgramas();
    this.obtenerNivel();
    this.inicializarForm();
    this.obtenerProgramasCampus();
  }

  /**
   * Entra a la validacion si hay cambios pendientes para mostrar el mensaje, si las descarta se cierra, sino se queda el componente abierto
   * esperando que se guarden los datos
   */
  public cerrarModalValidacion(): void {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  /**
   * Obtiene los programas que tenga el campus seleccionado
   */
  obtenerProgramasCampus(): void{
    this.ProgramaService.obtenerProgramasCampus(this.creacionEdicionForm.value.CampusId).subscribe((objPrograma: Programa[]) => {
      this.Programas = objPrograma;
    })
  }

  /**
   * Obtiene del programa seleccionado los niveles que estan registrados
   */
  obtenerProgramaNivel(): void{
    this.ProgramaService.obtenerProgramasNivel(this.creacionEdicionForm.value.NivelId).subscribe((objPrograma: Programa[])=> {
      this.Programas = objPrograma;
    })
  }

  /**
   * Obtiene los catalogos de Campus para mostrarlos en el select
   */
  public obtenerCampus(): void {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      }
    );
  }

  /**
   * Obtiene los catalogos de Programas para mostrarlos en el select
   */
  public obtenerProgramas(): void{
    this.programaservice.obtenerInfoPrograma().subscribe(
      (programas: InfoPrograma[]) => {
        this.Programas = programas;
      }
    );
  }

  /**
   * Obtiene los catalogos de Nivel para mostrarlos en el select
   */
  public obtenerNivel(): void {
    this.subscriptionCampus = this.nivelService.getNivel().subscribe(
      (nivel: Nivel[]) => {
        this.nivelList = nivel;
      }
    );
  }

  /**
   * Inicia el formulario, con sus validaciones
   */
  public inicializarForm(): void {
    this.creacionEdicionForm= new FormGroup({
      CargaInscripcionId:new FormControl(this.tipoAccion === AccionEnum.CREAR ? 0: this.data.cargaInscripcionId),
      CampusId:  new FormControl(this.tipoAccion === AccionEnum.CREAR ? 0 : this.data.campusId, [Validators.required, this.validateId]),
      NivelId:new FormControl(this.tipoAccion === AccionEnum.CREAR ? 0 : this.data.nivelId, [Validators.required, this.validateId]),
      Ciclos:new FormControl(this.tipoAccion === AccionEnum.CREAR ? null: this.data.ciclos,[Validators.pattern(/^[+]?([0-9]+(?:[0-9]*)?|\[0-9]+)$/)] ),
      TipoCarga:new FormControl(this.tipoAccion === AccionEnum.CREAR ? null: this.data.tipoCarga, [Validators.required]),
      CargaMinima:new FormControl(this.tipoAccion === AccionEnum.CREAR ? null : this.data.cargaMinima, [Validators.required, Validators.pattern(/^[+]?([0-9]+(?:[0-9]*)?|\[0-9]+)$/)]),
      CargaMaxima: new FormControl(this.tipoAccion === AccionEnum.CREAR ? null : this.data.cargaMaxima, [Validators.required, Validators.pattern(/^[+]?([0-9]+(?:[0-9]*)?|\[0-9]+)$/)]),
      ProgramaId:new FormControl(this.tipoAccion === AccionEnum.CREAR ? 0: this.data.programaId!=null?this.data.programaId:0, ),
      NoParciales:new FormControl(this.tipoAccion === AccionEnum.CREAR ? null: this.data.noParciales,[Validators.pattern(/^[+]?([0-9]+(?:[0-9]*)?|\[0-9]+)$/)] ),
      AccumulatedQualification:new FormControl(this.tipoAccion === AccionEnum.CREAR ? null: this.data.accumulatedQualification ?? false, [Validators.required] ),
      Sobrecarga:new FormControl(this.tipoAccion === AccionEnum.CREAR ? null: this.data.sobrecarga,[Validators.required,Validators.pattern(/^[+]?([0-9]+(?:[0-9]*)?|\[0-9]+)$/)] ),
      ParcialFinal: new FormControl(this.tipoAccion === AccionEnum.CREAR ? null : this.data.parcialFinal == null ? false: this.data.parcialFinal, [Validators.required])
    });
    this.creacionEdicionForm.valueChanges.subscribe((cambios) => {
      this.boolCambio = true;
    });
  }

  /**
   * Validacion que compara las fechas ingresadas, si la fecha inicial es mayor a la fecha final, manda un mensaje de error
   * @param form
   * @param nombreControl1
   * @param nombreControl2
   * @returns
   */
  public validarCargas = function (form: FormGroup, nombreControl1: string, nombreControl2: string) {
    if (form.get(nombreControl1).value !== null && form.get(nombreControl2).value !== null) {
      return form.get(nombreControl1).value <= form.get(nombreControl2).value;
    }
    return true;
  }

  /**
   * Funcion que envia los datos verificando si el tipo de accion es crear o editar
   */
  public enviar(): void {
    this.datosConfiguracion = Object.assign(this.creacionEdicionForm.value);
    if(this.datosConfiguracion.ProgramaId==0){
      this.datosConfiguracion.ProgramaId=null;
    }
    if(this.tipoAccion===AccionEnum.CREAR){
      this.inscripcion.postConfiguracion(this.datosConfiguracion).subscribe((respuestaPost: SimpleResponse)=>{
        if (respuestaPost.success) {
          let mensaje: string = this.traductorService.translate('guardadoCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
          this.inicializarForm();
          this.boolCambio = false;
          this.dialogRef.close();
        } else {
          this.snackService.mostrarSnackBack('Ya existe una configuración con Campus, Nivel y Programa Académico ingresados.');
        }
      });
    }else if(this.tipoAccion===AccionEnum.EDITAR){
      this.inscripcion.editarConfiguracion(this.datosConfiguracion).subscribe((respuestaEditar: SimpleResponse)=>{
        if (respuestaEditar.success) {
          this.boolCambio = false;
          let mensaje: string = this.traductorService.translate('guardadoCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
          this.inicializarForm();
          this.dialogRef.close();
        } else {
          this.snackService.mostrarSnackBack('Ya existe una configuración con Campus, Nivel y Programa Académico ingresados.');
        }
      })
    }
  }

  /**
   * Funcion que valida si los valores de select tienen el valor de 0, de ser asi manda un mensaje de que es requerido el campo
   * @param control
   * @returns
   */
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Funcion que no permite la entrada de letras e y E, simbolos de - y +
   * @param event
   * @returns False = si hay uno de esos simbolos y no los pinta, true = si el input esta libre de esos simbolos
   */
  public soloNumeros(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }


}
