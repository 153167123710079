import { SnackService } from './../services/snack-service.service';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { Component, OnInit } from '@angular/core';

import { UtileriasService } from 'src/app/_services/utilerias.service';
import { InstitucionService } from 'src/app/_services/institucion.service';
import { InstitucionEdicionComponent } from './institucion-edicion/institucion-edicion.component';
import { Institucion } from 'src/app/_models/institucion';
import { AdministracionOpcion } from '@app/_models/AdministracionOpcion';

@Component({
  selector: 'app-institucion',
  templateUrl: './institucion.component.html',
  styleUrls: ['./institucion.component.scss']
})
export class InstitucionComponent implements OnInit {

  public existeInstitucion: boolean = false;
  public institucion: Institucion;

  constructor(
    public util: UtileriasService,
    private institucionService: InstitucionService,
    private snackService: SnackService
  ) {
    this.institucionService.obtenerInstitucion().subscribe(
      institucion => {
        this.institucion = institucion;
        console.log("this.institucion",this.institucion);
        this.existeInstitucion = !!institucion && !!institucion.clave;
        this.institucionService.obtenerOpcionesBoton().subscribe((response: ApiResponse<AdministracionOpcion>) => {
          if (response.success) {
            this.institucion.administracionOpcion = response.data;
          } else {
            this.snackService.mostrarSnackBack(response.message);
          }
        })
      }
    );
  }

  ngOnInit(): void {
  }

  crear() {
    const dialogo = this.util.abrirDialogoLateral(InstitucionEdicionComponent, this.institucion);

    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.institucion = result;
        this.existeInstitucion = !!this.institucion && !!this.institucion.clave;
      }
    });
  }

  editar() {
    const dialogo = this.util.abrirDialogoLateral(InstitucionEdicionComponent, this.institucion);

    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.institucion = result;
      }
    });
  }

}
