import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';

@Component({
  selector: 'app-eliminar-candidato',
  templateUrl: './eliminar-candidato.component.html',
  styleUrls: ['./eliminar-candidato.component.scss']
}) 
export class EliminarCandidatoComponent implements OnInit {
 
  public boolRecibido: boolean = false;
  public boolCambio: boolean = false;
  public usuarioId: number;
  public boolIsArray: boolean = false;
  public claveCandidato: string = '';
  public numAlumnoSeleccionado: number;
  constructor(public dialogRef: MatDialogRef<EliminarCandidatoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    public nuevoIngresoService: NuevoIngresoService) { }

    ngOnInit(): void {
      this.validarDataRecibida();
    }
    public validarDataRecibida() {
      this.boolIsArray = Array.isArray(this.data);
      if (this.boolIsArray) {
        this.numAlumnoSeleccionado = this.data.length;
      } 
    }
 
  public eliminarCandidato() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(c => (c.usuarioId.toString()));
      this.claveCandidato = arrayClaves.toString();
    } else {
      this.claveCandidato = this.data.usuarioId.toString();
    }
    
    this.nuevoIngresoService.DeleteMultipleCandidato(this.claveCandidato).subscribe(
      (respuestaEliminado: SimpleResponse) => {
        if (respuestaEliminado.success) {
          this.nuevoIngresoService.refrescarTabla()
          this.nuevoIngresoService.invocarMetodoObtenerCandidato();
          this.snackService.mostrarSnackBack('Se ha eliminado la información.');
          this.dialogRef.close(this.data);
        } else {
          this.snackService.mostrarSnackBack('Algún profesor no pudo ser eliminado porque pertenece a un grupo.');
        }
      },
      error => {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar el profesor. Por favor, intente de nuevo más tarde.');
      }
    );
  }

  public cerrarModal() {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }
  
}
