import { ConsultaCalParcialDTO } from './../_models/ConsultaCalParcialDTO';
import { Campus } from './../_models/campus';
import { environment, baseUrl } from '@environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SeguimientoAlumnoDTO } from '../_models/SeguimientoAlumnoDTO';
import { PeriodoDto } from '../_models/periodo';
import { ProgramaActivo } from '../_models/programa';
import { FiltroDto } from '../_models/filtroDTO';

@Injectable({
  providedIn: 'root'
})
export class SeguimientoAcademicoAlumnoService {

  constructor(private httpClient: HttpClient) { }


  public ObtenerAlumnos(): Observable<ApiResponse<SeguimientoAlumnoDTO[]>> {
    return this.httpClient.get<ApiResponse<SeguimientoAlumnoDTO[]>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoAlumno/ObtenerAlumnos`);
  }

  public ObtenerPeriodos(): Observable<ApiResponse<PeriodoDto[]>> {
    return this.httpClient.get<ApiResponse<PeriodoDto[]>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoAlumno/ObtenerPeriodos`);
  }

  public ObtenerCampus(periodoId: number): Observable<ApiResponse<Campus[]>> {
    return this.httpClient.get<ApiResponse<Campus[]>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoAlumno/ObtenerCampus?PeriodoId=${periodoId}`);
  }

  public ObtenerProgramas(campusId: number): Observable<ApiResponse<ProgramaActivo[]>> {
    return this.httpClient.get<ApiResponse<ProgramaActivo[]>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoAlumno/ObtenerProgramas?CampusId=${campusId}`);
  }

  public GetCalificacionesSegAcaAlumno(alumnoId: number, grupoId: number): Observable<ApiResponse<ConsultaCalParcialDTO[]>> {
    return this.httpClient.get<ApiResponse<ConsultaCalParcialDTO[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizajeAlumno/GetCalificacionesSegAcaAlumno/${alumnoId}/${grupoId}`);
  }

  public descargarExcel(Alumnos: SeguimientoAlumnoDTO[]) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/SeguimientoAlumno/DownloadExcel`, Alumnos,
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }
}
