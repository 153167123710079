import { MaterialModule } from './../../../modules/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicalInformationFormComponent } from './medical-information-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { I18NextModule } from 'angular-i18next';



@NgModule({
  declarations: [
    MedicalInformationFormComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    I18NextModule.forRoot(),
  ],
  exports:[
    MedicalInformationFormComponent
  ]
})
export class MedicalInformationFormModule { }
