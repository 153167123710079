import { EntidadFederativa } from "./entidad-federativa";

export class Direccion {
  correo?: string;
  direccionId: number;
  usuarioId: number;
  tipoDireccionId: number;
  entidadFederativaId: number;
  ciudad: string;
  calle: string;
  pais?: number;
  numeroExterior: string;
  numeroInterior: string;
  colonia: string;
  codigoPostal: string;
  tipoAccion?: number;
  entidadFederativa?: EntidadFederativa;
}
