import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AccionEnum } from '../_models/accion.enum';
import { Aula } from '../_models/aula';
import { Campus } from '../_models/campus';
import { AulaService } from '../_services/aula.service';
import { CampusService } from '../_services/campus.service';
import { UtileriasService } from '../_services/utilerias.service';
import { AulaEliminarComponent } from './aula-eliminar/aula-eliminar.component';
import { ModalAulaComponent } from './modal-aula/modal-aula.component';

@Component({
  selector: 'app-aula',
  templateUrl: './aula.component.html',
  styleUrls: ['./aula.component.scss']
})
export class AulaComponent implements OnInit {
  displayedColumns: string[] = ['select', 'clave', 'nombre', 'campus', 'edificio', 'estatus'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allCampus') private allCampus:MatOption;
  selection = new SelectionModel<any>(true, []);
  public aula: Aula;
  public numSeleccionado: number;
  public arrOriginal: any[] = [];

  public ArCampus: Campus[] = [];
  public campusF = new FormControl();
  public idnombre = new FormControl();
  public estatusF= new FormControl();
  public arrFiltrado: any[] = [];

  constructor(
    public util: UtileriasService,
    private aulaService: AulaService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    private campusService: CampusService,
  ) { }

  ngOnInit(): void {
    if(this.aulaService.otbenerAulaSubscription == undefined){
      this.aulaService.otbenerAulaSubscription = this.aulaService.invocarObtenerAula.subscribe(()=>{
        this.cargarAula();
      })
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    this.cargarAula();
    this.LlenarFiltros();
    this.LimpiarFiltros();
    console.log(this.arrOriginal);
  }

  cargarAula() {
    this.aulaService.getAula().subscribe(data=>{
      console.log('Datos',data);
        this.arrOriginal = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.selection = new SelectionModel<any>(true, []);
        // this.filtro();
      })
  }
  public LlenarFiltros() {
    this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.ArCampus = campus;
        this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
        this.filtro();
      })
      this.aulaService.getAula().subscribe((aula: any[]) => {
        this.arrOriginal = aula;
        this.filtro();
      })
  }
  public filtro() {
    let nombre:string = this.idnombre.value == null?"":this.idnombre.value;
    let campus: any[] = this.campusF.value == null?[]:this.campusF.value;
    let estatus: any = this.estatusF.value ==null?0:this.estatusF.value;
    let arrFiltrado: any[] = [...this.arrOriginal];
    // let filtroModificado:any = [];
    // filtroModificado = [...this.arrOriginal];
    // this.arrFiltrado = filtroModificado;
    // console.log('Filtros', filtroModificado);
    if (nombre.length > 0 || nombre !== '') {
      arrFiltrado = arrFiltrado.filter(el => {
        return el.nombre.toLowerCase().search(nombre.toLowerCase()) > -1 || el.clave.toString().search(nombre) > -1;
      })
    }
    if (campus.length > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return campus.indexOf(f.campusId) > -1 });
    }
    if (estatus == 0){
      arrFiltrado =arrFiltrado.filter(r=>{ return r.estatus == false || r.estatus == true});
    }
    if (estatus !==0) {
      if(estatus ==1){
        arrFiltrado = arrFiltrado.filter(r => { return r.estatus == true } );
      }else{
        arrFiltrado = arrFiltrado.filter(r => { return r.estatus == false } );
      }
    }
    this.dataSource = new MatTableDataSource(arrFiltrado);
    this.dataSource.paginator = this.paginator;

  }
  tosslePerOneCampus(all){ 
    if (this.allCampus.selected) {  
      this.allCampus.deselect();
      return false;
    }
    if(this.campusF.value.length == this.ArCampus.length){this.allCampus.select();}
    this.filtro();
  }
  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.campusF.patchValue([...this.ArCampus.map(item => item.campusId), 0]);
    } else {
      this.campusF.patchValue([]);
    }
    this.filtro();
   }

   LimpiarFiltros() {
    this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
    this.idnombre.setValue("");
    this.dataSource = new MatTableDataSource(this.arrFiltrado);
    this.dataSource.paginator = this.paginator;
    this.cargarAula();
  }

  openAula(elemento: any = null) {
    this.util.abrirDialogoLateral(ModalAulaComponent, elemento);
}
indice:any;
obtenerPosicion(index:number){
  this.indice = index; 
}

Editar(elemento?){
    let datos: any = {
      salonId: elemento.salonId,
      clave: elemento.clave,
      campusId: elemento.campusId,
      edificioId: elemento.edificioId,
      nombre: elemento.nombre,
      estatus: elemento.estatus,
      fechaCreacion: elemento.fechaCreacion,
      usuarioCreacion: elemento.usuarioCreacion,
      fechaModificacion: elemento.fechaModificacion,
      usuarioModificacion: elemento.usuarioModificacion,
      tipoAccion: AccionEnum.EDITAR
    }
    const dialogo = this.util.abrirDialogoLateral(ModalAulaComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.aula = result;
      }
    });
}

Eliminar(btnDataTable: boolean, elemento?: any): void {
  if (btnDataTable) {
    var datos = elemento;
  }else {
    if (elemento.length === 1){
      var datos = elemento[0];
    } else{
      var datos = elemento;
    }
  }
  const dialogo = this.util.abrirDialogoLateral(AulaEliminarComponent, datos);
  dialogo.afterClosed().subscribe(result => {
    if (!!result) {
      this.aula = result;
    }
  });
}

isAllSelected() {
  this.numSeleccionado = this.selection.selected.length;
  const numRows = this.arrOriginal.length;
  return this.numSeleccionado === numRows;
}

checkboxLabel(row?: any): string {
  if (!row) {
    return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  }
  return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.UsuarioId + 1}`;
}

masterToggle() {
  this.isAllSelected() ? this.selection.clear() :
    this.arrOriginal.forEach(row => this.selection.select(row));

}
ExcelSubscription: Subscription;
public downloadExcel(): void {
  this.ExcelSubscription = this.aulaService.downloadExcelAula(this.arrOriginal).subscribe((res: Blob) => {
    var filename = "Consultar_Aulas.xlsx";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(res, filename);
    } else {
      var a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      var blob = new Blob([res], { type: 'application/xlsx' });
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.click();
    }
    this.ExcelSubscription.unsubscribe();
  });
}

}
