import { Component, OnInit, Inject, TemplateRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Profesor } from '../../../../_models/profesor';
import { BiografiaService } from '../../../../_services/biografia.service';
import { ProfesorService } from '../../../../_services/profesor.service';
import { UsuarioService } from '../../../../_services/usuario.service';

@Component({
  selector: 'app-form-datos-personales',
  templateUrl: './form-datos-personales.component.html',
  styleUrls: ['./form-datos-personales.component.scss']
})
export class FormDatosPersonalesComponent implements OnInit {
  @Input() usuarioId: number;
  @Input() nombreData: string;
  @Input() apPat: string;
  @Input() apMat: string;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>
  public errorActualizaProfesor: string = "";
  public objValidacionCancelar: Profesor = null;
  public dialogRefAdver: any;
  public datosForm: FormGroup;
  public tipoAccion: number;
  public dateBorn: Date;

  constructor(public dialogProfesorRef: MatDialogRef<FormDatosPersonalesComponent>,
    private profesorService: ProfesorService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioService,
    private biografiaService: BiografiaService
  ) { }

  ngOnInit(): void {
    this.inicializarForm();
    if (this.usuarioId) {
          this.biografiaService.obtenerBiografiaByProfesorId(this.usuarioId).subscribe(
            datosUsuarioBiografia => {
              
            });
    }
  }

  public inicializarForm() {
    this.datosForm = new FormGroup({
      id: new FormControl(this.usuarioId),
      nombre: new FormControl(this.nombreData),
      apellidoPaterno: new FormControl(this.apPat),
      apellidoMaterno: new FormControl(this.apMat),
      fechaNacimiento: new FormControl(),
    });
  }

  get id() { return this.datosForm.get('id'); }
  get nombre() { return this.datosForm.get('nombre'); }
  get apellidoPaterno() { return this.datosForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.datosForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.datosForm.get('fechaNacimiento'); }

  public cerrarModalValidacion() {

  }
}
