import { EntidadFederativa } from "./entidad-federativa";

/**
 * Modelo para registrar los datos de biografia del usuario
 */
export class Biografia {
  /**
   * Ciudad de nacimiento del usuario
   */
  ciudadNacimiento: string;

  /**
   * Identificador unico del usuario 
   */
  usuarioId: number;

  /**
   * Genero con el que se identifica el usuario
   */
  generoId: number;

  /**
   * Genero legal del usuario
   */
  legalGeneroId: number;

  /**
   * Fecha de nacimiento del usuario 
   */
  fechaNacimiento: Date;

  /**
   * Pais donde radica el usuario
   */
  pais?: number;

  /**
   * Entidad federativa donde nacio el usuario
   */
  entidadNacimiento: number;

  /**
   * Ciudad de nacimiento del usuario 
   */
  iudadNacimiento: string;

  /**
   * Define si el usuario sigue vivo
   */
  esFinado: boolean;

  /**
   * Ciudadania del usuario
   */
  ciudadaniaPaisId: number;

  /**
   * Define el estado civil del usuario
   */
  estadoCivilId: number;

  /**
   * Clave unica de registro poblacional
   */
  curp: string;

  /**
   * Numero de seguro social
   */
  nss: string;

  /**
   * Grupo sanguineo que el usuario tiene
   */
  grupoSanguineoId: number;

  /**
   * Define las alergias que contiene el usuario
   */
  alergias: string;

  /**
   * Define las enfermedades que contiene el usuario
   */
  enfermedades: string;

  /**
   * Indica el tipo de accion que tendra el registro
   */
  tipoAccion?: number;

  /**
   * Entidad federativa donde nacio el usuario 
   */
  entidadNacimientoNavigation: EntidadFederativa
}
