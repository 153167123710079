import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl, environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IntegracionDto } from '../_models/integracionDto';
import { ApiResponse } from '../models/api/ApiRespose.model';

@Injectable({
    providedIn: 'root'
})
export class IntegracionService {

    constructor(private httpClient: HttpClient) { }

    //Obtiene las integraciones del tipo pago segun la institucion
    public ObtenerDatosIntegracion(): Observable<ApiResponse<IntegracionDto[]>> {
        return this.httpClient.get<ApiResponse<IntegracionDto[]>>(`${environment.enves[baseUrl].apiUrl}/Integracion/ObtenerDatosIntegracion`);
    }
}
