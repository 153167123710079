export enum EstatusAcademicos {
    Propspecto = 1,
    Candidato = 2,
    Admitido = 3,
    Graduado = 4,
    Baja = 5,
    Suspendido = 6,
    Cancelado = 7,
    Alumno = 8,
    NoAdmitido = 9,
    CandidatoEgreso = 10,
    Egresado = 11,
}