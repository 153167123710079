import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { PeriodoService } from '@app/_services/periodo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Periodo } from '@app/_models/periodo';
import { TipoPeriodo } from '@app/_models/tipo-periodo';
import { TipoPeriodoService } from '@app/_services/tipo-periodo.service';
import { UtileriasService } from '@app/_services/utilerias.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-periodo-detalle',
  templateUrl: './periodo-detalle.component.html',
  styleUrls: ['./periodo-detalle.component.scss']
})
export class PeriodoDetalleComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PeriodoDetalleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Periodo) { }

  ngOnInit(): void {
  }

}
