import { InsitucionService } from './_services/insitucion.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, EventPayload, AuthError } from '@azure/msal-browser';
import { Component, Inject, OnDestroy, OnInit, AfterViewInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from "rxjs/Subscription";
import { AuthenticationService } from './_services/authentication.service';
import { NavigationService } from './_services/navigation.service';
import { Usuario } from './_models'
import { InsightsMonitoringServiceService } from './_services/insights-monitoring-service.service';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AdmisionesService } from './_services/admisiones.service';
import { environment, baseUrl } from '@environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  

  //Variables para obtener Obs y saber si se esta en el modulo de admisiones
  public existeModuloAdmision$: Observable<boolean>;
  public existeModuloAdmision: boolean = false;
  //Variables para saber si es educacion continua
  public institucionInfo$: Observable<any>;
  public institucionInfo: any = null;
  public educacionContinua: boolean = false;

  public isAuthenticated: boolean = false;
  public userName: string;
  private readonly _destroying$ = new Subject<void>();
  public tenantId: string = environment.enves[baseUrl].institucion;
  public identificador: string;
  public usuarioRol: any;
  public infoCargada: boolean = false;
  public routerEvents: Subscription = new Subscription();
  public version2 : boolean = false;
  public bodyAttribute: string;
  
  private subscription: Subscription;
  private sidebarSubs: Subscription;
  @ViewChild('sideBarMenuN') sideBarMenuN: ElementRef;
  

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private insightsMonitoringServiceService: InsightsMonitoringServiceService,
    private admisionesService: AdmisionesService,
    private institucionService: InsitucionService,
    private renderer: Renderer2
  ) {
    this.routerEvents = this.router.events.subscribe((event) => { 
      if(event instanceof NavigationEnd){
        if(event.url.includes('v2')){
          this.version2 = true;
          //document.getElementById('href1').remove();
        } else {
          document.getElementById('href1')!.remove();
          document.getElementById('href2')!.remove();
          this.version2 = false;
          
        }
      } else {
        null ;
      }
      
    });

    this.renderer.listen('window', 'click',(e:Event)=>{
        
        if(e.target!==this.sideBarMenuN.nativeElement && !this.navigationService.selectedNavSideToggleFlag){
          
          const ktAppSidebar = document.getElementById('kt_app_sidebar');
          if (ktAppSidebar != null) {
            ktAppSidebar.classList.remove('drawer');
            ktAppSidebar.classList.remove('drawer-start');
            ktAppSidebar.classList.remove('drawer-on');
            this.navigationService.setNavSideToggleFlag(false);
          }         
        } else if (this.navigationService.selectedNavSideToggleFlag) {
          this.navigationService.setNavSideToggleFlag(false);
        }
    });
   
  }
  ngAfterViewInit(): void {
    document.getElementById('preloader1')?.classList.add('ocultarPreloader');
  }

  ngOnInit(): void {
    this.sidebarSubs = this.navigationService.selectedDataKtAppSidebarMinimizeDesk$.subscribe((dataKtAppSidebarMinimizeDesk: string) => this.bodyAttribute = dataKtAppSidebarMinimizeDesk);
    this.subscription = this.broadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    ).subscribe((msg: EventMessage) => {
      console.log("successful login", msg);
      this.navigationService.isToggledMenu.next(true);
      let payload: AuthenticationResult = msg.payload as AuthenticationResult;
      this.msalService.instance.setActiveAccount(payload.account);
      let cuenta = this.msalService.instance.getActiveAccount();
      this.authenticationService.setUser(Object.assign(new Usuario, {
        Identificador: cuenta.localAccountId,
        Correo: cuenta.username,
        Nombre: cuenta.name,
        ApellidoPaterno: "",
        ApellidoMaterno: "",
        Token: payload.idToken
      }));
      this.isAuthenticated = true;
      const identificador = sessionStorage.getItem('identificador');
      this.subscription.add(
        this.authenticationService.obtenerDatosRol(identificador).subscribe(data => {
          this.usuarioRol = data;
          this.infoCargada = true;
        })
      );
    });
    this.isAuthenticated = this.authenticationService.isAuthenticated;

    this.broadcastService.msalSubject$
      .pipe(
        // Optional filtering of events
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          // Do something with the error
          let error: AuthError = result.error as AuthError;
          console.log("Error Logging:", error);
        }
      });

    /**
     * Observable para saber si esta en el modulo de admisiones
     */
    this.existeModuloAdmision$ = this.admisionesService.getModuloAdmisionesAlumno$();
    this.subscription.add(
      this.existeModuloAdmision$.subscribe(
        (existe: boolean) => {
          this.existeModuloAdmision = existe;
        })
    );

    this.subscription.add(
      this.institucionService.obtenerInformacionInstitucion(this.tenantId).subscribe(value => {
        this.institucionInfo = value;
        this.admisionesService.setInstitucion(value);
        if (value.academicManagementModelId == 2) {
          this.educacionContinua = true;
        }
      })
    );

    if (this.isAuthenticated) {
      const identificador = sessionStorage.getItem('identificador');
      this.subscription.add(
        this.authenticationService.obtenerDatosRol(identificador).subscribe(data => {
          this.usuarioRol = data;
          this.infoCargada = true;
        })
      );
    } else {
      this.infoCargada = true;
    }
  }

  ngOnDestroy() {
    //this.broadcastService.msalSubject$.take(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routerEvents) {
      this.routerEvents.unsubscribe();
    }
    this.sidebarSubs.unsubscribe();
  }
}
