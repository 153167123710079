import { AuthorizeRegistrationSubject } from './../../../../clean-architecture/core.domain/models/authorize-registration/authorize-registration.model';
import { DeclineEnrollmentComponent } from './../../../../clean-architecture/UI/common/modals/decline-enrollment/decline-enrollment.component';
import { MatDialog } from '@angular/material/dialog';
import { BlockService } from 'src/app/_services/block.service';
import { Block } from './../../../../_models/block';
import { TraductorService } from './../../../../_services/traductor.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InscripcionService } from 'src/app/_services/inscripcion.service';
import { Periodo } from '../../../../_models/periodo';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelectValidator } from 'src/app/validator/SelectValidator';
import i18next from 'node_modules/i18next';
import { SeleccionDeMateriasDTO } from 'src/app/models/Inscripcion/SeleccionDeMateriasDTO.model';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { GruposDisponiblesParaMateriaDTO } from 'src/app/models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { MatRadioButton } from '@angular/material/radio';
import { ConfirmationDialogServiceService } from 'src/app/services/confirmation-dialog-service.service';
import { ExisteMateriaSeriada } from 'src/app/models/customEntities/existeMateriaSeriada.mode';
import { InscripcionCosto } from 'src/app/_models/inscripcion-costo';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { ValidarCostoInscripcionDto } from 'src/app/_models/validarCostoInscripcionDto';
import { ColegiaturaCosto } from 'src/app/_models/colegiatura-costo';
import { MatTableDataSource } from '@angular/material/table';
import { I18NextPipe } from 'angular-i18next';
import { MatTabChangeEvent } from '@angular/material/tabs';
@Component({
  selector: 'app-seleccion-materias',
  templateUrl: './seleccion-materias.component.html',
  styleUrls: ['./seleccion-materias.component.scss']
})

export class SeleccionMateriasComponent implements OnInit {
 
  @Input() authorizeRegistration: boolean = false;
  @Input() authorizeRegistrationSubjects: AuthorizeRegistrationSubject[];
  
  @Output() formValidity: EventEmitter<any> = new EventEmitter();
  
  constructor(public inscripcion: InscripcionService,
    public nuevoIngresoService: NuevoIngresoService,
    private snackService: SnackService,
    private formBuilder: FormBuilder,
    private confirmacionDialogService: ConfirmationDialogServiceService,
    private admisionesService: AdmisionesService,
    private traductorService: TraductorService,
    public blockService: BlockService,
    private i18nextPipe: I18NextPipe,
    private _dialog: MatDialog) {
    this.generarFormularioVacio();
  }

  //Subscripciones
  private subscription: Subscription = new Subscription();

  public gruposDisponibles = Array<GruposDisponiblesParaMateriaDTO>();
  private gruposDisponiblesEnBaseDeDatos = Array<GruposDisponiblesParaMateriaDTO>();
  public gruposAInscribir = new Array<GruposDisponiblesParaMateriaDTO>();
  public gruposADesuscribir = new Array<GruposDisponiblesParaMateriaDTO>();

  public gruposInscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
  public gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
  private gruposInscritosLocalmenteTemporal = new Array<GruposDisponiblesParaMateriaDTO>();
  private gruposDesuscritoLocalmenteTemporal = new Array<GruposDisponiblesParaMateriaDTO>();

  public materiasDisponibles = new Array<SeleccionDeMateriasDTO>();
  private materiasDisponiblesEnBaseDeDatos = new Array<SeleccionDeMateriasDTO>();
  public materiaDisponibleSeleccionada = new SeleccionDeMateriasDTO();

  public activarTablaDeMateriasDelPeriodo = false;
  public activarTablaDeGruposDeLaMateria = false;

  public bloqueList: Block[] = [];
  public BlockSelected: Block;
  public dataSource: MatTableDataSource<any>;
  public infoCargado: boolean = false;
  public gruposDisponiblesBloque = Array<SeleccionDeMateriasDTO>();
  public bloqueForm: FormGroup;
  displayedColumns: string[] = ['clave', 'materia', 'creditos', 'ciclo', 'inscrita'];
  public tabSeleccionadoIndex: FormControl = new FormControl();
  public tabSeleccionado: number = 0;



  public periodos: Array<Periodo>;
  public periodosForm: FormGroup;

  public alumnoNoCubrioCuentaExistente: boolean;
  public existeSeriacionDeMaterias: boolean;
  private curentLanguaje: string;
  public materiasSeriadas: string;

  public datosUsuarioBusqueda: any;
  private datosUsuarioSubscription: Subscription;
  private cambiosTemporales: boolean;
  private radioButtonCheckStaus: boolean;

  public cantidadDeCreditosAntesDeBaja: number;
  public cantidadDeMateriasAntesDeBaja: number;

  public existeInscripcionCosto: boolean = false;
  public existeCuotaColegiatura: boolean = false;
  /**
   * Desuscribe el select para la inscripcion
   */
  ngOnDestroy(): void {
    if (this.datosUsuarioSubscription != null) {
      this.datosUsuarioSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.curentLanguaje = i18next.language;
    this.datosUsuarioSubscription = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$().subscribe(data => {
      if (data) {
        this.datosUsuarioBusqueda = data[0];

        this.tabSeleccionadoIndex.setValue(1);


        this.validarCuotaInscripcion();
        this.cargarPeriodos(this.datosUsuarioBusqueda);
        this.generarFormulario();
        this.consultarMateriasEnPeriodoSeleccionado();
        this.inicializarBloqueForm();

      }
    });

    this.nuevoIngresoService.getSeriacionSubjet().subscribe((existeSeriacion: ExisteMateriaSeriada) => {
      this.existeSeriacionDeMaterias = existeSeriacion.existeMateriaSeriada;
      this.materiasSeriadas = existeSeriacion.materiasSeriadas;
    });
  }


  public inicializarBloqueForm() {
    this.bloqueForm = new FormGroup({
      nombreBloque: new FormControl(0),
    });
    this.obtenerBloques();
  }

  get nombreBloque() { return this.bloqueForm.get('nombreBloque'); }




  public onChangeTab(event: MatTabChangeEvent) {
    let tab: number = event.index;
    this.tabSeleccionado = tab;
  }



  selected: number;
  public validarExisteAlumnoEnBloque() {
    this.blockService.ValidarExisteAlumnoEnBloque(this.periodoActualFiel.value, this.datosUsuarioBusqueda.campusId, this.datosUsuarioBusqueda.alumnoId).subscribe(response => {
      if (response.success) {
        this.tabSeleccionadoIndex.setValue(1);



        this.bloqueForm.setValue({
          nombreBloque: this.datosUsuarioBusqueda.blockId
        });
        //this.nombreBloque.updateValueAndValidity();


        this.onNombreBloqueSelected(this.datosUsuarioBusqueda.blockId);
      } else {
        this.tabSeleccionadoIndex.setValue(0);
      }
    })
  }

  /**
   * Metodo para validar si existe una cuota de inscricion y determinar si aparece o no el step de inscripcion
   */
  public validarCuotaInscripcion(): void {
    let datauser: ValidarCostoInscripcionDto = {
      campusId: this.datosUsuarioBusqueda.campusId,
      periodoId: this.datosUsuarioBusqueda.priodoIngreso,
      programaId: this.datosUsuarioBusqueda.programaId,
      tipoAlumno: this.datosUsuarioBusqueda.tipoAlumno ? 'RI' : 'NI'
    };
    this.subscription.add(
      this.admisionesService.ValidarExisteCuotaInscripcion(datauser).subscribe(
        (response: ApiResponse<boolean>) => {
          if (response.success) {
            this.existeInscripcionCosto = response.data;
            this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.datosUsuarioBusqueda.campusId, this.datosUsuarioBusqueda.programa.nivelId, this.datosUsuarioBusqueda.priodoIngreso, this.datosUsuarioBusqueda.programaId)
              .subscribe((cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
                if (cuotaColegiaturaRegistrada.success) {
                  if (cuotaColegiaturaRegistrada.data !== null) {
                    this.existeCuotaColegiatura = true;
                  } else {
                    if (cuotaColegiaturaRegistrada.message !== null) {
                      let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
                      this.snackService.mostrarSnackBack(mensaje);
                    }
                  }
                } else {
                  this.snackService.mostrarSnackBack(cuotaColegiaturaRegistrada.message);
                }
              });
          } else {
            this.snackService.mostrarSnackBack(response.message);
          }
        })
    );
  }

  /**
   * Genera un nuevo formulario
   */
  private generarFormularioVacio(): void {
    this.periodosForm = this.formBuilder.group({
      periodoInscrito: [0]
    });
  };

  /**
   * Genera el formularuio reactivo que se utiliza en la vista
   */
  private generarFormulario(): void {
    this.periodosForm = this.formBuilder.group({
      periodoInscrito: [this.datosUsuarioBusqueda.priodoIngresoNavigation.periodoId]
    },
      {
        validators: [SelectValidator('periodoInscrito')]
      }
    );
  }

  /**
   * Obtiene los periodos en el que está inscrito el usuario
   * @param usuario
   */
  public async cargarPeriodos(usuario): Promise<void> {
    this.inscripcion.GetPeriodos(usuario.alumnoId).subscribe((periodos: ApiResponse<any>) => {
      this.periodos = periodos.data;
    });
  }

  /**
   * Con base al periodo indicado se consultan las materias del plan de estudio
   * así como el historial de éstas para saber si el alumno ya cursó la materia o no en el periodo indicado
   */
  public consultarMateriasEnPeriodoSeleccionado(): void {
    this.alumnoNoCubrioCuentaExistente = false;
    this.existeCuotaColegiatura = false;
    if (this.periodoActualFiel.valid) {
      this.nuevoIngresoService.ExisteInscripcionCosto(this.datosUsuarioBusqueda.campusId, this.datosUsuarioBusqueda.programa.nivelId, this.periodoActualFiel.value, this.datosUsuarioBusqueda.programaId, this.datosUsuarioBusqueda.tipoAlumno ? 'RI' : 'NI').subscribe(
        (InscripcionCosto: ApiResponse<InscripcionCosto>) => {
          if (InscripcionCosto.success) {
            if (InscripcionCosto.data !== null) {
              this.existeInscripcionCosto = true;
              this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.datosUsuarioBusqueda.campusId, this.datosUsuarioBusqueda.programa.nivelId, this.periodoActualFiel.value, this.datosUsuarioBusqueda.programaId)
                .subscribe((cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
                  if (cuotaColegiaturaRegistrada.success) {
                    if (cuotaColegiaturaRegistrada.data !== null) {
                      this.existeCuotaColegiatura = true;
                    } else {
                      if (cuotaColegiaturaRegistrada.message !== null) {
                        let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
                        this.snackService.mostrarSnackBack(mensaje);
                      }
                    }
                  } else {
                    this.snackService.mostrarSnackBack(cuotaColegiaturaRegistrada.message);
                  }
                });
              this.nuevoIngresoService.ValidarInscripcionPagada(this.datosUsuarioBusqueda.alumnoId, this.periodoActualFiel.value).subscribe(
                (inscripcionPagada) => {
                  if (!inscripcionPagada) {
                    this.existeInscripcionCosto = true;
                    this.alumnoNoCubrioCuentaExistente = true;
                  }
                });
            } else {
              this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.datosUsuarioBusqueda.campusId, this.datosUsuarioBusqueda.programa.nivelId, this.periodoActualFiel.value, this.datosUsuarioBusqueda.programaId)
                .subscribe((cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
                  if (cuotaColegiaturaRegistrada.success) {
                    if (cuotaColegiaturaRegistrada.data !== null) {
                      this.existeCuotaColegiatura = true;
                    } else {
                      if (cuotaColegiaturaRegistrada.message !== null) {
                        let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
                        this.snackService.mostrarSnackBack(mensaje);
                      }
                    }
                  } else {
                    this.snackService.mostrarSnackBack(cuotaColegiaturaRegistrada.message);
                  }
                });
            }
          } else {
            this.snackService.mostrarSnackBack(InscripcionCosto.message);
          }
        });
    }
    this.obtenerMaterias(this.periodoActualFiel.value);
  }

  /**
   * Obtiene un listado de Materias ofertadas para el plan académico en el periodo indicado
   * @param periodoId
   */
  public obtenerMaterias(periodoId): void {
    if (this.datosUsuarioBusqueda && this.periodoActualFiel.valid) {
      this.inscripcion.ObtenerMateriasProgramadasParaElPlanDeEstudiosEnPeriodoEspecifico(this.datosUsuarioBusqueda.alumnoId, this.datosUsuarioBusqueda.programaId, periodoId, this.datosUsuarioBusqueda.usuario.identificador).subscribe((materiasDisponiblesApiResponse: ApiResponse<Array<SeleccionDeMateriasDTO>>) => {
        if (materiasDisponiblesApiResponse.success) {
          this.materiasDisponibles = materiasDisponiblesApiResponse.data;
          this.materiasDisponiblesEnBaseDeDatos = materiasDisponiblesApiResponse.data.map(m => Object.assign({}, m));
          this.activarTablaDeMateriasDelPeriodo = true;
          this.cantidadDeCreditosAntesDeBaja = this.materiasDisponibles.filter(m => m.inscrito).reduce((a, b) => a + b.creditos, 0);
          this.cantidadDeMateriasAntesDeBaja = this.materiasDisponibles.filter(m => m.inscrito).length;
        }
        else {
          this.snackService.mostrarSnackBack(materiasDisponiblesApiResponse.message);
        }
      });
    }
  }

  /**
   * Busca entre todos los grupos registrados localmente si alguno hace match con
   * la materia seleccionada. Si es así, lo retorna, en caso contrario regresa NULL
   * @param claveMateria
   * @returns Objeto GrupoDisponible si lo encuentra, en caso contrario retorna un Null
   */
  private grupoRegistradoLocalmente(claveMateria: string): GruposDisponiblesParaMateriaDTO {
    return this.gruposInscritosLocalmente.find(g => g.claveMateria == claveMateria);
  }

  /**
   * Busca entre todos los grupos desuscritos localmente si alguno hace match con
   * la materia seleccionada. Si es así, lo retorna, en caso contrario regresa NULL
   * @param claveMateria
   * @returns Objeto GrupoDisponible si lo encuentra, en caso contrario retorna un Null
   */
  private grupoDesuscritoLocalmente(claveMateria: string): GruposDisponiblesParaMateriaDTO {
    return this.gruposDesuscritosLocalmente.find(g => g.claveMateria == claveMateria);
  }

  /**
  * Este método valida si se han inscrito grupos de forma local.
  * Si es así, preselecciona nuevamente el grupo escodigo a la hora de cargar los datos
  * @param claveMateria
  */
  private preSeleccionarGrupo(claveMateria: string): void {
    const grupoEncontradoLocalmente = this.grupoRegistradoLocalmente(claveMateria);
    if (this.gruposInscritosLocalmente.length <= 0 || !grupoEncontradoLocalmente)
      return;
    this.gruposDisponibles.find(g => g.grupo == grupoEncontradoLocalmente.grupo).cursando = true;
  }

  /*
  * Este método valida si se han desuscrito eñ grupos de forma local.
  * Si es así, deselecciona el grupo a la hora de cargar los datos
  * @param claveMateria
  */
  private deseleccionarGrupoLocalmente(claveMateria: string): void {
    const grupoEncontradoLocalmente = this.grupoDesuscritoLocalmente(claveMateria);
    if (this.gruposDesuscritosLocalmente.length <= 0 || !grupoEncontradoLocalmente)
      return;
    this.gruposDisponibles.find(g => g.grupo == grupoEncontradoLocalmente.grupo).cursando = false;
  }

  /**
   * Obtiene un listado de grupos en los que se imparte la materia indicada
   */
  public obtenerGruposParaLaMateria(materia: SeleccionDeMateriasDTO): void {

    this.materiaDisponibleSeleccionada = this.materiasDisponiblesEnBaseDeDatos.find(m => m.clave == materia.clave);;
    this.activarTablaDeGruposDeLaMateria = true;
    this.activarTablaDeMateriasDelPeriodo = false;
    this.inscripcion.ObtenerGruposDeUnaMateriaEnPeriodoEspecifico(this.materiaDisponibleSeleccionada.idMateria, this.periodoActualFiel.value, this.datosUsuarioBusqueda.usuario.identificador, this.curentLanguaje).subscribe((apiGruposReponse: ApiResponse<Array<GruposDisponiblesParaMateriaDTO>>) => {
      if (apiGruposReponse.success) {
        this.gruposDisponibles = apiGruposReponse.data;
        this.gruposDisponiblesEnBaseDeDatos = apiGruposReponse.data.map(g => Object.assign({}, g));
        this.cambiosTemporales = false;
        this.gruposDesuscritoLocalmenteTemporal = this.gruposDesuscritosLocalmente.map(g => Object.assign({}, g));
        this.gruposInscritosLocalmenteTemporal = this.gruposInscritosLocalmente.map(g => Object.assign({}, g));
        this.preSeleccionarGrupo(materia.clave);
        this.deseleccionarGrupoLocalmente(materia.clave);
      }
      else {
        let mensaje: string = this.traductorService.translate(apiGruposReponse.message);
        this.snackService.mostrarSnackBack(mensaje);
      }
    });
  }

  /**
   * Oculta la tabla actual en la que se ven los grupos que impoarten la materia indicada
   * y muestra nuevamente la tabla en la que se muestran las materias programadas para el plan académico
   */
  public regresaraTablaMateriasObtenidas(): void {
    if (this.cambiosTemporales) {
      this.confirmacionDialogService.abrirModalDescartarCambios('Quieres confirmar su cambio de selección', 'Si vuelve a la pantalla anterior sin confirmar sus cambios los perderá.').afterClosed().subscribe((descartarCambios: boolean) => {
        if (descartarCambios) {
          this.gruposDesuscritosLocalmente = this.gruposDesuscritoLocalmenteTemporal.map(g => Object.assign({}, g));
          this.gruposInscritosLocalmente = this.gruposInscritosLocalmenteTemporal.map(g => Object.assign({}, g));
          this.ocultarTablaSeleccionDeGrupos();
        }
      });
    }
    else {
      this.ocultarTablaSeleccionDeGrupos();
    }
  }

  /**
   * Cambia el estado de las variables banderaas para ocultas la tabla en la que se muestran los grupo disponinibles
   */
  public ocultarTablaSeleccionDeGrupos(): void {
    this.activarTablaDeMateriasDelPeriodo = true;
    this.activarTablaDeGruposDeLaMateria = false;
  }

  /**
   * Retorna el grupo que se tiene almacenado en la base de datos
   * como el grupo en el que se cursa dicha materia
   * @returns Un objeto de tipo Grupo
   */
  private obtenerGrupoInscritoEnLaBaseDeDatos(): GruposDisponiblesParaMateriaDTO {
    return this.gruposDisponiblesEnBaseDeDatos.find(g => g.cursando);
  }

  /**
   * Busca entre los grupos Inscritos localmente alguno que haga match con la clave de materia
   * del grupo seleccionado, y si existe, lo elimina.
   * @param claveDeLaMateria
   */
  private eliminarGrupoDeLosGruposInscritosLocalmente(claveDeLaMateria: string): void {
    const indexGrupoAEliminarLocalmente = this.gruposInscritosLocalmente.findIndex(g => g.claveMateria == claveDeLaMateria);
    if (indexGrupoAEliminarLocalmente < 0)
      return;
    this.gruposInscritosLocalmente.splice(indexGrupoAEliminarLocalmente, 1);
  }

  /**
   * Busca entre los grupos que se han desuscrito de forma local con base a la clave materia indicada
   * Si lo encuentra lo elimina
   * @param claveDeLaMateria
   */
  private eliminarGrupoDeLosGruposDesuscritosLocalmente(claveDeLaMateria: string): void {
    const indexGrupoAEliminarLocalmente = this.gruposDesuscritosLocalmente.findIndex(g => g.claveMateria == claveDeLaMateria);
    if (indexGrupoAEliminarLocalmente < 0)
      return;
    this.gruposDesuscritosLocalmente.splice(indexGrupoAEliminarLocalmente, 1);
  }

  /**
   * Agrega al arreglo local el grupo indicado, lo cual indica que dicho grupo se debe
   * desuscribir en el API
   * @param grupo
   */
  private desuscribirGrupoLocalmente(grupo: GruposDisponiblesParaMateriaDTO): void {
    this.gruposDesuscritosLocalmente.push(grupo);
  }

  /**
   * Agrega el grupo recibio como parámetro al arreglo de grupos que están inscritos de forma local
   * @param grupo
   */
  private inscribirGrupoLocalmente(grupo: GruposDisponiblesParaMateriaDTO): void {
    this.gruposInscritosLocalmente.push(grupo);
  }

  /**
   * Actualiza localmente el estado de la suscripción de la materia al estado que se le indica
   */
  private actualizarEstadoDeMateriaEnLaVista(claveMateria: string, estadoInscrito: boolean): void {
    this.materiasDisponibles.find(m => m.clave == claveMateria).inscrito = estadoInscrito;
  }

  /**
   * Si el radio buton está marcado en la vista, el método lo desmarca y guarda en pantalla la nueva selección del usuario
   * @param event
   * @param grupoRadioButton
   */
  public cambiarSeleccionDeGrupo(event: MouseEvent, grupoRadioButton: MatRadioButton, grupo: GruposDisponiblesParaMateriaDTO): void {
    if (!this.existeCuotaColegiatura) return;
    if (this.materiaDisponibleSeleccionada.tieneFalificacionFinal) return;

    if (grupo.cupoDisponible <= 0) {
      event.preventDefault();
      grupoRadioButton.checked = false;
      return;
    }
    this.radioButtonCheckStaus = grupoRadioButton.checked;
    const grupoInscritoEnLaBaseDedatos = this.obtenerGrupoInscritoEnLaBaseDeDatos();
    if (this.materiaDisponibleSeleccionada.inscrito) {
      this.eliminarGrupoDeLosGruposInscritosLocalmente(grupo.claveMateria);
      this.eliminarGrupoDeLosGruposDesuscritosLocalmente(grupo.claveMateria);
      if (grupoRadioButton.checked) {
        this.cambiosTemporales = grupoInscritoEnLaBaseDedatos.grupo == grupo.grupo;
        event.preventDefault();
        grupoRadioButton.checked = false;
        this.desuscribirGrupoLocalmente(grupoInscritoEnLaBaseDedatos);
      }
      else {
        this.cambiosTemporales = grupoInscritoEnLaBaseDedatos.grupo != grupo.grupo;
        if (grupoInscritoEnLaBaseDedatos.grupo != grupo.grupo) {
          this.inscribirGrupoLocalmente(grupo);
          this.desuscribirGrupoLocalmente(grupoInscritoEnLaBaseDedatos);
        }
      }
    }
    else {
      this.cambiosTemporales = !grupoRadioButton.checked;
      this.eliminarGrupoDeLosGruposInscritosLocalmente(grupo.claveMateria);
      if (grupoRadioButton.checked) {
        event.preventDefault();
        grupoRadioButton.checked = false;
      }
      else {
        this.inscribirGrupoLocalmente(grupo);
      }
    }
  }

  /**
   * Ayuda con el rendimiento de la app en los NgFor cuando haya cambios
   * @param index
   * @returns
   */
  public trackByIndex(index: number): number {
    return index;
  }

  /**
   * Método que inscribe localmente las materias y actualiza la vista
   */
  public guardarCambiosTemporalmente() {
    this.actualizarEstadoDeMateriaEnLaVista(this.materiaDisponibleSeleccionada.clave, !this.radioButtonCheckStaus);
    this.nuevoIngresoService.definirFormularioValidoInscripcion(this.cambiosHechos);
    if (this.cambiosTemporales) {
      this.cambiosTemporales = false;
      this.regresaraTablaMateriasObtenidas();
    }
    else {
      this.regresaraTablaMateriasObtenidas();
    }
  }


  public obtenerBloques() {
    this.blockService.obtenerBlocksSeleccionMaterias(this.datosUsuarioBusqueda.campusId, this.periodoActualFiel.value).subscribe(response => {
      if (response.success) {
        this.bloqueList = response.data;
        this.validarExisteAlumnoEnBloque();
      }
    })
  }

  public onNombreBloqueSelected(blockId: number) {
    if (blockId != 0) {

      this.BlockSelected = new Block();
      this.BlockSelected = this.bloqueList.find(x => { return x.blockId == blockId });

      this.blockService.obtenerMateriasBloque(blockId, this.datosUsuarioBusqueda.alumnoId).subscribe(response => {
        if (response.success) {
          this.gruposDisponiblesBloque = response.data;
          this.dataSource = new MatTableDataSource(this.gruposDisponiblesBloque);
          this.infoCargado = true;
        }
      })
    } else {
      this.dataSource = new MatTableDataSource();
    }
  }

  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }


  /**
   * Este método se ejecuta una vez se han guardado los cambios en la base de datos
   * Reiniciando el mecanismo para regresarlos a su estado inicial
   */
  public ResetSeleccionDeMaterias(): void {
    this.gruposInscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
    this.gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
    this.cambiosTemporales = false;
    this.consultarMateriasEnPeriodoSeleccionado();
  }

  get periodoActualFiel() {
    return this.periodosForm.get('periodoInscrito');
  }

  get cambiosHechos(): boolean {
    return this.gruposInscritosLocalmente.length > 0 || this.gruposDesuscritosLocalmente.length > 0;
  }

  get cantidadCambios(): number {
    return this.gruposInscritosLocalmente.length + this.gruposDesuscritosLocalmente.length;
  }

  public get isSoloGruposInscritosPendientes(): boolean {
    return this.gruposInscritosLocalmente.length > 0 && this.gruposDesuscritosLocalmente.length <= 0;
  }

  public get isSoloGruposDesuscritosPendientes(): boolean {
    return this.gruposDesuscritosLocalmente.length > 0 && this.gruposInscritosLocalmente.length <= 0;
  }
  public get isGruposDesuscritosEInscritosPendientes(): boolean {
    return this.gruposInscritosLocalmente.length > 0 && this.gruposDesuscritosLocalmente.length > 0;
  }

  //#region Decline enrollment

  public declineEnrollment(): void {
    const setData = {
      data: [...this.authorizeRegistrationSubjects],
      periodoId: this.periodoActualFiel,
      alumnoId: this.datosUsuarioBusqueda.alumnoId
    }    
    const dialogo = this._dialog.open(DeclineEnrollmentComponent, { data: setData, disableClose: true });
    dialogo.afterClosed().subscribe(result => {});
  }

  public AuthorizeEnrollment():void
  {
    this.formValidity.emit(true);
  }

  //#endregion
}
