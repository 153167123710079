import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AtributosSeguimientoComponent } from './atributos-seguimiento/atributos-seguimiento.component';

const routes: Routes = [
  { path: 'atributos', component: AtributosSeguimientoComponent, canActivate: [MsalGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtributosRoutingModule { }
