import { Component, OnInit } from '@angular/core';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import * as Editor from '../../../../ckeditor/src/ckeditor.js';
@Component({
  selector: 'app-tema-modal',
  templateUrl: './tema-modal.component.html',
  styleUrls: ['./tema-modal.component.scss']
})
export class TemaModalComponent implements OnInit {

  // public Editor = ClassicEditor;
  constructor() { }

  ngOnInit(): void {
  }

}
