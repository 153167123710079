
export class PERSONAL_CONTACTS_INFORMATION_MODEL{
    usuarioId           : number;
    tipoRelacionId      : number;
    nombreCompleto      : string;
    telefono            : string;
    correoElectronico   : string;
    titulo              : string;
    constructor(
        usuarioId           : number,
        tipoRelacionId      : number,
        nombreCompleto      : string,
        telefono            : string,
        correoElectronico   : string,
        titulo              : string
    )
    {
        this.usuarioId          =   usuarioId        ;
        this.tipoRelacionId     =   tipoRelacionId   ;
        this.nombreCompleto     =   nombreCompleto   ;
        this.telefono           =   telefono         ;
        this.correoElectronico  =   correoElectronico;
        this.titulo             =   titulo;
    }
  }