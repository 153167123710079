import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-datos-usuario',
  templateUrl: './datos-usuario.component.html',
  styleUrls: ['./datos-usuario.component.scss']
})
export class DatosUsuarioComponent implements OnInit {


  public datosUsuarioBusqueda$: Observable<UsuarioIngreso>;
  public datosUsuarioBusqueda: UsuarioIngreso;
  public renderizarForm: boolean = false;
  public tipoAccion: number;
  public datosPersonalesForm: FormGroup;

  constructor(public dialogDatosRef: MatDialogRef<DatosUsuarioComponent>,
    public nuevoIngresoService: NuevoIngresoService,
    private utileriasService: UtileriasService,
    private _cd: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
  }

  public inicializarForm() {
    this.datosPersonalesForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      nombre: new FormControl(this.datosUsuarioBusqueda.nombre, [Validators.required]),
      segundoNombre: new FormControl(this.datosUsuarioBusqueda.segundoNombre),
      apellidoPaterno: new FormControl(this.datosUsuarioBusqueda.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(this.datosUsuarioBusqueda.apellidoMaterno, [Validators.required]),
      fechaNacimiento: new FormControl(this.utileriasService.formatearFecha(this.datosUsuarioBusqueda.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD'), [Validators.required]),
      fotografia: new FormControl(this.datosUsuarioBusqueda.fotografia !== null ? this.datosUsuarioBusqueda.fotografia : ''),
      fotografiaFile: new FormControl(this.datosUsuarioBusqueda.fotografia !== null ? this.datosUsuarioBusqueda.fotografia : '')
    });
    this.renderizarForm = true;
    this.datosPersonalesForm.valueChanges.subscribe(val => {
      this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.datosPersonalesForm.valid);
    });
  }

  get id() { return this.datosPersonalesForm.get('id'); }
  get nombre() { return this.datosPersonalesForm.get('nombre'); }
  get segundoNombre() { return this.datosPersonalesForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.datosPersonalesForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.datosPersonalesForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.datosPersonalesForm.get('fechaNacimiento'); }
  get fotografia() { return this.datosPersonalesForm.get('fotografia'); }


  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.datosPersonalesForm.patchValue({
        fotografiaFile: file.name
      });
      reader.onload = () => {
        this.datosUsuarioBusqueda.fotografia = reader.result;
        this._cd.markForCheck();
        this.datosPersonalesForm.patchValue({
          fotografia: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  }

  public renderizarFormulario() {
    this.inicializarForm();
  }
}
