import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '@app/_services/authentication.service';

export const BYPASS_JW_TOKEN = new HttpContextToken(() => false);
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.context.get(BYPASS_JW_TOKEN) === true) {
      console.log("request", request);
      console.log("BYPASS_JW_TOKEN", request.context.get(BYPASS_JW_TOKEN));
      return next.handle(request)
    }

    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.Token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.Token}`,

        }
      });
    }
    return next.handle(request);
  }
}
