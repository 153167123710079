import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { Campus } from 'src/app/_models/campus';
import { Edificio } from 'src/app/_models/edificio';
import { Programa } from 'src/app/_models/programa';
import { CampusService } from 'src/app/_services/campus.service';
import { EdificioService } from 'src/app/_services/edificio.service';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-modal-edificio',
  templateUrl: './modal-edificio.component.html',
  styleUrls: ['./modal-edificio.component.scss']
})
export class ModalEdificioComponent implements OnInit {

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public edicionForm: FormGroup;
  public campusList: Campus[] = [];
  public subscriptionCampus: Subscription;
  public claveRegistrada: boolean = false;
  public arrPrograma: Programa[];
  public edificioId: number = 0;
  public agregar: boolean = false;

  constructor(
   public dialogRef: MatDialogRef<ModalEdificioComponent>,
   @Inject(MAT_DIALOG_DATA) public data: any,
    private campusService: CampusService,
    private programaService: ProgramaAcademicoService,
    private snackBar: MatSnackBar,
    private util: UtileriasService,
    private dialog: MatDialog,
    private edificioService: EdificioService
  ) {  }

  ngOnInit(): void {
    this.llenarCampos();
   this.llenarCampos();
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      clave: new FormControl(this.data?.Clave, [Validators.required]),
      campus: new FormControl(this.data?.CampusId, [Validators.required]),
      nombre: new FormControl(this.data?.Nombre, [Validators.required]),
    });
    this.edicionForm.valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
  }

  get clave() { return this.edicionForm.get('clave'); }
  get campus() { return this.edicionForm.get('campus'); }
  get nombre() { return this.edicionForm.get('nombre'); }

  llenarCampos(){
    if(this.data != null){
      this.agregar = true;
    }
    this.campusService.obtenerCampus().subscribe((camp: Campus[])=>{
      this.campusList = camp;
    })
    if(this.agregar){
      this.programaService.obtenerProgramas().subscribe((objPrograma: Programa[]) => {
        this.arrPrograma = objPrograma;
      })
    }
    this.limpiar();
  }
  limpiar(){
    this.edicionForm = new FormGroup({
      clave: new FormControl(this.data?.Clave, [Validators.required]),
      campus: new FormControl(this.data?.CampusId, [Validators.required]),
      nombre: new FormControl(this.data?.Nombre, [Validators.required]),
    });
    this.edicionForm.valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
  }
  obtenerProgramas(){
    this.programaService.obtenerProgramasCampus(this.edicionForm.value.campus).subscribe((objPrograma: Programa[]) => {
      this.arrPrograma = objPrograma;
    })
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.edicionForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido.";
    }
    return error;
  }

  guardar(){
    let datos: Edificio = new Edificio();

    datos.Clave= this.edicionForm.value.clave;
    datos.CampusId= parseInt(this.edicionForm.value.campus);
    datos.Nombre = this.edicionForm.value.nombre;
    datos.UsuarioModificacion = -1
  
    if(!this.agregar){
      this.edificioService.postEdificio(datos).subscribe(datosEnviados => {
        if (datosEnviados) {
          this.edificioService.invocarMetodoObtenerEdificio();
          this.boolCambio = false;
          this.snackBar.open('Se ha guardado el edificio correctamente.', 'OK', { duration: 5000 });    
        } else {
          this.snackBar.open('Ha ocurrido un error al guardar el edificio. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
        }
      });  
    }else{
      datos.EdificioId = this.data.EdificioId;
      this.edificioService.editarEdificio(datos).subscribe(datosEnviados => {
        if (datosEnviados) {
          this.edificioService.invocarMetodoObtenerEdificio();
          this.boolCambio = false;
          this.snackBar.open('Se ha modificado los datos correctamente.', 'OK', { duration: 5000 });
        } else {
          this.snackBar.open('Ha ocurrido un error al editar los datos del edificio. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
        }
      });
    }
  }

  public cambiarVistaEditar(idEdificio: number) {
    this.boolCambio = false;
    this.data = Object.assign(this.edicionForm.value);
    this.data.tipoAccion = 2;
    this.edificioId = idEdificio;
  }

  public cambiarVistaEliminar() {
    this.data.tipoAccion = 4;
  }
  public enviar(): void {}

  public obtenerCampus() {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      }
    );
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }
}
