import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { PeriodoService } from '@app/_services/periodo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Periodo } from '@app/_models/periodo';
import { TipoPeriodo } from '@app/_models/tipo-periodo';
import { TipoPeriodoService } from '@app/_services/tipo-periodo.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-periodo-creacion',
  templateUrl: './periodo-creacion.component.html',
  styleUrls: ['./periodo-creacion.component.scss']
})
export class PeriodoCreacionComponent implements OnInit {

  public errorCreacionPeriodo: string = "";

  public creacionPeriodoForm: FormGroup = new FormGroup({
    clavePeriodo: new FormControl('', [Validators.required]),
    nombrePeriodo: new FormControl('', [Validators.required]),
    tipoPeriodo: new FormControl('', [Validators.required]),
    fechaInicioPeriodo: new FormControl('', [Validators.required]),
    fechaFinPeriodo: new FormControl('', [Validators.required])
  });

  get clavePeriodo() { return this.creacionPeriodoForm.get('clavePeriodo'); }
  get nombrePeriodo() { return this.creacionPeriodoForm.get('nombrePeriodo'); }
  get tipoPeriodo() { return this.creacionPeriodoForm.get('tipoPeriodo'); }
  get fechaInicioPeriodo() { return this.creacionPeriodoForm.get('fechaInicioPeriodo'); }
  get fechaFinPeriodo() { return this.creacionPeriodoForm.get('fechaFinPeriodo'); }

  public tipoPeriodoList: TipoPeriodo[] = [];

  constructor(public dialogRef: MatDialogRef<PeriodoCreacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Periodo,
    private periodoService: PeriodoService,
    private tipoPeriodoService: TipoPeriodoService,
    private snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.getTipoPeriodo();
  }

  public async getTipoPeriodo() {
    this.tipoPeriodoList = await this.tipoPeriodoService.getTipoPeriodo().pipe(take(1)).toPromise();
  }

  public enviar(): void {
    this.data = this.obtenerObjPeriodo();
    this.periodoService.generarPeriodo(this.data).subscribe(
      guardado => {
        if (guardado) {
          this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
          this.dialogRef.close(this.data);
          this.router.navigate(['/periodo']);
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar el periodo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al guardar el periodo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

  public obtenerObjPeriodo(): Periodo {
    return {
      clave: this.creacionPeriodoForm.get('clavePeriodo').value,
      tipoPeriodoId: this.creacionPeriodoForm.get('tipoPeriodo').value,
      nombre: this.creacionPeriodoForm.get('nombrePeriodo').value,
      fechaInicio: this.creacionPeriodoForm.get('fechaInicioPeriodo').value,
      fechaFin: this.creacionPeriodoForm.get('fechaFinPeriodo').value
    }
  }

}
