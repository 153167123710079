import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { EntidadFederativa } from 'src/app/_models/entidad-federativa';
import { EstadoCivil } from 'src/app/_models/estado-civil';
import { EstadoCuentaDto } from 'src/app/_models/estado-cuenta-dto';
import { Genero } from 'src/app/_models/genero';
import { GrupoSanguineo } from 'src/app/_models/grupo-sanguineo';
import { Nivel } from 'src/app/_models/nivel';
import { Pais } from 'src/app/_models/pais';
import { TipoDireccion } from 'src/app/_models/tipo-direccion';
import { TipoEmpresa } from 'src/app/_models/tipo-empresa';
import { TipoPuestoEmpresa } from 'src/app/_models/tipo-puesto-empresa';
import { TipoTelefono } from 'src/app/_models/tipo-telefono';
import { DireccionService } from 'src/app/_services/direccion.service';
import { EmpresaService } from 'src/app/_services/empresa.service';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { EstadoCivilService } from 'src/app/_services/estado-civil.service';
import { GeneroService } from 'src/app/_services/genero.service';
import { GrupoSanguineoService } from 'src/app/_services/grupo-sanguineo.service';
import { NivelService } from 'src/app/_services/nivel.service';
import { SeguimientoCarteraService } from 'src/app/_services/seguimiento-cartera.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-contenido-datos-personales',
  templateUrl: './contenido-datos-personales.component.html',
  styleUrls: ['./contenido-datos-personales.component.scss']
})
export class ContenidoDatosPersonalesComponent implements OnInit {
  public datosPersonalesForm: FormGroup;
  public tipoDireccionList: TipoDireccion[] = [];
  public paisList: Pais[] = [];
  public tipoTelefonoList: TipoTelefono[] = [];
  public sanguineoList: GrupoSanguineo[] = [];
  public estadoCivilList: EstadoCivil[];
  public generoList: Genero[] = [];
  public sexoList: Genero[] = [];
  public paisListBiografia: Pais[] = [];
  public nivelList: Nivel[] = [];
  public tipoEmpresaList: TipoEmpresa[] = [];
  public tipoPuestoEmpresaList: TipoPuestoEmpresa[] = [];
  public estadoListContacto: EntidadFederativa[] = [];
  public datosSeguimientoUsuario: any;
  public usuarioSesion: any;
  public usuario: any;
  public infoCargada: boolean = false;
  public entidadesFederativasPaisNacimiento = new Array<EntidadFederativa>();
  public entidadesFederativasPaisFacturacion = new Array<EntidadFederativa>();
  public paisIdContacto: number = 0;
  public paisIdNacimiento: number = 0;
  public paisIdFacturacion: number = 0;
  public estadoListBiografia: EntidadFederativa[] = [];
  public estadoListFacturacion: EntidadFederativa[] = [];
  public tipoAccionFamiliarPapa: any;
  public tipoAccionFamiliarMama: any;
  public tipoAccionFamiliarTutor: any;
  public tipoAccionFamiliarEmergencia: any;

  constructor(
    // public nuevoIngresoService: NuevoIngresoService,
    // private utileriasService: UtileriasService,
    private direccionService: DireccionService,
    private grupoSanguineoService: GrupoSanguineoService,
    private nivelService: NivelService,
    private generoService: GeneroService,
    private estadoCivilService: EstadoCivilService,
    private empresaService: EmpresaService,
    private seguimientoService: SeguimientoCarteraService,
    private enseñanazaAprendizajeService: EnsenanzaAprendizajeService,
    private utileriasService: UtileriasService,
  ) { }

  ngOnInit(): void {
    this.enseñanazaAprendizajeService.obtenerUsuarioConectado().subscribe((usuario) => {
      this.usuarioSesion = usuario
      this.obtenerDatosUsuario(usuario.usuarioId);
    })
    this.llamarDatos();


  }


  public llamarDatos() {
    this.getTipoDireccion();
    this.getPais();
    this.getTipoTelefono();
    this.getTipoGenero();
    this.getTipoSexo();
    this.getTipoCivil();
    this.getTipoSanguineo();
    this.getTipoNivel();
    this.getPaisBiografia();
    this.getTipoEmpresa();
    this.getTipoPuesto();
  }

  public inicializarForm() {
    this.datosPersonalesForm = new FormGroup({
      //Datos personales 
      nombre: new FormControl({ value: this.usuario.nombre, disabled: true }),
      segundoNombre: new FormControl({ value: this.usuario.segundoNombre, disabled: true }),
      apellidoPaterno: new FormControl({ value: this.usuario.apellidoPaterno, disabled: true }),
      apellidoMaterno: new FormControl({ value: this.usuario.apellidoMaterno, disabled: true }),
      fechaNacimiento: new FormControl({ value: this.utileriasService.formatearFecha(this.usuario.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD'), disabled: true }),
      //Datos de Contacto 
      tipoDireccionId: new FormControl({ value: this.usuario.direccion.length === 0 ? 0 : this.usuario.direccion[0].tipoDireccionId, disabled: true }),
      paisId: new FormControl({ value: this.paisIdContacto, disabled: true }),
      entidadFederativaId: new FormControl({ value: this.usuario.direccion.length === 0 ? 0 : this.usuario.direccion[0].entidadFederativaId, disabled: true }),
      calle: new FormControl({ value: this.usuario.direccion.length === 0 ? '' : this.usuario.direccion[0].calle, disabled: true }),
      ciudad: new FormControl({ value: this.usuario.direccion.length === 0 ? '' : this.usuario.direccion[0].ciudad, disabled: true }),
      numeroExterior: new FormControl({ value: this.usuario.direccion.length === 0 ? '' : this.usuario.direccion[0].numeroExterior, disabled: true }),
      numeroInterior: new FormControl({ value: this.usuario.direccion.length === 0 ? '' : this.usuario.direccion[0].numeroInterior, disabled: true }),
      colonia: new FormControl({ value: this.usuario.direccion.length === 0 ? '' : this.usuario.direccion[0].colonia, disabled: true }),
      codigoPostal: new FormControl({ value: this.usuario.direccion.length === 0 ? '' : this.usuario.direccion[0].codigoPostal, disabled: true }),
      tipoTelefonoId: new FormControl({ value: this.usuario.telefono.length === 0 ? 0 : this.usuario.telefono[0].tipoTelefonoId, disabled: true }),
      numero: new FormControl({ value: this.usuario.telefono.length === 0 ? '' : this.usuario.telefono[0].numero, disabled: true }),
      extension: new FormControl({ value: this.usuario.telefono.length === 0 ? '' : this.usuario.telefono[0].extension, disabled: true }),
      correo: new FormControl({ value: this.usuario.correo, disabled: true }),
      //Datos Biograficos 
      legalGeneroId: new FormControl({ value: this.usuario.biografia === null ? 0 : this.usuario.biografia.legalGeneroId, disabled: true }),
      generoId: new FormControl({ value: this.usuario.biografia === null ? 0 : this.usuario.biografia.generoId, disabled: true }),
      ciudadania: new FormControl({ value: this.usuario.biografia === null ? 0 : this.usuario.biografia.ciudadaniaPaisId, disabled: true }),
      estadoCivilId: new FormControl({ value: this.usuario.biografia === null ? 0 : this.usuario.biografia.estadoCivilId, disabled: true }),
      curp: new FormControl({ value: this.usuario.biografia === null ? '' : this.usuario.biografia.curp, disabled: true }),
      paisNacimiento: new FormControl({ value: this.paisIdNacimiento, disabled: true }),
      entidadNacimiento: new FormControl({ value: this.usuario.biografia === null ? '' : this.usuario.biografia.entidadNacimiento, disabled: true }),
      ciudadNacimiento: new FormControl({ value: this.usuario.biografia === null ? '' : this.usuario.biografia.ciudadNacimiento, disabled: true }),
      //Datos Familiares
      nombreCompletoMadre: new FormControl({ value: this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.nombreCompleto, disabled: true }),
      telefonoMadre: new FormControl({ value: this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.telefono, disabled: true }),
      correoElectronicoMadre: new FormControl({ value: this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.correoElectronico, disabled: true }),
      nombreCompletoPadre: new FormControl({ value: this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.nombreCompleto, disabled: true }),
      telefonoPadre: new FormControl({ value: this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.telefono, disabled: true }),
      correoElectronicoPadre: new FormControl({ value: this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.correoElectronico, disabled: true }),
      nombreCompletoTutor: new FormControl({ value: this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.nombreCompleto, disabled: true }),
      telefonoTutor: new FormControl({ value: this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.telefono, disabled: true }),
      correoElectronicoTutor: new FormControl({ value: this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.correoElectronico, disabled: true }),
      //Contacto Emergencia
      nombreContacto: new FormControl({ value: this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.nombreCompleto, disabled: true }),
      telefonoContacto: new FormControl({ value: this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.telefono, disabled: true }),
      correoContacto: new FormControl({ value: this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.correoElectronico, disabled: true }),
      //Datos Medicos  
      grupoSanguineoId: new FormControl({ value: this.usuario.biografia === null ? 0 : this.usuario.biografia.grupoSanguineoId, disabled: true }),
      alergias: new FormControl({ value: this.usuario.biografia === null ? '' : this.usuario.biografia.alergias, disabled: true }),
      enfermedades: new FormControl({ value: this.usuario.biografia === null ? '' : this.usuario.biografia.enfermedades, disabled: true }),
      //Estudios Previos 
      nivel: new FormControl({ value: this.usuario.estudioPrevio.length === 0 ? 0 : this.usuario.estudioPrevio[0].nivelId, disabled: true }),
      academico: new FormControl({ value: this.usuario.estudioPrevio.length === 0 ? '' : this.usuario.estudioPrevio[0].escuela, disabled: true }),
      promedio: new FormControl({ value: this.usuario.estudioPrevio.length === 0 ? '' : this.usuario.estudioPrevio[0].promedio, disabled: true }),
      //Datos de Facturacion  
      rfc: new FormControl({ value: this.usuario.datoFiscal === null ? '' : this.usuario.datoFiscal.rfc, disabled: true }),
      razonSocial: new FormControl({ value: this.usuario.datoFiscal === null ? '' : this.usuario.datoFiscal.razonSocial, disabled: true }),
      tipoDireccionFacturacionId: new FormControl({ value: this.usuario.datoFiscal === null ? 0 : this.usuario.datoFiscal.tipoDireccionFacturacionId, disabled: true }),
      paisFacturacionId: new FormControl({ value: this.paisIdFacturacion, disabled: true }),
      entidadFederativaFacturacionId: new FormControl({ value: this.usuario.datoFiscal === null ? 0 : this.usuario.datoFiscal.entidadFederativaId, disabled: true }),
      calleFacturacion: new FormControl({ value: this.usuario.datoFiscal === null ? 0 : this.usuario.datoFiscal.calle, disabled: true }),
      ciudadFacturacion: new FormControl({ value: this.usuario.datoFiscal === null ? '' : this.usuario.datoFiscal.ciudad, disabled: true }),
      numeroExteriorFacturacion: new FormControl({ value: this.usuario.datoFiscal === null ? '' : this.usuario.datoFiscal.numeroExterior, disabled: true }),
      numeroInteriorFacturacion: new FormControl({ value: this.usuario.datoFiscal === null ? '' : this.usuario.datoFiscal.numeroInterior, disabled: true }),
      coloniaFacturacion: new FormControl({ value: this.usuario.datoFiscal === null ? '' : this.usuario.datoFiscal.colonia, disabled: true }),
      codigoPostalFacturacion: new FormControl({ value: this.usuario.datoFiscal === null ? '' : this.usuario.datoFiscal.codigoPostal, disabled: true }),
      //Ocupacion
      tipoEmpresaId: new FormControl({ value: this.usuario.ocupacion === null ? 0 : this.usuario.ocupacion.tipoEmpresaId, disabled: true }),
      tipoPuestoEmpresaId: new FormControl({ value: this.usuario.ocupacion === null ? 0 : this.usuario.ocupacion.tipoPuestoEmpresaId, disabled: true })
    });
    this.infoCargada = true;
  }

  public getTipoDireccion() {
    this.direccionService.obtenerTipoDireccion().subscribe(
      (tipoDireccion: TipoDireccion[]) => {
        this.tipoDireccionList = tipoDireccion;
      });
  }

  public getPais() {
    this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisList = pais;
      });
  }

  public getPaisBiografia() {
    this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisListBiografia = pais;
      });
  }

  public getTipoTelefono() {
    this.direccionService.obtenerTipoTelefono().subscribe(
      (tipoTelefono: TipoTelefono[]) => {
        this.tipoTelefonoList = tipoTelefono;
      });
  }

  public getTipoGenero() {
    this.generoService.obtenerGenero().subscribe(
      (tipoGenero: Genero[]) => {
        this.generoList = tipoGenero;
      }
    );
  }

  public getTipoSexo() {
    this.generoService.obtenerGenero().subscribe(
      (tipoSexo: Genero[]) => {
        this.sexoList = tipoSexo;
      }
    );
  }

  public getTipoCivil() {
    this.estadoCivilService.obtenerEstadoCivil().subscribe(
      (tipoCivil: EstadoCivil[]) => {
        this.estadoCivilList = tipoCivil;
      }
    );
  }

  public getTipoSanguineo() {
    this.grupoSanguineoService.obtenerGrupoSanguineo().subscribe(
      (tipoSanguineo: GrupoSanguineo[]) => {
        this.sanguineoList = tipoSanguineo;
      }
    );
  }

  public getTipoNivel() {
    this.nivelService.getNivel().subscribe(
      (tiposNivel: Nivel[]) => {
        this.nivelList = tiposNivel;
      }
    );
  }

  public getTipoEmpresa() {
    this.empresaService.obtenerTipoEmpresa().subscribe(
      (tipoEmpresa: TipoEmpresa[]) => {
        this.tipoEmpresaList = tipoEmpresa;
      });
  }

  public getTipoPuesto() {
    this.empresaService.obtenerTipoPuestoEmpresa().subscribe(
      (tipoPuestoEmpresa: TipoPuestoEmpresa[]) => {
        this.tipoPuestoEmpresaList = tipoPuestoEmpresa;
      });
  }

  public obtenerDatosUsuario(usuarioId: number) {
    this.seguimientoService.obtenerSeguimientoUsuarioById(usuarioId).subscribe(
      (response: ApiResponse<EstadoCuentaDto[]>) => {
        if (response.success) {
          this.usuario = response.data;
          this.getEstadosContacto(this.usuario.direccion[0]?.entidadFederativaId);
          this.getEstadosBiografia(this.usuario.biografia?.entidadNacimiento)
          this.getEstadosFacturacion(this.usuario.datoFiscal?.entidadFederativaId)
          this.asignarDatosFamiliares();

        }
      })
  }


  public asignarDatosFamiliares() {
    if (this.usuario.personaRelacionadaUsuario.length >= 1) {
      this.tipoAccionFamiliarPapa = this.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1) === undefined ? null : this.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1);
      this.tipoAccionFamiliarMama = this.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2) === undefined ? null : this.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2);
      this.tipoAccionFamiliarTutor = this.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3) === undefined ? null : this.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3);
      this.tipoAccionFamiliarEmergencia = this.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 4) === undefined ? null : this.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 4);
    } else {
      this.tipoAccionFamiliarPapa = null;
      this.tipoAccionFamiliarMama = null;
      this.tipoAccionFamiliarTutor = null;
      this.tipoAccionFamiliarEmergencia = null;
    }

    this.inicializarForm();

  }


  /**
     * Obtiene las entidades federativas del País de nacimiento
     * @param IdPais 
     */
  public getEntidadesFederativasDelPaisNacimiento(IdPais: number): void {
    if (IdPais > 0) {
      this.direccionService.obtenerEntidadesFederativas(IdPais).subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
        this.entidadesFederativasPaisNacimiento = entidadesFederativas;
      });
    }
  }

  /**
     * Obtiene las entidades deferativas del país de facturación seleccionado
     * @param IdPais 
     */
  public getEntidadesFederativasPaisFacturacion(IdPais: number): void {
    if (IdPais > 0) {
      this.direccionService.obtenerEntidadesFederativas(IdPais).subscribe((entidadesFederativasPaisFacturacion: Array<EntidadFederativa>) => {
        this.entidadesFederativasPaisFacturacion = entidadesFederativasPaisFacturacion;
      });
    }
  }

  public getEstadosContacto(entidadFederativaId) {
    this.direccionService.obtenerEntidadFederativa(entidadFederativaId).subscribe(entidad => {
      this.paisIdContacto = entidad.paisId;
      this.paisId.setValue(this.paisIdContacto);
      this.getEntidadesFederativas(this.paisIdContacto);
    })

  }
  public getEstadosBiografia(entidadFederativaId) {
    this.direccionService.obtenerEntidadFederativa(entidadFederativaId).subscribe(entidad => {
      this.paisIdNacimiento = entidad.paisId;
      this.paisNacimiento.setValue(this.paisIdNacimiento);
      this.getEntidadesNacimiento(this.paisIdNacimiento);
    })

  }
  public getEstadosFacturacion(entidadFederativaId) {
    this.direccionService.obtenerEntidadFederativa(entidadFederativaId).subscribe(entidad => {
      this.paisIdFacturacion = entidad.paisId;
      this.paisFacturacionId.setValue(this.paisIdFacturacion);
      this.getEntidadesFacturacion(this.paisIdFacturacion);
    })

  }



  /**
    * Ejecuta el método del Api que consulta la lista de entidades federativas
    * Simpre que se haya seleccionado un país
    * @param IdPais 
    */
  public getEntidadesFederativas(IdPais: number): void {
    if (IdPais > 0) {
      this.direccionService.obtenerEntidadesFederativas(IdPais).subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
        this.estadoListContacto = entidadesFederativas;
      });
    }
  }
  public getEntidadesNacimiento(IdPais: number): void {
    if (IdPais > 0) {
      this.direccionService.obtenerEntidadesFederativas(IdPais).subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
        this.estadoListBiografia = entidadesFederativas;
      });
    }
  }
  public getEntidadesFacturacion(IdPais: number): void {
    if (IdPais > 0) {
      this.direccionService.obtenerEntidadesFederativas(IdPais).subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
        this.estadoListFacturacion = entidadesFederativas;
      });
    }
  }


  //Datos personales
  get id() { return this.datosPersonalesForm.get('id'); }
  get nombre() { return this.datosPersonalesForm.get('nombre'); }
  get segundoNombre() { return this.datosPersonalesForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.datosPersonalesForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.datosPersonalesForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.datosPersonalesForm.get('fechaNacimiento'); }
  get fotografia() { return this.datosPersonalesForm.get('fotografia'); }

  //Datos de Contacto
  get tipoDireccionId() { return this.datosPersonalesForm.get('tipoDireccionId'); }
  get paisId() { return this.datosPersonalesForm.get('paisId'); }
  get entidadFederativaId() { return this.datosPersonalesForm.get('entidadFederativaId'); }
  get tipoTelefonoId() { return this.datosPersonalesForm.get('tipoTelefonoId'); }
  get ciudad() { return this.datosPersonalesForm.get('ciudad'); }
  get calle() { return this.datosPersonalesForm.get('calle'); }
  get numeroExterior() { return this.datosPersonalesForm.get('numeroExterior'); }
  get numeroInterior() { return this.datosPersonalesForm.get('numeroInterior'); }
  get colonia() { return this.datosPersonalesForm.get('colonia'); }
  get codigoPostal() { return this.datosPersonalesForm.get('codigoPostal'); }
  get numero() { return this.datosPersonalesForm.get('numero'); }
  get extension() { return this.datosPersonalesForm.get('extension'); }
  get correo() { return this.datosPersonalesForm.get('correo'); }

  //Datos Biograficos
  get legalGeneroId() { return this.datosPersonalesForm.get('legalGeneroId'); }
  get ciudadania() { return this.datosPersonalesForm.get('ciudadania'); }
  get curp() { return this.datosPersonalesForm.get('curp'); }
  get paisNacimiento() { return this.datosPersonalesForm.get('paisNacimiento'); }
  get entidadNacimiento() { return this.datosPersonalesForm.get('entidadNacimiento'); }
  get ciudadNacimiento() { return this.datosPersonalesForm.get('ciudadNacimiento'); }

  //Datos Familiares
  get nombreCompletoMadre() { return this.datosPersonalesForm.get('nombreCompletoMadre'); }
  get telefonoMadre() { return this.datosPersonalesForm.get('telefonoMadre'); }
  get correoElectronicoMadre() { return this.datosPersonalesForm.get('correoElectronicoMadre'); }
  get nombreCompletoPadre() { return this.datosPersonalesForm.get('nombreCompletoPadre'); }
  get telefonoPadre() { return this.datosPersonalesForm.get('telefonoPadre'); }
  get correoElectronicoPadre() { return this.datosPersonalesForm.get('correoElectronicoPadre'); }
  get nombreCompletoTutor() { return this.datosPersonalesForm.get('nombreCompletoTutor'); }
  get telefonoTutor() { return this.datosPersonalesForm.get('telefonoTutor'); }
  get correoElectronicoTutor() { return this.datosPersonalesForm.get('correoElectronicoTutor'); }

  //Contacto Emergencia
  get nombreContacto() { return this.datosPersonalesForm.get('nombreContacto'); }
  get telefonoContacto() { return this.datosPersonalesForm.get('telefonoContacto'); }
  get correoContacto() { return this.datosPersonalesForm.get('correoContacto'); }

  //Datos Medicos
  get grupoSanguineoId() { return this.datosPersonalesForm.get('grupoSanguineoId'); }
  get alergias() { return this.datosPersonalesForm.get('alergias'); }
  get enfermedades() { return this.datosPersonalesForm.get('enfermedades'); }

  //Estudios Previos
  get nivel() { return this.datosPersonalesForm.get('nivel'); }
  get academico() { return this.datosPersonalesForm.get('academico'); }
  get promedio() { return this.datosPersonalesForm.get('promedio'); }

  //Datos Facturacion
  get tipoDireccionFacturacionId() { return this.datosPersonalesForm.get('tipoDireccionFacturacionId'); }
  get paisFacturacionId() { return this.datosPersonalesForm.get('paisFacturacionId'); }
  get entidadFederativaFacturacionId() { return this.datosPersonalesForm.get('entidadFederativaFacturacionId'); }
  get ciudadFacturacion() { return this.datosPersonalesForm.get('ciudadFacturacion'); }
  get calleFacturacion() { return this.datosPersonalesForm.get('calleFacturacion'); }
  get numeroExteriorFacturacion() { return this.datosPersonalesForm.get('numeroExteriorFacturacion'); }
  get numeroInteriorFacturacion() { return this.datosPersonalesForm.get('numeroInteriorFacturacion'); }
  get coloniaFacturacion() { return this.datosPersonalesForm.get('coloniaFacturacion'); }
  get codigoPostalFacturacion() { return this.datosPersonalesForm.get('codigoPostalFacturacion'); }

  //Datos Fiscales
  get tipoEmpresa() { return this.datosPersonalesForm.get('tipoEmpresaId'); }
  get puesto() { return this.datosPersonalesForm.get('tipoPuestoEmpresaId'); }





}
