import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable({
  providedIn: 'root'
})
export class RegistrarUsuarioService {

  constructor(private httpClient: HttpClient) { }

  public registrarUsuario(
    
    correo: string,
    nombre: string,
    apellidoPaterno: string,
    apellidoMaterno: string,
    fechaNacimiento: Date,
    password: string,
    //campusPrograma: number,
    programaAcademico: number,
    ciclo: number

    
  ): 
  Observable<boolean> {
    let registro: any = {
      correo: correo,
      password: password,
      nombre: nombre,
      apellidoPaterno: apellidoPaterno,
      apellidoMaterno: apellidoMaterno,
      //campusPrograma:campusPrograma,
      biografia: {
        fechaNacimiento: fechaNacimiento
      }
    };
    // if (!!campusPrograma) {
    //   registro.campus ={
    //     CampusId:campusPrograma
    //   }
      
    // }
     if (!!ciclo) {
       registro.periodo = {
         periodoID: ciclo
       };
    };
    if (!!programaAcademico) {
       registro.programa = {
        programaID: programaAcademico
       };
     };
     console.log("DATOS QUE LLEGAN AL SERVICE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" + registro);
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/RegistrarUsuario`, registro); 
  };

  public registrarUsuarioProfesor(
    
    correo: string,
    nombre: string,
    segundoNombre:string,
    apellidoPaterno: string,
    apellidoMaterno: string,
    fechaNacimiento: Date,
    password: string,
    //campusPrograma: number,
    programaAcademico: number,
    ciclo: number

    
  ): 
  Observable<boolean> {
    let registro: any = {
      correo: correo,
      password: password,
      nombre: nombre,
      segundoNombre:segundoNombre,
      apellidoPaterno: apellidoPaterno,
      apellidoMaterno: apellidoMaterno,
      //campusPrograma:campusPrograma,
      biografia: {
        fechaNacimiento: fechaNacimiento
      }
    };
    // if (!!campusPrograma) {
    //   registro.campus ={
    //     CampusId:campusPrograma
    //   }
      
    // }
     if (!!ciclo) {
       registro.periodo = {
         periodoID: ciclo
       };
    };
    if (!!programaAcademico) {
       registro.programa = {
        programaID: programaAcademico
       };
     };
    
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/RegistrarUsuario/CrearCuentaProfesor`, registro); 
  };

  //Test Guardar candidato
  //+++++++++++++++++++++++++++++++++++++++++++++++++
  
  public registrarUsuarioCandidato(
    
    correo: string,
    nombre: string,
    apellidoPaterno: string,
    apellidoMaterno: string,
    fechaNacimiento: Date,
    password: string,
    campusPrograma: number,
    programaAcademico: number,
    ciclo: number

    
  ): 
  Observable<boolean> {
    let registro: any = {
      correo: correo,
      password: password,
      nombre: nombre,
      apellidoPaterno: apellidoPaterno,
      apellidoMaterno: apellidoMaterno,
      campusPrograma:campusPrograma,
      biografia: {
        fechaNacimiento: fechaNacimiento
      }
    };
    if (!!campusPrograma) {
      registro.campus ={
        CampusId:campusPrograma
      }
      
    }
     if (!!ciclo) {
       registro.periodo = {
         periodoID: ciclo
       };
    };
    if (!!programaAcademico) {
       registro.programa = {
        programaID: programaAcademico
       };
     };
     console.log("DATOS QUE LLEGAN AL SERVICE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" + registro);
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/RegistrarUsuario`, registro); 
  };




  // **********************************************************
  public actualizarUsuarioCandidato(
    
    correo: string,
    nombre: string,
    apellidoPaterno: string,
    apellidoMaterno: string,
    fechaNacimiento: Date,
    password: string,
    campusPrograma: number,
    programaAcademico: number,
    ciclo: number,
    usuarioId:number

    
  ): 
  Observable<boolean> {
    let registro: any = {
      usuarioId:usuarioId,
      correo: correo,
      password: password,
      nombre: nombre,
      apellidoPaterno: apellidoPaterno,
      apellidoMaterno: apellidoMaterno,
      campusPrograma:campusPrograma,
      biografia: {
        fechaNacimiento: fechaNacimiento
      }
    };
    if (!!campusPrograma) {
      registro.campus ={
        CampusId:campusPrograma
      }
      
    }
     if (!!ciclo) {
       registro.periodo = {
         periodoID: ciclo
       };
    };
    if (!!programaAcademico) {
       registro.programa = {
        programaID: programaAcademico
       };
     };
     console.log("DATOS QUE LLEGAN AL SERVICE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", registro);
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/RegistrarUsuario/PutDatosPersonales`, registro); 
  };
  //+++++++++++++++++++++++++++++++++++++++++++++++++
  //+++++++++++++++++++++++++++++++++++++++++++++++++
  //+++++++++++++++++++++++++++++++++++++++++++++++++

  public cuentaDisponible(
    correo: string
  ): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.enves[baseUrl].apiUrl}/RegistrarUsuario/verificar/correo/${correo}/`);
  };

  public activaCuenta(
    token: string
  ): Observable<boolean> {
    return this.httpClient.get<boolean>(`/api/RegistrarUsuario/activar/token/${token}/`);
  };

}
