import { ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { FILTERS_CONTROLS } from '@app/clean-architecture/core.application/validators/filters-form.validators';
import { Periodo, Campus } from '@app/_models';
import { CampusService } from '@app/_services/campus.service';
import { PeriodoService } from '@app/_services/periodo.service';

export class FiltersBase {

    constructor(
        protected formBuilder: FormBuilder,
        protected _campusService: CampusService,
        protected _periodoService: PeriodoService,
    ) { }

  //#region ViewChild
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allExtraordinario') private allExtraordinario: MatOption;
  //#endregion

//#region Properties

public Periodos: Periodo[];
private periodosactivos: number[] = [];
public Campus: Campus[];
public Opciones: any[] = [{opcionId: true, nombre: 'Sí'}, {opcionId: false, nombre: 'No'}];
public filtersForm: FormGroup = this.formBuilder.group(FILTERS_CONTROLS);
//#endregion
  
 //#region Requests Service Web

  /**
  * I'm trying to get the current date and compare it with the dates in the database to get the
  * active periods
  */
   public obtenerPeriodo():void {
    let today = new Date();
    this._periodoService.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        this.Periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio <= today && fin >= today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        this.setPeriodo(true);
      });
    }
    public setPeriodo(activos: boolean):void {
      if (activos) {
        if (this.periodosactivos.length > 0) {                        
          this.filtersForm.get('periodo').setValue([...this.periodosactivos]);          
        } 
      } else {
        this.filtersForm.get('periodo').setValue([...this.Periodos.map(c => c.periodoId), 0]);          
      }                
    }
  
  
     
    /* It's getting the campus from the database and setting the value of the form control to the
    array of the ids of the options. */
    public obtenerCampus():void {
      this._campusService.obtenerCampus().subscribe((campus: Campus[]) => {
        this.Campus = campus;      
        this.setCampus();
      });
    }
    public setCampus():void {           
        this.filtersForm.get('campus').setValue([...this.Campus.map(c => c.campusId), 0]);
    }


    /**
     * It sets the value of the form control to the array of the ids of the options.
     */
    public setExtraordinario():void {
      this.filtersForm.get('extraordinario').setValue([...this.Opciones.map(c => c.opcionId), 0]);
    }



    public setDataFilter():void
    {
      this.setPeriodo(true);
      this.setCampus();
      this.setExtraordinario();
    }
  //#endregion


  //#region MatMultiSelect Check
 
  //#region Periodo
  /**
   * If the allPeriodo.selected is true, then set the value of the periodo to false.
   * If the allPeriodo.selected is false, then set the value of the periodo to an empty array.
   */
  toggleAllSelectionPeriodo() {

    if (this.allPeriodo.selected) {
        this.setPeriodo(false);
    } else {
      this.filtersForm.get('periodo').setValue([]);
    }
  }
  /**
  * If the user selects all the checkboxes, then the "all" checkbox is selected. If the user deselects
  * one of the checkboxes, then the "all" checkbox is deselected.
  * @param {number} periodoId - number
  */
  tosslePerOnePeriodo(periodoId: number) {
    
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
    }
    if (this.filtersForm.get('periodo').value.length === this.Periodos.length) {
      this.allPeriodo.select();
    }
  }
  //#endregion

  //#region Campus
  /**
   * If the allCampus.selected is true, then set the campus. If not, then set the campus to an empty
   * array.
   */
   toggleAllSelectionCampus() {    
    if (this.allCampus.selected) {
      this.setCampus();
    } else {
      this.filtersForm.get('campus').setValue([]);
    }
  }
  /**
   * If the user selects all the campuses, then the "allCampus" checkbox is selected.
   * @param {number} campusId - number - the id of the campus that was selected/deselected
   */
  tosslePerOneCampus(campusId: number) {

    if (this.allCampus.selected) {
      this.allCampus.deselect();
    }
    if (this.filtersForm.get('campus').value.length == this.Campus.length) {
      this.allCampus.select();
    }   
  }
  //#endregion
  
  //#region Extraordinario
  /**
   * If the allExtraordinario.selected is true, then set the campus. If not, then set the campus to an empty
   * array.
   */
   toggleAllSelectionExtraordinario() {    
    if (this.allExtraordinario.selected) {
      this.setExtraordinario();
    } else {
      this.filtersForm.get('extraordinario').setValue([]);
    }
  }
  /**
   * If the user selects all the campuses, then the "allCampus" checkbox is selected.
   * @param {number} campusId - number - the id of the campus that was selected/deselected
   */
  tosslePerOneExtraordinario(campusId: number) {

    if (this.allExtraordinario.selected) {
      this.allExtraordinario.deselect();
    }
    if (this.filtersForm.get('extraordinario').value.length == this.Opciones.length) {
      this.allExtraordinario.select();
    }   
  }
  //#endregion
  //#endregion
  
  }
  