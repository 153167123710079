import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ConfiguracionCuotaService } from '../../../_services/configuracion-cuota.service';
import { CampusService } from '../../../_services/campus.service';
import { NivelService } from '../../../_services/nivel.service';
import { PeriodoService } from '../../../_services/periodo.service';
import { Campus } from '../../../_models/campus';
import { Nivel } from '../../../_models/nivel';
import { Periodo } from '../../../_models/periodo';
import { Programa } from '../../../_models/programa';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { InscripcionCosto } from '../../../_models/inscripcion-costo';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgramaAcademicoService } from '../../../_services/programa-academico.service';
import { UtileriasService } from '../../../_services/utilerias.service';
import { SelectValidator } from 'src/app/validator/SelectValidator';
import { Subscription } from 'rxjs';
import { SnackService } from 'src/app/services/snack-service.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';

@Component({
  selector: 'app-modal-inscripcion',
  templateUrl: './modal-inscripcion.component.html',
  styleUrls: ['./modal-inscripcion.component.scss']
})
export class ModalInscripcionComponent implements OnInit, OnDestroy {

  constructor(private cuota: ConfiguracionCuotaService,
    private campusservice: CampusService,
    private nivelservice: NivelService,
    private periodoservice: PeriodoService,
    private programaservice: ProgramaAcademicoService,
    private formBuilder: FormBuilder,
    private utileria: UtileriasService,
    public dialogRef: MatDialogRef<ModalInscripcionComponent>,
    public snackService: SnackService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  //#region Propiedades
  public arrCampus: Campus[];
  public arrNivel: Nivel[];
  public arrPeriodo: Periodo[];
  public arrPrograma: Programa[];
  public arrTipoA = [{ 'id': '1', 'name': 'Nuevo Ingreso' }, { 'id': '2', 'name': 'Reingreso' }]

  public agregar: Boolean = true;
  public formCampos: FormGroup;
  private suscripcion = new Subscription();
  //#endregion

  //#region  Métodos
  ngOnDestroy(): void {
    if (this.suscripcion) {
      this.suscripcion.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.agregar = this.data == null;
    if (this.agregar) {
      this.generarFormularioParaCreacion();
    }
    else {
      this.generarFormularioEdicion();
      this.obtenerNiveles();
      this.obtenerProgramas();
    }
    this.consultarDatos();
  }
  /**
  * Este método genera un Form reactivo cuando se abre el modal para crear simplemente
  */
  private generarFormularioParaCreacion(): void {
    this.formCampos = this.formBuilder.group({
      periodo: [0, Validators.required],
      campus: [0, Validators.required],
      nivel: [0, Validators.required],
      programa: [0, Validators.required],
      fechaInicio: [this.utileria.formatearFecha(new Date(), 'YYYY[-]MM[-]DD'), Validators.required],
      fechaFin: [this.utileria.formatearFecha(new Date(), 'YYYY[-]MM[-]DD'), Validators.required],
      tipoA: [0, Validators.required],
      costo: ['', [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      impuesto: ['', [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      intereses: ['', [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      parcialidades: [0, Validators.required]
    },
      {
        validators: [SelectValidator('periodo'), SelectValidator('campus'),
        SelectValidator('nivel'), SelectValidator('tipoA'), SelectValidator('programa')]
      }
    );
  }
  /**
   * Método que genera un form reactivo con los datos que recibe
   */
  private generarFormularioEdicion(): void {
    this.formCampos = this.formBuilder.group({
      periodo: [this.data?.periodoId, Validators.required],
      campus: [this.data?.campusId, Validators.required],
      nivel: [this.data?.nivelId, Validators.required],
      programa: [this.data?.programaId, Validators.required],
      fechaInicio: [this.utileria.formatearFecha(this.data?.fechaInicio, 'YYYY[-]MM[-]DD'), Validators.required],
      fechaFin: [this.utileria.formatearFecha(this.data?.fechaFin, 'YYYY[-]MM[-]DD'), Validators.required],
      tipoA: [this.data?.tipoAlumno == 'NI'? 1 : 2, Validators.required],
      costo: [this.data?.costo, [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      impuesto: [this.data?.impuesto, [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      intereses: [this.data?.intereses, [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      parcialidades: [this.data?.parcialidades, Validators.required]
    });
  }
  /**
   * Método que consulta los campus y periodos existentes
   */
  private consultarDatos(): void {
    this.suscripcion.add(
      this.campusservice.obtenerCampus().subscribe((camp: Campus[]) => {
        this.arrCampus = camp;
      })
    )
    this.suscripcion.add(
      this.periodoservice.obtenerPeriodos().subscribe((periodos: Periodo[]) => {
        this.arrPeriodo = periodos;
      })
    );
  }

  /**
   * Obtiene un listado de niveles que están disponibles para un campus
   */
  public obtenerNiveles(): void {
    if (this.campusConfig.invalid || this.periodoConfig.invalid) return;
    this.suscripcion.add(
      this.nivelservice.obtenerNivelesDelCampus(this.campusConfig.value).subscribe((nivelesResponse: ApiResponse<Array<Nivel>>) => {
        if (nivelesResponse.success) {
          this.arrNivel = nivelesResponse.data;
        }
        else {
          this.snackService.mostrarSnackBack(nivelesResponse.message);
        }
      })
    );
  }
  /**
   * Obtiene los programas disponibles para el campus y el nivel indicado
   */
  public obtenerProgramas(): void {
    if (this.periodoConfig.invalid || this.campusConfig.invalid || this.nivelConfig.invalid) return;
    this.suscripcion.add(
      this.programaservice.obtenerProgramasCampusYNivel(this.campusConfig.value, this.nivelConfig.value).subscribe((apiProgramasResponse: ApiResponse<Array<Programa>>) => {
        if (apiProgramasResponse.success) {
          this.arrPrograma = apiProgramasResponse.data;
        }
        else {
          this.snackService.mostrarSnackBack(apiProgramasResponse.message);
        }
      })
    );

  }
  /**
   * Guardar la información del servicio acerca de la inscripción
   */
  public guardar(): void {
    let datos: InscripcionCosto = new InscripcionCosto();
    datos.PeriodoId = this.periodoConfig.value;
    datos.ProgramaId = this.programaConfig.value;
    datos.costo = this.costoConfig.value;
    datos.CampusId = this.campusConfig.value;
    datos.NivelId = this.nivelConfig.value;
    datos.fechaInicio = this.fechaInicioConfig.value;
    datos.fechaFin = this.fechaFinConfig.value;
    datos.Impuesto = this.impuestoConfig.value;
    datos.TipoAlumno = this.tipoAConfig.value == 1? 'NI': 'RI';
    datos.Intereses = this.interesesConfig.value;
    datos.Parcialidades = 0;
    datos.UsuarioModificacion = -1

    if (this.agregar) {
      this.suscripcion.add(
        this.cuota.postInscripcionCosto(datos).subscribe(datosEnviados => {
          if (datosEnviados) {
            this.snackService.mostrarSnackBack('Se ha guardado el servicio correctamente.');
            this.cuota.metodoObtenerInscripciones();
          }
          else {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar el servicio. Por favor, intente de nuevo más tarde.');
          }
        })
      );
    }
    else {
      this.suscripcion.add(
        this.cuota.editarInscripcionCosto(datos).subscribe(datosEnviados => {
          if (datosEnviados) {
            this.snackService.mostrarSnackBack('Se ha editado el costo de inscripcion correctamente.');
            this.cuota.metodoObtenerInscripciones();
          }
          else {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al editar el costo de inscripcion. Por favor, intente de nuevo más tarde.');
          }
        })
      );
    }
  }
  //#endregion

  //#region Gets
  get periodoConfig() {
    return this.formCampos.get('periodo');
  }
  get campusConfig() {
    return this.formCampos.get('campus');
  }
  get nivelConfig() {
    return this.formCampos.get('nivel');
  }
  get programaConfig() {
    return this.formCampos.get('programa');
  }
  get costoConfig() {
    return this.formCampos.get('costo')
  };
  get impuestoConfig() {
    return this.formCampos.get('impuesto')
  };
  get parcialidadConfig() {
    return this.formCampos.get('parcialidades')
  };
  get fechaInicioConfig() {
    return this.formCampos.get('fechaInicio');
  }
  get fechaFinConfig() {
    return this.formCampos.get('fechaFin')
  };
  get tipoAConfig() {
    return this.formCampos.get('tipoA')
  };
  get interesesConfig() {
    return this.formCampos.get('intereses')
  };
  //#endregion
}
