import { ConsultaDatosComponent } from './consulta-datos/consulta-datos.component';
import { AvanceCursoSeguimientoComponent } from './avance-curso-seguimiento/avance-curso-seguimiento.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: 'avance-curso', component: AvanceCursoSeguimientoComponent, canActivate: [MsalGuard] },
  { path: 'consulta-datos', component: ConsultaDatosComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AvanceCursoRoutingModule { }
