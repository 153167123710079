import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { SelectValidator } from 'src/app/validator/SelectValidator';
import { PagoZumaModalComponent } from 'src/app/_components/pago-zuma-modal/pago-zuma-modal.component';
import { AlumnoReingresoDTO } from 'src/app/_models/alumnoReingresoDTO.model';
import { CargoDTO } from 'src/app/_models/cargoDTO.model';
import { ColegiaturaCosto } from 'src/app/_models/colegiatura-costo';
import { EstadoCuentaDto } from 'src/app/_models/estado-cuenta-dto';
import { InscripcionCosto } from 'src/app/_models/inscripcion-costo';
import { IntegracionDto } from 'src/app/_models/integracionDto';
import { IntentoPagoZumaDTO } from 'src/app/_models/IntentoPagoZumaDTO.model';
import { Periodo } from 'src/app/_models/periodo';
import { PagoZuma } from 'src/app/_models/Zuma/LinkPago/PagoZuma';
import { PagoZumaResponse } from 'src/app/_models/Zuma/LinkPago/PagoZumaResponse';
import { ValidarInscripcionExistente } from 'src/app/_models/Zuma/validarInscripcionExistente';
import { IntegracionService } from 'src/app/_services/integracion.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { PagoLineaZumaService } from 'src/app/_services/pago-linea-zuma.service';
import { ReinscripcionService } from 'src/app/_services/reinscripcion.service';
import { SeguimientoCarteraService } from 'src/app/_services/seguimiento-cartera.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-reingreso-pago-cuota-inscripcion',
  templateUrl: './reingreso-pago-cuota-inscripcion.component.html',
  styleUrls: ['./reingreso-pago-cuota-inscripcion.component.scss']
})
export class ReingresoPagoCuotaInscripcionComponent implements OnInit, OnChanges {

  //Variables Subcripcion eventemmiter
  public informacionInvocarMetodo: any;

  //#region Constructor
  constructor(private nuevoIngresoService: NuevoIngresoService,
    private snackService: SnackService,
    private reinscripcionService: ReinscripcionService,
    private formBuilder: FormBuilder,
    private pagoLineaZumaService: PagoLineaZumaService,
    private seguimientoCarteraService: SeguimientoCarteraService,
    public utileriasService: UtileriasService,
    private integracionService: IntegracionService) {
    this.generarFormularioVacio();
  }
  //#endregion

  //#regions  
  public costoInscripcion: InscripcionCosto = new InscripcionCosto();
  private ultimoPeriodoValidoSeleccionado: number;
  public fechDateaActual: Date = new Date();
  public inscripcionExistente: boolean = false;
  public inscripcionPagadaEnElPeriodo: boolean = false;
  public cuotaColegiaturaExistente: ApiResponse<ColegiaturaCosto> = null;
  //#endregion
  //#region OutPut

  @Output()
  valuePeriodoResponse: EventEmitter<number> = new EventEmitter<number>();
  //#endregion

  //#region  Inputs
  @Input()
  public periodos: Array<Periodo> = new Array<Periodo>();
  @Input()
  public alumno: AlumnoReingresoDTO;
  @Input()
  public periodosForm: FormGroup;
  //#endregion
  //Variables para las subcripciones
  public subscription: Subscription = new Subscription();
  //Variable para controlar el boton de pagar
  public metodoPagoDisponible: boolean = false;


  //#region  Métodos

  ngOnInit(): void {
    this.ObtenerDatosIntegracion();
    if (this.pagoLineaZumaService.invocarMetodoSubscription == undefined) {
      this.pagoLineaZumaService.invocarMetodoSubscription = this.pagoLineaZumaService.invocarMetodo.subscribe((event) => {
        this.guardarIntentoPago(event.pagoZuma, event.url);
      });
    }
  }
  /**
 * Se genera el formulario vacío ligado al Select de periodos
 */
  private generarFormularioVacio(): void {
    this.periodosForm = this.formBuilder.group({
      periodoInscrito: [0]
    },
      {
        validators: [SelectValidator('periodoInscrito')]
      }
    );
  }

  /**
   * Metodo para obtener los metodos de pago configurados
   */
  public ObtenerDatosIntegracion(): void {
    this.subscription.add(
      this.integracionService.ObtenerDatosIntegracion().subscribe((response: ApiResponse<IntegracionDto[]>) => {
        if (response.success) {
          if (response.data.length > 0) {
            this.metodoPagoDisponible = true;
          } else {
            this.metodoPagoDisponible = false;
          }
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      })
    );
  }

  /**
   * Detecta el cambio de valor en la propiedad input
   * una vez el padre setea el valor
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.periodos.firstChange) {
      this.definirPeriodoActivo();
    }
    this.obtenerInformacionDelPeriodo();
  }
  /**
  * Toma los periodos regresados por el Api y en caso se que haya más de uno 
  * se selecciona el primero existente y lo define como el principal
  */
  private definirPeriodoActivo(): void {
    if (this.periodos != null && this.periodos.length > 0) {
      this.periodosForm.patchValue({
        periodoInscrito: this.periodos[0].periodoId
      });
    }
  }
  /**
 * Obtiene el costo de la inscripción (El pago del semestre, bimestre, tetramestre)
 * @returns Costo de una colegiatura
 */
  public async obtenerInformacionDelPeriodo(): Promise<void> {
    if (this.alumno == null) return;
    if (this.periodos == null) return;
    if (this.periodoActualFiel.invalid) return;
    if (this.ultimoPeriodoValidoSeleccionado == this.periodoActualFiel.value) return;
    this.ultimoPeriodoValidoSeleccionado = this.periodoActualFiel.value;
    let apiCostoInscripcionResponse: ApiResponse<InscripcionCosto> = await this.nuevoIngresoService.ExisteInscripcionCosto(this.alumno.idCampus, this.alumno.idNivel, this.periodoActualFiel.value, this.alumno.idPrograma, 'RI').toPromise();
    if (apiCostoInscripcionResponse.success) {
      if (apiCostoInscripcionResponse.data !== null) {
        this.costoInscripcion = apiCostoInscripcionResponse.data;
        this.inscripcionExistente = true;
        this.inscripcionPagadaEnElPeriodo = await this.nuevoIngresoService.ValidarInscripcionPagada(this.alumno.idAlumno, this.periodoActualFiel.value).toPromise();
      } else {
        this.inscripcionExistente = false;
        this.inscripcionPagadaEnElPeriodo = false;
      }
    }
    else {
      this.snackService.mostrarSnackBack(apiCostoInscripcionResponse.message);
      return;
    }
    this.cuotaColegiaturaExistente = await this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.alumno.idCampus, this.alumno.idNivel, this.periodoActualFiel.value, this.alumno.idPrograma).toPromise();
    this.reinscripcionService.cambiarPeriodoSeleccionado(this.inscripcionExistente, this.inscripcionPagadaEnElPeriodo, this.periodoActualFiel.value, this.cuotaColegiaturaExistente.data === null ? false : true);
    this.valuePeriodoResponse.emit(this.periodoActualFiel.value);
  }

  /**
   * Metodo que hara la accion de pagar con servicios de ZUMA
   */
  public async registrarMovimiento(): Promise<void> {

    let apiValidarCargoInscripcionResponse: ApiResponse<ValidarInscripcionExistente> = await this.seguimientoCarteraService.validarCargoInscripcion(this.alumno.idUsuario, this.alumno.idAlumno, this.periodoActualFiel.value).toPromise();
    if (apiValidarCargoInscripcionResponse.success) {
      if (!apiValidarCargoInscripcionResponse.data.existeCargoInscripcion) {
        await this.guardarInscripcionAlumno();
      }
      if (apiValidarCargoInscripcionResponse.data.existeCargoInscripcion) {
        await this.enviarPagoZuma();
      }
    } else {
      this.snackService.mostrarSnackBack(apiValidarCargoInscripcionResponse.message);
    }
  }
  /**
   * Metodo que abre el componente modal para hacer el pago con zuma
   */
  private async guardarInscripcionAlumno(): Promise<void> {
    const inscripcion: CargoDTO = new CargoDTO();
    inscripcion.usuarioId = this.alumno.idUsuario;
    inscripcion.tipoCargoId = 2;
    inscripcion.monto = this.costoInscripcion.costo;
    inscripcion.descripcion = `Inscripción ${this.alumno.nombreCompleto}`;
    inscripcion.fechaVencimiento = this.costoInscripcion.fechaInicio;
    inscripcion.alumnoId = this.alumno.idAlumno;
    inscripcion.periodoId = this.periodoActualFiel.value;
    inscripcion.periodoSeleccionadoInscripcion = this.periodoActualFiel.value;
    let apiInscripcionResponse = await this.seguimientoCarteraService.enviarInscripcion(inscripcion).toPromise();
    if (apiInscripcionResponse.success) {
      await this.enviarPagoZuma();
    }
    else {
      this.snackService.mostrarSnackBack(apiInscripcionResponse.message);
    }
  }
  /**
  * Metodo que llama el api de zuma y genera el folio para hacer el pago
  */
  private async enviarPagoZuma(): Promise<void> {
    const pagoZuma: PagoZuma = {
      reference: "Pago de Inscripción",
      amount: this.costoInscripcion.costo,
      promotion: "1",
      productCode: "AWPE_RPVT_XUG",
      expire: 1,
      sendLink: false,
      email: "",
      paymentChannel: "ZUMA_API"
    }
    this.utileriasService.abrirModalPagoZuma(PagoZumaModalComponent, pagoZuma);
  }
  /**
   * Metodo que sirve para guardar el intento pago junto con una consulta que obtiene el cargo para enviarlo
   */
  private async guardarIntentoPago(pagoZuma: PagoZuma, respuestaPagoZuma: PagoZumaResponse): Promise<void> {
    let apiCargoInscripcion: ApiResponse<EstadoCuentaDto> = await this.seguimientoCarteraService.obtenerCargoInscripcionPendiente(this.alumno.idUsuario, this.periodoActualFiel.value, this.alumno.idAlumno).toPromise();
    if (apiCargoInscripcion.success) {
      const cargo: Array<EstadoCuentaDto> = new Array<EstadoCuentaDto>();
      apiCargoInscripcion.data.motorPagoId = 1;
      cargo.push(apiCargoInscripcion.data);
      let pagoIntento: IntentoPagoZumaDTO = {
        cargos: cargo,
        pagoZuma: pagoZuma,
        pagoZumaResponse: respuestaPagoZuma
      }
      let apiPostPagoResponse = await this.pagoLineaZumaService.PostIntentoPago(pagoIntento).toPromise();
      if (apiPostPagoResponse.success) {
        console.log("CORRECTO");
      }
      else {
        this.snackService.mostrarSnackBack("Ha ocurrido un error al guardar el intento pago. Por favor, intente de nuevo más tarde.");
      }
    }
    else {
      this.snackService.mostrarSnackBack("Ha ocurrido un error al obtener el cargo de inscripción. Por favor, intente de nuevo más tarde.");
    }
  }
  //#endregion

  //#region  Gets
  get periodoActualFiel() {
    return this.periodosForm.get('periodoInscrito');
  }
  //#endregion

  /**
  * Se manda a llamar cuando se cierra el componente y se desuscribe de todos los select elegidos
  */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
