import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { ModalRegistroComponent } from 'src/app/_components/modal-registro/modal-registro.component';
import { AdmisionRegistro } from 'src/app/_models/admisionRegistro';
import { Campus } from 'src/app/_models/campus';
import { Periodo } from 'src/app/_models/periodo';
import { Programa } from 'src/app/_models/programa';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { baseUrl, environment } from 'src/environments/environment';
import { CampusProgramaDTO } from 'src/app/_models/CampusProgramaDTO';

@Component({
  selector: 'app-registrar-solicitante',
  templateUrl: './registrar-solicitante.component.html',
  styleUrls: ['./registrar-solicitante.component.scss']
})
export class RegistrarSolicitanteComponent implements OnInit, OnDestroy {

  //Subscripciones
  private Subscription: Subscription = new Subscription();
  //Variables Catalogos
  public campusList: CampusProgramaDTO[] = [];
  public periodoList: Periodo[] = [];
  public programaList: Programa[] = [];
  //Variables Formulario
  public registroForm: FormGroup;
  //Variables ver contrasena
  public passwordTipoTexto: boolean = false;
  public passwordCTipoTexto: boolean = false;
  //Variable tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;

  constructor(
    public dialogRef: MatDialogRef<RegistrarSolicitanteComponent>,
    private admisionesService: AdmisionesService,
    private snackService: SnackService,
    private utileriaService: UtileriasService
  ) { }

  ngOnInit(): void {
    this.ObtenerCampusByUrl();
    this.InicializarForm();
  }

  /**
   * Metodo que incializa el formulario para hacer el registro de nueva cuenta
   */
  public InicializarForm() {
    this.registroForm = new FormGroup({
      correoElectronico: new FormControl('', [Validators.required, Validators.email]),
      nombre: new FormControl('', [Validators.required]),
      segundoNombre: new FormControl(''),
      apellidoPaterno: new FormControl('', [Validators.required]),
      apellidoMaterno: new FormControl(''),
      prefijo: new FormControl(''),
      fechaNacimiento: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()¡¿=|?]/), this.Parameters]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()¡¿=|?]/), this.Parameters]),
      telefono: new FormControl(''),
      campus: new FormControl(0, [Validators.required, this.ValidateId]),
      // preferenciaContacto: new FormControl(0, [Validators.required, this.ValidateId]),
      programaAcademico: new FormControl(0, [Validators.required, this.ValidateId]),
      periodo: new FormControl(0, [Validators.required, this.ValidateId]),
      terminosCondiciones: new FormControl(false, [Validators.required])
    });
    this.registroForm.get("campus").valueChanges.subscribe(campusId => {
      this.registroForm.patchValue({
        programaAcademico: 0,
        periodo: 0
      });
      if (campusId !== 0) {
        this.OnCampusSelected(this.campus.value);
      } else {
        this.registroForm.patchValue({
          programaAcademico: 0,
          periodo: 0
        });
      }
    });

    this.registroForm.get("programaAcademico").valueChanges.subscribe(programaId => {
      this.registroForm.patchValue({
        periodo: 0
      });
      if (programaId !== 0) {
        this.OnProgramaSelected();
      } else {
        this.registroForm.patchValue({
          periodo: 0
        });
      }
    });

  }

  /**
   * Variables para validaciones FormGroup
   */
  get correoElectronico() { return this.registroForm.get('correoElectronico'); }
  get nombre() { return this.registroForm.get('nombre'); }
  get segundoNombre() { return this.registroForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.registroForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.registroForm.get('apellidoMaterno'); }
  get prefijo() { return this.registroForm.get('prefijo'); }
  get fechaNacimiento() { return this.registroForm.get('fechaNacimiento'); }
  get password() { return this.registroForm.get('password'); }
  get passwordConfirm() { return this.registroForm.get('passwordConfirm'); }
  get telefono() { return this.registroForm.get('telefono'); }
  // get preferenciaContacto() { return this.registroForm.get('preferenciaContacto'); }
  get campus() { return this.registroForm.get('campus'); }
  get programaAcademico() { return this.registroForm.get('programaAcademico'); }
  get periodo() { return this.registroForm.get('periodo'); }
  get terminosCondiciones() { return this.registroForm.get('terminosCondiciones'); }

  /**
   * Parametros para comporbar contrasena correcta
   * @param control
   * @returns 
   */
  private Parameters(control: AbstractControl): { [key: string]: any } | null {
    let regExp = /[ÑÁÉÍÓÚñáéíóú]/.test(control.value);
    return regExp ? { 'password': { value: control.value } } : null;
  }

  /**
   * Metodo que valida si los select tiene algo seleccionado
   * @param control 
   * @returns 
   */
  private ValidateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Metodo para traer el mensaje de error y mostrarlo en el html
   * @param controlName 
   * @returns 
   */
  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.registroForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  /**
   * Metodo para ver contraseña
   */
  public mostrarContrasena() {
    this.passwordTipoTexto = !this.passwordTipoTexto;
  }

  /**
   * Metodo para ver contraseña confuirmacion
   *  */
  public mostrarContrasenaC() {
    this.passwordCTipoTexto = !this.passwordCTipoTexto;
  }

  /**
   * Metodo para obtener el catalogo de campus
   */
  public ObtenerCampusByUrl() {
    this.Subscription.add(
      this.admisionesService.ObtenerCampusByUrl(this.tenantId).subscribe(
        (campus: CampusProgramaDTO[]) => {
          this.campusList = campus;
        })
    );
  }

  /**
   * Metodo que se activa cuando el select selecciona un campus diferente
   * @param campus 
   */
  public OnCampusSelected(campus: number) {
    this.programaList = [];
    if (!!campus) {
      this.Subscription.add(
        this.admisionesService.ObtenerProgramasByUrl(this.tenantId,campus).subscribe(
          (programas: Programa[]) => {
            this.programaList = programas;
          })
      );
    }
  }

  /**
   * Metodo para obtener el catalogo de periodos
   */
  public OnProgramaSelected() {
    if (this.programaAcademico.value !== 0 && this.campus.value !== 0) {
      this.Subscription.add(
        this.admisionesService.ObtenerPeriodoByUrl(this.tenantId, this.programaAcademico.value, this.campus.value).subscribe(
          (periodo: Periodo[]) => {
            this.periodoList = periodo;
          })
      );
    }
  }

  /**
   * Metodo para enviar la info del formulario al servicio
   */
  public EnviarResgistroAdmision() {
    let infoUsuario: AdmisionRegistro = Object.assign(this.registroForm.value);
    infoUsuario.url = this.tenantId;
    infoUsuario.estatusCuenta = 2;
    this.admisionesService.EnviarResgistroAdmision(infoUsuario, this.tenantId).subscribe((respuesta: SimpleResponse) => {
      if (respuesta.success) {
        this.utileriaService.abrirModalRegistro(ModalRegistroComponent, respuesta.message);
      } else {
        this.snackService.mostrarSnackBack(respuesta.message);
      }
    });
  }

  /**
   * Metodo cerrar el modal
   */
  public CerrarModal(): void {
    this.dialogRef.close();
  }

  /**
   * Metodo que se activa cuando se cierra el componente
   */
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }
}
