import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../../_services/authentication.service';
import { UsuarioService } from '../../../_services/usuario.service';

@Component({
  selector: 'app-change-password-n',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponentN implements OnInit {

  public cuentaGenerada: boolean = false;
  public errorActualizacion: string = "";

  public actualizaPasswordForm: FormGroup = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,16})$/)]),
    passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,16})$/)])
  });

  get oldPassword() {
    return this.actualizaPasswordForm.get("oldPassword");
  }

  get password() { return this.actualizaPasswordForm.get('password'); }

  get passwordConfirm() {
    return this.actualizaPasswordForm.get("passwordConfirm"); }

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponentN>,
    private registroService: AuthenticationService,
  ) { }

  ngOnInit(): void {
  }

  public enviar(): void {
    this.errorActualizacion = "";

    console.log("actualizaPasswordForm", this.actualizaPasswordForm.value);

    this.registroService.actualizarPassword(
      this.actualizaPasswordForm.get("oldPassword").value,
      this.actualizaPasswordForm.get("password").value
    ).then(guardado => {
      if (!!guardado) {
        this.cuentaGenerada = true;
        this.dialogRef.close(true);
      } else {
        this.errorActualizacion = "Ocurrió un error al crear la cuenta. Por favor, intenta más tarde.";
      }
    });
  }

}
