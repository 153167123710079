import { ModalEliminarAreaMateriaComponent } from './modal-eliminar-area-materia/modal-eliminar-area-materia.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UtileriasService } from '../_services/utilerias.service';
import { AccionEnum } from '../_models/accion.enum';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AreaMateriaService } from '../_services/area-materia-service';
import { MateriaService } from '../_services/materia.service';
import { AreaMateria } from '../_models/area-materia';
import { ModalAreaMateriaComponent } from './modal-area-materia/modal-area-materia.component';

@Component({
  selector: 'app-area-materia',
  templateUrl: './area-materia.component.html',
  styleUrls: ['./area-materia.component.scss']
})
export class AreaMateriaComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns: string[] = ['select', 'clave', 'nombre'];
  public dataSource: MatTableDataSource<any>;
  public selection = new SelectionModel<any>(true, []);
  public departamento: any;
  public infoCargado = false;
  public numDepartamentoSeleccionado: number = 0;
  public claveName = new FormControl();
  private arrOriginal: any[] = [];
  public ExcelSubscription: Subscription;

  constructor(public util: UtileriasService,
    private areaMateriaService: AreaMateriaService,
    private materiaService: MateriaService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
  ) {
    this.obtenerAreas();
  }

  ngOnInit() {
    if (this.areaMateriaService.invMetodoObtenerAreaMateriaSubscription === undefined) {
      this.areaMateriaService.invMetodoObtenerAreaMateriaSubscription = this.areaMateriaService.invocarObtenerAreaMateria.subscribe(() => {
        this.obtenerAreas();
      });
      this.paginador.itemsPerPageLabel = "Registros por página";
      this.paginador.nextPageLabel = "Página siguiente";
      this.paginador.previousPageLabel = "Página anterior";
      this.paginador.firstPageLabel = "Primera página";
      this.paginador.lastPageLabel = "Última página";

      this.paginador.getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
          return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
    }
    this.obtenerAreas();
  }

  /**
   * Obtener Areas
   */
  public obtenerAreas() {
    this.materiaService.obtenerAreaMateria().subscribe((departamento: AreaMateria[]) => {
      this.dataSource = new MatTableDataSource(departamento);
      this.selection = new SelectionModel<any>(true, []);
      this.dataSource.paginator = this.paginator;
      this.infoCargado = true;
      this.arrOriginal = [...departamento];
    }
    )
  }

  /**
   * Metodo Crear
   */
  public crear(): void {
    let datos: AreaMateria = {
      areaMateriaId: 0,
      clave: '',
      nombre: '',
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(ModalAreaMateriaComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.departamento = result;
      }
    })
  }

  /**
   * Metodo Editar
   * @param elemento 
   */
  public editar(elemento?: AreaMateria): void {
    let datos: AreaMateria = {
      areaMateriaId: elemento.areaMateriaId,
      clave: elemento.clave,
      nombre: elemento.nombre,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(ModalAreaMateriaComponent, datos);
  }

  /**
   * Metodo Borrar
   * @param btnDataTable 
   * @param elemento 
   */
  public borrar(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogEliminar = this.dialog.open(ModalEliminarAreaMateriaComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
    dialogEliminar.afterClosed().subscribe(result => {
      if (!!result) {
      }
    });
  }

  public filtro() {
    let claveNombre: string = this.claveName === null ? '' : this.claveName.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    filtroModificado = filtroModificado.filter(f => {
      return f.clave.toLowerCase().search(claveNombre.toLocaleLowerCase()) > -1 || f.nombre.toLowerCase().search(claveNombre.toLowerCase()) > -1;
    });
    this.dataSource = new MatTableDataSource(filtroModificado);
  }

  limpiarFiltros() {
    this.claveName.setValue('');
    this.obtenerAreas();
  }

  isAllSelected() {
    this.numDepartamentoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numDepartamentoSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row))
  }

  /**
   * Descaarga de Excel
   */
  public descargarExcel() {
    this.ExcelSubscription = this.areaMateriaService.downloadExcelAreaMateria(this.arrOriginal).subscribe((res: Blob) => {
      var filename = "Consultar-AreaMateria.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

}
