import { NuevoIngresoService } from '@app/_services/nuevo-ingreso.service';
import { UtileriasService } from '../../../../app/_services/utilerias.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Genero } from '../../../../app/_models/genero';
import { GeneroService } from '../../../../app/_services/genero.service';
import { AvanceCursoInformacionAlumno } from './../../../_models/AvanceCursoInformacionAlumno';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-datos-personales',
  templateUrl: './modal-datos-personales.component.html',
  styleUrls: ['./modal-datos-personales.component.scss']
})
export class ModalDatosPersonalesComponent implements OnInit {

  @Input() data: AvanceCursoInformacionAlumno;
  public datosPersonalesForm: FormGroup;
  public sexoList: Genero[] = [];
  public changesPersonalData: boolean = false;

  constructor(private generoService: GeneroService,
    private utileriasService: UtileriasService,
    public nuevoIngresoService: NuevoIngresoService) { }

  ngOnInit(): void {
    this.getGeneros();
    this.inicializarForm();
  }

  /**
    * Ejecuta el método del Api que retorna los géneros existentes
    */
  public getGeneros(): void {
    this.generoService.obtenerGenero().subscribe((generos: Array<Genero>) => {
      this.sexoList = generos;
    });
  }

  /**
    * Se inicializa el formulario con los dos datos cargados 
    */
  public inicializarForm() {
    this.datosPersonalesForm = new FormGroup({
      usuarioId: new FormControl(this.data.usuarioId),
      nombre: new FormControl(this.data.nombre, [Validators.required]),
      segundoNombre: new FormControl(this.data.segundoNombre),
      apellidoPaterno: new FormControl(this.data.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(this.data.apellidoMaterno),
      fechaNacimiento: new FormControl(this.data.fechaNacimiento === null ? '' : this.utileriasService.formatearFecha(this.data.fechaNacimiento, 'YYYY[-]MM[-]DD'), [Validators.required]),
      correo: new FormControl(this.data.correo, [Validators.required, Validators.email]),
      legalGeneroId: new FormControl(this.data.sexoLegalId === 0 ? 0 : this.data.sexoLegalId, [Validators.required, this.validateId])
    });
    this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.datosPersonalesForm.valid);
    this.datosPersonalesForm.valueChanges.subscribe(val => {
      this.changesPersonalData = true;
      this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.datosPersonalesForm.valid);
    });
  }
  get usuarioId() { return this.datosPersonalesForm.get('usuarioId'); }
  get nombre() { return this.datosPersonalesForm.get('nombre'); }
  get segundoNombre() { return this.datosPersonalesForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.datosPersonalesForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.datosPersonalesForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.datosPersonalesForm.get('fechaNacimiento'); }
  get correo() { return this.datosPersonalesForm.get('correo'); }
  get legalGeneroId() { return this.datosPersonalesForm.get('legalGeneroId'); }

  /**
   * Metodo para validar si el campo de genero esta vacio
   * @param controlName 
   * @returns 
   */
  public getErrorFormSexo(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Sexo Legal es requerido.";
    }
    return error;
  }

  /**
   * Metodo para validar si los campos tienen datos seleccionados
   * @param control 
   * @returns 
   */
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

}
