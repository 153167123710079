import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UtileriasService } from '../../_services/utilerias.service';
import { TemaModalComponent } from './modals/tema-modal/tema-modal.component';
import { RecursoModalComponent } from './modals/recurso-modal/recurso-modal.component';
import { ParcialesModalComponent } from './modals/parciales-modal/parciales-modal.component';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource} from '@angular/material/tree';
import { FlatTreeControl, NestedTreeControl} from '@angular/cdk/tree';
import { EnsenanzaAprendizajeService } from '../../_services/ensenanza-aprendizaje.service';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Recurso } from 'src/app/_models/recurso';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Principios básicos', // aqui iria el módulo o tema 
    children: [
      {
        name: 'Leyes de los signos', //aqui iria el recurso 
        children: [
          {name: 'Leyes_signos.pdf'}, // los archivos o los enlaces 
          {name: 'Leyes de los signos'},
        ]
      }, {
        name: 'Tarea 1 - Ejercicios básicos',
        children: [
          {name: 'Tarea_1.docx'},
        ]
      },
    ]
  },
];

@Component({
  selector: 'app-contenido-curso',
  templateUrl: './contenido-curso.component.html',
  styleUrls: ['./contenido-curso.component.scss']
})

export class ContenidoCursoComponent implements OnInit {
  public grupoSeleccionado$: Observable<any>;
  public grupoSeleccionado: any;
  treeControl = new NestedTreeControl<FoodNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();

  @Input() grupoID: number;

  constructor( public util: UtileriasService,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,) {
    this.dataSource.data = TREE_DATA;
  }
  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.grupoSeleccionado$ = this.ensenanzaAprendizajeService.obtenerGrupoSeleccionado$();
    this.grupoSeleccionado$.subscribe(datos =>{
      this.grupoSeleccionado = datos;
    });
  }
  llenarTreeParciales(idGrupo:number){
   this.ensenanzaAprendizajeService.obtenerParcialesRecursos(idGrupo).subscribe((res: ApiResponse<Recurso[]>)=>{

   })
  }
  abrirModalTema(){
    this.util.abrirDialogoLateral(TemaModalComponent);
  }
  abrirModalParcial(){
    this.util.abrirDialogoLateral(ParcialesModalComponent);
  }
  abrirModalRecurso(){
    this.util.abrirDialogoLateral(RecursoModalComponent);
  }

}
