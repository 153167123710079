import { CompraCursosCompraRealizadaComponent } from './compra-cursos-compra-realizada/compra-cursos-compra-realizada.component';
import { CompraCursosCarritoComponent } from './compra-cursos-carrito/compra-cursos-carrito.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoriasCursosComponent } from './categorias-cursos/categorias-cursos.component';
import { CompraCursoFichaCursoComponent } from './compra-curso-ficha-curso/compra-curso-ficha-curso.component';
import { ResultadosCursosComponent } from './resultados-cursos/resultados-cursos.component';

const routes: Routes = [
  { path: 'compra-cursos/categorias-cursos', component: CategoriasCursosComponent },
  { path: 'compra-cursos/resultados-cursos', component: ResultadosCursosComponent },
  { path: 'compra-cursos/ficha-curso/:cursoId', component: CompraCursoFichaCursoComponent },
  { path: 'compra-cursos/ficha-curso/:cursoId/carrito', component: CompraCursosCarritoComponent },
  { path: 'compra-cursos/ficha-curso/:cursoId/compra-realizada', component: CompraCursosCompraRealizadaComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompraCursosRoutingModule { }
