import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { EstadoCivil } from '../_models/estado-civil';

@Injectable({
  providedIn: 'root'
})
export class EstadoCivilService {

  constructor(private httpClient: HttpClient) { }

  //Get Catalogo Estado Civil
  public obtenerEstadoCivil(): Observable<EstadoCivil[]> {
    return this.httpClient.get<EstadoCivil[]>(`${environment.enves[baseUrl].apiUrl}/EstadoCivil`);
  }
}
