import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { baseUrl, environment } from '@environments/environment';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { AttributesDto } from 'src/app/_models/AttributesDto';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { AttributesGuardarDto } from '../_models/AttributesDto';

@Injectable({
  providedIn: 'root'
})
export class AttributesService {

  invocarObtenerAttributes = new EventEmitter();
  obtenerAttributesSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  /**
   * Servicio para traer los atributos por institucion
   * @returns 
   */
  public attributesInstitucion(): Observable<ApiResponse<AttributesDto[]>> {
    return this.httpClient.get<ApiResponse<AttributesDto[]>>(`${environment.enves[baseUrl].apiUrl}/Attributes/AttributesInstitucion`);
  }

  /**
   * Servicio para guardar los atributos por alumno
   * @returns 
   */
  public guardarAtributosAlumno(Atributos: AttributesGuardarDto): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Attributes/GuardarAtributosAlumno`, Atributos);
  }

  /**
   * Servicio para guardar y editar un atributo
   * @returns 
   */
  public atributoAgregarEditar(Atributo: AttributesDto): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Attributes/AtributoAgregarEditar`, Atributo);
  }

  /**
   * Servicio para eliminar atributos
   * @param AtributoId 
   * @returns 
   */
  public eliminarAtributo(AtributoId: string): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Attributes/EliminarAtributo/${AtributoId}`);
  }

  /**
   * Descargar excel atributos
   * @param atributos 
   * @returns 
   */
  public downloadExcelAtributos(atributos: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Attributes/DownloadExcelAtributo`, JSON.stringify(atributos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  /**
   * Metodo para llamar el servicio para actualizar la tabla de seguimiento de atributos
   */
  public metodoObtenerAtributo() {
    this.invocarObtenerAttributes.emit();
  }
}
