import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContenidoMiCursoComponent } from '../../contenido/contenido-mi-curso/contenido-mi-curso.component';
import { EnsenanzaAprendizajeService } from '../../../_services/ensenanza-aprendizaje.service';
import { SnackService } from 'src/app/services/snack-service.service';

@Component({
  selector: 'app-modal-detalle-eliminar',
  templateUrl: './modal-detalle-eliminar.component.html',
  styleUrls: ['./modal-detalle-eliminar.component.scss']
})
export class ModalDetalleEliminarComponent implements OnInit {

  constructor(
    public dialogRevisionRef: MatDialogRef<ContenidoMiCursoComponent>,
    private ensenanzaServ: EnsenanzaAprendizajeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService) { }

  ngOnInit(): void {

  }

  public cerrarModal() {
    this.dialogRevisionRef.close();
  }
  eliminar(){
    if(this.data.esRecurso){
      this.ensenanzaServ.eliminarRecurso(this.data.recurso.recursoId).subscribe(res=>{
        this.ensenanzaServ.invocarMetodoObtenerContenido();
        this.ensenanzaServ.invocarMetodoPorRevisar();
        this.snackService.mostrarSnackBack(res.message);
      });
    }
    else{
      this.ensenanzaServ.eliminarTema(this.data.modulo.moduloId).subscribe(res=>{
        this.ensenanzaServ.invocarMetodoObtenerContenido();
        this.ensenanzaServ.invocarMetodoPorRevisar();
        this.snackService.mostrarSnackBack(res.message);
      });
    }
    this.dialogRevisionRef.close();
  }
}
