import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PersonaRelacionada } from '../../../../_models/persona-relacionada';
import { ProfesorService } from '../../../../_services/profesor.service';

@Component({
  selector: 'app-datos-familiares-profesor',
  templateUrl: './datos-familiares-profesor.component.html',
  styleUrls: ['./datos-familiares-profesor.component.scss']
})
export class DatosFamiliaresProfesorComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>
  public errorActualizaProfesor: string = "";
  public dialogRefAdver: any;
  public familiarForm: FormGroup;
  public emergenciaForm: FormGroup;
  public tipoAccion: number;
  public boolCambio: boolean = false;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public renderizarForm: boolean = false;

  public tipoAccionFamiliarPapa: any;
  public tipoAccionFamiliarMama: any;
  public tipoAccionFamiliarConyuge: any;
  public tipoAccionFamiliarEmergencia: any;

  convertir = false;
  convertirPadre = false;
  convertirMadre = false;
  convertirConyuge = false;

 public contactoEmergenciaBloqueado = false;

  constructor(public dialogProfesorRef: MatDialogRef<DatosFamiliaresProfesorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PersonaRelacionada,
    private profesorService: ProfesorService,
    private dialog: MatDialog  ) { }

  public renderizarFormulario() {
    if (this.datosUsuarioBusqueda.personaRelacionadaUsuario.length >= 1) {
      this.tipoAccionFamiliarPapa = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1);
      this.tipoAccionFamiliarMama = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2);
      this.tipoAccionFamiliarConyuge = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 5) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 5);
      this.tipoAccionFamiliarEmergencia = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 4) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 4);
      this.profesorService.setChangesFamiliares(false);
    } else {
      this.tipoAccionFamiliarPapa = null;
      this.tipoAccionFamiliarMama = null;
      this.tipoAccionFamiliarConyuge = null;
      this.tipoAccionFamiliarEmergencia = null;
    }
    this.inicializarForm();
    this.profesorService.setChangesFamiliares(false);
  }

  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.profesorService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
    //this.AsignacionSlide();
    this.profesorService.setChangesFamiliares(false);
  }

  ngAfterViewInit(): void {
    //this.AsignacionSlide();
    this.profesorService.setChangesFamiliares(false);
  }

  public inicializarForm() {
    this.familiarForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      //Datos Familiares
      estatusPadre: new FormControl(false),
      nombreCompletoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.nombreCompleto),
      telefonoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correoElectronicoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.correoElectronico, [Validators.email]),

      estatusMadre: new FormControl(false),
      nombreCompletoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.nombreCompleto),
      telefonoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correoElectronicoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.correoElectronico, [Validators.email]),

      estatusConyuge: new FormControl(false),
      nombreCompletoConyuge: new FormControl(this.tipoAccionFamiliarConyuge === null ? '' : this.tipoAccionFamiliarConyuge.nombreCompleto),
      telefonoConyuge: new FormControl(this.tipoAccionFamiliarConyuge === null ? '' : this.tipoAccionFamiliarConyuge.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correoElectronicoConyuge: new FormControl(this.tipoAccionFamiliarConyuge === null ? '' : this.tipoAccionFamiliarConyuge.correoElectronico, [Validators.email]),

      nombreContacto: new FormControl(this.tipoAccionFamiliarEmergencia === null ? '' : this.tipoAccionFamiliarEmergencia.nombreCompleto),
      telefonoContacto: new FormControl(this.tipoAccionFamiliarEmergencia === null ? '' : this.tipoAccionFamiliarEmergencia.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correoContacto: new FormControl(this.tipoAccionFamiliarEmergencia === null ? '' : this.tipoAccionFamiliarEmergencia.correoElectronico, [Validators.email]),
    });

    this.renderizarForm = true;
    this.AsignacionSlide();
    this.profesorService.setChangesFamiliares(false);
    
    this.familiarForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
      this.profesorService.definirFormularioValidoNuevoProfesor(this.familiarForm.valid);
      //this.profesorService.setChangesFamiliares(true);
    });
    //Padre
    this.familiarForm.get("nombreCompletoPadre").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    this.familiarForm.get("telefonoPadre").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    this.familiarForm.get("correoElectronicoPadre").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    //Madre
    this.familiarForm.get("nombreCompletoMadre").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    this.familiarForm.get("telefonoMadre").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    this.familiarForm.get("correoElectronicoMadre").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    //Conyuge
    this.familiarForm.get("nombreCompletoConyuge").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    this.familiarForm.get("telefonoConyuge").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    this.familiarForm.get("correoElectronicoConyuge").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    //Contacto Emergencia
    this.familiarForm.get("nombreContacto").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    this.familiarForm.get("telefonoContacto").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    this.familiarForm.get("correoContacto").valueChanges.subscribe(() => {
      this.profesorService.setChangesFamiliares(true);
    });
    //Como evaluar cambios
    //https://www.tektutorialshub.com/angular/valuechanges-in-angular-forms/
  }

  //Datos Familiares
  //get estatusPadre() { return this.familiarForm.get('estatusPadre'); }
  get nombreCompletoPadre() { return this.familiarForm.get('nombreCompletoPadre'); }
  get telefonoPadre() { return this.familiarForm.get('telefonoPadre'); }
  get correoElectronicoPadre() { return this.familiarForm.get('correoElectronicoPadre'); }

  //get estatusMadre() { return this.familiarForm.get('estatusMadre'); }
  get nombreCompletoMadre() { return this.familiarForm.get('nombreCompletoMadre'); }
  get telefonoMadre() { return this.familiarForm.get('telefonoMadre'); }
  get correoElectronicoMadre() { return this.familiarForm.get('correoElectronicoMadre'); }

  //get estatusConyuge() { return this.familiarForm.get('estatusConyuge'); }
  get nombreCompletoConyuge() { return this.familiarForm.get('nombreCompletoConyuge'); }
  get telefonoConyuge() { return this.familiarForm.get('telefonoConyuge'); }
  get correoElectronicoConyuge() { return this.familiarForm.get('correoElectronicoConyuge'); }

  //Contacto Emergencia
  get nombreContacto() { return this.familiarForm.get('nombreContacto'); }
  get telefonoContacto() { return this.familiarForm.get('telefonoContacto'); }
  get correoContacto() { return this.familiarForm.get('correoContacto'); }

  public activarContacto(event: any, familiar: any) {
    if (event.checked) {
      if (familiar === 'madre'){
        this.convertirPadre = false;
        this.convertirMadre = true;
        this.convertirConyuge = false;
  
      }

      if (familiar === 'padre'){
        this.convertirPadre = true;
        this.convertirMadre = false;
        this.convertirConyuge = false;
      }

      if (familiar === 'conyuge'){
        this.convertirPadre = false;
        this.convertirMadre = false;
        this.convertirConyuge = true;
      }

      this.familiarForm.controls['nombreContacto'].disable();
      this.familiarForm.controls['telefonoContacto'].disable();
      this.familiarForm.controls['correoContacto'].disable();

    }else{
      this.familiarForm.controls['nombreContacto'].enable();
      this.familiarForm.controls['telefonoContacto'].enable();
      this.familiarForm.controls['correoContacto'].enable();
    }
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogProfesorRef.close(null);
    }
  }

  public AsignacionSlide() {
    if (this.tipoAccionFamiliarPapa?.nombreCompleto == this.tipoAccionFamiliarEmergencia?.nombreCompleto && this.tipoAccionFamiliarPapa?.telefonoPadre == this.tipoAccionFamiliarEmergencia?.telefonoContacto && this.tipoAccionFamiliarPapa?.correoElectronicoPadre == this.tipoAccionFamiliarEmergencia?.correoContacto) {
      this.convertirPadre = true;
      this.familiarForm.controls['nombreContacto'].disable();
      this.familiarForm.controls['telefonoContacto'].disable();
      this.familiarForm.controls['correoContacto'].disable();
      this.profesorService.setChangesFamiliares(false);
    }
    if (this.tipoAccionFamiliarMama?.nombreCompleto == this.tipoAccionFamiliarEmergencia?.nombreCompleto && this.tipoAccionFamiliarMama?.telefonoMadre == this.tipoAccionFamiliarEmergencia?.telefonoContacto && this.tipoAccionFamiliarMama?.correoElectronicoMadre == this.tipoAccionFamiliarEmergencia?.correoContacto) {
      this.convertirMadre = true;
      this.familiarForm.controls['nombreContacto'].disable();
      this.familiarForm.controls['telefonoContacto'].disable();
      this.familiarForm.controls['correoContacto'].disable();
      this.profesorService.setChangesFamiliares(false);
    }
    if (this.tipoAccionFamiliarConyuge?.nombreCompleto == this.tipoAccionFamiliarEmergencia?.nombreCompleto && this.tipoAccionFamiliarConyuge?.telefonoConyuge == this.tipoAccionFamiliarEmergencia?.telefonoContacto && this.tipoAccionFamiliarConyuge?.correoElectronicoConyuge == this.tipoAccionFamiliarEmergencia?.correoContacto) {
      this.convertirConyuge = true;
      this.familiarForm.controls['nombreContacto'].disable();
      this.familiarForm.controls['telefonoContacto'].disable();
      this.familiarForm.controls['correoContacto'].disable();
      this.profesorService.setChangesFamiliares(false);
    }
    this.profesorService.setChangesFamiliares(false);
  }
}
