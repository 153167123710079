import { TraductorService } from './../_services/traductor.service';
import { SnackService } from './snack-service.service';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GeneracionExcelServiceService {

  constructor(private snackBar: MatSnackBar,
    private snackService: SnackService,
private traductorService: TraductorService) { }

/**
 *
 * @param blobDatos Genera un archivo excel a partir de un blob de datos recibidos
 * @param archivoNombre
 */
  public GenerarExcel(blobDatos: Blob, archivoNombre: string){
    archivoNombre = `${archivoNombre}.xlsx`;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {//Para internet explorer /
      window.navigator.msSaveOrOpenBlob(blobDatos, archivoNombre);
    }
    else {
      let a:HTMLAnchorElement = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      let blob = new Blob([blobDatos], { type: 'application/xlsx' });
      a.href = window.URL.createObjectURL(blob);
      a.download = archivoNombre;
      a.click();
    }
    let mensaje: string = this.traductorService.translate('descargaArchivo');
    this.snackService.mostrarSnackBack(mensaje);
  }

  /**
 *
 * @param blobDatos Genera un archivo pdf a partir de un blob de datos recibidos
 * @param archivoNombre
 */
   public descargarConstancia(blobDatos: Blob, archivoNombre: string, IsZip: boolean){
   if (IsZip) {
    archivoNombre = `${archivoNombre}.zip`;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {//Para internet explorer /
      window.navigator.msSaveOrOpenBlob(blobDatos, archivoNombre);
    }
    else {
      let a:HTMLAnchorElement = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      let blob = new Blob([blobDatos], { type: 'application/zip' });
      a.href = window.URL.createObjectURL(blob);
      a.download = archivoNombre;
      a.click();
    }
    let mensaje: string = this.traductorService.translate('descargaArchivo');
    this.snackService.mostrarSnackBack(mensaje);
   } else {
    archivoNombre = `${archivoNombre}.pdf`;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {//Para internet explorer /
      window.navigator.msSaveOrOpenBlob(blobDatos, archivoNombre);
    }
    else {
      let a:HTMLAnchorElement = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      let blob = new Blob([blobDatos], { type: 'application/pdf' });
      a.href = window.URL.createObjectURL(blob);
      a.download = archivoNombre;
      a.click();
    }
    let mensaje: string = this.traductorService.translate('descargaArchivo');
    this.snackService.mostrarSnackBack(mensaje);
   }
  }
}
