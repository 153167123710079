import { TraductorService } from './../../_services/traductor.service';
import { Component, OnInit, Inject, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Programa } from '@app/_models/programa';
import { ProgramaDatosGeneralesComponent } from '../programa-datos-generales/programa-datos-generales.component';
import { ProgramaRequerimientosGeneralesComponent } from '../programa-requerimientos-generales/programa-requerimientos-generales.component';
import { ProgramaInformacionAcuerdoComponent } from '../programa-informacion-acuerdo/programa-informacion-acuerdo.component';
import { ProgramaPerfilEgresadoComponent } from '../programa-perfil-egresado/programa-perfil-egresado.component';
import { ProgramaObjetivosComponent } from '../programa-objetivos/programa-objetivos.component';
import { AccionEnum } from '../../_models/accion.enum';
import { Subscription, Observable } from 'rxjs';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { MatDialog } from '@angular/material/dialog';
import { CampusService } from 'src/app/_services/campus.service';
import { NivelService } from 'src/app/_services/nivel.service';
import { ModalidadService } from 'src/app/_services/modalidad.service';
import { Campus } from '../../_models/campus';
import { Nivel } from 'src/app/_models/nivel';
import { Modalidad } from 'src/app/_models/modalidad';
import { FormControl } from '@angular/forms';
import { ProgramaAcademicoCoordinadorComponent } from '../programa-academico-coordinador/programa-academico-coordinador.component';
@Component({
  selector: 'app-programa-academico-edicion',
  templateUrl: './programa-academico-edicion.component.html',
  styleUrls: ['./programa-academico-edicion.component.scss']
})
export class ProgramaAcademicoEdicionComponent implements OnInit, OnDestroy {

  @ViewChild('datosGenerales') datosGenerales: ProgramaDatosGeneralesComponent;
  @ViewChild('requerimientosGenerales') requerimientosGenerales: ProgramaRequerimientosGeneralesComponent;
  @ViewChild('informacionAcuerdo') informacionAcuerdo: ProgramaInformacionAcuerdoComponent;
  @ViewChild('datosObjetivos') datosObjetivos: ProgramaObjetivosComponent;
  @ViewChild('datosPerfil') datosPerfil: ProgramaPerfilEgresadoComponent;
  @ViewChild('coordinador') coordinador: ProgramaAcademicoCoordinadorComponent;
  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  public boolDesabilitarTab: boolean = true;
  public datosPrograma: any;
  public tabSeleccionado: number = 0;
  public tabPrevioSeleccionado: number = 0;
  public tabCambio: boolean = false;
  public boolTabDisabled$: Observable<boolean>;
  public boolTabDisabledSubscription: Subscription;
  public boolCambioPendiente$: Observable<boolean>;
  public boolCambio: boolean = false;
  public boolCambioPendienteSubscription: Subscription;
  public dialogoRefAdver: any;
  public campusList: Campus[] = [];
  public nivelList: Nivel[] = [];
  public modalidadList: Modalidad[] = [];
  public tabSeleccionadoIndex: FormControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<ProgramaAcademicoEdicionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private programaAcademicoService: ProgramaAcademicoService,
    private dialog: MatDialog,
    private campusService: CampusService,
    private nivelService: NivelService,
    private modalidadService: ModalidadService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    console.log("DATA", this.data)
    this.boolTabDisabled$ = this.programaAcademicoService.obtenerBoolTab$();
    this.boolTabDisabledSubscription = this.boolTabDisabled$.subscribe(bool => this.boolDesabilitarTab = bool);
    this.boolCambioPendiente$ = this.programaAcademicoService.obtenerBoolCambio$();
    this.boolCambioPendienteSubscription = this.boolCambioPendiente$.subscribe(bool => this.boolCambio = bool);
    this.datosPrograma = this.data;
    if (this.datosPrograma.tipoAccion === AccionEnum.EDITAR) {
      this.programaAcademicoService.definirBoolTab(false);
    }
    this.obtenerCampus();
    this.obtenerNivel();
    this.obtenerModalidad();
    if (this.datosPrograma.solicitudCargaDoc === 5) {
      setTimeout(() => {
        this.tabSeleccionadoIndex.setValue(5);
      }, 500);
    }
  }

  public onChangeTab(event: MatTabChangeEvent) {
    this.tabCambio = true;
    let tab: number = event.index;
    this.tabSeleccionado = tab;
    //if (this.boolCambio) {
    //  this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    //}
  }

  public enviar() {
    switch (this.tabSeleccionado) {
      case 0: //Datos generales
        if (this.data.tipoAccion === AccionEnum.EDITAR) {
          this.datosGenerales.modificarDatosGenerales();
        } else {
          this.datosGenerales.enviarDatosGenerales();
        }
        break;
      case 1: //Requerimientos generales
        this.requerimientosGenerales.enviarRequerimientosGenereales();
        break;
      case 2: //Informacion acuerdo
        this.informacionAcuerdo.enviarInformacionAcuerdo();
        break;
      case 3: //Objetivos
        this.datosObjetivos.modificarDatosObjetivos();
        break;
      case 4: //Datos de perfil
        this.datosPerfil.modificarDatosPerfil();
        break;
      case 5: //Plan de estudios}

        break;
      case 6: //Coordinador
        this.coordinador.EditarCoordinador();
        break;
    }
  }
  // Traer los datos para cambiar la etiqueta
  public obtenerCampus() {
    this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      }
    );
  }

  public obtenerNivel() {
    this.nivelService.getNivel().subscribe(
      (nivel: Nivel[]) => {
        this.nivelList = nivel;
      }
    );
  }

  public obtenerModalidad() {
    this.modalidadService.getModalidad().subscribe(
      (modalidad: Modalidad[]) => {
        this.modalidadList = modalidad;
      }
    );
  }

  public findNombreCampus(Idcampus?: number) {
    if (Idcampus !== 0) {
      return this.campusList?.find?.(id => id.campusId === Idcampus).nombre;
    } else {
      let mensaje: string = this.traductorService.translate('sinCampus');
      return mensaje;
    }
  }

  public findNombreNivel(IdNivel?: number) {
    if (IdNivel !== 0) {
      let mensaje: string = this.traductorService.translate(`_catalogos.nivel.nombre-${IdNivel}`);
      // return this.nivelList?.find?.(id => id.nivelId === IdNivel).nombre;
      return mensaje;
    } else {
      let mensaje: string = this.traductorService.translate('sinNivel');
      return mensaje;
    }
  }

  public findNombreModalidad(IdModalidad?: number) {
    if (IdModalidad !== 0) {
      let mensaje: string = this.traductorService.translate(`_catalogos.modalidad.nombre-${IdModalidad}`);
      // return this.modalidadList?.find?.(id => id.modalidadId === IdModalidad).nombre;
      return mensaje;
    } else {
      let mensaje: string = this.traductorService.translate('sinModalidad');
      return mensaje;
    }
  }
  // Fin

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.dialogRef.close(this.data);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      if (this.tabCambio) {
        //dejar en el tab, cerrar modal de adver, inicializar form y actualizar tabactaual obs
        this.dialogoRefAdver.close();
        this.dialogRef.close(this.data);
      } else {
        this.dialogoRefAdver.close();
        this.dialogRef.close(this.data);
      }
    } else {
      if (this.tabCambio) {
        //mandar tab anterior
        this.tabCambio = false;
        //this.tabPrevioSeleccionado = 0;
      } else {
        this.dialogoRefAdver.close();
      }
    }
  }

  ngOnDestroy() {
    if (this.boolTabDisabledSubscription) {
      this.boolTabDisabledSubscription.unsubscribe();
    }
    if (this.boolCambioPendienteSubscription) {
      this.boolCambioPendienteSubscription.unsubscribe();
    }
    this.programaAcademicoService.definirBoolCambio(false);
  }

}