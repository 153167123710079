import { SimpleResponse } from './../../models/api/SimpleResponse.model';
import { CuponService } from './../../_services/cupon.service';
import { TraductorService } from './../../_services/traductor.service';
import { SnackService } from './../../services/snack-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-eliminar-cupon',
  templateUrl: './modal-eliminar-cupon.component.html',
  styleUrls: ['./modal-eliminar-cupon.component.scss']
})
export class ModalEliminarCuponComponent implements OnInit, OnDestroy {

  //Variable subcripcion
  private subscription: Subscription = new Subscription();
  //Variables eliminar cupones
  public numCuponSeleccionados = 0;
  public boolIsArray = false;
  public claveCupon = '';

  constructor(public dialogRef: MatDialogRef<ModalEliminarCuponComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private traductorService: TraductorService,
    private cuponService: CuponService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  /**
   * Metodo para validar la informacion recibida
   */
  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numCuponSeleccionados = this.data.length;
    }
  }

  /**
   * Metodo para desactivar cupones
   */
  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(d => (d.couponId.toString()));
      this.claveCupon = arrayClaves.toString();
    } else {
      this.claveCupon = this.data.couponId.toString();
    }
    this.subscription.add(
      this.cuponService.eliminarCuponesId(this.claveCupon).subscribe((response: SimpleResponse) => {
        if (response.success) {
          this.cuponService.invocarMetodoObtenerCupones();
          this.dialogRef.close(true);
          setTimeout(() => {
            let mensaje: string = this.traductorService.translate(response.message);
            this.snackService.mostrarSnackBack(mensaje);
          }, 1000);
        } else {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
