import { NivelService } from './../../_services/nivel.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Nivel } from 'src/app/_models/nivel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datos-estudios-admision-express',
  templateUrl: './datos-estudios-admision-express.component.html',
  styleUrls: ['./datos-estudios-admision-express.component.scss']
})
export class DatosEstudiosAdmisionExpressComponent implements OnInit {
  //Variable Formulario
  public datosEstudiosForm: FormGroup;
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variable de catalogo
  public nivelList: Nivel[] = [];



  constructor(private nivelService: NivelService,) { }

  ngOnInit(): void {
    this.getTipoNivel();
    this.inicializarFormDatosEstudios();
  }

  public inicializarFormDatosEstudios() {
    this.datosEstudiosForm = new FormGroup({
      nivel: new FormControl(0, Validators.required),
      academico: new FormControl("", Validators.required),
      promedio: new FormControl("", [Validators.required, Validators.pattern(/^[1-9]+/)]),
    })
  }

  //Estudios Previos
  get nivel() { return this.datosEstudiosForm.get('nivel'); }
  get academico() { return this.datosEstudiosForm.get('academico'); }
  get promedio() { return this.datosEstudiosForm.get('promedio'); }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.datosEstudiosForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido.";
    }
    return error;
  }

  /**
    * Metodo para traer catalogo tipo nivel
    */
  public getTipoNivel() {
    this.subscription.add(
      this.nivelService.getNivel().subscribe(
        (tiposNivel: Nivel[]) => {
          this.nivelList = tiposNivel;
        })
    );
  }



  /**
     * Metodo que se activa cuando se cierra el componente
     */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


}
