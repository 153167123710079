export class ProgramaRequerimientoGenerales {
    ciclos?: number;
    semanaCiclo: number;
    tipoPeriodoId: number;
    totalMateria?: number;
    porcentajeRevalidacion: number;
    escalaCalificacion: number;
    calificacionMinima: number;
    titulacionTesis: boolean;
    titulacionMateria: boolean;
    titulacionPromedio: boolean;
    titulacionExamen: boolean;
    titulacionCredito: boolean;
    tipoReconocimientoId: number;
    IdPrograma?: number;
    tabSeccion: string;
    authorizeRegistration: boolean;
}