import { Component, OnInit, Inject } from '@angular/core';
import { ConfiguracionCuotaService } from '../../../_services/configuracion-cuota.service';
import { CampusService } from '../../../_services/campus.service';
import { NivelService } from '../../../_services/nivel.service';
import { PeriodoService } from '../../../_services/periodo.service';
import { Campus } from '../../../_models/campus';
import { Nivel } from '../../../_models/nivel';
import { Periodo } from '../../../_models/periodo';
import { Programa } from '../../../_models/programa';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ColegiaturaCosto } from '../../../_models/colegiatura-costo';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgramaAcademicoService } from '../../../_services/programa-academico.service';
import { UtileriasService } from '../../../_services/utilerias.service';
import { Subscription } from 'rxjs';
import { SelectValidator } from 'src/app/validator/SelectValidator';
import { SnackService } from 'src/app/services/snack-service.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { TipoCobro } from '../../../_models/tipo-cobro';
import { ValidarInscripcionExistente } from 'src/app/_models/Zuma/validarInscripcionExistente';

@Component({
  selector: 'app-modal-colegiatura',
  templateUrl: './modal-colegiatura.component.html',
  styleUrls: ['./modal-colegiatura.component.scss']
})
export class ModalColegiaturaComponent implements OnInit {

  //#region Propiedades
  public arrCampus: Campus[];
  public arrNivel: Nivel[];
  public arrPeriodo: Periodo[];
  public arrPrograma: Programa[];
  public arraTipoCobro: TipoCobro[] = [{ clave: "M", nombre: "materia" }, { clave: "C", nombre: "creditos" }, { clave: "P", nombre: "periodo" }]
  public agregar: boolean = false;
  public formCampos: FormGroup;
  public isFalse: boolean = true;
  private suscripcion = new Subscription();
  public diaInicialVencimiento: number = 1;
  public diaFinalVencimiento: number = 31;
  public diaVencimiento: boolean = true;
  //#endregion

  constructor(private cuota: ConfiguracionCuotaService,
    private campusservice: CampusService,
    private nivelservice: NivelService,
    private periodoservice: PeriodoService,
    private programaservice: ProgramaAcademicoService,
    private snackBar: MatSnackBar,
    private utileria: UtileriasService,
    public dialogRef: MatDialogRef<ModalColegiaturaComponent>,
    private formBuilder: FormBuilder,
    public snackService: SnackService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  //#region  Métodos
  ngOnDestroy(): void {
    if (this.suscripcion) {
      this.suscripcion.unsubscribe();
    }
  }

  cambiarValor(estatu) {
    this.diaVencimiento = estatu;
  }

  ngOnInit(): void {
    this.agregar = this.data == null;
    if (this.agregar) {
      this.generarFormularioParaCreacion();
    }
    else {
      this.generarFormularioEdicion();
    }
    this.consultarDatos();
  }
  /**
   * Este método genera un Form reactivo cuando se abre el modal para crear simplemente
   */
  private generarFormularioParaCreacion(): void {
    this.formCampos = this.formBuilder.group({
      periodo: [0, Validators.required],
      campus: [0, Validators.required],
      nivel: [0, Validators.required],
      programa: [0, Validators.required],
      fechaInicio: [this.utileria.formatearFecha(new Date(), 'YYYY[-]MM[-]DD'), Validators.required],
      fechaFin: [this.utileria.formatearFecha(new Date(), 'YYYY[-]MM[-]DD'), Validators.required],
      tipoCobro: [0, Validators.required],
      parcialidades: ['', Validators.required],
      costo: [0, [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      impuesto: [0, [Validators.required, Validators.pattern(/^[0-9\.]/), Validators.min(0), Validators.max(100)]],
      intereses: [0, [Validators.required, Validators.pattern(/^[0-9\.]/), Validators.min(0), Validators.max(100)]],
      diaTransaccion: ['', Validators.required],
      fechaVencimiento: [this.utileria.formatearFecha(new Date(), 'YYYY[-]MM[-]DD'), Validators.required],
      diaVencimiento: [1, [Validators.required, Validators.pattern(/^[0-9\.]/), Validators.min(0), Validators.max(100)]]
    },
      {
        validators: [SelectValidator('periodo'), SelectValidator('campus'),
        SelectValidator('nivel'), SelectValidator('tipoCobro'), SelectValidator('programa')]
      }
    );
  }
  /**
  * Método que genera un form reactivo con los datos que recibe
  * this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.direccion[0].tipoDireccionId
  */
  private generarFormularioEdicion(): void {
    this.formCampos = this.formBuilder.group({
      periodo: [this.data.periodoId, Validators.required],
      campus: [this.data.campusId, Validators.required],
      nivel: [this.data.nivelId, Validators.required],
      programa: [this.data.programaId, Validators.required],
      fechaInicio: [this.utileria.formatearFecha(this.data.fechaInicio, 'YYYY[-]MM[-]DD'), Validators.required],
      fechaFin: [this.utileria.formatearFecha(this.data.fechaFin, 'YYYY[-]MM[-]DD'), Validators.required],
      tipoCobro: [this.data.claveTipoCobro, Validators.required],
      parcialidades: [this.data.parcialidades, Validators.required],
      costo: [this.data.costo, [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      impuesto: [this.data.impuesto, [Validators.required, Validators.pattern(/^[0-9\.]/), Validators.min(0), Validators.max(100)]],
      intereses: [this.data.intereses, [Validators.required, Validators.pattern(/^[0-9\.]/), Validators.min(0), Validators.max(100)]],
      diaTransaccion: [this.data.fechaVencimiento == 1 ? true : false, Validators.required],
      fechaVencimiento: [this.utileria.formatearFecha(this.data.fechaVencimiento, 'YYYY[-]MM[-]DD'), Validators.required],
      diaVencimiento: [this.data.diaVencimiento, [Validators.required, Validators.pattern(/^[0-9\.]/), Validators.min(0), Validators.max(100)]]
    });
    this.obtenerNiveles();
    this.obtenerProgramas();

  }

  /**
   * Método que consulta los campus y periodos existentes
   */
  private consultarDatos(): void {
    this.suscripcion.add(
      this.campusservice.obtenerCampus().subscribe((camp: Campus[]) => {
        this.arrCampus = camp;
      })
    )
    this.suscripcion.add(
      this.periodoservice.obtenerPeriodos().subscribe((periodos: Periodo[]) => {
        this.arrPeriodo = periodos;
      })
    );
  }
  /**
    * Obtiene un listado de niveles que están disponibles para un campus
    */
  public obtenerNiveles(): void {
    if (this.campusConfig.invalid || this.periodoConfig.invalid) return;
    this.suscripcion.add(
      this.nivelservice.obtenerNivelesDelCampus(this.campusConfig.value).subscribe((nivelesResponse: ApiResponse<Array<Nivel>>) => {
        if (nivelesResponse.success) {
          this.arrNivel = nivelesResponse.data;
        }
        else {
          this.snackService.mostrarSnackBack(nivelesResponse.message);
        }
      })
    );
  }
  /**
   * Obtiene los programas disponibles para el campus y el nivel indicado
   */
  public obtenerProgramas(): void {
    if (this.periodoConfig.invalid || this.campusConfig.invalid || this.nivelConfig.invalid) return;
    this.suscripcion.add(
      this.programaservice.obtenerProgramasCampusYNivel(this.campusConfig.value, this.nivelConfig.value).subscribe((apiProgramasResponse: ApiResponse<Array<Programa>>) => {
        if (apiProgramasResponse.success) {
          this.arrPrograma = apiProgramasResponse.data;
        }
        else {
          this.snackService.mostrarSnackBack(apiProgramasResponse.message);
        }
      })
    );

  }
  prueba() {
    console.log(this.formCampos)
  }

  public validarFechas = function (form: FormGroup, nombreControl1: string, nombreControl2: string) {
    if (form.get(nombreControl1).value !== null && form.get(nombreControl2).value !== null) {
      return form.get(nombreControl1).value < form.get(nombreControl2).value;
    }
    return true;
  }

  public validarDiaVencimiento = function (form: FormGroup) {
    if (form.get('diaVencimiento').value == '') {
      return false;
    }

    if (form.get('diaVencimiento').value >= this.diaInicialVencimiento && form.get('diaVencimiento').value <= this.diaFinalVencimiento) {
      return false;
    }
    return true;
  }

  guardar() {
    let datos: ColegiaturaCosto = new ColegiaturaCosto();
    datos.periodoId = parseInt(this.formCampos.value.periodo);
    datos.campusId = parseInt(this.formCampos.value.campus);
    datos.nivelId = parseInt(this.formCampos.value.nivel);
    datos.programaId = parseInt(this.formCampos.value.programa);
    datos.claveTipoCobro = this.formCampos.value.tipoCobro;
    datos.costo = parseFloat(this.formCampos.value.costo);
    datos.impuesto = parseFloat(this.formCampos.value.impuesto);
    datos.intereses = parseFloat(this.formCampos.value.intereses);
    datos.diaTransaccion = this.formCampos.value.diaTransaccion;
    datos.fechaInicio = this.formCampos.value.fechaInicio;
    datos.fechaFin = this.formCampos.value.fechaFin;
    datos.parcialidades = this.formCampos.value.parcialidades;
    datos.fechaVencimiento = this.formCampos.value.fechaVencimiento;
    datos.diaVencimiento = parseInt(this.formCampos.value.diaVencimiento)
    datos.UsuarioModificacion = -1

    if (this.agregar) {
      this.cuota.postColegiaturaCosto(datos).subscribe(response => {
        if (response.success) {
          this.snackBar.open(response.message, 'OK', { duration: 5000 });
          this.cuota.metodoObtenerColegiaturas();
        } else {
          // this.snackBar.open('Ha ocurrido un error al guardar el servicio. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
          this.snackBar.open(response.message, 'OK', { duration: 5000 });
        }
      });
    } else {
      this.cuota.editrColegiaturaCosto(datos).subscribe(response => {
        if (response.success) {
          this.snackBar.open(response.message, 'OK', { duration: 5000 });
          this.cuota.metodoObtenerColegiaturas();
        } else {
          this.snackBar.open(response.message, 'OK', { duration: 5000 });
        }
      });
    }
  }
  //#endregion

  //#region Gets
  get periodoConfig() {
    return this.formCampos.get('periodo');
  }
  get campusConfig() {
    return this.formCampos.get('campus');
  }
  get nivelConfig() {
    return this.formCampos.get('nivel');
  }
  get programaConfig() {
    return this.formCampos.get('programa');
  }
  get costoConfig() {
    return this.formCampos.get('costo')
  };
  get impuestoConfig() {
    return this.formCampos.get('impuesto')
  };
  get parcialidadConfig() {
    return this.formCampos.get('parcialidades')
  };
  get fechaInicioConfig() {
    return this.formCampos.get('fechaInicio');
  }
  get fechaVencimientoConfig() {
    return this.formCampos.get('fechaVencimiento');
  }
  get fechaFinConfig() {
    return this.formCampos.get('fechaFin')
  };
  get tipoCobroConfig() {
    return this.formCampos.get('tipoCobro')
  };
  get interesesConfig() {
    return this.formCampos.get('intereses')
  };
  get diaVencimientoConfig() {
    return this.formCampos.get('diaVencimiento')
  };
  //#endregion

  public soloNumeros(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }


}
