import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { Campaign, Campus, Entry, InfoPrograma, Nivel, Periodo } from 'src/app/_models';
import {
  CampaignService,
  CampusService,
  InscripcionService,
  LeadEntryService,
  LeadService,
  NivelService,
  PeriodoService,
} from 'src/app/_services';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { DeleteLeadComponent, LeadDetailModalComponent } from '.';
import { SnackService } from '../services/snack-service.service';
import { Lead } from '../_models/lead';
import { InfoProgramaService } from '../_services/infoPrograma.service';

interface Calification {
  value: number;
}

interface DateContacts {
  name: string;
  value: Date;
}

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss'],
})
export class LeadsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private inscripcionService: InscripcionService,
    private campusService: CampusService,
    private snackService: SnackService,
    private programaservice: InfoProgramaService,
    private periodoService: PeriodoService,
    private util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private generadorExcel: GeneracionExcelServiceService,
    private nivelservice: NivelService,
    private campaignService: CampaignService,
    private leadEntryService: LeadEntryService,
    private leadService: LeadService,
    public router: Router
  ) {}

  displayedColumns: string[] = [
    'select',
    'nombre',
    'campaña',
    'etapa',
    'ultimocontacto',
    'calificacion',
    'entrada',
    'programa',
    'telefono',
  ];
  //#region inputs all
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allNivel') private allNivel: MatOption;
  @ViewChild('allEntry') private allEntry: MatOption;
  @ViewChild('allCampaign') private allCampaign: MatOption;
  @ViewChild('allCalification') private allCalification: MatOption;
  @ViewChild('allContact') private allContact: MatOption;
  //#endregion

  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public numIngresoSeleccionado: number = 0;
  public leadForm: FormGroup;
  public datafilter: any;
  public arregloFiltroOriginal: any[] = [];
  public campusList: Campus[] = [];
  public Programas: InfoPrograma[] = [];
  public Periodos: Periodo[] = [];
  public arregloFiltroModificado: any[] = [];
  public datosEliminar: [];
  public tipoDeAlumno: boolean;
  public nivelList: Nivel[] = [];
  public campaignList: Campaign[] = [];
  public entryList: Entry[] = [];
  public calificationList: Calification[] = [
    { value: 0 },
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
  ];
  public lastContactList: DateContacts[] = [
    {
      name: 'dia1',
      value: this.subDates(0, 1),
    },
    {
      name: 'dia7',
      value: this.subDates(0, 7),
    },
    {
      name: 'dia15',
      value: this.subDates(0, 15),
    },
    {
      name: 'mes',
      value: this.subDates(1, 1),
    },
  ];
  public campaignDis: boolean = false;
  public filterC: number = 0;

  subDates(opc, days): Date {
    let date = new Date();
    const find = new Date(Number(date));
    if (opc == 0) {
      find.setDate(date.getDate() - days);
    } else if (opc == 1) {
      find.setMonth(date.getMonth() - days);
    }
    return find;
  }

  //#region  FormControls
  nameF = new FormControl();
  campaignF = new FormControl();
  periodoF = new FormControl();
  ProgramaF = new FormControl();
  nivelF = new FormControl();
  CampusF = new FormControl();
  entryF = new FormControl();
  qualificationF = new FormControl();
  lastcontactF = new FormControl();
  //#endregion

  ngOnDestroy() {
    this.campaignService.deleteCampaignId();
  }

  ngOnInit(): void {

    this.getLeads();
    this.inicializarForm();
    this.LlenarFiltros();
    //this.paginador.itemsPerPageLabel = "Registros por página";
    //this.paginador.nextPageLabel = "Página siguiente";
    //this.paginador.previousPageLabel = "Página anterior";
    //this.paginador.firstPageLabel = "Primera página";
    //this.paginador.lastPageLabel = "Última página";
  }

  public inicializarForm() {
    this.leadForm = new FormGroup({
      nameF: new FormControl(),
      campaignF: new FormControl([]),
      periodoF: new FormControl([]),
      ProgramaF: new FormControl([]),
      nivelF: new FormControl([]),
      CampusF: new FormControl([]),
      entryF: new FormControl([]),
      calificationF: new FormControl([]),
      lastcontactF: new FormControl([]),
    });
    this.nameF.setValue('');
    this.campaignF.setValue['-1'];
    this.periodoF.setValue['-1'];
    this.ProgramaF.setValue(['-1']);
    this.nivelF.setValue['-1'];
    this.CampusF.setValue(['-1']);
    this.entryF.setValue['-1'];
    this.qualificationF.setValue['-1'];
    this.lastcontactF.setValue['-1'];
  }
  //#region  Get's
  public async obtenerCampus(): Promise<void> {
    if (this.campusList.length == 0) {
      let campus = await this.campusService.obtenerCampus().toPromise();
      this.campusList = campus;
    }
    this.CampusF.setValue([...this.campusList.map((item) => item.campusId), 0, -1]);
    this.filterTrigger();
  }

  public async obtenerPeriodo(): Promise<void> {
    if (this.Periodos.length == 0 || !this.Periodos) {
      let periodo = await this.periodoService.obtenerPeriodos().toPromise();
      this.Periodos = periodo;
    }
    this.periodoF.setValue([...this.Periodos.map((item) => item.periodoId), 0, -1]);
    this.filterTrigger();
  }

  public async obtenerProgramas(): Promise<void> {
    if (this.Programas.length == 0) {
      let programas = await this.programaservice.obtenerInfoPrograma().toPromise();
      this.Programas = programas;
    }
    this.ProgramaF.setValue([...this.Programas.map((item) => item.programaId), 0, -1]);
    this.filterTrigger();
  }

  public async obtenerNiveles(): Promise<void> {
    if (this.nivelList.length == 0) {
      let nivel = await this.nivelservice.getNivel().toPromise();
      this.nivelList = nivel;
    }
    this.nivelF.patchValue([...this.nivelList.map((item) => item.nivelId), 0, -1]);
    this.filterTrigger();
  }

  public async getEntrys(): Promise<void> {
    if (this.entryList.length == 0) {
      let entry = await this.leadEntryService.getEntrys().toPromise();
      this.entryList = entry.data;
    }
    this.entryF.patchValue([...this.entryList.map((item) => item.sourceId), 0, -1]);
    this.filterTrigger();
  }

  public async getCampaigns(): Promise<void> {
    if (this.campaignList.length == 0) {
      let campaigns = await this.campaignService.getCampaigns().toPromise();
      this.campaignList = campaigns.data;
    }
    let c = this.campaignService.getCampaignId();
    if (c) {
      this.campaignF.patchValue([Number(c)]);
      this.campaignDis = true;
    } else {
      this.campaignF.patchValue([...this.campaignList.map((item) => item.campaignId), 0, -1]);
    }
    this.filterTrigger();
  }

  public getCalifications(): void {
    this.qualificationF.patchValue([...this.calificationList.map((item) => item.value), -1]);
  }

  // public getLastContact(): void {
  //   this.lastcontactF.patchValue([...this.lastContactList.map((item) => item.value), 0, -1]);
  // }

  public async getLeads(): Promise<void> {
    let leads = await this.leadService.getLeads().toPromise();
    if (leads.success) {
      for (let i = 0; i < leads.data.length; i++) {
        if (leads.data[i].interactionCreationDate == '0001-01-01T00:00:00') {
          leads.data[i].interactionCreationDate = '';
        } else {
          leads.data[i].interactionCreationDate = this.util.formatearFecha(leads.data[i].interactionCreationDate);
        }
      }

      this.dataSource = new MatTableDataSource(leads.data);
      this.arregloFiltroOriginal = [...leads.data];
      this.dataSource.paginator = this.paginator;
      this.datafilter = leads.data;
      this.filterTrigger();
    } else {
      this.dataSource = new MatTableDataSource([]);
      this.arregloFiltroOriginal = [];
    }
  }

  //#endregion

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  isAllSelected() {
    if (this.datafilter) {
      this.numIngresoSeleccionado = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numIngresoSeleccionado === numRows;
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.leadId + 1}`;
  }

  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      this.datosEliminar = elemento;
    } else {
      if (elemento.length === 1) {
        this.datosEliminar = elemento[0];
      } else {
        this.datosEliminar = elemento;
      }
    }
    //this.dialog.open(SeguimientoInscripcionCorreoComponent, { data: this.datosEliminar, disableClose: true });
  }

  ExcelSubscription: Subscription;
  public descargarExcel(elemento?: any) {
    let IdAlumnos;
    if (elemento != null && elemento.length > 0) {
      IdAlumnos = elemento.map((m) => m.alumnoId.toString());
    } else {
      IdAlumnos = this.datafilter.map((m) => m.alumnoId.toString());
    }
    this.inscripcionService.descargarExcel(IdAlumnos).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, 'Reinscripcion');
    });
  }

  tipoInscripcion: string = 'NI';
  tipoAl: number = 0;

  public vaciarArregloModificado() {
    this.arregloFiltroModificado.length = 0;
  }

  public filtro() {
    let name: string = this.nameF.value == null ? '' : this.nameF.value;
    let campaing: Campaign[] = this.campaignF.value == null ? null : this.campaignF.value;
    let calificacion: Calification[] = this.qualificationF.value == null ? [] : this.qualificationF.value;
    let entrada: string = this.entryF.value == null ? '' : this.entryF.value;
    let periodo: Periodo[] = this.periodoF.value == null ? '' : this.periodoF.value;
    let programa: any[] = this.ProgramaF.value == null ? [] : this.ProgramaF.value;
    let nivel: any[] = this.nivelF.value == null ? [] : this.nivelF.value;
    let campus: any[] = this.CampusF.value == null ? [] : this.CampusF.value;

    let contact: any[] = this.lastcontactF.value == null ? '' : this.lastcontactF.value;

    let filtroModificado: any = [];
    filtroModificado = [...this.arregloFiltroOriginal];

    let ids: any;
    filtroModificado = filtroModificado.filter((f) => {
      return f.name.toLowerCase().search(name.toLowerCase()) > -1;
    });
    //Entrada
    // TODO: Fix entry type filter
    /*
    filtroModificado = filtroModificado.filter((f) => {
      let r: Array<any> = [];
      ids =
        f.campaigns.length > 0
          ? (ids = f.campaigns.map((x) => {
              x.entry.map((y) => {
                r.push(y.sourceId);
                return r;
              });
              return 0;
            }))
          : -1;

      let result: boolean = false;
      if (ids == -1) {
        return (result = entrada.indexOf(ids) > -1);
      } else {
        r.forEach((e) => {
          if (!result) {
            return (result = entrada.indexOf(e ? e : 0) > -1);
          }
        });
      }

      return result;
    });*/
    //Campaña
    filtroModificado = filtroModificado.filter((f) => {
      ids =
        f.campaigns.length > 0
          ? (ids = f.campaigns.map((f) => {
              return f.campaignId;
            }))
          : -1;

      let result: boolean = false;
      if (ids == -1) {
        return (result = campaing.indexOf(ids) > -1);
      } else {
        ids.forEach((e) => {
          if (!result) {
            result = campaing.indexOf(e ? e : 0) > -1;
          }
        });
      }
      return result;
    });
    filtroModificado = filtroModificado.filter((f) => {
      return calificacion.indexOf(f.qualification ? f.qualification : 0) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      return periodo.indexOf(f.termId ? f.termId : -1) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      return campus.indexOf(f.campusId ? f.campusId : -1) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      return programa.indexOf(f.programId ? f.programId : -1) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      //Modifica esto
      return nivel.indexOf(f.leadInterestLevel.length > 0 ? f.leadInterestLevel[0].levelId : -1) > -1;
    });

    if (contact.length > 0) {
      filtroModificado = filtroModificado.filter((f) => {
        if (!f.interactionCreationDate) {
          return contact.indexOf(-1) > -1;
        }
        let result: boolean = false;
        contact.forEach((element) => {
          const idate = this.util.formatearFecha(element);
          if (!result) {
            result = idate == f.interactionCreationDate ? true : false;
          }
        });
        return result;
      });
    }
    //filtros desactivados
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<any>(true, []);
  }

  public filterTrigger(): void {
    if (this.filterC == 5) {
      this.filtro();
    } else this.filterC++;
  }

  public LlenarFiltros(): void {
    this.getCampaigns();
    this.obtenerPeriodo();
    this.obtenerProgramas();
    this.obtenerNiveles();
    this.obtenerCampus();
    this.getEntrys();
    this.getCalifications();
    //this.getLastContact();

    //#region obtiene periodos activos
    // let today = new Date();
    // this.periodoService.obtenerPeriodos().subscribe(
    //   (periodos: Periodo[]) => {
    //     this.Periodos = periodos;
    //     for (let x of periodos) {
    //       let inicio = new Date(x.fechaInicio);
    //       let fin = new Date(x.fechaFin);
    //       if (inicio < today && fin > today) {
    //         this.periodosactivos.push(x.periodoId);
    //       }
    //     }
    //     if(this.periodosactivos.length > 0){
    //       //desactiva pre seleccion de periodos activos
    //       //this.periodoF.setValue([...this.periodosactivos]);
    //     }
    // });
    //#endregion
  }

  public LimpiarFiltros() {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.filterC = 0;
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
    this.dataSource.paginator = this.paginator;
    if (this.campaignDis) {
      this.filtro();
    }
  }

  public borrar(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }

    const dialogo = this.util.abrirDialogoLateral(DeleteLeadComponent, datos);
    dialogo.afterClosed().subscribe((result) => {
      if (result) {
        this.getLeads();
      }
      this.selection = new SelectionModel<any>(true, []);
    });
  }

  newLead(): void {
    const leaddetail = this.util.abrirDialogoLateral(LeadDetailModalComponent);
    leaddetail.afterClosed().subscribe((result) => {
      this.getLeads();
      this.leadService.updateTabsSubscription.unsubscribe();
    });
  }

  editLead(leadId: any) {
    this.leadService.getLead(leadId.leadId).subscribe((lead: ApiResponse<Lead>) => {
      if (lead.success) {
        const leaddetail = this.util.abrirDialogoLateral(LeadDetailModalComponent, lead.data);
        leaddetail.afterClosed().subscribe((result) => {
          this.getLeads();
          this.leadService.updateTabsSubscription.unsubscribe();
        });
      } else {
        this.snackService.mostrarSnackBack('Error: no se encontró Información');
      }
    });
  }

  //#region  Funciones checkbox
  toggleAllSelectionPeriodo() {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.Periodos.map((item) => item.periodoId), 0, -1]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.filtro();
  }
  toggleAllSelectionPrograma() {
    if (this.allPrograma.selected) {
      this.ProgramaF.patchValue([...this.Programas.map((item) => item.programaId), 0, -1]);
    } else {
      this.ProgramaF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.CampusF.patchValue([...this.campusList.map((item) => item.campusId), 0, -1]);
    } else {
      this.CampusF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionNivel() {
    if (this.allNivel.selected) {
      this.nivelF.patchValue([...this.nivelList.map((item) => item.nivelId), 0, -1]);
    } else {
      this.nivelF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionEntry() {
    if (this.allEntry.selected) {
      this.entryF.patchValue([...this.entryList.map((item) => item.sourceId), 0, -1]);
    } else {
      this.entryF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionCampaign() {
    if (this.allCampaign.selected) {
      this.campaignF.patchValue([...this.campaignList.map((item) => item.campaignId), 0, -1]);
    } else {
      this.campaignF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionCalification() {
    if (this.allCalification.selected) {
      this.qualificationF.patchValue([...this.calificationList.map((item) => item.value), -1]);
    } else {
      this.qualificationF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionContact() {
    if (this.allContact.selected) {
      this.lastcontactF.patchValue([...this.lastContactList.map((item) => item.value), 0, -1]);
    } else {
      this.lastcontactF.patchValue([]);
    }
    this.filtro();
  }

  tosslePerOnePeriodo() {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if (this.periodoF.value.length == this.Periodos.length + 1) {
      this.allPeriodo.select();
    }
    this.filtro();
  }

  tosslePerOnePrograma() {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.ProgramaF.value.length == this.Programas.length + 1) {
      this.allPrograma.select();
    }
    this.filtro();
  }

  tosslePerOneCampus() {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.CampusF.value.length == this.campusList.length + 1) {
      this.allCampus.select();
    }
    this.filtro();
  }

  tosslePerOneNivel() {
    if (this.allNivel.selected) {
      this.allNivel.deselect();
      return false;
    }
    if (this.nivelF.value.length == this.nivelList.length + 1) {
      this.allNivel.select();
    }
    this.filtro();
  }

  tosslePerOneEntry() {
    if (this.allEntry.selected) {
      this.allEntry.deselect();
      return false;
    }
    if (this.entryF.value.length == this.entryList.length + 1) {
      this.allEntry.select();
    }
    this.filtro();
  }

  tosslePerOneCampaign() {
    if (this.allCampaign.selected) {
      this.allCampaign.deselect();
      return false;
    }
    if (this.campaignF.value.length == this.campaignList.length + 1) {
      this.allCampaign.select();
    }
    this.filtro();
  }

  tosslePerOneCalification() {
    if (this.allCalification.selected) {
      this.allCalification.deselect();
      return false;
    }
    if (this.qualificationF.value.length == this.calificationList.length) {
      this.allCalification.select();
    }
    this.filtro();
  }

  tosslePerOneContact() {
    if (this.allContact.selected) {
      this.allContact.deselect();
      return false;
    }
    if (this.lastcontactF.value.length == this.lastContactList.length + 1) {
      this.allContact.select();
    }
    this.filtro();
  }
  //#endregion
}
