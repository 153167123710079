
export class UsuarioNuevoIngresoDto {
        /// <summary>
        /// Identificador del usuario
        /// </summary>
        usuarioId: number

        /// <summary>
        /// Identificador del usuario
        /// </summary>
        udentificador: string;

        /// <summary>
        /// Identificador del alumno
        /// </summary>
        alumnoId: number

        /// <summary>
        /// Nombre completo del usuario
        /// </summary>
        nombreCompleto: string;

        /// <summary>
        /// Periodo en el que esta el usuario
        /// </summary>
        periodoId: number;

        /// <summary>
        /// clave del periodo
        /// </summary>
        periodoClave: string;

        /// <summary>
        /// Nombre del periodo
        /// </summary>
        periodoNombre: string;

        /// <summary>
        /// Identificador del programa
        /// </summary>
        programaId: number;

        /// <summary>
        /// Clave del programa
        /// </summary>
        programaClave: string;

        /// <summary>
        /// Nombre del programa
        /// </summary>
        programaNombre: string

        /// <summary>
        /// Identificador del campus
        /// </summary>
        campusId: number;

        /// <summary>
        /// Clave del campus 
        /// </summary>
        campusClave: string;

        /// <summary>
        /// Nombre del campus
        /// </summary>
        campusNombre: string

        /// <summary>
        /// Fecha de inicio del periodo que esta inscrito
        /// </summary>
        fechaInicioPeriodo: any;

        /// <summary>
        /// Define si los datos personales estan registrados
        /// </summary>
        datosPersonalesRegistrados: boolean;

        /// <summary>
        /// Fecha de nacimiento del usuario
        /// </summary>
        fechaNacimiento: any;

        /// <summary>
        /// Curp registrado por el usuario
        /// </summary>
        curpUsuario: string;

        /// <summary>
        /// Define si existe una direccion registrada del usuario
        /// </summary>
        existeDireccion: boolean;

        /// <summary>
        /// Define si existe un telefono registrado
        /// </summary>
        existeTelefono: boolean;

        /// <summary>
        /// Define si exite una persona relacionada registrada 
        /// </summary>
        existePersonaRelacionada: boolean;

        /// <summary>
        /// Define si existe un estudio previo
        /// </summary>
        existeEstudioPrevio: boolean;

        /// <summary>
        /// Define si exite el documento acta de nacimiento
        /// </summary>
        existeDocActaNacimiento: boolean;

        /// <summary>
        /// Define si existe el documento carta compromiso
        /// </summary>
        existeDocCartaCompromiso: boolean;

        /// <summary>
        /// Define si hay documentos de tipo identificacion con fotografia
        /// </summary>
        existeDocIndentFoto: boolean;

        /// <summary>
        /// Define si hay documentos de tipo estudios previos 
        /// </summary>
        existeDocEstudiosPrevios: boolean;

        /// <summary>
        /// Define si hay documentos cargados del usuario
        /// </summary>
        existeDocumentos: boolean

        /// <summary>
        /// Define si existe alguna foto registrada 
        /// </summary>
        existeFoto: boolean;

        /// <summary>
        /// Define si existe un apoyo financiero
        /// </summary>
        existeApoyoFinanciero: boolean;

        /// <summary>
        /// Define si se pago la inscripcion
        /// </summary>
        existePago: boolean;

        /// <summary>
        /// Define si tiene materias registradas
        /// </summary>
        materiasRegistradas: boolean;

        /// <summary>
        /// Estatus academico
        /// </summary>
        estatusAcemido: number;

        /// <summary>
        /// Identificador del estatus academico
        /// </summary>
        estatusAcademicoId: number;

        /// <summary>
        /// Clave del estatus
        /// </summary>
        estatusClave: string;

        /// <summary>
        /// Nombre del estatus
        /// </summary>
        estatusNombre: string;

        /// <summary>
        /// Correo del usuario
        /// </summary>
        correo: string;


        blockId: number;
        blockLabelId: number;
        blockName: string;
        blockCode: string;
        blockPeriodoId: number;
        blockCampusId: number;
}


export class UsuarioIngreso {
        /**
         * Identificador del usuario
         */
        UsuarioId: number;

        /**
         * Identificador de la institucion a la que pertenece
         */
        InstitucionId: any;

        /**
         * Correo del usuario
         */
        Correo: string;

        /**
         * Identificador unico del usuario
         */
        Identificador: string;

        /**
         * Estatus de la cuenta del usuario
         */
        EstatusCuentaId: number;

        /**
         * Identificador del usuario
         */
        usuarioId: number;

        /**
         * Correo del usuario
         */
        correo: string;

        /** 
         * Nombre(s) del usuario 
         */
        nombre: string;

        /** 
        * Segundo nombre del usuario 
        */
        segundoNombre: string;

        /**
         * Apellido paterno del usuario 
         */
        apellidoPaterno: string;

        /**
         * Apellido materno del usuario
         */
        apellidoMaterno: string;

        /**
         * Nombre del archivo que sera la fotorgrafia 
         */
        fotografia: string | ArrayBuffer;

        /**
         * documentos que el usuario sube
         */
        usuarioDocumento: any;

        /**
         * Informacion de las solicitudes de apoyo financiero
         */
        solicitudApoyoFinanciero: any;

        /**
         * Informacion completa del alumno
         */
        alumno: any;

        /**
         * Solicitud de carga de documento
         */
        solicitudCargaDoc: number;

        /**
         * Informacion de la direccion del alumno
         */
        direccion: any;

        /**
         * Informacion del telfono del alumno
         */
        telefono: any;

        /**
         * Informacion de la biografia del alumno
         */
        biografia: any;

        /**
         * Informacion de la(s) personas relacionadas con el alumno
         */
        personaRelacionadaUsuario: any;

        /**
         * Informacion de los estudios previos del alumno
         */
        estudioPrevio: any;

        /**
         * Define la informacion del profesor 
         */
        profesor: any;

        /**
         * Identificador del alumno
         */
        identificador: string;

        /**
         * Informacion de los datos fiscales del alumno
         */
        datoFiscal: any;

        /**
         * Informacion de la ocupacion del alumno
         */
        ocupacion: any;

        /**
         * Identificador del periodo al que ingresa el alumno
         */
        periodoId: number;
}