export class StudentChangeDTO {

  studentChangeID: number;
  alumnoID: number;

  periodoClave: string;
  periodoID: number;

  campusClave: string;
  campusID: number;

  programaClave: string;
  programaID: number;

  fechaCambio: Date;
  usuario: number;

  estatus: string;

}


export class StudentChangePost {

  alumnoID: number;

  periodoID: number;

  campusID: number;

  programaID: number;
}
