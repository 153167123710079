import { FiltersBase } from './filters.base';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FILTERS_CONTROLS } from '@app/clean-architecture/core.application/validators/filters-form.validators';
import { CampusService } from '@app/_services/campus.service';
import { PeriodoService } from '@app/_services/periodo.service';


@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent extends FiltersBase implements OnInit {

  //#region  Input, Output & ViewChild
  @Input() cancelledFields: string[] = [];
  @Input() initialLoad: boolean;
  @Output() formFilters: EventEmitter<any> = new EventEmitter();  
  //#endregion 

  //#region Properties

  
  private _currentfilters: any;    
  public deletedInputs = {
    periodo: false,
    alumno: false,
    campus: false,
    materia: false,
    matercalificacionFinalia: false,
    numeroFaltas: false,
    extraordinario: false   
  };
  
  //#endregion



 //#region LifeCycle
 constructor(
  override formBuilder: FormBuilder,
  override _campusService: CampusService,  
  override _periodoService: PeriodoService,
  ) { 
    super(
      formBuilder,
      _campusService,
      _periodoService
    );
  }

 ngOnInit(): void {  
   this._deleteCancelledFields();
   this._subscribeToForm();
   this.obtenerPeriodo();
   this.obtenerCampus();
   this.setExtraordinario();
 }
 //#endregion

 

  //#region Form Methods
  /**
 * If the cancelledFields array has a length greater than 0, then for each field in the
 * cancelledFields array, if the filtersForm has a control with the name of the field, then clear the
 * validators for that control and add the field to the deletedInputs object.
 */
  private _deleteCancelledFields(): void {
    if (this.cancelledFields.length > 0) {
      this.cancelledFields.forEach(field => {
        if (this.filtersForm.get(field)) {
          this.filtersForm.get(field).clearValidators();
          this.deletedInputs[field] = true;
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  /**
  * When the status of the form changes, set the errors, set the validity of the form, and emit the
  * Filters.
  */
  private _subscribeToForm(): void {

    this.filtersForm.valueChanges
      .subscribe(status => {        
        this._emitFilters();

        if (this.initialLoad) {
          this.formFilters.emit(this._currentfilters);
        }
      });
  }

  /**
 * If the form is invalid, set the error message to the error message that corresponds to the first
 * invalid form control.
 */
  public setErrors(): void {    
    /*Falta Implementar*/
  }

  public _emitFilters() {
        
    this._currentfilters =
    {
      status: 'VALID',
      periodo: this.filtersForm.value.periodo,
      alumno: this.filtersForm.value.alumno,
      campus: this.filtersForm.value.campus,
      materia: this.filtersForm.value.materia,
      calificacionFinal: this.filtersForm.value.calificacionFinal,
      numeroFaltas: this.filtersForm.value.numeroFaltas,
      extraordinario: this.filtersForm.value.extraordinario
    };    
  }
  //#endregion

  //#region Buttons

    public clearFilters():void {
      this.filtersForm = this.formBuilder.group(FILTERS_CONTROLS);
      this._subscribeToForm();
      this.setDataFilter();
      this._emitFilters();
      this.formFilters.emit(this._currentfilters);
    }
  
    public search(): void {
      this.formFilters.emit(this._currentfilters);
    }
  //#endregion

}
