import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnInit, ViewChild, Inject, TemplateRef, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AccionEnum } from '../../../_models/accion.enum';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { NuevoSolicitanteComponent } from './apoyo-financiero-nuevo-solicitante/nuevo-solicitante.component';
import { ApoyoFinancieroComponent } from './apoyo-financiero/apoyo-financiero.component';
import { ApoyoDatosUsuarioComponent } from './apoyo-financiero-datos-usuario/apoyo-datos-usuario.component';
import { SituacionEconomicaComponent } from './apoyo-financiero-situacion-economica/situacion-economica.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SolicitudApoyoFinancieroService } from '../../../_services/solicitud-apoyo-financiero.service';
import { UtileriasService } from '../../../_services/utilerias.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NuevoIngresoService } from '../../../_services/nuevo-ingreso.service';
import { ApoyoFinancieroDocumentoComponent } from 'src/app/_components/apoyo-financiero-documento/apoyo-financiero-documento.component';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { DatosPersonalesApoyo, RegistroApoyo, SolicitudApoyoFinanciero, SolicitudDeApoyo } from 'src/app/_models/solicitud-apoyo-financiero';
import { UsuarioDocumento } from 'src/app/_models/usuario-documento';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { DatosContactoF, DatosFamilia, Imagen, Registros } from 'src/app/_models/usuario';

@Component({
  selector: 'app-solicitud-apoyo-modal',
  templateUrl: './solicitud-apoyo-modal.component.html',
  styleUrls: ['./solicitud-apoyo-modal.component.scss']
})
export class SolicitudApoyoModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(NuevoSolicitanteComponent) solicitante: NuevoSolicitanteComponent;
  @ViewChild(ApoyoFinancieroComponent) apoyoFinanciero: ApoyoFinancieroComponent;
  @ViewChild(ApoyoDatosUsuarioComponent) datosUsuario: ApoyoDatosUsuarioComponent;
  @ViewChild(SituacionEconomicaComponent) situacionEconomica: SituacionEconomicaComponent;
  @ViewChild(ApoyoFinancieroDocumentoComponent) cargaDocumentos: ApoyoFinancieroDocumentoComponent;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  @ViewChild('dialogFoto') dialogFoto: TemplateRef<'dialogFoto'>;
  @ViewChild('profesorTabs', { static: false }) profesorTabs: MatTabGroup;
  @ViewChild('modalVisorPDF') modalVisorPDF: TemplateRef<'modalVisorPDF'>;
  @ViewChild('dialogoCerrarModal') dialogoCerrarModal: TemplateRef<'dialogoCerrarModal'>;
  @ViewChild('dialogoCandidatoCancelado') dialogoCandidatoCancelado: TemplateRef<'dialogoCandidatoCancelado'>;
  public dialogoRefCerrarModal: MatDialogRef<any>;
  public modalVisorPDFRef: MatDialogRef<any>;
  public busquedaCorreoForm: FormGroup;
  public correoBusqueda: string = '';
  public usuarioEncontrado: boolean = true;
  public ocultarNuevoUsuario: boolean = true;
  public mensajeUsuarioEncontrado: boolean = false;
  public mensajeUsuarioNoEncontrado: boolean = false;
  public datosNuevoUsuario: any;
  public formValidoNuevaSolicitud$: Observable<boolean>;
  public formValidoNuevaSolicitud: boolean;
  public boolDesabilitarTab: boolean = true;
  public tabSeleccionado: number = 0;
  public tabSeleccionadoIndex: FormControl = new FormControl(0);
  public tabCambio: boolean = false;
  public boolTabDisabled$: Observable<boolean>;
  public boolTabDisabledSubscription: Subscription;
  public boolCambioPendiente$: Observable<boolean>;
  public boolCambio: boolean = false;
  public boolCambioPendienteSubscription: Subscription;
  public dialogoRefAdver: MatDialogRef<any>;
  public dialogRefFoto: MatDialogRef<any>;
  public tipoAccion: number;
  public ocultarBuscador: boolean = true;
  public solicitudEdicion: boolean = false;
  public solicitudCreacion: boolean = false;
  public datosUsuarioActual: any;
  public datosPersonalesGuardado: boolean = true;
  public datosContactoGuardado: boolean = true;
  public datosFamiliaresGuardado: boolean = true;
  public datosOcupacionGuardado: boolean = true;
  public solicitudAceptada: SolicitudApoyoFinanciero = null;
  public solicitudEnProceso: SolicitudApoyoFinanciero = null;
  public solicitudCancelada: SolicitudApoyoFinanciero | boolean = null;
  public apoyoFinancieroActivo: boolean = false;
  public apoyoFinancieroProceso: boolean = false;
  public apoyoFinancieroCancelado: boolean = false;
  public imagePath: SafeResourceUrl;
  public imagePath2: SafeResourceUrl;
  public imagenUsuarioForm: FormGroup;
  public base64Image: string;
  public boolImagen: boolean = false;
  public imagePath3: SafeResourceUrl;
  public imgURL: string | ArrayBuffer;
  public message: string;
  public eventImage: FileList[];
  public detalleSolicitud: boolean = false;
  public solicitudCargaDoc: boolean;
  public tabRequerido: string = '';
  public pdf: ArrayBuffer;
  public tipoUsuarioEncontrado: string = "";
  public nombreUsuario: string = "";
  public dialogoRefAdverCancelado: MatDialogRef<any>;
  public profesor: boolean = false;
  public registrarUsuarioBandera: boolean = false;

  public changesApoyoFinanciero$: Observable<boolean>;
  public changesApoyoFinanciero: boolean;
  public changesDatosUsuario$: Observable<boolean>;
  public changesDatosUsuario: boolean;
  public changesSituacionEconomica$: Observable<boolean>;
  public changesSituacionEconomica: boolean;
  public changesDocumentos$: Observable<boolean>;
  public changesDocumentos: boolean;
  public tabCambiosApoyoFinanciero: string = "";
  public tabCambiosUsuario: string = "";
  public tabCambiosSituacionEconomica: string = "";
  public tabCambiosDocumentos: string = "";

  constructor(public dialogRef: MatDialogRef<SolicitudApoyoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private dialog: MatDialog,
    private solicitudApoyoFinancieroSerrvice: SolicitudApoyoFinancieroService,
    private utileriaService: UtileriasService,
    private sanitizer: DomSanitizer,
    private _cd: ChangeDetectorRef,
    public nuevoIngresoService: NuevoIngresoService,
    private traductorService: TraductorService) { }

  ngOnDestroy(): void {
    if (this.nuevoIngresoService.buscarUsuarioSubscription != undefined) {
      this.nuevoIngresoService.buscarUsuarioSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.nuevoIngresoService.buscarUsuarioSubscription = this.nuevoIngresoService.buscarUsuario.subscribe(() => {
      this.enviarBusquedaCorreo();
    });
    this.formValidoNuevaSolicitud$ = this.solicitudApoyoFinancieroSerrvice.obtenerFormularioValidoNuevaSolicitud$();
    this.formValidoNuevaSolicitud$.subscribe(bool => this.formValidoNuevaSolicitud = bool);
    this.changesApoyoFinanciero$ = this.solicitudApoyoFinancieroSerrvice.getChangesApoyoFinanciero$();
    this.changesApoyoFinanciero$.subscribe(bool => this.changesApoyoFinanciero = bool);
    this.changesDatosUsuario$ = this.solicitudApoyoFinancieroSerrvice.getChangesDatosUsuario$();
    this.changesDatosUsuario$.subscribe(bool => this.changesDatosUsuario = bool);
    this.changesSituacionEconomica$ = this.solicitudApoyoFinancieroSerrvice.getChangesSituacionEconomica$();
    this.changesSituacionEconomica$.subscribe(bool => this.changesSituacionEconomica = bool);
    this.changesDocumentos$ = this.solicitudApoyoFinancieroSerrvice.getChangesDocumentos$();
    this.changesDocumentos$.subscribe(bool => this.changesDocumentos = bool);
    this.inicializarForm();
  }

  ngAfterViewInit(): void {
    if (!this.data) {
      this.tipoAccion = AccionEnum.CREAR;
      this.ocultarNuevoUsuario = false;
      this.mensajeUsuarioNoEncontrado = true;
    } else {
      this.tipoAccion = AccionEnum.EDITAR;
      this.solicitudCargaDoc = this.data.solicitudCargaDoc;
      this.tabRequerido = this.data.tabRequerido;
      this.obtenerSolicitudByIdTabla();
    }
  }

  public obtenerSolicitudByIdTabla() {
    this.solicitudApoyoFinancieroSerrvice.obtenerSolicitudById(this.data.solicitudApoyoFinancieroId).subscribe(
      (solicitud: ApiResponse<SolicitudApoyoFinanciero>) => {
        this.datosUsuarioActual = solicitud.data;
        this.data = solicitud.data;
        this.solicitudApoyoFinancieroSerrvice.definirDatosUsuarioBusqueda(solicitud.data);
        if (this.datosUsuarioActual.estatusSolicitudApoyoId === 1) {
          this.usuarioEncontrado = false;
          this.mensajeUsuarioEncontrado = true;
          this.renderizarFormulariosTabs();
          if (this.tabRequerido === 'documentos') {
            setTimeout(() => {
              this.tabSeleccionadoIndex.setValue(4);
            }, 500);
          }
          if (this.tabRequerido === 'nombre') {
            setTimeout(() => {
              this.tabSeleccionadoIndex.setValue(1);
            }, 500);
          }
          if (this.solicitudCargaDoc) {
          }
        } else {
          this.detalleSolicitud = true;
          this.solicitudAceptada = this.data;
          this.apoyoFinancieroActivo = true;
          if (this.datosUsuarioActual.estatusSolicitudApoyoId === 2) {
            this.solicitudCancelada = false;
          } else {
            this.solicitudCancelada = true;
          }
        }
        this.nuevoIngresoService.imagenUsuario(this.data.usuarioId).subscribe(
          (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
            if (datosUsuarioImagen.success) {
              this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
              this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
            }
          }
        );
        this.solicitudCreacion = true;
        this.renderizarFoto();
      }
    );
  }

  public inicializarForm() {
    this.busquedaCorreoForm = new FormGroup({
      correo: new FormControl('', [Validators.required, Validators.email])
    });
    this.busquedaCorreoForm.valueChanges.subscribe(val => {
      this.mensajeUsuarioEncontrado = false;
      this.mensajeUsuarioNoEncontrado = false;
      this.ocultarNuevoUsuario = true;
      this.usuarioEncontrado = true;
      this.apoyoFinancieroActivo = false;
    });
  }

  get correo() { return this.busquedaCorreoForm.get('correo'); }

  public renderizarFoto() {
    this.imagenUsuarioForm = new FormGroup
      ({
        usuarioId: new FormControl(this.datosUsuarioActual.usuario.usuarioId),
        fotografia: new FormControl(''),
        fotografiaFile: new FormControl(''),
      });
  }

  get fotografia() { return this.imagenUsuarioForm.get('fotografia'); }

  preview(event) {
    if (event.length === 0)
      return;
    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath3 = event;
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.eventImage = event;
  }

  public imagenModal() {
    if (!this.boolImagen) {
      this.dialogRefFoto = this.dialog.open(this.dialogFoto, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  public cerrarModalImagen(descartar: boolean) {
    if (descartar) {
      this.dialogRefFoto.close();
    } else {
      this.dialogRefFoto.close();
      this.onFileChangePerfil(this.eventImage);
      this.enviarImagen();
      this.imgURL = "";
    }
  }

  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.onload = () => {
        this.datosUsuarioActual.usuario.fotografia = reader.result;
        this._cd.markForCheck();
        this.imagenUsuarioForm.patchValue({
          fotografia: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  }

  public onFileChangePerfil(event) {
    const reader = new FileReader();
    if (event && event.length > 0) {
      this.imagePath2 = event;
      this.imagePath = event;
      const [file] = event;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.readAsDataURL(event[0]);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
        this.imagePath2 = reader.result;
      }
      this.eventImage = event;
    }
  }

  public enviarImagen() {
    let imagenForm = Object.assign(this.imagenUsuarioForm.value);
    let imagen: Imagen = {
      usuarioId: imagenForm.usuarioId,
      fotografia: imagenForm.fotografia,
      fotografiaFile: imagenForm.fotografiaFile,
    }
    this.nuevoIngresoService.editarImagen(imagen).subscribe(
      (imagenData: SimpleResponse) => {
        if (imagenData.success) {
          let mensaje: string = this.traductorService.translate('guardadoCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      }
    )
  }

  public enviarBusquedaCorreo() {
    this.correoBusqueda = this.solicitante.correo.value;
    if (this.correoBusqueda === '') {
      this.snackService.mostrarSnackBack('Debes ingresar un correo.');
    } else {
      this.solicitudApoyoFinancieroSerrvice.obtenerUsuarioSolicitud(this.correoBusqueda).subscribe(
        (usuarioBusuqueda: ApiResponse<any>) => {
          if (usuarioBusuqueda.data != null) {
            this.datosUsuarioActual = usuarioBusuqueda.data[0];
            this.ocultarBuscador = true;
            this.solicitudEnProceso = usuarioBusuqueda.data.find(x => x.estatusSolicitudApoyoId === 1);
            this.solicitudAceptada = usuarioBusuqueda.data.find(x => x.estatusSolicitudApoyoId === 2);
            this.solicitudCancelada = usuarioBusuqueda.data.find(x => x.estatusSolicitudApoyoId === 3);
            this.solicitudCreacion = true;
            this.base64Image = 'data:image/png;base64,' + usuarioBusuqueda.data[0].usuario.fotografia;
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
            this.renderizarFoto();
            for (let alumnoCandidato = 0; alumnoCandidato < usuarioBusuqueda.data[0].usuario.alumno.length; alumnoCandidato++) {
              if (usuarioBusuqueda.data[0].usuario.alumno[alumnoCandidato].estatusAcademico == 1 && usuarioBusuqueda.data[0].usuario.alumno[alumnoCandidato].alumnoGrupo.length == 0 && usuarioBusuqueda.data[0].usuario.alumno[alumnoCandidato].estatusAcademico == 1) {
                this.nombreUsuario = usuarioBusuqueda.data[0].usuario.nombre + " " + usuarioBusuqueda.data[0].usuario.apellidoPaterno + " " + usuarioBusuqueda.data[0].usuario.apellidoMaterno;
                this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + this.nombreUsuario + " registrado para el programa " + usuarioBusuqueda.data[0].usuario.alumno[alumnoCandidato].programa.nombre + " y el período: " + usuarioBusuqueda.data[0].usuario.alumno[alumnoCandidato].priodoIngresoNavigation.nombre;
                this.snackService.mostrarSnackBack(this.tipoUsuarioEncontrado);
              }
              else if (usuarioBusuqueda.data[0].usuario.alumno[alumnoCandidato].alumnoGrupo.length >= 1) {
                this.nombreUsuario = usuarioBusuqueda.data[0].usuario.nombre + " " + usuarioBusuqueda.data[0].usuario.apellidoPaterno + " " + usuarioBusuqueda.data[0].usuario.apellidoMaterno;
                this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un alumno con el nombre de: " + this.nombreUsuario + " inscrito en el programa " + usuarioBusuqueda.data[0].usuario.alumno[alumnoCandidato].programa.nombre;
                this.snackService.mostrarSnackBack(this.tipoUsuarioEncontrado);
              } else if (usuarioBusuqueda.data[0].usuario.alumno[alumnoCandidato].estatusAcademico == 7) {
                this.nombreUsuario = usuarioBusuqueda.data[0].usuario.nombre + " " + usuarioBusuqueda.data[0].usuario.apellidoPaterno + " " + usuarioBusuqueda.data[0].usuario.apellidoMaterno;
                this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + this.nombreUsuario + " con estatus cancelado. ";
                this.snackService.mostrarSnackBack(this.tipoUsuarioEncontrado);
              }
            }
            if (this.solicitudAceptada != null) {
              this.apoyoFinancieroActivo = true;
              this.detalleSolicitud = true;
              this.solicitudCancelada = false;
            } else {
              if (this.solicitudEnProceso != null) {
                this.solicitudApoyoFinancieroSerrvice.definirDatosUsuarioBusqueda(this.solicitudEnProceso);
                this.mensajeUsuarioEncontrado = true;
                this.usuarioEncontrado = false;
                this.apoyoFinancieroActivo = false;
                this.renderizarFormulariosTabs();
                this.datosUsuarioActual = this.solicitudEnProceso;
              } else if (this.solicitudCancelada != null) {
                this.detalleSolicitud = true;
                this.datosUsuarioActual.solicitudApoyoFinancieroId = null;
                this.solicitudApoyoFinancieroSerrvice.definirDatosUsuarioBusqueda(this.datosUsuarioActual);
                this.mensajeUsuarioEncontrado = true;
                this.usuarioEncontrado = false;
                this.apoyoFinancieroActivo = false;
                this.renderizarFormulariosTabs();
              }
            }
          } else {
            this.solicitudApoyoFinancieroSerrvice.obtenerUsuariobByCorreo(this.correoBusqueda).subscribe(
              usuarioByCorreo => {
                if (usuarioByCorreo != null) {
                  let usuarioProfesor = usuarioByCorreo.usuario.alumno;
                  let usuario = usuarioProfesor.filter(x => x.estatusAcademico == 2 || x.estatusAcademico == 8);
                  let correo = usuarioByCorreo.usuario.correo;
                  let nombreUsuario = usuarioByCorreo.usuario.nombre + " " + usuarioByCorreo?.usuario?.segundoNombre + " " + usuarioByCorreo.usuario.apellidoPaterno + " " + usuarioByCorreo.usuario.apellidoMaterno;
                  if (usuario.length > 0) {
                    this.datosUsuarioActual = usuarioByCorreo;
                    this.solicitudApoyoFinancieroSerrvice.definirDatosUsuarioBusqueda(usuarioByCorreo);
                    this.base64Image = 'data:image/png;base64,' + usuarioByCorreo.usuario.fotografia;
                    this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
                    this.renderizarFoto();
                    this.solicitudApoyoFinancieroSerrvice.definirFormularioValidoNuevaSolicitud(this.datosUsuario?.datosFamiliaresForm?.valid);
                    this.mensajeUsuarioEncontrado = true;
                    this.mensajeUsuarioNoEncontrado = false;
                    this.solicitudCreacion = true;
                    this.ocultarBuscador = true;
                    this.ocultarNuevoUsuario = true;
                    this.usuarioEncontrado = false;
                    this.apoyoFinancieroActivo = false;
                    this.renderizarFormulariosTabs();
                    if (usuarioByCorreo) {
                      for (let alumnoCandidato = 0; alumnoCandidato < usuarioByCorreo.usuario.alumno.length; alumnoCandidato++) {
                        if (usuarioByCorreo.usuario.alumno[alumnoCandidato].estatusAcademico == 2 && usuarioByCorreo.usuario.alumno[alumnoCandidato].alumnoGrupo.length == 0) {
                          this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + nombreUsuario + " registrado para el programa " + usuarioByCorreo.usuario.alumno[alumnoCandidato].programa.nombre + " y el período: " + usuarioByCorreo.usuario.alumno[alumnoCandidato].priodoIngresoNavigation.nombre;
                        }
                        else if (usuarioByCorreo.usuario.alumno[alumnoCandidato].alumnoGrupo.length >= 1) {
                          this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un alumno con el nombre de: " + nombreUsuario + " inscrito en el programa " + usuarioByCorreo.usuario.alumno[alumnoCandidato].programa.nombre;
                        } else if (usuarioByCorreo.usuario.alumno[alumnoCandidato].estatusAcademico == 7) {
                          this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + nombreUsuario + " con estatus cancelado. ";
                        } else if (usuarioByCorreo.usuario.alumno[alumnoCandidato].estatusAcademico == 8) {
                          this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un alumno con el nombre de: " + nombreUsuario + ".";
                        }
                      }
                      if (usuarioByCorreo.usuario.alumno[0].estatusAcademico == 7) {
                      } else {
                        this.snackService.mostrarSnackBack(this.tipoUsuarioEncontrado);
                      }
                    } else {
                      if (usuarioByCorreo.usuario.alumno[0].estatusAcademico == 2 && usuarioByCorreo.usuario.alumno[0].alumnoGrupo.length == 0) {
                        this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + nombreUsuario + " registrado para el programa " + usuarioByCorreo.usuario.alumno[0].programa.nombre + " y el período: " + usuarioByCorreo.usuario.alumno[0].priodoIngresoNavigation.nombre;
                      }
                      else if (usuarioByCorreo.usuario.alumno[0].alumnoGrupo.length >= 1) {
                        this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un alumno con el nombre de: " + nombreUsuario + " inscrito en el programa " + usuarioByCorreo.usuario.alumno[0].programa.nombre;
                      }
                      else if (usuarioByCorreo.usuario.alumno[0].estatusAcademico == 7) {
                        this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un candidato con el nombre de: " + nombreUsuario + " con estatus cancelado. ";
                      }
                      else if (usuarioByCorreo.usuario.alumno[0].estatusAcademico == 8) {
                        this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un alumno con el nombre de: " + nombreUsuario + ".";
                      } else {
                        this.snackService.mostrarSnackBack(this.tipoUsuarioEncontrado);
                      }
                    }
                  }
                  else {
                    this.nuevoIngresoService.definirDatosUsuarioBusquedaCorreo(usuarioByCorreo.usuario);
                    this.nombreUsuario = usuarioByCorreo.usuario.nombre + " " + usuarioByCorreo.usuario.apellidoPaterno + " " + usuarioByCorreo.usuario.apellidoMaterno;
                    this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un profesor con el nombre de: " + this.nombreUsuario + " debe darse de alta como alumno, si desea aplicar a un apoyo financiero. ";
                    this.snackService.mostrarSnackBack(this.tipoUsuarioEncontrado);
                    this.profesor = true;
                    this.solicitante.recibirInfoProfesor(this.correoBusqueda, true);
                  }

                } else {
                  this.solicitudApoyoFinancieroSerrvice.definirDatosUsuarioBusqueda(usuarioBusuqueda.data);
                  this.solicitante.correoBusqueda = this.correoBusqueda;
                  this.snackService.mostrarSnackBack('No se encontró a un usuario registrado. Por favor llene el formulario para registrar el Nuevo Solicitante. ');
                  this.solicitante.recibirInfo(this.busquedaCorreoForm.get('correo').value);
                  this.solicitante.renderizarForm();
                  this.ocultarBuscador = true;
                  this.ocultarNuevoUsuario = false;
                  this.mensajeUsuarioNoEncontrado = true;
                }
              }
            );
          }
        }
      );
    }
  }

  public enviarRegistroUsuario() {
    if (this.profesor == true) {
      this.datosNuevoUsuario = Object.assign(this.solicitante.nuevoSolicitudUsuarioForm.value);
      let registro: Registros = {
        usuarioId: this.datosNuevoUsuario.usuarioId,
        correo: this.datosNuevoUsuario.correoUsuario,
        periodo: {
          periodoId: this.datosNuevoUsuario.periodoUsuario
        },
        campus: {
          CampusId: this.datosNuevoUsuario.campusUsuario
        },
        programa: {
          ProgramaId: this.datosNuevoUsuario.programaAcademicoUsuario
        }
      };
      this.nuevoIngresoService.registrarProgramaUsuario(registro).subscribe(
        (respuesta: SimpleResponse) => {
          if (respuesta.success) {
            this.registrarUsuarioBandera = false;
            this.ocultarNuevoUsuario = false;
            this.obtenerInfoUsuario(this.datosNuevoUsuario.correoUsuario);
            this.nuevoIngresoService.refrescarTabla();
            this.nuevoIngresoService.updateTabs();
            this.ngOnInit();
            this.renderizarFoto();
            let mensaje: string = this.traductorService.translate('guardadoCorrecto');
            this.snackService.mostrarSnackBack(mensaje);
            this.nuevoIngresoService.invocarMetodoPrograma();
          } else {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        }
      );
    } else {
      this.datosNuevoUsuario = Object.assign(this.solicitante.nuevoSolicitudForm.value);
      let registro: RegistroApoyo = {
        usuario: {
          correo: this.datosNuevoUsuario.correo,
          nombre: this.datosNuevoUsuario.nombre,
          segundoNombre: this.datosNuevoUsuario.segundoNombre,
          apellidoPaterno: this.datosNuevoUsuario.apellidoPaterno,
          apellidoMaterno: this.datosNuevoUsuario.apellidoMaterno,
          password: this.datosNuevoUsuario.password
        },
        biografia: {
          fechaNacimiento: this.datosNuevoUsuario.fechaNacimiento
        },
        periodo: {
          periodoId: this.datosNuevoUsuario.periodo
        },
        campus: {
          CampusId: this.datosNuevoUsuario.campus
        },
        programa: {
          ProgramaId: this.datosNuevoUsuario.programaAcademico
        },
        solicitudApoyoFinanciero: {
          promedio: this.datosNuevoUsuario.promedio,
          tipoApoyoFinancieroId: this.datosNuevoUsuario.tipoApoyoFinanciero,
          empresaId: this.datosNuevoUsuario.empresa
        },
      };
      this.solicitudApoyoFinancieroSerrvice.registrarNuevoUsuario(registro).subscribe(
        (usuarioGuardado: SimpleResponse) => {
          if (usuarioGuardado.success) {
            this.solicitudApoyoFinancieroSerrvice.metodoObtenerSolicitudes();
            let mensaje: string = this.traductorService.translate('usuarioGuardadoCorrecto');
            this.snackService.mostrarSnackBack(mensaje);
            this.obtenerInfoUsuario(this.datosNuevoUsuario.correo);
          } else {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        }
      );
    }
  }

  public obtenerInfoUsuario(correo: string) {
    this.solicitudApoyoFinancieroSerrvice.obtenerUsuariobByCorreo(correo).subscribe(
      (usuarioBusuqueda: any) => {
        if (usuarioBusuqueda != null) {
          usuarioBusuqueda.solicitudApoyoFinancieroId = null;
          this.solicitudApoyoFinancieroSerrvice.definirDatosUsuarioBusqueda(usuarioBusuqueda);
          this.datosUsuarioActual = usuarioBusuqueda;
          this.solicitudCreacion = true;
          this.usuarioEncontrado = false;
          this.ocultarNuevoUsuario = true;
          this.mensajeUsuarioEncontrado = true;
          this.mensajeUsuarioNoEncontrado = false;
          this.renderizarFormulariosTabs();
          this.renderizarFoto();
        } else {
          this.snackService.mostrarSnackBack("Ocurrio un Error al buscar el usario.")
        }
      }
    );
  }

  public renderizarFormulariosTabs() {
    this.apoyoFinanciero.ngOnInit();
    this.apoyoFinanciero.renderizarFormulario();
    this.datosUsuario.renderizarFormulario();
    this.situacionEconomica.renderizarFormulario();
    this.cargaDocumentos.renderizarFormulario(this.datosUsuarioActual.usuarioId, this.datosUsuarioActual.solicitudApoyoFinancieroId, this.datosUsuarioActual.usuario.correo);
  }

  public enviarFormulario() {
    if (this.tabSeleccionado === 0) {
      let apoyoFinancieroForm = Object.assign(this.apoyoFinanciero.formApoyoFinanciero.value);
      let fechaActual = new Date();
      let solicitudApoyoFinanciero: SolicitudDeApoyo = {
        solicitudApoyoFinancieroId: this.datosUsuarioActual.solicitudApoyoFinancieroId === null ? 0 : this.datosUsuarioActual.solicitudApoyoFinancieroId,
        usuarioId: this.datosUsuarioActual.usuarioId,
        campusId: apoyoFinancieroForm.campus,
        periodoId: apoyoFinancieroForm.periodo,
        programaAcademicoId: apoyoFinancieroForm.programaAcademico,
        promedio: apoyoFinancieroForm.promedio,
        tipoApoyoFinancieroId: apoyoFinancieroForm.tipoApoyoFinanciero,
        empresaId: apoyoFinancieroForm.empresa === 0 ? null : apoyoFinancieroForm.empresa,
        fechaRevisionSolicitud: apoyoFinancieroForm.revisado === true ? fechaActual : null
      }
      if (solicitudApoyoFinanciero.solicitudApoyoFinancieroId === 0) {
        this.solicitudApoyoFinancieroSerrvice.registrarApoyoFinanciero(solicitudApoyoFinanciero).subscribe(
          (apoyoFinanciero: ApiResponse<number>) => {
            if (apoyoFinanciero.data != 0) {
              this.solicitudApoyoFinancieroSerrvice.setChangesApoyoFinanciero(false);
              this.solicitudApoyoFinancieroSerrvice.metodoObtenerSolicitudes();
              this.datosUsuarioActual.solicitudApoyoFinancieroId = apoyoFinanciero.data;
              let mensaje: string = this.traductorService.translate('apoyoGuardado');
              this.snackService.mostrarSnackBack(mensaje);
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
        );
      } else if (solicitudApoyoFinanciero.solicitudApoyoFinancieroId > 0) {
        this.solicitudApoyoFinancieroSerrvice.ActualizarApoyoFinanciero(solicitudApoyoFinanciero).subscribe(
          (apoyoFinanciero: SimpleResponse) => {
            if (apoyoFinanciero.success) {
              this.solicitudApoyoFinancieroSerrvice.setChangesApoyoFinanciero(false);
              this.solicitudApoyoFinancieroSerrvice.metodoObtenerSolicitudes();
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
        );
      }
    }
    if (this.tabSeleccionado === 1) {
      let datoCorreoValidar = Object.assign(this.datosUsuario.datosContactoForm.value);
      this.nuevoIngresoService.obtenerCorreoOcupado(datoCorreoValidar.correo, datoCorreoValidar.usuarioId).subscribe(
        (respuestaCorreo: SimpleResponse) => {
          if (respuestaCorreo.success) {
            let mensaje: string = this.traductorService.translate('errorCorreo');
            this.snackService.mostrarSnackBack(mensaje);
          } else {
            if (this.datosUsuario.datosPersonalesFormCambio) {
              let datosPersonalesForm = Object.assign(this.datosUsuario.datosPersonalesForm.value);
              let datosPersonales: DatosPersonalesApoyo = {
                usuarioId: datosPersonalesForm.usuarioId,
                nombre: datosPersonalesForm.nombre,
                segundoNombre: datosPersonalesForm.segundoNombre,
                apellidoPaterno: datosPersonalesForm.apellidoPaterno,
                apellidoMaterno: datosPersonalesForm.apellidoMaterno,
                fotografia: datosPersonalesForm.fotografia,
                fotografiaFile: datosPersonalesForm.fotografiaFile,
                biografia: {
                  fechaNacimiento: datosPersonalesForm.fechaNacimiento
                }
              }
              this.solicitudApoyoFinancieroSerrvice.editarDatosPersonales(datosPersonales).subscribe(
                (datosPersonales: SimpleResponse) => {
                  if (datosPersonales.success) {
                    this.datosPersonalesGuardado = true;
                  } else {
                    this.datosPersonalesGuardado = false;
                  }
                }
              )
            }
            if (this.datosUsuario.datosContactoFormCambio) {
              let datosContactoForm = Object.assign(this.datosUsuario.datosContactoForm.value);
              let datosContacto: DatosContactoF = {
                usuarioId: datosContactoForm.usuarioId,
                correo: datosContactoForm.correo,
                direccion: [
                  {
                    tipoDireccionId: datosContactoForm.tipoDireccionId,
                    entidadFederativaId: datosContactoForm.entidadFederativaId,
                    ciudad: datosContactoForm.ciudad,
                    calle: datosContactoForm.calle,
                    numeroExterior: datosContactoForm.numeroExterior,
                    numeroInterior: datosContactoForm.numeroInterior,
                    colonia: datosContactoForm.colonia,
                    codigoPostal: datosContactoForm.codigoPostal
                  }
                ],
                telefono: [
                  {
                    tipoTelefonoId: datosContactoForm.tipoTelefonoId,
                    paisId: datosContactoForm.paisId,
                    numero: datosContactoForm.numero,
                    extension: datosContactoForm.extension
                  }
                ]
              }
              this.solicitudApoyoFinancieroSerrvice.registrarDatosContacto(datosContacto).subscribe(
                (datosCntacto: SimpleResponse) => {
                  if (datosCntacto.success) {
                    this.datosContactoGuardado = true;
                  } else {
                    this.datosContactoGuardado = false;
                  }
                }
              )
            }
            if (this.datosUsuario.datosFamiliaresFormCambio) {
              let datosFamiliaresForm = Object.assign(this.datosUsuario.datosFamiliaresForm.value);
              let datosFamiliares: DatosFamilia[] = [];
              if (datosFamiliaresForm.nombreCompletoPadre != '') {
                let datosFamilia: DatosFamilia = {
                  UsuarioId: datosFamiliaresForm.usuarioId,
                  TipoRelacionId: 1,
                  NombreCompleto: datosFamiliaresForm.nombreCompletoPadre,
                  Telefono: datosFamiliaresForm.telefonoPadre,
                  CorreoElectronico: datosFamiliaresForm.correoElectronicoPadre
                }
                datosFamiliares.push(datosFamilia);
              }
              if (datosFamiliaresForm.nombreCompletoMadre != '') {
                let datosFamilia: DatosFamilia = {
                  UsuarioId: datosFamiliaresForm.usuarioId,
                  TipoRelacionId: 2,
                  NombreCompleto: datosFamiliaresForm.nombreCompletoMadre,
                  Telefono: datosFamiliaresForm.telefonoMadre,
                  CorreoElectronico: datosFamiliaresForm.correoElectronicoMadre,
                }
                datosFamiliares.push(datosFamilia);
              }
              if (datosFamiliaresForm.nombreCompletoTutor != '') {
                let datosFamilia: DatosFamilia = {
                  UsuarioId: datosFamiliaresForm.usuarioId,
                  TipoRelacionId: 3,
                  NombreCompleto: datosFamiliaresForm.nombreCompletoTutor,
                  Telefono: datosFamiliaresForm.telefonoTutor,
                  CorreoElectronico: datosFamiliaresForm.correoElectronicoTutor
                }
                datosFamiliares.push(datosFamilia);
              }
              this.solicitudApoyoFinancieroSerrvice.registrarDatosFamilia(datosFamiliares).subscribe(
                (datosFamilia: SimpleResponse) => {
                  if (datosFamilia.success) {
                    this.datosFamiliaresGuardado = true;
                  } else {
                    this.datosFamiliaresGuardado = false;
                  }
                }
              )
            }
            if (this.datosUsuario.datosOcupacionFormCambio) {
              let ocupacionForm = Object.assign(this.datosUsuario.datosOcupacionForm.value);
              this.solicitudApoyoFinancieroSerrvice.registrarOcupacion(ocupacionForm).subscribe(
                (ocupacion: SimpleResponse) => {
                  if (ocupacion.success) {
                    this.datosOcupacionGuardado = true;
                  } else {
                    this.datosOcupacionGuardado = false;
                  }
                }
              )
            }
            if (this.datosPersonalesGuardado && this.datosContactoGuardado && this.datosFamiliaresGuardado && this.datosOcupacionGuardado) {
              this.solicitudApoyoFinancieroSerrvice.metodoObtenerSolicitudes();
              this.solicitudApoyoFinancieroSerrvice.setChangesDatosUsuario(false);
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
        });
    }
    if (this.tabSeleccionado === 2) {
      let situacionEconomicaForm = Object.assign(this.situacionEconomica.situacionEconomicaForm.value);
      this.solicitudApoyoFinancieroSerrvice.registrarSituacionEconomica(situacionEconomicaForm).subscribe(
        (situacionEconomica: SimpleResponse) => {
          if (situacionEconomica.success) {
            this.solicitudApoyoFinancieroSerrvice.metodoObtenerSolicitudes();
            this.solicitudApoyoFinancieroSerrvice.setChangesSituacionEconomica(false);
            let mensaje: string = this.traductorService.translate('guardadoCorrecto');
            this.snackService.mostrarSnackBack(mensaje);
          } else {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        }
      )
    }
  }

  public obtenerNombreDocumento(documentoId: number, solicitud: SolicitudApoyoFinanciero) {
    let arregloDocsSolicitud: any[] = [];
    solicitud.usuario.usuarioDocumento.forEach(obj => {
      if (obj.documentoId === documentoId) {
        let valoresSplitted: string[] = obj.nombreFisico.split("-", 2);
        if (valoresSplitted[0] === solicitud.solicitudApoyoFinancieroId.toString() && valoresSplitted[1] === 'apoyo') {
          arregloDocsSolicitud.push(obj);
        }
      }
    });
    arregloDocsSolicitud.sort((a, b) => {
      return <any>new Date(b.fechaCreacion) - <any>new Date(a.fechaCreacion);
    });
    let documento = arregloDocsSolicitud.find(x => x.documentoId === documentoId);
    if (documento != null) {
      return documento.nombreOriginal;
    } else {
      return "No se cargo ningún documento"
    }
  }

  public obtenerFechaDocumento(documentoId: number, solicitud: SolicitudApoyoFinanciero) {
    let arregloDocsSolicitud: any[] = [];
    solicitud.usuario.usuarioDocumento.forEach(obj => {
      if (obj.documentoId === documentoId) {
        let valoresSplitted: string[] = obj.nombreFisico.split("-", 2);
        if (valoresSplitted[0] === solicitud.solicitudApoyoFinancieroId.toString() && valoresSplitted[1] === 'apoyo') {
          arregloDocsSolicitud.push(obj);
        }
      }
    });
    arregloDocsSolicitud.sort((a, b) => {
      return <any>new Date(b.fechaCreacion) - <any>new Date(a.fechaCreacion);
    });
    let documento = arregloDocsSolicitud.find(x => x.documentoId === documentoId);
    if (documento != null) {
      let fecha = this.utileriaService.formatearFecha(documento.fechaCreacion);
      return fecha;
    } else {
      return "Sin fecha de carga"
    }
  }

  public visualizarPdf(documentoId: number) {
    let solicitud = this.solicitudAceptada;
    let arregloDocsSolicitud: any[] = [];
    solicitud.usuario.usuarioDocumento.forEach(obj => {
      if (obj.documentoId === documentoId) {
        let valoresSplitted: string[] = obj.nombreFisico.split("-", 2);
        if (valoresSplitted[0] === solicitud.solicitudApoyoFinancieroId.toString() && valoresSplitted[1] === 'apoyo') {
          arregloDocsSolicitud.push(obj);
        }
      }
    });
    arregloDocsSolicitud.sort((a, b) => {
      return <any>new Date(b.fechaCreacion) - <any>new Date(a.fechaCreacion);
    });
    let documento = arregloDocsSolicitud.find(x => x.documentoId === documentoId);
    if (documento != null) {
      this.solicitudApoyoFinancieroSerrvice.obtenerDocumentoById(documento.usuarioDocumentoId).subscribe(
        (documento: ApiResponse<UsuarioDocumento>) => {
          if (documento.data !== null) {
            this.pdf = this.base64ToArrayBuffer(documento.data.nombreFisico);
            this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
          } else {
            this.snackService.mostrarSnackBack('El documento ya no se encuentra almacenado en la base de datos.');
          }
        }
      );
    } else {
      this.snackService.mostrarSnackBack('No hay ningún documento guardado.');
    }
  }

  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.replace("data:application/pdf;base64,", "");
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public cerrarModalVisualizador() {
    this.modalVisorPDFRef.close();
  }

  public onChangeTab(event: MatTabChangeEvent) {
    this.tabCambio = true;
    let tab: number = event.index;
    this.tabSeleccionado = tab;
    switch (this.tabSeleccionado) {
      case 0: {
        this.solicitudApoyoFinancieroSerrvice.definirFormularioValidoNuevaSolicitud(this.apoyoFinanciero.formApoyoFinanciero.valid);
        break;
      }
      case 1: {
        this.solicitudApoyoFinancieroSerrvice.definirFormularioValidoNuevaSolicitud(this.datosUsuario.datosUsuarioValid);
        break;
      }
      case 2: {
        this.solicitudApoyoFinancieroSerrvice.definirFormularioValidoNuevaSolicitud(this.situacionEconomica.situacionEconomicaForm.valid);
        break;
      }
      case 3: {
        break;
      }
      default: {
        break;
      }
    }
  }

  public cerrarModal() {
    if (this.changesApoyoFinanciero || this.changesDatosUsuario || this.changesSituacionEconomica || this.changesDocumentos) {
      if (this.changesApoyoFinanciero) {
        this.tabCambiosApoyoFinanciero = "Apoyo Financiero";
      } else {
        this.tabCambiosApoyoFinanciero = "";
      }
      if (this.changesDatosUsuario) {
        this.tabCambiosUsuario = " - Datos Usuario";
      } else {
        this.tabCambiosUsuario = "";
      }
      if (this.changesSituacionEconomica) {
        this.tabCambiosSituacionEconomica = " - Situación Económica";
      } else {
        this.tabCambiosSituacionEconomica = "";
      }
      if (this.changesDocumentos) {
        this.tabCambiosDocumentos = " - Carga Documentos";
      } else {
        this.tabCambiosDocumentos = "";
      }
      this.dialogoRefCerrarModal = this.dialog.open(this.dialogoCerrarModal, { disableClose: true });
    } else {
      this.dialogRef.close(this.data);
    }
  }

  public cerrarModalAdvertenciaCancelarCambios(descartar: boolean) {
    if (descartar) {
      this.dialogoRefCerrarModal.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefCerrarModal.close();
    }
  }

}
