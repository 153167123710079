import { Usuario } from './usuario';
import { Periodo } from '../_models/periodo'
import { Profesor } from '../_models/profesor'
import { Grupo } from '../_models/grupo'
import { EscalaCalificacion } from './escala-calificacion';

export class Grupos {
    /**
     * Modelo de modulo para grupos
     */
    modulo?: Modulo[];

    /**
     * Modelo de grupos para guardar
     */
    grupo?: Grupo[];

    /**
     * Modulo de periodos
     */
    periodo?: Periodo[];

    /**
     * Modulo de grupos parciales
     */
    grupoParcial?: GrupoParcial[];
}
export class GrupoParcial {
    /**
     * Identificador del grupo parcial
     */
    GrupoId: number;

    /**
     * Nombre del grupo
     */
    Nombre: string;

    /**
     * Numero de parciales que tendra el grupo
     */
    Parcial: number;

    /**
     * Ponderacion de los parciales y/o examenes
     */
    Ponderacion?: number;

    /**
     * Usuario que creo los registros
     */
    UsuarioCreacion: string;

    /**
     * Fecha de modificacion de los registros
     */
    FechaModificacion?: string;

    /**
     * Usuario de modifico los registros
     */
    UsuarioModificacion: string;

}
export class Modulo {
    /**
     * Modelo de recursos
     */
    recurso?: Recurso[];

    /**
     * Identificador unico de modulos
     */
    ModuloId: number;

    /**
     * Identificador unico del grupo al que corresponde el modulo
     */
    GrupoId: number;

    /**
     * Numero de parciales que contendra el modulo
     */
    parcial: number;

    /**
     * Clave del modulo
     */
    Clave: string;

    /**
     * Nombre del modulo
     */
    Nombre: string;

    /**
     * Orden de las materias del grupo
     */
    Orden: number;

    /**
     * Descripcion del modulo
     */
    Descripcion: string;


    /**
     * Usuario que creo el registro
     */
    UsuarioCreacion?: number;

    /**
     * Fecha de modificacion del registro
     */
    FechaModificacion: string;

    /**
     * Usuario que modifico el registro
     */
    UsuarioModificacion?: number;
}
export class Recurso {
    /**
     * Identificador unico del curso
     */
    RecursoId: number;

    /**
     * Identificador unico del modulo al que pertenece el recurso
     */
    ModuloId: number;

    /**
     * Tipo de recurso que se creara
     */
    TipoRecursoId: number;

    /**
     * Clave del recurso
     */
    Clave: string;

    /**
     * Nombre del recurso
     */
    Nombre: string;

    /**
     * Orden de los datos del recurso
     */
    Orden: number;

    /**
     * Descripcion del recurso
     */
    Descripcion: string;

    /**
     * Define si se encuentra publicado
     */
    Publicado: number;

    /**
     * Define la calificacion que tiene el recurso
     */
    Ponderacion?: number;

    /**
     * Define la fecha limite de entrega
     */
    FechaEntrega: string;

    /**
     * Define si el recurso puede ser entregado aunque este vencida la fecha de entrega
     */
    PermiteVencida?: number;

    /**
     * Define los minutos en los que ser� contestado el examen
     */
    Cronometro?: number;

    /**
     * Define si las preguntas seran organizadas al azar
     */
    OrdenAleatorio: number;

    /**
     * Define si el recurso esta cerrado para subir archivos
     */
    Cerrado: number;


    /**
     * Usuario que creo el registro
     */
    UsuarioCreacion: number;

    /**
     * Fecha en la que se modifico el registro
     */
    FechaModificacion: string;

    /**
     * Usuario que modifico el registro
     */
    UsuarioModificacion?: number;

    /**
     * Identificador unico del parcial al que pertenece el recurso
     */
    ParcialId?: number;

}
export class RecursoAlumnoArchivo {
    /**
     * Identificador unico del recurso que el alumno sube
     */
    recursoAlumnoArchivoId: number;

    /**
     * Identificador del tipo de recurso
     */
    recursoId: number;

    /**
     * Identificador unico del alumno que sube el archivo
     */
    alumnoId: number;

    /**
     * Nombre original del documento a subir
     */
    nombreOriginal: string;

    /**
     * Nombre fisico que tiene el documento al momento de subir
     */
    nombreFisico: string;


    /**
     * Usuario que creo el registro
     */
    usuarioCreacion: number;

    /**
     * Fecha en la que se modifica el registro
     */
    fechaModificacion?: string;

    /**
     * Usuario que modifico el registro
     */
    usuarioModificacion?: number;
}

///parte para cuestionarios Encuestas y Examenes
export class Seccion {
    /**
     * Nombre de la seccion a la que pertenece
     */
    nombre: string;

    /**
     * Preguntas que contendr� la seccion
     */
    cuestionarioPregunta: Pregunta[];

    /**
     * Constructor que agrega m�s preguntas a la seccion
     */
    constructor() {
        this.nombre = "";
        this.cuestionarioPregunta = [];
    }
}
export class Pregunta {
    /**
     * Identificador del tipo de pregunta
     */
    tipoPreguntaCuestionarioId: string;

    /**
     * Titulo de la pregunta
     */
    titulo: string;

    /**
     * Descripcion de la pregunta
     */
    descripcion: string;

    /**
     * Calificacion que tendra cada pregunta
     */
    ponderacion: number;

    /**
     * Modelo de respuesta
     */
    cuestionarioRespuesta: Respuesta[];

    /**
     * Constructor que agrega diversas preguntas
     */
    constructor() {
        this.tipoPreguntaCuestionarioId = "";
        this.titulo = "";
        this.descripcion = "";
        this.ponderacion = 0;
        this.cuestionarioRespuesta = [];
    }
}
export class Respuesta {
    /**
     * Titulo de la respuesta
     */
    titulo: string;

    /**
     * Calificacion que tendra la respuesta
     */
    ponderacion: number;

    /**
     * Define el numero de opciones que tendra para contestar
     */
    numeroOpcion: number

    /**
     * Constructor que registra una o multiples respuestas
     * @param opcionNumero
     */
    constructor(opcionNumero?) {
        this.titulo = "";
        this.ponderacion = 0;
        this.numeroOpcion = opcionNumero;
    }
}

export class Examen {
    //nombre: string;
    secciones: Seccion[];

    constructor() {
        //this.nombre = "";
        this.secciones = [];
    }
}

export interface ModuloInterface {
    /**
     * Nombre del modulo
     */
    nombre: string;

    /**
     * Numero del parcial
     */
    parcial: number;

    /**
     * Identificador del grupo
     */
    grupoID: number;
}

export class FechaTomaAsistenciaDto {
    /**
     * Fecha en la que se tomo asistencia
     */
    fecha: Date;

    /**
     * Hora a la que corresponde el grupo
     */
    hora: string;

    /**
     * Aula a la que pertenece la toma de asistencia
     */
    aula: string;
}

export class InformacionGrupoAsistencia {
    /**
     * Periodo al que pertenece la informacion de asistencia
     */
    periodoId: number;

    /**
     * Nombre del periodo
     */
    periodoNombre: string;

    /**
     * Identificador del grupo al que pertenece la asistencia
     */
    grupoId: number;

    /**
     * Clave del grupo
     */
    grupoClave: string;

    /**
     * Aula a la que pertenecen las asistencias del grupo
     */
    aula: string;

    /**
     * Materia a la que pertenece el grupo
     */
    materiaId: number;

    /**
     * Clave de la materia
     */
    materiaClave: string;

    /**
     * Nombre de la materia
     */
    materiaNombre: string;

    /**
     * Identificador del programa al que pertenece la materia y el grupo
     */
    programaId: number;

    /**
     * Identificador de la escala de calificaciones
     */
    escalaCalificacionId: number;

    /**
     * Modelo de escala de calificacion
     */
    escalaCalificacion: EscalaCalificacion;

    /**
     * Calificaciones completas de los alumnos
     */
    calificaciones: any;

    /**
     * Define el horario en el que se imparte la materia
     */
    horario: any;
    /**
     * Propiedad para guardar la url del grupo, este solo aplica cuando el lmsprovider es igual a 2(Teams)
     */
    grupoWebUrl: string;

    profesor?: Usuario;

    nombreProfesor?: string;

    blockCode?: string;
    blockName?: string;
}

export class ListaAlumnosGrupos {
    /**
     * Identificador del usuario
     */
    usuarioId: number;

    /**
     * Identificador del alumno
     */
    alumnoId: number;

    /**
     * Nombre(s) del alumno
     */
    nombre: string;

    /**
     * Apellido paterno del alumno
     */
    apellidoPaterno: string;

    /**
     * Apellido materno del alumno
     */
    apellidoMaterno?: string;

    /**
     * Nombre completo del alumno
     */
    nombreCompleto: string;

    /**
     * Correo del usuario
     */
    correoAl: string;

    /**
     * Programa al que esta inscrito el alumno
     */
    programaId?: number;

    /**
     * Nombre del programa
     */
    programaNombre: string;

    /**
     * Nombre corto del programa
     */
    programaNombreCorto: string;

    /**
     * Define si el alumno tiene falta en la clase
     */
    esFalta: boolean;

    /**
     * Define la fecha de entrega de una tarea, examen o encuesta.
     */
    fechaEntrega: Date;

    /**
     * Define la calificacion obtenida del recurso
     */
    calificacion: number;

    /**
     * Define el estatus del recurso: entregada, sin entregar, etc.
     */
    estatusRecurso: string;

    /**
     * Define la calificacion minima a obtener
     */
    calificacionMinima: number;

    /**
     * Identificador del ciclo al que pertenece la materia
     */
    cicloId: number;

    /**
     * Nombre del ciclo
     */
    cicloNombre: string;

    /**
     * Comentarios adicionales a la tarea o examen
     */
    comentarios: string;

    /**
     * Identificador del alumno
     */
    identificador: string;

    /**
     * Calificacion final que tiene el usuario
     */
    calificacionFinal: number;
}

export class InfoPorRevisarDto {
    /**
     * Identificador del grupo
     */
    grupoId: number;

    /**
     * Identificador del recurso
     */
    recursoId: number;

    /**
     * Identificador del modulo
     */
    moduloId: number;

    /**
     * Define el numero de parcial al que pertenece el recurso o la informacion a revisarParcialNombre
     */
    parcial: number;
    /**
    * Define el nombre del parcial al que pertenece el recurso o la informacion a revisar
    */
    parcialNombre: string;

    /**
     * Identificador del tipo de recurso
     */
    tipoRecursoId: number;

    /**
     * Nombre del tipo de recurso
     */
    tipoRecursoNombre: string;

    /**
     * Descripcion del recurso
     */
    descripcion: string;

    /**
     * Titulo de la informacion
     */
    titulo: string;

    /**
     * obtiene la puntuacion obtenida
     */
    puntuacion: number;

    /**
     * Define la fecha limite que tiene para agregar
     */
    fechaLimiteEntrega: Date;

    /**
     * Define el numero de recursos revisados
     */
    revisados: number;

    /**
     * Define si el trabajo esta por revisar o ya esta revisado
     */
    porRevisar: number;

    /**
     * Define si el usuario entrego o no el recurso
     */
    sinEntregar: number;

    /**
     * Define la cantidad de alumnos inscritos
     */
    numeroAlumnos: number;

    /**
     * Hora fin de entrega del recurso
     */
    horaFin: string;
}

export class CuestionarioArea {
    /**
     * Identificador del cuestionario area
     */
    cuestionarioAreaId: number;

    /**
     * Identificador del recurso al que pertenece el cuestionario
     */
    recursoId: number;

    /**
     * Nombre del cuestionario
     */
    nombre: string;
    /**
     * Usuario que creo el registro
     */
    usuarioCreacion: number;

    /**
     * Fecha en la que se modifico el registro
     */
    fechaModificacion: Date;
    fechaCreacion: Date;

    /**
     * Usuario que modifica la informacion
     */
    usuarioModificacion: number;

    /**
     * Preguntas propias del cuestionario
     */
    cuestionarioPregunta: any[];
    recurso: any;

    reintento?: boolean;
}

export class CuestionarioRespuestaDto {
    /**
     * Identificador del cuestionario con sus respuestas
     */
    cuestionarioRespuestaId: number;

    /**
     * Identificador del cuestionario con sus preguntas
     */
    cuestionarioPreguntaId: number;

    /**
     * Titulo de las respuestas del cuestionario
     */
    titulo: string;

    /**
     * Calificacion de las respuestas
     */
    ponderacion: number;

    /**
     * Calificacion de las preguntas
     */
    ponderacionPregunta: number;

    /**
     * Usuario de creacion del registro
     */
    usuarioCreacion: number;

    /**
     * Fecha de modificacion del registro
     */
    fechaModificacion: Date;

    /**
     * Usuario de modificacion del registro
     */
    usuarioModificacion: number;

    /**
     * Define el tipo de pregunta
     */
    tipoPreguntaCuestionarioId: number;

    calificaion?: number;
}

export class ContenidoParcial {
    /**
     * Nombre del contenido
     */
    nombre: string;

    /**
     * Numero de parcial al que corresponde
     */
    parcial: number;

    /**
     * Calificacion o ponderacion que contendr� el contenido
     */
    ponderacion: number;

    /**
     * Identificador del grupo al que  se guardara el contenido
     */
    grupoId?: number;
}

export class Calificaciones {
    /**
     * Identificador del grupo al que se le asigna la calificacion
     */
    grupoId: number;

    /**
     * Numero de parcial al cual calificar
     */
    parcial: number;

    /**
     * Identificador del alumno
     */
    alumnoId: number;

    /**
     * Identificador del usuario
     */
    usuarioId: number;

    /**
     * Nombre completo del usuario
     */
    nombreCompleto: string;

    /**
     * Define si el alumno esta en proceso de graduacion
     */
    procesoGraduacion: boolean;

    /**
     * Calificacion que saco el alumno en el curso
     */
    calificacion: number;

    /**
     * Identificador de la calificacion no numerica
     */
    calificacionNoNumericaId?: number;

    /**
     * Calificacion no numerica igual a la calificacion
     */
    calificacionNoNumerica: string;

    /**
     * Define la cantidad de faltas que obtuvo el usuario
     */
    faltas: number;

    /**
     * Define si la calificacion esta confirmada
     */
    confirmado: boolean;

    /**
     * Obtiene el usuario que esta en sesion
     */
    usuarioSesion: number;

    /**
     * Escala de calificacion
     */
    escalaCalificacionId: number;

    /**
     * Identificador del alumno al cual se registra
     */
    identificador: string;

    /**
     * Calificacion final del curso
     */
    calificacionFinal?: number;
}

export class TipoPreguntaCuestionario {
    /**
     * Identificador unico del tipo de pregunta
     */
    tipoPreguntaCuestionarioId: number;

    /**
     * Descripcion del tipo de pregunta
     */
    descripcion: string

    /**
     * Usuario de creacion del registro
     */
    usuarioCreacion: number;

    /**
     * Fecha en la que se modifico el registro
     */
    fechaModificacion: Date;

    /**
     * Usuario en el que se modifico el registro
     */
    usuarioModificacion: number;
}
