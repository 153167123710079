import { FiltroAdminAcademicoAlumnoDto } from './../../../_models/FiltroAdminAcademicoAlumnoDto';
import { baseUrl, environment } from 'src/environments/environment';
import { MakeUpExamResponse } from './../../core.domain/models/make-up-exam/make-up-exam.response';
import { ApiResponse, ApiResponseInstance } from './../../../models/api/ApiRespose.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Mapping } from '../../core.domain/class/mapping.class'

@Injectable({
  providedIn: 'root'
})
export class GenericHttpService {

  constructor(private _http: HttpClient) { }

  /**
   * A function that returns an observable of type ApiResponseInstance<MakeUpExamResponse[]>
   * @param {T} _class - The class that you want to map the response to.
   * @returns An observable of type ApiResponseInstance<MakeUpExamResponse[]>
   */
  public getMakeUpExam<T extends Mapping>(_class: T, page: number, itemsPerPage: number, filters: any ): Observable<ApiResponseInstance<T[]>> {    
  return this._http.post<ApiResponseInstance<T[]>>(`${environment.enves[baseUrl].apiUrl}/MakeUpExam/getTrakingTale/${page}/${itemsPerPage}`, filters).
    pipe(
      map((response: ApiResponseInstance<T[]>) => {        
          return new ApiResponseInstance( 
            response.data.map(res => _class.mapBackEnd(res)),
            response.success,
            response.message,
            )
          }
        )
      );
  }

  public downloadExcel(filtro: FiltroAdminAcademicoAlumnoDto) {
    return this._http.post(`${environment.enves[baseUrl].apiUrl}/MakeUpExam/downloadExcel`, filtro,
      {
        responseType: "blob"
      });
  }

  //#region Generic methods
  /**
   * This function takes a string as a parameter and returns an Observable of type T.
   * @param {string} urlEndpoint - string - The URL endpoint to call.
   * @returns Observable of T.
   */
   public get<T>(urlEndpoint: string): Observable<T> {
    return this._http.get<T>(`${environment.enves[baseUrl].apiUrl}/${urlEndpoint}`);
  }

 /**
  * This function takes a generic type T, and returns an Observable of type T.
  * @param {T} entity - The entity to be added.
  * @param {string} urlEndpoint - The url endpoint of the web api.
  * @returns Observable of T.
  */
  public add<T>(entity: T, urlEndpoint: string): Observable<T> {
    return this._http.post<T>(urlEndpoint, entity);
  }

  /**
   * This function takes an entity and a urlEndpoint and returns an Observable of type T.
   * @param {T} entity - The entity to be updated.
   * @param {string} urlEndpoint - The url endpoint of the web api.
   * @returns Observable of T.
   */
  public update<T>(entity: T, urlEndpoint: string): Observable<T> {
    return this._http.put<T>(urlEndpoint, entity);
  }

  /**
   * This function takes a string as a parameter and returns an Observable of type T.
   * @param {string} urlEndpoint - string - The URL endpoint to call.
   * @returns Observable of T.
   */
  public delete<T>(urlEndpoint: string): Observable<T> {
    return this._http.delete<T>(urlEndpoint);
  }
  //#endregion
  
}
