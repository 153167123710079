import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Profesor } from '../../../../_models/profesor';
import { ProfesorService } from '../../../../_services/profesor.service';
import { UsuarioService } from '../../../../_services/usuario.service';

@Component({
  selector: 'app-form-datos-familiares',
  templateUrl: './form-datos-familiares.component.html',
  styleUrls: ['./form-datos-familiares.component.scss']
})
export class FormDatosFamiliaresComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>
  public errorActualizaProfesor: string = "";
  public objValidacionCancelar: Profesor = null;
  public dialogRefAdver: any;
  public familiaForm: FormGroup;
  public tipoAccion: number;

  constructor(public dialogProfesorRef: MatDialogRef<FormDatosFamiliaresComponent>,

    private profesorService: ProfesorService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.inicializarForm();
  }

  public inicializarForm() {
    this.familiaForm = new FormGroup({
      parentesco: new FormControl(),
      nombre: new FormControl(),
      apellido: new FormControl(),
      telefono: new FormControl(),
      correo: new FormControl()
    });
  }

  get parentesco() { return this.familiaForm.get('parentesco'); }
  get nombre() { return this.familiaForm.get('nombre'); }
  get apellido() { return this.familiaForm.get('apellido'); }
  get telefono() { return this.familiaForm.get('telefono'); }
  get correo() { return this.familiaForm.get('correo'); }

  public cerrarModalValidacion() {

  }

}
