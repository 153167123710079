export class Language {
    languageId:     number;
    keyCode:        string;
    name:           string;
    isDefault:      boolean;
    institutionId:  string;
    isSaved:        boolean;
    isAuth:         boolean;

    selected?:      boolean;
    
    constructor(    
        languageId:     number,
        keyCode:        string,
        name:           string,
        isDefault:      boolean,
        institutionId:  string,
        isSaved:        boolean,
        isAuth:         boolean,
        
        selected?:      boolean
    ){        
        this.languageId     = languageId;
        this.keyCode        = keyCode;
        this.name           = name;
        this.isDefault      = isDefault;
        this.institutionId  = institutionId;
        this.isSaved        = isSaved;
        this.isAuth         = isAuth;

        this.selected       = selected;
    }

    static mapBackEnd(data: any): Language {
   
        return new Language(
          data.languageId,   
          data.keyCode,
          data.name,
          data.isDefault,
          data.institutionId,
          data.isSaved,
          data.isAuth,
          
          data.selected
        );    
      } 
}