import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Programa } from '../../../../_models/programa';
import { Campus } from '../../../../_models/campus';
import { Periodo } from '../../../../_models/periodo';
import { TipoApoyoFinanciero } from '../../../../_models/tipo-apoyo-financiero';
import { Empresa } from '../../../../_models/empresa';
import { PeriodoService } from '../../../../_services/periodo.service';
import { CampusService } from '../../../../_services/campus.service';
import { TipoApoyoFinancieroService } from '../../../../_services/tipo-apoyo-financiero.service';
import { ProgramaAcademicoService } from '../../../../_services/programa-academico.service';
import { EmpresaService } from '../../../../_services/empresa.service';
import { AccionEnum } from '../../../../_models/accion.enum';
import { SolicitudApoyoFinancieroService } from '../../../../_services/solicitud-apoyo-financiero.service';

@Component({
  selector: 'app-apoyo-financiero',
  templateUrl: './apoyo-financiero.component.html',
  styleUrls: ['./apoyo-financiero.component.scss']
})
export class ApoyoFinancieroComponent implements OnInit, OnDestroy {

  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public renderizarForm: boolean = false;
  public tipoAccion: AccionEnum;
  public formApoyoFinanciero: FormGroup;
  public subscritpionCampus: Subscription;
  public subscriptionPeriodo: Subscription;
  public subscriptionPrograma: Subscription;
  public subscriptionApoyoFinanciero: Subscription;
  public subscriptionEmpresa: Subscription;
  public campusList: Campus[] = [];
  public periodoList: Periodo[] = [];
  public programaList: Programa[] = [];
  public apoyoList: TipoApoyoFinanciero[] = [];
  public empresaList: Empresa[] = [];
  public sinConvenio: boolean = false;
  public conGrupo: boolean = false;

  constructor(private campusService: CampusService,
    private periodoService: PeriodoService,
    private programaService: ProgramaAcademicoService,
    private apoyoService: TipoApoyoFinancieroService,
    private empresaService: EmpresaService,
    private solicitudApoyoFinancieroService: SolicitudApoyoFinancieroService) { }

  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.solicitudApoyoFinancieroService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
  }

  public inicializarCat(): void {
    this.getTipoApoyo();
    this.getTipoCampus();
    this.getTipoPeriodo();
    this.getTipoEmpresa();
    this.inicializarForm();
  }

  public inicializarForm() {
    console.log(this.datosUsuarioBusqueda);
    this.formApoyoFinanciero = new FormGroup({
      campus: new FormControl(this.datosUsuarioBusqueda.usuario?.alumno[0].campusId, [Validators.required, this.validateId]),
      periodo: new FormControl(this.datosUsuarioBusqueda.usuario?.alumno[0].priodoIngreso, [Validators.required, this.validateId]),
      programaAcademico: new FormControl(this.datosUsuarioBusqueda.usuario?.alumno[0].programaId, [Validators.required, this.validateId]),
      promedio: new FormControl( this.datosUsuarioBusqueda.solicitudApoyoFinancieroId == 0 ? '' : this.datosUsuarioBusqueda.promedio, [Validators.required, Validators.pattern(/^[1-9]+/)]),
      tipoApoyoFinanciero: new FormControl(this.datosUsuarioBusqueda.solicitudApoyoFinancieroId == 0 ? '' : this.datosUsuarioBusqueda.tipoApoyoFinancieroId, [Validators.required, this.validateId]),
      empresa: new FormControl( this.datosUsuarioBusqueda.solicitudApoyoFinancieroId == 0 ? '' : this.datosUsuarioBusqueda.empresaId, [Validators.required, this.validateId]),
      revisado: new FormControl(this.datosUsuarioBusqueda.fechaRevisionSolicitud === null ? false : true)
    });

    this.renderizarForm = true;
    this.formApoyoFinanciero.valueChanges.subscribe(val => {
      this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(this.formApoyoFinanciero.valid);
      this.solicitudApoyoFinancieroService.setChangesApoyoFinanciero(true);
    });
    this.formApoyoFinanciero.get("campus").valueChanges.subscribe(campus => {
      this.onCampusSelected(campus);
      this.formApoyoFinanciero.patchValue({
        programaAcademico: 0
      });
    });
    if (this.datosUsuarioBusqueda?.usuario?.alumno[0].alumnoGrupo.length >= 1) {
      this.conGrupo = true;
    } else {
      this.conGrupo = false;
    }
    let valorInicial = this.apoyoList.find(x => x.tipoApoyoFinancieroId == this.datosUsuarioBusqueda?.tipoApoyoFinancieroId);
    if (valorInicial?.convenio) {
      this.sinConvenio = false;
      this.empresa.setValidators([Validators.required, this.validateId]);
      this.formApoyoFinanciero.controls.empresa.enable();
    } else {
      this.sinConvenio = true;
      this.empresa.setValidators(null);
    }
    this.formApoyoFinanciero.get("tipoApoyoFinanciero").valueChanges.subscribe(campus => {
      let vaor = this.apoyoList.find(x => x.tipoApoyoFinancieroId == campus);
      if (vaor !== undefined) {
        if (vaor.convenio) {
          this.sinConvenio = false;
          this.empresa.setValidators([Validators.required, this.validateId]);
          this.formApoyoFinanciero.patchValue({
            empresa: 0
          });
        } else {
          this.sinConvenio = true;
          this.empresa.setValidators(null);
          this.formApoyoFinanciero.patchValue({
            empresa: 0
          });
        }
        this.empresa.updateValueAndValidity();
      }
    });
    this.onCampusSelected(this.datosUsuarioBusqueda?.usuario?.alumno[0].campusId);
  }

  public renderizarFormulario() {
    if (this.datosUsuarioBusqueda?.solicitudApoyoFinancieroId != null && this.datosUsuarioBusqueda?.solicitudApoyoFinancieroId > 0) {
      this.tipoAccion = AccionEnum.EDITAR;
    } else {
      this.tipoAccion = AccionEnum.CREAR;
    }
    this.inicializarCat();
  }

  get campus() { return this.formApoyoFinanciero.get('campus'); }
  get periodo() { return this.formApoyoFinanciero.get('periodo'); }
  get programaAcademico() { return this.formApoyoFinanciero.get('programaAcademico'); }
  get promedio() { return this.formApoyoFinanciero.get('promedio'); }
  get tipoApoyoFinanciero() { return this.formApoyoFinanciero.get('tipoApoyoFinanciero'); }
  get empresa() { return this.formApoyoFinanciero.get('empresa'); }

  public getErrorFormCampus(controlName: string): string {
    let error = '';
    const control = this.formApoyoFinanciero.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Campus es requerido.";
    }
    return error;
  }

  public getErrorFormPrograma(controlName: string): string {
    let error = '';
    const control = this.formApoyoFinanciero.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Programa Académico es requerido.";
    }
    return error;
  }

  public getErrorFormPeriodo(controlName: string): string {
    let error = '';
    const control = this.formApoyoFinanciero.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Período es requerido.";
    }
    return error;
  }

  public getErrorFormTipoApoyo(controlName: string): string {
    let error = '';
    const control = this.formApoyoFinanciero.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Tipo Apoyo Financiero es requerido.";
    }
    return error;
  }

  public getErrorFormEmpresa(controlName: string): string {
    let error = '';
    const control = this.formApoyoFinanciero.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Empresa es requerido.";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public getTipoCampus() {
    this.subscritpionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      });
  }

  public getTipoPeriodo() {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoList = periodo;
      });
  }

  public getTipoPrograma() {
    this.subscriptionPrograma = this.programaService.obtenerProgramas().subscribe(
      (programa: Programa[]) => {
        this.programaList = programa;
      });
  }

  public onCampusSelected(campus: number) {
    this.programaList = [];
    if (!!campus) {
      this.campusService.obtenerProgramas(campus).subscribe(
        programas => {
          this.programaList = programas;
        })
    }
  }

  public getTipoApoyo() {
    this.subscriptionApoyoFinanciero = this.apoyoService.obtenerTipoApoyoFinanciero().subscribe(
      (apoyo: TipoApoyoFinanciero[]) => {
        this.apoyoList = apoyo;
      });
  }

  public getTipoEmpresa() {
    this.subscriptionEmpresa = this.empresaService.obtenerEmpresa().subscribe(
      (empresa: Empresa[]) => {
        this.empresaList = empresa;
      });
  }

  ngOnDestroy(): void {
    if (this.subscritpionCampus) {
      this.subscritpionCampus.unsubscribe();
    }
    if (this.subscriptionPeriodo) {
      this.subscriptionPeriodo.unsubscribe();
    }
    if (this.subscriptionApoyoFinanciero) {
      this.subscriptionApoyoFinanciero.unsubscribe();
    }
    if (this.subscriptionEmpresa) {
      this.subscriptionEmpresa.unsubscribe();
    }
  }
}
