import { Alumno } from 'src/app/_models';
import { Usuario } from '@app/_models/usuario';
import { STUDENT_LEDGER_CONTROLS } from './../../../../core.application/validators/student-ledger-validator';
import { Programa } from '@app/_models/programa';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { TraductorService } from 'src/app/_services/traductor.service';
import { SeguimientoCarteraService } from 'src/app/_services/seguimiento-cartera.service';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiResponse } from '../../../../../models/api/ApiRespose.model';
import { ResumenEstadoCuentaDto } from '../../../../../_models/resumenEstadoCuentaDto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PeriodoDto } from '@app/_models/periodo';
import { EstadoCuentaDto } from '@app/_models/estado-cuenta-dto';
import { ColegiaturaCosto } from '@app/_models/colegiatura-costo';
import { CorreoSeguimiento } from '../../../../../_models/seguimiento-cartera';
import { MatDialog } from '@angular/material/dialog';
import { EnviarCorreoCandidatoComponent } from '../../../../../nuevo-ingreso/nuevo-ingreso-modal/enviar-correo-candidato/enviar-correo-candidato.component';

@Component({
  selector: 'app-student-ledger',
  templateUrl: './student-ledger.component.html',
  styleUrls: ['./student-ledger.component.scss']
})
export class StudentLedgerComponent implements OnInit, OnDestroy, AfterViewInit {

  //#region Input, Ouput, Emitter
  @Input() alumnoId: number;
  @Input() usuarioId: number;  

  @Input() tabId: number;
  @Input() tabIndex: number;
  @Output() formValid = new EventEmitter<boolean>();
  //#endregion

  //#region Observable
  private Subscription: Subscription = new Subscription();
  //#endregion  

  //#region Properties
  public ResumenEstadoCuentaDto: ResumenEstadoCuentaDto;
  public Programas: Programa[];
  public Periodos: PeriodoDto[];
  public Usuario: Usuario;
  public Alumno: Alumno;
  public vencimientoColegiatura: any;
  public EstadoCuenta: EstadoCuentaDto[];
  public EstadoCuentaLess: EstadoCuentaDto[];
  public EstadoCuentaMore: EstadoCuentaDto[];
  public flagPaginator: boolean;
  public studentLedgerForm: FormGroup = this.formBuilder.group(STUDENT_LEDGER_CONTROLS);
  //#endregion

 //#region LifeCycle
 constructor(
  private formBuilder: FormBuilder,
  private _seguimientoService: SeguimientoCarteraService,
  private _traductorService: TraductorService,
  private _dialog: MatDialog,
  private _utileriaService: UtileriasService
  ) {} 
 

  ngOnInit(): void {        
    this._getInformationByUsuarioId();    
  }

  ngAfterViewInit(): void {
    this._subscribeToForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabIndex']) {
      changes['tabIndex'].currentValue === this.tabId ? this.Emit() : null;
    }
  }

  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  //#region Emmit Valid
  public Emit($event?: boolean):void{
    this.formValid.emit($event);
  }
  //#endregion



  //#region Methods  

  private _getAllMethods():void{
    this._getInformationSummaryAccount();
    this._getProgramsByUsuarioId();
    this._getPeriodosByAlumnoId();    
  }

 /**
  * It gets the information summary account.
  */
  private _getInformationSummaryAccount() {
    this.Subscription.add(
      this._seguimientoService.obtenerResumenEstadoCuentaByUsuarioId(this.alumnoId).subscribe(
        (response: ApiResponse<ResumenEstadoCuentaDto>) => {
          if (response.data.saldoVencido > 0) {
            let mensaje: string = this._traductorService.translate('_catalogos.cartera.nombre-1');          
          } else {
            let mensaje: string = this._traductorService.translate('_catalogos.cartera.nombre-2');          
          }
          this.ResumenEstadoCuentaDto = response.data;
        }
      )
    );          
  }


  /**
   * This function is used to get the programs by user id
   */
  private _getProgramsByUsuarioId(): void {
    this.Subscription.add(
      this._seguimientoService.obteneProgramasUsuario(this.usuarioId).subscribe((response: ApiResponse<Programa[]>) => {
        this.Programas = response.data;        
      })
    );
  }

 /**
  * It gets the periods of the student.
  */
  private _getPeriodosByAlumnoId():void{
   this.Subscription.add(
    this._seguimientoService.obtenePeriodosAlumno(this.alumnoId).subscribe((Response: ApiResponse<PeriodoDto[]>) => {
      this.Periodos = Response.data;
      this._getEstadoDeCuentaByUsuarioId(this.Periodos[0].periodoId, this.alumnoId);
      this._getFechaVencimiento(this.Periodos[0].periodoId);      
    })
   );
  }

  /**
   * It gets the account status by user id.
   */
  private _getEstadoDeCuentaByUsuarioId(periodoId: number, alumnoId: number):void {
   this.Subscription.add(
    this._seguimientoService.obtenerEstadoCuentaByUsuarioId(this.usuarioId, periodoId, alumnoId).subscribe(
      (Response: EstadoCuentaDto[]) => {
        this.EstadoCuenta = Response;

        this.EstadoCuentaLess = Response.slice(0, 4);
        this.EstadoCuenta = this.EstadoCuentaLess;
        this.EstadoCuentaMore = Response.slice(4);
        
      })
   );
  }

  private _getFechaVencimiento(periodoId: number):void{
    this._seguimientoService.obtenerFechaVencimiento(this.Alumno.programaId, this.Alumno.campusId, periodoId).subscribe(
      (response: ColegiaturaCosto[]) => {
        if (response != null) {
          this.vencimientoColegiatura = response[0].fechaVencimiento;
          console.log("this.vencimientoColegiatura",this.vencimientoColegiatura);
        }
      }
    );
  }

  /**
   * It concatenates the EstadoCuentaMore array to the EstadoCuenta array if the flag is true, and if
   * it's false, it slices the EstadoCuenta array to only show the first 5 elements
   * @param {boolean} flag - boolean - This is the flag that will be sent from the component.
   */
  public showMoreMovements(flag: boolean):void {
    if (flag) {
      this.EstadoCuenta = this.EstadoCuenta.concat(this.EstadoCuentaMore);
      this.flagPaginator = false;
    } else {
      this.EstadoCuenta = this.EstadoCuenta.slice(0, 5);
      this.flagPaginator = true;
    }
  }


  private _getInformationByUsuarioId(): void {
    this.Subscription.add(
      this._seguimientoService.obtenerSeguimientoUsuarioById(this.usuarioId).subscribe(
        (response: ApiResponse<Usuario>) => {
          this.Usuario = response.data;
          this.Alumno = this.Usuario.alumno.find(x => x.alumnoId == this.alumnoId);
          this._getAllMethods();
          this.studentLedgerForm.get('programaId').setValue(this.Alumno.programaId);
          this.studentLedgerForm.get('periodoId').setValue(this.Alumno.priodoIngreso);
        })
    );
  }
  //#endregion

  //#region FormChange

  private _subscribeToForm(): void {
    this.Subscription.add(
      this.studentLedgerForm
      .controls['programaId']
        .valueChanges
          .subscribe(programaId => {                    
            this.changePrograma(programaId);
          }
        )
      );

      this.Subscription.add(
        this.studentLedgerForm
        .controls['periodoId']
          .valueChanges
            .subscribe(periodoId => {                    
              this._getEstadoDeCuentaByUsuarioId(periodoId, this.alumnoId);
            }
          )
        );
  }
  public changePrograma(programaId: number): void {    
    this.Alumno = this.Usuario.alumno.find(x => x.programaId == programaId);
    this.alumnoId = this.Alumno.alumnoId;
    this._getInformationSummaryAccount();
    this._getPeriodosByAlumnoId();        
  }

  //#endregion

  //#region Excel & Correo
  public descargarExcelMovimientos(): void {
    this.Subscription.add(
      this._seguimientoService.descargarExcelMovimientos(this.EstadoCuenta).subscribe(
        (res: Blob) => {
          var filename = "Estado.xlsx";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
            window.navigator.msSaveOrOpenBlob(res, filename);
          } else {
            var a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            var blob = new Blob([res], { type: 'application/xlsx' });
            a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            a.click();
          }        
        }
      )
    );
  }

  public enviarCorreoUsuario(): void {
    var datos: CorreoSeguimiento = {
      correo: this.Usuario.correo
    };
    this._dialog.open(EnviarCorreoCandidatoComponent, { data: datos, disableClose: true });
  }
  //#endregion

}
