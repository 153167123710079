import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service';
import { TipoApoyoFinancieroService } from '../../../_services/tipo-apoyo-financiero.service';
@Component({
  selector: 'app-tipo-apoyo-financiero-eliminar',
  templateUrl: './tipo-apoyo-financiero-eliminar.component.html',
  styleUrls: ['./tipo-apoyo-financiero-eliminar.component.scss']
})
export class TipoApoyoFinancieroEliminarComponent implements OnInit {

  public numGruposSeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public claveTipos: string = '';

  constructor(public dialogRef: MatDialogRef<TipoApoyoFinancieroEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private tipoApoyoFinacieroService: TipoApoyoFinancieroService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numGruposSeleccionados = this.data.length;
    }
  }

  public eliminarTipoApoyoFinanciero() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(p => (p.tipoApoyoFinancieroId.toString()));
      this.claveTipos = arrayClaves.toString();
    } else {
      this.claveTipos = this.data.tipoApoyoFinancieroId.toString();
    }
    this.tipoApoyoFinacieroService.eliminarTipoApoyoFinanciero(this.claveTipos).subscribe(
      eliminado => {
        if (eliminado) {
          let mensaje: string = this.traductorService.translate('eliminarCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
          this.tipoApoyoFinacieroService.invocarMetodoObtenerTipoApoyoFinanciero();
          this.dialogRef.close();
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      },
      error => {
        let mensaje: string = this.traductorService.translate('errorGuardar');
        this.snackService.mostrarSnackBack(mensaje);
      }
    );
  }

}
