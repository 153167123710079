import { InsitucionService } from './../../../_services/insitucion.service';
import { baseUrl, environment } from './../../../../environments/environment';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginator } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { InfoPrograma } from '../../../_models/infoPrograma';
import { InfoProgramaService } from '../../../_services/infoPrograma.service';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service'
import { SelectionModel } from '@angular/cdk/collections';
import { ModalCorreoComponent } from '../mi-grupo/modal-correo/modal-correo.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { ListaAlumnosGrupos } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { EnviarCorreoComponent } from 'src/app/_components/enviar-correo/enviar-correo.component';

@Component({
  selector: 'app-mi-grupo',
  templateUrl: './mi-grupo.component.html',
  styleUrls: ['./mi-grupo.component.scss']
})
export class MiGrupoComponent implements OnInit {
  @Input() grupo: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  grupoSelect;
  public filtroForm: FormGroup;
  public Programas: InfoPrograma[] = [];
  selection = new SelectionModel<any>(true, []);

  displayedColumns: string[] = ['select', 'ID', 'nombre', 'programa'];
  public dataSource: MatTableDataSource<any>;
  public datafilter: any;
  public numIngresoSeleccionado: number = 0;
  public arregloFiltroOriginal: any[];
  //Tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;
  public lmsProviderId: number;

  constructor(
    private paginador: MatPaginatorIntl,
    public programaservice: InfoProgramaService,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private dialog: MatDialog,
    private institucionService: InsitucionService
  ) { }

  ngOnInit(): void {
    if (this.grupo) {
      this.grupoSelect = this.grupo;
    }
    this.obtenerInformacionInstitucion();
    this.cargarAlumnos();
    this.obtenerProgramas();
    this.inicializarForm();
  }

  /**
 * Obtiene la informacion de la insitutcion
 */
  public obtenerInformacionInstitucion(): void {
    this.institucionService.obtenerInformacionInstitucion(this.tenantId).subscribe((response: any) => {
      this.lmsProviderId = response.lmsProviderId;
    })
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      idnombre: new FormControl(),
      programaAcademico: new FormControl(0)
    });
  }

  public filtrar() {
    var programa = this.filtroForm['value'].programaAcademico;
    var alumno = this.filtroForm['value'].idnombre == null ? "" : this.filtroForm['value'].idnombre;
    let filtroModificado: any = [];
    filtroModificado = this.arregloFiltroOriginal
    if (programa != 0) {
      filtroModificado = filtroModificado.filter(row => row.programaId === programa);
    }
    if (alumno.length > 0 || alumno !== '') {
      filtroModificado = filtroModificado.filter(row => {
        return row.nombre.toLowerCase().search(alumno.toLowerCase()) > -1 || row.identificador.search(alumno) > -1;
      })
    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
  }

  public limpiar() {
    this.inicializarForm();
    this.dataSource = new MatTableDataSource(this.datafilter);
    this.dataSource.paginator = this.paginator;
  }

  public obtenerProgramas() {
    this.EnsenanzaAprendizajeAlumnoService.GetProgramasMigrupo(this.grupo.grupoId).subscribe(
      (programas: ApiResponse<InfoPrograma[]>) => {
        this.Programas = programas.data;
      }
    );
  }

  public cargarAlumnos() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerAlumnos(this.grupoSelect.grupoId).subscribe(
      (miGrupo: ApiResponse<ListaAlumnosGrupos[]>) => {
        this.dataSource = new MatTableDataSource(miGrupo.data);
        this.dataSource.paginator = this.paginator;
        this.datafilter = miGrupo.data;
        this.arregloFiltroOriginal = miGrupo.data;
      });
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.datafilter.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    if (this.datafilter) {
      this.numIngresoSeleccionado = this.selection.selected.length;
      const numRows = this.datafilter.length;
      return this.numIngresoSeleccionado === numRows;
    }
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }

  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    this.dialog.open(EnviarCorreoComponent, { data: datos, disableClose: true });
  }

}
