import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ConfiguracionApoyoFinanciero } from '../_models/configuracion-apoyo-financiero';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionApoyoFinancieroService {

  invocarObtenerTipoApoyoFinancieroEmit = new EventEmitter();
  metodoTipoApoyoFinancieroSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  //Post Configuracion Apoyo Financiero
  public agregarConfiguracionApoyoFinanciero(configuracionApoyoFinanciero: ConfiguracionApoyoFinanciero): Observable<ConfiguracionApoyoFinanciero> {
    return this.httpClient.post<ConfiguracionApoyoFinanciero>(`${environment.enves[baseUrl].apiUrl}/ConfiguracionApoyoFinanciero`, configuracionApoyoFinanciero);
  }

}
