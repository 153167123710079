import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RequestCategoryDTO } from 'src/app/_models/solicitud-dto';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { SolicitudCategoriaModalEdicionComponent } from './solicitud-categoria-modal-edicion/solicitud-categoria-modal-edicion.component';
import { Subscription } from 'rxjs';
import { SolicitudCategoriaModalEliminarComponent } from './solicitud-categoria-modal-eliminar/solicitud-categoria-modal-eliminar.component';

@Component({
  selector: 'app-solicitud-categoria',
  templateUrl: './solicitud-categoria.component.html',
  styleUrls: ['./solicitud-categoria.component.scss']
})
export class SolicitudCategoriaComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'clave', 'nombre', 'estatus'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  public ExcelSubscription: Subscription;
  public requestCategory: any;
  public infoCargado: boolean = false;
  public numRequestSeleccionado: number = 0;
  public claveName = new FormControl();
  private arrOriginal: RequestCategoryDTO[] = [];

  constructor(private solicitudService: SolicitudService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private util: UtileriasService) { }

  ngOnInit(): void {
    if(this.solicitudService.invMetodoObtenerCategoriaSubscription === undefined){
      this.solicitudService.invMetodoObtenerCategoriaSubscription = this.solicitudService.invocarObtenerCategorias.subscribe(() => {
        this.obtenerCategoriasSolicitud();
      });
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
  
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
    }
    this.obtenerCategoriasSolicitud();
  }

  public obtenerCategoriasSolicitud() {
    this.solicitudService.obtenerSolicitudCategoria().subscribe((categorias: ApiResponse<RequestCategoryDTO[]>) => {
      this.dataSource = new MatTableDataSource(categorias.data);
      this.selection = new SelectionModel<any>(true, []);
      this.dataSource.paginator = this.paginator;
      this.infoCargado = true;
      this.arrOriginal = [...categorias.data];
    });
  }

  public crear(): void {
    let datos: RequestCategoryDTO = {
      requestCategoryId: 0,
      code: '',
      name: '',
      status: false,
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(SolicitudCategoriaModalEdicionComponent, datos);
    dialogo.afterClosed().subscribe((result) => {
      this.requestCategory = result;
    });
  }

  public editar(elemento?: any): void {
    let datos: RequestCategoryDTO = {
      requestCategoryId: elemento.requestCategoryId,
      code: elemento.code,
      name: elemento.name,
      status: elemento.status,
      isLetter: elemento.isLetter,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(SolicitudCategoriaModalEdicionComponent, datos);
  }

  public descargarExcel() {
    this.ExcelSubscription = this.solicitudService.downloadExcelCategoria(this.arrOriginal).subscribe((res: Blob) => {
      let filename: string = "Request-Category.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        let a: HTMLAnchorElement = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        let blob: Blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  public borrar(btnDataTable: boolean, elemento?: any) {
    if(btnDataTable) {
      var datos = elemento;
    } else {
      if(elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    this.dialog.open(SolicitudCategoriaModalEliminarComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  public filtro() {
    let claveNombre: string = this.claveName === null ? '' : this.claveName.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    filtroModificado = filtroModificado.filter(f => {
      return f.code.toLowerCase().search(claveNombre.toLocaleLowerCase()) > -1 || f.name.toLowerCase().search(claveNombre.toLocaleLowerCase()) > -1;
    });
    this.dataSource = new MatTableDataSource(filtroModificado);
  }

  limpiarFiltros() {
    this.claveName.setValue('');
    this.obtenerCategoriasSolicitud();
  }

  isAllSelected() {
    this.numRequestSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numRequestSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row))
  }
}
