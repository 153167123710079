import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Salon } from '../../../../_models/salon';
import { GrupoService } from '../../../../_services/grupo.service';

@Component({
  selector: 'app-form-profesor-aula',
  templateUrl: './form-profesor-aula.component.html',
  styleUrls: ['./form-profesor-aula.component.scss']
})
export class FormProfesorAulaComponent implements OnInit, OnDestroy {

  public formProfesorAula: FormGroup;
  public datosCampusPeriodo$: Observable<any>;
  public datosBloque$: Observable<any>;
  public datosMateria$: Observable<any>;
  public subscriptionSalon: Subscription;
  public salonList: Salon[] = [];
  public campusId: number;

  constructor(private grupoService: GrupoService) { }

  ngOnInit(): void {
    this.datosCampusPeriodo$ = this.grupoService.obtenerDatosCampusPeriodo$();

    this.grupoService.obtenerDatosCampusPeriodo$().subscribe(data => {
      this.campusId = data.campus.campusId;
      this.obtenerSalones();
    });

    this.datosMateria$ = this.grupoService.obtenerDatosMateria$();
    this.datosBloque$ = this.grupoService.obtenerDatosBloque$();

    this.inicializaFrom();
  }

  public inicializaFrom() {
    this.formProfesorAula = new FormGroup({
      salonId: new FormControl(0)
    });
  }

  public obtenerSalones() {
    this.subscriptionSalon = this.grupoService.obtenerSalon(this.campusId, true).subscribe(
      (salon: Salon[]) => {
        this.salonList = salon;
      });
  }

  public finalizarFormulario() {
    this.grupoService.invocarMetodoGruardarGrupo();
  }

  ngOnDestroy(): void {
    if (this.subscriptionSalon) {
      this.subscriptionSalon.unsubscribe();
    }
  }

}
