import { MatDialog } from '@angular/material/dialog';
import { LANGUAGE_CONTROLS } from './../../../../core.application/validators/language/language-form.validators';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, Output, EventEmitter, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { Mapping } from '@app/clean-architecture/core.domain/class/mapping.class';
import { LanguageService } from '@app/clean-architecture/infrastructure/services/language/language.service';
import { Language } from '@app/clean-architecture/core.domain/models/language/language.model';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { SnackService } from '@app/services/snack-service.service';
import { TraductorService } from '@app/_services/traductor.service';
import { EmitterSaveActionService } from '@app/clean-architecture/infrastructure/services/common/emitter-save-action.service';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit, OnChanges, OnDestroy {

  //#region Inputs, Outputs
  @Input() tabId: number;
  @Input() tabIndex: number;
  @Output() formValid = new EventEmitter<boolean>();
  //#endregion
  
  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion
  
  //#region Properties
  public Languages: Language[] = [];
  public LanguagesToSelected: Language[] = [];
  public LanguagesByInstitution: Language[] = [];  
  public languageAddForm: FormGroup = this.formBuilder.group(LANGUAGE_CONTROLS);

  private _modalAddLanguagesRef: any;  
  //#endregion

  //#region ViewChild
  @ViewChild('modalAddLanguages') modalAddLanguages: TemplateRef<any>;
  //#endregion

  //#region LifeCycle
  constructor(
    private _EmitterSaveActionService : EmitterSaveActionService,
    private formBuilder: FormBuilder,
    private _LanguageService: LanguageService,
    private _dialog: MatDialog,
    private _snackService: SnackService,
    private _traductorService: TraductorService,
    @Inject(I18NEXT_SERVICE) private _i18NextService: ITranslationService,
  ) {
    this.Subscription.add(
      _EmitterSaveActionService.$emitSaveTab.subscribe((tabIndex: number) => {
        if (tabIndex === this.tabId) {        
          this.postLanguagesInstitution();
        }
      })
    );
  }
  
  ngOnInit(): void {    
    this.getLanguages(Language);
    this.getLanguagesByInstitution(Language);
    this._subscribeToForm();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabIndex']) {
      changes['tabIndex'].currentValue === this.tabId ? this.Emit() : null;
    }   
  }
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  //#region Methods
  public Emit():void{
    this.formValid.emit(true);
  }
  private _subscribeToForm(): void {
    this.Subscription.add(
      this.languageAddForm
      .statusChanges
        .subscribe(status => {
          }
        )
      );
  }
  public openModalAddLanguages(): void
  {    
    this.languageAddForm.get('language')?.setValue([]);
    this._modalAddLanguagesRef = this._dialog.open(this.modalAddLanguages, { disableClose: true });
  }
  public closeModalAddLanguages():void
  {
    this._modalAddLanguagesRef.close();
  }
  //#endregion

  //#region Languages
  public getLanguages<T extends Mapping>(_class: T):void {        
    this.Subscription.add(
      this._LanguageService.getLanguages(_class).subscribe((response: ApiResponse<any[]>) => {    
        this.Languages = response.data;
        this.LanguagesToSelected = response.data;
      })
    ); 
  }

  public getLanguagesByInstitution<T extends Mapping>(_class: T):void {        
    this.Subscription.add(
      this._LanguageService.getLanguagesByInstitution(_class).subscribe((response: ApiResponse<any[]>) => {    
        this.LanguagesByInstitution = response.data;        
      })
    ); 
  }

  private postLanguagesInstitution():void {        
    this._LanguageService.postLanguagesInstitution(this.LanguagesByInstitution).subscribe(
      (response: SimpleResponse) => {
        if (response.success) {
          this.getLanguages(Language);
          this.getLanguagesByInstitution(Language);
          let mensaje: string = this._traductorService.translate('guardadoCorrecto');
          this._snackService.mostrarSnackBack(mensaje);

          const languageDefault = this.LanguagesByInstitution.find(x => x.isDefault === true);
          this._i18NextService.changeLanguage(languageDefault.keyCode).then(x => {                         
            document.location.reload();
          });
        }else{
          let mensaje: string = this._traductorService.translate('errorGuardar');
          this._snackService.mostrarSnackBack(mensaje);
        }
    });    
  }

  private deleteLanguagesInstitution(languageId: number):void {
    const languageToDelete = this.LanguagesByInstitution.filter(x => x.languageId === languageId);
    if (languageToDelete[0].isSaved) {
      this._LanguageService.deleteLanguagesInstitution(languageToDelete).subscribe(
        (response: SimpleResponse) => {
          if (response.success) {
            this.getLanguages(Language);
            this.getLanguagesByInstitution(Language);
            let mensaje: string = this._traductorService.translate('guardadoCorrecto');
            this._snackService.mostrarSnackBack(mensaje);
          }else{
            let mensaje: string = this._traductorService.translate('errorGuardar');
            this._snackService.mostrarSnackBack(mensaje);
          }
      });
    } else {
      this.LanguagesByInstitution.forEach(x => {      
        languageToDelete.forEach(j => {        
          if (x.languageId === j.languageId) {
            x.selected = false;
          }else{
            x.selected = true;
          }
        });
      });
      this.LanguagesByInstitution = this.LanguagesByInstitution.filter( x => x.selected === true);      
      this.Languages.forEach(x => {      
       if (this.LanguagesByInstitution.length > 0) {
        this.LanguagesByInstitution.forEach(j => {        
          if (x.languageId === j.languageId) {
            x.selected = true;
          }
        });
       } else {
        x.selected = false;
       }
      });
      this.LanguagesToSelected = this.Languages.filter( x => x.selected === false || x.selected === undefined);      
    }
  }

  public addLanguage():void
  {
    this.LanguagesByInstitution = this.LanguagesByInstitution.concat(this.languageAddForm.get('language')?.value);    
    this.Languages.forEach(x => {
      this.LanguagesByInstitution.forEach(j => {
        if (x.languageId === j.languageId) {
          x.selected = true;          
        }
      });
    });    
    
    this.LanguagesToSelected = this.Languages.filter( x => x.selected === false || x.selected === undefined);    

    this.closeModalAddLanguages();
  }

  public makeMainLanguage(languageId: number):void
  {
      this.LanguagesByInstitution.forEach(element => {
       
        if (languageId === element.languageId) {
          element.isDefault = true;
        } else {                
          element.isDefault = false;
        }

      });
  }

  public removeLanguage(languageId: number):void
  {
    this.deleteLanguagesInstitution(languageId);
  }
  //#endregion

  //#region Test
    // en:any;
    // es:any;
    // private test():void
    // {
  
    //   this.Subscription.add(
    //     this._LanguageService.getEnglish().subscribe((response: any) => {    
    //       this.en = response;
  
    //       this.Subscription.add(
    //         this._LanguageService.getSpanish().subscribe((response: any) => {    
    //           this.es = response;
    //           this.assingLanguage();            
    //         })
    //       );
  
    //     })
    //   );
  
    //   this.assingLanguage();
    // }
  
    // private assingLanguage():void
    // {
  
    //   console.log("RESULT", this.jsonConcat(this.en, this.es))
    // }
  
    // private jsonConcat(o1, o2) {
    //   for (var key in o2) {
    //   if (typeof o1[key] === "string") {
    //     o1[key] = o1[key] + '/' +o2[key];
    //   }else if (typeof o1[key] === "object") {
        
    //     this.jsonConcat(o1[key], o2[key]);
  
    //   }
    //   }
    //   return o1;
    // }
  
    //#endregion
}
