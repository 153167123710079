import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service'
import { UtileriasService } from '../../../_services//utilerias.service';
import { ModalDetalleComponent } from '../modal-detalle/modal-detalle.component';
import { Grupos } from '../../../_models/ensenanzaAprendizajeAlumno';
import { Recurso } from 'src/app/_models/recurso';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { RecursoAdjunto } from 'src/app/_models/recurso-adjunto';
import { Grupo } from 'src/app/_models/grupo';
import { ContenidoParcial } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { I18NextPipe } from 'angular-i18next';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-mi-curso',
  templateUrl: './mi-curso.component.html',
  styleUrls: ['./mi-curso.component.scss']
})
export class MiCursoComponent implements OnInit {
  @Input() grupoID: number;
  public alumnoId: number;
  expandir: boolean = false;
  indexRecurso = -1;
  recursoSelect;
  flagButtonDescarga: boolean = false;
  recursoId: number = 0;
  public activeIndex: number;
  esArchivo: boolean;
  link: string;
  constructor(public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    public util: UtileriasService,
    private ensenanzaApredServ: EnsenanzaAprendizajeService,
    private i18nextPipe: I18NextPipe,
    private activatedRoute: ActivatedRoute,
    private traductorService: TraductorService) { }
  contenido: ContenidoParcial[] = [];
  grupoParcial: any[] = [];
  modulos: any[] = [];
  expandirModulo: boolean = false;
  datosGrupo: any;
  puntosBaseParcial: number;
  existeParcialFinal: boolean = false;
  public btnElemento: string = '';
  indiceModulo: number;
  indiceRecurso: number;
  public ultimoParcial: number;
  //Variable validar modal
  public existeModal$: Observable<boolean>;
  public existeModalDetalle: boolean = false;
  //Variables actividades secuenciales
  public activityOrderType: number = 0;
  public mensajeSecuencial:string = "";

  ngOnInit(): void {
    let mensaje: string = this.traductorService.translate("_tableroAlumno.mensajeSecuencuial");
    this.mensajeSecuencial = mensaje;
    this.alumnoId = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;
    this.flagButtonDescarga = false
    this.cargarContenido();
    this.cargarDatosGrupo();
    this.existeModal$ = this.EnsenanzaAprendizajeAlumnoService.GetModalExistente$();
    this.existeModal$.subscribe(
      (existe: any) => {
        this.existeModalDetalle = existe;
      });
    // const recursoIdParam: string = this.activatedRoute.snapshot.paramMap.get('recursoId');
    // if (recursoIdParam !== null) {
    //   if (!this.existeModalDetalle) {
    //     const recursoId: number = +recursoIdParam;
    //     this.EnsenanzaAprendizajeAlumnoService.obtenerRecursoByRecursoId(recursoId, this.alumnoId).subscribe((response: ApiResponse<Recurso>) => {
    //       this.recursoSelect = response.data;
    //       this.verDetalle();
    //     });
    //   }
    // }
  }

  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  grupo: Grupos[];
  public cargarContenido() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerContenidoAlumno(this.grupoID).subscribe((respuestaContenido: ApiResponse<any>) => {
      this.grupo = respuestaContenido.data;
      if (respuestaContenido.data) {
        this.activityOrderType = respuestaContenido.data.activityOrderTypeId;
        if (respuestaContenido.data.modulo.length > 0) {
          this.EnsenanzaAprendizajeAlumnoService.definirModulo(respuestaContenido.data.modulo);

          for (var i = 0; i < respuestaContenido.data.modulo.length; i++) {
            var recurso = respuestaContenido.data.modulo[i].recurso
            for (var x = 0; x < recurso.length; x++) {
              var RecursoAdjunto = recurso[x].recursoAdjunto;
              for (var y = 0; y < RecursoAdjunto.length; y++) {
                var indexDocument = RecursoAdjunto[y].nombreOriginal.indexOf(".");
                var tipoDocument = RecursoAdjunto[y].nombreOriginal.substr(indexDocument);
                RecursoAdjunto[y].tipoDocumento = tipoDocument;
              }
            }
          }
        }
        this.grupoParcial = respuestaContenido.data.grupoParcial;
        this.cargarParciales();
        this.modulos = respuestaContenido.data.modulo;
        this.EnsenanzaAprendizajeAlumnoService.definirProfesorId(respuestaContenido.data.profesorId);
        this.EnsenanzaAprendizajeAlumnoService.definirClaveMateria(respuestaContenido.data.clave);
      }

    })
  }

  public cargarDatosGrupo() {
    this.ensenanzaApredServ.getPonderacionGrupo(this.grupoID).subscribe((grupo: ApiResponse<any>) => {
      this.datosGrupo = grupo.data;
      this.puntosBaseParcial = this.datosGrupo.materiaOferta.materia.ciclo.programa.escalaCalificacionNavigation.calificacionNoNumerica[0].valorNumerico;
    })
  }

  public cargarParciales() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerParciales(this.alumnoId, this.grupoID).subscribe(resp => {
      if (resp.exito) {
        if (resp.dataParcialFinal) {
          this.existeParcialFinal = true;
          this.ultimoParcial = this.grupoParcial[this.grupoParcial.length - 1].parcial;
        } else {
          this.existeParcialFinal = false;
        }
        resp.dataParciales.forEach(parcial => {

          let parcialText = '_tableroProfesor.parcial';
          if (!!this.existeParcialFinal && parcial == resp.dataParciales.length) {
            parcialText = '_tableroProfesor.parcial-final';
          };

          if (this.grupoParcial.length > 0) {
            let nuevoParcial = this.grupoParcial.find(object => {
              return object.parcial == parcial
            });
            if (nuevoParcial) {
              let parcialobj = {
                parcial: nuevoParcial.parcial,
                ponderacion: nuevoParcial.ponderacion,
                nombre: nuevoParcial.nombre,
              }
              if (!parcialobj?.nombre) {
                parcialobj.nombre = this.translate(parcialText) + ' ' + parcial;
              }
              this.contenido.push(parcialobj);
            } else {

              let _parcialObj = {
                parcial: parcial,
                ponderacion: 0,
                nombre: this.translate(parcialText) + ' ' + parcial
              }
              this.contenido.push(_parcialObj);
            }
          }
          else {
            let _parcialObj = {
              parcial: parcial,
              ponderacion: 0,
              nombre: this.translate(parcialText) + ' ' + parcial
            }
            this.contenido.push(_parcialObj);
          }
        });
      }
    })
  }


  public mostrarDescargar(documento, mostrar, index) {
    this.activeIndex = index;
    this.esArchivo = documento.esArchivo;
    if (!this.esArchivo) {
      this.link = documento.nombreFisico;
    }
    if (this.flagButtonDescarga) {
      this.flagButtonDescarga = false;
      this.recursoId = 0;
    } else {
      this.recursoId = documento.recursoAdjuntoId;
      this.flagButtonDescarga = true;
    }
  }

  public expandirTema(elemento, indiceModulo, expande) {
    this.btnElemento = elemento;
    this.indiceModulo = indiceModulo;
    this.expandir = expande;
  }

  public expandirRecurso(elemento, indiceModulo, index, expande, recurso, recursoSeleccionado) {
    if (this.activityOrderType === 2) {
      if (!recursoSeleccionado.desactivarRecurso) {
        this.btnElemento = elemento;
        this.indiceModulo = indiceModulo;
        this.indiceRecurso = index;
        this.indexRecurso = index;
        this.expandir = expande;
        if (this.expandir) {
          this.recursoSelect = recurso.recurso[index];
          this.recursoSelect.parcialID = recurso.parcial;
        }
        this.flagButtonDescarga = false;
      }
    } else {
      this.btnElemento = elemento;
      this.indiceModulo = indiceModulo;
      this.indiceRecurso = index;
      this.indexRecurso = index;
      this.expandir = expande;
      if (this.expandir) {
        this.recursoSelect = recurso.recurso[index];
        this.recursoSelect.parcialID = recurso.parcial;
      }
      this.flagButtonDescarga = false;
    }
  }

  ubicarBoton(elemento: string) {
    this.btnElemento = elemento;
  }

  public descargarRecurso(recursitoId, recursoSeleccionado) {
    if (this.activityOrderType === 2) {
      if (!recursoSeleccionado.desactivarRecurso) {
        this.EnsenanzaAprendizajeAlumnoService.obtenerRecursoAdjunto(recursitoId).subscribe(
          (respuestaRecurso: ApiResponse<RecursoAdjunto>) => {
            let documento: ArrayBufferLike = this.base64ToArrayBuffer(respuestaRecurso.data.nombreFisico);
            let mime: string = respuestaRecurso.data.nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : "application/octect-stream";
            let a: HTMLAnchorElement = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            let blob: Blob = new Blob([documento], { type: mime });
            a.href = window.URL.createObjectURL(blob);
            a.download = respuestaRecurso.data.nombreOriginal;
            a.click();
          })
      }
    } else {
      this.EnsenanzaAprendizajeAlumnoService.obtenerRecursoAdjunto(recursitoId).subscribe(
        (respuestaRecurso: ApiResponse<RecursoAdjunto>) => {
          let documento: ArrayBufferLike = this.base64ToArrayBuffer(respuestaRecurso.data.nombreFisico);
          let mime: string = respuestaRecurso.data.nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : "application/octect-stream";
          let a: HTMLAnchorElement = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          let blob: Blob = new Blob([documento], { type: mime });
          a.href = window.URL.createObjectURL(blob);
          a.download = respuestaRecurso.data.nombreOriginal;
          a.click();
        })
    }
  }

  public abrirUrl(recursoSeleccionado) {
    if (this.activityOrderType === 2) {
      if (!recursoSeleccionado.desactivarRecurso) {
        window.open(this.link, "_blank");
      }
    } else {
      window.open(this.link, "_blank");
    }
  }

  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public verDetalle(recursoSeleccionado) {
    if (this.activityOrderType === 2) {
      if (!recursoSeleccionado.desactivarRecurso) {
        const opcionIdParam: string = this.activatedRoute.snapshot.paramMap.get('opcionId');
        if (opcionIdParam === "micurso") {
          this.recursoSelect.entregar = false;
          this.recursoSelect.calificacion = true;
          this.recursoSelect.ultimoParcial = this.ultimoParcial;
          this.recursoSelect.existeParcialFinal = this.existeParcialFinal;
          this.recursoSelect.soloDetalle = false;
          this.util.abrirDialogoLateral(ModalDetalleComponent, this.recursoSelect);
        }
      }
    } else {
      const opcionIdParam: string = this.activatedRoute.snapshot.paramMap.get('opcionId');
      if (opcionIdParam === "micurso") {
        this.recursoSelect.entregar = false;
        this.recursoSelect.calificacion = true;
        this.recursoSelect.ultimoParcial = this.ultimoParcial;
        this.recursoSelect.existeParcialFinal = this.existeParcialFinal;
        this.recursoSelect.soloDetalle = false;
        this.util.abrirDialogoLateral(ModalDetalleComponent, this.recursoSelect);
      }
    }
  }
}

