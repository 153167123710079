import { BiografiaService } from './../../../../../_services/biografia.service';
import { TraductorService } from '@app/_services/traductor.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { EmitterSaveActionService } from '@app/clean-architecture/infrastructure/services/common/emitter-save-action.service';
import { OCUPATION_INFORMATION_MODEL } from './../../../../core.domain/models/user/ocupation-information.model';
import { PREVIOUS_INFORMATION_MODEL } from './../../../../core.domain/models/user/previous-studies-information.model';
import { AdminAcademicaService } from './../../../../../_services/admin-academica.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApiResponse } from '../../../../../models/api/ApiRespose.model';
import { PERSONAL_INFORMATION_MODEL } from '../../../../core.domain/models/user/personal-information.model';
import { CONTACT_INFORMATION_MODEL } from '../../../../core.domain/models/user/contact-information.model';
import { BIOGRAPHICAL_INFORMATION_MODEL } from '../../../../core.domain/models/user/biographical-information.model';
import { PERSONAL_CONTACTS_INFORMATION_MODEL } from '../../../../core.domain/models/user/personal-contacts-information.model';
import { MEDICAL_INFORMATION_MODEL } from '../../../../core.domain/models/user/medical-information.model';
import { BILLING_INFORMATION_MODEL } from '../../../../core.domain/models/user/billing-information.model';
import { SimpleResponse } from '../../../../../models/api/SimpleResponse.model';
import { Biografia } from '../../../../../_models/biografia';
import { User } from '../../../../core.domain/models/user/user-data-model';
import { PersonaRelacionada } from '../../../../../_models/persona-relacionada';
import { EstudiosPrevios } from '../../../../../_models/estudios-previos';
import { DatoFiscal } from '../../../../../_models/dato-fiscal';
import { Ocupacion } from '../../../../../_models/Ocupacion.model';


export class UserDataBase {

    //#region Subscriptions
    public Subscription: Subscription = new Subscription();
    //#endregion

    //#region Properties
    public User: User;
    private errorAlActualizar: boolean;

    //#endregion

    constructor(
        protected _adminAcademicaService: AdminAcademicaService,
        protected _EmitterSaveActionService: EmitterSaveActionService,
        protected nuevoIngresoService: NuevoIngresoService,
        protected snackService: SnackService,
        protected traductorService: TraductorService,
        protected biografiaService: BiografiaService,
    ) { }


    //#region Save
    
    public save() {        
        this.comprobarDisponibilidadCorreo();        
    }

    private _saveAllData():void
    {
      this.editarDatosPeronales();
      this.editarDatosDeContacto();
      this.validarCurpIngresada();
      this.actualizarDatosPersonasRelacionadas();
      this.actualizarDatosDeEmergencia();
      this.actualizarEstudiosPrevios();
      this.comprobarDisponibilidadRFC();
      this.actualizarDatosDeOcupacion();
    }

    private comprobarDisponibilidadCorreo(): void {
        this.Subscription.add(
          this.nuevoIngresoService.obtenerCorreoOcupado(this.CONTACT_INFORMATION.correo, this.User.usuarioId).subscribe(
            (ocupado: SimpleResponse) => {
              if (ocupado.success) {
                let mensaje: string = this.traductorService.translate('errorCorreo');
                this.snackService.mostrarSnackBack(mensaje);
                this.errorAlActualizar = true;
              }
              else {
                this._saveAllData();
              }
            })
        );
      }

      private editarDatosPeronales(): void {
        let datosPersonales = {
          correo: this.CONTACT_INFORMATION.correo,
          nombre: this.PERSONAL_INFORMATION.nombre,
          segundoNombre: this.PERSONAL_INFORMATION.segundoNombre,
          usuarioId: this.User.usuarioId,
          apellidoPaterno: this.PERSONAL_INFORMATION.apellidoPaterno,
          apellidoMaterno: this.PERSONAL_INFORMATION.apellidoMaterno,
          biografia: {
            fechaNacimiento: this.PERSONAL_INFORMATION.fechaNacimiento
          }
        };
        this.Subscription.add(
          this.nuevoIngresoService.editarDatosPersonales(datosPersonales).subscribe((exito: SimpleResponse) => {
            if (exito.success) {
              
            }
            else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
              this.errorAlActualizar = true;
            }
          })
        );
      }

      private editarDatosDeContacto(): void {
        let datosContacto = {
          usuarioId: this.User.usuarioId,
          correo: this.CONTACT_INFORMATION.correo,
          direccion: [
            {
              tipoDireccionId: this.CONTACT_INFORMATION.tipoDireccionId,
              paisId: this.CONTACT_INFORMATION.paisId,
              entidadFederativaId: this.CONTACT_INFORMATION.entidadFederativaId,
              calle: this.CONTACT_INFORMATION.calle,
              ciudad: this.CONTACT_INFORMATION.ciudad,
              numeroExterior: this.CONTACT_INFORMATION.numeroExterior,
              numeroInterior: this.CONTACT_INFORMATION.numeroInterior,
              colonia: this.CONTACT_INFORMATION.ciudad,
              codigoPostal: this.CONTACT_INFORMATION.codigoPostal
            }
          ],
          telefono: [
            {
              tipoTelefonoId: this.CONTACT_INFORMATION.tipoTelefonoId,
              numero: this.CONTACT_INFORMATION.numero,
              paisId: this.CONTACT_INFORMATION.paisId,
              extension: this.CONTACT_INFORMATION.extension
            }
          ]
        }
        this.Subscription.add(
          this.nuevoIngresoService.registrarDatosContacto(datosContacto).subscribe((datosActualizados: SimpleResponse) => {
            if (datosActualizados.success) {
              
            }
            else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
              this.errorAlActualizar = true;
            }
          })
        );
      }

      private validarCurpIngresada(): void {
        this.Subscription.add(
          this.biografiaService.obtenerBiografiaByCurp(this.BIOGRAPHICAL_INFORMATION.curp, this.User.usuarioId).subscribe(
            (CurpEncontrada: Biografia) => {
                if (CurpEncontrada) {
                let mensaje: string = this.traductorService.translate('errorCRUP');
                this.snackService.mostrarSnackBack(mensaje);
                this.errorAlActualizar = true;
                }
                else {
                this.modificarBiografia();
                }
          })
        );
      }

      private modificarBiografia(): void {
        let datosBiografia: Biografia = new Biografia();
        datosBiografia.usuarioId = this.User.usuarioId;
        datosBiografia.legalGeneroId = this.BIOGRAPHICAL_INFORMATION.legalGeneroId;
        datosBiografia.generoId = this.BIOGRAPHICAL_INFORMATION.generoId;
        datosBiografia.entidadNacimiento = this.BIOGRAPHICAL_INFORMATION.entidadNacimiento;
        datosBiografia.ciudadNacimiento = this.BIOGRAPHICAL_INFORMATION.ciudadNacimiento;
        datosBiografia.estadoCivilId = this.BIOGRAPHICAL_INFORMATION.estadoCivilId;
        datosBiografia.ciudadaniaPaisId = this.BIOGRAPHICAL_INFORMATION.ciudadaniaPaisId;
        datosBiografia.curp = this.BIOGRAPHICAL_INFORMATION.curp;
        datosBiografia.grupoSanguineoId = this.MEDICAL_INFORMATION.grupoSanguineoId;
        datosBiografia.alergias = this.MEDICAL_INFORMATION.alergias;
        datosBiografia.enfermedades = this.MEDICAL_INFORMATION.enfermedades;
    
        this.Subscription.add(
          this.nuevoIngresoService.modificarBiografia(datosBiografia).subscribe((datosActualizados: SimpleResponse) => {
            if (datosActualizados.success) {
              
            }
            else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
              this.errorAlActualizar = true;
            }
          })
        );
      }

      private actualizarDatosPersonasRelacionadas(): void {
        let personasRelacionadas = new Array<PersonaRelacionada>();        
        this.PERSONAL_CONTACTS_INFORMATION.forEach(element => {
            if (element.tipoRelacionId !== 4) {
                let data = new PersonaRelacionada();
                data.usuarioId = this.User.usuarioId;
                data.tipoRelacionId =element.tipoRelacionId;
                data.nombreCompleto = element.nombreCompleto;
                data.telefono = element.telefono;
                data.correoElectronico = element.correoElectronico;
                personasRelacionadas.push(data);
            }
        });
        this.Subscription.add(
          this.nuevoIngresoService.registrarDatosFamilia(personasRelacionadas).subscribe((datosFamilairesActualziados: SimpleResponse) => {
            if (datosFamilairesActualziados.success) {
              
            }
            else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
              this.errorAlActualizar = true;
            }
          })
        );
      }

      private actualizarDatosDeEmergencia(): void {
        let datosEmergencia = new PersonaRelacionada();
        datosEmergencia.usuarioId = this.User.usuarioId;
        datosEmergencia.tipoRelacionId = this.PERSONAL_CONTACTS_INFORMATION[3]?.tipoRelacionId;
        datosEmergencia.nombreCompleto = this.PERSONAL_CONTACTS_INFORMATION[3]?.nombreCompleto;
        datosEmergencia.telefono = this.PERSONAL_CONTACTS_INFORMATION[3]?.telefono;
        datosEmergencia.correoElectronico = this.PERSONAL_CONTACTS_INFORMATION[3]?.correoElectronico;
        this.Subscription.add(
          this.nuevoIngresoService.registrarDatosEmergencia(datosEmergencia).subscribe((datosActualizados: SimpleResponse) => {
            if (datosActualizados.success) {              
            }
            else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
              this.errorAlActualizar = true;
            }
          })
        );
      }

      private actualizarEstudiosPrevios(): void {
        let datosEstudiosPrevios = new EstudiosPrevios();
        datosEstudiosPrevios.usuarioId = this.User.usuarioId;
        datosEstudiosPrevios.nivelId = this.PREVIOUS_INFORMATION.nivelId;
        datosEstudiosPrevios.escuela = this.PREVIOUS_INFORMATION.escuela;
        datosEstudiosPrevios.promedio = this.PREVIOUS_INFORMATION.promedio;        
        this.nuevoIngresoService.agregarEstudiosPrevios(datosEstudiosPrevios).subscribe((datosActualizados: SimpleResponse) => {
          if (datosActualizados.success) {            
          }
          else {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
            this.errorAlActualizar = true;
          }
        });
      }

      private comprobarDisponibilidadRFC(): void {
        this.Subscription.add(
          this.nuevoIngresoService.GetDatoFiscalByRFC(this.BILLING_INFORMATION.rfc, this.User.usuarioId).subscribe(
            (datoFiscalEncontrado: ApiResponse<DatoFiscal>) => {
              if (datoFiscalEncontrado.data) {
                this.errorAlActualizar = true;
                let mensaje: string = this.traductorService.translate('errorRFC');
                this.snackService.mostrarSnackBack(mensaje);
              }
              else {
                this.actualizarDatosFiscales();
              }
            })
        );
      }

      private actualizarDatosFiscales(): void {
        let datosFiscales = new DatoFiscal();
        datosFiscales.usuarioId = this.User.usuarioId;
        datosFiscales.rfc = this.BILLING_INFORMATION.rfc;
        datosFiscales.razonSocial = this.BILLING_INFORMATION.razonSocial;
        datosFiscales.entidadFederativaId = this.BILLING_INFORMATION.entidadFederativaId;
        datosFiscales.calle = this.BILLING_INFORMATION.calle;
        datosFiscales.ciudad = this.BILLING_INFORMATION.ciudad;
        datosFiscales.numeroExterior = this.BILLING_INFORMATION.numeroExterior;
        datosFiscales.numeroInterior = this.BILLING_INFORMATION.numeroInterior;
        datosFiscales.colonia = this.BILLING_INFORMATION.colonia;
        datosFiscales.codigoPostal = this.BILLING_INFORMATION.codigoPostal;
        datosFiscales.nombre = this.PERSONAL_INFORMATION.nombre;
        datosFiscales.apellidoPaterno = this.PERSONAL_INFORMATION.apellidoPaterno;
        datosFiscales.apellidoMaterno = this.PERSONAL_INFORMATION.apellidoMaterno;
        this.Subscription.add(
          this.nuevoIngresoService.agregarDatosFiscal(datosFiscales).subscribe((datosFiscalesActualizados: SimpleResponse) => {
            if (datosFiscalesActualizados.success) {
              
            }
            else {
              let mensaje: string = this.traductorService.translate('errorRFC');
              this.snackService.mostrarSnackBack(mensaje);
              this.errorAlActualizar = true;
            }
          })
        );
      }
    
      private actualizarDatosDeOcupacion(): void {
        let ocupacion = new Ocupacion();
        ocupacion.usuarioId = this.User.usuarioId;
        ocupacion.tipoEmpresaId = this.OCUPATION_INFORMATION.tipoEmpresaId;
        ocupacion.tipoPuestoEmpresaId = this.OCUPATION_INFORMATION.tipoPuestoEmpresaId;
        this.Subscription.add(
          this.nuevoIngresoService.registrarOcupacion(ocupacion).subscribe((datosOcupacionActualizados: SimpleResponse) => {
            if (datosOcupacionActualizados.success) {
              this.snackService.mostrarSnackBack('Se han guardado los datos correctamente.');              
            }
            else {
              let mensaje: string = this.traductorService.translate('errorRFC');
              this.snackService.mostrarSnackBack(mensaje);
            }
          })
        );
      }
    //#endregion

    //#region Methods
    public _getAllUserInformation(identificador: string): void {
        this.Subscription.add(
            this._adminAcademicaService.ObtenerInformacionUsuario(identificador).subscribe(
                (response: ApiResponse<User>) => {
                    this.User = User.mapBackEnd(response.data);                    
                    this._setAllData();
                })
        );
    }
    private _setAllData(): void {
        this._setPersonalInformation();
        this._setContactInformation();
        this._setBiographicalInformation();
        this._setPersonalContactsInformation();
        this._setMedicalInformation();
        this._setPreviousInformation();
        this._setBillingInformation();
        this._setOcupationInformation();
    }
    //#endregion

    //#region  get & set data
    public PERSONAL_INFORMATION_MODEL: PERSONAL_INFORMATION_MODEL;
    private _setPersonalInformation(): void {
        this.PERSONAL_INFORMATION_MODEL = new PERSONAL_INFORMATION_MODEL(
            this.User.nombre,
            this.User.segundoNombre,
            this.User.apellidoPaterno,
            this.User.apellidoMaterno,
            this.User.biografia.fechaNacimiento,
        );
    }
    public PERSONAL_INFORMATION: PERSONAL_INFORMATION_MODEL;
    public _getPersonalInformation(data: PERSONAL_INFORMATION_MODEL): void {
        this.PERSONAL_INFORMATION = new PERSONAL_INFORMATION_MODEL(
            data.nombre,
            data.segundoNombre,
            data.apellidoPaterno,
            data.apellidoMaterno,
            data.fechaNacimiento,
        );
    }




    public CONTACT_INFORMATION_MODEL: CONTACT_INFORMATION_MODEL;
    private _setContactInformation(): void {
        this.CONTACT_INFORMATION_MODEL = new CONTACT_INFORMATION_MODEL(
            this.User.correo,
            this.User.direccion[0].tipoDireccionId,
            this.User.direccion[0].entidadFederativa.paisId,
            this.User.direccion[0].entidadFederativaId,
            this.User.direccion[0].ciudad,
            this.User.direccion[0].calle,
            this.User.direccion[0].numeroExterior,
            this.User.direccion[0].numeroInterior,
            this.User.direccion[0].colonia,
            this.User.direccion[0].codigoPostal,
            this.User.telefono[0].tipoTelefonoId,
            this.User.telefono[0].numero,
            this.User.telefono[0].extension,
        );
    }
    public CONTACT_INFORMATION: CONTACT_INFORMATION_MODEL;
    public _getContactInformation(data: CONTACT_INFORMATION_MODEL): void {
        this.CONTACT_INFORMATION = new CONTACT_INFORMATION_MODEL(
            data.correo,
            data.tipoDireccionId,
            data.paisId,
            data.entidadFederativaId,
            data.ciudad,
            data.calle,
            data.numeroExterior,
            data.numeroInterior,
            data.colonia,
            data.codigoPostal,
            data.tipoTelefonoId,
            data.numero,
            data.extension,
        );
    }




    public BIOGRAPHICAL_INFORMATION_MODEL: BIOGRAPHICAL_INFORMATION_MODEL;
    private _setBiographicalInformation(): void {
        this.BIOGRAPHICAL_INFORMATION_MODEL = new BIOGRAPHICAL_INFORMATION_MODEL(
            this.User.biografia.legalGeneroId,
            this.User.biografia.generoId,
            this.User.biografia.ciudadaniaPaisId,
            this.User.biografia.estadoCivilId,
            this.User.biografia.curp,
            this.User.biografia.entidadNacimientoNavigation.paisId,
            this.User.biografia.entidadNacimiento,
            this.User.biografia.ciudadNacimiento,
        );
    }
    public BIOGRAPHICAL_INFORMATION: BIOGRAPHICAL_INFORMATION_MODEL;
    public _getBiographicalInformation(data: BIOGRAPHICAL_INFORMATION_MODEL): void {
        this.BIOGRAPHICAL_INFORMATION = new BIOGRAPHICAL_INFORMATION_MODEL(
            data.legalGeneroId,
            data.generoId,
            data.ciudadaniaPaisId,
            data.estadoCivilId,
            data.curp,
            data.ciudadaniaPaisId,
            data.entidadNacimiento,
            data.ciudadNacimiento,
        );
    }




    public PERSONAL_CONTACTS_INFORMATION_MODEL: Array<PERSONAL_CONTACTS_INFORMATION_MODEL>;
    private _setPersonalContactsInformation(): void {
        this.PERSONAL_CONTACTS_INFORMATION_MODEL = new Array<PERSONAL_CONTACTS_INFORMATION_MODEL>();
        this.User.personaRelacionadaUsuario.forEach(element => {

            const contact = new PERSONAL_CONTACTS_INFORMATION_MODEL(
                element.usuarioId,
                element.tipoRelacionId,
                element.nombreCompleto,
                element.telefono,
                element.correoElectronico,
                ''
            );

            this.PERSONAL_CONTACTS_INFORMATION_MODEL.push(contact);

        });
    }
    public PERSONAL_CONTACTS_INFORMATION: Array<PERSONAL_CONTACTS_INFORMATION_MODEL>;
    public _getPersonalContactsInformation(data: Array<PERSONAL_CONTACTS_INFORMATION_MODEL>): void {
        this.PERSONAL_CONTACTS_INFORMATION = new Array<PERSONAL_CONTACTS_INFORMATION_MODEL>();
        data.forEach(element => {

            const contact = new PERSONAL_CONTACTS_INFORMATION_MODEL(
                element.usuarioId,
                element.tipoRelacionId,
                element.nombreCompleto ?? '',
                element.telefono ?? '',
                element.correoElectronico ?? '',
                ''
            );

            this.PERSONAL_CONTACTS_INFORMATION.push(contact);

        });
    }




    public MEDICAL_INFORMATION_MODEL: MEDICAL_INFORMATION_MODEL;
    private _setMedicalInformation(): void {
        this.MEDICAL_INFORMATION_MODEL = new MEDICAL_INFORMATION_MODEL(
            this.User.biografia.grupoSanguineoId,
            this.User.biografia.alergias,
            this.User.biografia.enfermedades,
        );
    }
    public MEDICAL_INFORMATION: MEDICAL_INFORMATION_MODEL;
    public _getMedicalInformation(data: MEDICAL_INFORMATION_MODEL): void {
        this.MEDICAL_INFORMATION = new MEDICAL_INFORMATION_MODEL(
            data.grupoSanguineoId,
            data.alergias,
            data.enfermedades,
        );
    }




    public PREVIOUS_INFORMATION_MODEL: PREVIOUS_INFORMATION_MODEL;
    private _setPreviousInformation(): void {
        this.PREVIOUS_INFORMATION_MODEL = new PREVIOUS_INFORMATION_MODEL(
            this.User.usuarioId,
            this.User.estudioPrevio[0].nivelId,
            this.User.estudioPrevio[0].escuela,
            this.User.estudioPrevio[0].promedio,
        );
    }
    public PREVIOUS_INFORMATION: PREVIOUS_INFORMATION_MODEL;
    public _getPreviousInformation(data: PREVIOUS_INFORMATION_MODEL): void {
        this.PREVIOUS_INFORMATION = new PREVIOUS_INFORMATION_MODEL(
            data.usuarioId,
            data.nivelId,
            data.escuela,
            data.promedio,
        );
    }




    public BILLING_INFORMATION_MODEL: BILLING_INFORMATION_MODEL;
    private _setBillingInformation(): void {
        this.BILLING_INFORMATION_MODEL = new BILLING_INFORMATION_MODEL(
            this.User.datoFiscal.razonSocial,
            this.User.datoFiscal.rfc,
            this.User.datoFiscal.entidadFederativa.paisId,
            this.User.datoFiscal.entidadFederativaId,
            this.User.datoFiscal.ciudad,
            this.User.datoFiscal.calle,
            this.User.datoFiscal.numeroExterior,
            this.User.datoFiscal.numeroInterior,
            this.User.datoFiscal.colonia,
            this.User.datoFiscal.codigoPostal
        );
    }
    public BILLING_INFORMATION: BILLING_INFORMATION_MODEL;
    public _getBillingInformation(data: BILLING_INFORMATION_MODEL): void {
        this.BILLING_INFORMATION = new BILLING_INFORMATION_MODEL(
            data.razonSocial,
            data.rfc,
            data.paisId,
            data.entidadFederativaId,
            data.ciudad,
            data.calle,
            data.numeroExterior,
            data.numeroInterior,
            data.colonia,
            data.codigoPostal
        );
    }




    public OCUPATION_INFORMATION_MODEL: OCUPATION_INFORMATION_MODEL;
    private _setOcupationInformation(): void {
        this.OCUPATION_INFORMATION_MODEL = new OCUPATION_INFORMATION_MODEL(
            this.User.ocupacion.usuarioId,
            this.User.ocupacion.tipoEmpresaId,
            this.User.ocupacion.tipoPuestoEmpresaId
        );
    }
    public OCUPATION_INFORMATION: OCUPATION_INFORMATION_MODEL;
    public _getOcupationInformation(data: OCUPATION_INFORMATION_MODEL): void {
        this.OCUPATION_INFORMATION = new OCUPATION_INFORMATION_MODEL(
            data.usuarioId,
            data.tipoEmpresaId,
            data.tipoPuestoEmpresaId
        );
    }

    //#endregion 
}
