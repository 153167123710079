import { ApiResponse } from '../../models/api/ApiRespose.model';  //src/app/models/api/ApiRespose.model
import { SnackService } from '../../services/snack-service.service';
import { AdministracionOpcion } from '../../_models/AdministracionOpcion';
import { InstitucionService } from '../../_services/institucion.service';
import { InsitucionService } from '../../_services/insitucion.service';
import { AdmisionesService } from '../../_services/admisiones.service';
import { Component, OnInit, ViewChild, Input, Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { NavigationService } from '../../_services/navigation.service';
import { MsalService } from '@azure/msal-angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnsenanzaAprendizajeService } from '../../_services/ensenanza-aprendizaje.service';
import { MatStepper, MatVerticalStepper } from '@angular/material/stepper';
import { StepperService } from '../../_services/stepper-services.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';
import { EstatusUsuarioEnum } from '../../_models/estatusUsuario.enum';
import { UserRolPermissionDto } from '../../_models/UserRolPermissionDto';
import { environment, baseUrl } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

// import { Subscription } from 'rxjs/Subscription';



@Component({
  selector: 'app-sidebar-menu-n',
  templateUrl: './sidebar-menu-n.component.html',
  styleUrls: ['./sidebar-menu-n.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }],
  // scripts: ['../../../assets/index_files/scripts.bundle']
})
export class SidebarMenuComponentN implements OnInit {
  public EstatusAcademicoCandidatoEgresoId: number = 10;
  public respuestaRol: any;
  public infoInstitucionSer$: Observable<any>;
  public infoInstitucionSer: any = null;
  public administracionOpcion: AdministracionOpcion = null!;
  public readonly EDUCACION_CONTINUA: number = 2;
  public userPermision: UserRolPermissionDto;
  public tenantId: string = environment.enves[baseUrl].institucion;
  public infoInstitucion: any;
  public base64Image: any;
  public base64Logo: any;
  public imagePath: any;
  public imagePathLogo: any;
  public resizedBase64: any;
  
  //#region  Constrcutor
  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private msalService: MsalService,
    private snackBar: MatSnackBar,
    private stepperService: StepperService,
    private _EnsenanzaAprendizajeService: EnsenanzaAprendizajeService,
    private admisionesService: AdmisionesService,
    private institucionService: InstitucionService,
    private snackService: SnackService,
    private insitucionService: InsitucionService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2) {


    this.infoInstitucionSer$ = this.admisionesService.getInstitucion$();
    this.infoInstitucionSer$.subscribe(
      (info: any) => {
        this.infoInstitucionSer = info;
        console.log("INFO ", this.infoInstitucionSer)
      });

    this.authenticationService.currentUser.subscribe(user => {
      this.isAdmin = false;
      this.IsProfesor = false;
      this.isAlumno = false;
      let cuenta = this.msalService.instance.getActiveAccount();
      this.isAuthenticated = !!cuenta;
      this.userName = "";
      if (this.isAuthenticated) {
        //this.isAdmin = user.IsAdmin;
        //this.IsProfesor = user.IsProfesor;
        //this.isAlumno = user.IsAlumno;
        this.institucionService.obtenerOpcionesBoton().subscribe((response: ApiResponse<AdministracionOpcion>) => {
          if (response.success) {
            this.administracionOpcion = response.data;            
          } else {
            this.snackService.mostrarSnackBack(response.message);
          }
        });
        this.userName = cuenta!.name!;
        this.identificador = cuenta!.username.substring(0, cuenta!.username.indexOf('@'));
        sessionStorage.setItem('identificador', this.identificador);
        
        // this.authenticationService.obtainPermission(this.identificador).subscribe((response: ApiResponse<UserRolPermissionDto>) => {
        //   this.userPermision = response.data;
        //   let permisos = JSON.stringify(this.userPermision);
        //   sessionStorage.setItem('userPermission', permisos);
        //   console.log("this.userPermision", this.userPermision)
        // });
        this.authenticationService.obtenerDatosRol(this.identificador).subscribe(data => {
          this.usuarioRol = data;
          this.infoCargada = true;
        });
      }
    });
    this.stepperService.getSubjetPaso().subscribe((avanzar: boolean) => {
      if (avanzar) {
        this.stepper.next();
      }
      else {
        this.stepper.previous();
      }
    });

    this.navigationService.selectedDataKtAppSidebarMinimizeMobile$.subscribe((sideBarStat)=>{
      
      if( sideBarStat === 'on'){   
        
      } else if( sideBarStat === 'off') {
        const  ktAppSidebar = document.getElementById('kt_app_sidebar');
        if (ktAppSidebar != null) {
          ktAppSidebar.classList.add('drawer');
          ktAppSidebar.classList.add('drawer-start');
          ktAppSidebar.classList.add('drawer-on');          
        }
      }
    });

        
  }
  //#endregion

  //#region Propiedades
  message: boolean;
  isExpanded = false;
  public isAdmin: boolean = false;
  public IsProfesor: boolean = false;
  public isAlumno: boolean = false;
  public userName: string = "";
  public isAuthenticated: boolean = false;
  public identificador: string;
  public usuarioRol: any;
  public infoCargada: boolean = false;
  public menuItemResincripcionFlag = false;
  public ktAppSidebarToggleFlag = true;

  @ViewChild('stepper') stepper: MatVerticalStepper;


  //#endregion

  ngOnInit(): void {
    
    this.obtenerInformacionInstitucion();
  }

  newMessage() {
    this._EnsenanzaAprendizajeService.invocarMetodoTableroCursos();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  sideBarMouseEnter(){
    console.log('mouseSide');
  }

  ktAppSidebarToggleClick(){
    // console.log('clickSideBarToggle');
    const  ktAppSidebarToggle = document.getElementById('kt_app_sidebar_toggle');

    if (ktAppSidebarToggle != null) {

    
      // this.sidebarSubs.unsubscribe();
      if (!this.ktAppSidebarToggleFlag){
              // ✅ Add class
        ktAppSidebarToggle.classList.add('active');
        // ktAppSidebarToggle.classList.add('show');
        this.ktAppSidebarToggleFlag = true;
        this.navigationService.setDataKtAppSidebarMinimizeDesk('on');
        
      } else {
        // ✅ Remove class
      // box.classList.remove('bg-yellow');
        ktAppSidebarToggle.classList.remove('active');
        // ktAppSidebarToggle.classList.remove('show');
        this.ktAppSidebarToggleFlag = false;
        this.navigationService.setDataKtAppSidebarMinimizeDesk('off');
      }

      
      
    }
  }

  clickReinscripcion(){
   
    const menuItemResincripcion = document.getElementById('menu_item_reinsripcion');

    if (menuItemResincripcion != null) {

      if (!this.menuItemResincripcionFlag){
              // ✅ Add class
        menuItemResincripcion.classList.add('hover');
        menuItemResincripcion.classList.add('show');
        this.menuItemResincripcionFlag = true;
      } else {
        // ✅ Remove class
      // box.classList.remove('bg-yellow');
        menuItemResincripcion.classList.remove('hover');
        menuItemResincripcion.classList.remove('show');
        this.menuItemResincripcionFlag = false;
      }

    
      
    }
  }

  public logout() {
    this.navigationService.isToggledMenu.next(false);
    this.authenticationService.logout();
    this.router.navigate(['/logout']);
  }

  public get isCurentRouteReinscription(): boolean {
    return this.router.url == '/v2/reinscripcion';
  }

  public redirigirDashboard() {
    
    this.admisionesService.validarPagoColegiatura().subscribe((respuesta: any) => {
      this.respuestaRol = respuesta;
      if (!respuesta.noExisteAlumno && (respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.candidato || respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.prospecto || respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.admitido)) {
        if (this.infoInstitucionSer.academicManagementModelId == 1) {
          this.router.navigate(['/']);
          setTimeout(() => {
            this.router.navigate(['/admisiones']);
          }, 100);
        }
      } else if (!respuesta.noExisteAlumno) {
        this.router.navigate(['/v2/dashboard-alumno']);
      } else {
        this.router.navigate(['/']);
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 100);
      }
    });
  }

  public obtenerInformacionInstitucion() {
    this.insitucionService.obtenerInformacionInstitucion(this.tenantId).subscribe(value => {
      this.infoInstitucion = value;
      // this.colorBarra = this.infoInstitucion.colorBar;
      // this.colorButtton = this.infoInstitucion.colorButton;
      // document.documentElement.style.setProperty('--first-color', this.colorButtton);
      // this.titleService.setTitle(this.infoInstitucion.nombre + " -Link4Campus");
      if (this.infoInstitucion.logoFile) {
        this.base64Logo = 'data:image/png;base64,' + this.infoInstitucion.logoFile;
        // this.imageUsuarioEditar = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Logo);
        this.compressImage(this.base64Logo, 20, 20).then(compressed => {
          this.resizedBase64 = compressed;
        })
        this.imagePathLogo = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Logo);
        
      }
    })

    
  }

  public compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx!.drawImage(img, 0, 0, newX, newY);
        const data = ctx!.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }
}
