export class Correo {
    /// <summary>
    /// Correo al cual va el correo
    /// </summary>
    CorreosDestinatario: string;

    /// <summary>
    /// Asunto que tendra el correo
    /// </summary>
    Asunto: string;

    /// <summary>
    /// Mensaje que se le agrega al correo
    /// </summary>
    CuerpoDelMensaje: string;

}
