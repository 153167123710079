import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Nivel } from '../_models/nivel';
import { ApiResponse } from '../models/api/ApiRespose.model';

@Injectable({
  providedIn: 'root'
})
export class NivelService {

  constructor(private httpClient: HttpClient) { }

  //Get Catalogo Tipo Nivel
  public getNivel(): Observable<Nivel[]> {
    return this.httpClient.get<Nivel[]>(`${environment.enves[baseUrl].apiUrl}/Nivel`);
  }
  /**
   * Obtiene una lista ordenada de los niveles disponibles en el campus
   * Niveles: Licenciatura, prepa, maestría
   * @param idCampus 
   * @returns 
   */
  public obtenerNivelesDelCampus(idCampus: number): Observable<ApiResponse<Array<Nivel>>> {
    return this.httpClient.get<ApiResponse<Array<Nivel>>>(`${environment.enves[baseUrl].apiUrl}/Nivel/ObtenerNivelesDelCampus?IdCampus=${idCampus}`);
  }
}
