export class Profesor {
  UsuarioId: number;
  EsActivo: boolean;
  PeriodoIngreso: number;
  FechaIngreso: string;
  TipoContratoId: number;
  DepartamentoId: number;
  FechaCreacion: string;
  UsuarioCreacion: number;
  FechaModificacion: string;
  UsuarioModificacion: number;
  tipoAccion?: number;
}
