import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { BlockService } from 'src/app/_services/block.service';

@Component({
  selector: 'app-edicion-bloque-alumno-eliminar',
  templateUrl: './edicion-bloque-alumno-eliminar.component.html',
  styleUrls: ['./edicion-bloque-alumno-eliminar.component.scss']
})
export class EdicionBloqueAlumnoEliminarComponent implements OnInit {
  public boolIsArray = false;
  public numBlockGroupSeleccionados = 0;
  public alumnoIds = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public blockService: BlockService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<EdicionBloqueAlumnoEliminarComponent>) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    console.log("this.data",this.data);
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numBlockGroupSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if (this.boolIsArray) {
      let arrayAlumnoId = this.data.map(d => (d.studentId.toString()));
      this.alumnoIds = arrayAlumnoId.toString();
    } else {
      this.alumnoIds = this.data[0].studentId.toString();
    }

    if (this.data[0].blockId != 0) {
      this.blockService.eliminarBlockStudent(this.data[0].blockId, this.alumnoIds).subscribe(
        (response: ApiResponse<any>) => {
          if (response.success) {
            this.blockService.invocarMetodoObtenerBloqueAlumno();
            this.blockService.invocarMetodoObtenerBloque();
            this.snackService.mostrarSnackBack(response.message);
            this.dialogRef.close(this.data);
          } else {
            this.snackService.mostrarSnackBack(response.message);
          }
        }, error => {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar el grupo del bloque. Por favor, intente de nuevo más tarde.');
        }
      );
    } else {
      this.dialogRef.close(this.alumnoIds);
    }

  }



}
