export enum Estados {
  Aguascalientes = 1,
  'Baja California' = 2,
  'Baja California Sur' = 3,
  Campeche = 4,
  Chiapas = 5,
  Chihuahua = 6,
  'Ciudad de México' = 7,
  Coahuila = 8,
  Colima = 9,
  Durango = 10,
  Guanajuato = 11,
  Guerrero = 12,
  Hidalgo = 13,
  Jalisco = 14,
  México = 15,
  Michoacán = 16,
  Morelos = 17,
  Nayarit = 18,
  'Nuevo León' = 19,
  Oaxaca = 20,
  Puebla = 21,
  Querétaro = 22,
  'Quintana Roo' = 23,
  'San Luis Potosí' = 24,
  Sinaloa = 25,
  Sonora = 26,
  Tabasco = 27,
  Tamaulipas = 28,
  Tlaxcala = 29,
  Veracruz = 30,
  Yucatán = 31,
  Zacatecas = 32,
}
