import { Component, Inject, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AccionEnum } from '../../_models/accion.enum';
import { Categoria } from 'src/app/_models/categoria';
import { ConfiguracionCuotaService } from 'src/app/_services/configuracion-cuota.service';
import { TipoServicio } from 'src/app/_models/tipo-servicio';
@Component({
  selector: 'app-modal-tipo-servicio',
  templateUrl: './modal-tipo-servicio.component.html',
  styleUrls: ['./modal-tipo-servicio.component.scss']
})
export class ModalTipoServicioComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public errorActualizaTipoServicio: string = "";
  public Idservicio: number = 0;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public edicionServicioForm: FormGroup;
  public categoriaList: Categoria[] = [];
  public servicio: TipoServicio;

  constructor(public dialogRef: MatDialogRef<ModalTipoServicioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TipoServicio,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private servicios: ConfiguracionCuotaService
    ) {
      this.boolCambio = false;
      this.inicializarForm();
      this.obtenerCategoria();
    }

  ngOnInit() {

  }

  public inicializarForm() {
    this.edicionServicioForm = new FormGroup({
      servicioId: new FormControl(this.data?.servicioId),
      clave: new FormControl(this.data?.clave, [Validators.required]),
      nombre: new FormControl(this.data?.nombre, [Validators.required]),
      descripcion: new FormControl(this.data?.descripcion, [Validators.required]),
      categoriaId: new FormControl(this.data?.categoriaId, [Validators.required]),
      estatus: new FormControl(this.data?.estatus === true ? true : false, [Validators.required])
    });
    this.edicionServicioForm.valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    })
  }

  get clave() { return this.edicionServicioForm.get('clave'); }
  get nombre() { return this.edicionServicioForm.get('nombre'); }
  get descripcion() { return this.edicionServicioForm.get('descripcion'); }
  get categoriaId() { return this.edicionServicioForm.get('categoriaId'); }
  get estatus() { return this.edicionServicioForm.get('estatus'); }

  public obtenerCategoria() {
    this.servicios.obtenerCategoria().subscribe(
      (categoria: any[]) => {
        this.categoriaList = categoria;
    });
  }

  public cambiarVistaEditar(servicioId: number){
    this.boolCambio = false;
    this.data = Object.assign(this.edicionServicioForm.value);
    this.data.tipoAccion = 2;
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        console.log("validando", control);
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public enviar(): void {
    switch(this.data.tipoAccion) {
      case AccionEnum.CREAR:
        if(this.edicionServicioForm.invalid){
          this.validateAllFormFields(this.edicionServicioForm);
          this.snackBar.open('Verifique los datos.', 'OK', { duration: 5000 });
          return;
        }

        this.data = Object.assign(this.edicionServicioForm.value);
        this.servicios.agregarTipoServicio(this.data).subscribe(
          idServicio => {
            if (idServicio > 0) {
              this.servicios.metodoObtenerTipoServicio();
              this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
              this.boolCambio = false;
              this.cambiarVistaEditar(idServicio);
              this.inicializarForm();
            } else {
              this.snackBar.open("Ha ocurrido un error al guardar el tipo de servicio. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
              this.data.tipoAccion = 3;
            }
          }, error => {
            this.snackBar.open("Ha ocurrido un error al guardar el tipo de servicio. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        );
        break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionServicioForm.value);
        this.data.tipoAccion = 2;
        this.servicios.modificarTipoServicio(this.data).subscribe(
          editado => {
            if(editado) {
              this.servicios.metodoObtenerTipoServicio();
              this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
              this.boolCambio = false;
            } else {
              this.snackBar.open("Ha ocurrido un error al guardar el tipo de servicio. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
              this.data.tipoAccion = 2;
            }
          }, error => {
            this.snackBar.open("Ha ocurrido un error al guardar el tipo de servicio. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        );
        break;
    }
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }
}
