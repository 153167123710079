import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportesService } from 'src/app/_services/reportes.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { ReporteDTO, AlumnoProgramaDTO } from 'src/app/_models/reporte-dto';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { FormControl } from '@angular/forms';
import { Periodo, Alumno } from 'src/app/_models';
import { PeriodoService } from 'src/app/_services';
import { MatOption } from '@angular/material/core';
import * as Chart from 'chart.js';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-taza-inscripcion',
  templateUrl: './taza-inscripcion.component.html',
  styleUrls: ['./taza-inscripcion.component.scss']
})
export class TazaInscripcionComponent implements OnInit {
  public alumnosPorPeriodo: Alumno[] = [];
  public datosInscripcion2: ReporteDTO[] = [];
  public promedioDatos: number[] = [];
  public infoCargado: boolean = false;
  public infoCargadoAlumno: boolean = false;

  private periodosActivos: number[] = [];
  private arrOriginal: ReporteDTO[] = [];
  public periodoF = new FormControl();
  public periodos: Periodo[] = [];
  public nombreNivel: string;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'nombre', 'programa', 'campus', 'periodo', 'correo', 'estatus'];
  dataSource: MatTableDataSource<Alumno>;
  selection = new SelectionModel<Alumno>(true, []);

  @ViewChild('allPeriodo') private allPeriodo: MatOption;

  //#region Variables para una nueva grafica
  barChartData: ChartDataSets[] = [];
  barChartLabels: Label[] = [];
  barChartOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      displayColors: false,
      callbacks: {
        label: function(tooltipItem, data) {
          const valores = JSON.parse(data.datasets[0].label);

          const admitidosInscritos = valores.find(x => x.clavePeriodo === tooltipItem.xLabel );

          return ["  \n\n",
          "Promedio: " + tooltipItem.yLabel +" % \n\n",
          " ",
          "Admitidos:" + admitidosInscritos.admitidos +"  \n\n",
          " ",
          "Inscritos:" + admitidosInscritos.inscritos];
        },
      },
    },
    scales: {
      xAxes: [{
        display: true,
        gridLines: { display: false }
      }],
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          stepSize: 20,
          callback: function(value) {return value+ "%"}
        }
      }]},
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
    hover: {
      animationDuration: 0,
    },
    animation: {
      duration: 1,
      onComplete: function() {
        var chartInstance = this.chart,
        ctx = chartInstance.ctx;

        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index] + " %";
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    }
  }
  barChartColors: Color[] = [{ borderColor: 'black',
                               backgroundColor: ['rgb(0, 51, 102)', 'rgb(0, 66, 134)','rgb(0, 76, 153)',
                               'rgb(0, 90, 176)', 'rgb(0, 102, 204)', 'rgb(0, 114, 245)', 'rgb(0, 128, 255)',
                               'rgb(0, 134, 255)', 'rgb(51, 153, 255)', 'rgb(76, 176, 255)', 'rgb(102, 178, 255)']}];
  barCharLegend = false;
  barChartPlugins = [];
  barChartType = 'bar';
  //#endregion

  constructor(private reporteService: ReportesService,
    public periodoservice: PeriodoService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    public generadorExcel: GeneracionExcelServiceService,) {
  }

  ngOnInit(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    this.llenarFiltros();
  }

  public llenarFiltros(): void {
    let today = new Date();
    this.periodoservice.ObtenerPeriodoFechaInicio().subscribe(
      (periodos: Periodo[]) => {
        this.periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio < today && fin > today) {
            this.periodosActivos.push(x.periodoId);
          }
        }
        if(this.periodosActivos.length > 0){
          this.periodoF.setValue([...this.periodosActivos]);
        }
        this.obtenerDatosFiltrado();
      }
    );
  }

  public limpiarFiltro(): void {
    this.periodoF.setValue([...this.periodosActivos]);
  }

  tosslePerOnePeriodo(all): boolean {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if(this.periodoF.value.length == this.periodos.length){ this.allPeriodo.select(); }
    this.barChartData = [];
    this.barChartLabels = [];
    this.obtenerDatosFiltrado();
  }

  toggleAllSelectionPeriodo(): void {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.periodos.map(item => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.barChartData = [];
    this.barChartLabels = [];
    this.obtenerDatosFiltrado();
  }

  public obtenerDatosFiltrado(): void {
    let periodo: number[] = this.periodoF.value == null ? [] : this.periodoF.value;
    if (periodo.length > 0) {
      this.reporteService.obtenerAlumnosFiltrados(periodo).subscribe((res: ApiResponse<ReporteDTO[]>) => {
        this.datosInscripcion2 = res.data;
        this.obtenerPromedio(this.datosInscripcion2);
        this.barChartData = [];
        this.barChartLabels = [];
        for(const i in this.datosInscripcion2) {
          /// Forma para que muestre el titulo de admitidos e inscritos en el mismo tooltip
          //this.barChartLabels.push(String(this.datosInscripcion2[i].clavePeriodo + 'Admitidos: ' + this.datosInscripcion2[i].admitidos + ' | Inscritos: ' + this.datosInscripcion2[i].inscritos));
          this.barChartLabels.push(this.datosInscripcion2[i].clavePeriodo);
        }
        this.barChartData.push({ data: this.promedioDatos, label: JSON.stringify(this.datosInscripcion2)} );
        this.infoCargado = true;
      });
    } else {
      this.infoCargado = false;
    }
  }

  public obtenerPromedio(elemento: ReporteDTO[]) {
    let contenido: ReporteDTO[] = elemento;
    this.promedioDatos = [];
    let promediosDatos: number;
    for(let i: number = 0; i < contenido.length; i++) {
      promediosDatos = contenido[i].promedio;
      this.promedioDatos.push(promediosDatos);
    }
    return this.promedioDatos.join(', ');
  }

  public obtenerDatos(event): void {
    //console.log("event",event);
    this.nombreNivel = event.active[0]._view.label;
    this.reporteService.obtenerInscripcion(this.nombreNivel).subscribe((respuesta: ApiResponse<Alumno[]>) => {
      this.alumnosPorPeriodo = respuesta.data;
      this.dataSource = new MatTableDataSource(respuesta.data);
      this.selection = new SelectionModel<Alumno>(true, []);
      this.dataSource.paginator = this.paginator;
      this.infoCargado = false;
      this.infoCargadoAlumno = true;
    })
  }

  volverGrafica() {
    this.infoCargado = true;
    this.infoCargadoAlumno = false;
    this.alumnosPorPeriodo = [];
  }

  ExcelSubscription: Subscription;
  public descargarExcelProgramas() {
    this.ExcelSubscription = this.reporteService.descargarAlumnoPeriodo(this.alumnosPorPeriodo).subscribe(
      (respuesta: Blob) => {
        let filename = `Alumnos`;
        this.generadorExcel.GenerarExcel(respuesta, filename);
        this.ExcelSubscription.unsubscribe();
      });
  }
}
