import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { EstudiosPrevios } from '../_models/estudios-previos';

@Injectable({
  providedIn: 'root'
})
export class EstudiosPreviosService {

  constructor(private httpClient: HttpClient) { }


  //PUT Estudios Previos
  public modificarEstudiosPrevios(estudiosPrevios: EstudiosPrevios): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/EstudiosPrevios/`, estudiosPrevios);
  }

  //POST Estudios Previos
  public agregarEstudiosPrevios(estudiosPrevios: EstudiosPrevios): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/EstudiosPrevios/`, estudiosPrevios);
  }
   //Obtener Estudios previos por Usuario ID
   public GetEstudiosPreviosByUsuarioId(usuarioId: number): Observable<any> {
    return this.httpClient.get<EstudiosPrevios>(`${environment.enves[baseUrl].apiUrl}/EstudiosPrevios/GetEstudiosPreviosByUsuarioId?usuarioId=` + usuarioId);
  }

  //PUT POST Estudios Previos
  public registrarEstudiosPrevios(estudioPrevios): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/EstudiosPrevios/PostEstudiosPrevios`, estudioPrevios);
  }

}
