import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { ConfiguracionCuotaService } from '../../_services/configuracion-cuota.service';
import { UtileriasService } from '../../_services/utilerias.service';
import { ModalPagosComponent } from './modal-pagos/modal-pagos.component';

@Component({
  selector: 'app-configuracion-pagos',
  templateUrl: './configuracion-pagos.component.html',
  styleUrls: ['./configuracion-pagos.component.scss']
})
export class ConfiguracionPagosComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'nombre', 'estatus'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public infoCargado: boolean = false;
  public numFormaPagoSeleccionado: number = 0;
  private subscripcionFormaPago: Subscription;
  public datafilter: any;

  constructor(private configuracion: ConfiguracionCuotaService,
    private paginador: MatPaginatorIntl,
    public util: UtileriasService,) { }

  ngOnInit(): void {
    if (this.configuracion.obtenerFormaPagoSubscription == undefined) {
      this.configuracion.obtenerFormaPagoSubscription = this.configuracion.invocarObtenerFormaPago.subscribe(() => {
        this.obtenerFormaPago();
      });
    }
    this.obtenerFormaPago();
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  public obtenerFormaPago() {
    this.subscripcionFormaPago = this.configuracion.obtenerFormaPagoByInstitucion(environment.enves[baseUrl].institucion).subscribe(
      (formasPago: any[]) => {
        this.dataSource = new MatTableDataSource(formasPago);
        this.dataSource.paginator = this.paginator;
        this.selection = new SelectionModel<any>(true, []);
        this.datafilter = formasPago;
        this.dataSource.filterPredicate = function (data, filter: string): boolean {
          return data.formaPago.nombre.toLowerCase().includes(filter);
        };
        this.infoCargado = true;
      });
  }

  applyFilter(filterValue: string) {
    this.selection.clear();
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    this.datafilter = this.dataSource.filteredData;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    this.numFormaPagoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numFormaPagoSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.formaPagoId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public editarFormaPago(element: any, tabla: boolean) {
    let infoTabla: any;
    if (tabla) {
      infoTabla = element;
    } else {
      infoTabla = element._selected[0];
    }
    this.util.abrirDialogoLateral(ModalPagosComponent, infoTabla);
  }

  ngOnDestroy(): void {
    if (this.subscripcionFormaPago !== null) {
      this.subscripcionFormaPago.unsubscribe();
    }
  }

}
