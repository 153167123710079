import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { TipoReconocimiento } from '../_models/tipo-reconocimiento';

@Injectable({
  providedIn: 'root'
})

export class TipoReconocimientoService {

  constructor(private httpClient: HttpClient) { }

  public getTipoReconocimiento(): Observable<TipoReconocimiento[]> {
    return this.httpClient.get<TipoReconocimiento[]>(`${environment.enves[baseUrl].apiUrl}/TipoReconocimiento`);
  }
}
