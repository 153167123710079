import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TraductorService } from '@app/_services/traductor.service';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { Documento } from 'src/app/_models/documento';
import { DatosDocumentos } from 'src/app/_models/usuario';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { DocumentoService } from 'src/app/_services/documento.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { SolicitudApoyoFinancieroService } from '../../../_services/solicitud-apoyo-financiero.service';

@Component({
  selector: 'app-cargar-documentos',
  templateUrl: './cargar-documentos.component.html',
  styleUrls: ['./cargar-documentos.component.scss']
})
export class CargarDocumentosComponent implements OnInit {

  displayedColumns: string[] = ['select', 'tipo', 'nombre', 'fecha'];
  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<DatosDocumentos>(true, []);
  public infoCargado = false;
  public documentos: any = [];
  public numDocumentoSeleccionado = 0;
  public documentoList: Documento[] = [];
  public tipoDocSelected: number;
  public date: Date = new Date();
  public readonly CLAVE_PROCESO = "NUEVO";
  @ViewChild('modalVisorPDF') modalVisorPDF: TemplateRef<'modalVisorPDF'>;
  public modalVisorPDFRef: any;
  public datosUsuarioBusqueda$: Observable<UsuarioIngreso>;
  public datosUsuarioBusqueda: UsuarioIngreso;
  public renderizarForm: boolean = false;
  public cargaDocumentoForm: FormGroup;
  public pdf: ArrayBuffer;
  public mime: string;
  public imagen: string;
  public existeSubProceso: boolean = false;

  constructor(
    public nuevoIngresoService: NuevoIngresoService,
    private dialog: MatDialog,
    private utileriasService: UtileriasService,
    private snackService: SnackService,
    private documentoService: DocumentoService,
    private traductorService: TraductorService,
    private solicitudApoyoFinancieroService: SolicitudApoyoFinancieroService,
  ) { }

  ngOnInit(): void {
    if (this.solicitudApoyoFinancieroService.obtenerSolicitudesSubscription === undefined) {
      this.solicitudApoyoFinancieroService.obtenerSolicitudesSubscription = this.solicitudApoyoFinancieroService.invocarObtenerSolicitudes
        .subscribe(() => {
          this.datosUsuarioBusqueda$ = this.solicitudApoyoFinancieroService.obtenerDatosUsuarioBusqueda$();
          this.datosUsuarioBusqueda$.subscribe(datos => {
            this.datosUsuarioBusqueda = datos;
            if (this.datosUsuarioBusqueda.alumno[0].tipoCandidatoId !== null) {
              this.existeSubProceso = true;
            } else {
              this.existeSubProceso = false;
            }
          });
          this.renderizarFormulario();
          console.log('Alumno encontrado', this.datosUsuarioBusqueda.alumno[0]);
        });
    }
    this.datosUsuarioBusqueda$ = this.solicitudApoyoFinancieroService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => {
      this.datosUsuarioBusqueda = datos;
      if (this.datosUsuarioBusqueda.alumno[0].tipoCandidatoId !== null) {
        this.existeSubProceso = true;
      } else {
        this.existeSubProceso = false;
      }
    });
  }

  public inicializarForm() {
    console.log('Alumno encontrado2', this.datosUsuarioBusqueda);
    if (this.datosUsuarioBusqueda.alumno[0].tipoCandidatoId !== null) {
      this.existeSubProceso = true;
    } else {
      this.existeSubProceso = false;
    }
    this.cargaDocumentoForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      tipoDocumento: new FormControl(),
      docs: new FormControl(),
      docsEditar: new FormControl(),
    });
    this.renderizarForm = true;
    this.cargaDocumentoForm.patchValue({
      tipoDocumento: 0
    });
    this.cargaDocumentoForm.valueChanges.subscribe(val => {
      this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.cargaDocumentoForm.valid);
    });
  }

  /**
   * Renderiza la buqueda de documentos e inicializa el form
   */
  public renderizarFormulario() {
    this.documentoService.ObtenerDocumentosProcesoSubProceso(this.CLAVE_PROCESO, this.datosUsuarioBusqueda.alumno[0].alumnoId)
      .subscribe(documentos => {
        if (documentos.success) {
          this.documentoList = [];
          this.documentos = [];
          this.documentoList = documentos.data;
          if (this.datosUsuarioBusqueda.usuarioDocumento.length > 0) {
            this.documentoList.forEach(documento => {
              let documentoArreglo: any = null;
              let documentoEncontrado: DatosDocumentos = null;
              documentoArreglo = this.datosUsuarioBusqueda.usuarioDocumento.filter(x => x.documentoId === documento.documentoId);
              if (documentoArreglo.length >= 1) {
                documentoArreglo = documentoArreglo.sort(this.compareDate);
                documentoEncontrado = documentoArreglo.find(x => x.nombreFisico);
                if (documentoEncontrado !== null) {
                  documentoEncontrado.documentoNombre = documento.nombre;
                  this.documentos.push(documentoEncontrado);
                }
              }
            });
            if (this.documentos.length > 0) {
              this.dataSource = new MatTableDataSource(this.documentos);
              this.selection = new SelectionModel<DatosDocumentos>(true, []);
              this.infoCargado = true;
            }
          }
          this.inicializarForm();
        } else {
          this.inicializarForm();
        }
      });
  }

  /**
   * Metodo que compara las fechas de documentos
   * @param emp1 
   * @param emp2 
   * @returns 
   */
  compareDate(emp1: any, emp2: any) {
    var emp1Date = new Date(emp1.date).getTime();
    var emp2Date = new Date(emp2.date).getTime();
    return emp1Date > emp2Date ? 1 : -1;
  }

  /**
   * Metodo para saber que tipo de documentos se selecciono
   * @param docId 
   */
  public docSelected(docId: number) {
    if (!!docId) {
      this.tipoDocSelected = docId;
    }
  }

  /**
   * Metodo para subir un documento con el select
   * @param event 
   */
  public onFileChange(event: any) {
    let documentos: any[] = [];
    let documentosEditar: any[] = [];
    const reader = new FileReader();
    const [file] = event.target.files;
    if (event.target.files && event.target.files.length) {
      let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === this.tipoDocSelected);
      reader.onload = () => {
        let documento: any = {
          usuarioId: this.datosUsuarioBusqueda.usuarioId,
          documentoId: tipoDocumentoSelecionado.documentoId,
          nombreOriginal: file.name,
          nombreFisico: reader.result,
          fechaCreacion: this.date,
          documentoNombre: tipoDocumentoSelecionado.nombre,
          estatusDocumentoId: 1
        }
        documentos.push(documento);
        if (this.dataSource === undefined || this.dataSource.data.length === 0) {
          this.dataSource = new MatTableDataSource(documentos);
          this.selection = new SelectionModel<any>(false, []);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentos;
          this.infoCargado = true;
        } else {
          documentosEditar = this.dataSource.data;
          documentosEditar = documentosEditar.filter(x => x.documentoId !== this.tipoDocSelected);
          this.dataSource = new MatTableDataSource(documentosEditar);
          this.selection = new SelectionModel<DatosDocumentos>(true, []);
          this.dataSource.data.push(documento);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentosEditar;
        }
        this.registrarDoc(documentos);
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * Metodo para editar un documento seleccionado
   * @param event 
   * @param tipoId 
   */
  public onFileChangeEditar(event, tipoDocumentoId: number) {
    let documentosEditar: DatosDocumentos[] = [];
    let documentosGuardar: any[] = [];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === tipoDocumentoId);
      reader.onload = () => {
        let documento = {
          usuarioId: this.datosUsuarioBusqueda.usuarioId,
          documentoId: tipoDocumentoSelecionado.documentoId,
          nombreOriginal: file.name,
          nombreFisico: reader.result,
          fechaCreacion: this.date,
          documentoNombre: tipoDocumentoSelecionado.nombre,
          estatusDocumentoId: 1
        }
        documentosEditar = this.documentos;
        documentosEditar = documentosEditar.filter(x => x.documentoId !== tipoDocumentoId);
        this.dataSource = new MatTableDataSource(documentosEditar);
        this.selection = new SelectionModel<DatosDocumentos>(true, []);
        this.dataSource.data.push(documento);
        this.dataSource._updateChangeSubscription();
        this.documentos = this.dataSource.data;
        documentosGuardar.push(documento);
        this.registrarDoc(documentosGuardar);
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * Metodo para subir un documento 
   * @param docs 
   */
  public registrarDoc(docs: Array<DatosDocumentos>) {
    if (docs != null) {
      this.nuevoIngresoService.registrarDocumentos(docs).subscribe(
        cargaDocumentos => {
          if (cargaDocumentos) {
            let mensaje: string = this.traductorService.translate('guardadoDocumentos');
            this.snackService.mostrarSnackBack(mensaje);
            this.nuevoIngresoService.refrescarTabla();
            this.nuevoIngresoService.updateTabs();
          } else {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los documentos. Por favor, intente de nuevo más tarde.');
          }
        }
      );
    }
  }

  /**
   * Metodo para eliminar un documento
   * @param documentId 
   */
  public eliminarDocumento(documentId: number) {
    if (documentId) {
      this.nuevoIngresoService.deleteDocumentoById(this.datosUsuarioBusqueda.usuarioId, documentId).subscribe(
        (respuestaEliminar: SimpleResponse) => {
          if (respuestaEliminar.success) {
            let documentosEliminar: DatosDocumentos[] = [];
            documentosEliminar = this.documentos;
            documentosEliminar = documentosEliminar.filter(x => x.documentoId !== documentId);
            this.dataSource = new MatTableDataSource(documentosEliminar);
            this.selection = new SelectionModel<DatosDocumentos>(true, []);
            this.documentos = documentosEliminar;
            if (this.documentos.length === 0) {
              this.infoCargado = false;
            }
            this.dataSource._updateChangeSubscription();
            this.nuevoIngresoService.refrescarTabla();
            this.nuevoIngresoService.updateTabs();
            this.snackService.mostrarSnackBack('Se ha eliminado el documento.');
          } else {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar el documento, intentelo mas tarde.');
          }
        })
    }
  }

  /**
   * Visualizar el documento seleccionado
   * @param refDocumento 
   */
  public visualizarPdf(refDocumento: number) {
    if (refDocumento > 0) {
      this.nuevoIngresoService.GetDocumentosCandidatoByCorreo(this.datosUsuarioBusqueda.correo, refDocumento).subscribe(
        (datosUsuarioDocumento: ApiResponse<UsuarioIngreso>) => {
          if (datosUsuarioDocumento.success) {
            var documentos = datosUsuarioDocumento.data.usuarioDocumento.filter(x => x.documentoId === refDocumento);
            var documentoVisualizar = documentos.sort(this.compareDate);
            this.mime = documentoVisualizar[0].nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : /\.(png|jpeg|jpg|gif|tiff|bmp)$/.test(documentoVisualizar[0].nombreOriginal) ? "image/" + /\.(png|jpeg|jpg|gif|tiff|bmp)$/.exec(documentoVisualizar[0].nombreOriginal)[1] : "application/octect-stream";

            this.pdf = this.base64ToArrayBuffer(documentoVisualizar[0].nombreFisico);
            if (this.mime.indexOf("image") >= 0) {
              this.imagen = `data:${this.mime};base64,${documentoVisualizar[0].nombreFisico}`;
            }
            if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
              this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
            } else {
              const blob = new Blob([this.pdf], { type: this.mime });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            }
          }

        }


      );
    } else {
      let archivo = this.cargaDocumentoForm.controls[refDocumento].value;
      this.mime = archivo.split(",")[0].split(":")[1].split(";")[0];
      this.imagen = this.cargaDocumentoForm.controls[refDocumento].value;
      this.pdf = this.base64ToArrayBuffer(archivo);
      if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
        this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
      } else {
        const blob = new Blob([this.pdf], { type: this.mime });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    }
  }

  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public cerrarModalPdf() {
    this.modalVisorPDFRef.close();
  }

  isAllSelected() {
    this.numDocumentoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numDocumentoSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.documentoId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
