import { FiltrosBlockStudentDto } from './../_models/FiltrosBlockStudentDto';
import { BlockLabelDto } from './../_models/BlockLabelDto';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { Block } from '../_models/block';
import { GrupoDTO } from '../_models/GrupoDTO.model';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { BlockStudent } from '@app/_models/blockStudent';
import { GruposDisponiblesParaMateriaDTO } from '@app/models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { SeleccionDeMateriasDTO } from '@app/models/Inscripcion/SeleccionDeMateriasDTO.model';
import { AsignacionMasivaBloqueDto } from '@app/_models/AsignacionMasivaBloqueDto';

@Injectable({
  providedIn: 'root'
})
export class BlockService {

  //Variables OBS
  private infoGrupos$ = new BehaviorSubject<any>(null);
  private infoAlumnos$ = new BehaviorSubject<any>(null);

  constructor(private httpClient: HttpClient) { }

  invocarObtenerBloque = new EventEmitter();
  invMetodoObtenerBloqueSubscription: Subscription;

  invocarObtenerBloqueGrupo = new EventEmitter();
  invMetodoObtenerBloqueGrupoSubscription: Subscription;

  invocarAñadirBloqueGrupo = new EventEmitter();
  invMetodoAñadirBloqueGrupoSubscription: Subscription;

  invocarAnadirBloqueAlumno = new EventEmitter();
  invMetodoAnadirBloqueAlumnoSubscription: Subscription;

  invocarObtenerBloqueAlumno = new EventEmitter();
  invMetodoObtenerBloqueAlumnoSubscription: Subscription;


  public obtenerBlocks(): Observable<ApiResponse<Block[]>> {
    return this.httpClient.get<ApiResponse<Block[]>>(`${environment.enves[baseUrl].apiUrl}/Block`);
  }

  public GetBlockById(BlockId: number): Observable<ApiResponse<Block>> {
    return this.httpClient.get<ApiResponse<Block>>(`${environment.enves[baseUrl].apiUrl}/Block/GetBlockById/${BlockId}`);
  }


  public obtenerBlocksSeleccionMaterias(campusId: number, periodoId: number): Observable<ApiResponse<Block[]>> {
    return this.httpClient.get<ApiResponse<Block[]>>(`${environment.enves[baseUrl].apiUrl}/Block/GetBlockSeleccionMaterias/${campusId}/${periodoId}`);
  }

  public obtenerBlocksSeleccionMateriasByAlumno(alumnoid: number, campusId: number, periodoId: number): Observable<ApiResponse<Block[]>> {
    return this.httpClient.get<ApiResponse<Block[]>>(`${environment.enves[baseUrl].apiUrl}/Block/obtenerBlocksSeleccionMateriasByAlumno/${alumnoid}/${campusId}/${periodoId}`);
  }

  public obtenerMateriasBloque(blockId: number, alumnoId: number): Observable<ApiResponse<Array<SeleccionDeMateriasDTO>>> {
    return this.httpClient.get<ApiResponse<Array<SeleccionDeMateriasDTO>>>(`${environment.enves[baseUrl].apiUrl}/Block/GetMateriasBloque/${blockId}/${alumnoId}`);
  }

  public ValidarExisteAlumnoEnBloque(periodoId: number, campusId: number, alumnoId: number): Observable<ApiResponse<number>> {
    return this.httpClient.get<ApiResponse<number>>(`${environment.enves[baseUrl].apiUrl}/Block/ValidarExisteAlumnoEnBloque/${periodoId}/${campusId}/${alumnoId}`);
  }


  /**
      * Convierte los datos obtenidos de los filtros a un archivo Excel
      * @param elemento
      * @returns Archivo en formato excel con los datos filtrados
      */
  public descargarExcel(elemento: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Block/DownloadExcel`, JSON.stringify(elemento),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  public descargarExcelAlumno(elemento: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Block/DownloadExcelAlumno`, JSON.stringify(elemento),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  public eliminarBlock(blockId: string): Observable<ApiResponse<any>> {
    return this.httpClient.delete<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Block/BorrarBloque?blockId=` + blockId);
  }

  public eliminarBlockGroups(blockId: number, grupoIds: string): Observable<ApiResponse<any>> {
    return this.httpClient.delete<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Block/BorrarBloqueGrupos/${blockId}/${grupoIds}/`);
  }


  public eliminarBlockStudent(blockId: number, studentIds: string): Observable<ApiResponse<any>> {
    return this.httpClient.delete<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Block/BorrarBloqueAlumnos/${blockId}/${studentIds}/`);
  }


  public agregarBloque(bloque: Block): Observable<ApiResponse<Block>> {
    return this.httpClient.post<ApiResponse<Block>>(`${environment.enves[baseUrl].apiUrl}/Block/GuardarBloque`, bloque);
  }

  public ObtenerBloqueGrupo(blockId: number, idioma: string): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Block/ObtenerBloqueGrupo/${blockId}/${idioma}`);
  }

  public ObtenerBloqueAlumno(blockId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Block/ObtenerBloqueAlumno/${blockId}`);
  }

  public ObtenerAlumnosAnadir(filtros: FiltrosBlockStudentDto): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Block/ObtenerAlumnosAnadir`, filtros);
  }

  //Get Grupos
  public obtenerGrupos(idioma: string, periodoId: number, campusId: number): Observable<ApiResponse<Array<GrupoDTO>>> {
    return this.httpClient.get<ApiResponse<any[]>>(`${environment.enves[baseUrl].apiUrl}/Block/ObtenerGruposFiltro/${idioma}/${periodoId}/${campusId}`);
  }


  public PostListBlockStudent(ListBlockStudent: Array<BlockStudent>): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Block/PostListBlockStudent`, ListBlockStudent);
  }


  public InscribirAGruposBloque(ListBlockStudent: Array<BlockStudent>): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Block/InscribirAGruposBloque`, ListBlockStudent);
  }

  public InscribirAGruposBloqueAlumno(blockStudent: BlockStudent): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Block/InscribirAGruposBloqueAlumno`, blockStudent);
  }
  public InscribirAGruposBloqueAlumnos(blockStudent: BlockStudent[]): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Block/InscribirAGruposBloqueAlumnos`, blockStudent);
  }

  public ExisteColegiaturaCostoDisponibleAlumno(ListBlockStudent: Array<any>): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Block/ExisteColegiaturaCostoDisponibleAlumno`, ListBlockStudent);
  }


  public CargoExcibicionBloque(ListBlockStudent: Array<any>): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Block/CargoExcibicionBloque`, ListBlockStudent);
  }

  public CargoParcialidadesBloque(ListBlockStudent: Array<any>): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Block/CargoParcialidadesBloque`, ListBlockStudent);
  }



  invocarMetodoObtenerBloque() {
    this.invocarObtenerBloque.emit();
  }
  invocarMetodoObtenerBloqueGrupo() {
    this.invocarObtenerBloqueGrupo.emit();
  }
  invocarMetodoAñadirrBloqueGrupo() {
    this.invocarAñadirBloqueGrupo.emit();
  }

  invocarMetodoObtenerBloqueAlumno() {
    this.invocarObtenerBloqueAlumno.emit();
  }

  invocarMetodoAnadirBloqueAlumno() {
    this.invocarAnadirBloqueAlumno.emit();
  }

  /**
   * Servicio para settear la informacion de los grupos en el subject
   * @param infoGrupos
   */
  public SetGruposABloque(infoGrupos: any) {
    this.infoGrupos$.next(infoGrupos);
  }

  /**
   * Servicio para obtener la informacion de los grupos como un obs
   * @returns
   */
  public GetGruposABloque$(): Observable<any> {
    return this.infoGrupos$.asObservable();
  }

  /**
   * Servicio para settear la informacion de los alumnos en el subject
   * @param infoAlumnos
   */
  public SetAlumnosABloque(infoAlumnos: any) {
    this.infoAlumnos$.next(infoAlumnos);
  }

  /**
   * Servicio para obtener la informacion de los alumnos como un obs
   * @returns
   */
  public GetAlumnosABloque$(): Observable<any> {
    return this.infoAlumnos$.asObservable();
  }


  //#region Modulo grupos

  public GetBlockLabelDto(): Observable<ApiResponse<BlockLabelDto[]>> {
    return this.httpClient.get<ApiResponse<BlockLabelDto[]>>(`${environment.enves[baseUrl].apiUrl}/BlockLabel/GetBlockLabelDto`);
  }

  public GetBlockByPeriodoCampus(BlockLabelId: number, PeriodoId: number, CampusId: number): Observable<ApiResponse<Block>> {
    return this.httpClient.get<ApiResponse<Block>>(`${environment.enves[baseUrl].apiUrl}/Block/GetBlockByPeriodoCampus/${BlockLabelId}/${PeriodoId}/${CampusId}`);
  }
  //#endregion

  public AsignacionMasivaBloqueAlumno(BlockStudent: AsignacionMasivaBloqueDto): Observable<ApiResponse<BlockStudent[]>> {
    return this.httpClient.post<ApiResponse<BlockStudent[]>>(`${environment.enves[baseUrl].apiUrl}/Block/AsignacionMasivaBloqueAlumno`, BlockStudent);
  }

  public VerificarMateriEnBloque(Block: Block): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/BlockGroup/VerificarMateriEnBloque`, Block);
  }

  public ObtenerBlockLabelName(alumnoId: number): Observable<ApiResponse<string>> {
    return this.httpClient.get<ApiResponse<string>>(`${environment.enves[baseUrl].apiUrl}/BlockLabel/ObtenerBlockLabelName/${alumnoId}`);
  }
}
