import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service';
import { Correo } from 'src/app/_models/correo';
import { UsuarioService } from 'src/app/_services/usuario.service';

@Component({
  selector: 'app-enviar-correo-usuarios',
  templateUrl: './enviar-correo-usuarios.component.html',
  styleUrls: ['./enviar-correo-usuarios.component.scss']
})
export class EnviarCorreoUsuariosComponent implements OnInit {

  public numUsuariossSeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public correosCandidatos: string = '';
  public boolCambio: boolean = false;
  public correoCandidatoForm: FormGroup;
  
  constructor(
    public dialogRef: MatDialogRef<EnviarCorreoUsuariosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: SnackService,
    private usuarioService: UsuarioService,
  ) { }

  ngOnInit(): void {
    this.validarDataRecibida();
    this.inicializarForm();
  }

  
  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numUsuariossSeleccionados = this.data.length;
    }
  }

  public inicializarForm() {
    this.correoCandidatoForm = new FormGroup({
     
      Asunto: new FormControl(''),
      CuerpoDelMensaje: new FormControl(''),
    });
  }
  get Asunto() { return this.correoCandidatoForm.get('Asunto'); }
  get CuerpoDelMensaje() { return this.correoCandidatoForm.get('CuerpoDelMensaje'); }

  public enviarCorreo() {
    if (this.boolIsArray) {
      const arrayCorreos = this.data.map(p => (p.correo.toString()));
      this.correosCandidatos = arrayCorreos.toString();
    } else {
      this.correosCandidatos = this.data.correo.toString();
    }
  
    const estructuraCorreo =  new Correo();
  
    estructuraCorreo.CorreosDestinatario = this.correosCandidatos;
    estructuraCorreo.Asunto = this.correoCandidatoForm.get('Asunto').value;
    estructuraCorreo.CuerpoDelMensaje = this.correoCandidatoForm.get('CuerpoDelMensaje').value;    
    this.usuarioService.enviarCorreos(estructuraCorreo).subscribe(correoEnviado =>{
    if (correoEnviado) {
      this.snackBar.mostrarSnackBack('Se ha enviado el correo con exito.');
      this.correoCandidatoForm.reset();
    } else {
      this.snackBar.mostrarSnackBack('Ha ocurrido un error al enviar el correo. Por favor, intente de nuevo más tarde.');
    }
    });
  }

  public cerrarModal() {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }

}
