import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-datos-personales-admision-express',
  templateUrl: './datos-personales-admision-express.component.html',
  styleUrls: ['./datos-personales-admision-express.component.scss']
})
export class DatosPersonalesAdmisionExpressComponent implements OnInit {

  //Variable Formulario
  public datosPersonalesForm: FormGroup;
  public passwordTipoTexto: boolean = false;
  public passwordCTipoTexto: boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.inicializarFormDatosPersonales();
  }


  inicializarFormDatosPersonales() {
    this.datosPersonalesForm = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      segundoNombre: new FormControl(""),
      apellidoPaterno: new FormControl("", [Validators.required]),
      apellidoMaterno: new FormControl(""),
      fechaNacimiento: new FormControl("", [Validators.required]),
      numero: new FormControl("", [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correo: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()¡¿=|?]/), this.parameters]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()¡¿=|?]/), this.parameters]),
    })
  }

  get nombre() { return this.datosPersonalesForm.get('nombre'); }
  get segundoNombre() { return this.datosPersonalesForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.datosPersonalesForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.datosPersonalesForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.datosPersonalesForm.get('fechaNacimiento'); }
  get correo() { return this.datosPersonalesForm.get('correo'); }
  get numero() { return this.datosPersonalesForm.get('numero'); }
  get password() { return this.datosPersonalesForm.get('password'); }
  get passwordConfirm() { return this.datosPersonalesForm.get("passwordConfirm"); }


  private parameters(control: AbstractControl): { [key: string]: any } | null {
    let regExp = /[ÑÁÉÍÓÚñáéíóú]/.test(control.value);

    return regExp ? { 'password': { value: control.value } } : null;
  }


  public mostrarContrasena() {
    this.passwordTipoTexto = !this.passwordTipoTexto;
  }

  public mostrarContrasenaC() {
    this.passwordCTipoTexto = !this.passwordCTipoTexto;
  }
}
