import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Persona } from '@app/_models/persona';
import { Usuario } from '@app/_models/usuario';
import { AuthenticationService } from './authentication.service';
import { MsalService } from '@azure/msal-angular';
import { Correo } from '../_models/correo';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  invocarObtenerCandidatos = new EventEmitter();
  updateTabsEmitter = new EventEmitter();
  invocarObtenerUsuarios = new EventEmitter();
  programaEmitter = new EventEmitter();
  invMetodoObtenerUsuariosSubscription: Subscription;
  private datosUsuarioBusqueda$ = new Subject<any>();
  private usuarioMenorEdad$ = new Subject<any>();
  private formValidoNuevaSolicitud$ = new Subject<boolean>();
  private formCambio$ = new Subject<boolean>();
  private formCambioNombre$ = new Subject<string>();
  private changesAcademicProgram$ = new Subject<boolean>();
  private changesPersonalData$ = new Subject<boolean>();
  private changesDocuments$ = new Subject<boolean>();
  private changesFinancialSupport$ = new Subject<boolean>();
  private changesSubjects$ = new Subject<boolean>();
  private changesPayment$ = new Subject<boolean>();
  private formValidAcademicProgram$ = new Subject<boolean>();
  invMetodoObtenerCandidatosSubscription: Subscription;
  updateTabsSubscription: Subscription;
  private cyclyeId$ = new Subject<any>();
  private studentId$ = new Subject<any>();
  private idPeriodo$ = new Subject<any>();
  private documentos$ = new Subject<boolean>();
  private materiasCount$ = new Subject<number>();

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private msalService: MsalService,
  ) { }

  // public obtenerUsuarios(): Observable<Persona[]> {
  //   return this.httpClient.get<Persona[]>(`${environment.enves[baseUrl].apiUrl}/Usuario`);
  // }

  public obtenerUsuario(
    usuarioId: number
  ): Observable<Usuario> {
    return this.httpClient.get<Usuario>(`${environment.enves[baseUrl].apiUrl}/Usuario/${usuarioId}/`);
  }

  //Me
  public Me(){
    return this.httpClient.get<Usuario>(`${environment.enves[baseUrl].apiUrl}/Usuario/Me/`).toPromise();
  }

  //Obtener Nuevos Usuarios
  public obtenerUsuarios(): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Usuario`);
  }

  //Obtiene usuarios admins
  public obtenerUsuariosAdmins(): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Usuario/Ad`);
  }

  public obtenerUsuarioPorCorreo(correo: string): Observable<Usuario> {
    return this.httpClient.get<Usuario>(`${environment.enves[baseUrl].apiUrl}/Usuario/ObtenerCorreo?correo=` + correo);
  }

  public actualizarPassword(oldPassword: string, password: string): Observable<boolean> {

    let cuenta = this.msalService.instance.getActiveAccount();
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Usuario/${cuenta.username}/password`, {
      currentPassword: oldPassword,
      newPassword: password,
    });
  }
  //Post Nuevo Candidato
  public registrarNuevoCandidato(datosUsuario): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/RegistrarUsuario/CrearCuentaUsuario`, datosUsuario);
  };

  //Put Usuario
  public editarUsuario(editarUser): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Usuario/PutEdicionUsuario`, editarUser);
  }
  //Delete Usuario
  public eliminarUsuario(usuarioId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Usuario/DeleteUsuario?usuarioId=` + usuarioId);
  }

  //Get Usuario por Correo
  public obtenerUsuarioCorreo(correoBusqueda: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Usuario/GetUsuarioBycorreo?correo=` + correoBusqueda);
  }

  //Enviar correo a usuarios
  public enviarCorreos(correos: Correo): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Usuario/EnviarCorreos`, correos);
  }
  //Excel
  public downloadExcelUsuarios(usuario: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Usuario/DownloadExcel`, JSON.stringify(usuario),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  // Set Datos Usuario Buqueda Observable
  public definirDatosUsuarioBusquedaCorreo(datosUsuario: any) {
    this.datosUsuarioBusqueda$.next(datosUsuario);
  }
  // Set Boolean Formulario Valido Observable
  public definirFormularioValidoNuevaSolicitud(boolForm: boolean) {
    this.formValidoNuevaSolicitud$.next(boolForm);
  }

  // Set Datos Usuario Buqueda Observable
  public definirDatosUsuarioBusqueda(datosUsuario: any) {
    this.datosUsuarioBusqueda$.next(datosUsuario);
  }

  //Get Datos Usuario Busqqueda Observable
  public obtenerDatosUsuarioBusqueda$(): Observable<any> {
    return this.datosUsuarioBusqueda$.asObservable();
  }

  //Descargar Documentos usuario
  public imagenUsuario(id): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Usuario/GetImagenUsuarioById/` + id);
  };
  //Registrar programa a usuario con cuenta
  public registrarProgramaUsuario(datsRegistro): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Usuario/RegistrarProgramaUsuario`, datsRegistro);
  };

  // Set Usuario Menor de edad
  public definirUsuarioMenorEdad(menorEdad: boolean) {
    this.usuarioMenorEdad$.next(menorEdad);
  }

  //Get Usuario Menor de edad
  public obtenerUsuarioMenorEdad$(): Observable<boolean> {
    return this.usuarioMenorEdad$.asObservable();
  }

  // Set Documentos Observable
  public definirValidarDocumentos(documentos: any) {
    this.documentos$.next(documentos);
  }

  //Get Documentos Observable
  public obtenerValidarDocumentos$(): Observable<any> {
    return this.documentos$.asObservable();
  }

  //Get invocarCandidato
  public invocarMetodoPrograma() {
    return this.programaEmitter.emit();
  }

  //Invocar Metodo obtenerCandidato
  invocarMetodoObtenerCandidato() {
    this.invocarObtenerCandidatos.emit();
  }
  //Invocar Metodo obtenerPeriodos
  public refrescarTabla() {
    this.invocarObtenerCandidatos.emit();
  }

  public updateTabs() {
    this.updateTabsEmitter.emit();
  }


  //Inovcar Método obtener Usuarios
  invocarMetodoObtenerUsuarios() {
    this.invocarObtenerUsuarios.emit();
  }


  // Set defineStudentId
  public defineStudentId(studentIdVar) {
    this.studentId$.next(studentIdVar);
  }

  //Get getStudentId
  public getStudentId$(): Observable<any> {
    return this.studentId$.asObservable();
  }
  // Set defineStudentId
  public defineIdPeriodo(idPeriodo) {
    this.idPeriodo$.next(idPeriodo);
  }
  //Get getStudentId
  public getPeriodoId$(): Observable<any> {
    return this.idPeriodo$.asObservable();
  }
  // Set Datos Usuario Buqueda Observable
  public defineCandidatCycleId(cycleId: any) {
    this.cyclyeId$.next(cycleId);
  }

  //Get Datos Usuario Busqqueda Observable
  public getCandidatCycleId$(): Observable<any> {
    return this.cyclyeId$.asObservable();
  }
  //Get Boolean Formulario Valido Observable
  public obtenerFormularioValidoNuevaSolicitud$(): Observable<any> {
    return this.formValidoNuevaSolicitud$.asObservable();
  }

  //Get Count de seleccion de materias
  public getMateriasCount(): Observable<number> {
    return this.materiasCount$.asObservable();
  }
  //Set Count de seleccion de materias
  public definirMateriasCount(count: number) {
    this.materiasCount$.next(count);
  }

  //Get Datos By Id Usuario
  public candidatoById(usuarioId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Usuario/GetCandidatoById?usuarioid=` + usuarioId);
  }


  //------OBSERVABLES MODAL BUTTON ---------------------//
  // Set Changes in academic program
  public setChangesAcademicProgram(change: boolean) {
    this.changesAcademicProgram$.next(change);
  }

  //Get changes academic program
  public getChangesAcademicProgram$(): Observable<boolean> {
    return this.changesAcademicProgram$.asObservable();
  }

}
