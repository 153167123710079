import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { AdmisionesService } from '@app/_services/admisiones.service';

@Component({
  selector: 'app-prueba-zoho',
  templateUrl: './prueba-zoho.component.html',
  styleUrls: ['./prueba-zoho.component.scss']
})
export class PruebaZohoComponent implements OnInit {

  public registroForm: FormGroup;

  constructor(private admisionesService: AdmisionesService) { }

  ngOnInit(): void {
    this.InicializarForm();
  }

  public InicializarForm() {
    this.registroForm = new FormGroup({
      Company: new FormControl(''),
      Last_Name: new FormControl(''),
      First_Name: new FormControl(''),
      Email: new FormControl(''),
      State: new FormControl('')
    });
  }

  public registro() {
    let data = this.registroForm.value;
    this.admisionesService.registroZoho(data).subscribe((response: SimpleResponse) => {
      alert("Registrado");
    })
  }

}
