export class MessageErrors {

    static mapErrorMessages(form: any): any {
      let controls: any = {};
      Object.entries(form.controls).forEach(control => {
        controls[control[0]] = undefined;
      });
      return controls;
    }
  
    static setErrorMessage(form: any): any {
      let errorMsgs: any = { content: undefined };
      Object.entries(form.controls).forEach(item => {
        if (form.controls[item[0]].hasError('required')) {
          errorMsgs[item[0]] = 'Campo requerido';
        }
        if (form.controls[item[0]].errors && form.controls[item[0]].errors.minlength) {
          errorMsgs[item[0]] = 'Mínimo ' + form.controls[item[0]].errors.minlength.requiredLength + ' caracteres';
        }
        if (form.controls[item[0]].hasError('email')) {
          errorMsgs[item[0]] = 'Email inválido';
        }
        if (form.controls[item[0]].hasError('pattern')) {
          errorMsgs[item[0]] = 'El dato debe de concordar al campo';
        }
        if (form.controls[item[0]].errors && form.controls[item[0]].errors.maxlength) {
          errorMsgs[item[0]] = 'Máximo ' + form.controls[item[0]].errors.maxlength.requiredLength + ' caracteres';
        }
       
      });
      return errorMsgs;
    }
  }
  