import { EstatusSolicitudApoyoDTO } from './../_models/EstatusSolicitudApoyoDTO';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { TipoApoyoFinanciero } from '../_models/tipo-apoyo-financiero';
import { ApiResponse } from '../models/api/ApiRespose.model';

@Injectable({
  providedIn: 'root'
})
export class TipoApoyoFinancieroService {

  invocarObtenerTipoApoyoFinancieroEmit = new EventEmitter();
  metodoTipoApoyoFinancieroSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  //Get Tipo Apoyo Financiero
  public GetestatusSolicitudApoyo(): Observable<ApiResponse<EstatusSolicitudApoyoDTO[]>> {
    return this.httpClient.get<ApiResponse<EstatusSolicitudApoyoDTO[]>>(`${environment.enves[baseUrl].apiUrl}/TipoApoyoFinanciero/GetestatusSolicitudApoyo`);
  }

  //Get Tipo Apoyo Financiero
  public obtenerTipoApoyoFinanciero(): Observable<TipoApoyoFinanciero[]> {
    return this.httpClient.get<TipoApoyoFinanciero[]>(`${environment.enves[baseUrl].apiUrl}/TipoApoyoFinanciero`);
  }

  //Get Tipo Apoyo Financiero para tabla de seguimiento
  public obtenerTipoApoyoFinancieroTablaSeguimiento(): Observable<TipoApoyoFinanciero[]> {
    return this.httpClient.get<TipoApoyoFinanciero[]>(`${environment.enves[baseUrl].apiUrl}/TipoApoyoFinanciero/GetTipoApoyoFinancierosTablaSeguimiento`);
  }


  //Post Tipo Apoyo Financiero
  public agregarTipoApoyoFinanciero(tipoApoyoFinanciero: TipoApoyoFinanciero): Observable<TipoApoyoFinanciero> {
    return this.httpClient.post<TipoApoyoFinanciero>(`${environment.enves[baseUrl].apiUrl}/TipoApoyoFinanciero`, tipoApoyoFinanciero);
  }

  //Put Tipo Apoyo Financiero
  public editarTipoApoyoFinanciero(tipoApoyoFinanciero: TipoApoyoFinanciero): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/TipoApoyoFinanciero`, tipoApoyoFinanciero);
  }

  //Delete Tipo Apoyo Financiero
  public eliminarTipoApoyoFinanciero(tipoApoyoFinancieroId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.enves[baseUrl].apiUrl}/TipoApoyoFinanciero/?tipoApoyoFinancieroId=${tipoApoyoFinancieroId}`);
  }

  //Invocar Metodo obtenerTipoApoyoFinanciero
  invocarMetodoObtenerTipoApoyoFinanciero() {
    this.invocarObtenerTipoApoyoFinancieroEmit.emit();
  }

  /**
     * Servicio que obtiene los datos de tipo apoyo financiero y los convierte a un archivo Excel
     * @returns
     */

  public descargarExcel(tipoApoyoFinancieroIds: Array<string>) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/TipoApoyoFinanciero/DownloadExcel`, JSON.stringify(tipoApoyoFinancieroIds),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }



}
