import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalMaterias } from '../models/customEntities/modalMaterias';
import { GruposDisponiblesParaMateriaDTO } from '../models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { ModalMateriasSeleccionadasComponent } from '../_components/modal-materias-seleccionadas/modal-materias-seleccionadas.component';

@Injectable({
  providedIn: 'root'
})
export class MatetiasSeleccionadasService {

  constructor(private dialog: MatDialog) { }
  /**
   * Muestra en pantalla un modal que puede desapilarse, y retorna la respuesta que ha escogido el usaurio
   * @param informacionDeMaterias Objeto que contiene la lista de materias selecciondas y el total a pagar
   * @returns ModalReference
   */
  public mostrarModalMaterias(materiasSeleccionadas: Array<GruposDisponiblesParaMateriaDTO>, total: number, tipoDeCobro: string) {
    let informacionDeMaterias = new ModalMaterias(
      total,
      materiasSeleccionadas,
      tipoDeCobro
    );
    return this.dialog.open(ModalMateriasSeleccionadasComponent, {
      data: informacionDeMaterias,
      disableClose: true,
      width: "45rem"
    });
  }
}
