import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { Direccion } from '../../../../_models/direccion';
import { NuevoIngreso } from '../../../../_models/nuevo-ingreso';
import { Profesor } from '../../../../_models/profesor';
import { Usuario } from '../../../../_models/usuario';
import { ProfesorService } from '../../../../_services/profesor.service';

@Component({
  selector: 'app-form-cuenta-registrar',
  templateUrl: './form-cuenta-registrar.component.html',
  styleUrls: ['./form-cuenta-registrar.component.scss']
})
export class FormCuentaRegistrarComponent implements OnInit {

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public profesorForm: FormGroup;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public errorActualizaProfesor: string = "";
  public correoValue: string = "";
  public objValidacionCancelar: Profesor = null;
  public dialogoRefAdver: any;
  public tipoAccion: number;
  public cuentaGenerada: boolean = false;
  public errorCrearCuenta: string = "";
  public nuevoIngreso: NuevoIngreso;
  public nombreRegistro: string = '';
  public datosRegistro: any;
  public idRegistro: number = 0;
  public boolCambio: boolean = false;
  public direccion: Direccion;
  public correoRegistro: any;
  public passwordTipoTexto: boolean = false;
  public passwordCTipoTexto: boolean = false;

  //public datosUsuario: Usuario;
  public usuarioID: number = 0;
  public renderizarFormulario: boolean = false;
  public validar: boolean = false;
  constructor(public dialogRef: MatDialogRef<FormCuentaRegistrarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Usuario,
    private profesorService: ProfesorService,
    public nuevoIngresoService: NuevoIngresoService,
    private snackService: SnackService
  ) { }

  ngOnInit(): void {
    this.inicializarForm();
  }

  public cerrarModalValidacion() {
    this.dialogRef.close();
  }
  public recibirInfo(correo: any) {
    this.correoRegistro = correo;
    this.inicializarForm();
  }

  public inicializarForm() {
    this.profesorForm = new FormGroup({
      correo: new FormControl('', [Validators.required, Validators.email]),
      nombre: new FormControl('', [Validators.required]),
      segundoNombre: new FormControl(''),
      apellidoPaterno: new FormControl('', [Validators.required]),
      apellidoMaterno: new FormControl(''),
      fechaNacimiento: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern(/^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W).{8,})$/), this.parameters]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8), this.parameters]),
    }, this.compararPasswords);
    this.renderizarFormulario = true;
    this.validar = this.profesorForm.valid;
    this.profesorForm.valueChanges.subscribe(() => {
      this.profesorService.definirFormularioValidoRegistroNuevoProfesor(this.profesorForm.valid);
    });
  }

  private parameters(control: AbstractControl): { [key: string]: any } | null {
    let regExp = /[ÑÁÉÍÓÚñáéíóú]/.test(control.value);
    return regExp ? { 'password': { value: control.value } } : null;
  }

  compararPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('passwordConfirm').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  get correo() { return this.profesorForm.get('correo'); }
  get nombre() { return this.profesorForm.get('nombre'); }
  get segundoNombre() { return this.profesorForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.profesorForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.profesorForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.profesorForm.get('fechaNacimiento'); }
  get password() { return this.profesorForm.get('password'); }
  get passwordConfirm() { return this.profesorForm.get('passwordConfirm'); }
  // get programaAcademico() { return this.profesorForm.get('programaAcademico'); }
  // get ciclo() { return this.profesorForm.get('ciclo'); }

  public mostrarContrasena() {
    this.passwordTipoTexto = !this.passwordTipoTexto;
  }

  public mostrarContrasenaC() {
    this.passwordCTipoTexto = !this.passwordCTipoTexto;
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.profesorForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  /**
  * Metodo para validar si el correo es valido o no
  * @param event 
  */
  public validarCorreo(event: any): void {
    if (this.correo.valid) {
      this.nuevoIngresoService.validarCorreo(event).subscribe((respuesta: ApiResponse<boolean>) => {
        if (respuesta.success) {
          if (respuesta.data) {
            this.nuevoIngresoService.buscarUsuarioByCorreo();
          }
        } else {
          this.snackService.mostrarSnackBack(respuesta.message);
        }
      });
    }
  }

}
