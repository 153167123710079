import { Usuario } from './../../../../_models/usuario';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { AdministracionAcademicaComponent } from 'src/app/administracion-academica/administracion-academica.component';
import { CalificacionNoNumerica } from 'src/app/_models/CalificacionNoNumerica';
import { HistorialCalificacionFinalParametroDTO } from 'src/app/_models/HistorialCalificacionFinalParametroDTO';
import { TipoMotivoCalificacion } from 'src/app/_models/TipoMotivoCalificacion';
import { AdminAcademicaService } from 'src/app/_services/admin-academica.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SnackService } from '../../../../services/snack-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-modal-cambio-calificacion',
  templateUrl: './modal-cambio-calificacion.component.html',
  styleUrls: ['./modal-cambio-calificacion.component.scss']
})
export class ModalCambioCalificacionComponent implements OnInit {
  //#region Observable
  private Subscription: Subscription = new Subscription();
  //#endregion
  //#region Propiedades
  public CalificacionNoNumerica: Array<CalificacionNoNumerica> = [];
  public TipoMotivoCalificacion: Array<TipoMotivoCalificacion> = [];
  public AlumnoID: number = 0;
  public CalficicacionFinal: number = 0;
  public MateriaConClave: string;
  public Periodo: string;
  public Grupo: number = 0;
  public InstitucionId: string;
  public ProgramaID: number = 0;
  public HCFParametro: HistorialCalificacionFinalParametroDTO;
  public MOID: number = 0;
  public CNNID: number = 0;
  public TMCID: number = 0;
  public FormValido: boolean = false;
  public myForm: FormGroup;
  //#endregion

  //#region Usuario
  public isAuthenticated: boolean = false;
  public userName: string = "";
  public correoUser: string = "";
  public base64Image: any;
  public base64Logo: any;
  public imagePath: any;
  public imagePathLogo: any;
  public identificador: string;
  datosUsuarioSubscription: Subscription;
  public usuario: any;
  programaUsuario: string = "";
  //#endregion

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private msalService: MsalService,
    private SnackServiceService: SnackService,
    public dialogRef: MatDialogRef<ModalCambioCalificacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: [0, 0, 0, "", "", 0, ""],
    private adminAcademiaService: AdminAcademicaService) { }

  ngOnInit(): void {
    this.AlumnoID = this.data[0];
    this.Grupo = this.data[1];
    this.MOID = this.data[2];
    this.Periodo = this.data[3];
    this.MateriaConClave = this.data[4];
    this.ProgramaID = this.data[5];
    this.InstitucionId = this.data[6];
    this.ObtenerDatosUsuario();
    this.GetUltimaCalificacionObtenida();
    this.NuevaCalificacionFinal();
    this.GetTipoMotivoCalificacion();

    //this.loadDataForm();
    this.buildForm();


  }




  private buildForm(): void {
    this.myForm = this.formBuilder.group({
      calficicacionFinal: [this.CalficicacionFinal],
      nuevaCal: ['', [Validators.required]],
      motivoCambio: ['', [Validators.required]],
    }
    );

    this.myForm.get('calficicacionFinal').disable();
  }

  public clearForm(): void {
    this.buildForm();
  }

  public onSubmit($event: Event): void {
    if (this.myForm.valid) {

      this.HCFParametro = new HistorialCalificacionFinalParametroDTO(this.AlumnoID, this.Grupo, this.myForm.value.nuevaCal, this.myForm.value.motivoCambio, this.usuario.usuarioId, this.MOID);
      this.GuardarHistorialCalFinal();

    } else {
      this.myForm.markAllAsTouched();
      this.SnackServiceService.mostrarSnackBack("favor de llenar todos los campos requeridos");
    }
  }


  get f() { return this.myForm.controls; }


  private loadDataForm() {
    this.myForm.controls['calficicacionFinal'].setValue(this.CalficicacionFinal);
  }


  public GetUltimaCalificacionObtenida(): void {
    this.Subscription.add(
      this.adminAcademiaService.GetUltimaCalificacionObtenida(this.data[0], this.data[1], this.data[2]).subscribe((CalficicacionFinal) => {
        this.CalficicacionFinal = CalficicacionFinal;
        this.loadDataForm();
      })
    );
  }


  public NuevaCalificacionFinal(): void {
    this.Subscription.add(
      this.adminAcademiaService.NuevaCalificacionFinal(this.ProgramaID).subscribe((CalificacionNoNumerica) => {
        this.CalificacionNoNumerica = CalificacionNoNumerica;
      })
    );
  }

  public GetTipoMotivoCalificacion(): void {
    this.Subscription.add(
      this.adminAcademiaService.TipoMotivoCalificacion(this.InstitucionId).subscribe((TipoMotivoCalificacion) => {
        this.TipoMotivoCalificacion = TipoMotivoCalificacion;

      })
    );
  }



  public GuardarHistorialCalFinal(): void {
    this.Subscription.add(
      this.adminAcademiaService.GuardarHistorialCalFinal(this.HCFParametro).subscribe((guardado) => {
        guardado;
        if (guardado == 1) {
          this.SnackServiceService.mostrarSnackBack("La calificación ha sido modificada");
          this.clearForm();
          this.CerrarModal();
        }

      })
    );
  }



  public ObtenerDatosUsuario() {
    this.authenticationService.currentUser.subscribe(user => {
      let cuenta = this.msalService.instance.getActiveAccount();
      this.isAuthenticated = !!cuenta;
      this.userName = "";
      if (this.isAuthenticated) {
        this.identificador = cuenta.username.substring(0, cuenta.username.indexOf('@'));
        this.datosUsuarioSubscription = this.authenticationService.obtenerDatosUsuario(this.identificador).subscribe(data => {
          this.usuario = data;
        })
        this.userName = cuenta.name;
        this.correoUser = cuenta.username;
      }
    });
  }


  public CerrarModal() {
    this.dialogRef.close();
  }

}
