import { TraductorService } from './../../../_services/traductor.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service'
import { Subscription } from 'rxjs';
import { RecursoAlumnoArchivo } from '../../../_models/ensenanzaAprendizajeAlumno'
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { RecursoAdjunto } from 'src/app/_models/recurso-adjunto';
import { Recurso } from 'src/app/_models/recurso';
import { EnviarCorreoComponent } from 'src/app/_components/enviar-correo/enviar-correo.component';
import { ModalConsultarExamenEncuestaComponent } from 'src/app/ensenanza-aprendizaje-alumno/vista-contenido/consultar/modal-consultar-examen-encuesta/modal-consultar-examen-encuesta/modal-consultar-examen-encuesta.component';
import * as customBuild from '../../../../app/_librarries/ckeditorCustom/build/ckeditor';
import { Router } from '@angular/router';
import { ModalContestarExEnComponent } from '../por-hacer/modal-contestar-ex-en/modal-contestar-ex-en.component';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-detalle',
  templateUrl: './modal-detalle.component.html',
  styleUrls: ['./modal-detalle.component.scss'],
  providers: [DatePipe]
})
export class ModalDetalleComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalDetalleComponent>,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private router: Router,
    private snackService: SnackService,
    private traductorService: TraductorService,
  ) { }

  public Editor = customBuild;

  ListRecursoAlumno: RecursoAlumnoArchivo[] = [];
  public idAlumno$: number;
  public idAlumnoSubscription: Subscription;
  public listRecusoAlumno: RecursoAlumnoArchivo[] = [];
  public listRecusosExistentes: RecursoAlumnoArchivo[] = [];
  public cargaDocumentoForm: FormGroup;
  public tareaEntregada: boolean = false;
  flagRecursoAlumno: boolean = false;
  public recursoAlumno: any;
  public profesorId$: number;
  public habilitarBtnConsulta: boolean = false;
  //nuevo
  public reintentosDisponibles: boolean = false;
  public numeroReintentos: number = 0;
  public datosExamen: any;
  public tipoRecursoId: number = 0;
  public contenidoCompletado: boolean = false;
  public grupoId: number = 0;
  public datosEncuesta: any;
  public responderEncuesta: boolean = false;

  ngOnDestroy(): void {
    this.EnsenanzaAprendizajeAlumnoService.SetModalExistente(false);
    this.sePuedeReintentar = false;
  }

  ngOnInit(): void {
    console.log("this.data", this.data);
    this.tipoRecursoId = this.data.tipoRecursoId;
    this.contenidoCompletado = this.data.existeRecursoAlumno;
    this.EnsenanzaAprendizajeAlumnoService.SetModalExistente(true);
    const parametros = window.location.pathname.split("/", 6);
    this.grupoId = +parametros[3];
    this.router.navigate([`/detalle-grupo-alumno/grupo/${parametros[3]}/opcion/${parametros[5]}/recurso/${this.data.recursoId}`]);
    this.validarFecha();
    this.validarDisponibilidadConsulta();
    this.tareaEntregada = false;
    if (!this.data.entregar && !this.data.calificacion) {
      if (this.data.recursoAdjunto.length >= 1) {
        for (var i = 0; i < this.data.recursoAdjunto.length; i++) {
          var indexDocument = this.data.recursoAdjunto[i].nombreOriginal.indexOf(".");
          var tipoDocument = this.data.recursoAdjunto[i].nombreOriginal.substr(indexDocument);
          this.data.recursoAdjunto[i].tipoDocumento = tipoDocument
        }
      }
    } else {
      this.idAlumno$ = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;
      this.profesorId$ = this.EnsenanzaAprendizajeAlumnoService.profesorId$;
      this.cargarRecursosAlumno();
      this.inicializarForm();
    }
    this.idAlumno$ = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;
    this.EnsenanzaAprendizajeAlumnoService.obtenerRecursoTareaAlumno(this.data.recursoId, this.idAlumno$).subscribe((recursoAlumno: ApiResponse<Recurso[]>) => {
      if (recursoAlumno.success) {
        this.recursoAlumno = recursoAlumno.data;
        this.data.recursoAlumno = this.recursoAlumno.recursoAlumno;
        console.log("RECURSO", this.recursoAlumno)
      }
    });
    this.validarReintentos();
    if (this.data.tipoRecursoId == 3) {
      this.cargarDetalleExamen();
    } else {
      this.cargarDetalleEncuesta();
    }
  }

  public modalResultado(): void {
    const modal = this.dialog.open(ModalConsultarExamenEncuestaComponent, { data: this.data, disableClose: true, height: '550px', width: '80vw' })
  }

  public inicializarForm() {
    this.cargaDocumentoForm = new FormGroup({
      nombreOriginal: new FormControl(),
      AlumnoId: new FormControl(),
      NombreFisico: new FormControl(),
      RecursoId: new FormControl(),
    })
  }

  public cerrarModal() {
    const parametros = window.location.pathname.split("/", 6);
    this.router.navigate([`/detalle-grupo-alumno/grupo/${parametros[3]}/opcion/${parametros[5]}`]);
    this.dialogRef.close();
  }

  public descargarRecursoCal(recurso) {
    let documento: ArrayBufferLike = this.base64ToArrayBuffer(recurso.nombreFisico);
    let mime: string = recurso.nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : "application/octect-stream";
    let a: HTMLAnchorElement = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    let blob: Blob = new Blob([documento], { type: mime });
    a.href = window.URL.createObjectURL(blob);
    a.download = recurso.nombreOriginal;
    a.click();
  }

  public descargarRecurso(recursoId) {
    this.EnsenanzaAprendizajeAlumnoService.obtenerRecursoAdjunto(recursoId).subscribe(
      (respuestaRecurso: ApiResponse<RecursoAdjunto>) => {
        let documento: ArrayBufferLike = this.base64ToArrayBuffer(respuestaRecurso.data.nombreFisico);
        let mime: string = respuestaRecurso.data.nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : "application/octect-stream";
        let a: HTMLAnchorElement = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        let blob: Blob = new Blob([documento], { type: mime });
        a.href = window.URL.createObjectURL(blob);
        a.download = respuestaRecurso.data.nombreOriginal;
        a.click();

      })
  }

  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public cargarRecursosAlumno() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerRecursoAlumno(this.idAlumno$, this.data.recursoId).subscribe((recursosAlumno: ApiResponse<RecursoAlumnoArchivo[]>) => {
      if (recursosAlumno.data) {
        this.listRecusoAlumno = recursosAlumno.data;
        for (var i = 0; i < this.listRecusoAlumno.length; i++) {
          var indexDocument = this.listRecusoAlumno[i].nombreOriginal.indexOf(".");
          var tipoDocument = this.listRecusoAlumno[i].nombreOriginal.substr(indexDocument);
          this.listRecusoAlumno[i].tipoDocumento = tipoDocument;
          var mime = this.listRecusoAlumno[i].tipoDocumento == ".pdf" ? "application/pdf" : "application/octet-stream";
          var nombreFisico = this.listRecusoAlumno[i].nombreFisico;
          this.listRecusoAlumno[i].nombreFisico = "data:" + mime + ";base64," + nombreFisico;
        }
        this.listRecusosExistentes = recursosAlumno.data;
        if (this.listRecusosExistentes.length > 0) {
          this.tareaEntregada = true;
        }
      }
    })
  }

  uploadFiles: Array<File>;
  public onFileChange(event) {
    var fechaHoy1 = new Date();
    let fechaHoy = this.datePipe.transform(fechaHoy1, 'yyyy-MM-dd');
    //if (fechaHoy <= this.data.fechaEntrega) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.onload = () => {
        this.cargaDocumentoForm.patchValue({
          nombreOriginal: file.name,
          AlumnoId: this.idAlumno$,
          RecursoId: this.data.recursoId,
          NombreFisico: reader.result
        });
        if (this.listRecusoAlumno.length > 0) {
          for (var x = 0; x < this.listRecusosExistentes.length; x++) {
            this.ListRecursoAlumno.push(this.listRecusosExistentes[x]);
          }
          this.listRecusosExistentes = [];
        }
        this.ListRecursoAlumno.push(this.cargaDocumentoForm.value);
        this.listRecusoAlumno = this.ListRecursoAlumno;
        for (var i = 0; i < this.listRecusoAlumno.length; i++) {
          var indexDocument = this.listRecusoAlumno[i].nombreOriginal.indexOf(".");
          var tipoDocument = this.listRecusoAlumno[i].nombreOriginal.substr(indexDocument);
          this.listRecusoAlumno[i].tipoDocumento = tipoDocument

        }
      };
      reader.readAsDataURL(file);
      this.flagRecursoAlumno = true;
    }
    /* } else {
      this.snackBar.open("La fecha de entrega ya fue vencida.", "OK", { duration: 5000 });
    } */
  }

  public eliminar(recurso, index) {
    var fechaHoy1 = new Date();
    let fechaHoy = this.datePipe.transform(fechaHoy1, 'yyyy-MM-dd');
    if (fechaHoy <= this.data.fechaEntrega) {
      if (this.listRecusoAlumno[index].usuarioCreacion != undefined) {
        this.EnsenanzaAprendizajeAlumnoService.eliminarRecursoAlumno(recurso.alumnoId, recurso.recursoAlumnoArchivoId).subscribe(
          (data: SimpleResponse) => {
            if (!data.success) {
              this.snackBar.open("No se pudo eliminar el documento.Favor volver a intentar", "OK", { duration: 5000 });
            }
          })
      }
      this.ListRecursoAlumno.splice(index, 1);
    } else {
      this.snackBar.open("No es posible eliminar documentos si la fecha de entrega es vencida.", "OK", { duration: 5000 });
    }
  }

  public guardar() {
    var fechaHoy1 = new Date();
    let fechaHoy = this.datePipe.transform(fechaHoy1, 'yyyy-MM-dd');
    // if (fechaHoy <= this.data.fechaEntrega) {
    this.EnsenanzaAprendizajeAlumnoService.agregarRecursos(this.ListRecursoAlumno).subscribe((agregaRecurso: SimpleResponse) => {
      if (agregaRecurso.success) {
        let mensaje: string = this.traductorService.translate('guardadoTarea');
        this.snackService.mostrarSnackBack(mensaje);
      } else {
        this.snackBar.open("No se pudo guardar el documento.Favor volver a intentar", "OK", { duration: 5000 });
      }
    })
    // } else {
    //this.snackBar.open("No es posible guardar documentos si la fecha de entrega es vencida.", "OK", { duration: 5000 });

    //}
  }

  fechaVencida: boolean = false;
  public validarFecha() {
    var hoy = moment();
    var horaFin = moment(this.data.horaFin, 'h:mma');


    var fechaHoy1 = new Date();
    let fechaHoy = this.datePipe.transform(fechaHoy1, 'yyyy-MM-dd');

    let fechaEntrega = this.datePipe.transform(this.data.fechaEntrega, 'yyyy-MM-dd');

    this.data.permiteVencida = this.data.permiteVencida ?? false;
    if (this.data.permiteVencida) {
      this.fechaVencida = false;
    }
    else if (fechaHoy < fechaEntrega) {
      this.fechaVencida = false;
    } else if (fechaHoy === fechaEntrega) {
      if (hoy.isBefore(horaFin)) {
        this.fechaVencida = false;
      }
      else {
        this.fechaVencida = true;
      }
    }
  }

  public validarDisponibilidadConsulta() {
    var fechaHoy1 = new Date();
    let fechaHoy = this.datePipe.transform(fechaHoy1, 'yyyy-MM-dd');
    if (fechaHoy >= this.data.fechaEntrega && this.data.estatus === 'Revisado') {
      this.habilitarBtnConsulta = true;
    } else {
      this.habilitarBtnConsulta = false;
    }
  }

  public enviarCorreo(): void {
    this.data.profesorId = this.profesorId$;
    this.dialog.open(EnviarCorreoComponent, { data: this.data, disableClose: true });
  }

  public abrirUrl(link) {
    window.open(link, "_blank");
  }

  ///Se inician los reintentos de presentar examen
  public reintentosHechos: any[] = [];
  public sePuedeReintentar: boolean = false;
  public calificacionPrograma: any[] = [];
  public resource: any;

  public validarReintentos() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerReintentos(this.idAlumno$, this.data.recursoId).subscribe((reintentos: ApiResponse<any>) => {
      this.reintentosHechos = reintentos.data;
      console.log("reintentos", reintentos);
      if (this.reintentosHechos != null) {
        this.numeroReintentos = this.reintentosHechos.length;
        this.EnsenanzaAprendizajeAlumnoService.ObtenerCalificacionMinima(this.idAlumno$).subscribe((calificacion: ApiResponse<any>) => {
          this.calificacionPrograma = calificacion.data;
          //validacion para saber la cantidad de reintentos hechos contra los disponibles
          console.log("this.data", this.data);
          this.resource = this.data.recursoAlumno.filter(x => x.alumnoId == this.idAlumno$);
          console.log("calificacionPrograma", this.calificacionPrograma);
          debugger
          if (this.reintentosHechos.length < this.data.reintentos && this.resource[0].calificacionNoNumerica) {
            if (this.resource[0].calificacionNoNumerica.valorNumerico < this.calificacionPrograma[0].calificacionMinima) {
              this.sePuedeReintentar = true;
              this.numeroReintentos = this.reintentosHechos.length;
            }
          } else if (this.reintentosHechos.length === this.data.reintentos) {
            this.sePuedeReintentar = false;
          } else if (this.reintentosHechos.length > this.data.reintentos) {
            this.sePuedeReintentar = false;
          }
        });
      } else {
        this.sePuedeReintentar = false;
      }
    });


  }

  public contestarExamen() {
    let today = new Date();
    let fechaEntrega = new Date(this.datosExamen.fechaEntrega);
    if (fechaEntrega <= today) {
      this.snackBar.open("La fecha de cierre ya paso.", "OK", { duration: 3000 });
    } else {
      this.data.reintento = true;
      this.data.alumnoId = this.idAlumno$;
      const modal = this.dialog.open(ModalContestarExEnComponent, { data: this.data, disableClose: true, height: '550px', width: '80vw' });
      this.dialogRef.close();
    }
  }

  public contestarEncuesta() {
    let today = new Date();
    let fechaEntrega = new Date(this.datosEncuesta.fechaEntrega);
    let fechaIncio = new Date(this.datosEncuesta.fechaInicio);
    if (this.datosEncuesta.horaFin) {
      let horaFin = new Date();
      let [hours, minutes] = this.datosEncuesta.horaFin.split(':');
      horaFin.setHours(+hours);
      horaFin.setMinutes(minutes);
      let fechaFin = new Date(this.datosEncuesta.fechaEntrega)
      fechaEntrega = this.combineDateAndTime(fechaFin, horaFin)
    }
    if (this.datosEncuesta.horaInicio) {
      let horaInicio = new Date();
      let [hours, minutes] = this.datosEncuesta.horaInicio.split(':');
      horaInicio.setHours(+hours);
      horaInicio.setMinutes(minutes);
      let fechaInicio = new Date(this.datosEncuesta.fechaInicio)
      fechaIncio = this.combineDateAndTime(fechaInicio, horaInicio)
    }
    if (fechaEntrega <= today) {
      this.snackBar.open("La fecha de cierre ya paso.", "OK", { duration: 3000 });
    } else if (fechaIncio.getTime() >= today.getTime()) {
      this.snackBar.open("La fecha de inicio aun no empieza.", "OK", { duration: 3000 });
    } else {
      const modal = this.dialog.open(ModalContestarExEnComponent, { data: this.data, disableClose: true, height: '550px', width: '80vw' })
      this.dialogRef.close();
    }
  }

  public combineDateAndTime = function (date, time) {
    let timeString = time.getHours() + ':' + time.getMinutes() + ':00';
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();
    var dateString = '' + year + '-' + month + '-' + day;
    var combined = new Date(dateString + ' ' + timeString);
    return combined;
  };

  public stringToHtml(titulo: string) {
    let xmlString = titulo;
    let doc: Document = new DOMParser().parseFromString(xmlString, "text/html");
    return doc.body.innerHTML;
  }

  public verResultado: boolean;
  public responderExamen: boolean = false;
  public preguntaAbierta: boolean = false;

  public cargarDetalleExamen() {
    this.EnsenanzaAprendizajeAlumnoService.GetDetalleExamen(this.data.recursoId, this.idAlumno$).subscribe((ex: ApiResponse<Recurso[]>) => {
      this.datosExamen = ex.data;
      this.datosExamen.descripcion = this.stringToHtml(this.datosExamen.descripcion)
      if (this.datosExamen.estatus == "Revisado") {
        this.verResultado = false;
      } else if (this.datosExamen.estatus == "Sin entregar") {
        this.verResultado = false;
        this.responderExamen = true;
      } else {
        this.verResultado = true;
      }
      this.EnsenanzaAprendizajeAlumnoService.GetExamenAbierto(this.data.recursoId).subscribe((valid: SimpleResponse) => {
        if (valid.success) {
          this.preguntaAbierta = true;
        }
      })
    })
  }

  public cargarDetalleEncuesta() {
    this.EnsenanzaAprendizajeAlumnoService.GetDetalleEncuesta(this.data.recursoId, this.idAlumno$).subscribe((en: ApiResponse<Recurso[]>) => {
      this.datosEncuesta = en.data;
      this.datosEncuesta.descripcion = this.stringToHtml(this.datosEncuesta.descripcion)
      if (this.datosEncuesta.estatus == "Revisado") {
        this.verResultado = true;
      } else if (this.datosEncuesta.estatus == "Sin entregar") {
        this.verResultado = false;
        this.responderEncuesta = true;
      }
    })
  }

  /**
   * Metodo que elimina o crea un recurso alumno solo para contenido
   * @param evento
   */
  public contenidoCompleto(evento): void {
    this.EnsenanzaAprendizajeAlumnoService.contenidoCompletado(this.grupoId, this.data.recursoId, this.idAlumno$, evento.checked).subscribe((response: SimpleResponse) => {
      if (response.success) {
        let mensaje: string = this.traductorService.translate(response.message);
        this.snackService.mostrarSnackBack(mensaje);
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }
}
