import { CampusProgramaDTO } from './../_models/CampusProgramaDTO';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';

import { Campus } from '@app/_models/campus';
import { Programa } from '../_models/programa';
import { Edificio } from '../_models/edificio';

@Injectable({
  providedIn: 'root'
})
export class CampusService {

  invocarObtenerCampus = new EventEmitter();
  invMetodoObtenerCampusSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  //Get Campus
  public obtenerCampus(): Observable<Campus[]> {
    return this.httpClient.get<Campus[]>(`${environment.enves[baseUrl].apiUrl}/Campus`);
  }

  //Get Campus OrderBy
  public otenerCampusOrderBy(): Observable<Campus[]> {
    return this.httpClient.get<Campus[]>(`${environment.enves[baseUrl].apiUrl}/Campus/OrderBy`);
  }

  //Post Campus
  public agregarCampus(campus: Campus): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Campus`, campus);
  }

  //Put Campus
  public modificarCampus(campus: Campus): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Campus`, campus);
  }

  //Delete Campus One
  public eliminarCampus(campusId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Campus?campusId=` + campusId);
  }

  public obtenerProgramas(campusId: number): Observable<Programa[]> {
    return this.httpClient.get<Programa[]>(`${environment.enves[baseUrl].apiUrl}/Campus/ProgramasCampus/${campusId}/`);
  }

  public obtenerCampusByClave(campusId: number): Observable<any> {
    return this.httpClient.get<Campus>(`${environment.enves[baseUrl].apiUrl}/Campus/GetCampusByClave?campusId=` + campusId);
  }

  public obtenerCampusClave(clave: string): Observable<any> {
    return this.httpClient.get<Campus>(`${environment.enves[baseUrl].apiUrl}/Campus/GetCampusClave?clave=` + clave);
  }
  //GET Edificio Campus
  public obtenerEdificioCampus(campusId: number): Observable<Edificio[]> {
    return this.httpClient.get<Edificio[]>(`${environment.enves[baseUrl].apiUrl}/Campus/GetEdificioByCampusId?campusId=` + campusId);
  }
  //Campus by Programa Académico
  public ObtenerCampusByProgramaAcademico(programaNombre: string): Observable<ApiResponse<CampusProgramaDTO[]>> {
    return this.httpClient.get<ApiResponse<CampusProgramaDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Campus/ObtenerCampusByProgramaAcademico/${programaNombre}/`);
  }

  //Invocar Metodo obtenerCampus
  invocarMetodoObtenerCampus() {
    this.invocarObtenerCampus.emit();
  }

  ///Obtiene los campus que tienen programas activos
  public obtenerCampusconProgramaActivo(): Observable<ApiResponse<CampusProgramaDTO[]>> {
    return this.httpClient.get<ApiResponse<CampusProgramaDTO[]>>(`${environment.enves[baseUrl].apiUrl}/Campus/GetCampusProgramaActivo`);
  }
}
