import { Injectable, EventEmitter } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Letter } from '../_models/letter';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { environment, baseUrl} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartasService {
  invocarObtenerLetters = new EventEmitter();
  invMetodoObtenerLettersSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  public obtenerLetter(): Observable<ApiResponse<Letter[]>> {
    return this.httpClient.get<ApiResponse<Letter[]>>(`${environment.enves[baseUrl].apiUrl}/Letter`);
  }

  public registrarLetter(datosLetter): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Letter`, datosLetter);
  }

  public modificarLetter(datosLetter): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Letter`, datosLetter);
  }

  public eliminarLetter(letterId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Letter?letterId=` + letterId);
  }

  invocarMetodoObtenerCartas() {
    this.invocarObtenerLetters.emit();
  }
}
