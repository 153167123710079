export class Edificio {
    EdificioId?: number;
    CampusId?:number;
    Clave: string;
    Nombre: string;
    CampusNombre:string;
    FechaCreacion: Date;
    UsuarioCreacion: number;
    FechaModificacion: Date | null;
    UsuarioModificacion: number | null;
    tipoAccion?: number;
}

