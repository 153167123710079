import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-egreso-alumno',
  templateUrl: './egreso-alumno.component.html',
  styleUrls: ['./egreso-alumno.component.scss']
})
export class EgresoAlumnoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
