import { TraductorService } from './../../../_services/traductor.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { TramitesService } from './../../../_services/tramites.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TramitesBajaMateria } from 'src/app/_models/tramites-baja-materia';

@Component({
  selector: 'app-tramites-baja-materia',
  templateUrl: './tramites-baja-materia.component.html',
  styleUrls: ['./tramites-baja-materia.component.scss']
})
export class TramitesBajaMateriaComponent implements OnInit {

  public bajaMateriaForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<TramitesBajaMateriaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private tramitesService: TramitesService,
    private snackBar: SnackService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    this.incializarFormulario();
  }

  /**
   * Inicializa el formulario
   */
  public incializarFormulario(): void {
    this.bajaMateriaForm = this.formBuilder.group({
      estatusMateria: [0, [Validators.required, this.validateId]],
      motivoCambio: ['', [Validators.required]]
    });
  }

  /**
   * Envia la baja de la materia
   */
  public enviarBajaMateria(): void {
    let datos: TramitesBajaMateria = {
      alumnoId: this.data.alumnoId,
      grupoId: this.data.grupoId,
      estatusMateria: this.bajaMateriaForm.value.estatusMateria,
      motivoCambio: this.bajaMateriaForm.value.motivoCambio
    };
    this.tramitesService.bajaMateria(datos).subscribe((respose: ApiResponse<boolean>) => {
      if (respose.success) {
        this.tramitesService.obtenerEstadoCuenta();
        setTimeout(() => {
          let mensajeBajaCorrecto: string = this.traductorService.translate('_tramites.mensaje-baja');
          this.snackBar.mostrarSnackBack(mensajeBajaCorrecto);
          this.CerrarModal();
        }, 3000);
      } else {
        let mensajeErrorBaja: string = this.traductorService.translate(respose.message);
        this.snackBar.mostrarSnackBack(mensajeErrorBaja);
      }
    });
  }

  /**
   * Valida que el control no tenga valor 0
   * @param control 
   * @returns 
   */
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }


  /**
   * Metodo cerrar modal
   */
  public CerrarModal() {
    this.dialogRef.close();
  }

}
