import { Component, OnInit, ViewChild, Inject, TemplateRef, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable } from 'rxjs';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccionEnum } from '../../../_models/accion.enum';
import { ProfesorService } from '../../../_services/profesor.service';
import { FormCuentaRegistrarComponent } from './form-cuenta-registrar/form-cuenta-registrar.component';
import { RegistrarUsuarioService } from '../../../_services/registrar-usuario.service';
import { DatosBiograficosComponent } from './datos-biograficos/datos-biograficos.component';
import { DatosLaboralesComponent } from './datos-laborales/datos-laborales.component';
import { CargaDocumentosProfesorComponent } from './carga-documentos-profesor/carga-documentos-profesor.component';
import { BiografiaService } from '../../../_services/biografia.service';
import { DireccionService } from '../../../_services/direccion.service';
import { NuevoIngresoService } from '../../../_services/nuevo-ingreso.service';
import { EstudiosPrevios } from '../../../_models/estudios-previos';
import { DomSanitizer } from '@angular/platform-browser';
import { FormacionAcademicaProfesorComponent } from './formacion-academica-profesor/formacion-academica-profesor.component';
import { Profesor } from '../../../_models/profesor';
import { DatoFiscalService } from '../../../_services/dato-fiscal.service';
import { EstudiosPreviosService } from '../../../_services/estudios-previos.service';
import { DatosFamiliaresProfesorComponent } from './datos-familiares-profesor/datos-familiares-profesor.component';
import { PersonaRelacionada } from '../../../_models/persona-relacionada';
import { SnackService } from 'src/app/services/snack-service.service';
import { TraductorService } from 'src/app/_services/traductor.service';
import { TipoContratoService } from 'src/app/_services/tipo-contrato.service';
import { TipoContrato } from 'src/app/_models/tipo-contrato';

@Component({
  selector: 'app-oferta-profesor-busqueda-correo',
  templateUrl: './oferta-profesor-busqueda-correo.component.html',
  styleUrls: ['./oferta-profesor-busqueda-correo.component.scss']
})
export class OfertaProfesorBusquedaCorreoComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  @ViewChild(FormCuentaRegistrarComponent) solicitante: FormCuentaRegistrarComponent;
  @ViewChild(FormCuentaRegistrarComponent) cuentaProfesor: FormCuentaRegistrarComponent;
  @ViewChild(DatosBiograficosComponent) datosBiografiaProfesor: DatosBiograficosComponent;
  @ViewChild(DatosFamiliaresProfesorComponent) datosFamiliaresProfesor: DatosFamiliaresProfesorComponent;
  @ViewChild(DatosLaboralesComponent) datosLaboralesProfesor: DatosLaboralesComponent;
  @ViewChild(FormacionAcademicaProfesorComponent) formacionAcademicaProfesor: FormacionAcademicaProfesorComponent
  @ViewChild(CargaDocumentosProfesorComponent) datosDocumentosProfesor: CargaDocumentosProfesorComponent;
  @ViewChild('dialogoCerrarModal') dialogoCerrarModal: TemplateRef<any>;
  @ViewChild('profesorTabs', { static: false }) profesorTabs: MatTabGroup;
  public datosUsuarioBusqueda$: Observable<any>;
  public dialogoRefCerrarModal: any;
  public datosUsuarioBusqueda: any;
  public tabSeleccionadoIndex: FormControl = new FormControl(0);
  public tabSeleccionado: number = 0;
  public mensajeUsuarioEncontrado: boolean = false;
  public mensajeUsuarioNoEncontrado: boolean = false;
  public usuarioEncontrado: boolean = true;
  public ocultarNuevoUsuario: boolean = true;
  public correoBusqueda: string = '';
  public tabCambio: boolean = false;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public nombreUsuario: string = "";
  public busquedaCorreoForm: FormGroup;
  public formValidoNuevoProfesor$: Observable<boolean>;
  public formValidoNuevoProfesor: boolean = false;
  public formValidoProfesor: boolean = false;
  public datosNuevoUsuario: any;
  public tipoAccion: number;
  public ocultarBuscador: boolean = true;
  public solicitudEdicion: boolean = false;
  public datosUsuarioActual: any;
  public tipoAccionDireccion: number;
  public mensajeUsuarioEncontradoEditar: boolean = false;
  public imagenUsuarioForm: FormGroup;
  public imageUsuarioEditar: any;
  public imagePath: any;
  public imagePath2: any;
  public imagePath3;
  public imgURL: any;
  public message: string;
  public eventImage: any;
  public eventoImagenPreview: any;
  public boolImagen: boolean = false;
  public base64Image: any;
  public CorreoOcupado: boolean = false;
  public dialogoRefAdverCancelado: any;
  public dialogoRefAdverDatosIncompletos: any;
  public tipoUsuarioEncontrado: string = "";
  public reactivarProfesorId: any;
  public registrarProfesorBandera: boolean = false;
  public formValidoRegistroNuevoProfesor$: Observable<boolean>;
  public tabCambios: string = ""
  public tabCambiosContrato: string = "";
  public tabCambiosProfesor: string = "";
  public tabCambiosDocumentos: string = "";
  public tabCambiosAcademica: string = "";
  public tabCambiosFamiliares: string = "";
  public changesContrato$: Observable<boolean>;
  public changesProfesor$: Observable<boolean>;
  public changesDocumentos$: Observable<boolean>;
  public changesFormacionAcademica$: Observable<boolean>;
  public changesFamiliares$: Observable<boolean>;
  public changesContrato: boolean = false;
  public changesProfesor: boolean = false;
  public changesDocumentos: boolean = false;
  public changesFormacionAcademica: boolean = false;
  public changesFamiliares: boolean = false;
 //#region Observable
 private Subscription: Subscription = new Subscription();
 //#endregion
  constructor(public dialogRef: MatDialogRef<OfertaProfesorBusquedaCorreoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private profesorService: ProfesorService,
    private registrarUsuarioService: RegistrarUsuarioService,
    private biografiaService: BiografiaService,
    private direccionService: DireccionService,
    private nuevoIngresoService: NuevoIngresoService,
    private datoFiscalService: DatoFiscalService,
    private _cd: ChangeDetectorRef,
    private estudioPrevioService: EstudiosPreviosService,
    private sanitizer: DomSanitizer,
    private snackService: SnackService,
    private traductorService: TraductorService,
    private tipoContratoService: TipoContratoService
  ) { }

  ngOnInit(): void {
    this.nuevoIngresoService.buscarUsuarioSubscription = this.nuevoIngresoService.buscarUsuario.subscribe(() => {
      this.enviarBusquedaCorreo();
    });
    if (this.profesorService.updateTabsSubscription === undefined) {
      this.profesorService.updateTabsSubscription = this.profesorService.updateTabsEmitter.subscribe(() => {
        this.datosUsuarioBusqueda$ = this.profesorService.obtenerDatosUsuarioBusqueda$();
        this.datosUsuarioBusqueda$.subscribe(datos => {

          this.datosUsuarioBusqueda = datos
        });
        this.renderizarFormulariosTabs();
        this.datosFamiliaresProfesor?.renderizarFormulario();
        this.renderizarFoto();
      });
    }
    this.getTipoContrato();
    this.formValidoNuevoProfesor$ = this.profesorService.obtenerFormularioValidoNuevoProfesor$();
    this.formValidoNuevoProfesor$.subscribe(bool => this.formValidoProfesor = bool);
    this.datosUsuarioBusqueda$ = this.profesorService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
    this.formValidoRegistroNuevoProfesor$ = this.profesorService.obtenerFormularioValidoRegistroNuevoProfesor$();
    this.formValidoRegistroNuevoProfesor$.subscribe(bool => this.formValidoNuevoProfesor = bool);
    this.changesContrato$ = this.profesorService.getChangesContrato$();
    this.changesContrato$.subscribe(bool => { this.changesContrato = bool; });
    this.changesProfesor$ = this.profesorService.getChangesProfesor$();
    this.changesProfesor$.subscribe(bool => { this.changesProfesor = bool; });
    this.changesDocumentos$ = this.profesorService.getChangesDocumentos$();
    this.changesDocumentos$.subscribe(bool => { this.changesDocumentos = bool; });
    this.changesFormacionAcademica$ = this.profesorService.getChangesFormacionAcademica$();
    this.changesFormacionAcademica$.subscribe(bool => { this.changesFormacionAcademica = bool; });
    this.changesFamiliares$ = this.profesorService.getChangesFamiliares$();
    this.changesFamiliares$.subscribe(bool => { this.changesFamiliares = bool; });
    this.inicializarForm();
  }

  public inicializarForm() {
    this.busquedaCorreoForm = new FormGroup({
      correo: new FormControl('', [Validators.required, Validators.email])
    });
    this.busquedaCorreoForm.valueChanges.subscribe(val => {
      this.mensajeUsuarioEncontrado = false;
      this.mensajeUsuarioNoEncontrado = false;
      this.ocultarNuevoUsuario = true;
      this.usuarioEncontrado = true;
    });
  }

  ngAfterViewInit(): void {
    if (!this.data) {
      this.tipoAccion = AccionEnum.CREAR;
      this.ocultarNuevoUsuario = false;
      this.mensajeUsuarioNoEncontrado = true;
    } else {
      this.tipoAccion = AccionEnum.EDITAR;
      this.datosUsuarioActual = this.data;
      this.profesorService.definirDatosUsuarioBusqueda(this.data);
      this.usuarioEncontrado = false;
      this.profesorService.imagenUsuario(this.data.usuarioId).subscribe(
        datosUsuarioImagen => {
          if (datosUsuarioImagen) {
            this.base64Image = 'data:image/png;base64,' + datosUsuarioImagen.fotografia;
            this.imageUsuarioEditar = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
          }
        }
      );
      this.solicitudEdicion = true;
      this.registrarProfesorBandera = false;
      this.usuarioEncontrado = false;
      this.mensajeUsuarioEncontrado = true;
      this.renderizarFoto();
      if (this.datosUsuarioActual.solicitudCargaDoc) {
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(4);
        }, 500);
      }
      this.renderizarFormulariosTabs();
      if (this.datosUsuarioActual.solicitudCargaDoc) {
        this.profesorService.definirFormularioValidoNuevoProfesor(this.datosDocumentosProfesor.documentoForm.valid);
      } else {
        this.profesorService.definirFormularioValidoNuevoProfesor(this.datosLaboralesProfesor.laboralForm.valid);
      }
    }
  }
  get correo() { return this.busquedaCorreoForm.get('correo'); }

  public BanderaCorreo(event) {
    this.CorreoOcupado = event;
  }

  public enviarBusquedaCorreo() {
    this.correoBusqueda = this.solicitante.correo.value;
    if (this.correoBusqueda === '') {
      this.snackBar.open('Debes ingresar un correo', 'Ok', { duration: 5000 });
    } else {
      this.profesorService.obtenerUsuarioProfesor(this.correoBusqueda).subscribe(
        usuarioBusqueda => {
          if (usuarioBusqueda != null) {
            this.ocultarBuscador = true;
            this.profesorService.definirDatosUsuarioBusqueda(usuarioBusqueda);
            this.mensajeUsuarioEncontrado = true;
            this.usuarioEncontrado = false;
            this.renderizarFormulariosTabs();
            this.ocultarNuevoUsuario = true;
            this.mensajeUsuarioNoEncontrado = false;
            this.datosUsuarioActual = usuarioBusqueda;
            this.registrarProfesorBandera = false;
            this.base64Image = 'data:image/png;base64,' + usuarioBusqueda.fotografia;
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
            this.renderizarFoto();
            this.profesorService.definirFormularioValidoNuevoProfesor(this.datosLaboralesProfesor.laboralForm.valid);
            if (!usuarioBusqueda?.profesor?.esActivo) {
              this.nombreUsuario = usuarioBusqueda?.nombre + " " + usuarioBusqueda?.apellidoPaterno + " " + usuarioBusqueda?.apellidoMaterno;
              this.tipoUsuarioEncontrado = "La cuenta de correo pertenece a un profesor con el nombre de: " + this.nombreUsuario + " con estatus cancelado. ";
              let periodoIngreso = usuarioBusqueda?.profesor?.periodoIngreso;
              let tipoContratoId = usuarioBusqueda?.profesor?.tipoContratoId;
              let cuentaDeposito = usuarioBusqueda?.profesor?.cuentaDeposito;
              let departamentoId = usuarioBusqueda?.profesor?.departamentoId;
              if (periodoIngreso != null && tipoContratoId != null && cuentaDeposito != null && departamentoId != null) {
                let mensaje1: string = this.traductorService.translate('_profesores.modal.cancelado');
                let mensaje2: string = this.traductorService.translate('_profesores.modal.reactivar');
                this.snackService.mostrarSnackBack(`${mensaje1} ${this.nombreUsuario}, ${mensaje2}`);
              } else {
                let mensaje1: string = this.traductorService.translate('_profesores.modal.cancelado');
                let mensaje2: string = this.traductorService.translate('_profesores.modal.reactivar');
                this.snackService.mostrarSnackBack(`${mensaje1} ${this.nombreUsuario}, ${mensaje2}`);
              }
            }
          } else {
            this.profesorService.definirDatosUsuarioBusqueda(usuarioBusqueda);
            this.ocultarNuevoUsuario = false;
            this.cuentaProfesor.recibirInfo(this.busquedaCorreoForm.get('correo').value);
            this.mensajeUsuarioNoEncontrado = true;
            this.registrarProfesorBandera = true;
            this.ocultarBuscador = true;
            this.snackBar.open("No se encontró a un usuario registrado. Por favor llene el formulario para registrar al profesor. ", "OK", { duration: 4000 });

          }
        }
      );
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      if (this.tabCambio) {
      } else {
        this.dialogoRefAdver.close();
        this.dialogRef.close(this.data);
      }
    } else {
      if (this.tabCambio) {
      } else {
        this.dialogoRefAdver.close();
      }
    }
  }

  public cerrarModal() {
    if (this.changesContrato || this.changesProfesor || this.changesDocumentos || this.changesFormacionAcademica || this.changesFamiliares) {
      if (this.changesContrato) {
        this.tabCambiosContrato = " - Datos de Contrato";
      } else {
        this.tabCambiosContrato = "";
      }
      if (this.changesProfesor) {
        this.tabCambiosProfesor = " - Profesor";
      } else {
        this.tabCambiosProfesor = "";
      }
      if (this.changesFamiliares) {
        this.tabCambiosFamiliares = " - Familiares";
      } else {
        this.tabCambiosFamiliares = "";
      }
      if (this.changesFormacionAcademica) {
        this.tabCambiosAcademica = " - Formación Académica";
      } else {
        this.tabCambiosAcademica = "";
      }
      if (this.changesDocumentos) {
        this.tabCambiosDocumentos = " - Documentos";
      } else {
        this.tabCambiosDocumentos = "";
      }
      this.dialogoRefCerrarModal = this.dialog.open(this.dialogoCerrarModal, { disableClose: true });
    } else {
      this.dialogRef.close(this.data);
    }
  }

  public enviarRegistroUsuario() {
    this.datosNuevoUsuario = Object.assign(this.cuentaProfesor.profesorForm.value);
    let registro: any = {
      usuario: {
        correo: this.datosNuevoUsuario.correo,
        nombre: this.datosNuevoUsuario.nombre,
        segundoNombre: this.datosNuevoUsuario.segundoNombre,
        apellidoPaterno: this.datosNuevoUsuario.apellidoPaterno,
        apellidoMaterno: this.datosNuevoUsuario.apellidoMaterno,
        password: this.datosNuevoUsuario.password
      },
      biografia: {
        fechaNacimiento: this.datosNuevoUsuario.fechaNacimiento
      }
    };
    if (this.datosNuevoUsuario.password === this.datosNuevoUsuario.passwordConfirm) {
      this.profesorService.obtenerCorreoOcupado(this.datosNuevoUsuario.correo, 0).subscribe(correoOcupado => {
        if (correoOcupado) {
          this.snackBar.open("El correo ya pertenece a un usuario.", "OK", { duration: 5000 });
        } else {
          this.registrarUsuarioService.registrarUsuarioProfesor(this.datosNuevoUsuario.correo,
            this.datosNuevoUsuario.nombre,
            this.datosNuevoUsuario.segundoNombre,
            this.datosNuevoUsuario.apellidoPaterno,
            this.datosNuevoUsuario.apellidoMaterno,
            this.datosNuevoUsuario.fechaNacimiento,
            this.datosNuevoUsuario.password,
            1,
            1).subscribe(
              usuarioGuardado => {
                if (usuarioGuardado) {
                  this.snackBar.open("Se ha guardado la información del usuario.", "OK", { duration: 5000 });
                  this.obtenerInfoUsuario(this.datosNuevoUsuario.correo);
                  this.registrarProfesorBandera = false;
                  this.mensajeUsuarioEncontrado = true;
                  this.mensajeUsuarioNoEncontrado = false;
                  this.profesorService.invocarMetodoObtenerProfesor();
                  //this.profesorService.updateTabs();
                  this.ngOnInit();
                  this.profesorService.cleanFiltros();
                } else {
                  this.snackBar.open("Ha ocurrido un error al guardar el usuario. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                }
              });
        }
      })
    } else {
      this.snackBar.open("Las contraseñas deben de ser iguales.", "OK", { duration: 5000 });
    }
  }

  public tipoContratoList: TipoContrato[] = [];
  public nameContrato: string = '';

  public getTipoContrato() {
    this.tipoContratoService.obtenerTipoContrato().subscribe(
      (tipoContrato: TipoContrato[]) => {
        this.tipoContratoList = tipoContrato;
      }
    );
  }

  public nombreContrato(idContrato) {
    this.nameContrato = this.tipoContratoList.find(id => id.tipoContratoId === idContrato).nombre;
    this.datosUsuarioBusqueda.profesor.tipoContrato.nombre = this.nameContrato;
    this.datosUsuarioBusqueda.profesor.tipoContrato.tipoContratoId = idContrato;
  }

  public enviarFormulario() {
    if (this.tabSeleccionado === 0) {
      let datoFiscalForm = Object.assign(this.datosLaboralesProfesor.laboralForm.value);
      let datosFiscales: any = {
        usuarioId: datoFiscalForm.usuarioID,
        rfc: datoFiscalForm.rfc,
        nombre: datoFiscalForm.nombre,
        apellidoPaterno: datoFiscalForm.apellidoPaterno,
        apellidoMaterno: datoFiscalForm.apellidoMaterno,
      }
      this.profesorService.registrarDatosFiscales(datosFiscales).subscribe(
        datoFiscal => {
          if (datoFiscal) {
            let laboralForm = Object.assign(this.datosLaboralesProfesor.laboralForm.value);
            let datosLaborales: any = {
              usuarioId: laboralForm.usuarioID,
              esActivo: laboralForm.esActivo,
              tipoContratoId: laboralForm.TipoContratoId,
              departamentoId: laboralForm.DepartamentoId,
              cuentaDeposito: laboralForm.CuentaDeposito
            };
            this.profesorService.registrarDatosLaborales(datosLaborales).subscribe(
              datoLaboral => {
                if (datoLaboral) {
                  this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
                  this.changesContrato = false;
                  //this.nombreContrato(datosLaborales.tipoContratoId);
                  this.profesorService.invocarMetodoObtenerProfesor();
                  this.ngOnInit();
                  this.profesorService.updateTabs();
                  this.obtenerInfoUsuario(this.data.correo);
                } else {
                  this.snackBar.open("Ha ocurrido un error al guardar. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                }
              }
            );
          } else {
            this.snackBar.open("Ha ocurrido un error al guardar. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        }
      )
    }
    if (this.tabSeleccionado === 1) {
      let datosPersonalesForm = Object.assign(this.datosBiografiaProfesor.biografiaForm.value);
      let datosPersonales: any = {
        usuarioId: datosPersonalesForm.usuarioId,
        nombre: datosPersonalesForm.nombre,
        segundoNombre: datosPersonalesForm.segundoNombre,
        apellidoPaterno: datosPersonalesForm.apellidoPaterno,
        apellidoMaterno: datosPersonalesForm.apellidoMaterno,
        correo: datosPersonalesForm.correo,
        biografia: {
          fechaNacimiento: datosPersonalesForm.fechaNacimiento
        }
      }
      this.profesorService.obtenerCorreoOcupado(datosPersonalesForm.correo, datosPersonalesForm.usuarioId).subscribe(ocupado => {
        if (ocupado) {
          this.snackBar.open('Ha ocurrido un error al guardar los datos personales.El correo ya se encuentra ocupado.', 'OK', { duration: 5000 });
        } else {
          this.nuevoIngresoService.editarDatosPersonales(datosPersonales).subscribe(
            datosPersonales => {
              if (datosPersonales) {
                let datosContactoForm = Object.assign(this.datosBiografiaProfesor.biografiaForm.value);
                let datosContacto: any = {
                  usuarioId: datosPersonalesForm.usuarioId,
                  direccion: [
                    {
                      tipoDireccionId: datosPersonalesForm.tipoDireccionId,
                      paisId: datosPersonalesForm.paisContacto,
                      entidadFederativaId: datosPersonalesForm.entidadFederativaId,
                      calle: datosPersonalesForm.calle,
                      ciudad: datosPersonalesForm.ciudad,
                      numeroExterior: datosPersonalesForm.numeroExterior,
                      numeroInterior: datosPersonalesForm.numeroInterior,
                      colonia: datosPersonalesForm.colonia,
                      codigoPostal: datosPersonalesForm.codigoPostal
                    }
                  ],
                  telefono: [
                    {
                      tipoTelefonoId: datosContactoForm.tipoTelefonoId,
                      paisId: datosContactoForm.paisContacto,
                      numero: datosContactoForm.numero,
                      extension: datosContactoForm.extension
                    }
                  ]
                }//tipoDireccionId
                this.direccionService.registrarDatosContacto(datosContacto).subscribe(datoContacto => {
                  if (datoContacto) {
                    let datosBiografiaForm = Object.assign(this.datosBiografiaProfesor.biografiaForm.value);
                    let datosBiografia: any = {
                      UsuarioId: datosBiografiaForm.usuarioId,
                      LegalGeneroId: datosBiografiaForm.legalGeneroId,
                      GeneroId: datosBiografiaForm.generoId,
                      CiudadaniaPaisId: datosBiografiaForm.ciudadaniaPaisId,
                      EstadoCivilId: datosBiografiaForm.estadoCivilId,
                      Curp: datosBiografiaForm.curp,
                      Nss: datosBiografiaForm.nss,
                      EntidadNacimiento: datosBiografiaForm.entidadNacimiento,
                      CiudadNacimiento: datosBiografiaForm.ciudadNacimiento,
                      Alergias: datosBiografiaForm.alergias,
                      GrupoSanguineoId: datosBiografiaForm.grupoSanguineoId,
                      Enfermedades: datosBiografiaForm.enfermedades
                    }
                    this.biografiaService.obtenerBiografiaByCurp(datosBiografia.Curp, datosBiografia.UsuarioId).subscribe(biografia => {
                      if (biografia == null) {
                        this.biografiaService.obtenerBiografiaByNss(datosBiografia.Nss, datosBiografia.UsuarioId).subscribe(
                          biografia => {
                            if (biografia == null) {
                              this.biografiaService.modificarBiografia(datosBiografia).subscribe(datoBiografia => {
                                if (datoBiografia) {
                                  // this.snackBar.open("Se ha guardado los datos de biografia.", "OK", { duration: 5000 });
                                  // this.snackBar.open("Se ha guardado los datos Familiares.", "OK", { duration: 5000 });
                                  this.changesProfesor = false;
                                  this.profesorService.invocarMetodoObtenerProfesor();
                                  this.profesorService.cleanFiltros();
                                  this.obtenerInfoUsuario(this.data.correo);
                                  this.snackBar.open("La información ha sido guardada correctamente.", "OK", { duration: 5000 });
                                  this.ngOnInit();
                                } else {
                                  this.snackBar.open("Ha ocurrido un error al guardar los datos de biografia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                                }
                              })
                            } else {
                              this.snackBar.open('Ha ocurrido un error al guardar los datos biograficos.El NSS ya le pertenece a un usuario.', 'OK', { duration: 5000 });
                            }
                          });
                      } else {
                        this.snackBar.open('Ha ocurrido un error al guardar los datos biograficos.El CURP ya le pertenece a un usuario.', 'OK', { duration: 5000 });
                      }
                    })
                  } else {
                    this.snackBar.open("Ha ocurrido un error al guardar los datos de contacto. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                  }
                })
              } else {
                this.snackBar.open("Ha ocurrido un error al guardar los datos personales. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
              }
            }
          )
        }
      })
    }
    if (this.tabSeleccionado === 2) {
      let datosFamiliaresForm = Object.assign(this.datosFamiliaresProfesor.familiarForm.value);
      let datosFamiliares: any[] = [];
      let datosFamiliaP: any = {
        UsuarioId: datosFamiliaresForm.usuarioId,
        TipoRelacionId: 1,
        NombreCompleto: datosFamiliaresForm.nombreCompletoPadre,
        Telefono: datosFamiliaresForm.telefonoPadre,
        CorreoElectronico: datosFamiliaresForm.correoElectronicoPadre
      }
      datosFamiliares.push(datosFamiliaP);
      let datosFamiliaM: any = {
        UsuarioId: datosFamiliaresForm.usuarioId,
        TipoRelacionId: 2,
        NombreCompleto: datosFamiliaresForm.nombreCompletoMadre,
        Telefono: datosFamiliaresForm.telefonoMadre,
        CorreoElectronico: datosFamiliaresForm.correoElectronicoMadre,
      }
      datosFamiliares.push(datosFamiliaM);
      let datosFamiliaC: any = {
        UsuarioId: datosFamiliaresForm.usuarioId,
        TipoRelacionId: 5,
        NombreCompleto: datosFamiliaresForm.nombreCompletoConyuge,
        Telefono: datosFamiliaresForm.telefonoConyuge,
        CorreoElectronico: datosFamiliaresForm.correoElectronicoConyuge
      }
      datosFamiliares.push(datosFamiliaC);
      this.profesorService.registrarDatosFamilia(datosFamiliares).subscribe(datoFamiliares => {
        if (datoFamiliares) {
          let datosEmergenciaForm = Object.assign(this.datosFamiliaresProfesor.familiarForm.value);
          let datosEmergencia: PersonaRelacionada;
          if (datosEmergenciaForm.estatusPadre == true) {
            let datoEmergencia: any = {
              UsuarioId: datosEmergenciaForm.usuarioId,
              TipoRelacionId: 4,
              NombreCompleto: datosEmergenciaForm.nombreCompletoPadre,
              Telefono: datosEmergenciaForm.telefonoPadre,
              CorreoElectronico: datosEmergenciaForm.correoElectronicoPadre
            }
            datosEmergencia = datoEmergencia;
          } else if (datosEmergenciaForm.estatusMadre == true) {
            let datoEmergencia: any = {
              UsuarioId: datosEmergenciaForm.usuarioId,
              TipoRelacionId: 4,
              NombreCompleto: datosEmergenciaForm.nombreCompletoMadre,
              Telefono: datosEmergenciaForm.telefonoMadre,
              CorreoElectronico: datosEmergenciaForm.correoElectronicoMadre
            }
            datosEmergencia = datoEmergencia;
          } else if (datosEmergenciaForm.estatusConyuge == true) {
            let datoEmergencia: any = {
              UsuarioId: datosEmergenciaForm.usuarioId,
              TipoRelacionId: 4,
              NombreCompleto: datosEmergenciaForm.nombreCompletoConyuge,
              Telefono: datosEmergenciaForm.telefonoConyuge,
              CorreoElectronico: datosEmergenciaForm.correoElectronicoConyuge
            }
            datosEmergencia = datoEmergencia;
          } else {
            let datoEmergencia: any = {
              UsuarioId: datosEmergenciaForm.usuarioId,
              TipoRelacionId: 4,
              NombreCompleto: datosEmergenciaForm.nombreContacto,
              Telefono: datosEmergenciaForm.telefonoContacto,
              CorreoElectronico: datosEmergenciaForm.correoContacto
            }
            datosEmergencia = datoEmergencia;
          }
          this.profesorService.registrarDatosEmergencia(datosEmergencia).subscribe(
            datoEmergencia => {
              if (datoEmergencia) {
                this.changesFamiliares = false;
                this.profesorService.invocarMetodoObtenerProfesor();
                this.profesorService.cleanFiltros();
                this.snackBar.open("La información ha sido guardada correctamente.", "OK", { duration: 5000 });
                this.ngOnInit();
              } else {
                this.snackBar.open("Ha ocurrido un error al guardar los datos de Emergencia. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
              }
            }
          )
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar los datos Familiares. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      });
    }
    if (this.tabSeleccionado === 3) {
      let datosEstudiosPreviosForm = Object.assign(this.formacionAcademicaProfesor.formacionForm.value);
      let datosEstudiosPrevios: any[] = [];
      if (datosEstudiosPreviosForm.institucionLic != '') {
        let datosEstudios: any = {
          UsuarioId: datosEstudiosPreviosForm.usuarioId,
          NivelId: 5,
          Escuela: datosEstudiosPreviosForm.institucionLic,
          Programa: datosEstudiosPreviosForm.programaLic,
          Promedio: datosEstudiosPreviosForm.promedioLic
        }
        datosEstudiosPrevios.push(datosEstudios);
      }
      if (datosEstudiosPreviosForm.institucionMaestria != '') {
        let datosEstudios: any = {
          UsuarioId: datosEstudiosPreviosForm.usuarioId,
          NivelId: 3,
          Escuela: datosEstudiosPreviosForm.institucionMaestria,
          Programa: datosEstudiosPreviosForm.programaMaestria,
          Promedio: datosEstudiosPreviosForm.promedioMaestria
        }
        datosEstudiosPrevios.push(datosEstudios);
      }
      if (datosEstudiosPreviosForm.institucionDoctorado != '') {
        let datosEstudios: any = {
          UsuarioId: datosEstudiosPreviosForm.usuarioId,
          NivelId: 4,
          Escuela: datosEstudiosPreviosForm.institucionDoctorado,
          Programa: datosEstudiosPreviosForm.programaDoctorado,
          Promedio: datosEstudiosPreviosForm.promedioDoctorado
        }
        datosEstudiosPrevios.push(datosEstudios);
      }
      this.estudioPrevioService.registrarEstudiosPrevios(datosEstudiosPrevios).subscribe(
        datoEstudioPrevio => {
          if (datoEstudioPrevio) {
            this.changesFormacionAcademica = false;
            this.profesorService.invocarMetodoObtenerProfesor();
            this.profesorService.cleanFiltros();
            this.snackBar.open("La información ha sido guardada correctamente.", "OK", { duration: 5000 });
            this.ngOnInit();
          } else {
            this.snackBar.open("Ha ocurrido un error al guardar la formación académica. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        }
      )
    }
    if (this.tabSeleccionado === 4) {
      let cargaDocumentosForm = Object.assign(this.datosDocumentosProfesor.documentoForm.value);
      if (cargaDocumentosForm.comprobante == '' && cargaDocumentosForm.ine == '' && cargaDocumentosForm.estudios == '') {
        this.snackBar.open('Debe cargar un archivo para poder guardarlo.', 'OK', { duration: 5000 })
      } else {
        let documentos: any[] = [];
        if (cargaDocumentosForm.comprobante != '') {
          let documento: any = {
            usuarioId: cargaDocumentosForm.usuarioId,
            documentoId: cargaDocumentosForm.documentoIdComprobanteIdentidad,
            nombreOriginal: cargaDocumentosForm.nombreOriginalComprobanteIdentidad,
            nombreFisico: cargaDocumentosForm.nombreFisicoComprobanteIdentidad,
            estatusDocumentoId: cargaDocumentosForm.estatusDocumentoIdComprobanteIdentidad,
          }
          documentos.push(documento);
        }

        if (cargaDocumentosForm.ine != '') {
          let documento: any = {
            usuarioId: cargaDocumentosForm.usuarioId,
            documentoId: cargaDocumentosForm.documentoIdArchivoIne,
            nombreOriginal: cargaDocumentosForm.nombreOriginalArchivoIne,
            nombreFisico: cargaDocumentosForm.nombreFisicoArchivoIne,
            estatusDocumentoId: cargaDocumentosForm.estatusDocumentoIdArchivoIne,
          }
          documentos.push(documento);
        }
        if (cargaDocumentosForm.estudios != '') {
          let documento: any = {
            usuarioId: cargaDocumentosForm.usuarioId,
            documentoId: cargaDocumentosForm.documentoIdArchivoEstudios,
            nombreOriginal: cargaDocumentosForm.nombreOriginalArchivoEstudios,
            nombreFisico: cargaDocumentosForm.nombreFisicoArchivoEstudios,
            estatusDocumentoId: cargaDocumentosForm.estatusDocumentoArchivoEstudios,
          }
          documentos.push(documento);
        }
        this.profesorService.registrarDocumentos(documentos).subscribe(
          cargaDocumentos => {
            if (cargaDocumentos) {
              this.changesDocumentos = false;
              this.profesorService.invocarMetodoObtenerProfesor();
              this.profesorService.updateTabs();
              this.profesorService.cleanFiltros();
              let mensaje: string = this.traductorService.translate('guardadoDocumentos');
              this.snackService.mostrarSnackBack(mensaje);
              this.ngOnInit();
            } else {
              this.snackBar.open("Ha ocurrido un error al guardar los documentos. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
            }
          }
        );
      }

    }
  }

  public obtenerInfoUsuario(correo: string) {
    this.profesorService.obtenerUsuarioProfesor(correo).subscribe(
      usuarioBusuqueda => {
        if (usuarioBusuqueda != null) {
          this.profesorService.definirDatosUsuarioBusqueda(usuarioBusuqueda);
          this.usuarioEncontrado = false;
          this.ocultarNuevoUsuario = true;
          this.renderizarFormulariosTabs();
          this.datosUsuarioActual = usuarioBusuqueda;
          this.datosUsuarioBusqueda = usuarioBusuqueda;
          this.renderizarFoto();
        } else {

        }
      }
    );
  }

  public renderizarFormulariosTabs() {
    this.datosLaboralesProfesor?.renderizarFormulario();
    this.datosBiografiaProfesor?.renderizarFormulario();
    this.datosDocumentosProfesor?.renderizarFormulario();
    this.formacionAcademicaProfesor?.renderizarFormulario();
    this.datosFamiliaresProfesor?.renderizarFormulario();
  }

  preview(event) {
    if (event.length === 0) {
      return;
    }
    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    let reader: FileReader = new FileReader();
    this.imagePath3 = event;
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.eventImage = event;
  }

  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.onload = () => {
        this.datosUsuarioBusqueda.fotografia = reader.result;
        this._cd.markForCheck();
        this.imagenUsuarioForm.patchValue({
          fotografia: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  }

  public imagenModal() {
    if (!this.boolImagen) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  public cerrarModalImagen(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      //this.dialogRef.close();
    } else {
      this.dialogoRefAdver.close();
      this.onFileChangePerfil(this.eventImage);
      this.enviarImagen();
      this.imgURL = "";
    }
  }

  public onFileChangePerfil(event) {
    const reader = new FileReader();
    if (event && event.length > 0) {
      this.imagePath2 = event;
      this.imagePath = event;
      const [file] = event;

      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.readAsDataURL(event[0]);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
        this.imagePath2 = reader.result;
      }
      this.eventImage = event;
    }
  }

  public renderizarFoto() {
    this.imagenUsuarioForm = new FormGroup
      ({
        usuarioId: new FormControl(this.datosUsuarioBusqueda?.usuarioId),
        fotografia: new FormControl(''),
        fotografiaFile: new FormControl(''),
      });
  }

  public enviarImagen() {
    let imagenForm = Object.assign(this.imagenUsuarioForm.value);
    let imagen: any = {
      usuarioId: imagenForm.usuarioId,
      fotografia: imagenForm.fotografia,
      fotografiaFile: imagenForm.fotografiaFile,
    }
    this.profesorService.editarImagen(imagen).subscribe(
      imagenData => {
        if (imagenData) {
          this.snackBar.open("Se ha guardado la imagen correctamente.", "OK", { duration: 5000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar la imagen. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      }
    )
  }

  public onChangeTab(event: MatTabChangeEvent) {
    this.tabCambio = true;
    let tab: number = event.index;
    this.tabSeleccionado = tab;
    switch (this.tabSeleccionado) {
      case 0: {
        this.tabCambios = 'Datos de Contrato';
        this.profesorService.definirFormularioValidoNuevoProfesor(this.datosLaboralesProfesor.laboralForm.valid);
        break;
      }
      case 1: {
        this.tabCambios = 'Profesor';
        this.profesorService.definirFormularioValidoNuevoProfesor(this.datosBiografiaProfesor.biografiaForm.valid);
        break;
      }
      case 2: {
        this.profesorService.definirFormularioValidoNuevoProfesor(this.datosFamiliaresProfesor.familiarForm.valid);
        break;
      }
      case 3: {
        this.tabCambios = 'Formación Académica';
        this.profesorService.definirFormularioValidoNuevoProfesor(this.formacionAcademicaProfesor.formacionForm.valid);
        break;
      }
      case 4: {
        this.tabCambios = 'Documentos';
        this.profesorService.definirFormularioValidoNuevoProfesor(this.datosDocumentosProfesor.documentoForm.valid);
        break;
      }
      default: {
        break;
      }
    }
  }

  public reactivarProfesor() {
    this.reactivarProfesorId = this.datosUsuarioBusqueda.usuarioId;
    this.profesorService.reactivarProfesorByUsuarioID(this.reactivarProfesorId).subscribe(
      reactivarProfesor => {
        if (reactivarProfesor) {
          this.profesorService.invocarMetodoObtenerProfesor();
          this.profesorService.cleanFiltros();
          this.snackBar.open('Se ha reactivado la cuenta.', 'OK', { duration: 5000 });

        } else {
          this.snackBar.open('El profesor no pudo ser eliminado porque pertenece a un grupo.', "OK", { duration: 5000 });
        }
      },
      error => {
        this.snackBar.open('Ha ocurrido un error al reactivar la cuenta. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    );
  }

  public cerrarModalAdvertenciaCancelarCambios(descartar: boolean) {
    if (descartar) {
      this.dialogoRefCerrarModal.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefCerrarModal.close();
    }
  }

  //#region obtenerProfesoresByUsuario
  /* public obtenerProfesor() {
    this.Subscription.add(
      this.profesorService.obtenerProfesoresByUsuario(this.data.usuarioId).subscribe(profesor => {
        if (profesor) {
          this.datosUsuarioBusqueda = profesor;
        }
      })
    );
  } */
  //#endregion
  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
    if (this.nuevoIngresoService.buscarUsuarioSubscription != undefined) {
      this.nuevoIngresoService.buscarUsuarioSubscription.unsubscribe();
    }
  }

}

