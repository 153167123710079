import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Campus, InfoPrograma, Nivel, Periodo } from 'src/app/_models';
import { CampusService, NivelService, PeriodoService } from 'src/app/_services';
import { InfoProgramaService } from 'src/app/_services/infoPrograma.service';
import { StudentTypeService } from 'src/app/_services/student-type.service';

@Component({
  selector: 'app-academic-infoCamp-tab',
  templateUrl: './academic-information-tab.component.html',
  styleUrls: ['./academic-information-tab.component.scss'],
})
export class AcademicInformationTabComponent implements OnInit {
  public acaInfoForm: FormGroup;

  constructor(
    private periodoService: PeriodoService,
    private campusService: CampusService,
    private nivelservice: NivelService,
    private programaservice: InfoProgramaService,
    private sTypeservice: StudentTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allNivel') private allNivel: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;

  public campusList: Campus[] = [];
  public periodoList: Periodo[] = [];
  public nivelList: Nivel[] = [];
  public programaList: InfoPrograma[] = [];
  public studentTList: any;
  periodoF = new FormControl();
  campusF = new FormControl();
  nivelF = new FormControl();
  programaF = new FormControl();

  ngOnInit(): void {
    console.log('ini');
    this.obtenerPeriodo();
    this.obtenerCampus();
    this.obtenerProgramas();
    this.obtenerNiveles();
    this.getSTypes();
    this.inicializarForm();
  }

  public inicializarForm() {
    this.acaInfoForm = this.fb.group({
      campaignTermIds: this.periodoF,
      campaignCampusIds: this.campusF,
      campaignLevelIds: this.nivelF,
      campaignProgramIds: this.programaF,
      campaignStudentTypeId: this.data ? this.data.studentTypeId : '',
      leadTarget: this.data ? this.data.leadsmeta : '',
      conversionTarget: this.data ? this.data.conversionmeta : '',
      expenseBudget: this.data ? this.data.expenseBudget : '',
      expectedIncome: this.data ? this.data.expectedIncome : '',
    });
    this.periodoF.setValue['-1'];
    this.campusF.setValue['-1'];
    this.nivelF.setValue['-1']; // No se registra, no hay campo en DB
    this.programaF.setValue['-1'];
  }

  public obtenerPeriodo(): void {
    this.periodoService.obtenerPeriodos().subscribe((periodo: Periodo[]) => {
      this.periodoList = periodo;
      if (this.data && this.data.leadInterestTerm) {
        this.periodoF.setValue([...this.data.leadInterestTerm.map((item) => item.termId)]);
      }
    });
  }

  public obtenerCampus(): void {
    this.campusService.obtenerCampus().subscribe((campus: Campus[]) => {
      this.campusList = campus;
      if (this.data && this.data.leadInterestCampus) {
        this.campusF.setValue([...this.data.leadInterestCampus.map((item) => item.campusId)]);
      }
    });
  }

  public obtenerNiveles(): void {
    this.nivelservice.getNivel().subscribe((nivel: Nivel[]) => {
      this.nivelList = nivel;
      if (this.data && this.data.leadInterestLevel) {
        this.nivelF.setValue([...this.data.leadInterestLevel.map((item) => item.levelId)]);
      }
    });
  }

  public obtenerProgramas(): void {
    this.programaservice.obtenerInfoPrograma().subscribe((programas: InfoPrograma[]) => {
      this.programaList = programas;
      if (this.data && this.data.leadInterestProgram) {
        this.programaF.setValue([...this.data.leadInterestProgram.map((item) => item.programId)]);
      }
    });
  }

  public async getSTypes(): Promise<void> {
    if (this.data && this.data.stype) {
      this.studentTList = this.data.stype;
    } //if (this.studentTList.length == 0)
    else {
      let types = await this.sTypeservice.getStudentTypes().toPromise();
      if (types.data) {
        console.log('student types', types);
        this.studentTList = types.data;
      }
    }
  }

  //#region  Funciones checkbox
  toggleAllSelectionPeriodo() {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.periodoList.map((item) => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
  }

  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      //this.campusF.patchValue([...this.campusList.map(({ campusId }) => ({ campusId })), { campusId: 0 }]);
      this.campusF.patchValue([...this.campusList.map((item) => item.campusId), 0]);
    } else {
      this.campusF.patchValue([]);
    }
  }

  toggleAllSelectionNivel() {
    if (this.allNivel.selected) {
      this.nivelF.patchValue([...this.nivelList.map((item) => item.nivelId), 0]);
    } else {
      this.nivelF.patchValue([]);
    }
  }

  toggleAllSelectionPrograma() {
    if (this.allPrograma.selected) {
      this.programaF.patchValue([...this.programaList.map((item) => item.programaId), 0]);
    } else {
      this.programaF.patchValue([]);
    }
  }

  tosslePerOnePeriodo() {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if (this.periodoF.value.length == this.periodoList.length) {
      this.allPeriodo.select();
    }
  }

  tosslePerOneCampus() {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.campusF.value.length == this.campusList.length) {
      this.allCampus.select();
    }
  }

  tosslePerOneNivel() {
    if (this.allNivel.selected) {
      this.allNivel.deselect();
      return false;
    }
    if (this.nivelF.value.length == this.nivelList.length) {
      this.allNivel.select();
    }
  }

  tosslePerOnePrograma() {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.programaF.value.length == this.programaList.length) {
      this.allPrograma.select();
    }
  }

  //#endregion
}
