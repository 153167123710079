import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnInit, TemplateRef, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { RequestTypeDTO, RequestCategoryDTO, SolicitudAdminDto } from 'src/app/_models/solicitud-dto';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { EnviarCorreoComponent } from 'src/app/_components/enviar-correo/enviar-correo.component';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-solicitud-seguimiento-modal',
  templateUrl: './solicitud-seguimiento-modal.component.html',
  styleUrls: ['./solicitud-seguimiento-modal.component.scss']
})
export class SolicitudSeguimientoModalComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public dialogoRefAdver: any;
  public boolCambio = false;
  public edicionForm: FormGroup;
  public tipoRequestList: RequestTypeDTO[] = [];
  public tipoRequest: RequestTypeDTO;
  public categoryRequestList: RequestCategoryDTO[] = [];
  public base64Image: string;
  public imagePath: SafeResourceUrl;
  public datosUsuario: any;
  public estatusF: any[] = [{value: 1, nombre: 'Recibido'}, {value: 2, nombre: 'En proceso'}, {value: 3, nombre: 'Terminada'}];
  public numDocumentoSeleccionado = 0;
  public infoCargado: boolean = false;
  displayedColumns: string[] = ['select', 'nombre', 'fecha'];
  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(false, []);
  public documentos: any = [];
  public date: Date = new Date();
  @ViewChild('modalVisorPDF') modalVisorPDF: TemplateRef<any>;
  public modalVisorPDFRef: any;
  public pdf: any;
  public mime: string;
  public imagen: string;
  
  constructor(public dialogRef: MatDialogRef<SolicitudSeguimientoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SolicitudAdminDto,
    private solicitudService: SolicitudService,
    private snackService: SnackService,
    private dialog: MatDialog,
    private nuevoIngresoService: NuevoIngresoService,
    private sanitizer: DomSanitizer,
    public util: UtileriasService,
    private traductorService: TraductorService) {
      this.inicializarForm();
      this.cargarImagen();
    }

  ngOnInit(): void {
    this.obtenerTiposSolicitud();
    this.obtenerCategorySolicitud();
    this.datosUsuario = this.data;
    if(this.data.isLetterCategory && !this.data.directDownload) {
      this.obtenerDocumentos();
    }
  }

  public cargarImagen(): void {
    this.nuevoIngresoService.imagenUsuario(this.data.usuarioId).subscribe(
      (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) =>{
        if (datosUsuarioImagen.success) {
          this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
        }
    });
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      code: new FormControl(this.data?.code),
      requestId: new FormControl(this.data?.requestId),
      requestCategoryId: new FormControl(this.data?.requestCategoryId),
      requestTypeId: new FormControl(this.data?.requestTypeId),
      userId: new FormControl(this.data?.usuarioId),
      name: new FormControl(this.data?.nombre),
      comment: new FormControl(this.data?.comment),
      completed: new FormControl(this.data?.completed),
      institutionResponse: new FormControl(this.data?.institutionResponse)
    });
    this.edicionForm.valueChanges.subscribe((cambios) => {
      this.boolCambio = true;
    });
  }

  get code() { return this.edicionForm.get('code'); }
  get requestId() { return this.edicionForm.get('requestId'); }
  get requestCategoryId() { return this.edicionForm.get('requestCategoryId'); }
  get requestTypeId() { return this.edicionForm.get('requestTypeId'); }
  get userId() { return this.edicionForm.get('userId'); }
  get name() { return this.edicionForm.get('name'); }
  get comment() { return this.edicionForm.get('comment'); }
  get completed() { return this.edicionForm.get('completed'); }
  get institutionResponse() { return this.edicionForm.get('institutionResponse'); }

  public obtenerTiposSolicitud() {
    this.solicitudService.obtenerSolicitudTipo().subscribe((tipos: ApiResponse<RequestTypeDTO[]>) => {
      this.tipoRequestList = tipos.data;
    });
    this.solicitudService.obtenerDescripcionSolicitud(this.data.requestTypeId).subscribe((tipos: ApiResponse<RequestTypeDTO>) => {
      this.tipoRequest = tipos.data;
    });
  }

  public obtenerCategorySolicitud() {
    this.solicitudService.obtenerSolicitudCategoria().subscribe((categories: ApiResponse<RequestCategoryDTO[]>) => {
      this.categoryRequestList = categories.data;
    });
  }

  public cerrarModalValidacion() {
    if(this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public enviar() {
    if(this.data.tipoAccion == AccionEnum.EDITAR) {
      this.data = Object.assign(this.edicionForm.value);
      this.data.tipoAccion = 2;
      this.solicitudService.modificarSolicitud(this.data).subscribe(
        (editado: SimpleResponse) => {
          if(editado.success) {
            this.solicitudService.invocarMetodoObtenerSolicitudesAdmin();
            this.snackService.mostrarSnackBack('Se ha editado la información');
            this.boolCambio = false;
            this.datosUsuario.completed = this.data.completed;
          } else {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al modificar la solicitud. Por favor, intente de nuevo más tarde.');
          }
        }, error => {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al modificar la solicitud. Por favor, intente de nuevo más tarde.');
        }
    )}
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  public envioCorreo() {
    this.dialog.open(EnviarCorreoComponent, {
      data: this.data,
      panelClass: "dialogo-enviar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  public descargarArchivo(requestTypeId: number) {
    this.solicitudService.descargarPdfDirecto(requestTypeId).subscribe(
      (respuesta: boolean) => {
        if(respuesta) {
          let mensaje: string = this.traductorService.translate('descargaArchivo');
          this.snackService.mostrarSnackBack(mensaje);
        } else {
          this.snackService.mostrarSnackBack("Ha ocurrido un error al descargar el archivo.")
        }
      }
    )
  }

  public obtenerDocumentos() {
    this.solicitudService.obtenerDocumentosSolicitud(this.data.requestId).subscribe(
      (documentos) => { 
        if(documentos.success) {
          if(documentos.data.length > 0) {
            this.documentos = documentos.data;
            this.dataSource = new MatTableDataSource(documentos.data);
            this.selection = new SelectionModel(false, []);
            this.infoCargado = true;
            console.log(documentos.data);
          }
        }
      });
  }

  public onFileChange(event) {
    let documentos: any[] = [];
    let documentosEditar: any[] = [];
    const reader = new FileReader();
    const [file] = event.target.files;
    if(event.target.files && event.target.files.length) {
      reader.onload = () => {
        let documento: any = {
          requestId: this.data.requestId,
          name: file.name,
          path: reader.result,
          status: true,
          fechaCreacion: this.date
        }
        documentos.push(documento);
        if (this.dataSource === undefined || this.dataSource.data.length === 0) {
          this.dataSource = new MatTableDataSource(documentos);
          this.selection = new SelectionModel<any>(false, []);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentos;
          this.infoCargado = true;
        } else {
          documentosEditar = this.dataSource.data;
          this.dataSource = new MatTableDataSource(documentosEditar);
          this.selection = new SelectionModel<any>(true, []);
          this.dataSource.data.push(documento);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentosEditar;
        }
        this.registrarDoc(documentos);
      }
      reader.readAsDataURL(file);
    }
  }

  public onFileChangeEditar(event, numeroArchivo) {
    let documentosEditar: any[] = [];
    let documentosGuardar: any[] = [];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.onload = () => {
        let documento = {
          requestId: this.data.requestId,
          name: file.name,
          fileNumber: numeroArchivo,
          path: reader.result,
          fechaCreacion: this.date,
          status: true
        }
        documentosEditar = this.documentos;
        this.dataSource = new MatTableDataSource(documentosEditar);
        this.selection = new SelectionModel<any>(true, []);
        this.dataSource.data.push(documento);
        this.dataSource._updateChangeSubscription();
        this.documentos = this.dataSource.data;
        documentosGuardar.push(documento);
        this.registrarDoc(documentosGuardar);
      }
      reader.readAsDataURL(file);
    }
  }

  public registrarDoc(docs): void {
    if(docs != null) {
      this.solicitudService.registrarDocumentos(docs).subscribe(
        (cargaDocumento: SimpleResponse) => {
          if(cargaDocumento.success) {
            let mensaje: string = this.traductorService.translate('guardadoDocumentos');
              this.snackService.mostrarSnackBack(mensaje);
          } else {
            this.snackService.mostrarSnackBack("Ha ocurrido un error al guardar los documentos, intente más tarde.");
          }
      });
    }
  }

  public eliminarDocumento(path: string): void {
    if(path != null) {
      this.solicitudService.deleteDocumentoById(path).subscribe(
        (documentDelete: SimpleResponse) => {
          if(documentDelete.success) {
            if(this.documentos.length === 1) {
              this.infoCargado = false;
              this.numDocumentoSeleccionado = 0;
            }
            this.dataSource._updateChangeSubscription();
            this.snackService.mostrarSnackBack("Se ha eliminado el documento");
          } else {
            this.snackService.mostrarSnackBack("Ha ocurrido un error al eliminar el documento, intentelo más tarde.")
          }
      });
    }
  }

  public visualizarPdf(path: string): void {
    if(path != null) {
      this.solicitudService.documentosSolicitud(path).subscribe(
        (documentos) => {
          if(documentos.success) {
            var documento = documentos.data;
            var documentoVisualizar = documento;
            this.mime = documentoVisualizar.name.indexOf(".pdf") >= 0 ? "application/pdf" : /\.(png|jpeg|jpg|gif|tiff|bmp)$/.test(documentoVisualizar.name) ? "image/" + /\.(png|jpeg|jpg|gif|tiff|bmp)$/.exec(documentoVisualizar.name)[1] : "application/octect-stream";
            this.pdf = this.base64ToArrayBuffer(documentoVisualizar.path);
            if (this.mime.indexOf("image") >= 0) {
              this.imagen = `data:${this.mime};base64,${documentoVisualizar.path}`;
            }
            if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
              this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
            } else {
              const blob = new Blob([this.pdf], { type: this.mime });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            }
          }
        }
      )
    } else {
      let archivo = this.documentos[0].path;
      this.mime = archivo.split(",")[0].split(":")[1].split(";")[0];
      this.imagen = this.documentos[0].path;
      this.pdf = this.base64ToArrayBuffer(archivo);
      if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
        this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
      } else {
        const blob = new Blob([this.pdf], { type: this.mime });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    }
  }

  base64ToArrayBuffer(base64): ArrayBufferLike {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public cerrarModalPdf(): void {
    this.modalVisorPDFRef.close();
  }

  public descargarArchivoDirecto(requestTypeId: number, usuarioId?: number){
    this.solicitudService.descargarPdfAsBlob(requestTypeId, usuarioId).subscribe(
      (respuesta: Blob) => {
        if (!!respuesta) {
          let url: string = window.URL.createObjectURL(respuesta);
          let anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `${this.tipoRequest.name.replace(" ", "")}.pdf`;
          anchor.click();
          window.URL.revokeObjectURL(url);
          let mensaje: string = this.traductorService.translate('descargaArchivo');
          this.snackService.mostrarSnackBack(mensaje);
        } else {
          this.snackService.mostrarSnackBack("Ha ocurrido un error al descargar el archivo. Por favor intente de nuevo más tarde.");
        }
      }, (err: any) => {
        this.snackService.mostrarSnackBack("Ha ocurrido un error al descargar el archivo. Por favor intente de nuevo más tarde.");
      }
    )
  }

  /**
   * Metodo interno del data table de angular material
   * @returns 
   */
  isAllSelected(): boolean {
    this.numDocumentoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numDocumentoSeleccionado === numRows;
  }

  /**
   * Metodo interno del data table de angular material
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.documentoId + 1}`;
  }

  /**
   * Metodo interno del data table de angular material
   */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row))
  }
}
