import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { InteractionTypeService, LeadService } from 'src/app/_services';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-lead-interactions-tab',
  templateUrl: './lead-interactions-tab.component.html',
  styleUrls: ['./lead-interactions-tab.component.scss'],
})
export class LeadInteractionsTabComponent implements OnInit {
  displayedColumns: string[] = ['Fecha', 'Usuario', 'Medio', 'Comentario'];
  dataSource: MatTableDataSource<any>;

  constructor(
    private readonly interTypeService: InteractionTypeService,
    private util: UtileriasService,
    private readonly leadService: LeadService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  public interForm: FormGroup;
  public interTypeList: any[] = [];

  inTypeF = new FormControl();

  ngOnInit(): void {
    this.getInterTypes();
    this.inicializarForm();
    this.leadService.DeleteInteractions();
    this.iniInterRecord();
  }

  public inicializarForm(): void {
    this.interForm = new FormGroup({
      descF: new FormControl(''),
      inTypeF: this.inTypeF,
      commentF: new FormControl(''),
    });

    this.inTypeF.setValue(0);
  }

  public addInteraction(): void {
    let interTab = Object.assign(this.interForm.value);
    let interaction = {
      InteractionId: interTab.inTypeF,
      Description: interTab.descF,
      Commentary: interTab.commentF,
    };
    this.leadService.SetInteractions(interaction);
    this.inicializarForm();
  }

  public iniInterRecord(): void {
    if (this.data && this.data.leadInteraction.length > 0) {
      this.data.leadInteraction.forEach((inter) => {
        inter.creationDate = this.util.formatearFecha(new Date(inter.creationDate));
      });
    }
    this.dataSource = new MatTableDataSource(this.data.leadInteraction);
  }

  //#region Get's
  public getInterTypes(): void {
    this.interTypeService.getInteractionType().subscribe((types: ApiResponse<Array<any>>) => {
      this.interTypeList = types.data;
    });
  }
  //#endregion
}
