import { CompraCursoRegistroDto } from './../_models/CompraCursoRegistroDto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import { CompraCursoDto } from '../_models/CompraCursoDto';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';

@Injectable({
    providedIn: 'root'
})
export class CompraCursoService {

    constructor(private httpClient: HttpClient) { }

    /**
     * Metodo para obtener datos de la materia con su identificador
     * @param cursoId 
     * @returns 
     */
    public CursoById(institucionId: string, cursoId: number): Observable<ApiResponse<CompraCursoDto>> {
        return this.httpClient.get<ApiResponse<CompraCursoDto>>(`${environment.enves[baseUrl].apiUrl}/CompraCurso/CursoById/${institucionId}/${cursoId}`);
    }

    /**
    * Metodo para guardar el nuevo usuario
    * @param usuario
    * @returns
    */
    public EnviarResgistroCompraCurso(usuario: CompraCursoRegistroDto, institucionId: string): Observable<SimpleResponse> {
        return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/CompraCurso/PostUsuarioCompraCurso/${institucionId}`, usuario);
    }

    /**
     * Metodo para revisar si ya tiene un alumno existente para la materia
     * @param usuarioId 
     * @param cursoId 
     * @returns 
     */
    public ValidarAlumnoExistente(usuarioId: number, cursoId: number): Observable<SimpleResponse> {
        return this.httpClient.get<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/CompraCurso/ValidarAlumnoExistente/${usuarioId}/${cursoId}`);
    }

}
