export class RequestCategoryDTO {
    requestCategoryId: number;
    code: string;
    name: string;
    status: boolean;
    isLetter?: boolean;
    tipoAccion?: number;
}

export class RequestTypeDTO {
    requestTypeId: number;
    requestCategoryId: number;
    requestCategoryName?: string;
    isLetterCategory?: boolean;
    code: string;
    name: string;
    description: string;
    email: string;
    status: boolean;
    tipoAccion?: number;
}

export class SolicitudAdminDto {
    requestId: number;
    code: number;
    file?: number;
    requestCategoryId: number;
    requestCategoryName: string;
    isLetterCategory?: boolean;
    requestTypeId: number;
    requestTypeName: string;
    directDownload?: boolean;
    usuarioId: number;
    nombre: string;
    institutionResponse?: string;
    comment: string;
    completed: number;
    completedDate: Date;
    programa?: string;
    correo?: string;
    numero?: string;
    fechaCreacion?: Date;
    tipoAccion?: number;
}
