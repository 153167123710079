import { MatDialog } from '@angular/material/dialog';
import { HeaderModalDetails } from './../../../../core.domain/models/header-modal-detail/header-modal-details.model';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs/internal/Subscription';
import { NuevoIngresoService } from '@app/_services/nuevo-ingreso.service';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { UsuarioIngreso } from '@app/_models/usuarionuevoingresodto';
import { ProfesorService } from '@app/_services/profesor.service';
import { SnackService } from '@app/services/snack-service.service';
import { TraductorService } from '@app/_services/traductor.service';

@Component({
  selector: 'app-header-modal-detail',
  templateUrl: './header-modal-detail.component.html',
  styleUrls: ['./header-modal-detail.component.scss']
})
export class HeaderModalDetailComponent implements OnInit {

  //#region Inputs, Outputs & Emitters
  @Input() data: HeaderModalDetails;
  //#endregion

  //#region Subscriptions
  private Subscription: Subscription = new Subscription();
  //#endregion
  
  //#region ViewChild
  @ViewChild('modalWarning') modalWarning: TemplateRef<any>;
  //#endregion

  //#region Properties
  public HeaderModalDetails: HeaderModalDetails;
  public imagePath: SafeResourceUrl;
  public base64Image: string;
  public eventImage: any;
  public imgURL: string | ArrayBuffer | null;
  public boolImagen: boolean = false;
  public dialogoRefAdver: any;

  private photo: string | ArrayBuffer | null;
  private photoName: string;
  //#endregion
  constructor(
    private _nuevoIngresoService: NuevoIngresoService,
    private _sanitizer: DomSanitizer,
    private _cd: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _profesorService: ProfesorService,
    private _snackService: SnackService,
    private _traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.HeaderModalDetails = new HeaderModalDetails(this.data);        
    this._getPhotoProfile();
  }

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#region Methods
  private _getPhotoProfile(): void {
    this.Subscription.add(
      this._nuevoIngresoService.imagenUsuario(this.HeaderModalDetails.usuarioId).subscribe(
        (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
          if (datosUsuarioImagen.success) {            
            this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
            this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
          }
        }
      )
    );
  }


  preview(event) {
    if (event.length === 0) {
      return;
    }
    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null) {
      //this.message = 'Only images are supported.';
      return;
    }
    let reader: FileReader = new FileReader();
    //this.imagePath3 = event;
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.eventImage = event;
  }


  
  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
     
      this.photoName  = file.name;
      
      reader.onload = () => {
        this.HeaderModalDetails.fotografia = reader.result;
        this._cd.markForCheck();
    
        this.photo = reader.result;

      };
      reader.readAsDataURL(file);
    }
  }


  public imagenModal() {    
    this.dialogoRefAdver = this._dialog.open(this.modalWarning, { disableClose: true });    
  }


  
  public cerrarModalImagen(descartar: boolean) {
    if (descartar) {
      this.imgURL = "";
      this.dialogoRefAdver.close();
    } else {
      this.dialogoRefAdver.close();
      this.onFileChangePerfil(this.eventImage);
      this.enviarImagen();
      this.imgURL = "";
    }
  }


  public onFileChangePerfil(event) {
    const reader = new FileReader();
    if (event && event.length > 0) {      
      this.imagePath = event;
      const [file] = event;

      this.photoName = file.name;

      reader.readAsDataURL(event[0]);
      reader.onload = (_event) => {
        this.imagePath = reader.result;        
      }
      this.eventImage = event;
    }
  }

  public enviarImagen() {    
    let imagen: any = {
      usuarioId: this.HeaderModalDetails.usuarioId,
      fotografia: this.photo,
      fotografiaFile: this.photoName,
    }    
    this._profesorService.editarImagen(imagen).subscribe(
      imagenData => {
        if (imagenData) {
          this._getPhotoProfile();        
          this._snackService.mostrarSnackBack("Se ha guardado la imagen correctamente.");
        } else {
          let mensaje: string = this._traductorService.translate('errorGuardar');
          this._snackService.mostrarSnackBack(mensaje);
        }
      }
    );
  }
  //#endregion

}
