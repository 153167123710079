import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import { Materia } from '../_models/materia';
import { AreaMateria } from '../_models/area-materia';
import { TipoImparticion } from '../_models/tipo-imparticion';
import { TipoInstalacion } from '../_models/tipo-instalacion';
import { TipoMateria } from '../_models/tipo-materia';
import { TipoEquivalencia } from '../_models/tipo-equivalencia';
import { TipoDuracion } from '../_models/tipo-duracion';
import { MateriaOferta } from '../_models/materia-oferta';

@Injectable({
  providedIn: 'root'
})
export class MateriaService {

  constructor(private httpClient: HttpClient) { }

  // Get Materia
  public obtenerMateria(): Observable<Materia[]> {
    return this.httpClient.get<Materia[]>(`${environment.enves[baseUrl].apiUrl}/Materia`);
  }
  // Get Materia
  public obtenerMaterias(idProgramas): Observable<Materia[]> {
    return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/Materia/GetMaterias`, idProgramas);
  }
  // Post Materia
  public agregarMateria(materia: Materia): Observable<Materia> {
    return this.httpClient.post<Materia>(`${environment.enves[baseUrl].apiUrl}/Materia`, materia);
  }

  // Put Materia
  public modificarMateria(materia: Materia): Observable<Materia> {
    return this.httpClient.put<Materia>(`${environment.enves[baseUrl].apiUrl}/Materia`, materia);
  }

  /**
   * Metodo para enviar la descripcion de la materia
   * @param materia 
   * @returns 
   */
  public agregarDescripcionMateria(materia: Materia): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Materia/AgregarDescripcionMateria`, materia);
  }

  // Delete Materia
  public eliminarMateria(materiaId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.enves[baseUrl].apiUrl}/Materia?materiaId=` + materiaId);
  }

  // Delete Materias Multiples
  public eliminarMateriasMultiples(materiaId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.enves[baseUrl].apiUrl}/Materia/DeleteMaterias?materiaId=` + materiaId);
  }

  // Post MateriaOferta
  public agregarMateriaOferta(materiaOferta): Observable<MateriaOferta> {
    return this.httpClient.post<MateriaOferta>(`${environment.enves[baseUrl].apiUrl}/MateriaOferta`, materiaOferta);
  }

  // Put MateriaOferta
  public modificarMateriaOferta(materiaOferta): Observable<MateriaOferta> {
    return this.httpClient.put<MateriaOferta>(`${environment.enves[baseUrl].apiUrl}/MateriaOferta`, materiaOferta);
  }

  // Delete MateriaOferta
  public eliminarMateriaOferta(materiaOfertaId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.enves[baseUrl].apiUrl}/MateriaOferta?materiaOfertaId=` + materiaOfertaId);
  }

  // Delete MateriasOfertas
  public eliminarMateriasOfertaMultiples(materiaOfertaId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.enves[baseUrl].apiUrl}/DeleteMateriaOfertaMultiple/MateriaOferta?materiaOfertaId=` + materiaOfertaId);
  }
  // Get TipoDuracion
  public obtenerTipoDuracion(): Observable<TipoDuracion[]> {
    return this.httpClient.get<TipoDuracion[]>(`${environment.enves[baseUrl].apiUrl}/TipoDuracion`);
  }

  //Get TipoEquivalnecia
  public obtenerTipoEquivalencia(): Observable<TipoEquivalencia[]> {
    return this.httpClient.get<TipoEquivalencia[]>(`${environment.enves[baseUrl].apiUrl}/TipoEquivalencia`);
  }

  // Get TipoMateria
  public obtenerTipoMateria(): Observable<TipoMateria[]> {
    return this.httpClient.get<TipoMateria[]>(`${environment.enves[baseUrl].apiUrl}/TipoMateria`);
  }

  // Get TipoInstalacion
  public obtenerTipoInstalacion(): Observable<TipoInstalacion[]> {
    return this.httpClient.get<TipoInstalacion[]>(`${environment.enves[baseUrl].apiUrl}/TipoInstalacion`);
  }

  // Get TipoImparticion
  public obtenerTipoImparticion(): Observable<TipoImparticion[]> {
    return this.httpClient.get<TipoImparticion[]>(`${environment.enves[baseUrl].apiUrl}/TipoImparticion`);
  }

  // Get AreaMateria
  public obtenerAreaMateria(): Observable<AreaMateria[]> {
    return this.httpClient.get<AreaMateria[]>(`${environment.enves[baseUrl].apiUrl}/AreaMateria`);
  }
}
