import { TramitesService } from './../../_services/tramites.service';
import { PeriodoService } from './../../_services/periodo.service';
import { Periodo } from './../../_models/periodo';
import { ResumenEstadoCuentaDto } from './../../_models/resumenEstadoCuentaDto';
import { EstadoCuentaCarteraComponent } from './../../seguimiento-cartera/detalle-cartera/estado-cuenta-cartera/estado-cuenta-cartera.component';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UsuarioIngreso } from './../../_models/usuarionuevoingresodto';
import { EstadoCuentaDto } from './../../_models/estado-cuenta-dto';
import { DatosPersonalesInscripcionComponent } from './../../inscripcion/seguimiento/seguimiento-modal/datos-personales-inscripcion/datos-personales-inscripcion.component';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TraductorService } from 'src/app/_services/traductor.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SeguimientoCarteraService } from 'src/app/_services/seguimiento-cartera.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { CargaInscripcion } from 'src/app/_models/carga-inscripcion';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'app-tramites-modal',
  templateUrl: './tramites-modal.component.html',
  styleUrls: ['./tramites-modal.component.scss']
})
export class TramitesModalComponent implements OnInit {

  @ViewChild(DatosPersonalesInscripcionComponent) datosPersonales: DatosPersonalesInscripcionComponent;
  @ViewChild(EstadoCuentaCarteraComponent) estadoCuenta: EstadoCuentaCarteraComponent;
  @ViewChild('dialogoCerrarModalCargo') dialogoCerrarModalCargo: TemplateRef<any>;
  @ViewChild('dialogAdvertenia') dialogoCerrarModal: TemplateRef<any>;
  public dialogoRefCerrarModalCargo: any;
  public datosUsuarioActual: any;
  public base64Image: any;
  public imagePath: any;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public formValido$: Observable<boolean>;
  public formValido: boolean;
  public dialogoRefCerrarModal: any;
  public tabCambio: boolean = false;
  public tabSeleccionado: number = 0;
  public cargaAlumno: any;
  public tabSeleccionadoIndex: FormControl = new FormControl(0);
  public cargaActualAl: string;
  public permiteParcialidades: boolean = false;
  public cuotaColegiatura: any;
  public flagAltaMateria: boolean;
  //VARIABLES CATALOGOS
  public subscriptionPeriodo: Subscription;
  public periodoList: Periodo[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TramitesModalComponent>,
    public nuevoIngresoService: NuevoIngresoService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private seguimientoService: SeguimientoCarteraService,
    private snackService: SnackService,
    private i18nextPipe: I18NextPipe,
    private tramitesService: TramitesService,
    private periodoService: PeriodoService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    if (this.tramitesService.obtenerEstadoCuentaSubscription == undefined) {
      this.tramitesService.obtenerEstadoCuentaSubscription = this.tramitesService.invocarResumenCuenta.subscribe(() => {
        this.getTipoPeriodo(this.data[0].usuarioId);
      });
    }
    this.nuevoIngresoService.getCargas(this.data[0].campusId, this.data[0].programa.nivelId, this.data[0].programaId).subscribe(
      (respuesta: ApiResponse<CargaInscripcion>) => {
        this.cargaAlumno = respuesta.data;
        if (!!this.cargaAlumno) {
          this.nuevoIngresoService.definirtipoCarga(this.cargaAlumno.tipoCarga);
        }
      })
    this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos)
    this.cargarImagen();
    this.formValido$ = this.nuevoIngresoService.obtenerFormularioValidoInscripcion$();
    this.formValido$.subscribe(bool => this.formValido = bool);
    this.getTipoPeriodo(this.data[0].usuarioId);
  }

  /**
   * Obtiene los datos del usuario
   * @param usuarioId
   */
  public buscarInformacionUsuario(usuarioId: number): void {
    this.seguimientoService.obtenerSeguimientoUsuarioById(usuarioId).subscribe(
      (seguimientoUsuario: ApiResponse<EstadoCuentaDto[]>) => {
        this.seguimientoService.setDatosSeguimientoUsuario$(seguimientoUsuario.data);
        this.buscarInfomacionEstadoCuenta(this.data[0].usuarioId, this.data[0].priodoIngreso);
        this.estadoCuenta.carteraTramite = true;
      });
  }

  /**
   * Obtiene la imagen que el usuario previamente guardo
   */
  cargarImagen(): void {
    this.nuevoIngresoService.imagenUsuario(this.data[0].usuarioId).subscribe(
      (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
        if (datosUsuarioImagen.success) {
          this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
        }
      }
    );
  }

  /**
   * Cierra el modal si no hay cambios pendientes, de ser asi manda una alerta para evitar que cierre
   */
  public cerrarModal(): void {
    if (this.formValido) {
      this.dialogoRefCerrarModal = this.dialog.open(this.dialogoCerrarModal, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Cierra el modal solo si da clic en descartar
   * @param descartar
   */
  public cerrarModalAdvertenciaCancelarCambios(descartar: boolean): void {
    if (descartar) {
      this.dialogoRefCerrarModal.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefCerrarModal.close();
    }
  }

  /**
   * Funcion que entra despues que el componente ha sido cargado
   */
  ngAfterViewInit(): void {
    this.datosUsuarioActual = this.data;
    this.nuevoIngresoService.definirDatosUsuarioBusqueda(this.data);
    this.inicializarTabs();
  }

  /**
   * Inicia los componentes alojados en el modal
   */
  inicializarTabs(): void {
    if (this.data[0].tabRequerido === 'periodo') {
      setTimeout(() => {
        this.tabSeleccionadoIndex.setValue(1);
      }, 500);
    }
  }

  /**
   * Funcion que guarda los datos segun la pestaña en la que este se encuentre
   */
  public enviar(): void {
    if (this.tabSeleccionado === 0) {
      this.datosPersonales.enviarDatosPersonales();
    }
    if (this.tabSeleccionado == 1) {
    }
  }

  /**
   * Funcion que detecta la pantalla en la que te encuentras y cargar sus componentes
   * @param event
   */
  public onChangeTab(event: MatTabChangeEvent): void {
    this.tabCambio = true;
    let tab: number = event.index;
    this.tabSeleccionado = tab;
  }

  /**
   * Abre un para agregar un nuevo tipo de cargo
   */
  public abrirModalTipoCargo(): void {
    this.dialogoRefCerrarModalCargo = this.dialog.open(this.dialogoCerrarModalCargo, { disableClose: true });
  }

  /**
   * Iguala la cargaActual con la que trae
   * @param carga
   */
  public setCarga(carga): void {
    this.cargaActualAl = carga;
  }

  //CATALOGOS
  public getTipoPeriodo(usuarioId: number) {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodosByUsuario(usuarioId).subscribe(
      (periodo: Periodo[]) => {
        if (periodo.length > 0) {
          this.periodoList = periodo;
          this.seguimientoService.setDatosPeriodoUsuario$(this.periodoList);
          this.buscarInformacionUsuario(this.data[0].usuarioId);
        }
      });
  }

  /**
   * BUSQUEDA ESTADO CUENTA
   * @param usuarioId 
   * @param periodoId 
   */
  public buscarInfomacionEstadoCuenta(usuarioId: number, periodoId: number) {
    this.seguimientoService.obtenerEstadoCuentaByUsuarioId(usuarioId, periodoId, this.data[0].alumnoId).subscribe(
      (estadoCuenta: EstadoCuentaDto[]) => {
        this.seguimientoService.setDatosEstadoCuentaUsuario$(estadoCuenta);
        this.buscarInfomacionResumenEstadoCuenta(usuarioId);
        this.datosPersonales.renderizarFormulario();
      });
  }

  /**
   * BUSQUEDA RESUMEN ESTADO CUENTA
   * @param usuarioId 
   */
  public buscarInfomacionResumenEstadoCuenta(usuarioId: number) {
    this.seguimientoService.obtenerResumenEstadoCuentaByUsuarioId(this.data[0].alumnoId).subscribe(
      (resumen: ApiResponse<ResumenEstadoCuentaDto>) => {
        if (resumen.data.saldoVencido > 0) {
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-1');
          this.seguimientoService.setDatoEstatus$(mensaje);
        } else {
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-2');
          this.seguimientoService.setDatoEstatus$(mensaje);
        }
        this.seguimientoService.setDatosResumenUsuario$(resumen.data);
      });
  }

  /**
   * Metodo para traducir los textos de los tabs
   * @param text 
   * @returns 
   */
  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }
}
