import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { GrupoSanguineo } from '../_models/grupo-sanguineo';

@Injectable({
  providedIn: 'root'
})
export class GrupoSanguineoService {

  constructor(private httpClient: HttpClient) { }

  //Get Catalogo Grupo Sanguineo
  public obtenerGrupoSanguineo(): Observable<GrupoSanguineo[]> {
    return this.httpClient.get<GrupoSanguineo[]>(`${environment.enves[baseUrl].apiUrl}/GrupoSanguineo`);
  }
}
