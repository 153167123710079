import { FiltroAdminAcademicoAlumnoDto } from './../../_models/FiltroAdminAcademicoAlumnoDto';
import { NuevoIngresoAsignarBloqueModalComponent } from './../../nuevo-ingreso/nuevo-ingreso-asignar-bloque-modal/nuevo-ingreso-asignar-bloque-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InscripcionService } from 'src/app/_services/inscripcion.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Alumno, EstatusAcademico } from '../../_models/inscripcion'
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CampusService } from '../../_services/campus.service';
import { Campus } from '../../_models/campus';
import { Periodo } from '../../_models/periodo';
import { PeriodoService } from '../../_services/periodo.service';
import { SeguimientoModalComponent } from './seguimiento-modal/seguimiento-modal.component'
import { MatDialog } from '@angular/material/dialog';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { MatOption } from '@angular/material/core';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { EnviarCorreoComponent } from 'src/app/_components/enviar-correo/enviar-correo.component';
import { ProgramaActivo } from 'src/app/_models/programa';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';
import { BlockLabelDto } from '@app/_models/BlockLabelDto';
import { BlockService } from '@app/_services/block.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18NextPipe } from 'angular-i18next';
import { Block } from '@app/_models/block';
import { NuevoIngresoService } from '@app/_services/nuevo-ingreso.service';
import { AttributesDto } from '../../_models/AttributesDto';
import { AttributesService } from '../../_services/attributes.service';
@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.scss']
})
export class SeguimientoComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns: string[] = ['select', 'ID', 'nombre', 'programa', 'campus', 'periodo', 'tipoAlumno', 'estatus', 'blockName'];
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allBloque') private allBloque: MatOption;
  @ViewChild('allAtritbuto') private allAtritbuto: MatOption;
  public Block: Block;
  public dataSource: MatTableDataSource<any>;
  public selection = new SelectionModel<any>(true, []);
  public numIngresoSeleccionado: number = 0;
  public filtroForm: FormGroup;
  public datafilter: any;
  public arregloFiltroOriginal: any[] = [];
  public subscriptionCampus: Subscription;
  public campusList: Campus[] = [];
  public estatusList: EstatusAcademico[] = [];
  public programas: ProgramaActivo[] = [];
  public periodos: Periodo[];
  public arregloFiltroModificado: any[] = [];
  private periodosactivos: any[] = [];
  public datosEliminar: [];
  public tipoDeAlumno: boolean;
  public BlockLabel: BlockLabelDto[];
  public idNombre = new FormControl();
  public CampusF = new FormControl();
  public ProgramaF = new FormControl();
  public periodoF = new FormControl();
  public estatusF = new FormControl();
  public tipoAlumnoF = new FormControl();
  public bloqueF = new FormControl();
  public atributoF = new FormControl();
  public periodoSeleccionado: number = 0;
  public campusSeleccionado: number = 0;
  public programaSeleccionado: number = 0;
  public statusSeleccionado: number = 0;
  public tipoAlumnoSeleccionado: number = 0;
  public ExcelSubscription: Subscription;
  public tipoInscripcion: string = "NI";
  public tipoAl: number = 0;
  public timer: any;
  public valorInicial = 100;
  public attributes: AttributesDto[];
  public existenAtributos: boolean = false;

  constructor(public inscripcionService: InscripcionService,
    public nuevoIngresoService: NuevoIngresoService,
    private campusService: CampusService,
    public programaservice: ProgramaAcademicoService,
    private periodoService: PeriodoService,
    public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private generadorExcel: GeneracionExcelServiceService,
    private _BlockService: BlockService,
    private snackBar: MatSnackBar,
    private i18nextPipe: I18NextPipe,
    public attributesService: AttributesService
  ) { }

  /**
   * Inicia el componente y las funciones de carga de los catalogos y el paginador de la tabla
   */
  async ngOnInit(): Promise<void> {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    await this.inicializarForm().then((_) => {
      this.LlenarFiltros();
    });
    if (this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription === undefined) {
      this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription = this.nuevoIngresoService.invocarObtenerCandidatos.subscribe(() => {
        this.LlenarFiltros();
      });
      this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription === undefined;
    }
  }

  /**
   * Inicializa el formulario y setea los campos usados
   */
  public inicializarForm(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.filtroForm = new FormGroup({
        tipoAlumno: new FormControl(0),
        periodoF: new FormControl([]),
        idnombre: new FormControl(),
        ProgramaF: new FormControl([]),
        CampusF: new FormControl([]),
        estatus: new FormControl(0),
        bloqueF: new FormControl([]),
        atributoF: new FormControl([]),
        authorizeRegistrationF: new FormControl(0)
      });
      this.CampusF.setValue(["-1"]);
      this.ProgramaF.setValue(["-1"]);
      this.periodoF.setValue(["-1"]);
      this.bloqueF.setValue(["-1"]);
      this.tipoAlumnoF.setValue(0);
      this.estatusF.setValue(0);
      this.atributoF.setValue(["-1"]);      
      resolve();
    });
  }

  /**
   * Hace un llenado de todos los filtros, ingresa a las funciones de obtencion de datos para su carga
   */
  public LlenarFiltros(): void {
    this.obtenerPeriodo();
  }

  /**
   * Obtiene los campus del periodo
   */
  public obtenerPeriodo(): void {
    let today = new Date();
    this.periodoService.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        this.periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio <= today && fin >= today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if (this.periodosactivos.length > 0) {
          this.periodoF.setValue([...this.periodosactivos]);
          this.filtroForm.patchValue({
            periodoF: this.periodosactivos
          });
        }
        this.obtenerProgramas();
      });
  }

  /**
     * Obtiene los campus del programa y setea su valor en todos
     */
  public obtenerProgramas(): void {
    this.programaservice.obtenerProgramasActivos().subscribe(
      (programas: ApiResponse<ProgramaActivo[]>) => {
        this.programas = programas.data;
        this.ProgramaF.setValue([...this.programas.map(item => item.programaId), 0]);
        this.filtroForm.patchValue({
          ProgramaF: this.programas.map(item => item.programaId)
        });
        this.obtenerCampus();
      }
    );
  }

  /**
   * Obtiene los campus del catalogo y setea su valor en todos
   */
  public obtenerCampus(): void {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe((campus: Campus[]) => {
      this.campusList = campus;
      this.CampusF.setValue([...this.campusList.map(item => item.campusId), 0]);
      this.filtroForm.patchValue({
        CampusF: this.campusList.map(item => item.campusId)
      });
      this.obtenerEstatus();
    });
  }

  /**
   * Obtiene los campus del estatus academico
   */
  public obtenerEstatus(): void {
    this.inscripcionService.GetEstatusAcademicos().subscribe((status: ApiResponse<EstatusAcademico[]>) => {
      this.estatusList = status.data;
      this.obtenerBloques();
    });
  }

  /**
   * Metodo que consulta los bloques
   */
  public obtenerBloques() {
    this._BlockService.GetBlockLabelDto().subscribe((respuesta: ApiResponse<BlockLabelDto[]>) => {
      this.BlockLabel = respuesta.data;
      this.obtenerAtributos();
    })
  }

  /**
  * Metodo que consulta los atributos
  */
  public obtenerAtributos() {
    this.attributesService.attributesInstitucion().subscribe((respuesta: ApiResponse<AttributesDto[]>) => {
      this.attributes = respuesta.data;
      this.existenAtributos = this.attributes.length > 0;
      this.filtrar();
    })
  }

  /**
   * Metodo que envia laa informacion de los filtros al back
   */
  public filtrar() {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      tipoAlumnoId: this.filtroForm.get('tipoAlumno').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatus: this.filtroForm.get('estatus').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value,
      authorizeRegistration: this.filtroForm.get('authorizeRegistrationF').value
    };
    this.cargarInscripciones(0, 1, this.paginator.pageSize, filtro);
  }

  /**
   * Metodo que inicia un timer cadaa vez que es llamado
   */
  public iniciarTimer() {
    this.timer = window.setTimeout(
      () => {
        this.filtrar()
      }, 400);
  }

  /**
   * Metodo que reinia el timer cada vez que es llamado
   */
  public reiniciarTimer() {
    clearTimeout(this.timer);
    this.iniciarTimer();
  }

  /**
   * Si solo un dato del select Periodo esta seleccionado busca solo esa informacion y la
   * muestra en la tabla principal
*/
  toggleAllSelectionPeriodo(): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.filtroForm.patchValue({
        periodoF: this.periodos.map(item => item.periodoId)
      });
      this.periodoF.patchValue([...this.periodos.map(item => item.periodoId), 0]);
    } else {
      this.filtroForm.patchValue({
        periodoF: []
      });
      this.periodoF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Programa esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionPrograma(): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.filtroForm.patchValue({
        ProgramaF: this.programas.map(item => item.programaId)
      });
      this.ProgramaF.patchValue([...this.programas.map(item => item.programaId), 0]);
    } else {
      this.filtroForm.patchValue({
        ProgramaF: []
      });
      this.ProgramaF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Campus esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionCampus(): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.filtroForm.patchValue({
        CampusF: this.campusList.map(item => item.campusId)
      });
      this.CampusF.patchValue([...this.campusList.map(item => item.campusId), 0]);
    } else {
      this.filtroForm.patchValue({
        CampusF: []
      });
      this.CampusF.patchValue([]);
    }
  }

  /**
   * Metodo cuando se seleccionan todos los bloques
   */
  toggleAllSelectionBloque() {
    // this.reiniciarTimer();
    if (this.allBloque.selected) {
      this.filtroForm.patchValue({
        bloqueF: this.BlockLabel.map(item => item.blockLabelId)
      });
      this.bloqueF.patchValue([...this.BlockLabel.map(item => item.blockLabelId), 0]);
    } else {
      this.filtroForm.patchValue({
        bloqueF: []
      });
      this.bloqueF.patchValue([]);
    }
  }

  /**
  * Metodo cuando se seleccionan todos los atributos
  */
  toggleAllSelectionAtributo() {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.filtroForm.patchValue({
        atributoF: this.attributes.map(item => item.attributeId)
      });
      this.atributoF.patchValue([...this.attributes.map(item => item.attributeId), 0]);
    } else {
      this.filtroForm.patchValue({
        atributoF: []
      });
      this.atributoF.patchValue([]);
    }
  }


  /**
   * Verifica si el campo Todos del select Periodo esta seleccionados, si lo esta
   * quita la seleccion de todos los campos, sino los selecciona todos
   * @param periodoId
   */
  tosslePerOnePeriodo(periodoId: number): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
    }
    if (this.periodoF.value.length == this.periodos.length) {
      this.allPeriodo.select();
    }
    const periodosSeleccionados = this.filtroForm.get('periodoF').value;
    const existePeriodo = periodosSeleccionados.find(x => x === periodoId);
    if (!existePeriodo) {
      const periodosAnteriores = periodosSeleccionados;
      periodosAnteriores.push(periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosAnteriores
      });
    } else {
      const periodosFiltrados = periodosSeleccionados.filter(x => x !== periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Programa esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param programaId
     * @returns
  */
  tosslePerOnePrograma(programaId: number): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
    }
    if (this.ProgramaF.value.length == this.programas.length) {
      this.allPrograma.select();
    }
    const programasSeleccionados = this.filtroForm.get('ProgramaF').value;
    const existePrograma = programasSeleccionados.find(x => x === programaId);
    if (!existePrograma) {
      const programasAnteriores = programasSeleccionados;
      programasAnteriores.push(programaId);
      this.filtroForm.patchValue({
        ProgramaF: programasAnteriores
      });
    } else {
      const programasFiltrados = programasSeleccionados.filter(x => x !== programaId);
      this.filtroForm.patchValue({
        ProgramaF: programasFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param campusId
     * @returns
  */
  tosslePerOneCampus(campusId: number): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.allCampus.deselect();
    }
    if (this.CampusF.value.length == this.campusList.length) {
      this.allCampus.select();
    }
    const campusSeleccionados = this.filtroForm.get('CampusF').value;
    const existeCampus = campusSeleccionados.find(x => x === campusId);
    if (!existeCampus) {
      const campusAnteriores = campusSeleccionados;
      campusAnteriores.push(campusId);
      this.filtroForm.patchValue({
        CampusF: campusAnteriores
      });
    } else {
      const campusFiltrados = campusSeleccionados.filter(x => x !== campusId);
      this.filtroForm.patchValue({
        CampusF: campusFiltrados
      });
    }
  }

  /**
   * Verifica si el campo Todos del select Bloque esta seleccionados, si lo esta
   * quita la seleccion de todos los campos, sino los selecciona todos
   * @param bloqueId
   * @returns
*/
  tosslePerOneBloque(bloqueId: number) {
    // this.reiniciarTimer();
    if (this.allBloque.selected) {
      this.allBloque.deselect();
    }
    if (this.bloqueF.value.length == this.BlockLabel.length) {
      this.allBloque.select();
    }
    const bloqueSeleccionados = this.filtroForm.get('bloqueF').value;
    const existeBloque = bloqueSeleccionados.find(x => x === bloqueId);
    if (!existeBloque) {
      const bloqueAnteriores = bloqueSeleccionados;
      bloqueAnteriores.push(bloqueId);
      this.filtroForm.patchValue({
        bloqueF: bloqueAnteriores
      });
    } else {
      const bloqueFiltrados = bloqueSeleccionados.filter(x => x !== bloqueId);
      this.filtroForm.patchValue({
        bloqueId: bloqueFiltrados
      });
    }
  }

  /**
* Verifica si el campo Todos del select atributo esta seleccionados, si lo esta
* quita la seleccion de todos los campos, sino los selecciona todos
* @param AtributoId
* @returns
*/
  tosslePerOneAtributo(AtributoId: number) {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.allAtritbuto.deselect();
    }
    if (this.atributoF.value.length == this.attributes.length) {
      this.allAtritbuto.select();
    }
    const AtributoSeleccionados = this.filtroForm.get('atributoF').value;
    const existeAtributo = AtributoSeleccionados.find(x => x === AtributoId);
    if (!existeAtributo) {
      const AtributoAnteriores = AtributoSeleccionados;
      AtributoAnteriores.push(AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoAnteriores
      });
    } else {
      const AtributoFiltrados = AtributoSeleccionados.filter(x => x !== AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoFiltrados
      });
    }
  }

  /**
   * Obtiene el catalogo de inscripciones para mostrarlos en la tabla principal, agrega una nueva propiedas llamada nombre completo
   * carga los datos a la tabla principal y agrega los datos a un arreglo original
   * @param tipoAlumno
   * @param pagina
   * @param registrosPagina
   * @param filtro
   */
  public cargarInscripciones(tipoAlumno: number, pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): void {
    this.inscripcionService.getInscripciones(tipoAlumno, pagina, registrosPagina, filtro).subscribe((respuestaAlumno: ApiResponse<any>) => {
      respuestaAlumno.data.alumnos = respuestaAlumno.data.alumnos.map(obj =>
      ({
        ...obj,
        identificador: obj.usuario.identificador,
        programaClave: obj.programa.clave
      }));
      if (respuestaAlumno.data) {
        this.arregloFiltroOriginal = respuestaAlumno.data.alumnos;
        this.datafilter = respuestaAlumno.data.alumnos;
        this.dataSource = new MatTableDataSource(this.datafilter);
        console.log("this.dataSource", this.dataSource);
        this.valorInicial = respuestaAlumno.data.cantidadAlumnos;
        this.selection = new SelectionModel<any>(true, []);
      } else {
        this.dataSource = new MatTableDataSource([]);
        this.arregloFiltroOriginal = [];
      }
    }, (err => {
      this.dataSource = new MatTableDataSource([]);
      this.arregloFiltroOriginal = [];
    }))
  }

  /**
   * Selecciona todas las columnas si no lo estan, o bien limpia la seleccion
   */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
   * seleccionados para modificar el check
   * @returns boolean para conocer si todos estan seleccionados
   */
  isAllSelected(): boolean {
    if (this.datafilter) {
      this.numIngresoSeleccionado = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numIngresoSeleccionado === numRows;
    }
  }

  /**
   * La etiqueta de la casilla de verificación de la fila pasada
  */
  checkboxLabel(row?: Alumno): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.alumnoId + 1}`;
  }

  /**
 * Metodo que detecta el cambio de pagina y llama el servicio para traer la siguiente informacion
 * @param event
 */
  public onPaginateChange(event: any) {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      tipoAlumnoId: this.filtroForm.get('tipoAlumno').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatus: this.filtroForm.get('estatus').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value,
      authorizeRegistration: this.filtroForm.get('authorizeRegistrationF').value
    };
    this.cargarInscripciones(0, event.pageIndex + 1, event.pageSize, filtro)
  }

  /**
   * Metodo para buscar los filtros
   */
  public buscarFiltros(): void {
    this.filtrar();
  }

  /**
   * Funcion que sirve para enviar correos y verifica si hay uno o varios segun la seleccion
   * y abre el componente SeguimientoInscripcionCorreoComponent pasandole los datos a eliminar
   * @param btnDataTable
   * @param elemento
   */
  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      this.datosEliminar = elemento;
    } else {
      if (elemento.length === 1) {
        this.datosEliminar = elemento[0];
      } else {
        this.datosEliminar = elemento;
      }
    }
    this.dialog.open(EnviarCorreoComponent, { data: this.datosEliminar, disableClose: true });
  }

  /**
   * Funcion que obtiene los datos filtrados de la tabla principal y los convierte a un archivo de Excel para su descarga
   * @param elemento
   */
  public descargarExcel(elemento?: any): void {
    let IdAlumnos;
    if (elemento != null && elemento.length > 0) {
      IdAlumnos = elemento.map(m => m.alumnoId);
    } else {
      IdAlumnos = null;
    }

    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      tipoAlumnoId: this.filtroForm.get('tipoAlumno').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatus: this.filtroForm.get('estatus').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value,
      authorizeRegistration: this.filtroForm.get('authorizeRegistrationF').value,
      usuariosId: IdAlumnos
    };

    this.inscripcionService.descargarExcel(filtro).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "Reinscripcion");
    });
  }

  /**
   * Vacia el arreglo
   */
  public vaciarArregloModificado(): void {
    this.arregloFiltroModificado.length = 0;
  }

  /**
   * Seccion para los filtros que usa el formulario
   * filtra los datos segun el parametro que ingrese
   */
  public filtro(): void {
    let periodos: any[] = this.periodoF.value == null ? [] : this.periodoF.value;
    let alumno: string = this.idNombre.value == null ? "" : this.idNombre.value;
    let campus: any[] = this.CampusF.value == null ? [] : this.CampusF.value;
    let programa: any[] = this.ProgramaF.value == null ? [] : this.ProgramaF.value;
    let status: any = this.estatusF.value == null ? [] : this.estatusF.value;
    let tipoAlumno: number = this.tipoAlumnoF.value == null ? 0 : this.tipoAlumnoF.value;
    let filtroModificado: any = [];
    let bloque: any[] = this.bloqueF.value == null ? [] : this.bloqueF.value;
    filtroModificado = [...this.arregloFiltroOriginal];
    filtroModificado = filtroModificado.filter(f => { return periodos.indexOf(f.priodoIngreso) > -1 });
    filtroModificado = filtroModificado.filter(f => { return f.nombreCompleto.toLowerCase().search(alumno.toLowerCase()) > -1 || f.usuario.identificador.toString().search(alumno) > -1 });
    filtroModificado = filtroModificado.filter(f => { return campus.indexOf(f.campusId) > -1 });
    filtroModificado = filtroModificado.filter(f => { return programa.indexOf(f.programaId) > -1 });
    filtroModificado = filtroModificado.filter(f => { return bloque.indexOf(f.blockLabelId) > -1 });
    if (status > 0) {
      filtroModificado = filtroModificado.filter(row => row.estatusAcademico == status);
      this.arregloFiltroModificado = this.arregloFiltroModificado.filter(row => row.estatusAcademico == status);
      this.statusSeleccionado = status;
    }
    if (tipoAlumno == 1 || tipoAlumno == 2) {
      if (tipoAlumno == 1) {
        this.tipoDeAlumno = false;
        this.tipoInscripcion = "NI";
      } else {
        this.tipoDeAlumno = true;
        this.tipoInscripcion = "RI";
      }
      filtroModificado = filtroModificado.filter(row => row.tipoAlumno == this.tipoDeAlumno);
      this.arregloFiltroModificado = this.arregloFiltroModificado.filter(row => row.tipoAlumno == this.tipoDeAlumno);
      this.tipoAlumnoSeleccionado = tipoAlumno;
    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<any>(true, []);
  }

  /**
   * Limpia el arreglo de filtro, llena nuevamente los filtros e inicia el formulario, y muestra la tabla sin filtrado
   */
  public LimpiarFiltros(): void {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Obtiene la solicitud (seleccion), checa si es Nuevo Ingreso (NI) o Reingreso (RI), y la pestaña a la cual dirije
   * @param solicitud
   * @param isCheck
   * @param tabRequerido
   */
  public inscribirMaterias(solicitud: any, isCheck: boolean, tabRequerido: string): void {
    if (isCheck) {
      if (!solicitud[0].tipoAlumno) {
        this.tipoInscripcion = "NI";
      } else {
        this.tipoInscripcion = "RI";
      }
      solicitud[0].tipoInscripcion = this.tipoInscripcion
      solicitud.tabRequerido = tabRequerido;
      this.util.abrirDialogoLateral(SeguimientoModalComponent, solicitud);
    } else {
      solicitud.tipoInscripcion = this.tipoInscripcion;
      let solicitudLista: any[] = [];
      solicitudLista.push(solicitud);
      solicitudLista = solicitudLista.map(obj =>
      ({
        ...obj,
        tabRequerido: tabRequerido
      })
      );
      this.util.abrirDialogoLateral(SeguimientoModalComponent, solicitudLista);
    }
  }

  /**
   * Abre el modal donde se encuentran todos los datos para agregar un nuevo usuario
   */
  abrirModal(): void {
    this.util.abrirDialogoLateral(SeguimientoModalComponent, 'variable');
  }

  /**
   * Metodo para hacer la asignaacion de bloques
   * @param blockLabel
   * @param alumnos
   */
  public asignarBloque(blockLabel: BlockLabelDto, alumnos: any[]): void {
    if (this.verificarCampus(alumnos)) {
      this.existeBloque(blockLabel, alumnos);
    } else {
      this.snackBar.open(this.translate("_nuevosIngresos.mismo-campus"), "OK", { duration: 5000 });
    }
  }

  /**
   * Metodo que verifica el campus
   * @param alumnos
   * @returns
   */
  private verificarCampus(alumnos: any[]): boolean {
    const primerAlumno = alumnos[0];
    for (let index = 0; index < alumnos.length; index++) {
      const alumno = alumnos[index];
      if (alumno.campusId != primerAlumno.campusId) {
        return false;
      }
    }
    return true;
  }

  /**
   * Metodo para validaar si existe un bloque
   * @param blockLabel
   * @param alumnos
   */
  private existeBloque(blockLabel: BlockLabelDto, alumnos: any[]): void {
    const primerAlumno = alumnos[0];
    this._BlockService.GetBlockByPeriodoCampus(blockLabel.blockLabelId, primerAlumno.blockPeriodoId, primerAlumno.campusId).subscribe((respuesta: ApiResponse<Block>) => {
      this.Block = respuesta.data;
      this.AbrirModalBloque(blockLabel, alumnos, respuesta.data, respuesta.success);
    });
  }

  /**
   * Metodo paraa haacer traaducciones
   * @param text
   * @returns
   */
  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  /**
   * Metodo paraa abrir el modal lateral del bloque
   * @param blockLabel
   * @param alumnos
   * @param block
   * @param existe
   */
  private AbrirModalBloque(blockLabel: BlockLabelDto, alumnos: any[], block: Block, existe: boolean) {
    let content = {
      alumnos: alumnos,
      blockLabel: blockLabel,
      block: block,
      existe: existe,
      nuevoIngreso: false
    }
    const dialogRef = this.dialog.open(NuevoIngresoAsignarBloqueModalComponent, { data: content, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.selection.clear();
    });
  }

  /**
  * Al momento de cerrar el componente se desuscribe de la descarga de Excel
  */
  ngOnDestroy(): void {
    if (this.ExcelSubscription != null) {
      this.ExcelSubscription.unsubscribe();
    }
  }
}
