import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NullHelper } from 'src/app/helper/Null.Helper';
import { PersonaRelacionadaHelper } from 'src/app/helper/PersonasRelacionadas.Helper';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { NegativeNumberValidator } from 'src/app/validator/NumeroNegativoValidator';
import { SelectValidator } from 'src/app/validator/SelectValidator';
import { DatoFiscal } from 'src/app/_models/dato-fiscal';
import { EntidadFederativa } from 'src/app/_models/entidad-federativa';
import { EstadoCivil } from 'src/app/_models/estado-civil';
import { Genero } from 'src/app/_models/genero';
import { GrupoSanguineo } from 'src/app/_models/grupo-sanguineo';
import { Alumno } from 'src/app/_models/inscripcion';
import { Nivel } from 'src/app/_models/nivel';
import { Ocupacion } from 'src/app/_models/Ocupacion.model';
import { Pais } from 'src/app/_models/pais';
import { PersonaRelacionada } from 'src/app/_models/persona-relacionada';
import { TipoDireccion } from 'src/app/_models/tipo-direccion';
import { TipoEmpresa } from 'src/app/_models/tipo-empresa';
import { TipoPuestoEmpresa } from 'src/app/_models/tipo-puesto-empresa';
import { TipoTelefono } from 'src/app/_models/tipo-telefono';
import { Usuario } from 'src/app/_models/usuario';
import { AdminAcademicaService } from 'src/app/_services/admin-academica.service';
import { BiografiaService } from 'src/app/_services/biografia.service';
import { DireccionService } from 'src/app/_services/direccion.service';
import { EmpresaService } from 'src/app/_services/empresa.service';
import { EstadoCivilService } from 'src/app/_services/estado-civil.service';
import { GeneroService } from 'src/app/_services/genero.service';
import { GrupoSanguineoService } from 'src/app/_services/grupo-sanguineo.service';
import { NivelService } from 'src/app/_services/nivel.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-datos-personales-admin-academica',
  templateUrl: './datos-personales-admin-academica.component.html',
  styleUrls: ['./datos-personales-admin-academica.component.scss']
})
export class DatosPersonalesAdminAcademicaComponent implements OnInit {
  //#region  Constrcutor
  constructor(private formBuilder: FormBuilder,
    public nuevoIngresoService: NuevoIngresoService,
    private adminAcademicaService: AdminAcademicaService,
    private utileriasService: UtileriasService,
    private direccionService: DireccionService,
    private generoService: GeneroService,
    private estadoCivilService: EstadoCivilService,
    private biografiaService: BiografiaService,
    private grupoSanguineoService: GrupoSanguineoService,
    private nivelService: NivelService,
    private empresaService: EmpresaService) {
    this.generarFormularioVacio();
  }
  //#endregion

  //#region  Propiedades
  @Input() alumno: Alumno;
  public tipoDeTelefonos: Array<TipoTelefono>;
  public datosPeronalesForm: FormGroup;
  public alumnoDatosBusqueda: Alumno;
  public tipoDirecciones: Array<TipoDireccion>;
  public paises: Array<Pais>;
  public entidadesFederativas = new Array<EntidadFederativa>();
  public entidadesFederativasPaisFacturacion = new Array<EntidadFederativa>();
  public generos: Array<Genero>;
  public entidadesFederativasPaisNacimiento = new Array<EntidadFederativa>();
  public sexos: Array<Genero>;
  public estadosCiviles: Array<EstadoCivil>;
  public CURPencontrado: boolean;
  public madre: PersonaRelacionada;
  public padre: PersonaRelacionada;
  public conyuge: PersonaRelacionada;
  public emergencia: PersonaRelacionada;
  public tutor: PersonaRelacionada;
  public tiposDeSangre: Array<GrupoSanguineo>;
  public nivelesAcademicos: Array<Nivel>;
  public tipoDeEmpresas: Array<TipoEmpresa>;
  public tipoDePuestos: Array<TipoPuestoEmpresa>;
  //#endregion

  //#region  Métodos
  ngOnInit(): void {
    this.getTipoDireccion();
    this.getPaises();
    this.getGeneros();
    this.getEstadosCiviles();
    this.getTiposDeSangre();
    this.getNivelesAcademicos();
    this.getTipoDePuestosEmpresa();
    this.getTipoDeEmpresas();
    this.getTipoDeTelefono();
    this.adminAcademicaService.ObtenerInformacionUsuario(this.alumno.usuario.identificador).subscribe(
      (alumno: ApiResponse<any>) => {
        this.alumnoDatosBusqueda = alumno.data;
        console.log("this.alumnoDatosBusqueda", this.alumnoDatosBusqueda);
        this.findPersonasRelacionadas();
        this.generarFormulario();
        this.getEntidadesFederativas(this.paisField.value);
        this.getEntidadesFederativasDelPaisNacimiento(this.paisNacimientoField.value);
        this.getEntidadesFederativasPaisFacturacion(this.paisFacturacionField.value);
      });
  }
  /**
   * Consulta el método del api que retorna un listado de los tipos de teléfonos existentes
   */
  getTipoDeTelefono(): void {
    this.direccionService.obtenerTipoTelefono().subscribe((tipoDeTelefonos: Array<TipoTelefono>) => {
      this.tipoDeTelefonos = tipoDeTelefonos;
    });
  }
  /**
   * Consulta el método del Api que carga todos los tipos de direcciones
   */
  public getTipoDireccion(): void {
    this.direccionService.obtenerTipoDireccion().subscribe((tipoDirecciones: Array<TipoDireccion>) => {
      this.tipoDirecciones = tipoDirecciones;
    });
  }
  /**
   * Ejecuta el método del Api que carga los Países
   */
  public getPaises(): void {
    this.direccionService.obtenerPaises().subscribe((paises: Array<Pais>) => {
      this.paises = paises;
    });
  }
  /**
   * Ejecuta el método del Api que consulta la lista de entidades federativas
   * Simpre que se haya seleccionado un país
   * @param IdPais 
   */
  public getEntidadesFederativas(IdPais: number): void {
    if (this.paisField.valid) {
      this.direccionService.obtenerEntidadesFederativas(IdPais).subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
        this.entidadesFederativas = entidadesFederativas;
      });
    }
  }
  /**
   * Ejecuta el método del Api que retorna los géneros existentes
   */
  public getGeneros(): void {
    this.generoService.obtenerGenero().subscribe((generos: Array<Genero>) => {
      this.generos = generos;
      this.sexos = generos;
    });
  }
  /**
   * Ejecuta el me´todo del Api que retorna los estados civiles existentes
   */
  public getEstadosCiviles(): void {
    this.estadoCivilService.obtenerEstadoCivil().subscribe((estodosCiviles: Array<EstadoCivil>) => {
      this.estadosCiviles = estodosCiviles;
    });
  }
  /**
   * Comprueba si la CURP que se está ingresando no ha sido registradac con antelación
   * @param curp 
   */
  public ComprobarDisponibilidadCurp(curp: string) {
    if (curp.trim().length == 18) {
      let usuarioId = this.alumnoDatosBusqueda.usuarioId;
      if (!usuarioId) {
        usuarioId = 0;
      }
      this.biografiaService.obtenerBiografiaByCurp(curp, usuarioId).subscribe(
        biografia => {
          if (biografia == null) {
            return this.CURPencontrado = false;
          } else {
            return this.CURPencontrado = true;
          }
        });
    }
  }
  /**
   * Obtiene las entidades federativas del País de nacimiento
   * @param IdPais 
   */
  public getEntidadesFederativasDelPaisNacimiento(IdPais: number): void {
    if (this.paisNacimientoField.valid) {
      this.direccionService.obtenerEntidadesFederativas(IdPais).subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
        this.entidadesFederativasPaisNacimiento = entidadesFederativas;
      });
    }
  }
  /**
   * Ejecuta el método que busca las personasRelacionada del usuario
   */
  public findPersonasRelacionadas(): void {
    this.padre = PersonaRelacionadaHelper.find(this.alumnoDatosBusqueda.personaRelacionadaUsuario, 1);
    this.madre = PersonaRelacionadaHelper.find(this.alumnoDatosBusqueda.personaRelacionadaUsuario, 2);
    this.tutor = PersonaRelacionadaHelper.find(this.alumnoDatosBusqueda.personaRelacionadaUsuario, 3);
    this.emergencia = PersonaRelacionadaHelper.find(this.alumnoDatosBusqueda.personaRelacionadaUsuario, 4);
    this.conyuge = PersonaRelacionadaHelper.find(this.alumnoDatosBusqueda.personaRelacionadaUsuario, 5);
  }
  /**
   * Obtiene los dinstintos tipo de sagre existentes que se encuentran registrados en el sistema
   */
  public getTiposDeSangre(): void {
    this.grupoSanguineoService.obtenerGrupoSanguineo().subscribe((tiposDeSangre: Array<GrupoSanguineo>) => {
      this.tiposDeSangre = tiposDeSangre;
    });
  }
  /**
   * Ejecuta el método del api que obtiene los niveles académicos disponibles
   */
  public getNivelesAcademicos(): void {
    this.nivelService.getNivel().subscribe((nivelesAcademicos: Array<Nivel>) => {
      this.nivelesAcademicos = nivelesAcademicos;
    });
  }
  /**
   * Obtiene las entidades deferativas del país de facturación seleccionado
   * @param IdPais 
   */
  public getEntidadesFederativasPaisFacturacion(IdPais: number): void {
    if (IdPais > 0) {
      this.direccionService.obtenerEntidadesFederativas(IdPais).subscribe((entidadesFederativasPaisFacturacion: Array<EntidadFederativa>) => {
        this.entidadesFederativasPaisFacturacion = entidadesFederativasPaisFacturacion;
      });
    }
  }
  /**
   * Obtiene los tipos de puestos ingresados en el sistema que una empresa puede tener dentro de ella
   */
  public getTipoDePuestosEmpresa() {
    this.empresaService.obtenerTipoPuestoEmpresa().subscribe((tipoPuestosEmpresa: Array<TipoPuestoEmpresa>) => {
      this.tipoDePuestos = tipoPuestosEmpresa;
    });
  }
  /**
   * Obtiene los tipos de empresa existentes en el sistema
   */
  public getTipoDeEmpresas() {
    this.empresaService.obtenerTipoEmpresa().subscribe((tipoDeEmpresas: Array<TipoEmpresa>) => {
      this.tipoDeEmpresas = tipoDeEmpresas;
    });
  }
  /**
   * Inicia el form reactivo que contiene los datos de la vista
   */
  generarFormulario(): void {
    this.datosPeronalesForm = this.formBuilder.group({
      //Datos del usuario
      nombre: [NullHelper.IsUndefined<string>(this.alumno.usuario.nombre, ''), Validators.required],
      segundoNombre: [NullHelper.IsUndefined<string>(this.alumno.usuario.segundoNombre, '')],
      apellidoPaterno: [NullHelper.IsUndefined<string>(this.alumno.usuario.apellidoPaterno, ''), Validators.required],
      apellidoMaterno: [NullHelper.IsUndefined<string>(this.alumno.usuario.apellidoMaterno, '')],
      fechaNacimiento: [this.utileriasService.formatearFecha(this.alumnoDatosBusqueda.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD'), Validators.required],
      //Datos de contacto 
      correo: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.correo, ''), [Validators.required, Validators.email]],
      tipoDireccion: [NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.direccion[0].tipoDireccionId, 0)],
      pais: [NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.direccion[0].entidadFederativa.paisId, 0)],
      entidadFederativa: [NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.direccion[0].entidadFederativaId, 0)],
      ciudad: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.direccion[0].ciudad, ''), Validators.required],
      calle: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.direccion[0].calle, ''), Validators.required],
      numeroExterior: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.direccion[0].numeroExterior, ''), Validators.required],
      numeroInterior: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.direccion[0].numeroInterior, '')],
      colonia: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.direccion[0].colonia, ''), Validators.required],
      codigoPostal: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.direccion[0].codigoPostal, ''), [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]],
      telefono: [(this.alumnoDatosBusqueda.telefono.length > 0 ? NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.telefono[0].numero, '') : ''), [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]],
      paisIdTelefono: [(this.alumnoDatosBusqueda.telefono.length > 0 ? NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.direccion[0].entidadFederativa.paisId, 0) : 0)],
      tipoTelefono: [(this.alumnoDatosBusqueda.telefono.length > 0 ? NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.telefono[0].tipoTelefonoId, 0) : '')],
      extensionTelefono: [(this.alumnoDatosBusqueda.telefono.length > 0 ? NullHelper.IsUndefined(this.alumnoDatosBusqueda.telefono[0].extension, '') : '')],
      // //Datos biograficos
      sexo: [NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.biografia.legalGeneroId, 0)],
      genero: [NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.biografia.generoId, 0)],
      ciudadania: [NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.biografia.ciudadaniaPaisId, 0)],
      estadoCivil: [NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.biografia.estadoCivilId, 0)],
      curp: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.biografia.curp, ''), [Validators.required, Validators.pattern(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/)]],
      paisNacimiento: [(this.alumnoDatosBusqueda.biografia.entidadNacimientoNavigation != null ? NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.biografia.entidadNacimientoNavigation.paisId, 0) : 0)],
      entidadFederativaNacimiento: [NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.biografia.entidadNacimiento, 0)],
      ciudadNacimiento: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.biografia.ciudadNacimiento, ''), Validators.required],
      // //datos familiares
      nombreMadre: [NullHelper.IsUndefined<string>(this.madre.nombreCompleto, '')],
      telefonoMadre: [NullHelper.IsUndefined<string>(this.madre.telefono, ''), Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)],
      correoMadre: [NullHelper.IsUndefined<string>(this.madre.correoElectronico, ''), Validators.email],
      nombrePadre: [NullHelper.IsUndefined<string>(this.padre.nombreCompleto, '')],
      telefonoPadre: [NullHelper.IsUndefined<string>(this.padre.telefono, ''), Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)],
      correoPadre: [NullHelper.IsUndefined<string>(this.padre.correoElectronico, ''), Validators.email],
      nombreTutor: [NullHelper.IsUndefined<string>(this.tutor.nombreCompleto, '')],
      telefonoTutor: [NullHelper.IsUndefined<string>(this.tutor.telefono, ''), Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)],
      correoTutor: [NullHelper.IsUndefined<string>(this.tutor.correoElectronico, ''), Validators.email],
      nombreEmergencia: [NullHelper.IsUndefined<string>(this.emergencia.nombreCompleto, '')],
      telefonoEmergencia: [NullHelper.IsUndefined<string>(this.emergencia.telefono, ''), Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)],
      correoEmergencia: [NullHelper.IsUndefined<string>(this.emergencia.correoElectronico, ''), Validators.email],
      tipoRelacionPadre: (1),
      tipoRelacionMadre: (2),
      tipoRelacionTutor: (3),
      tipoRelacionEmergencia: (4),
      // //Datos medicos
      tipoDeSangre: [NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.biografia.grupoSanguineoId, 0)],
      alergias: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.biografia.alergias, '')],
      enfermedades: [NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.biografia.enfermedades, '')],
      //estudiosPrevios
      nivelAcademicoPrevio: [(this.alumnoDatosBusqueda.estudioPrevio.length > 0 ? NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.estudioPrevio[0].nivelId, 0) : 0)],
      escuelaDeProcedencia: [(this.alumnoDatosBusqueda.estudioPrevio.length > 0 ? NullHelper.IsUndefined<string>(this.alumnoDatosBusqueda.estudioPrevio[0].escuela, '') : ''), Validators.required],
      promedioPrevio: [(this.alumnoDatosBusqueda.estudioPrevio.length > 0 ? NullHelper.IsUndefined<number>(this.alumnoDatosBusqueda.estudioPrevio[0].promedio, 0) : ''), [Validators.required, Validators.pattern(/^[1-9]+/)]],
      // //DatosFiscales
      nombreRazonSocial: [NullHelper.IsPropertyUndefined<string, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'razonSocial', '')],
      rfc: [NullHelper.IsPropertyUndefined<string, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'rfc', '')],
      paisFactura: [NullHelper.IsPropertyUndefined<number, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'entidadFederativa.paisId', 0)],
      ciudadFacturacion: [NullHelper.IsPropertyUndefined<string, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'ciudad', '')],
      calleFacturacion: [NullHelper.IsPropertyUndefined<string, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'calle', '')],
      numeroExteriorFacturacion: [NullHelper.IsPropertyUndefined<string, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'numeroExterior', '')],
      numeroInteriorFacturacion: [NullHelper.IsPropertyUndefined<string, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'numeroInterior', '')],
      coloniaFacturacion: [NullHelper.IsPropertyUndefined<string, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'colonia', '')],
      codigoPostalFacturacion: [NullHelper.IsPropertyUndefined<string, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'codigoPostal', ''), Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)],
      estadoFacturacion: [NullHelper.IsPropertyUndefined<number, DatoFiscal>(this.alumnoDatosBusqueda.datoFiscal, 'entidadFederativaId', 0)],
      // //Ocupación
      tipoDeEmpresaOcupacion: [NullHelper.IsPropertyUndefined<number, Ocupacion>(this.alumnoDatosBusqueda.ocupacion, 'tipoEmpresaId', 0)],
      tipoDePuestoOcupacion: [NullHelper.IsPropertyUndefined<number, Ocupacion>(this.alumnoDatosBusqueda.ocupacion, 'tipoPuestoEmpresaId', 0)]
    },
      {
        validators: [SelectValidator('pais'), SelectValidator('tipoDireccion'),
        SelectValidator('entidadFederativa'), SelectValidator('tipoTelefono'),
        SelectValidator('sexo'), SelectValidator('ciudadania'),
        SelectValidator('paisNacimiento'), SelectValidator('entidadFederativaNacimiento'),
        SelectValidator('nivelAcademicoPrevio'),
        NegativeNumberValidator('numeroExteriorFacturacion'), NegativeNumberValidator('numeroInteriorFacturacion')
        ]
      }
    );
    this.escucharCambiosDelFormulario();
  }
  /**
   * Genera el formulario sin datos para no mostrar el form desmaquedado al cargar
   */
  generarFormularioVacio(): void {
    this.datosPeronalesForm = this.formBuilder.group({
      nombre: [''],
      segundoNombre: [''],
      apellidoPaterno: [''],
      apellidoMaterno: [''],
      fechaNacimiento: [this.utileriasService.formatearFecha(new Date(), 'YYYY[-]MM[-]DD')],
      correo: [''],
      tipoDireccion: [''],
      pais: [''],
      entidadFederativa: [''],
      ciudad: [''],
      calle: [''],
      numeroExterior: [''],
      numeroInterior: [''],
      colonia: [''],
      codigoPostal: [''],
      telefono: [''],
      paisIdTelefono: [''],
      tipoTelefono: [''],
      extensionTelefono: [''],
      sexo: [''],
      genero: [''],
      ciudadania: [''],
      estadoCivil: [''],
      curp: [''],
      paisNacimiento: [''],
      entidadFederativaNacimiento: [''],
      ciudadNacimiento: [''],
      nombreMadre: [''],
      telefonoMadre: [''],
      correoMadre: [''],
      nombrePadre: [''],
      telefonoPadre: [''],
      correoPadre: [''],
      nombreTutor: [''],
      telefonoTutor: [''],
      correoTutor: [''],
      nombreEmergencia: [''],
      telefonoEmergencia: [''],
      correoEmergencia: [''],
      tipoDeSangre: [''],
      alergias: [''],
      enfermedades: [''],
      nivelAcademicoPrevio: [''],
      escuelaDeProcedencia: [''],
      promedioPrevio: [''],
      nombreRazonSocial: [''],
      rfc: [''],
      paisFactura: [''],
      estadoFacturacion: [''],
      ciudadFacturacion: [''],
      calleFacturacion: [''],
      numeroExteriorFacturacion: [''],
      numeroInteriorFacturacion: [''],
      coloniaFacturacion: [''],
      codigoPostalFacturacion: [''],
      tipoDeEmpresaOcupacion: [''],
      tipoDePuestoOcupacion: ['']
    });
  }
  /**
   * Detecta el cambio de estado del formulario, cualquier cambio
   * y lo notifica, esto sirve para validar si esl From es válido o no 
   * desde el componente padre
   */
  public escucharCambiosDelFormulario(): void {
    let validarForm = {
      renderizado: true,
      estado: this.datosPeronalesForm.invalid
    }
    this.adminAcademicaService.formularioDatosUsuarioHaCambiado(validarForm);
    this.datosPeronalesForm.valueChanges.subscribe((changes) => {
      let validarForm = {
        renderizado: false,
        estado: this.datosPeronalesForm.invalid
      }
      this.adminAcademicaService.formularioDatosUsuarioHaCambiado(validarForm);
    });
  }

  //#endregion

  //#region  Gets
  get nombreFiel() {
    return this.datosPeronalesForm.get('nombre');
  }
  get segundoNombreField() {
    return this.datosPeronalesForm.get('segundoNombre');
  }
  get apellidoPaternoField() {
    return this.datosPeronalesForm.get('apellidoPaterno');
  }
  get fechaNacimientoField() {
    return this.datosPeronalesForm.get('fechaNacimiento');
  }
  get correoField() {
    return this.datosPeronalesForm.get('correo');
  }
  get tipoDireccionField() {
    return this.datosPeronalesForm.get('tipoDireccion');
  }
  get paisField() {
    return this.datosPeronalesForm.get('pais');
  }
  get entidadFederativaField() {
    return this.datosPeronalesForm.get('entidadFederativa');
  }
  get ciudadField() {
    return this.datosPeronalesForm.get('ciudad');
  }
  get calleField() {
    return this.datosPeronalesForm.get('calle');
  }
  get numeroExteriorField() {
    return this.datosPeronalesForm.get('numeroExterior');
  }
  get coloniaField() {
    return this.datosPeronalesForm.get('colonia');
  }
  get codigoPostalField() {
    return this.datosPeronalesForm.get('codigoPostal');
  }
  get sexoField() {
    return this.datosPeronalesForm.get('sexo');
  }
  get ciudadaniaField() {
    return this.datosPeronalesForm.get('ciudadania');
  }
  get curpField() {
    return this.datosPeronalesForm.get('curp');
  }
  get paisNacimientoField() {
    return this.datosPeronalesForm.get('paisNacimiento');
  }
  get entidadFederativaNacimientoField() {
    return this.datosPeronalesForm.get('entidadFederativaNacimiento');
  }
  get ciudadNacimientoField() {
    return this.datosPeronalesForm.get('ciudadNacimiento');
  }
  get correoDeLamadreField() {
    return this.datosPeronalesForm.get('correoMadre');
  }
  get telefonoDeLamadreField() {
    return this.datosPeronalesForm.get('telefonoMadre');
  }
  get correoDelPadreField() {
    return this.datosPeronalesForm.get('correoPadre');
  }
  get telefonoDelPadreField() {
    return this.datosPeronalesForm.get('telefonoPadre');
  }
  get correoDelTutorField() {
    return this.datosPeronalesForm.get('correoTutor');
  }
  get telefonoDelTutorField() {
    return this.datosPeronalesForm.get('telefonoTutor');
  }
  get escuelaProcedenciarField() {
    return this.datosPeronalesForm.get('escuelaDeProcedencia');
  }
  get promedioPrevioField() {
    return this.datosPeronalesForm.get('promedioPrevio');
  }
  get nivelAcademicoPrevioField() {
    return this.datosPeronalesForm.get('nivelAcademicoPrevio');
  }
  get nombreRazonSocialField() {
    return this.datosPeronalesForm.get('nombreRazonSocial');
  }
  get paisFacturacionField() {
    return this.datosPeronalesForm.get('paisFactura');
  }
  get numeroExteriorFacturacionField() {
    return this.datosPeronalesForm.get('numeroExteriorFacturacion');
  }
  get numeroInteriorFacturacionFiel() {
    return this.datosPeronalesForm.get('numeroInteriorFacturacion');
  }
  get codigoPostalFacturacionField() {
    return this.datosPeronalesForm.get('codigoPostalFacturacion');
  }
  get telefonoField() {
    return this.datosPeronalesForm.get('telefono');
  }
  get tipoTelefonoField() {
    return this.datosPeronalesForm.get('tipoTelefono');
  }
  get telefonoEmergenciaField() {
    return this.datosPeronalesForm.get('telefonoEmergencia');
  }
  get correoEmergenciaField() {
    return this.datosPeronalesForm.get('correoEmergencia');
  }
  //#endregion
}
