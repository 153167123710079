import { Router } from '@angular/router';
import { UtileriasService } from './../_services/utilerias.service';
import { AuthenticationService } from './../_services/authentication.service';
import { SnackService } from './../services/snack-service.service';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { CursosAlumnoDashboard, DashboardAlumnoDto, PorHacerDashboardAlumno } from './../_models/dashboardAlumnoDto';
import { DashboardAlumnoService } from './../_services/dashboard-alumno.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import i18next from 'i18next';
import { InstitucionService } from '@app/_services/institucion.service';
import { AdministracionOpcion } from '@app/_models/AdministracionOpcion';

@Component({
  selector: 'app-dashboard-alumno',
  templateUrl: './dashboard-alumno.component.html',
  styleUrls: ['./dashboard-alumno.component.scss']
})
export class DashboardAlumnoComponent implements OnInit, OnDestroy {

  //#region
  public avisos: any = [
    {
      nombre: "Junta virtual con padres de familia",
      horario: "Mar 26 de Octubre, 7:00 pm"
    },
    {
      nombre: "Taller sobre servicios bibliotecarios",
      horario: "Mie 27 de Octubre, 12:00 pm"
    },
    {
      nombre: "Sesión informativa sobre intercambios",
      horario: "Jue 28 de Octubre, 6:00 pm"
    },
    {
      nombre: "Posada",
      horario: "Jue 28 de Octubre, 6:00 pm"
    },
    {
      nombre: "Cena celebración navidad",
      horario: "Jue 28 de Octubre, 6:00 pm"
    }
  ];
  //#endregion

  //Variable identificador alumno
  public identificadorAlumno: string = "";
  //Variable foto perfil
  public fotoPerfilBase64: string = "";
  //Variable subcripcion
  public subscription: Subscription = new Subscription();
  //Variable renderizar vista
  public renderizarDashboard: boolean = false;
  //Vatiable informacion dashboard
  public informacion: DashboardAlumnoDto = new DashboardAlumnoDto();
  //Variables grafica
  public color: string = '#2E75B6';
  public mode: string = 'determinate';
  public pocentajeValor: number = 0;
  //Variables paginador
  public page: number = 1;
  public pageSize: number = 3;
  public pageAvisos: number = 1;
  public pageSizeAvisos: number = 3;
  //Variable lenguaje
  private curentLanguaje: string;
  public administracionOpcion: AdministracionOpcion = null;
  //Variables para saber columnas
  public estiloColumnaUnoMostrar: string = '';
  public estiloColumnaDosMostrar: string = '';
  public estiloColumnaTresMostrar: string = '';


  constructor(private DashboardAlumnoService: DashboardAlumnoService,
    private SnackService: SnackService,
    private authenticationService: AuthenticationService,
    public utileriasService: UtileriasService,
    private institucionService: InstitucionService,
    private router: Router) {

  }

  /**
   * Se ejecuta al iniciar el componente
   */
  ngOnInit(): void {
    if (!!this.authenticationService.isAuthenticated) {
      this.curentLanguaje = i18next.language;
      this.fotoPerfilBase64 = localStorage.getItem('fotoPerfil');
      this.identificadorAlumno = sessionStorage.getItem('identificador');
      this.subscription.add(
        this.DashboardAlumnoService.infoDashboardAlumno(this.identificadorAlumno, this.curentLanguaje).subscribe((response: ApiResponse<DashboardAlumnoDto>) => {
          if (response.success) {
            this.pocentajeValor = response.data.avanceProgramaDashboardAlumno.avance;
            this.informacion = response.data;
            console.log("this.informacion", this.informacion)
            this.renderizarDashboard = true;
          } else {
            this.SnackService.mostrarSnackBack(response.message);
          }
        })
      );
      this.obtenerOpcionesBoton();
    }
  }

  public validarColumnnas(): void {
    //3 columnas 40 40 20
    if ((this.administracionOpcion.misCursos || this.administracionOpcion.porHacer) && this.administracionOpcion.telefonoWhatsApp) {
      this.estiloColumnaUnoMostrar = 'tres-columnas';
      this.estiloColumnaDosMostrar = 'tres-columnas';
      this.estiloColumnaTresMostrar = 'tercera-columna';
    }
    //2 columnas 50 50
    if ((this.administracionOpcion.misCursos || this.administracionOpcion.porHacer) && !this.administracionOpcion.telefonoWhatsApp) {
      this.estiloColumnaUnoMostrar = 'dos-columnas';
      this.estiloColumnaDosMostrar = 'dos-columnas';
      this.estiloColumnaTresMostrar = 'sin-margen';
    }
    //2 columnas 80 20
    if (!this.administracionOpcion.misCursos && !this.administracionOpcion.porHacer && this.administracionOpcion.telefonoWhatsApp) {
      this.estiloColumnaUnoMostrar = 'primera-columna';
      this.estiloColumnaDosMostrar = 'sin-margen';
      this.estiloColumnaTresMostrar = 'tercera-columna';
    }
    //1 columna 100
    if (!this.administracionOpcion.misCursos && !this.administracionOpcion.porHacer && !this.administracionOpcion.telefonoWhatsApp) {
      this.estiloColumnaUnoMostrar = 'una-columna';
      this.estiloColumnaDosMostrar = 'sin-margen';
      this.estiloColumnaTresMostrar = 'sin-margen';
    }
  }

  public obtenerOpcionesBoton(): void {
    this.institucionService.obtenerOpcionesBoton().subscribe((response: ApiResponse<AdministracionOpcion>) => {
      if (response.success) {
        this.administracionOpcion = response.data;
        this.validarColumnnas();
      } else {
        this.SnackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
   * Metodo para redireccionar al componente solicitado
   * @param direccion
   */
  public redireccionarComponente(direccion: string): void {
    this.router.navigate([`/${direccion}`]);
  }

  /**
   * Metodo para redireccionar al componente solicitado
   * @param recurso
   */
  public redireccionarComponentePorHacer(recurso: PorHacerDashboardAlumno): void {
    this.router.navigate([`/detalle-grupo-alumno/grupo/${recurso.grupoId}/opcion/porhacer/recurso/${recurso.recursoId}`]);
  }

  /**
   * Metodo para redireccionar al componente solicitado
   * @param recurso
   */
  public redireccionarComponenteMisCursos(recurso: CursosAlumnoDashboard): void {
    this.router.navigate([`/detalle-grupo-alumno/grupo/${recurso.grupoId}/opcion/micurso`]);
  }

  /**
   * Se ejecuta al destruir el componente
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.renderizarDashboard = false;
  }

}
