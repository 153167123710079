export class CONTACT_INFORMATION_MODEL {

    correo                : string;
    tipoDireccionId       : number;
    paisId                : number;
    entidadFederativaId   : number;
    ciudad                : string;
    calle                 : string;
    numeroExterior        : string;
    numeroInterior        : string;
    colonia               : string;
    codigoPostal          : string;
    tipoTelefonoId        : number;
    numero                : string;
    extension             : string;

    constructor(
        correo                : string,
        tipoDireccionId       : number,
        paisId                : number,
        entidadFederativaId   : number,
        ciudad                : string,
        calle                 : string,
        numeroExterior        : string,
        numeroInterior        : string,
        colonia               : string,
        codigoPostal          : string,
        tipoTelefonoId        : number,
        numero                : string,
        extension             : string
    ){
        this.correo                = correo             ;
        this.tipoDireccionId       = tipoDireccionId    ;
        this.paisId                = paisId             ;
        this.entidadFederativaId   = entidadFederativaId;
        this.ciudad                = ciudad             ;
        this.calle                 = calle              ;
        this.numeroExterior        = numeroExterior     ;
        this.numeroInterior        = numeroInterior     ;
        this.colonia               = colonia            ;
        this.codigoPostal          = codigoPostal       ;
        this.tipoTelefonoId        = tipoTelefonoId     ;
        this.numero                = numero             ;
        this.extension             = extension          ;
    }
  
  };