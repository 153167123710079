export class MakeUpExamReasonModel  {
    makeUpExamReasonId  :       number;
    institutionId       :       string;
    key                 :       string;
    name                :       string;

  constructor(
    makeUpExamReasonId  :       number,
    institutionId       :       string,
    key                 :       string,
    name                :       string
  ) {
    this.makeUpExamReasonId = makeUpExamReasonId;
    this.institutionId      = institutionId;
    this.key                = key;
    this.name               = name;
  }

  static mapBackEnd(data: any): MakeUpExamReasonModel {
   
    return new MakeUpExamReasonModel(
      data.makeUpExamReasonId,   
      data.institutionId,
      data.key,
      data.name
    );    
  }   
  
}
