import { StudentChangeService } from './../../../_services/student-change.service';
import { AdminAcademicaService } from './../../../_services/admin-academica.service';
import { StudentStatusChangeDTO } from './../../../_models/StudentStatusChange';
import { StatusChangeService } from './../../../_services/status-change.service';
import { CambioEstatusModalComponent } from './cambio-estatus-modal/cambio-estatus-modal.component';
import { Component, Input, OnInit } from '@angular/core';
import { Alumno } from 'src/app/_models/inscripcion';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-estatus',
  templateUrl: './estatus.component.html',
  styleUrls: ['./estatus.component.scss']
})
export class EstatusComponent implements OnInit {

  //#region Properties
  private Subscription: Subscription = new Subscription();
  public StudentStatusChangeDTO: StudentStatusChangeDTO[];

  public IsStudentStatusChange: boolean = false;

    //#region MatTable
    dataSource: MatTableDataSource<StudentStatusChangeDTO>;
    displayedColumns: string[] = [
      'periodoClave',
      'campusClave',
      'programaClave',
      'fechaCambio',
      'usuario',
      'estatus'];
    //#endregion


  @Input() data: {alumno: Alumno, indexTap:number};
  //#endregion

  //#region Life Cycle
  constructor(
    private _StatusChangeService: StatusChangeService,
    private adminAcademiaService: AdminAcademicaService,
    private studentChangeService: StudentChangeService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.GetStudentStatusChangEByAlumnoId();
  }
  //#endregion


  //#region Methods Api
  public GetStudentStatusChangEByAlumnoId(): void
{
  this.Subscription.add(
    this._StatusChangeService.GetStudentStatusChangEByAlumnoId(this.data.alumno.alumnoId).subscribe(
      (resp : ApiResponse<StudentStatusChangeDTO[]>) => {
        this.StudentStatusChangeDTO = resp.data;

        if( this.StudentStatusChangeDTO.length > 0){
          this.IsStudentStatusChange = true;
          this.dataSource = new MatTableDataSource(this.StudentStatusChangeDTO);
        }else{
          this.IsStudentStatusChange = false;
        }
      }
    )
  );
}

  //#endregion


  //#region Open Modal
  public AbrirModal(): void
  {
    const dialogRef = this.dialog.open(CambioEstatusModalComponent, { data: this.data.alumno, disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.GetStudentStatusChangEByAlumnoId();
        this.studentChangeService.invocarMetodoObtenerContenido();
        this.adminAcademiaService.actualizarDatosTabla();
      }
    });
  }
  //#endregion
}
