import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { RegistrarSolicitanteComponent } from '../admisiones-alumno/registrar-solicitante/registrar-solicitante.component';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { EstatusUsuarioEnum } from '../_models/estatusUsuario.enum';
import { AdmisionesService } from '../_services/admisiones.service';
import { UtileriasService } from '../_services/utilerias.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  public NombreCompleto: string = "";
  public identificador: string = "";
  public usuarioRol: any = null;
  public ocultar: boolean = true;
  public mostrarDash: boolean = true;

  public infoInstitucionSer$: Observable<any>;
  public infoInstitucionSer: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public util: UtileriasService,
    private admisionesService: AdmisionesService,
    private msalService: MsalService
  ) { }

  ngOnInit(): void {
    this.infoInstitucionSer$ = this.admisionesService.getInstitucion$();
    this.infoInstitucionSer$.subscribe(
      (info: any) => {
        this.infoInstitucionSer = info;
      });
    if (this.router.isActive('/signout', false)) {
      this.authenticationService.signout();
      this.router.navigate(['/']);
    } else {
      if (!!this.authenticationService.isAuthenticated) {
        this.admisionesService.validarPagoColegiatura().subscribe((respuesta: any) => {
          if (!respuesta.noExisteAlumno && (respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.candidato || respuesta.alumnoInfo.estatusAcademico == EstatusUsuarioEnum.prospecto)) {
            if (this.infoInstitucionSer.academicManagementModelId == 1) {
              this.router.navigate(['/admisiones']);
            }
          }
        });
      }

      if (this.route.snapshot.fragment === "/register") {
        this.util.abrirDialogoLateral(RegistrarSolicitanteComponent);
      }
    }
  }

  /**
   * Metodo que abre un modal para el registro de un nuevo usuario
   */
  public registrarUsuario(): void {
    this.util.abrirDialogoLateral(RegistrarSolicitanteComponent);
  }

}
