import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { Edificio } from '../_models/edificio';

@Injectable({
  providedIn: 'root'
})
export class EdificioService {
  invocarObtenerEdificio = new EventEmitter();

  obtenerEdificioSubscription: Subscription;
  constructor(private httpClient: HttpClient) { }
   //Obtener Configuracion Edificio
   public getEdificio():Observable<any>{
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Edificio`);
  }
  //Guarda Configuracion Edificio
  public postEdificio(lugar: Edificio): Observable<any>{
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Edificio`, lugar);
  }
  //Editar Configuracion Edificio
  public editarEdificio(lugar: Edificio): Observable<any>{
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Edificio`, lugar);
  }
  //Eliminar Configuracion Edificio
  public eliminarEdificio(edificioId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Edificio?edificioId=` + edificioId);
  }

  // public eliminarEdificio(lugar: Edificio): Observable<any>{
  //   return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/Edificio/Eliminar`, lugar);
  // }

  //Invocar Método obtenerEdificio
  public invocarMetodoObtenerEdificio() {
    this.invocarObtenerEdificio.emit();
  }
}
