import { CampusService } from './../../../../_services/campus.service';
import { CampusProgramaDTO } from './../../../../_models/CampusProgramaDTO';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { Periodo } from '../../../../_models/periodo';
import { PeriodoService } from '../../../../_services/periodo.service';
import { ApiResponse } from '../../../../models/api/ApiRespose.model';
import { BlockLabelService } from '../../../../_services/block-label.service';
import { BlockLabel } from '../../../../_models/blockLabel';

@Component({
  selector: 'app-registrar-bloque',
  templateUrl: './registrar-bloque.component.html',
  styleUrls: ['./registrar-bloque.component.scss']
})
export class RegistrarBloqueComponent implements OnInit {
  public nuevoBloqueForm: FormGroup;
  public periodoList: Periodo[] = [];
  public campusList: CampusProgramaDTO[] = [];
  public nombreBloqueList: BlockLabel[] = [];
  public subscriptionPeriodo: Subscription;
  public subscritpionCampus: Subscription;

  constructor(private periodoService: PeriodoService,
    private campusService: CampusService,
    private blockLabelService: BlockLabelService) { }

  ngOnInit(): void {
    this.getTipoPeriodo();
    this.getTipoCampus();
    this.getNombreBloque();
    this.inicializarForm();
  }


  public inicializarForm() {
    this.nuevoBloqueForm = new FormGroup({

      capacidad: new FormControl(""),
      nombreBloque: new FormControl(0, [Validators.required, this.validateId]),
      periodo: new FormControl(0, [Validators.required, this.validateId]),
      campus: new FormControl(0, [Validators.required, this.validateId]),
    });
    // this.nuevoSolicitudForm.valueChanges.subscribe(val => {
    //   this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.nuevoSolicitudForm.valid);
    // });
  }

  public getTipoPeriodo() {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoList = periodo;
      });
  }
  public getTipoCampus() {
    this.subscritpionCampus = this.campusService.obtenerCampusconProgramaActivo().subscribe(
      (campus: ApiResponse<CampusProgramaDTO[]>) => {
        this.campusList = campus.data;
      });
  }

  public getNombreBloque() {
    this.blockLabelService.obtenerBlockLabels().subscribe(
      (response: ApiResponse<BlockLabel[]>) => {
        this.nombreBloqueList = response.data.filter(x => x.status === true);;
      })
  }

  public onNombreBloqueSelected(blockLabeliD: number) {
    if (blockLabeliD != 0) {
      let blockLabel;
      blockLabel = this.nombreBloqueList.filter(block => block.blockLabelId === blockLabeliD);

      this.capacidad.setValue(blockLabel[0].capacity);

    }
  }



  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.nuevoBloqueForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }



  get periodo() { return this.nuevoBloqueForm.get('periodo'); }
  get campus() { return this.nuevoBloqueForm.get('campus'); }
  get nombreBloque() { return this.nuevoBloqueForm.get('nombreBloque'); }
  get capacidad() { return this.nuevoBloqueForm.get('capacidad'); }



}
