import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioService } from '@app/_services/usuario.service';
import { Persona } from '@app/_models/persona';
import { NgDialogAnimationService } from "ng-dialog-animation";
import { UsuarioEdicionComponent } from '@app/usuario-edicion/usuario-edicion.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
  public usuarios: Persona[] = [];
  constructor(
    public dialog: NgDialogAnimationService,
    private usuarioService: UsuarioService,
  ) { }

  ngOnInit(): void {
    this.usuarioService.obtenerUsuarios().subscribe(usuarios => this.usuarios = usuarios);
  }

  public mostrar(id: number){
    const dialogo = this.dialog.open(UsuarioEdicionComponent, {
      data: id,
      position: {
        top: "0",
        right: "0",
        bottom: "0"
      },
      animation:{to:"left"},
      height: '100vh',
      maxHeight: '100vh',
      maxWidth: '800px'
    });

    
  }

}
