import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Campus, InfoPrograma, Nivel, Periodo } from 'src/app/_models';
import { CampusService, NivelService, PeriodoService } from 'src/app/_services';
import { InfoProgramaService } from 'src/app/_services/infoPrograma.service';

@Component({
  selector: 'app-lead-academic-interest',
  templateUrl: './lead-academic-interest.component.html',
  styleUrls: ['./lead-academic-interest.component.scss'],
})
export class LeadAcademicInterestComponent implements OnInit {
  public interestAForm: FormGroup;

  constructor(
    private periodoService: PeriodoService,
    private campusService: CampusService,
    private nivelservice: NivelService,
    private programaservice: InfoProgramaService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allNivel') private allNivel: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;

  public campusList: Campus[] = [];
  public periodoList: Periodo[] = [];
  public nivelList: Nivel[] = [];
  public programaList: InfoPrograma[] = [];

  periodoF = new FormControl();
  campusF = new FormControl();
  nivelF = new FormControl();
  programaF = new FormControl();

  ngOnInit(): void {
    this.obtenerPeriodo();
    this.obtenerCampus();
    this.obtenerProgramas();
    this.obtenerNiveles();
    this.inicializarForm();
    console.log('academic inter tab');
  }

  public inicializarForm() {
    this.interestAForm = new FormGroup({
      periodoF: this.periodoF,
      campusF: this.campusF,
      nivelF: this.nivelF,
      programaF: this.programaF,
      campus: new FormControl(this.data ? this.data.campusId : ''),
      programaAcademico: new FormControl(this.data ? this.data.programId : ''),
      periodo: new FormControl(this.data ? this.data.termId : ''),
    });
    this.periodoF.setValue['-1'];
    this.campusF.setValue['-1'];
    this.nivelF.setValue['-1']; // No se registra, no hay campo en DB
    this.programaF.setValue['-1'];
  }

  public obtenerPeriodo(): void {
    this.periodoService.obtenerPeriodos().subscribe((periodo: Periodo[]) => {
      this.periodoList = periodo;
      if (this.data && this.data.leadInterestTerm) {
        this.periodoF.setValue([...this.data.leadInterestTerm.map((item) => item.termId)]);
      }
    });
  }

  public obtenerCampus(): void {
    this.campusService.obtenerCampus().subscribe((campus: Campus[]) => {
      this.campusList = campus;
      if (this.data && this.data.leadInterestCampus) {
        this.campusF.setValue([...this.data.leadInterestCampus.map((item) => item.campusId)]);
      }
    });
  }

  public obtenerNiveles(): void {
    this.nivelservice.getNivel().subscribe((nivel: Nivel[]) => {
      this.nivelList = nivel;
      if (this.data && this.data.leadInterestLevel) {
        this.nivelF.setValue([...this.data.leadInterestLevel.map((item) => item.levelId)]);
      }
    });
  }

  public obtenerProgramas(): void {
    this.programaservice.obtenerInfoPrograma().subscribe((programas: InfoPrograma[]) => {
      this.programaList = programas;
      if (this.data && this.data.leadInterestProgram) {
        this.programaF.setValue([...this.data.leadInterestProgram.map((item) => item.programId)]);
      }
    });
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.interestAForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = 'El campo es requerido.';
    }
    return error;
  }

  //#region  Funciones checkbox
  toggleAllSelectionPeriodo() {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.periodoList.map((item) => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
    //this.filtro();
  }

  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      //this.campusF.patchValue([...this.campusList.map(({ campusId }) => ({ campusId })), { campusId: 0 }]);
      this.campusF.patchValue([...this.campusList.map((item) => item.campusId), 0]);
    } else {
      this.campusF.patchValue([]);
    }
    //this.filtro();
  }

  toggleAllSelectionNivel() {
    if (this.allNivel.selected) {
      this.nivelF.patchValue([...this.nivelList.map((item) => item.nivelId), 0]);
    } else {
      this.nivelF.patchValue([]);
    }
    //this.filtro();
  }

  toggleAllSelectionPrograma() {
    if (this.allPrograma.selected) {
      this.programaF.patchValue([...this.programaList.map((item) => item.programaId), 0]);
    } else {
      this.programaF.patchValue([]);
    }
    //this.filtro();
  }

  tosslePerOnePeriodo() {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if (this.periodoF.value.length == this.periodoList.length) {
      this.allPeriodo.select();
    }
    //this.filtro();
  }

  tosslePerOneCampus() {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.campusF.value.length == this.campusList.length) {
      this.allCampus.select();
    }
    //this.filtro();
  }

  tosslePerOneNivel() {
    if (this.allNivel.selected) {
      this.allNivel.deselect();
      return false;
    }
    if (this.nivelF.value.length == this.nivelList.length) {
      this.allNivel.select();
    }
    //this.filtro();
  }

  tosslePerOnePrograma() {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.programaF.value.length == this.programaList.length) {
      this.allPrograma.select();
    }
    //this.filtro();
  }

  //#endregion
}
