import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oferta-academica',
  templateUrl: './oferta-academica.component.html',
  styleUrls: ['./oferta-academica.component.scss']
})
export class OfertaAcademicaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
