export class TipoMotivoCalificacion {
    /**
     * Identificador del cambio de calificacion
     */
    tipoMotivoCalificacionID:   string;
    /**
     * Nombre del tipo de motivo de calificacion
     */
    nombre:       string;
  
    /**
     * Constructor para agregar mas informacion del cambio de calificacion
     * @param _TipoMotivoCalificacion 
     */
    constructor(_TipoMotivoCalificacion: Object) {
        Object.assign(this, _TipoMotivoCalificacion);
    }
    
}
