import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { Client } from '@microsoft/microsoft-graph-client';

import { environment, baseUrl } from '@environments/environment';
import { Usuario } from '@app/_models/usuario';
import { AuthenticationResult } from '@azure/msal-browser';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { UserRolDto, UserRolPermissionDto } from '@app/_models/UserRolPermissionDto';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Usuario>;
  public currentUser: Observable<Usuario>;
  private userRolID$ = new BehaviorSubject<number>(0);
  private userPermissionsSubject: BehaviorSubject<UserRolPermissionDto>;
  public userRolID2$: Observable<number>;
  public userRolID: number;

  constructor(
    private http: HttpClient,
    private authService: MsalService,
  ) {
    this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.userRolID2$ = this.getUserRol$();
    this.userRolID$.subscribe(rolId => this.userRolID = rolId);
    console.log("ROL SELECCIONADO", this.userRolID)
  }

  public get currentUserValue(): Usuario {
    return this.currentUserSubject.value;
  }

  public get isAuthenticated(): boolean {
    return !!this.authService.instance.getActiveAccount();
  }

  refreshToken(): Promise<AuthenticationResult> {
    return this.authService.acquireTokenSilent({
      scopes: ["user.read"]
    }).toPromise();
  }

  // Silently request an access token
  async getAccessToken(): Promise<string> {
    let result = await this.authService.acquireTokenSilent({
      scopes: ["user.read"]
    }).toPromise()
      .catch((reason) => {
        console.log('Get token failed', JSON.stringify(reason, null, 2));
      });

    if (result) {
      return result.accessToken;
    }

    // Couldn't get a token
    return null;
  }

  public async actualizarPassword(oldPassword: string, newPassword: string): Promise<boolean> {
    if (!this.isAuthenticated) return null;

    let graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        let token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });

    // Get the user from Graph (GET /me)
    let graphUser: any = await graphClient
      .api('/me/changePassword')
      .post({
        currentPassword: oldPassword,
        newPassword: newPassword
      });

    console.log("graph", graphUser);

    return true;
  }

  public setUser(usuario: Usuario) {
    localStorage.setItem('currentUser', JSON.stringify(usuario));
    this.currentUserSubject.next(usuario);
  }

  login() {

    return this.http.get<Usuario>(`${environment.enves[baseUrl].apiUrl}/login`)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    this.authService.logout();
  }

  //Método que limpia galletas y sesiones después de un logout de AD
  signout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userPermission');
    this.currentUserSubject.next(null);

  }
  public obtenerDatosUsuario(identificador): Observable<Usuario> {
    return this.http.get<Usuario>(`${environment.enves[baseUrl].apiUrl}/Usuario/ObtenerDatosPerfilAlumno/` + identificador);
  }

  public obtenerDatosRol(identificador): Observable<Usuario> {
    return this.http.get<Usuario>(`${environment.enves[baseUrl].apiUrl}/Usuario/ObtenerDatosRolUsuario/${identificador}`);
  }

  /**
   * Servicio para traer los roles y permisos de un usuario
   * @param identificador 
   * @returns 
   */
  public obtainPermission(identificador: string): Observable<ApiResponse<UserRolPermissionDto>> {
    return this.http.get<ApiResponse<UserRolPermissionDto>>(`${environment.enves[baseUrl].apiUrl}/Usuario/ObtainPermission/${identificador}`);
  }

  public editarRolesUsuario(roles: any): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Usuario/EditarRolesUsuario`, roles);
  }

  //Cambio de imagen usuario
  public editarImagen(imagenUsuario): Observable<boolean> {
    return this.http.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Usuario/PutImagenUsuario`, imagenUsuario);
  };


  //#region  Constancias
  public setTokenConstancia(token: string) {
    localStorage.setItem('tokenConstancia', token);
  }
  public getTokenConstancia(): string {
    return localStorage.getItem('tokenConstancia');
  }
  //#endregion JSON.parse(localStorage.getItem('currentUser'))

  /**
   * Funcion para saber si tienes permisos de usar la opcion del menu
   * @param permission 
   * @returns 
   */
  public hasPermission(permission: number): boolean {
    this.userPermissionsSubject = new BehaviorSubject<UserRolPermissionDto>(JSON.parse(sessionStorage.getItem('userPermission')));
    if (this.userPermissionsSubject.value.roles.length > 0) {
      let usuarioRol = this.userPermissionsSubject.value.roles.find(x => x.roleID == this.userRolID);
      let hasPermissionOption = usuarioRol.permisos.find(x => x.permissionID === permission);
      if (hasPermissionOption) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * OBS para setear el rolid del usuario
   * @param rolID 
   */
  public setUserRol(rolID: number) {
    this.userRolID$.next(rolID);
  }

  /**
   * OBS para obtener el rol id del usuario
   * @returns 
   */
  public getUserRol$(): Observable<number> {
    return this.userRolID$.asObservable();
  }
}
