import { I18NextModule } from 'angular-i18next';
import { MaterialModule } from '../../../modules/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactInformationFormComponent } from './contact-information-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [
    ContactInformationFormComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    I18NextModule.forRoot(),
  ],
  exports: [
    ContactInformationFormComponent
  ]
})
export class ContactInformationFormModule { }
