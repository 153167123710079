import { ConfirmationDialogServiceService } from 'src/app/services/confirmation-dialog-service.service';
import { ColegiaturaCosto } from './../../../_models/colegiatura-costo';
import { GruposDisponiblesParaMateriaDTO } from './../../../models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { TraductorService } from 'src/app/_services/traductor.service';
import { EgresoService } from 'src/app/_services/egreso.service';
import { Component, OnInit, Inject, AfterViewInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { SeleccionMateriasComponent } from './seleccion-materias/seleccion-materias.component';
import { HorarioComponent } from './horario/horario.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { InscripcionService } from '../../../_services/inscripcion.service';
import { SeguimientoCarteraService } from '../../../_services/seguimiento-cartera.service';
import { DatosPersonalesInscripcionComponent } from './datos-personales-inscripcion/datos-personales-inscripcion.component';
import { FormControl } from '@angular/forms';
import { GrupoDTO } from 'src/app/_models/GrupoDTO.model';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { ColegiaturaCostoDto } from 'src/app/_models/ColegiaturaCosto.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { ExisteMateriaSeriada } from 'src/app/models/customEntities/existeMateriaSeriada.mode';
import { CargaInscripcion } from 'src/app/_models/carga-inscripcion';
import { EstadoCuentaDto } from 'src/app/_models/estado-cuenta-dto';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { BajaDTO } from 'src/app/_models/BajaDTO.model';
import { BlockStudent } from '@app/_models/blockStudent';
import { BlockService } from '@app/_services/block.service';
import { AuthorizeRegistrationSubject } from '../../../clean-architecture/core.domain/models/authorize-registration/authorize-registration.model';
import { GenericHttpService } from '@app/clean-architecture/infrastructure/services/generic-http.service';

@Component({
  selector: 'app-seguimiento-modal',
  templateUrl: './seguimiento-modal.component.html',
  styleUrls: ['./seguimiento-modal.component.scss']
})

export class SeguimientoModalComponent implements OnInit, AfterViewInit {
  @ViewChild(DatosPersonalesInscripcionComponent) datosPersonales: DatosPersonalesInscripcionComponent;
  @ViewChild(SeleccionMateriasComponent) seleccionMaterias: SeleccionMateriasComponent;
  @ViewChild('dialogoCerrarModalCargo') dialogoCerrarModalCargo: TemplateRef<any>;
  @ViewChild('dialogAdvertenia') dialogoCerrarModal: TemplateRef<any>;
  @ViewChild(HorarioComponent) horario: HorarioComponent;
  @ViewChild('dialogoAgregarAlumnosBloque') dialogoAgregarAlumnosBloque: TemplateRef<'dialogoAgregarAlumnosBloque'>;

  public mostrarPeriodoPorModulo: boolean = true;
  public dialogoRefCerrarModalCargo: any;
  public datosUsuarioActual: any;
  public base64Image: any;
  public imagePath: any;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public formValido$: Observable<boolean>;
  public formValido: boolean;
  public dialogoRefCerrarModal: any;
  public tabCambio: boolean = false;
  public tabSeleccionado: number = 0;
  public cargaAlumno: any;
  public tabSeleccionadoIndex: FormControl = new FormControl(0);
  private colegiaturaCuota: ColegiaturaCostoDto;
  public materiaAltaBaja: boolean = false;
  public gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
  public materiasBajaAltaAlumno: any;
  public dialogoRefCerrarModalAgregarAlumnosBloque: MatDialogRef<any>;
  public cargaActualAl: string;
  public authorizeRegistration: boolean;
  public authorizeRegistrationSubjects: AuthorizeRegistrationSubject[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SeguimientoModalComponent>,
    public nuevoIngresoService: NuevoIngresoService,
    private _GenericHttpService: GenericHttpService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private inscripcionService: InscripcionService,
    private seguimientoService: SeguimientoCarteraService,
    private snackService: SnackService,
    private egresoService: EgresoService,
    private traductorService: TraductorService,
    public blockService: BlockService,
    private confirmacionDialogService: ConfirmationDialogServiceService) {
  }

  private mapSubjects():void
  {
    this.authorizeRegistrationSubjects = this.data[0].alumnoGrupo.map(res => AuthorizeRegistrationSubject.mapBackEnd(
      { 'clave':res.grupo.materiaOferta.clave,
        'nombre': res.grupo.materiaOferta.nombre 
      }));      
  }
  ngOnInit(): void {
    console.log("DATA", this.data);
    this.cargaActualAl = this.data[0].alumnoGrupo.length;
    this.authorizeRegistration = this.data[0].authorizeRegistration;
    this.mapSubjects();


    this.nuevoIngresoService.getCargas(this.data[0].campusId, this.data[0].programa.nivelId, this.data[0].programaId).subscribe(
      (respuesta: ApiResponse<CargaInscripcion>) => {
        this.cargaAlumno = respuesta.data;
        if (!!this.cargaAlumno) {
          this.nuevoIngresoService.definirtipoCarga(this.cargaAlumno.tipoCarga);
        }
      });
    let dataResumen: any = {
      alumnoId: this.data[0].alumnoId,
      id: this.data[0].usuarioId,
      id_Campus: this.data[0].campusId,
      id_Periodo: this.data[0].priodoIngreso,
      id_Programa: this.data[0].programaId
    }
    this.seguimientoService.setDatosAlumno$(dataResumen);
    this.seguimientoService.setAlumnoIdSeleccionado$(this.data[0].alumnoId);
    this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos)
    this.cargarImagen();
    this.formValido$ = this.nuevoIngresoService.obtenerFormularioValidoInscripcion$();
    this.formValido$.subscribe(bool => this.formValido = bool);
    this.buscarInformacionUsuario(this.data[0].usuarioId);
  }

  /**
   * Obtiene los datos del usuario
   * @param usuarioId
   */
  public buscarInformacionUsuario(usuarioId: number): void {
    this.seguimientoService.obtenerSeguimientoUsuarioById(usuarioId).subscribe(
      (seguimientoUsuario: ApiResponse<EstadoCuentaDto[]>) => {

        this.seguimientoService.setDatosSeguimientoUsuario$(seguimientoUsuario.data);
        this.datosPersonales.renderizarFormulario();
      });
  }

  /**
   * Obtiene la imagen que el usuario previamente guardo
   */
  cargarImagen(): void {
    this.nuevoIngresoService.imagenUsuario(this.data[0].usuarioId).subscribe(
      (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
        if (datosUsuarioImagen.success) {
          this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
        }
      }
    );
  }

  /**
   * Cierra el modal si no hay cambios pendientes, de ser asi manda una alerta para evitar que cierre
   */
  public cerrarModal(): void {
    if (this.formValido) {
      this.dialogoRefCerrarModal = this.dialog.open(this.dialogoCerrarModal, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Cierra el modal solo si da clic en descartar
   * @param descartar
   */
  public cerrarModalAdvertenciaCancelarCambios(descartar: boolean): void {
    if (descartar) {
      this.dialogoRefCerrarModal.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefCerrarModal.close();

    }
  }

  /**
   * Funcion que entra despues que el componente ha sido cargado
   */
  ngAfterViewInit(): void {
    this.datosUsuarioActual = this.data;
    this.nuevoIngresoService.definirDatosUsuarioBusqueda(this.data);
    this.inicializarTabs();
  }

  /**
   * Inicia los componentes alojados en el modal
   */
  inicializarTabs(): void {
    if (this.data[0].tabRequerido === 'periodo') {
      setTimeout(() => {
        this.tabSeleccionadoIndex.setValue(1);
      }, 500);
    }
  }

  public permiteParcialidades: boolean = false;
  public cuotaColegiatura: any;
  public flagAltaMateria: boolean;

  /**
   * Funcion que guarda los datos segun la pestaña en la que este se encuentre
   */
  public enviar(): void {
    if (this.tabSeleccionado === 0) {
      this.datosPersonales.enviarDatosPersonales();
    }
    if (this.tabSeleccionado == 1) {
      this.egresoService.ConfiguracionAcademicaValida(this.datosUsuarioBusqueda[0].programa.campusId, this.datosUsuarioBusqueda[0].programa.nivelId, this.seleccionMaterias.periodoActualFiel.value).subscribe((response: ApiResponse<boolean>) => {
        if (response.success) {
          if (response.data) {

            if (response.message === '_nuevosIngresos.mensaje-3') {
              let mensaje: string = this.traductorService.translate(response.message);
              let mensaje2: string = this.traductorService.translate('mensajeRI');
              this.confirmacionDialogService.abrirModalDescartarCambios(mensaje2, mensaje).afterClosed().subscribe((descartarCambios: boolean) => {
                if (descartarCambios) {


                  let seriacion = new ExisteMateriaSeriada();
                  seriacion.existeMateriaSeriada = false;
                  this.nuevoIngresoService.existeSeriacionDeMaterias(seriacion);
                  if (this.seleccionMaterias.isSoloGruposDesuscritosPendientes) {
                    this.gruposDesuscritosLocalmente = this.seleccionMaterias.gruposDesuscritosLocalmente;
                    this.desuscribirMaterias();
                  }
                  if (this.seleccionMaterias.isSoloGruposInscritosPendientes) {
                    this.inscribirMaterias();
                  }
                  if (this.seleccionMaterias.isGruposDesuscritosEInscritosPendientes) {
                    this.gruposDesuscritosLocalmente = this.seleccionMaterias.gruposDesuscritosLocalmente;
                    this.materiaAltaBaja = true;
                    this.materiaAltaBajaAlumno();
                  }

                }
              });

            } else {

              let seriacion = new ExisteMateriaSeriada();
              seriacion.existeMateriaSeriada = false;
              this.nuevoIngresoService.existeSeriacionDeMaterias(seriacion);
              if (this.seleccionMaterias.isSoloGruposDesuscritosPendientes) {
                this.gruposDesuscritosLocalmente = this.seleccionMaterias.gruposDesuscritosLocalmente;
                this.desuscribirMaterias();
              }
              if (this.seleccionMaterias.isSoloGruposInscritosPendientes) {
                this.inscribirMaterias();
              }
              if (this.seleccionMaterias.isGruposDesuscritosEInscritosPendientes) {
                this.gruposDesuscritosLocalmente = this.seleccionMaterias.gruposDesuscritosLocalmente;
                this.materiaAltaBaja = true;
                this.materiaAltaBajaAlumno();
              }

            }




          } else {
            let mensaje: string = this.traductorService.translate(response.message);
            this.snackService.mostrarSnackBack(mensaje);
          }
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      });
    }
  }

  /**
   * Funcion que detecta la pantalla en la que te encuentras y cargar sus componentes
   * @param event
   */
  public onChangeTab(event: MatTabChangeEvent): void {
    this.tabCambio = true;
    let tab: number = event.index;
    this.tabSeleccionado = tab;
    switch (this.tabSeleccionado) {
      case 2: {
        this.inscripcionService.definirHorarioSeleccionado(true);
        break;
      }
      case 1: {
        break;
      }
      default: {
        this.inscripcionService.definirHorarioSeleccionado(false);
        break;
      }

    }
  }

  /**
   * Abre un para agregar un nuevo tipo de cargo
   */
  public abrirModalTipoCargo(): void {
    this.dialogoRefCerrarModalCargo = this.dialog.open(this.dialogoCerrarModalCargo, { disableClose: true });
  }

  /**
   * Funcion que guarda los pagos
   */
  public cargoPorExibicion(): void {
    if (this.materiaAltaBaja) {
      this.materiaAltaBaja = false;
      this.materiasBajaAltaAlumno.cargoExcibicion = true;
      this.nuevoIngresoService.AltaBajaMateriaAlumno(this.materiasBajaAltaAlumno).subscribe((response: SimpleResponse) => {
        if (response.success) {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.horario.ObtenerHorarioGrupos(this.data[0].alumnoId);
          this.formValido = false;
        }
        else {
          this.snackService.mostrarSnackBack(response.message);
        }
      });
    }
    else {
      this.nuevoIngresoService.CargoExcibicion(this.colegiaturaCuota).subscribe((cargoExcibicion: SimpleResponse) => {
        if (cargoExcibicion.success) {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          let blockId = this.seleccionMaterias.nombreBloque.value;
          this.seleccionMaterias.onNombreBloqueSelected(blockId)
          this.snackService.mostrarSnackBack(mensaje);
          this.horario.ObtenerHorarioGrupos(this.data[0].alumnoId);
          this.formValido = false;
        }
        else {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    }
    this.dialogoRefCerrarModalCargo.close();
  }

  /**
   * Funcion que guarda los cargos por parcialidades
   */
  public cargoPorParcialidades(): void {
    if (this.materiaAltaBaja) {
      this.materiaAltaBaja = false;
      this.materiasBajaAltaAlumno.cargoExcibicion = false;
      this.nuevoIngresoService.AltaBajaMateriaAlumno(this.materiasBajaAltaAlumno).subscribe((response: SimpleResponse) => {
        if (response.success) {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
          this.horario.ObtenerHorarioGrupos(this.data[0].alumnoId);
          this.formValido = false;
        }
        else {
          this.snackService.mostrarSnackBack(response.message);
        }
      });
    }
    else {
      this.nuevoIngresoService.CargoParcialidades(this.colegiaturaCuota).subscribe((cargoExcibicion: SimpleResponse) => {
        if (cargoExcibicion.success) {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          let blockId = this.seleccionMaterias.nombreBloque.value;
          this.seleccionMaterias.onNombreBloqueSelected(blockId)
          this.snackService.mostrarSnackBack(mensaje);
          this.horario.ObtenerHorarioGrupos(this.data[0].alumnoId);
          this.formValido = false;
        }
        else {
          let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    }
    this.dialogoRefCerrarModalCargo.close();
  }

  /**
   * Iguala la cargaActual con la que trae
   * @param carga
   */
  public setCarga(carga): void {
    this.cargaActualAl = carga;
  }

  /**
   * Inscribe a un nuevo grupo al alumno para la materia y periodo indicado
   */
  private inscribirMaterias(): void {
    this.colegiaturaCuota = new ColegiaturaCostoDto();
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.datosUsuarioBusqueda[0].programa.campusId, this.datosUsuarioBusqueda[0].programa.nivelId, this.seleccionMaterias.periodoActualFiel.value, this.datosUsuarioBusqueda[0].programa.programaId).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<any>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data.parcialidades) {
            this.permiteParcialidades = true;
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
          this.colegiaturaCuota.alumnoId = this.datosUsuarioBusqueda[0].alumnoId;
          this.colegiaturaCuota.campusId = this.datosUsuarioBusqueda[0].programa.campusId;
          this.colegiaturaCuota.nivelId = this.datosUsuarioBusqueda[0].programa.nivelId;
          this.colegiaturaCuota.periodoId = this.seleccionMaterias.periodoActualFiel.value;
          this.colegiaturaCuota.costo = cuotaColegiaturaRegistrada.data.costo;
          this.colegiaturaCuota.claveTipoCobro = cuotaColegiaturaRegistrada.data.claveTipoCobro;
          this.colegiaturaCuota.impuesto = cuotaColegiaturaRegistrada.data.impuesto;
          this.colegiaturaCuota.intereses = cuotaColegiaturaRegistrada.data.intereses;
          this.colegiaturaCuota.fechaInicio = cuotaColegiaturaRegistrada.data.fechaInicio;
          this.colegiaturaCuota.diaTransaccion = cuotaColegiaturaRegistrada.data.diaTransaccion;
          this.colegiaturaCuota.diaVencimiento = cuotaColegiaturaRegistrada.data.diaVencimiento;
          this.colegiaturaCuota.fechaVencimiento = cuotaColegiaturaRegistrada.data.fechaVencimiento;
          this.colegiaturaCuota.fechaFin = cuotaColegiaturaRegistrada.data.fechaFin;
          this.colegiaturaCuota.esNuevoIngreso = false;
          this.registrarMaterias();
        }
        else {
          let mensaje: string = this.traductorService.translate("_nuevosIngresos.mensaje-son-cobro");
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    this.horario.ObtenerHorarioGrupos(this.data[0].alumnoId);
  }

  /**
   * Registra nuevas materias a un candidato
   */
  private registrarMaterias(): void {
    let grupos = this.seleccionMaterias.gruposInscritosLocalmente.map((grupo) => {
      let grupoApiDTO = new GrupoDTO();
      grupoApiDTO.claveMateria = grupo.claveMateria
      return grupoApiDTO
    });
    this.nuevoIngresoService.existeSeriacionDeMateriasEnLaInscripcion(this.datosUsuarioBusqueda[0].alumnoId, grupos).subscribe((ExisteSeriacion: SimpleResponse) => {
      if (ExisteSeriacion.success) {
        let seriacion = new ExisteMateriaSeriada();
        seriacion.existeMateriaSeriada = true;
        seriacion.materiasSeriadas = ExisteSeriacion.message;
        this.nuevoIngresoService.existeSeriacionDeMaterias(seriacion);
      }
      this.nuevoIngresoService.inscribirAgrupos(this.datosUsuarioBusqueda[0].alumnoId, this.datosUsuarioBusqueda[0].usuario.identificador, this.seleccionMaterias.gruposInscritosLocalmente).subscribe((materiasInscritas: SimpleResponse) => {
        if (materiasInscritas.success) {
          this.seleccionMaterias.ResetSeleccionDeMaterias();
          if (this.colegiaturaCuota.costo !== 0) {
            this.abrirModalTipoCargo();
          }
        }
        else {
          let mensaje: string = this.traductorService.translate(materiasInscritas.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    });
  }

  /**
   * Desuscribe de un grupo a un alumno que ya está cursando una materia en un grupo siempre
   */
  private desuscribirMaterias(): void {
    const informacionDeBaja = new BajaDTO();
    informacionDeBaja.identiticador = this.datosUsuarioBusqueda[0].usuario.identificador;
    informacionDeBaja.idPeriodo = this.seleccionMaterias.periodoActualFiel.value;
    informacionDeBaja.grupos = this.gruposDesuscritosLocalmente;
    informacionDeBaja.cantidadDeCreditosAntesDeBaja = this.seleccionMaterias.cantidadDeCreditosAntesDeBaja;
    informacionDeBaja.cantidadDeMateriasAntesDeBaja = this.seleccionMaterias.cantidadDeMateriasAntesDeBaja;
    this.nuevoIngresoService.darDeBajaDeGrupos(informacionDeBaja).subscribe((materiasBaja: SimpleResponse) => {
      if (materiasBaja.success) {
        this.formValido = false;
        this.seleccionMaterias.ResetSeleccionDeMaterias();
        let mensaje: string = this.traductorService.translate(materiasBaja.message);
        this.snackService.mostrarSnackBack(mensaje);
      }
      else {
        let mensaje: string = this.traductorService.translate(materiasBaja.message);
        this.snackService.mostrarSnackBack(mensaje);
      }
    });
  }

  public materiaAltaBajaAlumno(): void {
    this.colegiaturaCuota = new ColegiaturaCostoDto();
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.datosUsuarioBusqueda[0].programa.campusId, this.datosUsuarioBusqueda[0].programa.nivelId, this.datosUsuarioBusqueda[0].priodoIngresoNavigation.periodoId, this.datosUsuarioBusqueda[0].programa.programaId).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data.parcialidades) {
            this.permiteParcialidades = true;
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
          this.colegiaturaCuota.alumnoId = this.data[0].alumnoId;
          this.colegiaturaCuota.campusId = this.datosUsuarioBusqueda[0].programa.campusId;
          this.colegiaturaCuota.nivelId = this.datosUsuarioBusqueda[0].programa.nivelId;
          this.colegiaturaCuota.periodoId = this.datosUsuarioBusqueda[0].priodoIngresoNavigation.periodoId;
          this.colegiaturaCuota.costo = cuotaColegiaturaRegistrada.data.costo;
          this.colegiaturaCuota.claveTipoCobro = cuotaColegiaturaRegistrada.data.claveTipoCobro;
          this.colegiaturaCuota.impuesto = cuotaColegiaturaRegistrada.data.impuesto;
          this.colegiaturaCuota.intereses = cuotaColegiaturaRegistrada.data.intereses;
          this.colegiaturaCuota.fechaInicio = cuotaColegiaturaRegistrada.data.fechaInicio;
          this.colegiaturaCuota.fechaFin = cuotaColegiaturaRegistrada.data.fechaFin;
          this.colegiaturaCuota.diaTransaccion = cuotaColegiaturaRegistrada.data.diaTransaccion;
          this.colegiaturaCuota.diaVencimiento = cuotaColegiaturaRegistrada.data.diaVencimiento;
          this.colegiaturaCuota.fechaVencimiento = cuotaColegiaturaRegistrada.data.fechaVencimiento;
          this.colegiaturaCuota.esNuevoIngreso = true;

          const informacionDeBaja = new BajaDTO();
          informacionDeBaja.identiticador = this.datosUsuarioBusqueda[0].usuario.identificador;
          informacionDeBaja.idPeriodo = this.seleccionMaterias.periodoActualFiel.value;
          informacionDeBaja.grupos = this.gruposDesuscritosLocalmente;
          informacionDeBaja.cantidadDeCreditosAntesDeBaja = this.seleccionMaterias.cantidadDeCreditosAntesDeBaja;
          informacionDeBaja.cantidadDeMateriasAntesDeBaja = this.seleccionMaterias.cantidadDeMateriasAntesDeBaja;

          this.materiasBajaAltaAlumno = {
            infoInscripcion: {
              identificador: this.datosUsuarioBusqueda.identificador,
              gruposInscritos: this.seleccionMaterias.gruposInscritosLocalmente
            },
            infoDesuscripcion: {
              informacionDeBaja: informacionDeBaja
            },
            infoColegiaturaCuota: {
              colegiaturaCuota: this.colegiaturaCuota
            }
          }
          this.seleccionMaterias.ResetSeleccionDeMaterias();
          if (this.colegiaturaCuota.costo !== 0) {
            this.abrirModalTipoCargo();
          }
        }
        else {
          let mensaje: string = this.traductorService.translate("_nuevosIngresos.mensaje-son-cobro");
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
  }

  public abrirModalAgregarAlumnosBloque() {
    this.dialogoRefCerrarModalAgregarAlumnosBloque = this.dialog.open(this.dialogoAgregarAlumnosBloque, { disableClose: true });
  }


  public cerarModalAgregarAlumnosBloque() {
    this.dialogoRefCerrarModalAgregarAlumnosBloque.close();
  }

  public guardarAlumno() {
    let blockId = this.seleccionMaterias.nombreBloque.value;
    let alumnoId = this.datosUsuarioBusqueda[0].alumnoId;

    let blockStudents: BlockStudent[] = [];
    let newBlockStudent = new BlockStudent();

    newBlockStudent.blockId = blockId;
    newBlockStudent.studentId = alumnoId;
    blockStudents.push(newBlockStudent);

    this.blockService.PostListBlockStudent(blockStudents).subscribe(response => {
      if (response.success) {
        this.snackService.mostrarSnackBack(response.message);

        this.blockService.invocarMetodoObtenerBloqueAlumno();
        this.cerarModalAgregarAlumnosBloque();
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    })
  }

  public Inscribir() {

    this.cerarModalAgregarAlumnosBloque();

    this.colegiaturaCuota = new ColegiaturaCostoDto();
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(
      this.datosUsuarioBusqueda[0].programa.campusId,
      this.datosUsuarioBusqueda[0].programa.nivelId,
      //this.datosUsuarioBusqueda[0].priodoIngresoNavigation.periodoId,
      this.seleccionMaterias?.BlockSelected?.periodo?.periodoId,
      this.datosUsuarioBusqueda[0].programa.programaId
    ).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data.parcialidades) {
            this.permiteParcialidades = true;
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
          this.colegiaturaCuota.alumnoId = this.datosUsuarioBusqueda[0].alumnoId;
          this.colegiaturaCuota.campusId = this.datosUsuarioBusqueda[0].programa.campusId;
          this.colegiaturaCuota.nivelId = this.datosUsuarioBusqueda[0].programa.nivelId;
          this.colegiaturaCuota.periodoId = this.seleccionMaterias?.BlockSelected?.periodo?.periodoId;//this.datosUsuarioBusqueda[0].priodoIngresoNavigation.periodoId;
          this.colegiaturaCuota.costo = cuotaColegiaturaRegistrada.data.costo;
          this.colegiaturaCuota.claveTipoCobro = cuotaColegiaturaRegistrada.data.claveTipoCobro;
          this.colegiaturaCuota.impuesto = cuotaColegiaturaRegistrada.data.impuesto;
          this.colegiaturaCuota.intereses = cuotaColegiaturaRegistrada.data.intereses;
          this.colegiaturaCuota.fechaInicio = cuotaColegiaturaRegistrada.data.fechaInicio;
          this.colegiaturaCuota.fechaFin = cuotaColegiaturaRegistrada.data.fechaFin;
          this.colegiaturaCuota.diaTransaccion = cuotaColegiaturaRegistrada.data.diaTransaccion;
          this.colegiaturaCuota.diaVencimiento = cuotaColegiaturaRegistrada.data.diaVencimiento;
          this.colegiaturaCuota.fechaVencimiento = cuotaColegiaturaRegistrada.data.fechaVencimiento;
          this.colegiaturaCuota.esNuevoIngreso = false;


          let blockId = this.seleccionMaterias.nombreBloque.value;
          let alumnoId = this.datosUsuarioBusqueda[0].alumnoId;

          let blockStudents: BlockStudent[] = [];
          let newBlockStudent = new BlockStudent();

          newBlockStudent.blockId = blockId;
          newBlockStudent.studentId = alumnoId;
          blockStudents.push(newBlockStudent);

          this.blockService.PostListBlockStudent(blockStudents).subscribe(response => {
            if (response.success) {
              this.blockService.InscribirAGruposBloqueAlumno(newBlockStudent).subscribe(response => {
                if (response.success) {
                  this.abrirModalTipoCargo();
                } else {
                  let mensaje: string = this.traductorService.translate(response.message);
                  this.snackService.mostrarSnackBack(mensaje);
                }
              })
            } else {
              this.snackService.mostrarSnackBack(response.message);
            }
          })

        }
        else {
          this.snackService.mostrarSnackBack("No es posible realizar la selección, debido a que no hay una cuota de colegiatura registrada.");
        }
      });


  }

//#region AuthorizeEnrrolment
public authorizeRegistrationSend():void
{
  this.colegiaturaCuota = new ColegiaturaCostoDto();
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.datosUsuarioBusqueda[0].programa.campusId, this.datosUsuarioBusqueda[0].programa.nivelId, this.seleccionMaterias.periodoActualFiel.value, this.datosUsuarioBusqueda[0].programa.programaId).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<any>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data.parcialidades) {
            this.permiteParcialidades = true;
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
          this.colegiaturaCuota.alumnoId = this.datosUsuarioBusqueda[0].alumnoId;
          this.colegiaturaCuota.campusId = this.datosUsuarioBusqueda[0].programa.campusId;
          this.colegiaturaCuota.nivelId = this.datosUsuarioBusqueda[0].programa.nivelId;
          this.colegiaturaCuota.periodoId = this.seleccionMaterias.periodoActualFiel.value;
          this.colegiaturaCuota.costo = cuotaColegiaturaRegistrada.data.costo;
          this.colegiaturaCuota.claveTipoCobro = cuotaColegiaturaRegistrada.data.claveTipoCobro;
          this.colegiaturaCuota.impuesto = cuotaColegiaturaRegistrada.data.impuesto;
          this.colegiaturaCuota.intereses = cuotaColegiaturaRegistrada.data.intereses;
          this.colegiaturaCuota.fechaInicio = cuotaColegiaturaRegistrada.data.fechaInicio;
          this.colegiaturaCuota.diaTransaccion = cuotaColegiaturaRegistrada.data.diaTransaccion;
          this.colegiaturaCuota.diaVencimiento = cuotaColegiaturaRegistrada.data.diaVencimiento;
          this.colegiaturaCuota.fechaVencimiento = cuotaColegiaturaRegistrada.data.fechaVencimiento;
          this.colegiaturaCuota.fechaFin = cuotaColegiaturaRegistrada.data.fechaFin;
          this.colegiaturaCuota.esNuevoIngreso = false;
          this.abrirModalTipoCargo();
          this.AuthorizeEnrollment();
        }
        else {
          let mensaje: string = this.traductorService.translate("_nuevosIngresos.mensaje-son-cobro");
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
}

//#region Subscription
private Subscription: Subscription = new Subscription();
//#endregion

public AuthorizeEnrollment():void
  {
    this.Subscription.add(
      this._GenericHttpService.get<SimpleResponse>(`Inscripcion/AuthorizeEnrollment/${this.datosUsuarioBusqueda[0].alumnoId}/${this.seleccionMaterias.periodoActualFiel.value}`).subscribe(
        response => 
         {
          //this._snackService.mostrarSnackBack("se ha guardado con éxito.");          
        }
      )
    );
  }
//#endregion
}
