import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { GrupoService } from '../../../../_services/grupo.service';
import { Profesor } from '../../../../_models/profesor';
import { Salon } from '../../../../_models/salon';
import { HoraValidator } from 'src/app/validator/HorasValidator';

@Component({
  selector: 'app-form-frecuencia-horario',
  templateUrl: './form-frecuencia-horario.component.html',
  styleUrls: ['./form-frecuencia-horario.component.scss']
})
export class FormFrecuenciaHorarioComponent implements OnInit, OnDestroy {

  public frecuenciaHorarioForm: FormGroup;
  public datosCampusPeriodo$: Observable<any>;
  public datosMateria$: Observable<any>;
  public datosBloque$: Observable<any>;
  public subscriptionSalon: Subscription;
  public salonList: Salon[] = [];
  public edicionNombre: boolean = true;
  public campusId: number;

  constructor(private grupoService: GrupoService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.datosCampusPeriodo$ = this.grupoService.obtenerDatosCampusPeriodo$();
    this.grupoService.obtenerDatosCampusPeriodo$().subscribe(data => {
      this.campusId = data.campus.campusId;
      this.obtenerSalones();
    });
    this.datosMateria$ = this.grupoService.obtenerDatosMateria$();
    this.datosBloque$ = this.grupoService.obtenerDatosBloque$();
    this.inicializarForm();
    this.obtenerSalones();
  }

  public obtenerSalones() {
    this.subscriptionSalon = this.grupoService.obtenerSalon(this.campusId, true).subscribe(
      (salon: Salon[]) => {
        this.salonList = salon;
      });
  }

  public inicializarForm() {
    this.frecuenciaHorarioForm = this.formBuilder.group({
      horarioSecciones: this.formBuilder.array([this.crearFormSeccion()], [Validators.required])
    });
    this.frecuenciaHorarioForm.valueChanges.subscribe((evento: any) => {
    });
  }

  public crearFormSeccion(): FormGroup {
    return this.formBuilder.group({
      nombre: new FormControl('Sección', [Validators.required]),
      esLunes: new FormControl(false),
      esMartes: new FormControl(false),
      esMiercoles: new FormControl(false),
      esJueves: new FormControl(false),
      esViernes: new FormControl(false),
      esSabado: new FormControl(false),
      horaInicio: new FormControl('', [Validators.required]),
      horaFin: new FormControl('', [Validators.required]),
      salonId: new FormControl(0)
    }, {
      validators: [HoraValidator('horaInicio', 'horaFin')]
    });

  }


  get horarioSecciones(): FormArray {
    return this.frecuenciaHorarioForm.get('horarioSecciones') as FormArray;
  }

  public agregarSeccion(): void {
    (this.frecuenciaHorarioForm.get('horarioSecciones') as FormArray).push(this.crearFormSeccion());
  }

  public eliminarSeccion(indice: number): void {
    (<FormArray>this.frecuenciaHorarioForm.controls.horarioSecciones).removeAt(indice);
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public editNombre(valor) {
    this.edicionNombre = valor;
  }

  public finalizarFormulario() {
    this.grupoService.invocarMetodoGruardarGrupo();
  }

  ngOnDestroy(): void {
    if (this.subscriptionSalon) {
      this.subscriptionSalon.unsubscribe;
    }
    if(this.grupoService.invMetodoSetFechasSubscription){
      this.grupoService.invMetodoSetFechasSubscription.unsubscribe();
      this.grupoService.invMetodoSetFechasSubscription = undefined;
    }
  }
  //#region  Getes
  public horarioSeccionHoraFin(index: number){
    return this.horarioSecciones.controls[index].get('horaFin');
  }
  public horarioSeccionHoraInicio(index: number){
    return this.horarioSecciones.controls[index].get('horaInicio');
  }
  //#endregion

}
