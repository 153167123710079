import { MovimientosComponent } from './movimientos/movimientos.component';
import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'movimientos', component: MovimientosComponent, canActivate: [MsalGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EscolaresRoutingModule { }
