import { AuthenticationService } from './../../_services/authentication.service';
import { ConsultaDatosService } from './../../_services/consulta-datos.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiResponse } from '../../models/api/ApiRespose.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-consulta-datos',
  templateUrl: './consulta-datos.component.html',
  styleUrls: ['./consulta-datos.component.scss']
})
export class ConsultaDatosComponent implements OnInit, OnDestroy,  AfterViewInit {

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  public Alumno: any;

  constructor(private _ActivatedRoute: ActivatedRoute,
    private _AuthenticationService :AuthenticationService,
    private _ConsultaDatosService: ConsultaDatosService) { }
  ngAfterViewInit(): void {
    //this.ConsultaDatosConstancia();
  }

  ngOnInit(): void {
    //this.ConsultaDatosConstancia();
    this.GetToken();
  }

  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
  }

  public GetToken():void
  {
    this._ActivatedRoute.queryParams.subscribe(params => {
      const token = params['token'];
      this._AuthenticationService.setTokenConstancia(token);
     //console.log(`token:${token}`);
      this.ConsultaDatosConstancia();
    });
  }

  public ConsultaDatosConstancia(): void
   {
     this.Subscription.add(
       this._ConsultaDatosService.ConsultaDatosConstancia().subscribe(
         (resp: ApiResponse<any>) => {
           this.Alumno = resp.data;
           console.log("this.Alumno",this.Alumno);
         }
       )
     );

   }
}
