import { TableActions } from './../../../core.application/constants/table-actions.model';
import { GenericTableRow } from '../../../core.domain/class/generic.table.row.class'

export class MakeUpExamResponse extends GenericTableRow {
  identificador:         string;
  usuarioId:             number;
  fotografia:            string;
  estatus:               string;
  programaId:            number;
  programaClave:         string;
  programa:              string;
  alumnoId:              number;
  nombreAlumno:          string;
  nombreCompleto:        TableActions;
  periodoId:             number;
  periodoClave:          TableActions;
  periodoNombre:         string;
  campusId:              number;
  campusClave:           TableActions;
  campusNombre:          string;
  grupoId:               number;
  materia:               string;
  materiaClave:          string;
  materiaNombre:         TableActions;  
  profesorIdentificador: null | TableActions;
  profesorNombre:        string | null;
  calificacionFinal:     string | null;
  numeroFaltas:          number | null;
  isMakeUpExam:          boolean;
  makeUpExamReasonId:    number;
  makeUpExamReasonName:  string;
  makeUpExamName:        TableActions;
  total:                 number;

  constructor(
    identificador:         string,
    usuarioId:             number,
    fotografia:            string,
    estatus:               string,
    programaId:            number,
    programaClave:         string,
    programa:              string,
    alumnoId:              number,
    nombreCompleto:        string,
    periodoId:             number,
    periodoClave:          TableActions,
    periodoNombre:         string,
    campusId:              number,
    campusClave:           string,
    campusNombre:          string,
    grupoId:               number,
    materiaClave:          string,
    materiaNombre:         TableActions,    
    profesorIdentificador: null | TableActions,
    profesorNombre:        string | null,
    calificacionFinal:     string | null,
    numeroFaltas:          number | null,
    isMakeUpExam:          boolean,
    makeUpExamReasonId:    number,
    makeUpExamReasonName:  string,
    makeUpExamName:        string,
    total:                 number
  ) {
    super({
      identificador,
      usuarioId,
      fotografia,
      estatus,
      programaId,
      programaClave,
      programa,
      alumnoId,
      nombreCompleto,
      periodoId,
      periodoClave,
      periodoNombre,
      campusId,
      campusClave,
      campusNombre,
      grupoId,
      materiaClave,
      materiaNombre,      
      profesorIdentificador,
      profesorNombre,
      calificacionFinal,
      numeroFaltas,
      isMakeUpExam,
      makeUpExamReasonId,
      makeUpExamReasonName,
      makeUpExamName,
      total,
    }, 
    [
      {property: 'nombreCompleto', cssClass:'text-left text-decoration-underline cursor-pointer truncate'},
      {property: 'materiaNombre', cssClass:'text-left truncate'},
      {property: 'profesorIdentificador', cssClass:'text-left'},
      {property: 'periodoClave', cssClass:'text-left'},
      {property: 'materiaClave', cssClass:'text-left'},
    ]
    );

  }

  static mapBackEnd(data: any): MakeUpExamResponse {
   
    return new MakeUpExamResponse(
      data.identificador,
      data.usuarioId,
      data.fotografia,
      data.estatus,
      data.programaId,
      data.programaClave,
      data.programa,
      data.alumnoId,
      data.nombreCompleto,
      data.periodoId,
      data.periodoClave,
      data.periodoNombre,
      data.campusId,
      data.campusClave,
      data.campusNombre,
      data.grupoId,
      data.materiaClave,
      data.materiaNombre,      
      data.profesorIdentificador,
      data.profesorNombre,
      data.calificacionFinal,
      data.numeroFaltas,
      data.isMakeUpExam,
      data.makeUpExamReasonId,
      data.makeUpExamReasonName,
      data.makeUpExamName,
      data.total
    );
    
  }

  

  objectSetup(genericObject: any): void {

    this.identificador          =  genericObject.identificador;
    this.usuarioId              =  genericObject.usuarioId;
    this.fotografia             =  genericObject.fotografia;
    this.estatus                =  genericObject.estatus;
    this.programaId             =  genericObject.programaId;
    this.programaClave          =  genericObject.programaClave;
    this.programa               =  genericObject.programa;
    this.alumnoId               =  genericObject.alumnoId;
    this.nombreAlumno           =  genericObject.nombreCompleto;
    this.nombreCompleto = {
      type: 'links',
      options: [
        { name: 'userDataTab', value : genericObject.nombreCompleto.substring(0, 15)+'...', toolTip: genericObject.nombreCompleto},        
      ]
    }; 

    this.periodoId            = genericObject.periodoId;
    this.periodoClave = {
      type: 'cellWithTooltip',
      options: [
        { value : genericObject.periodoClave, toolTip: genericObject.periodoNombre},        
      ]
    };    
    this.periodoNombre          = genericObject.periodoNombre;
    
    this.campusId               = genericObject.campusId;
    this.campusClave = {
      type: 'cellWithTooltip',
      options: [
        { value : genericObject.campusClave, toolTip: genericObject.campusNombre},        
      ]
    };
    this.campusNombre           = genericObject.campusNombre;

    this.grupoId                = genericObject.grupoId;
    this.materia                = genericObject.materiaNombre;
    this.materiaClave           = genericObject.materiaClave;

    this.materiaNombre = {
      type: 'cellWithTooltip',
      options: [
        { value : genericObject.materiaNombre.substring(0, 15)+'...', toolTip: genericObject.materiaNombre},        
      ]
    };
    
    this.profesorIdentificador = {
      type: 'cellWithTooltip',
      options: [
        { value : genericObject.profesorIdentificador, toolTip: genericObject.profesorNombre},        
      ]
    };     
    this.profesorNombre         = genericObject.profesorNombre;
    this.calificacionFinal      = genericObject.calificacionFinal;
    this.numeroFaltas           = genericObject.numeroFaltas;
    this.isMakeUpExam           = genericObject.isMakeUpExam;
    this.total                  = genericObject.total;
        
    this.makeUpExamReasonId     = genericObject.makeUpExamReasonId;
    this.makeUpExamReasonName     = genericObject.makeUpExamReasonName;
    this.makeUpExamName = {
      type: 'options',
      options: [
        { name: 'makeUpExamtab'   , matIcon: (genericObject.isMakeUpExam ? 'SkypeCircleCheck': 'CirclePlus'), toolTip: genericObject.makeUpExamName },        
      ]
    };    
    
  }  
  
}
