import { TraductorService } from './../_services/traductor.service';
import { I18NextPipe } from 'angular-i18next';
import { SnackService } from './../services/snack-service.service';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { baseUrl, environment } from 'src/environments/environment';

@Component({
  selector: 'app-wizard-boton-login',
  templateUrl: './wizard-boton-login.component.html',
  styleUrls: ['./wizard-boton-login.component.scss']
  //encapsulation: ViewEncapsulation.None
})
export class WizardBotonLoginComponent implements OnInit {

  @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration;

  constructor(private snackServiceService: SnackService, private i18nextPipe: I18NextPipe, private traductorService: TraductorService) { }

  ngOnInit(): void {
  }

  //#region Propiedades
  public botonHtml: string;
  public botonHtmlCss: string;

  public panelOpenState : boolean = false;

  public texto: string = "Ir a Link4Campus";
  public textoColor: string = "#ffffff";
  public textoSize: number = 14;

  public fondoColor: string = "#2E75B6";
  public fondoColorSolido: boolean = true;

  public fondoColorInicial: string = "#2E75B6";
  public fondoColorFinal: string = "#2E75B6";

  public bordeAncho: string = "3";
  public bordeRedondeo: string = "12";
  public bordeColor: string = "#26CAF2";


  public sombraVertical: string = "7";
  public sombraHorizontal: string = "-5";
  public sombraDifuminado: string = "10";
  public sombraColor: string = "#26B5F2";

  public rellenoVertical: string = "10";
  public rellenoHorizontal: string = "15";

  public tipografia: string = "Arial";
  public familiaTipografias: string[] = ["Arial","Verdana","Helvetica","Tahoma","Trebuchet MS","Times New","Georgia","Garamond","Courier New","Brush Script MT"];
  //#endregion


  public getStyle():string {

    this.botonHtmlCss = `<a style="${ this.botonHtml }" onclick="signIn()">${ this.texto }</a>
    <script  type="text/javascript" src="https://alcdn.msauth.net/browser/2.16.1/js/msal-browser.min.js"></script>
    <script>

      const msalConfig = {
          auth: {
            clientId: "${environment.enves[baseUrl].AzureAD.auth.clientId}",
            authority: "${environment.enves[baseUrl].AzureAD.auth.authority}",
            //validateAuthority: true,
            postLogoutRedirectUri: "${environment.enves[baseUrl].AzureAD.auth.postLogoutRedirectUri}",
            redirectUri: "${environment.enves[baseUrl].AzureAD.auth.redirectUri}",
            //navigateToLoginRequestUrl: true,
          },
          cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
          },
      };

      const myMSALObj = new msal.PublicClientApplication(msalConfig);

      const loginRequest = {
          scopes: ["user.read", "openid", "profile","${environment.enves[baseUrl].AzureAD.resources.link4CampusApi.resourceScope}"],
      };


      function signIn() {
          myMSALObj.loginRedirect(loginRequest);
      }

    </script>`;

    this.botonHtml = `
    appearance: button;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: ${ this.rellenoHorizontal }px;
    padding-right: ${ this.rellenoHorizontal }px;
    padding-top: ${ this.rellenoVertical }px;
    padding-bottom: ${ this.rellenoVertical }px;

    border: ${ this.bordeAncho }px solid ${ this.bordeColor };
    border-radius: ${ this.bordeRedondeo }px;

    font-size: ${ this.textoSize }px;
    color: ${ this.textoColor };
    font-family: '${ this.tipografia }';


    background: linear-gradient(45deg, ${ this.fondoColorInicial }, ${ this.fondoColorFinal });
    text-decoration: none;

    box-shadow: ${ this.sombraHorizontal }px ${ this.sombraVertical }px ${ this.sombraDifuminado }px ${ this.sombraColor };
    `
    return this.botonHtml;
  }

  public onChange(fondoColorSolido):void {
    if (fondoColorSolido) {
      //this.fondoColor = "#2E75B6";
      this.fondoColorInicial = this.fondoColor;
      this.fondoColorFinal = this.fondoColor;
    }else{
      this.fondoColorInicial = "#2E75B6";
      this.fondoColorFinal = "#34F226";
    }
  }

  public fondoColorOnChange():void{
    this.fondoColorInicial = this.fondoColor;
    this.fondoColorFinal = this.fondoColor;
  }

  CopyDetails(): void {
    this.snackServiceService.mostrarSnackBack( this.traductorService.translate('wizard-boton.copiado'));
  }

}
// https://localhost:4200/
// #error=unsupported_response_type&
// error_description=AADSTS700054:+response_type+'id_token'+is+not+enabled+for+the+application.%0D%0ATrace+ID:+c19fb8c1-d279-4043-98cd-15bedcd16001%0D%0ACorrelation+ID:+b6219694-d76b-4adc-8927-d80f82e65033%0D%0ATimestamp:+2021-12-27+18:15:36Z&error_uri=https://login.microsoftonline.com/error?code=700054&state=63f4561a-421e-4d11-9010-fd5711c68927


/*
BSSO Telemetry: {"result":"Error","error":"bssoNotSupported","type":"TBAuthTelemetry","data":{"BSSO.info":"not-supported"},"traces":["BrowserSSO Initialized","window.navigator.msLaunchUri is not available for _pullBrowserSsoCookie"]}
 */
