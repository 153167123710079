import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

export interface PaginationEmitterEvent {
  url: string;
  limit: number;
}

export class Pagination {
  first       : string;
  last        : string;
  totalItems  : string;
  totalPages  : string;
  next       ?: string;
  prev       ?: string;

  constructor(
    first       : string,
    last        : string,
    totalItems  : string,
    totalPages  : string,
    next       ?: string,
    prev       ?: string,
  ) {
    this.first      = first;
    this.last       = last;
    this.totalItems = totalItems;
    this.totalPages = totalPages;
    this.next       = next;
    this.prev       = prev;
  }
}


export interface FilterEmitterEvent {
  prop: string;
  value: string;
}[]
@Component({
  selector: 'app-table-footer',
  templateUrl: './table-footer.component.html',
  styleUrls: ['./table-footer.component.scss']
})
export class TableFooterComponent implements OnChanges, AfterViewInit {

  @Input() pagination: Pagination;
  @Output() paginatorEmitter: EventEmitter<PaginationEmitterEvent> = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalPages: number;
  limiter: number;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    /* This is a way to get the limit from the pagination object. */
    if (changes['pagination'].currentValue) {
      const tempLimiter = this.pagination?.first?.split('limit=');
      this.limiter = Number(tempLimiter[tempLimiter.length - 1])
    }
  }

  /**
   * The function is called after the view is initialized. If the pagination is enabled, the total
   * number of pages is set to the number of pages returned by the paginator
   */
  ngAfterViewInit(): void {
    if (this.pagination) {
      this.totalPages = this.paginator.getNumberOfPages();
    }
  }

  /**
   * It emits an event with the url and the limit of the page size
   * @param {PageEvent} pageEvent - PageEvent
   */
  pageChanged(pageEvent: PageEvent): void {
    const CONFIGS = {
      isNext  : pageEvent.previousPageIndex < pageEvent.pageIndex,
      isPrev  : pageEvent.previousPageIndex > pageEvent.pageIndex,
      isLast  : pageEvent.pageIndex === Number(this.pagination.totalPages) - 1,
      isFirst : pageEvent.pageIndex === 0,
    }

    let url: string | undefined;

    this.getLimiter();

    if (CONFIGS.isFirst)
      url = `${this.pagination.first}${pageEvent.pageSize}`;

    else if (CONFIGS.isLast)
      url = this.pagination.last;

    else if (CONFIGS.isPrev)
      url = this.pagination.prev;

    else if (CONFIGS.isNext)
      url = this.pagination.next;


    this.paginatorEmitter.emit({ url, limit: pageEvent.pageSize })

  }

  /**
   * It takes the first page of the pagination object and finds the limit parameter. If it exists, it
   * will slice the string to only include the limit parameter
   */
  private getLimiter(): void {
    const initLimit = this.pagination.first.indexOf('limit=');

    if (initLimit !== -1) {
      const lastLimit = this.pagination.first.indexOf('&', initLimit);
      if (lastLimit !== -1) {
        this.pagination.first = this.pagination.first.slice(initLimit, lastLimit);
      }
      else if (lastLimit === -1) {
        this.pagination.first = this.pagination.first.slice(0, initLimit + 6);
      }
    }
  }

}
