export class StudentStatusChangeDTO {

  studentStatusChangeID: number;
  alumnoID: number;

  periodoClave: string;
  periodoID: number;

  campusClave: string;
  campusID: number;

  programaClave: string;
  programaID: number;

  status: string;
  statusId: number;

  fechaCambio: Date;
  usuario: number;

}


export class StudentStatusChangePost {

  alumnoID: number;

  estatusAcademicoId: number;

  periodoID: number;

  campusID: number;

  programaID: number;

  academicName?: string;

  academicDate?: Date;

  financialName?: string;

  financialDate?: Date;

  reasonChange?: string;
}
