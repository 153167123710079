import { UserDataFormModule } from './../../forms/user-data-form/user-data-form.module';
import { StudentLedgerModule } from './../../content/student-ledger/student-ledger.module';
import { DatosPersonalesAdminAcademicaComponent } from './../../../../../administracion-academica/avance-academico-modal/datos-personales-admin-academica/datos-personales-admin-academica.component';
import { HeaderModalDetailModule } from './../../components/header-modal-detail/header-modal-detail.module';
import { MakeUpExamFormModule } from './../../forms/make-up-exam-form/make-up-exam-form.module';
import { MaterialModule } from './../../modules/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LateralModalComponent } from './lateral-modal.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { I18NextModule } from 'angular-i18next';
import { PaginaConstruccionComponent } from '@app/pagina-construccion/pagina-construccion.component';
import { GradesModule } from '../../content/grades/grades.module';



@NgModule({
  declarations: [
    LateralModalComponent,
    PaginaConstruccionComponent,
    DatosPersonalesAdminAcademicaComponent    
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,    
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    
    HeaderModalDetailModule,
    GradesModule,    
    StudentLedgerModule,
    UserDataFormModule,
    MakeUpExamFormModule,


    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    I18NextModule.forRoot()
 
  ],
  exports:[
    LateralModalComponent
  ]
})
export class LateralModalModule { }
