export class PREVIOUS_INFORMATION_MODEL {

    usuarioId   :   number;
    nivelId     :   number;
    escuela     :   string;
    promedio    :   number;

    constructor(
        usuarioId   :   number,
        nivelId     :   number,
        escuela     :   string,
        promedio    :   number
    ){
        this.usuarioId  =   usuarioId;
        this.nivelId    =   nivelId  ;
        this.escuela    =   escuela  ;
        this.promedio   =   promedio ;
    }
  
  };