import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service';
import { Campaign, CampaignDTO } from 'src/app/_models';
import { CampaignService } from 'src/app/_services';
import { AcademicInformationTabComponent, GeneralInfoCampaignTabComponent } from '.';

@Component({
  selector: 'app-campaign-detail-modal',
  templateUrl: './campaign-detail-modal.component.html',
  styleUrls: ['./campaign-detail-modal.component.scss'],
})
export class CampaignDetailModalComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<CampaignDetailModalComponent>,
    private campaignsService: CampaignService,
    private snackService: SnackService,
    @Inject(MAT_DIALOG_DATA) public data: Campaign
  ) {}

  ngOnInit(): void {
    if (this.data) {
    }
  }
  @ViewChild(GeneralInfoCampaignTabComponent) generalinfoCamComponent: GeneralInfoCampaignTabComponent;
  @ViewChild(AcademicInformationTabComponent) academicinfoCamComponent: AcademicInformationTabComponent;

  public CerrarModal(): void {
    this.dialog.close();
  }

  public async campaignRegister(): Promise<void> {
    if (!this.generalinfoCamComponent.generalForm.valid || !this.academicinfoCamComponent.acaInfoForm.valid) {
      this.snackService.mostrarSnackBack('Datos incompletos.');
    } else {
      let genTab = Object.assign(this.generalinfoCamComponent.generalForm.value);
      let acaTab = Object.assign(this.academicinfoCamComponent.acaInfoForm.value);

      genTab.campaignLeadEntryIds = this.arraytoString(genTab.campaignLeadEntryIds);
      acaTab.campaignTermIds = this.arraytoString(acaTab.campaignTermIds);
      acaTab.campaignCampusIds = this.arraytoString(acaTab.campaignCampusIds);
      acaTab.campaignLevelIds = this.arraytoString(acaTab.campaignLevelIds);
      acaTab.campaignProgramIds = this.arraytoString(acaTab.campaignProgramIds);

      let newCampaign: CampaignDTO = {
        campaignId: 0,
        name: genTab.name,
        description: genTab.description,
        startDate: genTab.startDate,
        endDate: genTab.endDate,
        campaignResponsibleId: genTab.campaignResponsibleId,
        campaignLeadEntryIds: genTab.campaignLeadEntryIds,

        campaignTermIds: acaTab.campaignTermIds,
        campaignCampusIds: acaTab.campaignCampusIds,
        campaignLevelIds: acaTab.campaignLevelIds,
        campaignProgramIds: acaTab.campaignProgramIds,
        campaignStudentTypeId: acaTab.campaignStudentTypeId,
        leadTarget: acaTab.leadTarget,
        conversionTarget: acaTab.conversionTarget,
        expenseBudget: acaTab.expenseBudget,
        expectedIncome: acaTab.expectedIncome,
      };

      console.log('new campaign', newCampaign);

      if (this.data) {
      } else {
        await this.newCampaign(newCampaign);
      }
    }
  }

  public async newCampaign(campaign: CampaignDTO): Promise<void> {
    let response = await this.campaignsService.postCampaign(campaign).toPromise();
    if (response.success) {
      this.snackService.mostrarSnackBack('Se ha registrado la información.');
      this.ngOnInit();
    } else {
      this.snackService.mostrarSnackBack('La información no pudo ser registrada.');
    }
  }

  public arraytoString(array) {
    return array ? (Array.isArray(array) ? array.map((item) => item).join(',') : array) : '';
  }
}
