import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalRecursosComponent } from '../modal-recursos.component';

@Component({
  selector: 'app-modal-enlace-adjunto',
  templateUrl: './modal-enlace-adjunto.component.html',
  styleUrls: ['./modal-enlace-adjunto.component.scss']
})
export class ModalEnlaceAdjuntoComponent implements OnInit {

  constructor(
    public dialogRef : MatDialogRef<ModalRecursosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  
  onNoclick(){
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.data.nombreOriginal="";
    this.data.nombreFisico="";
  }

}
