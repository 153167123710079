import { Component, OnInit, Inject, TemplateRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Profesor } from '../../../../_models/profesor';
import { Subscription } from 'rxjs';
import { Pais } from '../../../../_models/pais';
import { EntidadFederativa } from '../../../../_models/entidad-federativa';
import { DireccionService } from '../../../../_services/direccion.service';
import { Direccion } from '../../../../_models/direccion';

@Component({
  selector: 'app-form-datos-contacto',
  templateUrl: './form-datos-contacto.component.html',
  styleUrls: ['./form-datos-contacto.component.scss']
})
export class FormDatosContactoComponent implements OnInit {

  //Pasar datos de Padre a Hijo
  @Input() datosUsuarioChild: any;
  @Input() usuarioId: number;
  // idUsuario = 248;

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>
  public errorActualizaProfesor: string = "";
  public objValidacionCancelar: Profesor = null;
  public dialogRefAdver: any;
  public profesorId: number = 0;
  public contactoForm: FormGroup;
  public tipoAccion: number;
  public subscripcionEntidadFederativa: Subscription;
  public boolCambio: boolean = false;

  public paises: Pais[] = [];
  public estados: EntidadFederativa[] = [];

  constructor(public dialogProfesorRef: MatDialogRef<FormDatosContactoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Direccion,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private direccionService: DireccionService,
  ) {
    this.direccionService.obtenerPaises().subscribe(
      paises => {
        this.paises = paises;

        if (!!this.data.pais) {
          this.onPaisSelected(this.data.pais);
        }
        this.contactoForm.patchValue(this.data);
        this.contactoForm.markAsUntouched();
      });
  }

  public onPaisSelected(pais: number) {
    this.estados = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estados = estados;
        })
    }
  }

  public cambiarVistaEditar(idProfesor: number) {
    this.data.tipoAccion = 2;
    this.profesorId = idProfesor;
  }

  public cambiarVistaEliminar() {
    this.data.tipoAccion = 4;
  }

  ngOnInit(): void {
    this.inicializarForm();

    if (this.usuarioId) {
      this.direccionService.obtenerDireccionByProfesorId(this.usuarioId).subscribe(datosUsuarioContacto => {
        this.contactoForm.get('entidadFederativaId').setValue(datosUsuarioContacto.entidadFederativaId);
        this.contactoForm.get('usuarioID').setValue(datosUsuarioContacto.usuarioId);
        this.contactoForm.get('tipoDireccionId').setValue(1);
        this.contactoForm.get('calle').setValue(datosUsuarioContacto.calle);
        this.contactoForm.get('numeroExterior').setValue(datosUsuarioContacto.numeroExterior);
        // this.contactoForm.get('direccion').setValue(datosUsuarioContacto.direccion);
        // this.contactoForm.get('pais').setValue(datosUsuarioContacto.pais);
        this.contactoForm.get('codigoPostal').setValue(datosUsuarioContacto.codigoPostal);
        this.contactoForm.get('colonia').setValue(datosUsuarioContacto.colonia);
       
        this.contactoForm.get('ciudad').setValue(datosUsuarioContacto.ciudad);
        // this.onPaisSelected(datosUsuarioContacto.PaisID);

        this.direccionService.obtenerEntidadFederativa(datosUsuarioContacto.entidadFederativaId).subscribe(datosEntidadFederativa => {

          this.contactoForm.get('pais').setValue(datosEntidadFederativa.paisId);
          this.onPaisSelected(datosEntidadFederativa.paisId);
        });

      });



    }
  }

  public inicializarForm() {

    this.contactoForm = new FormGroup({
      usuarioID: new FormControl(null),
      tipoDireccionId: new FormControl(1),
      pais: new FormControl(null, [Validators.required]),
      entidadFederativaId: new FormControl(null, [Validators.required]),
      ciudad: new FormControl('', [Validators.required]),
      codigoPostal: new FormControl('', [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      calle: new FormControl('', [Validators.required]),
      numeroExterior: new FormControl('', [Validators.required]),
      colonia: new FormControl('', [Validators.required]),
    });

    this.contactoForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
    });
  }

  get tipoDireccionId() { return this.contactoForm.get('tipoDireccionId'); }
  get pais() { return this.contactoForm.get('pais'); }
  get entidadFederativaId() { return this.contactoForm.get('entidadFederativaId'); }
  get ciudad() { return this.contactoForm.get('ciudad'); }
  get codigoPostal() { return this.contactoForm.get('codigoPostal'); }
  get calle() { return this.contactoForm.get('calle'); }
  get numeroExterior() { return this.contactoForm.get('numeroExterior'); }
  get colonia() { return this.contactoForm.get('colonia'); }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogProfesorRef.close(null);
    }
  }

public editar(): void {
  this.data = Object.assign(this.contactoForm.value);
  this.direccionService.modificarDireccion(this.data).subscribe(
    _direccion => {
      if (_direccion) {
        this.snackBar.open('Se ha guardado la informacion.', 'OK', {duration: 5000});
      } else {
        this.snackBar.open('Ha ocurrido un error al guardar la informacion. Por favor,intente de nuevo mas tarde.', 'OK', {duration: 5000});
      }
    }
  )
}


  public enviar(): void {
    this.contactoForm.controls.usuarioID.setValue(this.datosUsuarioChild.usuarioId);
    this.data = Object.assign(this.contactoForm.value);
    this.direccionService.agregarDireccion(this.data).subscribe(
      _direccion => {
        if (_direccion) {
          this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        this.snackBar.open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }
}
