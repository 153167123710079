export class AttributesDto {
    attributeId?: number;
    institucionId?: string;
    code?: string;
    name?: string;
    tipoAccion?: number;
  }

  export class StudentAttributesDto
  {
      alumnoId: number;
      attributeId: number;
  }

  export class AttributesGuardarDto {
    alumnoId: number;
    attributes: number[];
    tipoCandidato?: number;
  }