import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { Alumno, Campaign, Campus, Entry, InfoPrograma, Nivel, Periodo } from 'src/app/_models';
import {
  CampaignService,
  CampusService,
  InscripcionService,
  LeadEntryService,
  NivelService,
  PeriodoService,
} from 'src/app/_services';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { CampaignDetailModalComponent, DeleteCampaignComponent } from '.';
import { InfoProgramaService } from '../_services/infoPrograma.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
})
export class CampaignsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public inscripcionService: InscripcionService,
    private campusService: CampusService,
    private snackBar: MatSnackBar,
    public programaservice: InfoProgramaService,
    private periodoService: PeriodoService,
    public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private generadorExcel: GeneracionExcelServiceService,
    private nivelservice: NivelService,
    private campaignsService: CampaignService,
    private leadEntryService: LeadEntryService,
    private router: Router
  ) {}

  displayedColumns: string[] = [
    'select',
    'nombre',
    'cantidadleads',
    'leadsmeta',
    'cantidadconversion',
    'conversionmeta',
    'fechas',
  ];
  //#region  inputs all
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allNivel') private allNivel: MatOption;
  @ViewChild('allEntry') private allEntry: MatOption;
  //#endregion

  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public numIngresoSeleccionado: number = 0;
  public filtroForm: FormGroup;
  public datafilter: any;
  public arregloFiltroOriginal: any[] = [];
  public subscriptionCampus: Subscription;
  public campusList: Campus[] = [];
  public Programas: InfoPrograma[] = [];
  public Periodos: Periodo[] = [];
  public arregloFiltroModificado: any[] = [];
  private periodosactivos: any[] = [];
  public datosEliminar: [];
  public tipoDeAlumno: boolean;
  public nivelList: Nivel[] = [];
  public campaignsList: Campaign[] = [];
  public entryList: Entry[] = [];

  //#region FormControls
  nameF = new FormControl();
  entryF = new FormControl();
  periodoF = new FormControl();
  ProgramaF = new FormControl();
  nivelF = new FormControl();
  CampusF = new FormControl();
  StartDateF = new FormControl();
  EndDateF = new FormControl();
  //#endregion

  ngOnDestroy() {
    if (this.ExcelSubscription != null) {
      this.ExcelSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getCampaigns();
    this.inicializarForm();
    this.LlenarFiltros();
    //this.paginador.itemsPerPageLabel = "Registros por página";
    //this.paginador.nextPageLabel = "Página siguiente";
    //this.paginador.previousPageLabel = "Página anterior";
    //this.paginador.firstPageLabel = "Primera página";
    //this.paginador.lastPageLabel = "Última página";
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      periodoF: new FormControl([]),
      idnombre: new FormControl(),
      ProgramaId: new FormControl([]),
      CampusId: new FormControl([]),
      StartDate: new FormControl([]),
      EndDate: new FormControl([]),
    });
    this.CampusF.setValue(['-1']);
    this.ProgramaF.setValue(['-1']);
    this.periodoF.setValue(['-1']);
    this.nivelF.setValue['-1'];
    this.entryF.setValue['-1'];
    this.StartDateF.setValue['-1'];
    this.EndDateF.setValue['-1'];
  }

  //#region Gets
  public obtenerCampus() {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe((campus: Campus[]) => {
      this.campusList = campus;
      this.CampusF.setValue([...this.campusList.map((item) => item.campusId), 0, -1]);
    });
  }

  public obtenerProgramas() {
    this.programaservice.obtenerInfoPrograma().subscribe((programas: InfoPrograma[]) => {
      this.Programas = programas;
      this.ProgramaF.setValue([...this.Programas.map((item) => item.programaId), 0, -1]);
    });
  }

  public obtenerPeriodo() {
    let today = new Date();
    this.periodoService.obtenerPeriodos().subscribe((periodo: Periodo[]) => {
      this.Periodos = periodo;
      this.periodoF.setValue([...this.Periodos.map((item) => item.periodoId), 0, -1]);
    });
  }

  public obtenerNiveles(): void {
    this.nivelservice.getNivel().subscribe((nivel: Nivel[]) => {
      this.nivelList = nivel;
      this.nivelF.patchValue([...this.nivelList.map((item) => item.nivelId), 0, -1]);
    });
  }

  public getEntrys(): void {
    this.leadEntryService.getEntrys().subscribe((entry: ApiResponse<Array<Entry>>) => {
      this.entryList = entry.data;
      this.entryF.patchValue([...this.entryList.map((item) => item.sourceId), 0]);
    });
  }

  public getCampaigns(): void {
    this.campaignsService.getCampaigns().subscribe((campaigns: ApiResponse<any>) => {
      if (campaigns.data) {
        for (let i = 0; i < campaigns.data.length; i++) {
          if (campaigns.data[i].startDate == '0001-01-01T00:00:00') {
            campaigns.data[i].startDate = '';
          } else {
            campaigns.data[i].startDate = this.util.formatearFecha(campaigns.data[i].startDate);
          }
          if (campaigns.data[i].endDate == '0001-01-01T00:00:00') {
            campaigns.data[i].endDate = '';
          } else {
            campaigns.data[i].endDate = this.util.formatearFecha(campaigns.data[i].endDate);
          }
        }
        //console.log(campaigns.data);
        this.dataSource = new MatTableDataSource(campaigns.data);
        this.arregloFiltroOriginal = [...campaigns.data];
        this.dataSource.paginator = this.paginator;
        this.datafilter = campaigns.data;
      } else {
        this.dataSource = new MatTableDataSource([]);
        this.arregloFiltroOriginal = [];
      }
    });
  }
  //#endregion

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  isAllSelected() {
    if (this.datafilter) {
      this.numIngresoSeleccionado = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numIngresoSeleccionado === numRows;
    }
  }

  checkboxLabel(row?: Alumno): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.alumnoId + 1}`;
  }

  ExcelSubscription: Subscription;
  public descargarExcel(elemento?: any) {
    let IdAlumnos;
    if (elemento != null && elemento.length > 0) {
      IdAlumnos = elemento.map((m) => m.alumnoId.toString());
    } else {
      IdAlumnos = this.datafilter.map((m) => m.alumnoId.toString());
    }
    this.inscripcionService.descargarExcel(IdAlumnos).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, 'Reinscripcion');
    });
  }

  public vaciarArregloModificado() {
    this.arregloFiltroModificado.length = 0;
  }

  public filtro() {
    let periodos: any[] = this.periodoF.value == null ? [] : this.periodoF.value;
    let name: string = this.nameF.value == null ? '' : this.nameF.value;
    let entrada: string = this.entryF.value == null ? '' : this.entryF.value;
    let campus: any[] = this.CampusF.value == null ? [] : this.CampusF.value;
    let programa: any[] = this.ProgramaF.value == null ? [] : this.ProgramaF.value;
    let nivel: any[] = this.nivelF.value == null ? [] : this.nivelF.value;
    let StartDate: Date = this.StartDateF.value == null ? null : this.StartDateF.value;
    let EndDate: Date = this.EndDateF.value == null ? null : this.EndDateF.value;

    let filtroModificado: any = [];
    filtroModificado = [...this.arregloFiltroOriginal];
    let ids: any;
    filtroModificado = filtroModificado.filter((f) => {
      // ids =
      //   f.campaignTerm.length > 0
      //     ? (ids = f.campaignTerm.map((f) => {
      //         return f.periodId;
      //       }))
      //     : -1;

      // let result: boolean = false;

      // if (ids == -1) {
      //   return (result = periodos.indexOf(ids) > -1);
      // } else {
      //   ids.forEach((e) => {
      //     if (!result) {
      //       result = periodos.indexOf(e ? e : 0) > -1;
      //     }
      //   });
      // }
      // return result;
      return this.filfunc(periodos, f.campaignTerm, 'periodId');
      //return periodos.indexOf(f.campaignTerm.length>0 ? f.campaignTerm.periodId : -1) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      return f.name.toLowerCase().search(name.toLowerCase()) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      return this.filfunc(campus, f.campaignCampus, 'campusId');
      //return campus.indexOf(f.campaignCampus ? f.campaignCampus.campusId : -1) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      return this.filfunc(programa, f.campaignProgram, 'programId');
      //return programa.indexOf(f.campaignProgram ? f.campaignProgram.programId : -1) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      return this.filfunc(nivel, f.campaignLevel, 'levelId');
      //return nivel.indexOf(f.campaignLevel ? f.campaignLevel.levelId : -1) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      return this.filfunc(entrada, f.campaignLeadEntry, 'leadEntryId');
      //return entrada.indexOf(f.campaignLeadEntry ? f.campaignLeadEntry.leadEntryId : -1) > -1;
    });
    filtroModificado = filtroModificado.filter((f) => {
      if (StartDate != null) {
        let sdate: Date = new Date(f.startDate ? f.startDate : null);
        let edate: Date = new Date(f.endDate ? f.endDate : null);
        let q = StartDate.getTime() >= sdate.getTime();
        let w = StartDate.getTime() <= edate.getTime();
        return StartDate.getTime() >= sdate.getTime() && StartDate.getTime() <= edate.getTime();
      }
      return true;
    });
    //filtros desactivados
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<any>(true, []);
  }

  filfunc(filter: any, arraylvl1: any, arraylvl2: any) {
    let ids: any;
    let result: boolean = false;
    let id: any;

    ids =
      arraylvl1.length > 0
        ? (ids = arraylvl1.map((item) => {
            Object.keys(item).forEach((key) => {
              if (key == arraylvl2) {
                id = item[key];
                return item[key];
              }
            });
            return id;
          }))
        : -1;

    if (ids == -1) {
      return (result = filter.indexOf(ids) > -1);
    } else {
      ids.forEach((e) => {
        if (!result) {
          result = filter.indexOf(e ? e : 0) > -1;
        }
      });
    }
    return result;
  }

  public LlenarFiltros() {
    this.obtenerCampus();
    this.obtenerProgramas();
    this.obtenerPeriodo();
    this.obtenerNiveles();
    this.getEntrys();

    //#region obtiene periodos activos
    // let today = new Date();
    // this.periodoService.obtenerPeriodos().subscribe(
    //   (periodos: Periodo[]) => {
    //     this.Periodos = periodos;
    //     for (let x of periodos) {
    //       let inicio = new Date(x.fechaInicio);
    //       let fin = new Date(x.fechaFin);
    //       if (inicio < today && fin > today) {
    //         this.periodosactivos.push(x.periodoId);
    //       }
    //     }
    //     if(this.periodosactivos.length > 0){
    //       //desactiva pre seleccion de periodos activos
    //       //this.periodoF.setValue([...this.periodosactivos]);
    //     }
    // });
    //#endregion

    this.filtro();
  }

  public LimpiarFiltros() {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
    this.dataSource.paginator = this.paginator;
  }

  public borrar(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }

    const dialogo = this.util.abrirDialogoLateral(DeleteCampaignComponent, datos);
    dialogo.afterClosed().subscribe((result) => {
      if (result) {
        this.getCampaigns();
      }
      this.selection = new SelectionModel<any>(true, []);
    });
  }

  CampaignLeads(campaign: any): void {
    this.campaignsService.setCampaignId(campaign.campaignId);
    this.router.navigate(['/leads']);
  }

  newCampaign(): void {
    const campaignmodal = this.util.abrirDialogoLateral(CampaignDetailModalComponent);
    campaignmodal.afterClosed().subscribe((result) => {
      this.getCampaigns();
    });
  }

  //#region Checkboxes
  toggleAllSelectionPeriodo() {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.Periodos.map((item) => item.periodoId), 0, -1]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionPrograma() {
    if (this.allPrograma.selected) {
      this.ProgramaF.patchValue([...this.Programas.map((item) => item.programaId), 0, -1]);
    } else {
      this.ProgramaF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.CampusF.patchValue([...this.campusList.map((item) => item.campusId), 0, -1]);
    } else {
      this.CampusF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionNivel() {
    if (this.allNivel.selected) {
      this.nivelF.patchValue([...this.nivelList.map((item) => item.nivelId), 0, -1]);
    } else {
      this.nivelF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionEntry() {
    if (this.allEntry.selected) {
      this.entryF.patchValue([...this.entryList.map((item) => item.sourceId), 0]);
    } else {
      this.entryF.patchValue([]);
    }
    this.filtro();
  }

  tosslePerOnePeriodo() {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if (this.periodoF.value.length == this.Periodos.length + 1) {
      this.allPeriodo.select();
    }
    this.filtro();
  }

  tosslePerOnePrograma() {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.ProgramaF.value.length == this.Programas.length + 1) {
      this.allPrograma.select();
    }
    this.filtro();
  }

  tosslePerOneCampus() {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.CampusF.value.length == this.campusList.length + 1) {
      this.allCampus.select();
    }
    this.filtro();
  }

  tosslePerOneNivel() {
    if (this.allNivel.selected) {
      this.allNivel.deselect();
      return false;
    }
    if (this.nivelF.value.length == this.nivelList.length + 1) {
      this.allNivel.select();
    }
    this.filtro();
  }

  tosslePerOneEntry() {
    if (this.allEntry.selected) {
      this.allEntry.deselect();
      return false;
    }
    if (this.entryF.value.length == this.entryList.length) {
      this.allEntry.select();
    }
    this.filtro();
  }

  //#endregion
}
