import { ContenidoParcial } from './../../../_models/ensenanza-aprendizaje-profesor';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CorreoComponent } from '../correo/correo.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {ModalDetalleComponent} from '../modal-detalle/modal-detalle.component';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { AsistenciaConsultar } from 'src/app/_models/ensenanzaAprendizajeAlumno';
import { Recurso } from 'src/app/_models/recurso';
import { TipoRecurso } from 'src/app/_models/tipo-recurso';
import { I18NextPipe } from 'angular-i18next';


@Component({
  selector: 'app-consultar',
  templateUrl: './consultar.component.html',
  styleUrls: ['./consultar.component.scss']
})
export class ConsultarComponent implements OnInit {
  @Input() grupo: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginatorC: MatPaginator;
  public alumnoId: number;
  public filtroForm: FormGroup;
  listParciales: any[];
  listContenidos: TipoRecurso[] = [];
  tipoConsulta: number;
  displayedColumns: string[] = ['parcial', 'fecha', 'asistencia'];
  public dataSource: MatTableDataSource<any>;
  Columns: string[] = ['parcial', 'recurso', 'titulo', 'puntuacion', 'fecha', 'calificacion'];
  public dataSourceCalificaciones: MatTableDataSource<any>;
  grupoSelect;
  public arregloFiltroOriginal: any[];
  public arregloFiltroOriginalCal: any[];
  public ultimoParcial: number;
  existeParcialFinal=false;
  contenido: ContenidoParcial[] = [];
  contenidoGrupoParcial: any[] = [];

  constructor(public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private ensenanzaServ: EnsenanzaAprendizajeService,
    private dialog: MatDialog,
    public util: UtileriasService,
    private i18nextPipe: I18NextPipe,
  ) { }

  ngOnInit(): void {
    this.alumnoId = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;
    if (this.grupo) {
      this.grupoSelect = this.grupo;
    }
    this.tipoConsulta = 0;
    this.inicializarForm();
    this.cargarContenidoP();
    this.cargarTipoContenido();
    this.cargarConsultar();
  }

  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      Parcial: new FormControl(-1),
      fecha: new FormControl(),
      asistencia: new FormControl(0),
      tipoRecurso: new FormControl(0)
    });
  }

  public cargarTipoContenido() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerTipoContenido().subscribe((contenido: ApiResponse<TipoRecurso[]>) => {
      if (!!contenido.data) {
        this.listContenidos = contenido.data.filter(contenido => contenido.nombre != 'Contenido');
      }
    })
  }

  //#region Get Parciales

  //#region  Cargar contenidos
  public cargarContenidoP() {
    this.ensenanzaServ.obtenerContenido(this.grupo.grupoId).subscribe((res: ApiResponse<any>) => {
      if (res.data) {
        this.contenidoGrupoParcial = res.data.grupoParcial;
        this.cargarParcialesP(res.data.clave);
      }
    })
  }
  //#endregion
  public cargarParcialesP(clave: string) {
    this.ensenanzaServ.obtenerParcialesProfesor(clave, this.grupo.grupoId).subscribe(parciales => {
      if (parciales.exito) {
        if(parciales.dataParcialFinal){
          this.existeParcialFinal = true;
          this.ultimoParcial = this.listParciales[this.listParciales.length -1].idPacial;
        }

        parciales.dataParciales.forEach(parcial => {

          let parcialText = '_tableroProfesor.parcial';
          if (!!this.existeParcialFinal && parcial == parciales.dataParciales.length) {
            parcialText = '_tableroProfesor.parcial-final';
          };

          if (this.contenidoGrupoParcial.length > 0) {
            let nuevoParcial = this.contenidoGrupoParcial.find(object => {
              return object.parcial == parcial
            });
            if (nuevoParcial) {
              let parcialobj = {
                parcial: nuevoParcial.parcial,
                ponderacion: nuevoParcial.ponderacion,
                nombre: nuevoParcial.nombre,
              }
              if (!parcialobj?.nombre) {
                parcialobj.nombre = this.translate(parcialText)// + ' ' + parcial;
              }
              this.contenido.push(parcialobj);
            }else{
              let _parcialObj = {
                parcial: parcial,
                ponderacion: 0,
                nombre: this.translate(parcialText)// + ' ' + parcial
              }
              this.contenido.push(_parcialObj);
            }
          }
          else {
            let _parcialObj = {
              parcial: parcial,
              ponderacion: 0,
              nombre: this.translate(parcialText) + ' ' + parcial
            }
            this.contenido.push(_parcialObj);
          }
        });
      }
    });
  }

  //#endregion

  public limpiar() {
    this.inicializarForm();
    if (this.tipoConsulta == 0) {
      this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
      this.dataSource.paginator = this.paginator;
    } else {
      this.dataSourceCalificaciones = new MatTableDataSource(this.arregloFiltroOriginalCal);
      this.dataSourceCalificaciones.paginator = this.paginatorC;
    }

  }

  listaRecusos: Recurso[] = [];

  cargarConsultar() {
    var idAlumno = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;
    if (this.tipoConsulta == 0) {
      this.EnsenanzaAprendizajeAlumnoService.obtenerConsultaAsistencia(this.grupoSelect.campusId, this.grupo.periodoId, idAlumno, this.grupo.grupoId).subscribe(
        (consultaAsis: ApiResponse<AsistenciaConsultar[]>) => {
          this.dataSource = new MatTableDataSource(consultaAsis.data);
          this.dataSource.paginator = this.paginator;
          this.arregloFiltroOriginal = consultaAsis.data;
      })
    } else {
      this.EnsenanzaAprendizajeAlumnoService.obtenerConsultaCalificaciones(idAlumno, this.grupo.grupoId).subscribe((row: ApiResponse<Recurso[]>) => {
        this.listaRecusos = row.data;
        this.dataSourceCalificaciones = new MatTableDataSource(this.listaRecusos);
        console.log("dataSourceCalificaciones", this.listaRecusos)
        this.dataSourceCalificaciones.paginator = this.paginatorC;
        this.arregloFiltroOriginalCal = this.listaRecusos;
      })
    }
  }

  public enviarCorreo(): void {
    this.dialog.open(CorreoComponent, { data: this.grupoSelect, disableClose: true });
  }

  public filtrar() {
    var parcial = this.filtroForm['value'].Parcial;
    var asistencia = this.filtroForm['value'].asistencia
    var asistio = asistencia == 1 ? true : asistencia == 2 ? false : 0;
    var fecha = this.filtroForm['value'].fecha
    var recurso = this.filtroForm['value'].tipoRecurso
    let filtroModificado: any = [];
    if (this.tipoConsulta == 0) {
      filtroModificado = this.arregloFiltroOriginal
      if (parcial != -1) {
        filtroModificado = filtroModificado.filter(row => row.parcial === parcial);
      }
      if (asistencia != 0) {
        filtroModificado = filtroModificado.filter(row => row.esFalta === asistio);

      }
      if (fecha != null) {
        filtroModificado = filtroModificado.filter(row => row.fecha.substring(0, 10) === fecha);
      }
      this.dataSource = new MatTableDataSource(filtroModificado);
      this.dataSource.paginator = this.paginator;
    } else {
      filtroModificado = this.arregloFiltroOriginalCal
      if (parcial != -1) {
        filtroModificado = filtroModificado.filter(row => row.parcial === parcial);
      }
      if (recurso != 0) {
        filtroModificado = filtroModificado.filter(row => row.recursoId === recurso);

      }
      this.dataSourceCalificaciones = new MatTableDataSource(filtroModificado);
      this.dataSourceCalificaciones.paginator = this.paginatorC;
    }
  }

  ExcelSubscription: Subscription;
  public exportExcel() {
    this.ExcelSubscription = this.EnsenanzaAprendizajeAlumnoService.descargarExcel(this.arregloFiltroOriginalCal).subscribe((res: Blob) => {
      var filename = "Calificaciones.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  public verRecurso(recurso){
    recurso.entregar=true;
    recurso.calificacion=true;
    recurso.grupoId = this.grupo.grupoId;
    recurso.ultimoParcial = this.ultimoParcial;
    recurso.existeParcialFinal = this.existeParcialFinal;
    recurso.soloDetalle = true;
    this.util.abrirDialogoLateral(ModalDetalleComponent,recurso);
  }
}
