import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnInit, Inject, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { AccionEnum } from '../../../_models/accion.enum';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Campus } from '../../../_models/campus';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { TipoApoyoFinanciero } from '../../../_models/tipo-apoyo-financiero';
import { CampusService } from '../../../_services/campus.service';
import { TipoApoyoFinancieroService } from '../../../_services/tipo-apoyo-financiero.service';
import { SnackService } from 'src/app/services/snack-service.service';

@Component({
  selector: 'app-tipo-apoyo-financiero-crear-editar',
  templateUrl: './tipo-apoyo-financiero-crear-editar.component.html',
  styleUrls: ['./tipo-apoyo-financiero-crear-editar.component.scss']
})
export class TipoApoyoFinancieroCrearEditarComponent implements OnInit, OnDestroy {

  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  public dialogoRefAdver: any;
  public tipoApoyoForm: FormGroup;
  public campusList: Campus[] = [];
  public subscriptionCampus: Subscription;
  public tipoAccion: number;
  public boolCambio: boolean = false;
  public datosTipoApoyoFinanciero: TipoApoyoFinanciero;

  constructor(public dialogRef: MatDialogRef<TipoApoyoFinancieroCrearEditarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TipoApoyoFinanciero,
    private campusService: CampusService,
    private dialog: MatDialog,
    private tipoApoyoFinancieroService: TipoApoyoFinancieroService,
    private snackService: SnackService,
    private traductorService: TraductorService) {
    if (this.data == undefined) {
      this.tipoAccion = AccionEnum.CREAR;
    } else {
      this.tipoAccion = AccionEnum.EDITAR;
    }
  }

  ngOnInit(): void {
    this.obtenerCampus();
    this.inicializarForm();
  }

  public inicializarForm() {
    this.tipoApoyoForm = new FormGroup({
      clave: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.data.clave, [Validators.required]),
      campusId: new FormControl(this.tipoAccion === AccionEnum.CREAR ? 0 : this.data.campusId, [Validators.required, this.validateId]),
      descripcion: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.data.descripcion, [Validators.required]),
      porcentaje: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.data.porcentaje, [Validators.required, Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]),
      activo: new FormControl(this.tipoAccion === AccionEnum.CREAR ? false : this.data.activo),
      convenio: new FormControl(this.tipoAccion === AccionEnum.CREAR ? false : this.data.convenio),
      PromedioMinimo: new FormControl(this.tipoAccion === AccionEnum.CREAR ? "" : this.data.promedioMinimo, [Validators.required, Validators.pattern(/^[1-9]+/)]),
      numMateriasReprobadas: new FormControl(this.tipoAccion === AccionEnum.CREAR ? "" : this.data.numMateriasReprobadas, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      FinPeriodoTipo: new FormControl(this.tipoAccion === AccionEnum.CREAR ? 1 : this.data.finPeriodoTipo),
      DiasAntesFinPeriodo: new FormControl(this.tipoAccion === AccionEnum.CREAR ? "" : this.data.diasAntesFinPeriodo === 0 ? "" : this.data.diasAntesFinPeriodo, [Validators.pattern(/^[1-9]+/)]),
      DiasDespuesFinPeriodo: new FormControl(this.tipoAccion === AccionEnum.CREAR ? "" : this.data.diasDespuesFinPeriodo === 0 ? "" : this.data.diasDespuesFinPeriodo, [Validators.pattern(/^[1-9]+/)])
    });

    this.tipoApoyoForm.valueChanges.subscribe(val => {
      this.boolCambio = true;
    });
  }

  get clave() { return this.tipoApoyoForm.get('clave'); }
  get descripcion() { return this.tipoApoyoForm.get('descripcion'); }
  get porcentaje() { return this.tipoApoyoForm.get('porcentaje'); }
  get PromedioMinimo() { return this.tipoApoyoForm.get('PromedioMinimo'); }

  public getErrorFormCampus(controlName: string): string {
    let error = '';
    const control = this.tipoApoyoForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Campus es requerido.";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }


  public changeRadioFinPeriodo() {
    this.limpiarDatosTipoApoyoForm();
    switch (this.FinPeriodoTipo.value) {
      case 2: {
        this.diasAntes.setValidators(Validators.required);
        this.diasAntes.updateValueAndValidity();
        break;
      }
      case 3: {
        this.diasDespues.setValidators(Validators.required);
        this.diasDespues.updateValueAndValidity();
        break;
      }
    }
  }

  public limpiarDatosTipoApoyoForm() {
    this.diasAntes.removeValidators(Validators.required);
    this.diasDespues.removeValidators(Validators.required);
    this.diasAntes.updateValueAndValidity();
    this.diasDespues.updateValueAndValidity();

  }


  get FinPeriodoTipo() { return this.tipoApoyoForm.get('FinPeriodoTipo'); }
  get diasAntes() { return this.tipoApoyoForm.get('DiasAntesFinPeriodo'); }
  get diasDespues() { return this.tipoApoyoForm.get('DiasDespuesFinPeriodo'); }




  public obtenerCampus() {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      }
    );
  }

  public enviarTipoApoyo() {
    this.datosTipoApoyoFinanciero = Object.assign(this.tipoApoyoForm.value);
    if (this.tipoAccion === AccionEnum.CREAR) {
      this.tipoApoyoFinancieroService.agregarTipoApoyoFinanciero(this.datosTipoApoyoFinanciero).subscribe(
        tipoApoyoFinanciero => {
          if (tipoApoyoFinanciero != null) {
            this.tipoApoyoFinancieroService.invocarMetodoObtenerTipoApoyoFinanciero();
            let mensaje: string = this.traductorService.translate('guardadoCorrecto');
            this.snackService.mostrarSnackBack(mensaje);
            // this.cambiarVistaEditar(tipoApoyoFinanciero);
            // this.inicializarForm();
            this.dialogRef.close(null);

          } else {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        },
        error => {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      );
    } else if (this.tipoAccion === AccionEnum.EDITAR) {
      this.datosTipoApoyoFinanciero.tipoApoyoFinancieroId = this.data.tipoApoyoFinancieroId;
      this.tipoApoyoFinancieroService.editarTipoApoyoFinanciero(this.datosTipoApoyoFinanciero).subscribe(
        editado => {
          if (editado) {
            this.tipoApoyoFinancieroService.invocarMetodoObtenerTipoApoyoFinanciero();
            this.dialogRef.close(null);
            let mensaje: string = this.traductorService.translate('guardadoCorrecto');
            this.snackService.mostrarSnackBack(mensaje);
          } else {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        },
        error => {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      );
    }
  }

  public cambiarVistaEditar(tipoApoyoFinanciero: TipoApoyoFinanciero) {
    this.boolCambio = false;
    this.tipoAccion = AccionEnum.EDITAR;
    this.data = tipoApoyoFinanciero;
    this.inicializarForm;
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(null);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  public ngOnDestroy(): void {
    if (this.subscriptionCampus) {
      this.subscriptionCampus.unsubscribe();
    }
  }
}
