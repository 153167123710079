import { BIOGRAPHICAL_INFORMATION_MODEL } from './../../../../../core.domain/models/user/biographical-information.model';
import { GeneroService } from 'src/app/_services/genero.service';
import { Genero } from 'src/app/_models/genero';
import { BIOGRAPHICAL_INFORMATION } from './../../../../../core.application/validators/user-data/biographical-information.validators';
import { MessageErrors } from './../../../../../core.application/functions/messageErros.function';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EntidadFederativa } from '@app/_models/entidad-federativa';
import { Pais } from '@app/_models/pais';
import { DireccionService } from '@app/_services/direccion.service';
import { EstadoCivil } from '@app/_models/estado-civil';
import { Subscription } from 'rxjs';
import { FORM_VALID_MODEL } from '@app/clean-architecture/core.domain/models/user/form-valid-model';

@Component({
  selector: 'app-biographical-information-form',
  templateUrl: './biographical-information-form.component.html',
  styleUrls: ['./biographical-information-form.component.scss']
})
export class BiographicalInformationFormComponent implements OnInit, OnDestroy, OnChanges {
  
  //#region  Input, Output & ViewChild
  @Input() data: BIOGRAPHICAL_INFORMATION_MODEL;
  @Input() cancelledFields: string[] = [];
  @Input() disabledledFields: string[] = [];

  @Output() formValidity: EventEmitter<FORM_VALID_MODEL> = new EventEmitter(); 
  @Output() formData = new EventEmitter<BIOGRAPHICAL_INFORMATION_MODEL>();
  //#endregion

  //#region Subscriptions
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Properties
  public biographicalInformationForm: FormGroup = this.formBuilder.group(BIOGRAPHICAL_INFORMATION);
  public ErrorMsg: any;
  private status: boolean = false;
  
  public paises: Array<Pais>;
  public entidadesFederativas: Array<EntidadFederativa>;
  public estadosCiviles: Array<EstadoCivil>;
  public generos: Array<Genero>;

  public  deletedInputs = {
    legalGeneroId       : false,
    generoId            : false,
    estadoCivilId       : false,
    curp                : false,
    ciudadaniaPaisId    : false,
    entidadNacimiento   : false,
    ciudadNacimiento    : false
  };
  //#endregion

  constructor(
    private formBuilder: FormBuilder,
    private _direccionService: DireccionService,
    private _generoService: GeneroService
  ) { 
    this.setErrors();
  }

  ngOnInit(): void {    
    this._getAllMethods();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] !== undefined) {
      this._setData();
    }
  }
  
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }


   //#region setData
   private _setData():void
   {  this.biographicalInformationForm.setValue(this.data);
      // this.biographicalInformationForm.get('legalGeneroId')?.setValue(this.data.legalGeneroId);
      // this.biographicalInformationForm.get('generoId')?.setValue(this.data.generoId);
      // this.biographicalInformationForm.get('ciudadaniaId')?.setValue(this.data.ciudadaniaId);
      // this.biographicalInformationForm.get('estadoCivilId')?.setValue(this.data.estadoCivilId);
      // this.biographicalInformationForm.get('curp')?.setValue(this.data.curp);
      // this.biographicalInformationForm.get('ciudadaniaPaisId')?.setValue(this.data.ciudadaniaPaisId);
      // this.biographicalInformationForm.get('entidadNacimiento')?.setValue(this.data.entidadNacimiento);
   }
   //#endregion

  //#region Helpers
  /**
   * The function sets the error message for the form
   */
  public setErrors(): void {
    this.ErrorMsg = MessageErrors.setErrorMessage(this.biographicalInformationForm);
  }

  /**
   * If the cancelledFields array has a length greater than 0, then for each field in the
   * cancelledFields array, if the biographicalInformationForm has a control with the name of the field, then clear the
   * validators for that control and add the field to the deletedInputs object.
   */
   _deleteCancelledFields(): void {
    if (this.cancelledFields.length > 0) {
      this.cancelledFields.forEach(field => {
        if (this.biographicalInformationForm.get(field)) {
          this.biographicalInformationForm.get(field)?.clearValidators();
          this.deletedInputs[field] = true;
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  /**
   * _disabledCancelledFields() is a function that disables and clears validators for fields that are
   * not required
   */
  _disabledCancelledFields(): void {
    if (this.disabledledFields.length > 0) {
      this.disabledledFields.forEach(field => {
        if (this.biographicalInformationForm.get(field)) {
          this.biographicalInformationForm.get(field)?.clearValidators();
          this.biographicalInformationForm.get(field)?.disable();
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  private _subscribeToForm(): void {

    this.biographicalInformationForm.markAllAsTouched();
    this._Emit();

    this.Subscription.add(
      this.biographicalInformationForm.statusChanges
      .subscribe(status => {
        this.setErrors();
        this.status = status === 'VALID';
        this._Emit();
      })
    );

    this.Subscription.add(
      this.biographicalInformationForm
      .get('ciudadaniaPaisId')
      ?.valueChanges
      .subscribe(value => {        
        this._getStatsByCountry(value);        
      })
    );
  }

  private _Emit():void
  {
    this.formValidity.emit(
      new FORM_VALID_MODEL(
        3,
        'BiographicalInformationFormComponent',
        this.status
      )
    );
    this.formData.emit(
      new BIOGRAPHICAL_INFORMATION_MODEL(
        this.biographicalInformationForm.get('legalGeneroId')?.value,
        this.biographicalInformationForm.get('generoId')?.value,
        this.biographicalInformationForm.get('ciudadaniaId')?.value,
        this.biographicalInformationForm.get('estadoCivilId')?.value,
        this.biographicalInformationForm.get('curp')?.value,
        this.biographicalInformationForm.get('ciudadaniaPaisId')?.value,
        this.biographicalInformationForm.get('entidadNacimiento')?.value,
        this.biographicalInformationForm.get('ciudadNacimiento')?.value
      )
    );
  }
  //#endregion

  //#region Methods

  private _getAllMethods():void
  {
    this._subscribeToForm();  
    this._getCountries();
    this._getEstadosCiviles();
    this._getGeneros();
    this._deleteCancelledFields();
    this._disabledCancelledFields();
  }

  private _getCountries(): void {
    this.Subscription.add(
      this._direccionService.obtenerPaises().subscribe(
        (paises: Array<Pais>) => {
          this.paises = paises;
        })
    );
  }

  private _getStatsByCountry(paisld: number): void {
    if (this.ciudadaniaPaisId?.valid) {
      this.Subscription.add(
        this._direccionService.obtenerEntidadesFederativas(paisld).subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
          this.entidadesFederativas = entidadesFederativas;
        })
      );
    }
  }

  private _getEstadosCiviles(): void {
    this.Subscription.add(
      this._direccionService.obtenerEstadoCivil().subscribe(
        (estodosCiviles: Array<EstadoCivil>) => {
          this.estadosCiviles = estodosCiviles;
        })
    );
  }

  private _getGeneros(): void {
    this.Subscription.add(
      this._generoService.obtenerGenero().subscribe((generos: Array<Genero>) => {
        this.generos = generos;      
      })
    );
  }
  //#endregion

  //#region Controls  

  get ciudadaniaPaisId() {
    return this.biographicalInformationForm.get('ciudadaniaPaisId');
  }
  //#endregion
}