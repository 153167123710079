import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Documento } from 'src/app/_models/documento';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { DatosDocumentos } from 'src/app/_models/usuario';
import { DocumentoService } from 'src/app/_services/documento.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { MatDialog } from '@angular/material/dialog';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { FormGroup, FormControl } from '@angular/forms';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { throwError } from 'rxjs';
import { SolicitudApoyoFinancieroService } from 'src/app/_services/solicitud-apoyo-financiero.service';
import { UsuarioDocumento } from 'src/app/_models/usuario-documento';
import { EnsenanzaAprendizajeService } from '../_services/ensenanza-aprendizaje.service';
import { AdminAcademicaService } from '../_services/admin-academica.service';
import { TraductorService } from '../_services/traductor.service';

@Component({
  selector: 'app-documentos-alumno',
  templateUrl: './documentos-alumno.component.html',
  styleUrls: ['./documentos-alumno.component.scss']
})
export class DocumentosAlumnoComponent implements OnInit {
  @ViewChild('modalVisorPDF') modalVisorPDF: TemplateRef<'modalVisorPDF'>;

  displayedColumns: string[] = ['select', 'tipo', 'nombre', 'fecha'];
  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<DatosDocumentos>(true, []);
  public infoCargado = false;
  public documentos: any = [];
  public numDocumentoSeleccionado = 0;
  public documentoList: Documento[] = [];
  public tipoDocSelected: number;
  public date: Date = new Date();
  public cargaDocumentoForm: FormGroup;
  public renderizarForm = false;
  public tipoAccionComprobanteIdentidad: DatosDocumentos[] = [];
  public tipoAccionCartaCompromiso: DatosDocumentos[] = [];
  public tipoAccionIdentificacionFotografia: DatosDocumentos[] = [];
  public tipoAccionEstudiosPrevios: DatosDocumentos[] = [];
  public comprobanteIdentidadCargado: boolean = false;
  public identificacionFotoCargado: boolean = false;
  public cartaCompromisoCargado: boolean = false;
  public estudiosPreviosCargado: boolean = false;
  public cartaCompromiso: any;
  public comprobanteIdentidad: any;
  public identificacionFotografia: any;
  public estudiosPrevios: any;
  public pdf: any;
  public mime: string;
  public imagen: string;
  public modalVisorPDFRef: any;
  public procesoList: any[] = [];
  public tipoDeProceso: string;
  public usuarioSesion: any;
  public documentosUsuario: any[] = [];
  public documentosUsuarioNuevoIngreso: any[] = [];
  public usuarioId: number;
  public solicitudId: number;
  public tipoProceso: string;
  public usuarioBusuqueda: any;
  public existeSubProceso: boolean = false;

  constructor(private documentoService: DocumentoService,
    public nuevoIngresoService: NuevoIngresoService,
    private dialog: MatDialog,
    private utileriasService: UtileriasService,
    private snackService: SnackService,
    private solicitudApoyoService: SolicitudApoyoFinancieroService,
    private enseñanzaAprendizajeService: EnsenanzaAprendizajeService,
    private adminAcademicaService: AdminAcademicaService,
    private traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.enseñanzaAprendizajeService.obtenerUsuarioConectado().subscribe((usuario) => {
      this.usuarioSesion = usuario;
      this.usuarioId = this.usuarioSesion.usuarioId;
      console.log("this.usuario", this.usuarioSesion)
      this.nuevoIngresoService.obtenerCandidatoCorreo(this.usuarioSesion.correo).subscribe(
        usuarioBusuqueda => {
          console.log("usuarioBusuqueda", usuarioBusuqueda)
          this.usuarioBusuqueda = usuarioBusuqueda;
          if (this.usuarioBusuqueda.alumno[0].tipoCandidatoId === null) {
            this.existeSubProceso = false;
          } else {
            this.existeSubProceso = true;
          }
          this.inicializarForm();
        })
      this.documentoService.obtenerProceso().subscribe(procesos => { this.procesoList = procesos.data; });
    });
  }

  public inicializarForm() {
    this.cargaDocumentoForm = new FormGroup({
      usuarioId: new FormControl(this.usuarioSesion.usuarioId),
      tipoProceso: new FormControl(),
      tipoDocumento: new FormControl(),
      docs: new FormControl(),
      docsEditar: new FormControl(),
    });
    this.renderizarForm = true;
  }

  public async onProcesoSelected(claveProceso: string) {
    this.tipoDeProceso = claveProceso;
    this.documentosUsuarioNuevoIngreso = [];
    this.dataSource = new MatTableDataSource(this.documentosUsuarioNuevoIngreso);
    this.documentoService.ObtenerDocumentosProcesoSubProceso(claveProceso, this.usuarioBusuqueda.alumno[0].alumnoId).subscribe(
      (documentos: ApiResponse<any>) => {
        this.documentoList = documentos.data;
      }
    );
    await this.cargarDatosUsuario(claveProceso).then(() => {
      this.cargarDatos(claveProceso, this.documentosUsuario);
    });
  }

  /** Servicio que obtiene los documentos de cada usuario según la clave de proceso */
  async cargarDatosUsuario(claveProceso) {
    this.adminAcademicaService.DocumentosUsuario(this.usuarioId, claveProceso).subscribe(
      (docs: ApiResponse<any>) => {
        this.documentosUsuario = docs.data;
        this.cargarDatos(claveProceso, this.documentosUsuario);
      }, (error) => {
        this.snackService.mostrarSnackBack(error);
      }
    )
  }


  public docSelected(docId: number): void {
    if (!!docId) {
      this.tipoDocSelected = docId;
    }
  }

  public cargarDatos(tipoProceso: string, documentosUsuario) {
    this.tipoProceso = tipoProceso;
    if (tipoProceso == 'APOYO') {
      this.obtenerSolicitud();
      this.cargarDatosApoyoFinanciero();
    } else if (tipoProceso == 'NUEVO') {
      this.cargarDatosIngreso(documentosUsuario);
    }
  }

  public obtenerSolicitud() {
    this.solicitudApoyoService.obtenerIdSolicitudApoyo(this.usuarioId).subscribe(
      (response: ApiResponse<number>) => {
        if (response.success) {
          this.solicitudId = response.data;
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      })
  }

  public cargarDatosApoyoFinanciero() {
    if (this.solicitudId != null) {
      this.solicitudApoyoService.obtenerDocumentos(this.usuarioSesion.usuarioId, this.solicitudId).subscribe(
        (documentosUsuario: ApiResponse<UsuarioDocumento[]>) => {
          if (documentosUsuario.success) {
            if (documentosUsuario.data.length > 0) {
              this.documentos = documentosUsuario.data;
              this.dataSource = new MatTableDataSource(documentosUsuario.data);
              this.selection = new SelectionModel<any>(true, []);
              this.infoCargado = true;
            }
          } else {
            this.snackService.mostrarSnackBack(documentosUsuario.message);
          }
        }
      )
    }
  }

  public cargarDatosIngreso(documentos) {
    this.documentosUsuarioNuevoIngreso = [];
    this.documentosUsuarioNuevoIngreso = documentos;
    this.documentos = [];
    this.documentoService.ObtenerDocumentosProceso("NUEVO").subscribe(documentos => {
      this.documentoList = documentos.data;
      if (this.documentosUsuarioNuevoIngreso.length > 0) {
        this.documentoList.forEach(documento => {
          let documentoArreglo: any = null;
          let documentoEncontrado: DatosDocumentos = null;
          documentoArreglo = this.documentosUsuarioNuevoIngreso.filter(x => x.documentoId === documento.documentoId);
          if (documentoArreglo.length >= 1) {
            documentoArreglo = documentoArreglo.sort(this.compareDate);
            documentoEncontrado = documentoArreglo.find(x => x.nombreFisico);
            if (documentoEncontrado !== null) {
              documentoEncontrado.nombreDocumento = documento.nombre;
              this.documentos.push(documentoEncontrado);
            }
          }
        });
        if (this.documentos.length > 0) {
          this.dataSource = new MatTableDataSource(this.documentos);
          this.selection = new SelectionModel<DatosDocumentos>(true, []);
          this.infoCargado = true;
        }
      } else {
        this.infoCargado = false;
      }
    });
  }

  compareDate(emp1: any, emp2: any) {
    var emp1Date = new Date(emp1.date).getTime();
    var emp2Date = new Date(emp2.date).getTime();
    return emp1Date > emp2Date ? 1 : -1;
  }

  /**
   * Metodo para subir un documento con el select
   * @param event 
   */
  public onFileChange(event: any) {
    if (this.tipoProceso == "NUEVO") {
      let documentos: any[] = [];
      let documentosEditar: any[] = [];
      const reader = new FileReader();
      const [file] = event.target.files;
      if (event.target.files && event.target.files.length) {
        let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === this.tipoDocSelected);
        reader.onload = () => {
          let documento: any = {
            usuarioId: this.usuarioId,
            documentoId: tipoDocumentoSelecionado.documentoId,
            nombreOriginal: file.name,
            nombreFisico: reader.result,
            fechaCreacion: this.date,
            nombreDocumento: tipoDocumentoSelecionado.nombre,
            estatusDocumentoId: 1
          }
          documentos.push(documento);
          if (this.dataSource === undefined || this.dataSource.data.length === 0) {
            this.dataSource = new MatTableDataSource(documentos);
            this.selection = new SelectionModel<any>(false, []);
            this.dataSource._updateChangeSubscription();
            this.documentos = documentos;
            this.infoCargado = true;
          } else {
            documentosEditar = this.dataSource.data;
            documentosEditar = documentosEditar.filter(x => x.documentoId !== this.tipoDocSelected);
            this.dataSource = new MatTableDataSource(documentosEditar);
            this.selection = new SelectionModel<DatosDocumentos>(true, []);
            this.dataSource.data.push(documento);
            this.dataSource._updateChangeSubscription();
            this.documentos = documentosEditar;
          }
          this.registrarDoc(documentos);
        }
        reader.readAsDataURL(file);
      }
    } else {
      if (this.solicitudId !== 0) {
        let documentos: any[] = [];
        let documentosEditar: any[] = [];
        const reader = new FileReader();
        const [file] = event.target.files;
        if (event.target.files && event.target.files.length) {
          let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === this.tipoDocSelected);
          reader.onload = () => {
            let documento: any = {
              usuarioId: this.usuarioId,
              documentoId: tipoDocumentoSelecionado.documentoId,
              nombreOriginal: file.name,
              nombreFisico: reader.result,
              fechaCreacion: this.date,
              nombreDocumento: tipoDocumentoSelecionado.nombre,
              estatusDocumentoId: 1,
              solicitudApoyoFinancieroId: this.solicitudId
            }
            documentos.push(documento);
            if (this.dataSource === undefined || this.dataSource.data.length === 0) {
              this.dataSource = new MatTableDataSource(documentos);
              this.selection = new SelectionModel<any>(false, []);
              this.dataSource._updateChangeSubscription();
              this.documentos = documentos;
              this.infoCargado = true;
            } else {
              documentosEditar = this.dataSource.data;
              documentosEditar = documentosEditar.filter(x => x.documentoId !== this.tipoDocSelected);
              this.dataSource = new MatTableDataSource(documentosEditar);
              this.selection = new SelectionModel<DatosDocumentos>(true, []);
              this.dataSource.data.push(documento);
              this.dataSource._updateChangeSubscription();
              this.documentos = documentosEditar;
            }
            this.registrarDoc(documentos);
          }
          reader.readAsDataURL(file);
        }
      } else {
        this.snackService.mostrarSnackBack("Es necesario contar con una solicitud de apoyo financiero, paraa subir documento a este proceso.");
      }
    }
  }

  /**
   * Metodo para editar un documento seleccionado
   * @param event 
   * @param tipoId 
   */
  public onFileChangeEditar(event, tipoDocumentoId: number) {
    let documentosEditar: DatosDocumentos[] = [];
    let documentosGuardar: any[] = [];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === tipoDocumentoId);
      reader.onload = () => {
        let documento = {
          usuarioId: this.usuarioId,
          documentoId: tipoDocumentoSelecionado.documentoId,
          nombreOriginal: file.name,
          nombreFisico: reader.result,
          fechaCreacion: this.date,
          nombreDocumento: tipoDocumentoSelecionado.nombre,
          estatusDocumentoId: 1,
          solicitudApoyoFinancieroId: this.solicitudId
        }
        documentosEditar = this.documentos;
        documentosEditar = documentosEditar.filter(x => x.documentoId !== tipoDocumentoId);
        this.dataSource = new MatTableDataSource(documentosEditar);
        this.selection = new SelectionModel<DatosDocumentos>(true, []);
        this.dataSource.data.push(documento);
        this.dataSource._updateChangeSubscription();
        this.documentos = this.dataSource.data;
        documentosGuardar.push(documento);
        this.registrarDoc(documentosGuardar);
      }
      reader.readAsDataURL(file);
    }
  }

  public registrarDoc(docs: Array<DatosDocumentos>) {
    if (docs != null) {
      if (this.tipoDeProceso == 'APOYO') {
        this.solicitudApoyoService.registrarDocumentos(docs).subscribe(
          (cargaDocumentos: SimpleResponse) => {
            if (cargaDocumentos.success) {
              let mensaje: string = this.traductorService.translate('guardadoDocumentos');
              this.snackService.mostrarSnackBack(mensaje);
            } else {
              this.snackService.mostrarSnackBack("Ha ocurrido un error al guardar los documentos. Por favor, intente de nuevo más tarde.");
            }
          }
        );
      }
      if (this.tipoDeProceso == 'NUEVO') {
        this.nuevoIngresoService.registrarDocumentos(docs).subscribe(
          (cargaDocumentos: SimpleResponse) => {
            if (cargaDocumentos) {
              let mensaje: string = this.traductorService.translate('guardadoDocumentos');
              this.snackService.mostrarSnackBack(mensaje);
            } else {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los documentos. Por favor, intente de nuevo más tarde.');
            }
          }
        );
      }
    }
  }

  /**
   * Metodo para eliminar un documento
   * @param documentId 
   */
  public eliminarDocumento(documentId: number) {
    if (documentId) {
      this.nuevoIngresoService.deleteDocumentoById(this.usuarioSesion.usuarioId, documentId).subscribe(
        (respuestaEliminar: SimpleResponse) => {
          if (respuestaEliminar.success) {
            let documentosEliminar: DatosDocumentos[] = [];
            documentosEliminar = this.documentos;
            documentosEliminar = documentosEliminar.filter(x => x.documentoId !== documentId);
            this.dataSource = new MatTableDataSource(documentosEliminar);
            this.selection = new SelectionModel<DatosDocumentos>(true, []);
            this.documentos = documentosEliminar;
            if (this.documentos.length === 0) {
              this.infoCargado = false;
            }
            this.dataSource._updateChangeSubscription();
            this.nuevoIngresoService.refrescarTabla();
            this.nuevoIngresoService.updateTabs();
            this.snackService.mostrarSnackBack('Se ha eliminado el documento.');
          } else {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar el documento, intentelo mas tarde.');
          }
        })
    }
  }

  public visualizarPdf(refDocumento: number) {
    if (refDocumento > 0) {
      this.nuevoIngresoService.GetDocumentosCandidatoByCorreo(this.usuarioSesion.correo, refDocumento).subscribe(
        (datosUsuarioDocumento: ApiResponse<UsuarioIngreso>) => {
          if (datosUsuarioDocumento.success) {
            var documentos = datosUsuarioDocumento.data.usuarioDocumento.filter(x => x.documentoId === refDocumento);
            var documentoVisualizar = documentos.sort(this.compareDate);
            this.mime = documentoVisualizar[0].nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : /\.(png|jpeg|jpg|gif|tiff|bmp)$/.test(documentoVisualizar[0].nombreOriginal) ? "image/" + /\.(png|jpeg|jpg|gif|tiff|bmp)$/.exec(documentoVisualizar[0].nombreOriginal)[1] : "application/octect-stream";

            this.pdf = this.base64ToArrayBuffer(documentoVisualizar[0].nombreFisico);
            if (this.mime.indexOf("image") >= 0) {
              this.imagen = `data:${this.mime};base64,${documentoVisualizar[0].nombreFisico}`;
            }
            if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
              this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
            } else {
              const blob = new Blob([this.pdf], { type: this.mime });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            }
          }

        }


      );
    } else {
      let archivo = this.cargaDocumentoForm.controls[refDocumento].value;
      this.mime = archivo.split(",")[0].split(":")[1].split(";")[0];
      this.imagen = this.cargaDocumentoForm.controls[refDocumento].value;
      this.pdf = this.base64ToArrayBuffer(archivo);
      if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
        this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
      } else {
        const blob = new Blob([this.pdf], { type: this.mime });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    }
  }

  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public cerrarModalPdf() {
    this.modalVisorPDFRef.close();
  }

  isAllSelected() {
    this.numDocumentoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numDocumentoSeleccionado === numRows;
  }

  checkboxLabel(row?: DatosDocumentos): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.documentoId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row))
  }

}
