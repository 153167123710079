import { Deserializable } from './deserializable';
import { Entry } from './entry';

export class Campaign implements Deserializable {
  campaignId?: number;
  name: string;
  startDate: Date;
  endDate: Date;
  description: string;
  leads?: number;
  conversions: number;
  expenseBudget: string;
  expectedIncome: string;
  responsible?: number;
  entry?: Entry;
  constructor() {
    this.campaignId = null;
    this.name = null;
    this.startDate = null;
    this.endDate = null;
    this.description = null;
    this.leads = null;
    this.conversions = null;
    this.expenseBudget = null;
    this.expectedIncome = null;
    this.responsible = null;
  }

  deserialize(input: any): this {
    Object.keys(input).forEach((key) => {
      input[key] = input[key] ? input[key] : null;
    });
    Object.assign(this, input);
    return this;
  }
}
