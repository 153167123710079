import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { FiltroAdminAcademicoAlumnoDto } from '@app/_models/FiltroAdminAcademicoAlumnoDto';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, Subject } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import { AvanceCursoInformacionAlumno } from '../_models/AvanceCursoInformacionAlumno';

@Injectable({
  providedIn: 'root'
})
export class AvanceCursoService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo para obtener el avance del curso para llenar la tabla de seguimiento
   * @returns
   */
  public avanceCursoAlumnos(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): Observable<ApiResponse<any[]>> {
    return this.httpClient.post<ApiResponse<any[]>>(`${environment.enves[baseUrl].apiUrl}/AvanceCurso/AvanceCursoAlumnos/${pagina}/${registrosPagina}`, filtro);
  }

  /**
   * Metodo para obtener la informacion del usuario para mostrar en el modal
   * @returns
   */
  public informacionAvanceCursoByAlumnoId(alumnoId: number, grupoId: number): Observable<ApiResponse<AvanceCursoInformacionAlumno>> {
    return this.httpClient.get<ApiResponse<AvanceCursoInformacionAlumno>>(`${environment.enves[baseUrl].apiUrl}/AvanceCurso/InformacionAvanceCursoByAlumnoId/${alumnoId}/${grupoId}`);
  }

  /**
   * Metodo para cambiar los datos personales del alumno
   * @returns
   */
  public guardarDatosPersonales(datos: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/AvanceCurso/GuardarDatosPersonales`, datos);
  }

  /**
   * Get recursos que esten publicados
   * @param grupoId
   * @returns
   */
  public obtenerContenidoAlumno(alumnoId: number, grupoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AvanceCurso/GetParcialesContenido/${alumnoId}/${grupoId}`);
  }

  /**
   * Descargar Excel
   * @param IdAlumnos
   * @returns
   */
  public descargarExcel(IdAlumnos: Array<string>) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/AvanceCurso/DownloadExcel`, JSON.stringify(IdAlumnos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  /**
   * Descargar Constancia
   * @param Alumnos
   * @returns
   */
   public descargarConstancia(Alumnos: Array<any>) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Letter/BuildPDF`, JSON.stringify(Alumnos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }
}
