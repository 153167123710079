import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfiguracionCuotaService } from '../../../_services/configuracion-cuota.service';
import { ModalInscripcionComponent } from '../../configuracion-inscripcion/modal-inscripcion/modal-inscripcion.component';

@Component({
  selector: 'app-modal-pagos',
  templateUrl: './modal-pagos.component.html',
  styleUrls: ['./modal-pagos.component.scss']
})
export class ModalPagosComponent implements OnInit {

  public formEstatus: FormGroup;

  constructor(public dialogRef: MatDialogRef<ModalInscripcionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private configuracion: ConfiguracionCuotaService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.inicializarForm();
  }

  public inicializarForm() {
    this.formEstatus = new FormGroup({
      estatus: new FormControl(true)
    })
  }

  public editarFormaPago() {
    let formValue = this.formEstatus.value;
    let datosFormPago = {
      formaPagoId: this.data.formaPagoId,
      institucionId: this.data.institucionId,
      activo: formValue.estatus
    }
    this.configuracion.actualizarFormaPago(datosFormPago).subscribe(estatusActualizado => {
      if (estatusActualizado) {
        this.configuracion.metodoObtenerFormaPago();
        this.snackBar.open('Se ha editado el estatus de la forma de pago correctamente.', 'OK', { duration: 5000 });
      } else {
        this.snackBar.open('Ha ocurrido un error al editar el estatus de la forma de pago. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    });  
  }
}
