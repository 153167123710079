import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Programa } from '../../_models/programa';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { CoordinadorDto } from '@app/_models/CoordinadorDto';
import { TraductorService } from '@app/_services/traductor.service';
import { SnackService } from '@app/services/snack-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';

@Component({
  selector: 'app-programa-academico-coordinador',
  templateUrl: './programa-academico-coordinador.component.html',
  styleUrls: ['./programa-academico-coordinador.component.scss']
})
export class ProgramaAcademicoCoordinadorComponent implements OnInit {

  @Input() datosPrograma: Programa;
  @ViewChild('dialogoAgregarCoordinadorModal') dialogoAgregarCoordinadorModal: TemplateRef<any>;
  @ViewChild('dialogoListaCoordinadorModal') dialogoListaCoordinadorModal: TemplateRef<any>;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginatorLista') paginatorLista: MatPaginator;
  public displayedColumns: string[] = ['select', 'code', 'name', 'departamento'];
  public displayedColumnsListaCoordinador: string[] = ['select', 'code', 'name', 'departamento'];
  public dialogoRefAgregarCoordinadorModal: any;
  public dialogoRefListaCoordinadorModal: any;
  public boolCambio: boolean = false;
  public dialogoRefAdver: any;
  public numCoordinadorSeleccionado = 0;
  public numCoordinadorSeleccionadoLista = 0;
  selection = new SelectionModel<any>(true, []);
  selectionListaCoordinador = new SelectionModel<any>(true, []);
  public dataSource: MatTableDataSource<any>;
  public dataSourceListaCoordinador: MatTableDataSource<any>;
  public infoCargado: boolean = false;
  private arrOriginal: any[] = [];
  private arrOriginalCoordinadorAsignado: any[] = [];
  public datafilter: any;
  public infoCargadoLista: boolean = false;

  constructor(private paginador: MatPaginatorIntl,
    private programaService: ProgramaAcademicoService,
    private traductorService: TraductorService,
    private snackService: SnackService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    console.log("DatosPrograma", this.datosPrograma)
    this.inicializarPaginador();
    this.obtenerCoordinadorById();
  }

  /**
   * Metodo para iniciar el paginador de la tabla
   */
  public inicializarPaginador(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  /**
   * Metodo para obtener la informacion del paquete a editar
   */
  public obtenerCoordinadorById() {
    this.programaService.coordinadoresByProgramaId(this.datosPrograma.programaId).subscribe((response: ApiResponse<CoordinadorDto[]>) => {
      if (response.success) {
        if (response.data.length > 0) {
          this.dataSource = new MatTableDataSource(response.data);
          this.arrOriginalCoordinadorAsignado = [...response.data];
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargado = true;
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 100);
        } else {
          this.dataSource = new MatTableDataSource([]);
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargado = true;
        }
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
  * Metodo que abre el modal y muestra los servicios disponibles
  */
  public agregarCoordinador(): void {
    this.programaService.listaCoordinadores().subscribe((response: ApiResponse<CoordinadorDto[]>) => {
      if (response.success) {
        if (response.data !== null) {
          let result = this.arrOriginalCoordinadorAsignado.map(obj => ({
            usuarioId: obj.usuarioId
          }));
          let gruposDisponibles = response.data.filter(function (objFromA) {
            return !result.find(function (objFromB) {
              return objFromA.usuarioId == objFromB.usuarioId
            })
          })
          this.arrOriginal = [...gruposDisponibles];
          this.dataSourceListaCoordinador = new MatTableDataSource(gruposDisponibles);
          this.datafilter = this.arrOriginal;
          this.selectionListaCoordinador = new SelectionModel<any>(true, []);
          this.infoCargadoLista = true;
          setTimeout(() => {
            this.dataSourceListaCoordinador.paginator = this.paginatorLista;
          }, 100);
        } else {
          this.dataSourceListaCoordinador = new MatTableDataSource([]);
          this.selectionListaCoordinador = new SelectionModel<any>(true, []);
          this.infoCargadoLista = true;
        }
        this.dialogoRefListaCoordinadorModal = this.dialog.open(this.dialogoListaCoordinadorModal, {
          panelClass: "dialogo-agregar",
          width: '50%',
          height: 'auto',
          maxHeight: '80vh',
          disableClose: true
        });
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
  * Metodo para cerrar el modal de la lista de coordinadores
  */
  public cerrarModalListaCoordinador() {
    this.dialogoRefListaCoordinadorModal.close();
  }

  /**
   * Metodo para agregar los Coordinadores 
   * @param elemento 
   */
  public anadirCoordinador(elemento: any[]) {
    this.arrOriginalCoordinadorAsignado = this.arrOriginalCoordinadorAsignado.concat(elemento);
    this.dataSource = new MatTableDataSource(this.arrOriginalCoordinadorAsignado);
    this.selection = new SelectionModel<any>(true, []);
    this.dataSource.paginator = this.paginator;
    this.dialogoRefListaCoordinadorModal.close();
  }

  /**
 * Filtro
 * @param filterValue 
 */
  public buscarNombreClave(filterValue: string) {
    filterValue = filterValue.toLowerCase();
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    if (filterValue != "") {
      filtroModificado = filtroModificado.filter(el => {
        return el.identificador.toLowerCase().search(filterValue) > -1 || el.nombreCompleto.toLowerCase().search(filterValue) > -1;
      })
    }
    this.dataSourceListaCoordinador = new MatTableDataSource(filtroModificado);
    this.dataSourceListaCoordinador.paginator = this.paginatorLista;
    this.datafilter = filtroModificado;
  }

  /**
   * Metodo para eliminar los Coordinadores 
   * @param elemento 
   */
  public borrarCoordinador(elemento: any[]) {
    let result = elemento.map(obj => ({
      usuarioId: obj.usuarioId
    }));
    this.arrOriginalCoordinadorAsignado = this.arrOriginalCoordinadorAsignado.filter(function (objFromA) {
      return !result.find(function (objFromB) {
        return objFromA.usuarioId == objFromB.usuarioId
      })
    })
    this.dataSource = new MatTableDataSource(this.arrOriginalCoordinadorAsignado);
    this.selection = new SelectionModel<any>(true, []);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Metodo para agregar un nuevo coordinador
   */
  public EditarCoordinador(): void {
    let dataCoordinador = this.dataSource.data
    this.programaService.editarCoordinador(this.datosPrograma.programaId, dataCoordinador).subscribe((response: SimpleResponse) => {
      if (response.success) {
        this.programaService.definirBoolCambio(false);
        this.programaService.invocarMetodo();
        let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
        this.snackService.mostrarSnackBack(mensaje);
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
  * Metodo Selecion data table
  * @returns 
  */
  isAllSelectedListaCoordinador() {
    this.numCoordinadorSeleccionadoLista = this.selectionListaCoordinador.selected.length;
    const numRows = this.dataSourceListaCoordinador.data.length;
    return this.numCoordinadorSeleccionadoLista === numRows;
  }

  /**
    * Metodo Selecion data table
    * @returns 
    */
  checkboxLabelListaCoordinador(row?: any): string {
    if (!row) {
      return `${this.isAllSelectedListaCoordinador() ? 'select' : 'deselect'} all`;
    }
    this.numCoordinadorSeleccionadoLista = this.selectionListaCoordinador.selected.length;
    return `${this.selectionListaCoordinador.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }

  /**
    * Metodo Selecion data table
    * @returns 
    */
  masterToggleListaCoordinador() {
    this.isAllSelectedListaCoordinador() ?
      this.selectionListaCoordinador.clear() :
      this.dataSourceListaCoordinador.data.forEach(row => this.selectionListaCoordinador.select(row));
  }

  /**
 * Metodo Selecion data table
 * @returns 
 */
  isAllSelected() {
    this.numCoordinadorSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numCoordinadorSeleccionado === numRows;
  }

  /**
   * Metodo Selecion data table
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.grupoId + 1}`;
  }

  /**
   * Metodo Selecion data table
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
