import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Correo } from 'src/app/_models/correo';
import { ProfesorService } from '../../_services/profesor.service'
import { SnackService } from 'src/app/services/snack-service.service';

@Component({
  selector: 'app-seguimiento-correo',
  templateUrl: './seguimiento-correo.component.html',
  styleUrls: ['./seguimiento-correo.component.scss']
})
export class SeguimientoCorreoComponent implements OnInit {

  public correoCartera: FormGroup;
  public boolisArray: boolean;
  public correoAlumnos: string;
  public lengthAlumnos: number;

  constructor(public dialogRef: MatDialogRef<SeguimientoCorreoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private profesorservice: ProfesorService,
    private snackService: SnackService,
    ) { }

  /**
   * Inicializa el formulario donde escribir el asunto y el mensaje
   * ademas de conocer si es a un solo usuario o varios
   */
  ngOnInit(): void {
    this.inicializarForm();
    this.validarDataRecibida();
  }

  /**
   * Inicia el formulario de la pantalla
   */
  public inicializarForm(): void {
    this.correoCartera = new FormGroup({ 
      Asunto: new FormControl(''),
      Mensaje: new FormControl(''),
    });
  }

  /**
   * Funcion que primero detecta si la informacion es un arreglo (varios datos) o un solo dato
   * y asi agregar un nuevo objeto con el asunto y mensaje para su posterior envio 
   */
  public enviarCorreo(): void{
    if(this.boolisArray){
      let correosArray = this.data.map(p => (p.correo.toString()));
      this.correoAlumnos = correosArray.toString();
    }else{
      this.correoAlumnos = this.data.correo;
    }
    const estructuraCorreo = new Correo();

    estructuraCorreo.CorreosDestinatario = this.correoAlumnos;
    estructuraCorreo.Asunto = this.correoCartera.get('Asunto').value;
    estructuraCorreo.CuerpoDelMensaje = this.correoCartera.get('Mensaje').value;
    this.profesorservice.enviarCorreos(estructuraCorreo).subscribe((correoEnviado: boolean)=>{
      if(correoEnviado){
        this.snackService.mostrarSnackBack('Se ha enviado el correo con exito.');
      } else {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al enviar el correo. Por favor, intente de nuevo más tarde.');
      }
    }
    )
  }

  /**
   * Valida si la informacion es un arreglo (varios datos) y obtiene una longitud del arreglo obtenido
   */
  public validarDataRecibida(): void{
    this.boolisArray = Array.isArray(this.data);
    if(this.boolisArray){
      this.lengthAlumnos = this.data.length;
    }
  }

  /**
   * Cierra el propio componente
   */
  public cerrarModal(): void{
    this.dialogRef.close(this.data);
  }

}
