import { SimpleResponse } from './../../../../models/api/SimpleResponse.model';
import { baseUrl, environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponseInstance } from '../../../../models/api/ApiRespose.model';
import { Mapping } from '../../../core.domain/class/mapping.class';

@Injectable({
  providedIn: 'root'
})
export class MakeUpExamReasonService {

  constructor(private _http: HttpClient) { }
  
  public getMakeUpExamReason<T extends Mapping>(_class: T): Observable<ApiResponseInstance<T[]>> {    
    return this._http.get<ApiResponseInstance<T[]>>(`${environment.enves[baseUrl].apiUrl}/MakeUpExam/getMakeUpExamReason`).
      pipe(
        map((response: ApiResponseInstance<T[]>) => {          
            return new ApiResponseInstance( 
              response.data.map(res => _class.mapBackEnd(res)),
              response.success,
              response.message,
              )
            }
          )
        );
    }

    
  public postAlumnoGrupoMakeupExam<T>(entity: T): Observable<SimpleResponse> {
    return this._http.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/MakeUpExam/postAlumnoGrupoMakeupExam`, entity);
  }

  public deleteAlumnoGrupoMakeupExam<T>(entity: T): Observable<SimpleResponse> {
    return this._http.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/MakeUpExam/deleteAlumnoGrupoMakeupExam`, entity);
  }

  public postMassiveAlumnoGrupoMakeupExam<T>(entity: T): Observable<SimpleResponse> {
    return this._http.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/MakeUpExam/postMassiveAlumnoGrupoMakeupExam`, entity);
  }
}
