import { StudentStatusChangePost, StudentStatusChangeDTO } from './../_models/StudentStatusChange';
import { SimpleResponse } from './../models/api/SimpleResponse.model';
import { ResumenEstadoCuentaDto } from 'src/app/_models/resumenEstadoCuentaDto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { EstatusAcademico } from '../_models/inscripcion';

@Injectable({
  providedIn: 'root'
})
export class StatusChangeService {

  constructor(private httpClient: HttpClient) { }



  /**
   * Obtiene todos los tipos de estatus academicos en el catalogo
   * @returns lista con los estatus academicos ApiResponse<List<EstatusAcademico>>
   */
   public GetEstatusAcademicos(): Observable<ApiResponse<EstatusAcademico[]>> {
    return this.httpClient.get<ApiResponse<EstatusAcademico[]>>(`${environment.enves[baseUrl].apiUrl}/Inscripcion/GetEstatusAcademicos`);
  }

   /**
   * Obtiene el total del saldo por usuarioId
   * @returns lista con los estatus academicos ApiResponse<List<ResumenEstadoCuentaDto>>
   */
    public GetResumenEstadoCuentaByUsuarioID(usuarioId: number): Observable<ApiResponse<ResumenEstadoCuentaDto>> {
      return this.httpClient.get<ApiResponse<ResumenEstadoCuentaDto>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/GetResumenEstadoCuentaByUsuarioID?usuarioId=${usuarioId}`);
    }


    /**
   * Obtiene el total del materias inscritas sin calificacion final
   * @returns lista con los estatus academicos ApiResponse<int>
   */
     public GetMateriasInscritasSinCalFinalByAlumnoID(alumnoId: number): Observable<ApiResponse<number>> {
      return this.httpClient.get<ApiResponse<number>>(`${environment.enves[baseUrl].apiUrl}/StudentChange/GetMateriasInscritasSinCalFinalByAlumnoID/${alumnoId}`);
    }




   //#region Cambio de estatus
   /**
   * Agrega un registro en tabla  StudentStatusChange
   * @param alumnoId
   * @returns
   */
    public CambioDeEstatus(StudentStatusChangePost: StudentStatusChangePost): Observable<SimpleResponse> {
      return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/StudentChange/CambioDeEstatus`, StudentStatusChangePost);
    }



       /**
   * Agrega un registro en tabla  StudentStatusChange
   * @param alumnoId
   * @returns
   */
    public GetStudentStatusChangEByAlumnoId(AlumnoId: number): Observable<ApiResponse<StudentStatusChangeDTO[]>> {
      return this.httpClient.get<ApiResponse<StudentStatusChangeDTO[]>>(`${environment.enves[baseUrl].apiUrl}/StudentChange/GetStudentStatusChangEByAlumnoId/${AlumnoId}`);
    }
   //#endregion


}
