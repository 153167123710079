export class AdmisionesDTO{

  alumnoID: number;
  nombre: string;
  nombreCorto: string;
  programaID: number;
  periodoNombre: string;
  periodoID: number;
  campusNombre: string;
  campusID: number;
  campusClave: string;
}
