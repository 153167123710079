import { SimpleResponse } from './../models/api/SimpleResponse.model';
import { CouponDto } from './../_models/CouponDto';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, baseUrl } from '../../environments/environment';
import { Observable, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CuponService {

    /** Subcripciones Eventemmiter */
    invocarObtenerCupones = new EventEmitter();
    invocarObtenerCuponesSubscription: Subscription;

    constructor(private httpClient: HttpClient) { }

    /**
     * Metodo para validar un cupon
     * @param cupon 
     * @returns 
     */
    public validarCuponSolicitado(institucionId: string, cupon: string): Observable<ApiResponse<any>> {
        return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Cupon/ValidarCuponSolicitado/${institucionId}/${cupon}`);
    }

    /**
     * Metodo para obtener los cupones para mostrar al admin
     * @returns 
     */
    public cuponSeguimiento(): Observable<ApiResponse<CouponDto[]>> {
        return this.httpClient.get<ApiResponse<CouponDto[]>>(`${environment.enves[baseUrl].apiUrl}/Cupon/CuponSeguimiento`);
    }

    /**
     * Metodo para obtener los cupones para mostrar al admin
     * @returns 
     */
    public tipoCupon(): Observable<ApiResponse<any[]>> {
        return this.httpClient.get<ApiResponse<any[]>>(`${environment.enves[baseUrl].apiUrl}/Cupon/TipoCupon`);
    }

    /**
     * Metodo para obtener los cupones para mostrar al admin
     * @returns 
     */
    public cuponEditarCrear(cupon: CouponDto): Observable<SimpleResponse> {
        return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Cupon/CuponEditarCrear`, cupon);
    }

    /**
     * 
     * @param cuponesId 
     * @returns 
     */
    public eliminarCuponesId(cuponesId: string): Observable<SimpleResponse> {
        return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Cupon/EliminarCuponesId?cuponesId=` + cuponesId);
    }

    /**
     * Metodo para descargar el excel con la informacion de la tabla seguimiento
     * @param cupon 
     * @returns 
     */
    public downloadExcelCupon(cupon: any) {
        return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Cupon/DownloadExcel`, JSON.stringify(cupon),
            {
                responseType: "blob",
                headers: {
                    'Content-Type': 'application/json',
                }
            });
    }

    /**
     * Metodo para inocar el metodo paara traer cupones
     */
    invocarMetodoObtenerCupones() {
        this.invocarObtenerCupones.emit();
    }
}
