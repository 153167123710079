import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AccionEnum } from '../../_models/accion.enum';
import { Departamento } from '../../_models/departamento';
import { DepartamentoService } from '../../_services/departamento.service';
@Component({
  selector: 'app-modal-departamento',
  templateUrl: './modal-departamento.component.html',
  styleUrls: ['./modal-departamento.component.scss']
})
export class ModalDepartamentoComponent implements OnInit {

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public errorActualizaDepartamento: string = "";
  public departamentoID: number = 0;
  public dialogoRefAdver: any;
  public boolCambio = false;
  public edicionForm: FormGroup;
  public departamento: Departamento;

  constructor(public dialogRef: MatDialogRef<ModalDepartamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Departamento,
    private departamentoService: DepartamentoService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { 
    this.boolCambio = false;
    this.inicializarForm();
  }

  ngOnInit(): void {
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      departamentoId: new FormControl(this.data?.departamentoId),
      clave: new FormControl(this.data?.clave, [Validators.required]),
      nombre: new FormControl(this.data?.nombre, [Validators.required])
    });
    this.edicionForm.valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
  }

  get clave() { return this.edicionForm.get('clave'); }
  get nombre() { return this.edicionForm.get('nombre'); }

  public cambiarVistaEditar(servicioId: number) {
    this.boolCambio = false;
    this.data = Object.assign(this.edicionForm.value);
    this.data.tipoAccion = 2;
  }

  public enviar(): void {
    switch(this.data.tipoAccion) {
      case AccionEnum.CREAR:
        this.data = Object.assign(this.edicionForm.value);
        this.departamentoService.agregarDepartamentos(this.data).subscribe(
          idServicio => {
            if (idServicio > 0) {
              this.departamentoService.invocarMetodoObtenerDepartamento();
              this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
              this.boolCambio = false;
              this.cambiarVistaEditar(idServicio);
              this.inicializarForm();
            } else {
              this.snackBar.open("Ha ocurrido un error al guardar el departamento. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
              this.data.tipoAccion = 3;
            }
          }, error => {
            this.snackBar.open("Ha ocurrido un error al guardar el departamento. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        );
        break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionForm.value);
        this.data.tipoAccion = 2;
        this.departamentoService.modificarDepartamento(this.data).subscribe(
          editado => {
            if (editado) {
              this.departamentoService.invocarMetodoObtenerDepartamento();
              this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
              this.boolCambio = false;
            } else {
              this.snackBar.open("Ha ocurrido un error al modificar el departamento. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
            }
          }, error => {
            this.snackBar.open("Ha ocurrido un error al guardar el departamento. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        );
      break;
    }
  }

  public cerrarModalValidacion() {
    if(this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }

}
