import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';
import { SimpleResponse } from '../../models/api/SimpleResponse.model';
import { Imagen } from '../../_models/usuario';
import { AdmisionesService } from '../../_services/admisiones.service';
import { SnackService } from '../../services/snack-service.service';
import { TraductorService } from '../../_services/traductor.service';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, AfterViewInit {

  @Input() alumnoId: number = 0;
  @ViewChild('sPad', { static: true }) signaturePadElement;
  signaturePad: any;
  public firmaBase64: string = "";
  public imagePath: any;
  public esconderFrimaPad: boolean = false;
  public esconderFrimaImg: boolean = false;

  constructor(private admisionesService: AdmisionesService,
    private snackService: SnackService,
    private traductorService: TraductorService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
    this.studentSignatureCheck(this.alumnoId)
  }

  clear() {
    this.signaturePad.clear();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
    }
  }

  save() {
    if (this.signaturePad.isEmpty()) {
      let mensaje: string = this.traductorService.translate('faltaFirma');
      this.snackService.mostrarSnackBack(mensaje);
    } else {
      const dataURL = this.signaturePad.toDataURL();
      let imagen: Imagen = {
        usuarioId: this.alumnoId,
        fotografia: dataURL,
        fotografiaFile: `signature-${this.alumnoId}.png`,
      }
      this.admisionesService.studentSignature(imagen).subscribe(
        (respuesta: SimpleResponse) => {
          if (respuesta.success) {
            this.esconderFrimaPad = true;
            this.esconderFrimaImg = false;
            this.firmaBase64 = imagen.fotografia;
            this.admisionesService.setExisteFirma(true);
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.firmaBase64);
            let mensaje: string = this.traductorService.translate('guardadoFirma');
            this.snackService.mostrarSnackBack(mensaje);
          } else {
            this.snackService.mostrarSnackBack(respuesta.message);
          }
        }
      )
    }
  }

  /**
   * Metodo para revisar si existe una firma relacionada con el alumno
   */
  studentSignatureCheck(alumnoId: number): void {
    this.admisionesService.studentSignatureCheck(alumnoId).subscribe((response: ApiResponse<any>) => {
      if (response.success) {
        if (response.data !== null) {
          this.esconderFrimaPad = true;
          this.esconderFrimaImg = false;
          this.admisionesService.setExisteFirma(true);
          this.firmaBase64 = 'data:image/png;base64,' + response.data;
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.firmaBase64);
        } else {
          this.admisionesService.setExisteFirma(false);
          this.esconderFrimaPad = false;
          this.esconderFrimaImg = true;
        }
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    })
  }

}
