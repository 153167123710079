import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import { IPeriodo } from '../admisiones/Periodo';
import { IPrograma } from '../admisiones/Programa';
import { ICampus } from '../admisiones/Campus';
import { AcademicoResponse } from '../admisiones/AcademicoResponse';
import { MyResponse } from '../admisiones/MyResponse';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
}
@Injectable({
  providedIn: 'root'
})
export class SolUsuariosService {
  
  private apiURL = this.baseUrl + "api/Admision";
  private ApiUrl = this.baseUrl + "api/AdmisionInformacion";
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.enves[baseUrl].apiUrl;
  }

  //Consulta...
  /*getUsuarios(): Observable<IUsuario[]> {
    return this.http.get<IUsuario[]>(this.apiURL);
  }*/

  public DatosPersonales(
    Nombre: string,
    ApellidoPaterno: string,
    ApellidoMaterno: string,
    Genero: number,
    Correo: string,
    Telefono: number,
    FechaNacimiento: Date,
    Calle: string,
    NumeroExterior: number,
    NumeroInterior: number,
    Colonia: string,
    CodigoPostal: string,
    Municipio: string,
    Estado: number): Observable<MyResponse> {
    return this.http.post<MyResponse>(this.baseUrl + '/Admision/guardar/DatosGenerales',
      {
        'Nombre': Nombre, 'ApellidoPaterno': ApellidoPaterno, 'ApellidoMaterno': ApellidoMaterno, 'Genero': Genero, 'Correo': Correo, 'Telefono': Telefono,
        'FechaNacimiento': FechaNacimiento, 'Calle': Calle, 'NumeroExterior': NumeroExterior, 'NumeroInterior': NumeroInterior,
        'Colonia': Colonia, 'CodigoPostal': CodigoPostal, 'Municipio': Municipio, 'Estado': Estado
      }, httpOptions);
      
  }
  public Inf(Periodo, Programa, Campus, UsuarioId: number): Observable<AcademicoResponse> {
    return this.http.post<AcademicoResponse>(this.baseUrl + '/Admision/guardar/InformacionAcademica',
      {
        'Periodo': Periodo, 'Programa': Programa, 'Campus': Campus, 'UsuarioId': UsuarioId
      }, httpOptions);

  }
  //Consulta de periodo
  getPeriodo(): Observable<IPeriodo[]> {
    return this.http.get<IPeriodo[]>(this.baseUrl + '/Admision/obtener/periodo');
  }
  //Consulta de Programa
  getPrograma(): Observable<IPrograma[]> {
    return this.http.get<IPrograma[]>(this.baseUrl + '/Admision/obtener/programa');
  }
  //Consulta de campus
  getCampus(): Observable<ICampus[]> {
    return this.http.get<ICampus[]>(this.baseUrl + '/Admision/obtener/campus');
  }
  //Post de Archivo prueba...
  postFile(archivo: File) {
    const formData = new FormData();
    formData.append('files', archivo);

    this.http.post(this.baseUrl + '/Admision/guardar/archivos', formData)
      .subscribe(resp => {
        console.log(resp);
      });
  }
}
