import { ClassToTableCell } from './../models/data-table/class-to-table-cell.models';

// any object being used in the generic table must extend GenericTableRow to make the table easier to fill
export abstract class GenericTableRow {

    formattedTableRow: any;
  
    constructor(genericObject: any, classToTableCell?: ClassToTableCell[]) {
  
      this.objectSetup(genericObject);
  
      // this method formats the object to fill in a generic table row
      this.createFormattedTableRow(genericObject);
      
      if (classToTableCell !== undefined) {
        classToTableCell.forEach(cell => {
          this.asignClassToTableCell(cell.property, cell.cssClass);
        });
      }            
    }
  
    // formats the object to be used in the generic table
    createFormattedTableRow(genericObject: any): void {
      this.formattedTableRow = {};
  
      Object.entries(this).forEach(item => {
        if (item[0] !== 'formattedTableRow') {
          /* A property of the class that is being used to store the object that will be used to
          fill the table. */          
          this.formattedTableRow[item[0]] = {value: item[1],  customClass: '', isChecked: false, rawData: genericObject };          
        }
      });      
    }
  
    public abstract objectSetup(genericObject: any): void;

    
  
    // assigns a css class to be used on the table, the parent of the table must declare that class on its css file  
    public  asignClassToTableCell(property: string, cssClass: string): void {
      this.formattedTableRow[property].customClass = cssClass;
    }
  }
