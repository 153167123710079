import { BaseResponse } from "./BaseResponse.mode";

export class ApiResponse<T> extends BaseResponse{
    data: T;
}

export class ApiResponseInstance<T> extends BaseResponse{
    data: T;

    constructor(data:T,  success: boolean, message: string) {
        super()
        this.data = data;
        this.success = success;
        this.message = message;
      }
}