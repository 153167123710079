export class TipoApoyoFinanciero {
  /**
   * Identificador del tipo de apoyo financiero
   */
  tipoApoyoFinancieroId: number;

  /**
   * Identificador del campus donde se encuentra el apoyo financiero
   */
  campusId: number;

  /**
   * Clave del tipo de apoyo financiero
   */
  clave: string;

  /**
   * Descripcion del tipo de apoyo financiero
   */
  descripcion: string;

  /**
   * Porcentaje que tendra el apoyo financiero
   */
  porcentaje: number;

  /**
   * Define si el apoyo financiero esta activo o inactivo
   */
  activo: boolean;

  /**
   * Define si el apoyo financiero tiene convenio con una empresa 
   */
  convenio: boolean;

  /**
   * Es el promedio minimo para el apoyo financiero
   */
  promedioMinimo: number;

  /**
   * Numero de materias reprobadas minimo
   */
  numMateriasReprobadas: number;

  /**
   * Es el valor que nos define cuando se auto-cancela el apoyo financiero
   * 1: El dia del fin del periodo
   * 2: N dias antes del fin del periodo
   * 3: N dias despues del fin del periodo
   */
  finPeriodoTipo: number

  /**
   * Los dias antes del fin del periodo que se cancelara el tipo de apoyo 
   */
  diasAntesFinPeriodo: number;
  /**
   * Los dias despues del fin del periodo que se cancelara el tipo de apoyo 
   */
  diasDespuesFinPeriodo: number;





  /**
   * Fecha de creacion del registro
   */
  fechaCreacion?: Date;

  /**
   * Usuario que creo el registro
   */
  usuarioCreacion?: number;

  /**
   * Fecha de modificacion del registro
   */
  fechaModificacion?: Date;

  /**
   * Usuario que modifica el registro
   */
  usuarioModificacion?: number;
}
