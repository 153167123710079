export class Service {
    Servicio_Id: number;
    Nombre: string;
    Monto:number;
    Parcialidades:number;
    Campus:string;
    Campus_Id:number;
    Nivel:string;
    Nivel_Id:number;
    Fecha_Inicio: any;
    Impuesto:number;
}
