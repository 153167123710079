import { TraductorService } from './../../_services/traductor.service';
import { SnackService } from './../../services/snack-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { UtileriasService } from '../../_services/utilerias.service'
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { CampusService } from '../../_services/campus.service';
import { NivelService } from '../../_services/nivel.service';
import { Campus } from '../../_models/campus';
import { Nivel } from '../../_models/nivel';
import { Service } from '../../_models/service';
import { ModalServiciosComponent } from './modal-servicios/modal-servicios.component';
import { ConfiguracionCuotaService } from '../../_services/configuracion-cuota.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-configuracion-servicios',
  templateUrl: './configuracion-servicios.component.html',
  styleUrls: ['./configuracion-servicios.component.scss']
})
export class ConfiguracionServiciosComponent implements OnInit {


  displayedColumns: string[] = ['select', 'nombre', 'campus', 'nivel', 'fechaInicio', 'costo', 'impuesto', 'parcialidades'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);
  @ViewChild('allNivel') private allNivel: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;

  public filtroForm: FormGroup;
  public numSeleccionado: number;
  public arrOriginal: any[] = [];
  public arrCampus: Campus[] = [];
  public arrNivel: Nivel[] = [];
  public infoCargado: boolean = false;


  constructor(
    public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private campusservice: CampusService,
    private nivelservice: NivelService,
    private servicios: ConfiguracionCuotaService,
    private snackBar: MatSnackBar,
    private snackService: SnackService,
private traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    this.limpiarFiltros();
    this.cargarServicios();
    if(this.servicios.obtenerServiciosSubscription == undefined){
      this.servicios.obtenerServiciosSubscription = this.servicios.invocarObtenerServicios.subscribe(()=>{
        this.servicios.getServicios().subscribe((servicios: any[])=>{
          this.arrOriginal = servicios;
          this.filtro();
        })
      })
    }
    this.llenarFiltros();
  }

  public cargarServicios(){
    this.servicios.getServicios().subscribe(data=>{
      this.arrOriginal=data;
      this.dataSource = new MatTableDataSource(data);
       this.dataSource.paginator = this.paginator;
       this.selection = new SelectionModel<any>(true, []);
    })
  }

  get nombreConfig() { return this.filtroForm.get('nombre'); }
  get campusConfig() { return this.filtroForm.get('campus'); }
  get nivelConfig() { return this.filtroForm.get('nivel'); }
  get fechaConfig() { return this.filtroForm.get('fecha'); }

  limpiarFiltros(){
    this.filtroForm = new FormGroup({
      nombre: new FormControl(''),
      campus: new FormControl([...this.arrCampus.map(item => item.campusId), 0]),
      nivel: new FormControl([...this.arrNivel.map(item => item.nivelId), 0]),
      fecha: new FormControl('')
    })
  }
  Eliminar(){
    let eliminado:any = this.selection.selected[0];
    let servicio: Service = new Service();
    servicio.Campus_Id = eliminado.campus_Id;
    servicio.Fecha_Inicio = eliminado.fecha_Inicio;
    servicio.Nivel_Id = eliminado.nivel_Id;
    servicio.Servicio_Id = eliminado.servicio_Id;
    this.servicios.eliminarServicio(servicio).subscribe((doto: boolean)=>{
      if (doto) {
        this.snackBar.open('Se ha eliminado el costo de servicio correctamente.', 'OK', { duration: 5000 });
      } else {
        this.snackBar.open('Ha ocurrido un error al eliminar el costo de servicio. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    })
  }
  llenarFiltros(){
    this.campusservice.obtenerCampus().subscribe((campus: Campus[]) => {
      this.arrCampus = campus;
      this.filtroForm.controls['campus'].patchValue([...this.arrCampus.map(item => item.campusId), 0]);
      this.filtro();
    });
    this.nivelservice.getNivel().subscribe((nivel: Nivel[]) => {
      this.arrNivel = nivel;
      this.filtroForm.controls['nivel'].patchValue([...this.arrNivel.map(item => item.nivelId), 0]);
      this.filtro();
    })
    this.servicios.getServicios().subscribe((servicios: any[])=>{
      this.arrOriginal = servicios;
      this.filtro();
    })
  }

  filtro() {
    let campus: any[] = this.filtroForm['value'].campus;
    let nivel: any[] = this.filtroForm['value'].nivel;
    let nombre: string = this.filtroForm['value'].nombre;
    let fecha: any = this.filtroForm['value'].fecha;
    let arrFiltrado: any[] = [...this.arrOriginal];
    if (nivel.length > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return nivel.indexOf(f.nivel_Id) > -1 });
    }
    if (campus.length > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return campus.indexOf(f.campus_Id) > -1 });
    }
    if(nombre.length > 0 || nombre !== ''){
      arrFiltrado = arrFiltrado.filter(f => { return f.nombre.toLowerCase().search(nombre.toLowerCase()) > -1  })
    }
    if(fecha.length > 0){
      arrFiltrado = arrFiltrado.filter(f => { return fecha.indexOf(this.util.formatearFecha(f.fecha_Inicio, 'YYYY[-]MM[-]DD')) > -1 })
    }
    this.dataSource = new MatTableDataSource(arrFiltrado);

  }

  downloadExcel(){
    this.servicios.downloadExcelServicioCosto().subscribe((servicio: any[]) => {
      let mensaje: string = this.traductorService.translate('descargaArchivo');
      this.snackService.mostrarSnackBack(mensaje);
    });
  }

  isAllSelected() {
    this.numSeleccionado = this.selection.selected.length;
    const numRows = this.arrOriginal.length;
    return this.numSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.UsuarioId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() :
      this.arrOriginal.forEach(row => this.selection.select(row));

  }
  openServicio(elemento: any = null){
    this.util.abrirDialogoLateral(ModalServiciosComponent, elemento);
  }
  tosslePerOneNivel(all) {
    if (this.allNivel.selected) {
      this.allNivel.deselect();
      return false;
    }
    if (this.filtroForm['value'].periodo.length == this.arrNivel.length) { this.allNivel.select(); }
    this.filtro();
  }
  tosslePerOneCampus(all) {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.filtroForm['value'].campus.length == this.arrCampus.length) { this.allCampus.select(); }
    this.filtro();
  }
  toggleAllSelectionNivel() {
    if (this.allNivel.selected) {
      this.filtroForm.controls['nivel'].patchValue([...this.arrNivel.map(item => item.nivelId), 0]);
    } else {
      this.filtroForm.controls['nivel'].patchValue([]);
    }
    this.filtro();
  }
  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.filtroForm.controls['campus'].patchValue([...this.arrCampus.map(item => item.campusId), 0]);
    } else {
      this.filtroForm.controls['campus'].patchValue([]);
    }
    this.filtro();
  }
}

