import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { TipoRelacion } from '@app/_models/tipo-relacion';

@Injectable({
  providedIn: 'root'
})
export class TipoRelacionService {

  constructor(private httpClient: HttpClient) { }

  //Get Catalogo Tipo Relacion
  public getTipoRelacion(): Observable<TipoRelacion[]> {
    return this.httpClient.get<TipoRelacion[]>(`${environment.enves[baseUrl].apiUrl}/TipoRelacion`);
  }
}
