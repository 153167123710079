import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { BlockService } from 'src/app/_services/block.service';

@Component({
  selector: 'app-edicion-bloque-grupo-eliminar',
  templateUrl: './edicion-bloque-grupo-eliminar.component.html',
  styleUrls: ['./edicion-bloque-grupo-eliminar.component.scss']
})
export class EdicionBloqueGrupoEliminarComponent implements OnInit {
  public boolIsArray = false;
  public numBlockGroupSeleccionados = 0;
  public grupoIds = '';

  constructor(public dialogRef: MatDialogRef<EdicionBloqueGrupoEliminarComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    public blockService: BlockService) { }

  ngOnInit(): void {
    this.validarDataRecibida();

  }


  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numBlockGroupSeleccionados = this.data.length;
    }

  }


  public eliminar() {
    if (this.boolIsArray) {
      let arrayGrupoId = this.data.map(d => (d.grupoId.toString()));
      this.grupoIds = arrayGrupoId.toString();
    } else {
      this.grupoIds = this.data.grupoId.toString();
    }

    this.blockService.eliminarBlockGroups(this.data.blockId, this.grupoIds).subscribe(
      (response: ApiResponse<any>) => {
        if (response.success) {
          this.dialogRef.close(this.data);
          this.snackService.mostrarSnackBack(response.message);
          this.blockService.invocarMetodoObtenerBloqueGrupo();
          this.blockService.invocarMetodoObtenerBloque();
        } else {
          this.snackService.mostrarSnackBack(response.message);
        }
      }, error => {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar el grupo del bloque. Por favor, intente de nuevo más tarde.');
      }
    );

  }





}
