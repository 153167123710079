import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service';
import { LeadEntryService } from 'src/app/_services';

@Component({
  selector: 'app-leads-source-modal-eliminar',
  templateUrl: './leads-source-modal-eliminar.component.html',
  styleUrls: ['./leads-source-modal-eliminar.component.scss']
})
export class LeadsSourceModalEliminarComponent implements OnInit {
  public numEntrySeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public idSource: string = '';

  constructor(public dialogRef: MatDialogRef<LeadsSourceModalEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private leadentryService: LeadEntryService
  ) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  /**
   * Valida la informacion recibida, si es un arreglo o no
   */
  public validarDataRecibida(): void {
    this.boolIsArray = Array.isArray(this.data);
    if(this.boolIsArray) {
      this.numEntrySeleccionados = this.data.length;
    }
  }

  /**
   * Funcion que detecta si los datos son un arreglo, cambia los Id's al tipo string, y los pasa al servicio
   * para ser eliminados, si fue exitoso se actualiza la tabla principal, si no manda un msj  de error.
   */
  public eliminar() {
    if(this.boolIsArray) {
      let arrayId = this.data.map(d => (d.sourceId.toString()));
      this.idSource = arrayId.toString();
    } else {
      this.idSource = this.data.sourceId.toString();
    }
    this.leadentryService.deleteEntry(this.idSource).subscribe(
      (eliminado: boolean) => {
        if (eliminado) {
          this.leadentryService.invocarMetodoObtenerEntry();
          this.snackService.mostrarSnackBack('Se ha eliminado la información.');
          this.dialogRef.close(this.data);
        } else {
          this.snackService.mostrarSnackBack('Alguna informacion esta siendo utilizada por otro módulo.');
        }
      }
    );
  }
}
