import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { LeadImportService } from 'src/app/_services/lead-import.service';

@Component({
  selector: 'app-leads-import-modal-columns',
  templateUrl: './leads-import-modal-columns.component.html',
  styleUrls: ['./leads-import-modal-columns.component.scss']
})
export class LeadsImportModalColumnsComponent implements OnInit {
  columnsOriginal: string[] =  ['Title','Middle Name','Second Last Name','Birth Date','Phone number','Street','Number', 'Internal Number','Neighborhood','State','Country','Zip Code','Source',
    'Parent 1 Name','Parent 1 Middle Name','Parent 1 Last Name','Parent 1 Second Last Name','Parent 1 Email','Parent 1 Phone number',
    'Parent 2 Name','Parent 2 Middle Name','Parent 2 Last Name','Parent 2 Second Last Name','Parent 2 Email','Parent 2 Phone number',
    'Parent 3 Name','Parent 3 Middle Name','Parent 3 Last Name','Parent 3 Second Last Name','Parent 3 Email','Parent 3 Phone number',
    'Term','Campus','Level','Program'];

  /** Este arreglo tiene esos 3 por defecto ya que son necesarios */
  columnasAcomodadas: string[] = ['Name', 'Last Name', 'Email'];

  constructor(private leadImportService: LeadImportService) { }

  ngOnInit(): void {
  }

  /**
   * Funcion para mover elementos de un drag&drop a otro
   * @param event 
   */
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  /**
   * Funcion que pasa las columnas acomodadas por el usuario a otro componente
   */
  public siguiente() {
    this.leadImportService.definirColumnas(this.columnasAcomodadas);
  }
}
