import { TraductorService } from '@app/_services/traductor.service';
import { ProgramaAcademicoF } from 'src/app/_models/usuario';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import { Periodo } from '../_models/periodo';
import { Programa } from '../_models/programa';
import { EstudiosPrevios } from '../_models/estudios-previos';
import { Correo } from '../_models/correo';
import { Grupo } from '../_models/grupo';
import { GruposDisponiblesParaMateriaDTO } from '../models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { GrupoDTO } from '../_models/GrupoDTO.model';
import { ExisteMateriaSeriada } from '../models/customEntities/existeMateriaSeriada.mode';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { ColegiaturaCosto } from '../_models/colegiatura-costo';
import { InscripcionCosto } from '../_models/inscripcion-costo';
import { UsuarioIngreso, UsuarioNuevoIngresoDto } from '../_models/usuarionuevoingresodto';
import { DatoFiscal } from '../_models/dato-fiscal';
import { Ciclo } from '../_models/ciclo';
import { MateriaOferta } from '../_models/materia-oferta';
import { CargaInscripcion } from '../_models/carga-inscripcion';
import { UsuarioNuevoIngreso } from '../_models/nuevo-ingreso';
import { Grupos } from '../_models/ensenanza-aprendizaje-profesor';
import { Boleta } from '../_models/boleta.model';
import { BajaDTO } from '../_models/BajaDTO.model';
import { FiltroAdminAcademicoAlumnoDto } from '@app/_models/FiltroAdminAcademicoAlumnoDto';

@Injectable({
  providedIn: 'root'
})
export class NuevoIngresoService {

  invocarObtenerCandidatos = new EventEmitter();
  buscarUsuario = new EventEmitter();
  public buscarUsuarioSubscription: Subscription;
  updateTabsEmitter = new EventEmitter();
  programaEmitter = new EventEmitter();
  private datosUsuarioBusqueda$ = new BehaviorSubject<any>(null);
  private documentos$ = new Subject<boolean>();
  private grupos$ = new Subject<any[]>();
  private cyclyeId$ = new Subject<any>();
  private studentId$ = new Subject<any>();
  private idPeriodo$ = new Subject<any>();
  private materiasCount$ = new Subject<number>();
  private usuarioMenorEdad$ = new Subject<any>();
  private formValidoNuevaSolicitud$ = new Subject<boolean>();
  private formCambio$ = new Subject<boolean>();
  private formCambioNombre$ = new Subject<string>();
  private changesAcademicProgram$ = new Subject<boolean>();
  private changesPersonalData$ = new Subject<boolean>();
  private changesDocuments$ = new Subject<boolean>();
  private changesFinancialSupport$ = new Subject<boolean>();
  private changesSubjects$ = new Subject<boolean>();
  private changesPayment$ = new Subject<boolean>();
  private formValidAcademicProgram$ = new Subject<boolean>();
  private seriacion$ = new Subject<ExisteMateriaSeriada>();
  invMetodoObtenerCandidatosSubscription: Subscription;
  private cambioEnSeleccionDeMaterias$ = new Subject<boolean>();
  updateTabsSubscription: Subscription;
  programaSubscription: Subscription;
  constructor(private httpClient: HttpClient,
    private traductorService: TraductorService) { }

  //Download Excel
  public descargarExcel(Filtros: FiltroAdminAcademicoAlumnoDto) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Candidato/DownloadExcel`, JSON.stringify(Filtros),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  /**
   * Obtiene los usuarios de nuevo ingreso, excluye usuarios que ya tienen materias aplicadas y
   * usuarios que su periodo de ingreso sobrepase la fecha de inicio solo si tiene materias
   * @returns
   */
  public obtenerNuevosIngresos(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Candidato/GetCandidatos/${pagina}/${registrosPagina}`, filtro);
  }

  //Get nuevo ingreso by Id
  public obtenerNuevosIngresoByUsuarioId(AlumnoId: number, UsuarioId: number): Observable<ApiResponse<UsuarioNuevoIngreso>> {
    return this.httpClient.get<ApiResponse<UsuarioNuevoIngreso>>(`${environment.enves[baseUrl].apiUrl}/Candidato/GetUsuarioByUsuarioId/${AlumnoId}/${UsuarioId}`);
  }

  //Get Catalogo Periodo
  public getPeriodo(): Observable<Periodo[]> {
    return this.httpClient.get<Periodo[]>(`${environment.enves[baseUrl].apiUrl}/Periodo`);
  }
  //Get Catalogo Programa
  public getPrograma(): Observable<Programa[]> {
    return this.httpClient.get<Programa[]>(`${environment.enves[baseUrl].apiUrl}/Programa`);
  }
  //Get Count de seleccion de materias
  public getMateriasCount(): Observable<number> {
    return this.materiasCount$.asObservable();
  }
  //Set Count de seleccion de materias
  public definirMateriasCount(count: number) {
    this.materiasCount$.next(count);
  }

  //Get Catalogo Programa
  public obtenerProgramaById(programaId: number): Observable<ApiResponse<Programa>> {
    return this.httpClient.get<ApiResponse<Programa>>(`${environment.enves[baseUrl].apiUrl}/Programa/ProgramaById/${programaId}`);
  }

  //Get Candidato por Correo
  public obtenerCandidatoCorreo(correoBusqueda: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/candidato/GetCandidatoBycorreo?correo=${correoBusqueda}`);
  }

  //Obtiene un booleano para ver si el correo esta dispinible o no
  public validarCorreo(correoBusqueda: string): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/candidato/ValidarCorreo/${correoBusqueda}`);
  }

  //Post Nuevo Candidato
  public registrarNuevoCandidato(datosUsuario): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/RegistrarUsuario`, datosUsuario);
  };

  // Set Datos Usuario Buqueda Observable
  public definirDatosUsuarioBusquedaCorreo(datosUsuario: any) {
    this.datosUsuarioBusqueda$.next(datosUsuario);
  }

  //Get Datos Usuario Busqqueda Observable
  public obtenerDatosUsuarioBusquedaCorreo$(): Observable<any> {
    return this.datosUsuarioBusqueda$.asObservable();
  }

  // Set Documentos Observable
  public definirValidarDocumentos(documentos: any) {
    this.documentos$.next(documentos);
  }

  //Get Documentos Observable
  public obtenerValidarDocumentos$(): Observable<any> {
    return this.documentos$.asObservable();
  }

  // Set Boolean Formulario Valido Observable
  public definirFormularioValidoNuevaSolicitud(boolForm: boolean) {
    this.formValidoNuevaSolicitud$.next(boolForm);
  }

  //Get Boolean Formulario Valido Observable
  public obtenerFormularioValidoNuevaSolicitud$(): Observable<any> {
    return this.formValidoNuevaSolicitud$.asObservable();
  }
  // Set Boolean Formulario Valido Observable
  public definirFormularioValidoInscripcion(boolForm: boolean) {
    this.formValidoNuevaSolicitud$.next(boolForm);
  }
  tipoCarga: string;
  public definirtipoCarga(carga) {
    this.tipoCarga = carga;
  }

  //Get Boolean Formulario Valido Observable
  public obtenerFormularioValidoInscripcion$(): Observable<any> {
    return this.formValidoNuevaSolicitud$.asObservable();
  }
  // Set Cambios en Formularios
  public definirCambioForms(boolForm: boolean) {
    this.formCambio$.next(boolForm);
  }

  //Get Boolean Formulario Valido Observable
  public obtenerCambioForms$(): Observable<any> {
    return this.formCambio$.asObservable();
  }

  // Set Cambios en nombre Formulario
  public definirCambioNombreForms(nombre: string) {
    this.formCambioNombre$.next(nombre);
  }

  //Get Boolean Formulario Valido Observable
  public obtenerCambioNOmbreForms$(): Observable<string> {
    return this.formCambioNombre$.asObservable();
  }

  // Set Datos Usuario Buqueda Observable
  public definirDatosUsuarioBusqueda(datosUsuario: any) {
    this.datosUsuarioBusqueda$.next(datosUsuario);
  }

  //Get Datos Usuario Busqqueda Observable
  public obtenerDatosUsuarioBusqueda$(): Observable<any> {
    return this.datosUsuarioBusqueda$.asObservable();
  }
  // Set Datos grupos
  public definirgrupos(grupos: any) {
    this.grupos$.next(grupos);
  }
  //Get Datos grupos Observable
  public obtenerGruposBusqueda$(): Observable<any> {
    return this.grupos$.asObservable();
  }
  //Editar programa a usuario con cuenta
  public editarProgramaUsuario(programa: ProgramaAcademicoF): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/EditarProgramaUsuario`, programa);
  };

  //Metodo para saber si el identificador es uno personalizado o uno por secuencia
  public obteneridentificadorPersonalizado(): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/candidato/IdentificadorPersonalizado`);
  };

  //Metodo para comporbar la disponibilidad de la matricula
  public disponibilidadMatricula(matricula: string): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/candidato/DisponibilidadMatricula/${matricula}`);
  }

  //Put Datos Personales
  public editarDatosPersonales(datosPersonales): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PutDatosCompletos`, datosPersonales);
  };

  //Put Datos Personales
  public editarImagen(imagenUsuario): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PutImagenUsuario`, imagenUsuario);
  };

  //Post/Put DatosContacto
  public registrarDatosContacto(datosContacto): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PostDatosContacto`, datosContacto);
  };

  //Put Biografia
  public modificarBiografia(biografia): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PutBiografia`, biografia);
  }

  //Post/Put Datos Familia
  public registrarDatosFamilia(datosFamilia): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PostDatosFamilia`, datosFamilia);
  };

  //Post/Put Datos Familia
  public registrarDatosEmergencia(datosEmergencia): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PostDatosEmergencia`, datosEmergencia);
  };
  //Post/put Estudios Previos
  public agregarEstudiosPrevios(estudiosPrevios: EstudiosPrevios): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PostEstudiosPrevios`, estudiosPrevios);
  }
  //Post/put Estudios Previos
  public agregarDatosFiscal(datosFiscales): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PostDatosFiscales`, datosFiscales);
  }

  //Post/Put Ocupacion
  public registrarOcupacion(ocupacion): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PostOcupacion`, ocupacion);
  };

  //Post Documentos
  public registrarDocumentos(documentos): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PostDocumentos`, documentos);
  };

  //Post/Put Apoyo Financiero
  public registrarApoyoFinanciero(datosApoyoFinanciero): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/PostApoyoFinanciero`, datosApoyoFinanciero);
  };

  //Descargar Documentos usuario
  public GetDocumentosCandidatoByCorreo(correo, id): Observable<ApiResponse<UsuarioIngreso>> {
    return this.httpClient.get<ApiResponse<UsuarioIngreso>>(`${environment.enves[baseUrl].apiUrl}/candidato/GetDocumentosCandidatoByCorreo/${correo}/${id}`);
  };
  //Post materias
  public registrarMaterias(alumnoId, grupos: Grupos[]): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/candidato/InscripcionRegistroGrupos?alumnoId=${alumnoId}`, grupos);
  };
  //Existe Seriacion
  public ExisteSeriacion(alumnoId, grupos: Grupos[]): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/ExisteSeriacion?alumnoId=${alumnoId}`, grupos);
  };

  //ExisteCostoColegiatura
  public ExisteColegiaturaCostoDisponible(campusId, nivelId, periodoId, programaId): Observable<ApiResponse<ColegiaturaCosto>> {
    return this.httpClient.get<ApiResponse<ColegiaturaCosto>>(`${environment.enves[baseUrl].apiUrl}/candidato/ExisteColegiaturaCostoDisponible/${campusId}/${nivelId}/${periodoId}/${programaId}`);
  };

  //ExisteCostoColegiatura
  public ExisteColegiaturaCostoDisponibleByAlumnos(alumnos: any): Observable<ApiResponse<ColegiaturaCosto[]>> {
    return this.httpClient.post<ApiResponse<ColegiaturaCosto[]>>(`${environment.enves[baseUrl].apiUrl}/candidato/ExisteColegiaturaCostoDisponibleByAlumnos`, alumnos);
  };

  //ExisteInscripcionCosto

  public ExisteInscripcionCosto(campusId, nivelId, periodoId, programaId, tipoInscripcion): Observable<ApiResponse<InscripcionCosto>> {
    return this.httpClient.get<ApiResponse<InscripcionCosto>>(`${environment.enves[baseUrl].apiUrl}/candidato/ExisteInscripcionCosto/${campusId}/${nivelId}/${periodoId}/${programaId}/${tipoInscripcion}`);
  }

  //ValidarInscripcionPagada
  public ValidarInscripcionPagada(alumnoId, periodoId): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.enves[baseUrl].apiUrl}/candidato/InscripcionPagada/${alumnoId}/${periodoId}`);
  };


  //CargoExcibicion
  public CargoExcibicion(colegiaturaCosto): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/CargoExcibicion`, colegiaturaCosto);
  };

  //CargoParcialidades
  public CargoParcialidades(colegiaturaCosto): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/CargoParcialidades`, colegiaturaCosto);
  };

  //Dar baja y alta de materia
  public AltaBajaMateriaAlumno(infoAltaBaja): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/AltaBajaMateriaAlumno`, infoAltaBaja);
  };

  //Descargar Documentos usuario
  public imagenUsuario(id): Observable<ApiResponse<UsuarioIngreso>> {
    return this.httpClient.get<ApiResponse<UsuarioIngreso>>(`${environment.enves[baseUrl].apiUrl}/candidato/GetImagenUsuarioById/${id}`);
  };

  //Registrar programa a usuario con cuenta
  public registrarProgramaUsuario(datsRegistro): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/RegistrarProgramaUsuario`, datsRegistro);
  };

  //Post Enviar correo
  public enviarCorreos(correos: Correo): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/EnviarCorreos`, correos);
  }

  //Delete CandidatoByUsuarioID
  public eliminarCandidatoByUsuarioID(candidatoId: string): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/DeleteCandidatoByUsuarioID?candidatoId=${candidatoId}`);
  }

  //Delete CandidatoByUsuarioID
  public DeleteMultipleCandidato(candidatoId: string): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/DeleteMultipleCandidato?candidatoId=${candidatoId}`);
  }


  //Invocar Metodo obtenerCandidato
  invocarMetodoObtenerCandidato() {
    this.invocarObtenerCandidatos.emit();
  }

  //Delete CandidatoByUsuarioID
  public reactivarCandidatoByUsuarioID(candidatoId: number): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/reactivarCandidatoByUsuarioID?candidatoId=${candidatoId}`);
  }

  //Get CorreoOcupado
  public obtenerCorreoOcupado(correo: string, usuarioId: number): Observable<SimpleResponse> {
    return this.httpClient.get<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/CorreoOcupado?correo=${correo}&usuarioId=${usuarioId}`);
  }

  //Obtener Datos Fiscal por RFC
  public GetDatoFiscalByRFC(rfc: string, usuarioId: number): Observable<ApiResponse<DatoFiscal>> {
    return this.httpClient.get<ApiResponse<DatoFiscal>>(`${environment.enves[baseUrl].apiUrl}/candidato/GetDatoFiscalByRFC?rfc=${rfc}&usuarioId=${usuarioId}`);
  }

  // Set Usuario Menor de edad
  public definirUsuarioMenorEdad(menorEdad: boolean) {
    this.usuarioMenorEdad$.next(menorEdad);
  }

  //Get Usuario Menor de edad
  public obtenerUsuarioMenorEdad$(): Observable<boolean> {
    return this.usuarioMenorEdad$.asObservable();
  }

  //Invocar Metodo obtenerPeriodos
  public refrescarTabla() {
    this.invocarObtenerCandidatos.emit();
  }

  //Invocar Metodo obtenerPeriodos
  public buscarUsuarioByCorreo() {
    this.buscarUsuario.emit();
  }

  public updateTabs() {
    this.updateTabsEmitter.emit();
  }

  //Delete CandidatoByUsuarioID
  public deleteDocumentoById(usuarioId: number, documentId: number): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/DeleteDocumentoById/${usuarioId}/${documentId}`);
  }

  //Get Subjects
  public GetCyclesByProgramId(programId): Observable<ApiResponse<Ciclo>> {
    return this.httpClient.get<ApiResponse<Ciclo>>(`${environment.enves[baseUrl].apiUrl}/candidato/GetCyclesByProgramId/${programId}`);
  }

  //Get Materias Alumno en un periodo
  public ObtenerMateriasAlumnoPeriodo(periodoId, alumnoId, programId): Observable<ApiResponse<Ciclo>> {
    return this.httpClient.get<ApiResponse<Ciclo>>(`${environment.enves[baseUrl].apiUrl}/candidato/ObtenerMateriasAlumnoPeriodo/${periodoId}/${alumnoId}/${programId}`);
  }

  // Set Datos Usuario Buqueda Observable
  public defineCandidatCycleId(cycleId: any) {
    this.cyclyeId$.next(cycleId);
  }

  //Get Datos Usuario Busqqueda Observable
  public getCandidatCycleId$(): Observable<any> {
    return this.cyclyeId$.asObservable();
  }

  //Get Subjects
  public GetGroupsBySubjectId(subjectsKeys): Observable<Grupo> {
    return this.httpClient.get<Grupo>(`${environment.enves[baseUrl].apiUrl}/candidato/GetGroupsBySubjectsKeys/${subjectsKeys}`);
  }

  public getGrupoPeriodo(periodoId, claves, alumnoId): Observable<Grupo> {
    return this.httpClient.get<Grupo>(`${environment.enves[baseUrl].apiUrl}/candidato/ObtenerGruposDisponiblesPeriodo/${periodoId}/${claves}/${alumnoId}`);
  }

  //POST groups
  public subscribeGroupToStudent(groupToSubscribe: Grupo): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/candidato/SubscribeGroupToStudent`, groupToSubscribe);
  }

  // Set defineStudentId
  public defineStudentId(studentIdVar) {
    this.studentId$.next(studentIdVar);
  }

  //Get getStudentId
  public getStudentId$(): Observable<any> {
    return this.studentId$.asObservable();
  }
  // Set defineStudentId
  public defineIdPeriodo(idPeriodo) {
    this.idPeriodo$.next(idPeriodo);
  }
  //Get getStudentId
  public getPeriodoId$(): Observable<any> {
    return this.idPeriodo$.asObservable();
  }
  //Get invocarCandidato
  public invocarMetodoPrograma() {
    return this.programaEmitter.emit();
  }
  //Get Subjects
  public getOffertSubject(key): Observable<ApiResponse<MateriaOferta>> {
    return this.httpClient.get<ApiResponse<MateriaOferta>>(`${environment.enves[baseUrl].apiUrl}/candidato/GetOffertSubject/${key}`);
  }

  //POST groups
  public deleteSubjectByGroup(groupToDelete: Grupo): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/DeleteSubjectByGroup`, groupToDelete);
  }

  //GET subjectFromStudent
  public checkSubjectInStudentService(studentId, periodoId, offertSubjectKeys): Observable<SimpleResponse> {
    return this.httpClient.get<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/CheckSubjectInStudent/${studentId}/${periodoId}/${offertSubjectKeys}`);
  }

  public getCargas(campusId, nivelId, programaId): Observable<ApiResponse<CargaInscripcion>> {
    return this.httpClient.get<ApiResponse<CargaInscripcion>>(`${environment.enves[baseUrl].apiUrl}/candidato/GetCargaInscripcion/${campusId}/${nivelId}/${programaId}`);
  }

  //Get Datos By Id Usuario
  public candidatoById(alumnoId: number, usuarioId: number): Observable<ApiResponse<UsuarioIngreso>> {
    return this.httpClient.get<ApiResponse<UsuarioIngreso>>(`${environment.enves[baseUrl].apiUrl}/candidato/GetCandidatoById?alumnoId=${alumnoId}&usuarioid=${usuarioId}`);
  }

  //------OBSERVABLES MODAL BUTTON ---------------------//
  // Set Changes in academic program
  public setChangesAcademicProgram(change: boolean) {
    this.changesAcademicProgram$.next(change);
  }

  //Get changes academic program
  public getChangesAcademicProgram$(): Observable<boolean> {
    return this.changesAcademicProgram$.asObservable();
  }

  // Set Changes in personal data
  public setChangesPersonalData(change: boolean) {
    this.changesPersonalData$.next(change);
  }

  //Get changes in personal data
  public getChangesPersonalData$(): Observable<boolean> {
    return this.changesPersonalData$.asObservable();
  }

  // Set Changes in documents
  public setChangesDocuments(change: boolean) {
    this.changesDocuments$.next(change);
  }

  //Get changes in documents
  public getChangesDocuments$(): Observable<boolean> {
    return this.changesDocuments$.asObservable();
  }

  // Set Changes in financial support
  public setChangesFinancialSupport(change: boolean) {
    this.changesFinancialSupport$.next(change);
  }

  //Get changes in financial support
  public getChangesFinancialSupport$(): Observable<boolean> {
    return this.changesFinancialSupport$.asObservable();
  }

  // Set Changes in subjects
  public setChangesSubjects(change: boolean) {
    this.changesSubjects$.next(change);
  }

  //Get changes in financial support
  public getChangesSubjects$(): Observable<boolean> {
    return this.changesSubjects$.asObservable();
  }

  // Set Changes in payment
  public setChangesPayment(change: boolean) {
    this.changesPayment$.next(change);
  }

  //Get changes in payment
  public getChangesPayment$(): Observable<boolean> {
    return this.changesPayment$.asObservable();
  }


  //OBSERVABLES FOR SUBJECTS
  // Set Boolean Formulario Valido Observable
  public defineAcademicProgramValid(boolForm: boolean) {
    this.formValidAcademicProgram$.next(boolForm);
  }

  //Get Boolean Formulario Valido Observable
  public getAcademicProgramValid$(): Observable<any> {
    return this.formValidAcademicProgram$.asObservable();
  }

  //Post Nuevo Ingreso
  public generarNuevosUsuario(nuevosIngresos): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/RegistrarUsuario`, nuevosIngresos);
  }
  //Put Nuevo Ingreso
  public modificarNuevosUsuario(nuevosIngresos): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/NuevoIngreso`, nuevosIngresos);
  }

  //Post Programa Ingreso
  public generarProgramaIngreso(nuevosIngresos): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/NuevoIngreso/PostPrograma`, nuevosIngresos);
  }
  //Put Programa Ingreso
  public modificarProgramaIngreso(nuevosIngresos): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/NuevoIngreso/PutProgramaIngreso`, nuevosIngresos);
  }

  //Post Datos Usuario Ingreso
  public generarDatosIngreso(nuevosIngresos): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/NuevoIngreso/PostDatosUsuario`, nuevosIngresos);
  }
  //Put Datos Usuario Ingreso
  public modificarDatosIngreso(nuevosIngresos): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/NuevoIngreso/PutDatosUsuarioIngreso`, nuevosIngresos);
  }

  public inscribirAgrupos(AlumnoId: number, identificador: string, grupos: Array<GruposDisponiblesParaMateriaDTO>) {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/InscribirAGrupos?AlumnoId=${AlumnoId}&identificador=${identificador}`, grupos);
  }

  public darDeBajaDeGrupos(informacionDeBaja: BajaDTO) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/candidato/DarDeBajaDeGrupo`, informacionDeBaja);
  }
  /**
   *
   * @returns Observable que notifica al componente hijo que hay un cambio
   */
  public getSeriacionSubjet(): Subject<ExisteMateriaSeriada> {
    return this.seriacion$;
  }

  public existeSeriacionDeMaterias(ExisteMateriaSeriada: ExisteMateriaSeriada): void {
    this.seriacion$.next(ExisteMateriaSeriada);
  }
  /**
   * Rtorna un observable utilizado para estar monitoreando los cambios hechos en selección de materias
   * @returns Observable
   */
  public getCambiosMateriasSubjet(): Subject<boolean> {
    return this.cambioEnSeleccionDeMaterias$;
  }
  /**
   * Notifica que se debe informar sobre un cambio real en las materias seleccionadas
   * @param cambiosRealizados
   */
  public cambiosRealziadosEnSeleccionDeMaterias(cambiosRealizados: boolean) {
    this.cambioEnSeleccionDeMaterias$.next(cambiosRealizados);
  }
  public existeSeriacionDeMateriasEnLaInscripcion(idAlumno: number, grupos: Array<GrupoDTO>) {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/candidato/ExisteSeriacionDeMaterias?alumnoId=${idAlumno}`, grupos);
  }

  //Put Datos Usuario Ingreso
  public modificarStepAlumno(usuarioId, stepActual): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.enves[baseUrl].apiUrl}/Candidato/ActualizarStepAlumno/${usuarioId}/${stepActual}`);
  }

  public consultarEstatusApoyoFinanciero(idUsuario: number, idCampus: number, idPeriodo: number, idPrograma: number): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/Candidato/ConsultarEstatusApoyoFinanciero?IdUsuario=${idUsuario}&IdCampus=${idCampus}&IdPeriodo=${idPeriodo}&IdPrograma=${idPrograma}`);
  }

  public findNombreEstatusAcademico(estatus?: number) {
    if (estatus !== 0) {
      let mensaje: string = this.traductorService.translate(`_catalogos.estatus-academico.nombre-${estatus}`);
      return mensaje;
    } else {
      return "";
    }
  }

  public findNombreNivel(nivel?: number) {
    if (nivel !== 0) {
      let mensaje: string = this.traductorService.translate(`_catalogos.nivel.nombre-${nivel}`);
      return mensaje;
    } else {
      return "";
    }
  }

}
