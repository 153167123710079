import { OCUPATION_INFORMATION_MODEL } from '../../../../../core.domain/models/user/ocupation-information.model';
import { OCUPATION_INFORMATION } from '../../../../../core.application/validators/user-data/occupation-information.validators';
import { MessageErrors } from '../../../../../core.application/functions/messageErros.function';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TipoEmpresa } from '@app/_models/tipo-empresa';
import { TipoPuestoEmpresa } from '@app/_models/tipo-puesto-empresa';
import { EmpresaService } from '@app/_services/empresa.service';
import { Subscription } from 'rxjs';
import { FORM_VALID_MODEL } from '@app/clean-architecture/core.domain/models/user/form-valid-model';

@Component({
  selector: 'app-ocupation-information-form',
  templateUrl: './ocupation-information-form.component.html',
  styleUrls: ['./ocupation-information-form.component.scss']
})
export class OcupationInformationFormComponent implements OnInit, OnDestroy, OnChanges {
  //#region  Input, Output & ViewChild 
  @Input() data: OCUPATION_INFORMATION_MODEL;

  @Input() cancelledFields: string[] = [];
  @Input() disabledledFields: string[] = [];

  @Output() formValidity: EventEmitter<FORM_VALID_MODEL> = new EventEmitter(); 
  @Output() formData = new EventEmitter<OCUPATION_INFORMATION_MODEL>();
  //#endregion

  //#region Subscriptions
  private Subscription: Subscription = new Subscription();
  //#endregion
  
  //#region Properties
  public tipoDeEmpresas: Array<TipoEmpresa>;
  public tipoDePuestos: Array<TipoPuestoEmpresa>;
  public ocupationInformationForm: FormGroup = this.formBuilder.group(OCUPATION_INFORMATION);
  public ErrorMsg: any;
  private status: boolean = false;

  public  deletedInputs = {
    tipoEmpresaId       : false,
    tipoPuestoEmpresaId : false,    
  };
  //#endregion

  constructor(
    private formBuilder: FormBuilder,
    private _empresaService: EmpresaService

  ) { 
    this.setErrors();
  }

  ngOnInit(): void {
    this.subscribeToForm();
    this._TypeOfCompanyPositions();
    this._getTypeOfCompanies();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] !== undefined) {
      this._setData();
    }
  }

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#region setData
  private _setData():void
  {    
    this.ocupationInformationForm.setValue(this.data);
    // this.ocupationInformationForm.get('usuarioId')?.setValue(this.data.usuarioId);
    // this.ocupationInformationForm.get('tipoEmpresaId')?.setValue(this.data.tipoEmpresaId);
    // this.ocupationInformationForm.get('tipoPuestoEmpresaId')?.setValue(this.data.tipoPuestoEmpresaId);

  }
  //#endregion

  //#region Methods
  private _TypeOfCompanyPositions() {
    this.Subscription.add(
      this._empresaService.obtenerTipoPuestoEmpresa().subscribe(
        (tipoPuestosEmpresa: Array<TipoPuestoEmpresa>) => {
          this.tipoDePuestos = tipoPuestosEmpresa;
        })
    );
  }
  /**
   * Obtiene los tipos de empresa existentes en el sistema
   */
   private _getTypeOfCompanies() {
   this.Subscription.add(
    this._empresaService.obtenerTipoEmpresa().subscribe(
      (tipoDeEmpresas: Array<TipoEmpresa>) => {
        this.tipoDeEmpresas = tipoDeEmpresas;
      })
   );
  }
  //#endregion

  //#region Helpers
  public setErrors(): void {
    this.ErrorMsg = MessageErrors.setErrorMessage(this.ocupationInformationForm);
  }

  /**
   * If the cancelledFields array has a length greater than 0, then for each field in the
   * cancelledFields array, if the ocupationInformationForm has a control with the name of the field, then clear the
   * validators for that control and add the field to the deletedInputs object.
   */
   _deleteCancelledFields(): void {
    if (this.cancelledFields.length > 0) {
      this.cancelledFields.forEach(field => {
        if (this.ocupationInformationForm.get(field)) {
          this.ocupationInformationForm.get(field)?.clearValidators();
          this.deletedInputs[field] = true;
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  /**
   * _disabledCancelledFields() is a function that disables and clears validators for fields that are
   * not required
   */
  _disabledCancelledFields(): void {
    if (this.disabledledFields.length > 0) {
      this.disabledledFields.forEach(field => {
        if (this.ocupationInformationForm.get(field)) {
          this.ocupationInformationForm.get(field)?.clearValidators();
          this.ocupationInformationForm.get(field)?.disable();
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  private subscribeToForm(): void {
    this.ocupationInformationForm.markAllAsTouched();
    this._Emit();
    this.Subscription.add(
      this.ocupationInformationForm.statusChanges
      .subscribe(status => {
        this.setErrors();
        this.status = status === 'VALID';
        this._Emit();
      })
    );
  }

  private _Emit():void
  {
    this.formValidity.emit(
      new FORM_VALID_MODEL(
        8,
        'OcupationInformationFormComponent',
        this.status
      )
    );
    this.formData.emit(
      new OCUPATION_INFORMATION_MODEL(
        this.ocupationInformationForm.get('usuarioId')?.value,
        this.ocupationInformationForm.get('tipoEmpresaId')?.value,
        this.ocupationInformationForm.get('tipoPuestoEmpresaId')?.value
      )
    );
  }
  //#endregion

}