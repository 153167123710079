import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { TipoPeriodo } from '@app/_models/tipo-periodo';

@Injectable({
  providedIn: 'root'
})
export class TipoPeriodoService {

  constructor(private httpClient: HttpClient) { }

  //Get Catalogo Tipo Periodo
  public getTipoPeriodo(): Observable<TipoPeriodo[]> {
    return this.httpClient.get<TipoPeriodo[]>(`${environment.enves[baseUrl].apiUrl}/TipoPeriodo`);
  }
}
