import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { ValidarCostoInscripcionDto } from 'src/app/_models/validarCostoInscripcionDto';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';

@Component({
  selector: 'app-admision-stepper',
  templateUrl: './admision-stepper.component.html',
  styleUrls: ['./admision-stepper.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class AdmisionStepperComponent implements OnInit, OnDestroy {

  //Variable ViewChild stepper
  @ViewChild('stepper') public stepperAdmision: MatStepper;
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Vairable para stepper
  isLinear = false;
  //Variable Usuario
  public usuario: any = null;
  public informacionUsuario: any = null;
  //Variables para mostrar tabs
  public showApoyo: boolean = false;
  public showInscripcion: boolean = false;
  //Variable renderizar stepper
  public renderizarStepper: boolean = false;
  public renderizando: boolean = true;


  constructor(private admisionesService: AdmisionesService,
    private ensenanza: EnsenanzaAprendizajeService,
    private snackService: SnackService,
  ) { }

  ngOnInit(): void {
    if (this.admisionesService.BackStepSubscription === undefined) {
      this.admisionesService.BackStepSubscription = this.admisionesService.BackStep.subscribe(() => {
        this.BackStepAdmision();
      });
    }
    if (this.admisionesService.NextStepSubscription === undefined) {
      this.admisionesService.NextStepSubscription = this.admisionesService.NextStep.subscribe(() => {
        this.NextStepAdmision();
      });
    }
    if (this.admisionesService.ValidarApoyoSubscription === undefined) {
      this.admisionesService.ValidarApoyoSubscription = this.admisionesService.ValidarApoyo.subscribe(() => {
        this.GetInformacionUsuario();
      });
    }
    this.subscription.add(
      this.ensenanza.obtenerUsuarioConectado().subscribe(
        (usuario: any) => {
          this.usuario = usuario;
          this.GetInformacionUsuario();
        })
    );
  }




  /**
   * Metodo para obtener la informacion del usuario
   */
  public GetInformacionUsuario() {
    this.subscription.add(
      this.admisionesService.GetInformacionUsuario(this.usuario.usuarioId).subscribe(
        (informacionUsuario: ApiResponse<any>) => {
          this.informacionUsuario = informacionUsuario.data;
          this.admisionesService.SetInformacionUsuario(informacionUsuario.data);
          this.validarApoyoFinanciero();
        })
    );
  }

  /**
   * Metodo para hacer la validacion del step de apoyo financiero
   */
  public validarApoyoFinanciero(): void {
    this.subscription.add(
      this.admisionesService.validarExisteApoyoFinancieroByCampus(this.informacionUsuario.alumnoRegistrado[0].campusId).subscribe(
        (response: ApiResponse<boolean>) => {
          if (response.success) {
            this.showApoyo = response.data;
            this.admisionesService.setExisteApoyo(response.data);
            this.validarCuotaInscripcion();
          } else {
            this.snackService.mostrarSnackBack(response.message);
          }
        })
    );
  }

  /**
   * Metodo para validar si existe una cuota de inscricion y determinar si aparece o no el step de inscripcion
   */
  public validarCuotaInscripcion(): void {
    let datauser: ValidarCostoInscripcionDto = {
      campusId: this.informacionUsuario.alumnoRegistrado[0].campusId,
      periodoId: this.informacionUsuario.alumnoRegistrado[0].priodoIngreso,
      programaId: this.informacionUsuario.alumnoRegistrado[0].programaId,
      tipoAlumno: 'NI'
    };
    this.subscription.add(
      this.admisionesService.ValidarExisteCuotaInscripcion(datauser).subscribe(
        (response: ApiResponse<boolean>) => {
          if (response.success) {
            this.showInscripcion = response.data;
            this.admisionesService.setExisteInscripcion(response.data);
            this.renderizarStepper = true;
            this.asignarStep();
          } else {
            this.snackService.mostrarSnackBack(response.message);
          }
        })
    );
  }

  public asignarStep(): void {
    if (this.informacionUsuario.alumnoRegistrado[0].stepAdmision !== null) {
      for (let index = 0; index < this.informacionUsuario.alumnoRegistrado[0].stepAdmision; index++) {
        let indexSumado = index + 1;
        if (indexSumado === this.informacionUsuario.alumnoRegistrado[0].stepAdmision) {
          if (this.renderizando) {
            this.stepperAdmision.selectedIndex = index + 1;
          } else {
            setTimeout(() => (
            this.stepperAdmision.selectedIndex = index + 1
            ), 2000);
          }
          this.renderizando = false;
        }
      }
    } else {
      this.renderizando = false;
      setTimeout(() => (
        this.stepperAdmision.selectedIndex = 0
      ), 2000);
    }
  }

  /**
   * Metodo que detenta el stepper de angular material
   * @param event 
   */
  public selectionChange(event: any) {
    if (!this.renderizando) {
      this.admisionesService.SetStepAdmision(event.selectedIndex);
      this.admisionesService.InvokeChangeStep();
    }
  }

  /**
   * Metodo para ir al step anterior
   */
  public BackStepAdmision() {
    this.stepperAdmision.previous();
  }

  /**
 * Metodo para ir al step siguiente
 */
  public NextStepAdmision() {
    this.stepperAdmision.next();
  }

  /**
   * Se manda a llamar cuando se cierra el componente y se desuscribe de todos los select elegidos
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
