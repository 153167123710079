import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { TramitesBajaMateria } from '../_models/tramites-baja-materia';

@Injectable({
    providedIn: 'root'
})
export class TramitesService {

    //Variables EventEmitter
    invocarResumenCuenta = new EventEmitter();
    obtenerEstadoCuentaSubscription: Subscription;

    constructor(private httpClient: HttpClient) { }

    /**
     * Servicio para dar de baja una mateia
     * @param bajaMateria 
     * @returns 
     */
    public bajaMateria(bajaMateria: TramitesBajaMateria): Observable<ApiResponse<boolean>> {
        return this.httpClient.post<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/Tramites/BajaMateria`, bajaMateria);
    }

    /**
     * Metodo para actualizar el tab estado de cuenta
     */
    public obtenerEstadoCuenta() {
        this.invocarResumenCuenta.emit();
    }
}