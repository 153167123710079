import { StatusChangeService } from './../../../../_services/status-change.service';
import { ResumenEstadoCuentaDto } from './../../../../_models/resumenEstadoCuentaDto';
import { SnackService } from 'src/app/services/snack-service.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Alumno, EstatusAcademico } from '../../../../_models/inscripcion';
import { Subscription } from 'rxjs';
import { ApiResponse } from '../../../../models/api/ApiRespose.model';
import { SimpleResponse } from '../../../../models/api/SimpleResponse.model';
import { StudentStatusChangePost } from '../../../../_models/StudentStatusChange';

@Component({
  selector: 'app-cambio-estatus-modal',
  templateUrl: './cambio-estatus-modal.component.html',
  styleUrls: ['./cambio-estatus-modal.component.scss']
})
export class CambioEstatusModalComponent implements OnInit {



  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion
 //#region Properties
  public Estatus: EstatusAcademico[] = [];
  public ResumenEstadoCuenta: ResumenEstadoCuentaDto;
  public MateriasInscritasSinCalFinal: number;
  public EstatusForm: FormGroup;
  public StudentStatusChangePost: StudentStatusChangePost;
  //#endregion


//#region Life Cycle
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Alumno,
    private _StatusChangeService: StatusChangeService,
    public dialogRef: MatDialogRef<CambioEstatusModalComponent>,
    private formBuilder: FormBuilder,
    private SnackServiceService: SnackService) {
    }

  ngOnInit(): void {
    this.ObtenerEstatus();
    this.GetResumenEstadoCuentaByUsuarioID();
    this.GetMateriasInscritasSinCalFinalByAlumnoID();
    this.buildForm();
  }
  //#endregion

//#region Api Methos
public ObtenerEstatus(): void
{
  this.Subscription.add(
    this._StatusChangeService.GetEstatusAcademicos().subscribe(
      (estatus : ApiResponse<EstatusAcademico[]>) => {
       /*  this.Estatus = estatus.data.filter((estatus) => {
          return estatus.estatusAcademicoId === 8 || estatus.estatusAcademicoId === 10 || estatus.estatusAcademicoId === 11 || estatus.estatusAcademicoId === 4 || estatus.estatusAcademicoId === 12
        }); */
        this.Estatus = estatus.data.filter(x => x.estatusAcademicoId === 5 || x.estatusAcademicoId === 6 || x.estatusAcademicoId === 8 || x.estatusAcademicoId === 10 || x.estatusAcademicoId === 11 || x.estatusAcademicoId === 12);
      }
    )
  );
}

public GetResumenEstadoCuentaByUsuarioID(): void
{
  this.Subscription.add(
    this._StatusChangeService.GetResumenEstadoCuentaByUsuarioID(this.data.usuarioId).subscribe(
      (ResumenEstadoCuentaDto : ApiResponse<ResumenEstadoCuentaDto>) => {
        this.ResumenEstadoCuenta = ResumenEstadoCuentaDto.data;
      }
    )
  );
}

public GetMateriasInscritasSinCalFinalByAlumnoID(): void
{
  this.Subscription.add(
    this._StatusChangeService.GetMateriasInscritasSinCalFinalByAlumnoID(this.data.alumnoId).subscribe(
      (MateriasInscritasSinCalFinal : ApiResponse<number>) => {
        this.MateriasInscritasSinCalFinal = MateriasInscritasSinCalFinal.data;
        //console.log("this.MateriasInscritasSinCalFinal",this.MateriasInscritasSinCalFinal);
      }
    )
  );
}

public CambioDeEstatus(StudentStatusChangePost: StudentStatusChangePost): void{
  this.Subscription.add(
    this._StatusChangeService.CambioDeEstatus(StudentStatusChangePost).subscribe((SimpleResponse: SimpleResponse) => {
     if (SimpleResponse.success) {
      this.SnackServiceService.mostrarSnackBack("El cambio de estatus académico se realizo con éxito");
      this.dialogRef.close(true);
     }else{
      this.SnackServiceService.mostrarSnackBack(SimpleResponse.message);
      //console.log(false);
     }
    })
  );
}

//#endregion

 //#region Forms
 private buildForm(): void {
  this.EstatusForm = this.formBuilder.group({
      estatusAcademicoF: [0, [Validators.required, this.validateId]],
      nombreAF: [''],
      fechaAF:[new Date],
      nombreFF: [''],
      fechaFF: [new Date ],
      motivoF: [''],
    }
  );
}
private validateId(control: AbstractControl) {
  return control.value === 0 ? { error: 'El campo es requerido' } : null;
}


public clearForm(): void {
  this.buildForm();
}

public onSubmit($event: Event): void {
  if (this.EstatusForm.valid) {

    this.StudentStatusChangePost= new StudentStatusChangePost();

    this.StudentStatusChangePost.alumnoID = this.data.alumnoId;
    this.StudentStatusChangePost.estatusAcademicoId = this.EstatusForm.value.estatusAcademicoF;

    this.StudentStatusChangePost.periodoID = this.data.priodoIngresoNavigation.periodoId;
    this.StudentStatusChangePost.campusID = this.data.campusId;
    this.StudentStatusChangePost.programaID = this.data.programaId;

    if (this.EstatusForm.value.estatusAcademicoF === 5) {

      this.StudentStatusChangePost.academicName = this.EstatusForm.value.nombreAF === ""? null: this.EstatusForm.value.nombreAF;
      this.StudentStatusChangePost.academicDate = this.EstatusForm.value.nombreAF === ""? null: this.EstatusForm.value.fechaAF;

      this.StudentStatusChangePost.financialName = this.EstatusForm.value.nombreAF === ""? null: this.EstatusForm.value.nombreAF;
      this.StudentStatusChangePost.financialDate = this.EstatusForm.value.nombreFF === "" ? null: this.EstatusForm.value.fechaFF;

      this.StudentStatusChangePost.reasonChange = this.EstatusForm.value.motivoF;

      if (this.EstatusForm.value.motivoF === "" || this.EstatusForm.value.motivoF === null) {
        this.SnackServiceService.mostrarSnackBack("favor de llenar todos los campos requeridos");
      }else{
        //console.log(this.StudentStatusChangePost);
        this.CambioDeEstatus(this.StudentStatusChangePost);
      }

    }else{

      this.StudentStatusChangePost.academicName = null;
      this.StudentStatusChangePost.academicDate = null;

      this.StudentStatusChangePost.financialName = null;
      this.StudentStatusChangePost.financialDate = null;

      this.StudentStatusChangePost.reasonChange = null;

      //console.log(this.StudentStatusChangePost);
      this.CambioDeEstatus(this.StudentStatusChangePost);

    }


  } else {
    this.EstatusForm.markAllAsTouched();
    this.SnackServiceService.mostrarSnackBack("favor de llenar todos los campos requeridos");
  }
}
//#endregion

//#region Close Modal
public CerrarModal():void {
  this.dialogRef.close();
}
//#endregion
}
