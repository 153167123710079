import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UploadService } from '../_services/upload-service';
import { Estados } from '../_models/estados.enum';
import { Municipios } from '../_models/municipios.enum';
import { isNgTemplate } from '@angular/compiler';
import { Generos } from '../_models/generos.enum';
import { IUsuario } from './Usuario';
import { IPeriodo } from './Periodo';
import { SolUsuariosService } from '../_services/sol-usuarios.service';
import { IPrograma } from './Programa';
import { ICampus } from './Campus';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

interface Campus {
  value: number;
  viewValue: string;
}
interface Programa {
  value: number;
  viewValue: string;
}
interface Periodo {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-admisiones',
  templateUrl: './admisiones.component.html',
  styleUrls: ['./admisiones.component.scss']
})
export class AdmisionesComponent implements OnInit {
  //UsuarioID
  public UsuarioID: number;
  //SelectFile
  selectedFile = null;

  archivo: File;

  //lstPeriodo
  public lstPeriodo: Observable<IPeriodo[]>;
  //lstPrograma
  public lstPrograma: Observable<IPrograma[]>;
  //lstCampus
  public lstCampus: Observable<ICampus[]>;

  usuario: IUsuario[];
  peri: IPeriodo[];
  prog: IPrograma[];
  camp: ICampus[];
  perioSelect: number
  modifedtext: string;
  progSelect: number;
  vertext: string;
  campSelect: number;
  viewtext: string;

  Object: any;
  uploadFiles: Array<File>;


  //Descartado... Campus, Programa, Periodo
  campuses: Campus[] = [
    { value: 1, viewValue: 'Apodaca' },
    { value: 2, viewValue: 'Escobedo' },
    { value: 3, viewValue: 'Monterrey' },
    { value: 4, viewValue: 'San Nicolas de los Garza' },
  ];
  programas: Programa[] = [
    { value: 1, viewValue: 'Enfermeria' },
    { value: 2, viewValue: 'Administración de empresas' },
    { value: 3, viewValue: 'Ingenieria en sistemas' },
    { value: 4, viewValue: 'Ingeniera en mecatronica' },
  ];
  periodos: Periodo[] = [
    { value: 1, viewValue: 'Enero - Junio' },
    { value: 2, viewValue: 'Julio - Diciembre' },
  ];
   
 
  constructor(private formBuilder: FormBuilder,
    private UploadService: UploadService, private SolUsuarioService: SolUsuariosService,
    public snackBar: MatSnackBar,
    private http: HttpClient) {

    //Get´s Periodo, Programa, Campus
    this.GetPer();
    this.GetProg();
    this.GetCamp();
  }
  //Consulta New GetPeriodo...
  public GetPer() {
    this.lstPeriodo = this.SolUsuarioService.getPeriodo();
  }
  //Consulta New GetPrograma...
  public GetProg() {
    this.lstPrograma = this.SolUsuarioService.getPrograma();
  }
  //Consulta New GetCampus...
  public GetCamp() {
    this.lstCampus = this.SolUsuarioService.getCampus();
  }

  get username() {
    return this.registerForm.get('username');
  }
  get apellidop() {
    return this.registerForm.get('apellidop');
  }
  get apellidom() {
    return this.registerForm.get('apellidom');
  }
  get correo() {
    return this.registerForm.get('correo');
  }
  get telefono() {
    return this.registerForm.get('telefono');
  }
  get postal() {
    return this.registerForm.get('postal');
  }
  get fechan() {
    return this.registerForm.get('fechan');
  }
  get calle() {
    return this.registerForm.get('calle');
  }
  get exterior() {
    return this.registerForm.get('exterior');
  }
  get interior() {
    return this.registerForm.get('interior');
  }
  get colonia() {
    return this.registerForm.get('colonia');
  }
  get municipio() {
    return this.registerForm.get('municipio');
  }
  get genero() {
    return this.registerForm.get('genero');
  }
  get estado() {
    return this.registerForm.get('estado');
  }
  //Informacion acádemica
  get periodo() {
    return this.regForm.get('periodo');
  }
  get programa() {
    return this.regForm.get('programa');
  }
  get campus() {
    return this.regForm.get('campus');
  }
  //FormGroup de Datos generales
  registerForm = this.formBuilder.group({
    
    username: ['', Validators.required],
    apellidop: ['', Validators.required],
    apellidom: ['', Validators.required],
    genero: [Generos.Masculino],
    correo: ['', Validators.compose([Validators.email, Validators.required])],
    telefono: ['', [Validators.required, Validators.minLength(10)]],
    postal: ['', Validators.required],
    fechan: ['', Validators.required],
    calle: ['', Validators.required],
    exterior: ['', Validators.required],
    interior: ['', Validators.required],
    colonia: ['', Validators.required],

    estado: [Estados.Aguascalientes],
    municipio: ['', Validators.required]
  });

  //FormGroup de documentos
  regisForm = this.formBuilder.group({
    acta: ['', Validators.required],
    ine: ['', Validators.required],
    curp: ['', Validators.required],
    comprobante: ['', Validators.required],
    certificado: ['', Validators.required]
  });

  //FormGroup de Informacion de carrera
  regForm = this.formBuilder.group({
    periodo: [this.periodos],
    programa: [this.programas],
    campus: [this.campuses]
  });

  estados: any[] = [];
  municipios: any[] = [];
  generos: any[] = [];

  ngOnInit(): void {
    //SolUsuario de servicios "Consulta"
    /*
    this.SolUsuarioService.getUsuarios()
      .subscribe(usuariosWS => this.usuario = usuariosWS,
        error => console.log(error));*/
    //Consulta GetPeriodo
    this.SolUsuarioService.getPeriodo()
      .subscribe(data => {
        this.peri = data;
        console.log(this.peri);
        this.Object
        error => console.log(error)
      });
    this.perioSelect = 3;
    //Consulta GetPrograma
    this.SolUsuarioService.getPrograma()
      .subscribe(data => {
        this.prog = data;
        console.log(this.prog);
        this.Object
        error => console.log(error)
      });
    this.progSelect = 1;
    //Consulta GetCampus
    this.SolUsuarioService.getCampus()
      .subscribe(data => {
        this.camp = data;
        console.log(this.camp);
        this.Object
        error => console.log(error)
      });
    this.campSelect = 1;

    for (let item in Estados) {
      if (isNaN(Number(item))) {
        this.estados.push({ text: item, value: Estados[item] });
      }
    }
    for (let item in Municipios) {
      if (isNaN(Number(item))) {
        this.municipios.push({ text: item, value: Municipios[item] });
      }
    }
    for (let item in Generos) {
      if (isNaN(Number(item))) {
        this.generos.push({ text: item, value: Generos[item] });
      }
    }
  }
  
  //Metodo de guardado en informacion de la carrera
  enviar() {
    //Metodo Post de Información academica...
    this.SolUsuarioService.Inf(this.periodo.value, this.programa.value, this.campus.value, this.UsuarioID).subscribe(result => {
      console.log(result);
      this.snackBar.open('Información académica con éxito', '', {
        duration: 2000,
      });
    },
      error => console.error(error)
      );

    if (!this.regForm.valid) {
      alert('Algun campo no se esta cumpliendo');
      return;
    }
    console.log(this.regForm.value);
  }

  regresar() {
    this.regForm.patchValue({
      periodo: this.periodos,
      programa: this.programas,
      campus: this.campuses
    });
  }
 
  //Metodo de guardado formulario Datos generales
  submit() {
    //Metodo Post de SolUsuarioService
    this.SolUsuarioService.DatosPersonales(this.username.value, this.apellidop.value, this.apellidom.value, this.genero.value, this.correo.value,
      this.telefono.value, this.fechan.value, this.calle.value, this.exterior.value, this.interior.value, this.colonia.value,
      this.postal.value, this.municipio.value, this.estado.value).subscribe(result => {
        this.UsuarioID = result.usuarioID;
        this.snackBar.open('Datos generales con éxito', '', {
          duration: 2000,
        });
        error => console.error(error)        
      });

    if (!this.registerForm.valid) {
      alert('Alguna regla de validación no se está respetando');
      return;
    }
    console.log(this.registerForm.value);
  }

  cancelar() {
    this.registerForm.patchValue({
      username: '',
      apellidop: '',
      apellidom: '',
      genero: Generos.Masculino,
      correo: '',
      postal: '',
      telefono: '',
      fechan: '',
      calle: '',
      exterior: '',
      interior: '',
      colonia: '',
      estado: Estados.Aguascalientes,
      municipio: Municipios.Abasolo
    });
  }
  //Metodo de guardado documentos
  guardar() {
    if (!this.regisForm.valid) {
      alert('Alguna regla de validación no se está respetando');
      return;
    }
    console.log(this.regisForm.value);
  }

  refrescar() {
    this.regisForm.patchValue({
      acta: false,
      curp: false,
      ine: false,
      certificado: false,
      comprobante: false
    });
  }

  //Metodo de archivos... (NodeJs.)
  onUpload() {
    let formData = new FormData();
    for (let i = 0; i < this.uploadFiles.length; i++) {
      formData.append('uploads[]', this.uploadFiles[i], this.uploadFiles[i].name);
    }
    //Llamar a nuestro services
    this.UploadService.uploadFile(formData).subscribe((res) => {
      console.log('Response:', res);
    });
  }
  onFileChange(e) {
    this.uploadFiles = e.target.files;
  }

  //Guardado de archivo...
  onFileSelected(event) {
     this.selectedFile = event.target.files[0];
  }

  onFileUpload() {
    this.SolUsuarioService.postFile(this.archivo);
  }
}
