import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { Recurso } from 'src/app/_models/recurso';
import { EnsenanzaAprendizajeAlumnoService } from '../../../../_services/ensenanzaAprendizajeAlumno.service'
import { ModalContestarExEnComponent } from '../modal-contestar-ex-en/modal-contestar-ex-en.component'
import { ModalResultadoExComponent } from '../modal-resultado-ex/modal-resultado-ex.component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ModalConsultarExamenEncuestaComponent } from '../../consultar/modal-consultar-examen-encuesta/modal-consultar-examen-encuesta/modal-consultar-examen-encuesta.component';

@Component({
  selector: 'app-modal-examen-encuesta',
  templateUrl: './modal-examen-encuesta.component.html',
  styleUrls: ['./modal-examen-encuesta.component.scss'],
  providers: [DatePipe]
})
export class ModalExamenEncuestaComponent implements OnInit, OnDestroy {
  public alumnoId: number;
  public datosExamen: any;
  public datosEncuesta: any;
  public verResultado: boolean;
  public preguntaAbierta: boolean = false;
  public responderExamen: boolean = false;
  public responderEncuesta: boolean = false;
  public prueba = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    public dialogRef: MatDialogRef<ModalExamenEncuestaComponent>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    this.EnsenanzaAprendizajeAlumnoService.SetModalExistente(false);
  }

  ngOnInit(): void {
    console.log('inform modal examen', this.data);
    this.EnsenanzaAprendizajeAlumnoService.SetModalExistente(true);
    this.alumnoId = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;
    const parametros = window.location.pathname.split("/", 6);
    this.router.navigate([`/detalle-grupo-alumno/grupo/${parametros[3]}/opcion/${parametros[5]}/recurso/${this.data.recursoId}`]);
    if (this.data.tipoRecursoId == 3) {
      this.cargarDetalleExamen();
    } else {
      this.cargarDetalleEncuesta();
      //this.validarFecha();
    }
  }

  public cerrarModal() {
    const parametros = window.location.pathname.split("/", 6);
    this.router.navigate([`/detalle-grupo-alumno/grupo/${parametros[3]}/opcion/${parametros[5]}`]);
    this.dialogRef.close();
  }

  public cargarDetalleExamen() {
    this.EnsenanzaAprendizajeAlumnoService.GetDetalleExamen(this.data.recursoId, this.alumnoId).subscribe((ex: ApiResponse<Recurso[]>) => {
      this.datosExamen = ex.data;
      this.datosExamen.descripcion = this.stringToHtml(this.datosExamen.descripcion)
      if (this.datosExamen.estatus == "Revisado") {
        this.verResultado = false;
      } else if (this.datosExamen.estatus == "Sin entregar") {
        this.verResultado = false;
        this.responderExamen = true;
      } else {
        this.verResultado = true;
      }

      this.validarFecha();
      this.EnsenanzaAprendizajeAlumnoService.GetExamenAbierto(this.data.recursoId).subscribe((valid: SimpleResponse) => {
        if (valid.success) {
          this.preguntaAbierta = true;
        }
      })
    })
  }

  public cargarDetalleEncuesta() {
    this.EnsenanzaAprendizajeAlumnoService.GetDetalleEncuesta(this.data.recursoId, this.alumnoId).subscribe((en: ApiResponse<Recurso[]>) => {
      this.datosEncuesta = en.data;
      this.datosEncuesta.descripcion = this.stringToHtml(this.datosEncuesta.descripcion)
      if (this.datosEncuesta.estatus == "Revisado") {
        this.verResultado = true;
      } else if (this.datosEncuesta.estatus == "Sin entregar") {
        this.verResultado = false;
        this.responderEncuesta = true;
      }
    })
  }


  fechaVencida: boolean = false;
  public validarFecha() {
    var hoy = moment();
    var horaFin = moment(this.datosExamen.horaFin, 'h:mma');

    console.log("Validar");
    var fechaHoy1 = new Date();
    let fechaHoy = this.datePipe.transform(fechaHoy1, 'yyyy-MM-dd');

    let fechaEntrega = this.datePipe.transform(this.datosExamen.fechaEntrega, 'yyyy-MM-dd');


    if (fechaHoy > fechaEntrega )
    {
      this.fechaVencida = true;
    }else if (fechaHoy === fechaEntrega)
    {
      if (hoy.isBefore(horaFin) ) {
        this.fechaVencida = false;
      }
      else
      {
        this.fechaVencida = true;
      }
    }
  }

  public contestarExamen() {

    if (this.fechaVencida) {
      this.snackBar.open("La fecha y hora de cierre ya paso.", "OK", { duration: 3000 });
    } else {

      this.data.reintento = false;
      this.data.alumnoId = this.alumnoId;
      const modal = this.dialog.open(ModalContestarExEnComponent, { data: this.data, disableClose: true, height: '550px', width: '80vw' });
      this.dialogRef.close();
    }
  }

  public stringToHtml(titulo: string) {
    let xmlString = titulo;
    let doc: Document = new DOMParser().parseFromString(xmlString, "text/html");
    return doc.body.innerHTML;
  }

  public contestarEncuesta() {
    let today = new Date();
    let fechaEntrega = new Date(this.datosEncuesta.fechaEntrega);
    let fechaIncio = new Date(this.datosEncuesta.fechaInicio);
    if (this.datosEncuesta.horaFin) {
      let horaFin = new Date();
      let [hours, minutes] = this.datosEncuesta.horaFin.split(':');
      horaFin.setHours(+hours);
      horaFin.setMinutes(minutes);
      let fechaFin = new Date(this.datosEncuesta.fechaEntrega)
      fechaEntrega = this.combineDateAndTime(fechaFin, horaFin)
    }
    if (this.datosEncuesta.horaInicio) {
      let horaInicio = new Date();
      let [hours, minutes] = this.datosEncuesta.horaInicio.split(':');
      horaInicio.setHours(+hours);
      horaInicio.setMinutes(minutes);
      let fechaInicio = new Date(this.datosEncuesta.fechaInicio)
      fechaIncio = this.combineDateAndTime(fechaInicio, horaInicio)
    }
    if (fechaEntrega <= today) {
      this.snackBar.open("La fecha de cierre ya paso.", "OK", { duration: 3000 });
    } else if (fechaIncio.getTime() >= today.getTime()) {
      this.snackBar.open("La fecha de inicio aun no empieza.", "OK", { duration: 3000 });
    } else {
      const modal = this.dialog.open(ModalContestarExEnComponent, { data: this.data, disableClose: true, height: '550px', width: '80vw' })
      this.dialogRef.close();
    }
  }

  public verResultadoExamenEncuesta() {

    this.EnsenanzaAprendizajeAlumnoService.obtenerConsultaCalificaciones(this.alumnoId, this.data.grupoId).subscribe(
      (row: ApiResponse<any[]>) => {
        const result = row.data.filter(x => x.recursoId === this.data.recursoId);
        result[0].entregar=true;
        result[0].calificacion=true;
        result[0].grupoId = this.data.grupoId;
        result[0].soloDetalle = true;
        console.log("result",result[0]);
        const modal = this.dialog.open(ModalConsultarExamenEncuestaComponent, { data: result[0], disableClose: true, height: '550px', width: '80vw' })
    })

    // this.dialog.open(ModalResultadoExComponent, { data: this.data, disableClose: true, height: '550px', width: '80vw' })

  }

  public combineDateAndTime = function (date, time) {
    let timeString = time.getHours() + ':' + time.getMinutes() + ':00';
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();
    var dateString = '' + year + '-' + month + '-' + day;
    var combined = new Date(dateString + ' ' + timeString);
    return combined;
  };
}
