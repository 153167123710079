import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from 'src/environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { TipoModalidadTitulacion } from '../_models/tipo-modalidad-titulacion';
import { ApiResponse } from '../models/api/ApiRespose.model';

@Injectable({
    providedIn: 'root'
  })
  export class TipoModalidadTitulacionService {
  
    constructor(private httpClient: HttpClient) { }
  
    public obtenerTiposTitulacion(): Observable<ApiResponse<Array<TipoModalidadTitulacion>>> {
      return this.httpClient.get<ApiResponse<Array<TipoModalidadTitulacion>>>(`${environment.enves[baseUrl].apiUrl}/TipoModalidadTitulacion/ObtenerTiposTitulacion`);
    }
  }