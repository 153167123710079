import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Direccion } from '../_models/direccion';
import { Pais } from '@app/_models/pais';
import { EntidadFederativa } from '@app/_models/entidad-federativa';
import { TipoDireccion } from '../_models/tipo-direccion';
import { TipoTelefono } from '../_models/tipo-telefono';
import { EstadoCivil } from '../_models/estado-civil';

@Injectable({
  providedIn: 'root'
})
export class DireccionService {

  constructor(private httpClient: HttpClient) { }

  //Get Direccion
  public obtenerDireccion(): Observable<Direccion[]> {
    return this.httpClient.get<Direccion[]>(`${environment.enves[baseUrl].apiUrl}/Direccion`);
  }

  public obtenerDireccionByProfesorId(usuarioId : number): Observable<any> {
    return this.httpClient.get<Direccion>(`${environment.enves[baseUrl].apiUrl}/Direccion/GetDireccionByProfesorId?usuarioId=` + usuarioId );
  }
  //Post Direccion
  public agregarDireccion(direccion: Direccion): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Direccion`, direccion);
  }
//Post/Put DatosContacto
public registrarDatosContacto(datosContacto): Observable<boolean> {
  return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Direccion/PostDatosContacto`, datosContacto);
};


  //Put Direccion
  public modificarDireccion(direccion: Direccion): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Direccion`, direccion);
  }

  public obtenerPaises(): Observable<Pais[]> {
    return this.httpClient.get<Pais[]>(`${environment.enves[baseUrl].apiUrl}/Pais`);
  }

  public obtenerEntidadesFederativas(paisId: number): Observable<EntidadFederativa[]> {
    return this.httpClient.get<EntidadFederativa[]>(`${environment.enves[baseUrl].apiUrl}/entidadFederativa/${paisId}/`);
  }

  //Get Catalogo Estado Civil
  public obtenerEstadoCivil(): Observable<EstadoCivil[]> {
    return this.httpClient.get<EstadoCivil[]>(`${environment.enves[baseUrl].apiUrl}/EstadoCivil`);
  }

  public obtenerEntidadFederativa(entidadFederativaId: number): Observable<any> {
    return this.httpClient.get<EntidadFederativa>
    (`${environment.enves[baseUrl].apiUrl}/entidadFederativa/ObtenerEntidadFederativa?entidadFederativaId=` + entidadFederativaId);
  }



  //Get Tipo Direccion
  public obtenerTipoDireccion(): Observable<TipoDireccion[]> {
    return this.httpClient.get<TipoDireccion[]>(`${environment.enves[baseUrl].apiUrl}/Direccion/GetTipoDireccion`);
  }

  //Get Direccion
  public obtenerTipoTelefono(): Observable<TipoTelefono[]> {
    return this.httpClient.get<TipoTelefono[]>(`${environment.enves[baseUrl].apiUrl}/Direccion/GetTipoTelefono`);
  }

  public obtenerPais(paisId: number): Observable<EntidadFederativa[]> {
    return this.httpClient.get<EntidadFederativa[]>(`${environment.enves[baseUrl].apiUrl}/entidadFederativa/${paisId}/`);
  }

}
