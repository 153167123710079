import { Usuario } from './../../../../_models/usuario';
export class HeaderModalDetails {

    nombre?         :   string;
    identificador?  :   string;
    usuarioId?      :   number;
    estatus?        :   string;
    programa?       :   string;
    campus?         :   string;
    periodo?        :   string;
    bloque?         :   string;
    promedio?       :   string;
    promedioPeriodo?:   string;    

    //#region header
    fotografia?     :   string | ArrayBuffer | null;
    //#endregion

    /* property 'usuario' is required */
    constructor(_HeaderModalDetails: Object) {
        Object.assign(this, _HeaderModalDetails);
    }
    
}
