import { Component, OnInit, Inject, ViewChild, TemplateRef, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Materia } from '../../_models/materia';
import { MateriaService } from '../../_services/materia.service';
import { Programa } from '../../_models/programa';
import { Ciclo } from '../../_models/ciclo';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { AccionEnum } from '../../_models/accion.enum';
import { ProgramaAcademicoModalComponent } from '../programa-academico-modal/programa-academico-modal.component';
import { ProgramaAcademicoModalAgregarCicloComponent } from '../programa-academico-modal-agregar-ciclo/programa-academico-modal-agregar-ciclo.component';
import { AreaMateria } from '../../_models/area-materia';
import { TipoInstalacion } from '../../_models/tipo-instalacion';
import { TipoMateria } from '../../_models/tipo-materia';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormArray, FormControl } from '@angular/forms';
import { ProgramaEliminarMateriasComponent } from '../programa-eliminar-materias/programa-eliminar-materias.component';
import { ProgramaAcademicoModalEdicionCicloComponent } from '../programa-academico-modal-edicion-ciclo/programa-academico-modal-edicion-ciclo.component';
import { ProgramaEliminarCiclosComponent } from '../programa-eliminar-ciclos/programa-eliminar-ciclos.component';
import { ImprimirServiceService } from 'src/app/services/imprimir-service.service';
@Component({
  selector: 'app-programa-plan-estudios',
  templateUrl: './programa-plan-estudios.component.html',
  styleUrls: ['./programa-plan-estudios.component.scss']
})
export class ProgramaPlanEstudiosComponent implements OnInit {

  @Input() datosPrograma: Programa;
  @ViewChild('dialogMateria') dialogMateria: TemplateRef<any>;
  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  @ViewChild('dialogAdverteniaMateria') dialogAdverteniaMateria: TemplateRef<any>;
  public cicloList: Ciclo[] = [];
  public materiaList: Materia[] = [];
  public materia: Materia;
  public ValorSeleccionado: Materia[] = [];
  public infoCargado: boolean = false;
  public dataModalCrearCilo: Ciclo;
  public dialogoRefAdver: any;
  public dialogoRefAdverMateria: any;
  public cicloSeleccionadoID = 0;
  public materiaSeleccionadaID = 0;
  public areaMateriaList: AreaMateria[] = [];
  public tipoInstalacionList: TipoInstalacion[] = [];
  public tipoMateriaList: TipoMateria[] = [];
  public infoCargada: boolean = false;
  public materiasEnviar: any;
  public dataOriginal: any;

  // Nuevo
  public columnaCiclo: string[] = ['selectCiclo', 'nombreCiclo', 'creditosCiclo'];
  public columnasMateria: string[] = ['select', 'clave', 'nombre', 'seriacion', 'creditos', 'hfd', 'hei', 'thor'];
  public dataSourceCiclo: MatTableDataSource<any>;
  public dataSourceMateria: MatTableDataSource<any>;
  selectionCiclo = [];
  selectionMateria = new SelectionModel<Materia>(true, []);
  public numCicloSelect = 0;
  public numMateriaSelect = 0;
  public claveCiclo: string;
  public nombreCiclo: string;
  public materiaClave: string;
  public materiaNombre: string;
  public checkCiclo = new FormControl();
  checkValor: any;
  indiceCiclo: any;
  listaCiclos: Array<Ciclo>= [];
  public seriacionDeMateria = [];

  constructor(public dialogRef: MatDialogRef<ProgramaPlanEstudiosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private materiaService: MateriaService,
    private snackBar: MatSnackBar,
    private programaAcademicoService: ProgramaAcademicoService,
    private dialog: MatDialog,
    private imprimirService: ImprimirServiceService) {
  }

  ngOnInit(): void {
    if (this.programaAcademicoService.invMetodoCiclosSubscription === undefined) {
      this.programaAcademicoService.invMetodoCiclosSubscription = this.programaAcademicoService.invocarObtenerCiclos.subscribe(() => {
        this.cicloList = [];
        this.obtenerCiclosByPrograma();
      });
      this.programaAcademicoService.invMetodoCiclosSubscription = undefined;
    }
    if (this.programaAcademicoService.invMetodoCiclosByProgramaSubscription === undefined) {
    this.programaAcademicoService.invMetodoCiclosByProgramaSubscription = this.programaAcademicoService.invocarObtenerCiclosByPrograma.subscribe(
      () => {
      this.obtenerCiclosByPrograma();
    });
    this.programaAcademicoService.invMetodoCiclosByProgramaSubscription = undefined;
    }
    this.obtenerAreaMateria();
    this.obtenerTipoMateria();
    this.obtenerTipoInstalacion();
    this.obtenerCiclosByPrograma();
  }

  public obtenerAreaMateria() {
    this.materiaService.obtenerAreaMateria().subscribe(
      (areaMateria: AreaMateria[]) => {
        this.areaMateriaList = areaMateria;
      });
  }

  public obtenerTipoMateria() {
    this.materiaService.obtenerTipoMateria().subscribe(
      (tipoMateria: TipoMateria[]) => {
        this.tipoMateriaList = tipoMateria;
      });
  }

  public obtenerTipoInstalacion() {
    this.materiaService.obtenerTipoInstalacion().subscribe(
      (tipoInstalacion: TipoInstalacion[]) => {
        this.tipoInstalacionList = tipoInstalacion;
      });
  }

  public findNombreAreaMateria(areaMateriaId?: number) {
    return this.areaMateriaList.find(id => id.areaMateriaId === areaMateriaId).nombre;
  }

  public findNombreTipoMateria(tipoMateriaId?: number) {
    return this.tipoMateriaList.find(id => id.tipoMateriaId === tipoMateriaId).nombre;
  }

  public findNombreTipoInstalacion(tipoInstalacionId?: number) {
    return this.tipoInstalacionList.find(id => id.tipoInstalacionId === tipoInstalacionId).nombre;
  }

  EliminarMultiples(Ciclo:Ciclo, isChecked: boolean) {
    if (isChecked) {
      this.listaCiclos.push(Ciclo);
      this.numMateriaSelect = this.listaCiclos.length;
    } else {

      let index = this.listaCiclos.findIndex(c => c.cicloId == Ciclo.cicloId)
      this.listaCiclos.splice(index, 1);
      this.numMateriaSelect = this.listaCiclos.length;
    }
  }

  public obtenerCiclosByPrograma() {
    this.programaAcademicoService.obtenerCiclosByPrograma(this.data.programaId).subscribe(
      (ciclo: Ciclo[]) => {
        // ciclo completo
        this.cicloList = ciclo;
        console.log('CICLO LIST', this.cicloList);
        this.dataSourceCiclo = new MatTableDataSource(ciclo);
        this.selectionCiclo = [];
        // for para contar los creditos de los ciclos
        for (let i = 0; i < this.cicloList.length; i++) {
          this.selectionCiclo[i] = new SelectionModel<any>(true, []);
          if (this.cicloList[i].materia.length > 0) {
            let sumaCreditosTotal = this.cicloList[i].materia.reduce((prev, curr) => prev + curr.creditos, 0);
            this.cicloList[i].materia.forEach(suma => suma.sumaCreditos = sumaCreditosTotal);
          }
        }
        // traer las materias segun el ciclo
        let arregloMaterias: any[] = [];
        arregloMaterias = this.cicloList.map(ciclo => (ciclo.materia));
        let arreglo: any[] = [];
        arregloMaterias.forEach(arr =>
          arr.forEach(mat => {
            arreglo.push(mat);
          })
        );
        // this.programaAcademicoService.definirArrayMaterias(arreglo);
        this.materiasEnviar = arreglo;
        this.dataSourceMateria = new MatTableDataSource(this.materiasEnviar);
        this.dataOriginal = arreglo;
        this.selectionMateria = new SelectionModel<any>(true, []);

        console.log('ARREGLO', this.materiasEnviar);
        this.infoCargada = true;
      });
      this.selectionMateria.clear();
  }

  obtenerSeriacion(element?: any, materiaid?: number){
    var contenido = element;
    var materiaID = materiaid;
    this.seriacionDeMateria = [];
    let datoSeriacion: any;
    for(let j = 0; j < contenido.seriacionMateria.length; j++){
      datoSeriacion = contenido.seriacionMateria[j].materiaSeriacion.clave;
      if(materiaid == contenido.seriacionMateria[j].materiaId){
        this.seriacionDeMateria.push(datoSeriacion);
      }
    }
    return this.seriacionDeMateria.join(', ');
  }

  public crearCiclo() {
    const dialogCrearCilo = this.dialog.open(ProgramaAcademicoModalAgregarCicloComponent, {
      data: this.datosPrograma,
      panelClass: "dialogo-ciclo",
      width: '50%',
      disableClose: true
    });

    dialogCrearCilo.afterClosed().subscribe(data => {
      this.dataModalCrearCilo = data;
      let cicloForm = Object.assign(this.dataModalCrearCilo);
      let ciclo: any = {
        cicloId: cicloForm?.cicloId,
        clave: cicloForm?.clave,
        nombre: cicloForm?.nombre,
        esOficial: cicloForm?.esOficial
      }

      if (this.dataModalCrearCilo !== null) {
        this.programaAcademicoService.obtenerCicloByClave(cicloForm.clave).subscribe(
          ocupado => {
            if (ocupado != true) {
              this.programaAcademicoService.generarCiclo(this.dataModalCrearCilo).subscribe(
                cicloGuardado => {
                  if (cicloGuardado !== null) {
                    this.snackBar.open("Se ha agregado el ciclo.", "OK", { duration: 5000 });
                    this.cicloList.push(cicloGuardado);
                    this.obtenerCiclosByPrograma();
                  } else {
                    this.snackBar.open("Ha ocurrido un error al modificar el programa. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                  }
                },
                error => {
                  console.log("error", error);
                  this.snackBar.open("Ha ocurrido un error al modificar el programa. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                }
              );
              this.snackBar.open("Se ha guardado correctamente el ciclo.", "OK", { duration: 7000 });
            } else {
              this.snackBar.open("Ha ocurrido un error al guardar un ciclo. La clave ingresada ya esta registrada, ingrese una clave diferente.", "OK", { duration: 7000 });
            }
          },
          error => {
            console.log("error", error);
            this.snackBar.open("Ha ocurrido un error al guardar un ciclo. La clave ingresada ya esta registrada, ingrese una clave diferente.", "OK", { duration: 7000 });
          }
        )

      }
      this.programaAcademicoService.invocarMetodoObtenerCiclos();
    });
  }

  public editarCiclo() {
    const dialogEditarCiclo = this.dialog.open(ProgramaAcademicoModalEdicionCicloComponent, {
      data: this.listaCiclos,
      panelClass: "dialogo-ciclo",
      width: '50%',
      disableClose: true
    });
    dialogEditarCiclo.afterClosed().subscribe(data => {
    });
  }

  public borrarCiclo(cicloId: number, clave?: string, nombre?: string) {
    this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    this.cicloSeleccionadoID = cicloId;
    this.claveCiclo = clave;
    this.nombreCiclo = nombre;
  }

  public cerrarModalAdvertencia(borrar: boolean) {
    if (borrar) {
      this.programaAcademicoService.eliminarCiclo(this.cicloSeleccionadoID).subscribe(
        eliminado => {
          if (eliminado) {
            this.cicloList = this.cicloList.filter(ciclo => ciclo.cicloId !== this.cicloSeleccionadoID);
            this.dialogoRefAdver.close();
            this.obtenerCiclosByPrograma();
            this.snackBar.open("Se ha eliminado el ciclo.", "OK", { duration: 5000 });
          } else {
            this.snackBar.open("Ha ocurrido un error al eliminar el ciclo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        },
        error => {
          console.log("error", error);
          this.snackBar.open("Ha ocurrido un error al eliminar el ciclo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      );
    } else {
      this.dialogoRefAdver.close();
    }
  }

  public crearMateria(cicloId: number): void {
    let datos = new Materia();
    datos.materiaId = 0;
    datos.cicloId = cicloId;
    datos.clave = '';
    datos.nombre = '';
    datos.seriacion = '';
    datos.creditos = 0;
    datos.hfe = 0;
    datos.hei = 0;
    datos.th =  0;
    datos.duracion = this.data.semanaCiclo;
    datos.tipoDuracionId = 0;
    datos.tipoInstalacionId = 0;
    datos.tipoMateriaId = 0;
    datos.areaMateriaId = 0;
    datos.tipoImparticionId = 0;
    datos.tipoAccion = AccionEnum.CREAR;
    datos.materiaOferta = [];
    datos.materiasEnviar= this.materiasEnviar;
    datos.descripcion = '';
    datos.imagenMateria = null;
    const dialogo = this.dialog.open(ProgramaAcademicoModalComponent, {
      data: datos,
      panelClass: "dialogo-ciclo",
      width: '50%',
      height: '85vh',
      maxHeight: '85vh',
      disableClose: true
    });

    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.materia = result;
      }
    })
  }

  public editarMateria(elemento?: Materia): void {
    let arrayMateriasFilter = this.materiasEnviar.filter(mat => mat.materiaId !== elemento.materiaId);
    let datos = new Materia();
      datos.materiaId = elemento.materiaId;
      datos.cicloId=  elemento.cicloId;
      datos.clave = elemento.clave;
      datos.nombre = elemento.nombre;
      datos.seriacion = elemento.seriacion;
      datos.creditos = elemento.creditos;
      datos.hfe = elemento.hfe;
      datos.hei = elemento.hei;
      datos.th = elemento.th;
      datos.duracion = elemento.duracion;
      datos.tipoDuracionId = elemento.tipoDuracionId;
      datos.tipoInstalacionId = elemento.tipoInstalacionId;
      datos.tipoMateriaId = elemento.tipoMateriaId;
      datos.areaMateriaId = elemento.areaMateriaId;
      datos.tipoImparticionId = elemento.tipoImparticionId;
      datos.tipoAccion = AccionEnum.EDITAR;
      datos.materiaOferta = elemento.materiaOferta;
      datos.materiasEnviar = arrayMateriasFilter;
      datos.seriacionMateria = elemento.seriacionMateria;
      datos.descripcion = elemento.descripcion;
      datos.imagenMateria = elemento.imagenMateria;
    const dialogo = this.dialog.open(ProgramaAcademicoModalComponent, {
      data: datos,
      panelClass: "dialogo-ciclo",
      width: '50%',
      height: '85vh',
      maxHeight: '85vh',
      disableClose: true
    });
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.materia = result;
      }
    })
  }

  BorrarCiclos(){
    console.log(this.listaCiclos);
    const dialogo = this.dialog.open(ProgramaEliminarCiclosComponent, {
      data: this.listaCiclos,
      panelClass: "dialogo-eliminar-ciclos",
      width: '50%',
      disableClose: true
    });
    dialogo.afterClosed().subscribe(result => {
      if(!!result){

      }
    });
  }

  public borrarMultiplesMaterias(btnDataTable: boolean, elemento?: any): void {
    console.log(this.listaCiclos);
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogo = this.dialog.open(ProgramaEliminarMateriasComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.obtenerCiclosByPrograma();
      }
    });
  }

  public imprimir(){
    const printContent:HTMLElement = document.getElementById("contenedor-ciclos");
    let cicloNombre = this.data.nombre;
    this.imprimirService.Imprimir(printContent, cicloNombre);
  }

  public borrarMateria(materiaSeleccionada: Materia) {
    this.dialogoRefAdverMateria = this.dialog.open(this.dialogAdverteniaMateria, { disableClose: true });
    this.materiaSeleccionadaID = materiaSeleccionada.materiaId;
    this.cicloSeleccionadoID = materiaSeleccionada.cicloId;
    this.materiaClave = materiaSeleccionada.clave;
    this.materiaNombre = materiaSeleccionada.nombre;
  }

  public cerrarModalAdvertenciaMateria(borrar: boolean) {
    if (borrar) {
      this.materiaService.eliminarMateria(this.materiaSeleccionadaID).subscribe(
        eliminado => {
          if (eliminado) {
            this.obtenerCiclosByPrograma();
            this.dialogoRefAdverMateria.close();
            this.snackBar.open("Se ha eliminado la materia.", "OK", { duration: 5000 });
          } else {
            this.snackBar.open("Ha ocurrido un error al eliminar el ciclo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        },
        error => {
          console.log("error", error);
          this.snackBar.open("Ha ocurrido un error al eliminar el ciclo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      );
    } else {
      this.dialogoRefAdverMateria.close();
    }
  }

  // Ciclo

  isAllSelectedCiclo(cicloId?: number, rowCicloId?: number, i?: number) {
    const numSelectedCiclo = this.selectionCiclo[i]?.selected.length;
    const numRowsCiclo = this.dataSourceMateria.data.length;
    let materiaFiltrada = this.dataSourceMateria.data.filter(mat => mat.cicloId === cicloId && mat.cicloId === rowCicloId);
    let numRowMatFiltrada = materiaFiltrada.length;
    return numSelectedCiclo === numRowMatFiltrada;
  }

  masterToggleCiclo(cicloId?: number, rowCicloId?: number, i?: number) {
    this.isAllSelectedCiclo(cicloId, rowCicloId, i) ? this.selectionCiclo[i].clear() : this.dataSourceCiclo.data.forEach(row => this.selectionCiclo[i].select(row));
  }

  checkboxLabelCiclo (row?: any, i?: number): string {
    this.numCicloSelect = this.selectionCiclo[i].selected.length;
    return `${this.selectionCiclo[i].isSelected(row) ? 'deselect' : 'select'} row ${row.cicloId + 1}`;
  }
}
