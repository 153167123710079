import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categorias-cursos',
  templateUrl: './categorias-cursos.component.html',
  styleUrls: ['./categorias-cursos.component.scss']
})
export class CategoriasCursosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
