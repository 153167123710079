
export class OCUPATION_INFORMATION_MODEL  {
  
    usuarioId           : number;
    tipoEmpresaId       : number;
    tipoPuestoEmpresaId : number;

    constructor(
        usuarioId           : number,
        tipoEmpresaId       : number,
        tipoPuestoEmpresaId : number,
    ){
        this.usuarioId              =   usuarioId           ;
        this.tipoEmpresaId          =   tipoEmpresaId      ;
        this.tipoPuestoEmpresaId    =   tipoPuestoEmpresaId;
    }
   
};