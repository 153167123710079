import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Empresa } from '../_models/empresa';
import { TipoEmpresa } from '../_models/tipo-empresa';
import { TipoPuestoEmpresa } from '../_models/tipo-puesto-empresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private httpClient: HttpClient) { }
  //Get Catalogo Empresa
  public obtenerEmpresa(): Observable<Empresa[]> {
    return this.httpClient.get<Empresa[]>(`${environment.enves[baseUrl].apiUrl}/Empresa`);
  }

  //Get Catalogo Empresa
  public obtenerTipoEmpresa(): Observable<TipoEmpresa[]> {
    return this.httpClient.get<TipoEmpresa[]>(`${environment.enves[baseUrl].apiUrl}/Empresa/GetTipoEmpresa`);
  }

  //Get Catalogo Empresa
  public obtenerTipoPuestoEmpresa(): Observable<TipoPuestoEmpresa[]> {
    return this.httpClient.get<TipoPuestoEmpresa[]>(`${environment.enves[baseUrl].apiUrl}/Empresa/GetTipoPuestoEmpresa`);
  }
}
