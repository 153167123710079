import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { BlockLabelService } from 'src/app/_services/block-label.service';
import { BlockLabel } from '../../../_models/blockLabel';

@Component({
  selector: 'app-oferta-academica-nombre-bloque-modal-edicion',
  templateUrl: './oferta-academica-nombre-bloque-modal-edicion.component.html',
  styleUrls: ['./oferta-academica-nombre-bloque-modal-edicion.component.scss']
})
export class OfertaAcademicaNombreBloqueModalEdicionComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public edicionForm: FormGroup;
  public dialogoRefAdver: any;
  public boolCambio = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: BlockLabel, private snackService: SnackService,
    private dialog: MatDialog,
    public blockLabelService: BlockLabelService,
    public dialogRef: MatDialogRef<OfertaAcademicaNombreBloqueModalEdicionComponent>) { }

  ngOnInit(): void {
    this.inicializarForm();
  }




  public inicializarForm() {
    this.edicionForm = new FormGroup({
      blockLabelId: new FormControl(this.data?.blockLabelId),
      code: new FormControl(this.data?.code, [Validators.required]),
      name: new FormControl(this.data?.name, [Validators.required]),
      status: new FormControl(this.data?.status, [Validators.required]),
      capacity: new FormControl(this.data?.capacity, [Validators.required, Validators.pattern(/^[1-9]+/)])
    });
    this.edicionForm.valueChanges.subscribe((cambios) => {
      this.boolCambio = true;
    });

  }

  get name() { return this.edicionForm.get('name'); }
  get status() { return this.edicionForm.get('status'); }
  get code() { return this.edicionForm.get('code'); }
  get capacity() { return this.edicionForm.get('capacity'); }
  get blockLabelId() { return this.edicionForm.get('blockLabelId'); }



  public enviar() {
    switch (this.data.tipoAccion) {
      case AccionEnum.CREAR:
        this.data = Object.assign(this.edicionForm.value);
        this.blockLabelService.agregarNombreBloque(this.data).subscribe(
          (response) => {
            if (response.success) {
              this.blockLabelService.invocarMetodoObtenerNombreBloque();
              this.snackService.mostrarSnackBack(response.message);
              this.boolCambio = false;
              this.inicializarForm();
              this.cambiarVistaEditar(response.data);
            } else {
              this.snackService.mostrarSnackBack(response.message);
              this.data.tipoAccion = 3;
            }
          }, error => {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la categoría de solicitud. Por favor, intente de nuevo más tarde.');
          }
        );
        break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionForm.value);
        this.data.tipoAccion = 2;
        this.blockLabelService.modificarNombreBloque(this.data).subscribe(
          (response) => {
            if (response.success) {
              this.blockLabelService.invocarMetodoObtenerNombreBloque();
              this.snackService.mostrarSnackBack('Se ha modificado la información.');
              this.boolCambio = false;
            } else {
              this.snackService.mostrarSnackBack(response.message);
            }
          }, error => {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al modificar el tipo de solicitud. Por favor, intente de nuevo más tarde.');
          }
        );
        break;
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }


  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cambiarVistaEditar(blockLabelId) {
    this.data = Object.assign(this.edicionForm.value);
    this.blockLabelId.setValue(blockLabelId);
    this.data.tipoAccion = 2;
    this.boolCambio = false;
  }




}
