import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment, baseUrl} from '@environments/environment';
import { Entry } from '../_models'
import { ApiResponse } from '../models/api/ApiRespose.model';

@Injectable({
  providedIn: 'root'
})
export class LeadEntryService {
  invocarObtenerEntry = new EventEmitter();
  invMetodoObtenerEntrySubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo para obtener los registros del catalogo LeadEntry
   * @returns 
   */
  public getEntrys() : Observable<ApiResponse<Array<Entry>>> {
    return this.httpClient.get<ApiResponse<Array<Entry>>>(`${environment.enves[baseUrl].apiUrl}/LeadEntry`);
  }

  /**
   * Metodo para añadir un nuevo registro al catalogo
   * @param entry 
   * @returns 
   */
  public addEntrys(entry: Entry): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/LeadEntry`, entry);
  }

  /**
   * Metodo para editar un registro ya guardado
   * @param entry 
   * @returns 
   */
  public editEntry(entry: Entry): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/LeadEntry`, entry);
  }

  /**
   * Metodo para eliminar uno o mas registros del catalogo
   * @param sourceId 
   * @returns 
   */
  public deleteEntry(sourceId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.enves[baseUrl].apiUrl}/LeadEntry?sourceId=${sourceId}`);
  }

  /**
   * Funcion para actualizar tabla principal despues de guardar, editar y eliminar los datos 
   */
  invocarMetodoObtenerEntry() {
    this.invocarObtenerEntry.emit();
  }
}
