import { I18NextPipe } from 'angular-i18next';
import { EnsenanzaAprendizajeAlumnoService } from './../../_services/ensenanzaAprendizajeAlumno.service';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { ConsultaCalParcialDTO } from './../../_models/ConsultaCalParcialDTO';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { SeguimientoAcademicoAlumnoService } from './../../_services/seguimiento-academico-alumno.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiResponse } from '../../models/api/ApiRespose.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlumnoGrupoParcial } from '../../_models/ensenanzaAprendizajeAlumno';

@Component({
  selector: 'app-consultar-cal-seguimiento-acadenico-alumno',
  templateUrl: './consultar-cal-seguimiento-acadenico-alumno.component.html',
  styleUrls: ['./consultar-cal-seguimiento-acadenico-alumno.component.scss']
})
export class ConsultarCalSeguimientoAcadenicoAlumnoComponent implements  OnInit, OnDestroy {

  //#region Constructor
  constructor(
    private _SeguimientoAcademicoAlumnoService: SeguimientoAcademicoAlumnoService,
    private ensenanzaApredServ: EnsenanzaAprendizajeService,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    public util: UtileriasService,
    private i18nextPipe: I18NextPipe,
    @Inject(MAT_DIALOG_DATA) public data: any,//{alumnoId: number, grupoId:number, nombre: string},
    public dialogRef: MatDialogRef<ConsultarCalSeguimientoAcadenicoAlumnoComponent>,
    ) {
      this.datosInput = data;
      this.alumnoId = data.datosPersonales.alumnoId;
      this.grupoId = data.contenidoCurso.grupoId;
      this.nombre = data.datosPersonales.nombre;
     }

  ngOnInit(): void {
    this.GetCalificacionesSegAcaAlumno();
    // this.cargarDatosGrupo();
    // this.cargarCalificaciones();
  }
  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  public datosInput: any;
  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Propiedades
   public ConsultaCalParcial: ConsultaCalParcialDTO[];

   expandir: boolean = false;
   indexRecurso = -1;
   recursoSelect;
   flagButtonDescarga: boolean = false;
   expandirModulo: boolean = false;
   datosGrupo: any;
   puntosBaseParcial: number;
   existeParcialFinal: boolean = false;
   public btnElemento: string = '';
   indiceModulo: number;
   indiceRecurso: number;
   public ultimoParcial: number;
   public AlumnoGrupoParcial : AlumnoGrupoParcial[];
   public alumnoId: number;
   public grupoId: number;
   public nombre: string;
   public CalificacionFinal: number = 0;
   public TieneCalificacionFinal: boolean;
   //#endregion

 //#region Metodos
 public GetCalificacionesSegAcaAlumno():void{
  this.Subscription.add(
    this._SeguimientoAcademicoAlumnoService.GetCalificacionesSegAcaAlumno(this.alumnoId, this.grupoId).subscribe(
      (respuesta: ApiResponse<ConsultaCalParcialDTO[]>) => {
        if (respuesta.success) {
          this.ConsultaCalParcial = respuesta.data;


          this.CalificacionFinal = this.ConsultaCalParcial[this.ConsultaCalParcial.length - 1].calificacionFinal;


          if (this.CalificacionFinal != 0 && this.CalificacionFinal != null && this.CalificacionFinal != undefined) {
            this.TieneCalificacionFinal = true;
          } else {
            this.TieneCalificacionFinal = false;
          }
          //this.ConsultaCalParcial.pop();
          } else {
          console.log(respuesta.message);
          }
      }
    )
  );
}

public cargarDatosGrupo() {
  this.ensenanzaApredServ.getPonderacionGrupo(this.grupoId).subscribe((grupo: ApiResponse<any>) => {
    this.datosGrupo = grupo.data;
    this.puntosBaseParcial = this.datosGrupo.materiaOferta.materia.ciclo.programa.escalaCalificacionNavigation.calificacionNoNumerica[0].valorNumerico;
  })
}

public cargarCalificaciones(){
  this.EnsenanzaAprendizajeAlumnoService.obtenerCalificaciones(this.alumnoId, this.grupoId).subscribe(
    (cal: ApiResponse<AlumnoGrupoParcial[]>)=>{
      this.AlumnoGrupoParcial = cal.data;
  })
}

private translate(text: string) {
  return this.i18nextPipe.transform(text, { format: 'cap' });
}
//#endregion

  //#region  Mat Expanded
  public expandirTema(elemento, indiceModulo, expande) {
    this.btnElemento = elemento;
    this.indiceModulo = indiceModulo;
    this.expandir = expande;
  }

  public expandirRecurso(elemento, indiceModulo, index, expande, recurso) {
    this.btnElemento = elemento;
    this.indiceModulo = indiceModulo;
    this.indiceRecurso = index;
    this.indexRecurso = index;
    this.expandir = expande;
    if (this.expandir) {
      this.recursoSelect = recurso.recurso[index];
      this.recursoSelect.parcialID = recurso.parcial;
    }
    this.flagButtonDescarga = false;
  }

  ubicarBoton(elemento: string) {
    this.btnElemento = elemento;
  }
  //#endregion
  public CerrarModal():void {
    this.dialogRef.close();
  }
}
