import { BlockGroup } from './blockGroup';
import { Campus } from './campus';
import { Periodo } from './periodo';
import { BlockStudent } from './blockStudent';
import { BlockLabel } from './blockLabel';
export class Block {
    blockId?: number;
    institucionId?: string;
    periodoId: number;
    campusId: number;
    blockLabelId: number;
    clavePeriodo?: string;
    numeroGrupos?: number;
    disponibles?: number;
    periodo?: Periodo;
    campus?: Campus;
    blockLabel?: BlockLabel;
    blockGroup?: Array<BlockGroup>;
    blockStudent?: Array<BlockStudent>;

    //#region AsignarBloques
    bloqueNombre?: string
    capacidad?: number;
    periodoClave?: string;
    periodoNombre?: string;
    campusNombre?: string;
    //#endregion
    gruposId?: Array<number> = [];
    alumnosId?: Array<number> = [];

    materiaOfertaId?: number;
}
