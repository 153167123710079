import { Campus } from './campus';
import { Deserializable } from './deserializable';
import { FunnelStage } from './funnelStage';
import { Institucion } from './institucion';
import { Periodo } from './periodo';
import { Programa } from './programa';

export class Lead implements Deserializable {
  leadId: number;
  institutionId: string;
  leadStageId: number;
  name: string;
  middleName: string;
  lastname: string;
  lastnameSecondary: string;
  title: string;
  birthDate: Date;
  email: string;
  altEmail: string;
  mobile: string;
  phone: string;
  source: string;
  qualification: number;
  comments: string;
  programId: number;
  termId: number;
  campusId: number;
  creationDate: Date;
  modificationDate: Date;
  campus: Campus;
  Institution: Institucion;
  leadStage: FunnelStage;
  program: Programa;
  term: Periodo;

  constructor() {
    this.leadId = null;
    this.institutionId = null;
    this.leadStageId = null;
    this.name = null;
    this.middleName = null;
    this.lastname = null;
    this.lastnameSecondary = null;
    this.title = null;
    this.birthDate = null;
    this.email = null;
    this.altEmail = null;
    this.mobile = null;
    this.phone = null;
    this.source = null;
    this.qualification = null;
    this.comments = null;
    this.programId = null;
    this.termId = null;
    this.campusId = null;
    this.creationDate = null;
    this.modificationDate = null;
    this.campus = null;
    this.Institution = null;
    this.leadStage = null;
    this.program = null;
    this.term = null;
  }

  deserialize(input: any): this {
    Object.keys(input).forEach((key) => {
      input[key] = input[key] ? input[key] : null;
    });
    Object.assign(this, input);
    return this;
  }
}


export class LeadImportDto {
  leadId: number;
	title: string;
  name: string;
  middleName: string
  lastName: string;
	secondLastName: string;
	birthDate: Date;
	email: string;
	phoneNumber: string;
	street: string;
	number: string;
	internalNumber: string;
	neighborhood: string;
	stateId: number;
	country: string;
	zipCode: string;
	source: string;
  parentName1: string;
	parentMiddleName1: string;
	parentLastName1: string;
	parentSecondLastName1: string;
	parentEmail1: string;
	parentPhoneNumber1: string;
	parentName2: string;
	parentMiddleName2: string;
	parentLastName2: string;
	parentSecondLastName2: string;
	parentEmail2: string;
	parentPhoneNumber2: string;
	parentName3: string;
  parentMiddleName3: string;
	parentLastName3: string;
	parentSecondLastName3: string;
	parentEmail3: string;
	parentPhoneNumber3: string;
  term: string;
	campus: string;
	level: string;
	program: string;
}