import { ContenidoParcial } from './../../../_models/ensenanza-aprendizaje-profesor';
import { I18NextPipe } from 'angular-i18next';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { InfoPorRevisarDto, InformacionGrupoAsistencia } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { TipoRecurso } from 'src/app/_models/tipo-recurso';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { ContenidoConsultarCorreoComponent } from './contenido-consultar-correo/contenido-consultar-correo.component'
@Component({
  selector: 'app-contenido-consultar',
  templateUrl: './contenido-consultar.component.html',
  styleUrls: ['./contenido-consultar.component.scss']
})
export class ContenidoConsultarComponent implements OnInit {
  @Input() grupoID: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tipoConsulta: number;
  fechasSesiones: any[];
  fechaSeleccionada: Date;
  fechaSeleccionadaMostrar: string;
  public indice: number;
  public indiceMaximo: number;
  public filtroForm: FormGroup;
  listaProgramas: any[];
  listaCiclos: any[];
  public listaParciales: any;
  public listaTipoRecurso: TipoRecurso[] = [];
  public infoCargada = false;
  public informacionGrupo: InformacionGrupoAsistencia;
  public informacionRecursos: InfoPorRevisarDto[] = [];
  dataSourceAsistencia: MatTableDataSource<any>;
  displayedColumnsAsistencia: string[] = ['select', 'Asistencia', 'ID', 'Nombre', 'Programa academico', 'Ciclo', 'ProcesoGraduacion'];
  dataSourceCalificaciones: MatTableDataSource<any>;
  displayedColumnsCalificaciones: string[] = ['parcial', 'tipoRecurso', 'titulo', 'puntuacion', 'fechaLimite', 'revisados', 'porRevisar', 'sinEntregar'];
  selection = new SelectionModel<any>(true, []);
  public datafilter: any;
  public numIngresoSeleccionado: number = 0;
  public arregloFiltroModificado: any[] = [];
  public arregloFiltroOriginal: any[];
  public cicloSeleccionado: number = 0;
  public programaSeleccionado: number = 0;
  public contenidoPorConsultar: boolean = true;
  public fechasDisponibles: boolean = false;
  public recursoSelccionado: any;
  public ultimoParcial: number = 0;
  CampusId = new FormControl();
  ProgramaId = new FormControl();
  ciclosF = new FormControl();
  existeParcialFinal: boolean = false;
  contenido: ContenidoParcial[] = [];
  contenidoGrupoParcial: any[] = [];

  constructor(private ensenanzaApredServ: EnsenanzaAprendizajeService,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    public utileriaService: UtileriasService,
    private dialog: MatDialog,
    private i18nextPipe: I18NextPipe) { }

  ngOnInit(): void {
    this.tipoConsulta = 0;
    this.inicializarForm();
    this.ObtenerFechasSesiones();




    if (this.ensenanzaApredServ.invocarObtenerPorRevisarSubscription == undefined) {
      this.ensenanzaApredServ.invocarObtenerPorRevisarSubscription = this.ensenanzaApredServ.invocarObtenerPorRevisar.subscribe(() => {
        this.informacionRecursos.length = 0;
        this.obtenerInformacionRecursos(this.grupoID);
      });
      this.ensenanzaApredServ.invocarObtenerPorRevisarSubscription = null;

    }
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      idnombre: new FormControl(),
      programa: new FormControl(0),
      ciclos: new FormControl(0),
      parcial: new FormControl(0),
      tipoRecurso: new FormControl(0),
      ciclosF: new FormControl([]),
      ProgramaId: new FormControl([]),
    });
    this.ciclosF.setValue(["-1"]);
    this.ProgramaId.setValue(["-1"]);
  }

  public ObtenerFechasSesiones() {
    this.ensenanzaApredServ.ObtenerFechaSesiones(this.grupoID).subscribe(response => {
      if (response.exito) {
        this.fechasSesiones = response.data;
        this.fechaSeleccionada = this.fechasSesiones[0];
        let fecha = new Date(this.fechaSeleccionada);
        this.fechaSeleccionadaMostrar = this.getFormattedDate(fecha, 'es-ES');
        this.indice = 0;
        this.indiceMaximo = this.fechasSesiones.length - 1;
        this.fechasDisponibles = true
        this.ObtenerAsistenciaAlumnos();
      } else {
        this.fechasDisponibles = false;
      }
    })
  }

  public LimpiarFiltros(tipoConsulta) {
    if (tipoConsulta == 0) {
      this.inicializarForm();
      this.dataSourceAsistencia = new MatTableDataSource(this.arregloFiltroOriginal);
      this.dataSourceAsistencia.paginator = this.paginator;
    }
    if (tipoConsulta == 1) {
      this.inicializarForm();
      this.dataSourceCalificaciones = new MatTableDataSource(this.datafilter);
      this.dataSourceCalificaciones.paginator = this.paginator;
    }
  }

  public ObtenerAsistenciaAlumnos() {
    this.ensenanzaApredServ.ObtenerGrupoAsistenciaAlumno(this.grupoID, this.fechaSeleccionada).subscribe(response => {
      if (response.exito) {
        this.dataSourceAsistencia = new MatTableDataSource(response.data);
        this.arregloFiltroOriginal = response.data;
        this.dataSourceAsistencia.paginator = this.paginator;
        this.datafilter = response.data;
        this.listaProgramas = response.dataPrograma;
        this.listaCiclos = response.dataCiclos;
      }
    })
  }

  public vaciarArregloModificado() {
    this.arregloFiltroModificado.length = 0;
  }

  public filtrarAsistencia() {
    var ciclos = this.ciclosF.value.filter(x => x != "-1");
    var alumno = this.filtroForm['value'].idnombre;
    var programa = this.ProgramaId.value.filter(x => x != "-1");
    var filtro: any;
    if (ciclos.length == 0 && programa.length == 0) {
      this.vaciarArregloModificado();
    }

    if (this.arregloFiltroModificado.length == 0 || this.cicloSeleccionado != 0
      || this.programaSeleccionado != 0) {
      this.arregloFiltroModificado = this.arregloFiltroOriginal;
      filtro = this.arregloFiltroModificado;
    }

    //Condicion si el filtro de periodos tiene valores
    if (ciclos.length > 0) {
      filtro = this.arregloFiltroModificado.filter(row => ciclos.includes(row.cicloId));
      this.arregloFiltroModificado = this.arregloFiltroModificado.filter(row => ciclos.includes(row.cicloId));
      this.cicloSeleccionado = ciclos.length;
    }

    if (programa.length > 0) {
      filtro = this.arregloFiltroModificado.filter(row => programa.includes(row.programaId));
      this.arregloFiltroModificado = this.arregloFiltroModificado.filter(row => programa.includes(row.programaId));
      this.programaSeleccionado = programa.length;
    }

    if (alumno != null) {
      filtro = this.arregloFiltroModificado.filter(data => {
        var nombre = data.nombreCompleto;
        return nombre.toLowerCase().indexOf(alumno.toLowerCase()) > -1 || data.usuarioId.toString().indexOf(alumno) > -1;
      })

    }
    this.dataSourceAsistencia = new MatTableDataSource(filtro);
    this.dataSourceAsistencia.paginator = this.paginator;
    this.selection = new SelectionModel<any>(true, []);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase();
    this.dataSourceAsistencia.filter = filterValue;
    this.dataSourceAsistencia.filterPredicate = function (data, filter: string): boolean {
      var nombre = data.nombreCompleto
      return nombre.toLowerCase().includes(filter) || data.identificador.includes(filter);
    };
  }

  public getFormattedDate(d: Date, lang) {
    return d.getDate().toLocaleString(lang) + ' '
      + d.toLocaleString(lang, { month: 'long' }) + ', '
      + d.toLocaleString(lang, { year: 'numeric' })
      ;
  }

  public ultimaFecha() {
    this.fechaSeleccionada = this.fechasSesiones[0];
    let fecha = new Date(this.fechaSeleccionada);
    this.fechaSeleccionadaMostrar = this.getFormattedDate(fecha, 'es-ES');
    this.indice = 0;
    this.ObtenerAsistenciaAlumnos();
  }

  public anteriorFecha() {
    this.fechaSeleccionada = this.fechasSesiones[this.indice + 1];
    let fecha = new Date(this.fechaSeleccionada);
    this.fechaSeleccionadaMostrar = this.getFormattedDate(fecha, 'es-ES');
    this.indice = this.indice + 1;
    this.ObtenerAsistenciaAlumnos();
  }

  public siguienteFecha() {
    this.fechaSeleccionada = this.fechasSesiones[this.indice - 1];
    let fecha = new Date(this.fechaSeleccionada);
    this.fechaSeleccionadaMostrar = this.getFormattedDate(fecha, 'es-ES');
    this.indice = this.indice - 1;
    this.ObtenerAsistenciaAlumnos();
  }

  public exportExcelCalificacion() {
    if(this.existeParcialFinal){
      this.ExcelSubscription = this.ensenanzaApredServ.descargarExcelConsultarCalificaciones(this.ultimoParcial,this.datafilter).subscribe((res: Blob) => {
        let filename: string = "Consultar_Calificacion.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, filename);
        } else {
          let a: HTMLAnchorElement = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          let blob: Blob = new Blob([res], { type: 'application/xlsx' });
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        }
        this.ExcelSubscription.unsubscribe();
      });
    }else{
      this.ExcelSubscription = this.ensenanzaApredServ.descargarExcelConsultarCalificaciones(0,this.datafilter).subscribe((res: Blob) => {
        var filename = "Consultar_Calificacion.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, filename);
        } else {
          var a = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          var blob = new Blob([res], { type: 'application/xlsx' });
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        }
        this.ExcelSubscription.unsubscribe();
      });
    }
  }

  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    this.dialog.open(ContenidoConsultarCorreoComponent, { data: datos, disableClose: true });
  }

  ExcelSubscription: Subscription;
  public exportExcelAsistencia(): void {
    this.ExcelSubscription = this.ensenanzaApredServ.descargarExcelAsistencia(this.arregloFiltroOriginal).subscribe((res: Blob) => {
      var filename = "Consultar_Asistencia.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.AlumnoId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.datafilter.forEach(row => this.selection.select(row));
  }
  isAllSelected() {
    if (this.datafilter) {
      this.numIngresoSeleccionado = this.selection.selected.length;
      const numRows = this.datafilter.length;
      return this.numIngresoSeleccionado === numRows;
    }
  }

  public obtenerInformacionRecursos(grupoId: number): void {
    this.ensenanzaApredServ.obtenerInformacionRecursos(grupoId).subscribe(
      (infoRecursos: ApiResponse<InfoPorRevisarDto[]>) => {
        if (infoRecursos.data !== null) {
          this.informacionRecursos = infoRecursos.data;
          this.dataSourceCalificaciones = new MatTableDataSource(infoRecursos.data);
          this.dataSourceCalificaciones.paginator = this.paginator;
          this.selection = new SelectionModel<any>(true, []);
          this.datafilter = infoRecursos.data;
          this.obtenerInfoGrupo(grupoId);
        } else {

        }
      });
  }

  public obtenerInfoGrupo(grupoId: number): void {
    this.ensenanzaApredServ.obtenerInfoGrupoAsistencia(grupoId).subscribe(
      (info: InformacionGrupoAsistencia) => {
        if (info !== null) {
          this.informacionGrupo = info;
          this.cargarContenido();
        } else {

        }
      });
  }

  //#region  MultiLenguaje

  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

//#endregion
  //#region  Cargar contenidos
  public cargarContenido() {
    this.ensenanzaApredServ.obtenerContenido(this.grupoID).subscribe((res: ApiResponse<any>) => {
      if (res.data) {
        this.contenidoGrupoParcial = res.data.grupoParcial;
        this.cargarParciales(res.data.clave);
      }
    })
  }
  //#endregion
  public cargarParciales(clave: string) {
    this.contenido.length = 0;
    this.ensenanzaApredServ.obtenerParcialesProfesor(clave, this.grupoID).subscribe(parciales => {
      if (parciales.exito) {
        if(parciales.dataParcialFinal){
          this.existeParcialFinal = true;
        }

        parciales.dataParciales.forEach(parcial => {

          let parcialText = '_tableroProfesor.parcial';
          if (!!this.existeParcialFinal && parcial == parciales.dataParciales.length) {
            parcialText = '_tableroProfesor.parcial-final';
          };

          if (this.contenidoGrupoParcial.length > 0) {
            let nuevoParcial = this.contenidoGrupoParcial.find(object => {
              return object.parcial == parcial
            });
            if (nuevoParcial) {
              let parcialobj = {
                parcial: nuevoParcial.parcial,
                ponderacion: nuevoParcial.ponderacion,
                nombre: nuevoParcial.nombre,
              }
              if (!parcialobj?.nombre) {
                parcialobj.nombre = this.translate(parcialText)// + ' ' + parcial;
              }
              this.contenido.push(parcialobj);
            }else{
              let _parcialObj = {
                parcial: parcial,
                ponderacion: 0,
                nombre: this.translate(parcialText)// + ' ' + parcial
              }
              this.contenido.push(_parcialObj);
            }
          }
          else {
            let _parcialObj = {
              parcial: parcial,
              ponderacion: 0,
              nombre: this.translate(parcialText) + ' ' + parcial
            }
            this.contenido.push(_parcialObj);
          }
        });


        this.obtenerTipoRecurso();
      }
    });
  }

  public filtrarCalificaciones() {
    var parcial = this.filtroForm['value'].parcial;
    var tipoRecurso = this.filtroForm['value'].tipoRecurso;
    let filtroModificado: any = [];
    filtroModificado = this.informacionRecursos;
    if (parcial != 0) {
      filtroModificado = filtroModificado.filter(row => row.parcial === parcial);
    }
    if (tipoRecurso != 0) {
      filtroModificado = filtroModificado.filter(row => row.tipoRecursoId === tipoRecurso);
    }
    this.dataSourceCalificaciones = new MatTableDataSource(filtroModificado);
    this.dataSourceCalificaciones.paginator = this.paginator;
  }

  public obtenerTipoRecurso() {
    this.ensenanzaApredServ.listaRecursos().subscribe((tipoRecurso: ApiResponse<TipoRecurso[]>) => {
      let tipoRecursos: TipoRecurso[] = tipoRecurso.data.filter(x => x.nombre !== 'Contenido');
      this.listaTipoRecurso = tipoRecursos;
      this.infoCargada = true;
    });
  }

  cargarConsultar() {
    if (this.tipoConsulta == 0) {
      this.inicializarForm();
      this.ObtenerFechasSesiones();
      this.contenidoPorConsultar = true;
    }
    else {
      this.obtenerInformacionRecursos(this.grupoID);
    }
  }

  public abrirDetalleRecurso(elemento: any) {
    this.contenidoPorConsultar = false;
    this.recursoSelccionado = elemento;
  }

  public abrirRecursos(grupoId: number) {
    this.contenidoPorConsultar = true;
    this.obtenerInformacionRecursos(grupoId);
  }
}
