import { UsuarioService } from './../_services/usuario.service';
import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Usuario } from '@app/_models/usuario';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public NombreCompleto: string = "";

  constructor(
    private _UsuarioService: UsuarioService,
    private authenticationService: MsalService,
  ) {
    /* let cuenta = this.authenticationService.instance.getActiveAccount();
    this.NombreCompleto = "";
    if (!!cuenta) {
      this.NombreCompleto = cuenta.name;
    } */
  }

  ngOnInit(): void {
    this.Me();
  }


  private async Me()
  {
    var _Usuario = await this._UsuarioService.Me();
    this.NombreCompleto = `${_Usuario.nombre ?? ""} ${_Usuario.segundoNombre ?? ""} ${_Usuario.apellidoPaterno ?? ""} ${_Usuario.apellidoMaterno ?? ""}`;
  }

}
