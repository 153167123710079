import { Component, OnInit, ViewChild } from '@angular/core';
import { UtileriasService } from '@app/_services/utilerias.service';
import { PeriodoService } from '@app/_services/periodo.service';
import { PeriodoEdicionComponent } from './periodo-edicion/periodo-edicion.component';
import { PeriodoEliminarComponent } from './periodo-eliminar/periodo-eliminar.component';
import { Periodo } from '@app/_models/periodo';
import { AccionEnum } from '../_models/accion.enum';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { TipoPeriodo } from '../_models/tipo-periodo';
import { TipoPeriodoService } from '../_services/tipo-periodo.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-periodo',
  templateUrl: './periodo.component.html',
  styleUrls: ['./periodo.component.scss']
})
export class PeriodoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allTipoPeriodo') private allTipoPeriodo: MatOption;
  displayedColumns: string[] = ['select', 'Clave', 'Nombre', 'Tipo', 'Fecha inicio', 'Fecha fin'];
  public dataSource = new MatTableDataSource<Periodo>();
  selection = new SelectionModel<Periodo>(true, []);

  public periodo: Periodo;
  public periodos: Periodo[] = [];
  public tipoPeriodoList: TipoPeriodo[] = [];
  private subcripcionTipoPeriodo: Subscription;
  public infoCargado: boolean = false;
  public numPeriodoSeleccionado: number = 0;
  public tipoList: TipoPeriodo[] = [];
  private arrOriginal: any[] = [];
  public datafilter: any;
  public filtroForm: FormGroup;
  public listaPeriodoaActual: Periodo[];
  public claveNombre = new FormControl();
  public tipoPeriodoForm = new FormControl();

  constructor(public util: UtileriasService,
    private periodoService: PeriodoService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    private tipoPeriodoService: TipoPeriodoService) { }

  ngOnInit() {
    if (this.periodoService.invMetodoObtenerPeriodosSubscription === undefined) {
      this.periodoService.invMetodoObtenerPeriodosSubscription = this.periodoService.invocarObtenerPeriodos.subscribe(() => {
        this.obtenerPeriodos();
      });
    }
    this.LlenarFiltros();
    this.obtenerPeriodos();
    this.getTipoPeriodo();
    this.getTipo();
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  public obtenerPeriodos() {
    this.periodoService.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        this.arrOriginal = [...periodos];
        this.datafilter = periodos;
        this.dataSource = new MatTableDataSource(periodos);
        this.selection = new SelectionModel<any>(true, []);
        this.dataSource.paginator = this.paginator;
        this.infoCargado = true;
      }
    );
  }

  public getTipoPeriodo() {
    this.subcripcionTipoPeriodo = this.tipoPeriodoService.getTipoPeriodo().subscribe(
      (tiposPeriodos: TipoPeriodo[]) => {
        this.tipoPeriodoList = tiposPeriodos;
        this.infoCargado = true;
      }
    );
  }
  public getTipo() {
    this.subcripcionTipoPeriodo = this.tipoPeriodoService.getTipoPeriodo().subscribe(
      (tiposPeriodos: TipoPeriodo[]) => {
        this.tipoList = tiposPeriodos;
        this.infoCargado = true;
      }
    );
  }

  public findNombreTipoPeriodo(idTipoPeriodo: number) {
    return this.tipoPeriodoList?.find?.(id => id.tipoPeriodoId === idTipoPeriodo).tipoPeriodoId;
  }
  public findTipoPeriodo(idTipoPeriodo: number) {
    return this.tipoList.find(id => id.tipoPeriodoId === idTipoPeriodo).tipoPeriodoId;
  }

  public findNameTipePer(idTipoPeriodo: number) {
    return this.tipoPeriodoList?.find?.(id => id.tipoPeriodoId === idTipoPeriodo).nombre;
  }

  public crear(elemento?: Periodo): void {
    let datos: Periodo = {
      periodoId: 0,
      clave: '',
      tipoPeriodoId: 0,
      nombre: '',
      fechaInicio: null,
      fechaFin: null,
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(PeriodoEdicionComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.periodo = result;
      }
    });
  }

  public editar(elemento?: Periodo): void {
    let datos: Periodo = {
      periodoId: elemento.periodoId,
      clave: elemento.clave,
      tipoPeriodoId: elemento.tipoPeriodoId,
      nombre: elemento.nombre,
      fechaInicio: elemento.fechaInicio,
      fechaFin: elemento.fechaFin,
      tipoAccion: AccionEnum.EDITAR
    }
    const dialogo = this.util.abrirDialogoLateral(PeriodoEdicionComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.periodo = result;
      }
    });
  }

  public borrar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogo = this.util.abrirDialogoLateral(PeriodoEliminarComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.periodo = result;
      }
    });
  }

  public filtrar() {
    let tipo = this.tipoPeriodoForm.value;
    if (tipo !== 0) {
      var filtro = this.arrOriginal.filter(row => row.tipoPeriodoId === tipo)
    } else {
      var filtro = this.arrOriginal;
    }
    this.dataSource = new MatTableDataSource(filtro);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<any>(true, []);
  }
  public filtro(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Filtros llenado. . .
  public filtrado() {
    let nombre: string = this.claveNombre.value == null ? "" : this.claveNombre.value;
    let tipo: any[] = this.tipoPeriodoForm.value == null ? [] : this.tipoPeriodoForm.value;
    let arrFiltrado: any = [];
    arrFiltrado = [...this.arrOriginal];
    if (nombre.length > 0) {
      arrFiltrado = arrFiltrado.filter(el => {
        return el.nombre.toLowerCase().search(nombre.toLowerCase()) > -1 || el.clave.toString().search(nombre) > -1;
      })
    }
    if (tipo.length > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return tipo.indexOf(f.tipoPeriodoId) > -1 });
    }
    this.dataSource = new MatTableDataSource(arrFiltrado);
    this.dataSource.paginator = this.paginator;
  }

  public LlenarFiltros() {
    this.tipoPeriodoService.getTipoPeriodo().subscribe(
      (tiposPeriodos: TipoPeriodo[]) => {
        this.tipoList = tiposPeriodos;
        this.tipoPeriodoForm.setValue([...this.tipoList.map(item => item.tipoPeriodoId), 0]);
        this.filtrado();
      }
    );
  }
  tosslePerOneTipoPeriodo(all) {
    if (this.allTipoPeriodo.selected) {
      this.allTipoPeriodo.deselect();
      return false;
    }
    if (this.tipoPeriodoForm.value.length == this.tipoList.length) { this.allTipoPeriodo.select(); }
    this.filtrado();
  }

  toggleAllSelectionTipPeriodo() {
    if (this.allTipoPeriodo.selected) {
      this.tipoPeriodoForm.patchValue([...this.tipoList.map(item => item.tipoPeriodoId), 0]);
    } else {
      this.tipoPeriodoForm.patchValue([]);
    }
    this.filtrado();
  }
  //FilterDataTable
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    this.numPeriodoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numPeriodoSeleccionado === numRows;
  }

  checkboxLabel(row?: Periodo): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  ngOnDestroy(): void {
    if (this.subcripcionTipoPeriodo) {
      this.subcripcionTipoPeriodo.unsubscribe();
    }
  }

  /**
   * Funcion que descarga un archivo excel con los periodos seleccionados
   */
  ExcelSubscription: Subscription;
  public descargarExcel(elemento?: any){
    if(elemento != null && elemento.length > 0) {
      this.ExcelSubscription = this.periodoService.descargarExcel(elemento).subscribe((res: Blob) => {
        let filename: string = "Periodos.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
          window.navigator.msSaveOrOpenBlob(res, filename);
        } else {
          let a: HTMLAnchorElement = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          let blob: Blob = new Blob([res], { type: 'application/xlsx' });
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        }
        this.ExcelSubscription.unsubscribe();
        });
    } else {
      this.ExcelSubscription = this.periodoService.descargarExcel(this.datafilter).subscribe((res: Blob) => {
        let filename: string = "Periodos.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
          window.navigator.msSaveOrOpenBlob(res, filename);
        } else {
          let a: HTMLAnchorElement = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          let blob: Blob = new Blob([res], { type: 'application/xlsx' });
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        }
        this.ExcelSubscription.unsubscribe();
        });
    }
  }

}
