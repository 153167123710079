import { Router } from '@angular/router';
import { BajaDTO } from './../_models/BajaDTO.model';
import { ReingresoHorarioMateriasComponent } from './reingreso-horario-materias/reingreso-horario-materias.component';
import { TraductorService } from 'src/app/_services/traductor.service';
import { EgresoService } from 'src/app/_services/egreso.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { archivoHelper } from '../helper/ArchivoHelper.Helper';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { ArchivoSeleccionado } from '../models/customEntities/archivoSeleccionado.model';
import { SeleccionDeMateriasDTO } from '../models/Inscripcion/SeleccionDeMateriasDTO.model';
import { ConfirmationDialogServiceService } from '../services/confirmation-dialog-service.service';
import { MatetiasSeleccionadasService } from '../services/matetias-seleccionadas.service';
import { SnackService } from '../services/snack-service.service';
import { AlumnoReingresoDTO } from '../_models/alumnoReingresoDTO.model';
import { ColegiaturaCosto } from '../_models/colegiatura-costo';
import { EnsenanzaAprendizajeService } from '../_services/ensenanza-aprendizaje.service';
import { InscripcionService } from '../_services/inscripcion.service';
import { ReinscripcionService } from '../_services/reinscripcion.service';
import { StepperService } from '../_services/stepper-services.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { GruposDisponiblesParaMateriaDTO } from '../models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { InscripcionCosto } from '../_models/inscripcion-costo';
import { AuthenticationService } from '../_services/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { TipoDePagoService } from '../services/tipo-de-pago.service';
import { ColegiaturaCostoDto } from '../_models/ColegiaturaCosto.model';
import { GrupoDTO } from '../_models/GrupoDTO.model';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { ExisteMateriaSeriada } from '../models/customEntities/existeMateriaSeriada.mode';
import { PagoMateriasComponent } from '../_components/pago-materias/pago-materias.component';
import { Periodo } from '../_models/periodo';
import { FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-reinscripcion',
  templateUrl: './reinscripcion.component.html',
  styleUrls: ['./reinscripcion.component.scss']
})
export class ReinscripcionComponent implements OnInit, OnDestroy {

  //#region Controlador
  constructor(private confirmationDialog: ConfirmationDialogServiceService,
    private stepperService: StepperService,
    private usuarioInfo: EnsenanzaAprendizajeService,
    private reinscripcion: ReinscripcionService,
    private snackService: SnackService,
    private sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private inscripcion: InscripcionService,
    private tipoDePagoService: TipoDePagoService,
    private nuevoIngresoService: NuevoIngresoService,
    private materiasService: MatetiasSeleccionadasService,
    private msalService: MsalService,
    private formBuilder: FormBuilder,
    private egresoService: EgresoService,
    private traductorService: TraductorService,
    private router: Router) {
    this.imgPath = sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem('fotoPerfil'));
  }
  //#endregion

  //#region 
  public pendientes = [
    {
      pendiente: "Lorem Ipsum is simply dummy",
      fecha: "7/06/2021"
    },
    {
      pendiente: "Lorem Ipsum is simply dummy",
      fecha: "7/06/2021"
    }
  ];
  private subscription = new Subscription();
  public base64: string;
  public imgPath: SafeResourceUrl;
  public alumno: AlumnoReingresoDTO = new AlumnoReingresoDTO();
  private identificador: string;
  public curentPage: number = 1;
  public materiasDisponibles = new Array<SeleccionDeMateriasDTO>();
  private costoMaterias: ColegiaturaCosto = new ColegiaturaCosto;
  public costoInscripcion: InscripcionCosto = new InscripcionCosto();
  public gruposInscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
  public cantidadGruposInscritos: number = 0;
  private aceptaMensualidades: boolean;
  private colegiaturaCuota: ColegiaturaCostoDto = new ColegiaturaCostoDto();
  public seriacion = new ExisteMateriaSeriada();
  public resertSeleccionMaterias: boolean = false;
  public periodos: Array<Periodo> = new Array<Periodo>();
  public apoyoFinancieroVigente: boolean = false;
  private idPeriodoSeleccionado: number;
  public materiasBajaAltaAlumno: any;
  public bloqueSeleccionado: boolean = false;
  //#endregion

  //#region ViewChild
  @ViewChild('pagos') pagos: PagoMateriasComponent;
  @ViewChild('seleccion-Materias') seleccionMaterias: ReingresoHorarioMateriasComponent;
  //#endregion

  //#endregion Métodos
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    let userName = this.msalService.instance.getActiveAccount().username;
    this.identificador = userName.substring(0, userName.indexOf('@'));
    this.cargarDatosDelUsuario();
  }
  /**
   * Carga la información del usuario con base a su identificador 
   */
  private async cargarDatosDelUsuario(): Promise<void> {
    let apiDatosUsuarioResponse: ApiResponse<AlumnoReingresoDTO> = await this.reinscripcion.obtenerDatosDeAlumnoReingreso(this.identificador).toPromise();
    if (apiDatosUsuarioResponse.success) {
      this.alumno = apiDatosUsuarioResponse.data;           
      await this.cargarPeriodos();
    }
    else {
      this.snackService.mostrarSnackBack(apiDatosUsuarioResponse.message);
    }
  }
  /**
   * Corrobora si en el periodo indicado el alumno tiene un apoyo financiero o no
   * @param idPeriodo 
   */
  private async consultarEstatusApoyoFinanciero(idPeriodo: number): Promise<void> {
    let apiEstatusApoyoFinanciero = await this.nuevoIngresoService.consultarEstatusApoyoFinanciero(this.alumno.idUsuario, this.alumno.idCampus, idPeriodo, this.alumno.idPrograma).toPromise();
    if (apiEstatusApoyoFinanciero.success) {
      this.apoyoFinancieroVigente = apiEstatusApoyoFinanciero.data;
    }
    else {
      let mensaje: string = this.traductorService.translate(apiEstatusApoyoFinanciero.message);
      this.snackService.mostrarSnackBack(mensaje);
    }
  }
  /**
   * Carga los periodos disponibles para reinscribir
   * @returns 
   */
  private async cargarPeriodos(): Promise<void> {
    let apiPeriodosResponse: ApiResponse<Array<Periodo>> = await this.reinscripcion.obtenerPeriodosReinscripcion(this.alumno.idCampus, this.alumno.idNivel).toPromise();
    if (apiPeriodosResponse.success) {
      this.periodos = apiPeriodosResponse.data;
    }
    else {
      this.snackService.mostrarSnackBack(apiPeriodosResponse.message);
      return;
    }
  }
  /**
   * Muestra en pantalla un mensaje de advertencia que debe ser cliceado
   */
  public async advertenciaCambioDeFoto(): Promise<void> {
    // this.confirmationDialog.aceptarDialog('La foto será rempazada, ¿Desea continuar?', 'Aceptar', 'Descartar').afterClosed().subscribe(async (aceptar: boolean) => {
    //   if (aceptar) {
    //     let archivo: ArchivoSeleccionado = await archivoHelper.abrirArchivo(".jpg, .jpeg, .png");
    //     this.imgPath = this.sanitizer.bypassSecurityTrustUrl(archivo.base64);
    //     this.base64 = archivo.base64;
    //   }
    // });
  }
  /**
   * Recibe el cambio del periodo del Select y consulta el costo de 
   * materia con dicha opción 
   * @param idPeriodo 
   */
  public async recibirPeriodo(idPeriodo: number): Promise<void> {
    this.idPeriodoSeleccionado = idPeriodo;
    let apiCostoMateriasResponse: ApiResponse<ColegiaturaCosto> = await this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.alumno.idCampus, this.alumno.idNivel, idPeriodo, this.alumno.idPrograma).toPromise();
    if (apiCostoMateriasResponse.success) {
      if (apiCostoMateriasResponse.data) {
        this.costoMaterias = apiCostoMateriasResponse.data;
        if (this.costoMaterias.parcialidades) {
          this.aceptaMensualidades = this.costoMaterias.parcialidades;
        }
        this.colegiaturaCuota.alumnoId = this.alumno.idAlumno;
        this.colegiaturaCuota.campusId = this.alumno.idCampus;
        this.colegiaturaCuota.nivelId = this.alumno.idNivel;
        this.colegiaturaCuota.periodoId = idPeriodo;
        this.colegiaturaCuota.costo = this.costoMaterias.costo;
        this.colegiaturaCuota.claveTipoCobro = this.costoMaterias.claveTipoCobro;
        this.colegiaturaCuota.impuesto = this.costoMaterias.impuesto;
        this.colegiaturaCuota.intereses = this.costoMaterias.intereses;
        this.colegiaturaCuota.fechaInicio = this.costoMaterias.fechaInicio;
        this.colegiaturaCuota.fechaFin = this.costoMaterias.fechaFin;
        this.colegiaturaCuota.diaTransaccion = apiCostoMateriasResponse.data.diaTransaccion;
        this.colegiaturaCuota.diaVencimiento = this.costoMaterias.diaVencimiento;
        this.colegiaturaCuota.fechaVencimiento = this.costoMaterias.fechaVencimiento;
        this.colegiaturaCuota.esNuevoIngreso = false;

      } else {
        if (apiCostoMateriasResponse.message !== null) {
          let mensaje: string = this.traductorService.translate(apiCostoMateriasResponse.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      }
      // else {
      //   this.snackService.mostrarSnackBack("No es posible realizar la selección, debido a que no hay una cuota de colegiatura registrada.");
      // }
    }
    else {
      this.snackService.mostrarSnackBack(apiCostoMateriasResponse.message);
    }
    this.consultarEstatusApoyoFinanciero(idPeriodo);
  }

  /**
   * Valida si las materias seleccionadas están seriadas o no
   */
  private async validarSeriacionDeMaterias(): Promise<SimpleResponse> {
    let grupos = this.gruposInscritosLocalmente.map((grupo) => {
      let grupoApiDTO = new GrupoDTO();
      grupoApiDTO.claveMateria = grupo.claveMateria
      return grupoApiDTO
    });
    let apiSeriacionResponse: SimpleResponse = await this.nuevoIngresoService.existeSeriacionDeMateriasEnLaInscripcion(this.alumno.idAlumno, grupos).toPromise();
    if (apiSeriacionResponse.success) {
      this.seriacion.existeMateriaSeriada = true;
      this.seriacion.materiasSeriadas = apiSeriacionResponse.message;
    }
    return await this.inscribirMaterias();
  }
  /**
   * Inscribe las materias ingresadas en la base de datos
   */
  private async inscribirMaterias(): Promise<SimpleResponse> {
    let apiInscripcionMateriasResponse = await this.nuevoIngresoService.inscribirAgrupos(this.alumno.idAlumno, this.alumno.identificador, this.gruposInscritosLocalmente).toPromise();
    if (!apiInscripcionMateriasResponse.success) {
      this.snackService.mostrarSnackBack(apiInscripcionMateriasResponse.message);
    }
    return apiInscripcionMateriasResponse;
  }
  /**
   * Recibe el parámetro emitido por el componente hijo (Un array de Materias o Grupos seleccionadps)
   * @param materiasSeleccionadas
   */
  public recibirSeleccionDeMaterias(materiasSeleccionadas: Array<GruposDisponiblesParaMateriaDTO>) {
    this.gruposInscritosLocalmente = materiasSeleccionadas;
  }
  public recibieCantidadSeleccionMaterias(cantidad: number) {
    this.cantidadGruposInscritos = cantidad;
  }

  public recibirBloqueSeleccionado(seleccionado: boolean) {
    this.bloqueSeleccionado = seleccionado;
  }



  /**
 * Notifica al api que haga el registro del pago en una sóla exhibición
 */
  private async pargosEnExhibicion(): Promise<SimpleResponse> {    
    if (this.seleccionMaterias !== undefined && this.seleccionMaterias?.gruposInscritosLocalmente?.length > 0 && this.seleccionMaterias?.gruposDesuscritosLocalmente?.length > 0) {
      const informacionDeBaja = new BajaDTO();
      informacionDeBaja.identiticador = this.alumno.identificador;
      informacionDeBaja.idPeriodo = this.idPeriodoSeleccionado;
      informacionDeBaja.grupos = this.seleccionMaterias.gruposDesuscritosLocalmente;
      informacionDeBaja.cantidadDeCreditosAntesDeBaja = this.seleccionMaterias.cantidadDeCreditosAntesDeBaja;
      informacionDeBaja.cantidadDeMateriasAntesDeBaja = this.seleccionMaterias.cantidadDeMateriasAntesDeBaja;
      this.materiasBajaAltaAlumno = {
        infoInscripcion: {
          identificador: this.alumno.identificador,
          gruposInscritos: this.seleccionMaterias.gruposInscritosLocalmente
        },
        infoDesuscripcion: {
          informacionDeBaja: informacionDeBaja
        },
        infoColegiaturaCuota: {
          colegiaturaCuota: this.colegiaturaCuota
        }
      }
      console.log("IF");
      return await this.nuevoIngresoService.AltaBajaMateriaAlumno(this.materiasBajaAltaAlumno).toPromise();
    }
    else {      
      console.log("ELSE");
      return await this.nuevoIngresoService.CargoExcibicion(this.colegiaturaCuota).toPromise();
    }
  }
  /**
   * Notifica al api que haga el registro del pago en mensualidades
   */
  private async pagosEnParcialidades(): Promise<SimpleResponse> {
    if (this.seleccionMaterias.gruposInscritosLocalmente.length > 0 && this.seleccionMaterias.gruposDesuscritosLocalmente.length > 0) {
      const informacionDeBaja = new BajaDTO();
      informacionDeBaja.identiticador = this.alumno.identificador;
      informacionDeBaja.idPeriodo = this.idPeriodoSeleccionado;
      informacionDeBaja.grupos = this.seleccionMaterias.gruposDesuscritosLocalmente;
      informacionDeBaja.cantidadDeCreditosAntesDeBaja = this.seleccionMaterias.cantidadDeCreditosAntesDeBaja;
      informacionDeBaja.cantidadDeMateriasAntesDeBaja = this.seleccionMaterias.cantidadDeMateriasAntesDeBaja;
      this.materiasBajaAltaAlumno = {
        infoInscripcion: {
          identificador: this.alumno.identificador,
          gruposInscritos: this.seleccionMaterias.gruposInscritosLocalmente
        },
        infoDesuscripcion: {
          informacionDeBaja: informacionDeBaja
        },
        infoColegiaturaCuota: {
          colegiaturaCuota: this.colegiaturaCuota
        }
      }
      return await this.nuevoIngresoService.AltaBajaMateriaAlumno(this.materiasBajaAltaAlumno).toPromise();
    }
    else {
      return await this.nuevoIngresoService.CargoParcialidades(this.colegiaturaCuota).toPromise();
    }
  }

  //#region Cambios de pantallas

  /**
   * Cambia la pantalla actual (Pago de cuota) y avanza a selección de materias
   */
  public cambiarSeleccionDeMaterias(): void {
    this.curentPage = 2;
    this.stepperService.siguientePaso();
    this.reinscripcion.actualziarHeaderDelTab();
  }
  /**
   * Cambia la pantalla actual (Selección de materia y horario) para regresar a 
   * pago de cuota
   */
  public regresarAPagoDeCuota(): void {
    this.curentPage = 1;
    this.stepperService.anteriorPaso();
  }
  /**
   * Cambia la pantalla actual (pago) para regresar a selección de materias
   */
  public regresarASeleccionDeMaterias(): void {
    this.curentPage = 2;
    this.stepperService.anteriorPaso();
  }
  /**
   * Cambia la pantalla actual (Pago) desde selección de materias
   */
  public async cambiarPantallaPago(): Promise<void> {
    if (this.cantidadMateriasSeleccionadasEsCero && this.bloqueSeleccionado) return;
    this.egresoService.ConfiguracionAcademicaValida(this.alumno.idCampus, this.alumno.idNivel, this.idPeriodoSeleccionado).subscribe(async (response: ApiResponse<boolean>) => {
      if (response.success) {
        if (response.data) {
                 
          var pagoAceptado = await this.materiasService.mostrarModalMaterias(this.gruposInscritosLocalmente, this.costoMaterias.costo, this.costoMaterias.claveTipoCobro).afterClosed().toPromise();
         
          if (pagoAceptado) {
            let apiInscripcionResponse = await this.validarSeriacionDeMaterias();
            if (apiInscripcionResponse.success && !this.alumno.authorizeRegistration) {
              let tipoDepagoParcialidades = await this.tipoDePagoService.mostrarModalTipoDePago(this.aceptaMensualidades).afterClosed().toPromise();
              let apiPagoResponse = new SimpleResponse();              
              //Realiza un pago u otro con base a lo que el usuario haya respondido 
              if (tipoDepagoParcialidades) {
                apiPagoResponse = await this.pagosEnParcialidades();
              }
              else {
                apiPagoResponse = await this.pargosEnExhibicion();
              }
              //Cuando el Api Response se valida dicha respuesta
              if (apiPagoResponse.success) {                
                this.gruposInscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
                this.resertSeleccionMaterias = true;
                await this.pagos.obtenerResumenMateria();                
                this.curentPage = 3;
                this.stepperService.siguientePaso();
              }
              else {
                let mensaje: string = this.traductorService.translate(apiPagoResponse.message);
                this.snackService.mostrarSnackBack(mensaje);
              }
            }
            else if (this.alumno.authorizeRegistration) {
              //let mensaje: string = this.traductorService.translate(apiInscripcionResponse.message);
              this.snackService.mostrarSnackBack('Se han registrado las materías correctamente');
              this.gruposInscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
                this.resertSeleccionMaterias = true;
                //await this.pagos.obtenerResumenMateria();
                this.curentPage = 3;
                this.stepperService.siguientePaso();
            }
            else {
              let mensaje: string = this.traductorService.translate(apiInscripcionResponse.message);
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
        } else {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }
  //#endregion

  //#endregion

  //#regions   Gets
  public get cantidadMateriasSeleccionadasEsCero(): boolean {
    return this.cantidadGruposInscritos <= 0;
  }
  //#endregion


  //#region  goToDashboard
  public goToDashboard(): void
  {
    this.router.navigate(['/dashboard-alumno']);
  }
  //#endregion
}
