export class MEDICAL_INFORMATION_MODEL {

    grupoSanguineoId    : number;
    alergias            : string;
    enfermedades        : string;

    constructor(
        grupoSanguineoId    : number,
        alergias            : string,
        enfermedades        : string,
    ){
        this.grupoSanguineoId   =   grupoSanguineoId;
        this.alergias           =   alergias        ;
        this.enfermedades       =   enfermedades    ;
    }
  
  };