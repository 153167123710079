import { GruposDisponiblesParaMateriaDTO } from "../Inscripcion/GruposDisponiblesParaMateriaDTO.model";

export class ModalMaterias{
    /**
     *
     */
    constructor(total: number, materias: Array<GruposDisponiblesParaMateriaDTO>, tipoDeCobro: string) {
        this.total = total;
        this.materiasSeleccionadas = materias;
        this.tipoDeCobro = tipoDeCobro;
    }
    /**
     * Representa el costo total a pagar por la actual selección
     */
    total: number;
    /**
     * Materias que el alumno ha seleccionado
     */
     materiasSeleccionadas = new Array<GruposDisponiblesParaMateriaDTO>();
     /**
      * Representa la forma de cobro (Materias, Créditos, Periodo)
      */
    tipoDeCobro: string;
}