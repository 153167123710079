import { Horario } from "src/app/_models/horario";
import { CalendarInfoDTO } from "./CalendarInfoDTO.model";

export class GruposDisponiblesParaMateriaDTO {
  /**
   * Clave de la materia a inscribir
   */
  claveMateria: string;

  /**
   * Nombre de la materia
   */
  nombreMateria: string;

  /**
   * Creditos que vale la materia
   */
  creditos: number;

  /**
   * Define si se esta cursando la materia
   */
  cursando: boolean;
  /**
   * Fecha del primer día en el que se imparte la materia
   */
  fechaFin: Date;
  /**
   * último día en el que se imparte la materia
   */
  fechaInicio: Date;
  /**
   * Define si hay un espacio disponible segun la capacidad del grupo
   */
  cupoDisponible: number;

  /**
   * Grupo al que pertenece
   */
  grupo: number;

  /**
   * Nombre del profesor asignado al grupo
   */
  profesorAsignado: string;

  /**
   * Materias oferta
   */
  idMateriaOferta: number;

  /**
   * Modelo con lista de horario
   */
  horario: Array<Horario>;

  /**
   * Represeta la o las secciones del grupo
   */
  calendario: Array<CalendarInfoDTO>;
}
