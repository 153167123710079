import { Horario } from "../../_models/horario";

export class SeleccionDeMateriasDTO {
    clave: string;
    nombre: string;
    idMateria: number;
    idMateriaOferta: number;
    creditos: number;
    ciclo: string;
    inscrito: boolean;
    tieneFalificacionFinal: boolean;
    cupoTotalDeLaOferta: number;
    nombreMateria: string;
    nombrePeriodo: string;
    calificacionFinal: number;
    periodoId: number
    horario: Array<Horario>;
    grupoId:number;
}