import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { IdiomaProgramaDto } from '@app/_models/IdiomaProgramaDto';
import { baseUrl, environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdiomaProgramaService {

  constructor(private httpClient: HttpClient) { }

  /**
 * Metodo para obtener catalogo de idioma programa
 * @returns 
 */
  public obtenerIdiomaPrograma(): Observable<ApiResponse<IdiomaProgramaDto[]>> {
    return this.httpClient.get<ApiResponse<IdiomaProgramaDto[]>>(`${environment.enves[baseUrl].apiUrl}/IdiomaPrograma/ObtenerIdiomaPrograma`);
  }
}
