import { OfertaCademicaBloqueModalComponent } from './oferta-cademica-bloque-modal/oferta-cademica-bloque-modal.component';
import { OfertaCademicaBloqueModalEliminarComponent } from './oferta-cademica-bloque-modal-eliminar/oferta-cademica-bloque-modal-eliminar.component';
import { Component, OnInit, Inject, OnDestroy, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { UtileriasService } from '../../_services/utilerias.service';
import { SelectionModel } from '@angular/cdk/collections';
import { BloqueInscripcion } from '../../_models/bloque-inscripcion';
import { BloqueInscripcionService } from '../../_services/bloque-inscripcion.service';
import { AccionEnum } from '../../_models/accion.enum';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { CicloService } from '../../_services/ciclo.service';
import { Materia } from '../../_models/materia';
import { Grupo } from '../../_models/grupo';
import { Periodo } from '../../_models/periodo';
import { MateriaService } from '../../_services/materia.service';
import { GrupoService } from '../../_services/grupo.service';
import { PeriodoService } from '../../_services/periodo.service';
import { MatDialog } from '@angular/material/dialog';
import { Programa } from '../../_models/programa';
import { Ciclo } from '../../_models/ciclo';
import { Campus } from 'src/app/_models/campus';
import { CampusService } from 'src/app/_services/campus.service';
import { Block } from '../../_models/block';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { BlockService } from '../../_services/block.service';
import { MatOption } from '@angular/material/core';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';

type NewType = GeneracionExcelServiceService;

@Component({
  selector: 'app-oferta-academica-bloque',
  templateUrl: './oferta-academica-bloque.component.html',
  styleUrls: ['./oferta-academica-bloque.component.scss']
})
export class OfertaAcademicaBloqueComponent implements OnInit, OnDestroy, AfterViewInit {

  dataSource: MatTableDataSource<Block>;
  displayedColumns: string[] = ['select', 'clave', 'nombre', 'campus', 'periodo', 'grupos', 'capacidad', 'disponibles'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public numBlockSeleccionado: any;
  private arrOriginal: Block[] = [];
  public arregloFiltroOriginal: Block[] = [];
  public arregloFiltroModificado: any[] = [];
  public datafilter: Block[] = [];

  public active: string;
  public direction: string;

  public tipoPeriodoList: Periodo[] = [];
  public tipoProgramaList: Programa[] = [];
  public tipoCicloList: Ciclo[] = [];
  private periodosactivos: number[] = [];

  private subscripcionBloques: Subscription;
  public subscriptionMateria: Subscription;
  public subscriptionPeriodo: Subscription;
  public subscriptionGrupo: Subscription;
  public subscriptionPrograma: Subscription;
  public subscriptionCiclo: Subscription;

  public bloqueId: number = 0;
  public selection = new SelectionModel<Block>(true, []);
  public grupoList: Grupo[] = [];
  public periodoList: Periodo[] = [];
  public infoCargado: boolean = false;
  public numBloqueSeleccionado: number = 0;
  public filtroForm: FormGroup;


  public periodoF = new FormControl();
  public campusF = new FormControl();
  public nombreF = new FormControl();

  public Periodos: Periodo[] = [];
  public ArCampus: Campus[] = [];
  //#region ViewChild
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  //#endregion



  constructor(private snackBar: MatSnackBar,
    private programaService: ProgramaAcademicoService,
    private cicloService: CicloService,
    private util: UtileriasService,
    private periodoService: PeriodoService,
    private dialog: MatDialog,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    public campusService: CampusService,
    public blockService: BlockService,
    private generadorExcel: GeneracionExcelServiceService,
  ) { }

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion



  ngOnInit() {
    if (this.blockService.invMetodoObtenerBloqueSubscription === undefined) {
      this.blockService.invMetodoObtenerBloqueSubscription = this.blockService.invocarObtenerBloque.subscribe(() => {
        this.obtenerBlock();
        this.limpiarFiltros();
      });
      this.blockService.invMetodoObtenerBloqueSubscription = undefined;
    }

    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };

    this.LlenarFiltros();
    this.obtenerBlock();
  }


  public obtenerBlock() {
    this.blockService.obtenerBlocks().subscribe(
      (response: ApiResponse<Block[]>) => {
        if (response.success) {
          let blockEncontrados: Block[] = response.data;
          this.arrOriginal = [...blockEncontrados];
          this.dataSource = new MatTableDataSource(blockEncontrados);
          this.datafilter = blockEncontrados;
          this.dataSource.paginator = this.paginator;
          this.arregloFiltroOriginal = response.data;
          this.infoCargado = true;
          this.selection = new SelectionModel<any>(true, []);
        }
      })
  }






  ngAfterViewInit() {

  }


  //Consultas de Filtrado.
  public consultarPeriodo() {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.tipoPeriodoList = periodo;
      });
  }


  public consultarProgramas() {
    this.subscriptionPrograma = this.programaService.obtenerProgramas().subscribe(
      (programa: Programa[]) => {
        this.tipoProgramaList = programa;
      });
  }



  /**
    * Llena los select de filtros y selecciona los periodos que estan activos
    * para mostrar esos en la tabla
    */
  public LlenarFiltros(): void {
    let today = new Date();
    this.periodoService.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        this.Periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio < today && fin > today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if (this.periodosactivos.length > 0) {
          this.periodoF.setValue([...this.periodosactivos]);
          this.filtro();
        }
      }
    );

    this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.ArCampus = campus;
        this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
        this.filtro();
      }
    )

  }



  public crearBloque(): void {
    this.util.abrirDialogoLateral(OfertaCademicaBloqueModalComponent);


  }

  public edicionBloque(elemento?: BloqueInscripcion): void {
    let datos: BloqueInscripcion = {
      bloqueInscripcionId: elemento.bloqueInscripcionId,
      periodoId: elemento.periodoId,
      clave: elemento.clave,
      nombre: elemento.nombre,
      capacidad: elemento.capacidad,
      materiaId: elemento.materiaId,
      grupoId: elemento.grupoId,
      tipoAccion: AccionEnum.EDITAR
    }


  }

  public editarBloque(bloque: any) {
    // this.nuevoIngresoService.obtenerNuevosIngresoByUsuarioId(solicitud.usuarioId).subscribe(
    //   (datosUsuario: ApiResponse<UsuarioNuevoIngreso>) => {
    //     this.buscarInformacionUsuario(solicitud.usuarioId);
    //     let usuario = datosUsuario.data;
    //     usuario.solicitudCargaDoc = solicitudCargaDoc;
    //     this.util.abrirDialogoLateral(NuevoIngresoModalComponent, usuario);
    //   }
    // );


    this.util.abrirDialogoLateral(OfertaCademicaBloqueModalComponent, bloque);

  }




  public descargarExcel(elemento?: Block[]): void {
    let IdBlock;
    if (elemento != null && elemento.length > 0) {
      IdBlock = elemento.map(m => m.blockId.toString());
    } else {
      IdBlock = this.datafilter.map(m => m.blockId.toString());
    }
    this.blockService.descargarExcel(IdBlock).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "Bloque");
    });
  }

  /**
     * Valida si se ha seleccionado todos los rows
     * @returns boolean
     */
  isAllSelected() {
    this.numBloqueSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numBloqueSeleccionado === numRows;
  }

  /**
     * Determina qué texto se mostrará en el Label con base a si se ha enviado on o un parámetro
     * @param row
     * @returns
     */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.blockId + 1}`;
  }

  /**
  * Marca todas los rows de la tabla
  */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  ngOnDestroy() {
    if (this.subscriptionPeriodo) {
      this.subscriptionPeriodo.unsubscribe;
    }
    if (this.subscripcionBloques) {
      this.subscripcionBloques.unsubscribe;
    }
    if (this.subscriptionPrograma) {
      this.subscriptionPrograma.unsubscribe;
    }
    if (this.subscriptionCiclo) {
      this.subscriptionCiclo.unsubscribe;
    }
  }


  /************************************************* */
  toggleAllSelectionPeriodo() {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.filtro();
  }
  tosslePerOnePeriodo(all) {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      this.filtro();
      return false;
    }
    if (this.periodoF.value.length == this.Periodos.length) { this.allPeriodo.select(); }
    this.filtro();
  }
  /************************************************ */
  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.campusF.patchValue([...this.ArCampus.map(item => item.campusId), 0]);
    } else {
      this.campusF.patchValue([]);
    }
    this.filtro();
  }
  tosslePerOneCampus(all) {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      this.filtro();
      return false;
    }
    if (this.campusF.value.length == this.ArCampus.length) { this.allCampus.select(); }
    this.filtro();
  }

  /************************************************ */
  buscarNombreClave(filterValue: string) {
    filterValue = filterValue.toLowerCase();
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    if (filterValue != "") {
      filtroModificado = filtroModificado.filter(el => {
        return el.blockLabel.name.toLowerCase().search(filterValue) > -1 || el.blockLabel.code.toLowerCase().search(filterValue) > -1;
      })
    }

    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.datafilter = filtroModificado;

  }


  /************************************************ */


  filtro() {

    let periodo: any[] = this.periodoF.value == null ? [] : this.periodoF.value;
    let campus: any[] = this.campusF.value == null ? [] : this.campusF.value;
    let nombre: string = this.nombreF.value == null ? "" : this.nombreF.value;
    var filtro: any;

    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];

    if (this.arregloFiltroModificado.length == 0) {
      this.arregloFiltroModificado = this.arregloFiltroOriginal;
    }



      filtroModificado = filtroModificado.filter(x => { return periodo.indexOf(x.periodoId) > -1 });


      filtroModificado = filtroModificado.filter(camp => { return campus.indexOf(camp.campusId) > -1 });

      //filtroModificado = filtroModificado.filter(row => { return row.blockLabel.name.toLowerCase().search(nombre.toLowerCase()) > -1 || row.blockLabel.code.toString().search(nombre) > -1 });


    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.datafilter = filtroModificado;
    this.selection = new SelectionModel<any>(true, []);
  }

  limpiarFiltros() {
    this.dataSource.paginator = this.paginator;
    this.periodoF.setValue([...this.periodosactivos]);
    this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
    this.nombreF.setValue("");
    this.filtro();
  }


  public borrar(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogoEliminar = this.dialog.open(OfertaCademicaBloqueModalEliminarComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });

  }




}

