import { Observable } from 'rxjs';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl, environment } from '../../environments/environment';
import { ActivityOrderType } from '../_models/activityOrderType';

@Injectable({
  providedIn: 'root'
})
export class ActivityOrderTypeService {

  constructor(private httpClient: HttpClient) { }

 
  public getActivityOrderType(): Observable<ApiResponse<ActivityOrderType[]>> {
    return this.httpClient.get<ApiResponse<ActivityOrderType[]>>(`${environment.enves[baseUrl].apiUrl}/ActivityOrderType/GetActivityOrderType/`);
  }


}
