import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { BlockService } from '@app/_services/block.service';
import { BlockLabelDto } from '@app/_models/BlockLabelDto';
import { DetalleEgresoModalComponent } from './../contenido/detalle-egreso-modal/detalle-egreso-modal.component';
import { EstatusAcademico } from './../../_models/inscripcion';
import { ApiResponse } from './../../models/api/ApiRespose.model';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Campus } from 'src/app/_models/campus';
import { Periodo } from 'src/app/_models/periodo';
import { Programa, ProgramaActivo } from 'src/app/_models/programa';
import { CampusService } from 'src/app/_services/campus.service';
import { InscripcionService } from 'src/app/_services/inscripcion.service';
import { EgresoService } from 'src/app/_services/egreso.service';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { TablaEgresoDTO } from 'src/app/_models/TablaEgresoDTO';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { Usuario } from 'src/app/_models/usuario';
import { PeriodoIngresoNavigation } from 'src/app/_models/PeriodoIngresoNavigation.model';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmacionEstatusComponent } from '../modal-confirmacion-estatus/modal-confirmacion-estatus.component';
import { ModalEnviarCorreoComponent } from '../../administracion-academica/modal-enviar-correo/modal-enviar-correo.component';
import { FiltroAdminAcademicoAlumnoDto } from '@app/_models/FiltroAdminAcademicoAlumnoDto';
import { AttributesDto } from '@app/_models/AttributesDto';
import { AttributesService } from '@app/_services/attributes.service';
import { TraductorService } from '@app/_services/traductor.service';

@Component({
  selector: 'app-egreso',
  templateUrl: './egreso.component.html',
  styleUrls: ['./egreso.component.scss']
})
export class EgresoComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allEstatus') private allEstatus: MatOption;
  @ViewChild('allBloque') private allBloque: MatOption;
  @ViewChild('allAtritbuto') private allAtritbuto: MatOption;
  private Subscription: Subscription = new Subscription();
  public filtroForm: FormGroup;
  public today = new Date();
  public Periodos: Periodo[];
  public Programas: ProgramaActivo[] = [];
  public Campus: Campus[] = [];
  public Estatus: EstatusAcademico[] = [];
  public TablaEgresoDTO: TablaEgresoDTO[] = [];
  private periodosactivos: any[] = [];
  public numEgresoSeleccionado: number = 0;
  private arrOriginal: any[] = [];
  public datafilter: any;
  public BlockLabel: BlockLabelDto[];
  periodoF = new FormControl();
  ProgramaF = new FormControl();
  CampusF = new FormControl();
  estatusF = new FormControl();
  NombreF = new FormControl();
  bloqueF = new FormControl();
  atributoF = new FormControl();
  public arregloFiltroModificado: any[] = [];
  public arregloFiltroOriginal: any[] = [];
  public valorInicial = 100;
  public attributes: AttributesDto[];
  public existenAtributos: boolean = false;
  public infoCargada: boolean = false;
  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'select',
    'id',
    'nombre',
    'programa',
    'campus',
    'periodo',
    'matcredplan',
    'matcredacre',
    'matcredinsc',
    'matcredpend',
    'promedio',
    'solicitud',
    'estatus',
    'blockName'];

  constructor(
    private periodoService: PeriodoService,
    public programaservice: ProgramaAcademicoService,
    private campusService: CampusService,
    public inscripcionService: InscripcionService,
    public util: UtileriasService,
    public EgresoService: EgresoService,
    private generadorExcel: GeneracionExcelServiceService,
    private dialog: MatDialog,
    private _BlockService: BlockService,
    public nuevoIngresoService: NuevoIngresoService,
    public attributesService: AttributesService,
    private paginador: MatPaginatorIntl,
    private traductorService: TraductorService,
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.EgresoService.invocarObtenerContenidoSubscription == undefined) {
      this.EgresoService.invocarObtenerContenidoSubscription = this.EgresoService.invocarObtenerContenido.subscribe(() => {
        this.LlenarFiltros();
      });
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    await this.inicializarForm().then((_) => {
      this.LlenarFiltros();
    });
  }
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  /**
   * Hace un llenado de todos los filtros, ingresa a las funciones de obtencion de datos para su carga
   */
  public LlenarFiltros(): void {
    this.obtenerPeriodo();
  }

  /**
  * Obtiene los campus del periodo
  */
  public obtenerPeriodo(): void {
    let today = new Date();
    this.periodoService.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        this.Periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio <= today && fin >= today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if (this.periodosactivos.length > 0) {
          this.periodoF.setValue([...this.periodosactivos]);
          this.filtroForm.patchValue({
            periodoF: this.periodosactivos
          });
        }
        this.obtenerProgramas();
      });
  }

  /**
   * Obtiene los campus del programa y setea su valor en todos
   */
  public obtenerProgramas(): void {
    this.nuevoIngresoService.getPrograma().subscribe(
      (programas: Programa[]) => {
        this.Programas = programas;
        this.ProgramaF.setValue([...this.Programas.map(item => item.programaId), 0]);
        this.filtroForm.patchValue({
          ProgramaF: this.Programas.map(item => item.programaId)
        });
        this.obtenerCampus();
      });
  }

  /**
   * Obtiene los campus del catalogo y setea su valor en todos
   */
  public obtenerCampus(): void {
    this.campusService.obtenerCampus().subscribe((campus: Campus[]) => {
      this.Campus = campus;
      this.CampusF.setValue([...this.Campus.map(item => item.campusId), 0]);
      this.filtroForm.patchValue({
        CampusF: this.Campus.map(item => item.campusId)
      });
      this.obtenerEstatus();
    });
  }

  /**
   * Obtiene los campus del estatus academico
   */
  public obtenerEstatus(): void {
    this.inscripcionService.GetEstatusAcademicos().subscribe((status: ApiResponse<EstatusAcademico[]>) => {
      this.Estatus = status.data.filter((estatus) => {
        return estatus.estatusAcademicoId === 8 || estatus.estatusAcademicoId === 10 || estatus.estatusAcademicoId === 11 || estatus.estatusAcademicoId === 4 || estatus.estatusAcademicoId === 12
      });
      this.estatusF.setValue([...this.Estatus.map(item => item.estatusAcademicoId), 0]);
      this.filtroForm.patchValue({
        estatusF: this.Estatus.map(item => item.estatusAcademicoId)
      });
      this.obtenerBloques();
    });
  }

  /**
   * Metodo que consulta los bloques
   */
  public obtenerBloques() {
    this._BlockService.GetBlockLabelDto().subscribe((respuesta: ApiResponse<BlockLabelDto[]>) => {
      this.BlockLabel = respuesta.data;
      this.obtenerAtributos();
    })
  }

  /**
* Metodo que consulta los atributos
*/
  public obtenerAtributos() {
    this.attributesService.attributesInstitucion().subscribe((respuesta: ApiResponse<AttributesDto[]>) => {
      this.attributes = respuesta.data;
      this.existenAtributos = this.attributes.length > 0;
      this.filtrar();
    })
  }

  /**
 * Metodo para buscar los filtros
 */
  public buscarFiltros(): void {
    this.filtrar();
  }

  /**
* Metodo que detecta el cambio de pagina y llama el servicio para traer la siguiente informacion
* @param event
*/
  public onPaginateChange(event: any) {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatusAr: this.filtroForm.get('estatusF').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value,
      numMatCrePendientes: this.filtroForm.get('MatCredPendientes').value
    };
    if (filtro.numMatCrePendientes == null) {
      filtro.numMatCrePendientes = 0
    }
    this.ObtenerAlumnos(event.pageIndex + 1, event.pageSize, filtro);
  }

  /**
   * Metodo que envia laa informacion de los filtros al back
   */
  public filtrar() {
    console.log("ENTRE filtro")
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatusAr: this.filtroForm.get('estatusF').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value,
      numMatCrePendientes: this.filtroForm.get('MatCredPendientes').value
    };
    if (filtro.numMatCrePendientes == null) {
      filtro.numMatCrePendientes = 0
    }
    console.log("FILTRO", filtro);
    console.log("FILTRO Valores", this.filtroForm.value);
    this.ObtenerAlumnos(1, this.paginator.pageSize, filtro);
  }

  public ObtenerAlumnos(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): void {
    this.Subscription.add(
      this.EgresoService.ObtenerAlumnos(pagina, registrosPagina, filtro).subscribe(
        (_TablaEgresoDTO: ApiResponse<any>) => {
          if (_TablaEgresoDTO.success) {
            this.arrOriginal = [..._TablaEgresoDTO.data.alumnosEgreso];
            this.arregloFiltroOriginal = [..._TablaEgresoDTO.data.alumnosEgreso];
            this.TablaEgresoDTO = _TablaEgresoDTO.data.alumnosEgreso;
            this.dataSource = new MatTableDataSource(this.TablaEgresoDTO);
            console.log("this.dataSource", this.dataSource);
            this.dataSource.paginator = this.paginator;
            this.datafilter = this.TablaEgresoDTO;
            this.valorInicial = _TablaEgresoDTO.data.cantidadAlumnos;
            this.selection = new SelectionModel<any>(true, []);
            this.infoCargada = true;
          } else {
            this.dataSource = new MatTableDataSource([]);
            this.arregloFiltroOriginal = [];
          }
        }
      )
    );
  }

  /**
 * Inicializa el formulario y setea los campos usados
 */
  public inicializarForm(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.filtroForm = new FormGroup({
        periodoF: new FormControl([]),
        idnombre: new FormControl(),
        ProgramaF: new FormControl([]),
        CampusF: new FormControl([]),
        estatusF: new FormControl([]),
        bloqueF: new FormControl([]),
        atributoF: new FormControl([]),
        MatCredPendientes: new FormControl()
      });
      this.CampusF.setValue(["-1"]);
      this.ProgramaF.setValue(["-1"]);
      this.periodoF.setValue(["-1"]);
      this.bloqueF.setValue(["-1"]);
      this.estatusF.setValue(["-1"]);
      this.atributoF.setValue(["-1"]);
      resolve();
    });
  }

  /**
   * Limpia el arreglo de filtro, llena nuevamente los filtros e inicia el formulario, y muestra la tabla sin filtrado
   */
  public LimpiarFiltros(): void {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
    this.dataSource.paginator = this.paginator;
  }

  /**
 * Vacia el arreglo
 */
  public vaciarArregloModificado(): void {
    this.arregloFiltroModificado.length = 0;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }
  isAllSelected() {
    if (this.datafilter) {
      this.numEgresoSeleccionado = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numEgresoSeleccionado === numRows;
    }
  }

  checkboxLabel(row?: TablaEgresoDTO): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.alumnoId + 1}`;
  }

  /**
    * Abre el modal lateral con la información requerida
    */
  public AbrirModalLateral(Alumno: TablaEgresoDTO, indexTap: number) {
    //Se crea el objeto con la data base del objeto cleckeado y el número del tap requerido
    Alumno.usuario = new Usuario();
    Alumno.programa = new Programa();
    Alumno.campus = new Campus();
    Alumno.priodoIngresoNavigation = new PeriodoIngresoNavigation();
    Alumno.usuario.usuarioID = Alumno.usuarioId;
    Alumno.usuario.identificador = Alumno.identificador;
    Alumno.usuario.nombre = Alumno.nombre;
    Alumno.usuario.apellidoPaterno = Alumno.apellidoPaterno;
    Alumno.usuario.apellidoMaterno = Alumno.apellidoMaterno;

    Alumno.programa.nombreCorto = Alumno.programaNombre;
    Alumno.programa.programaId = Alumno.programaId;

    Alumno.campus.campusId = Alumno.campusId;
    Alumno.campus.nombre = Alumno.campusNombre;
    Alumno.priodoIngresoNavigation.nombre = Alumno.periodoNombre;
    const data = {
      alumno: {
        ...Alumno
      },
      indexTap
    }
    this.util.abrirDialogoLateral(DetalleEgresoModalComponent, data);
  }

  public descargarExcel(elemento?: any) {
    let IdAlumnos;
    if (elemento != null && elemento.length > 0) {
      IdAlumnos = elemento.map(m => m.alumnoId.toString());
    } else {
      IdAlumnos = this.datafilter.map(m => m.alumnoId.toString());
    }
    this.Subscription.add(
      this.EgresoService.descargarExcel(IdAlumnos).subscribe((blob: Blob) => {
        this.generadorExcel.GenerarExcel(blob, "Egreso");
      }));
  }

  public ActualizasEstatusAlumno(elemento?: TablaEgresoDTO[]) {

    let data: TablaEgresoDTO[] = elemento;
    let content = {
      data: data,
      opcion: 1
    }

    const dialogRef = this.dialog.open(ModalConfirmacionEstatusComponent, { data: content, disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      this.selection.clear();
    });
  }

  public ActualizasEstatusCandidatoEgreso(elemento?: TablaEgresoDTO[]) {

    let data: TablaEgresoDTO[] = elemento;
    let content = {
      data: data,
      opcion: 2
    }

    const dialogRef = this.dialog.open(ModalConfirmacionEstatusComponent, { data: content, disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      this.selection.clear();
    });
  }

  public ActualizasEstatusEgreso(elemento?: TablaEgresoDTO[]) {

    let data: TablaEgresoDTO[] = elemento;
    let content = {
      data: data,
      opcion: 3
    }

    const dialogRef = this.dialog.open(ModalConfirmacionEstatusComponent, { data: content, disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      this.selection.clear();
    });
  }

  public enviarCorreo(btnDataTable: boolean, elemento?: Array<TablaEgresoDTO>): void {
    let datos;
    if (btnDataTable) {
      datos = elemento;
    }
    else {
      if (elemento.length === 1) {
        datos = elemento[0];
      }
      else {
        datos = elemento;
      }
    }

    this.dialog.open(ModalEnviarCorreoComponent, { data: datos, disableClose: true });
  }

  /**
* Si solo un dato del select Periodo esta seleccionado busca solo esa informacion y la
* muestra en la tabla principal
*/
  toggleAllSelectionPeriodo(): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.filtroForm.patchValue({
        periodoF: this.Periodos.map(item => item.periodoId)
      });
      this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.filtroForm.patchValue({
        periodoF: []
      });
      this.periodoF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Programa esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionPrograma(): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.filtroForm.patchValue({
        ProgramaF: this.Programas.map(item => item.programaId)
      });
      this.ProgramaF.patchValue([...this.Programas.map(item => item.programaId), 0]);
    } else {
      this.filtroForm.patchValue({
        ProgramaF: []
      });
      this.ProgramaF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Campus esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionCampus(): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.filtroForm.patchValue({
        CampusF: this.Campus.map(item => item.campusId)
      });
      this.CampusF.patchValue([...this.Campus.map(item => item.campusId), 0]);
    } else {
      this.filtroForm.patchValue({
        CampusF: []
      });
      this.CampusF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select estatus esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionEstatus(): void {
    // this.reiniciarTimer();
    if (this.allEstatus.selected) {
      this.filtroForm.patchValue({
        estatusF: this.Estatus.map(item => item.estatusAcademicoId)
      });
      this.estatusF.patchValue([...this.Estatus.map(item => item.estatusAcademicoId), 0]);
    } else {
      this.filtroForm.patchValue({
        estatusF: []
      });
      this.estatusF.patchValue([]);
    }
  }

  /**
   * Metodo cuando se seleccionan todos los bloques
   */
  toggleAllSelectionBloque() {
    // this.reiniciarTimer();
    if (this.allBloque.selected) {
      this.filtroForm.patchValue({
        bloqueF: this.BlockLabel.map(item => item.blockLabelId)
      });
      this.bloqueF.patchValue([...this.BlockLabel.map(item => item.blockLabelId), 0]);
    } else {
      this.filtroForm.patchValue({
        bloqueF: []
      });
      this.bloqueF.patchValue([]);
    }
  }

  /**
* Metodo cuando se seleccionan todos los atributos
*/
  toggleAllSelectionAtributo() {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.filtroForm.patchValue({
        atributoF: this.attributes.map(item => item.attributeId)
      });
      this.atributoF.patchValue([...this.attributes.map(item => item.attributeId), 0]);
    } else {
      this.filtroForm.patchValue({
        atributoF: []
      });
      this.atributoF.patchValue([]);
    }
  }

  /**
  * Verifica si el campo Todos del select Periodo esta seleccionados, si lo esta
  * quita la seleccion de todos los campos, sino los selecciona todos
  * @param periodoId
  */
  tosslePerOnePeriodo(periodoId: number): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
    }
    if (this.periodoF.value.length == this.Periodos.length) {
      this.allPeriodo.select();
    }
    const periodosSeleccionados = this.filtroForm.get('periodoF').value;
    const existePeriodo = periodosSeleccionados.find(x => x === periodoId);
    if (!existePeriodo) {
      const periodosAnteriores = periodosSeleccionados;
      periodosAnteriores.push(periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosAnteriores
      });
    } else {
      const periodosFiltrados = periodosSeleccionados.filter(x => x !== periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Programa esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param programaId
     * @returns
  */
  tosslePerOnePrograma(programaId: number): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
    }
    if (this.ProgramaF.value.length == this.Programas.length) {
      this.allPrograma.select();
    }
    const programasSeleccionados = this.filtroForm.get('ProgramaF').value;
    const existePrograma = programasSeleccionados.find(x => x === programaId);
    if (!existePrograma) {
      const programasAnteriores = programasSeleccionados;
      programasAnteriores.push(programaId);
      this.filtroForm.patchValue({
        ProgramaF: programasAnteriores
      });
    } else {
      const programasFiltrados = programasSeleccionados.filter(x => x !== programaId);
      this.filtroForm.patchValue({
        ProgramaF: programasFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param campusId
     * @returns
  */
  tosslePerOneCampus(campusId: number): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.allCampus.deselect();
    }
    if (this.CampusF.value.length == this.Campus.length) {
      this.allCampus.select();
    }
    const campusSeleccionados = this.filtroForm.get('CampusF').value;
    const existeCampus = campusSeleccionados.find(x => x === campusId);
    if (!existeCampus) {
      const campusAnteriores = campusSeleccionados;
      campusAnteriores.push(campusId);
      this.filtroForm.patchValue({
        CampusF: campusAnteriores
      });
    } else {
      const campusFiltrados = campusSeleccionados.filter(x => x !== campusId);
      this.filtroForm.patchValue({
        CampusF: campusFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param campusId
     * @returns
  */
  tosslePerOneEstatus(estatusAcademicoId: number): void {
    // this.reiniciarTimer();
    if (this.allEstatus.selected) {
      this.allEstatus.deselect();
    }
    if (this.estatusF.value.length == this.Estatus.length) {
      this.allEstatus.select();
    }
    const EstatusSeleccionados = this.filtroForm.get('estatusF').value;
    const existeEstatus = EstatusSeleccionados.find(x => x === estatusAcademicoId);
    if (!existeEstatus) {
      const estatusAnteriores = EstatusSeleccionados;
      estatusAnteriores.push(estatusAcademicoId);
      this.filtroForm.patchValue({
        estatusF: estatusAnteriores
      });
    } else {
      const estatusFiltrados = EstatusSeleccionados.filter(x => x !== estatusAcademicoId);
      this.filtroForm.patchValue({
        estatusF: estatusFiltrados
      });
    }
  }

  /**
   * Verifica si el campo Todos del select Bloque esta seleccionados, si lo esta
   * quita la seleccion de todos los campos, sino los selecciona todos
   * @param bloqueId
   * @returns
*/
  tosslePerOneBloque(bloqueId: number) {
    // this.reiniciarTimer();
    if (this.allBloque.selected) {
      this.allBloque.deselect();
    }
    if (this.bloqueF.value.length == this.BlockLabel.length) {
      this.allBloque.select();
    }
    const bloqueSeleccionados = this.filtroForm.get('bloqueF').value;
    const existeBloque = bloqueSeleccionados.find(x => x === bloqueId);
    if (!existeBloque) {
      const bloqueAnteriores = bloqueSeleccionados;
      bloqueAnteriores.push(bloqueId);
      this.filtroForm.patchValue({
        bloqueF: bloqueAnteriores
      });
    } else {
      const bloqueFiltrados = bloqueSeleccionados.filter(x => x !== bloqueId);
      this.filtroForm.patchValue({
        bloqueF: bloqueFiltrados
      });
    }
  }

  /**
 * Verifica si el campo Todos del select atributo esta seleccionados, si lo esta
 * quita la seleccion de todos los campos, sino los selecciona todos
 * @param AtributoId
 * @returns
 */
  tosslePerOneAtributo(AtributoId: number) {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.allAtritbuto.deselect();
    }
    if (this.atributoF.value.length == this.attributes.length) {
      this.allAtritbuto.select();
    }
    const AtributoSeleccionados = this.filtroForm.get('atributoF').value;
    const existeAtributo = AtributoSeleccionados.find(x => x === AtributoId);
    if (!existeAtributo) {
      const AtributoAnteriores = AtributoSeleccionados;
      AtributoAnteriores.push(AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoAnteriores
      });
    } else {
      const AtributoFiltrados = AtributoSeleccionados.filter(x => x !== AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoFiltrados
      });
    }
  }
}
