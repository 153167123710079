import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UtileriasService } from '../_services/utilerias.service';
import { CampusService } from '../_services/campus.service';
import { CampusEdicionComponent } from './campus-edicion/campus-edicion.component';
import { CampusEliminarComponent } from './campus-eliminar/campus-eliminar.component';
import { Campus } from '../_models/campus';
import { AccionEnum } from '../_models/accion.enum';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'app-campus',
  templateUrl: './campus.component.html',
  styleUrls: ['./campus.component.scss']
})
export class CampusComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'Clave', 'Nombre', 'Rector', 'Telefono'];
  dataSource: MatTableDataSource<Campus>;
  selection = new SelectionModel<Campus>(true, []);

  public campus: Campus;
  public campusList: Campus[] = [];
  public valorSeleccionado: Campus[] = [];
  public infoCargado: boolean = false;
  public numCampusSeleccionado: number = 0;
  public claveName = new FormControl();
  private arrOriginal: Campus[] =[];
  constructor(
    public util: UtileriasService,
    private campusService: CampusService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
  ) {
    this.obtenerCampus();
  }

  ngOnInit() {
    if (this.campusService.invMetodoObtenerCampusSubscription === undefined) {
      this.campusService.invMetodoObtenerCampusSubscription = this.campusService.invocarObtenerCampus.subscribe(() => {
        this.obtenerCampus();
      });
      this.paginador.itemsPerPageLabel = "Registros por página";
      this.paginador.nextPageLabel = "Página siguiente";
      this.paginador.previousPageLabel = "Página anterior";
      this.paginador.firstPageLabel = "Primera página";
      this.paginador.lastPageLabel = "Última página";
  
      this.contador.getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
          return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
    }
    this.obtenerCampus();
  }

  /**
   * Funcion que obtiene los campus y los muestra en un dataSource
   */
  public obtenerCampus() {
    this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.dataSource = new MatTableDataSource(campus);
        this.selection = new SelectionModel<Campus>(true, []);
        this.dataSource.paginator = this.paginator;
        this.infoCargado = true;
        this.arrOriginal = [...campus];
      });
  }

  /**
   * Funcion que busca el nombre del campus para mostrarlo en la tabla principal
   * @param clave 
   * @returns 
   */

  public findNombreCampus(clave: string) {
    return this.campusList.find(clave1 => clave1.clave === clave);
  }

  /**
   * Funcion que para crear un nuevo campus el cual manda los datos inicializados al componente de creacion 
   */

  public crear(): void {
    let datos: Campus = {
      campusId: 0,
      clave: '',
      nombre: '',
      pais: 0,
      entidadFederativaId: 0, 
      calle: '',
      numeroExterior: '',
      colonia: '',
      ciudad: '',
      codigoPostal: '',
      telefono: '',
      rectorCampus: '',
      directorEscolar: '',
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(CampusEdicionComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.campus = result;
      }
    });
  }

  /**
   * Funcion que manda los datos de un campus para su edicion en otro componente
   * @param elemento 
   */

  public editar(elemento?: Campus): void {
    let datos: Campus = {
      campusId: elemento.campusId,
      clave: elemento.clave,
      nombre: elemento.nombre,
      pais: 484,
      entidadFederativaId: elemento.entidadFederativaId,
      calle: elemento.calle,
      numeroExterior: elemento.numeroExterior,
      numeroInterior: elemento.numeroInterior,
      colonia: elemento.colonia,
      ciudad: elemento.ciudad,
      codigoPostal: elemento.codigoPostal,
      telefono: elemento.telefono,
      rectorCampus: elemento.rectorCampus,
      directorEscolar: elemento.directorEscolar,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(CampusEdicionComponent, datos);
  } 

  /**
   * Funcion para eliminar un campus desde la tabla principal al momento de seleccionar un elemento y valida si es uno o más datos
   * @param btnDataTable 
   * @param elemento 
   */

  public borrar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogo = this.util.abrirDialogoLateral(CampusEliminarComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.campus = result;
      }
    });
  }

  /**
   * Funcion que busca el nombre del campus o la clave
   */

  public filtro() {
    let claveNombre: string = this.claveName.value === null ? '' : this.claveName.value;
    let filtroModificado: Campus[] = [];
    filtroModificado = [...this.arrOriginal];
    filtroModificado = filtroModificado.filter(f => {
      return f.clave.toLowerCase().search(claveNombre.toLowerCase()) > -1 || f.nombre.toLowerCase().search(claveNombre.toLowerCase()) > -1;
    });
    this.dataSource = new MatTableDataSource(filtroModificado);
  }

  /**
   * Funcion que limpia los filtros que el usuario eligio
   */
  
  public LimpiarFiltros() {
    this.claveName.setValue('');
    this.obtenerCampus();
  }

/**
 * Funcion que checa si todos los select del dataSource son iguales 
 * @returns si el numero de campus seleccionados es igual a los select 
 */

  isAllSelected() {
    this.numCampusSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numCampusSeleccionado === numRows;
  }

  /**
   * Funcion que checa si los select estan seleccionados, si no los selecciona
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: Campus): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  /**
   * Funcion que checa si los select estan todos seleccionados y de ser asi los limpia, si no los selecciona todos
   */

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
