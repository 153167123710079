import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { LeadService } from 'src/app/_services';
import {
  GeneralInformationTabComponent,
  LeadAcademicInterestComponent,
  LeadInteractionsTabComponent,
  LeadPersonalInformationTabComponent,
} from './index';

@Component({
  selector: 'app-lead-detail-modal',
  templateUrl: './lead-detail-modal.component.html',
  styleUrls: ['./lead-detail-modal.component.scss'],
})
export class LeadDetailModalComponent implements OnInit {
  public infoLead$: Observable<boolean>;
  public infoLead: boolean;
  public infoNewLead: any;
  constructor(
    public dialog: MatDialogRef<LeadDetailModalComponent>,
    private readonly leadService: LeadService,
    private snackService: SnackService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.leadService.SetInfoLead(this.data);
    }
    this.infoLead$ = this.leadService.GetInfoLead();

    this.infoLead$.subscribe((bool) => (this.infoLead = bool));
  }

  @ViewChild(GeneralInformationTabComponent) generalinfoComponent: GeneralInformationTabComponent;
  @ViewChild(LeadPersonalInformationTabComponent) personalinfoComponent: LeadPersonalInformationTabComponent;
  @ViewChild(LeadAcademicInterestComponent) academicinfoComponent: LeadAcademicInterestComponent;
  @ViewChild(LeadInteractionsTabComponent) interactionsComponent: LeadInteractionsTabComponent;

  public CerrarModal(): void {
    this.dialog.close();
  }

  // ngOnDestroy(): void {
  //   if (this.leadService.updateTabsSubscription != null) {
  //     this.leadService.updateTabsSubscription.unsubscribe();
  //   }
  // }

  public async LeadRegister(): Promise<void> {
    this.personalinfoComponent.validateForm();
    if (
      !this.personalinfoComponent.personalInForm.valid ||
      !this.generalinfoComponent.generalForm.valid ||
      !this.academicinfoComponent.interestAForm.valid
    ) {
      this.snackService.mostrarSnackBack('Datos incompletos.');
    } else {
      let genTab = Object.assign(this.generalinfoComponent.generalForm.value);
      let perTab = Object.assign(this.personalinfoComponent.personalInForm.value);
      let acaTab = Object.assign(this.academicinfoComponent.interestAForm.value);

      genTab.campaignF = genTab.campaignF
        ? Array.isArray(genTab.campaignF)
          ? genTab.campaignF.map((item) => item).join(',')
          : genTab.campaignF
        : '';

      acaTab.campusF = acaTab.campusF
        ? Array.isArray(acaTab.campusF)
          ? acaTab.campusF.map((item) => item).join(',')
          : acaTab.campusF
        : '';
      acaTab.nivelF = acaTab.nivelF
        ? Array.isArray(acaTab.nivelF)
          ? acaTab.nivelF.map((item) => item).join(',')
          : acaTab.nivelF
        : '';
      acaTab.programaF = acaTab.programaF
        ? Array.isArray(acaTab.programaF)
          ? acaTab.programaF.map((item) => item).join(',')
          : acaTab.programaF
        : '';
      acaTab.periodoF = acaTab.periodoF
        ? Array.isArray(acaTab.periodoF)
          ? acaTab.periodoF.map((item) => item).join(',')
          : acaTab.periodoF
        : '';

      let interactions = JSON.parse(this.leadService.GetInteractions());
      let newLead: any = {
        Lead: {
          leadId: 0,
          //gen
          LeadStageId: genTab.funnelsF == -1 ? null : genTab.funnelsF,
          //per
          Name: perTab.nombre,
          MiddleName: perTab.nombre2,
          Lastname: perTab.apellidoPaterno,
          LastnameSecondary: perTab.apellidoMaterno,
          Title: perTab.titulo,
          BirthDate: perTab.fechaNacimiento,
          Email: perTab.correo,
          AltEmail: perTab.correoalt,
          Mobile: perTab.movil,
          Phone: perTab.telefono,
          //gen
          Source: genTab.sourceF,
          Qualification: genTab.qualificationF,
          Comments: genTab.commentsF,
          //aca
          ProgramId: acaTab.programaAcademico > 0 ? acaTab.programaAcademico : '',
          TermId: acaTab.periodo > 0 ? acaTab.periodo : '',
          CampusId: acaTab.campus > 0 ? acaTab.campus : '',
        },
        //gen
        CampaignIDs: genTab.campaignF.length != 0 ? genTab.campaignF : '',
        //per
        LeadAddress:
          //[
          {
            AddressTypeId: perTab.tipoDireccionId,
            StateId: perTab.entidadFederativa,
            County: perTab.colonia,
            City: perTab.ciudad,
            Address1: perTab.calle,
            Address2: perTab.numeroExterior,
            Address3: perTab.numeroInterior,
            ZipCode: perTab.codigoPostal,
          },

        LeadContact: [
          {
            RelationTypeId: 2,
            Name: perTab.pnombreMadre,
            MiddleName: perTab.snombreMadre,
            Lastname: perTab.apellidoPMadre,
            LastnameSecondary: perTab.apellidoMMadre,
            Title: perTab.tituloM,
            Email: perTab.correoMadre,
            Phone: perTab.telefonoMadre,
          },
          {
            RelationTypeId: 1,
            Name: perTab.pNombrePadre,
            MiddleName: perTab.sNombrePadre,
            Lastname: perTab.apellidoPPadre,
            LastnameSecondary: perTab.apellidoMPadre,
            Title: perTab.tituloP,
            Email: perTab.correoPadre,
            Phone: perTab.telefonoPadre,
          },
          {
            RelationTypeId: 4,
            Name: perTab.pNombreTutor,
            MiddleName: perTab.sNombreTutor,
            Lastname: perTab.apellidoMTutor,
            LastnameSecondary: perTab.apellidoPTutor,
            Title: perTab.tituloT,
            Email: perTab.correoTutor,
            Phone: perTab.telefonoTutor,
          },
        ],
        //inter
        LeadInteraction: interactions ? interactions : [],
        //aca
        InterestCampusIDs: acaTab.campusF,
        InterestLevelIDs: acaTab.nivelF,
        InterestProgramIDs: acaTab.programaF,
        InterestTermIDs: acaTab.periodoF,
        CreationUser: 0,
      };
      newLead.LeadContact = newLead.LeadContact.filter((item) =>
        item.name ? item : '' || (item.Email ? item : '' || item.Phone ? item : '')
      );
      newLead.LeadAddress = newLead.LeadAddress.StateId != 0 ? newLead.LeadAddress : '';
      newLead.LeadInteraction = newLead.LeadInteraction.filter((item) => (item.InteractionId != 0 ? item : ''));

      if (this.data) {
        await this.updateLead(newLead);
      } else {
        await this.newLead(newLead);
      }
    }
  }

  private async newLead(lead: any): Promise<void> {
    this.leadService.postLeads(lead).subscribe(
      (response: ApiResponse<any>) => {
        if (response.success) {
          this.snackService.mostrarSnackBack('Se ha registrado la información.');
          this.ngOnInit();
        } else {
          this.snackService.mostrarSnackBack('La información no pudo ser registrada.');
        }
      },
      (err) => {
        this.snackService.mostrarSnackBack('La información no pudo ser registrada.');
      }
    );
  }

  private async updateLead(lead: any): Promise<void> {
    lead.Lead.leadId = this.data.leadId;
    this.leadService.putLead(lead).subscribe(
      (response: ApiResponse<any>) => {
        if (response.success) {
          this.snackService.mostrarSnackBack('Se ha registrado la información.');
          this.ngOnInit();
        } else {
          this.snackService.mostrarSnackBack('La información no pudo ser registrada.');
        }
      },
      (err) => {
        this.snackService.mostrarSnackBack('La información no pudo ser registrada.');
      }
    );
  }
}
