import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { EstatusMigratorioDto } from '@app/_models/EstatusMigratorioDto';
import { RazaDto } from '@app/_models/RazaDto';
import { TipoRelacion } from '@app/_models/tipo-relacion';
import { TipoDocumentoDto } from '@app/_models/TipoDocumentoDto';
import { EstatusMigratorioService } from '@app/_services/estatus-migratorio.service';
import { RazaService } from '@app/_services/raza.service';
import { TipoDocumentoService } from '@app/_services/tipo-documento.service';
import { TipoRelacionService } from '@app/_services/tipo-relacion.service';
import { Observable, Subscription } from 'rxjs';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { EntidadFederativa } from 'src/app/_models/entidad-federativa';
import { EstadoCivil } from 'src/app/_models/estado-civil';
import { EstadoCuentaDto } from 'src/app/_models/estado-cuenta-dto';
import { Genero } from 'src/app/_models/genero';
import { GrupoSanguineo } from 'src/app/_models/grupo-sanguineo';
import { Institucion } from 'src/app/_models/institucion';
import { Nivel } from 'src/app/_models/nivel';
import { Pais } from 'src/app/_models/pais';
import { TipoDireccion } from 'src/app/_models/tipo-direccion';
import { TipoEmpresa } from 'src/app/_models/tipo-empresa';
import { TipoPuestoEmpresa } from 'src/app/_models/tipo-puesto-empresa';
import { TipoTelefono } from 'src/app/_models/tipo-telefono';
import { TipoAdmisionEnum } from 'src/app/_models/tipoAdmision.enum';
import { DatosPersonaRelacionada } from 'src/app/_models/usuario';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { BiografiaService } from 'src/app/_services/biografia.service';
import { DireccionService } from 'src/app/_services/direccion.service';
import { EmpresaService } from 'src/app/_services/empresa.service';
import { EstadoCivilService } from 'src/app/_services/estado-civil.service';
import { GeneroService } from 'src/app/_services/genero.service';
import { GrupoSanguineoService } from 'src/app/_services/grupo-sanguineo.service';
import { InstitucionService } from 'src/app/_services/institucion.service';
import { NivelService } from 'src/app/_services/nivel.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-datos-personales-modal',
  templateUrl: './datos-personales-modal.component.html',
  styleUrls: ['./datos-personales-modal.component.scss']
})
export class DatosPersonalesModalComponent implements OnInit {

  /** Formularios necesarios */
  public datosPersonalesForm: FormGroup;
  public datosContactoForm: FormGroup;
  public datosBiograficosForm: FormGroup;
  public datosFamiliaresForm: FormGroup;
  public datosEmergenciaForm: FormGroup;
  public datosMedicosForm: FormGroup;
  public datosEstudiosForm: FormGroup;
  public datosFacturacionForm: FormGroup;
  public datosOcupacionForm: FormGroup;

  /** Suscripciones a los select */
  public subscriptionTipoDireccion: Subscription;
  public subscriptionPais: Subscription;
  public subscriptionEstado: Subscription;
  public subscriptionTipoTelefono: Subscription;
  public subscriptionTipoEmpresa: Subscription;
  public subscriptionPuesto: Subscription;

  /** Arreglos que guardan los select  */
  public tipoDireccionList: TipoDireccion[] = [];
  public paisList: Pais[] = [];
  public paisListBiografia: Pais[] = [];
  public estadoList: EntidadFederativa[] = [];
  public estadoListBiografia: EntidadFederativa[] = [];
  public tipoTelefonoList: TipoTelefono[] = [];
  public sexoList: Genero[] = [];
  public generoList: Genero[] = [];
  public estadoCivilList: EstadoCivil[];
  public sanguineoList: GrupoSanguineo[] = [];
  public nivelList: Nivel[] = [];
  public tipoEmpresaList: TipoEmpresa[] = [];
  public tipoPuestoEmpresaList: TipoPuestoEmpresa[] = [];

  /** Variables necesarias para renderizar */
  public renderizarForm: boolean = false;
  public datosUsuarioBusqueda$: Observable<UsuarioIngreso>;
  public usuarioMenorEdad$: Observable<boolean>;
  public datosUsuarioBusqueda: UsuarioIngreso;
  public curpEncontrado: boolean = false;
  public usuarioMenorEdad: boolean;
  public estudiosPreviosCrear: boolean = false;
  public institucion: Institucion;
  public curpNeed: boolean = false;
  public moduloEditar: number = 0;

  /** Tipos de accion para llenar los datos  */
  public tipoAccionFotografia: number;
  public tipoAccionDireccion: number;
  public tipoAccionTelefono: number;
  public tipoAccionBiografia: number;
  public tipoAccionFamiliar: number;
  public tipoAccionEstudiosPrevios: number;
  public tipoAccionDatoFiscal: number;
  public tipoAccionDatosMedicos: number;
  public tipoAccionTipoEmpresa: number;
  public tipoAccionTipoPuesto: number;
  public tipoAccionFamiliarPapa: DatosPersonaRelacionada;
  public tipoAccionFamiliarMama: DatosPersonaRelacionada;
  public tipoAccionFamiliarTutor: DatosPersonaRelacionada;
  public tipoAccionFamiliarEmergencia: DatosPersonaRelacionada;
  public tipoAccionFamiliarObli: DatosPersonaRelacionada;

  public razaList: RazaDto[] = [];
  public estatusMigratorioList: EstatusMigratorioDto[] = [];
  public tipoDocumentoList: TipoDocumentoDto[] = [];
  public familiarList: TipoRelacion[] = [];

  /** Variables para validar CURP */
  public CURPencontrado: boolean = false;

  constructor(public nuevoIngresoService: NuevoIngresoService,
    private utileriasService: UtileriasService,
    private direccionService: DireccionService,
    private grupoSanguineoService: GrupoSanguineoService,
    private nivelService: NivelService,
    private generoService: GeneroService,
    private estadoCivilService: EstadoCivilService,
    private empresaService: EmpresaService,
    private institucionService: InstitucionService,
    private biografiaService: BiografiaService,
    private razaService: RazaService,
    private estatusMigratorioService: EstatusMigratorioService,
    private tipoDocumentoService: TipoDocumentoService,
    private tipoRelacionService: TipoRelacionService) { }

  ngOnInit(): void {
    this.llamarDatos();
  }

  /**
   * Se inicializa el formulario con los dos datos cargados 
   */
  public inicializarForm() {
    console.log("DATA PARA DATOS BIOGRAFICOS", this.datosUsuarioBusqueda)
    this.datosPersonalesForm = new FormGroup({
      //Datos personales
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      nombre: new FormControl(this.datosUsuarioBusqueda.nombre, [Validators.required]),
      segundoNombre: new FormControl(this.datosUsuarioBusqueda.segundoNombre),
      apellidoPaterno: new FormControl(this.datosUsuarioBusqueda.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(this.datosUsuarioBusqueda.apellidoMaterno),
      fechaNacimiento: new FormControl(this.utileriasService.formatearFecha(this.datosUsuarioBusqueda.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD'), [Validators.required]),
      //Datos de Contacto
      tipoDireccionId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.direccion[0].tipoDireccionId, [Validators.required]),
      paisId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.ciudadaniaPaisId, [Validators.required]),
      entidadFederativaId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.direccion[0].entidadFederativaId, [Validators.required]),
      calle: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].calle, [Validators.required]),
      ciudad: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].ciudad, [Validators.required]),
      numeroExterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].numeroExterior, [Validators.required]),
      numeroInterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].numeroInterior),
      colonia: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].colonia, [Validators.required]),
      codigoPostal: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].codigoPostal, [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      tipoTelefonoId: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.telefono[0].tipoTelefonoId, [Validators.required]),
      numero: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.telefono[0].numero, [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      extension: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.telefono[0].extension),
      correo: new FormControl(this.datosUsuarioBusqueda.correo, [Validators.required, Validators.email]),

      otroTipoTelefonoId: new FormControl(this.datosUsuarioBusqueda.telefono.length > 1 ? this.datosUsuarioBusqueda.telefono[1].tipoTelefonoId : ''),
      otroNumero: new FormControl(this.datosUsuarioBusqueda.telefono.length > 1 ? this.datosUsuarioBusqueda.telefono[1].numero : '', [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      otroExtension: new FormControl(this.datosUsuarioBusqueda.telefono.length > 1 ? this.datosUsuarioBusqueda.telefono[1].extension : ''),
      //Datos Biograficos
      legalGeneroId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.legalGeneroId),
      generoId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR || this.datosUsuarioBusqueda.biografia.generoId == null ? 0 : this.datosUsuarioBusqueda.biografia.generoId),
      ciudadania: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.ciudadaniaPaisId),
      estadoCivilId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR || this.datosUsuarioBusqueda.biografia.estadoCivilId == null ? 0 : this.datosUsuarioBusqueda.biografia.estadoCivilId),
      paisNacimiento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.ciudadaniaPaisId),
      entidadNacimiento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.entidadNacimiento),
      ciudadNacimiento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.ciudadNacimiento),

      raza: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.razaId === null ? 0 : this.datosUsuarioBusqueda.biografia.razaId),
      estatusMigratorio: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.estatusMigratorioId === null ? 0 : this.datosUsuarioBusqueda.biografia.estatusMigratorioId),
      tipoDocumento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.tipoDocumentoId === null ? 0 : this.datosUsuarioBusqueda.biografia.tipoDocumentoId, [Validators.required, this.validateId]),
      curp: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.curp),
      paisDocumento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.paisDocumentoId === null ? 0 : this.datosUsuarioBusqueda.biografia.paisDocumentoId),
      hasCriminalRecord: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? null : this.datosUsuarioBusqueda.biografia.hasCriminalRecord === null ? null : this.datosUsuarioBusqueda.biografia.hasCriminalRecord, [Validators.required]),
      hasAcademicDisciplinaryRecord: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? null : this.datosUsuarioBusqueda.biografia.hasAcademicDisciplinaryRecord === null ? null : this.datosUsuarioBusqueda.biografia.hasAcademicDisciplinaryRecord, [Validators.required]),
      //Datos Familiares
      nombreCompletoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.nombreCompleto),
      telefonoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.correoElectronico, [Validators.email]),
      nombreCompletoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.nombreCompleto),
      telefonoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.correoElectronico, [Validators.email]),
      nombreCompletoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.nombreCompleto),
      telefonoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.correoElectronico, [Validators.email]),

      tipoRelacion: new FormControl(this.tipoAccionFamiliarObli == null ? 0 : this.tipoAccionFamiliarObli.tipoRelacionId),
      nombreContactoObli: new FormControl(this.tipoAccionFamiliarObli == null ? '' : this.tipoAccionFamiliarObli.nombreCompleto),
      telefonoContactoObli: new FormControl(this.tipoAccionFamiliarObli == null ? '' : this.tipoAccionFamiliarObli.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correoContactoObli: new FormControl(this.tipoAccionFamiliarObli == null ? '' : this.tipoAccionFamiliarObli.correoElectronico, [Validators.email]),
      //Contacto Emergencia
      nombreContacto: new FormControl(this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.nombreCompleto),
      telefonoContacto: new FormControl(this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correoContacto: new FormControl(this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.correoElectronico, [Validators.email]),
      //Datos Medicos
      grupoSanguineoId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? null : this.datosUsuarioBusqueda.biografia.grupoSanguineoId),
      alergias: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.alergias),
      enfermedades: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.enfermedades),
      //Estudios Previos
      nivel: new FormControl(this.tipoAccionEstudiosPrevios === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.estudioPrevio[0].nivelId),
      academico: new FormControl(this.tipoAccionEstudiosPrevios === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.estudioPrevio[0].escuela),
      promedio: new FormControl(this.tipoAccionEstudiosPrevios === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.estudioPrevio[0].promedio, [Validators.pattern(/^[1-9]+/)]),
      //Datos de Facturacion
      rfc: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.rfc),
      razonSocial: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.razonSocial),
      tipoDireccionFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.datoFiscal.tipoDireccionId),
      paisFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.ciudadaniaPaisId),
      entidadFederativaFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.datoFiscal.entidadFederativaId),
      calleFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.calle),
      ciudadFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.ciudad),
      numeroExteriorFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.numeroExterior),
      numeroInteriorFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.numeroInterior),
      coloniaFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.colonia),
      codigoPostalFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.codigoPostal, [Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      //Ocupacion
      tipoEmpresaId: new FormControl(this.tipoAccionTipoEmpresa === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.ocupacion.tipoEmpresaId),
      tipoPuestoEmpresaId: new FormControl(this.tipoAccionTipoPuesto === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.ocupacion.tipoPuestoEmpresaId),
    });
    this.obtenerInstitucion();

    /** Se decide si el modulo es nuevo ingreso  */
    if (this.moduloEditar == 1) {
      let menorEdad = this.calcularEdad(this.datosUsuarioBusqueda.biografia.fechaNacimiento);
      this.nuevoIngresoService.definirUsuarioMenorEdad(menorEdad);

      if (menorEdad) {
        this.nombreCompletoTutor.setValidators([Validators.required]);
        this.telefonoTutor.setValidators([Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]);
        this.correoElectronicoTutor.setValidators([Validators.required, Validators.email]);
      } else {
        this.nombreCompletoTutor.setValidators(null);
        this.telefonoTutor.setValidators(null);
        this.correoElectronicoTutor.setValidators(null);
      }
      this.nombreCompletoTutor.updateValueAndValidity();
      this.telefonoTutor.updateValueAndValidity();
      this.correoElectronicoTutor.updateValueAndValidity();


      this.fechaNacimiento.valueChanges.subscribe(checked => {
        let menorEdad = this.calcularEdad(checked);
        if (menorEdad) {
          this.nombreCompletoTutor.setValidators([Validators.required]);
          this.telefonoTutor.setValidators([Validators.required]);
          this.correoElectronicoTutor.setValidators([Validators.required, Validators.email]);
        } else {
          this.nombreCompletoTutor.setValidators(null);
          this.telefonoTutor.setValidators(null);
          this.correoElectronicoTutor.setValidators(null);
        }
        this.nombreCompletoTutor.updateValueAndValidity();
        this.telefonoTutor.updateValueAndValidity();
        this.correoElectronicoTutor.updateValueAndValidity();
      });
    }

    if (this.datosUsuarioBusqueda.alumno[0].programa.tipoAdmisionId === TipoAdmisionEnum.MANUAL) {
      this.legalGeneroId.setValidators([Validators.required, this.validateId]);
      this.ciudadania.setValidators([Validators.required, this.validateId]);
      this.paisNacimiento.setValidators([Validators.required, this.validateId]);
      this.entidadNacimiento.setValidators([Validators.required, this.validateId]);
      this.ciudadNacimiento.setValidators([Validators.required]);
      this.nivel.setValidators([Validators.required, this.validateId]);
      this.academico.setValidators([Validators.required]);
      this.promedio.setValidators([Validators.required]);
    } else {
      this.legalGeneroId.setValidators(null);
      this.ciudadania.setValidators(null);
      this.paisNacimiento.setValidators(null);
      this.entidadNacimiento.setValidators(null);
      this.ciudadNacimiento.setValidators(null);
      this.nivel.setValidators(null);
      this.academico.setValidators(null);
      this.promedio.setValidators(null);
    }
    this.legalGeneroId.updateValueAndValidity();
    this.ciudadania.updateValueAndValidity();
    this.paisNacimiento.updateValueAndValidity();
    this.entidadNacimiento.updateValueAndValidity();
    this.ciudadNacimiento.updateValueAndValidity();
    this.nivel.updateValueAndValidity();
    this.academico.updateValueAndValidity();
    this.promedio.updateValueAndValidity();

    this.onPaisSelected(this.datosPersonalesForm.controls['paisId'].value);
    this.onPaisSelectedBiografia(this.datosPersonalesForm.controls['paisNacimiento'].value);
    this.renderizarForm = true;

    this.datosPersonalesForm.valueChanges.subscribe(val => {
      this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.datosPersonalesForm.valid);
      this.nuevoIngresoService.definirCambioNombreForms("Datos Personales");
      this.nuevoIngresoService.setChangesPersonalData(true);
    });
  }

  /**
   * Funcion que calcula la edad del usuario y detecta si es menor de edad
   * @param fecha 
   * @returns 
   */
  public calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    if (edad < 18) {
      return true
    } else {
      return false;
    }
  }

  //Datos personales
  get usuarioId() { return this.datosPersonalesForm.get('usuarioId'); }
  get nombre() { return this.datosPersonalesForm.get('nombre'); }
  get segundoNombre() { return this.datosPersonalesForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.datosPersonalesForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.datosPersonalesForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.datosPersonalesForm.get('fechaNacimiento'); }
  //Datos de Contacto
  get tipoDireccionId() { return this.datosPersonalesForm.get('tipoDireccionId'); }
  get paisId() { return this.datosPersonalesForm.get('paisId'); }
  get entidadFederativaId() { return this.datosPersonalesForm.get('entidadFederativaId'); }
  get tipoTelefonoId() { return this.datosPersonalesForm.get('tipoTelefonoId'); }
  get ciudad() { return this.datosPersonalesForm.get('ciudad'); }
  get calle() { return this.datosPersonalesForm.get('calle'); }
  get numeroExterior() { return this.datosPersonalesForm.get('numeroExterior'); }
  get numeroInterior() { return this.datosPersonalesForm.get('numeroInterior'); }
  get colonia() { return this.datosPersonalesForm.get('colonia'); }
  get codigoPostal() { return this.datosPersonalesForm.get('codigoPostal'); }
  get numero() { return this.datosPersonalesForm.get('numero'); }
  get extension() { return this.datosPersonalesForm.get('extension'); }
  get correo() { return this.datosPersonalesForm.get('correo'); }

  get otroTipoTelefonoId() { return this.datosPersonalesForm.get('otroTipoTelefonoId'); }
  get otroNumero() { return this.datosPersonalesForm.get('otroNumero'); }
  get otroExtension() { return this.datosPersonalesForm.get('otroExtension'); }
  //Datos Biograficos
  get legalGeneroId() { return this.datosPersonalesForm.get('legalGeneroId'); }
  get ciudadania() { return this.datosPersonalesForm.get('ciudadania'); }
  get curp() { return this.datosPersonalesForm.get('curp'); }
  get paisNacimiento() { return this.datosPersonalesForm.get('paisNacimiento'); }
  get entidadNacimiento() { return this.datosPersonalesForm.get('entidadNacimiento'); }
  get ciudadNacimiento() { return this.datosPersonalesForm.get('ciudadNacimiento'); }

  get tipoDocumento() { return this.datosPersonalesForm.get('tipoDocumento'); }
  get estatusMigratorio() { return this.datosPersonalesForm.get('estatusMigratorio'); }
  get raza() { return this.datosPersonalesForm.get('raza'); }
  get paisDocumento() { return this.datosPersonalesForm.get('paisDocumento'); }
  get hasCriminalRecord() { return this.datosPersonalesForm.get('hasCriminalRecord'); }
  get hasAcademicDisciplinaryRecord() { return this.datosPersonalesForm.get('hasAcademicDisciplinaryRecord'); }
  //Datos Familiares
  get nombreCompletoMadre() { return this.datosPersonalesForm.get('nombreCompletoMadre'); }
  get telefonoMadre() { return this.datosPersonalesForm.get('telefonoMadre'); }
  get correoElectronicoMadre() { return this.datosPersonalesForm.get('correoElectronicoMadre'); }
  get nombreCompletoPadre() { return this.datosPersonalesForm.get('nombreCompletoPadre'); }
  get telefonoPadre() { return this.datosPersonalesForm.get('telefonoPadre'); }
  get correoElectronicoPadre() { return this.datosPersonalesForm.get('correoElectronicoPadre'); }
  get nombreCompletoTutor() { return this.datosPersonalesForm.get('nombreCompletoTutor'); }
  get telefonoTutor() { return this.datosPersonalesForm.get('telefonoTutor'); }
  get correoElectronicoTutor() { return this.datosPersonalesForm.get('correoElectronicoTutor'); }

  get tipoRelacion() { return this.datosPersonalesForm.get('tipoRelacion'); }
  get nombreContactoObli() { return this.datosPersonalesForm.get('nombreContactoObli'); }
  get telefonoContactoObli() { return this.datosPersonalesForm.get('telefonoContactoObli'); }
  get correoContactoObli() { return this.datosPersonalesForm.get('correoContactoObli'); }
  //Contacto Emergencia
  get nombreContacto() { return this.datosPersonalesForm.get('nombreContacto'); }
  get telefonoContacto() { return this.datosPersonalesForm.get('telefonoContacto'); }
  get correoContacto() { return this.datosPersonalesForm.get('correoContacto'); }
  //Datos Medicos
  get grupoSanguineoId() { return this.datosPersonalesForm.get('grupoSanguineoId'); }
  get alergias() { return this.datosPersonalesForm.get('alergias'); }
  get enfermedades() { return this.datosPersonalesForm.get('enfermedades'); }
  //Estudios Previos
  get nivel() { return this.datosPersonalesForm.get('nivel'); }
  get academico() { return this.datosPersonalesForm.get('academico'); }
  get promedio() { return this.datosPersonalesForm.get('promedio'); }
  //Datos Facturacion
  get tipoDireccionFacturacionId() { return this.datosPersonalesForm.get('tipoDireccionFacturacionId'); }
  get paisFacturacionId() { return this.datosPersonalesForm.get('paisFacturacionId'); }
  get entidadFederativaFacturacionId() { return this.datosPersonalesForm.get('entidadFederativaFacturacionId'); }
  get ciudadFacturacion() { return this.datosPersonalesForm.get('ciudadFacturacion'); }
  get calleFacturacion() { return this.datosPersonalesForm.get('calleFacturacion'); }
  get numeroExteriorFacturacion() { return this.datosPersonalesForm.get('numeroExteriorFacturacion'); }
  get numeroInteriorFacturacion() { return this.datosPersonalesForm.get('numeroInteriorFacturacion'); }
  get coloniaFacturacion() { return this.datosPersonalesForm.get('coloniaFacturacion'); }
  get codigoPostalFacturacion() { return this.datosPersonalesForm.get('codigoPostalFacturacion'); }
  //Datos Fiscales
  get tipoEmpresa() { return this.datosPersonalesForm.get('tipoEmpresaId'); }
  get puesto() { return this.datosPersonalesForm.get('tipoPuestoEmpresaId'); }

  /**
   * Funciones para validar los select y que no tenga el valor en 0
   */
  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido.";
    }
    return error;
  }
  public getErrorFormSexo(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Sexo Legal es requerido.";
    }
    return error;
  }
  public getErrorFormGenero(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Género es requerido.";
    }
    return error;
  }
  public getErrorFormCiudad(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Ciudadanía es requerido.";
    }
    return error;
  }
  public getErrorFormCivil(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Estado Civil es requerido.";
    }
    return error;
  }
  public getErrorFormPais(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "País es requerido.";
    }
    return error;
  }
  public getErrorFormEstado(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Estado es requerido.";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Se manda a llamar desde el modal padre para cargar los datos del usuario y una vez cargados iniciar el formulario con los datos
   * cargados si la accion es EDITAR o si hay que crearlos 
   * 1 = Nuevo Ingreso
   * Al agregar más componentes agregar más If según el modulo al que se envian los datos
   * @param modulo 
   * 
   */
  public renderizarFormulario(modulo: number) {

    this.moduloEditar = modulo;
    if (this.moduloEditar == 1) {
      // this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
      // this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
      this.usuarioMenorEdad$ = this.nuevoIngresoService.obtenerUsuarioMenorEdad$();
      this.usuarioMenorEdad$.subscribe(menorEdad => this.usuarioMenorEdad = menorEdad);
    }

    if (this.datosUsuarioBusqueda != null) {
      if (this.datosUsuarioBusqueda.fotografia != null) {
        this.tipoAccionFotografia = AccionEnum.EDITAR;
      } else {
        this.tipoAccionFotografia = AccionEnum.CREAR;
      }
    }
    if (this.datosUsuarioBusqueda.direccion.length >= 1) {
      this.tipoAccionDireccion = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDireccion = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.telefono.length >= 1) {
      this.tipoAccionTelefono = AccionEnum.EDITAR;
    } else {
      this.tipoAccionTelefono = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.biografia.legalGeneroId != null) {
      this.tipoAccionBiografia = AccionEnum.EDITAR;
    } else {
      this.tipoAccionBiografia = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.personaRelacionadaUsuario.length >= 1) {
      this.tipoAccionFamiliarPapa = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1);
      this.tipoAccionFamiliarMama = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2);
      this.tipoAccionFamiliarTutor = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3);
      this.tipoAccionFamiliarEmergencia = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 4) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 4);
      this.tipoAccionFamiliarObli = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId !== 4 && x.tipoRelacionId !== 3 && x.tipoRelacionId !== 2 && x.tipoRelacionId !== 1) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId !== 4 && x.tipoRelacionId !== 3 && x.tipoRelacionId !== 2 && x.tipoRelacionId !== 1);
    } else {
      this.tipoAccionFamiliarPapa = null;
      this.tipoAccionFamiliarMama = null;
      this.tipoAccionFamiliarTutor = null;
      this.tipoAccionFamiliarEmergencia = null;
      this.tipoAccionFamiliarObli = null;
    }
    if (this.datosUsuarioBusqueda.estudioPrevio.length >= 1) {
      this.tipoAccionEstudiosPrevios = AccionEnum.EDITAR;
      this.estudiosPreviosCrear = false;
    } else {
      this.tipoAccionEstudiosPrevios = AccionEnum.CREAR;
      this.estudiosPreviosCrear = true;
    }
    if (this.datosUsuarioBusqueda.datoFiscal != null) {
      this.tipoAccionDatoFiscal = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDatoFiscal = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.ocupacion != null) {
      if (this.datosUsuarioBusqueda.ocupacion.tipoEmpresaId != null) {
        this.tipoAccionTipoEmpresa = AccionEnum.EDITAR;
      } else {
        this.tipoAccionTipoEmpresa = AccionEnum.CREAR;
      }
    } else {
      this.tipoAccionTipoEmpresa = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.ocupacion != null) {
      if (this.datosUsuarioBusqueda.ocupacion.tipoPuestoEmpresaId != null) {
        this.tipoAccionTipoPuesto = AccionEnum.EDITAR;
      } else {
        this.tipoAccionTipoPuesto = AccionEnum.CREAR;
      }
    } else {
      this.tipoAccionTipoPuesto = AccionEnum.CREAR;
    }
    this.inicializarForm();
  }

  /**
   * Obtiene la institucion donde se encuentra inscrito para validar si el curp es necesario o no
   */
  public obtenerInstitucion(tipoAdmisionId?: number): void {
    let _tipoAdmisionId: number = tipoAdmisionId;
    if (!tipoAdmisionId) {
      _tipoAdmisionId = this.datosUsuarioBusqueda.alumno[0].programa.tipoAdmisionId;
    }
    this.institucionService.obtenerInstitucion().subscribe(
      institucion => {
        this.institucion = institucion;
        if (institucion.pais === 484) {
          if (_tipoAdmisionId === TipoAdmisionEnum.MANUAL) {
            this.curpNeed = true;
            this.curp.setValidators([Validators.required]);
          } else {
            this.curpNeed = false;
            this.curp.setValidators(null);
          }
          this.curp.updateValueAndValidity();
          this.nuevoIngresoService.definirCambioNombreForms(null);
          this.nuevoIngresoService.setChangesPersonalData(false);
        } else {
          this.curpNeed = false;
          this.curp.setValidators(null);
          this.curp.updateValueAndValidity();
          this.nuevoIngresoService.definirCambioNombreForms(null);
          this.nuevoIngresoService.setChangesPersonalData(false);
        }
      }
    );
  }

  /**
   * Metodo paraa aplicar las validaciones si hay un cambio en el programa
   */
  public aplicarValidaciones(tipoAdmisionId: number): void {
    if (tipoAdmisionId === TipoAdmisionEnum.MANUAL) {
      this.legalGeneroId.setValidators([Validators.required, this.validateId]);
      this.ciudadania.setValidators([Validators.required, this.validateId]);
      this.paisNacimiento.setValidators([Validators.required, this.validateId]);
      this.entidadNacimiento.setValidators([Validators.required, this.validateId]);
      this.ciudadNacimiento.setValidators([Validators.required]);
      this.nivel.setValidators([Validators.required, this.validateId]);
      this.academico.setValidators([Validators.required]);
      this.promedio.setValidators([Validators.required]);
    } else {
      this.legalGeneroId.setValidators(null);
      this.ciudadania.setValidators(null);
      this.paisNacimiento.setValidators(null);
      this.entidadNacimiento.setValidators(null);
      this.ciudadNacimiento.setValidators(null);
      this.nivel.setValidators(null);
      this.academico.setValidators(null);
      this.promedio.setValidators(null);
    }
    this.legalGeneroId.updateValueAndValidity();
    this.ciudadania.updateValueAndValidity();
    this.paisNacimiento.updateValueAndValidity();
    this.entidadNacimiento.updateValueAndValidity();
    this.ciudadNacimiento.updateValueAndValidity();
    this.nivel.updateValueAndValidity();
    this.academico.updateValueAndValidity();
    this.promedio.updateValueAndValidity();
  }

  /**
   * Metodo para encontrar un curp duplicado o ya existente
   * @param curp 
   */
  public CURPInsert(curp: string) {
    if (curp.trim().length == 18) {
      let usuarioId = this.datosUsuarioBusqueda.usuarioId;
      if (!usuarioId) {
        usuarioId = 0;
      }
      this.biografiaService.obtenerBiografiaByCurp(curp, usuarioId).subscribe(
        biografia => {
          if (biografia == null) {
            return this.CURPencontrado = false;
          } else {
            return this.CURPencontrado = true;
          }
        });
    }
  }

  public paisSelect(paisId: number, field: string) {
    if (field === "paisDocumento") {
      let campoCiudadania = this.datosPersonalesForm.get('ciudadania')?.value;
      if (campoCiudadania === 0) {
        this.datosPersonalesForm.patchValue({
          ciudadania: paisId
        });
      }
    }
    else {
      let campoPaisDocumento = this.datosPersonalesForm.get('paisDocumento')?.value;
      if (campoPaisDocumento === 0) {
        this.datosPersonalesForm.patchValue({
          paisDocumento: paisId
        });
      }
    }
  }

  public llamarDatos(): void {
    this.getTipoDireccion();
    this.getPais();
    this.getTipoTelefono();
    this.getTipoGenero();
    this.getTipoSexo();
    this.getTipoCivil();
    this.getTipoSanguineo();
    this.getTipoNivel();
    this.getPaisBiografia();
    this.getTipoEmpresa();
    this.getTipoPuesto();
    this.getRaza();
    this.getEstatusMigratorio();
    this.getTipoDocumento();
    this.getTipoRelacion();
  }

  public getTipoDireccion() {
    this.subscriptionTipoDireccion = this.direccionService.obtenerTipoDireccion().subscribe(
      (tipoDireccion: TipoDireccion[]) => {
        this.tipoDireccionList = tipoDireccion;
      }
    );
  }

  public getPais() {
    this.subscriptionPais = this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisList = pais;
      }
    );
  }

  public onPaisSelected(pais: number) {
    this.estadoList = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estadoList = estados;
        }
      );
    }
  }

  public getTipoTelefono() {
    this.subscriptionTipoTelefono = this.direccionService.obtenerTipoTelefono().subscribe(
      (tipoTelefono: TipoTelefono[]) => {
        this.tipoTelefonoList = tipoTelefono;
      });
  }

  public getTipoSexo() {
    this.generoService.obtenerGenero().subscribe(
      (tipoSexo: Genero[]) => {
        this.sexoList = tipoSexo;
      }
    );
  }
  public getTipoGenero() {
    this.generoService.obtenerGenero().subscribe(
      (tipoGenero: Genero[]) => {
        this.generoList = tipoGenero;
      }
    );
  }
  public getTipoSanguineo() {
    this.grupoSanguineoService.obtenerGrupoSanguineo().subscribe(
      (tipoSanguineo: GrupoSanguineo[]) => {
        this.sanguineoList = tipoSanguineo;
      }
    );
  }
  public getTipoCivil() {
    this.estadoCivilService.obtenerEstadoCivil().subscribe(
      (tipoCivil: EstadoCivil[]) => {
        this.estadoCivilList = tipoCivil;
      }
    );
  }

  public getPaisBiografia() {
    this.subscriptionPais = this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisListBiografia = pais;
      });
  }

  public onPaisSelectedBiografia(pais: number) {
    this.estadoListBiografia = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estadoListBiografia = estados;
        })
    }
  }

  public getTipoNivel() {
    this.nivelService.getNivel().subscribe(
      (tiposNivel: Nivel[]) => {
        this.nivelList = tiposNivel;
      }
    );
  }

  public getTipoEmpresa() {
    this.subscriptionTipoEmpresa = this.empresaService.obtenerTipoEmpresa().subscribe(
      (tipoEmpresa: TipoEmpresa[]) => {
        this.tipoEmpresaList = tipoEmpresa;
      });
  }

  public getTipoPuesto() {
    this.subscriptionPuesto = this.empresaService.obtenerTipoPuestoEmpresa().subscribe(
      (tipoPuestoEmpresa: TipoPuestoEmpresa[]) => {
        this.tipoPuestoEmpresaList = tipoPuestoEmpresa;
      });
  }

  /**
* Metodo para traer catalogo de raza
*/
  public getRaza() {
    this.razaService.obtenerRaza().subscribe(
      (response: ApiResponse<RazaDto[]>) => {
        this.razaList = response.data;
      })
  }

  /**
* Metodo para traer catalogo destatusmigratorio
*/
  public getEstatusMigratorio() {
    this.estatusMigratorioService.obtenerEstatusMigratorio().subscribe(
      (response: ApiResponse<EstatusMigratorioDto[]>) => {
        this.estatusMigratorioList = response.data;
      })
  }

  /**
* Metodo para traer catalogo tipo documento
*/
  public getTipoDocumento() {
    this.tipoDocumentoService.obtenerTipoDocumento().subscribe(
      (response: ApiResponse<TipoDocumentoDto[]>) => {
        this.tipoDocumentoList = response.data;
      })
  }

  public getTipoRelacion() {
    this.tipoRelacionService.getTipoRelacion().subscribe(
      (tipoRelacion: TipoRelacion[]) => {
        this.familiarList = tipoRelacion.filter(x => x.clave !== 'PA' && x.clave !== 'MA' && x.clave !== 'TU' && x.clave !== 'EM');
      }
    );
  }

  /**
* Metodos para validar campo vacio
* @param controlName 
* @returns 
*/
  public getErrorFormTipoDocumento(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Tipo de documento es requerido.";
    }
    return error;
  }

  ngOnDestroy(): void {
    if (this.subscriptionTipoEmpresa) {
      this.subscriptionTipoEmpresa.unsubscribe();
    }
    if (this.subscriptionPuesto) {
      this.subscriptionPuesto.unsubscribe();
    }
    if (this.subscriptionTipoDireccion) {
      this.subscriptionTipoDireccion.unsubscribe();
    }
    if (this.subscriptionPais) {
      this.subscriptionPais.unsubscribe();
    }
    if (this.subscriptionEstado) {
      this.subscriptionEstado.unsubscribe();
    }
    if (this.subscriptionTipoTelefono) {
      this.subscriptionTipoTelefono.unsubscribe();
    }
  }
}
