import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NuevoIngreso, UsuarioNuevoIngreso } from 'src/app/_models/nuevo-ingreso';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { NuevoIngresoService } from '../_services/nuevo-ingreso.service';;
import { Periodo } from 'src/app/_models/periodo';
import { Programa, ProgramaActivo } from 'src/app/_models/programa';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Usuario } from '../_models/usuario';
import { NuevoIngresoModalComponent } from './nuevo-ingreso-modal/nuevo-ingreso-modal.component';
import { EliminarCandidatoComponent } from './nuevo-ingreso-modal/eliminar-candidato/eliminar-candidato.component';
import { FormControl, FormGroup } from '@angular/forms';
import { PeriodoService } from '../_services/periodo.service';
import { ProgramaAcademicoService } from '../_services/programa-academico.service';
import { CampusService } from '../_services/campus.service';
import { Campus } from '../_models/campus';
import { MatOption } from '@angular/material/core';
import { SeguimientoCarteraService } from '../_services/seguimiento-cartera.service';
import { Subscription } from 'rxjs';
import { GeneracionExcelServiceService } from '../services/generacion-excel-service.service';
import { InscripcionService } from "src/app/_services/inscripcion.service";
import { EstatusAcademico } from '../_models/inscripcion';
import { TableSharedServicesService } from '../_services/table-shared-services.service';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { EnviarCorreoComponent } from '../_components/enviar-correo/enviar-correo.component';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioNuevoIngresoDto } from '../_models/usuarionuevoingresodto';
import { leadingComment } from '@angular/compiler';
import { EstadoCuentaDto } from '../_models/estado-cuenta-dto';
import { BlockLabelDto } from '@app/_models/BlockLabelDto';
import { BlockService } from '@app/_services/block.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18NextPipe } from 'angular-i18next';
import { Block } from '@app/_models/block';
import { NuevoIngresoAsignarBloqueModalComponent } from './nuevo-ingreso-asignar-bloque-modal/nuevo-ingreso-asignar-bloque-modal.component';
import { FiltroAdminAcademicoAlumnoDto } from '@app/_models/FiltroAdminAcademicoAlumnoDto';
import { TraductorService } from '@app/_services/traductor.service';
import { AttributesDto } from '@app/_models/AttributesDto';
import { AttributesService } from '@app/_services/attributes.service';
@Component({
  selector: 'app-nuevo-ingreso',
  templateUrl: './nuevo-ingreso.component.html',
  styleUrls: ['./nuevo-ingreso.component.scss']
})
export class NuevoIngresoComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['select', 'nombre', 'periodo', 'programaAcademico', 'campus', 'datosPersonales', 'documentos', 'fotografia', 'apoyoFinanciero', 'inscripcion', 'estatus', 'blockName'];
  public dataSource: MatTableDataSource<UsuarioNuevoIngresoDto>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allEstatus') private allEstatus: MatOption;
  @ViewChild('allBloque') private allBloque: MatOption;
  @ViewChild('allAtritbuto') private allAtritbuto: MatOption;
  public Block: Block;

  selection = new SelectionModel<UsuarioNuevoIngresoDto>(true, []);
  public BlockLabel: BlockLabelDto[];
  public programaAcademicoList: Programa[] = [];
  public cicloList: Periodo[] = [];
  public nuevoIngreso: NuevoIngreso;
  public ingresos: NuevoIngreso[] = [];
  public infoCargado: boolean = false;
  public infoCargada: boolean = false;
  public numIngresoSeleccionado: number = 0;
  public datosUsuarioBusqueda: Usuario[];
  public datafilter: UsuarioNuevoIngresoDto[] = [];
  private arrOriginal: UsuarioNuevoIngresoDto[] = [];
  public filtroForm: FormGroup;
  public idNombre = new FormControl();
  public CampusF = new FormControl();
  public ProgramaF = new FormControl();
  public periodoF = new FormControl();
  public estatusF = new FormControl();
  public bloqueF = new FormControl();
  public atributoF = new FormControl();
  public periodos: Periodo[] = [];
  private periodosactivos: number[] = [];
  public programas: ProgramaActivo[] = [];
  public arCampus: Campus[] = [];
  public estatusAcademicoList: EstatusAcademico[] = [];
  private tableSubscription: Subscription = new Subscription();
  public arregloFiltroModificado: any[] = [];
  public arregloFiltroOriginal: any[] = [];
  public valorInicial = 100;
  public attributes: AttributesDto[];
  public existenAtributos: boolean = false;

  constructor(
    public util: UtileriasService,
    public nuevoIngresoService: NuevoIngresoService,
    private paginador: MatPaginatorIntl,
    public periodoservice: PeriodoService,
    public programaservice: ProgramaAcademicoService,
    public campusservice: CampusService,
    private seguimientoService: SeguimientoCarteraService,
    private InscripcionService: InscripcionService,
    private generadorExcel: GeneracionExcelServiceService,
    private tableShared: TableSharedServicesService,
    private dialog: MatDialog,
    private _BlockService: BlockService,
    private snackBar: MatSnackBar,
    private i18nextPipe: I18NextPipe,
    private traductorService: TraductorService,
    public attributesService: AttributesService
  ) {
    this.tableSubscription.add(
      this.tableShared.getTableSubject().subscribe((actualziarTabla: boolean) => {
        if (actualziarTabla) {
          this.LlenarFiltros();
        }
      })
    );
  }
  ngOnDestroy(): void {
    if (this.tableSubscription) {
      this.tableSubscription.unsubscribe();
    }
  }

  /*NUEVA VERSION*/
  async ngOnInit(): Promise<void> {
    if (this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription === undefined) {
      this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription = this.nuevoIngresoService.invocarObtenerCandidatos.subscribe(() => {
        this.LlenarFiltros();
      });
      this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription === undefined;
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    await this.inicializarForm().then((_) => {
      this.LlenarFiltros();
    });
  }

  /**
   * Inicializa el formulario y setea los campos usados
   */
  public inicializarForm(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.filtroForm = new FormGroup({
        periodoF: new FormControl([]),
        idnombre: new FormControl(),
        ProgramaF: new FormControl([]),
        CampusF: new FormControl([]),
        estatusF: new FormControl([]),
        bloqueF: new FormControl([]),
        atributoF: new FormControl([])
      });
      this.CampusF.setValue(["-1"]);
      this.ProgramaF.setValue(["-1"]);
      this.periodoF.setValue(["-1"]);
      this.bloqueF.setValue(["-1"]);
      this.estatusF.setValue(["-1"]);
      this.atributoF.setValue(["-1"]);
      resolve();
    });
  }

  /**
   * Hace un llenado de todos los filtros, ingresa a las funciones de obtencion de datos para su carga
   */
  public LlenarFiltros(): void {
    this.obtenerPeriodo();
  }

  /**
   * Obtiene los campus del periodo
   */
  public obtenerPeriodo(): void {
    let today = new Date();
    this.nuevoIngresoService.getPeriodo().subscribe(
      (periodos: Periodo[]) => {
        this.periodos = periodos;

        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio <= today && fin >= today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if (this.periodosactivos.length > 0) {
          this.periodoF.setValue([...this.periodosactivos]);
        }

        console.log("this.periodo", this.periodoF.value);

        // this.periodoF.setValue([...this.periodos.map(item => item.periodoId), 0]);
        // this.filtroForm.patchValue({
        //   periodoF: this.periodos.map(item => item.periodoId)
        // });
        this.obtenerProgramas();
      });
  }

  /**
   * Obtiene los campus del programa y setea su valor en todos
   */
  public obtenerProgramas(): void {
    this.nuevoIngresoService.getPrograma().subscribe(
      (programas: Programa[]) => {
        this.programas = programas;
        this.ProgramaF.setValue([...this.programas.map(item => item.programaId), 0]);
        this.filtroForm.patchValue({
          ProgramaF: this.programas.map(item => item.programaId)
        });
        this.obtenerCampus();
      });
  }

  public nombreEstatus(estatus?: number) {
    if (estatus !== 0) {
      let mensaje: string = this.traductorService.translate(`_catalogos.estatus-academico.nombre-${estatus}`);
      return mensaje;
    } else {
      return "";
    }
  }

  /**
   * Obtiene los campus del catalogo y setea su valor en todos
   */
  public obtenerCampus(): void {
    this.campusservice.obtenerCampus().subscribe((campus: Campus[]) => {
      this.arCampus = campus;
      this.CampusF.setValue([...this.arCampus.map(item => item.campusId), 0]);
      this.filtroForm.patchValue({
        CampusF: this.arCampus.map(item => item.campusId)
      });
      this.obtenerEstatus();
    });
  }

  /**
   * Obtiene los campus del estatus academico
   */
  public obtenerEstatus(): void {
    this.InscripcionService.GetEstatusAcademicos().subscribe((status: ApiResponse<EstatusAcademico[]>) => {
      this.estatusAcademicoList = status.data.filter((estatus) => {
        return estatus.clave == 'ADMTD' || estatus.clave == 'NOADMTD' || estatus.clave == 'CANC' || estatus.clave == 'CANDI' || estatus.clave == 'ALUM'
      });
      this.estatusF.setValue([...this.estatusAcademicoList.map(item => item.estatusAcademicoId), 0]);
      this.filtroForm.patchValue({
        estatusF: this.estatusAcademicoList.map(item => item.estatusAcademicoId)
      });
      this.obtenerBloques();
    });
  }

  /**
   * Metodo que consulta los bloques
   */
  public obtenerBloques() {
    this._BlockService.GetBlockLabelDto().subscribe((respuesta: ApiResponse<BlockLabelDto[]>) => {
      this.BlockLabel = respuesta.data;
      this.obtenerAtributos();
    })
  }

  /**
* Metodo que consulta los atributos
*/
  public obtenerAtributos() {
    this.attributesService.attributesInstitucion().subscribe((respuesta: ApiResponse<AttributesDto[]>) => {
      this.attributes = respuesta.data;
      this.existenAtributos = this.attributes.length > 0;
      this.filtrar();
    })
  }

  /**
   * Metodo que envia laa informacion de los filtros al back
   */
  public filtrar() {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.periodoF.value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatusAr: this.filtroForm.get('estatusF').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value
    };
    
    console.log("FILTRO", filtro);
    this.obtenerNuevosIngresos(1, this.paginator.pageSize, filtro);
  }

  /**
  * Metodo que detecta el cambio de pagina y llama el servicio para traer la siguiente informacion
  * @param event
  */
  public onPaginateChange(event: any) {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatusAr: this.filtroForm.get('estatusF').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value
    };
    this.obtenerNuevosIngresos(event.pageIndex + 1, event.pageSize, filtro);
  }

  /**
 * Obtiene el catalogo de inscripciones para mostrarlos en la tabla principal, agrega una nueva propiedas llamada nombre completo
 * carga los datos a la tabla principal y agrega los datos a un arreglo original
 * @param tipoAlumno
 * @param pagina
 * @param registrosPagina
 * @param filtro
 */
  public obtenerNuevosIngresos(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto) {
    this.nuevoIngresoService.obtenerNuevosIngresos(pagina, registrosPagina, filtro).subscribe(
      (nuevosIngresos: ApiResponse<any>) => {
        if (nuevosIngresos.data) {
          this.arregloFiltroOriginal = nuevosIngresos.data.alumnosNuevoIngreso;
          this.datafilter = nuevosIngresos.data.alumnosNuevoIngreso;
          this.dataSource = new MatTableDataSource(this.datafilter);
          console.log("dataSource", this.dataSource)
          this.valorInicial = nuevosIngresos.data.cantidadAlumnos;
          this.selection = new SelectionModel<UsuarioNuevoIngresoDto>(true, []);
          this.infoCargada = true;
        } else {
          this.dataSource = new MatTableDataSource([]);
          this.arregloFiltroOriginal = [];
        }
      });
  }

  public crearSolicitud(): void {
    this.util.abrirDialogoLateral(NuevoIngresoModalComponent);
  }

  public editarSolicitud(solicitud: any, solicitudCargaDoc: number) {
    this.nuevoIngresoService.obtenerNuevosIngresoByUsuarioId(solicitud.alumnoId, solicitud.usuarioId).subscribe(
      (datosUsuario: ApiResponse<UsuarioNuevoIngreso>) => {
        this.buscarInformacionUsuario(solicitud.usuarioId);
        let usuario = datosUsuario.data;
        usuario.blockName = solicitud.blockName;
        usuario.solicitudCargaDoc = solicitudCargaDoc;
        console.log("USUARIO QUE OCUPO", usuario);
        this.util.abrirDialogoLateral(NuevoIngresoModalComponent, usuario);
      }
    );
  }

  //DATOS SEGUIMIENTO
  public buscarInformacionUsuario(usuarioId: number) {
    this.seguimientoService.obtenerSeguimientoUsuarioById(usuarioId).subscribe(
      (seguimientoUsuario: ApiResponse<EstadoCuentaDto[]>) => {
        this.seguimientoService.setDatosSeguimientoUsuario$(seguimientoUsuario.data);
      });
  }

  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogo = this.dialog.open(EnviarCorreoComponent, {
      data: datos,
      panelClass: "dialogo-enviar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  public calcularEdad(fecha) {
    let hoy: Date = new Date();
    let cumpleanos: Date = new Date(fecha);
    let edad: number = hoy.getFullYear() - cumpleanos.getFullYear();
    let m: number = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    if (edad < 18) {
      return true
    } else {
      return false;
    }
  }

  public borrar(btnDataTable: boolean, elemento?: Array<UsuarioNuevoIngresoDto>): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length > 1) {
        var datos = elemento;
      }
    }
    const dialogo = this.util.abrirDialogoLateral(EliminarCandidatoComponent, datos);
  }

  applyFilter(filterValue: string) {
    this.selection.clear();
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    this.datafilter = this.dataSource.filteredData;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  /**
   * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
   * seleccionados para modificar el check
   * @returns boolean para conocer si todos estan seleccionados
   */
  isAllSelected(): boolean {
    if (this.datafilter) {
      this.numIngresoSeleccionado = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numIngresoSeleccionado === numRows;
    }
  }



  checkboxLabel(row?: UsuarioNuevoIngresoDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Limpia el arreglo de filtro, llena nuevamente los filtros e inicia el formulario, y muestra la tabla sin filtrado
   */
  public LimpiarFiltros(): void {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Vacia el arreglo
   */
  public vaciarArregloModificado(): void {
    this.arregloFiltroModificado.length = 0;
  }


  /**
* Si solo un dato del select Periodo esta seleccionado busca solo esa informacion y la
* muestra en la tabla principal
*/
  toggleAllSelectionPeriodo(): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.filtroForm.patchValue({
        periodoF: this.periodos.map(item => item.periodoId)
      });
      this.periodoF.patchValue([...this.periodos.map(item => item.periodoId), 0]);
    } else {
      this.filtroForm.patchValue({
        periodoF: []
      });
      this.periodoF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Programa esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionPrograma(): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.filtroForm.patchValue({
        ProgramaF: this.programas.map(item => item.programaId)
      });
      this.ProgramaF.patchValue([...this.programas.map(item => item.programaId), 0]);
    } else {
      this.filtroForm.patchValue({
        ProgramaF: []
      });
      this.ProgramaF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select Campus esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionCampus(): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.filtroForm.patchValue({
        CampusF: this.arCampus.map(item => item.campusId)
      });
      this.CampusF.patchValue([...this.arCampus.map(item => item.campusId), 0]);
    } else {
      this.filtroForm.patchValue({
        CampusF: []
      });
      this.CampusF.patchValue([]);
    }
  }

  /**
     * Si solo un dato del select estatus esta seleccionado busca solo esa informacion y la
     * muestra en la tabla principal
  */
  toggleAllSelectionEstatus(): void {
    // this.reiniciarTimer();
    if (this.allEstatus.selected) {
      this.filtroForm.patchValue({
        estatusF: this.estatusAcademicoList.map(item => item.estatusAcademicoId)
      });
      this.estatusF.patchValue([...this.estatusAcademicoList.map(item => item.estatusAcademicoId), 0]);
    } else {
      this.filtroForm.patchValue({
        estatusF: []
      });
      this.estatusF.patchValue([]);
    }
  }



  /**
   * Metodo cuando se seleccionan todos los bloques
   */
  toggleAllSelectionBloque() {
    // this.reiniciarTimer();
    if (this.allBloque.selected) {
      this.filtroForm.patchValue({
        bloqueF: this.BlockLabel.map(item => item.blockLabelId)
      });
      this.bloqueF.patchValue([...this.BlockLabel.map(item => item.blockLabelId), 0]);
    } else {
      this.filtroForm.patchValue({
        bloqueF: []
      });
      this.bloqueF.patchValue([]);
    }
  }

    /**
* Metodo cuando se seleccionan todos los atributos
*/
toggleAllSelectionAtributo() {
  // this.reiniciarTimer();
  if (this.allAtritbuto.selected) {
    this.filtroForm.patchValue({
      atributoF: this.attributes.map(item => item.attributeId)
    });
    this.atributoF.patchValue([...this.attributes.map(item => item.attributeId), 0]);
  } else {
    this.filtroForm.patchValue({
      atributoF: []
    });
    this.atributoF.patchValue([]);
  }
}

  /**
  * Verifica si el campo Todos del select Periodo esta seleccionados, si lo esta
  * quita la seleccion de todos los campos, sino los selecciona todos
  * @param periodoId
  */
  tosslePerOnePeriodo(periodoId: number): void {
    // this.reiniciarTimer();
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
    }
    if (this.periodoF.value.length == this.periodos.length) {
      this.allPeriodo.select();
    }
    const periodosSeleccionados = this.filtroForm.get('periodoF').value;
    const existePeriodo = periodosSeleccionados.find(x => x === periodoId);
    if (!existePeriodo) {
      const periodosAnteriores = periodosSeleccionados;
      periodosAnteriores.push(periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosAnteriores
      });
    } else {
      const periodosFiltrados = periodosSeleccionados.filter(x => x !== periodoId);
      this.filtroForm.patchValue({
        periodoF: periodosFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Programa esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param programaId
     * @returns
  */
  tosslePerOnePrograma(programaId: number): void {
    // this.reiniciarTimer();
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
    }
    if (this.ProgramaF.value.length == this.programas.length) {
      this.allPrograma.select();
    }
    const programasSeleccionados = this.filtroForm.get('ProgramaF').value;
    const existePrograma = programasSeleccionados.find(x => x === programaId);
    if (!existePrograma) {
      const programasAnteriores = programasSeleccionados;
      programasAnteriores.push(programaId);
      this.filtroForm.patchValue({
        ProgramaF: programasAnteriores
      });
    } else {
      const programasFiltrados = programasSeleccionados.filter(x => x !== programaId);
      this.filtroForm.patchValue({
        ProgramaF: programasFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param campusId
     * @returns
  */
  tosslePerOneCampus(campusId: number): void {
    // this.reiniciarTimer();
    if (this.allCampus.selected) {
      this.allCampus.deselect();
    }
    if (this.CampusF.value.length == this.arCampus.length) {
      this.allCampus.select();
    }
    const campusSeleccionados = this.filtroForm.get('CampusF').value;
    const existeCampus = campusSeleccionados.find(x => x === campusId);
    if (!existeCampus) {
      const campusAnteriores = campusSeleccionados;
      campusAnteriores.push(campusId);
      this.filtroForm.patchValue({
        CampusF: campusAnteriores
      });
    } else {
      const campusFiltrados = campusSeleccionados.filter(x => x !== campusId);
      this.filtroForm.patchValue({
        CampusF: campusFiltrados
      });
    }
  }

  /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param campusId
     * @returns
  */
  tosslePerOneEstatus(estatusAcademicoId: number): void {
    // this.reiniciarTimer();
    if (this.allEstatus.selected) {
      this.allEstatus.deselect();
    }
    if (this.estatusF.value.length == this.estatusAcademicoList.length) {
      this.allEstatus.select();
    }
    const EstatusSeleccionados = this.filtroForm.get('estatusF').value;
    const existeEstatus = EstatusSeleccionados.find(x => x === estatusAcademicoId);
    if (!existeEstatus) {
      const estatusAnteriores = EstatusSeleccionados;
      estatusAnteriores.push(estatusAcademicoId);
      this.filtroForm.patchValue({
        estatusF: estatusAnteriores
      });
    } else {
      const estatusFiltrados = EstatusSeleccionados.filter(x => x !== estatusAcademicoId);
      this.filtroForm.patchValue({
        estatusF: estatusFiltrados
      });
    }
  }


  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  /**
   * Verifica si el campo Todos del select Bloque esta seleccionados, si lo esta
   * quita la seleccion de todos los campos, sino los selecciona todos
   * @param bloqueId
   * @returns
*/
  tosslePerOneBloque(bloqueId: number) {
    // this.reiniciarTimer();
    if (this.allBloque.selected) {
      this.allBloque.deselect();
    }
    if (this.bloqueF.value.length == this.BlockLabel.length) {
      this.allBloque.select();
    }
    const bloqueSeleccionados = this.filtroForm.get('bloqueF').value;
    const existeBloque = bloqueSeleccionados.find(x => x === bloqueId);
    if (!existeBloque) {
      const bloqueAnteriores = bloqueSeleccionados;
      bloqueAnteriores.push(bloqueId);
      this.filtroForm.patchValue({
        bloqueF: bloqueAnteriores
      });
    } else {
      const bloqueFiltrados = bloqueSeleccionados.filter(x => x !== bloqueId);
      this.filtroForm.patchValue({
        bloqueF: bloqueFiltrados
      });
    }
  }

   /**
 * Verifica si el campo Todos del select atributo esta seleccionados, si lo esta
 * quita la seleccion de todos los campos, sino los selecciona todos
 * @param AtributoId
 * @returns
*/
tosslePerOneAtributo(AtributoId: number) {
  // this.reiniciarTimer();
  if (this.allAtritbuto.selected) {
    this.allAtritbuto.deselect();
  }
  if (this.atributoF.value.length == this.attributes.length) {
    this.allAtritbuto.select();
  }
  const AtributoSeleccionados = this.filtroForm.get('atributoF').value;
  const existeAtributo = AtributoSeleccionados.find(x => x === AtributoId);
  if (!existeAtributo) {
    const AtributoAnteriores = AtributoSeleccionados;
    AtributoAnteriores.push(AtributoId);
    this.filtroForm.patchValue({
      atributoF: AtributoAnteriores
    });
  } else {
    const AtributoFiltrados = AtributoSeleccionados.filter(x => x !== AtributoId);
    this.filtroForm.patchValue({
      atributoF: AtributoFiltrados
    });
  }
}


  /**
   * Metodo para buscar los filtros
   */
  public buscarFiltros(): void {
    this.filtrar();
  }



  public descargarExcel(elemento?: UsuarioNuevoIngresoDto[]) {
    let IdAlumnos: any;
    if (elemento != null && elemento.length > 0) {
      IdAlumnos = elemento.map(m => m.usuarioId);
    } else {
      IdAlumnos = null;
    }

    const filtro: FiltroAdminAcademicoAlumnoDto = {
      periodos: this.filtroForm.get('periodoF').value,
      identificador: this.filtroForm.get('idnombre').value,
      programas: this.filtroForm.get('ProgramaF').value,
      campus: this.filtroForm.get('CampusF').value,
      estatusAr: this.filtroForm.get('estatusF').value,
      bloques: this.filtroForm.get('bloqueF').value,
      atributos: this.filtroForm.get('atributoF').value,
      usuariosId: IdAlumnos
    };

    this.nuevoIngresoService.descargarExcel(filtro).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "NuevosIngresos");
    });
  }

  public asignarBloque(blockLabel: BlockLabelDto, alumnos: UsuarioNuevoIngresoDto[]): void {
    if (this.verificarPeriodoYCampus(alumnos)) {


      this.existeBloque(blockLabel, alumnos);

    } else {
      this.snackBar.open(this.translate("_nuevosIngresos.mismo-campus-periodo"), "OK", { duration: 5000 });
    }

  }



  private verificarPeriodoYCampus(alumnos: UsuarioNuevoIngresoDto[]): boolean {

    const primerAlumno = alumnos[0];
    for (let index = 0; index < alumnos.length; index++) {
      const alumno = alumnos[index];

      if (alumno.campusId != primerAlumno.campusId || alumno.periodoId != primerAlumno.periodoId) {
        return false;
      }

    }

    return true;

  }


  private existeBloque(blockLabel: BlockLabelDto, alumnos: UsuarioNuevoIngresoDto[]): void {
    const primerAlumno = alumnos[0];

    this._BlockService.GetBlockByPeriodoCampus(blockLabel.blockLabelId, primerAlumno.periodoId, primerAlumno.campusId).subscribe((respuesta: ApiResponse<Block>) => {

      this.Block = respuesta.data;

      this.AbrirModalBloque(blockLabel, alumnos, respuesta.data, respuesta.success);

    })

  }
  private AbrirModalBloque(blockLabel: BlockLabelDto, alumnos: UsuarioNuevoIngresoDto[], block: Block, existe: boolean) {
    let content = {
      alumnos: alumnos,
      blockLabel: blockLabel,
      block: block,
      existe: existe,
      nuevoIngreso: true
    }
    const dialogRef = this.dialog.open(NuevoIngresoAsignarBloqueModalComponent, { data: content, disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      this.selection.clear();
    });
  }

}
