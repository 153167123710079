import { Component, OnInit, Inject } from '@angular/core';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service';

@Component({
  selector: 'app-solicitud-categoria-modal-eliminar',
  templateUrl: './solicitud-categoria-modal-eliminar.component.html',
  styleUrls: ['./solicitud-categoria-modal-eliminar.component.scss']
})
export class SolicitudCategoriaModalEliminarComponent implements OnInit {
  public numRequestSeleccionados = 0;
  public boolIsArray = false;
  public codeRequest = '';

  constructor(public dialogRef: MatDialogRef<SolicitudCategoriaModalEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private solicitudService: SolicitudService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if(this.boolIsArray){
      this.numRequestSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(d => (d.requestCategoryId.toString()));
      this.codeRequest = arrayClaves.toString();
    } else {
      this.codeRequest = this.data.requestCategoryId.toString();
    }
    this.solicitudService.eliminarSolicitudCategoria(this.codeRequest).subscribe(
      eliminado => {
        if (eliminado) {
          this.solicitudService.invocarMetodoObtenerCategoria();
          this.snackService.mostrarSnackBack('Se ha eliminado la información.');
          this.dialogRef.close(this.data);
        } else {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar la categoria. Por favor, intente de nuevo más tarde.');
        }
      },
      error => {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar la categoria. Por favor, intente de nuevo más tarde.');
      }
    )
  }
}
