import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { SolicitudAdminDto } from 'src/app/_models/solicitud-dto';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { MatDialog } from '@angular/material/dialog';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { SolicitudAlumnoModalEdicionComponent } from './solicitud-alumno-modal-edicion/solicitud-alumno-modal-edicion.component';

@Component({
  selector: 'app-solicitud-alumno',
  templateUrl: './solicitud-alumno.component.html',
  styleUrls: ['./solicitud-alumno.component.scss']
})
export class SolicitudAlumnoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['clave', 'tipo', 'comentario', 'fecha', 'estatus'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(false, []);

  public request: any;
  public infoCargado: boolean = false;
  public numRequestSeleccionado: number = 0;
  private arrOriginal: SolicitudAdminDto[] = [];

  public usuarioSesion: any;
  public base64Image: string;
  public imagePath: SafeResourceUrl;
  public datosUsuario: any;
  public datosCompletoUsuario: any;

  constructor(private solicitudService: SolicitudService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private util: UtileriasService,
    private enseñanzaAprendizajeService: EnsenanzaAprendizajeService,
    public usuarioService: AuthenticationService) { 
      this.enseñanzaAprendizajeService.obtenerUsuarioConectado().subscribe((usuario) => {
        this.usuarioSesion = usuario;
          this.obtenerMisSolicitudes(this.usuarioSesion.usuarioId);
      });
    }

  ngOnInit(): void {
    if(this.solicitudService.invMetodoObtenerSolicitudesSubscription == undefined) {
      this.solicitudService.invMetodoObtenerSolicitudesSubscription = this.solicitudService.invocarObtenerSolicitudes.subscribe(() => {
        this.obtenerMisSolicitudes(this.usuarioSesion.usuarioId);
      });
      this.obtenerMisSolicitudes(this.usuarioSesion.usuarioId);
      this.paginador.itemsPerPageLabel = "Registros por página";
      this.paginador.nextPageLabel = "Página siguiente";
      this.paginador.previousPageLabel = "Página anterior";
      this.paginador.firstPageLabel = "Primera página";
      this.paginador.lastPageLabel = "Última página";
      this.paginador.getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
          return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
    }
  }

  obtenerMisSolicitudes(usuarioId: number) {
    this.solicitudService.obtenerMisSolicitudes(usuarioId).subscribe((solicitudes: ApiResponse<SolicitudAdminDto[]>) => {
      this.dataSource = new MatTableDataSource(solicitudes.data);
      this.selection = new SelectionModel<any>(true, []);
      this.dataSource.paginator = this.paginator;
      this.infoCargado = true;
      this.arrOriginal = [...solicitudes.data];
    });
  }

  public crear(): void {
    let datos: SolicitudAdminDto = {
      requestId: 0,
      code: 0,
      requestCategoryId: 0,
      requestCategoryName: '',
      requestTypeId: 0,
      requestTypeName: '',
      usuarioId: this.usuarioSesion.usuarioId,
      nombre: this.usuarioSesion.nombre + ' ' + (this.usuarioSesion.segundoNombre == null ? '' : this.usuarioSesion.segundoNombre) + ' ' + this.usuarioSesion.apellidoPaterno + ' ' + this.usuarioSesion.apellidoMaterno,
      comment: '',
      completed: 1,
      completedDate: null,
      tipoAccion: AccionEnum.CREAR,
    }
    const dialogo = this.util.abrirDialogoLateral(SolicitudAlumnoModalEdicionComponent, datos);
    dialogo.afterClosed().subscribe((result) => {
      this.request = result;
    });
  }

  public verDetalle(elemento?: any): void {
    let datos: SolicitudAdminDto = {
      requestId: elemento.requestId,
      code: elemento.code,
      requestCategoryId: elemento.requestCategoryId,
      requestCategoryName: elemento.requestCategoryName,
      requestTypeId: elemento.requestTypeId,
      requestTypeName: elemento.requestTypeName,
      usuarioId: elemento.usuarioId,
      nombre: elemento.nombreCompleto,
      comment: elemento.comment,
      completed: elemento.completed,
      completedDate: elemento.completedDate,
      correo: elemento.correo,
      programa:  elemento.programa,
      numero: elemento.numero,
      institutionResponse: elemento.institutionResponse,
      fechaCreacion: elemento.fechaCreacion,
      directDownload: elemento.directDownload,
      isLetterCategory: elemento.isLetterCategory,
      tipoAccion: AccionEnum.VER
    }
    const dialogo = this.util.abrirDialogoLateral(SolicitudAlumnoModalEdicionComponent, datos);
    dialogo.afterClosed().subscribe((result) => {
      this.request = result;
    });
  }

  isAllSelected() {
    this.numRequestSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numRequestSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row))
  }
}
