export class Campus {
		/// <summary>
    /// Identificador del campus
    /// </summary>
    campusId?: number;
        
    /// <summary>
    /// Identificador publico del campus.
    /// </summary>
    clave: string;
        
    /// <summary>
    /// Nombre del campus.
    /// </summary>
    nombre: string;
        
    /// <summary>
    /// Entidad federativa donde esta úbicado el campus.
    /// </summary>
    entidadFederativaId: number;

    /// <summary>
    /// Clave de país donde esta úbicado el campus.
    /// </summary>
    pais?: number;

    /// <summary>
    /// Dirección: ciudad del campus.
    /// </summary>
    ciudad: string;

    /// <summary>
    /// Dirección: calle del campus.
    /// </summary>
    calle: string;

    /// <summary>
    /// Dirección: número exterior del campus.
    /// </summary>
    numeroExterior: string;

    /// <summary>
    /// Dirección: número interior del campus.
    /// </summary>
    numeroInterior?: string;

    /// <summary>
    /// Dirección: colonia del campus.
    /// </summary>
    colonia: string;

    /// <summary>
    /// Dirección: código postal del campus.
    /// </summary>
    codigoPostal: string;

    /// <summary>
    /// Dirección: teléfono del campus.
    /// </summary>
    telefono: string;

    /// <summary>
    /// Dirección: extensión del campus.
    /// </summary>
    //extension?: string;

    /// <summary>
    /// Nombre del director de escolar del campus.
    /// </summary>
    directorEscolar: string;

    /// <summary>
    /// Nombre del rector del campus.
    /// </summary>
    rectorCampus: string;

    /// <summary>
    /// Tipo de Accion
    /// </summary>
    tipoAccion?: number;

}
