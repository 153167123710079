import { BlockGroup } from './../_models/blockGroup';
import { environment, baseUrl } from '@environments/environment';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AsignacionMasivaBloqueDto } from '../_models/AsignacionMasivaBloqueDto';

@Injectable({
  providedIn: 'root'
})
export class BlockGroupService {

  constructor(private httpClient: HttpClient) { }


  public PostBlockGroup(BlockGroup: BlockGroup): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/BlockGroup/PostBlockGroup`, BlockGroup);
  }
  public PostListBlockGroup(ListBlockGroup: Array<BlockGroup>): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/BlockGroup/PostListBlockGroup`, ListBlockGroup);
  }

  public AsignacionMasivaBloqueDto(BlockGroup: AsignacionMasivaBloqueDto): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/BlockGroup/AsignacionMasivaBloque`, BlockGroup);
  }
}
