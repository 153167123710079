import { InstitucionEmailAccount } from './institucionEmailAccount';
import { AdministracionOpcion } from './AdministracionOpcion';
export class Institucion {

        /// <summary>
        /// Clave oficial de la institución ante la SEP.
        /// </summary>
        institucionId: string;

        /// <summary>
        /// Clave oficial de la institución ante la SEP.
        /// </summary>
        clave: string;

        /// <summary>
        /// Nombre oficial de la institución.
        /// </summary>
        nombre: string;

        /// <summary>
        /// Nombre del archivo de logo de la institución.
        /// </summary>
        logo: string;

        /// <summary>
        /// Representación binaria del archivo del logo.
        /// </summary>
        logoFile: any;

        /// <summary>
        /// Clave del país en el que esta ubicado la institución.
        /// </summary>
        pais: number;

        /// <summary>
        /// Nombre del país en el que esta ubicado la institución.
        /// </summary>
        paisNombre: string;

        /// <summary>
        /// Clave de la entidad federativa en la que esta ubicada la institución.
        /// </summary>
        entidadFederativaId: number;

        /// <summary>
        /// Nombre de la entidad federativa en la que esta ubicada la institución.
        /// </summary>
        entidadFederativaNombre: string;

        /// <summary>
        /// Ciudad en la que esta ubicada la institución.
        /// </summary>
        ciudad: string;

        /// <summary>
        /// Calle en la que esta ubicada la institución.
        /// </summary>
        calle: string;

        /// <summary>
        /// Número exterior de la ubicación de la instiución.
        /// </summary>
        numeroExterior: string;

        /// <summary>
        /// Número interior de la ubicación de la instiución.
        /// </summary>
        numeroInterior: string;

        /// <summary>
        /// Nombre de la coloina de la ubicación de la instiución.
        /// </summary>
        colonia: string;

        /// <summary>
        /// Código postal de la ubicación de la institución.
        /// </summary>
        codigoPostal: string;

        /// <summary>
        /// Teléfono de contacto principal de la institución.
        /// </summary>
        telefono: string;

        /// <summary>
        /// Extensión del teléfono de contacto principal de la insitución en caso de que aplique.
        /// </summary>
        extension: string;

        /// <summary>
        /// Correo electrónico de contacto principal de la insitución en caso de que aplique.
        /// </summary>
        correo: string;

        /// <summary>
        /// Nombre completo del responsable legal de la institución.
        /// </summary>
        responsableLegal: string;

        /// <summary>
        /// Nombre completo del rector de la institución.
        /// </summary>
        nombreRector: string;

        /// <summary>
        /// Razón social de la institución.
        /// </summary>
        razonSocial: string;

        /// <summary>
        /// RFC de la institución.
        /// </summary>
        rfc: string;

        /// <summary>
        /// Clave del país en el que esta ubicada fiscalmente la institución.
        /// </summary>
        fiscalPais: number;

        /// <summary>
        /// Nombre del país en el que esta ubicada fiscalmente la institución.
        /// </summary>
        fiscalPaisNombre: string;

        fiscalEntidadFederativaId: number;

        /// <summary>
        /// Nombre de la entidad federativa en la que esta ubicada fiscalmente la institución.
        /// </summary>
        fiscalEntidadFederativaNombre: string;

        /// <summary>
        ///
        /// </summary>
        identificadorTemporal?: number;
        /// <summary>
        ///
        /// </summary>
        identificadorPermanente?: number;
        /// <summary>
        ///
        /// </summary>
        identificadorPersonalizado?: boolean;

        colorBar: string;

        colorButton: string;

        fiscalCiudad: string;

        fiscalCalle: string;

        fiscalNumeroExterior: string;

        fiscalNumeroInterior: string;

        fiscalColonia: string;

        fiscalCodigoPostal: string;

        emailDelivery: boolean;
        esValidoPassword: boolean;
        customEmailAccounts: boolean;
        lmsProviderId: number;


        institutionEmailAccount: InstitucionEmailAccount[];

        academicManagementModelId?: number;

        /**
         * Propiedad para traer las opciones de botones
         */
        administracionOpcion: AdministracionOpcion;

         /// <summary>
        /// Contains de clientId for the conection with PAYPAL
        /// </summary>
        clientIdPayPal: string;

}
