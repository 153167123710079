import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  constructor( private snackBar: MatSnackBar) { }

  /**
   * Muestra en pantalla un SnackBar con las opciones visuales ya establecidas
   * @param mensaje
   */
  public mostrarSnackBack(mensaje: string){
    this.snackBar.open(mensaje, 'OK', { duration: 5000, panelClass: ['blue-snackbar'] });
  }
}
