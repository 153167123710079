import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Programa } from '../../../../_models/programa';
import { GrupoService } from '../../../../_services/grupo.service';
import { ProgramaAcademicoService } from '../../../../_services/programa-academico.service';

@Component({
  selector: 'app-form-programa-academico',
  templateUrl: './form-programa-academico.component.html',
  styleUrls: ['./form-programa-academico.component.scss']
})
export class FormProgramaAcademicoComponent implements OnInit, OnDestroy {

  public datosCampusPeriodo$: Observable<any>;
  private subcripcionProgramas: Subscription;
  public programasDisponibles: any[] = [];
  public programasSeleccionados: any[] = [];
  public disponibles: any[] = [];
  public seleccionados: any[] = [];
  public disponiblesSeleccionados: any[] = [];
  public seleccionadosAgregados: any[] = [];
  public campusId:number=0;
  constructor(private grupoService: GrupoService,
    private programaAcademicoService: ProgramaAcademicoService) { }

  ngOnInit(): void {
    this.campusId = 0;
    this.programasDisponibles = [];
    this.datosCampusPeriodo$ = this.grupoService.obtenerDatosCampusPeriodo$();
    this.datosCampusPeriodo$.subscribe(campus => {
      if (campus != null) {
        this.campusId = campus.campus.campusId;
      }
      this.obtenerProgramas();
    });
  }

  public obtenerProgramas() {
    this.subcripcionProgramas = this.programaAcademicoService.obtenerProgramasCampus(this.campusId).subscribe(
      (programas: any[]) => {
        console.log(programas);
        this.programasDisponibles = programas.filter(x => x.estatus === true);
      }
    );
  }

  public programasDisponiblesChange(evento) {
    this.disponiblesSeleccionados = evento;

  }

  public programasSeleccionadosChange(evento) {
    this.seleccionadosAgregados = evento;
  }

  public pasarDisponibles() {
    this.disponiblesSeleccionados.forEach(
      elemento => {
        let datos = this.programasDisponibles.find(id => id.programaId === elemento.programaId);
        if (datos !== undefined) {
          this.programasSeleccionados.push(datos);
          this.programasDisponibles = this.programasDisponibles.filter(id => id.programaId !== elemento.programaId);
        }
      });
  }

  public pasarAgregados() {
    this.seleccionadosAgregados.forEach(
      elemento => {
        let datos = this.programasSeleccionados.find(id => id.programaId === elemento.programaId);
        if (datos !== undefined) {
          this.programasDisponibles.push(datos);
          this.programasSeleccionados = this.programasSeleccionados.filter(id => id.programaId !== elemento.programaId);
        }
      });
  }
  public siguiente(){
    this.seleccionados=[];
    this.programasSeleccionados.forEach(row=>
      this.seleccionados.push(row.programaId))
    this.grupoService.definirDatosPrograma(this.seleccionados);
  }

  ngOnDestroy(): void {
    if (this.subcripcionProgramas) {
      this.subcripcionProgramas.unsubscribe();
    }
  }

}
