import { PersonaRelacionada } from "../_models/persona-relacionada";

export class PersonaRelacionadaHelper{
    /**
     * Busca en el arreglo la persona que haga math con el idTipoRealacion enviado como parametro,
     * y lo retorna si existe dicho elemento dentro del arreglo
     * @param personas 
     * @param tipoRelacion 
     * @returns 
     */
    public static find(personas: Array<PersonaRelacionada>, tipoRelacion:number): PersonaRelacionada{
        let persona = personas.find(p=> p.tipoRelacionId == tipoRelacion);
        return persona == null ? new PersonaRelacionada(): persona;
    }
}