import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnInit, ViewChild, TemplateRef, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Letter } from 'src/app/_models/letter';
import { CartasService } from 'src/app/_services/cartas.service';
import { SnackService } from 'src/app/services/snack-service.service';
import * as customBuild from '../../../../app/_librarries/ckeditorCustom/build/ckeditor';
import { AccionEnum } from 'src/app/_models/accion.enum';

@Component({
  selector: 'app-cartas-modal-edicion',
  templateUrl: './cartas-modal-edicion.component.html',
  styleUrls: ['./cartas-modal-edicion.component.scss']
})
export class CartasModalEdicionComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  @ViewChild('vistaPrevia') vistaPrevia: TemplateRef<'vistaPrevia'>;
  public idLetter: number = 0;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public edicionForm: FormGroup;
  public Editor = customBuild;
  public modalVistaPreviaRef: any;
  public contenido: string = '';
  public banderaInstrucciones: boolean = false;

  constructor(public dialogRef: MatDialogRef<CartasModalEdicionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Letter,
    private letterService: CartasService,
    private snackService: SnackService,
    private dialog: MatDialog,
    private traductorService: TraductorService) { 
      this.inicializarForm();
      this.contenido = this.data.content;
      this.boolCambio = false;
    }

  ngOnInit(): void {
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      letterId: new FormControl(this.data?.letterId),
      code: new FormControl(this.data?.code, [Validators.required]),
      name: new FormControl(this.data?.name, [Validators.required]),
      description: new FormControl(this.data?.description),
      directDownload: new FormControl(this.data?.directDownload, [Validators.required]),
      content: new FormControl(this.data?.content, [Validators.required])
    });
    // this.edicionForm.valueChanges.subscribe((cambios) => {
    //   this.boolCambio = true;
    // });
    this.edicionForm.get("code").valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
    this.edicionForm.get("name").valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
    this.edicionForm.get("description").valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
    this.edicionForm.get("directDownload").valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
  }

  get letterId() { return this.edicionForm.get('letterId'); }
  get code() { return this.edicionForm.get('code'); }
  get name() { return this.edicionForm.get('name'); }
  get description() { return this.edicionForm.get('description'); }

  public cerrarModalValidacion() {
    if(this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  public mostrarInstrucciones(bandera: boolean) {
    if(bandera) {
      const instruccion: HTMLElement = document.getElementById("instrucciones");
      instruccion.style.display = "none";
      this.banderaInstrucciones = true;
    } else {
      const instruccion: HTMLElement = document.getElementById("instrucciones");
      instruccion.style.display = "block";
      this.banderaInstrucciones = false;
    }  
  }

  //#region Funciones para abrir un modal para ver el contenido que agrego en content
  public abrirVistaPrevia() {
    
    this.modalVistaPreviaRef = this.dialog.open(this.vistaPrevia, {disableClose: true});
  }

  public cerrarModalPdf() {
    this.modalVistaPreviaRef.close();
  }
  //#endregion

  public cambiarVistaEditar(letterID: number) {
    this.boolCambio = false;
    this.data = Object.assign(this.edicionForm.value);
    this.data.tipoAccion = 2;
  }

  public enviar() {
    switch(this.data.tipoAccion) {
      case AccionEnum.CREAR:
        this.data = Object.assign(this.edicionForm.value);
        this.letterService.registrarLetter(this.data).subscribe(
          (letters: number) => {
            if(letters > 0) {
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
              this.boolCambio = false;
              this.cambiarVistaEditar(letters);
              this.inicializarForm();
              this.letterService.invocarMetodoObtenerCartas();
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
              this.data.tipoAccion = 3;
            }
          }, error => {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
        });
      break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionForm.value);
        this.data.tipoAccion = 2;
        this.letterService.modificarLetter(this.data).subscribe(
          (editado: boolean) => {
            if(editado) {
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
              this.boolCambio = false;
              this.letterService.invocarMetodoObtenerCartas();
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          }, error => {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
        });
      break;
    }
  }
}
