import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import { IGeneros } from '../usuario-edicion/Generos';
import { ISexoLegal } from '../usuario-edicion/SexoLegal';
import { IEstados } from '../usuario-edicion/Estados';
import { IPais } from '../usuario-edicion/Pais';
import { IEstadoCivil } from '../usuario-edicion/EstadoCivil';
import { Estados } from '../_models/estados.enum';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
}

@Injectable({
  providedIn: 'root'
})
export class UsuarioEdicionService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.enves[baseUrl].apiUrl;
  }

  public UsuarioEdicion(Difunto,
    SexoLegal,
    Genero,
    Ciudadania,
    EstadoCivil,
    NSS,
    Curp,
    LugarNacimiento,
    Pais,
    Estado,
    Ciudad,
  ): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/UsuarioEdicion/Guardado',
      {
        'Difunto': Difunto, 'SexoLegal': SexoLegal, 'Genero': Genero, 'Ciudadania': Ciudadania,
        'EstadoCivil': EstadoCivil, 'NSS': NSS, 'Curp': Curp, 'LugarNacimiento': LugarNacimiento,
        'Pais': Pais, 'Estado': Estado, 'Ciudad': Ciudad
      }, httpOptions);
  }
  //Consulta de SexoLegal
  getSexoLegal(): Observable<IGeneros[]> {
    return this.http.get<IGeneros[]>(this.baseUrl + '/UsuarioEdicion/SexoLegal');
  }
  //Consulta de Género
  getGenero(): Observable<IGeneros[]> {
    return this.http.get<IGeneros[]>(this.baseUrl + '/UsuarioEdicion/Genero');
  }
  //Consulta de Ciudadania
  getCiudadania(): Observable<IPais[]> {
    return this.http.get<IPais[]>(this.baseUrl + '/UsuarioEdicion/Ciudadania');
  }
  //Consulta de Estado Civil
  getEstadoCivil(): Observable<IEstadoCivil[]> {
    return this.http.get<IEstadoCivil[]>(this.baseUrl + '/UsuarioEdicion/EstadoCivil');
  }
  //Consulta de Lugar de Nacimiento
  getNacimiento(): Observable<IEstados[]> {
    return this.http.get<IEstados[]>(this.baseUrl + '/UsuarioEdicion/LugarNacimiento');
  }
  //Consulta de Pais
  getPais(): Observable<IPais[]> {
    return this.http.get<IPais[]>(this.baseUrl + '/UsuarioEdicion/Pais');
  }
  //Consulta de Estado
  getEstado(): Observable<IEstados[]> {
    return this.http.get<IEstados[]>(this.baseUrl + '/UsuarioEdicion/EntidadFederativa');
  }
}
