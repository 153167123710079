import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-datos-familiares-admision-express',
  templateUrl: './datos-familiares-admision-express.component.html',
  styleUrls: ['./datos-familiares-admision-express.component.scss']
})
export class DatosFamiliaresAdmisionExpressComponent implements OnInit {

  //Variable Formulario
  public datosFamiliaresForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.inicializarFormDatosFamiliares();
  }



  public inicializarFormDatosFamiliares() {
    this.datosFamiliaresForm = new FormGroup({
      usuarioId: new FormControl(0),
      nombreCompletoTutor: new FormControl(""),
      telefonoTutor: new FormControl("", [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoTutor: new FormControl("", [Validators.email]),
    })
  }


  get nombreCompletoTutor() { return this.datosFamiliaresForm.get('nombreCompletoTutor'); }
  get telefonoTutor() { return this.datosFamiliaresForm.get('telefonoTutor'); }
  get correoElectronicoTutor() { return this.datosFamiliaresForm.get('correoElectronicoTutor'); }


}
