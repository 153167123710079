import { BlockGroup } from './../../../_models/blockGroup';
import { BlockGroupService } from './../../../_services/block-group.service';
import { BlockService } from 'src/app/_services/block.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GrupoService } from '../../../_services/grupo.service';
import { FormCampusPeriodoComponent } from './form-campus-periodo/form-campus-periodo.component';
import { FormDuracionComponent } from './form-duracion/form-duracion.component';
import { FormFrecuenciaHorarioComponent } from './form-frecuencia-horario/form-frecuencia-horario.component';
import { FormMateriaComponent } from './form-materia/form-materia.component';
import { FormProfesorAulaComponent } from './form-profesor-aula/form-profesor-aula.component';
import { FormProgramaAcademicoComponent } from './form-programa-academico/form-programa-academico.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Grupo } from '../../../_models/grupo';
import { Horario } from '../../../_models/horario';
import { Observable, Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Block } from 'src/app/_models/block';

@Component({
  selector: 'app-oferta-academica-modal',
  templateUrl: './oferta-academica-modal.component.html',
  styleUrls: ['./oferta-academica-modal.component.scss']
})
export class OfertaAcademicaModalComponent implements OnInit, OnDestroy {

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  @ViewChild(FormCampusPeriodoComponent) formCampusPeriodo: FormCampusPeriodoComponent;
  @ViewChild(FormProgramaAcademicoComponent) formPorgramaAcademico: FormProgramaAcademicoComponent;
  @ViewChild(FormMateriaComponent) formMateria: FormMateriaComponent;
  @ViewChild(FormDuracionComponent) formDuracion: FormDuracionComponent;
  @ViewChild(FormFrecuenciaHorarioComponent) formFrecuenciaHorario: FormFrecuenciaHorarioComponent;
  @ViewChild(FormProfesorAulaComponent) formProfesorAula: FormProfesorAulaComponent;
  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  @ViewChild('dialogGrupoAdicional') dialogGrupoAdicional: TemplateRef<any>;
  @ViewChild('stepper') stepper: MatStepper;
  public dialogoRefAdver: any;
  public dialogoRefGrupoAdicional: any;
  public boolCambioPendiente$: Observable<boolean>;
  public boolCambio: boolean = false;
  public boolCambioPendienteSubscription: Subscription;
  public horarioRequerido: boolean = true;
  public stepSeleccionado: number = 0;
  public invMetodoSubscription: Subscription;

  public Block: Block;
  public BlockGroup: BlockGroup;


  constructor(public dialogRef: MatDialogRef<OfertaAcademicaModalComponent>,
    private grupoService: GrupoService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private blockService: BlockService,
    private blockGroupService: BlockGroupService) { }

  ngOnInit(): void {
    if (this.invMetodoSubscription === undefined) {
      this.invMetodoSubscription = this.grupoService.invocarGuardarGrupo.subscribe((row) => {
        this.validarEmpalmes();
      });
      this.invMetodoSubscription = undefined;
    }
    this.boolCambioPendiente$ = this.grupoService.obtenerBoolCambio$();
    this.boolCambioPendienteSubscription = this.boolCambioPendiente$.subscribe(bool => this.boolCambio = bool);
  }

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }
  get campusPeriodo() { return this.formCampusPeriodo ? this.formCampusPeriodo.formCampusPeriodo : null; }
  get programaAcademico() { return null; }
  get materia() { return this.formMateria ? this.formMateria.formMateria : null; }
  get duracion() { return this.formDuracion ? this.formDuracion.formDuracion : null; }
  get frecuenciaHorario() { return this.formFrecuenciaHorario ? this.formFrecuenciaHorario.frecuenciaHorarioForm : null; }
  get profesorAula() { return this.formProfesorAula ? this.formProfesorAula.formProfesorAula : null; }

  public cambioStep(event) {
    let datosDuracion = this.duracion.value;
    this.horarioRequerido = datosDuracion.horarioRequerido;
    if (event.previouslySelectedIndex === 3 && event.selectedIndex === 4 && datosDuracion.horarioRequerido) {
      this.stepSeleccionado = 5;
    }
    if (event.previouslySelectedIndex === 5 && event.selectedIndex === 4 && datosDuracion.horarioRequerido) {
      this.stepSeleccionado = 3;
    }
  }

  public validarEmpalmes() {
    var datosDuracion = this.duracion.value;
    var datosFrecuenciaHorario = this.frecuenciaHorario.value;
    var datosProfesorAula = this.profesorAula.value;

    let profesorId = datosDuracion.profesorId === 0 ? null : datosDuracion.profesorId;

    if (this.horarioRequerido) {
      datosFrecuenciaHorario.horarioSecciones.forEach(
        elemento => {
          elemento.fechaInicio = datosDuracion.fechaInicio;
          elemento.fechaFin = datosDuracion.fechaFin;
          elemento.horarioRequerido = datosDuracion.horarioRequerido;
        });
      var datosHorario = datosFrecuenciaHorario.horarioSecciones;
    } else {
      let datosHorarioPendiente: Horario = {};
      datosHorarioPendiente.fechaInicio = datosDuracion.fechaInicio;
      datosHorarioPendiente.fechaFin = datosDuracion.fechaFin;
      datosHorarioPendiente.horarioRequerido = datosDuracion.horarioRequerido;
      datosHorarioPendiente.salonId = datosProfesorAula.salonId === 0 ? null : datosProfesorAula.salonId;
      var datosHorario: any = []
      datosHorario.push(datosHorarioPendiente);
    }

    this.grupoService.validarEmpalmeProfesor(profesorId != null ? profesorId : 0, datosHorario).subscribe((respuesta: ApiResponse<boolean>) => {
      if (respuesta.success) {
        if (respuesta.data) {
          this.snackBar.open(respuesta.message, "OK", { duration: 5000 });
        } else {
          this.grupoService.validarEmpalmeSalon(datosHorario).subscribe((respuestaSalon: ApiResponse<boolean>) => {
            if (respuestaSalon.success) {
              if (respuestaSalon.data) {
                this.snackBar.open(respuestaSalon.message, "OK", { duration: 5000 });
              } else {
                this.guardarGrupo();
              }
            }
          })
        }
      }
    })
  }

  public guardarGrupo() {
    var datosCampusPeriodo = this.campusPeriodo.value;
    var datosMateria = this.materia.value;
    var datosDuracion = this.duracion.value;
    var datosFrecuenciaHorario = this.frecuenciaHorario.value;
    var datosProfesorAula = this.profesorAula.value;
    let datosGrupo: Grupo = {};
    datosGrupo.periodoId = datosCampusPeriodo.periodo.periodoId;
    datosGrupo.clave = datosMateria.materia.clave;
    datosGrupo.activityOrderTypeId = datosMateria.actividades;
    datosGrupo.materiaOfertaId = datosMateria.materia.materiaOfertaId;
    datosGrupo.profesorId = datosDuracion.profesorId === 0 ? null : datosDuracion.profesorId;
    datosGrupo.esActivo = true;
    datosGrupo.capacidad = datosMateria.capacidad;
    datosGrupo.blockLabelId = datosMateria.blockLabel.blockLabelId;
    if (this.horarioRequerido) {
      datosFrecuenciaHorario.horarioSecciones.forEach(
        elemento => {
          elemento.fechaInicio = datosDuracion.fechaInicio;
          elemento.fechaFin = datosDuracion.fechaFin;
          elemento.horarioRequerido = datosDuracion.horarioRequerido;
        });
      var datosHorario = datosFrecuenciaHorario.horarioSecciones;
    } else {
      let datosHorarioPendiente: Horario = {};
      datosHorarioPendiente.fechaInicio = datosDuracion.fechaInicio;
      datosHorarioPendiente.fechaFin = datosDuracion.fechaFin;
      datosHorarioPendiente.horarioRequerido = datosDuracion.horarioRequerido;
      datosHorarioPendiente.salonId = datosProfesorAula.salonId === 0 ? null : datosProfesorAula.salonId;
      var datosHorario: any = []
      datosHorario.push(datosHorarioPendiente);
    }
    datosGrupo.horario = datosHorario;
    this.grupoService.guardarGrupo(datosGrupo).subscribe(
      idGrupoGuardado => {
        if (idGrupoGuardado > 0) {
          if (this.horarioRequerido) {
            datosFrecuenciaHorario.horarioSecciones.forEach(
              elemento => {
                elemento.fechaInicio = datosDuracion.fechaInicio;
                elemento.fechaFin = datosDuracion.fechaFin;
                elemento.horarioRequerido = datosDuracion.horarioRequerido;
                elemento.grupoId = idGrupoGuardado;
              });
            var datosHorario = datosFrecuenciaHorario.horarioSecciones;
          } else {
            let datosHorarioPendiente: Horario = {};
            datosHorarioPendiente.fechaInicio = datosDuracion.fechaInicio;
            datosHorarioPendiente.fechaFin = datosDuracion.fechaFin;
            datosHorarioPendiente.horarioRequerido = datosDuracion.horarioRequerido;
            datosHorarioPendiente.grupoId = idGrupoGuardado;
            datosHorarioPendiente.salonId = datosProfesorAula.salonId === 0 ? null : datosProfesorAula.salonId;
            var datosHorario: any = []
            datosHorario.push(datosHorarioPendiente);
          }
          this.grupoService.guardarHorario(datosHorario).subscribe(
            horarioGuardado => {
              if (horarioGuardado) {

                if (datosMateria.blockLabel.blockLabelId != 0 && datosMateria.blockLabel.blockLabelId != null) {

                  this.Block = new Block();

                  this.Block.periodoId = datosGrupo.periodoId;
                  this.Block.campusId = datosCampusPeriodo.campus.campusId;
                  this.Block.blockLabelId = datosMateria.blockLabel.blockLabelId;


                  this.Subscription.add(
                    this.blockService.agregarBloque(this.Block).subscribe(resp => {

                      if (resp.success) {


                        this.BlockGroup = new BlockGroup();
                        this.BlockGroup.blockId = resp.data.blockId;
                        this.BlockGroup.grupoId = idGrupoGuardado;

                        this.Subscription.add(
                          this.blockGroupService.PostBlockGroup(this.BlockGroup).subscribe(resp => {

                            if (resp.success) {
                              this.grupoService.invocarMetodoObtenerGrupos();
                              this.dialogoRefGrupoAdicional = this.dialog.open(this.dialogGrupoAdicional, { disableClose: true });
                              this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
                            } else {
                              this.snackBar.open(resp.message, "OK", { duration: 5000 });
                            }
                          }));

                      } else if (resp.data != null) {

                        this.BlockGroup = new BlockGroup();
                        this.BlockGroup.blockId = resp.data.blockId;
                        this.BlockGroup.grupoId = idGrupoGuardado;

                        this.Subscription.add(
                          this.blockGroupService.PostBlockGroup(this.BlockGroup).subscribe(resp => {

                            if (resp.success) {
                              this.grupoService.invocarMetodoObtenerGrupos();
                              this.dialogoRefGrupoAdicional = this.dialog.open(this.dialogGrupoAdicional, { disableClose: true });
                              this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
                            } else {
                              this.snackBar.open(resp.message, "OK", { duration: 5000 });
                            }
                          }));

                      }
                      else {
                        this.snackBar.open(resp.message, "OK", { duration: 5000 });
                      }
                    }));
                } else {
                  this.grupoService.invocarMetodoObtenerGrupos();
                  this.dialogoRefGrupoAdicional = this.dialog.open(this.dialogGrupoAdicional, { disableClose: true });
                  this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
                }


              } else {
                this.snackBar.open("Ha ocurrido un error al guardar el grupo. Por favor, intente de nuevo más tarde.1", "OK", { duration: 5000 });
              }
            },
            error => {
              console.log("Error", error);
              //this.snackBar.open("Ha ocurrido un error al guardar el grupo. Por favor, intente de nuevo más tarde.2", "OK", { duration: 5000 });
            }
          );
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar el grupo. Por favor, intente de nuevo más tarde.3", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("Error", error);
        //this.snackBar.open("Ha ocurrido un error al guardar el grupo. Por favor, intente de nuevo más tarde.4", "OK", { duration: 5000 });
      }
    );
  }

  public cerrarModal() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close();
      this.stepper.reset();
    } else {
      this.dialogoRefAdver.close();
    }
  }

  public cerrarModalGrupoAdicional(aceptar: boolean) {
    if (aceptar) {
      //this.formDuracion.formDuracion.reset();
      this.formFrecuenciaHorario.frecuenciaHorarioForm.reset();
      this.formProfesorAula.formProfesorAula.reset();
      this.stepper.selectedIndex = 2;
      this.stepSeleccionado = 2;
      this.formFrecuenciaHorario.inicializarForm();
      //this.formDuracion.inicializaFrom();
      this.formMateria.inicializarForm();
      this.dialogoRefGrupoAdicional.close();

    } else {
      this.dialogRef.close();
      this.dialogoRefGrupoAdicional.close();
      this.stepper.reset();

    }
  }

}
