import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment,baseUrl } from '../../environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { Service } from '../_models/service';
import { InscripcionCosto } from '../_models/inscripcion-costo';
import { ColegiaturaCosto } from '../_models/colegiatura-costo';
import { ConfiguracionReembolso } from '../_models/configuracion-reembolso';
import { TipoServicio } from '../_models/tipo-servicio';
import { BaseResponse } from '../models/api/BaseResponse.mode';
import { ApiResponse } from '../models/api/ApiRespose.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionCuotaService {

  invocarObtenerFormaPago = new EventEmitter();
  invocarObtenerServicios = new EventEmitter();
  invocarObtenerInscripciones = new EventEmitter();
  invocarObtenerColegiaturas = new EventEmitter();
  invocarObtenerReembolso = new EventEmitter();
  invocarObtenerTipoServicio = new EventEmitter();
  obtenerFormaPagoSubscription: Subscription;
  obtenerServiciosSubscription: Subscription;
  obtenerInscripcionesSubscription: Subscription;
  obtenerColegiaturasSubscription: Subscription;
  obtenerReembolsoSubscription: Subscription;
  obtenerTipoServicioSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }
  //obtiene los servicios
  public getServicios():Observable<any>{
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Servicio`);
  }
  //obterner los nombres de los servicios
  public getServiciosNombres(campusId: number, nivelId: number):Observable<ApiResponse<TipoServicio[]>>{
    return this.httpClient.get<ApiResponse<TipoServicio[]>>(`${environment.enves[baseUrl].apiUrl}/Servicio/GetNombres/${campusId}/${nivelId}`);
  }

  //obterner los nombres de los servicios
  public getCatalogoServiciosNombres():Observable<ApiResponse<TipoServicio[]>>{
    return this.httpClient.get<ApiResponse<TipoServicio[]>>(`${environment.enves[baseUrl].apiUrl}/Servicio/GetCatalogoNombres/`);
  }
  //guardar el costo del servicio
  public guardarServicio(servicio: Service): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Servicio`, servicio);
  }
  //editar el servicio
  public editarServicio(servicio: Service): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Servicio`, servicio);
  }
  public eliminarServicio(servicio: Service): Observable<any>{
    return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/Servicio/Eliminar`, servicio);
  }
  //obtener los costos de la inscripcion
  public getInscripcionCosto():Observable<any>{
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/InscripcionCosto`);
  }
  //guardar los costos de inscripcion
  public postInscripcionCosto(inscripcionC: InscripcionCosto): Observable<boolean>{
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/InscripcionCosto`, inscripcionC);
  }
  //editar los costos de Inscripcion
  public editarInscripcionCosto(inscripcionC: InscripcionCosto): Observable<boolean>{
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/InscripcionCosto`, inscripcionC);
  }
  public eliminarInscripcionCosto(inscripcionC: InscripcionCosto): Observable<any>{
    return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/InscripcionCosto/Eliminar`, inscripcionC);
  }
  //obtener los costos de Colegiatura
  public getColegiaturaCosto():Observable<any>{
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/ColegiaturaCosto`);
  }
  //guarda los costos de Colegiatura
  public postColegiaturaCosto(colegiaturaC: ColegiaturaCosto):Observable<BaseResponse>{
    return this.httpClient.post<BaseResponse>(`${environment.enves[baseUrl].apiUrl}/ColegiaturaCosto`, colegiaturaC);
  }
  public editrColegiaturaCosto(colegiaturaC: ColegiaturaCosto): Observable<BaseResponse>{
    return this.httpClient.put<BaseResponse>(`${environment.enves[baseUrl].apiUrl}/ColegiaturaCosto`, colegiaturaC);
  }
  public eliminarColegiaturaCosto(colegiaturaC: ColegiaturaCosto): Observable<any>{
    return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/ColegiaturaCosto/Eliminar`, colegiaturaC);
  }
  //Obtener Configuracion Reembolso
  public getConfiguracionReembolso():Observable<any>{
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/ConfiguracionReembolso`);
  }
  //Guarda Configuracion Reembolso
  public postConfiguracionReembolso(reembolso: ConfiguracionReembolso): Observable<any>{
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/ConfiguracionReembolso`, reembolso);
  }
  //Editar Configuracion Reembolso
  public editartConfiguracionReembolso(reembolso: ConfiguracionReembolso): Observable<any>{
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/ConfiguracionReembolso`, reembolso);
  }
  //Eliminar Configuracion Reembolso
  public eliminarConfiguracionReembolso(reembolso: ConfiguracionReembolso): Observable<any>{
    return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/ConfiguracionReembolso/Eliminar`, reembolso);
  }

  // Obtener la categoria
  public obtenerCategoria(): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Servicio/GetCategoria`);
  }

  // Obtener los tipos de servicios
  public obtenerTiposServicios(): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Servicio/TipoServicio`);
  }

  // Post tipo servicio
  public agregarTipoServicio(servicio: TipoServicio): Observable<any> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Servicio/PostTipoServicio`, servicio);
  }

  // Put tipo servicio
  public modificarTipoServicio(servicio: TipoServicio): Observable<any> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Servicio/PutTipoServicio`, servicio);
  }

  // Delete tipo servicio
  public eliminarTipoServicio(servicioId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Servicio/DeleteTipoServicio?servicioId=` + servicioId);
  }

  public downloadExcelColegiaturaCosto():Observable<any>{
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/ColegiaturaCosto/DownloadExcel`)
  }
  public downloadExcelServicioCosto():Observable<any>{
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Servicio/DownloadExcel`)
  }
  public downloadExcelInscripcionCosto():Observable<any>{
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/ColegiaturaCosto/DownloadExcel`)
  }
  public downloadExcelConfiguracionReembolso(reembolso: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/ConfiguracionReembolso/DownloadExcel`,JSON.stringify(reembolso),
    {
      responseType: "blob",
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  // descargar Excel 
  public downloadExcelTipoServicio(servicios: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Servicio/DownloadExcelTS`, JSON.stringify(servicios),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  //Obtener forma de pago
  public obtenerFormaPagoByInstitucion(institucionId: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/FormaPago/GetFormaPagoByInstitucion?institucionId=` + institucionId);
  }

  //Actualizar estatus forma de pago
  public actualizarFormaPago(datosFormPago: any): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/FormaPago/UpdateEstatusFormaPago`, datosFormPago)
  }

  //Invocar Metodo obtenerFormaDePago
  public metodoObtenerFormaPago() {
    this.invocarObtenerFormaPago.emit();
  }

  //Invocar Metodo obtenerFormaDePago
  public metodoObtenerServicios() {
    this.invocarObtenerServicios.emit();
  }

  //Invocar Metodo obtenerFormaDePago
  public metodoObtenerInscripciones() {
    this.invocarObtenerInscripciones.emit();
  }

  //Invocar Metodo obtenerFormaDePago
  public metodoObtenerColegiaturas() {
    this.invocarObtenerColegiaturas.emit();
  }

  //Invocar Método obtenerReembolso
  public metodoObtenerReembolso() {
    this.invocarObtenerReembolso.emit();
  }

  //Invocar metodo obtenerTipoServicio
  public metodoObtenerTipoServicio() {
    this.invocarObtenerTipoServicio.emit();
  }
}
