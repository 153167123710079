import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { Campus } from 'src/app/_models/campus';
import { Periodo } from 'src/app/_models/periodo';
import { Programa } from 'src/app/_models/programa';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { CampusService } from 'src/app/_services/campus.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { CampusProgramaDTO } from 'src/app/_models/CampusProgramaDTO';

@Component({
  selector: 'app-registrar-usuario',
  templateUrl: './registrar-usuario.component.html',
  styleUrls: ['./registrar-usuario.component.scss']
})
export class RegistrarUsuarioComponent implements OnInit {

  public nuevoSolicitudForm: FormGroup;
  public nuevoSolicitudUsuarioForm: FormGroup;
  public renderizarFormulario: boolean = false;
  public correoRegistro: string;
  public nombreCompletoUsuario: string;
  public datosUsuarioBusqueda$: Observable<UsuarioIngreso>;
  public datosUsuarioBusqueda: UsuarioIngreso;
  public subscritpionCampus: Subscription;
  public subscriptionPeriodo: Subscription;
  public subscriptionPrograma: Subscription;
  public campusList: CampusProgramaDTO[] = [];
  public periodoList: Periodo[] = [];
  public programaList: Programa[] = [];
  public tipoAccion: number;
  public formularioProfesor: boolean = false;
  public passwordTipoTexto: boolean = false;
  public passwordCTipoTexto: boolean = false;
  public usuarioId: number;

  constructor(public dialogRef: MatDialogRef<RegistrarUsuarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsuarioIngreso,
    private campusService: CampusService,
    private periodoService: PeriodoService,
    public nuevoIngresoService: NuevoIngresoService,
    private snackService: SnackService) { }

  ngOnInit(): void {
    this.getTipoCampus();
    this.getTipoPeriodo();
    this.inicializarForm();
  }

  public recibirInfo(correo: string) {
    this.tipoAccion = AccionEnum.CREAR;
    this.correoRegistro = correo;
    this.inicializarForm();
  }

  public recibirInfoProfesor(correo: string, profesor: boolean) {
    this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusquedaCorreo$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
    let nombreCompleto: string = `${this.datosUsuarioBusqueda.nombre} ${this.datosUsuarioBusqueda.segundoNombre == null ? '' : this.datosUsuarioBusqueda.segundoNombre} ${this.datosUsuarioBusqueda.apellidoPaterno} ${this.datosUsuarioBusqueda.apellidoMaterno}`
    this.tipoAccion = AccionEnum.EDITAR;
    this.correoRegistro = correo;
    this.formularioProfesor = profesor;
    this.nombreCompletoUsuario = nombreCompleto;
    this.inicializarFormProfesor();
  }

  public inicializarForm() {
    this.nuevoSolicitudForm = new FormGroup({
      correo: new FormControl('', [Validators.required, Validators.email]),
      nombre: new FormControl('', [Validators.required]),
      segundoNombre: new FormControl(''),
      apellidoPaterno: new FormControl('', [Validators.required]),
      apellidoMaterno: new FormControl(''),
      fechaNacimiento: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()¡¿=|?]/), this.parameters]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()¡¿=|?]/), this.parameters]),
      programaAcademico: new FormControl(0, [Validators.required, this.validateId]),
      periodo: new FormControl(0, [Validators.required, this.validateId]),
      campus: new FormControl(0, [Validators.required, this.validateId]),
    });
    this.renderizarFormulario = true;
    this.nuevoSolicitudForm.valueChanges.subscribe(val => {
      this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.nuevoSolicitudForm.valid);
    });
  }

  public inicializarFormProfesor() {
    this.nuevoSolicitudUsuarioForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      nombreCompleto: new FormControl(this.nombreCompletoUsuario),
      correoUsuario: new FormControl(this.correoRegistro),
      programaAcademicoUsuario: new FormControl(0, [Validators.required, this.validateId]),
      periodoUsuario: new FormControl(0, [Validators.required, this.validateId]),
      campusUsuario: new FormControl(0, [Validators.required, this.validateId]),
    });
    this.renderizarFormulario = true;
    this.nuevoSolicitudUsuarioForm.valueChanges.subscribe(val => {
      this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.nuevoSolicitudUsuarioForm.valid);
    });
  }

  get correo() { return this.nuevoSolicitudForm.get('correo'); }
  get nombre() { return this.nuevoSolicitudForm.get('nombre'); }
  get segundoNombre() { return this.nuevoSolicitudForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.nuevoSolicitudForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.nuevoSolicitudForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.nuevoSolicitudForm.get('fechaNacimiento'); }
  get password() { return this.nuevoSolicitudForm.get('password'); }
  get passwordConfirm() { return this.nuevoSolicitudForm.get('passwordConfirm'); }
  get programaAcademico() { return this.nuevoSolicitudForm.get('programaAcademico'); }
  get periodo() { return this.nuevoSolicitudForm.get('periodo'); }
  get campus() { return this.nuevoSolicitudForm.get('campus'); }

  get nombreCompleto() { return this.nuevoSolicitudUsuarioForm.get('nombreCompleto'); }
  get correoUsuario() { return this.nuevoSolicitudUsuarioForm.get('correoUsuario'); }
  get programaAcademicoUsuario() { return this.nuevoSolicitudUsuarioForm.get('programaAcademicoUsuario'); }
  get campusUsuario() { return this.nuevoSolicitudUsuarioForm.get('campusUsuario'); }
  get periodoUsuario() { return this.nuevoSolicitudUsuarioForm.get('periodoUsuario'); }
  get aceptaTerminos() { return this.nuevoSolicitudUsuarioForm.get('aceptaTerminos'); }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  public getErrorFormUsuario(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudUsuarioForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  private parameters(control: AbstractControl): { [key: string]: any } | null {
    let regExp = /[ÑÁÉÍÓÚñáéíóú]/.test(control.value);

    return regExp ? { 'password': { value: control.value } } : null;
  }

  public mostrarContrasena() {
    this.passwordTipoTexto = !this.passwordTipoTexto;
  }

  public mostrarContrasenaC() {
    this.passwordCTipoTexto = !this.passwordCTipoTexto;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  private compararPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('passwordConfirm').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  public onCampusSelected(campus: number) {
    this.programaList = [];
    if (!!campus) {
      this.campusService.obtenerProgramas(campus).subscribe(
        programas => {
          this.programaList = programas;
        })
    }
  }

  public getTipoCampus() {
    this.subscritpionCampus = this.campusService.obtenerCampusconProgramaActivo().subscribe(
      (campus: ApiResponse<CampusProgramaDTO[]>) => {
        this.campusList = campus.data;
      });
  }

  public getTipoPeriodo() {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoList = periodo;
      });
  }

  /**
   * Metodo para validar si el correo es valido o no
   * @param event 
   */
  public validarCorreo(event: any): void {
    if (this.correo.valid) {
      this.nuevoIngresoService.validarCorreo(event).subscribe((respuesta: ApiResponse<boolean>) => {
        if (respuesta.success) {
          if (respuesta.data) {
            this.nuevoIngresoService.buscarUsuarioByCorreo();
          }
        } else {
          this.snackService.mostrarSnackBack(respuesta.message);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscritpionCampus) {
      this.subscritpionCampus.unsubscribe();
    }
    if (this.subscriptionPeriodo) {
      this.subscriptionPeriodo.unsubscribe();
    }
    if (this.subscriptionPrograma) {
      this.subscriptionPrograma.unsubscribe();
    }
  }

}
