export class ConfiguracionApoyoFinanciero {
  /**
   * Identificador de la configuracion de apoyo financiero
   */
  configuracionApoyoFinancieroID: number;

  /**
   * Identificador del campus 
   */
  campusId: number;

  /**
   * Porcentaje minimo de la configuracion de apoyo financiero
   */
  porcentajeMinimo: number;

  /**
   * Porcentaje maximo de la configuracion de apoyo financiero
   */
  porcentajeMaximo: number;

  /**
   * 
   */
  preasignacionApoyo?: boolean;

  /**
   * Fecha de creacion de registro
   */
  fechaCreacion?: Date;

  /**
   * Usuario de creacion de registro
   */
  usuarioCreacion?: number;

  /**
   * Fecha de modificacion del registro
   */
  fechaModificacion?: Date;

  /**
   * Usuario que modifico el registro
  */
  usuarioModificacion?: number;
}
