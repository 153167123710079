import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { LeadService } from 'src/app/_services';

@Component({
  selector: 'app-delete-lead',
  templateUrl: './delete-lead.component.html',
  styleUrls: ['./delete-lead.component.scss'],
})
export class DeleteLeadComponent implements OnInit {
  public boolIsArray: boolean = false;
  public numSelectedLead: number;
  public leadIds: string = '';
  public deleted: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteLeadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly leadService: LeadService,
    private snackService: SnackService
  ) {}

  ngOnInit(): void {
    this.validarDataRecibida();
  }
  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numSelectedLead = this.data.length;
    }
  }

  public deleteLead(): void {
    if (this.boolIsArray) {
      let arrayIds = this.data.map((c) => c.leadId.toString());
      this.leadIds = arrayIds.toString();
    } else {
      this.leadIds = this.data.leadId.toString();
    }
    this.leadService.deleteLeads(this.leadIds).subscribe(
      (response: ApiResponse<any>) => {
        if (response.success) {
          this.deleted = true;
          this.snackService.mostrarSnackBack('Se ha eliminado la información.');
          this.dialogRef.close(this.deleted);
        } else {
          this.snackService.mostrarSnackBack('La información no pudo ser eliminada.');
        }
      },
      (err) => {
        this.snackService.mostrarSnackBack('La información no pudo ser eliminada.');
      }
    );
  }

  public cerrarModal(): void {
    this.dialogRef.close(this.deleted);
  }
}
