import { MaterialModule } from './../../modules/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GradesComponent } from './grades.component';
import { BrowserModule } from '@angular/platform-browser';
import { I18NextModule } from 'angular-i18next';



@NgModule({
  declarations: [
    GradesComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    I18NextModule.forRoot(),
  ],
  exports: [ 
    GradesComponent
  ]
})
export class GradesModule { }
