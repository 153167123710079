import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProgramaAcademicoService } from '../../../_services/programa-academico.service';
import { AdminAcademicaService } from '../../../_services/admin-academica.service';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { ImprimirServiceService } from 'src/app/services/imprimir-service.service';

@Component({
  selector: 'app-masterias-reprobadas',
  templateUrl: './masterias-reprobadas.component.html',
  styleUrls: ['./masterias-reprobadas.component.scss']
})
export class MasteriasReprobadasComponent implements OnInit, OnDestroy {

  @Input() data: any;
  materiasHistorial: any;
  cicloList: any[] = [];
  flagMateriasReprobadas = false;
  displayedColumns: string[] = ['Clave', 'Nombre', 'Periodo', 'Programa', 'Calificación'];
  dataSource: MatTableDataSource<any>;

  constructor(private programaAcademicoService: ProgramaAcademicoService,
    private adminAcademiaService: AdminAcademicaService,
    private excelGenerator: GeneracionExcelServiceService,
    public imprimirService: ImprimirServiceService,) { }

  //#region Métodos
  ngOnInit(): void {
    this.obtenerMateriasHistorialAcademico();
  }
  ngOnDestroy(): void {
  }
  obtenerMateriasHistorialAcademico() {
    this.adminAcademiaService.GetListaMateriasReprobadas(this.data.alumno.alumnoId).subscribe(
      (respuestaMaterias: ApiResponse<any>) => {
        console.log("respuestaMaterias", respuestaMaterias);
        this.materiasHistorial = respuestaMaterias.data;
        if (this.materiasHistorial.length > 0) {
          this.flagMateriasReprobadas = true;
          this.dataSource = new MatTableDataSource(this.materiasHistorial);
        }
      })
  }
  /**
 * Ejecuta el método del api que descarga la información que hay en pantalla
 */
  descargarExcel() {
    this.adminAcademiaService.descargarSheetDocumentReprobadas(this.materiasHistorial).subscribe((res: Blob) => {
      let filename = `MateriasReprobadas${this.data.alumno.usuario.nombre}${this.data.alumno.usuario.apellidoPaterno}${this.data.alumno.usuario.apellidoMaterno}`;
      this.excelGenerator.GenerarExcel(res, filename);
    })
  }

  public imprimir() {
    const printContent: HTMLElement = document.getElementById("materias");
    let nombreAlumno = `${this.data.alumno.usuario.nombre}${this.data.alumno.usuario.apellidoMaterno}${this.data.alumno.usuario.apellidoMaterno}`
    this.imprimirService.Imprimir(printContent, nombreAlumno);
  }
  //#endregion
}
