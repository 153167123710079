import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { UtileriasService } from '../../_services/utilerias.service';
import { TipoApoyoFinanciero } from '../../_models/tipo-apoyo-financiero';
import { TipoApoyoFinancieroService } from '../../_services/tipo-apoyo-financiero.service';
import { TipoApoyoFinancieroCrearEditarComponent } from './apoyo-financiero-tipo-crear-editar/tipo-apoyo-financiero-crear-editar.component';
import { TipoApoyoFinancieroEliminarComponent } from './apoyo-financiero-tipo-eliminar/tipo-apoyo-financiero-eliminar.component';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
@Component({
  selector: 'app-tipo-apoyo-financiero',
  templateUrl: './tipo-apoyo-financiero.component.html',
  styleUrls: ['./tipo-apoyo-financiero.component.scss']
})
export class TipoApoyoFinancieroComponent implements OnInit, OnDestroy {

  public numTipoApoyoSeleccionado: number = 0;
  public infoCargada: boolean = false;
  private subcripcionTipoApoyoFinanciero: Subscription;
  public datosEliminar: any;
  public datafilter: TipoApoyoFinanciero[] = [];
  public estatusApoyoFinancieroF = new FormControl();
  @ViewChild('allEstatus') private allEstatus: MatOption;
  public nombre = new FormControl();
  private arrOriginal: TipoApoyoFinanciero[] = [];

  constructor(private paginador: MatPaginatorIntl,
    private tipoApoyoFinancieroService: TipoApoyoFinancieroService,
    public util: UtileriasService,
    private generadorExcel: GeneracionExcelServiceService) { }

  listData: MatTableDataSource<TipoApoyoFinanciero>;
  displayedColumns: string[] = ['select', 'Clave', 'Descripcion', 'Porcentaje', 'Estatus', 'Convenio'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<TipoApoyoFinanciero>(true, []);

  ngOnInit(): void {
    if (this.tipoApoyoFinancieroService.metodoTipoApoyoFinancieroSubscription == undefined) {
      this.tipoApoyoFinancieroService.metodoTipoApoyoFinancieroSubscription = this.tipoApoyoFinancieroService.invocarObtenerTipoApoyoFinancieroEmit.subscribe(() => {
        this.obtenerTipoApoyoFinanciero();
      });
    }
    this.obtenerTipoApoyoFinanciero();
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };


    this.LlenarFiltro();
  }

  public obtenerTipoApoyoFinanciero() {
    this.subcripcionTipoApoyoFinanciero = this.tipoApoyoFinancieroService.obtenerTipoApoyoFinancieroTablaSeguimiento().subscribe(
      (apoyoFinanciero: TipoApoyoFinanciero[]) => {
        this.selection = new SelectionModel<TipoApoyoFinanciero>(true, []);
        this.listData = new MatTableDataSource(apoyoFinanciero);
        this.datafilter = apoyoFinanciero;
        this.arrOriginal = apoyoFinanciero;
        this.listData.paginator = this.paginator;
        this.infoCargada = true;
      }
    );
  }

  public crearTipoApoyo() {
    this.util.abrirDialogoLateral(TipoApoyoFinancieroCrearEditarComponent);
  }

  public editarTipoApoyo(tipoApoyo: TipoApoyoFinanciero) {
    this.util.abrirDialogoLateral(TipoApoyoFinancieroCrearEditarComponent, tipoApoyo);
  }

  public borrarTipoApoyo(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      this.datosEliminar = elemento;
    } else {
      if (elemento.length === 1) {
        this.datosEliminar = elemento[0];
      } else {
        this.datosEliminar = elemento;
      }
    }
    this.util.abrirDialogoLateral(TipoApoyoFinancieroEliminarComponent, this.datosEliminar);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listData.filter = filterValue.trim().toLowerCase();
    if (this.listData.paginator) {
      this.listData.paginator.firstPage();
    }
  }

  isAllSelected() {
    this.numTipoApoyoSeleccionado = this.selection.selected.length;
    const numRows = this.listData.data.length;
    return this.numTipoApoyoSeleccionado === numRows;
  }

  checkboxLabel(row?: TipoApoyoFinanciero): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.listData.data.forEach(row => this.selection.select(row));
  }


  //#region Download Excel

  public descargarExcel(elemento?) {
    let tipoApoyoFinancieroIds: Array<string>;

    if (elemento != null && elemento.length > 0) {
      tipoApoyoFinancieroIds = elemento.map(m => m.tipoApoyoFinancieroId.toString());
    } else {
      tipoApoyoFinancieroIds = this.datafilter.map(m => m.tipoApoyoFinancieroId.toString());
    }

    this.tipoApoyoFinancieroService.descargarExcel(tipoApoyoFinancieroIds).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "Tipo Apoyo Financiero");
    })



  }

  public LlenarFiltro() {
    this.estatusApoyoFinancieroF.setValue([true, false, -1]);
  }


  limpiarFiltros() {
    this.nombre.setValue("");
    this.estatusApoyoFinancieroF.setValue([true, false, -1]);
    this.filtro();
  }


  public filtro() {
    let estatusAdmicion = this.estatusApoyoFinancieroF.value == null ? [] : this.estatusApoyoFinancieroF.value;
    let nombre: string = this.nombre.value == null ? "" : this.nombre.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    filtroModificado = filtroModificado.filter(f => { return f.clave.toLowerCase().search(nombre.toLowerCase()) > -1 || f.descripcion.toString().search(nombre) > -1; });
    filtroModificado = filtroModificado.filter(f => { return estatusAdmicion.indexOf(f.activo) > -1 });
    this.listData = new MatTableDataSource(filtroModificado);
    this.listData.paginator = this.paginator;
    this.datafilter = filtroModificado;
  }

  toggleAllSelectionEstatus() {
    if (this.allEstatus.selected) {
      this.estatusApoyoFinancieroF.patchValue([true, false, -1]);
    } else {
      this.estatusApoyoFinancieroF.patchValue([]);
    }
    this.filtro();
  }


  tosslePerOneEstatus(all) {
    if (this.allEstatus.selected) {
      this.allEstatus.deselect();
      return false;
    }
    if (this.estatusApoyoFinancieroF.value.length == 2) { this.allEstatus.select(); }
    this.filtro();
  }



  ngOnDestroy(): void {
    if (this.subcripcionTipoApoyoFinanciero) {
      this.subcripcionTipoApoyoFinanciero.unsubscribe();
    }
  }

}
