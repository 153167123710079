import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl, environment } from '@environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { Lead } from '../_models/lead';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  private infoLead$ = new Subject<any>();
  updateTabsSubscription: Subscription;
  constructor(private httpClient: HttpClient) {}

  public SetInfoLead(infoLead: any) {
    this.infoLead$.next(infoLead);
  }

  public GetInfoLead(): Observable<any> {
    return this.infoLead$.asObservable();
  }

  //#region  Interactions
  public SetInteractions(interaction) {
    let getint = this.GetInteractions();
    let inter = JSON.parse(getint);
    if (inter) {
      inter.push(interaction);
      inter = JSON.stringify(inter);
    } else {
      let intera = [interaction];
      inter = JSON.stringify(intera);
    }
    sessionStorage.setItem('interactions', inter);
  }
  public GetInteractions() {
    return sessionStorage.getItem('interactions');
  }
  public DeleteInteractions() {
    sessionStorage.removeItem('interactions');
  }
  //#endregion

  //Get
  public getLeads(): Observable<ApiResponse<Array<any>>> {
    return this.httpClient.get<ApiResponse<Array<any>>>(`${environment.enves[baseUrl].apiUrl}/Lead`);
  }

  //Get
  public getLead(leadId: string): Observable<ApiResponse<Lead>> {
    return this.httpClient.get<ApiResponse<Lead>>(`${environment.enves[baseUrl].apiUrl}/Lead/lead?id=${leadId}`).pipe(
      map((response) => {
        if (response.data) {
          response.data = new Lead().deserialize(response.data);
        }
        return response;
      })
    );
  }

  //Post
  public postLeads(lead: any): Observable<ApiResponse<Array<any>>> {
    return this.httpClient.post<ApiResponse<Array<any>>>(`${environment.enves[baseUrl].apiUrl}/Lead`, lead);
  }

  //Delete
  public deleteLeads(leadIds: string): Observable<ApiResponse<any>> {
    return this.httpClient.delete<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Lead/leads?ids=${leadIds}`);
  }

  //Put
  public putLead(lead: any): Observable<ApiResponse<Array<any>>> {
    return this.httpClient.put<ApiResponse<Array<any>>>(`${environment.enves[baseUrl].apiUrl}/Lead`, lead);
  }
}
