import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfesorService } from 'src/app/_services/profesor.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-oferta-profesor-eliminar',
  templateUrl: './oferta-profesor-eliminar.component.html',
  styleUrls: ['./oferta-profesor-eliminar.component.scss']
})
export class OfertaProfesorEliminarComponent implements OnInit {
  public numProfesorSeleccionado: number = 0;
  public boolIsArray: boolean = false;
  public claveProfesor: string = '';
 
  public boolRecibido: boolean = false;
  public boolCambio: boolean = false;
  public usuarioId: number;
  

  constructor(public dialogRef: MatDialogRef<OfertaProfesorEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private profesorService: ProfesorService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }
  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numProfesorSeleccionado = this.data.length;
    } 
  }
 
  public eliminarProfesor() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(c => (c.usuarioId.toString()));
      this.claveProfesor = arrayClaves.toString();
    } else {
      this.claveProfesor = this.data.usuarioId.toString();
    }
     //this.usuarioId = this.data.usuarioId;
    
    this.profesorService.eliminarProfesor(this.claveProfesor).subscribe(
      eliminado => {
        if (eliminado) {
          this.profesorService.invocarMetodoObtenerProfesor();
          this.snackBar.open("Se ha eliminado la información.", "OK", { duration: 5000 });
          this.dialogRef.close(this.data);
        } else {
          this.snackBar.open("Algún profesor no pudo ser eliminado porque pertenece a un grupo.", "OK", { duration: 5000 });
        }
      },
      error => {
        this.snackBar.open("Ha ocurrido un error al eliminar el profesor. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }
  public cerrarModal() {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }

}
