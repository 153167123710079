import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { Aula } from '../_models/aula';

@Injectable({
  providedIn: 'root'
})
export class AulaService {
  invocarObtenerAula = new EventEmitter();

  otbenerAulaSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }
     //Obtener Aula
     public getAula():Observable<any>{
      return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Aula`);
    }
    //Guardar Aula
    public postAula(salon: Aula): Observable<any>{
      return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Aula`, salon);
    }
    //Editar Aula
    public editarAula(salon: Aula): Observable<any>{
      return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Aula`, salon);
    }
    //Eliminar Aula
    public eliminarAula(salonId: string): Observable<any> {
      return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Aula?salonId=` + salonId);
    }
    //Excel
    public downloadExcelAula(salon: any) {
      return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Aula/DownloadExcel`,JSON.stringify(salon),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
    }

    //Invocar Método ObtenerAula
    public invocarMetodoObtenerAula(){
      this.invocarObtenerAula.emit();
    }
}
