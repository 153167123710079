import { Validators } from "@angular/forms";
import { ValidateId } from "../../functions/validate-id-function";

export const CONTACT_INFORMATION = { 

  correo                : [, { validators: [Validators.required, Validators.email], updateOn: 'change' }],
  tipoDireccionId       : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
  paisId                : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
  entidadFederativaId   : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
  ciudad                : [, { validators: [Validators.required], updateOn: 'change' }],
  calle                 : [, { validators: [Validators.required], updateOn: 'change' }],
  numeroExterior        : [, { validators: [Validators.required], updateOn: 'change' }],
  numeroInterior        : [, { updateOn: 'change' }],
  colonia               : [, { validators: [Validators.required], updateOn: 'change' }],
  codigoPostal          : [, { validators: [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)], updateOn: 'change' }],
  tipoTelefonoId        : [0, { validators: [Validators.required, ValidateId], updateOn: 'change' }],
  numero              : [, { validators: [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)], updateOn: 'change' }],  
  extension             : [, { updateOn: 'change' }]

};