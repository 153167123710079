import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { EntidadFederativa } from 'src/app/_models/entidad-federativa';
import { EstadoCivil } from 'src/app/_models/estado-civil';
import { Genero } from 'src/app/_models/genero';
import { GrupoSanguineo } from 'src/app/_models/grupo-sanguineo';
import { Institucion } from 'src/app/_models/institucion';
import { Nivel } from 'src/app/_models/nivel';
import { Pais } from 'src/app/_models/pais';
import { TipoDireccion } from 'src/app/_models/tipo-direccion';
import { TipoEmpresa } from 'src/app/_models/tipo-empresa';
import { TipoPuestoEmpresa } from 'src/app/_models/tipo-puesto-empresa';
import { TipoTelefono } from 'src/app/_models/tipo-telefono';
import { DatosPersonaRelacionada } from 'src/app/_models/usuario';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { BiografiaService } from 'src/app/_services/biografia.service';
import { DireccionService } from 'src/app/_services/direccion.service';
import { EmpresaService } from 'src/app/_services/empresa.service';
import { EstadoCivilService } from 'src/app/_services/estado-civil.service';
import { GeneroService } from 'src/app/_services/genero.service';
import { GrupoSanguineoService } from 'src/app/_services/grupo-sanguineo.service';
import { InstitucionService } from 'src/app/_services/institucion.service';
import { NivelService } from 'src/app/_services/nivel.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-datos-completos-candidato',
  templateUrl: './datos-completos-candidato.component.html',
  styleUrls: ['./datos-completos-candidato.component.scss']
})
export class DatosCompletosCandidatoComponent implements OnInit {
  public datosUsuarioBusqueda$: Observable<UsuarioIngreso>;
  public usuarioMenorEdad$: Observable<boolean>;
  public datosUsuarioBusqueda: UsuarioIngreso;
  public renderizarForm: boolean = false;
  public tipoAccion: number;
  public subscriptionTipoDireccion: Subscription;
  public subscriptionPais: Subscription;
  public subscriptionEstado: Subscription;
  public subscriptionTipoTelefono: Subscription;
  public subscriptionTipoEmpresa: Subscription;
  public subscriptionPuesto: Subscription;
  public datosPersonalesForm: FormGroup;
  public tipoAccionFotografia: number;
  public tipoAccionDireccion: number;
  public tipoAccionTelefono: number;
  public tipoAccionBiografia: number;
  public tipoAccionFamiliar: number;
  public tipoAccionEstudiosPrevios: number;
  public tipoAccionDatoFiscal: number;
  public tipoAccionDatosMedicos: number;
  public tipoAccionTipoEmpresa: number;
  public tipoAccionTipoPuesto: number;
  public tipoDireccionList: TipoDireccion[] = [];
  public paisList: Pais[] = [];
  public paisListBiografia: Pais[] = [];
  public estadoList: EntidadFederativa[] = [];
  public estadoListBiografia: EntidadFederativa[] = [];
  public tipoTelefonoList: TipoTelefono[] = [];
  public sexoList: Genero[] = [];
  public generoList: Genero[] = [];
  public estadoCivilList: EstadoCivil[];
  public sanguineoList: GrupoSanguineo[] = [];
  public nivelList: Nivel[] = [];
  public tipoEmpresaList: TipoEmpresa[] = [];
  public tipoPuestoEmpresaList: TipoPuestoEmpresa[] = [];
  public tipoAccionFamiliarPapa: DatosPersonaRelacionada;
  public tipoAccionFamiliarMama: DatosPersonaRelacionada;
  public tipoAccionFamiliarTutor: DatosPersonaRelacionada;
  public tipoAccionFamiliarEmergencia: DatosPersonaRelacionada;
  public curpEncontrado: boolean = false;
  public usuarioMenorEdad: boolean;
  public estudiosPreviosCrear: boolean = false;
  public institucion: Institucion;
  public curpNeed: boolean = false;

  constructor(public dialogDatosRef: MatDialogRef<DatosCompletosCandidatoComponent>,
    public nuevoIngresoService: NuevoIngresoService,
    private utileriasService: UtileriasService,
    private direccionService: DireccionService,
    private grupoSanguineoService: GrupoSanguineoService,
    private nivelService: NivelService,
    private generoService: GeneroService,
    private estadoCivilService: EstadoCivilService,
    private empresaService: EmpresaService,
    private biografiaService: BiografiaService,
    private institucionService: InstitucionService,
    @Inject(MAT_DIALOG_DATA) public data: UsuarioIngreso,

  ) { }

  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
    this.usuarioMenorEdad$ = this.nuevoIngresoService.obtenerUsuarioMenorEdad$();
    this.usuarioMenorEdad$.subscribe(menorEdad => this.usuarioMenorEdad = menorEdad);
    this.getTipoDireccion();
    this.getPais();
    this.getTipoTelefono();
    this.getTipoGenero();
    this.getTipoSexo();
    this.getTipoCivil();
    this.getTipoSanguineo();
    this.getTipoNivel();
    this.getPaisBiografia();
    this.getTipoEmpresa();
    this.getTipoPuesto();
  }

  public inicializarForm() {

    this.datosPersonalesForm = new FormGroup({
      //Datos personales
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      nombre: new FormControl(this.datosUsuarioBusqueda.nombre, [Validators.required]),
      apellidoPaterno: new FormControl(this.datosUsuarioBusqueda.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(this.datosUsuarioBusqueda.apellidoMaterno),
      fechaNacimiento: new FormControl(this.utileriasService.formatearFecha(this.datosUsuarioBusqueda.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD'), [Validators.required]),
      fotografia: new FormControl(this.tipoAccionFotografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.fotografia),
      fotografiaFile: new FormControl(this.datosUsuarioBusqueda.fotografia !== null ? this.datosUsuarioBusqueda.fotografia : ''),
      //Datos de Contacto
      tipoDireccionId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.direccion[0].tipoDireccionId, [Validators.required]),
      paisId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.ciudadaniaPaisId, [Validators.required, this.validateId]),
      entidadFederativaId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.direccion[0].entidadFederativaId, [Validators.required, this.validateId]),
      calle: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].calle, [Validators.required]),
      ciudad: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].ciudad, [Validators.required]),
      numeroExterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].numeroExterior, [Validators.required]),
      numeroInterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].numeroInterior),
      colonia: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].colonia, [Validators.required]),
      codigoPostal: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.direccion[0].codigoPostal, [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      tipoTelefonoId: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.telefono[0].tipoTelefonoId, [Validators.required]),
      numero: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.telefono[0].numero, [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      extension: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.telefono[0].extension),
      correo: new FormControl(this.datosUsuarioBusqueda.correo, [Validators.required, Validators.email]),
      //Datos Biograficos
      legalGeneroId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.legalGeneroId, [Validators.required, this.validateId]),
      generoId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR || this.datosUsuarioBusqueda.biografia.generoId == null ? 0 : this.datosUsuarioBusqueda.biografia.generoId),
      ciudadania: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.ciudadaniaPaisId, [Validators.required, this.validateId]),
      estadoCivilId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR || this.datosUsuarioBusqueda.biografia.estadoCivilId == null ? 0 : this.datosUsuarioBusqueda.biografia.estadoCivilId),
      curp: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.curp, [Validators.required, Validators.pattern(/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/)]),
      paisNacimiento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.ciudadaniaPaisId, [Validators.required, this.validateId]),
      entidadNacimiento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.entidadNacimiento, [Validators.required, this.validateId]),
      ciudadNacimiento: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.ciudadNacimiento, [Validators.required]),
      //Datos Familiares
      nombreCompletoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.nombreCompleto),
      telefonoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.correoElectronico, [Validators.email]),
      nombreCompletoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.nombreCompleto),
      telefonoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.correoElectronico, [Validators.email]),
      nombreCompletoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.nombreCompleto),
      telefonoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      correoElectronicoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.correoElectronico, [Validators.email]),
      //Contacto Emergencia
      nombreContacto: new FormControl(this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.nombreCompleto),
      telefonoContacto: new FormControl(this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.telefono, [Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      correoContacto: new FormControl(this.tipoAccionFamiliarEmergencia == null ? '' : this.tipoAccionFamiliarEmergencia.correoElectronico, [Validators.email]),
      //Datos Medicos
      grupoSanguineoId: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? null : this.datosUsuarioBusqueda.biografia.grupoSanguineoId),
      alergias: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.alergias),
      enfermedades: new FormControl(this.tipoAccionBiografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.biografia.enfermedades),
      //Estudios Previos
      nivel: new FormControl(this.tipoAccionEstudiosPrevios === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.estudioPrevio[0].nivelId, [Validators.required]),
      academico: new FormControl(this.tipoAccionEstudiosPrevios === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.estudioPrevio[0].escuela, [Validators.required]),
      promedio: new FormControl(this.tipoAccionEstudiosPrevios === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.estudioPrevio[0].promedio, [Validators.required, Validators.pattern(/^[1-9]+/)]),
      //Datos de Facturacion
      rfc: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.rfc),
      razonSocial: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.razonSocial),
      tipoDireccionFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.datoFiscal.tipoDireccionId),
      paisFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.biografia.ciudadaniaPaisId),
      entidadFederativaFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.datoFiscal.entidadFederativaId),
      calleFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.calle),
      ciudadFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.ciudad),
      numeroExteriorFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.numeroExterior),
      numeroInteriorFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.numeroInterior),
      coloniaFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.colonia),
      codigoPostalFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.datoFiscal.codigoPostal, [Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      //Ocupacion
      tipoEmpresaId: new FormControl(this.tipoAccionTipoEmpresa === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.ocupacion.tipoEmpresaId),
      tipoPuestoEmpresaId: new FormControl(this.tipoAccionTipoPuesto === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.ocupacion.tipoPuestoEmpresaId),

    });

    this.obtenerInstitucion();
    let menorEdad = this.calcularEdad(this.datosUsuarioBusqueda.biografia.fechaNacimiento);

    this.nuevoIngresoService.definirUsuarioMenorEdad(menorEdad);

    if (menorEdad) {
      this.nombreCompletoTutor.setValidators([Validators.required]);
      this.telefonoTutor.setValidators([Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]);
      this.correoElectronicoTutor.setValidators([Validators.required, Validators.email]);
    } else {
      this.nombreCompletoTutor.setValidators(null);
      this.telefonoTutor.setValidators(null);
      this.correoElectronicoTutor.setValidators(null);
    }
    this.nombreCompletoTutor.updateValueAndValidity();
    this.telefonoTutor.updateValueAndValidity();
    this.correoElectronicoTutor.updateValueAndValidity();


    this.fechaNacimiento.valueChanges.subscribe(checked => {
      let menorEdad = this.calcularEdad(checked);
      if (menorEdad) {
        this.nombreCompletoTutor.setValidators([Validators.required]);
        this.telefonoTutor.setValidators([Validators.required]);
        this.correoElectronicoTutor.setValidators([Validators.required, Validators.email]);
      } else {
        this.nombreCompletoTutor.setValidators(null);
        this.telefonoTutor.setValidators(null);
        this.correoElectronicoTutor.setValidators(null);
      }
      this.nombreCompletoTutor.updateValueAndValidity();
      this.telefonoTutor.updateValueAndValidity();
      this.correoElectronicoTutor.updateValueAndValidity();
    });

    this.onPaisSelected(this.datosPersonalesForm.controls['paisId'].value);
    this.onPaisSelectedBiografia(this.datosPersonalesForm.controls['paisNacimiento'].value);
    this.renderizarForm = true;

    this.datosPersonalesForm.valueChanges.subscribe(val => {
      this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.datosPersonalesForm.valid);
      //this.nuevoIngresoService.definirCambioForms(true);
      this.nuevoIngresoService.definirCambioNombreForms("Datos Personales");
      this.nuevoIngresoService.setChangesPersonalData(true);
    });
  }

  public obtenerInstitucion() {
    this.institucionService.obtenerInstitucion().subscribe(
      institucion => {
        this.institucion = institucion;
        if (institucion.pais === 484) {
          this.curpNeed = true;
          this.curp.setValidators([Validators.required]);
          this.curp.updateValueAndValidity();
          this.nuevoIngresoService.definirCambioNombreForms(null);
          this.nuevoIngresoService.setChangesPersonalData(false);
        } else {
          this.curpNeed = false;
          this.curp.setValidators(null);
          this.curp.updateValueAndValidity();
          this.nuevoIngresoService.definirCambioNombreForms(null);
          this.nuevoIngresoService.setChangesPersonalData(false);
        }
      }
    );
  }


  public calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    if (edad < 18) {
      return true
    } else {
      return false;
    }
  }

  //Datos personales
  get id() { return this.datosPersonalesForm.get('id'); }
  get nombre() { return this.datosPersonalesForm.get('nombre'); }
  get apellidoPaterno() { return this.datosPersonalesForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.datosPersonalesForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.datosPersonalesForm.get('fechaNacimiento'); }
  get fotografia() { return this.datosPersonalesForm.get('fotografia'); }

  //Datos de Contacto
  get tipoDireccionId() { return this.datosPersonalesForm.get('tipoDireccionId'); }
  get paisId() { return this.datosPersonalesForm.get('paisId'); }
  get entidadFederativaId() { return this.datosPersonalesForm.get('entidadFederativaId'); }
  get tipoTelefonoId() { return this.datosPersonalesForm.get('tipoTelefonoId'); }
  get ciudad() { return this.datosPersonalesForm.get('ciudad'); }
  get calle() { return this.datosPersonalesForm.get('calle'); }
  get numeroExterior() { return this.datosPersonalesForm.get('numeroExterior'); }
  get numeroInterior() { return this.datosPersonalesForm.get('numeroInterior'); }
  get colonia() { return this.datosPersonalesForm.get('colonia'); }
  get codigoPostal() { return this.datosPersonalesForm.get('codigoPostal'); }
  get numero() { return this.datosPersonalesForm.get('numero'); }
  get extension() { return this.datosPersonalesForm.get('extension'); }
  get correo() { return this.datosPersonalesForm.get('correo'); }

  //Datos Biograficos
  get legalGeneroId() { return this.datosPersonalesForm.get('legalGeneroId'); }
  get ciudadania() { return this.datosPersonalesForm.get('ciudadania'); }
  get curp() { return this.datosPersonalesForm.get('curp'); }
  get paisNacimiento() { return this.datosPersonalesForm.get('paisNacimiento'); }
  get entidadNacimiento() { return this.datosPersonalesForm.get('entidadNacimiento'); }
  get ciudadNacimiento() { return this.datosPersonalesForm.get('ciudadNacimiento'); }

  //Datos Familiares
  get nombreCompletoMadre() { return this.datosPersonalesForm.get('nombreCompletoMadre'); }
  get telefonoMadre() { return this.datosPersonalesForm.get('telefonoMadre'); }
  get correoElectronicoMadre() { return this.datosPersonalesForm.get('correoElectronicoMadre'); }
  get nombreCompletoPadre() { return this.datosPersonalesForm.get('nombreCompletoPadre'); }
  get telefonoPadre() { return this.datosPersonalesForm.get('telefonoPadre'); }
  get correoElectronicoPadre() { return this.datosPersonalesForm.get('correoElectronicoPadre'); }
  get nombreCompletoTutor() { return this.datosPersonalesForm.get('nombreCompletoTutor'); }
  get telefonoTutor() { return this.datosPersonalesForm.get('telefonoTutor'); }
  get correoElectronicoTutor() { return this.datosPersonalesForm.get('correoElectronicoTutor'); }

  //Contacto Emergencia
  get nombreContacto() { return this.datosPersonalesForm.get('nombreContacto'); }
  get telefonoContacto() { return this.datosPersonalesForm.get('telefonoContacto'); }
  get correoContacto() { return this.datosPersonalesForm.get('correoContacto'); }

  //Datos Medicos
  get grupoSanguineoId() { return this.datosPersonalesForm.get('grupoSanguineoId'); }
  get alergias() { return this.datosPersonalesForm.get('alergias'); }
  get enfermedades() { return this.datosPersonalesForm.get('enfermedades'); }

  //Estudios Previos
  get nivel() { return this.datosPersonalesForm.get('nivel'); }
  get academico() { return this.datosPersonalesForm.get('academico'); }
  get promedio() { return this.datosPersonalesForm.get('promedio'); }

  //Datos Facturacion
  get tipoDireccionFacturacionId() { return this.datosPersonalesForm.get('tipoDireccionFacturacionId'); }
  get paisFacturacionId() { return this.datosPersonalesForm.get('paisFacturacionId'); }
  get entidadFederativaFacturacionId() { return this.datosPersonalesForm.get('entidadFederativaFacturacionId'); }
  get ciudadFacturacion() { return this.datosPersonalesForm.get('ciudadFacturacion'); }
  get calleFacturacion() { return this.datosPersonalesForm.get('calleFacturacion'); }
  get numeroExteriorFacturacion() { return this.datosPersonalesForm.get('numeroExteriorFacturacion'); }
  get numeroInteriorFacturacion() { return this.datosPersonalesForm.get('numeroInteriorFacturacion'); }
  get coloniaFacturacion() { return this.datosPersonalesForm.get('coloniaFacturacion'); }
  get codigoPostalFacturacion() { return this.datosPersonalesForm.get('codigoPostalFacturacion'); }
  
  //Datos Fiscales
  get tipoEmpresa() { return this.datosPersonalesForm.get('tipoEmpresaId'); }
  get puesto() { return this.datosPersonalesForm.get('tipoPuestoEmpresaId'); }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido.";
    }
    return error;
  }
  public getErrorFormSexo(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Sexo Legal es requerido.";
    }
    return error;
  }
  public getErrorFormGenero(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Género es requerido.";
    }
    return error;
  }
  public getErrorFormCiudad(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Ciudadanía es requerido.";
    }
    return error;
  }
  public getErrorFormCivil(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Estado Civil es requerido.";
    }
    return error;
  }
  public getErrorFormPais(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "País es requerido.";
    }
    return error;
  }
  public getErrorFormEstado(controlName: string): string {
    let error = '';
    const control = this.datosPersonalesForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Estado es requerido.";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public renderizarFormulario() {
    if (this.datosUsuarioBusqueda != null) {
      if (this.datosUsuarioBusqueda.fotografia != null) {
        this.tipoAccionFotografia = AccionEnum.EDITAR;
      } else {
        this.tipoAccionFotografia = AccionEnum.CREAR;
      }
    }
    if (this.datosUsuarioBusqueda.direccion.length >= 1) {
      this.tipoAccionDireccion = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDireccion = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.telefono.length >= 1) {
      this.tipoAccionTelefono = AccionEnum.EDITAR;
    } else {
      this.tipoAccionTelefono = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.biografia.legalGeneroId != null) {
      this.tipoAccionBiografia = AccionEnum.EDITAR;
    } else {
      this.tipoAccionBiografia = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.personaRelacionadaUsuario.length >= 1) {
      this.tipoAccionFamiliarPapa = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1);
      this.tipoAccionFamiliarMama = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2);
      this.tipoAccionFamiliarTutor = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3);
      this.tipoAccionFamiliarEmergencia = this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 4) === undefined ? null : this.datosUsuarioBusqueda.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 4);

    } else {
      this.tipoAccionFamiliarPapa = null;
      this.tipoAccionFamiliarMama = null;
      this.tipoAccionFamiliarTutor = null;
      this.tipoAccionFamiliarEmergencia = null;
    }
    if (this.datosUsuarioBusqueda.estudioPrevio.length >= 1) {
      this.tipoAccionEstudiosPrevios = AccionEnum.EDITAR;
      this.estudiosPreviosCrear = false;
    } else {
      this.tipoAccionEstudiosPrevios = AccionEnum.CREAR;
      this.estudiosPreviosCrear = true;
    }
    if (this.datosUsuarioBusqueda.datoFiscal != null) {
      this.tipoAccionDatoFiscal = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDatoFiscal = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.ocupacion != null) {
      if (this.datosUsuarioBusqueda.ocupacion.tipoEmpresaId != null) {
        this.tipoAccionTipoEmpresa = AccionEnum.EDITAR;
      } else {
        this.tipoAccionTipoEmpresa = AccionEnum.CREAR;
      }
    } else {
      this.tipoAccionTipoEmpresa = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.ocupacion != null) {
      if (this.datosUsuarioBusqueda.ocupacion.tipoPuestoEmpresaId != null) {
        this.tipoAccionTipoPuesto = AccionEnum.EDITAR;
      } else {
        this.tipoAccionTipoPuesto = AccionEnum.CREAR;
      }
    } else {
      this.tipoAccionTipoPuesto = AccionEnum.CREAR;
    }
    this.inicializarForm();
  }
  //DATOS DE CONTACTO
  public getTipoDireccion() {
    this.subscriptionTipoDireccion = this.direccionService.obtenerTipoDireccion().subscribe(
      (tipoDireccion: TipoDireccion[]) => {
        this.tipoDireccionList = tipoDireccion;
      });
  }

  public getPais() {
    this.subscriptionPais = this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisList = pais;
      });
  }

  public onPaisSelected(pais: number) {
    this.estadoList = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estadoList = estados;
        })
    }
  }

  public getTipoTelefono() {
    this.subscriptionTipoTelefono = this.direccionService.obtenerTipoTelefono().subscribe(
      (tipoTelefono: TipoTelefono[]) => {
        this.tipoTelefonoList = tipoTelefono;
      });
  }

  //DATOS BIOGRAFICOS
  public getTipoSexo() {
    this.generoService.obtenerGenero().subscribe(
      (tipoSexo: Genero[]) => {
        this.sexoList = tipoSexo;
      }
    );
  }
  public getTipoGenero() {
    this.generoService.obtenerGenero().subscribe(
      (tipoGenero: Genero[]) => {
        this.generoList = tipoGenero;
      }
    );
  }
  public getTipoSanguineo() {
    this.grupoSanguineoService.obtenerGrupoSanguineo().subscribe(
      (tipoSanguineo: GrupoSanguineo[]) => {
        this.sanguineoList = tipoSanguineo;
      }
    );
  }
  public getTipoCivil() {
    this.estadoCivilService.obtenerEstadoCivil().subscribe(
      (tipoCivil: EstadoCivil[]) => {
        this.estadoCivilList = tipoCivil;
      }
    );
  }

  public getPaisBiografia() {
    this.subscriptionPais = this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisListBiografia = pais;
      });
  }

  public onPaisSelectedBiografia(pais: number) {
    this.estadoListBiografia = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estadoListBiografia = estados;
        })
    }
  }

  public CURPInsert(curp: string) {
    if (curp.trim().length == 18) {
      let usuarioId = this.datosUsuarioBusqueda.usuarioId;
      if (!usuarioId) {
        usuarioId = 0;
      }

      this.biografiaService.obtenerBiografiaByCurp(curp, usuarioId).subscribe(
        biografia => {
          if (biografia == null) {
            return this.curpEncontrado = false;
          } else {
            return this.curpEncontrado = true;
          }
        });
    }
  }
  //ESTUDIOS PREVIOS
  public getTipoNivel() {
    this.nivelService.getNivel().subscribe(
      (tiposNivel: Nivel[]) => {
        this.nivelList = tiposNivel;
      }
    );
  }

  //OCUPACION
  public getTipoEmpresa() {
    this.subscriptionTipoEmpresa = this.empresaService.obtenerTipoEmpresa().subscribe(
      (tipoEmpresa: TipoEmpresa[]) => {
        this.tipoEmpresaList = tipoEmpresa;
      });
  }

  public getTipoPuesto() {
    this.subscriptionPuesto = this.empresaService.obtenerTipoPuestoEmpresa().subscribe(
      (tipoPuestoEmpresa: TipoPuestoEmpresa[]) => {
        this.tipoPuestoEmpresaList = tipoPuestoEmpresa;
      });
  }

  ngOnDestroy(): void {

    if (this.subscriptionTipoEmpresa) {
      this.subscriptionTipoEmpresa.unsubscribe();
    }
    if (this.subscriptionPuesto) {
      this.subscriptionPuesto.unsubscribe();
    }
    if (this.subscriptionTipoDireccion) {
      this.subscriptionTipoDireccion.unsubscribe();
    }
    if (this.subscriptionPais) {
      this.subscriptionPais.unsubscribe();
    }
    if (this.subscriptionEstado) {
      this.subscriptionEstado.unsubscribe();
    }
    if (this.subscriptionTipoTelefono) {
      this.subscriptionTipoTelefono.unsubscribe();
    }

  }



}
