import { Pipe, PipeTransform } from '@angular/core';
import i18next from 'node_modules/i18next';

@Pipe({
  name: 'frecuencia'
})
export class FrecuenciaPipe implements PipeTransform {

  transform(frecuencia: string): string {
    if (frecuencia == null || frecuencia.length == 0 || frecuencia == '')
      return i18next.t('_grupos.sin_frecuencia');
    return frecuencia;
  }

}
