import { Component, Input, OnInit } from '@angular/core';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { RecursoAdjunto } from 'src/app/_models/recurso-adjunto';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service'
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';

@Component({
  selector: 'app-contenido-archivos',
  templateUrl: './contenido-archivos.component.html',
  styleUrls: ['./contenido-archivos.component.scss']
})
export class ContenidoArchivosComponent implements OnInit {

  @Input() grupoID: number;
  constructor(public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private ensenanzaApredServ: EnsenanzaAprendizajeService,) { }
  archivos: RecursoAdjunto[] = [];

  ngOnInit(): void {
    this.cargarArchivos();
    if(this.ensenanzaApredServ.invocarObtenerArchivoSubscription == undefined) {
      this.ensenanzaApredServ.invocarObtenerArchivoSubscription = this.ensenanzaApredServ.invocarObtenerArchivos.subscribe(() => {
          this.archivos = [];
          this.cargarArchivos();
        }
      );
    }
  }

  public cargarArchivos() {
    this.EnsenanzaAprendizajeAlumnoService.obtenerArchivos(this.grupoID).subscribe(
      (archivos: ApiResponse<RecursoAdjunto[]>) => {
      if (archivos.data) {
        for (var y = 0; y < archivos.data.length; y++) {
          var indexDocument = archivos.data[y].nombreOriginal.indexOf(".");
          var tipoDocument = archivos.data[y].nombreOriginal.substr(indexDocument);
          archivos.data[y].tipoDocumento = tipoDocument;
        }
        this.archivos = archivos.data;
      }
    })
  }

  public descargarRecurso(nombreFisico: string) {
    window.open("https://universiondemo01storage.blob.core.windows.net/recurso-adjunto/" + nombreFisico, "_blank");
  }

  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public abrirUrl(url: string) {
    window.open(url, "_blank");
  }
}
