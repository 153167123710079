export class HistorialCalFinal {
    final:            number;
    motivo:           number;
    fecha:       string;
    usuario: string;

    constructor(_HistorialCalFinal: Object) {
        Object.assign(this, _HistorialCalFinal);
    }
    
}
