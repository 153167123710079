import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, TemplateRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormControl, FormGroup } from '@angular/forms';
import { SeguimientoCarteraService } from '../../_services/seguimiento-cartera.service';
import { DatosPersonalesCarteraComponent } from './datos-personales-cartera/datos-personales-cartera.component';
import { EstadoCuentaCarteraComponent } from './estado-cuenta-cartera/estado-cuenta-cartera.component';
import { MovimientosCarteraComponent } from './movimientos-cartera/movimientos-cartera.component';
import { NuevoIngresoService } from '../../_services/nuevo-ingreso.service';
import { SolicitudApoyoFinancieroService } from '../../_services/solicitud-apoyo-financiero.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PeriodoService } from '../../_services/periodo.service';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { DatoFiscal } from 'src/app/_models/dato-fiscal';
import { EstadoCuentaDto } from 'src/app/_models/estado-cuenta-dto';
import { ResumenEstadoCuentaDto } from 'src/app/_models/resumenEstadoCuentaDto';
import { DatosContactoF, DatosFiscal, Imagen } from 'src/app/_models/usuario';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { Periodo } from 'src/app/_models/periodo';
import { TraductorService } from '../../_services/traductor.service';

@Component({
  selector: 'app-detalle-cartera',
  templateUrl: './detalle-cartera.component.html',
  styleUrls: ['./detalle-cartera.component.scss']
})
export class DetalleCarteraComponent implements OnInit, OnDestroy {

  public mostrarPeriodoPorModulo: boolean = true;
  //VARIABLE INFORMACION USUARIO GLOBAL
  public datosSeguimientoUsuario: any;
  public alumnoSeleccionado: any;
  //VIEWCHILD TABS
  @ViewChild(DatosPersonalesCarteraComponent) datosPersonales: DatosPersonalesCarteraComponent;
  @ViewChild(EstadoCuentaCarteraComponent) estadoCuenta: EstadoCuentaCarteraComponent;
  @ViewChild(MovimientosCarteraComponent) movimientos: MovimientosCarteraComponent;
  //VARIABLEs TAB SELECCIONADO
  public tabSeleccionadoIndex: FormControl = new FormControl(0);
  public tabSeleccionado: number = 0;
  //VARIABLES GUARDADO EXITOSO
  public datosContactoGuardado: boolean = true;
  public datosFacturacionGuardado: boolean = true;
  //VARIABLES FOTO PERFIL
  @ViewChild('dialogFoto') dialogFoto: TemplateRef<'dialogFoto'>;
  public dialogRefFoto: any;
  public imagenUsuarioForm: FormGroup;
  public message: string;
  public imagePath: SafeResourceUrl;
  public imagePath2: SafeResourceUrl;
  public imagePath3: SafeResourceUrl;
  public imgURL: string | ArrayBuffer;
  public eventImage: FileList[];
  public base64Image: string;
  public boolImagen: boolean = false;
  public banderaRenderizarFoto: boolean = false;
  //VARIABLES CATALOGOS
  public subscriptionPeriodo: Subscription;
  public periodoList: Periodo[];
  //VARIABLES OBS ESTATUS CUENTA
  public subscriptionDatoEstatus: Subscription;
  public datoEstatus$: Observable<string>;
  public datoEstatus: string;
  public tabRequerido: string = '';
  //Obs
  public datosMostrar$: Observable<any>;
  public subscriptionDatosMostrar: Subscription;

  constructor(public dialogDetalleCarteraRef: MatDialogRef<DetalleCarteraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private seguimientoService: SeguimientoCarteraService,
    public nuevoIngresoService: NuevoIngresoService,
    private solicitudApoyoFinancieroService: SolicitudApoyoFinancieroService,
    private _cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private periodoService: PeriodoService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    this.datosMostrar$ = this.seguimientoService.getDatosMostrar$();
    this.subscriptionDatosMostrar = this.datosMostrar$.subscribe((resumen: any) => {
      this.alumnoSeleccionado = resumen;
    });
    this.seguimientoService.setDatosAlumno$(this.data);
    if (this.seguimientoService.invMetodoNuevoMovimientoSubscription == undefined) {
      this.seguimientoService.invMetodoNuevoMovimientoSubscription = this.seguimientoService.invocarNuevoMovimiento.subscribe(() => {
        setTimeout(() => {
          this.tabSeleccionadoIndex.setValue(3);
          this.seguimientoService.invocarMetodoNuevoMovimientoTipoPago();
        }, 100);
      })
    }
    this.tabRequerido = this.data.tabRequerido;
    this.datoEstatus$ = this.seguimientoService.getDatoEstatus$();
    this.subscriptionDatoEstatus = this.datoEstatus$.subscribe(
      (datoEstatus: string) => {
        this.datoEstatus = datoEstatus;
      });
    this.getTipoPeriodo(this.data.id);
    if (this.tabRequerido === 'nombre') {
      setTimeout(() => {
        this.tabSeleccionadoIndex.setValue(0);
      }, 500);
    }
    if (this.tabRequerido === 'saldo') {
      setTimeout(() => {
        this.tabSeleccionadoIndex.setValue(1);
      }, 500);
    }
    if (this.tabRequerido === 'movimiento') {
      setTimeout(() => {
        this.tabSeleccionadoIndex.setValue(2);
      }, 500);
    }
  }

  //ENVIAR IINFO DE TABS HIJAS
  public enviarFormulario() {
    switch (this.tabSeleccionado) {
      case 0: {
        let validarDatosContacto = Object.assign(this.datosPersonales.datosContactoForm.value);
        this.nuevoIngresoService.obtenerCorreoOcupado(validarDatosContacto.correo, validarDatosContacto.usuarioId).subscribe(
          (respuestaCorreo: SimpleResponse) => {
            if (respuestaCorreo.success) {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos personales. El correo ya se encuentra ocupado, por favor introduzca otro correo.');
            } else {
              let datosDatoFiscalForm = Object.assign(this.datosPersonales.datosFacturacionForm.value);
              this.nuevoIngresoService.GetDatoFiscalByRFC(datosDatoFiscalForm.rfc, datosDatoFiscalForm.usuarioId).subscribe(
                (datoFiscal: ApiResponse<DatoFiscal>) => {
                  if (datoFiscal.data != null) {
                    this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de facturacion. El RFC ingresado ya esta registrado, ingrese un RFC diferente.');
                  } else {
                    if (this.datosPersonales.datosContactoFormCambio) {
                      let datosContactoForm = Object.assign(this.datosPersonales.datosContactoForm.value);
                      let datosContacto: DatosContactoF = {
                        usuarioId: datosContactoForm.usuarioId,
                        correo: datosContactoForm.correo,
                        direccion: [
                          {
                            tipoDireccionId: datosContactoForm.tipoDireccionId,
                            entidadFederativaId: datosContactoForm.entidadFederativaId,
                            ciudad: datosContactoForm.ciudad,
                            calle: datosContactoForm.calle,
                            numeroExterior: datosContactoForm.numeroExterior,
                            numeroInterior: datosContactoForm.numeroInterior,
                            colonia: datosContactoForm.colonia,
                            codigoPostal: datosContactoForm.codigoPostal
                          }
                        ],
                        telefono: [
                          {
                            tipoTelefonoId: datosContactoForm.tipoTelefonoId,
                            paisId: datosContactoForm.paisId,
                            numero: datosContactoForm.numero,
                            extension: datosContactoForm.extension
                          }
                        ]
                      }
                      this.solicitudApoyoFinancieroService.registrarDatosContacto(datosContacto).subscribe(
                        (datosContacto: SimpleResponse) => {
                          if (datosContacto.success) {
                            this.datosContactoGuardado = true;
                            this.datosPersonales.datosContactoFormCambio = false;
                          } else {
                            this.datosContactoGuardado = false;
                          }
                        }
                      )
                    }
                    if (this.datosPersonales.datosFacturacionFormCambio) {
                      let datosDatoFiscalForm = Object.assign(this.datosPersonales.datosFacturacionForm.value);
                      let datosDatoFiscal: DatosFiscal = {
                        UsuarioId: datosDatoFiscalForm.usuarioId,
                        Rfc: datosDatoFiscalForm.rfc,
                        RazonSocial: datosDatoFiscalForm.razonSocial,
                        EntidadFederativaId: datosDatoFiscalForm.entidadFederativaFacturacionId,
                        Calle: datosDatoFiscalForm.calleFacturacion,
                        Ciudad: datosDatoFiscalForm.ciudadFacturacion,
                        NumeroExterior: datosDatoFiscalForm.numeroExteriorFacturacion,
                        NumeroInterior: datosDatoFiscalForm.numeroInteriorFacturacion,
                        Colonia: datosDatoFiscalForm.coloniaFacturacion,
                        CodigoPostal: datosDatoFiscalForm.codigoPostalFacturacion,
                        Nombre: datosDatoFiscalForm.nombre,
                        ApellidoPaterno: datosDatoFiscalForm.apellidoPaterno,
                        ApellidoMaterno: datosDatoFiscalForm.apellidoMaterno
                      }
                      this.nuevoIngresoService.agregarDatosFiscal(datosDatoFiscal).subscribe(
                        (datoFiscal: SimpleResponse) => {
                          if (datoFiscal.success) {
                            this.datosFacturacionGuardado = true;
                            this.datosPersonales.datosFacturacionFormCambio = false;
                          } else {
                            this.datosFacturacionGuardado = false;
                          }
                        });
                    }
                    if (this.datosContactoGuardado && this.datosFacturacionGuardado) {
                      this.snackService.mostrarSnackBack('Se ha guardado los datos del usuario.');
                    } else {
                      this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos del usuario. Por favor, intente de nuevo más tarde.');
                    }
                  }
                }
              );
            }
          });
        break;
      }
      case 1: {
        break;
      }
      case 2: {
        break;
      }
      case 3: {
        break;
      }
      case 4: {
        break;
      }
      default: {
        break;
      }
    }
  }

  //CATALOGOS
  public getTipoPeriodo(usuarioId: number) {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodosByUsuario(usuarioId).subscribe(
      (periodo: Periodo[]) => {
        if (periodo.length > 0) {
          this.periodoList = periodo;
          this.seguimientoService.setDatosPeriodoUsuario$(this.periodoList);
          this.buscarInformacionUsuario(this.data.id, this.periodoList[0].periodoId);
        } else {
          this.buscarInformacionUsuario(this.data.id);
        }
      });
  }

  //BUSQUEDA INICIAL
  public buscarInformacionUsuario(usuarioId: number, periodoId?: number) {
    this.seguimientoService.obtenerSeguimientoUsuarioById(usuarioId).subscribe(
      (seguimientoUsuario: ApiResponse<EstadoCuentaDto[]>) => {
        this.datosSeguimientoUsuario = seguimientoUsuario.data;
        this.seguimientoService.setDatosSeguimientoUsuario$(seguimientoUsuario.data);
        const Alumno = this.datosSeguimientoUsuario.alumno.find(x => x.alumnoId === this.data.alumnoId);
        this.alumnoSeleccionado = Alumno;
        this.seguimientoService.setAlumnoIdSeleccionado$(this.alumnoSeleccionado.alumnoId);
        if (periodoId !== undefined) {
          this.buscarInfomacionEstadoCuenta(this.data.id, periodoId);
        } else {
          let estadoCuentaSinMovimientos = []
          this.seguimientoService.setDatosEstadoCuentaUsuario$(estadoCuentaSinMovimientos);
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-2');
          this.seguimientoService.setDatoEstatus$(mensaje);
          let resumenSinMovimientos = {
            saldoPagar: 0,
            saldoTotal: 0,
            saldoVencido: 0,
            usuarioId: usuarioId
          }
          this.seguimientoService.setDatosResumenUsuario$(resumenSinMovimientos);
          this.renderizarFormularioTab();
        }
        this.nuevoIngresoService.imagenUsuario(this.datosSeguimientoUsuario.usuarioId).subscribe(
          (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
            if (datosUsuarioImagen.success) {
              this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
              this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
            }
          }
        );
        this.banderaRenderizarFoto = true;
        this.renderizarFoto();
      }
    );
  }

  //BUSQUEDA ESTADO CUENTA
  public buscarInfomacionEstadoCuenta(usuarioId: number, periodoId: number) {
    this.seguimientoService.obtenerEstadoCuentaByUsuarioId(usuarioId, periodoId, this.data.alumnoId).subscribe(
      (estadoCuenta: EstadoCuentaDto[]) => {
        this.seguimientoService.setDatosEstadoCuentaUsuario$(estadoCuenta);
        this.buscarInfomacionResumenEstadoCuenta(usuarioId);
      });
  }

  //BUSQUEDA RESUMEN ESTADO CUENTA
  public buscarInfomacionResumenEstadoCuenta(usuarioId: number) {
    this.seguimientoService.obtenerResumenEstadoCuentaByUsuarioId(this.data.alumnoId).subscribe(
      (resumen: ApiResponse<ResumenEstadoCuentaDto>) => {
        if (resumen.data.saldoVencido > 0) {
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-1');
          this.seguimientoService.setDatoEstatus$(mensaje);
        } else {
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-2');
          this.seguimientoService.setDatoEstatus$(mensaje);
        }
        this.seguimientoService.setDatosResumenUsuario$(resumen.data);
        this.renderizarFormularioTab();
      });
  }

  //RENDERIZA TABS HIJAS
  public renderizarFormularioTab() {
    this.datosPersonales.renderizarFormulario();
  }

  //TAB CHANGE
  public onChangeTab(event: MatTabChangeEvent) {
    this.tabSeleccionado = event.index;
  }

  //IMAGEN DE PERFIL
  public renderizarFoto() {
    this.imagenUsuarioForm = new FormGroup
      ({
        usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
        fotografia: new FormControl(''),
        fotografiaFile: new FormControl(''),
      });
  }

  get fotografia() { return this.imagenUsuarioForm.get('fotografia'); }

  preview(event) {
    if (event.length === 0)
      return;
    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath3 = event;
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.eventImage = event;
  }

  public imagenModal() {
    if (!this.boolImagen) {
      this.dialogRefFoto = this.dialog.open(this.dialogFoto, { disableClose: true });
    } else {
      this.dialogDetalleCarteraRef.close();
    }
  }

  public cerrarModalImagen(descartar: boolean) {
    if (descartar) {
      this.dialogRefFoto.close();
    } else {
      this.dialogRefFoto.close();
      this.onFileChangePerfil(this.eventImage);
      this.enviarImagen();
      this.imgURL = "";
    }
  }

  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.onload = () => {
        this.datosSeguimientoUsuario.fotografia = reader.result;
        this._cd.markForCheck();
        this.imagenUsuarioForm.patchValue({
          fotografia: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  }

  public onFileChangePerfil(event) {
    const reader = new FileReader();
    if (event && event.length > 0) {
      this.imagePath2 = event;
      this.imagePath = event;
      const [file] = event;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.readAsDataURL(event[0]);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
        this.imagePath2 = reader.result;
      }
      this.eventImage = event;
    }
  }

  public enviarImagen() {
    let imagenForm = Object.assign(this.imagenUsuarioForm.value);
    let imagen: Imagen = {
      usuarioId: imagenForm.usuarioId,
      fotografia: imagenForm.fotografia,
      fotografiaFile: imagenForm.fotografiaFile,
    }
    this.nuevoIngresoService.editarImagen(imagen).subscribe(
      (imagenData: SimpleResponse) => {
        if (imagenData.success) {
          this.snackService.mostrarSnackBack('Se ha guardado la imagen correctamente.');
        } else {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la imagen. Por favor, intente de nuevo más tarde.');
        }
      }
    )
  }

  //CERRAR MODAL
  public cerrarModal() {
    this.dialogDetalleCarteraRef.close();
  }

  //COMPONENTE DESTUIDO
  ngOnDestroy(): void {
    if (this.subscriptionPeriodo) {
      this.subscriptionPeriodo.unsubscribe();
    }
    if (this.subscriptionDatoEstatus) {
      this.subscriptionDatoEstatus.unsubscribe();
    }
    if (this.subscriptionDatosMostrar) {
      this.subscriptionDatosMostrar.unsubscribe();
    }
  }
}
