import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GrupoService } from '../../../../_services/grupo.service';

@Component({
  selector: 'app-modal-grupo-eliminar',
  templateUrl: './modal-grupo-eliminar.component.html',
  styleUrls: ['./modal-grupo-eliminar.component.scss']
})
export class ModalGrupoEliminarComponent implements OnInit {

  public numGruposSeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public claveGrupos: string = '';

  constructor(public dialogRef: MatDialogRef<ModalGrupoEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private grupoService: GrupoService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numGruposSeleccionados = this.data.length;
    }
  }

  public eliminarGrupo() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(p => (p.grupoId.toString()));
      this.claveGrupos = arrayClaves.toString();
    } else {
      this.claveGrupos = this.data.grupoId.toString();
    }
    this.grupoService.eliminarGrupo(this.claveGrupos).subscribe(
      eliminado => {
        if (eliminado) {
          this.snackBar.open("Se ha eliminado la información.", "OK", { duration: 5000 });
          this.grupoService.invocarMetodoObtenerGrupos();
          this.dialogRef.close(this.data);
        } else {
          this.snackBar.open("Ha ocurrido un error al eliminar el grupo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al eliminar el grupo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

}
