import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NivelService } from '../../_services/nivel.service';
import { ModalidadService } from '../../_services/modalidad.service';
import { Nivel } from '../../_models/nivel';
import { Modalidad } from '../../_models/modalidad';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtileriasService } from '../../_services/utilerias.service';
import { AccionEnum } from '../../_models/accion.enum';
import { Programa } from '../../_models/programa';
import { TipoAdmisionService } from 'src/app/_services/tipo-admision.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { TipoAdmision } from 'src/app/_models/tipo-admision';
import { CampusService } from 'src/app/_services';
import { Campus } from 'src/app/_models';

@Component({
  selector: 'app-programa-datos-generales',
  templateUrl: './programa-datos-generales.component.html',
  styleUrls: ['./programa-datos-generales.component.scss']
})
export class ProgramaDatosGeneralesComponent implements OnInit, OnDestroy {

  @Input() datosPrograma: Programa;
  public datosGenerales: any;
  public idPrograma: number = 0;
  public errorPrograma: string = "";
  public tipoAccion: number;
  public programaForm: FormGroup;
  private subcripcionNivel: Subscription;
  private subcripcionModalidad: Subscription;
  public tipoNivelList: Nivel[] = [];
  public tipoModalidadList: Modalidad[] = [];
  public clavePrograma: string = '';
  private subcripcionTipoAdmision: Subscription;
  public tipoAdmisionList: TipoAdmision[] = [];
  public campusList: Campus[] = [];

  constructor(private nivelService: NivelService,
    private modalidadService: ModalidadService,
    private programaAcademicoService: ProgramaAcademicoService,
    private snackBar: MatSnackBar,
    private utileriasService: UtileriasService,
    private tipoAdmisionService: TipoAdmisionService,
    private campusService: CampusService,) { }

  ngOnInit(): void {
    this.tipoAccion = this.datosPrograma.tipoAccion;
    if (this.tipoAccion === AccionEnum.EDITAR) {
      this.clavePrograma = this.datosPrograma.clave;
    }
    this.inicializarFrom();
    this.getTipoNivel();
    this.getTipoModalidad();
    this.getTipoAdmision();
    this.obtenerCampus();
  }

  public inicializarFrom() {
    this.programaForm = new FormGroup({
      clave: new FormControl(this.datosPrograma.clave === '' ? '' : this.datosPrograma.clave, [Validators.required]),
      edicion: new FormControl(this.datosPrograma.edicion === '' ? '' : this.datosPrograma.edicion, [Validators.required]),
      estatus: new FormControl(this.datosPrograma.estatus === false ? false : true),
      nombre: new FormControl(this.datosPrograma.nombre === '' ? '' : this.datosPrograma.nombre, [Validators.required]),
      nombreCorto: new FormControl(this.datosPrograma.nombreCorto === '' ? '' : this.datosPrograma.nombreCorto, [Validators.required]),
      campusId: new FormControl(this.datosPrograma.campusId === 0 ? 1 : this.datosPrograma.campusId, [Validators.required]),
      nivelId: new FormControl(this.datosPrograma.nivelId === 0 ? 1 : this.datosPrograma.nivelId, [Validators.required]),
      modalidadId: new FormControl(this.datosPrograma.modalidadId === 0 ? 1 : this.datosPrograma.modalidadId, [Validators.required]),
      fechaVigencia: new FormControl(this.datosPrograma.fechaVigencia === null ? null : this.utileriasService.formatearFecha(this.datosPrograma.fechaVigencia, 'YYYY[-]MM[-]DD'), [Validators.required]),
      tipoAdmisionId: new FormControl(this.datosPrograma.tipoAdmisionId === 0 ? 1 : this.datosPrograma.tipoAdmisionId, [Validators.required])
    });

    this.programaForm.valueChanges.subscribe(val => {
      this.programaAcademicoService.definirBoolCambio(true);
    });
  }

  get clave() { return this.programaForm.get('clave'); }
  get edicion() { return this.programaForm.get('edicion'); }
  get estatus() { return this.programaForm.get('estatus'); }
  get nombre() { return this.programaForm.get('nombre'); }
  get nombreCorto() { return this.programaForm.get('nombreCorto'); }
  get campusId() { return this.programaForm.get('campusId'); }
  get nivelId() { return this.programaForm.get('nivelId'); }
  get modalidadId() { return this.programaForm.get('modalidadId'); }
  get fechaVigencia() { return this.programaForm.get('fechaVigencia'); }
  get tipoAdmisionId() { return this.programaForm.get('tipoAdmisionId'); }

  public getTipoNivel() {
    this.subcripcionNivel = this.nivelService.getNivel().subscribe(
      (tiposNivel: Nivel[]) => {
        this.tipoNivelList = tiposNivel;
      }
    );
  }

  public obtenerCampus() {
    this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      })
  }

  public getTipoAdmision() {
    this.subcripcionTipoAdmision = this.tipoAdmisionService.obtenerTipoAdmision().subscribe(
      (tiposAdmision: ApiResponse<TipoAdmision[]>) => {
        this.tipoAdmisionList = tiposAdmision.data;
      }
    )
  }

  public getTipoModalidad() {
    this.subcripcionModalidad = this.modalidadService.getModalidad().subscribe(
      (tiposModalidad: Modalidad[]) => {
        this.tipoModalidadList = tiposModalidad;
      }
    );
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        console.log("validando", control);
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public enviarDatosGenerales() {
    this.datosGenerales = Object.assign(this.programaForm.value);
    if (this.programaForm.invalid) {
      this.validateAllFormFields(this.programaForm);
     // this.snackBar.open("Verifique los datos.", "OK", { duration: 5000 });
      return;
    }
    this.programaAcademicoService.generarPrograma(this.datosGenerales).subscribe(
      idPrograma => {
        if (idPrograma !== 0) {
          this.cambiarVistaEditar(idPrograma);
          this.programaAcademicoService.definirBoolCambio(false);
          this.programaAcademicoService.invocarMetodo();
          this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar el período. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al guardar el período. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

  public modificarDatosGenerales() {
    this.datosGenerales = Object.assign(this.programaForm.value);
    if (this.programaForm.invalid) {
      this.validateAllFormFields(this.programaForm);
      //this.snackBar.open("Verifique los datos.", "OK", { duration: 5000 });
      return;
    }
    this.datosGenerales.idPrograma = this.datosPrograma.programaId;
    this.datosGenerales.tabSeccion = "DG"
    this.programaAcademicoService.modificarProgramaDatosGenerales(this.datosGenerales).subscribe(
      editado => {
        if (editado) {
          this.programaAcademicoService.definirBoolCambio(false);
          this.programaAcademicoService.invocarMetodo();
          this.snackBar.open("Se ha modificado la información.", "OK", { duration: 5000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar el periodo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al guardar el periodo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

  public cambiarVistaEditar(idPrograma: number) {
    this.clavePrograma = this.datosGenerales.clave;
    this.datosPrograma.tipoAccion = AccionEnum.EDITAR;
    this.datosPrograma.programaId = idPrograma;
    this.tipoAccion = AccionEnum.EDITAR;
    this.idPrograma = idPrograma;
    this.programaAcademicoService.definirIdPrograma(idPrograma);
    this.programaAcademicoService.definirBoolTab(false);
  }

  ngOnDestroy(): void {
    if (this.subcripcionNivel) {
      this.subcripcionNivel.unsubscribe();
    }
    if (this.subcripcionModalidad) {
      this.subcripcionModalidad.unsubscribe();
    }
    if (this.subcripcionTipoAdmision) {
      this.subcripcionTipoAdmision.unsubscribe();
    }
  }

}
