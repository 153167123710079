import { AbstractControl, FormGroup } from '@angular/forms';
    
export function NegativeNumberValidator(number: string){
    return (formGroup: FormGroup) => {
        let numberField:AbstractControl = formGroup.controls[number];
        if (numberField.errors && ! numberField.errors.negativeNumberValidator) {
            return;
        }
        if (numberField.value >= 0) {
            numberField.setErrors(null);
        } 
        else {
            numberField.setErrors({negativeNumberValidator: true});
        }
    }
}