import { Component, OnInit, Inject, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Campus } from '../../_models/campus';
import { FormGroup, FormControl, AbstractControl, Validators } from '@angular/forms';
import { CampusService } from '../../_services/campus.service';
import { ConfiguracionApoyoFinanciero } from '../../_models/configuracion-apoyo-financiero';
import { ConfiguracionApoyoFinancieroService } from '../../_services/configuracion-apoyo-financiero.service';
import { SnackService } from 'src/app/services/snack-service.service';

@Component({
  selector: 'app-configuracion-apoyo-financiero',
  templateUrl: './configuracion-apoyo-financiero.component.html',
  styleUrls: ['./configuracion-apoyo-financiero.component.scss']
})
export class ConfiguracionApoyoFinancieroComponent implements OnInit, OnDestroy {
  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  public dialogoRefAdver: any;
  public configuracionApoyoForm: FormGroup;
  public campusList: Campus[] = [];
  public subscriptionCampus: Subscription;
  public boolCambio: boolean = false;
  public datosConfiguracionApoyo: ConfiguracionApoyoFinanciero;

  constructor(public dialogRef: MatDialogRef<ConfiguracionApoyoFinancieroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfiguracionApoyoFinanciero,
    private campusService: CampusService,
    private dialog: MatDialog,
    private configuracionApoyoFinancieroService: ConfiguracionApoyoFinancieroService,
    private snackService: SnackService ) { }

  ngOnInit(): void {
    this.obtenerCampus();
    this.inicializarForm();
  }

  public inicializarForm() {
    this.configuracionApoyoForm = new FormGroup({
      campusId: new FormControl(0, [Validators.required, this.validateId]),
      porcentajeMinimo: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]+/)]),
      porcentajeMaximo: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]+/)]),
      preasignacionApoyo: new FormControl(false)
    });

    this.configuracionApoyoForm.valueChanges.subscribe(val => {
      this.boolCambio = true;
    });
  }

  get porcentajeMinimo() { return this.configuracionApoyoForm.get('porcentajeMinimo'); }
  get porcentajeMaximo() { return this.configuracionApoyoForm.get('porcentajeMaximo'); }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.configuracionApoyoForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Campus es requerido.";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public obtenerCampus() {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      }
    );
  }

  public enviarConfiguracionApoyo() {
    this.datosConfiguracionApoyo = Object.assign(this.configuracionApoyoForm.value);
    this.configuracionApoyoFinancieroService.agregarConfiguracionApoyoFinanciero(this.datosConfiguracionApoyo).subscribe(
      configuracionApoyoFinanciero => {
        if (configuracionApoyoFinanciero != null) {
          this.snackService.mostrarSnackBack('Se ha guardado la configuración de apoyo financiero.');
          this.dialogRef.close(null);
        } else {
          this.snackService.mostrarSnackBack('El campus seleccionado ya tiene una configuración de apoyo financiero registrada.');
        }
      }
    );
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(null);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  public ngOnDestroy(): void {
    if (this.subscriptionCampus) {
      this.subscriptionCampus.unsubscribe();
    }
  }

  public validarPorcentajes = function (form: FormGroup, nombreControl1: string, nombreControl2: string) {
    if (form.get(nombreControl1).value !== null && form.get(nombreControl2).value !== null) {
      return form.get(nombreControl1).value < form.get(nombreControl2).value;
    }
    return true;
  }
}
