import { MovimientoService } from './../../_services/movimiento.service';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApiResponse } from '../../models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from '../../services/generacion-excel-service.service';
import { Campus } from '../../_models/campus';
import { Periodo } from '../../_models/periodo';
import { ProgramaActivo } from '../../_models/programa';
import { CampusService } from '../../_services/campus.service';
import { PeriodoService } from '../../_services/periodo.service';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { MatTableDataSource } from '@angular/material/table';
import { MovimientosDto } from '../../_models/Movimientos/MovimientosDto';

@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.scss']
})
export class MovimientosComponent implements OnInit, OnDestroy {

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

 //#region Constructor
 constructor(
  private periodoService: PeriodoService,
  public programaservice: ProgramaAcademicoService,
  private campusService: CampusService,
  private generadorExcel: GeneracionExcelServiceService,
  private _MovimientoService :MovimientoService

) { }
 //#endregion

  //#region  Cilo de vida
  ngOnInit(): void {
    this.LlenarFiltros();
    //this.ObtenerMovimientosTabla();
    // this.ObtenerProgramasTabla();
  }
  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
  }
 //#endregion

  //#region Propiedades Form
  public Today: Date = new Date();
  public Periodos: Periodo[] = [];
  public Programas: ProgramaActivo[] = [];
  public Campus: Campus[] = [];
  public Movimientos: any[] = [];
  public Filtros: any;
  public Detalle: boolean;

  public MovimientosTabla: MovimientosDto[] = [];
  public MovimientosTest: MovimientosDto;

  public ProgramasTabla: any[] = [];
  public ProgramasTest: any;
  //#endregion

  //#region ViewChild
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allMovimientos') private allMovimientos: MatOption;
  //#endregion

  //#region  FormControls
  public periodoF: FormControl = new FormControl();
  public ProgramaF: FormControl = new FormControl();
  public CampusF: FormControl = new FormControl();
  public MovimientosF: FormControl = new FormControl();
  public FechaInicioF: FormControl = new FormControl();
  public FechaFinF: FormControl = new FormControl();
  public MovimientosTotal : any;
  //#endregion

   //#region FormFiltros
   public inicializarForm(): void {
    this.periodoF.setValue(['-1']);
    this.ProgramaF.setValue(['-1']);
    this.CampusF.setValue(['-1']);
    this.MovimientosF.setValue(['-1']);
    this.FechaInicioF.setValue(new Date(new Date().setDate(new Date().getDate() - 5)));
    this.FechaFinF.setValue(this.Today);
  }
  //#endregion

   //#region Llenar filtros
   public LlenarFiltros(): void
   {
     this.inicializarForm();
     this.ObtenerPeriodos();
     this.ObtenerProgramas();
     this.ObtenerCampus();
     this.ObtenerMovimientos();
   }
   public ObtenerPeriodos(): void
   {
     this.Subscription.add(
       this.periodoService.obtenerPeriodos().subscribe(
         (periodos: Periodo[]) => {
          this.Periodos = periodos;
          this.periodoF.setValue([...this.Periodos.map(item => item.periodoId), 0]);
         }
       )
     );

   }
   public ObtenerProgramas(): void
   {
     this.Subscription.add(
       this.programaservice.obtenerProgramasActivos().subscribe(
         (programas: ApiResponse<ProgramaActivo[]>) => {
           this.Programas = programas.data;
           this.ProgramaF.setValue([...this.Programas.map(item => item.programaId), 0]);
         }
       )
     );
   }
   public ObtenerCampus():void
   {
     this.Subscription.add(
       this.campusService.obtenerCampus().subscribe(
         (campus : Campus[]) => {
           this.Campus = campus;
           this.CampusF.setValue([...this.Campus.map(item => item.campusId), 0]);
         }
       )
     );
   }
   public ObtenerMovimientos():void
   {
    this.Movimientos = [ { movimientoId: 1, nombre: 'Inicio'}, { movimientoId: 2, nombre: 'Término'} ];
    this.MovimientosF.setValue([...this.Movimientos.map(item => item.nombre), 0]);
   }
   //#endregion

   //#region Métodos Filtro
   public buscarFiltros(): void {
    this.filtrar();
  }
  public filtrar()
  {
    this.Filtros = {
      'PeriodoId' : this.periodoF.value == null ? [] : this.periodoF.value,
      'ProgramaId': this.ProgramaF.value == null ? [] : this.ProgramaF.value,
      'CampusId': this.CampusF.value == null ? [] : this.CampusF.value,
      'MovimientoId': this.MovimientosF.value == null ? [] : this.MovimientosF.value,
      'FechaInicio': this.FechaInicioF.value,
      'FechaFin': this.FechaFinF.value
    };
    this.ObtenerMovimientosTabla();
  }

  public filtro():void{
    let periodo: any[] = this.periodoF.value == null ? [] : this.periodoF.value;
    let programa: any[] = this.ProgramaF.value == null ? [] : this.ProgramaF.value;
    let campus: any[] = this.CampusF.value == null ? [] : this.CampusF.value;
  }
  /************************************************* */
  public toggleAllSelectionPeriodo():void {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.filtro();
  }
  public tosslePerOnePeriodo(all):boolean {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if (this.periodoF.value.length == this.Periodos.length) { this.allPeriodo.select(); }
    this.filtro();
  }
  /************************************************ */

  public toggleAllSelectionPrograma():void {
    if (this.allPrograma.selected) {
      this.ProgramaF.patchValue([...this.Programas.map(item => item.programaId), 0]);
    } else {
      this.ProgramaF.patchValue([]);
    }
    this.filtro();
  }
  public tosslePerOnePrograma(all):boolean {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.ProgramaF.value.length == this.Programas.length) { this.allPrograma.select(); }
    this.filtro();
  }
  /************************************************ */
  public toggleAllSelectionCampus():void {
    if (this.allCampus.selected) {
      this.CampusF.patchValue([...this.Campus.map(item => item.campusId), 0]);
    } else {
      this.CampusF.patchValue([]);
    }
    this.filtro();
  }
  public tosslePerOneCampus(all):boolean {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.CampusF.value.length == this.Campus.length) { this.allCampus.select(); }
    this.filtro();
  }
  /************************************************ */
  public toggleAllSelectionMovimientos():void {
    if (this.allMovimientos.selected) {
      this.MovimientosF.patchValue([...this.Movimientos.map(item => item.nombre), 0]);
    } else {
      this.MovimientosF.patchValue([]);
    }
    this.filtro();
  }
  public tosslePerOneMovimientos(all):boolean {
    if (this.allMovimientos.selected) {
      this.allMovimientos.deselect();
      return false;
    }
    if (this.MovimientosF.value.length == this.Movimientos.length) { this.allMovimientos.select(); }
    this.filtro();
  }
  /************************************************ */
  public ShowDetalle(value: boolean):void{
    this.Detalle = value;
  }
   //#endregion


   //#region MatTable Movimientos
    public MovimientosDS: MatTableDataSource<MovimientosDto>;
    public displayedColumns: string[] = [
      'movimiento',
      'hombres',
      'mujeres',
      'total',
      'adultosMayores'];

    public ObtenerMovimientosTabla(): void
    {
      this.Subscription.add(
        this._MovimientoService.ObtenerMovimientos(this.Filtros).subscribe((resp: ApiResponse<any>) => {
          this.MovimientosTotal = resp.data;


          this.MovimientosDS = new MatTableDataSource(this.MovimientosTotal.movimientoDto);

          this.ObtenerProgramasTabla();
          this.ObtenerMovimientosDetalleTabla();
        })
      );
    }

  //#endregion

   //#region MatTable Movimientos
   public ProgramasDS: MatTableDataSource<any>;
   public displayedColumnsProgram: string[] = [];

   public ObtenerProgramasTabla(): void
   {
    this.displayedColumnsProgram = [];
    this.displayedColumnsProgram = this.MovimientosTotal.programas;

     this.ProgramasDS = new MatTableDataSource(this.MovimientosTotal.movimientoProgramaDinamyc);
   }

 //#endregion


 //#region MatTable Movimientos
 public MovimientosDetalle: MatTableDataSource<any>;
 public displayedColumnsMovimientosDetalle: string[] = [
  'no',
  'id',
  'institucion',
  'campus',
  'programa',
  'nombreCompleto',
  'edad',
  'genero',
  'adultoMayor',
  'fechaInicio',
  'fechaFin',
  'movimiento'
 ];

 public ObtenerMovimientosDetalleTabla(): void
 {
   this.MovimientosDetalle = new MatTableDataSource(this.MovimientosTotal.detalleMovimientoDto);
   this.MovimientosDetalle.paginator = this.paginator;
 }

//#endregion

  public descargarExcel():void{
    if (!this.Detalle) {
      this.Subscription.add(
        this._MovimientoService.descargarExcel(this.MovimientosTotal).subscribe((blob: Blob) => {
        this.generadorExcel.GenerarExcel(blob, "Movimientos");
      }));
    } else {
      this.Subscription.add(
        this._MovimientoService.DownloadExcelDetalle(this.MovimientosTotal).subscribe((blob: Blob) => {
        this.generadorExcel.GenerarExcel(blob, "Movimientos");
      }));
    }
  }

}
