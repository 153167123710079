import { FiltroAdminAcademicoAlumnoDto } from './../_models/FiltroAdminAcademicoAlumnoDto';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, Subject } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { Documento } from '../_models/documento';
import { UsuarioDocumento } from '../_models/usuario-documento';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { SolicitudApoyoFinanciero } from '../_models/solicitud-apoyo-financiero';
import { Usuario } from '../_models';
import { SeguimientoApoyoFinancieroDto } from '../_models/SeguimientoApoyoFinancieroDto';

@Injectable({
  providedIn: 'root'
})
export class SolicitudApoyoFinancieroService {

  private datosUsuarioBusqueda$ = new Subject<any>();
  private formValidoNuevaSolicitud$ = new Subject<boolean>();

  invocarObtenerSolicitudes = new EventEmitter();
  obtenerSolicitudesSubscription: Subscription;

  private changesApoyoFinanciero$ = new Subject<boolean>();
  private changesDatosUsuario$ = new Subject<boolean>();
  private changesSituacionEconomica$ = new Subject<boolean>();
  private changesDocumentos$ = new Subject<boolean>();
  private formCambioNombre$ = new Subject<string>();

  constructor(private httpClient: HttpClient) { }

  /**
   * Obtiene los datos de un usuario mediante un correo
   * @param correoBusqueda
   * @returns
   */
  public obtenerUsuariobByCorreo(correoBusqueda: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/GetUsuarioByCorreo?correo=` + correoBusqueda);
  }

  /**
   * Obtiene todos los usuarios que han hecho una solicitud de apoyo financiero
   */
  public obtenerSolicitudesApoyoFinanciero(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/SeguimientoApoyoFinanciero/${pagina}/${registrosPagina}`, filtro);
  }

  /**
   * Obtiene todos los usuarios que han hecho una solicitud de apoyo financiero
   */
  public obtenerSolicitudesApoyoFinancieroByUsuario(usuarioId: number): Observable<ApiResponse<SolicitudApoyoFinanciero>> {
    return this.httpClient.get<ApiResponse<SolicitudApoyoFinanciero>>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/GetSolicitudesApoyoFinancieroByUsuario/${usuarioId}`);
  }

  /**
   * Obtiene los datos de una solicitud mediante el correo
   * @param correoBusqueda
   * @returns
   */
  public obtenerUsuarioSolicitud(correoBusqueda: string): Observable<ApiResponse<SolicitudApoyoFinanciero[]>> {
    return this.httpClient.get<ApiResponse<SolicitudApoyoFinanciero[]>>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/GetUsuarioApoyoFinanciero?correo=${correoBusqueda}`);
  }

  /**
   * Registra un nuevo usuario si es que no existe
   * @param datosUsuario
   * @returns
   */
  public registrarNuevoUsuario(datosUsuario): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/PostNuevoUsuario`, datosUsuario);
  };

  /**
   * Agrega nuevos datos al apoyo financiero del usuario
   * @param datosApoyoFinanciero
   * @returns
   */
  public registrarApoyoFinanciero(datosApoyoFinanciero): Observable<ApiResponse<number>> {
    return this.httpClient.post<ApiResponse<number>>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/PostApoyoFinanciero`, datosApoyoFinanciero);
  };

  /**
   * Edita los datos de paoyo financiero del usuario
   * @param datosApoyoFinanciero
   * @returns
   */
  public ActualizarApoyoFinanciero(datosApoyoFinanciero): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/PutApoyoFinanciero`, datosApoyoFinanciero);
  };

  /**
   * Edita los datos personales del usuario
   * @param datosPersonales
   * @returns
   */
  public editarDatosPersonales(datosPersonales): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/PutDatosPersonales`, datosPersonales);
  };

  /**
   * Agrega los datos de contacto que ingresa el usuario
   * @param datosContacto
   * @returns
   */
  public registrarDatosContacto(datosContacto): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/PostDatosContacto`, datosContacto);
  };

  /**
   * Agrega o modifica los registros de datos de familia del usuario
   * @param datosFamilia
   * @returns
   */
  public registrarDatosFamilia(datosFamilia): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/PostDatosFamilia`, datosFamilia);
  };

  /**
   * Servicio que agrega o modifica los registros de datos de ocupacion del usuario
   * @param ocupacion
   * @returns
   */
  public registrarOcupacion(ocupacion): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/PostOcupacion`, ocupacion);
  };

  /**
   * Servicio que agrega o modifica los registros de la situacion economica del usuario
   * @param datosSituacionEconomica
   * @returns
   */
  public registrarSituacionEconomica(datosSituacionEconomica): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/PostSituacionEconomica`, datosSituacionEconomica);
  };

  /**
   * Servicio que agrega nuevos documentos
   * @param documentos
   * @returns
   */
  public registrarDocumentos(documentos): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/PostDocumentos`, documentos);
  };

  /**
   * Obtiene el documento segun el id del documento
   * @param documentoId
   * @returns
   */
  public obtenerDocumentoById(documentoId: number): Observable<ApiResponse<UsuarioDocumento>> {
    return this.httpClient.get<ApiResponse<UsuarioDocumento>>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/GetDocumentoById?idDocumento=${documentoId}`);
  }

  /**
   * Obtiene las solicitudes mediante un identificador de usuario
   * @param solicitudId
   * @returns
   */
  public obtenerSolicitudById(solicitudId: number): Observable<ApiResponse<SolicitudApoyoFinanciero>> {
    return this.httpClient.get<ApiResponse<SolicitudApoyoFinanciero>>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/GetSolicitudById?idSolicitud=${solicitudId}`);
  }

  /**
   * Servicio que asigna si el tipo de accion es crear o eliminar si el tipo de accion es delete y agregar su motivo
   * @param tipoAccion
   * @param solicitudId
   * @param motivo
   * @returns
   */
  public actualizarEstatusSolicitud(tipoAccion: string, solicitudId: string, motivo: string): Observable<SimpleResponse> {
    return this.httpClient.get<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/UpdateEstatusSoliucitud?solicitudId=${solicitudId}&tipoAccion=${tipoAccion}&motivo=${motivo}`);
  }

  /**
   * Elimina un documento segun el identificador del mismo
   * @param usuarioId
   * @param documentId
   * @returns
   */
  public deleteDocumentoById(usuarioId: number, documentId: number): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/DeleteDocumentoById/${usuarioId}/${documentId}`);
  }

  /**
   * Servicio que obtiene los datos de solicitud y los convierte a un archivo Excel
   * @returns
   */
  /*  public descargarExcel(): Observable<SolicitudApoyoFinanciero[]> {
      return this.httpClient.get<SolicitudApoyoFinanciero[]>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/DownloadExcel`);
    }
  */
  public descargarExcel(filtro: FiltroAdminAcademicoAlumnoDto) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/DownloadExcel`, filtro,
      {
        responseType: "blob"
      });
  }

  /**
   * Servicio para consultar los tipos de documentos que se necesitan para apoyo financiero
   * @param datosUsuario
   * SolicitudApoyoFinanciero[]
   */
  public obtenerProcesoDocumento(): Observable<ApiResponse<Documento[]>> {
    return this.httpClient.get<ApiResponse<Documento[]>>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/TipoDocumentoProcesoApoyo`);
  }

  /**
   * Servicio para obtener los documentos que tiene un usuario en apoyo financiero
   * @param datosUsuario
   */
  public obtenerDocumentos(usuarioId: number, solicitudId: number): Observable<ApiResponse<UsuarioDocumento[]>> {
    return this.httpClient.get<ApiResponse<UsuarioDocumento[]>>(`${environment.enves[baseUrl].apiUrl}/SolicitudApoyoFinanciero/DocumentosUsuarioApoyo/${usuarioId}/${solicitudId}`);
  }

  /**
   * MEtodo que regresa el id de la solicitud del usuario
   * @param usuarioId
   * @returns
   */
  public obtenerIdSolicitudApoyo(usuarioId: number): Observable<ApiResponse<number>> {
    return this.httpClient.get<ApiResponse<number>>(`${environment.enves[baseUrl].apiUrl}/Admisiones/ObtenerIdSolicitudApoyo/${usuarioId}`);
  }

  // Set Datos Usuario Buqueda Observable
  public definirDatosUsuarioBusqueda(datosUsuario: any) {
    this.datosUsuarioBusqueda$.next(datosUsuario);
  }

  //Get Datos Usuario Busqqueda Observable
  public obtenerDatosUsuarioBusqueda$(): Observable<any> {
    return this.datosUsuarioBusqueda$.asObservable();
  }

  // Set Boolean Formulario Valido Observable
  public definirFormularioValidoNuevaSolicitud(boolForm: boolean) {
    this.formValidoNuevaSolicitud$.next(boolForm);
  }

  //Get Boolean Formulario Valido Observable
  public obtenerFormularioValidoNuevaSolicitud$(): Observable<boolean> {
    return this.formValidoNuevaSolicitud$.asObservable();
  }

  /**
   * Llama al metodo de obtener solicitudes para recargar la tabla principal cuando se actualizan o eliminan los datos
   */
  metodoObtenerSolicitudes() {
    this.invocarObtenerSolicitudes.emit();
  }

  // Set Changes in ApoyoFinanciero
  public setChangesApoyoFinanciero(change: boolean) {
    this.changesApoyoFinanciero$.next(change);
  }

  /**
   * Obtiene los cambios del formulario apoyo financiero
   * @returns
   */
  public getChangesApoyoFinanciero$(): Observable<boolean> {
    return this.changesApoyoFinanciero$.asObservable();
  }

  // Set Changes in DatosUsuario
  public setChangesDatosUsuario(change: boolean) {
    this.changesDatosUsuario$.next(change);
  }

  /**
   * Obtiene los cambios del formulariode datos usuario
   * @returns
   */
  public getChangesDatosUsuario$(): Observable<boolean> {
    return this.changesDatosUsuario$.asObservable();
  }

  // Set Changes in SituacionEconomica
  public setChangesSituacionEconomica(change: boolean) {
    this.changesSituacionEconomica$.next(change);
  }

  /**
   * Obtiene los cambios del formulario situacion economica
   * @returns
   */
  public getChangesSituacionEconomica$(): Observable<boolean> {
    return this.changesSituacionEconomica$.asObservable();
  }

  // Set Changes in Documentos
  public setChangesDocumentos(change: boolean) {
    this.changesDocumentos$.next(change);
  }

  /**
   * Obtiene los cambios del formulario carga de documentos
   * @returns
   */
  public getChangesDocumentos$(): Observable<boolean> {
    return this.changesDocumentos$.asObservable();
  }

  // Set Cambios en nombre Formulario
  public definirCambioNombreForms(nombre: string) {
    this.formCambioNombre$.next(nombre);
  }

}
