import { CalendarInfoDTO } from './../models/Inscripcion/CalendarInfoDTO.model';
/**
 * Modelo que junta laa informacion de todas las tarjetas del dashboard de alumno
 */
export class DashboardAlumnoDto {
    perfilDashboardAlumno: PerfilDashboardAlumno
    avanceProgramaDashboardAlumno: AvanceProgramaDashboardAlumno
    estadoCuentaDashboardAlumno: EstadoCuentaDashboardAlumno
    porHacerDashboardAlumno: PorHacerDashboardAlumno[]
    misCursosDashboardAlumno: MisCursosDashboardAlumno
}

/**
 * Modelo que trae la informacion de la tarjeta Mi Perfil
 */
export class PerfilDashboardAlumno {
    usuarioId: number
    identificador: string
    alumnoId: number
    nombreCompleto: string
    correo: string
    telefono: string
}

/**
 * Modelo que trae la informacion de la tarjeta Avance del programa
 */
export class AvanceProgramaDashboardAlumno {
    avance: number
    materiasAprobadas: number
    materiasRestantes: number
    promedioGeneral: number
    PromedioPeriodo: number
    nombrePrograma: string
}

/**
 * Modelo que trae la informacion de la tarjeta Estado de Cuenta
 */
export class EstadoCuentaDashboardAlumno {
    fechaCorte: Date
    saldoPagar: number
    apoyoActivo: boolean
}

/**
 * Modelo que trae la informacion de laa tarjeta Por Hacer
 */
export class PorHacerDashboardAlumno {
    nombre: string
    materia: string
    fechaEntrega: Date
    grupoId: number
    recursoId: number
}

/**
 * Modelo que trae la informacion de la tarjeta Mis Cursos
 */
export class MisCursosDashboardAlumno {
    nombrePeriodo: string
    fechaIncioPeriodo: Date
    fechaFinPeriodo: Date
    blockName: string
    cursosAlumnoDashboard: CursosAlumnoDashboard[]
}

/**
* Modelo que trae la informacion de los cursos del alumnoos
*/
export class CursosAlumnoDashboard {
    grupoId: number
    nombreGrupo: string
    claveGrupo: string
    horarioGrupo: CalendarInfoDTO[]
    promedio: number
    faltas: number
    accumulatedQualification: boolean
    existeCalFinal: boolean
    alumnoGrupoParcial: any[]
    alumnoId: number
    parcial_Final: number
    calificacionNoNumericaNombre: string
    faltasFinal: number
    calificacionFinal: number
}