import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MiGrupoComponent} from '../mi-grupo.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {EnsenanzaAprendizajeAlumnoService} from '../../../../_services/ensenanzaAprendizajeAlumno.service'
import { Correo } from 'src/app/_models/correo';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-modal-correo',
  templateUrl: './modal-correo.component.html',
  styleUrls: ['./modal-correo.component.scss']
})
export class ModalCorreoComponent implements OnInit {
  public boolCambio: boolean = false;
  public correoForm: FormGroup;
  public boolIsArray: boolean = false;
  public numSeleccionados: number = 0;
  public correos: string = '';

  constructor(public dialogRef: MatDialogRef<MiGrupoComponent>,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.validarDataRecibida();
    this.InicializarForm();
  }
  public cerrarModal() {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }
  public InicializarForm() {
    this.correoForm = new FormGroup({
      Asunto: new FormControl('', Validators.required),
      CuerpoDelMensaje: new FormControl('', Validators.required),
    });
  }
  arrayCorreo:string;
  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numSeleccionados = this.data.length;
       this.arrayCorreo = this.data.map(p => (p.correoAl.toString()));

    }
  }
  public enviarCorreo() {
    if (this.boolIsArray) {
      const arrayCorreos = this.data.map(p => (p.correoAl.toString()));
      this.correos = arrayCorreos.toString();
    } else {
      this.correos = this.data.correoAl.toString();
    }
    const estructuraCorreo = new Correo();
    estructuraCorreo.CorreosDestinatario = this.correos;
    estructuraCorreo.Asunto = this.correoForm.get('Asunto').value;
    estructuraCorreo.CuerpoDelMensaje = this.correoForm.get('CuerpoDelMensaje').value;

    this.EnsenanzaAprendizajeAlumnoService.enviarCorreos(estructuraCorreo).subscribe(correoEnviado => {
      if (correoEnviado) {
        this.snackBar.open('Se ha enviado el correo con éxito.', 'OK', { duration: 5000 });
      } else {
        this.snackBar.open('Ha ocurrido un error al enviar el correo. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    });
  }
}
