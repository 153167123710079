import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, baseUrl } from '../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { BaseResponse } from '../models/api/BaseResponse.mode';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { DashboardAlumnoDto } from '../_models/dashboardAlumnoDto';

@Injectable({
    providedIn: 'root'
})
export class DashboardAlumnoService {

    constructor(private httpClient: HttpClient) { }

    //obterner los nombres de los servicios
    public infoDashboardAlumno(identificador: string, idioma: string): Observable<ApiResponse<DashboardAlumnoDto>> {
        return this.httpClient.get<ApiResponse<DashboardAlumnoDto>>(`${environment.enves[baseUrl].apiUrl}/DashboardAlumno/InfoDashboardAlumno/${identificador}/${idioma}`);
    }
}
