import { AsignarBloqueModalComponent } from './asignar-bloque-modal/asignar-bloque-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { I18NextPipe } from 'angular-i18next';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BlockService } from './../../_services/block.service';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { GrupoService } from '../../_services/grupo.service';
import { OfertaAcademicaModalComponent } from './oferta-academica-modal/oferta-academica-modal.component';
import { UtileriasService } from '../../_services/utilerias.service';
import { FormEdicionGrupoComponent } from './oferta-academica-modal/form-edicion-grupo/form-edicion-grupo.component';
import { ModalGrupoEliminarComponent } from './oferta-academica-modal/modal-grupo-eliminar/modal-grupo-eliminar.component';
import { CalendarOptions } from '@fullcalendar/angular';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import esLocale from '@fullcalendar/core/locales/es';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CampusService } from '../../_services/campus.service';
import { PeriodoService } from '../../_services/periodo.service';
import { Campus } from '../../_models/campus';
import { Periodo } from '../../_models/periodo';
import { MateriaService } from '../../_services/materia.service';
import { Materia } from '../../_models/materia';
import { Salon } from '../../_models/salon';
import { Profesor } from '../../_models/profesor';
import { Grupo } from '../../_models/grupo';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { Programa } from '../../_models/programa';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { MatOption } from '@angular/material/core';
import { MatTabGroup } from '@angular/material/tabs';
import i18next from 'node_modules/i18next';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { GrupoDTO } from 'src/app/_models/GrupoDTO.model';
import { BlockLabelDto } from '../../_models/BlockLabelDto';
import { Block } from '../../_models/block';

@Component({
  selector: 'app-oferta-academica-grupo',
  templateUrl: './oferta-academica-grupo.component.html',
  styleUrls: ['./oferta-academica-grupo.component.scss']
})
export class OfertaAcademicaGrupoComponent implements OnInit, AfterViewInit, OnDestroy {


  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion


  //#region ViewChield
  @ViewChild('grupoTabs', { static: false }) grupoTabs: MatTabGroup;
  @ViewChild('fullcalendar', { static: true }) fullcalendar: FullCalendarComponent;
  //@ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.listGrupoData.sort = value;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allAula') private allAula: MatOption;
  @ViewChild('allBloque') private allBloque: MatOption;
  //#endregion


  //#region Mat Table Configurations
  listGrupoData: MatTableDataSource<any>;
  displayedColumns: string[] = ['select', 'grupoId', 'clave', 'materiaMostrada', 'periodoClave', 'programaClave', 'campusClave', 'profesorIdentificador', 'salon', 'frecuencia', 'horaInicio', 'capacidad', 'blockName'];
  selection = new SelectionModel<any>(true, []);
  calendarOptions: CalendarOptions;
  localeCalendario = esLocale;
  //#endregion


  //#region Properties
  public datosCalendario: any[] = [];
  public resources: any[] = [];
  public eventos: any[] = [];
  public filtroCalendarioId: number = 0;
  public infoCargado: boolean = false;
  public filtroForm: FormGroup;
  public filtroCalendarioForm: FormGroup;
  public numGrupoSeleccionado: number = 0;
  public calendarioActivado: boolean = false;

  public campusList: Campus[] = [];
  public periodoList: Periodo[] = [];
  public materiaList: Materia[] = [];
  public profesorList: Profesor[] = [];
  public salonList: Salon[] = [];
  public grupoList: Grupo[] = [];
  public arregloFiltroOriginal: Grupo[] = [];
  public infoOriginal: MatTableDataSource<Grupo>;
  public datafilter: any;
  private arrOriginal: any[] = [];
  public Periodos: Periodo[] = [];
  public Programas: Programa[] = [];
  public ArCampus: Campus[] = [];
  public Aula: any;
  public BlockLabel: BlockLabelDto[];
  public Block: Block;
  //#endregion


  //#region FormControl
  public periodoF = new FormControl();
  public programasF = new FormControl();
  public campusF = new FormControl();
  public aulaF = new FormControl();
  public bloqueF = new FormControl();
  public materia = new FormControl();
  public profesor = new FormControl();
  //#endregion


  //#region Life Cycle
  constructor(private grupoService: GrupoService,
    public util: UtileriasService,
    private campusService: CampusService,
    private periodoService: PeriodoService,
    private materiaService: MateriaService,
    private programaService: ProgramaAcademicoService,
    /*private paginador: MatPaginatorIntl,*/
    private location: Location,
    private router: Router,
    //private contador: MatPaginatorIntl,
    private generadorExcel: GeneracionExcelServiceService,
    private _BlockService: BlockService,
    private snackBar: MatSnackBar,
    private i18nextPipe: I18NextPipe,
    private dialog: MatDialog
  ) {
    moment.locale("es");
  }

  ngOnInit(): void {
    if (this.grupoService.invMetodoObtenerGruposSubscription == undefined) {
      this.grupoService.invMetodoObtenerGruposSubscription = this.grupoService.invocarObtenerGrupos.subscribe(() => {
        this.obtenerGrupos();
      });
      this.grupoService.invMetodoObtenerGruposSubscription == undefined;
    }
    if (this.grupoService.invMetodoObtenerRefreshSubscription == undefined) {
      this.grupoService.invMetodoObtenerRefreshSubscription = this.grupoService.invocarObtenerGrupos.subscribe(() => {
        this.obtenerGrupos();
      })
      this.grupoService.invMetodoObtenerRefreshSubscription == undefined
    }

    this.obtenerGrupos();
    this.inicializarForm();
    this.LlenarFiltros();
    this.inicializarCalendarioForm();
  }

  ngAfterViewInit() {
    this.listGrupoData.sort = this.sort;
  }

  ngOnDestroy(): void {

    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#endregion


  //#region Methods
  public calendario() {
    if (this.filtroCalendarioId === 0) {
      this.resources = this.getResources();
      this.eventos = this.getEvents();
    } else if (this.filtroCalendarioId === 1) {
      this.resources = this.getResourcesProfesor();
      this.eventos = this.getEventsProfesor();
    } else if (this.filtroCalendarioId === 2) {
      this.resources = this.getResourcesAula();
      this.eventos = this.getEventsAula();
    }

    this.calendarOptions = {
      nowIndicator: true,
      eventResizableFromStart: true,
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      resourceAreaWidth: '200px',
      height: 650,
      locale: esLocale,
      plugins: [resourceTimelinePlugin],
      initialView: 'resourceTimelineDay',
      selectable: true,
      //filterResourcesWithEvents: true,
      editable: false,
      resources: this.resources,
      events: this.eventos,
      eventColor: '#43b9fe',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
      }
    };
  }


  public getResourcesProfesor() {
    let datosProcesados: any[] = [];
    datosProcesados = this.datosCalendario.map(grupo => ({
      id: grupo.grupoId,
      title: `Grupo número ${grupo.grupoId}`,
      /* children: grupo.horarioArreglo.map(horario => ({
        id: grupo.profesor.usuario.nombre + grupo.profesor.usuarioId + grupo.grupoId,
        title: grupo.profesor.usuario.nombre + grupo.profesor.usuario.apellidoPaterno + grupo.profesor.usuario.apellidoMaterno
      })) */
    }));
    return datosProcesados;
  }

  public getEventsProfesor() {
    let eventosProcesados: any[] = [];
    this.datosCalendario.forEach(grupo => {
      grupo.horarioArreglo.forEach(horario => {
        let dias: number[] = [];
        if (horario.esLunes) {
          dias.push(1);
        };
        if (horario.esMartes) {
          dias.push(2);
        };
        if (horario.esMiercoles) {
          dias.push(3);
        };
        if (horario.esJueves) {
          dias.push(4);
        };
        if (horario.esViernes) {
          dias.push(5);
        };
        if (horario.esSabado) {
          dias.push(6);
        };
        if (horario.esDomingo) {
          dias.push(7);
        };
        eventosProcesados.push({
          id: horario.horarioId,
          title: horario.nombre,
          start: horario.fechaInicio,
          end: horario.fechaFin,
          daysOfWeek: dias,
          startRecur: horario.fechaInicio,
          endRecur: horario.fechaFin,
          startTime: horario.horaInicio,
          endTime: horario.horaFin,
          resourceId: grupo.grupoId,
          description: horario.nombre
        })
      });
    });
    return eventosProcesados;
  }

  public getResourcesAula() {
    let datosProcesados: any[] = [];
    datosProcesados = this.datosCalendario.map(grupo => ({
      id: grupo.grupoId,
      title: `Grupo número ${grupo.grupoId}`,
      /* children: grupo.horarioArreglo.map(horario => ({
        id: horario.salon.nombre + horario.salon.salonId + grupo.grupoId,
        title: horario.salon.nombre
      })) */
    }));
    return datosProcesados;
  }

  public getEventsAula() {
    let eventosProcesados: any[] = [];
    this.datosCalendario.forEach(grupo => {
      grupo.horarioArreglo.forEach(horario => {
        let dias: number[] = [];
        if (horario.esLunes) {
          dias.push(1);
        };
        if (horario.esMartes) {
          dias.push(2);
        };
        if (horario.esMiercoles) {
          dias.push(3);
        };
        if (horario.esJueves) {
          dias.push(4);
        };
        if (horario.esViernes) {
          dias.push(5);
        };
        if (horario.esSabado) {
          dias.push(6);
        };
        if (horario.esDomingo) {
          dias.push(7);
        };
        eventosProcesados.push({
          id: horario.horarioId,
          title: horario.nombre,
          start: horario.fechaInicio,
          end: horario.fechaFin,
          daysOfWeek: dias,
          startRecur: horario.fechaInicio,
          endRecur: horario.fechaFin,
          startTime: horario.horaInicio,
          endTime: horario.horaFin,
          resourceId: grupo.grupoId,
          description: horario.nombre
        })
      });
    });
    return eventosProcesados;
  }

  public getResources() {
    let datosProcesados: any[] = [];
    datosProcesados = this.datosCalendario.map(grupo => ({
      id: grupo.grupoId,
      title: `Grupo número ${grupo.grupoId}`,
      /* children: grupo.horarioArreglo.map(horario => ({
        id: horario.nombre + horario.grupoId,
        title: horario.nombre
      })) */
    }));
    return datosProcesados;
  }

  public getEvents() {
    let eventosProcesados: any[] = [];
    this.datosCalendario.forEach(grupo => {
      grupo.horarioArreglo.forEach(horario => {
        let dias: number[] = [];
        if (horario.esLunes) {
          dias.push(1);
        };
        if (horario.esMartes) {
          dias.push(2);
        };
        if (horario.esMiercoles) {
          dias.push(3);
        };
        if (horario.esJueves) {
          dias.push(4);
        };
        if (horario.esViernes) {
          dias.push(5);
        };
        if (horario.esSabado) {
          dias.push(6);
        };
        if (horario.esDomingo) {
          dias.push(7);
        };
        eventosProcesados.push({
          id: horario.horarioId,
          title: horario.nombre,
          start: horario.fechaInicio,
          end: horario.fechaFin,
          daysOfWeek: dias,
          startRecur: horario.fechaInicio,
          endRecur: horario.fechaFin,
          startTime: horario.horaInicio,
          endTime: horario.horaFin,
          resourceId: horario.grupoId,
          description: horario.nombre
        })
      });
    });
    return eventosProcesados;
  }

  public obtenerGrupos() {
    this.Subscription.add(
      this.grupoService.obtenerGrupos(i18next.language).subscribe(
        (grupos: ApiResponse<Array<GrupoDTO>>) => {
          this.arrOriginal = [...grupos.data];
          this.listGrupoData = new MatTableDataSource(grupos.data);
          this.datafilter = this.arrOriginal;
          this.listGrupoData.paginator = this.paginator;
          this.listGrupoData.sort = this.sort;
          this.selection = new SelectionModel<any>(true, []);
          this.datosCalendario = grupos.data;
          this.calendario();
          this.infoCargado = true;
        })
    );
  }

  public refrescarDatos() {
    this.inicializarForm();
    this.grupoService.invocarMetodoObtenerGrupos();
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      campus: new FormControl(0),
      periodo: new FormControl(0),
      materia: new FormControl(0),
      noGrupo: new FormControl(0),
      aula: new FormControl(0),
      profesor: new FormControl(0)
    });
  }

  public inicializarCalendarioForm() {
    this.filtroCalendarioForm = new FormGroup({
      filtroCalendario: new FormControl(0)
    });

    this.filtroCalendarioForm.valueChanges.subscribe(val => {
      this.filtroCalendarioId = val.filtroCalendario;
      this.calendario();
    });
  }

  public activarCalendario(boolActivado: boolean) {
    this.calendarioActivado = boolActivado;
  }
  onChangeTab(event) {
    switch (event.index) {
      case 1:
        this.calendarioActivado = true;
        break;
      default:
        this.calendarioActivado = false;
        break;
    }
  }
  //#endregion


  //#region Crud Grupo

  public crearGrupo() {
    const dialogo = this.util.abrirDialogoLateral(OfertaAcademicaModalComponent);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {

      }
    });
  }

  public edicionGrupo(data: any) {
    this.Subscription.add(
      this.grupoService.obtenerGrupoById(data.grupoId).subscribe(
        (dataGrupo: ApiResponse<any>) => {
          let infoGrupo = dataGrupo.data;
          infoGrupo.blockLabelId = data.blockLabelId;
          this.util.abrirDialogoLateral(FormEdicionGrupoComponent, infoGrupo);
        })
    );
  }

  public borrar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    this.util.abrirDialogoLateral(ModalGrupoEliminarComponent, datos);
  }

  //#endregion


  //#region Select Table
  isAllSelected() {
    this.numGrupoSeleccionado = this.selection.selected.length;
    const numRows = this.listGrupoData.data.length;
    return this.numGrupoSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.numGrupoSeleccionado = this.selection.selected.length;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.listGrupoData.data.forEach(row => this.selection.select(row));
  }
  //#endregion


  //#region Filtros
  applyFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase();
    if (filterValue != "") {
      this.listGrupoData.filter = filterValue;
      this.listGrupoData.filterPredicate = function (data, filter: string): boolean {
        var nombre = data.profesorNombre;
        return nombre.toLowerCase().includes(filter) || data.profesorIdentificador.toString().includes(filter);
      };
      this.listGrupoData.paginator = this.paginator;
    }
  }


  public filtro() {
    let nombre: string = this.materia.value == null ? "" : this.materia.value;
    let profe: string = this.profesor.value == null ? "" : this.profesor.value;
    let periodo: any[] = this.periodoF.value == null ? [] : this.periodoF.value;
    let campus: any[] = this.campusF.value == null ? [] : this.campusF.value;
    let programa: any[] = this.programasF.value == null ? [] : this.programasF.value;
    let aula: any[] = this.aulaF.value == null ? [] : this.aulaF.value;
    let bloque: any[] = this.bloqueF.value == null ? [] : this.bloqueF.value;

    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    if (nombre.length > 0 || nombre !== '') {
      filtroModificado = filtroModificado.filter(el => { return el.materiaMostrada.toLowerCase().search(nombre.toLowerCase()) > -1 || el.grupoId.toString().search(nombre) > -1; });
    }
    if (profe.length > 0 || profe !== '') {
      filtroModificado = filtroModificado.filter(f => { return f.profesorNombre.toLowerCase().search(profe.toLowerCase()) > -1; });
    }
    if (periodo.findIndex(e => e === 0) === -1) {
      filtroModificado = filtroModificado.filter(f => { return periodo.indexOf(f.periodoId) > -1 });
    }
    if (campus.findIndex(e => e === 0) === -1) {
      filtroModificado = filtroModificado.filter(f => { return campus.indexOf(f.campusId) > -1 });
    }
    if (programa.findIndex(e => e === 0) === -1) {
      filtroModificado = filtroModificado.filter(f => { return programa.indexOf(f.programaId) > -1 });
    }
    // if (aula.findIndex(e => e === 0) === -1) {
    //   filtroModificado = filtroModificado.filter(f => { return aula.indexOf(f.horarioArreglo.salonId) > -1 });
    // }
    if (aula.findIndex(e => e === 0) === -1) {
      if (this.aulaF?.value?.length != this.Aula?.length) {
        let nuevoFiltro = this.aulaF.value;
        filtroModificado = filtroModificado.filter(function (item) {
          return nuevoFiltro.indexOf(item.horarioArreglo[0]?.salonId) !== -1;

        })
      }
    }

    if (bloque.findIndex(e => e === 0) === -1) {
      filtroModificado = filtroModificado.filter(f => { return bloque.indexOf(f.blockLabelId) > -1 });
    }

    this.listGrupoData = new MatTableDataSource(filtroModificado);
    this.listGrupoData.paginator = this.paginator;
    this.datafilter = filtroModificado;
  }

  LimpiarFiltros() {
    this.periodoF.setValue([...this.Periodos.map(item => item.periodoId), 0]);
    this.programasF.setValue([...this.Programas.map(item => item.programaId), 0]);
    this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
    this.aulaF.setValue([...this.Aula.map(item => item.salonId), 0]);
    this.bloqueF.setValue([...this.BlockLabel.map(item => item.blockLabelId), 0]);
    this.materia.setValue("");
    this.profesor.setValue("");
    this.obtenerGrupos();
  }

  public LlenarFiltros() {
    this.Subscription.add(this.periodoService.ObtenerPeriodoFechaInicio().subscribe(
      (periodos: Periodo[]) => {
        this.Periodos = periodos;
        this.periodoF.setValue([...this.Periodos.map(item => item.periodoId), 0]);
        this.filtro();
      })
    );

    this.Subscription.add(this.programaService.obtenerProgramas().subscribe(
      (programas: Programa[]) => {
        this.Programas = programas;
        this.programasF.setValue([...this.Programas.map(item => item.programaId), 0]);
        this.filtro();
      })
    );

    this.Subscription.add(this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.ArCampus = campus;
        this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
        this.filtro();
      })
    );

    this.Subscription.add(this.grupoService.obtenerSalon().subscribe(
      (aula: any) => {
        this.Aula = aula;
        this.aulaF.setValue([...this.Aula.map(item => item.salonId), 0]);
        this.filtro();
      })
    );


    this.Subscription.add(
      this._BlockService.GetBlockLabelDto().subscribe((respuesta: ApiResponse<BlockLabelDto[]>) => {
        this.BlockLabel = respuesta.data;
        this.bloqueF.setValue([...this.BlockLabel.map(item => item.blockLabelId), 0]);
        this.filtro();
      })
    );

  }
  //#endregion


  //#region Selection
  tosslePerOnePeriodo(all) {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if (this.periodoF.value.length == this.Periodos.length) { this.allPeriodo.select(); }
    this.filtro();
  }

  tosslePerOnePrograma(all) {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.programasF.value.length == this.Programas.length) { this.allPrograma.select(); }
    this.filtro();
  }

  tosslePerOneCampus(all) {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.campusF.value.length == this.ArCampus.length) { this.allCampus.select(); }
    this.filtro();
  }

  tosslePerOneAula(all) {
    if (this.allAula.selected) {
      this.allAula.deselect();
      this.filtro();
    }
    if (this.aulaF.value.length == this.Aula.length) { this.allAula.select(); } else {
      this.filtro();
    }
  }

  tosslePerOneBloque(all) {
    if (this.allBloque.selected) {
      this.allBloque.deselect();
      this.filtro();
    }
    if (this.bloqueF.value.length === this.BlockLabel.length) { console.log("Cambio"); this.filtro(); } else {
      this.filtro();
    }
  }

  toggleAllSelectionPeriodo() {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionPrograma() {
    if (this.allPrograma.selected) {
      this.programasF.patchValue([...this.Programas.map(item => item.programaId), 0]);
    } else {
      this.programasF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.campusF.patchValue([...this.ArCampus.map(item => item.campusId), 0]);
    } else {
      this.campusF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionAula() {
    if (this.allAula.selected) {
      this.aulaF.patchValue([...this.Aula.map(item => item.salonId), 0]);
    } else {
      this.aulaF.patchValue([]);
    }
    this.filtro();
  }

  toggleAllSelectionBloque() {
    if (this.allBloque.selected) {
      this.bloqueF.patchValue([...this.BlockLabel.map(item => item.blockLabelId), 0]);
    } else {
      this.bloqueF.patchValue([]);
    }
    this.filtro();
  }
  //#endregion

  //#region Translate Method
  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }
  //#endregion

  //#region Excel
  public descargarExcel(elemento?: any) {

    let grupos;
    if (elemento != null && elemento.length > 0) {
      grupos = elemento;
    } else {
      grupos = this.datafilter;
    }

    this.grupoService.descargarExcel(grupos).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "OfertaAcadémicaGrupos");
    })
  }
  //#endregion Excel

  //#region Asignar Bloque Modal

  public asignarBloque(blockLabel: BlockLabelDto, grupos: GrupoDTO[]): void {
    if (this.verificarPeriodoYCampus(grupos)) {


      this.existeBloque(blockLabel, grupos);

    } else {
      this.snackBar.open(this.translate("_grupos.mismo-campus-periodo"), "OK", { duration: 5000 });
    }

  }

  private AbrirModalBloque(blockLabel: BlockLabelDto, grupos: GrupoDTO[], block: Block, existe: boolean) {
    let content = {
      grupos: grupos,
      blockLabel: blockLabel,
      block: block,
      existe: existe
    }

    const dialogRef = this.dialog.open(AsignarBloqueModalComponent, { data: content, disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      //this.obtenerGrupos();
      this.LimpiarFiltros();
      this.selection.clear();
    });
  }


  private verificarPeriodoYCampus(grupos: GrupoDTO[]): boolean {

    const primerGrupo = grupos[0];
    for (let index = 0; index < grupos.length; index++) {
      const grupo = grupos[index];

      if (grupo.campusId != primerGrupo.campusId || grupo.periodoId != primerGrupo.periodoId) {
        return false;
      }

    }

    return true;

  }

  private existeBloque(blockLabel: BlockLabelDto, grupos: GrupoDTO[]): void {
    const primerGrupo = grupos[0];
    this.Subscription.add(
      this._BlockService.GetBlockByPeriodoCampus(blockLabel.blockLabelId, primerGrupo.periodoId, primerGrupo.campusId).subscribe((respuesta: ApiResponse<Block>) => {

        this.Block = respuesta.data;
        this.AbrirModalBloque(blockLabel, grupos, respuesta.data, respuesta.success);

      })
    );

  }
  //#endregion

  //#endregion

}
