import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { environment, baseUrl } from '../../environments/environment';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { ResumenEstadoCuentaDto } from '../_models/resumenEstadoCuentaDto';
import { ValidarInscripcionExistente } from '../_models/Zuma/validarInscripcionExistente';
import { EstadoCuentaDto } from '../_models/estado-cuenta-dto';
import { SeguimientoCartera, ServicioCartera } from '../_models/seguimiento-cartera';
import { InscripcionCosto, ResumenDatos } from '../_models/inscripcion-costo';
import { UsuarioIngreso } from '../_models/usuarionuevoingresodto';
@Injectable({
  providedIn: 'root'
})
export class SeguimientoCarteraService {

  private datosSeguimientoUsuario$ = new Subject<any>();
  private datosEstadoCuentaUsuario$ = new Subject<any>();
  private datosResumenEstadoCuentaUsuario$ = new Subject<any>();
  private datosPeriodoUsuario$ = new Subject<any>();
  private datosAlumno$ = new BehaviorSubject<any>(null);
  private datosMostrar$ = new BehaviorSubject<any>(null);
  private alumnoIdSeleccionado$ = new BehaviorSubject<any>(null);
  private datoEstatus$ = new Subject<any>();

  invocarObtenerSeguimientoCartera = new EventEmitter();
  invMetodoSubscription: Subscription;

  invocarNuevoMovimiento = new EventEmitter();
  invMetodoNuevoMovimientoSubscription: Subscription;

  invocarNuevoMovimientoTipoPago = new EventEmitter();
  invMetodoNuevoMovimientoTipoPagoSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  /**
   * Filtro Inicial con solo los periodos activos
   * @returns
   */
  public getSeguimientoCartera(): Observable<ApiResponse<SeguimientoCartera[]>> {
    return this.httpClient.get<ApiResponse<SeguimientoCartera[]>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera`);
  }

  /**
   * NO SE ESTA USANDO ???
   * Se usa para obtener los filtros de seguiminento
   * @param datosFiltroSeguimiento 
   * @returns 
   */
  public postFiltroSeguimientoCartera(datosFiltroSeguimiento): Observable<any> {
    return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/PostFiltroSeguimientoCartera`, datosFiltroSeguimiento);
  }

  /**
   * Convierte los datos obtenidos de los filtros a un archivo Excel 
   * @param elemento 
   * @returns Archivo en formato excel con los datos filtrados
   */
  public descargarExcel(elemento: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/DownloadExcel`, JSON.stringify(elemento),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  /**
   * Obtiene los datos de seguimiento a cartera a partir de el id de usuario
   * @param usuarioId 
   * @returns Respuesta de modelo seguimiento a cartera
   */
  public obtenerSeguimientoUsuarioById(usuarioId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/GetSeguimientoByUsuarioID?usuarioId=${usuarioId}`);
  }

  /**
   * Obtiene los periodos a los que esta inscrito el grupo
   * @param programaId 
   * @returns Respuesta de modelo seguimiento a cartera
   */
  public obteneProgramasUsuario(usuarioId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/ProgramasUsuario/${usuarioId}`);
  }

  /**
   * Obtiene los periodos a los que esta inscrito el grupo
   * @param programaId 
   * @returns Respuesta de modelo seguimiento a cartera
   */
  public obtenePeriodosAlumno(alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/PeriodosByPrograma/${alumnoId}`);
  }

  /**
   * Obtiene todos los movimientos y pagos que realizo un usuario en un periodo establecido
   * @param usuarioId 
   * @param periodoId 
   * @returns 
   */
  public obtenerEstadoCuentaByUsuarioId(usuarioId: number, periodoId: number, alumnoId: number): Observable<EstadoCuentaDto[]> {
    return this.httpClient.get<EstadoCuentaDto[]>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/GetEstadoCuentaByUsuarioID?usuarioId=${usuarioId}&periodoId=${periodoId}&alumnoId=${alumnoId}`);
  }

  /**
   * Obtiene un resumen de movimientos mas relevantes hechos por el usuario
   * @param usuarioId 
   * @returns 
   */
  public obtenerResumenEstadoCuentaByUsuarioId(usuarioId: number): Observable<ApiResponse<ResumenEstadoCuentaDto>> {
    return this.httpClient.get<ApiResponse<ResumenEstadoCuentaDto>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/GetResumenEstadoCuentaByUsuarioID?usuarioId=${usuarioId}`);
  }

  /**
   * Obtiene los cargos que el usuario tiene pendientes de pagar
   * @param usuarioId 
   * @returns 
   */
  public obtenerCargosPendientesByUsuarioId(usuarioId: number): Observable<ApiResponse<EstadoCuentaDto[]>> {
    return this.httpClient.get<ApiResponse<EstadoCuentaDto[]>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/GetCargosPendientesByUsuario?usuarioId=${usuarioId}`);
  }

  /**
   * Agrega un movimiento nuevo a un usuario 
   * @param movimiento 
   * @returns 
   */
  public enviarMovimientoTipoPago(movimiento: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/PostMovimientoPago`, movimiento);
  }

  /**
   * Agrega un nuevo tipo de descuento
   * @param descuento 
   * @returns 
   */
  public enviarMovimientoTipoDescuento(descuento: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/PostMovimientoDescuento`, descuento);
  }

  /**
   * Busca el monto de inscripcion 
   * @param busqueda 
   * @returns 
   */
  public buscarCostoInscripcion(busqueda: any): Observable<ApiResponse<InscripcionCosto>> {
    return this.httpClient.post<ApiResponse<InscripcionCosto>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/GetCostoInscripcion`, busqueda);
  }

  /**
   * Agrega una nueva inscripcion al alumno
   * @param inscripcion 
   * @returns 
   */
  public enviarInscripcion(inscripcion: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/PostInscripcion`, inscripcion);
  }

  /**
   * Obtiene la informacion si tiene un movimiento de tipo servicio
   * @param servicioId 
   * @returns 
   */
  public obtenerInfoServicio(servicioId: number): Observable<ApiResponse<ServicioCartera>> {
    return this.httpClient.get<ApiResponse<ServicioCartera>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/GetInfoServicio?servicioId=${servicioId}`);
  }

  /**
   * Agrega un movimiento de tipo servicio al alumno
   * @param servicio 
   * @returns 
   */
  public enviarServicio(servicio: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/PostServicio`, servicio);
  }

  /**
   * Obtiene la informacion si tiene un movimiento de tipo cancelacion
   * @param movimientos 
   * @returns 
   */
  public enviarMovimientosCancelados(movimientos: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/PostMovimientosCancelados`, movimientos);
  }

  /**
   * Agrega un nuevo movimiento de tipo cancelacion al alumno
   * @param usuarioId 
   * @returns 
   */
  public obtenerMovimientosCancelacionByIdUsuario(usuarioId: number): Observable<ApiResponse<EstadoCuentaDto[]>> {
    return this.httpClient.get<ApiResponse<EstadoCuentaDto[]>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/GetMovimientosCancelacion?usuarioId=${usuarioId}`);
  }

  /**
   * convierte la informacion de movimientos a un archivo Excel
   * @param usuarioId 
   * @param periodoId 
   * @returns Un archivo excel con los movimientos realizados
   */
  public descargarExcelMovimientos(estados: EstadoCuentaDto[]) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/DownloadExcelMovimientos`, JSON.stringify(estados),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );
  }

  /**
   * Metodo para obtener el resumen de cartera unicamente de los cargos de las materias
   * @param usuarioId 
   * @returns ResumenEstadoCuentaDto
   */
  public obtenerResumenMateriasByUsuarioId(usuarioId: number): Observable<ApiResponse<ResumenEstadoCuentaDto>> {
    return this.httpClient.get<ApiResponse<ResumenEstadoCuentaDto>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/ResumenMateriasByUsuarioID/${usuarioId}`);
  }

  /**
   * Metodo para obtener los cargos por materia 
   * @param usuarioId 
   * @returns 
   */
  public obtenerCargosMateriasByUsuarioId(usuarioId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/CargosMateriasByUsuarioId/${usuarioId}`);
  }

  /**
   * Metodo para obtener un cargo de inscripcion pendiente
   * @param usuarioId 
   * @param periodoId 
   * @param alumnoId 
   * @returns 
   */
  public obtenerCargoInscripcionPendiente(usuarioId: number, periodoId: number, alumnoId: number): Observable<ApiResponse<EstadoCuentaDto>> {
    return this.httpClient.get<ApiResponse<EstadoCuentaDto>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/CargoInscripcionPendiente/${usuarioId}/${periodoId}/${alumnoId}`);
  }

  // Set Datos Seguimiento Usuario OBS
  public setDatosSeguimientoUsuario$(datosSeguimientoUsuario: any) {
    this.datosSeguimientoUsuario$.next(datosSeguimientoUsuario);
  }

  /** 
   * Obtiene los datos de seguimiento Usuario OBS
   */
  public getDatosSeguimientoUsuario$(): Observable<UsuarioIngreso> {
    return this.datosSeguimientoUsuario$.asObservable();
  }

  /**
   * Set Datos Resumen Cuenta OBS
   */
  public setDatosResumenUsuario$(datosResumenUsuario: any) {
    this.datosResumenEstadoCuentaUsuario$.next(datosResumenUsuario);
  }

  /**
   * Get Datos Resumen Cuenta OBS
   */
  public getDatosResumenUsuario$(): Observable<ResumenDatos> {
    return this.datosResumenEstadoCuentaUsuario$.asObservable();
  }

  /**
   * Set Datos Estado Cuenta OBS
   */
  public setDatosEstadoCuentaUsuario$(datosEstadoCuentaUsuario: any) {
    this.datosEstadoCuentaUsuario$.next(datosEstadoCuentaUsuario);
  }

  /**
   * Get Datos Estado Cuenta OBS
   */
  public getDatosEstadoCuentaUsuario$(): Observable<EstadoCuentaDto[]> {
    return this.datosEstadoCuentaUsuario$.asObservable();
  }

  /**
   * Set Datos Periodo OBS
   */
  public setDatosPeriodoUsuario$(datosPeriodoUsuario: any) {
    this.datosPeriodoUsuario$.next(datosPeriodoUsuario);
  }

  /**
   * Obtiene los datos del periodo de usuario
   */
  public getDatosPeriodoUsuario$(): Observable<UsuarioIngreso[]> {
    return this.datosPeriodoUsuario$.asObservable();
  }

  /**
 * Set Datos Periodo OBS
 */
  public setDatosAlumno$(datosAlumno: any) {
    this.datosAlumno$.next(datosAlumno);
  }

  /**
   * Obtiene los datos del periodo de usuario
   */
  public getDatosAlumno$(): Observable<any[]> {
    return this.datosAlumno$.asObservable();
  }

    /**
 * Set Datos alumnoIdSeleccionado OBS
 */
     public setAlumnoIdSeleccionado$(alumnoIdSeleccionado: any) {
      this.alumnoIdSeleccionado$.next(alumnoIdSeleccionado);
    }
  
    /**
     * Obtiene los alumnoIdSeleccionado de usuario
     */
    public getAlumnoIdSeleccionado$(): Observable<any[]> {
      return this.alumnoIdSeleccionado$.asObservable();
    }

  // Set Datos Seguimiento Usuario OBS
  public setDatosMostrar$(datosMostrar: any) {
    this.datosMostrar$.next(datosMostrar);
  }

  /** 
   * Obtiene los datos de seguimiento Usuario OBS
   */
  public getDatosMostrar$(): Observable<any> {
    return this.datosMostrar$.asObservable();
  }

  // Set Dato estatus cuenta OBS
  public setDatoEstatus$(datosPeriodoUsuario: any) {
    this.datoEstatus$.next(datosPeriodoUsuario);
  }

  /**
   * Obtiene los datos de estatus cuenta OBS
   */
  public getDatoEstatus$(): Observable<any> {
    return this.datoEstatus$.asObservable();
  }

  public validarCargoInscripcion(usuarioId: number, alumnoId: number, periodoId: number): Observable<ApiResponse<ValidarInscripcionExistente>> {
    return this.httpClient.get<ApiResponse<ValidarInscripcionExistente>>(`${environment.enves[baseUrl].apiUrl}/SeguimientoCartera/ValidarCargoInscripcion/${usuarioId}/${alumnoId}/${periodoId}`);
  }

  public obtenerFechaVencimiento(programaId: number, campusId: number, periodoId?: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.enves[baseUrl].apiUrl}/ColegiaturaCosto/ColegiaturaPrograma?programaId=${programaId}&campusId=${campusId}&periodoId=${periodoId}`);
  }

  /**
   * Invocar Metodo ObtenerSeguimientoCartera
   */
  invocarMetodoObtenerSeguimientoCartera() {
    this.invocarObtenerSeguimientoCartera.emit();
  }

  /**
   * Invocar Metodo Nuevo Movimiento
   */
  invocarMetodoNuevoMovimiento() {
    this.invocarNuevoMovimiento.emit();
  }

  /**
   * Llama el metodo de nuevo movimiento de tipo de pago
   */
  invocarMetodoNuevoMovimientoTipoPago() {
    this.invocarNuevoMovimientoTipoPago.emit();
  }

}
