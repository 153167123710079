import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import { Ciclo } from '../_models/ciclo';

@Injectable({
  providedIn: 'root'
})
export class CicloService {

  constructor(private httpClient: HttpClient) { }

  public obtenerCiclo(): Observable<Ciclo[]> {
    return this.httpClient.get<Ciclo[]>(`${environment.enves[baseUrl].apiUrl}/Ciclo`);
  }

  public obtenerCiclos(): Observable<ApiResponse<Ciclo[]>> {
    return this.httpClient.get<ApiResponse<Ciclo[]>>(`${environment.enves[baseUrl].apiUrl}/Ciclo/GetCiclos`);
  }

  public agregarCiclo(ciclo: Ciclo): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Ciclo`, ciclo);
  }

  public modificarCiclo(ciclo: Ciclo): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Ciclo`, ciclo);
  }
  // Get Ciclo X Clave
  public obtenerCicloByClave(clave: string, cicloId: number): Observable<Ciclo> {
    return this.httpClient.get<Ciclo>(`${environment.enves[baseUrl].apiUrl}/Ciclo/ObtenerClave?clave=` + clave + `&cicloId=` + cicloId);
  }

  // Agregar método de eliminar
}
