import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { BlockService } from 'src/app/_services/block.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Materia } from '../../../../_models/materia';
import { GrupoService } from '../../../../_services/grupo.service';
import { MateriaService } from '../../../../_services/materia.service';
import { Block } from 'src/app/_models/block';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { BlockLabelDto } from 'src/app/_models/BlockLabelDto';
import { ActivityOrderTypeService } from '../../../../_services/activity-order-type.service';
import { ActivityOrderType } from '@app/_models/activityOrderType';

@Component({
  selector: 'app-form-materia',
  templateUrl: './form-materia.component.html',
  styleUrls: ['./form-materia.component.scss']
})
export class FormMateriaComponent implements OnInit, OnDestroy {

  //#region Propierties
  public formMateria: FormGroup;
  public materiaList: Materia[] = [];
  public datosCampusPeriodo$: Observable<any>;
  public BlockLabel: BlockLabelDto[];
  public Actividades: ActivityOrderType[];
  public periodoId: number;
  public campusId: number;
  public Block: Block;
  //#endregion

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Life Cycle
  constructor(private materiaService: MateriaService,
    private grupoService: GrupoService,
    private _BlockService: BlockService,
    private snackBar: MatSnackBar,
    private activityOrderTypeService: ActivityOrderTypeService) { this.Block = new Block(); }

  ngOnInit(): void {
    this.grupoService.obtenerDatosPrograma$().subscribe(programas => {
      this.obtenerMaterias(programas);

    })
    this.datosCampusPeriodo$ = this.grupoService.obtenerDatosCampusPeriodo$();


    this.grupoService.obtenerDatosCampusPeriodo$().subscribe((respuesta) => {
      this.periodoId = respuesta.periodo.periodoId;
      this.campusId = respuesta.campus.campusId;
    });

    this.GetBlockLabelDto();
    this.GetActivityOrderType()
    this.inicializarForm();
  }

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#endregion

  public inicializarForm() {
    this.formMateria = new FormGroup({
      materia: new FormControl(0, [Validators.required, this.validateId]),
      blockLabel: new FormControl(0),
      capacidad: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]+/)]),
      materiaOfertaId: new FormControl(0),
      actividades: new FormControl(0,  [Validators.required, this.validateId]),
    });
    this.formMateria.valueChanges.subscribe(val => {
      this.grupoService.definirDatosMateria(val);
    });
    this.formMateria.get("materia").valueChanges.subscribe(materia => {
      this.formMateria.patchValue({
        materiaOfertaId: materia.materiaOfertaId
      });
      this.Block.materiaOfertaId = materia.materiaOfertaId;
    });

    this.formMateria.get("blockLabel").valueChanges.subscribe((blockLabel: BlockLabelDto) => {
      this.formMateria.patchValue({
        capacidad: blockLabel.capacity
      });
      this.grupoService.definirDatosBloque(blockLabel);
      this.Block.blockLabelId = blockLabel.blockLabelId;
    });
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.formMateria.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  //let conjunto = new Set(Materia[]);
  //let materiaRepetidas: Materia[] = [];
  // let sinMateriasRepetidas = [new Set(materiaRepetidas)];
  //let materiasList: Materia[] = [];
  public obtenerMaterias(programas) {
    this.Subscription.add(
      this.materiaService.obtenerMaterias(programas).subscribe((materia: Materia[]) => {
        //let uniques = Array.from(new Set(this.materiaList));
        this.materiaList = materia;
        let result = materia.filter((item, index) => {
          return materia.indexOf(item) === index;
        })
        /* materia.forEach((item) => {
           if (!uniqueArr.includes(item)) {
             uniqueArr.push(item);
           }
         })*/
        /*const result = materia.reduce((acc, item) => {
          if (!acc.includes(item)) {
            acc.push(item);
          }
          return acc;
        }, [])
        console.log(result);*/
        /*const dataArr = new Set(materia);
        let result [...dataArr]*/
        //console.log(new Set(materia));
      })
    );
  }

  get materia() { return this.formMateria.get('materia'); }
  get actividades() { return this.formMateria.get('actividades'); }
  get capacidad() { return this.formMateria.get('capacidad'); }

  public GetBlockLabelDto(): void {
    this.Subscription.add(
      this._BlockService.GetBlockLabelDto().subscribe((respuesta: ApiResponse<BlockLabelDto[]>) => {
        this.BlockLabel = respuesta.data;
      })
    );
  }

  public GetActivityOrderType(): void {
    this.Subscription.add(
      this.activityOrderTypeService.getActivityOrderType().subscribe((respuesta: ApiResponse<ActivityOrderType[]>) => {
        if (respuesta.success) {
          this.Actividades = respuesta.data;
        }

      })
    )
  }





  public IsVerificarMateriaEnBloque: boolean = true;
  @Input() data: MatStepper;

  //#region Verificar Materia en Bloque
  public VerificarMateriaEnBloque(): void {
    this.Block.campusId = this.campusId;
    this.Block.periodoId = this.periodoId;

    console.log("this.Block", this.Block);
    this.Subscription.add(
      this._BlockService.VerificarMateriEnBloque(this.Block).subscribe((respuesta: SimpleResponse) => {
        if (respuesta.success) {
          this.IsVerificarMateriaEnBloque = true;
          this.snackBar.open(respuesta.message, "OK", { duration: 5000 });
        } else if (!respuesta.success && respuesta.message != "") {
          this.IsVerificarMateriaEnBloque = true;
          this.snackBar.open(respuesta.message, "OK", { duration: 5000 });
        } else {
          console.log("Avanza");
          this.data.next();
        }
      })
    );
  }
  //#endregion

}
