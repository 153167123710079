import { AbstractControl, FormGroup } from '@angular/forms';

export function HoraValidator(horaInicio: string, horaFin: string) {
    return (formGroup: FormGroup) => {
        let horaInicioField: AbstractControl = formGroup.controls[horaInicio];
        let horaFinField: AbstractControl = formGroup.controls[horaFin];
        if (horaFinField.errors && !horaFinField.errors.horaValidator) {
            return;
        }
        if (horaInicioField.value == null || horaFinField.value == null) {
            return;
        }
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const today = `${month}/${day}/${year}`;

        const horaInicioDate = Date.parse(`${today} ${horaInicioField.value}`);
        const horaFinDate = Date.parse(`${today} ${horaFinField.value}`);

        if (horaInicioDate < horaFinDate) {
            horaFinField.setErrors(null);
        }
        else {
            horaFinField.setErrors({ horaValidator: true });
        }

    }
}