import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { DatoFiscal } from '../_models/dato-fiscal';


@Injectable({
  providedIn: 'root'
})
export class DatoFiscalService {

  constructor(private httpClient: HttpClient) { }

  //Post PersonaRelacionada
  public agregarDatoFiscal(datoFiscal: DatoFiscal): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/DatoFiscal`, datoFiscal);
  }

  //PUT Datos fiscal
  public modificarDatoFiscal(datoFiscal: DatoFiscal): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/DatoFiscal/`, datoFiscal);
  }

  //Obtener Datos fiscal por Usuario ID
  public GetDatoFiscalByUsuarioId(usuarioId: number): Observable<any> {
    return this.httpClient.get<DatoFiscal>(`${environment.enves[baseUrl].apiUrl}/DatoFiscal/GetDatoFiscalByUsuarioId?usuarioId=` + usuarioId);
  }

  //Obtener Datos Fiscal por RFC
  public GetDatoFiscalByRFC(rfc: string,usuarioId: number): Observable<any> {
    return this.httpClient.get<DatoFiscal>(`${environment.enves[baseUrl].apiUrl}/DatoFiscal/GetDatoFiscalByRFC?rfc=` + rfc + `&usuarioId=` + usuarioId);
  }
}
