import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Campus } from 'src/app/_models/campus';
import { EstatusAcademico } from 'src/app/_models/inscripcion';
import { Periodo } from 'src/app/_models/periodo';
import { Programa } from 'src/app/_models/programa';
import { CampusService } from 'src/app/_services/campus.service';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { InscripcionService } from "src/app/_services/inscripcion.service";
import { TipoModalidadTitulacionService } from 'src/app/_services/tipo-modalidad-titulacion.service';
import { TipoModalidadTitulacion } from 'src/app/_models/tipo-modalidad-titulacion';
import { EgresoService } from 'src/app/_services/egreso.service';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { SnackService } from '../../../services/snack-service.service';

@Component({
  selector: 'app-contenido-programa-academico',
  templateUrl: './contenido-programa-academico.component.html',
  styleUrls: ['./contenido-programa-academico.component.scss']
})
export class ContenidoProgramaAcademicoComponent implements OnInit {
  public programaAcademicoForm: FormGroup;
  public datosEgresoForm: FormGroup;
  public periodoList: Periodo[] = [];
  public periodoActivoList: Periodo[] = [];
  public programaList: Programa[] = [];
  public campusList: Campus[] = [];
  public EstatusAcademicoList: EstatusAcademico[] = [];
  public arrayTipoTitulacion: TipoModalidadTitulacion[] = [];
  public usuarioSesion: any;
  public usuario: any;
  public infoCargada: boolean = false;
  public periodosActivosId: string;
  public configuracionAcademica: any;
  public botonGuardarDeshabilitado: boolean = false;
  public fechaInicioSolicitud: Date;
  public fechaFinSolicitud: Date;

  constructor(private periodoService: PeriodoService,
    private campusService: CampusService,
    private InscripcionService: InscripcionService,
    private tipoModalidadTitulacionService: TipoModalidadTitulacionService,
    private egresoService: EgresoService,
    private enseñanazaAprendizajeService: EnsenanzaAprendizajeService,
    private snackServiceService: SnackService
  ) { }

  ngOnInit(): void {
    this.enseñanazaAprendizajeService.obtenerUsuarioConectado().subscribe((usuario) => {
      this.usuarioSesion = usuario;
      this.obtenerDatosSeguimientoAlumno(this.usuarioSesion.usuarioId)
    })
    this.getPeriodos();
    this.getPeriodosActivos();
    this.getCampus();
    this.getEstatusAcademicos();
    this.getTipoTitulacion();



  }

  public inicializarForm() {
    this.programaAcademicoForm = new FormGroup({
      campus: new FormControl({ value: this.usuario.alumno.length === 0 ? 0 : this.usuario.alumno[0].campusId, disabled: true }),
      programaAcademico: new FormControl({ value: this.usuario.alumno.length === 0 ? 0 : this.usuario.alumno[0].programaId, disabled: true }),
      periodo: new FormControl({ value: this.usuario.alumno.length === 0 ? 0 : this.usuario.alumno[0].priodoIngreso, disabled: true }),
      estatusAdmision: new FormControl({ value: this.usuario.alumno.length === 0 ? 0 : this.usuario.alumno[0].estatusAcademico, disabled: true }),
    });
    
    this.datosEgresoForm = new FormGroup({
      modadidadTitulacion: new FormControl(this.usuario.alumno.length === 0 ? 0 : this.usuario.alumno[0].tipoModalidadTitulacionId),
      periodoEgreso: new FormControl(this.usuario.alumno.length === 0 ? 0 : this.usuario.alumno[0].periodoEgresoId)
    });
    this.infoCargada = true;

  }


  public obtenerDatosSeguimientoAlumno(usuarioId: number) {
    this.egresoService.ObtenerAlumnoSeguimientoProgramaAcademico(usuarioId).subscribe(response => {
      this.usuario = response.data;
      this.getProgramas();
      this.obtenerConfiguracionAcademica();
      this.inicializarForm();
    })

  }



  public getPeriodos() {
    this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoList = periodo;
      });
  }


  public getPeriodosActivos() {
    this.periodoService.obtenerPeriodosActivos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoActivoList = periodo;
        this.periodosActivosId = this.periodoActivoList.map(a => a.periodoId.toString()).join(',');
      });
  }

  public getProgramas() {

    this.campusService.obtenerProgramas(this.usuario.alumno[0].campusId).subscribe(
      programas => {
        this.programaList = programas;
      })

  }


  public getCampus() {
    this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;

      })
  }


  public getEstatusAcademicos(): void {
    this.InscripcionService.GetEstatusAcademicos().subscribe(
      (EstatusAcademico: ApiResponse<EstatusAcademico[]>) => {
        this.EstatusAcademicoList = EstatusAcademico.data;
      });
  }

  public getTipoTitulacion() {
    this.tipoModalidadTitulacionService.obtenerTiposTitulacion().subscribe(response => {
      if (response.success) {
        this.arrayTipoTitulacion = response.data;
      }
    })
  }

  public obtenerConfiguracionAcademica() {
    this.egresoService.ObtenerConfiguracionAcademica(this.usuario.alumno[0].campusId, this.usuario.alumno[0].programa.nivelId, this.periodosActivosId).subscribe(response => {
      if (response.success) {
        this.configuracionAcademica = response.data;
        let fechaActual = new Date();
        this.fechaInicioSolicitud = new Date(this.configuracionAcademica.solicitudGraduacionFechaInicio);
        this.fechaFinSolicitud = new Date(this.configuracionAcademica.solicitudGraduacionFechaFin);
        if (fechaActual < this.fechaInicioSolicitud || fechaActual > this.fechaFinSolicitud) {
          this.modadidadTitulacion.disable();
          this.periodoEgreso.disable();
          this.botonGuardarDeshabilitado = true;
        }



      }
    })
  }


  get modadidadTitulacion() { return this.datosEgresoForm.get('modadidadTitulacion'); }
  get periodoEgreso() { return this.datosEgresoForm.get('periodoEgreso'); }

  public guardarDatosEgreso() {

    let alumno: any = {
      SolicitudGraduacionFechaInicio: this.fechaInicioSolicitud,
      SolicitudGraduacionFechaFin: this.fechaFinSolicitud,
      alumnoId: this.usuario.alumno[0].alumnoId,
      periodoEgresoId: this.periodoEgreso.value,
      tipoModalidadTitulacionId : this.modadidadTitulacion.value,
    }

    this.egresoService.AsinarTipoTitulacionPeriodoAlumno(alumno).subscribe(response => {
      if (response.success) {
        this.snackServiceService.mostrarSnackBack(response.message);
      } else {
        this.snackServiceService.mostrarSnackBack(response.message);
      }
    })


  }







}
