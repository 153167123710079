import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccionEnum } from '../../_models/accion.enum';
import { Entry } from 'src/app/_models';
import { SnackService } from 'src/app/services/snack-service.service';
import { LeadEntryService } from 'src/app/_services';

@Component({
  selector: 'app-leads-source-modal',
  templateUrl: './leads-source-modal.component.html',
  styleUrls: ['./leads-source-modal.component.scss']
})
export class LeadsSourceModalComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public sourceId: number = 0;
  public dialogRefAdver: any;
  public boolCambio: boolean = false;
  public edicionForm: FormGroup;
  public entry: Entry;

  constructor(public dialogRef: MatDialogRef<LeadsSourceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Entry,
    private snackService: SnackService,
    private leadService: LeadEntryService,
    private dialog: MatDialog) {
      this.boolCambio = false;
      this.inicializarForm();
     }

  ngOnInit(): void {
  }

  /**
   * Inicia el formulario con los datos y detecta los cambios que realizan a fin de marcar saber si hay cambios
   */
  public inicializarForm(): void {
    this.edicionForm = new FormGroup({
      sourceId: new FormControl(this.data?.sourceId),
      name: new FormControl(this.data?.name, [Validators.required]),
      description: new FormControl(this.data?.description, [Validators.required])
    });
    this.edicionForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
    });
  }

  get name() { return this.edicionForm.get('name'); }
  get description() { return this.edicionForm.get('description'); }

  /**
   * Funcion que cambia la vista y el mensaje de creacion a edicion
   * @param sourceId 
   */
  public cambiarVistaEditar(sourceId: number): void {
    this.boolCambio = false;
    this.data = Object.assign(this.edicionForm.value);
    this.data.tipoAccion = 2;
    this.sourceId = sourceId;
  }

  /**
   * Funcion que manda los datos del formulario al servicio, detecta si la accion es CREAR o EDITAR
   */
  public enviar(): void {
    switch(this.data.tipoAccion) {
      case AccionEnum.CREAR:
        this.data = Object.assign(this.edicionForm.value);
        this.leadService.addEntrys(this.data).subscribe(
          (sourceId: number) => {
            if (sourceId > 0) {
              this.leadService.invocarMetodoObtenerEntry();
              this.snackService.mostrarSnackBack('Se ha guardado la información.');
              this.boolCambio = false;
              this.cambiarVistaEditar(sourceId);
              this.inicializarForm();
            } else {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la información. Por favor, intente de nuevo más tarde.');
              this.data.tipoAccion = 3;
            }
          }
        );
        break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionForm.value);
        this.data.tipoAccion = 2;
        this.data.sourceId = this.sourceId;
        this.leadService.editEntry(this.data).subscribe(
          (editado: boolean) => {
            if (editado) {
              this.leadService.invocarMetodoObtenerEntry();
              this.snackService.mostrarSnackBack('Se ha guardado la información.');
              this.boolCambio = false;
            } else {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al modificar la información. Por favor, intente de nuevo más tarde.');
            }
          }
        );
        break;
    }
  }

  /**
   * Cierra el modal solo si no hay cambios pendientes
   */
  public cerrarModalValidacion(): void {
    if(this.boolCambio) {
      this.dialogRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  /**
   * Cierra el modal de cambios pendientes solo si el usuario le da en "Descartar"
   * @param descartar
   */
  public cerrarModalAdvertencia(descartar: boolean): void {
    if (descartar) {
      this.dialogRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogRefAdver.close();
    }
  }
}
