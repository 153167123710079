import { NullHelper } from './../../../../helper/Null.Helper';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { GrupoService } from '../../../../_services/grupo.service';
import { Profesor } from '../../../../_models/profesor';
import { Salon } from '../../../../_models/salon';
import { UtileriasService } from '../../../../_services/utilerias.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Grupo } from '../../../../_models/grupo';
import { HoraValidator } from '@app/validator/HorasValidator';
import { Horario } from '@app/_models/horario';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { ActivityOrderTypeService } from '@app/_services/activity-order-type.service';
import { ActivityOrderType } from '@app/_models/activityOrderType';

@Component({
  selector: 'app-form-edicion-grupo',
  templateUrl: './form-edicion-grupo.component.html',
  styleUrls: ['./form-edicion-grupo.component.scss']
})
export class FormEdicionGrupoComponent implements OnInit, OnDestroy {

  @ViewChild('dialogAdverteniaHorario') dialogAdverteniaHorario: TemplateRef<any>;
  public dialogoRefAdverHorario: any;
  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  public dialogoRefAdver: any;

  public grupoEdicionForm: FormGroup;
  public subscriptionProfesor: Subscription;
  public subscriptionSalon: Subscription;
  public profesorList: Profesor[] = [];
  public salonList: Salon[] = [];
  public edicionNombre: boolean = true;
  public secccionHorarioID: number;
  public indiceSeccionHorario: number;
  public boolCambio: boolean = false;
  public IshorarioRequerido: boolean;
  public Actividades: ActivityOrderType[];


  constructor(public dialogRef: MatDialogRef<FormEdicionGrupoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private grupoService: GrupoService,
    private utileriasService: UtileriasService,
    private snackBar: MatSnackBar,
    private activityOrderTypeService: ActivityOrderTypeService) { }

  ngOnInit(): void {
    console.log("DATA", this.data);
    this.inicializarForm();
    /* if (this.data.horario[0].horarioRequerido) {
    } else {
      this.inicializarFormSinHorario();
    } */
    this.grupoService.invocarMetodoSetFechas();
    this.obtenerProfesores();
    this.obtenerProfesores();
    this.obtenerSalones();
    this.GetActivityOrderType()
  }

  public inicializarForm() {
    this.grupoEdicionForm = this.formBuilder.group({
      fechaInicio: new FormControl(this.data.horario[0].fechaInicio === null ? null : this.utileriasService.formatearFecha(this.data.horario[0].fechaInicio, 'YYYY[-]MM[-]DD'), [Validators.required]),
      fechaFin: new FormControl(this.data.horario[0].fechaFin === null ? null : this.utileriasService.formatearFecha(this.data.horario[0].fechaFin, 'YYYY[-]MM[-]DD'), [Validators.required]),
      profesorId: new FormControl(this.data.profesorId === null ? 0 : this.data.profesorId, [Validators.required]),
      capacidad: new FormControl(this.data.capacidad === null ? null : this.data.capacidad, [Validators.required]),
      horarioRequerido: new FormControl(this.data.horario[0].horarioRequerido),
      salonId: new FormControl(this.data.horario[0].salonId === null ? 0 : this.data.horario[0].salonId, [Validators.required]),
      horarioSecciones: this.formBuilder.array([]),
      actividades: new FormControl(this.data.activityOrderTypeId === null ? 0 : this.data.activityOrderTypeId, [Validators.required, this.validateId]),

    });
    this.generarArrayHorarioSecciones(this.data.horario);
    this.IshorarioRequerido = NullHelper.IsUndefined(this.data?.horario[0]?.horarioRequerido, false);
    this.grupoEdicionForm.valueChanges.subscribe((value) => {
      this.boolCambio = true;
      this.IshorarioRequerido = value.horarioRequerido;
    });
  }

  public inicializarFormSinHorario() {
    this.grupoEdicionForm = this.formBuilder.group({
      fechaInicio: new FormControl(this.data.horario[0].fechaInicio === null ? null : this.utileriasService.formatearFecha(this.data.horario[0].fechaInicio, 'YYYY[-]MM[-]DD'), [Validators.required]),
      fechaFin: new FormControl(this.data.horario[0].fechaFin === null ? null : this.utileriasService.formatearFecha(this.data.horario[0].fechaFin, 'YYYY[-]MM[-]DD'), [Validators.required]),
      profesorId: new FormControl(this.data.profesorId === null ? 0 : this.data.profesorId, [Validators.required]),
      capacidad: new FormControl(this.data.capacidad === null ? null : this.data.capacidad, [Validators.required, Validators.pattern(/^[1-9]+/)]),
      horarioRequerido: new FormControl(false),
      salonId: new FormControl(this.data.horario[0].salonId === null ? 0 : this.data.horario[0].salonId, [Validators.required]),
      horarioId: new FormControl(this.data.horario[0].horarioId),
      actividades: new FormControl(this.data.activityOrderTypeId === null ? 0 : this.data.activityOrderTypeId, [Validators.required, this.validateId]),

    });
    this.IshorarioRequerido = false;
    this.grupoEdicionForm.valueChanges.subscribe((value) => {
      this.boolCambio = true;
      this.IshorarioRequerido = value.horarioRequerido;
    });
  }

  get fechaInicio() { return this.grupoEdicionForm.get('fechaInicio'); }
  get fechaFin() { return this.grupoEdicionForm.get('fechaFin'); }
  get salonId() { return this.grupoEdicionForm.get('salonId'); }
  get grupoEdicion() { return this.grupoEdicionForm ? this.grupoEdicionForm : null; }
  get capacidad() { return this.grupoEdicionForm.get('capacidad'); }
  get horarioRequerido() { return this.grupoEdicionForm.get('horarioRequerido'); }
  get actividades() { return this.grupoEdicionForm.get('actividades'); }


  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public generarArrayHorarioSecciones(horarioSecciones) {
    horarioSecciones.forEach(seccion => {
      (this.grupoEdicionForm.get('horarioSecciones') as FormArray).push(this.formArrayHorarioSeccion(seccion));
    });
  }

  public formArrayHorarioSeccion(seccion): FormGroup {
    return this.formBuilder.group({
      nombre: new FormControl(seccion.nombre ?? 'Sección'),
      esLunes: new FormControl(seccion.esLunes),
      esMartes: new FormControl(seccion.esMartes),
      esMiercoles: new FormControl(seccion.esMiercoles),
      esJueves: new FormControl(seccion.esJueves),
      esViernes: new FormControl(seccion.esViernes),
      esSabado: new FormControl(seccion.esSabado),
      horaInicio: new FormControl(seccion.horaInicio),
      horaFin: new FormControl(seccion.horaFin),
      salonId: new FormControl(seccion?.salon?.salonId ?? 0),
      horarioId: new FormControl(seccion.horarioId)
    });
  }

  public crearFormSeccion(): FormGroup {
    return this.formBuilder.group({
      nombre: new FormControl('Sección', [Validators.required]),
      esLunes: new FormControl(false),
      esMartes: new FormControl(false),
      esMiercoles: new FormControl(false),
      esJueves: new FormControl(false),
      esViernes: new FormControl(false),
      esSabado: new FormControl(false),
      horaInicio: new FormControl('', [Validators.required]),
      horaFin: new FormControl('', [Validators.required]),
      salonId: new FormControl(0)
    }, {
      validators: [HoraValidator('horaInicio', 'horaFin')]
    });

  }

  public GetActivityOrderType(): void {
    this.activityOrderTypeService.getActivityOrderType().subscribe((respuesta: ApiResponse<ActivityOrderType[]>) => {
      if (respuesta.success) {
        this.Actividades = respuesta.data;
      }
    })
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.grupoEdicionForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  public validarFechas = function (form: FormGroup, nombreControl1: string, nombreControl2: string) {
    if (form.get(nombreControl1).value !== null && form.get(nombreControl2).value !== null) {
      return form.get(nombreControl1).value <= form.get(nombreControl2).value;
    }
    return true;
  }

  //#region Eliminar Sección Fuera de Uso
  public eliminarHorario(indice) {
    this.secccionHorarioID = NullHelper.IsUndefined(this.data?.horario[indice]?.horarioId, 0);
    this.indiceSeccionHorario = indice;
    this.dialogoRefAdverHorario = this.dialog.open(this.dialogAdverteniaHorario, { disableClose: true });
  }

  public eliminarSeccion(eliminar: boolean): void {
    if (eliminar) {
      if (this.secccionHorarioID != 0) {
        this.grupoService.eliminarHorarioSeccion(this.secccionHorarioID).subscribe(
          horarioEliminado => {
            if (horarioEliminado) {
              this.grupoService.invocarMetodoObtenerGrupos();
              (<FormArray>this.grupoEdicionForm.controls.horarioSecciones).removeAt(this.indiceSeccionHorario);
              this.dialogoRefAdverHorario.close();
              this.snackBar.open("Se ha eliminado el horario.", "OK", { duration: 5000 });
            } else {
              this.snackBar.open("Ha ocurrido un error al eliminar el horario. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
            }
          },
          error => {
            console.log("Error", error);
            this.snackBar.open("Ha ocurrido un error al eliminar el horario. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
          }
        );
      } else {
        this.dialogoRefAdverHorario.close();
        (<FormArray>this.grupoEdicionForm.controls.horarioSecciones).removeAt(this.indiceSeccionHorario);

      }
    } else {
      this.dialogoRefAdverHorario.close();
    }
  }

  public EliminarSeccionesRange(): void {
    this.grupoService.EliminarSeccionesRange([]).subscribe(
      horarioEliminado => {
        if (horarioEliminado) {
          this.grupoService.invocarMetodoObtenerGrupos();
          (<FormArray>this.grupoEdicionForm.controls.horarioSecciones).removeAt(this.indiceSeccionHorario);
          this.dialogoRefAdverHorario.close();
          this.snackBar.open("Se ha eliminado el horario.", "OK", { duration: 5000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al eliminar el horario. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("Error", error);
        this.snackBar.open("Ha ocurrido un error al eliminar el horario. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }
  //#endregion

  public obtenerProfesores() {
    this.subscriptionProfesor = this.grupoService.obtenerProfesor().subscribe(
      (profesor: Profesor[]) => {
        this.profesorList = profesor;
      });
  }

  public obtenerSalones() {
    this.subscriptionSalon = this.grupoService.obtenerSalon(this.data.materiaOferta?.materia?.ciclo?.programa?.campus?.campusId).subscribe(
      (salon: Salon[]) => {
        this.salonList = salon;
      });
  }

  public editNombre(valor) {
    this.edicionNombre = valor;
  }

  public cerrarModal() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(null);
    } else {
      this.dialogoRefAdver.close();
    }
    this.grupoService.invocarMetodoSetFechas();
  }

  public editarGrupo() {
    let datosGrupo: Grupo = this.data;
    datosGrupo.profesorId = this.grupoEdicion.value.profesorId;
    datosGrupo.capacidad = this.grupoEdicion.value.capacidad;
    datosGrupo.activityOrderTypeId = this.grupoEdicion.value.actividades;
    datosGrupo.blockLabelId = this.data.blockLabelId;
    if (this.IshorarioRequerido) {
      let datosForEdicion = this.grupoEdicion.value;
      datosForEdicion.horarioSecciones.forEach(
        elemento => {
          elemento.fechaInicio = datosForEdicion.fechaInicio;
          elemento.fechaFin = datosForEdicion.fechaFin;
          elemento.horarioRequerido = datosForEdicion.horarioRequerido;
          elemento.grupoId = datosGrupo.grupoId;
          elemento.horaInicio = elemento.horaInicio;
          elemento.horaFin = elemento.horaFin;
        });
      var datosEdicionEnviar = datosForEdicion.horarioSecciones;
      datosGrupo.horario = datosEdicionEnviar;
    } else {
      let datosForEdicion = this.grupoEdicion.value;
      datosForEdicion.horarioSecciones.forEach(
        elemento => {
          elemento.fechaInicio = datosForEdicion.fechaInicio;
          elemento.fechaFin = datosForEdicion.fechaFin;
          elemento.horarioRequerido = datosForEdicion.horarioRequerido;
          elemento.grupoId = datosGrupo.grupoId;
          elemento.horaInicio = null;
          elemento.horaFin = null;
          elemento.EsLunes = null;
          elemento.EsMartes = null;
          elemento.EsMiercoles = null;
          elemento.EsJueves = null;
          elemento.EsViernes = null;
          elemento.EsSabado = null;
        });
      var datosEdicionEnviar = datosForEdicion.horarioSecciones;
      datosGrupo.horario = datosEdicionEnviar;
    }
    console.log(datosGrupo);
    //#region Guardar Grupo/Horario
    this.grupoService.editarGrupo(datosGrupo).subscribe(
      grupoEditado => {
        if (grupoEditado) {
          this.grupoService.editarHorario(datosEdicionEnviar).subscribe(
            horarioEditado => {
              if (horarioEditado) {
                this.grupoService.invocarMetodoObtenerGrupos();
                this.dialogRef.close();
                this.snackBar.open("Se ha editado la información.", "OK", { duration: 5000 });
              } else {
                this.snackBar.open("Ha ocurrido un error al editar el grupo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
              }
            },
            error => {
              console.log("Error", error);
              this.snackBar.open("Ha ocurrido un error al editar el grupo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
            }
          );
        } else {
          this.snackBar.open("Ha ocurrido un error al editar el grupo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("Error", error);
        this.snackBar.open("Ha ocurrido un error al editar el grupo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
    //#endregion

  }


  //#region Secciones

  public agregarSeccion(): void {
    (this.grupoEdicionForm.get('horarioSecciones') as FormArray).push(this.crearFormSeccion());
  }

  public validarEmpalmes() {
    let datosForEdicion = this.grupoEdicion.value;


    let profesorId = this.grupoEdicion.value.profesorId;

    if (this.IshorarioRequerido) {
      datosForEdicion.horarioSecciones.forEach(
        elemento => {
          elemento.fechaInicio = datosForEdicion.fechaInicio;
          elemento.fechaFin = datosForEdicion.fechaFin;
          elemento.horarioRequerido = datosForEdicion.horarioRequerido;
        });
      var datosHorario = datosForEdicion.horarioSecciones;
    } else {
      let datosHorarioPendiente: Horario = {};
      datosHorarioPendiente.fechaInicio = datosForEdicion.fechaInicio;
      datosHorarioPendiente.fechaFin = datosForEdicion.fechaFin;
      datosHorarioPendiente.horarioRequerido = datosForEdicion.horarioRequerido;
      datosHorarioPendiente.salonId = datosForEdicion.salonId === 0 ? null : datosForEdicion.salonId;
      var datosHorario: any = []
      datosHorario.push(datosHorarioPendiente);
    }

    this.grupoService.validarEmpalmeProfesor(profesorId != null ? profesorId : 0, datosHorario).subscribe((respuesta: ApiResponse<boolean>) => {
      if (respuesta.success) {
        if (respuesta.data) {
          this.snackBar.open(respuesta.message, "OK", { duration: 5000 });
        } else {
          this.grupoService.validarEmpalmeSalon(datosHorario).subscribe((respuestaSalon: ApiResponse<boolean>) => {
            if (respuestaSalon.success) {
              if (respuestaSalon.data) {
                this.snackBar.open(respuestaSalon.message, "OK", { duration: 5000 });
              } else {
                this.editarGrupo();
              }
            }
          })
        }
      }
    })
  }
  //#endregion


  ngOnDestroy(): void {
    if (this.subscriptionProfesor) {
      this.subscriptionProfesor.unsubscribe;
    }
    if (this.subscriptionSalon) {
      this.subscriptionSalon.unsubscribe;
    }
  }

}
