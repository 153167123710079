export class MateriaCursada {
    alumnoId:            number;
    periodoId:           number;
    mOId:       number;
    nombreMateriaOferta: string;
    calificacionFinal:   number;
    claveMOferta: string;
    grupoId: number;
    periodoNombre: string;

    constructor(_MateriaCursada: Object) {
        Object.assign(this, _MateriaCursada);
    }

    public get NombreConClave(): string{
        return `${ this.claveMOferta} - ${ this.nombreMateriaOferta}`
    }
}
