import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalMaterias } from 'src/app/models/customEntities/modalMaterias';

@Component({
  selector: 'app-modal-materias-seleccionadas',
  templateUrl: './modal-materias-seleccionadas.component.html',
  styleUrls: ['./modal-materias-seleccionadas.component.scss']
})
export class ModalMateriasSeleccionadasComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalMateriasSeleccionadasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalMaterias) {
    this.informacionMaterias = data;
    switch (data.tipoDeCobro) {
      case 'M':
        this.total = data.materiasSeleccionadas.length * data.total;
        break;
      case 'P':
        this.total = data.total;
        break;
      case 'C':
        this.total = data.materiasSeleccionadas.reduce((a, b) => a + b.creditos, 0) * data.total;
        break;
    }
  }
  //#regions  Métodos
  public informacionMaterias: ModalMaterias;
  public total: number;
  //#endregion

  //#region  Métodos
  ngOnInit(): void {
  }
  /**
   * Retorna una respuesta positiva al mensaje de confirmación
   */
  public aceptar(): void {
    this.dialogRef.close(true);
  }
  /**
   * Retorna una respuesta negativa al mensaje de confirmación
   */
  public cancelar(): void {
    this.dialogRef.close(false);
  }
  //#endregion

  //#regions Gets
  public get isNotCobroPorPeriodo(): boolean {
    return this.data.tipoDeCobro.toLowerCase() == 'C'.toLowerCase()
      || this.data.tipoDeCobro.toLowerCase() == 'M'.toLowerCase();
  }

  public get isCobroPorPeriodo(): boolean {
    return !this.isNotCobroPorPeriodo;
  }
  //#endregion
}
