import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service';
import { SolicitudService } from 'src/app/_services/solicitud.service';

@Component({
  selector: 'app-solicitud-tipo-modal-eliminar',
  templateUrl: './solicitud-tipo-modal-eliminar.component.html',
  styleUrls: ['./solicitud-tipo-modal-eliminar.component.scss']
})
export class SolicitudTipoModalEliminarComponent implements OnInit {
  public numRequestTypeSeleccionados = 0;
  public boolIsArray = false;
  public codeRequestType = '';
  public eliminadoCorrecto: boolean = false;
  
  constructor(public dialogRef: MatDialogRef<SolicitudTipoModalEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private solicitudService: SolicitudService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if(this.boolIsArray) {
      this.numRequestTypeSeleccionados = this.data.length;
    }
    for(let i = 1; i < this.data.length; i++) {
      if(this.data[i].isLetterCategory) {
        this.snackService.mostrarSnackBack('Algún(as) claves no se pueden borrar desde este módulo.');
        return this.eliminadoCorrecto = true;  
      }
    }
  }

  public eliminar() {
    if(this.boolIsArray) {
      let arrayClaves = this.data.map(d => (d.requestTypeId.toString()));
      this.codeRequestType = arrayClaves.toString();
    } else {
      this.codeRequestType = this.data.requestTypeId.toString();
    }
    this.solicitudService.eliminarSolicitudTipo(this.codeRequestType).subscribe(
      (eliminado: boolean) => {
        if(eliminado) {
          this.solicitudService.invocarMetodoObtenerTipo();
          this.snackService.mostrarSnackBack('Se ha eliminado la información.');
          this.dialogRef.close(this.data);
        } else {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar el tipo de solicitud. Por favor, intente de nuevo más tarde.');
        }
      }, error => {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al eliminar el tipo de solicitud. Por favor, intente de nuevo más tarde.');
      }
    );
  }

}
