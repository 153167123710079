import { SnackService } from './../../services/snack-service.service';
import { TraductorService } from './../../_services/traductor.service';
import { Component, Inject, ChangeDetectorRef, TemplateRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AccionEnum } from '../../_models/accion.enum';

import { DireccionService } from '../../_services/direccion.service';
import { CampusService } from '../../_services/campus.service';
import { LoadingScreenService } from '../../_services/loading-screen.service';
import { Campus } from '../../_models/campus';
import { Pais } from '../../_models/pais';
import { EntidadFederativa } from '../../_models/entidad-federativa';
import { UtileriasService } from '../../_services/utilerias.service';

@Component({
  selector: 'app-campus-edicion',
  templateUrl: './campus-edicion.component.html',
  styleUrls: ['./campus-edicion.component.scss']
})
export class CampusEdicionComponent implements OnInit {

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public errorActualizaCampus: string = "";
  public campusId: number = 0;
  public objValidacionCancelar: Campus = null;
  public infoCargado: boolean = false;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public edicionForm: FormGroup;
  public campus: Campus;
  public campusList: Campus[] = [];
  public claveRegistrada: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CampusEdicionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Campus,
    private direccionService: DireccionService,
    private campusService: CampusService,
    private snackBar: MatSnackBar,
    private util: UtileriasService,
    private dialog: MatDialog,
    private snackService: SnackService,
    private traductorService: TraductorService
  ) {

    this.inicializarForm();
    this.campusId = this.data.campusId;
    this.edicionForm.markAsUntouched();
    this.boolCambio = false;
    this.direccionService.obtenerPaises().subscribe(
      paises => {
        this.paises = paises;

        if (!!this.data.pais) {
          this.onPaisSelected(this.data.pais);
        }
        this.edicionForm.patchValue(this.data);
        this.edicionForm.markAsUntouched();
        this.boolCambio = false;
      }
    );
  }

  ngOnInit() {
    this.boolCambio = false;
  }

  ngAfterViewInit() {
    this.boolCambio = false;
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      //campusId: new FormControl(0),
      clave: new FormControl(this.data?.clave, [Validators.required]),
      nombre: new FormControl(this.data?.nombre, [Validators.required]),
      entidadFederativaId: new FormControl(this.data?.entidadFederativaId, [Validators.required]),
      //      fotografia: new FormControl(this.tipoAccionFotografia === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.fotografia),
      pais: new FormControl(this.data?.tipoAccion === AccionEnum.CREAR ? 0 : 484, [Validators.required]),
      ciudad: new FormControl(this.data?.ciudad, [Validators.required]),
      calle: new FormControl(this.data?.calle, [Validators.required]),
      numeroExterior: new FormControl(this.data?.numeroExterior, [Validators.required]),
      numeroInterior: new FormControl(this.data?.numeroInterior === '' ? '' : this.data.numeroInterior),
      colonia: new FormControl(this.data?.colonia, [Validators.required]),
      codigoPostal: new FormControl(this.data?.codigoPostal, [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
      telefono: new FormControl(this.data?.telefono, [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
      //extension: new FormControl(this.data.extension === '' ? '' : this.data.extension, [Validators.required]),
      directorEscolar: new FormControl(this.data?.directorEscolar, [Validators.required]),
      rectorCampus: new FormControl(this.data?.rectorCampus, [Validators.required]),
    });
    this.onPaisSelected(484);
    this.edicionForm.valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
  }

  //get campusId() { return this.edicionForm.get('campusId'); }
  get clave() { return this.edicionForm.get('clave'); }
  get nombre() { return this.edicionForm.get('nombre'); }
  //get logo() { return this.edicionForm.get('logo'); }
  get pais() { return this.edicionForm.get('pais'); }
  get entidadFederativaId() { return this.edicionForm.get('entidadFederativaId'); }
  get ciudad() { return this.edicionForm.get('ciudad'); }
  get calle() { return this.edicionForm.get('calle'); }
  get numeroExterior() { return this.edicionForm.get('numeroExterior'); }
  //get numeroInterior() { return this.edicionForm.get('numeroInterior'); }
  get colonia() { return this.edicionForm.get('colonia'); }
  get codigoPostal() { return this.edicionForm.get('codigoPostal'); }
  get telefono() { return this.edicionForm.get('telefono'); }
  //get extension() { return this.edicionForm.get('extension'); }
  get directorEscolar() { return this.edicionForm.get('directorEscolar'); }
  get rectorCampus() { return this.edicionForm.get('rectorCampus'); }

  get esNueva() { return !this.clave }

  public paises: Pais[] = [];
  public estados: EntidadFederativa[] = [];

  public onPaisSelected(pais: number) {
    this.estados = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estados = estados;
        }
      );
    };
  }

  public claveInsert(clave: string) {
    if (clave.length >= 2) {
      this.campusService.obtenerCampusClave(clave).subscribe(
        campus => {
          if (campus == null) {
            return this.claveRegistrada = false;
          } else {
            return this.claveRegistrada = true;
          }
        }
      );
    }
  }

  public cambiarVistaEditar(idCampus: number) {
    this.boolCambio = false;
    this.data = Object.assign(this.edicionForm.value);
    this.data.tipoAccion = 2;
    this.campusId = idCampus;
    this.onPaisSelected(484);
    //this.util.abrirDialogoLateral(CampusEdicionComponent, this.data);
  }

  public cambiarVistaEliminar() {
    this.data.tipoAccion = 4;
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public enviar(): void {
    //this.obtenerObjCampusCreacion()
    switch (this.data.tipoAccion) {
      case AccionEnum.CREAR:
        if (this.edicionForm.invalid) {
          this.validateAllFormFields(this.edicionForm);
          this.snackBar.open("Verifique los datos.", "OK", { duration: 5000 });
          return;
        };

        this.data = Object.assign(this.edicionForm.value);
        this.campusService.agregarCampus(this.data).subscribe(
          idCampus => {
            if (idCampus > 0) {
              this.campusService.invocarMetodoObtenerCampus();
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
              this.boolCambio = false;
              this.cambiarVistaEditar(idCampus);
              this.inicializarForm();
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
              this.data.tipoAccion = 3;
            }
          },
          error => {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        );
        break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionForm.value);
        this.data.campusId = this.campusId;
        this.data.tipoAccion = 2;
        this.campusService.modificarCampus(this.data).subscribe(
          editado => {
            if (editado) {
              this.campusService.invocarMetodoObtenerCampus();
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
              this.boolCambio = false;
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
              this.data.tipoAccion = 2;
            }
          },
          error => {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        );
        break;
    }
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }
}
