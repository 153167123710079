import { Validators } from "@angular/forms";

export const PERSONAL_INFORAMTION = {

  nombre            : [, { validators: [Validators.required], updateOn: 'change' }],
  segundoNombre     : [, { updateOn: 'change' }],
  apellidoPaterno   : [, { validators: [Validators.required], updateOn: 'change' }],
  apellidoMaterno   : [, { updateOn: 'change' }],
  fechaNacimiento   : [, { validators: [Validators.required], updateOn: 'change' }],

};