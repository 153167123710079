export class BILLING_INFORMATION_MODEL {

    razonSocial           : string;
    rfc                   : string;
    paisId                : number;
    entidadFederativaId   : number;
    ciudad                : string;
    calle                 : string;
    numeroExterior        : string;
    numeroInterior        : string;
    colonia               : string;
    codigoPostal          : string;   

    constructor(
        razonSocial           : string,
        rfc                   : string,
        paisId                : number,
        entidadFederativaId   : number,
        ciudad                : string,
        calle                 : string,
        numeroExterior        : string,
        numeroInterior        : string,
        colonia               : string,
        codigoPostal          : string,
    ){
        this.razonSocial           = razonSocial        ;
        this.rfc                   = rfc                ;
        this.paisId                = paisId             ;
        this.entidadFederativaId   = entidadFederativaId;
        this.ciudad                = ciudad             ;
        this.calle                 = calle              ;
        this.numeroExterior        = numeroExterior     ;
        this.numeroInterior        = numeroInterior     ;
        this.colonia               = colonia            ;
        this.codigoPostal          = codigoPostal       ;
    }
  
  };