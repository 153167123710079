import { AreaMateriaService } from '../../_services/area-materia-service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { SnackService } from '../../services/snack-service.service';
import { TraductorService } from '../../_services/traductor.service';
@Component({
  selector: 'app-modal-eliminar-area-materia',
  templateUrl: './modal-eliminar-area-materia.component.html',
  styleUrls: ['./modal-eliminar-area-materia.component.scss']
})
export class ModalEliminarAreaMateriaComponent implements OnInit {

  public numAreaMateriaSeleccionados = 0;
  public boolIsArray = false;
  public claveAreaMateria = '';

  constructor(public dialogRef: MatDialogRef<ModalEliminarAreaMateriaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private areaMateriaService: AreaMateriaService,
    private snackService: SnackService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numAreaMateriaSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(d => (d.areaMateriaId.toString()));
      this.claveAreaMateria = arrayClaves.toString();
    } else {
      this.claveAreaMateria = this.data.areaMateriaId.toString();
    }
    this.areaMateriaService.eliminarAreaMateriaId(this.claveAreaMateria).subscribe(
      eliminado => {
        if (eliminado) {
          this.areaMateriaService.invocarMetodoObtenerAreaMateria();
          let mensaje: string = this.traductorService.translate("_area-materia.eliminar-correcto");
          this.snackService.mostrarSnackBack(mensaje);
          this.dialogRef.close(this.data);
        } else {
          let mensaje: string = this.traductorService.translate("_area-materia.eliminar-usado");
          this.snackService.mostrarSnackBack(mensaje);
        }
      },
      error => {
        let mensaje: string = this.traductorService.translate("_area-materia.eliminar-incorrecto");
        this.snackService.mostrarSnackBack(mensaje);
      }
    )
  }

}
