import { AuthorizeCredentials } from './../_models/Authorize/AuthorizeCredentials';
import { Charge, TransactionRequest, TransactionResponseCreditCard } from './../_models/Authorize/Charge';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { baseUrl, environment } from './../../environments/environment';
import { SimpleResponse } from './../models/api/SimpleResponse.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PagoLineaAuthorizeService {

  constructor(private httpClient: HttpClient) { }

  //#region  ReferenciaPaypal
   /**
   * Servicio que genera un registro en la tabla ReferenciaPaypal
   * @param ReferenciaPaypal
   * @returns Retorma una simpleresponse
   */
    public EnviarReferenciaPaypal(ReferenciaPaypal: any): Observable<SimpleResponse> {
      return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Paypal/EnviarReferenciaPaypal`, ReferenciaPaypal);
    }
  //#endregion


  //#region  IntentoPago
   /**
   * Servicio que genera un registro en la tabla IntentoPago
   * @param PagoIntento
   * @returns Retorma una simpleresponse
   */
    //  public PostIntentoPago(PagoIntento: any): Observable<SimpleResponse> {
    //   return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Paypal/PostIntentoPago`, PagoIntento);
    // }
  //#endregion


  //#region  CreateOrder
   /**
   * Servicio que genera una orden de compra a paypal
   * @param CreateOrder
   * @returns Retorma una simpleresponse
   */
    public ChargeCard(_PagoAuthorize: TransactionRequest): Observable<ApiResponse<TransactionResponseCreditCard>> {
      return this.httpClient.post<ApiResponse<TransactionResponseCreditCard>>(`${environment.enves[baseUrl].apiUrl}/Authorize/ChargeCard`, _PagoAuthorize);
    }
  //#endregion


  //#region  GetCredendialsAuthorize
   /**
   * Servicio obtiene las credenciales y configuración de authorize
   * @param GetCredendialsAuthorize
   * @returns Retorma una AuthorizeCredentials
   */
    public GetCredentialsAuthorize(): Observable<ApiResponse<AuthorizeCredentials>> {
      return this.httpClient.get<ApiResponse<AuthorizeCredentials>>(`${environment.enves[baseUrl].apiUrl}/Authorize/GetCredentialsAuthorize`);
    }
  //#endregion


  //#region  GetIntegracion
   /**
   * Metodo para verificar si la institución tiene registros en tabla integración
   * @param GetIntegracion
   * @returns Retorma un bool
   */
    public GetIntegracion(): Observable<ApiResponse<boolean>> {
      return this.httpClient.get<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/Authorize/GetIntegracion`);
    }
  //#endregion
}
