import { UtileriasService } from 'src/app/_services/utilerias.service';
import { AvanceCursoInformacionAlumno } from './../../../_models/AvanceCursoInformacionAlumno';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-datos-programa',
  templateUrl: './modal-datos-programa.component.html',
  styleUrls: ['./modal-datos-programa.component.scss']
})
export class ModalDatosProgramaComponent implements OnInit {

  @Input() data: AvanceCursoInformacionAlumno;
  constructor(public util: UtileriasService) { }

  ngOnInit(): void {
  }

}
