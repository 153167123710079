export class PersonaRelacionada {
  personaRelacionaId: number;
  usuarioId: number;
  tipoRelacionId: number;
  nombreCompleto: string;
  telefono: string;
  correoElectronico: string;
  personaUsuarioId?: number;
  tipoAccion?: number;
}
