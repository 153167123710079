import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContenidoConsultarComponent } from '../contenido-consultar.component';
import { Correo } from 'src/app/_models/correo';
import {EnsenanzaAprendizajeAlumnoService} from '../../../../_services/ensenanzaAprendizajeAlumno.service';
import { SnackService } from 'src/app/services/snack-service.service';
@Component({
  selector: 'app-contenido-consultar-correo',
  templateUrl: './contenido-consultar-correo.component.html',
  styleUrls: ['./contenido-consultar-correo.component.scss']
})
export class ContenidoConsultarCorreoComponent implements OnInit {
  public boolIsArray: boolean = false;
  public correos: string = '';
  public correoForm: FormGroup;
  public boolCambio: boolean = false;
  public numSeleccionados: number = 0;
  public formularioValido: boolean = false;
  constructor(public dialogRef: MatDialogRef<ContenidoConsultarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private snackService: SnackService
  ) { }

  ngOnInit(): void {
    this.validarDataRecibida();
    this.InicializarForm();

  }

  get Asunto() { return this.correoForm.get('Asunto'); }
  get CuerpoDelMensaje() { return this.correoForm.get('CuerpoDelMensaje'); }

  public InicializarForm() {
    this.correoForm = new FormGroup({
      Asunto: new FormControl('', Validators.required),
      CuerpoDelMensaje: new FormControl('', Validators.required),
    });
  }


  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numSeleccionados = this.data.length;
    }
  }


  public enviarCorreo() {
    if (this.boolIsArray) {
      const arrayCorreos = this.data.map(p => (p.usuario.correo.toString()));
      this.correos = arrayCorreos.toString();
    } else {
      this.correos = this.data.correoAl.toString();
    }
    const estructuraCorreo = new Correo();
    estructuraCorreo.CorreosDestinatario = this.correos;
    estructuraCorreo.Asunto = this.correoForm.get('Asunto').value;
    estructuraCorreo.CuerpoDelMensaje = this.correoForm.get('CuerpoDelMensaje').value;

    this.EnsenanzaAprendizajeAlumnoService.enviarCorreos(estructuraCorreo).subscribe(correoEnviado => {
      if (correoEnviado) {
        this.snackService.mostrarSnackBack('Se ha enviado el correo con éxito.');
      } else {
        this.snackService.mostrarSnackBack('Ha ocurrido un error al enviar el correo. Por favor, intente de nuevo más tarde.');
      }
    });
  }


  public cerrarModal() {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }


}
