import { TraductorService } from './../../../_services/traductor.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProgramaAcademicoService } from '../../../_services/programa-academico.service';
import { Ciclo } from '../../../_models/ciclo';
import { AdminAcademicaService } from '../../../_services/admin-academica.service';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { HistorialAcademico } from 'src/app/_models/HistorialAcademico.model';
import { Alumno } from 'src/app/_models/inscripcion';
import { ImprimirServiceService } from 'src/app/services/imprimir-service.service';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';

@Component({
  selector: 'app-historial-academico',
  templateUrl: './historial-academico.component.html',
  styleUrls: ['./historial-academico.component.scss']
})
export class HistorialAcademicoComponent implements OnInit{

  //#region Constructor
  constructor(private programaAcademicoService: ProgramaAcademicoService,
    private adminAcademiaService: AdminAcademicaService,
    public adminAcademicaService: AdminAcademicaService,
    public imprimirService: ImprimirServiceService,
    public generadorExcel: GeneracionExcelServiceService,
    private traductorService: TraductorService) {
    }
  //#endregion

  //#region Propiedades
  @Input() data: {alumno: Alumno, indexTap:number};
  cicloList: any = [];
  Historial: Array<HistorialAcademico>;
  cicloListFusion:Array<any>=[];
  materiasHistorial:any;
  displayedColumns: string[] = [
    'Clave',
    'Nombre',
    'Créditos',
    'Calificación',
    'Periodo',
    'Estatus'];
  dataSource: MatTableDataSource<any>;
  ExcelSubscription: Subscription;
  //#endregion

  //#region  Métodos
  ngOnInit(): void {
    if (this.adminAcademicaService.actualizarHistorialAcademicaSubscription == undefined) {
      this.adminAcademicaService.actualizarHistorialAcademicaSubscription = this.adminAcademicaService.invocarObtenerHistorialAcademico.subscribe((programaId:number) => {
        if (programaId != 0) {
          this.data.alumno.programaId = programaId;
        }
        this.obtenerInfoParaCiclos();
      });
      this.adminAcademicaService.actualizarHistorialAcademicaSubscription = null;
    }
    this.obtenerInfoParaCiclos();
  }

  obtenerInfoParaCiclos() {
    this.programaAcademicoService.obtenerCiclosByPrograma(this.data.alumno.programaId).subscribe((ciclo: Ciclo[]) => {
      this.cicloList = ciclo;
      for(let i=0; i<this.cicloList.length;i++){
        this.obtenerMateriasHistorialAcademico(this.cicloList[i]);
      }
    })
  }
  obtenerMateriasHistorialAcademico(cicloList:any) {
    this.adminAcademiaService.getHistorialAcademico(this.data.alumno.alumnoId, this.data.alumno.priodoIngreso, cicloList.cicloId).subscribe(
      (respuestaHistorial: ApiResponse<any>) => {
        this.materiasHistorial = respuestaHistorial.data;
        let prueba = cicloList.materias = respuestaHistorial.data;
        // let promedio = this.materiasHistorial[0].promedio;
        //crear un nuevo arreglo con esos datos
        //hacer un push con esos datos
        //y hacer el dataSource con el nuevo arreglo
        this.cicloListFusion.push(prueba);
        // this.data.alumno.promedioPeriodo = promedio;                
        this.dataSource = new MatTableDataSource(prueba);
    })
    //que retorne algo
  }
  imprimirDocumento(){
    window.print();
  }
    /**
   * Ejecuta el método del api que descarga la información que hay en pantalla
   */
     public descargarExcel() {
      this.ExcelSubscription = this.adminAcademicaService.descargarSheetDocumentHistorial(this.cicloList).subscribe((res: Blob) => {
        let filename: string =`HistorialAcademico${this.data.alumno.usuario.nombre}${this.data.alumno.usuario.apellidoPaterno}${this.data.alumno.usuario.apellidoMaterno}`;
        this.generadorExcel.GenerarExcel(res, filename);
        this.ExcelSubscription.unsubscribe();
      })
    }

  public imprimir(){
    const printContent:HTMLElement = document.getElementById("historial");
    const encabezadoContent = `<div class="encabezado-flex">
                                <p><strong>ID:</strong>${this.data.alumno.usuario.identificador}</p>
                                <p><strong>Campus:</strong>${this.data.alumno.campus.nombre}</p>
                                <p><strong>Alumno:</strong>${this.data.alumno.usuario.nombre + this.data.alumno.usuario.apellidoPaterno + this.data.alumno.usuario.apellidoMaterno}</p>
                                <p><strong>Programa:</strong>${this.data.alumno.programa.nombre}</p>
                              </div>`;
    let nombreAlumno = `${this.data.alumno.usuario.nombre}${this.data.alumno.usuario.apellidoMaterno}${this.data.alumno.usuario.apellidoMaterno}`
    this.imprimirService.Imprimir(printContent, nombreAlumno, encabezadoContent);
    }
  //#endregion

  public findNombreEstatus(estatus?: string) {
    if (estatus !== "") {
      let mensaje: string = this.traductorService.translate(estatus);
      return mensaje;
    } else {
      return "";
    }
  }
}
