import { AbstractControl, FormGroup } from '@angular/forms';

export function SelectValidator(select: string) {
    return (formGroup: FormGroup) => {
        let selectField: AbstractControl = formGroup.controls[select];
        if (selectField.errors && !selectField.errors.unSelectValidator) {
            return;
        }
        if (selectField.value == 0) {
            selectField.setErrors({ unSelectValidator: true });
        } else {
            selectField.setErrors(null);
        }
    }
}