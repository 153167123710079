import { TraductorService } from 'src/app/_services/traductor.service';
import { I18NextPipe } from 'angular-i18next';
import { Component, OnInit, Input, Inject, ViewChild, ElementRef, TemplateRef, AfterViewInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalImportarExamenComponent } from './modal-importar-examen/modal-importar-examen.component';
import { ModalEnlaceAdjuntoComponent } from './modal-enlace-adjunto/modal-enlace-adjunto.component';
import { FormGroup, FormControl } from '@angular/forms';
import { CuestionarioArea, Examen, Pregunta, Respuesta, Seccion, TipoPreguntaCuestionario } from '../../_models/ensenanza-aprendizaje-profesor';
import { AuthenticationService } from '../../_services/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { ContenidoMiCursoComponent } from '../contenido/contenido-mi-curso/contenido-mi-curso.component';
import { DatePipe } from '@angular/common'
import * as moment from 'moment';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { TipoRecurso } from 'src/app/_models/tipo-recurso';
import { Recurso } from 'src/app/_models/recurso';
import * as customBuild from '../../../app/_librarries/ckeditorCustom/build/ckeditor';
import { EnsenanzaAprendizajeService } from '@app/_services/ensenanza-aprendizaje.service';

@Component({
  selector: 'app-modal-recursos',
  templateUrl: './modal-recursos.component.html',
  styleUrls: ['./modal-recursos.component.scss']
})
export class ModalRecursosComponent implements OnInit, AfterViewInit {

  public Editor = customBuild;
  @ViewChild('tituloContenido2') tituloContenido: ElementRef;
  @ViewChild('tituloTarea2') tituloTarea: ElementRef;
  @ViewChild('tituloExamen2') tituloExamen: ElementRef;
  @ViewChild('tituloEncuesta2') tituloEncuesta: ElementRef;
  @Input() grupoID: number;
  contenido: boolean = false;
  ocultarImportar: boolean = false;
  tipoRecurso: Array<TipoRecurso> = [];
  modulos: any[] = [];
  public usuario: any;
  iteradorSeccion: Array<any> = [];
  public tiposPreguntas: TipoPreguntaCuestionario[] = [];
  public importarExamenValido: boolean = true;
  public importarEncuestaValido: boolean = true;
  recursoIdExamen: number = 3;
  recursoIdEncuesta: number = 4;
  public datosGrupo;
  selectedEstatus: any;
  selected: any;
  selectedModulo: any;
  listaNumerosEscala = [1, 2, 3, 4, 5];
  listaArchivosEnlace: Array<any> = [];
  listaArchivosEnlaceInfo: Array<any> = [];
  listadoEnlaces: Array<any> = [];
  listadoArchivos: Array<any> = [];
  public puntosPreguntaBase;
  //variables para el recurso en general
  modulo_id: number;
  tipo_recursoId: number;
  titulo: string = '';
  clave: string;
  nombre: string;
  orden: number;
  descripcion: string = '';
  publicado: boolean;
  ponderacion: number;
  reintentos: number;
  fechaInicio: string = ""; //seria el campo para fecha de liberacion
  horaInicio: string = "";
  fechaEntrega: string = ""; //seria el campo para fecha de cierre
  horaEntrega: string;
  preguntasAleatorias: boolean;
  respuestasAleatorias: boolean;
  respuestaPublica: boolean = true;
  permiteVencida: boolean;
  cronometro: number = 0;
  cronometroSegundos: number;
  cerrado: boolean;
  seleccionPregunta: boolean;
  public tiempoSeleccionado: number = 1;
  puntajeTotalPreguntas: number = 0.0;
  public UsaBancoPreguntas: boolean;
  public PreguntasAdesplegar: number;
  //#region
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public dialogoRefAdver: any;
  public recursoGuardado: boolean = false;
  public encuestaContestadaBandera: boolean = false;
  private readonly TIPO_ENCUESTA = 4;
  //#endregion

  //SelectFile
  selectedFile = null;
  public cargaDocumentoForm: FormGroup;
  //traer datos de la modal de enlace-adjunto
  nombreOriginal: string;
  nombreFisico: string;
  today: string | Date;
  //cuestionarios ( examenes - cuestionarios)
  public cuestionario: Examen;
  public claveMateria;
  public objRecurso;
  public examenCreado = false;
  constructor(
    public dialogRevisionRef: MatDialogRef<ContenidoMiCursoComponent>,
    public dialogRef: MatDialogRef<ModalRecursosComponent>,
    private ensenanzaServ: EnsenanzaAprendizajeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private snackService: SnackService,
    private authenticationService: AuthenticationService,
    private msalService: MsalService,
    private datePipe: DatePipe,
    private i18nextPipe: I18NextPipe,
    private traductorService: TraductorService,
  ) { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    console.log("DATA RECURSO", this.data);
    this.today = new Date();
    this.today = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    let cuenta = this.msalService.instance.getActiveAccount();
    this.claveMateria = this.ensenanzaServ.clave$;
    let identificador = cuenta.username.substring(0, cuenta.username.indexOf('@'));
    this.authenticationService.obtenerDatosUsuario(identificador).subscribe(dataU => {
      this.usuario = dataU;
    })
    if (this.data.recurso?.tipoRecursoId === this.TIPO_ENCUESTA) {
      this.encuestaContestada();
    }
    this.cargarPuntosBase();
    this.validarCambios();
    this.obtenerTipoRecurso();
    this.cargarContenido();
    this.obtenerTipoPreguntas();
    this.inicializarForm();
    this.iniciarEditar();

    if (this.ensenanzaServ.metodoObtenerEstructuraSubscription != null) {
      this.ensenanzaServ.metodoObtenerEstructuraSubscription = this.ensenanzaServ.invocarObtenerEstructuraEmit.subscribe(() => {
        this.obtenerEstructura();
        //console.log("Método Obtener Estrucutra Subscripion");
      });
      this.ensenanzaServ.metodoObtenerEstructuraSubscription = null;
    }

    if (!this.data.esNuevoRegistro) {
      this.cargarInfoEditar();
    }
  }

  public encuestaContestada(): void {
    this.ensenanzaServ.encuestaContestada(this.data.recurso.recursoId, this.data.grupoObj.grupoId).subscribe((response: ApiResponse<boolean>) => {
      if (response.success) {
        this.encuestaContestadaBandera = response.data;
      } else {
        console.log("ERROR AL SABER SI LA ENCUESTA FUE CONTESTADA.")
      }
    });
  }

  public obtenerEstructura() {
    if (this.ensenanzaServ.estructuraExamen$) {
      this.cuestionario.secciones = this.ensenanzaServ.estructuraExamen$;
      this.importarExamenValido = false;
      this.importarEncuestaValido = false;
    }
  }

  public obtenerTipoPreguntas() {
    this.ensenanzaServ.getTipoPreguntaCuestionario().subscribe((tipoPregunta: ApiResponse<TipoPreguntaCuestionario[]>) => {
      this.tiposPreguntas = tipoPregunta.data;
      console.log("TIPOPREGUNTAS", tipoPregunta.data)
    })
  }

  onChangeTiempo(value) {
    if (value == 1) {
      this.cronometroSegundos = this.cronometro * 3600;
    }

    if (value == 2) {
      this.cronometroSegundos = this.cronometro * 60;
    }
  }

  onChangeValorTiempo() {

    if (this.tiempoSeleccionado == 1) {
      this.cronometroSegundos = this.cronometro * 3600;
    }

    if (this.tiempoSeleccionado == 2) {
      this.cronometroSegundos = this.cronometro * 60;
    }
  }


  obtenerTipoRecurso() {
    this.ensenanzaServ.listaRecursos().subscribe((tipoRecurso: ApiResponse<TipoRecurso[]>) => {
      this.tipoRecurso = tipoRecurso.data;
    })
  }
  cargarContenido() {
    let numeroParcial = this.data.parcial;
    this.ensenanzaServ.obtenerContenido(this.data.grupoObj.grupoId).subscribe((res: ApiResponse<any>) => {
      this.modulos = res.data.modulo;
      this.modulos.sort((a, b) => (a.nombre < b.nombre) ? -1 : 1);
      this.modulos = this.modulos.filter(par => par.parcial == numeroParcial);
      console.log("MODULOS", this.modulos)
    });
  }

  inicializarForm() {
    this.cargaDocumentoForm = new FormGroup({
      //datos que necesito de archivos
      nombreOriginal: new FormControl(),
      nombreFisico: new FormControl(),
    })
  }
  GuardarBtnContenido = false;
  btnSeccion = false;
  iniciarEditar() {
    if (!this.data.esNuevoRegistro) {
      if (this.data.recurso.tipoRecursoId === 1) {
        setTimeout(() => {
          this.tituloContenido.nativeElement.focus();
        }, 100);
      }
      if (this.data.recurso.tipoRecursoId === 2) {
        setTimeout(() => {
          this.tituloTarea.nativeElement.focus();
        }, 100);
      }
      if (this.data.recurso.tipoRecursoId === 3) {
        setTimeout(() => {
          this.tituloExamen.nativeElement.focus();
        }, 100);
      }
      if (this.data.recurso.tipoRecursoId === 4) {
        setTimeout(() => {
          this.tituloEncuesta.nativeElement.focus();
        }, 100);
      }
    }

  }
  activarBtnGuardar() {
    //contenido
    if (this.selected === 1) {
      if (this.titulo !== '' && this.descripcion !== '' && this.selectedModulo > 0 && this.selectedEstatus !== undefined) {
        this.GuardarBtnContenido = true;
      } else {
        this.GuardarBtnContenido = false;
      }
    }
    if (this.selected === 2) {
      if (this.titulo !== '' && this.selectedModulo > 0 && this.descripcion != '' && this.fechaEntrega !== "" && this.selectedEstatus !== undefined) {
        this.GuardarBtnContenido = true;
      } else { this.GuardarBtnContenido = false; }
    }
    if (this.selected === 3) {

      if (this.UsaBancoPreguntas) {
        this.CalcularPonderacionPreguntaBanco();
      }

      if (this.titulo !== '' && this.selectedModulo > 0 && this.descripcion != '' && this.fechaEntrega !== "" && this.fechaInicio !== ""
        && this.horaEntrega !== "" && this.horaInicio !== "" && this.ponderacion >= 0 && this.cronometro > 0 && this.reintentos != 0 && this.reintentos <= 999 && this.selectedEstatus !== undefined) {
        this.GuardarBtnContenido = true;
        if (this.btnSeccion) {
          this.GuardarBtnContenido = false;
          this.cuestionario.secciones.forEach(seccion => {
            if (seccion.nombre != "") {
              this.GuardarBtnContenido = true;
              seccion.cuestionarioPregunta.forEach(pregunta => {
                if (pregunta.titulo != "" && pregunta.tipoPreguntaCuestionarioId !== "") {
                  if (pregunta.tipoPreguntaCuestionarioId.toString() === '1') {
                    if (pregunta.ponderacion > 0) {
                      this.GuardarBtnContenido = true;
                    } else {
                      this.GuardarBtnContenido = false;
                    }
                  }
                  if (pregunta.tipoPreguntaCuestionarioId.toString() === '2') {
                    var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                    var detectarPonderacion = this.validarPonderacion(pregunta.cuestionarioRespuesta)
                    if (detectarVacio.length == 0 && detectarPonderacion.length > 0) {
                      this.GuardarBtnContenido = true;
                    } else {
                      this.GuardarBtnContenido = false;
                    }
                  }
                  if (pregunta.tipoPreguntaCuestionarioId.toString() === '3') {
                    var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                    var detectarPonderacion = this.validarPonderacion(pregunta.cuestionarioRespuesta)
                    if (detectarVacio.length == 0 && detectarPonderacion.length > 0) {
                      this.GuardarBtnContenido = true;
                    } else {
                      this.GuardarBtnContenido = false;
                    }
                  }
                  if (pregunta.tipoPreguntaCuestionarioId.toString() === '4') {
                    var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                    var detectarPonderacion = this.validarPonderacion(pregunta.cuestionarioRespuesta)
                    if (detectarVacio.length == 0 && detectarPonderacion.length > 0) {
                      this.GuardarBtnContenido = true;
                    } else {
                      this.GuardarBtnContenido = false;
                    }
                  }
                } else {
                  this.GuardarBtnContenido = false;
                }
              });
            }
            else {
              this.GuardarBtnContenido = false
            }
          });
          this.btnSeccion = false;
        }
      }
      else {
        this.GuardarBtnContenido = false;
      }
    }
    if (this.selected === 4) {
      if (this.titulo !== '' && this.selectedModulo > 0 && this.descripcion != '' && this.fechaEntrega !== "" && this.fechaInicio !== ""
        && this.horaEntrega !== "" && this.horaInicio !== "" && this.selectedEstatus !== undefined) {
        this.GuardarBtnContenido = true;
        if (this.btnSeccion) {
          this.GuardarBtnContenido = false;
          this.cuestionario.secciones.forEach(seccion => {
            if (seccion.nombre != "") {
              this.GuardarBtnContenido = true;
              seccion.cuestionarioPregunta.forEach(pregunta => {
                if (pregunta.titulo != "" && pregunta.tipoPreguntaCuestionarioId !== "") {
                  if (pregunta.tipoPreguntaCuestionarioId.toString() === '1') {
                    this.GuardarBtnContenido = true;
                  }
                  if (pregunta.tipoPreguntaCuestionarioId.toString() === '2') {
                    var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                    if (detectarVacio.length > 0) {
                      this.GuardarBtnContenido = false;
                    } else {
                      this.GuardarBtnContenido = true;
                    }
                  }
                  if (pregunta.tipoPreguntaCuestionarioId.toString() === '3') {
                    var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                    if (detectarVacio.length > 0) {
                      this.GuardarBtnContenido = false;
                    } else {
                      this.GuardarBtnContenido = true;
                    }
                  }
                  if (pregunta.tipoPreguntaCuestionarioId.toString() === '4') {
                    var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                    if (detectarVacio.length > 0) {
                      this.GuardarBtnContenido = false;
                    } else {
                      this.GuardarBtnContenido = true;
                    }
                  }
                }
                else {
                  this.GuardarBtnContenido = false;
                }
              });
            } else {
              this.GuardarBtnContenido = false;
            }
          });
          this.btnSeccion = false;
        }
      }
      else {
        this.GuardarBtnContenido = false;
      }
    }
  }
  validarEspacioVacioRespuesta(array: Respuesta[]) {
    //examen
    var detectarVacio = array.filter(function (event) {
      return event.titulo === "";
    });
    return detectarVacio;

  }

  validarPonderacion(array: Respuesta[]) {
    var detectarMayorcero = array.filter(function (event) {
      return event.ponderacion > 0;
    })
    return detectarMayorcero;
  }

  onChange(event) {
    if (this.selected === 3) {
      this.cuestionario = new Examen();

    }

    if (this.selected === 4) {
      this.cuestionario = new Examen();

    }
  }

  onChangeTema(event) {

  }

  onChangePregunta(event) {

  }

  cargarPuntosBase() {
    this.ensenanzaServ.getPonderacionGrupo(this.data.grupoObj.grupoId).subscribe((grupo: ApiResponse<any>) => {
      this.datosGrupo = grupo.data;
      this.puntosPreguntaBase = this.datosGrupo.materiaOferta.materia.ciclo.programa.escalaCalificacionNavigation.calificacionNoNumerica[0].valorNumerico;
      this.puntajeTotalPreguntas = Math.min(this.puntajeTotalPreguntas, (isNaN(parseInt(this.puntosPreguntaBase)) ? 0 : this.puntosPreguntaBase));
    })
  }

  cargarInfoEditar() {
    //contenido
    if (!this.data.esNuevoRegistro && this.data.recurso.tipoRecursoId === 1) {
      this.titulo = this.data.recurso.nombre;
      this.selectedModulo = this.data.recurso.moduloId;
      this.selected = this.data.recurso.tipoRecursoId;
      this.selectedEstatus = this.data.recurso.publicado == null ? false : this.data.recurso.publicado;
      this.descripcion = this.data.recurso.descripcion;
    }
    //tarea
    if (!this.data.esNuevoRegistro && this.data.recurso.tipoRecursoId === 2) {
      this.titulo = this.data.recurso.nombre;
      this.selectedModulo = this.data.recurso.moduloId;
      this.selected = this.data.recurso.tipoRecursoId;
      this.selectedEstatus = this.data.recurso.publicado == null ? false : this.data.recurso.publicado;
      this.descripcion = this.data.recurso.descripcion;
      this.fechaEntrega = this.datePipe.transform(new Date(this.data.recurso.fechaEntrega), 'yyyy-MM-dd');
      this.horaEntrega = this.data.recurso.horaFin;
      this.permiteVencida = this.data.recurso.permiteVencida;
      this.ponderacion = this.data.recurso.ponderacion;
    }
    //examen
    if (this.data.recurso.tipoRecursoId === 3) {
      this.cuestionario = new Examen();


      this.cargarPuntosBase();

      if (!this.data.esNuevoRegistro) {
        this.titulo = this.data.recurso.nombre;
        this.selectedModulo = this.data.recurso.moduloId;
        this.selected = this.data.recurso.tipoRecursoId;
        this.selectedEstatus = this.data.recurso.publicado == null ? false : this.data.recurso.publicado;
        this.descripcion = this.data.recurso.descripcion;
        //this.data.recurso.fechaEntrega = this.datePipe.transform(new Date(this.data.recurso.fechaEntrega), 'yyyy-MM-dd');
        this.fechaEntrega = this.datePipe.transform(new Date(this.data.recurso.fechaEntrega), 'yyyy-MM-dd');
        this.horaEntrega = this.data.recurso.horaFin;
        //this.data.recurso.fechaInicio = this.datePipe.transform(new Date(this.data.recurso.fechaInicio), 'yyyy-MM-dd');
        this.fechaInicio = this.datePipe.transform(new Date(this.data.recurso.fechaInicio), 'yyyy-MM-dd');
        if (this.data.recurso.cronometro >= 3600) {
          let horas = this.data.recurso.cronometro / 3600;
          this.cronometro = horas;
          this.tiempoSeleccionado = 1;
        } else {
          let horas = this.data.recurso.cronometro / 60;
          this.cronometro = horas;
          this.tiempoSeleccionado = 2;
        }
        this.onChangeValorTiempo();

        this.horaInicio = this.data.recurso.horaInicio;
        this.ponderacion = this.data.recurso.ponderacion;
        this.reintentos = this.data.recurso.reintentos;

        this.UsaBancoPreguntas = this.data.recurso.usaBancoPreguntas;
        this.PreguntasAdesplegar = this.data.recurso.preguntasAdesplegar;

        this.preguntasAleatorias = this.data.recurso.ordenAleatorio;
        this.respuestasAleatorias = this.data.recurso.ordenAleatorioRespuestas;
        this.ensenanzaServ.getEstructuraExamen(this.data.recurso.recursoId).subscribe((estructura: ApiResponse<CuestionarioArea[]>) => {
          if (estructura.data.length > 0) {
            this.cuestionario.secciones = estructura.data;
            this.examenCreado = true;
            if (this.cuestionario.secciones.length > 0) {
              this.importarExamenValido = false;
            }
            this.obtenerPuntosActualCuestionario();
            setTimeout(() => {
              this.obtenerPuntosActualCuestionario();
            }, 2500);
          } else {
            this.snackService.mostrarSnackBack('No se encontro ninguna estructura.');
          }

        })
      }
    }

    //encuesta editar
    if (this.data.recurso.tipoRecursoId === 4) {
      this.cuestionario = new Examen();

      this.cargarPuntosBase();

      if (!this.data.esNuevoRegistro) {
        this.titulo = this.data.recurso.nombre;
        this.selectedModulo = this.data.recurso.moduloId;
        this.selected = this.data.recurso.tipoRecursoId;
        this.selectedEstatus = this.data.recurso.publicado == null ? false : this.data.recurso.publicado;
        this.descripcion = this.data.recurso.descripcion;
        this.data.recurso.fechaEntrega = this.datePipe.transform(new Date(this.data.recurso.fechaEntrega), 'yyyy-MM-dd')
        this.fechaEntrega = this.data.recurso.fechaEntrega;
        this.horaEntrega = this.data.recurso.horaFin;
        this.data.recurso.fechaInicio = this.datePipe.transform(new Date(this.data.recurso.fechaInicio), 'yyyy-MM-dd');
        this.fechaInicio = this.data.recurso.fechaInicio
        this.horaInicio = this.data.recurso.horaInicio
        this.ponderacion = this.data.recurso.ponderacion;
        this.preguntasAleatorias = this.data.recurso.ordenAleatorio;
        this.respuestasAleatorias = this.data.recurso.ordenAleatorioRespuestas;
        this.respuestaPublica = !this.data.recurso.respuestaPublica;
        this.ensenanzaServ.getEstructuraExamen(this.data.recurso.recursoId).subscribe((estructura: ApiResponse<CuestionarioArea[]>) => {
          if (estructura.data.length > 0) {
            this.cuestionario.secciones = estructura.data;
            if (this.cuestionario.secciones.length > 0) {
              this.importarEncuestaValido = false;
            }
          } else {
            this.snackService.mostrarSnackBack('No se encontro ninguna estructura.');
          }
        })
      }
    }
  }

  mostrarTotalPuntajePreguntas(indexSeccion?, indexPregunta?) {
    let ponderacionPregunta = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta;
    let sum: number = 0;
    ponderacionPregunta.forEach(a => sum += a.ponderacion);
    let ponderacionActual = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion;
    this.puntajeTotalPreguntas = parseFloat(sum.toFixed(3));

    if (this.puntajeTotalPreguntas === 0) {
      this.cuestionario.secciones.forEach(seccion => {
        seccion.cuestionarioPregunta.forEach(pregunta => {
          this.puntajeTotalPreguntas += pregunta.ponderacion;
          this.puntajeTotalPreguntas = Math.min(this.puntajeTotalPreguntas, (isNaN(parseInt(this.puntosPreguntaBase)) ? 0 : this.puntosPreguntaBase));
        })
      })
    }
  }

  //#region Calcular puntaje de pregunta por la suma de puntaje de respuestas
  public changesPonderacionRespuesta(indexSeccion, indexPregunta, indexRespuesta, event) {

    if (event.keyCode === 190) {

    } else {
      let respuestasPregunta = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta;
      let ponderacionIngresadaPregunta = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion;
      let ponderacionTotalActual = 0.0;
      let valido = true;
      let ponderacionActual = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta[indexRespuesta].ponderacion;
      let sum: number = 0;
      respuestasPregunta.forEach(a => sum += a.ponderacion);

      var nuevoValor = sum - ponderacionActual;
      /*  if (nuevoValor == 0) {
         this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = 0;
       } else { */

      if (ponderacionActual <= ponderacionIngresadaPregunta) {
        //this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = nuevoValor;

      } else {

        this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta[indexRespuesta].ponderacion = 0;
        let mensaje3: string = this.traductorService.translate("mensajePonderacion3");
        let mensaje4: string = this.traductorService.translate("mensajePonderacion4");
        this.snackService.mostrarSnackBack(`${mensaje3} ${ponderacionIngresadaPregunta} ${mensaje4}`);
      }

      //}
      /* this.cuestionario.secciones.forEach(seccion => {
        seccion.cuestionarioPregunta.forEach((pregunta, index) => {
          ponderacionTotalActual += pregunta.ponderacion;
          if (ponderacionTotalActual > this.puntosPreguntaBase) {
            valido = false;
            this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta[indexRespuesta].ponderacion = 0;
            this.snackService.mostrarSnackBack(`Los puntos excenden la ponderación base ${this.puntosPreguntaBase}`);
          }
        })
      })
      ponderacionTotalActual = ponderacionTotalActual + ponderacionActual;
      if (ponderacionTotalActual > this.puntosPreguntaBase) {
        valido = false;
        this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta[indexRespuesta].ponderacion = 0;
        this.snackService.mostrarSnackBack(`Los puntos excenden la ponderación base ${this.puntosPreguntaBase}`);
      } */

      if (valido) {
        // console.log("valido sum", sum);
        //this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = sum;

      } else {
        // console.log("valido nuevoValor", nuevoValor);
        //this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = nuevoValor;

      }
    }
  }
  //#endregion

  /**
   * Metodo para traducir los textos de los tabs
   * @param text
   * @returns
   */
  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  public numeroPreguntas = 0;
  public CalcularPonderacionPreguntaBanco() {

    this.numeroPreguntas = 0;

    this.cuestionario.secciones.forEach(seccion => {
      seccion.cuestionarioPregunta.forEach(pregunta => {
        this.numeroPreguntas++;
      })
    })

    for (let i = 0; i < this.cuestionario.secciones.length; i++) {
      const seccion = this.cuestionario.secciones[i];

      for (let j = 0; j < this.cuestionario.secciones[i].cuestionarioPregunta.length; j++) {
        const pregunta = this.cuestionario.secciones[i].cuestionarioPregunta[j];

        if (this.PreguntasAdesplegar > 0) {
          this.cuestionario.secciones[i].cuestionarioPregunta[j].ponderacion = (this.puntosPreguntaBase / this.PreguntasAdesplegar);
        }

        //this.puntajeTotalPreguntas = this.puntosPreguntaBase;

      }

    }

    this.puntajeTotalPreguntas = 0;
    this.cuestionario.secciones.forEach(seccion => {
      seccion.cuestionarioPregunta.forEach(pregunta => {
        this.puntajeTotalPreguntas += pregunta.ponderacion;
        this.puntajeTotalPreguntas = Math.min(this.puntajeTotalPreguntas, (isNaN(parseInt(this.puntosPreguntaBase)) ? 0 : this.puntosPreguntaBase));
      })
    });

  }

  public changesPonderacionPregunta(indexSeccion, indexPregunta) {
    let ponderacionTotalActual = 0;

    let ponderacionActual = ponderacionTotalActual + this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion;

    ponderacionTotalActual = ponderacionTotalActual - ponderacionActual;

    this.cuestionario.secciones.forEach(seccion => {
      seccion.cuestionarioPregunta.forEach(pregunta => {
        ponderacionTotalActual = ponderacionTotalActual + pregunta.ponderacion;
        if (ponderacionTotalActual >= this.puntosPreguntaBase) {
          this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = 0;
          let mensaje3: string = this.traductorService.translate("mensajePonderacion3");
          this.snackService.mostrarSnackBack(`${mensaje3} ${this.puntosPreguntaBase}`);
        }
      })
    })

    ponderacionTotalActual = ponderacionTotalActual + ponderacionActual;
    if (ponderacionTotalActual > this.puntosPreguntaBase) {
      this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = 0;
      let mensaje3: string = this.traductorService.translate("mensajePonderacion3");
      this.snackService.mostrarSnackBack(`${mensaje3} ${this.puntosPreguntaBase}`);
    }
  }


  public agregarRespuesta(pregunta: Pregunta) {
    this.GuardarBtnContenido = false;
    pregunta.cuestionarioRespuesta.push(new Respuesta())
  }
  public agregarPregunta(seccion: Seccion) {
    this.GuardarBtnContenido = false;
    seccion.cuestionarioPregunta.push(new Pregunta())
  }
  public agregarSeccion() {
    this.btnSeccion = true;
    let seccion = new Seccion();
    seccion.cuestionarioPregunta.push(new Pregunta());
    this.cuestionario.secciones.push(seccion);
    this.importarExamenValido = false;
    this.importarEncuestaValido = false;
  }
  public borrarRespuesta(pregunta: Pregunta, index: number): void {
    pregunta.cuestionarioRespuesta.splice(index, 1);
  }

  public salir() {
    if (this.selected === undefined) {
      this.dialogRevisionRef.close(null);
    }
    if (!this.GuardarBtnContenido && this.recursoGuardado) {
      this.validarCambios();
    } else {
      this.dialogRevisionRef.close(null);
    }
  }

  public validarCambios() {
    if (this.selected === 1) {
      if (this.titulo !== '' || this.descripcion !== '') {
        this.cerrarModalValidacion(true);
      } else {
        this.cerrarModalValidacion(false);
      }
    }
    if (this.selected === 2) {
      if (this.titulo !== '' || this.descripcion !== '' || this.fechaEntrega !== "") {
        this.cerrarModalValidacion(true);
      } else {
        this.cerrarModalValidacion(false);
      }
    }
    if (this.selected === 3) {
      if (this.titulo !== '' || this.descripcion !== '' || this.fechaEntrega !== "" || this.fechaInicio !== ""
        || this.horaEntrega !== "" || this.horaInicio !== "" || this.ponderacion >= 0 || this.cronometro > 0 || this.reintentos >= 1) {
        this.cerrarModalValidacion(true);
      } else {
        this.cerrarModalValidacion(false);
      }
    }
    if (this.selected === 4) {
      if (this.titulo !== '' || this.descripcion !== '' || this.fechaEntrega !== "" || this.fechaInicio !== ""
        || this.horaEntrega !== "" || this.horaInicio !== "") {
        this.cerrarModalValidacion(true);
      } else {
        this.cerrarModalValidacion(false);
      }
    }
  }

  public cerrarModalValidacion(cambio: boolean) {
    if (cambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRevisionRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRevisionRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  cambiarPregunta(seccionIndex: number, indexPregunta: number, pregunta: any) {

    this.GuardarBtnContenido = false;

    if (this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].tipoPreguntaCuestionarioId == "1") {
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].ponderacion = 0;
    }
    if (this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].tipoPreguntaCuestionarioId == "2" ||
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].tipoPreguntaCuestionarioId == "3") {
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].ponderacion = 0;
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.length = 0;
      this.agregarRespuesta(pregunta);
      this.agregarRespuesta(pregunta);
    }

    if (this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].tipoPreguntaCuestionarioId == "4") {
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].ponderacion = 0;
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.length = 0;
      if (this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.length == 0) {

        for (let i = 1; i <= 5; i++) {
          this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.push(new Respuesta(i))
        }
      }
    }
  }
  //////////////////

  importar(tipoRecursoId) {
    if (tipoRecursoId == 3) {
      this.ensenanzaServ.getExamenesPorImportar(this.usuario.usuarioId, this.claveMateria).subscribe((respuestaExamen: ApiResponse<Recurso[]>) => {
        if (respuestaExamen.data.length > 0) {
          this.dialog.open(ModalImportarExamenComponent, { data: respuestaExamen.data, disableClose: true });
        } else {
          this.snackService.mostrarSnackBack('No se encuentra ningun examen para importar.');
        }
      })
    }
    if (tipoRecursoId == 4) {
      this.ensenanzaServ.getEncuestaPorImportar(this.usuario.usuarioId, this.claveMateria).subscribe((respuestaEncuesta: ApiResponse<Recurso[]>) => {
        if (respuestaEncuesta.data.length > 0) {
          this.dialog.open(ModalImportarExamenComponent, { data: respuestaEncuesta.data, disableClose: true });
        } else {
          this.snackService.mostrarSnackBack('No se encuentra ningun examen para importar.');
        }
      })
    }
  }


  eliminarSeccion(indexSeccion) {
    this.btnSeccion = false;
    if (this.cuestionario.secciones[indexSeccion].cuestionarioPregunta.length > 0) {
      this.snackService.mostrarSnackBack('No se puede eliminar secciones con preguntas asignadas.');

    } else {
      this.cuestionario.secciones.splice(indexSeccion, 1);
    }

    if (this.cuestionario.secciones.length == 0) {
      this.importarExamenValido = true;
      this.importarEncuestaValido = true;
    }

  }

  eliminarPregunta(indexSeccion, indexPregunta) {
    if (this.puntajeTotalPreguntas > 0) {
      this.puntajeTotalPreguntas -= this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion;
      this.puntajeTotalPreguntas = Math.min(this.puntajeTotalPreguntas, (isNaN(parseInt(this.puntosPreguntaBase)) ? 0 : this.puntosPreguntaBase));
    }
    this.GuardarBtnContenido = false;
    this.cuestionario.secciones[indexSeccion].cuestionarioPregunta.splice(indexPregunta, 1);
  }
  eliminarRespuesta(indexSeccion, indexPregunta, indexRespuesta) {
    //this.GuardarBtnContenido = true;
    let ponderacionRespuesta = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta[indexRespuesta].ponderacion;
    this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion -= ponderacionRespuesta;
    this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.splice(indexRespuesta, 1);
  }
  //enlazar button a un inputFile
  showSelectFile() {
    const input = document.getElementById('nombreOriginal');
    input.click();
  }
  //Guardado de archivo...
  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.onload = () => {
        this.cargaDocumentoForm.patchValue({
          //nombre de archivos
          nombreOriginal: file.name,
          nombreFisico: reader.result
        });
        this.listadoArchivos.push(Object.assign(this.cargaDocumentoForm.value));
        this.listadoArchivos.forEach(element => {
          element.esArchivo = true;
        });
        this.listaArchivosEnlace = this.listaArchivosEnlace.concat(this.listadoArchivos);
        for (var i = 0; i < this.listaArchivosEnlace.length; i++) {
          var indexDocument = this.listaArchivosEnlace[i].nombreOriginal.indexOf(".");
          var tipoDocument = this.listaArchivosEnlace[i].nombreOriginal.substr(indexDocument);
          this.listaArchivosEnlace[i].tipoDocumento = tipoDocument;
        }
      };
      //vaciar la lista
      this.listadoArchivos = [];
      reader.readAsDataURL(file); //error
    }
  }
  //me mandar doble objeto al hacer el post :/
  meterLiga() {
    const dialogRef = this.dialog.open(ModalEnlaceAdjuntoComponent, {
      data: {
        nombreOriginal: this.nombreOriginal,
        nombreFisico: this.nombreFisico,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.nombreOriginal = result.nombreOriginal;
      //validar que tenga el http
      let exists = result.nombreFisico.indexOf('http');
      if (exists === -1) {
        this.nombreFisico = 'http://'.concat(result.nombreFisico);
        result.nombreFisico = this.nombreFisico;
      } else {
        this.nombreFisico = result.nombreFisico;
      }
      this.listadoEnlaces.push(result);
      this.listadoEnlaces.forEach(element => {
        element.esArchivo = false;
      });
      this.listaArchivosEnlace = this.listaArchivosEnlace.concat(this.listadoEnlaces);
      //vaciar la lista
    })
    this.listadoEnlaces = [];
  }

  obtenerFechaHora(date, time) {
    let momentObj;
    if (time == undefined) {
      momentObj = moment(date, 'YYYY-MM-DDLT');
    } else {
      momentObj = moment(date + time, 'YYYY-MM-DDLT');
    }
    // conversion
    var dateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
    return dateTime;
  }


  eliminarAdjunto(indice: number) {
    this.listaArchivosEnlace.splice(indice, 1);
  }
  eliminarAdjuntoEditar(recursoAdjunto: any, index: number) {
    this.ensenanzaServ.deleteDocumentoById(recursoAdjunto.recursoAdjuntoId).subscribe((res: SimpleResponse) => {
      this.data.recurso.recursoAdjunto = this.data.recurso.recursoAdjunto.filter(x => x.recursoAdjuntoId != recursoAdjunto.recursoAdjuntoId);
      this.snackService.mostrarSnackBack('Archivo eliminado.');
    });
    this.listaArchivosEnlaceInfo.splice(index, 1);
  }

  obtenerPuntosActualCuestionario() {
    let sumaTotal = 0;
    this.cuestionario.secciones.forEach(seccion => {
      seccion.cuestionarioPregunta.forEach(pregunta => {
        sumaTotal += pregunta.ponderacion;
      })
    })
    sumaTotal = Math.min(sumaTotal, (isNaN(parseInt(this.puntosPreguntaBase)) ? 0 : this.puntosPreguntaBase));
    this.puntajeTotalPreguntas = sumaTotal;

    return sumaTotal;
  }

  publicarRecurso() {
    if (!this.data.esNuevoRegistro) {
      if (this.data.recurso.tipoRecursoId == 3) {
        if (this.examenCreado) {

          let puntosActulCuestionario = this.obtenerPuntosActualCuestionario();

          if (!(puntosActulCuestionario < this.puntosPreguntaBase)) {
            this.publicarRecursoServ(this.puntosPreguntaBase, this.data.recurso.recursoId);

          } else {
            this.snackService.mostrarSnackBack(`Para publicar un examen, la sumatoria de los puntos debe ser igual a ${this.puntosPreguntaBase} (máximo valor numérico).`);
          }

        } else {
          this.snackService.mostrarSnackBack('Guarde un examen valido para poder publicar el recurso.');
        }
      } else {
        this.publicarRecursoServ(this.puntosPreguntaBase, this.data.recurso.recursoId);

      }




    } else {
      this.publicarRecursoServ(this.puntosPreguntaBase, this.objRecurso.recursoId);
    }
  }


  publicarRecursoServ(puntosMaximoBase, recursoId) {
    this.ensenanzaServ.PublicarRecurso(puntosMaximoBase, recursoId).subscribe(res => {
      if (res.exito) {
        this.snackService.mostrarSnackBack(res.message);
      } else {
        this.snackService.mostrarSnackBack(res.message);
      }
    })
  }

  guardarRecurso(tipoRecurso: number) {
    if (this.data.esNuevoRegistro) {
      //es nuevo registro
      switch (tipoRecurso) {
        case 1: //para Contenido
          let dataContenido;
          if (this.objRecurso == undefined) {
            dataContenido = {
              moduloId: this.selectedModulo,
              tipoRecursoId: this.selected,
              clave: '',
              nombre: this.titulo,
              descripcion: this.descripcion,
              publicado: this.selectedEstatus
            }
          } else {
            dataContenido = {
              moduloId: this.selectedModulo,
              tipoRecursoId: this.selected,
              clave: '',
              nombre: this.titulo,
              recursoId: this.objRecurso.recursoId,
              descripcion: this.descripcion,
              publicado: this.selectedEstatus
            }
          }
          this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, 0, this.selectedModulo, dataContenido).subscribe(res => {
            if (res.exito) {
              this.objRecurso = res.data;
              this.recursoGuardado = true;
              //parte del adjunto
              if (this.listaArchivosEnlace.length > 0) {
                //parte del adjunto
                //llamar la lista concatenada y asignarle el recursoId
                this.listaArchivosEnlace.forEach(element => {
                  element.recursoId = res.data.recursoId
                });
                this.ensenanzaServ.subirArchivoEnlace(this.listaArchivosEnlace).subscribe(res => {
                  if (res.exito) {
                    this.listaArchivosEnlaceInfo = this.listaArchivosEnlaceInfo.concat(res.data);
                    //enlazar listado
                  }
                });
                this.listaArchivosEnlace = [];
              }
              else {

              }

              this.ensenanzaServ.invocarMetodoObtenerContenido();
              this.ensenanzaServ.invocarMetodoObtenerArchivos();
              this.ensenanzaServ.invocarMetodoPorRevisar();
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
            }
          });
          break;
        case 2: // para Tarea
          if ((this.ponderacion == undefined ? 0 : this.ponderacion) <= this.data.ponderacion) {

            let dataTarea;
            if (this.objRecurso == undefined) {
              dataTarea = {
                moduloId: this.selectedModulo,
                tipoRecursoId: this.selected,
                clave: '', //no se como pasarle la clave del grupo ya se me ocurrirá algo
                nombre: this.titulo,
                descripcion: this.descripcion,
                fechaEntrega: this.fechaEntrega,
                horaFin: this.horaEntrega,
                permiteVencida: this.permiteVencida,
                ponderacion: this.ponderacion == undefined ? 0 : this.ponderacion,
                publicado: this.selectedEstatus
              }
            } else {
              dataTarea = {
                moduloId: this.selectedModulo,
                tipoRecursoId: this.selected,
                clave: '', //no se como pasarle la clave del grupo ya se me ocurrirá algo
                nombre: this.titulo,
                descripcion: this.descripcion,
                fechaEntrega: this.fechaEntrega,
                horaFin: this.horaEntrega,
                permiteVencida: this.permiteVencida,
                ponderacion: this.ponderacion == undefined ? 0 : this.ponderacion,
                recursoId: this.objRecurso.recursoId,
                publicado: this.selectedEstatus
              }
            }
            this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, this.data.ponderacion, dataTarea.moduloId, dataTarea).subscribe(res => {
              if (res.exito) {
                this.objRecurso = res.data;
                this.recursoGuardado = true;
                if (this.listaArchivosEnlace.length > 0) {
                  //parte del adjunto
                  //llamar la lista concatenada y asignarle el recursoId
                  this.listaArchivosEnlace.forEach(element => {
                    element.recursoId = res.data.recursoId
                  });
                  this.ensenanzaServ.subirArchivoEnlace(this.listaArchivosEnlace).subscribe(res => {
                    if (res.exito) {
                      this.listaArchivosEnlaceInfo = this.listaArchivosEnlaceInfo.concat(res.data);
                      //enlazar listado
                    }
                  });
                  this.listaArchivosEnlace = [];
                } else {
                  //this.ensenanzaServ.invocarMetodoPorRevisar();
                }
                this.ensenanzaServ.invocarMetodoObtenerContenido();
                this.ensenanzaServ.invocarMetodoPorRevisar();
                this.ensenanzaServ.invocarMetodoObtenerArchivos();
                let mensaje: string = this.traductorService.translate('guardadoCorrecto');
                this.snackService.mostrarSnackBack(mensaje);
              } else {
                this.snackService.mostrarSnackBack(res.message);
              }
            })
          } else {
            this.snackService.mostrarSnackBack(`La ponderación no puede ser mayor a la ponderación base ${this.puntosPreguntaBase}.`);
          }
          break;
        case 3: //Para Examen
          let fechaLiberacionEx = this.obtenerFechaHora(this.fechaInicio, this.horaInicio);
          let fechaCierreEx = this.obtenerFechaHora(this.fechaEntrega, this.horaEntrega);
          if (fechaLiberacionEx < fechaCierreEx) {
            if (this.ponderacion <= this.data.ponderacion) {
              let dataExamen;


              //#region Validar que la ponderación de respuesta no supere la ponderación de la pregunta
              let respuestaPonderacionInvalida = false;

              //this.puntajeTotalPreguntas = 0;
              this.cuestionario.secciones.forEach(seccion => {
                seccion.cuestionarioPregunta.forEach(pregunta => {
                  //this.puntajeTotalPreguntas += pregunta.ponderacion;
                  pregunta.cuestionarioRespuesta.forEach(respuesta => {
                    if (respuesta.ponderacion > pregunta.ponderacion) {
                      respuestaPonderacionInvalida = true;
                    }
                  });
                })
              });

              if (respuestaPonderacionInvalida) {
                this.snackService.mostrarSnackBack(`Los puntos de las respuestas excenden la ponderación base de la pregunta`);
              }
              //#endregion


              if (this.objRecurso === undefined) {
                dataExamen = {
                  moduloId: this.selectedModulo,
                  tipoRecursoId: this.selected,
                  clave: '', //no se como pasarle la clave del grupo ya se me ocurrirá algo
                  nombre: this.titulo,
                  fechaInicio: this.fechaInicio,
                  horaInicio: this.horaInicio,
                  descripcion: this.descripcion,
                  fechaEntrega: this.fechaEntrega, //seria la fecha de cierre
                  horaFin: this.horaEntrega,
                  ordenAleatorio: this.preguntasAleatorias,
                  ordenAleatorioRespuestas: this.respuestasAleatorias,
                  ponderacion: this.ponderacion,
                  reintentos: this.reintentos,
                  cronometro: this.cronometroSegundos,

                  publicado: this.UsaBancoPreguntas ? ((this.numeroPreguntas >= this.PreguntasAdesplegar) ? this.selectedEstatus : false) : this.selectedEstatus,

                  usaBancoPreguntas: this.UsaBancoPreguntas,
                  preguntasAdesplegar: this.UsaBancoPreguntas ? this.PreguntasAdesplegar : null
                }
              } else {
                dataExamen = {
                  moduloId: this.selectedModulo,
                  tipoRecursoId: this.selected,
                  clave: '', //no se como pasarle la clave del grupo ya se me ocurrirá algo
                  nombre: this.titulo,
                  fechaInicio: this.fechaInicio,
                  horaInicio: this.horaInicio,
                  descripcion: this.descripcion,
                  fechaEntrega: this.fechaEntrega, //seria la fecha de cierre
                  horaFin: this.horaEntrega,
                  ordenAleatorio: this.preguntasAleatorias,
                  ordenAleatorioRespuestas: this.respuestasAleatorias,
                  ponderacion: this.ponderacion,
                  reintentos: this.reintentos,
                  cronometro: this.cronometroSegundos,
                  recursoId: this.objRecurso.recursoId,

                  publicado: this.UsaBancoPreguntas ? ((this.numeroPreguntas >= this.PreguntasAdesplegar) ? this.selectedEstatus : false) : this.selectedEstatus,

                  usaBancoPreguntas: this.UsaBancoPreguntas,
                  preguntasAdesplegar: this.UsaBancoPreguntas ? this.PreguntasAdesplegar : null
                }
              }

              if (this.selectedEstatus) {
                if (this.PreguntasAdesplegar > this.numeroPreguntas) {
                  //El recurso no puede ser publicado. El banco no cuenta con suficientes preguntas para desplegar lo configurado.
                  this.snackService.mostrarSnackBack(this.translate('_tableroProfesor.validar-publicado'));
                  //break;
                  dataExamen.publicado = false;
                }
              }

              this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, this.data.ponderacion, this.selectedModulo, dataExamen).subscribe(res => {
                if (res.exito) {
                  this.objRecurso = res.data;
                  this.recursoGuardado = true;
                  this.ensenanzaServ.CreacionEdicionExamenEncuesta(res.data.recursoId, this.cuestionario.secciones).subscribe(res => {
                    if (res.exito) {
                      let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
                      this.snackService.mostrarSnackBack(mensaje);
                      this.examenCreado = true;
                      this.ensenanzaServ.invocarMetodoObtenerContenido();
                      this.ensenanzaServ.invocarMetodoPorRevisar();
                      this.ensenanzaServ.invocarMetodoObtenerArchivos();
                      this.dialogRevisionRef.close();
                    }

                  }, error => {
                  });


                } else {
                  this.snackService.mostrarSnackBack(res.message);
                }
              });
            } else {
              this.snackService.mostrarSnackBack(`La ponderación no puede ser mayor a la ponderación base ${this.puntosPreguntaBase}.`);
            }
          } else {
            this.snackService.mostrarSnackBack('La fecha liberacion no puede ser mayor a la fecha cierre.');
          }
          break;
        case 4: // para Encuestas
          let fechaLiberacionEn = this.obtenerFechaHora(this.fechaInicio, this.horaInicio);
          let fechaCierreEn = this.obtenerFechaHora(this.fechaEntrega, this.horaEntrega);

          if (fechaLiberacionEn < fechaCierreEn) {
            let dataEncuesta;
            if (this.objRecurso == undefined) {
              dataEncuesta = {
                moduloId: this.selectedModulo,
                tipoRecursoId: this.selected,
                clave: '',
                nombre: this.titulo,
                fechaInicio: this.fechaInicio,
                horaInicio: this.horaInicio,
                descripcion: this.descripcion,
                fechaEntrega: this.fechaEntrega,
                horaFin: this.horaEntrega,
                ordenAleatorio: this.preguntasAleatorias,
                ordenAleatorioRespuesta: this.respuestasAleatorias,
                respuestaPublica: !this.respuestaPublica,
                publicado: this.selectedEstatus
              }
            } else {
              dataEncuesta = {
                moduloId: this.selectedModulo,
                tipoRecursoId: this.selected,
                clave: '',
                nombre: this.titulo,
                fechaInicio: this.fechaInicio,
                horaInicio: this.horaInicio,
                descripcion: this.descripcion,
                fechaEntrega: this.fechaEntrega,
                horaFin: this.horaEntrega,
                ordenAleatorio: this.preguntasAleatorias,
                ordenAleatorioRespuesta: this.respuestasAleatorias,
                respuestaPublica: !this.respuestaPublica,
                recursoId: this.objRecurso.recursoId,
                publicado: this.selectedEstatus
              }
            }
            this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, 0, this.selectedModulo, dataEncuesta).subscribe(res => {

              if (res.exito) {
                this.objRecurso = res.data;
                this.recursoGuardado = true;
                this.ensenanzaServ.CreacionEdicionExamenEncuesta(res.data.recursoId, this.cuestionario.secciones).subscribe(res => {
                  let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
                  this.snackService.mostrarSnackBack(mensaje);
                }, error => {
                });
                this.ensenanzaServ.invocarMetodoObtenerContenido();
                this.ensenanzaServ.invocarMetodoPorRevisar();
                this.ensenanzaServ.invocarMetodoObtenerArchivos();
                let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
                this.snackService.mostrarSnackBack(mensaje);
              } else {
                this.snackService.mostrarSnackBack(res.message);

              }
            });
          } else {
            this.snackService.mostrarSnackBack('La fecha liberacion no puede ser mayor a la fecha cierre.');
          }
          break;
      }
    }
    else {
      //editar registro
      switch (tipoRecurso) {
        case 1: //para Contenido
          let editarContenido = {
            recursoId: this.data.recurso.recursoId,
            moduloId: this.selectedModulo,
            tipoRecursoId: this.selected,
            clave: '',
            nombre: this.titulo,
            descripcion: this.descripcion,
            publicado: this.selectedEstatus
          }
          //manda los datos invertidos el valor del modulo toma el valor del recurso
          this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, 0, this.selectedModulo, editarContenido).subscribe(res => {
            //parte del adjunto
            if (res.exito) {
              this.recursoGuardado = true;
              this.listaArchivosEnlace.forEach(element => {
                element.recursoId = editarContenido.recursoId
              });
              if (this.listaArchivosEnlace.length > 0) {
                this.ensenanzaServ.subirArchivoEnlace(this.listaArchivosEnlace).subscribe(res => {
                  if (res.exito) {
                    this.listaArchivosEnlaceInfo = this.listaArchivosEnlaceInfo.concat(res.data);
                    //enlazar listado
                    this.ensenanzaServ.invocarMetodoObtenerContenido();
                    this.ensenanzaServ.invocarMetodoObtenerArchivos();
                  }
                });
                this.listaArchivosEnlace = [];
              }
              else {
                this.ensenanzaServ.invocarMetodoObtenerContenido();
                this.ensenanzaServ.invocarMetodoObtenerArchivos();
              }
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
            }
          });
          break;
        case 2: // para Tarea
          if (this.ponderacion <= this.data.ponderacion) {
            let editarTarea = {
              recursoId: this.data.recurso.recursoId,
              moduloId: this.selectedModulo,
              tipoRecursoId: this.selected,
              clave: '', //no se como pasarle la clave del grupo ya se me ocurrirá algo
              nombre: this.titulo,
              descripcion: this.descripcion,
              fechaEntrega: this.fechaEntrega,
              horaFin: this.horaEntrega,
              permiteVencida: this.permiteVencida,
              ponderacion: this.ponderacion,
              publicado: this.selectedEstatus
            }
            this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, this.data.ponderacion, this.selectedModulo, editarTarea)
              .subscribe(res => {
                if (res.exito) {
                  this.recursoGuardado = true;
                  this.listaArchivosEnlace.forEach(element => {
                    element.recursoId = editarTarea.recursoId
                  });
                  if (this.listaArchivosEnlace.length > 0) {
                    this.ensenanzaServ.subirArchivoEnlace(this.listaArchivosEnlace).subscribe(res => {
                      this.ensenanzaServ.invocarMetodoObtenerContenido();
                      this.ensenanzaServ.invocarMetodoPorRevisar();
                      this.ensenanzaServ.invocarMetodoObtenerArchivos();
                    });
                  }
                  else {
                    this.ensenanzaServ.invocarMetodoObtenerContenido();
                    this.ensenanzaServ.invocarMetodoPorRevisar();
                    this.ensenanzaServ.invocarMetodoObtenerArchivos();
                  }
                  let mensaje: string = this.traductorService.translate('guardadoCorrecto');
                  this.snackService.mostrarSnackBack(mensaje);
                }
              });
          } else {
            this.snackService.mostrarSnackBack(`La ponderacion no puede ser mayor a la ponderacion base ${this.puntosPreguntaBase}.`);
          }
          break;
        case 3: //Para Examen
          let fechaLiberacionEx = this.obtenerFechaHora(this.fechaInicio, this.horaInicio);
          let fechaCierreEx = this.obtenerFechaHora(this.fechaEntrega, this.horaEntrega);
          if (fechaLiberacionEx < fechaCierreEx) {
            if (this.ponderacion <= this.data.ponderacion) {
              //this.onChangeValorTiempo();

              //#region Validar que la ponderación de respuesta no supere la ponderación de la pregunta
              let respuestaPonderacionInvalida = false;

              //this.puntajeTotalPreguntas = 0;
              this.cuestionario.secciones.forEach(seccion => {
                seccion.cuestionarioPregunta.forEach(pregunta => {
                  //this.puntajeTotalPreguntas += pregunta.ponderacion;
                  pregunta.cuestionarioRespuesta.forEach(respuesta => {
                    if (respuesta.ponderacion > pregunta.ponderacion) {
                      respuestaPonderacionInvalida = true;
                    }
                  });
                })
              });

              if (respuestaPonderacionInvalida) {
                this.snackService.mostrarSnackBack(`Los puntos de las respuestas excenden la ponderación base de la pregunta`);
              }
              //#endregion


              let dataExamen = {
                recursoId: this.data.recurso.recursoId,
                moduloId: this.selectedModulo,
                tipoRecursoId: this.selected,
                clave: '',
                nombre: this.titulo,
                fechaInicio: this.fechaInicio,
                horaInicio: this.horaInicio,
                descripcion: this.descripcion,
                fechaEntrega: this.fechaEntrega, //seria la fecha de cierre
                horaFin: this.horaEntrega,
                ordenAleatorio: this.preguntasAleatorias,
                ordenAleatorioRespuestas: this.respuestasAleatorias,
                ponderacion: this.ponderacion,
                reintentos: this.reintentos,
                cronometro: this.cronometroSegundos,

                publicado: this.UsaBancoPreguntas ? ((this.numeroPreguntas >= this.PreguntasAdesplegar) ? this.selectedEstatus : false) : this.selectedEstatus,

                usaBancoPreguntas: this.UsaBancoPreguntas,
                preguntasAdesplegar: this.UsaBancoPreguntas ? this.PreguntasAdesplegar : null
              }

              //#region Validar que el número de preguntas a desplegar no superé al número de preguntas del banco

              if (this.selectedEstatus) {
                if (this.PreguntasAdesplegar > this.numeroPreguntas) {
                  //El recurso no puede ser publicado. El banco no cuenta con suficientes preguntas para desplegar lo configurado.
                  this.snackService.mostrarSnackBack(this.translate('_tableroProfesor.validar-publicado'));
                  //break;
                  dataExamen.publicado = false;
                }
              }

              //#endregion
              this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, this.data.ponderacion, this.selectedModulo, dataExamen).subscribe(res => {
                if (res.exito) {
                  this.recursoGuardado = true;
                  this.examenCreado = true;

                  this.ensenanzaServ.CreacionEdicionExamenEncuesta(this.data.recurso.recursoId, this.cuestionario.secciones).subscribe(res => {
                    let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
                    this.snackService.mostrarSnackBack(mensaje);
                    if (res.exito) {
                      this.dialogRevisionRef.close();
                      this.ensenanzaServ.invocarMetodoObtenerContenido();
                      this.ensenanzaServ.invocarMetodoObtenerArchivos();
                      this.ensenanzaServ.invocarMetodoPorRevisar();
                    } else {

                    }
                  }, error => {

                  });

                  let mensaje: string = this.traductorService.translate('guardadoCorrecto');
                  this.snackService.mostrarSnackBack(mensaje);


                } else {
                  this.snackService.mostrarSnackBack(res.message);
                }
              });
            } else {
              this.snackService.mostrarSnackBack(`La ponderacion no puede ser mayor a la ponderacion base ${this.puntosPreguntaBase}.`);
            }
          } else {
            this.snackService.mostrarSnackBack('La fecha liberacion no puede ser mayor a la fecha cierre.');
          }
          break;
        case 4: // para Encuestas
          let fechaLiberacionEn = this.obtenerFechaHora(this.fechaInicio, this.horaInicio);
          let fechaCierreEn = this.obtenerFechaHora(this.fechaEntrega, this.horaEntrega);
          if (fechaLiberacionEn < fechaCierreEn) {
            let dataEncuesta = {
              recursoId: this.data.recurso.recursoId,
              moduloId: this.selectedModulo,
              tipoRecursoId: this.selected,
              clave: '',
              nombre: this.titulo,
              fechaInicio: this.fechaInicio,
              horaInicio: this.horaInicio,
              descripcion: this.descripcion,
              fechaEntrega: this.fechaEntrega,
              horaFin: this.horaEntrega,
              ordenAleatorio: this.preguntasAleatorias,
              ordenAleatorioRespuestas: this.respuestasAleatorias,
              respuestaPublica: this.respuestaPublica,
              publicado: this.selectedEstatus
            }
            this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, 0, this.selectedModulo, dataEncuesta).subscribe(res => {
              if (res.exito) {
                this.recursoGuardado = true;
                this.ensenanzaServ.CreacionEdicionExamenEncuesta(this.data.recurso.recursoId, this.cuestionario.secciones).subscribe(res => {
                  let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
                  this.snackService.mostrarSnackBack(mensaje);
                }, error => {
                });
                this.ensenanzaServ.invocarMetodoObtenerContenido();
                this.ensenanzaServ.invocarMetodoObtenerArchivos();
                let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
                this.snackService.mostrarSnackBack(mensaje);
              } else {
                this.snackService.mostrarSnackBack(res.message);
              }
            });
          } else {
            this.snackService.mostrarSnackBack('La fecha liberacion no puede ser mayor a la fecha cierre.');
          }
          break;
      }
    }

  }
}
