import { StudentChangeService } from './../../_services/student-change.service';
import { NuevoIngresoService } from './../../_services/nuevo-ingreso.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ApiResponse } from '../../models/api/ApiRespose.model';
import { UsuarioIngreso } from '../../_models/usuarionuevoingresodto';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeguimientoAlumnoDTO } from 'src/app/_models/SeguimientoAlumnoDTO';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-seguimiento-academico-alumno-modal',
  templateUrl: './seguimiento-academico-alumno-modal.component.html',
  styleUrls: ['./seguimiento-academico-alumno-modal.component.scss']
})
export class SeguimientoAcademicoAlumnoModalComponent implements OnInit {

  //#region Constructor
  constructor(
    public dialogRef: MatDialogRef<SeguimientoAcademicoAlumnoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { alumno: SeguimientoAlumnoDTO, indexTap: number },
    private nuevoIngresoService: NuevoIngresoService,
    private sanitizer: DomSanitizer,
    private studentChangeService: StudentChangeService
    ) {
      this.alumno = data.alumno;
     }

  ngOnInit(): void {
    this.cargarImagen();
    this.DatosEncabezado();
  }
  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Propiedades
  public base64Image: string;
  public imagePath: SafeResourceUrl;
  public alumno: SeguimientoAlumnoDTO;
  public alumnoInfo: any;
  public dataBoleta : any;
  //#endregion

  //#region Metodos
  public cargarImagen(): void {
    this.Subscription.add(
      this.nuevoIngresoService.imagenUsuario(this.alumno.usuarioId).subscribe(
        (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
          if (datosUsuarioImagen.success) {
            this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
          }
        }
      )
    );
  }

  public DatosEncabezado(): void {
    this.Subscription.add(
      this.studentChangeService.GetAlumnobyAlumno(this.alumno.alumnoId).subscribe((alumno: ApiResponse<any>) => {
        if (alumno.data) {
          this.alumnoInfo = alumno.data[0];
          this.alumnoInfo.promedio = this.alumno.promedioPrograma;
          this.alumnoInfo.promedioPeriodo = this.alumno.promedioPeriodo;
          this.dataBoleta = {
            alumno: {
              ...alumno.data[0],
              promedio : this.alumno.promedioPrograma,
              promedioPeriodo : this.alumno.promedioPeriodo,
            },
            indexTap: this.data.indexTap
          }
        }
      }, (err => {

      }))
    );
  }

  public CerrarModal(): void {
      this.dialogRef.close();

  }
  //#endregion
}
