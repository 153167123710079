import { GeneracionExcelServiceService } from './../../services/generacion-excel-service.service';
import { ServicePackageSeguimientoDto } from './../../_models/ServicePackageSeguimientoDto';
import { ServicioPaqueteModalComponent } from './../servicio-paquete-modal/servicio-paquete-modal.component';
import { AdminAcademicoAlumnoDto } from './../../_models/AdminAcademicoAlumnoDto';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { ServicioPaqueteService } from './../../_services/servicio-paquete.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { FiltroAdminAcademicoAlumnoDto } from '@app/_models/FiltroAdminAcademicoAlumnoDto';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-servicio-paquete-seguimiento',
  templateUrl: './servicio-paquete-seguimiento.component.html',
  styleUrls: ['./servicio-paquete-seguimiento.component.scss']
})
export class ServicioPaqueteSeguimientoComponent implements OnInit {
  public datafilter: any[] = [];
  public numAlumnosSeleccionados: number = 0;
  public selection = new SelectionModel<any>(true, []);
  public displayedColumns: string[] = ['select', 'clave', 'nombre', 'cantidad', 'estatus'];
  public dataSource: MatTableDataSource<any>;
  public filtroForm: FormGroup;
  public idNombre = new FormControl();
  public estatusF = new FormControl();
  public estatus: any[] = [];
  public valorInicial = 0;
  public arregloFiltroModificado: any[] = [];
  public arregloFiltroOriginal: any[] = [];
  public infoCargada: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allEstatus') private allEstatus: MatOption;

  constructor(private nuevoIngresoService: NuevoIngresoService,
    public util: UtileriasService,
    private snackServiceService: SnackService,
    private dialog: MatDialog,
    private paginador: MatPaginatorIntl,
    private servicioPaqueteService: ServicioPaqueteService,
    private generadorExcel: GeneracionExcelServiceService) { }

  async ngOnInit(): Promise<void> {
    if (this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription === undefined) {
      this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription = this.nuevoIngresoService.invocarObtenerCandidatos.subscribe(() => {
        this.LlenarFiltros();
      });
      this.nuevoIngresoService.invMetodoObtenerCandidatosSubscription === undefined;
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    await this.inicializarForm().then((_) => {
      this.LlenarFiltros();
    });
  }

  /**
   * Hace un llenado de todos los filtros, ingresa a las funciones de obtencion de datos para su carga
   */
  public LlenarFiltros(): void {
    this.obtenerEstatus();
  }

  /**
   * Obtiene los campus del estatus academico
   */
  public obtenerEstatus(): void {
    this.estatus = [
      {
        estatusId: 1,
        clave: "ACTIVO",
        nombre: "Activo"
      },
      {
        estatusId: 2,
        clave: "INACTIVO",
        nombre: "Inactivo"
      },
    ];
    this.estatusF.setValue([...this.estatus.map(item => item.estatusId), 0]);
    this.filtroForm.patchValue({
      estatusF: this.estatus.map(item => item.estatusId)
    });
    this.filtrar();
  }

  /**
   * Inicializa el formulario y setea los campos usados
   */
  public inicializarForm(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.filtroForm = new FormGroup({
        idnombre: new FormControl(),
        estatusF: new FormControl([])
      });
      this.estatusF.setValue(["-1"]);
      resolve();
    });
  }

  /**
   * Metodo para hacer la descarga de excel de alumnos seleccionados
   */
  public descargarExcel(): void {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      identificador: this.filtroForm.get('idnombre').value,
      estatusAr: this.filtroForm.get('estatusF').value
    };
    this.servicioPaqueteService.paquetesServicio(1, this.paginator.pageSize, filtro).subscribe(
      (paquetes: ApiResponse<AdminAcademicoAlumnoDto>) => {
        if (paquetes.data.paqueteServicio !== null) {
          this.servicioPaqueteService.descargarExcel(paquetes.data.paqueteServicio).subscribe((blob: Blob) => {
            this.generadorExcel.GenerarExcel(blob, "PaquetesDeServicios");
          });
        }
      });
  }

  /**
   * Metodo que envia la informacion de los filtros al back
   */
  public filtrar() {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      identificador: this.filtroForm.get('idnombre').value,
      estatusAr: this.filtroForm.get('estatusF').value
    };
    this.obtenerPaqueteServicios(1, this.paginator.pageSize, filtro);
  }

  /**
   * Metodo que detecta el cambio de pagina y llama el servicio para traer la siguiente informacion
   * @param event
   */
  public onPaginateChange(event: any) {
    const filtro: FiltroAdminAcademicoAlumnoDto = {
      identificador: this.filtroForm.get('idnombre').value,
      estatusAr: this.filtroForm.get('estatusF').value
    };
    this.obtenerPaqueteServicios(event.pageIndex + 1, event.pageSize, filtro);
  }

  /**
   * 
   * @param pagina
   * @param registrosPagina
   * @param filtro
   */
  public obtenerPaqueteServicios(pagina: number, registrosPagina: number, filtro: FiltroAdminAcademicoAlumnoDto) {
    this.servicioPaqueteService.paquetesServicio(pagina, registrosPagina, filtro).subscribe(
      (paquetes: ApiResponse<AdminAcademicoAlumnoDto>) => {
        if (paquetes.data.paqueteServicio !== null) {
          this.arregloFiltroOriginal = paquetes.data.paqueteServicio;
          this.datafilter = paquetes.data.paqueteServicio;
          this.dataSource = new MatTableDataSource(this.datafilter);
          this.valorInicial = paquetes.data.cantidadAlumnos;
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargada = true;
        } else {
          this.dataSource = new MatTableDataSource([]);
          this.arregloFiltroOriginal = [];
          this.datafilter = [];
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargada = true;
        }
      });
  }

  /**
   * Metodo para buscar los filtros
   */
  public buscarFiltros(): void {
    this.filtrar();
  }


  /**
   * Limpia el arreglo de filtro, llena nuevamente los filtros e inicia el formulario, y muestra la tabla sin filtrado
   */
  public LimpiarFiltros(): void {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arregloFiltroOriginal);
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Vacia el arreglo
   */
  public vaciarArregloModificado(): void {
    this.arregloFiltroModificado.length = 0;
  }

  /**
   * Si solo un dato del select estatus esta seleccionado busca solo esa informacion y la
   * muestra en la tabla principal
   */
  toggleAllSelectionEstatus(): void {
    // this.reiniciarTimer();
    if (this.allEstatus.selected) {
      this.filtroForm.patchValue({
        estatusF: this.estatus.map(item => item.estatusId)
      });
      this.estatusF.patchValue([...this.estatus.map(item => item.estatusId), 0]);
    } else {
      this.filtroForm.patchValue({
        estatusF: []
      });
      this.estatusF.patchValue([]);
    }
  }

  /**
   * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta
   * quita la seleccion de todos los campos, sino los selecciona todos
   * @param campusId
   * @returns
   */
  tosslePerOneEstatus(estatusId: number): void {
    // this.reiniciarTimer();
    if (this.allEstatus.selected) {
      this.allEstatus.deselect();
    }
    if (this.estatusF.value.length == this.estatus.length) {
      this.allEstatus.select();
    }
    const EstatusSeleccionados = this.filtroForm.get('estatusF').value;
    const existeEstatus = EstatusSeleccionados.find(x => x === estatusId);
    if (!existeEstatus) {
      const estatusAnteriores = EstatusSeleccionados;
      estatusAnteriores.push(estatusId);
      this.filtroForm.patchValue({
        estatusF: estatusAnteriores
      });
    } else {
      const estatusFiltrados = EstatusSeleccionados.filter(x => x !== estatusId);
      this.filtroForm.patchValue({
        estatusF: estatusFiltrados
      });
    }
  }

  /**
 * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
 * seleccionados para modificar el check
 * @returns boolean para conocer si todos estan seleccionados
 */
  isAllSelected(): boolean {
    if (this.datafilter) {
      this.numAlumnosSeleccionados = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numAlumnosSeleccionados === numRows;
    }
  }

  /**
   * Funcion para el check de la tabla
   * @param row
   * @returns
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }

  /**
   * Funcion para el check de la tabla
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Metodo para abrir el modal para generar un nuevo paquete de servicio
   */
  public paqueteServicioCrearEditar(nuevoPaquete: boolean, element?: ServicePackageSeguimientoDto): void {
    let data: ServicePackageSeguimientoDto = new ServicePackageSeguimientoDto();
    if (!element) {
      data.nuevoPaquete = nuevoPaquete;
    } else {
      data = element;
      data.nuevoPaquete = nuevoPaquete;
    }
    this.util.abrirDialogoLateral(ServicioPaqueteModalComponent, data);
  }

}
