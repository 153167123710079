import { TraductorService } from './../../_services/traductor.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { Documento } from 'src/app/_models/documento';
import { UsuarioDocumento } from 'src/app/_models/usuario-documento';
import { DocumentoService } from 'src/app/_services/documento.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { SolicitudApoyoFinancieroService } from 'src/app/_services/solicitud-apoyo-financiero.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-apoyo-financiero-documento',
  templateUrl: './apoyo-financiero-documento.component.html',
  styleUrls: ['./apoyo-financiero-documento.component.scss']
})
export class ApoyoFinancieroDocumentoComponent implements OnInit, OnDestroy {

  //Variables para las subcripciones
  public subscription: Subscription = new Subscription();
  //Variables de solicitud y usuario
  public usuarioId: number = 0;
  public solicitudId: number = 0;
  public correo: string = "";
  //Variables renderizar tabla
  public infoCargado: boolean = false;
  //Variables para tabla
  displayedColumns: string[] = ['select', 'tipo', 'nombre', 'fecha'];
  public dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  //Variables para documentos
  public documentos: any = [];
  public numDocumentoSeleccionado = 0;
  public documentoList: Documento[] = [];
  public tipoDocSelected: number;
  public date: Date = new Date();
  @ViewChild('modalVisorPDF') modalVisorPDF: TemplateRef<any>;
  public modalVisorPDFRef: any;
  public renderizarForm: boolean = false;
  public cambioFotografia: boolean = false;
  public cargaDocumentoForm: FormGroup;
  public pdf: ArrayBuffer;
  public mime: string;
  public imagen: string;
  public CLAVE_PROCESO = "APOYO";
  @Input() UsuarioIdInput: number = 0;
  @Input() EstatusSolicitudApoyoId?: number = 0;

  constructor(
    public nuevoIngresoService: NuevoIngresoService,
    private dialog: MatDialog,
    private utileriasService: UtileriasService,
    private snackBar: SnackService,
    private apoyoFinancieroService: SolicitudApoyoFinancieroService,
    private documentoService: DocumentoService,
    private snackService: SnackService,
    private traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.ObtenerDocumentosList();
    if (this.UsuarioIdInput !== 0) {
      this.usuarioId = this.UsuarioIdInput;
      this.obtenerSolicictudId(this.UsuarioIdInput);
    }
  }
  /**
   * Metodo para obtener el catalogo de documentos para el dropdown
   */
  public ObtenerDocumentosList() {
    this.subscription.add(
      this.documentoService.ObtenerDocumentosProcesoSubProcesoApoyo(this.CLAVE_PROCESO)
        .subscribe((documentos: ApiResponse<Documento[]>) => this.documentoList = documentos.data)
    );
  }


  /**
   * Metodo que se ejecuta para renderizar el componente
   * @param usuarioId
   * @param solicitudId
   * @param correo
   */
  public renderizarFormulario(usuarioId: number, solicitudId: number, correo: string): void {
    this.usuarioId = usuarioId;
    this.solicitudId = solicitudId;
    this.correo = correo;
    this.ObtenerDocumentosList();
    if (solicitudId === null) {
      this.inicializarForm();
    } else {
      this.obtenerDocumentos();
      this.inicializarForm();
    }
  }

  public obtenerDocumentos():void{
    this.subscription.add(
      this.apoyoFinancieroService.obtenerDocumentos(this.usuarioId, this.solicitudId)
        .subscribe((documentosUsuario: ApiResponse<UsuarioDocumento[]>) => {
          if (documentosUsuario.success) {
            if (documentosUsuario.data.length > 0) {
              this.documentos = documentosUsuario.data;
              this.dataSource = new MatTableDataSource(documentosUsuario.data);
              this.selection = new SelectionModel<any>(true, []);
              this.infoCargado = true;
            }
          } else {
            this.snackBar.mostrarSnackBack(documentosUsuario.message);
          }
        })
    );
  }
  /**
   * Metodo que inicializa el form del envio y edicion de documentos
   */
  public inicializarForm(): void {
    this.cargaDocumentoForm = new FormGroup({
      usuarioId: new FormControl(this.usuarioId),
      tipoDocumento: new FormControl(0),
      docs: new FormControl(),
      docsEditar: new FormControl(),
    });
    this.renderizarForm = true;
  }

  /**
   * Metodo que trae el id de la solicitud, se ejecuta cuando se registra un apoyo fiananciero cuando el usuario no tiene ninguno
   * @param usuarioId
   */
  public obtenerSolicictudId(usuarioId: number): void {
    this.subscription.add(
      this.apoyoFinancieroService.obtenerIdSolicitudApoyo(usuarioId).subscribe(
        (response: ApiResponse<number>) => {
          if (response.success) {
            this.solicitudId = response.data;
          } else {
            this.snackBar.mostrarSnackBack(response.message);
          }
        })
    );
  }

  /**
   * Metodo que se activa el usar el select
   * @param docId
   */
  public docSelected(docId: number): void {
    console.log(docId);
    if (!!docId) {
      this.tipoDocSelected = docId;
    }
  }


  /**
 * Metodo para subir un documento con el select
 * @param event
 */
  public onFileChange(event: any) {
    if (this.tipoDocSelected == undefined) {
      this.tipoDocSelected = this.documentoList[0].documentoId;
    }
    let documentos: any[] = [];
    let documentosEditar: any[] = [];
    const reader = new FileReader();
    const [file] = event.target.files;
    if (event.target.files && event.target.files.length) {
      let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === this.tipoDocSelected);
      reader.onload = () => {
        let documento: any = {
          usuarioId: this.usuarioId,
          documentoId: tipoDocumentoSelecionado.documentoId,
          nombreOriginal: file.name,
          nombreFisico: reader.result,
          fechaCreacion: this.date,
          documentoNombre: tipoDocumentoSelecionado.nombre,
          estatusDocumentoId: 1,
          solicitudApoyoFinancieroId: this.solicitudId
        }
        documentos.push(documento);
        if (this.dataSource === undefined || this.dataSource.data.length === 0) {
          this.dataSource = new MatTableDataSource(documentos);
          this.selection = new SelectionModel<any>(false, []);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentos;
          this.infoCargado = true;
        } else {
          documentosEditar = this.dataSource.data;
          documentosEditar = documentosEditar.filter(x => x.documentoId !== this.tipoDocSelected);
          this.dataSource = new MatTableDataSource(documentosEditar);
          this.selection = new SelectionModel<any>(true, []);
          this.dataSource.data.push(documento);
          this.dataSource._updateChangeSubscription();
          this.documentos = documentosEditar;
        }
        this.registrarDoc(documentos);
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * Metodo para editar un documento seleccionado
   * @param event
   * @param tipoId
   */
  public onFileChangeEditar(event, tipoDocumentoId: number) {
    let documentosEditar: any[] = [];
    let documentosGuardar: any[] = [];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      let tipoDocumentoSelecionado: Documento = this.documentoList.find((doc: Documento) => doc.documentoId === tipoDocumentoId);
      reader.onload = () => {
        let documento = {
          usuarioId: this.usuarioId,
          documentoId: tipoDocumentoSelecionado.documentoId,
          nombreOriginal: file.name,
          nombreFisico: reader.result,
          fechaCreacion: this.date,
          documentoNombre: tipoDocumentoSelecionado.nombre,
          estatusDocumentoId: 1,
          solicitudApoyoFinancieroId: this.solicitudId
        }
        documentosEditar = this.documentos;
        documentosEditar = documentosEditar.filter(x => x.documentoId !== tipoDocumentoId);
        this.dataSource = new MatTableDataSource(documentosEditar);
        this.selection = new SelectionModel<any>(true, []);
        this.dataSource.data.push(documento);
        this.dataSource._updateChangeSubscription();
        this.documentos = this.dataSource.data;
        documentosGuardar.push(documento);
        this.registrarDoc(documentosGuardar);
      }
      reader.readAsDataURL(file);
    }
  }

  /**
   * Metodo que registra los documentos en base y azure
   * @param docs
   */
  public registrarDoc(docs: any): void {
    if (this.solicitudId !== null) {
      if (docs != null) {
        this.apoyoFinancieroService.registrarDocumentos(docs).subscribe(
          (cargaDocumentos: SimpleResponse) => {
            if (cargaDocumentos.success) {
              this.renderizarFormulario(this.usuarioId, this.solicitudId, this.correo);
              let mensaje: string = this.traductorService.translate('guardadoDocumentos');
              this.snackService.mostrarSnackBack(mensaje);
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          }
        );
      }
    } else {
      this.snackBar.mostrarSnackBack("Es necesario registrar una solicitud de apoyo financiero antes de cargar los documentos.");
    }
  }

  /**
   * Metodo que elimina los documentos de base y azure
   * @param documentId
   */
  public eliminarDocumento(documentId: number): void {
    if (documentId) {
      this.apoyoFinancieroService.deleteDocumentoById(this.usuarioId, documentId).subscribe((documentDelete: SimpleResponse) => {
        if (documentDelete.success) {
          if (this.documentos.length === 1) {
            this.infoCargado = false;
            this.numDocumentoSeleccionado = 0;
          }
          this.renderizarFormulario(this.usuarioId, this.solicitudId, this.correo);
          this.snackBar.mostrarSnackBack("Se ha eliminado el documento.");
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    }
  }

  /**
   * Visualizador para ver los pdf al oprimir el boton visualizar
   * @param refDocumento
   */
  public visualizarPdf(refDocumento: any): void {
    if (refDocumento > 0) {
      this.nuevoIngresoService.GetDocumentosCandidatoByCorreo(this.correo, refDocumento).subscribe(
        (datosUsuarioDocumento: ApiResponse<any>) => {
          if (datosUsuarioDocumento.success) {
            var documentos = datosUsuarioDocumento.data.usuarioDocumento.filter(x => x.documentoId === refDocumento);
            var documentoVisualizar = documentos.sort(this.compareDate);
            this.mime = documentoVisualizar[0].nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : /\.(png|jpeg|jpg|gif|tiff|bmp)$/.test(documentoVisualizar[0].nombreOriginal) ? "image/" + /\.(png|jpeg|jpg|gif|tiff|bmp)$/.exec(documentoVisualizar[0].nombreOriginal)[1] : "application/octect-stream";
            this.pdf = this.base64ToArrayBuffer(documentoVisualizar[0].nombreFisico);
            if (this.mime.indexOf("image") >= 0) {
              this.imagen = `data:${this.mime};base64,${documentoVisualizar[0].nombreFisico}`;
            }
            if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
              this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
            } else {
              const blob = new Blob([this.pdf], { type: this.mime });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            }
          }
        }
      );
    } else {
      let archivo = this.cargaDocumentoForm.controls[refDocumento].value;
      this.mime = archivo.split(",")[0].split(":")[1].split(";")[0];
      this.imagen = this.cargaDocumentoForm.controls[refDocumento].value;
      this.pdf = this.base64ToArrayBuffer(archivo);
      if (this.mime.indexOf("pdf") >= 0 || this.mime.indexOf("image") >= 0) {
        this.modalVisorPDFRef = this.dialog.open(this.modalVisorPDF, { disableClose: true });
      } else {
        const blob = new Blob([this.pdf], { type: this.mime });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }
    }
  }

  /**
   * Convierte el base64 a un arraybuffer para poder leerlo en el front
   * @param base64
   * @returns
   */
  base64ToArrayBuffer(base64): ArrayBufferLike {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  /**
   * Metodo para cerrar el modal del visualizador pdf
   */
  public cerrarModalPdf(): void {
    this.modalVisorPDFRef.close();
  }

  /**
   * Metodo interno del data table de angular material
   * @returns
   */
  isAllSelected(): boolean {
    this.numDocumentoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numDocumentoSeleccionado === numRows;
  }

  /**
   * Metodo interno del data table de angular material
   * @param row
   * @returns
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.documentoId + 1}`;
  }

  /**
   * Metodo interno del data table de angular material
   */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row))
  }

  /**
   * Metodo para comparar las fechas de los documentos
   * @param emp1
   * @param emp2
   * @returns
   */
  compareDate(emp1: any, emp2: any): any {
    var emp1Date = new Date(emp1.date).getTime();
    var emp2Date = new Date(emp2.date).getTime();
    return emp1Date > emp2Date ? 1 : -1;
  }

  /**
   * Metodo que se activa cuando se cierra el componente
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
