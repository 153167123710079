import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableSharedServicesService {

  constructor() { }

  private updateTable$ = new Subject<boolean>();
  
  /**
   * Ayuda a suscribirse a los cambios desde un componente padre desde el que se espera qeu un hijo notifique 
   * sobre la actualización.
   * @returns Observable
   */
  public getTableSubject(): Subject<boolean>{
    return this.updateTable$;
  }

  /**
   * Ayuda a notificar un componente padre sobre la actualización de la tabla.
   * Se ejecuta este método siempre que se esté en un componente en el que no se pueda llegar
   * hasta el padre
   */
  public updateTable(): void{
    this.updateTable$.next(true);
  }
}
