import { TraductorService } from './../../_services/traductor.service';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { SnackService } from 'src/app/services/snack-service.service';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { Aula } from 'src/app/_models/aula';
import { Campus } from 'src/app/_models/campus';
import { Edificio } from 'src/app/_models/edificio';
import { Programa } from 'src/app/_models/programa';
import { AulaService } from 'src/app/_services/aula.service';
import { CampusService } from 'src/app/_services/campus.service';
import { EdificioService } from 'src/app/_services/edificio.service';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-modal-aula',
  templateUrl: './modal-aula.component.html',
  styleUrls: ['./modal-aula.component.scss']
})
export class ModalAulaComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public edicionForm: FormGroup;
  public campusList: Campus[] = [];
  public edificioList: Edificio[];
  public subscriptionCampus: Subscription;
  public claveRegistrada: boolean = false;
  public arrPrograma: Programa[];
  public agregar: boolean = false;
  private datos: Aula = new Aula();
  public idSalon: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ModalAulaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private campusService: CampusService,
    private programaService: ProgramaAcademicoService,
    private snackBar: SnackService,
    private util: UtileriasService,
    private dialog: MatDialog,
    private edificioService: EdificioService,
    private aulaService: AulaService,
    private snackService: SnackService,
    private traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.llenarCampos();
    this.inicializarForm();
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      salonId: new FormControl(this.data?.salonId),
      clave: new FormControl(this.data?.clave, [Validators.required]),
      campus: new FormControl(this.data?.campusId, [Validators.required]),
      nombre: new FormControl(this.data?.nombre, [Validators.required]),
      edificio: new FormControl(this.data?.edificioId, [Validators.required]),
      estatus: new FormControl(this.data?.estatus == true ? true : false, [Validators.required])
    });
    this.edicionForm.valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
  }

  get clave() { return this.edicionForm.get('clave'); }
  get campus() { return this.edicionForm.get('campus'); }
  get nombre() { return this.edicionForm.get('nombre'); }
  get edificio() { return this.edicionForm.get('edificio'); }

  llenarCampos() {
    if (this.data != null) {
      this.agregar = true;
    }
    this.campusService.obtenerCampus().subscribe((camp: Campus[]) => {
      this.campusList = camp;
    })

    if (this.agregar) {
      this.edificioService.getEdificio().subscribe((objEdificio: Edificio[]) => {
        this.edificioList = objEdificio;
      })
    }
    // this.limpiar();
  }
  limpiar() {
    this.edicionForm = new FormGroup({
      salondId: new FormControl(this.data?.salonId),
      clave: new FormControl(this.data?.clave, [Validators.required]),
      campus: new FormControl(this.data?.campusId, [Validators.required]),
      nombre: new FormControl(this.data?.nombre, [Validators.required]),
      edificio: new FormControl(this.data?.edificioId, [Validators.required]),
      estatus: new FormControl(this.data?.estatus, [Validators.required])
    });
    this.edicionForm.valueChanges.subscribe(cambios => {
      this.boolCambio = true;
    });
  }
  obtenerProgramas() {
    this.programaService.obtenerProgramasCampus(this.edicionForm.value.campus).subscribe((objPrograma: Programa[]) => {
      this.arrPrograma = objPrograma;
    })
  }
  obtenerEdificios(campus: number) {
    this.campusService.obtenerEdificioCampus(campus).subscribe((objEdificio: Edificio[]) => {
      this.edificioList = objEdificio;
    })
  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.edicionForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido.";
    }
    return error;
  }

  guardar() {
    this.datos.edificioId = this.edicionForm.value.edificio;
    this.datos.clave = this.edicionForm.value.clave;
    this.datos.nombre = this.edicionForm.value.nombre;
    this.datos.estatus = this.edicionForm.value.estatus;
    this.datos.usuarioModificacion = -1

    if (!this.agregar) {
      this.agregarAula();
    }
    else {
      this.editarAula();
    }
  }

  agregarAula() {
    this.aulaService.postAula(this.datos).subscribe(datosEnviados => {
      if (datosEnviados) {
        this.boolCambio = false;
        this.aulaService.invocarMetodoObtenerAula();
        let mensaje: string = this.traductorService.translate('guardadoCorrecto');
        this.snackService.mostrarSnackBack(mensaje);
      } else {
        let mensaje: string = this.traductorService.translate('errorGuardar');
        this.snackService.mostrarSnackBack(mensaje);
      }
    });
  }

  editarAula() {
    this.datos.salonId = this.data.salonId;
    this.aulaService.editarAula(this.datos).subscribe(datosEnviados => {
      if (datosEnviados) {
        this.boolCambio = false;
        this.aulaService.invocarMetodoObtenerAula();
        let mensaje: string = this.traductorService.translate('guardadoCorrecto');
        this.snackService.mostrarSnackBack(mensaje);
      }
      else {
        let mensaje: string = this.traductorService.translate('errorGuardar');
        this.snackService.mostrarSnackBack(mensaje);
      }
    });
  }

  public obtenerCampus() {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      }
    );
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }
}
