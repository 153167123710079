import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl, environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { Campaign, CampaignDTO } from '../_models';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  private infoCampaign$ = new Subject<Campaign>();
  constructor(private httpClient: HttpClient) {}

  //Get
  public getCampaigns(): Observable<ApiResponse<Array<Campaign>>> {
    return this.httpClient.get<ApiResponse<Array<Campaign>>>(`${environment.enves[baseUrl].apiUrl}/Campaign`);
  }

  //Post
  public postCampaign(campaign: CampaignDTO): Observable<ApiResponse<Array<any>>> {
    return this.httpClient.post<ApiResponse<Array<any>>>(`${environment.enves[baseUrl].apiUrl}/Campaign`, campaign);
  }

  //Delete
  public deleteCampaign(campaignIds: string): Observable<ApiResponse<any>> {
    return this.httpClient.delete<ApiResponse<any>>(
      `${environment.enves[baseUrl].apiUrl}/Campaign/campaigns?ids=${campaignIds}`
    );
  }

  //#region CampaignId for Lead filter
  public setCampaignId(campaign) {
    sessionStorage.setItem('campaign', campaign);
  }
  public getCampaignId() {
    return sessionStorage.getItem('campaign');
  }
  public deleteCampaignId() {
    sessionStorage.removeItem('campaign');
  }
  //#endregion

  public setInfoCampaign(infoCampaign: Campaign) {
    this.infoCampaign$.next(infoCampaign);
  }
  public getInfoCampaign(): Observable<Campaign> {
    return this.infoCampaign$.asObservable();
  }
}
