import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { FechaTomaAsistenciaDto, InformacionGrupoAsistencia, ListaAlumnosGrupos } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { EnsenanzaAprendizajeService } from '../../../../_services/ensenanza-aprendizaje.service';
import { EnsenanzaAprendizajeAlumnoService } from '../../../../_services/ensenanzaAprendizajeAlumno.service';
import { UtileriasService } from '../../../../_services/utilerias.service';

@Component({
  selector: 'app-toma-asistencia',
  templateUrl: './toma-asistencia.component.html',
  styleUrls: ['./toma-asistencia.component.scss']
})
export class TomaAsistenciaComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'id', 'nombre', 'programa'];
  dataSource: MatTableDataSource<ListaAlumnosGrupos>;
  selection = new SelectionModel<ListaAlumnosGrupos>(true, []);

  public infoCargado: boolean = false;
  public infoGrupoCargado: boolean = false;
  public fechaGrupoCargado: boolean = false;
  public mensajeSinFechas: boolean = false;
  public numAlumnoSeleccionado: number = 0;
  public datafilter: ListaAlumnosGrupos[] = [];
  public listaAlumnos: ListaAlumnosGrupos[] = [];
  public informacionGrupo: InformacionGrupoAsistencia;
  public fechasDisponibles: FechaTomaAsistenciaDto[] = [];
  public fechaSeleccionada: FechaTomaAsistenciaDto;
  public indice: number;
  public indiceMaximo: number;
  public usuarioSesion: UsuarioIngreso;

  constructor(public dialogTomaAsistenciaRef: MatDialogRef<TomaAsistenciaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paginador: MatPaginatorIntl,
    private ensenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    private snackService: SnackService,
    public utileriaService: UtileriasService) { }

  ngOnInit(): void {
    this.obtenerUsuarioSesion();
    this.obtenerInfoGrupo(this.data);
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  public obtenerUsuarioSesion(): void {
    this.ensenanzaAprendizajeService.obtenerUsuarioConectado().subscribe(
      (usuario) => {
        this.usuarioSesion = usuario;
      }
    )
  }

  public obtenerInfoGrupo(grupoId: number): void {
    this.ensenanzaAprendizajeService.obtenerInfoGrupoAsistencia(grupoId).subscribe(
      (info: InformacionGrupoAsistencia) => {
        if (info !== null) {
          this.informacionGrupo = info;
          this.informacionGrupo.nombreProfesor = `${this.informacionGrupo?.profesor?.nombre ?? ''} ${this.informacionGrupo?.profesor?.apellidoPaterno ?? ''} ${this.informacionGrupo?.profesor?.apellidoMaterno ?? ''}`;
          this.obtenerFechasAsistencia(grupoId);
          this.infoGrupoCargado = true;
        } else {

        }
      });
  }

  public obtenerFechasAsistencia(grupoId: number): void {
    this.ensenanzaAprendizajeService.obtenerFechasTomaAsistencia(grupoId).subscribe(
      (fechas: ApiResponse<FechaTomaAsistenciaDto[]>) => {
        if (fechas.data.length > 0) {
          this.fechasDisponibles = fechas.data;
          this.fechaSeleccionada = this.fechasDisponibles[this.fechasDisponibles.length - 1];
          this.indice = this.fechasDisponibles.length - 1;
          this.indiceMaximo = this.fechasDisponibles.length - 1;
          this.obtenerAlumnosByGrupo(grupoId);
          this.fechaGrupoCargado = true;
        } else {
          this.mensajeSinFechas = true;
        }
      });
  }

  public obtenerAlumnosByGrupo(grupoId: number) {
    this.ensenanzaAprendizajeAlumnoService.obtenerAlumnos(grupoId).subscribe(
      (alumnos: ApiResponse<ListaAlumnosGrupos[]>) => {
        this.listaAlumnos = alumnos.data;
        this.asignarAsitenciaExistente();
      });
  }

  /**
   * Metodo que asigna la asistencia si ya ha sido tomada previomanete
   */
  public asignarAsitenciaExistente(): void {
    this.ensenanzaAprendizajeService.ObtenerGrupoAsistenciaAlumno(this.informacionGrupo.grupoId, this.fechaSeleccionada.fecha).subscribe(response => {
      if (response.exito) {
        if (response.data.length > 0) {
          let alumnosFalta: ListaAlumnosGrupos[] = response.data.filter(x => x.esFalta === false);
          this.listaAlumnos = response.data;
          this.dataSource = new MatTableDataSource(this.listaAlumnos);
          this.dataSource.paginator = this.paginator;
          this.selection = new SelectionModel<ListaAlumnosGrupos>(true, alumnosFalta);
          this.datafilter = this.listaAlumnos;
          this.infoCargado = true;
        } else {
          this.dataSource = new MatTableDataSource(this.listaAlumnos);
          this.dataSource.paginator = this.paginator;
          this.selection = new SelectionModel<ListaAlumnosGrupos>(true, []);
          this.datafilter = this.listaAlumnos;
          this.infoCargado = true;
        }
      }
    })
  }

  public ultimaFecha(): void {
    this.fechaSeleccionada = this.fechasDisponibles[this.fechasDisponibles.length - 1];
    this.indice = this.fechasDisponibles.length - 1;
    this.asignarAsitenciaExistente();
  }

  public siguienteFecha(): void {
    this.fechaSeleccionada = this.fechasDisponibles[this.indice + 1];
    this.indice = this.indice + 1;
    this.asignarAsitenciaExistente();
  }

  public anteriorFecha(): void {
    this.fechaSeleccionada = this.fechasDisponibles[this.indice - 1];
    this.indice = this.indice - 1;
    this.asignarAsitenciaExistente();
  }

  public enviarAsistencia(): void {
    let alumnosSinAsistencia = this.selection.selected;
    let alumnosLista = this.listaAlumnos;
    let alumnosConAsistencia = alumnosLista;
    for (var i = 0; i < alumnosSinAsistencia.length; i++) {
      alumnosConAsistencia = alumnosConAsistencia.filter(x => x.usuarioId !== alumnosSinAsistencia[i].usuarioId);
    }
    alumnosSinAsistencia = alumnosSinAsistencia.map(obj =>
    ({
      ...obj,
      EsFalta: true
    })
    );
    alumnosConAsistencia = alumnosConAsistencia.map(obj =>
    ({
      ...obj,
      EsFalta: false
    })
    );
    let alumnos = alumnosSinAsistencia.concat(alumnosConAsistencia);
    let datosAsistencia = {
      GrupoId: this.data,
      Fecha: this.fechaSeleccionada.fecha,
      Hora: this.fechaSeleccionada.hora.split(' ', 1)[0],
      UsuarioId: this.usuarioSesion.usuarioId,
      Alumnos: alumnos
    }
    this.ensenanzaAprendizajeService.enviarAsistencia(datosAsistencia).subscribe(
      (guardado: SimpleResponse) => {
        if (guardado.success) {
          this.snackService.mostrarSnackBack('Se ha guardado la asistencia correctamente.');
        } else {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la imagen. Por favor, intente de nuevo más tarde.');
        }
      });
  }

  ExcelSubscription: Subscription;
  public descargarExcel(): void {
    this.ExcelSubscription = this.ensenanzaAprendizajeService.descargarExcelAsistencia(this.listaAlumnos).subscribe((res: Blob) => {
      let filename: string = "Asistencia.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        let a: HTMLAnchorElement = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        let blob: Blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  isAllSelected(): boolean {
    this.numAlumnoSeleccionado = this.selection.selected.length;
    const numRows = this.datafilter.length;
    return this.numAlumnoSeleccionado === numRows;
  }

  checkboxLabel(row?: ListaAlumnosGrupos): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }

  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.datafilter.forEach(row => this.selection.select(row));
  }

  public cerrarModal(): void {
    this.dialogTomaAsistenciaRef.close();
  }

  ngOnDestroy(): void {
    if (this.ExcelSubscription != null) {
      this.ExcelSubscription.unsubscribe();
    }
  }
}
