import { Component, OnInit,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InscripcionService  } from 'src/app/_services/inscripcion.service';
import { UtileriasService } from '../../_services/utilerias.service';
import { configuracionInscripcion } from '../../_models/inscripcion'
import { ConfiguracionCreacionEdicionComponent } from './configuracion-creacion-edicion/configuracion-creacion-edicion.component'
import { SelectionModel } from '@angular/cdk/collections';
import { ConfiguracionEliminarComponent } from './configuracion-eliminar/configuracion-eliminar.component'
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Nivel } from '../../_models/nivel';
import { Campus } from '../../_models/campus';
import { CampusService } from '../../_services/campus.service';
import { NivelService } from '../../_services/nivel.service'
import { Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { Programa } from 'src/app/_models/programa';
import { ProgramaAcademicoService } from 'src/app/_services/programa-academico.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
@Component({
  selector: 'app-configuraciones',
  templateUrl: './configuraciones.component.html',
  styleUrls: ['./configuraciones.component.scss']
})
export class ConfiguracionesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select','campus','nivel','programa', 'ciclos','parciales', 'cargaMin', 'cargaMax'];
  public dataSource: MatTableDataSource<configuracionInscripcion>;
  public datafilter:any;
  selection = new SelectionModel<configuracionInscripcion>(true, []);
  public numIngresoSeleccionado: number = 0;
  public campusList: Campus[] = [];
  public nivelList: Nivel[] = [];
  public programaList: Programa[] = [];
  public subscriptionCampus: Subscription; 
  public subscriptionNivel: Subscription; 
  public subscritpitonPrograma: Subscription;
  public filtroForm: FormGroup;
  public arregloFiltroOriginal: any[];
  public datosEliminar: [];
  public campus: number;
  public programa: number;
  public nivel: number;
  public filtros: any[];

  constructor(public inscripcion: InscripcionService,
    public util: UtileriasService,
    private campusService: CampusService,
    private nivelService: NivelService,
    private programaService: ProgramaAcademicoService,
    private paginador: MatPaginatorIntl,) { }

  /**
   * Inicia el componente, el paginador, y obtiene los catalogos para los filtros
   */
  ngOnInit(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.cargarConfiguraciones();
    this.obtenerCampus();
    this.obtenerNivel();
    this.obtenerPrograma();
    this.inicializarForm();
  }

  /**
   * Inicia el formulario principal de los filtros
   */
  public inicializarForm(): void {
    this.filtroForm = new FormGroup({
      CampusId: new FormControl(0),
      NivelId: new FormControl(0),
      ProgramaId: new FormControl(0),
    });
  }

  /**
   * Seccion para los filtros que usa el formulario
   * filtra los datos segun el parametro que ingrese
   */
  public filtrar(): void {
    this.campus = this.filtroForm['value'].CampusId;
    this.nivel = this.filtroForm['value'].NivelId;
    this.programa = this.filtroForm['value'].ProgramaId;
    if(this.campus !=0 && this.nivel == 0 && this.programa == 0){
      this.filtros = this.arregloFiltroOriginal.filter(row => row.campusId === this.campus);
    }else if(this.campus == 0 && this.nivel != 0 && this.programa == 0){
      this.filtros = this.arregloFiltroOriginal.filter(row => row.nivelId === this.nivel);
    }else if(this.campus == 0 && this.nivel ==0 && this.programa != 0){
      this.filtros = this.arregloFiltroOriginal.filter(row => row.programaId === this.programa);
    }else if(this.campus !=0 && this.nivel !=0 && this.programa != 0){
      this.filtros = this.arregloFiltroOriginal.filter(row => row.campusId === this.campus && row.nivelId === this.nivel && row.programaId === this.programa);
    }else{
      this.filtros = this.arregloFiltroOriginal;
    }
    this.dataSource = new MatTableDataSource(this.filtros);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<configuracionInscripcion>(true, []);
  }

  /**
   * Entra al servicio y recibe los datos que se mostraran en la tabla principal  
   */
  public cargarConfiguraciones(): void{
    this.inscripcion.getConfiguraciones().subscribe((respuestaCarga: ApiResponse<any>)=>{
      this.arregloFiltroOriginal = respuestaCarga.data;
      this.dataSource = new MatTableDataSource(respuestaCarga.data);
       this.dataSource.paginator = this.paginator;
      this.datafilter = respuestaCarga.data;
    })
  }

  /**
   * Obtiene el catalogo de campus que seran cargadas en el select de campus
   */
  public obtenerCampus(): void {
    this.subscriptionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      }
    );
  }

  /**
   * Obtiene el catalogo de nivel que seran cargadas en el select de nivel
   */
  public obtenerNivel(): void {
    this.subscriptionNivel = this.nivelService.getNivel().subscribe(
      (nivel: Nivel[]) => {
        this.nivelList = nivel;
      }
    );
  }

  /**
   * Obtiene el catalogo de programa que seran cargadas en el select de programa
   */
  public obtenerPrograma(): void {
    this.subscritpitonPrograma = this.programaService.obtenerProgramas().subscribe(
      (programa:  Programa[])=>{
        this.programaList = programa;
      });
  }

  /**
   * Funcion para cargar una nueva configuracion, carga el componente ConfiguracionCreacionEdicionComponent como un modal
   */
  public crearConfiguracion(): void {
    const dialogo =this.util.abrirDialogoLateral(ConfiguracionCreacionEdicionComponent);
    dialogo.afterClosed().subscribe(result => {      
      this.cargarConfiguraciones();      
    });
  }

  /**
   * Funcion para editar una configuracion de la tabla, carga el componente ConfiguracionCreacionEdicionComponent como un modal
   * pasando los datos que tiene el seleccion 
   * @param configuracion 
   */
  public editarConfiguracion(configuracion: configuracionInscripcion[]): void {
    let configuracionSelecionada = configuracion[0];
    const dialogo= this.util.abrirDialogoLateral(ConfiguracionCreacionEdicionComponent, configuracionSelecionada);
    dialogo.afterClosed().subscribe(result => {      
         this.cargarConfiguraciones();    
         this.selection.clear();  
     });
  }

  /** 
   * Selecciona todas las columnas si no lo estan, o bien limpia la seleccion
   */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.datafilter.forEach(row => this.selection.select(row));
  }

  /**
   * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
   * seleccionados para modificar el check
   * @returns boolean para conocer si todos estan seleccionados
   */
  isAllSelected(): boolean {
    if(this.datafilter){
      this.numIngresoSeleccionado = this.selection.selected.length;
      const numRows = this.datafilter.length;
      return this.numIngresoSeleccionado === numRows;
    }   
  }

  /** 
   * La etiqueta de la casilla de verificación de la fila pasada
  */
  checkboxLabel(row?: configuracionInscripcion): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.CampusId + 1}`;
  }

  /**
   * Funcion que sirve para eliminar configuraciones y verifica si hay uno o varios segun la seleccion 
   * y abre el componente ConfiguracionEliminarComponent pasandole los datos a eliminar
   * @param btnDataTable 
   * @param elemento 
   */
  public borrar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      this.datosEliminar = elemento;
    } else {
      if (elemento.length === 1) {
        this.datosEliminar = elemento[0];
      } else {
        this.datosEliminar = elemento;
      }
    }
    const dialogo = this.util.abrirDialogoLateral(ConfiguracionEliminarComponent, this.datosEliminar);
     dialogo.afterClosed().subscribe(result => {
       this.cargarConfiguraciones();
       this.selection.clear();
     });
  }
  
  /**
   * Funcion que descarga un archivo excel con las configuraciones seleccionadas
   */
  ExcelSubscription: Subscription;

  public descargarExcel(elemento?: any) {
    if(elemento != null && elemento.length > 0) {
      this.ExcelSubscription = this.inscripcion.descargarExcelInscripcion(elemento).subscribe((res: Blob) => {
        let filename: string = "Carga-Inscripcion.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
          window.navigator.msSaveOrOpenBlob(res, filename);
        } else {
          let a: HTMLAnchorElement = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          let blob: Blob = new Blob([res], { type: 'application/xlsx' });
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        }
        this.ExcelSubscription.unsubscribe();
      });
    } else {
      this.ExcelSubscription = this.inscripcion.descargarExcelInscripcion(this.datafilter).subscribe((res: Blob) => {
        let filename: string = "Carga-Inscripcion.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
          window.navigator.msSaveOrOpenBlob(res, filename);
        } else {
          let a: HTMLAnchorElement = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          let blob: Blob = new Blob([res], { type: 'application/xlsx' });
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        }
        this.ExcelSubscription.unsubscribe();
      });
    }
  }
}
