import { ModalCuponComponent } from './../modal-cupon/modal-cupon.component';
import { CouponDto } from './../../_models/CouponDto';
import { ApiResponse } from './../../models/api/ApiRespose.model';
import { CuponService } from './../../_services/cupon.service';
import { AccionEnum } from './../../_models/accion.enum';
import { UtileriasService } from './../../_services/utilerias.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ModalEliminarCuponComponent } from '../modal-eliminar-cupon/modal-eliminar-cupon.component';

@Component({
  selector: 'app-cupon-seguimiento',
  templateUrl: './cupon-seguimiento.component.html',
  styleUrls: ['./cupon-seguimiento.component.scss']
})
export class CuponSeguimientoComponent implements OnInit, OnDestroy {

  //Variables Tabla seguimiento
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns: string[] = ['select', 'code', 'name', 'startDate', 'endDate', 'totalUses', 'cuponTotalUses', 'userCouponTotalUses', 'lastUseDate', 'status'];
  public dataSource: MatTableDataSource<CouponDto>;
  public selection = new SelectionModel<CouponDto>(true, []);
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variables Filtro
  public codeName = new FormControl();
  private arrOriginal: any[] = [];
  //Varariable Lista Cupones
  public ListaCuponesSeguimiento: CouponDto[] = [];
  //Variables select
  public numCuponesSeleccionado: number = 0;
  //Variable bandera info cargada
  public infoCargado = false;
  //Variables descargar Excel
  public ExcelSubscription: Subscription;

  constructor(private cuponService: CuponService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    public utileriaService: UtileriasService,
  ) { }

  ngOnInit() {
    if (this.cuponService.invocarObtenerCuponesSubscription === undefined) {
      this.cuponService.invocarObtenerCuponesSubscription = this.cuponService.invocarObtenerCupones.subscribe(() => {
        this.obtenerCupon();
      });
    }
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    this.obtenerCupon();
  }

  /**
   * Obtener los cupones de la tabla de seguimiento
   */
  public obtenerCupon() {
    this.subscription.add(
      this.cuponService.cuponSeguimiento().subscribe((response: ApiResponse<CouponDto[]>) => {
        this.ListaCuponesSeguimiento = response.data;
        this.dataSource = new MatTableDataSource(this.ListaCuponesSeguimiento);
        this.selection = new SelectionModel<any>(true, []);
        this.dataSource.paginator = this.paginator;
        this.infoCargado = true;
        this.arrOriginal = [...this.ListaCuponesSeguimiento];
      })
    );
  }

  /**
   * Metodo Crear cupon
   */
  public crearCupon(): void {
    let datosCupon: CouponDto = new CouponDto();
    datosCupon.tipoAccion = AccionEnum.CREAR;
    datosCupon.startDate = null;
    datosCupon.endDate = null;
    this.utileriaService.abrirDialogoLateral(ModalCuponComponent, datosCupon);
  }

  /**
   * Metodo Editar cupon
   * @param elemento 
   */
  public editarCupon(elemento: CouponDto): void {
    let datosCupon: CouponDto = Object.assign(elemento);
    datosCupon.tipoAccion = AccionEnum.EDITAR;
    this.utileriaService.abrirDialogoLateral(ModalCuponComponent, datosCupon);
  }

  /**
   * Metodo Para eliminar un cupon baja logica 
   * @param cupon 
   */
  public eliminarCupon(cupon?: any) {
    if (cupon.length === 1) {
      var datos = cupon[0];
    } else {
      var datos = cupon;
    }
    const dialogEliminar = this.dialog.open(ModalEliminarCuponComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
    dialogEliminar.afterClosed().subscribe(result => {
      if (result) {
        this.selection = new SelectionModel<CouponDto>(true, []);
      }
    });
  }

  /**
   * Metodo para filtrar
   */
  public filtro() {
    let codeName: string = this.codeName === null ? '' : this.codeName.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    filtroModificado = filtroModificado.filter(f => {
      return f.code.toLowerCase().search(codeName.toLocaleLowerCase()) > -1;
    });
    this.dataSource = new MatTableDataSource(filtroModificado);
  }

  /**
   * Metodo para limpiar los filtros
   */
  limpiarFiltros() {
    this.codeName.setValue('');
    this.obtenerCupon();
  }

  /**
   * Metodo de select
   * @returns 
   */
  isAllSelected() {
    this.numCuponesSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numCuponesSeleccionado === numRows;
  }

  /**
   * Metodo de select
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.code + 1}`;
  }

  /**
   * Metodo de select
   */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row))
  }

  /**
   * Descarga de Excel de cupones
   */
  public descargarExcel() {
    this.ExcelSubscription = this.cuponService.downloadExcelCupon(this.arrOriginal).subscribe((res: Blob) => {
      var filename = "Cupones.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
