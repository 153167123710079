import { Component, OnInit, ViewChild, Inject, OnDestroy, TemplateRef, Input } from '@angular/core';
import { AccionEnum } from '../../_models/accion.enum';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { Programa } from '../../_models/programa';
import { Ciclo } from '../../_models/ciclo';
import { CicloService } from '../../_services/ciclo.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-programa-academico-modal-agregar-ciclo',
  templateUrl: './programa-academico-modal-agregar-ciclo.component.html',
  styleUrls: ['./programa-academico-modal-agregar-ciclo.component.scss']
})
export class ProgramaAcademicoModalAgregarCicloComponent implements OnInit, OnDestroy {

  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  @Input() cicloId: number;
  public ciloForm: FormGroup;
  public datosFormCiclo: Ciclo;
  public boolCambio: boolean = false;
  public dialogoRefAdver: any;
  public idPrograma$: Observable<number>;
  public idPrograma: number;
  public idProgramaSubscription: Subscription;
  public CLAVEencontrado: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Programa,
    public dialogCicloRef: MatDialogRef<ProgramaAcademicoModalAgregarCicloComponent>,
    private dialog: MatDialog,
    private programaAcademicoService: ProgramaAcademicoService,
    private cicloService: CicloService,
    private snackBar: MatSnackBar) { }

  //Validar si la CLAVE ya esta asociado
  public CLAVEInsert(clave: string) {
    if (clave.trim().length == 11) {
      let cicloId = this.cicloId;
      if (!cicloId) {
        cicloId = 0;
      }

      this.cicloService.obtenerCicloByClave(clave, cicloId).subscribe(
        ciclo => {
          if (ciclo == null) {
            return this.CLAVEencontrado = false;
          } else {
            return this.CLAVEencontrado = true;
          }
        });
      console.log("Ver mensaje", clave);
    }
  }

  ngOnInit(): void {
    this.idPrograma$ = this.programaAcademicoService.obtenerIdPrograma$();
    this.idProgramaSubscription = this.idPrograma$.subscribe(id => this.idPrograma = id);
    this.inicializarFrom();
  }

  public inicializarFrom() {
    this.ciloForm = new FormGroup({
      clave: new FormControl('', [Validators.required]),
      nombre: new FormControl('', [Validators.required]),
      esOficial: new FormControl(true, [Validators.required]),
    });

    this.ciloForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
    });
  }

  get clave() { return this.ciloForm.get('clave'); }
  get nombre() { return this.ciloForm.get('nombre'); }
  get esOficial() { return this.ciloForm.get('esOficial'); }

  public enviar() {
    /*let ciloForm = Object.assign(this.ciloForm.value);
    let ciclo: any = {
      cicloId: ciloForm.cicloId,
      clave: ciloForm.clave,
      nombre: ciloForm.nombre,
      esOficial: ciloForm.esOficial
    }
    this.cicloService.obtenerCicloByClave(ciloForm.clave, ciloForm.cicloId).subscribe(
      ocupado => {
        if (ocupado != null) {
          this.snackBar.open("Se ha guardado correctamente el ciclo.", "OK", { duration: 7000 });
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar un ciclo. La Clave ingresado ya esta registrado, ingrese una Clave diferente.", "OK", { duration: 7000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al guardar un ciclo. La Clave ingresado ya esta registrado, ingrese una Clave diferente.", "OK", { duration: 7000 });
      }
    )*/

    this.datosFormCiclo = Object.assign(this.ciloForm.value);
    if (this.data?.tipoAccion === AccionEnum.EDITAR) {
      this.datosFormCiclo.programaId = this.data?.programaId;
    } else if (this.data?.tipoAccion === AccionEnum.CREAR) {
      this.datosFormCiclo.programaId = this.idPrograma;
    }
    this.dialogCicloRef.close(this.datosFormCiclo);
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.dialogCicloRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogCicloRef.close(null);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  ngOnDestroy() {
    if (this.idProgramaSubscription) {
      this.idProgramaSubscription.unsubscribe();
    }
  }

}
