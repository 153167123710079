import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfiguracionCuotaService } from 'src/app/_services/configuracion-cuota.service';


@Component({
  selector: 'app-modal-eliminar-servicio',
  templateUrl: './modal-eliminar-servicio.component.html',
  styleUrls: ['./modal-eliminar-servicio.component.scss']
})
export class ModalEliminarServicioComponent implements OnInit {
  public numServiciosSeleccionados = 0;
  public boolIsArray = false;
  public claveServicios = '';

  constructor( public dialogRef: MatDialogRef<ModalEliminarServicioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private servicios: ConfiguracionCuotaService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numServiciosSeleccionados = this.data.length;
    }
    console.log('Data de eliminar', this.data);
    /*
        if(this.data.length === 1){
      this.boolIsArray = false;
    } else {
      this.boolIsArray = true;
      this.numCicloSeleccionados = this.data.length;
    }
    */
  }

  public eliminar() {
    if(this.boolIsArray){
      let arrayClaves = this.data.map(x => (x.servicioId.toString()));
      this.claveServicios = arrayClaves.toString();
    } else {
      this.claveServicios = this.data.servicioId.toString();
    }
    
    this.servicios.eliminarTipoServicio(this.claveServicios).subscribe(
      eliminado => {
        if(eliminado){
          this.dialogRef.close();
          this.snackBar.open('Se ha eliminado el servicio.', 'OK', { duration: 5000 });
          this.servicios.metodoObtenerTipoServicio();
        } else {
          this.snackBar.open('Ha ocurrido un error al eliminar el servicio. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
        }
      }, 
      error => {
        this.snackBar.open('Ha ocurrido un error al eliminar el servicio. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    );
  }

}
