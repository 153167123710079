import { FORM_VALID_MODEL } from './../../../../core.domain/models/user/form-valid-model';
import { SnackService } from 'src/app/services/snack-service.service';
import { TraductorService } from '@app/_services/traductor.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { EmitterSaveActionService } from '@app/clean-architecture/infrastructure/services/common/emitter-save-action.service';
import { User } from './../../../../core.domain/models/user/user-data-model';
import { AdminAcademicaService } from './../../../../../_services/admin-academica.service';
import { Component, OnInit, OnDestroy, Output, Input, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UserDataBase } from './user-data.base';
import { BiografiaService } from '../../../../../_services/biografia.service';

@Component({
  selector: 'app-user-data-form',
  templateUrl: './user-data-form.component.html',
  styleUrls: ['./user-data-form.component.scss']
})
export class UserDataFormComponent extends UserDataBase implements OnInit, OnChanges, OnDestroy {

  //#region  Input, Output & ViewChild  
  @Input() identificador: string;

  @Input() tabId: number;
  @Input() tabIndex: number;
  @Output() formValid = new EventEmitter<boolean>();
  //#endregion

  //#region Properties
  private isValid: boolean = false;
  private FORM_VALID: FORM_VALID_MODEL[] = [];
  //#endregion

  constructor(
    override _adminAcademicaService: AdminAcademicaService,
    override _EmitterSaveActionService : EmitterSaveActionService,
    override  nuevoIngresoService: NuevoIngresoService,
    override snackService: SnackService,
    override traductorService: TraductorService,
    override biografiaService: BiografiaService,
  ) { 
    super(
      _adminAcademicaService,
      _EmitterSaveActionService,
      nuevoIngresoService,
      snackService,
      traductorService,
      biografiaService
    );
    this.Subscription.add(
      _EmitterSaveActionService.$emitSaveTab.subscribe((tabIndex: number) => {
        if (tabIndex === this.tabId) {        
          this.save();
        }
      })
    );
  }

  ngOnInit(): void {    
    this._getAllUserInformation(this.identificador);
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabIndex']) {
      changes['tabIndex'].currentValue === this.tabId ? this.Emit() : null;
    }
  }
  
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  public _getFormIsValid(FORM_VALID_MODEL: FORM_VALID_MODEL):void
  { 
    this.FORM_VALID = this.FORM_VALID.filter(x => x.FormId !== FORM_VALID_MODEL.FormId)
    this.FORM_VALID.push(FORM_VALID_MODEL);
    this.isValid = this.FORM_VALID.every(x => x.IsValid);     
    this.Emit();
  
  }

  private Emit():void
  {
    this.formValid.emit(this.isValid);  
  }

}
