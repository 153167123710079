import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuarioService } from '@app/_services/usuario.service';
import { LoadingScreenService } from '@app/_services/loading-screen.service';
import { Usuario } from '@app/_models/usuario';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EmergenciaService } from '../_services/emergencia.service';
import { IGeneros } from './Generos';
import { IPais } from './Pais';
import { IEstadoCivil } from './EstadoCivil';
import { IEstados } from './Estados';
import { Observable } from 'rxjs';
import { UsuarioEdicionService } from '@app/_services/usuario-edicion.service';


@Component({
  selector: 'app-usuario-edicion',
  templateUrl: './usuario-edicion.component.html',
  styleUrls: ['./usuario-edicion.component.scss']
})

export class UsuarioEdicionComponent {
  //public personaList: PersonaList[];
  emergenciaForm: FormGroup;

  //Mio Héctor Soria
  //lstSexoLegal
  public lstSexoLegal: Observable<IGeneros[]>;
  //lstGenero
  public lstGenero: Observable<IGeneros[]>;
  //lstCiudadania
  public lstCiudadania: Observable<IPais[]>;
  //lstEstadoCivil
  public lstEstadoCivil: Observable<IEstadoCivil[]>;
  //lstLugarNacimiento
  public lstLugarNacimiento: Observable<IEstados[]>;
  //lstPais
  public lstPais: Observable<IPais[]>;
  //lstEstado
  public lstEstado: Observable<IEstados[]>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: number,
        private _http: HttpClient,
        //private _emergenciaService: EmergenciaService,
        private _fb: FormBuilder,
        private _avRoute: ActivatedRoute,
        private _router: Router,
        private loadingService: LoadingScreenService,
      private usuarioService: UsuarioService,


      private formBuilder: FormBuilder,
      private usuarioservices: UsuarioEdicionService
    ){
   // this.getPersonas();


      //Héctor Soria
      //Gest´s sexo, genero, ciudadania, estado, pais, estado. . .
      this.GetSexo();
      this.GetGenero();
      this.GetCiudadania();
      this.GetEstadoCivil();
      this.GetLugarNacimiento();
      this.GetPais();
      this.GetEstado();

      this.usuarioId = this.data;

      this.usuarioService.obtenerUsuario(this.usuarioId).subscribe(
        usuario => {
          this.usuario = usuario;
          this.usuarioForm.patchValue(usuario);
        }
      );
  }

  private usuarioId: number;

  public usuario: Usuario;
  public errorActualizaUsuario: string = "";

  public usuarioForm: FormGroup = new FormGroup({
    correo: new FormControl('', [Validators.required, Validators.email]),
    nombre: new FormControl('', [Validators.required]),
    segundoNombre: new FormControl(''),
    apellidoPaterno: new FormControl('', [Validators.required]),
    apellidoMaterno: new FormControl(''),
    fechaNacimiento: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    passwordConfirm: new FormControl('', [Validators.required]),
    razonSocial: new FormControl('', []),
    nombreContactoEmergencia: new FormControl('', [Validators.required]),
    telefonoContactoEmergencia: new FormControl('', [Validators.required]),
    correoContactoEmergencia: new FormControl('', [Validators.required]),
    grupoSanguineo: new FormControl('', []),
    alergias: new FormControl('', []),
    enfermedades: new FormControl('', [])
  });

  //Consulta Get's
  public GetSexo() {
    this.lstSexoLegal = this.usuarioservices.getSexoLegal();
  }
  public GetGenero() {
    this.lstGenero = this.usuarioservices.getGenero();
  }
  public GetCiudadania() {
    this.lstCiudadania = this.usuarioservices.getCiudadania();
  }
  public GetEstadoCivil() {
    this.lstEstadoCivil = this.usuarioservices.getEstadoCivil();
  }
  public GetLugarNacimiento() {
    this.lstLugarNacimiento = this.usuarioservices.getNacimiento();
  }
  public GetPais() {
    this.lstPais = this.usuarioservices.getPais();
  }
  public GetEstado() {
    this.lstEstado = this.usuarioservices.getEstado();
  }
 //Héctor Soria
  //Get UserForm
  get difunto() {
    return this.UserForm.get('difunto');
  }
  get sexo() {
    return this.UserForm.get('sexo');
  }
  get genero() {
    return this.UserForm.get('genero');
  }
  get ciudadania() {
    return this.UserForm.get('ciudadania');
  }
  get civil() {
    return this.UserForm.get('civil');
  }
  get social() {
    return this.UserForm.get('social');
  }
  get curp() {
    return this.UserForm.get('curp');
  }
  get lugarnacimiento() {
    return this.UserForm.get('lugarnacimiento');
  }
  get pais() {
    return this.UserForm.get('pais');
  }
  get estado() {
    return this.UserForm.get('estado');
  }
  get ciudad() {
    return this.UserForm.get('ciudad');
  }

  get correo() { return this.usuarioForm.get('correo'); }
  get nombre() { return this.usuarioForm.get('nombre'); }
  get segundoNombre() { return this.usuarioForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.usuarioForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.usuarioForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.usuarioForm.get('fechaNacimiento'); }
  get razonSocial() { return this.usuarioForm.get('razonSocial'); }
  get nombreContactoEmergencia() { return this.usuarioForm.get('nombreContactoEmergencia'); }
  get telefonoContactoEmergencia() { return this.usuarioForm.get('telefonoContactoEmergencia'); }
  get correoContactoEmergencia() { return this.usuarioForm.get('correoContactoEmergencia'); }
  get grupoSanguineo() { return this.usuarioForm.get('grupoSanguineo'); }
  get alergias() { return this.usuarioForm.get('alergias'); }
  get enfermedades() { return this.usuarioForm.get('enfermedades'); }

  //FormGroup de UsuarioEdicion
  UserForm = this.formBuilder.group({
    difunto:  false,
    sexo: ['', Validators.required],
    genero: ['', Validators.required],
    ciudadania: ['', Validators.required],
    civil: ['', Validators.required],
    social: ['', Validators.required],
    curp: ['', Validators.required],
    lugarnacimiento: ['', Validators.required],
    pais: ['', Validators.required],
    estado: ['', Validators.required],
    ciudad: ['', Validators.required]
  });

  /*
  getPersonas() {
    this._emergenciaService.getPersonas().subscribe(
      data => this.personaList = data
    )
    console.log(this.personaList);
  }
  */

    /*
    this.usuarioId = parseInt(this._avRoute.snapshot.paramMap.get('id'));*/

  /*
    DeletePersona(UsuarioID: number) {
      var res = confirm("¿Quiere eliminar a esta persona?");
      if (res) {
        this._emergenciaService.deletePersona(UsuarioID).subscribe((data) => {
          this.getPersonas();
        }, error => console.error(error))
      }
    }

*/
  public enviar(): void {
    
  }

  //Método de guardado
  guardar() {
    this.usuarioservices.UsuarioEdicion(
      this.difunto.value, this.sexo.value, this.genero.value,
      this.ciudadania.value, this.civil.value, this.social.value,
      this.curp.value, this.lugarnacimiento.value, this.pais.value,
      this.estado.value, this.ciudad.value).subscribe(result => {
        console.log(result);
      },
        error => console.error(error)
      );

    if (!this.UserForm.valid) {
      alert('Falta un campo por llenar');
      return;
    }
    console.log(this.UserForm.value);
  }

  //Método de cancelar el formulario
  cancelar() {
    this.UserForm.patchValue({
      dufunto: '',
      sexo: '',
      genero: '',
      ciudadania: '',
      civil: '',
      social: '',
      curp: '',
      lugarnacimiento: '',
      pais: '',
      estado: '',
      ciudad: ''
    });
  }
}
