import { SimpleResponse } from './../models/api/SimpleResponse.model';
import { HttpClient } from '@angular/common/http';
import { StudentChangeDTO, StudentChangePost } from './../_models/StudentChangeDTO';
import { baseUrl, environment } from './../../environments/environment';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentChangeService {

  constructor(private httpClient: HttpClient) { }


   //#region GetStudentChangedsByAlumnoId
   /**
   * Obtiene la lista de StudentChangeds by AlumnoId
   * @param alumnoId
   * @returns
   */
    public GetStudentChangedsByAlumnoId(alumnoId: number): Observable<ApiResponse<StudentChangeDTO[]>> {
      return this.httpClient.get<ApiResponse<StudentChangeDTO[]>>(`${environment.enves[baseUrl].apiUrl}/StudentChange/GetStudentChangedsByAlumnoId/${alumnoId}`);
    }
   //#endregion

   //#region CambioProgramaAcademico
   /**
   * Agrega un registro en tabla  StudentChange
   * @param alumnoId
   * @returns
   */
    public CambioProgramaAcademico(StudentChangePost: StudentChangePost): Observable<SimpleResponse> {
      return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/StudentChange/CambioProgramaAcademico`, StudentChangePost);
    }
   //#endregion


   //#region RecargarDatos del modal header
  public AlumnoId:number;
  invocarObtenerContenido = new EventEmitter();
  invocarObtenerContenidoSubscription: Subscription;
   //Invocar Metodo obtener info de Alumnos
   public invocarMetodoObtenerContenido() {
    this.invocarObtenerContenido.emit();
  }

  /**
   * Obtiene todas la inscripciones segun el tipo de alumno NI: Nuevo Ingreso o RI: Reingreso
   * @param alumnoId
   * @returns
   */
   public GetAlumnobyAlumno(alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/AdminAcademica/GetAlumnobyAlumno/` + alumnoId);
  }
  //#endregion

}
