import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import { CuestionarioArea, CuestionarioRespuestaDto, FechaTomaAsistenciaDto, InfoPorRevisarDto, InformacionGrupoAsistencia, ListaAlumnosGrupos, RecursoAlumnoArchivo, Seccion, TipoPreguntaCuestionario } from '../_models/ensenanza-aprendizaje-profesor';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { CalificacionNoNumerica } from '../_models/calificacion-no-numerica';
import { Ciclo } from '../_models/ciclo';
import { MateriaOferta } from '../_models/materia-oferta';
import { GrupoPeriodoDto } from '../_models/grupo-periodo-dto';
import { Materia } from '../_models/materia';
import { RecursoAdjunto } from '../_models/recurso-adjunto';
import { Recurso } from '../_models/recurso';
import { TipoRecurso } from '../_models/tipo-recurso';
import { Grupo } from '../_models/grupo';
import { TraductorService } from './traductor.service';

@Injectable({
  providedIn: 'root'
})
export class EnsenanzaAprendizajeService {
  public clave$: any;
  public estructuraExamen$: Seccion[];
  invocarObtenerGrupos = new EventEmitter();
  private grupoSeleccionado$ = new Subject<any>();

  invocarObtenerInfoGrupo = new EventEmitter();
  obtenerInfoGrupoSubscription: Subscription;

  invocarObtenerArchivos = new EventEmitter();
  invocarObtenerArchivoSubscription: Subscription;

  invocarObtenerContenido = new EventEmitter();
  invocarObtenerContenidoSubscription: Subscription;

  invocarObtenerPorRevisar = new EventEmitter();
  invocarObtenerPorRevisarSubscription: Subscription;

  invocarObtenerEstructuraEmit = new EventEmitter();
  metodoObtenerEstructuraSubscription: Subscription;
  //traer el parcial1 en calificaciones
  invocarObtenerParcial1Emit = new EventEmitter();
  metodoObtenerParcial1Subscription: Subscription;
  //regresar a tablero Cursos
  invocarTableroCursos = new EventEmitter();
  invocarMetodoTableroCursosSubscription: Subscription;

  private messageSource = new BehaviorSubject(true);

  currentMessage = this.messageSource.asObservable();

  changeMessage(message: boolean) {
    this.messageSource.next(message);
  }

  constructor(private httpClient: HttpClient,
    private traductorService: TraductorService) { }

  public definirClaveMateria(clave: number) {
    this.clave$ = clave;
  }

  public definirEstructuraExamen(estructuraExamen: Seccion[]) {
    this.estructuraExamen$ = estructuraExamen;
  }

  //Invocar Metodo obtenerTipoApoyoFinanciero
  invocarMetodoObtenerEstructura() {
    this.invocarObtenerEstructuraEmit.emit();
  }

  /**
   * Obtiene las materias completas sin ningun parametro
   * @returns
   */
  public obtenerMateriasOferta(): Observable<ApiResponse<MateriaOferta[]>> {
    return this.httpClient.get<ApiResponse<MateriaOferta[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetMaterias`);
  }

  /**
   * Metodo para saber si la encuesta fue contestada o no
   * @param recursoId 
   * @param grupoId 
   * @returns 
   */
  public encuestaContestada(recursoId: number, grupoId: number): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/EncuestaContestada/${recursoId}/${grupoId}`);
  }

  //Get Periodo-grupo
  public obtenerPeriodosByProfesorId(): Observable<GrupoPeriodoDto[]> {
    return this.httpClient.get<GrupoPeriodoDto[]>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetPeriodosByProfesorId`);
  }
  //Get MateriaOferta
  public obtenerMateriaOfertaAgrupada(periodosId: number): Observable<ApiResponse<Materia[]>> {
    return this.httpClient.get<ApiResponse<Materia[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetMateriabyPeriodo?periodosId=${periodosId}`);
  }

  //Get MateriaOferta
  public obtenerMateriaOfertaSinFecha(): Observable<any> {
    return this.httpClient.get<any[]>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetMateriabyPeriodoGrupoSinFecha`);
  }

  /**
   * Obtiene los grupos según el usuario
   * @param idUsuario
   * @returns
   */
  public obtenerGruposUsuario(idUsuario: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/?usuarioId=${idUsuario}`);
  }

  public obtenerGruposProfesor(clave: string, periodoId: string, idioma: string): Observable<any> {
    return this.httpClient.get<any[]>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetMateriasProfesor?clave=${clave}&periodoId=${periodoId}&idioma=${idioma}`);
  }

  public obtenerGrupoProfesorByGrupoId(grupoId: number, idioma: string): Observable<any> {
    return this.httpClient.get<any[]>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetMateriaProfesorByGrupoId?grupoId=${grupoId}&idioma=${idioma}`);
  }

  public obtenerCardsGruposProfesor(idPeriodo: number): Observable<any> {
    return this.httpClient.get<any[]>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetListaMateriasPeriodo?idPeriodo=${idPeriodo}`);
  }
  //GetConjuntoGrupoProfesor
  public obtenerConjuntoPeriodos(periodosId: string, idioma: string): Observable<any> {
    return this.httpClient.get<any[]>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetConjuntoGrupoProfesor?periodosId=${periodosId}&idioma=${idioma}`);
  }
  //Get Parciales
  public obtenerParcialesProfesor(clave: string, grupoId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetNumeroParcialesProfesor/${clave}/${grupoId}`);
  }
  //Get grupo seleccionado Observable
  public obtenerGrupoSeleccionado$(): Observable<any> {
    return this.grupoSeleccionado$.asObservable();
  }

  /**
   * Obtiene todos los recursos que previamente se han cargado
   * @param recursoAdjuntoId
   * @returns
   */
  public obtenerRecursoAdjunto(recursoAdjuntoId): Observable<ApiResponse<RecursoAdjunto>> {
    return this.httpClient.get<ApiResponse<RecursoAdjunto>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetRecursoAdjunto/${recursoAdjuntoId}`);
  }
  // Set grupo seleccionado
  public definirGrupoSeleccionado(datosUsuario: any) {
    this.grupoSeleccionado$.next(datosUsuario);
  }
  //Invocar Metodo obtenerGrupoUsuario
  invocarMetodoObtenerGrupos() {
    this.invocarObtenerGrupos.emit();
  }

  /**
   * Obtiene los recursos necesarios para el parcial
   * @param grupoId
   * @returns
   */
  public obtenerParcialesRecursos(grupoId: number): Observable<ApiResponse<Recurso[]>> {
    return this.httpClient.get<ApiResponse<Recurso[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetParcialesRecursos/${grupoId}`);
  }



  public obtenerContenido(grupoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetParcialesContenido/${grupoId}`);
  }

  ////////////////////////////////////Servicios para recursos///////////////////////////////////////////

  /**
   * Obtiene un arreglo con los tipos de recursos
   */
  public listaRecursos(): Observable<ApiResponse<TipoRecurso[]>> {
    return this.httpClient.get<ApiResponse<TipoRecurso[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetTipoRecurso`);
  }

  //estos servicios van a modificarse
  public ingresarRecurso(idModulo: number, data: any): Observable<any> {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/PostRecurso_Contenido/${idModulo}`, data);
  }
  public subirArchivoEnlace(recursoAdjuntos: Array<any>): Observable<any> {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/PostRecursoAdjunto/`, recursoAdjuntos);
  }
  //cuestionarios
  public PostCuestionarioArea(idRecurso: number, data: Array<any>): Observable<any> {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/PostCuestionarioArea/${idRecurso}`, data);
  }
  public PostTemaModulo(data: any): Observable<any> {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/PostTema_Modulo/`, data);
  }
  public PutTemaModulo(idGrupo: number, idModulo: number, data: any): Observable<any> {
    return this.httpClient.put(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/PutTema_Modulo/${idGrupo}/${idModulo}`, data);
  }
  public PutRecursoTareaContenido(idRecurso: number, data: any): Observable<any> {
    return this.httpClient.put(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/PutRecursoTareaContenido/${idRecurso}`, data);
  }

  //GetInfoGrupoAsistencia
  public obtenerInfoGrupoAsistencia(grupoId: number): Observable<InformacionGrupoAsistencia> {
    return this.httpClient.get<InformacionGrupoAsistencia>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetInfoGrupoAsistencia/${grupoId}`);
  }

  //GetFechasTomaAsistencia
  public obtenerFechasTomaAsistencia(grupoId: number): Observable<ApiResponse<FechaTomaAsistenciaDto[]>> {
    return this.httpClient.get<ApiResponse<FechaTomaAsistenciaDto[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetFechasTomaAsistencia/${grupoId}`);
  }

  //PostAsistencia
  public enviarAsistencia(alumnos: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/PostAsistencia/`, alumnos);
  }

  //OBTENER USUARIO CONECTADO
  public obtenerUsuarioConectado(): Observable<any> {
    return this.httpClient.get(`${environment.enves[baseUrl].apiUrl}/Usuario/Me/`);
  }

  //Download Excel Asistencia
  public descargarExcelAsistencia(alumnosAsitencia: ListaAlumnosGrupos[]) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/DownloadExcelAsistencia`, JSON.stringify(alumnosAsitencia),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  //Download Excel Mi Grupo
  public descargarExcelMiGrupo(alumnosAsitencia: ListaAlumnosGrupos[]) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/DownloadExcelMiGrupo`, JSON.stringify(alumnosAsitencia),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  //Download Excel Por Revisar
  public descargarExcelPorRevisar(alumnosAsitencia: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/DownloadExcelPorRevisar`, JSON.stringify(alumnosAsitencia),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  /**
   * Obtiene la informacion para descargar excel
   * @param recursoId 
   * @param grupoId 
   * @returns 
   */
  public obtenerExcelEncuesta(recursoId: number, grupoId: number): Observable<ApiResponse<any[]>> {
    return this.httpClient.get<ApiResponse<any[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/ObtenerExcelEncuesta/${recursoId}/${grupoId}`);
  }

  //Download Excel Encuesta
  public descargarExcelEncuesta(encuesta: any[]) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/DownloadExcelEncuesta`, JSON.stringify(encuesta),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  //Download Excel Consultar Calificaciones
  public descargarExcelConsultarCalificaciones(ultimoParcial: number, calificacionConsultar: InfoPorRevisarDto[]) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/DownloadExcelConsultarCalificaciones/${ultimoParcial}`, calificacionConsultar,
      {
        responseType: "blob"
      });
  }

  /**
   * Obtiene la informacion pendiente a revisar de los alumnos mediante el grupoId
   * @param grupoId
   * @returns
   */
  public obtenerInformacionRecursos(grupoId: number): Observable<ApiResponse<InfoPorRevisarDto[]>> {
    return this.httpClient.get<ApiResponse<InfoPorRevisarDto[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetInfoPorRevisar/${grupoId}`);
  }

  /**
   * Obtiene la informacion de un recurso especifico a revisar de los alumnos mediante el recursoid
   * @param grupoId
   * @returns
   */
  public informacionRecursoByRecursoId(recursoId: number, grupoId: number): Observable<ApiResponse<InfoPorRevisarDto[]>> {
    return this.httpClient.get<ApiResponse<InfoPorRevisarDto[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/InformacionRecursoByRecursoId/${recursoId}`);
  }

  /**
   * Obtiene los recursos de los alumnos mediante el grupo y el recurso
   * @param grupoId
   * @param recursoId
   * @returns
   */
  public obtenerAlumnosRecurso(grupoId: number, recursoId: number): Observable<ApiResponse<ListaAlumnosGrupos[]>> {
    return this.httpClient.get<ApiResponse<ListaAlumnosGrupos[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetAlumnosRecurso/${grupoId}/${recursoId}`);
  }

  /**
   * Obtiene los archivos del alumno
   * @param alumnoId
   * @param recursoId
   * @returns
   */
  public obtenerAlumnoArchivo(alumnoId: number, recursoId: number): Observable<ApiResponse<RecursoAlumnoArchivo>> {
    return this.httpClient.get<ApiResponse<RecursoAlumnoArchivo>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetRecursoAlumnoArchivo/${alumnoId}/${recursoId}`);
  }

  /**
   * Obtiene el documento
   * @param documentoId
   * @returns
   */
  public obtenerDocumentoRecurso(documentoId: number): Observable<ApiResponse<RecursoAlumnoArchivo>> {
    return this.httpClient.get<ApiResponse<RecursoAlumnoArchivo>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetDocumentoRecurso/${documentoId}`);
  }

  /**
   * Obtiene el resultado que el alumno obtuvo en su examen
   * @param recursoId
   * @returns
   */
  public obtenerExamenEncuesta(recursoId: number): Observable<ApiResponse<CuestionarioArea[]>> {
    return this.httpClient.get<ApiResponse<CuestionarioArea[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetExamenResultado/${recursoId}`);
  }

  /**
   * Obtiene las respuestas que contesto el alumno
   * @param recursoId
   * @param alumnoId
   * @returns
   */
  public obtenerRespuestasAlumnoExamen(recursoId: number, alumnoId: number): Observable<ApiResponse<CuestionarioRespuestaDto[]>> {
    return this.httpClient.get<ApiResponse<CuestionarioRespuestaDto[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetRespuestasAlumnoExamen/${recursoId}/${alumnoId}`);
  }

  /**
   * Obtiene las respuestas correctas del examen por el profesor
   * @param recursoId
   * @param alumnoId
   * @returns
   */
  public obtenerRespuestasCorrectaExamen(recursoId: number, alumnoId: number): Observable<ApiResponse<CuestionarioRespuestaDto[]>> {
    return this.httpClient.get<ApiResponse<CuestionarioRespuestaDto[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetRespuestasCorrectaExamen/${recursoId}/${alumnoId}`);
  }

  /**
   * Guarda la calificacion que el profesor le registre al alumno
   * @param calificacion
   * @returns
   */
  public enviarCalificacion(calificacion: any): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/PostCalificacion/`, calificacion);
  }

  /**
   * Cierra una tarea para que ya no puedan subir archivos o contestarla
   * @param cerrado
   * @param recursoId
   * @returns
   */
  public cerrarTarea(cerrado: boolean, recursoId: number): Observable<SimpleResponse> {
    return this.httpClient.get<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/PutCerrarTarea/${cerrado}/${recursoId}`);
  }

  //GetAlumnosCalificaciones
  public obtenerAlumnosCalificaciones(grupoId: number, parcial: number, escalaCalificacionId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetAlumnosCalificaciones/${grupoId}/${parcial}/${escalaCalificacionId}`);
  }

  //GetAlumnosCalificacionesFinal
  public obtenerAlumnosCalificacionesFinal(grupoId: number, parcial: number, escalaCalificacionId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetAlumnosCalificacionesFinal/${grupoId}/${parcial}/${escalaCalificacionId}`);
  }

  //GetEscalaCalificacionByPrograma
  public obtenerEscalaCalificacionByPrograma(programaId: number): Observable<ApiResponse<CalificacionNoNumerica[]>> {
    return this.httpClient.get<ApiResponse<CalificacionNoNumerica[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetEscalaCalificacionByPrograma/${programaId}`);
  }

  //PostAsistencia
  public enviarCalificacionesAumnos(calAlumnos: any): Observable<any> {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/PostCalificacionesAlumnos/`, calAlumnos);
  }

  //PostCalificacionesAlumnos Final
  public enviarCalificacionesAumnosFinal(calAlumnos: any): Observable<any> {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/PostCalificacionesAlumnosFinal/`, calAlumnos);
  }


  //Invocar Metodo obtenerinfo
  public metodoObtenerInfoGrupo() {
    this.invocarObtenerGrupos.emit();
  }

  //Invocar Metodo invocarObtenerInfoGrupo
  public metodoObtenerInfoRecurso() {
    this.invocarObtenerInfoGrupo.emit();
  }

  //Invocar Metodo obtener info de parciales
  public invocarMetodoObtenerContenido() {
    this.invocarObtenerContenido.emit();
  }

  public invocarMetodoObtenerArchivos() {
    this.invocarObtenerArchivos.emit();
  }

  //Invocar Metodo regresar a tablero de cursos
  public invocarMetodoTableroCursos() {
    this.invocarTableroCursos.emit();
  }

  //Invocar Metodo obtener info por revisar
  public invocarMetodoPorRevisar() {
    this.invocarObtenerPorRevisar.emit();
  }
  public invocarObtenerParcial1() {
    this.invocarObtenerParcial1Emit.emit();
  }


  //#region Cambio NombreParcial
  invocarCambioNombreEmit = new EventEmitter();
  invocarMetodoCambioNombreSubscription: Subscription;
  public invocarCambioNombre() {
    this.invocarCambioNombreEmit.emit();
  }
  //#endregion

  public subirEnlace(recursoAdjuntos: Array<any>): Observable<any> {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/PostRecursoAdjuntoEnlace/`, recursoAdjuntos);
  }
  public ingresarTema(data: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/PostTema_Modulo`, data);
  }

  /**
   * Obtiene los examenes
   * @param profesorId
   * @param clave
   * @returns
   */
  public getExamenesPorImportar(profesorId: number, clave: any): Observable<ApiResponse<Recurso[]>> {
    return this.httpClient.get<ApiResponse<Recurso[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetExamenesPorImportar/${profesorId}/${clave}`);

  }

  /**
   * Obtiene todas las encuestas
   * @param profesorId
   * @param clave
   * @returns
   */
  public getEncuestaPorImportar(profesorId: number, clave: string): Observable<ApiResponse<Recurso[]>> {
    return this.httpClient.get<ApiResponse<Recurso[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetEncuestaPorImportar/${profesorId}/${clave}`);

  }

  /**
   * Obtiene la estructura del examen a presentar
   * @param recursoId
   * @returns
   */
  public getEstructuraExamen(recursoId: number): Observable<ApiResponse<CuestionarioArea[]>> {
    return this.httpClient.get<ApiResponse<CuestionarioArea[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetEstructuraExamen/${recursoId}/`);
  }

  /**
   * Obtiene el tipo de pregunta que tiene el cuestionario abierta, multiple, etc.
   * @returns
   */
  public getTipoPreguntaCuestionario(): Observable<ApiResponse<TipoPreguntaCuestionario[]>> {
    return this.httpClient.get<ApiResponse<TipoPreguntaCuestionario[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetTipoPreguntaCuestionario/`);
  }

  /**
   * Elimina un documento pasandole el tipo de documento
   * @param documentId
   * @returns
   */
  public deleteDocumentoById(documentId: number): Observable<SimpleResponse> {
    return this.httpClient.delete<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/DeleteDocumentoById/${documentId}`);
  }
  public eliminarRecurso(recursoId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/BorrarRecurso/${recursoId}`);
  }
  public eliminarTema(moduloId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/BorrarModulo/${moduloId}`);
  }

  /**
   * Registra la ponderacion de cada parcial
   * @param grupoParciales
   * @returns
   */
  public IngresarPonderacionParcial(grupoParciales: Array<any>): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/IngresarPonderacionParcial/`, grupoParciales);
  }

  public CreacionEdicionExamenEncuesta(idRecurso: number, data: Array<any>) {
    return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/CreacionEdicionExamenEncuesta/${idRecurso}`, data);
  }


  public CreacionEdicionRecurso(grupoID: any, puntosParcialMaximo: any, idModulo: number, data: any) {
    return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/GrupoParcials/CreacionEdicionRecurso/${grupoID}/${puntosParcialMaximo}/${idModulo}`, data);
  }

  /**
   * Obtiene la ponderacion del grupo
   * @param idGrupo
   * @returns
   */
  public getPonderacionGrupo(idGrupo: number): Observable<ApiResponse<Grupo>> {
    return this.httpClient.get<ApiResponse<Grupo>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetPonderacionGrupo/${idGrupo}`);
  }

  public PublicarRecurso(puntosPreguntaBase: number, idRecurso: number) {
    return this.httpClient.post<any>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/PublicarRecurso/${puntosPreguntaBase}`, idRecurso);
  }

  public ObtenerFechaSesiones(idGrupo: number) {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/ObtenerFechaSesiones/${idGrupo}`);
  }

  public ObtenerGrupoAsistenciaAlumno(idGrupo: number, fechaSesion) {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/ObtenerGrupoAsistenciaAlumno/${idGrupo}/${fechaSesion}`);
  }

  public GetCicloByProgramaID(idGrupo: number,): Observable<ApiResponse<Ciclo[]>> {
    return this.httpClient.get<ApiResponse<Ciclo[]>>(`${environment.enves[baseUrl].apiUrl}/EnsenanzaAprendizaje/GetCicloByProgramaID/${idGrupo}`);
  }

  public findNombreTipoRecurso(TipoRecurso?: number) {
    if (TipoRecurso !== 0) {
      let mensaje: string = this.traductorService.translate(`_catalogos.recurso.nombre-${TipoRecurso}`);
      return mensaje;
    } else {
      return "";
    }
  }

  public findNombreTipoPregunta(tipoPregunta?: number) {
    if (tipoPregunta !== 0) {
      let mensaje: string = this.traductorService.translate(`_catalogos.pregunta.nombre-${tipoPregunta}`);
      return mensaje;
    } else {
      return "";
    }
  }
}
