import { FORM_VALID_MODEL } from './../../../../../core.domain/models/user/form-valid-model';
import { PERSONAL_CONTACTS_INFORMATION_MODEL } from './../../../../../core.domain/models/user/personal-contacts-information.model';
import { Subscription } from 'rxjs/Subscription';
import { MessageErrors } from './../../../../../core.application/functions/messageErros.function';
import { PERSONAL_CONTACTS_INFORAMTION } from './../../../../../core.application/validators/user-data/personal-contacts-information.validatos';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-personal-contacts-information-form',
  templateUrl: './personal-contacts-information-form.component.html',
  styleUrls: ['./personal-contacts-information-form.component.scss']
})
export class PersonalContactsInformationFormComponent implements OnInit, OnDestroy, OnChanges {
  
  //#region  Input, Output & ViewChild 
  @Input() data: PERSONAL_CONTACTS_INFORMATION_MODEL[];

  @Output() formValidity: EventEmitter<FORM_VALID_MODEL> = new EventEmitter(); 
  @Output() formData = new EventEmitter<PERSONAL_CONTACTS_INFORMATION_MODEL[]>();
  //#endregion
  
  //#region Subscriptions
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Properties  
  public personalContactsInformationForm: FormGroup = this.formBuilder.group(
    {
      contacts: this.formBuilder.array([])
    }
  );
  public ErrorMsg: any[] = [];
  private status: boolean = false;

  public  deletedInputs = {        
    nombreCompleto      : false,
    telefono            : false,
    correoElectronico   : false
  };

  public contactsLabels: string[] = [
    '_datosPersonales.datos-familia.madre',
    '_datosPersonales.datos-familia.padre',
    '_datosPersonales.datos-familia.tutor',
    '_datosPersonales.datos-familia.emergencia'
  ];
  //#endregion

  constructor(
    private formBuilder: FormBuilder
  ) { 
    this._addControls();
    this.setErrors();
  }

  ngOnInit(): void {
    this.subscribeToForm();    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] !== undefined) {
      this._setData();
    }
  }

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#region setData
  private _setData():void
  {            
    this.data.forEach((element, i) => {      
      const control = (this.personalContactsInformationForm.get('contacts') as FormArray).controls[i];
      element.titulo = this.contactsLabels[i];
      control.setValue(element);
    });    
  }
  //#endregion

  //#region Helpers
  public setErrors(): void {    
    for (let i = 0; i < this.contactsLabels.length; i++) {      
      const control = (this.personalContactsInformationForm.get('contacts') as FormArray).controls[i];
      this.ErrorMsg[i] = MessageErrors.setErrorMessage( control );
    }
  }

  private subscribeToForm(): void {
    this.personalContactsInformationForm.markAllAsTouched();
    this._Emit();
    this.personalContactsInformationForm.updateValueAndValidity()
    this.Subscription.add(
      this.personalContactsInformationForm.statusChanges
      .subscribe(status => {
        this.setErrors();
        this.status = status === 'VALID';
        this._Emit();
      })
    );    
  }

  private _Emit():void
  {
    this.formValidity.emit(
      new FORM_VALID_MODEL(
        4,
        'PersonalContactsInformationFormComponent',
        this.status
      )
    );
    const data = new Array<PERSONAL_CONTACTS_INFORMATION_MODEL>()

    for (let i = 0; i < this.contactsLabels.length; i++) {      
      const control = (this.personalContactsInformationForm.get('contacts') as FormArray).controls[i];
      data.push(
        new PERSONAL_CONTACTS_INFORMATION_MODEL(
          control.get('usuarioId')?.value,
          control.get('tipoRelacionId')?.value,
          control.get('nombreCompleto')?.value,
          control.get('telefono')?.value,
          control.get('correoElectronico')?.value,
          control.get('titulo')?.value
          )
      );
    }    
    this.formData.emit(data);
  }

  get contacts() {
    return this.personalContactsInformationForm.controls["contacts"] as FormArray;
  }

  private _addControls(){    

    this.contactsLabels.forEach((title, i) => {
        const personalContactInformationForm: FormGroup = this.formBuilder.group(PERSONAL_CONTACTS_INFORAMTION);

        personalContactInformationForm.get('usuarioId')?.setValue(1);
        personalContactInformationForm.get('tipoRelacionId')?.setValue(i + 1);
        personalContactInformationForm.get('titulo')?.setValue(title);

        this.contacts.push(personalContactInformationForm);
        
        const control = (this.personalContactsInformationForm.get('contacts') as FormArray).controls[i];

        this.ErrorMsg.push(MessageErrors.setErrorMessage( control ));
    });
  }
  //#endregion

}