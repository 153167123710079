import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { Entry } from '../_models';
import { AccionEnum } from '../_models/accion.enum';
import { LeadEntryService } from '../_services';
import { UtileriasService } from '../_services/utilerias.service';
import { LeadsSourceModalEliminarComponent } from './leads-source-modal-eliminar/leads-source-modal-eliminar.component';
import { LeadsSourceModalComponent } from './leads-source-modal/leads-source-modal.component';

@Component({
  selector: 'app-leads-source-type',
  templateUrl: './leads-source-type.component.html',
  styleUrls: ['./leads-source-type.component.scss']
})
export class LeadsSourceTypeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'id', 'nombre', 'descripcion']
  dataSource: MatTableDataSource<Entry>;
  selection = new SelectionModel<Entry>(true, []);
  
  public infoCargado = false;
  public numEntrySeleccionado: number = 0;
  public claveName = new FormControl();
  private arrOriginal: any[] = [];

  constructor(public util: UtileriasService,
    private leadEntryService: LeadEntryService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog
    ) {
      this.obtenerEntrys();
    }

  ngOnInit(): void {
    if (this.leadEntryService.invMetodoObtenerEntrySubscription === undefined) { 
      this.leadEntryService.invMetodoObtenerEntrySubscription = this.leadEntryService.invocarObtenerEntry.subscribe(() => {
        this.obtenerEntrys();
      });
      this.paginador.itemsPerPageLabel = "Registros por página";
      this.paginador.nextPageLabel = "Página siguiente";
      this.paginador.previousPageLabel = "Página anterior";
      this.paginador.firstPageLabel = "Primera página";
      this.paginador.lastPageLabel = "Última página";
    
      this.paginador.getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
          return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
    }
    this.obtenerEntrys();
  }

  /**
   * Funcion para obtener los registros
   */
  public obtenerEntrys(): void {
    this.leadEntryService.getEntrys().subscribe((entry: ApiResponse<Array<Entry>>) => {
      this.dataSource = new MatTableDataSource(entry.data);
      this.selection = new SelectionModel<Entry>(true, []);
      this.dataSource.paginator = this.paginator;
      this.infoCargado = true;
      this.arrOriginal = [...entry.data];
    });
  }

  /**
   * Funcion para crear un nuevo registro del tipo lead source, manda los datos iniciales como un modelo de datos
   * Para despues abrir un modal con los datos e inyectarlos al formulario
   */
  public crear(): void {
    let datos: Entry = {
      sourceId: 0,
      name: '',
      description: '',
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(LeadsSourceModalComponent, datos);
  }

  /**
   * Funcion para editar un registro, mandando los datos segun la seleccion que el usuario tiene
   * para despues abrir un modal con los datos y agregarlos segun el formulario
   * @param elemento 
   */
  public editar(elemento?: Entry): void {
    let datos: Entry = {
      sourceId: elemento.sourceId,
      name: elemento.name,
      description: elemento.description,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(LeadsSourceModalComponent, datos);
  }

  /**
   * Funcion que manda los datos segun la seleccion, segun la cantidad de datos los agrega en un arreglo o los manda
   * como un unico registro, despues se pasan los datos y se abre el modal
   * @param btnDataTable 
   * @param elemento 
   */
  public borrar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogEliminar = this.dialog.open(LeadsSourceModalEliminarComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  /**
   * Funcion para filtrar los datos segun el nombre que el usuario ingrese en el cuadro de entrada
   */
  public filtro(): void {
    let claveNombre: string = this.claveName === null ? '' : this.claveName.value;
    let filtroModificado: any = [];
    filtroModificado = [this.arrOriginal];
    filtroModificado = filtroModificado.filter(f => {
      return f.name.toLowerCase().search(claveNombre.toLocaleLowerCase()) > -1;
    });
    this.dataSource = new MatTableDataSource(filtroModificado);
  }

  /**
   * Funcion que limpia el input con el nombre ingresado, y setea todos los valores como al inicio
   */
  public limpiarFiltros(): void {
    this.claveName.setValue('');
    this.obtenerEntrys();
  }

  /**
   * Funcion que detecta si todas las casillas estan marcadas igual a la seleccion del usuario
   * @returns 
   */
  isAllSelected(): boolean {
    this.numEntrySeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numEntrySeleccionado === numRows;
  }

  /**
   * Funcion que selecciona todas las casillas si no lo estan, y las desmarca si estan todas seleccionadas
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: Entry): string {
    if(!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.sourceId + 1}`;
  }

  /** 
   * Selecciona todas las columnas si no lo estan, o bien limpia la seleccion
   */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear(): 
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
