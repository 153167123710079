import { map } from 'rxjs/operators';
import { ApiResponseInstance } from './../../../../models/api/ApiRespose.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { baseUrl, environment } from 'src/environments/environment';
import { Mapping } from '../../../core.domain/class/mapping.class';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private _http: HttpClient) { }

  public getLanguages<T extends Mapping>(_class: T): Observable<ApiResponseInstance<T[]>> {    
    return this._http.get<ApiResponseInstance<T[]>>(`${environment.enves[baseUrl].apiUrl}/Institucion/getLanguages`).
      pipe(
        map((response: ApiResponseInstance<T[]>) => {          
            return new ApiResponseInstance( 
              response.data.map(res => _class.mapBackEnd(res)),
              response.success,
              response.message,
              )
            }
          )
        );
    }

  public getLanguagesByInstitution<T extends Mapping>(_class: T): Observable<ApiResponseInstance<T[]>> {    
    return this._http.get<ApiResponseInstance<T[]>>(`${environment.enves[baseUrl].apiUrl}/Institucion/getLanguagesByInstitution/${environment.enves[baseUrl].institucion}`).
      pipe(
        map((response: ApiResponseInstance<T[]>) => {          
            return new ApiResponseInstance( 
              response.data.map(res => _class.mapBackEnd(res)),
              response.success,
              response.message,
              )
            }
          )
        );
    }
    
  public postLanguagesInstitution<T>(entity: T): Observable<SimpleResponse> {
    return this._http.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Institucion/postLanguagesInstitution`, entity);
  }

  public deleteLanguagesInstitution<T>(entity: T): Observable<SimpleResponse> {
    return this._http.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Institucion/deleteLanguagesInstitution`, entity);
  }

  //#region Test
  public getEnglish(): Observable<any> {
    return this._http.get(`locales/en.translation.json`);
  }
  public getSpanish(): Observable<any> {
    return this._http.get(`locales/es.translation.json`);
  }
  //#endregion
}
