import { SnackService } from '@app/services/snack-service.service';
import { Subscription } from 'rxjs/Subscription';
import { GenericHttpService } from './../../../../infrastructure/services/generic-http.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmitterSaveActionService } from './../../../../infrastructure/services/common/emitter-save-action.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiResponseInstance } from '@app/models/api/ApiRespose.model';
import { ActivatedRoute } from '@angular/router';
import { TraductorService } from './../../../../../_services/traductor.service';

@Component({
  selector: 'app-reports-embed',
  templateUrl: './reports-embed.component.html',
  styleUrls: ['./reports-embed.component.scss']
})
export class ReportsEmbedComponent implements OnInit, OnDestroy {

  public url: SafeResourceUrl;
  private _Subscription: Subscription = new Subscription();
  nombreReporte: string;
  traduccionReporte: string;


  constructor(
    public sanitizer: DomSanitizer,
    private _GenericHttpService: GenericHttpService,
    private _snackService: SnackService,
    private activatedRoute: ActivatedRoute,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    this.nombreReporte = this.activatedRoute.snapshot.routeConfig.path;
    this.traduccionReporte = this.traductorService.translate(`reportes-embed.${this.nombreReporte}`);;
    this.setUrl();
  }

  ngOnDestroy(): void {
    if (this._Subscription) {
      this._Subscription.unsubscribe();
    }
  }
  setUrl() {
    this._Subscription.add(
      this._GenericHttpService.get<ApiResponseInstance<string>>(`Reporte/gerReportQuickSight/${this.nombreReporte}`).subscribe((response: ApiResponseInstance<string>) => {
        if (response.success) {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(response.data);
        } else {
          this._snackService.mostrarSnackBack(response.message);
        }
      })
    );
  }

}
