import { Component, Inject, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { PeriodoService } from '@app/_services/periodo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Periodo } from '@app/_models/periodo';
import { TipoPeriodo } from '@app/_models/tipo-periodo';
import { TipoPeriodoService } from '@app/_services/tipo-periodo.service';
import { UtileriasService } from '@app/_services/utilerias.service';
import { AccionEnum } from '../../_models/accion.enum';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TraductorService } from '@app/_services/traductor.service';
import { SnackService } from '@app/services/snack-service.service';

@Component({
  selector: 'app-periodo-edicion',
  templateUrl: './periodo-edicion.component.html',
  styleUrls: ['./periodo-edicion.component.scss']
})
export class PeriodoEdicionComponent implements OnDestroy {

  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  public errorCreacionPeriodo: string = "";
  public tipoPeriodoList: TipoPeriodo[] = [];
  private subcripcionTipoPeriodo: Subscription;
  public periodoId: number = 0;
  public objValidacionCancelar: Periodo = null;
  public infoCargado: boolean = false;
  public edicionPeriodoForm: FormGroup;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;

  constructor(public dialogRef: MatDialogRef<PeriodoEdicionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Periodo,
    private periodoService: PeriodoService,
    private tipoPeriodoService: TipoPeriodoService,
    private snackBar: MatSnackBar,
    private utileriasService: UtileriasService,
    private dialog: MatDialog,
    private snackService: SnackService,
    private traductorService: TraductorService) {
    this.getTipoPeriodo();
    this.inicializarFrom();
    this.periodoId = this.data.periodoId;
  }

  get clave() { return this.edicionPeriodoForm.get('clave'); }
  get nombre() { return this.edicionPeriodoForm.get('nombre'); }
  get tipoPeriodoId() { return this.edicionPeriodoForm.get('tipoPeriodoId'); }
  get fechaInicio() { return this.edicionPeriodoForm.get('fechaInicio'); }
  get fechaFin() { return this.edicionPeriodoForm.get('fechaFin'); }

  public inicializarFrom() {
    this.edicionPeriodoForm = new FormGroup({
      clave: new FormControl(this.data.clave === '' ? '' : this.data.clave, [Validators.required]),
      nombre: new FormControl(this.data.nombre === '' ? '' : this.data.nombre, [Validators.required]),
      tipoPeriodoId: new FormControl(this.data.tipoPeriodoId === 0 ? 1 : this.data.tipoPeriodoId, [Validators.required]),
      fechaInicio: new FormControl(this.data.fechaInicio === null ? null : this.utileriasService.formatearFecha(this.data.fechaInicio, 'YYYY[-]MM[-]DD'), [Validators.required]),
      fechaFin: new FormControl(this.data.fechaFin === null ? null : this.utileriasService.formatearFecha(this.data.fechaFin, 'YYYY[-]MM[-]DD'), [Validators.required])
    });

    this.edicionPeriodoForm.valueChanges.subscribe((cambios) => {
      console.log("Cambios", cambios);
      this.boolCambio = true;
    });
  }

  public getTipoPeriodo() {
    this.subcripcionTipoPeriodo = this.tipoPeriodoService.getTipoPeriodo().subscribe(
      (tiposPeriodos: TipoPeriodo[]) => {
        this.tipoPeriodoList = tiposPeriodos;
        this.infoCargado = true;
      }
    );
  }

  public findNombreTipoPeriodo(idTipoPeriodo: number) {
    return this.tipoPeriodoList.find(id => id.tipoPeriodoId === idTipoPeriodo).nombre;
  }

  public cambiarVistaEditar(idPeriodo: number) {
    this.boolCambio = false;
    this.data = Object.assign(this.edicionPeriodoForm.value);
    this.data.tipoAccion = 2;
    this.periodoId = idPeriodo;
  }

  public cambiarVistaEliminar() {
    this.data.tipoAccion = 4;
  }

  public enviar(): void {
    switch (this.data.tipoAccion) {
      case AccionEnum.CREAR:
        let datos = Object.assign(this.edicionPeriodoForm.value);
        this.periodoService.generarPeriodo(datos).subscribe(
          idPeriodo => {
            if (idPeriodo > 0) {
              this.periodoService.invocarMetodoObtenerPeriodos();
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
              this.cambiarVistaEditar(idPeriodo);
              this.inicializarFrom();
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          },
          error => {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        );
        break;
      case AccionEnum.EDITAR:
        let datosEdit = Object.assign(this.edicionPeriodoForm.value);
        datosEdit.periodoId = this.periodoId;
        datosEdit.tipoAccion = 2;
        this.periodoService.modificarPeriodo(datosEdit).subscribe(
          editado => {
            if (editado) {
              this.periodoService.invocarMetodoObtenerPeriodos();
              let mensaje: string = this.traductorService.translate('guardadoCorrecto');
              this.snackService.mostrarSnackBack(mensaje);
              this.boolCambio = false;
            } else {
              let mensaje: string = this.traductorService.translate('errorGuardar');
              this.snackService.mostrarSnackBack(mensaje);
            }
          },
          error => {
            let mensaje: string = this.traductorService.translate('errorGuardar');
            this.snackService.mostrarSnackBack(mensaje);
          }
        );
        break;
    }
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  ngOnDestroy(): void {
    if (this.subcripcionTipoPeriodo) {
      this.subcripcionTipoPeriodo.unsubscribe();
    }
    this.periodoId = 0;
  }

  public validarFechas = function (form: FormGroup, nombreControl1: string, nombreControl2: string) {
    if (form.get(nombreControl1).value !== null && form.get(nombreControl2).value !== null) {
      return form.get(nombreControl1).value < form.get(nombreControl2).value;
    }
    return true;
  }
}
