export class FORM_VALID_MODEL {

    FormId  : number;
    Name    : string;
    IsValid : boolean;

    constructor(
        FormId  : number,
        Name    : string,
        IsValid : boolean
    ){
        this.FormId     =   FormId ;
        this.Name       =   Name   ;
        this.IsValid    =   IsValid;
    }
  
  };