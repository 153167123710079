import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { IntentoPagoZumaDTO } from '../_models/IntentoPagoZumaDTO.model';
import { ModificarEmailZuma } from '../_models/Zuma/Email/ModificarEmailZuma';
import { LinkEstatus } from '../_models/Zuma/LinkEstatus/LinkEstatus';
import { PagoZuma } from '../_models/Zuma/LinkPago/PagoZuma';

@Injectable({
  providedIn: 'root'
})
export class PagoLineaZumaService {

  invocarMetodo = new EventEmitter();
  invocarMetodoSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  /**
   * Servicio que consume un Api de ZUMA para generar un link de pago
   * @param zumaPago 
   * @returns Retorna modelo response con la respuesta de la creacion de pago 
   */
  public PostPagoZuma(zumaPago: PagoZuma): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Zuma/PostPagoZuma`, zumaPago);
  }

  /**
   * Servicio que consume Api de ZUMA se debe enviar los cargos el pagozuma y el pagozumaresponse
   * @param PagoIntento 
   * @returns Retorma una simpleresponse
   */
  public PostIntentoPago(PagoIntento: IntentoPagoZumaDTO): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Zuma/PostIntentoPago`, PagoIntento);
  }

  /**
   * Servicio que consume Api de ZUMA para mandar la liga de pago ya creada a otro correo
   * @param modificarEmail 
   * @returns Retorna modelo response con la respuesta de el envio de liga al otro correo
   */
  public PostModifyEmail(modificarEmail: ModificarEmailZuma): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Zuma/PostModifyEmail`, modificarEmail);
  }

  /**
   * Servicio que consume Api de ZUMA para consultar el estatus de un link de pago
   * @param linkEstatus 
   * @returns Retorna modelo response con la respuesta de el estatus del link de pago
   */
  public PostLinkEstatus(linkEstatus: LinkEstatus): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Zuma/PostLinkEstatus`, linkEstatus);
  }

  /**
   * Metodo para guardar la referencia del pago de zuma
   * @param referencia 
   * @returns 
   */
  public enviarReferenciaZuma(Referencia: any): Observable<ApiResponse<string>> {
    return this.httpClient.post<ApiResponse<string>>(`${environment.enves[baseUrl].apiUrl}/Zuma/EnviarReferenciaZuma`, Referencia);
  }

  /**
   * Llama al metodo de obtener solicitudes para recargar la tabla principal cuando se actualizan o eliminan los datos
   */
  metodoInvocarMetodo(pagoZuma: any, url: any) {
    let datos: any = {
      pagoZuma: pagoZuma,
      url: url
    }
    this.invocarMetodo.emit(datos);
  }
}
