export class AlumnoGrupoMakeupExam {
    alumnoGrupoMakeupExamId: number;
    studentId: number;
    sectionId: number;
    makeUpExamReasonId: number;
    
    constructor(    
        studentId: number,
        sectionId: number,
        makeUpExamReasonId: number
    ){        
        this.studentId                  = studentId;
        this.sectionId                  = sectionId;
        this.makeUpExamReasonId         = makeUpExamReasonId;
    }
}