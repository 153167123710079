import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UtileriasService } from '../_services/utilerias.service';
import { AccionEnum } from '../_models/accion.enum';
import { FormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DepartamentoService } from '../_services/departamento.service';
import { Departamento } from '../_models/departamento';
import { ModalEliminarDepartamentoComponent } from './modal-eliminar-departamento/modal-eliminar-departamento.component';
import { ModalDepartamentoComponent } from './modal-departamento/modal-departamento.component';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-departamento',
  templateUrl: './departamento.component.html',
  styleUrls: ['./departamento.component.scss']
})
export class DepartamentoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'clave', 'nombre'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);

  public departamento: any;
  public infoCargado = false;
  public numDepartamentoSeleccionado: number = 0;
  public claveName = new FormControl();
  private arrOriginal: any[] = [];

  constructor(public util: UtileriasService,
    private departamentoService: DepartamentoService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
  ) { 
    this.obtenerDepartamentos();
  }

  ngOnInit() {
    if(this.departamentoService.invMetodoObtenerDepartamentoSubscription === undefined){
      this.departamentoService.invMetodoObtenerDepartamentoSubscription = this.departamentoService.invocarObtenerDepartamento.subscribe(() => {
        this.obtenerDepartamentos();
      });
      this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
  
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
      };
    }
    this.obtenerDepartamentos();
  }

  public obtenerDepartamentos() {
    this.departamentoService.obtenerDepartamentos().subscribe(departamento => {
        this.dataSource = new MatTableDataSource(departamento.data);
        this.selection = new SelectionModel<any>(true, []);
        this.dataSource.paginator = this.paginator;
        this.infoCargado = true;
        this.arrOriginal = [...departamento.data];
      }
    )
  }

  public crear(): void {
    let datos: Departamento = {
      departamentoId: 0,
      clave: '',
      nombre: '',
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(ModalDepartamentoComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if(!!result){
        this.departamento = result;
      }
    })
  }

  public editar(elemento?: any): void {
    let datos: Departamento = {
      departamentoId: elemento.departamentoId,
      clave: elemento.clave,
      nombre: elemento.nombre,
      tipoAccion: AccionEnum.EDITAR
    }
    this.util.abrirDialogoLateral(ModalDepartamentoComponent, datos);
  }

  ExcelSubscription: Subscription;
  public descargarExcel(){
    this.ExcelSubscription = this.departamentoService.downloadExcelDepartamento(this.arrOriginal).subscribe((res: Blob) => {
      var filename = "Consultar-Departamento.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });

  }

  public borrar(btnDataTable: boolean, elemento?: any){
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogEliminar = this.dialog.open(ModalEliminarDepartamentoComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
    dialogEliminar.afterClosed().subscribe(result => {
      if (!!result) {
        
      }
    });
  }

  public filtro() {
    let claveNombre: string = this.claveName === null ? '' : this.claveName.value;
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    filtroModificado = filtroModificado.filter(f => {
      return f.clave.toLowerCase().search(claveNombre.toLocaleLowerCase()) > -1 || f.nombre.toLowerCase().search(claveNombre.toLowerCase()) > -1;
    });
    this.dataSource = new MatTableDataSource(filtroModificado);
  }

  limpiarFiltros() {
    this.claveName.setValue('');
    this.obtenerDepartamentos();
  }

  isAllSelected() {
    this.numDepartamentoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numDepartamentoSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row))
  }
}
