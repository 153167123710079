import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Correo } from 'src/app/_models/correo';
import { SnackService } from 'src/app/services/snack-service.service';
import { NuevoIngresoService } from '../../_services/nuevo-ingreso.service';
import { ProfesorService } from 'src/app/_services/profesor.service';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';

@Component({
  selector: 'app-enviar-correo',
  templateUrl: './enviar-correo.component.html',
  styleUrls: ['./enviar-correo.component.scss']
})
export class EnviarCorreoComponent implements OnInit {
  public numUsuarioSeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public correosUsuarios: string = '';
  public boolCambio: boolean = false;
  public correoEnvioForm: FormGroup;

  /** UsuarioNuevoIngresoDto = any */
  constructor(public dialogRef: MatDialogRef<EnviarCorreoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private nuevoIngresoService: NuevoIngresoService,
    private profesorService: ProfesorService) { }

  ngOnInit(): void {
    if (this.data.profesorId) this.obtenerProfesor();
    this.validarInfoRecibida();
    this.inicializarForm();
  }

  public obtenerProfesor() {
    this.profesorService.obtenerProfesoresByUsuario(this.data.profesorId).subscribe(profesor => {
      if (profesor) {
        this.data.correoAl = profesor.correo;
      }
    });
  }

  /**
   * Funcion que valida la informacion que recibia del formulario
   */
  public validarInfoRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    console.log(this.data);
    if (this.boolIsArray) {
      this.numUsuarioSeleccionados = this.data.length;
    }
  }

  get asunto() { return this.correoEnvioForm.get('asunto'); }
  get mensaje() { return this.correoEnvioForm.get('mensaje'); }

  /**
   * Funcion que inicia el formulario
   */
  public inicializarForm(): void {
    this.correoEnvioForm = new FormGroup({
      asunto: new FormControl('', [Validators.required]),
      mensaje: new FormControl('', [Validators.required])
    });
  }

  /**
   * Funcion que envia los correos mediante el servicio y obtiene los valores del formulario
   */
  public enviarCorreo(): void {
    if (this.boolIsArray) {
      const arrayCorreos = this.data.map(x => (x.correo.toString()));
      this.correosUsuarios = arrayCorreos.toString();
    } else {
      if (this.data.correoAl != null){
        this.correosUsuarios = this.data.correoAl.toString();
      } else {
        this.correosUsuarios = this.data.correo.toString();
      }    
    }
    const estructuraCorreo = new Correo();
    estructuraCorreo.CorreosDestinatario = this.correosUsuarios;
    estructuraCorreo.Asunto = this.correoEnvioForm.get('asunto').value;
    estructuraCorreo.CuerpoDelMensaje = this.correoEnvioForm.get('mensaje').value;
    this.nuevoIngresoService.enviarCorreos(estructuraCorreo).subscribe((correoEnviado: SimpleResponse) => {
      if (correoEnviado.success) {
        this.snackService.mostrarSnackBack('Se ha enviado el correo.');
      } else {
        this.snackService.mostrarSnackBack('No está activo el envío de correos en su institución.');
      }
    });
  }

  /**
   * Funcion que cierra el modal
   */
  public cerrarModal(): void {
    if (!this.boolCambio) {
      this.dialogRef.close(this.data);
    }
  }
}
