import { Pipe, PipeTransform } from "@angular/core";
import i18next from "i18next";

@Pipe({
  name: "salon",
})
export class SalonPipe implements PipeTransform {
  transform(salon: string): string {
    if (salon.length == 0 || salon == null || salon == "")
      return i18next.t("_grupos.sin_salon");
    return salon;
  }
}
