import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admision-eventos',
  templateUrl: './admision-eventos.component.html',
  styleUrls: ['./admision-eventos.component.scss']
})
export class AdmisionEventosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
