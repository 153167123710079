import { I18NextModule } from 'angular-i18next';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReportsEmbedComponent } from './reports-embed/reports-embed.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsEmbedRoutingModule } from './reports-embed-routing.module';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [
    ReportsEmbedComponent
  ],
  imports: [
    CommonModule,
    ReportsEmbedRoutingModule,
    FlexLayoutModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    I18NextModule.forRoot(),
  ],
  exports: [
    ReportsEmbedComponent
  ]
})
export class ReportsEmbedModule { }
