import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { AlumnoReingresoDTO } from '../_models/alumnoReingresoDTO.model';
import { Periodo } from '../_models/periodo';

@Injectable({
  providedIn: 'root'
})
export class ReinscripcionService {
  //#region Constructor
  constructor(private httpClient: HttpClient) {
    this.urlApi = `${environment.enves[baseUrl].apiUrl}/Reinscripcion/`;
  }
  //#endregion

  //#regions  Propiedades
  private periodoSeleccionado$ = new Subject<{ inscripcionExistente:boolean, inscripcionPagada: boolean, periodoId: number, colegiaturaExistente: boolean }>();
  private tab$ = new Subject<boolean>();
  private urlApi: string;
  //#endregion

  //#region  Métodos
  /**
   * Recupera el observable que emite los eventos 
   * @returns Observable con la variable bandera si el alumo ha pagado en ese periodo,
   * así como el periodo Id del Select
   */
  public obtenerPeriodoSeleccionado(): Subject<{ inscripcionPagada: boolean, periodoId: number }> {
    return this.periodoSeleccionado$;
  }
  /**
   * Debido al bug de Angualr que no activa el header cuando está Hidden el compopnente
   * se hace uso de un observable para controlar manualmente el evento de reinicio
   * @returns 
   */
  public obtenerTab(): Subject<boolean> {
    return this.tab$;
  }
  /**
   * Actualiza el Header del Tab de selección de materias debido al Bug de Angular co el tab 
   * en tipo Hidden
   * @returns 
   */
  public actualziarHeaderDelTab(): void {
    return this.tab$.next(true);
  }
  /**
   * Emite un cambio cada vez que se cambia el select del componente de pago
   * @param inscripcionPagada 
   * @param periodoId 
   */
  public cambiarPeriodoSeleccionado(inscripcionExistente: boolean, inscripcionPagada: boolean, periodoId: number, colegiaturaExistente: boolean): void {
    this.periodoSeleccionado$.next({ inscripcionExistente, inscripcionPagada, periodoId, colegiaturaExistente });
  }
  /**
   * Obtiene la información de usuario que se mostrará en el Moddal azul de reingreso
   * @param idUsuario 
   * @returns ApiResponse(AlumnoReingresoDTO)
   */
  public obtenerDatosDeAlumnoReingreso(identificador: string): Observable<ApiResponse<AlumnoReingresoDTO>> {
    return this.httpClient.get<ApiResponse<AlumnoReingresoDTO>>(`${this.urlApi}ObtenerDatosDeAlumnoReingreso?Identificador=${identificador}`);
  }
  /**
   * Obtiene un listado de periodos disponibles para reingreso
   * @param idCampus 
   * @param idNivel 
   * @returns 
   */
  public obtenerPeriodosReinscripcion(idCampus: number, idNivel: number): Observable<ApiResponse<Array<Periodo>>> {
    return this.httpClient.get<ApiResponse<Array<Periodo>>>(`${this.urlApi}ObtenerPeriodosDeReinscripcion?idNivel=${idNivel}&idCampus=${idCampus}`);
  }
  //#endregion
}
