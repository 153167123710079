import { ServicioPaqueteService } from './../../../_services/servicio-paquete.service';
import { ServicePackageSeguimientoDto, DetallePaqueteServicio } from './../../../_models/ServicePackageSeguimientoDto';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { Campus } from '../../../_models/campus';
import { Periodo } from '../../../_models/periodo';
import { Programa } from '../../../_models/programa';
import { CampusService } from '../../../_services/campus.service';
import { PeriodoService } from '../../../_services/periodo.service';
import { SeguimientoCarteraService } from '../../../_services/seguimiento-cartera.service';
import { UtileriasService } from '../../../_services/utilerias.service';
import { ConfiguracionCuotaService } from '../../../_services/configuracion-cuota.service';
import { NuevoIngresoService } from '../../../_services/nuevo-ingreso.service';
import { baseUrl, environment } from '../../../../environments/environment';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { EstadoCuentaDto } from 'src/app/_models/estado-cuenta-dto';
import { ResumenEstadoCuentaDto } from 'src/app/_models/resumenEstadoCuentaDto';
import { InscripcionCosto, InscripcionPago, MontoInscripcion, MovimientosPagos, ServiciosPago } from 'src/app/_models/inscripcion-costo';
import { ServicioCartera } from 'src/app/_models/seguimiento-cartera';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { TipoServicio } from 'src/app/_models/tipo-servicio';
import { TipoFormaPago } from 'src/app/_models/tipo-forma-pago';
import { TraductorService } from 'src/app/_services/traductor.service';

@Component({
  selector: 'app-movimientos-cartera',
  templateUrl: './movimientos-cartera.component.html',
  styleUrls: ['./movimientos-cartera.component.scss']
})
export class MovimientosCarteraComponent implements OnInit, OnDestroy {

  @Input() public mostrarPeriodoPorModulo: boolean;
  //DATTOS USUARIO
  public subscriptionDatosSeguimiento: Subscription;
  public datosSeguimientoUsuario$: Observable<UsuarioIngreso>;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosSeguimientoUsuario: UsuarioIngreso;
  public datosUsuarioBusqueda: any;
  //FORMULARIO
  public nuevoMovimientoForm: FormGroup;
  //VARIABLES TABLA MOVIMIENTOS
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'fechaVencimiento', 'descripcion', 'saldo', 'distribucionMonto'];
  displayedColumnsCancel: string[] = ['select', 'fechaVencimiento', 'descripcion', 'tipoMovimiento', 'saldo'];
  displayedColumnsDetalle: string[] = ['nombre', 'costo', 'aplica'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  //CATALOGO
  public subscriptionTipoServicio: Subscription;
  public tipoServicioList: TipoServicio[] = [];
  public listPaquetes: ServicePackageSeguimientoDto[] = [];
  public subscriptionTipoFormaPago: Subscription;
  public tipoFormaPagoList: TipoFormaPago[] = [];
  public subscritpionCampus: Subscription;
  public campusList: Campus[] = [];
  public subscriptionPeriodo: Subscription;
  public periodoList: Periodo[] = [];
  public programaList: Programa[] = [];
  //BANDERA RENDERIZAR FORM COMPLETO
  public infoCargada: boolean = false;
  //BANDERAS FRONT FORM
  public showTipoServicio: boolean = false;
  public showMonto: boolean = false;
  public showMotivo: boolean = false;
  public showFormaCargo: boolean = false;
  public showMotivoCancelacion: boolean = false;
  public showPeriodo: boolean = false;
  public showCampus: boolean = false;
  public showPrograma: boolean = false;
  public showTipoAlumno: boolean = false;
  public showMontoInscripcion: boolean = false;
  public showFormaPago: boolean = false;
  public showMontoFormaPago: boolean = false;
  public showNombreBanco: boolean = false;
  public showNombreTienda: boolean = false;
  public showReferenciaNumerica: boolean = false;
  public showFechaPago: boolean = false;
  public showMensaje: boolean = false;
  public showTablaMovimientos: boolean = false;
  public showTablaMovimientosCancelacion: boolean = false;
  public showBtnGuardar: boolean = false;
  public showMensajeError: boolean = false;
  public showPaqueteServicio: boolean = false;
  public showTablaDetallePaquete: boolean = false;
  //VARIABLES CHECKBOX TABLE
  public numMovimientosSeleccionado: number = 0;
  //VARIABLE DATOS DE CARGOS
  public cargosPendientes: any[] = [];
  //VARIABLE MONTO DISABLED
  public inscripcion: InscripcionCosto;
  public servicio: any;
  //VARIABLE PARCIALIDADES
  public numeroParcialidad: number = 0;
  //VARIABLES DATOS CANCELACION
  public movimientosCancelacion: EstadoCuentaDto[] = [];
  //DISABLED MONTO
  public disabledMonto: boolean = false;
  //VARIABLES INSCRIPCION
  public periodoValue: number = 0;
  public campusValue: number = 0;
  public programaValue: number = 0;
  public tipoAlumnoValue: number = 0;
  //Datos Alumno
  public datosAlumno$: Observable<any>;
  public datosAlumno: any;
  public subscriptionDatosAlumno: Subscription;
  public alumnoSeleccionado$: Observable<any>;
  public alumnoSeleccionado: any;
  public subscriptionAlumnoSeleccionado: Subscription;
  //NIVEL
  public nivelId: number;

  constructor(private paginador: MatPaginatorIntl,
    public utileriaService: UtileriasService,
    private snackService: SnackService,
    private seguimientoCarterasService: SeguimientoCarteraService,
    private campusService: CampusService,
    private periodoService: PeriodoService,
    private configuracionCuotaService: ConfiguracionCuotaService,
    private nuevoIngresoService: NuevoIngresoService,
    private traductorService: TraductorService,
    private admisionesService: AdmisionesService,
    private servicioPaqueteService: ServicioPaqueteService) { }

  /**
   * Inicia el componente, obtiene los datos del usuario para mostarlos y obtiene todos los catalogos
   * para despues agregarlos al select, ademas de iniciar el select
   */
  ngOnInit(): void {
    console.log("mostrarPeriodoPorModulo", this.mostrarPeriodoPorModulo);
    //DATOS SEGUIMIENTOS USUARIO
    this.datosAlumno$ = this.seguimientoCarterasService.getDatosAlumno$();
    this.subscriptionDatosAlumno = this.datosAlumno$.subscribe((resumen: any) => {
      this.datosAlumno = resumen;
    });
    this.seguimientoCarterasService.getDatosSeguimientoUsuario$().subscribe((datosSeguimiento) => {
      this.datosSeguimientoUsuario = datosSeguimiento;
      const alumnoEncontrado = this.datosSeguimientoUsuario.alumno.find(x => x.alumnoId === this.datosAlumno.alumnoId);
      this.nivelId = alumnoEncontrado.programa.nivelId;
      this.getTipoServicio();
      this.getPaquetes();
      this.initFormNuevoMovimiento();
    });
    this.alumnoSeleccionado$ = this.seguimientoCarterasService.getAlumnoIdSeleccionado$();
    this.subscriptionAlumnoSeleccionado = this.alumnoSeleccionado$.subscribe((resumen: any) => {
      this.alumnoSeleccionado = resumen;
    });
    /*  this.datosSeguimientoUsuario$ = this.seguimientoCarterasService.getDatosSeguimientoUsuario$();
     this.subscriptionDatosSeguimiento = this.datosSeguimientoUsuario$.subscribe((datosSeguimiento) => {
       this.datosSeguimientoUsuario = datosSeguimiento;
       this.getTipoServicio();
       this.initFormNuevoMovimiento();
     });
  */
    if (this.seguimientoCarterasService.invMetodoNuevoMovimientoTipoPagoSubscription == undefined) {
      this.seguimientoCarterasService.invMetodoNuevoMovimientoTipoPagoSubscription = this.seguimientoCarterasService.invocarNuevoMovimientoTipoPago.subscribe(() => {
        this.nuevoMovimientoBtn();
      })
    }

    //#region Obtener Datos de usuario busqueda
    this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$().subscribe(data => {
      if (data) {
        if (data.length > 0) {
          this.datosUsuarioBusqueda = data[0];
          this.datosSeguimientoUsuario = data[0];
        } else {
          this.datosUsuarioBusqueda = data;
          this.datosSeguimientoUsuario = data;

        }
        this.initFormNuevoMovimiento();
      }
    });
    //#endregion
    /*  this.datosUsuarioBusqueda$ = this.nuevoIngresoService.obtenerDatosUsuarioBusqueda$();
     this.datosUsuarioBusqueda$.subscribe(
       (datos) => {
         if (datos.length > 0) {
           this.datosUsuarioBusqueda = datos[0];
         } else {
           this.datosUsuarioBusqueda = datos;
         }
         this.initFormNuevoMovimiento();
       }); */

    //CATALOGOS
    this.getTipoFormaPago();
    this.getTipoCampus();
    this.getTipoPeriodo();

    //PAGINADOR
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  //INICIALIZAR FORM
  /**
   * Inicia el formulario, evalua los cambios hechos para mostrar un mensaje si es que no se guardan
   */
  public initFormNuevoMovimiento(): void {
    if (this.datosUsuarioBusqueda != null) {
      this.nuevoMovimientoForm = new FormGroup({
        usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
        tipoMovimiento: new FormControl(0),
        monto: new FormControl(0),
        motivo: new FormControl(''),
        tipoServicio: new FormControl(0),
        paqueteServicio: new FormControl(0),
        formaCargo: new FormControl(true),
        periodo: new FormControl(0),
        campus: new FormControl(0),
        programa: new FormControl(0),
        tipoAlumno: new FormControl(0),
        formaPago: new FormControl(0),
        fechaPago: new FormControl(''),
        nombreBanco: new FormControl(''),
        nombreTienda: new FormControl(''),
        referenciaNumerica: new FormControl('')
      });
    } else {
      this.nuevoMovimientoForm = new FormGroup({
        usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
        tipoMovimiento: new FormControl(0),
        monto: new FormControl(0),
        motivo: new FormControl(''),
        tipoServicio: new FormControl(0),
        paqueteServicio: new FormControl(0),
        formaCargo: new FormControl(true),
        periodo: new FormControl(0),
        campus: new FormControl(0),
        programa: new FormControl(0),
        tipoAlumno: new FormControl(0),
        formaPago: new FormControl(0),
        fechaPago: new FormControl(''),
        nombreBanco: new FormControl(''),
        nombreTienda: new FormControl(''),
        referenciaNumerica: new FormControl('')
      });
    }
    this.infoCargada = true;
    this.tipoMovimiento.valueChanges.subscribe((value: number) => {
      this.disabledBanderas();
      this.motivo.setValidators(null);
      this.monto.setValidators(null);
      this.tipoServicio.setValidators(null);
      this.paqueteServicio.setValidators(null);
      this.fechaPago.setValidators(null);
      this.nombreBanco.setValidators(null);
      this.referenciaNumerica.setValidators(null);
      this.nombreTienda.setValidators(null);
      this.periodo.setValidators(null);
      this.campus.setValidators(null);
      this.programa.setValidators(null);
      this.tipoAlumno.setValidators(null);
      this.nuevoMovimientoForm.patchValue({
        monto: 0,
        motivo: '',
        tipoServicio: 0,
        paqueteServicio: 0,
        periodo: 0,
        campus: 0,
        tipoAlumno: 0,
        formaPago: 0
      });
      this.showBtnGuardar = false;
      this.showMensajeError = false;
      switch (value) {
        case 0: {
          this.disabledBanderas();
          this.motivo.setValidators(null);
          this.monto.setValidators(null);
          this.tipoServicio.setValidators(null);
          this.paqueteServicio.setValidators(null);
          this.fechaPago.setValidators(null);
          this.nombreBanco.setValidators(null);
          this.referenciaNumerica.setValidators(null);
          this.nombreTienda.setValidators(null);
          this.periodo.setValidators(null);
          this.campus.setValidators(null);
          this.programa.setValidators(null);
          this.tipoAlumno.setValidators(null);
          this.nuevoMovimientoForm.patchValue({
            monto: 0,
            motivo: '',
            tipoServicio: 0,
            paqueteServicio: 0,
            periodo: 0,
            campus: 0,
            tipoAlumno: 0,
            formaPago: 0
          });
          this.showBtnGuardar = false;
          break;
        }
        case 1: {
          this.showMonto = true;
          this.showMotivo = true;
          this.showMensaje = true;
          this.showTablaMovimientos = true;
          this.disabledMonto = true;
          this.showBtnGuardar = true;
          this.getPagosPendientes();
          this.monto.setValidators([Validators.required, Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]);
          this.motivo.setValidators([Validators.required]);
          break;
        }
        case 2: {
          this.showTipoServicio = true;
          this.disabledMonto = true;
          this.tipoServicio.setValidators([Validators.required, this.getErrorFormSelect]);
          break
        }
        case 3: {
          this.showMonto = true;
          this.showMotivo = true;
          this.showMensaje = true;
          this.showTablaMovimientos = true;
          this.disabledMonto = true;
          break;
        }
        case 4: {
          this.showMotivoCancelacion = true;
          this.showMensaje = true;
          this.showTablaMovimientosCancelacion = true;
          this.showBtnGuardar = true;
          this.getMovimientosCancelacion();
          this.motivo.setValidators([Validators.required]);
          break;
        }
        case 5: {
          this.showPeriodo = true;
          this.showCampus = true;
          this.showPrograma = true;
          this.showTipoAlumno = true;
          this.showMontoInscripcion = true;
          this.disabledMonto = true;
          this.showBtnGuardar = true;
          this.periodo.setValidators([Validators.required, this.getErrorFormSelect]);
          this.campus.setValidators([Validators.required, this.getErrorFormSelect]);
          this.programa.setValidators([Validators.required, this.getErrorFormSelect]);
          this.tipoAlumno.setValidators([Validators.required, this.getErrorFormSelect]);
          this.monto.setValidators([Validators.required, Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]);
          if (this.datosUsuarioBusqueda != null) {
            if (this.datosAlumno.id_Periodo != undefined) {
              this.nuevoMovimientoForm.patchValue({
                tipoServicio: 0,
                paqueteServicio: 0,
                periodo: this.datosAlumno.id_Periodo,
                campus: this.datosAlumno.id_Campus,
                programa: this.datosAlumno.id_Programa,
                tipoAlumno: 1,
              });
            } else {
              this.nuevoMovimientoForm.patchValue({
                tipoServicio: 0,
                paqueteServicio: 0,
                periodo: this.datosAlumno.id_Periodo,
                campus: this.datosAlumno.id_Campus,
                programa: this.datosAlumno.id_Programa,
                tipoAlumno: 1,
              });
            }


          }
          break;
        }
        case 6: {
          this.showFormaPago = true;
          this.getPagosPendientes();
          break;
        }
        case 7: {
          this.showPaqueteServicio = true;
          this.paqueteServicio.setValidators([Validators.required, this.getErrorFormSelect]);
          if (this.mostrarPeriodoPorModulo) {
            this.periodo.setValidators([Validators.required, this.getErrorFormSelect]);
            if (this.datosAlumno.id_Periodo != undefined) {
              this.nuevoMovimientoForm.patchValue({
                periodo: this.datosAlumno.id_Periodo,
              });
            }
          }
          break;
        }
        default: {
          break;
        }
      }
    });
    this.tipoServicio.valueChanges.subscribe((value: number) => {
      this.showMonto = false;
      this.showFormaCargo = false;
      this.nuevoMovimientoForm.patchValue({
        monto: '',
        formaCargo: true
      });
      this.showNombreBanco = false;
      this.showMontoFormaPago = false;
      this.showNombreTienda = false;
      this.showReferenciaNumerica = false;
      this.showFechaPago = false;
      this.seguimientoCarterasService.obtenerInfoServicio(value).subscribe(
        (servicio: ApiResponse<ServicioCartera>) => {
          if (servicio.data !== null) {
            this.servicio = servicio.data;
            this.numeroParcialidad = this.servicio.servicioCosto[0].parcialidades;
            if (this.numeroParcialidad > 0) {
              this.showMonto = true;
              this.showFormaCargo = true;
              this.showBtnGuardar = true;
            } else {
              this.showMonto = true;
              this.showBtnGuardar = true;
            }
            this.nuevoMovimientoForm.patchValue({
              monto: this.servicio.servicioCosto[0].costo + this.servicio.servicioCosto[0].impuesto,
            });
          }
        });
    });
    this.paqueteServicio.valueChanges.subscribe((value: number) => {
      if (value != 0) {
        this.servicioPaqueteService.detallePaqueteServicio(this.datosAlumno.id_Campus, this.nivelId, this.datosAlumno.id_Periodo, this.datosAlumno.id_Programa, this.datosAlumno.alumnoId, value).subscribe((response: ApiResponse<DetallePaqueteServicio[]>) => {
          if (response.success) {
            this.showTablaDetallePaquete = true;
            this.showBtnGuardar = true;
            this.dataSource = new MatTableDataSource(response.data);
            // setTimeout(() => {
            //   this.dataSource.paginator = this.paginator;
            // }, 100);
          } else {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al traer el detalle del paquete de servicios.');
          }
        });
      }
    });
    this.formaPago.valueChanges.subscribe((value: number) => {
      this.monto.setValidators([Validators.required, Validators.pattern(/^[0-9]+([.][0-9]+)?$/)]);
      this.fechaPago.setValidators([Validators.required]);
      this.showNombreBanco = false;
      this.showMontoFormaPago = false;
      this.showNombreTienda = false;
      this.showReferenciaNumerica = false;
      this.showFechaPago = false;
      this.showMensaje = false;
      this.showTablaMovimientos = false;
      this.showTablaMovimientosCancelacion = false;
      this.showTablaDetallePaquete = false;
      this.disabledMonto = true;
      this.showBtnGuardar = true;
      switch (value) {
        case 0: {
          break;
        }
        case 1: {
          this.showMontoFormaPago = true;
          this.showFechaPago = true;
          this.showMensaje = true;
          this.showTablaMovimientos = true;
          break;
        }
        case 2: {
          this.showMontoFormaPago = true;
          this.showNombreBanco = true;
          this.showReferenciaNumerica = true;
          this.showFechaPago = true;
          this.showMensaje = true;
          this.showTablaMovimientos = true;
          this.nombreBanco.setValidators([Validators.required]);
          this.referenciaNumerica.setValidators([Validators.required]);
          break;
        }
        case 3: {
          this.showMontoFormaPago = true;
          this.showNombreBanco = true;
          this.showReferenciaNumerica = true;
          this.showFechaPago = true;
          this.showMensaje = true;
          this.showTablaMovimientos = true;
          this.nombreBanco.setValidators([Validators.required]);
          this.referenciaNumerica.setValidators([Validators.required]);
          break;
        }
        case 4: {
          this.showMontoFormaPago = true;
          this.showNombreBanco = true;
          this.showReferenciaNumerica = true;
          this.showFechaPago = true;
          this.showMensaje = true;
          this.showTablaMovimientos = true;
          this.nombreBanco.setValidators([Validators.required]);
          this.referenciaNumerica.setValidators([Validators.required]);
          break;
        }
        case 5: {
          this.showMontoFormaPago = true;
          this.showNombreBanco = true;
          this.showReferenciaNumerica = true;
          this.showFechaPago = true;
          this.showMensaje = true;
          this.showTablaMovimientos = true;
          this.nombreBanco.setValidators([Validators.required]);
          this.referenciaNumerica.setValidators([Validators.required]);
          break;
        }
        case 6: {
          this.showMontoFormaPago = true;
          this.showNombreTienda = true;
          this.showReferenciaNumerica = true;
          this.showFechaPago = true;
          this.showMensaje = true;
          this.showTablaMovimientos = true;
          this.nombreTienda.setValidators([Validators.required]);
          this.referenciaNumerica.setValidators([Validators.required]);
          break;
        }
        default: {
          break;
        }
      }
    });
    this.campus.valueChanges.subscribe((campus: number) => {
      this.campusValue = campus;
      if (this.periodoValue !== 0 && this.campusValue !== 0 && this.programaValue !== 0 && this.tipoAlumnoValue !== 0) {
        this.buscarMontoInscripcion();
      }
      this.onCampusSelected(campus);
      this.nuevoMovimientoForm.patchValue({
        programa: 0
      });
    });
    this.periodo.valueChanges.subscribe((periodo: number) => {
      this.periodoValue = periodo;
      if (this.periodoValue !== 0 && this.campusValue !== 0 && this.programaValue !== 0 && this.tipoAlumnoValue !== 0) {
        this.buscarMontoInscripcion();
      }
    });
    this.programa.valueChanges.subscribe((programa: number) => {
      this.programaValue = programa;
      if (this.periodoValue !== 0 && this.campusValue !== 0 && this.programaValue !== 0 && this.tipoAlumnoValue !== 0) {
        this.buscarMontoInscripcion();
      }
    });
    this.tipoAlumno.valueChanges.subscribe((tipoAlumno: number) => {
      this.tipoAlumnoValue = tipoAlumno;
      if (this.periodoValue !== 0 && this.campusValue !== 0 && this.programaValue !== 0 && this.tipoAlumnoValue !== 0) {
        this.buscarMontoInscripcion();
      }
    });
  }

  /**
   * Obtiene los valores del formulario para su validacion posterior
   */
  get tipoMovimiento() { return this.nuevoMovimientoForm.get('tipoMovimiento'); }
  get tipoServicio() { return this.nuevoMovimientoForm.get('tipoServicio'); }
  get paqueteServicio() { return this.nuevoMovimientoForm.get('paqueteServicio'); }
  get monto() { return this.nuevoMovimientoForm.get('monto'); }
  get motivo() { return this.nuevoMovimientoForm.get('motivo'); }
  get periodo() { return this.nuevoMovimientoForm.get('periodo'); }
  get campus() { return this.nuevoMovimientoForm.get('campus'); }
  get programa() { return this.nuevoMovimientoForm.get('programa'); }
  get tipoAlumno() { return this.nuevoMovimientoForm.get('tipoAlumno'); }
  get formaPago() { return this.nuevoMovimientoForm.get('formaPago'); }
  get nombreBanco() { return this.nuevoMovimientoForm.get('nombreBanco'); }
  get nombreTienda() { return this.nuevoMovimientoForm.get('nombreTienda'); }
  get referenciaNumerica() { return this.nuevoMovimientoForm.get('referenciaNumerica'); }
  get fechaPago() { return this.nuevoMovimientoForm.get('fechaPago'); }

  /**
   * Valida si el select esta con el valor 0 y mostrar un mensaje de error si lo muestra
   * @param control
   * @returns
   */
  public getErrorFormSelect(control: AbstractControl): any {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
   * seleccionados para modificar el check
   * @returns boolean para conocer si todos estan seleccionados
   */
  isAllSelected(): boolean {
    this.numMovimientosSeleccionado = this.selection.selected.length;
    const numRows = this.cargosPendientes.length;
    return this.numMovimientosSeleccionado === numRows;
  }

  /**
   * La etiqueta de la casilla de verificación de la fila pasada
  */
  checkboxLabel(row?: EstadoCuentaDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.movimientoId + 1}`;
  }

  /**
   * Selecciona todas las columnas si no lo estan, o bien limpia la seleccion
   */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.cargosPendientes.forEach(row => this.selection.select(row));
  }

  /**
   * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
   * seleccionados para modificar el check
   * @returns boolean para conocer si todos estan seleccionados
  */
  isAllSelectedCancel(): boolean {
    this.numMovimientosSeleccionado = this.selection.selected.length;
    const numRows = this.movimientosCancelacion.length;
    return this.numMovimientosSeleccionado === numRows;
  }

  /**
   * La etiqueta de la casilla de verificación de la fila pasada
  */
  checkboxLabelCancel(row?: EstadoCuentaDto): string {
    if (!row) {
      return `${this.isAllSelectedCancel() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.movimientoId + 1}`;
  }

  /**
   * Selecciona todas las columnas si no lo estan, o bien limpia la seleccion
   */
  masterToggleCancel(): void {
    this.isAllSelectedCancel() ? this.selection.clear() : this.movimientosCancelacion.forEach(row => this.selection.select(row));
  }


  public nuevoMovimientoBtn() {
    this.nuevoMovimientoForm.patchValue({
      tipoMovimiento: 6
    });
  }

  /**
   * Obtiene los datos registrados en el catalogo tipo de pago
   */
  public getTipoFormaPago(): void {
    this.subscriptionTipoFormaPago = this.configuracionCuotaService.obtenerFormaPagoByInstitucion(environment.enves[baseUrl].institucion).subscribe(
      (tipoFormaPago: TipoFormaPago[]) => {
        this.tipoFormaPagoList = tipoFormaPago.filter(x => x.activo === true);
      });
  }

  /**
   * Obtiene los tipos de servicios que existen en el catalogo
   */
  public getTipoServicio(): void {
    this.subscriptionTipoServicio = this.configuracionCuotaService.getServiciosNombres(this.datosAlumno.id_Campus, this.nivelId).subscribe(
      (response: ApiResponse<TipoServicio[]>) => {
        this.tipoServicioList = response.data;
      });
  }

  /**
   * Obtiene los tipos de servicios que existen en el catalogo
   */
  public getPaquetes(): void {
    this.subscriptionTipoServicio = this.servicioPaqueteService.getListaPaquetes().subscribe(
      (response: ApiResponse<ServicePackageSeguimientoDto[]>) => {
        this.listPaquetes = response.data;
      });
  }

  /**
   * Obtiene el catalogo de campus
   */
  public getTipoCampus(): void {
    this.subscritpionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      });
  }

  /**
   * Obtiene el catalogo de periodo
   */
  public getTipoPeriodo(): void {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoList = periodo;
      });
  }

  /**
   * Segun el campus seleccionado es como se muestran los programas que pertenecen a dicho campus
   * @param campus
   */
  public onCampusSelected(campus: number): void {
    this.programaList = [];
    if (!!campus) {
      this.campusService.obtenerProgramas(campus).subscribe(
        (programas: Programa[]) => {
          this.programaList = programas;
        })
    }
  }

  /**
   * Deshabilita banderas que hacen que se muestre ciertos divs en la pantalla
   */
  public disabledBanderas(): void {
    this.showMonto = false;
    this.showMotivo = false;
    this.showTipoServicio = false;
    this.showFormaCargo = false;
    this.showPeriodo = false;
    this.showCampus = false;
    this.showPrograma = false;
    this.showTipoAlumno = false;
    this.showMontoInscripcion = false;
    this.showMotivoCancelacion = false;
    this.showMensaje = false;
    this.showTablaMovimientos = false;
    this.showTablaMovimientosCancelacion = false;
    this.showTablaDetallePaquete = false;
    this.showFormaPago = false;
    this.showNombreBanco = false;
    this.showMontoFormaPago = false;
    this.showNombreTienda = false;
    this.showReferenciaNumerica = false;
    this.showFechaPago = false;
    this.showBtnGuardar = false;
    this.showMensajeError = false;
    this.showPaqueteServicio = false;
  }

  /**
   * Funcion que registra un nuevo movimiento mandando los datos del formulario
   */
  public registrarMovimiento(): void {
    let formNuevoMovimiento = Object.assign(this.nuevoMovimientoForm.value);
    switch (formNuevoMovimiento.tipoMovimiento) {
      case 1: {
        this.enviarNuevoDescuento(formNuevoMovimiento);
        break;
      }
      case 2: {
        this.enviarServicio(formNuevoMovimiento);
        break;
      }
      case 3: {
        break;
      }
      case 4: {
        this.enviarMovimientosCancelacion(this.selection.selected, formNuevoMovimiento);
        break;
      }
      case 5: {
        this.enviarInscripcion(formNuevoMovimiento);
        break;
      }
      case 6: {
        this.enviarNuevoPago(formNuevoMovimiento);
        break;
      }
      case 7: {
        this.enviarPaqueServicio();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Funcion que agrega un nuevo movimiento de tipo descuento a los registros del alumno
   * @param formValue
   */
  public enviarNuevoDescuento(formValue: MovimientosPagos): void {
    this.cargosPendientes = this.cargosPendientes.filter(x => x.saldoAbono > 0);

    this.cargosPendientes = this.cargosPendientes.map(obj =>
    ({
      ...obj,
      formaPago: 7,
      montoTotalAbonoMovimiento: +formValue.monto,
      comentario: formValue.motivo
    })
    );

    this.seguimientoCarterasService.enviarMovimientoTipoDescuento(this.cargosPendientes).subscribe(
      (DescuentoRegistrado: SimpleResponse) => {
        if (DescuentoRegistrado.success) {
          this.seguimientoCarterasService.invocarMetodoObtenerSeguimientoCartera();
          this.actualizarCartera();
          this.getPagosPendientes();
          this.snackService.mostrarSnackBack('Se ha registrado el descuento exitosamente.');
        } else {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar el descuento. Por favor, intente de nuevo más tarde.');
        }
      });
  }

  /**
 * Funcion que agrega un nuevo movimiento de tipo servicio a los registros del alumno
 * @param formValue
 */
  public enviarServicio(formValue: MovimientosPagos): void {
    let servicio: ServiciosPago = {
      usuarioId: formValue.usuarioId,
      tipoCargoId: 5,
      monto: this.servicio.servicioCosto[0].costo + this.servicio.servicioCosto[0].impuesto,
      descripcion: `Servicio (${this.servicio.nombre})`,
      fechaVencimiento: new Date(),
      alumnoId: this.datosAlumno.alumnoId,
      periodoId: this.datosAlumno.id_Periodo,
      formaCargo: formValue.formaCargo,
      parcialidades: this.servicio.servicioCosto[0].parcialidades
    }
    this.seguimientoCarterasService.enviarServicio(servicio).subscribe(
      (servicioRegistrado: SimpleResponse) => {
        if (servicioRegistrado.success) {
          this.seguimientoCarterasService.invocarMetodoObtenerSeguimientoCartera();
          this.actualizarCartera();
          let mensaje: string = this.traductorService.translate('servicioRegistrado');
          this.snackService.mostrarSnackBack(mensaje);
        } else {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar el servicio. Por favor, intente de nuevo más tarde.');
        }
      });
  }

  /**
   * Funcion que agrega un nuevo movimiento de tipo inscripcion a los registros del alumno
   * @param formValue
   */
  public enviarInscripcion(formValue: MovimientosPagos): void {


    let inscripcion: InscripcionPago = {
      usuarioId: formValue.usuarioId,
      tipoCargoId: 2,
      monto: this.inscripcion.costo + this.inscripcion.impuesto,
      descripcion: `Inscripción ${this.inscripcion.periodo.nombre}`,
      fechaVencimiento: this.inscripcion.fechaInicio,
      alumnoId: this.datosAlumno.alumnoId,
      periodoId: this.datosAlumno.id_Periodo,
      PeriodoSeleccionadoInscripcion: formValue.periodo
    }

    this.seguimientoCarterasService.enviarInscripcion(inscripcion).subscribe(
      (respuestaInscripcion: SimpleResponse) => {
        if (respuestaInscripcion.success) {
          this.seguimientoCarterasService.invocarMetodoObtenerSeguimientoCartera();
          this.actualizarCartera();
          //#region Actualizar mensaje en Selección de Materias
          this.admisionesService.invocarMetodoValidarCuotaInscripcion();
          //#endregion
          this.snackService.mostrarSnackBack(respuestaInscripcion.message);
        } else {
          this.snackService.mostrarSnackBack(respuestaInscripcion.message);
        }
      });
  }

  /**
 * Funcion que agrega un nuevo movimiento de tipo pago a los registros del alumno
 * @param formValue
 */
  public enviarNuevoPago(formValue: MovimientosPagos): void {
    this.cargosPendientes = this.cargosPendientes.filter(x => x.saldoAbono > 0);

    this.cargosPendientes = this.cargosPendientes.map(obj =>
    ({
      ...obj,
      formaPago: formValue.formaPago,
      montoTotalAbonoMovimiento: +formValue.monto,
      nombreBanco: formValue.nombreBanco,
      referenciaNumerica: formValue.referenciaNumerica,
      fechaPago: formValue.fechaPago
    })
    );

    this.seguimientoCarterasService.enviarMovimientoTipoPago(this.cargosPendientes).subscribe(
      (movimientoRegistrado: SimpleResponse) => {
        if (movimientoRegistrado.success) {
          this.seguimientoCarterasService.invocarMetodoObtenerSeguimientoCartera();
          this.actualizarCartera();
          this.getPagosPendientes();
          //#region Actualizar mensaje en Selección de Materias
          this.admisionesService.invocarMetodoValidarCuotaInscripcion();
          //#endregion
          this.snackService.mostrarSnackBack('Se ha registrado el pago exitosamente.');


        } else {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar el pago. Por favor, intente de nuevo más tarde.');
        }
      });
  }

  /**
   * Metodo para registrar un paquete de servicios
   */
  public enviarPaqueServicio(): void {
    let cargosAplicables: DetallePaqueteServicio[] = this.dataSource.data.filter(x => x.aplicaServicio);
    let formNuevoMovimiento = Object.assign(this.nuevoMovimientoForm.value);
    console.log("formNuevoMovimiento", formNuevoMovimiento);
    
    let PeriodoEnviado: number;
    if (this.mostrarPeriodoPorModulo) {
      PeriodoEnviado = formNuevoMovimiento.periodo;
    } else {
      PeriodoEnviado = this.datosAlumno.id_Periodo;
    }
    
    console.log("PeriodoEnviado", PeriodoEnviado);

    this.servicioPaqueteService.enviarPaqueteCargo(this.datosAlumno.id_Campus, this.datosAlumno.id_Programa, PeriodoEnviado, this.datosAlumno.alumnoId, cargosAplicables).subscribe((paqueteRegistrado: SimpleResponse) => {
      if (paqueteRegistrado.success) {
        this.seguimientoCarterasService.invocarMetodoObtenerSeguimientoCartera();
        this.actualizarCartera();
        let mensaje: string = this.traductorService.translate('paqueteRegistro');
        this.snackService.mostrarSnackBack(mensaje);
      } else {
        this.snackService.mostrarSnackBack(`Ha ocurrido un error al guardar el pago. Por favor, intente de nuevo más tarde. ${paqueteRegistrado.message}`);
      }
    });
  }

  /**
   * Funcion que agrega busca el nuevo monto de la inscripcion que tiene el alumno
   */
  public buscarMontoInscripcion(): void {
    let busqueda: MontoInscripcion = {
      PeriodoId: this.periodoValue,
      campusId: this.campusValue,
      programaId: this.programaValue,
      tipoAlumnoId: this.tipoAlumno.value == 1 ? 'NI' : 'RI',
    }
    this.seguimientoCarterasService.buscarCostoInscripcion(busqueda).subscribe(
      (respuestaInscripcion: ApiResponse<InscripcionCosto>) => {
        if (respuestaInscripcion.data !== null) {
          this.inscripcion = respuestaInscripcion.data;
          this.nuevoMovimientoForm.patchValue({
            monto: respuestaInscripcion.data.costo + respuestaInscripcion.data.impuesto
          });
        } else {
          let mensaje = this.traductorService.translate('_seguimientoCartera.movimientos.inscripcion-costo-no-encontrado');
          this.snackService.mostrarSnackBack(mensaje);
          this.nuevoMovimientoForm.patchValue({
            monto: 0
          });
        }
      });
  }

  /**
   * Funcion que obtiene los pagos pendientes que no ha realizado el alumno
   */
  public getPagosPendientes() {
    this.subscriptionTipoFormaPago = this.seguimientoCarterasService.obtenerCargosPendientesByUsuarioId(this.alumnoSeleccionado).subscribe(
      (cargosPendientes: ApiResponse<EstadoCuentaDto[]>) => {
        console.log("this.alumnoSeleccionado", this.alumnoSeleccionado)
        console.log("cargosPendientes", cargosPendientes)
        this.cargosPendientes = cargosPendientes.data;
        this.dataSource = new MatTableDataSource(this.cargosPendientes);
        this.dataSource.paginator = this.paginator;
        this.selection = new SelectionModel<EstadoCuentaDto>(true, []);

      });
  }

  /**
 * Funcion que obtiene todas las cancelaciones de parte del usuario
 */
  public getMovimientosCancelacion() {
    this.seguimientoCarterasService.obtenerMovimientosCancelacionByIdUsuario(this.datosSeguimientoUsuario.usuarioId).subscribe(
      (movimientosCancelacion: ApiResponse<EstadoCuentaDto[]>) => {
        this.movimientosCancelacion = movimientosCancelacion.data;
        this.dataSource = new MatTableDataSource(this.movimientosCancelacion);
        this.dataSource.paginator = this.paginator;
        this.selection = new SelectionModel<EstadoCuentaDto>(true, []);

      });
  }

  /**
   * Registra un saldo abonado a la cuenta
   * @param valorSaldoAbono
   * @param element
   * @param i
   */
  public enviarSaldoAbono(valorSaldoAbono, element, i): void {
    let valorSaldoNum = +valorSaldoAbono;
    let cargo = this.cargosPendientes.find(x => x.cargoId === element.cargoId);
    cargo.saldoAbono = valorSaldoNum;

    if (cargo.saldoAbono > cargo.saldoActual) {
      this.showMensajeError = true;
    } else {
      this.showMensajeError = false;
    }

    let sumaCreditosTotal = this.cargosPendientes.reduce((prev, curr) => prev + curr.saldoAbono, 0);
    this.nuevoMovimientoForm.patchValue({
      monto: sumaCreditosTotal
    });
  }

  /**
  * Registra un saldo abonado a la cuenta
  * @param valorSaldoAbono
  * @param element
  * @param i
  */
  public enviarParcialidadDetalle(valor, element, i): void {
    let elementoEncontrado = this.dataSource.data.find(x => x.servicioId === element.servicioId);
    elementoEncontrado.pagoParcialidades = valor === 1 ? false : true;
    let otrosServicios = this.dataSource.data.filter(x => x.servicioId !== element.servicioId);
    otrosServicios.splice(i, 0, elementoEncontrado);
    this.dataSource.data = otrosServicios;
  }

  /**
   * Registra si una cancelacion fue exitosa
   * @param movimientosSelected
   * @param formNuevoMovimiento
   */
  public enviarMovimientosCancelacion(movimientosSelected: EstadoCuentaDto[], formNuevoMovimiento: MovimientosPagos): void {
    let movimientosCancelacion = movimientosSelected.map(obj =>
    ({
      ...obj,
      alumnoId: this.datosAlumno.alumnoId,
      periodoId: this.datosAlumno.id_Periodo,
      comentario: formNuevoMovimiento.motivo
    })
    );
    this.seguimientoCarterasService.enviarMovimientosCancelados(movimientosCancelacion).subscribe(
      (movimientosRegistrados: SimpleResponse) => {
        if (movimientosRegistrados.success) {
          this.seguimientoCarterasService.invocarMetodoObtenerSeguimientoCartera();
          this.actualizarCartera();
          this.getMovimientosCancelacion();
          this.snackService.mostrarSnackBack('Se ha hecho la cancelación exitosamente.');
        } else {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la cancelación. Por favor, intente de nuevo más tarde.');
        }
      });
  }

  /**
   * Actualiza toda la pantalla para conocer los nuevos datos registrados
   */
  public actualizarCartera(): void {
    let usuarioId = this.datosSeguimientoUsuario.usuarioId;
    let periodoId = this.datosAlumno.id_Periodo;

    this.seguimientoCarterasService.obtenerEstadoCuentaByUsuarioId(usuarioId, periodoId, this.datosAlumno.alumnoId).subscribe(
      (estadoCuenta: EstadoCuentaDto[]) => {
        this.seguimientoCarterasService.setDatosEstadoCuentaUsuario$(estadoCuenta);
      });

    this.seguimientoCarterasService.obtenerResumenEstadoCuentaByUsuarioId(this.datosAlumno.alumnoId).subscribe(
      (resumen: ApiResponse<ResumenEstadoCuentaDto>) => {
        if (resumen.data.saldoVencido > 0) {
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-1');
          this.seguimientoCarterasService.setDatoEstatus$(mensaje);
        } else {
          let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-2');
          this.seguimientoCarterasService.setDatoEstatus$(mensaje);
        }
        this.seguimientoCarterasService.setDatosResumenUsuario$(resumen.data);
      });
  }

  /**
   * Se manda a llamar cuando se cierra el componente y se desuscribe de todos los select elegidos
   */
  ngOnDestroy(): void {
    if (this.subscriptionTipoFormaPago) {
      this.subscriptionTipoFormaPago.unsubscribe();
    }
    if (this.subscritpionCampus) {
      this.subscritpionCampus.unsubscribe();
    }
    if (this.subscriptionPeriodo) {
      this.subscriptionPeriodo.unsubscribe();
    }
    if (this.subscriptionTipoServicio) {
      this.subscriptionTipoServicio.unsubscribe();
    }
  }

}
