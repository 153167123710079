
export class PERSONAL_INFORMATION_MODEL{
    nombre            : string;
    segundoNombre     : string;
    apellidoPaterno   : string;
    apellidoMaterno   : string;
    fechaNacimiento   : Date;
    constructor(
      nombre            : string,
      segundoNombre     : string,
      apellidoPaterno   : string,
      apellidoMaterno   : string,
      fechaNacimiento   : Date
    )
    {
      this.nombre           = nombre         ;
      this.segundoNombre    = segundoNombre  ;
      this.apellidoPaterno  = apellidoPaterno;
      this.apellidoMaterno  = apellidoMaterno;
      this.fechaNacimiento  = fechaNacimiento;
    }
  }