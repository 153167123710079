import { InsitucionService } from './../../../_services/insitucion.service';
import { baseUrl, environment } from './../../../../environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Ciclo } from 'src/app/_models/ciclo';
import { InformacionGrupoAsistencia, ListaAlumnosGrupos } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { ModalCorreoComponent } from '../../../ensenanza-aprendizaje-alumno/vista-contenido/mi-grupo/modal-correo/modal-correo.component';
import { EnviarCorreoComponent } from '../../../_components/enviar-correo/enviar-correo.component';
import { InfoPrograma } from '../../../_models/infoPrograma';
import { EnsenanzaAprendizajeService } from '../../../_services/ensenanza-aprendizaje.service';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service';
import { UtileriasService } from '../../../_services/utilerias.service';
import { TomaAsistenciaComponent } from './toma-asistencia/toma-asistencia.component';
@Component({
  selector: 'app-contenido-mi-grupo',
  templateUrl: './contenido-mi-grupo.component.html',
  styleUrls: ['./contenido-mi-grupo.component.scss']
})
export class ContenidoMiGrupoComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['select', 'id', 'nombre', 'programa', 'periodo', 'selectpg'];
  dataSource: MatTableDataSource<ListaAlumnosGrupos>;
  selection = new SelectionModel<ListaAlumnosGrupos>(true, []);
  @Input() grupoID: number;

  public listaAlumnos: ListaAlumnosGrupos[] = [];
  public informacionGrupo: InformacionGrupoAsistencia;
  public infoCargado: boolean = false;
  public infoGrupoCargado: boolean = false;
  public datafilter: ListaAlumnosGrupos[] = [];
  public numAlumnosSeleccionado: number = 0;
  public Programas: InfoPrograma[] = [];
  public filtroForm: FormGroup;
  public ciclos: Ciclo[] = [];
  //Tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;
  public lmsProviderId: number;

  constructor(private utileriaService: UtileriasService,
    private ensenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private ensenanzaAprendizajeService: EnsenanzaAprendizajeService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private institucionService: InsitucionService) { }

  ngOnInit(): void {
    this.obtenerInformacionInstitucion();
    this.obtenerInfoGrupo(this.grupoID);
    this.obtenerAlumnosByGrupo(this.grupoID);
    this.obtenerProgramas();
    this.inicializarForm();
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  /**
   * Obtiene la informacion de la insitutcion
   */
  public obtenerInformacionInstitucion(): void {
    this.institucionService.obtenerInformacionInstitucion(this.tenantId).subscribe((response: any) => {
      this.lmsProviderId = response.lmsProviderId;
    })
  }

  public tomarAsistencia(): void {
    this.utileriaService.abrirDialogoLateral(TomaAsistenciaComponent, this.grupoID);
  }

  public obtenerInfoGrupo(grupoId: number): void {
    this.ensenanzaAprendizajeService.obtenerInfoGrupoAsistencia(grupoId).subscribe(
      (info: InformacionGrupoAsistencia) => {
        if (info !== null) {
          this.informacionGrupo = info;
          this.informacionGrupo.nombreProfesor = `${this.informacionGrupo?.profesor?.nombre ?? ''} ${this.informacionGrupo?.profesor?.apellidoPaterno ?? ''} ${this.informacionGrupo?.profesor?.apellidoMaterno ?? ''}`;
          this.infoGrupoCargado = true;
        } else {

        }
      });
  }

  public obtenerAlumnosByGrupo(grupoId: number): void {
    this.ensenanzaAprendizajeAlumnoService.obtenerAlumnos(grupoId).subscribe(
      (alumnos: ApiResponse<ListaAlumnosGrupos[]>) => {
        this.listaAlumnos = alumnos.data;
        this.dataSource = new MatTableDataSource(alumnos.data);
        this.dataSource.paginator = this.paginator;
        this.selection = new SelectionModel<ListaAlumnosGrupos>(true, []);
        this.datafilter = alumnos.data;
        this.infoCargado = true;
      });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public GetCicloByProgramaID(grupoId: number) {
    this.ensenanzaAprendizajeService.GetCicloByProgramaID(grupoId).subscribe(
      (ciclos: ApiResponse<Ciclo[]>) => {
        this.ciclos = ciclos.data;
      });
  }
  public inicializarForm() {
    this.filtroForm = new FormGroup({
      idnombre: new FormControl(),
      programaAcademico: new FormControl(0),
      periodo: new FormControl(0)
    });
  }

  public filtrar() {
    var programa = this.filtroForm['value'].programaAcademico;
    var ciclo = this.filtroForm['value'].periodo;
    let filtroModificado: any = [];
    filtroModificado = this.listaAlumnos
    if (programa != 0) {
      filtroModificado = filtroModificado.filter(row => row.programaId === programa);
      this.GetCicloByProgramaID(programa);
    }
    if (ciclo != 0) {
      filtroModificado = filtroModificado.filter(row => row.cicloId === ciclo);
    }
    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
  }

  public limpiar() {
    this.inicializarForm();
    this.dataSource = new MatTableDataSource(this.datafilter);
    this.dataSource.paginator = this.paginator;
  }

  public obtenerProgramas() {
    this.ensenanzaAprendizajeAlumnoService.GetProgramasMigrupo(this.grupoID).subscribe(
      (programas: ApiResponse<InfoPrograma[]>) => {
        this.Programas = programas.data;
      }
    );
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.datafilter.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    if (this.datafilter) {
      this.numAlumnosSeleccionado = this.selection.selected.length;
      const numRows = this.datafilter.length;
      return this.numAlumnosSeleccionado === numRows;
    }
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }

  ExcelSubscription: Subscription;
  public descargarExcel(): void {
    this.ExcelSubscription = this.ensenanzaAprendizajeService.descargarExcelMiGrupo(this.listaAlumnos).subscribe((res: Blob) => {
      let filename: string = "Grupo.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        let a: HTMLAnchorElement = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        let blob: Blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
    });
  }

  public enviarCorreo(elemento?: any): void {
    if (elemento.length === 1) {
      var datos = elemento[0];
    } else {
      var datos = elemento;
    }
    this.dialog.open(EnviarCorreoComponent, { data: datos, disableClose: true });
  }
}
