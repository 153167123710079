import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from '../services/generacion-excel-service.service';
import { ImprimirServiceService } from '../services/imprimir-service.service';
import { HistorialAcademico } from '../_models/HistorialAcademico.model';
import { UsuarioIngreso } from '../_models/usuarionuevoingresodto';
import { AdminAcademicaService } from '../_services/admin-academica.service';
import { NuevoIngresoService } from '../_services/nuevo-ingreso.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { CampusService } from '../_services';
import { Campus, Periodo } from '../_models';
import { FormControl, FormGroup } from '@angular/forms';
import { Boleta } from '../_models/boleta.model';
import { AlumnoGrupoParcial } from '../_models/ensenanzaAprendizajeAlumno';
import { EnsenanzaAprendizajeService } from '../_services/ensenanza-aprendizaje.service';

@Component({
  selector: 'app-boleta-calificacion',
  templateUrl: './boleta-calificacion.component.html',
  styleUrls: ['./boleta-calificacion.component.scss']
})
export class BoletaCalificacionComponent implements OnInit {
  public base64Image: string;
  public imagePath: SafeResourceUrl;
  public cicloList: HistorialAcademico[] = [];
  public historial: HistorialAcademico[] = [];
  public materiasHistorial: Array<Boleta> = [];
  ExcelSubscription: Subscription;
  public infoCargado: boolean = false;
  public datosUsuario: any;
  public datosCompletoUsuario: any;
  public periodos: Periodo[] = [];
  public arCampus: Campus[] = [];
  public filtroForm: FormGroup;
  periodoForm = new FormControl();
  public selected: number;
  flagBoleta: boolean = false;
  flagBoletaCargaDatos: boolean = true;
  parciales: Array<number> = [];
  public identificadorAlumno: string = "";

  constructor(private nuevoIngresoService: NuevoIngresoService,
    private sanitizer: DomSanitizer,
    public adminAcademicaService: AdminAcademicaService,
    public imprimirService: ImprimirServiceService,
    public generadorExcel: GeneracionExcelServiceService,
    public usuarioService: AuthenticationService,
    private enseñanzaAprendizajeService: EnsenanzaAprendizajeService,
    public campusservice: CampusService,) {
    if (!!this.usuarioService.isAuthenticated) {
      this.enseñanzaAprendizajeService.obtenerUsuarioConectado().subscribe((datos) => {
        this.identificadorAlumno = sessionStorage.getItem('identificador');
        this.datosUsuario = this.usuarioService.obtenerDatosUsuario(this.identificadorAlumno).subscribe((data) => {
          this.datosCompletoUsuario = data;
          console.log("this.datosCompletoUsuario",this.datosCompletoUsuario);
          this.inicializarForm();
          this.llenarFiltros();
          this.cargarImagen();
        });
      });

    }
  }

  ngOnInit(): void {
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      periodoF: new FormControl(null)
    });
  }

  filtrar() {
    this.obtenerBoleta();
  }

  llenarFiltros() {
    this.adminAcademicaService.obtenerPeriodosByAlumno(this.datosCompletoUsuario.alumno[0].alumnoId).subscribe(
      (periodos: ApiResponse<any>) => {
        this.periodos = periodos.data;
        this.selected = this.periodos[0].periodoId;
        this.obtenerBoleta();
      }
    )
    this.campusservice.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.arCampus = campus;
      }
    )
  }

  public findNombrePeriodo(idPeriodo?: number) {

    return this.periodos.find(id => id.periodoId === idPeriodo)?.nombre;
  }

  public findNombreCampus(idCampus?: number) {
    return this.arCampus.find(id => id.campusId === idCampus).nombre;
  }

  obtenerBoleta() {
    this.adminAcademicaService.GetBoleta(this.datosCompletoUsuario.alumno[0].alumnoId, this.selected, this.datosCompletoUsuario.alumno[0].campusId, this.datosCompletoUsuario.alumno[0].programaId, this.datosCompletoUsuario.alumno[0].programa.nivelId).subscribe(
      (respuestaBoleta: ApiResponse<any>) => {
        this.materiasHistorial = null;
        this.materiasHistorial = respuestaBoleta.data;
        if (this.materiasHistorial !== null || this.materiasHistorial !== undefined) {
          this.parciales.length = 0;
          for (let i = 1; i <= this.materiasHistorial[0].parciales; i++) {
            this.parciales.push(i);
          }

          // this.materiasHistorial.forEach(boleta => {
          //   for (let i = boleta.alumnoGrupoParcial.length; i < this.materiasHistorial[0].parciales; i++) {
          //     const calificacionFalsa = new AlumnoGrupoParcial();
          //     calificacionFalsa.calificacion = -1;
          //     boleta.alumnoGrupoParcial.push(calificacionFalsa);
          //   }
          // });

          if (this.materiasHistorial.length <= 0) {
            this.flagBoletaCargaDatos = false;
          }

          this.flagBoleta = true;

        }
      });
    // que retorne algo
  }

  /**
   * Funcion que carga la imagen del usuario si tiene
  */
  public cargarImagen(): void {
    this.nuevoIngresoService.imagenUsuario(this.datosCompletoUsuario.usuarioId).subscribe(
      (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
        if (datosUsuarioImagen.success) {
          this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
        }
      }
    );
  }

  /**
  * Ejecuta el método del api que descarga la información que hay en pantalla
  */
  public descargarExcel() {
    this.ExcelSubscription = this.adminAcademicaService.descargarSheetDocumentHistorial(this.cicloList).subscribe((res: Blob) => {
      let filename = `HistorialAcademico${this.datosCompletoUsuario.nombre}${this.datosCompletoUsuario.apellidoPaterno}${this.datosCompletoUsuario.apellidoMaterno}`;
      this.generadorExcel.GenerarExcel(res, filename);
      this.ExcelSubscription.unsubscribe();
    })
  }

  public imprimir() {
    const printContent: HTMLElement = document.getElementById("historialExtra");
    let nombreAlumno = `${this.datosCompletoUsuario.nombre}${this.datosCompletoUsuario.apellidoPaterno}${this.datosCompletoUsuario.apellidoMaterno}`;
    this.imprimirService.Imprimir(printContent, nombreAlumno);
  }

}
