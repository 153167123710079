import { GeneracionExcelServiceService } from './../../../../../services/generacion-excel-service.service';
import { EdicionBloqueAlumnoEliminarComponent } from './edicion-bloque-alumno-eliminar/edicion-bloque-alumno-eliminar.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Block } from 'src/app/_models/block';
import { BlockService } from 'src/app/_services/block.service';
import { EdicionBloqueAlumnoAgregarComponent } from './edicion-bloque-alumno-agregar/edicion-bloque-alumno-agregar.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-edicion-bloque-alumno',
  templateUrl: './edicion-bloque-alumno.component.html',
  styleUrls: ['./edicion-bloque-alumno.component.scss']
})
export class EdicionBloqueAlumnoComponent implements OnInit {
  @Input() data: Block;
  public Subscription: Subscription = new Subscription();
  //Variables Obs
  public infoAlumnos$: Observable<any>;
  public infoAlumnos: any = null;





  selection = new SelectionModel<any>(true, []);
  public numAlumnoSeleccionado: number = 0;
  public dataSource: MatTableDataSource<any>;
  public datafilter: any;
  displayedColumns: string[] = ['select', 'ID', 'nombre', 'periodo', 'programa', 'campus', 'ciclo', 'inscrito'];
  public arrOriginal: any[] = [];
  public arrTemporales: any[] = [];
  public arrOriginalTemporales: any[] = [];




  public arregloFiltroOriginal: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public blockId: number;

  constructor(public blockService: BlockService,
    private dialog: MatDialog,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    private generadorExcel: GeneracionExcelServiceService,
  ) { }

  ngOnInit(): void {
    if (this.blockService.invMetodoObtenerBloqueAlumnoSubscription === undefined) {
      this.blockService.invMetodoObtenerBloqueAlumnoSubscription = this.blockService.invocarObtenerBloqueAlumno.subscribe(() => {
        this.obtenerBloqueAlumno();
      });
      this.blockService.invMetodoObtenerBloqueAlumnoSubscription = undefined;
    }

    this.blockService.invMetodoAnadirBloqueAlumnoSubscription = this.blockService.invocarAnadirBloqueAlumno.subscribe(() => {
      this.anadirAlumnosBloque();
    });





    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };




    this.obtenerBloqueAlumno();

  }

  public anadirAlumnosBloque() {
    this.infoAlumnos$ = this.blockService.GetAlumnosABloque$();
    this.Subscription.add(
      this.infoAlumnos$.subscribe(
        (info: any) => {
          this.infoAlumnos = info;

          if (this.infoAlumnos.length > 1) {
            this.infoAlumnos.forEach(value => {
              this.data.alumnosId = this.data.alumnosId ?? [];
              this.data.alumnosId.push(value.studentId)
              this.arrTemporales.push(value)

            });
          } else {
            this.data.alumnosId = this.data.alumnosId ?? [];
            this.data.alumnosId.push(this.infoAlumnos.studentId)
            this.arrTemporales.push(this.infoAlumnos);
          }


          this.actualizarTablaAlumno();

        })
    );

    this.eliminarSubscripcion();

  }


  public actualizarTablaAlumno() {
    this.arrOriginalTemporales = this.arrOriginal;
    this.arrOriginalTemporales = this.arrOriginalTemporales.concat(this.arrTemporales);
    this.dataSource = new MatTableDataSource(this.arrOriginalTemporales);
    this.dataSource.paginator = this.paginator;
    this.datafilter = this.arrOriginalTemporales;
    this.selection = new SelectionModel<any>(true, []);
    this.dataSource._updateChangeSubscription();
  }




  public obtenerBloqueAlumno() {
    this.blockService.ObtenerBloqueAlumno(this.data?.blockId).subscribe(response => {
      if (response.success) {

        let blockEncontrados: Block[] = response.data;
        this.arrOriginal = [...blockEncontrados];
        this.dataSource = new MatTableDataSource(blockEncontrados);
        this.datafilter = blockEncontrados;
        this.dataSource.paginator = this.paginator;
        this.arregloFiltroOriginal = response.data;
        this.selection = new SelectionModel<any>(true, []);
        this.blockId = response?.data[0]?.blockId ?? this.data?.blockId;


        let arrayalumnoIds = response.data.map(c => (c.studentId.toString()));

        this.data.alumnosId = [];

        arrayalumnoIds.forEach(value => {
          this.data.alumnosId.push(value)
        });

      }
    })
  }


  /**
    * Selecciona todas las columnas si no lo estan, o bien limpia la seleccion
    */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
   * seleccionados para modificar el check
   * @returns boolean para conocer si todos estan seleccionados
   */
  isAllSelected(): boolean {
    if (this.datafilter) {
      this.numAlumnoSeleccionado = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return this.numAlumnoSeleccionado === numRows;
    }
  }

  /**
   * La etiqueta de la casilla de verificación de la fila pasada
  */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.studentId + 1}`;
  }



  public borrar(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogoEliminar = this.dialog.open(EdicionBloqueAlumnoEliminarComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });

    dialogoEliminar.afterClosed().subscribe(result => {
      this.arrOriginalTemporales = this.arrOriginalTemporales.filter(item => !result.split(',').map(Number).includes(item.student.alumnoId));
      this.dataSource = new MatTableDataSource(this.arrOriginalTemporales);
      this.dataSource.paginator = this.paginator;
      this.datafilter = this.arrOriginalTemporales;
      this.selection = new SelectionModel<any>(true, []);
      this.dataSource._updateChangeSubscription();

    });
  }

  public descargarExcel(elemento?): void {
    let IdStudent;
    if (elemento != null && elemento.length > 0) {
      IdStudent = elemento.map(m => m.studentId.toString());
    } else {
      IdStudent = this.datafilter.map(m => m.studentId.toString());
    }

    let element = {
      idBlock: this.blockId,
      arrayIds: IdStudent
    }


    this.blockService.descargarExcelAlumno(element).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "Bloque Alumnos");
    });
  }


  public agregarAlumno(): void {

    const dialogoEliminar = this.dialog.open(EdicionBloqueAlumnoAgregarComponent, {
      data: this.data,
      panelClass: "dialogo-agregar",
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });

  }

  eliminarSubscripcion() {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }





}
