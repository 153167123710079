export class AvanceCursoInformacionAlumno {
    usuarioId: number;
    identificador: string;
    fotografia: string;
    nombreCompleto: string;
    nombre: string;
    segundoNombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    fechaNacimiento: Date;
    correo: string;
    sexoLegalId: number;
    alumnoId: number;
    fechaInicio: Date;
    fechaFin: Date;
    periodoId: number;
    periodoNombre: string;
    programaId: number;
    programaNombre: string;
    campusId: number;
    campusNombre: string;
    estatusId: number;
    tabSeleccionado: number;
}