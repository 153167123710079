import { Documento } from 'src/app/_models/documento';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { AdmisionRegistro } from '../_models/admisionRegistro';
import { Campus } from '../_models/campus';
import { InscripcionCosto } from '../_models/inscripcion-costo';
import { Periodo } from '../_models/periodo';
import { Programa } from '../_models/programa';
import { ValidarCostoInscripcionDto } from '../_models/validarCostoInscripcionDto';
import { CampusProgramaDTO } from '../_models/CampusProgramaDTO';
import { Imagen } from '../_models/usuario';

@Injectable({
  providedIn: 'root'
})
export class AdmisionesService {

  //Variables OBS
  private stepAdmision$ = new Subject<number>();
  private infoUsuario$ = new BehaviorSubject<any>(null);
  private existeApoyo$ = new BehaviorSubject<any>(false);
  private existeInscripcion$ = new BehaviorSubject<any>(false);
  private moduloAdmisionAlumno$ = new BehaviorSubject<any>(false);
  private institucion$ = new BehaviorSubject<any>(null);
  private existeFirma$ = new BehaviorSubject<any>(false);
  //Variables EventEmitter
  BackStep = new EventEmitter();
  BackStepSubscription: Subscription;
  NextStep = new EventEmitter();
  NextStepSubscription: Subscription;
  ChangeStep = new EventEmitter();
  ChangeStepSubscription: Subscription;
  ValidarApoyo = new EventEmitter();
  ValidarApoyoSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  //#region APIs

  /**
   * Metodo para traer el catalogo de campus
   * @param url url para filtrar los datos por institucion
   * @returns
   */
  public ObtenerCampusByUrl(institucionId: string): Observable<CampusProgramaDTO[]> {
    return this.httpClient.get<CampusProgramaDTO[]>(`${environment.enves[baseUrl].apiUrl}/Home/GetCampusByUrl/${institucionId}`);
  }

  /**
   * Metodo para traer el catalogo de programas
   * @param url url para filtrar los datos por institucion
   * @param campusId Campus seleccionado
   * @returns
   */
  public ObtenerProgramasByUrl(institucionId: string, campusId: number): Observable<Programa[]> {
    return this.httpClient.get<Programa[]>(`${environment.enves[baseUrl].apiUrl}/Home/GetProgramasByUrl/${institucionId}/${campusId}`);
  }

  /**
   * Metodo para traer el catalogo de periodos
   * @param url url para filtrar los datos por institucion
   * @returns
   */
  public ObtenerPeriodoByUrl(institucionId: string, programaId: number, campusId: number): Observable<Periodo[]> {
    return this.httpClient.get<Periodo[]>(`${environment.enves[baseUrl].apiUrl}/Home/GetPeriodoByUrl/${institucionId}/${programaId}/${campusId}`);
  }

  /**
   * Metodo para guardar el nuevo usuario
   * @param usuario
   * @returns
   */
  public EnviarResgistroAdmision(usuario: AdmisionRegistro, institucionId: string): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Admisiones/PostAdmisionUsuario/${institucionId}`, usuario);
  }

  /**
   * Metodo para obtener la infromacion del usuario
   * @param usuarioId
   * @returns
   */
  public GetInformacionUsuario(usuarioId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Admisiones/GetInformacionUsuario/${usuarioId}`);
  }

  /**
   * Metodo para actualizar datos de admision del usuario
   * @param usuario
   * @returns
   */
  public actualizarDatosAdmision(usuario: any): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Admisiones/PutDatosAdmision`, usuario);
  }

  /**
   * Metodo para obtener la infromacion de documentos del usuario
   * @param usuarioId
   * @returns
   */
  public GetDocumentosAdmision(usuarioId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Admisiones/GetDocumentosAdmision/${usuarioId}`);
  }

  /**
   * Metodo para obtener la infromacion de apoyo financiero del usuario
   * @param usuarioId
   * @returns
   */
  public obtenerApoyoFinancieroAdmision(usuarioId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Admisiones/ApoyoFinancieroAdmision/${usuarioId}`);
  }

  /**
   * Servicio que valida si existe un apoyo fiananciero para el campus selecciona
   * @param campusId
   * @returns
   */
  public validarExisteApoyoFinancieroByCampus(campusId: number): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/Admisiones/ValidarExisteApoyoFinancieroByCampus/${campusId}`);
  }

  /**
   * Servicio que valida si existe una cuota de inscripcion registrada
   * @param dataUser
   * @returns
   */
  public ValidarExisteCuotaInscripcion(dataUser: ValidarCostoInscripcionDto): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(`${environment.enves[baseUrl].apiUrl}/Admisiones/ValidarExisteCuotaInscripcion/${dataUser.campusId}/${dataUser.periodoId}/${dataUser.programaId}/${dataUser.tipoAlumno}`);
  }

  //#region Validar Cuota de Inscripción
  invocarValidarCuotaInscripcion = new EventEmitter();
  invocarMetodoValidarCuotaInscripcionSubscription: Subscription;
  /**
  * Invocar Metodo validarCuotaInscripcion
  */
  invocarMetodoValidarCuotaInscripcion() {
    this.invocarValidarCuotaInscripcion.emit();
  }
  //#endregion

  /**
   * Servicio que trae la inscripcion costo
   * @param dataUser
   * @returns
   */
  public obtenerInscripcionCosto(dataUser: ValidarCostoInscripcionDto): Observable<ApiResponse<InscripcionCosto>> {
    return this.httpClient.get<ApiResponse<InscripcionCosto>>(`${environment.enves[baseUrl].apiUrl}/Admisiones/ObtenerInscripcionCosto/${dataUser.campusId}/${dataUser.periodoId}/${dataUser.programaId}/${dataUser.tipoAlumno}`);
  }

  /**
   * Metodo para descargar un archivo excel con los grupos
   * @param grupos
   * @returns
   */
  public descargarExcelNomenclatura(grupos: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Admisiones/DownloadExcel`, JSON.stringify(grupos),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  /**
   * Valida Si existe o no un pago por colegiatura
   * @returns
   */
  public validarPagoColegiatura(): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Admisiones/ValidarPagoColegiatura`);
  }

  /**
   * Metodo para guardar la firma del usuario
   * @param signature 
   * @returns 
   */
  public studentSignature(signature: Imagen): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Admisiones/StudentSignature`, signature);
  };

  /**
 * Metodo para revisar si existe una firma asociada al alumno
 * @param alumnoId 
 * @returns 
 */
  public studentSignatureCheck(alumnoId: number): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Admisiones/StudentSignatureCheck/${alumnoId}`);
  };

  //#endregion

  //#region Observables

  /**
   * Servicio para settear un valor en el subject
   * @param stepAdmision
   */
  public SetStepAdmision(stepAdmision: number) {
    this.stepAdmision$.next(stepAdmision);
  }

  /**
   * Servicio para obtener el step como un obs
   * @returns
   */
  public GetStepAdmision$(): Observable<number> {
    return this.stepAdmision$.asObservable();
  }

  /**
   * Servicio para settear la informacion del usuario en el subject
   * @param infoUsuario
   */
  public SetInformacionUsuario(infoUsuario: any) {
    this.infoUsuario$.next(infoUsuario);
  }

  /**
   * Servicio para obtener la informacion del usuario como un obs
   * @returns
   */
  public GetInfoUsuario$(): Observable<any> {
    return this.infoUsuario$.asObservable();
  }

  /**
   * metodo para guardar la informacion de si existe el apoyo o no
   * @param existe
   */
  public setExisteApoyo(existe: boolean) {
    this.existeApoyo$.next(existe);
  }

  /**
   * Metodo que retorna el valor guardado en el obs de si existe apoyo
   * @returns
   */
  public getExisteApoyo$(): Observable<boolean> {
    return this.existeApoyo$.asObservable();
  }

    /**
   * metodo para guardar la informacion de si existe el apoyo o no
   * @param existe
   */
     public setExisteFirma(existe: boolean) {
      this.existeFirma$.next(existe);
    }
  
    /**
     * Metodo que retorna el valor guardado en el obs de si existe apoyo
     * @returns
     */
    public getExisteFirma$(): Observable<boolean> {
      return this.existeFirma$.asObservable();
    }


  /**
   * metodo para guardar la informacion de si existe la inscripcion o no
   * @param existe
   */
  public setExisteInscripcion(existe: boolean) {
    this.existeInscripcion$.next(existe);
  }

  /**
   * Metodo que retorna el valor guardado en el obs de si existe inscripcion
   * @returns
   */
  public getExisteInscripcion$(): Observable<boolean> {
    return this.existeInscripcion$.asObservable();
  }

  /**
   * Metodo que guarda un booleano para saber si esta dentro del modulo de admisiones
   * @param modulo
   * @returns
   */
  public setModuloAdmisionesAlumno(modulo: boolean): void {
    return this.moduloAdmisionAlumno$.next(modulo);
  }

  /**
   * Metodo que retorna el valor guardado en el obs de si existe inscripcion
   * @returns
   */
  public getModuloAdmisionesAlumno$(): Observable<boolean> {
    return this.moduloAdmisionAlumno$.asObservable();
  }

  /**
   * Metodo que guarda un booleano para saber si esta dentro del modulo de admisiones
   * @param modulo
   * @returns
   */
  public setInstitucion(institucion: any): void {
    return this.institucion$.next(institucion);
  }

  /**
   * Metodo que retorna el valor guardado en el obs de si existe inscripcion
   * @returns
   */
  public getInstitucion$(): Observable<any> {
    return this.institucion$.asObservable();
  }

  //#endregion

  //#region EventEmitter

  /**
   * Metodo que invoca eventemitter para volver con el stepper
   */
  public InvokeBackStep() {
    this.BackStep.emit();
  }

  /**
  * Metodo que invoca eventemitter para continuar con el stepper
  */
  public InvokeNextStep() {
    this.NextStep.emit();
  }

  /**
   * Metodo que invoca el envento de cambio del stepper
   */
  public InvokeChangeStep() {
    this.ChangeStep.emit();
  }

  /**
   * Metodo para ejecutar el metodo para evaluar si el apoyo financiero existe en el campus
   */
  public invokeValidarApoyo() {
    this.ValidarApoyo.emit();
  }

  //#endregion


  /**
  * Metodo para guardar el nuevo alumno en admision express
  * @param usuario
  * @returns
  */
  public EnviarResgistroAdmisionExpress(institucionId: string, usuario: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Home/RegistrarUsuarioExpress/${institucionId}`, usuario);
  }


  /**
   * Metodo para traer los documentos para el proceso con el URL
   * @param institucionId url para filtrar los datos por institucion
   * @param campusId Campus seleccionado
   * @returns
   */
  public ObtenerDocumentosProcesoByUrl(institucionId: string, CLAVE_PROCESO: string): Observable<ApiResponse<Documento[]>> {
    return this.httpClient.get<ApiResponse<Documento[]>>(`${environment.enves[baseUrl].apiUrl}/Home/ObtenerDocumentosProcesoByUrl/${institucionId}/${CLAVE_PROCESO}`);
  }



  /////PRUEBA ZOHO
  public registroZoho(lead: any): Observable<SimpleResponse> {
    return this.httpClient.post<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Admisiones/registroZoho`, lead);
  }

}
