import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { LeadImportDto } from '../_models/lead';

@Injectable({
  providedIn: 'root'
})
export class ExcelReaderService {
  arrayBuffer: any;

  constructor() { }

  /** Servicio que lee un archivo de tipo Excel (xlsx, csv u otro del mismo tipo) 
   * y convierte sus registros en un arreglo a fin de agregarlo 
   * a la base de datos
   */
  xlsxToJSON(event) {
    return new Promise<any>((resolve, reject) => {
      let arreglo: LeadImportDto[] = [];
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(event);
      fileReader.onload = async (e) => {
        this.arrayBuffer = fileReader.result;
        let data = new Uint8Array(this.arrayBuffer);
        let arr = new Array();
        for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join("");
        let workbook = XLSX.read(bstr, { type: "binary", cellDates: true });
        let first_sheet_name = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[first_sheet_name];
        // arraylist es el JSON después de tratar la data del excel
        arreglo = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        resolve(arreglo);
      }
    });
  }
}
