import { EntidadFederativa } from "./entidad-federativa";

export class DatoFiscal {
    /**
     * Identificador del usuario
     */
    usuarioId: number;

    /**
     * Tipo de facturacion 
     */
    tipoFacturacionId: number;

    /**
     * Registro federal de contribuyentes
     */
    rfc: string;

    /**
     * Nombre del usuario que ingresa el dato fiscal
     */
    nombre: string;

    /**
     * Apellido paterno el usuario
     */
    apellidoPaterno: string;

    /**
     * Apellido materno del usuario
     */
    apellidoMaterno: string;

    /**
     * Razon social para la facturacion
     */
    razonSocial: string;

    /**
     * Define si el RFC va a ser de una persona o empresa
     */
    esIgualPersonal: boolean;

    /**
     * Entidad federativa donde se factura
     */
    entidadFederativaId: number;

    /**
     * Ciudad de facturacion
     */
    ciudad: string;

    /**
     * Calle donde vive la persona
     */
    calle: string;

    /**
     * Numero exterior de la casa
     */
    numeroExterior: string;

    /**
     * Numero interior de la casa
     */
    numeroInterior: string;

    /**
     * Colonia donde se encuentra la casa
     */
    colonia: string;

    /**
     * Codigo postal donde se factura
     */
    codigoPostal: string;

    /**
     * Modelo de entidad federativa
     */
    entidadFederativa?: EntidadFederativa;
}
