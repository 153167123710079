import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DepartamentoService } from '@app/_services/departamento.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-eliminar-departamento',
  templateUrl: './modal-eliminar-departamento.component.html',
  styleUrls: ['./modal-eliminar-departamento.component.scss']
})
export class ModalEliminarDepartamentoComponent implements OnInit {

  public numDepartamentoSeleccionados = 0;
  public boolIsArray = false;
  public claveDepartamento = '';

  constructor(public dialogRef: MatDialogRef<ModalEliminarDepartamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private departamentoService: DepartamentoService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if(this.boolIsArray){
      this.numDepartamentoSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(d => (d.departamentoId.toString()));
      this.claveDepartamento = arrayClaves.toString();
    } else {
      this.claveDepartamento = this.data.departamentoId.toString();
    }
    this.departamentoService.eliminarDepartamento(this.claveDepartamento).subscribe(
      eliminado => {
        if (eliminado) {
          this.departamentoService.invocarMetodoObtenerDepartamento();
          this.snackBar.open("Se ha eliminado la información.", "OK", { duration: 5000 });
          this.dialogRef.close(this.data);
        } else {
          this.snackBar.open("Alguno(s) de los departamento(s) está siendo utilizado por otro módulo.", "OK", { duration:5000 });
        }
      },
      error => {
        this.snackBar.open("Ha ocurrido un error al eliminar el departamento. Por favor, intente de nuevo más tarde", "OK", { duration: 5000 }); 
      }
    )
  }

}
