import { I18NextPipe } from 'angular-i18next';
import { SnackService } from './../../services/snack-service.service';
import { SimpleResponse } from './../../models/api/SimpleResponse.model';
import { AvanceCursoService } from './../../_services/avance-curso.service';
import { NuevoIngresoService } from './../../_services/nuevo-ingreso.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AvanceCursoInformacionAlumno } from './../../_models/AvanceCursoInformacionAlumno';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormControl } from '@angular/forms';
import { ModalDatosPersonalesComponent } from './modal-datos-personales/modal-datos-personales.component';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-avance-curso-modal',
  templateUrl: './avance-curso-modal.component.html',
  styleUrls: ['./avance-curso-modal.component.scss']
})
export class AvanceCursoModalComponent implements OnInit {

  @ViewChild(ModalDatosPersonalesComponent) datosPersonales: ModalDatosPersonalesComponent;
  @ViewChild('dialogoCerrarModal') dialogoCerrarModal: TemplateRef<'dialogoCerrarModal'>;
  public tabSeleccionadoIndex: FormControl = new FormControl();
  public tabSeleccionado: number = 0;
  public activarBtnGuardar: boolean = false;
  public formCorrecto: boolean = false;
  public base64Image: string;
  public imagePath: SafeResourceUrl;
  public formValido$: Observable<boolean>;
  public formValido: boolean;
  public tabCambiosDatos: string = "";
  public dialogoRefCerrarModal: MatDialogRef<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AvanceCursoModalComponent>,
    private sanitizer: DomSanitizer,
    public nuevoIngresoService: NuevoIngresoService,
    public avanceCursosService: AvanceCursoService,
    private snackBar: SnackService,
    private i18nextPipe: I18NextPipe,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cargarImagen();
  }

  /**
   * Metodo para cargar la imagen y redireccionar al tab seleccionado
   */
  public cargarImagen(): void {
    this.base64Image = 'data:image/jpg;base64,' + this.data.datosPersonales.fotografia;
    this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
    if (this.data.datosPersonales.tabSeleccionado === 0) {
      setTimeout(() => {
        this.tabSeleccionadoIndex.setValue(0);
        this.activarBtnGuardar = true;
      }, 500);
    } else if (this.data.datosPersonales.tabSeleccionado === 1) {
      setTimeout(() => {
        this.tabSeleccionadoIndex.setValue(1);
        this.activarBtnGuardar = false;
      }, 500);
    } else if (this.data.datosPersonales.tabSeleccionado === 2) {
      setTimeout(() => {
        this.tabSeleccionadoIndex.setValue(2);
        this.activarBtnGuardar = false;
      }, 500);
    }
    this.formValido$ = this.nuevoIngresoService.obtenerFormularioValidoNuevaSolicitud$();
    this.formValido$.subscribe(bool => this.formValido = bool);
  }

  /**
   * Metodo para detectar el cambio del tab
   * @param event 
   */
  public onChangeTab(event: MatTabChangeEvent) {
    this.tabSeleccionado = event.index;
    switch (this.tabSeleccionado) {
      case 0: {
        this.activarBtnGuardar = true;
        break;
      }
      case 1: {
        this.activarBtnGuardar = false;
        break;
      }
      case 2: {
        this.activarBtnGuardar = false;
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Metodo que enviar la informacion para guardarla
   */
  public enviarFormulario() {
    let datos: any = this.datosPersonales.datosPersonalesForm.value;
    this.avanceCursosService.guardarDatosPersonales(datos).subscribe((response: SimpleResponse) => {
      if (response.success) {
        this.nuevoIngresoService.invocarMetodoObtenerCandidato();
        this.data.datosPersonales.nombreCompleto = response.message;
        this.datosPersonales.changesPersonalData = false;
        this.snackBar.mostrarSnackBack("Se ha guardado la información.");
      } else {
        this.snackBar.mostrarSnackBack(response.message);
      }
    });
  }

  /**
   * Metodo para hacer traducciones
   * @param text 
   * @returns 
   */
  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  /**
   * Metodo para cerrar el modal
   */
  public cerrarModal() {
    if (this.datosPersonales.changesPersonalData) {
      this.tabCambiosDatos = "Datos Personales";
      this.dialogoRefCerrarModal = this.dialog.open(this.dialogoCerrarModal, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Cerrar modal cambios pendientes
   * @param descartar 
   */
  public cerrarModalAdvertenciaCancelarCambios(descartar: boolean) {
    if (descartar) {
      this.dialogoRefCerrarModal.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefCerrarModal.close();
    }
  }
}
