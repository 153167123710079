import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AlumnoComponent } from './alumno/alumno.component';

const childRoutes: Routes = [

    { 
        path: 'v2', 
        loadChildren: () => import('./alumno/alumno.module').then( m => m.AlumnoModule ) 
      },
//   { path: 'boleta', component: BoletaCalificacionComponent },
//   { path: 'documentos-alumno', component: DocumentosAlumnoComponent },
//   { path: 'estado-cuenta-alumno', component: EstadoCuentaAlumnoComponent },
//   { path: 'ensenanza-aprendizaje-Alumno', component: EnsenanzaAprendizajeAlumnoComponent },
//   { path: 'transcript', component: TranscriptComponent },
//   { path: 'mis-solicitudes', component: SolicitudAlumnoComponent },
//   { path: 'dashboard-alumnov2', component: DashboardAlumnoComponent },
];

@NgModule({
  imports: [RouterModule.forChild(childRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }