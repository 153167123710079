import { Component, OnInit } from '@angular/core';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SimpleResponse } from '../models/api/SimpleResponse.model';
import { IntentoPagoZumaDTO } from '../_models/IntentoPagoZumaDTO.model';
import { ModificarEmailZuma } from '../_models/Zuma/Email/ModificarEmailZuma';
import { LinkEstatus } from '../_models/Zuma/LinkEstatus/LinkEstatus';
import { PagoZuma } from '../_models/Zuma/LinkPago/PagoZuma';
import { PagoZumaResponse } from '../_models/Zuma/LinkPago/PagoZumaResponse';
import { PagoLineaZumaService } from '../_services/pago-linea-zuma.service';

@Component({
  selector: 'app-pago-linea-temporal',
  templateUrl: './pago-linea-temporal.component.html',
  styleUrls: ['./pago-linea-temporal.component.scss']
})
export class PagoLineaTemporalComponent implements OnInit {

  public Cargos: any[] = [
    {"usuarioId":215,"cargoId":268,"transaccionId":0,"formaPago":1,"tipoMovimiento":0,"monto":610,"montoTotalAbonoMovimiento":870,"descripcion":"Cargo de Servicio (Tarjeta Universitaria)","nombreBanco":"","referenciaNumerica":"","comentario":null,"fechaVencimiento":"2021-06-02T23:59:00","fechaCreacion":"2021-06-02T10:50:33.583","fechaPago":"2021-06-02","alumnoId":0,"periodoId":0,"saldoCargo":610,"saldoAbono":610,"saldoActual":610,"tipoTransaccion":"Cargo","motorPagoId":1},
    {"usuarioId":215,"cargoId":269,"transaccionId":0,"formaPago":1,"tipoMovimiento":0,"monto":250,"montoTotalAbonoMovimiento":870,"descripcion":"Cargo de Servicio (Kardex)","nombreBanco":"","referenciaNumerica":"","comentario":null,"fechaVencimiento":"2021-06-02T23:59:00","fechaCreacion":"2021-06-02T10:55:29.38","fechaPago":"2021-06-02","alumnoId":0,"periodoId":0,"saldoCargo":250,"saldoAbono":250,"saldoActual":250,"tipoTransaccion":"Cargo","motorPagoId":1},
    {"usuarioId":215,"cargoId":270,"transaccionId":0,"formaPago":1,"tipoMovimiento":0,"monto":10,"montoTotalAbonoMovimiento":870,"descripcion":"Cargo de Servicio (Ficha de Copiado)","nombreBanco":"","referenciaNumerica":"","comentario":null,"fechaVencimiento":"2021-06-02T23:59:00","fechaCreacion":"2021-06-02T10:55:47.467","fechaPago":"2021-06-02","alumnoId":0,"periodoId":0,"saldoCargo":10,"saldoAbono":10,"saldoActual":10,"tipoTransaccion":"Cargo","motorPagoId":1}
  ]

  constructor(public pagoLineaZumaService: PagoLineaZumaService) { }

  ngOnInit(): void {
  }

  public PagoZuma() {
    let pagoPrueba: PagoZuma = {
      reference: "referencia de compra",
      amount: 3000.00,
      promotion: "3",
      productCode: "AWPE_RPVT_XUG",
      expire: 1,
      sendLink: true,
      email: "minavia@ectotec.com",
      paymentChannel: "ZUMA_API"
    }
    this.pagoLineaZumaService.PostPagoZuma(pagoPrueba).subscribe((res: ApiResponse<PagoZumaResponse>) => {
      if (res.success) {
        let PagoIntento: IntentoPagoZumaDTO = {
          cargos: this.Cargos,
          pagoZuma: pagoPrueba,
          pagoZumaResponse: res.data
        }
        this.pagoLineaZumaService.PostIntentoPago(PagoIntento).subscribe((res: SimpleResponse) => {
        });
      } else {
      }
    });
  }

  public ModificarEmail() {
    let modEmailPrueba: ModificarEmailZuma = {
      link: "http://zpdashv2.swipeapps.io/#/i/NKW_QOUS-3UF89PXM",
      mail: "miguelnavia95@gmail.com"
    }
    this.pagoLineaZumaService.PostModifyEmail(modEmailPrueba).subscribe((res: ApiResponse<any>) => {
      console.log("RESPUESTA MODIFICAR EMAIL ZUMA", res);
    });
  }

  public ObtenerEstatusLink() {
    let linkEstatus: LinkEstatus = {
      longCode: "http://zpdashv2.swipeapps.io/#/i/NKW_QOUS-3UF89PXM"
    }
    this.pagoLineaZumaService.PostLinkEstatus(linkEstatus).subscribe((res: ApiResponse<any>) => {
      console.log("RESPUESTA ESTATUS LINK ZUMA", res);
    });
  }

}
