import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Campus } from 'src/app/_models/campus';
import { Alumno, EstatusAcademico } from 'src/app/_models/inscripcion';
import { Periodo } from 'src/app/_models/periodo';
import { Programa } from 'src/app/_models/programa';
import { CampusService } from 'src/app/_services/campus.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { InscripcionService } from "src/app/_services/inscripcion.service";
import { TablaEgresoDTO } from 'src/app/_models/TablaEgresoDTO';
import { TipoModalidadTitulacion } from 'src/app/_models/tipo-modalidad-titulacion';
import { TipoModalidadTitulacionService } from 'src/app/_services/tipo-modalidad-titulacion.service';
import { AttributesDto } from '@app/_models/AttributesDto';
import { MatOption } from '@angular/material/core';
import { AttributesService } from '@app/_services/attributes.service';
@Component({
  selector: 'app-programa-academico-egreso',
  templateUrl: './programa-academico-egreso.component.html',
  styleUrls: ['./programa-academico-egreso.component.scss']
})
export class ProgramaAcademicoEgresoComponent implements OnInit {

  @Input() data: { alumno: TablaEgresoDTO, indexTap: number };
  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Propiedades
  public programaAcademicoForm: FormGroup;
  public renderizarFormulario: boolean = false;
  public nuevoSolicitudForm: FormGroup;
  public nuevoSolicitudUsuarioForm: FormGroup;
  public correoRegistro: any;
  public nombreCompletoUsuario: string;
  public campusList: Campus[] = [];
  public periodoList: Periodo[] = [];
  public estatusAcademicoList: EstatusAcademico[] = [];
  public programaList: Programa[] = [];
  public TipoTitulacionList: TipoModalidadTitulacion[] = [];
  public periodoActivoList: Periodo[] = [];
  public tipoAccion: number;
  public utileriasService: any;
  public formularioProfesor: boolean = false;
  public campusChange: boolean = false;
  public tabCambiosPrograma: string = "";
  public nivelID: number;
  public materias: number;
  public attributes: AttributesDto[];
  public existenAtributos: boolean = false;
  public atributoF = new FormControl();
  @ViewChild('allAtritbuto') private allAtritbuto: MatOption;
  public filtroForm: FormGroup;
  //#endregion

  //#region Ciclo de vida
  constructor(
    private campusService: CampusService,
    private periodoService: PeriodoService,
    public nuevoIngresoService: NuevoIngresoService,
    private InscripcionService: InscripcionService,
    private tipoModalidadTitulacionService: TipoModalidadTitulacionService,
    public attributesService: AttributesService
  ) {
  }

  async ngOnInit(): Promise<void> {
    console.log("DATA", this.data)
    // this.obtenerAtributos();
    await this.inicializarFormAtributo().then((_) => {
      this.obtenerAtributos();
    });
    this.getTipoCampus();
    this.getTipoPeriodo();
    this.GetEstatusAcademicos();
    this.getTipoTitulacion();
    this.getPeriodosActivos();
    this.renderizarFormularioPrograma();
  }
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  /**
* Metodo que consulta los bloques
*/
  public obtenerAtributos() {
    this.attributesService.attributesInstitucion().subscribe((respuesta: ApiResponse<AttributesDto[]>) => {
      this.attributes = respuesta.data;
      this.existenAtributos = this.attributes.length > 0;
      if (this.existenAtributos) {
        this.atributoF.setValue([...this.data.alumno.studentAttributes.map(item => item.attributeId)]);
        this.filtroForm.patchValue({
          atributoF: this.data.alumno.studentAttributes.map(item => item.attributeId)
        });
      }
    })
  }

  /**
* Metodo cuando se seleccionan todos los atributos
*/
  toggleAllSelectionAtributo() {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.filtroForm.patchValue({
        atributoF: this.attributes.map(item => item.attributeId)
      });
      this.atributoF.patchValue([...this.attributes.map(item => item.attributeId), 0]);
    } else {
      this.filtroForm.patchValue({
        atributoF: []
      });
      this.atributoF.patchValue([]);
    }
  }

  /**
  * Verifica si el campo Todos del select atributo esta seleccionados, si lo esta
  * quita la seleccion de todos los campos, sino los selecciona todos
  * @param AtributoId
  * @returns
  */
  tosslePerOneAtributo(AtributoId: number) {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.allAtritbuto.deselect();
    }
    if (this.atributoF.value.length == this.attributes.length) {
      this.allAtritbuto.select();
    }
    const AtributoSeleccionados = this.filtroForm.get('atributoF').value;
    const existeAtributo = AtributoSeleccionados.find(x => x === AtributoId);
    if (!existeAtributo) {
      const AtributoAnteriores = AtributoSeleccionados;
      AtributoAnteriores.push(AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoAnteriores
      });
    } else {
      const AtributoFiltrados = AtributoSeleccionados.filter(x => x !== AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoFiltrados
      });
    }
  }

  //#region Render Form
  public renderizarFormularioPrograma() {

    this.inicializarForm();
  }

  public inicializarForm() {
    this.programaAcademicoForm = new FormGroup({
      usuarioId: new FormControl(this.data.alumno.usuarioId),
      campus: new FormControl(this.data.alumno.campusId, [Validators.required, this.validateId]),
      programaAcademico: new FormControl(this.data.alumno.programaId, [Validators.required, this.validateId]),
      periodo: new FormControl(this.data.alumno.periodoAdmisión, [Validators.required, this.validateId]),
      estatusAdmision: new FormControl(this.data.alumno.estatusAcademicoId, [Validators.required, this.validateId]),

      modalidadTitulacion: new FormControl(this.data.alumno.tipoModalidadTitulacionId, [Validators.required, this.validateId]),
      periodoEgreso: new FormControl(this.data.alumno.periodoEgresoId, [Validators.required, this.validateId]),

    });

    this.programaAcademicoForm.get('campus').disable();
    this.programaAcademicoForm.get('programaAcademico').disable();
    this.programaAcademicoForm.get('periodo').disable();

    this.renderizarFormulario = true;
    this.programaAcademicoForm.valueChanges.subscribe(val => {
      this.nuevoIngresoService.definirFormularioValidoNuevaSolicitud(this.programaAcademicoForm.valid);
      this.nuevoIngresoService.setChangesAcademicProgram(true);
    });
    this.programaAcademicoForm.get("campus").valueChanges.subscribe(campus => {
      this.onCampusSelected(campus);
      this.programaAcademicoForm.patchValue({
        programaAcademico: 0
      });
    });
    this.onCampusSelected(this.data.alumno.campusId);
  }
  //#endregion

  //#region Get Selects
  get programaAcademico() { return this.programaAcademicoForm.get('programaAcademico'); }
  get campus() { return this.programaAcademicoForm.get('campus'); }
  get periodo() { return this.programaAcademicoForm.get('periodo'); }
  get estatusAdmision() { return this.programaAcademicoForm.get('estatusAdmision'); }
  //#endregion

  //#region Llenar Combos
  public getTipoCampus() {
    this.Subscription.add(
      this.campusService.obtenerCampus().subscribe(
        (campus: Campus[]) => {
          this.campusList = campus;
        })
    );
  }

  public onCampusSelected(campus: number) {
    this.programaList = [];
    if (!!campus) {
      this.campusService.obtenerProgramas(campus).subscribe(
        programas => {
          this.programaList = programas;
          if (this.programaAcademicoForm.controls['programaAcademico'].value != null) {
            var programa = this.programaList.filter(x => x.programaId == this.programaAcademicoForm.controls['programaAcademico'].value)
            this.nivelID = programa[0].nivelId;
          }
        });
    }
  }

  public getTipoPeriodo() {
    this.Subscription.add(
      this.periodoService.obtenerPeriodos().subscribe(
        (periodo: Periodo[]) => {
          this.periodoList = periodo;
        })
    );
  }

  public GetEstatusAcademicos(): void {
    this.Subscription.add(
      this.InscripcionService.GetEstatusAcademicos().subscribe(
        (EstatusAcademico: ApiResponse<EstatusAcademico[]>) => {
          this.estatusAcademicoList = EstatusAcademico.data.filter((estatus) => {
            return estatus.estatusAcademicoId === 8 || estatus.estatusAcademicoId === 10 || estatus.estatusAcademicoId === 11 || estatus.estatusAcademicoId === 4 || estatus.estatusAcademicoId === 12
          });
        })
    );
  }
  //#endregion

  //#region extras
  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.programaAcademicoForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }
  //#endregion

  //#region Combos Datos Egreso
  public getTipoTitulacion() {
    this.tipoModalidadTitulacionService.obtenerTiposTitulacion().subscribe(response => {
      if (response.success) {
        this.TipoTitulacionList = response.data;
      }
    })
  }
  public getPeriodosActivos() {
    this.periodoService.obtenerPeriodosActivos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoActivoList = periodo;

      });
  }

  /**
   * Metodo que inicializa el form para los filtros
   */
  public inicializarFormAtributo(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.filtroForm = new FormGroup({
        atributoF: new FormControl([])
      });
      this.atributoF.setValue(["-1"]);
      resolve();
    });
  }
  //#endregion
}