import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
@Component({
  selector: 'app-programa-eliminar-ciclos',
  templateUrl: './programa-eliminar-ciclos.component.html',
  styleUrls: ['./programa-eliminar-ciclos.component.scss']
})
export class ProgramaEliminarCiclosComponent implements OnInit {
  public numCicloSeleccionados = 0;
  public boolIsArray = false;
  public claveCiclos = '';

  constructor( public dialogRef: MatDialogRef<ProgramaEliminarCiclosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private programaAcademicoService: ProgramaAcademicoService
  ) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    if(this.data.length === 1){
      this.boolIsArray = false;
    } else {
      this.boolIsArray = true;
      this.numCicloSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if(this.boolIsArray) {
      let arrayClaves = this.data.map(c => (c.cicloId.toString()));
      this.claveCiclos = arrayClaves.toString();
    } else {
      this.claveCiclos = this.data[0].cicloId.toString();
    }
    this.programaAcademicoService.eliminarMultiplesCiclos(this.claveCiclos).subscribe(
      eliminado => {
        if(eliminado) {
          this.programaAcademicoService.invocarMetodoObtenerCiclos();
          // this.programaAcademicoService.invocarMetodoCiclos();
          this.dialogRef.close();
          this.snackBar.open('Se han eliminado el ciclo(s)', 'OK', { duration: 5000 });
        } else {
          this.snackBar.open('Ha ocurrido un error al eliminar el ciclo(s). Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
        }
      }, 
      error => {
        this.snackBar.open('Ha ocurrido un error al eliminar el ciclo(s). Por favor intente de nuevo más tarde.', 'OK', { duration: 5000 });
      });
  }
}
