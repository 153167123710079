import { FORM_VALID_MODEL } from './../../../../../core.domain/models/user/form-valid-model';
import { MessageErrors } from './../../../../../core.application/functions/messageErros.function';
import { PERSONAL_INFORAMTION } from './../../../../../core.application/validators/user-data/personal-information.validators';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PERSONAL_INFORMATION_MODEL } from '../../../../../core.domain/models/user/personal-information.model';

@Component({
  selector: 'app-personal-information-form',
  templateUrl: './personal-information-form.component.html',
  styleUrls: ['./personal-information-form.component.scss']
})
export class PersonalInformationFormComponent implements OnInit, OnDestroy, OnChanges {

  //#region  Input, Output & ViewChild
  @Input() data: PERSONAL_INFORMATION_MODEL;

  @Input() cancelledFields: string[] = [];
  @Input() disabledledFields: string[] = [];

  @Output() formValidity: EventEmitter<FORM_VALID_MODEL> = new EventEmitter(); 
  @Output() formData = new EventEmitter<PERSONAL_INFORMATION_MODEL>();
  //#endregion
  
  //#region Subscriptions
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Properties
  public personalInformationForm: FormGroup = this.formBuilder.group(PERSONAL_INFORAMTION);
  public ErrorMsg: any;
  private status: boolean = false;

  public  deletedInputs = {
    nombre            : false,
    segundoNombre     : false,
    apellidoPaterno   : false,
    apellidoMaterno   : false,
    fechaNacimiento   : false
  };
  //#endregion

  constructor(
    private formBuilder: FormBuilder
  ) { 
    this.setErrors();
  }  
  
  ngOnInit(): void {
    this.subscribeToForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] !== undefined) {
      this._setData();
    }
  }

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#region setData
  private _setData():void
  {    
    this.personalInformationForm.setValue(this.data);
    // this.personalInformationForm.get('nombre')?.setValue(this.data.nombre);
    // this.personalInformationForm.get('segundoNombre')?.setValue(this.data.segundoNombre);
    // this.personalInformationForm.get('apellidoPaterno')?.setValue(this.data.apellidoPaterno);
    // this.personalInformationForm.get('apellidoMaterno')?.setValue(this.data.apellidoMaterno);
    // this.personalInformationForm.get('fechaNacimiento')?.setValue(this.data.fechaNacimiento);
  }
  //#endregion

  //#region Helpers
  public setErrors(): void {
    this.ErrorMsg = MessageErrors.setErrorMessage(this.personalInformationForm);
  }

  /**
   * If the cancelledFields array has a length greater than 0, then for each field in the
   * cancelledFields array, if the personalInformationForm has a control with the name of the field, then clear the
   * validators for that control and add the field to the deletedInputs object.
   */
   _deleteCancelledFields(): void {
    if (this.cancelledFields.length > 0) {
      this.cancelledFields.forEach(field => {
        if (this.personalInformationForm.get(field)) {
          this.personalInformationForm.get(field)?.clearValidators();
          this.deletedInputs[field] = true;
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  /**
   * _disabledCancelledFields() is a function that disables and clears validators for fields that are
   * not required
   */
  _disabledCancelledFields(): void {
    if (this.disabledledFields.length > 0) {
      this.disabledledFields.forEach(field => {
        if (this.personalInformationForm.get(field)) {
          this.personalInformationForm.get(field)?.clearValidators();
          this.personalInformationForm.get(field)?.disable();
        } else {
          console.log(field, 'field does not exist');
        }
      });
    }
  }

  private subscribeToForm(): void {

    this.personalInformationForm.markAllAsTouched();
    this._Emit();
    this.Subscription.add(
      this.personalInformationForm.statusChanges
      .subscribe(status => {
        this.setErrors();                
        this.status = status === 'VALID';
        this._Emit();
      })
    );
  }

  private _Emit():void
  {
    this.formValidity.emit(
      new FORM_VALID_MODEL(
        1,
        'PersonalInformationFormComponent',
        this.status
      )
    );
    this.formData.emit(
      new PERSONAL_INFORMATION_MODEL(
        this.personalInformationForm.get('nombre')?.value,
        this.personalInformationForm.get('segundoNombre')?.value,
        this.personalInformationForm.get('apellidoPaterno')?.value,
        this.personalInformationForm.get('apellidoMaterno')?.value,
        this.personalInformationForm.get('fechaNacimiento')?.value,
      )
    );
  }
  //#endregion
}