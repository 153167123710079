import { BlockService } from 'src/app/_services/block.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import i18next from 'node_modules/i18next';
import { ApiResponse } from '../../../../../../models/api/ApiRespose.model';
import { GrupoDTO } from '../../../../../../_models/GrupoDTO.model';

@Component({
  selector: 'app-edicion-bloque-grupo-agregar',
  templateUrl: './edicion-bloque-grupo-agregar.component.html',
  styleUrls: ['./edicion-bloque-grupo-agregar.component.scss']
})
export class EdicionBloqueGrupoAgregarComponent implements OnInit {
  displayedColumns: string[] = ['select', 'noGrupo', 'claveMateria', 'nombreMateria', 'programa', 'profesor', 'aula', 'frecuencia', 'horario'];
  listGrupoData: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  public numGrupoSeleccionado: number = 0;
  private arrOriginal: any[] = [];
  public datafilter: any;
  public infoCargado: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;



  constructor(public dialogRef: MatDialogRef<EdicionBloqueGrupoAgregarComponent>,
    public blockService: BlockService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.obtenerGrupos();
  }




  public cerrarModal() {
    this.dialogRef.close();
  }


  public obtenerGrupos() {
    this.blockService.obtenerGrupos(i18next.language, this.data.periodoId, this.data.campusId).subscribe(
      (grupos: ApiResponse<Array<GrupoDTO>>) => {

        let result = this.data.gruposId;


        let gruposDisponibles = grupos.data.filter(function (objFromA) {
          return !result.find(function (objFromB) {
            return objFromA.grupoId == objFromB
          })
        })


        this.arrOriginal = [...gruposDisponibles];
        this.listGrupoData = new MatTableDataSource(gruposDisponibles);
        this.datafilter = this.arrOriginal;
        this.listGrupoData.paginator = this.paginator;
        this.selection = new SelectionModel<any>(true, []);
        this.infoCargado = true;


      }
    );
  }


  public anadirGruposBloque(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }


    this.blockService.SetGruposABloque(datos);
    this.blockService.invocarMetodoAñadirrBloqueGrupo();
    this.cerrarModal();

  }



  isAllSelected() {
    this.numGrupoSeleccionado = this.selection.selected.length;
    const numRows = this.listGrupoData.data.length;
    return this.numGrupoSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.numGrupoSeleccionado = this.selection.selected.length;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Clave + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.listGrupoData.data.forEach(row => this.selection.select(row));
  }


  buscarNombreClave(filterValue: string) {
    filterValue = filterValue.toLowerCase();
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    if (filterValue != "") {
      filtroModificado = filtroModificado.filter(el => {
        return el.claveMateria.toLowerCase().search(filterValue) > -1 || el.materiaMostrada.toLowerCase().search(filterValue) > -1 || el.grupoId.toString().search(filterValue) > -1;
      })
    }

    this.listGrupoData = new MatTableDataSource(filtroModificado);
    this.listGrupoData.paginator = this.paginator;
    this.datafilter = filtroModificado;

  }




}
