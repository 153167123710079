import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import { InfoPrograma } from '../_models/infoPrograma';

@Injectable({
  providedIn: 'root'
})
export class InfoProgramaService {

  constructor(private httpClient: HttpClient) { }

  //Get InfoPrograma
  public obtenerInfoPrograma(): Observable<InfoPrograma[]> {
    return this.httpClient.get<InfoPrograma[]>(`${environment.enves[baseUrl].apiUrl}/Programa`);
  }

  //Post InfoPrograma
  public agregarInfoPrograma(infoPrograma: InfoPrograma): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Programa`, infoPrograma);
  }

  //Put InfoPrograma
  public modificarInfoPrograma(infoPrograma: InfoPrograma): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Programa`, infoPrograma);
  }

  //Delete InfoPrograma
  public eliminarInfoPrograma(programaId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Programa?programaId` + programaId);
  }
}
