import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subscription, Subject } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';

import { Biografia } from '../_models/biografia';

@Injectable({
  providedIn: 'root'
})
export class BiografiaService {

  constructor(private httpClient: HttpClient) { }

  ///Biografia

  //Get Biografia
  public obtenerBiografia(): Observable<Biografia[]> {
    return this.httpClient.get<Biografia[]>(`${environment.enves[baseUrl].apiUrl}/Biografia`);
  }

  //Get Biografia X UsuarioID
  public obtenerBiografiaByProfesorId(usuarioId : number): Observable<any> {
    return this.httpClient.get<Biografia>(`${environment.enves[baseUrl].apiUrl}/Biografia/GetBiografiaByProfesorId?usuarioId=` + usuarioId );
  }

  //Get Biografia X NSS
  public obtenerBiografiaByNss(nss: string,usuarioId: number): Observable<Biografia> {
    return this.httpClient.get<Biografia>(`${environment.enves[baseUrl].apiUrl}/Biografia/NSS?nss=` + nss + `&usuarioId=` + usuarioId);
  }

  //Get Biografia X Curp
  public obtenerBiografiaByCurp(curp: string,usuarioId: number): Observable<Biografia> {
    return this.httpClient.get<Biografia>(`${environment.enves[baseUrl].apiUrl}/Biografia/ObtenerCURP?curp=` + curp + `&usuarioId=` + usuarioId);
  }

  //Post Biografia
  public agregarBiografia(biografia: Biografia): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.enves[baseUrl].apiUrl}/Biografia`, biografia);
  }

  //Put Biografia
  public modificarBiografia(biografia: Biografia): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Biografia`, biografia);
  }
}
