import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LeadImportDto } from 'src/app/_models/lead';
import { LeadImportService } from 'src/app/_services/lead-import.service';

@Component({
  selector: 'app-leads-import-modal-select',
  templateUrl: './leads-import-modal-select.component.html',
  styleUrls: ['./leads-import-modal-select.component.scss']
})
export class LeadsImportModalSelectComponent implements OnInit {
  public columnasCompletas: string[] =  [];
  public listaColumnas: any[] = [];
  public ejemploCol: any[] = [];
  public columnArr: LeadImportDto[] = [];

  constructor(private leadImportService: LeadImportService) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.obtenerColumnasOrdenadas();
  }

  public obtenerColumnasOrdenadas() {
    this.leadImportService.obtenerColumnas().subscribe((columnas: string[]) => {
      this.columnasCompletas = columnas;
      
    });
  }

  /**
   * Funcion que habilita o deshabilita el input de tipo texto, agrega y elimina los datos fijos que se van
   * a pasar al siguiente componente
   * @param columna 
   * @param isChecked 
   * @param i 
   */
  activarInput(columna: any, isChecked: boolean, i: number) {
    let text = <HTMLInputElement> document.getElementById('text' + i);

    if(isChecked) {
      text.disabled = false;
      text.required = true;
      this.listaColumnas.push(columna);
    } else {
      let borrar: number = -1;
      this.listaColumnas.forEach((item, index) => {
        if(item == columna) {
          borrar = index;
        }
      });
      if (borrar >= 0) {
        this.listaColumnas.splice(borrar, 1);
      }
      text.disabled = true;
      text.value = '';
      text.required = false;
      let index = this.ejemploCol.findIndex(c => c.column === columna);
      this.ejemploCol.splice(index, 1);
    }
  }

  obtenerValorInput(columna: string, i: number) {
    let text = <HTMLInputElement> document.getElementById('text' + i);
    let valor: string = text.value;
    // let arreglo = {col: columna, val: valor};
    var arrNuevo = new Map<string, string>();
    arrNuevo.set(columna, valor);

    let jsonObject = {};
    arrNuevo.forEach((values, key) => {
      jsonObject[key.trim().toLowerCase()] = values
    });

    this.ejemploCol.push(jsonObject);
  }

  public siguiente() {
    this.leadImportService.definircolumnasValorFijo(this.ejemploCol);
  }

}
