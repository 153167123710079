import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AccionEnum } from '../../../../_models/accion.enum';
import { SolicitudApoyoFinancieroService } from '../../../../_services/solicitud-apoyo-financiero.service';
import { UtileriasService } from '../../../../_services/utilerias.service';
import { EntidadFederativa } from '../../../../_models/entidad-federativa';
import { Pais } from '../../../../_models/pais';
import { TipoDireccion } from '../../../../_models/tipo-direccion';
import { TipoTelefono } from '../../../../_models/tipo-telefono';
import { DireccionService } from '../../../../_services/direccion.service';
import { TipoEmpresa } from '../../../../_models/tipo-empresa';
import { TipoPuestoEmpresa } from '../../../../_models/tipo-puesto-empresa';
import { EmpresaService } from '../../../../_services/empresa.service';
import { DatosPersonaRelacionada } from 'src/app/_models/usuario';

@Component({
  selector: 'app-apoyo-datos-usuario',
  templateUrl: './apoyo-datos-usuario.component.html',
  styleUrls: ['./apoyo-datos-usuario.component.scss']
})
export class ApoyoDatosUsuarioComponent implements OnInit {

  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public renderizarForm: boolean = false;
  public datosPersonalesForm: FormGroup;
  public datosContactoForm: FormGroup;
  public datosFamiliaresForm: FormGroup;
  public datosOcupacionForm: FormGroup;
  public tipoAccion: number;
  public tipoAccionDireccion: number;
  public tipoAccionTelefono: number;
  public tipoAccionFamiliar: number;
  public tipoAccionTipoEmpresa: number;
  public tipoAccionTipoPuesto: number;
  public datosPersonalesFormValid: boolean;
  public datosContactoFormValid: boolean;
  public datosFamiliaresFormValid: boolean;
  public datosOcupacionFormValid: boolean;
  public datosPersonalesFormCambio: boolean = false;
  public datosContactoFormCambio: boolean = false;
  public datosFamiliaresFormCambio: boolean = false;
  public datosOcupacionFormCambio: boolean = false;
  public datosUsuarioValid: boolean = false;
  public tipoAccionFamiliarPapa: DatosPersonaRelacionada;
  public tipoAccionFamiliarMama: DatosPersonaRelacionada;
  public tipoAccionFamiliarTutor: DatosPersonaRelacionada;
  public subscriptionTipoDireccion: Subscription;
  public subscriptionPais: Subscription;
  public subscriptionEstado: Subscription;
  public subscriptionTipoTelefono: Subscription;
  public subscriptionTipoEmpresa: Subscription;
  public subscriptionPuesto: Subscription;
  public tipoDireccionList: TipoDireccion[] = [];
  public paisList: Pais[] = [];
  public estadoList: EntidadFederativa[] = [];
  public tipoTelefonoList: TipoTelefono[] = [];
  public tipoEmpresaList: TipoEmpresa[] = [];
  public tipoPuestoEmpresaList: TipoPuestoEmpresa[] = [];

  constructor(private solicitudApoyoFinancieroService: SolicitudApoyoFinancieroService,
    private utileriasService: UtileriasService,
    private direccionService: DireccionService,
    private empresaService: EmpresaService) { }

  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.solicitudApoyoFinancieroService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
    this.getTipoDireccion();
    this.getPais();
    this.getTipoTelefono();
    this.getTipoEmpresa();
    this.getTipoPuesto();
  }

  public inicializarForm() {
    this.datosPersonalesForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuario.usuarioId),
      nombre: new FormControl(this.datosUsuarioBusqueda.usuario.nombre, [Validators.required]),
      segundoNombre: new FormControl(this.datosUsuarioBusqueda.usuario.segundoNombre),
      apellidoPaterno: new FormControl(this.datosUsuarioBusqueda.usuario.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(this.datosUsuarioBusqueda.usuario.apellidoMaterno),
      fechaNacimiento: new FormControl(this.utileriasService.formatearFecha(this.datosUsuarioBusqueda.usuario.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD'), [Validators.required]),
    });

    this.datosContactoForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuario.usuarioId),
      tipoDireccionId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.usuario.direccion[0].tipoDireccionId, [Validators.required, this.validateId]),
      paisId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : 484, [Validators.required, this.validateId]),
      entidadFederativaId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.usuario.direccion[0].entidadFederativaId, [Validators.required, this.validateId]),
      calle: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.direccion[0].calle, [Validators.required]),
      ciudad: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.direccion[0].ciudad, [Validators.required]),
      numeroExterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.direccion[0].numeroExterior, [Validators.required]),
      numeroInterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.direccion[0].numeroInterior),
      colonia: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.direccion[0].colonia, [Validators.required]),
      codigoPostal: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.direccion[0].codigoPostal, [Validators.required]),
      tipoTelefonoId: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.usuario.telefono[0].tipoTelefonoId, [Validators.required]),
      numero: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.telefono[0].numero, [Validators.required]),
      extension: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.telefono[0].extension),
      correo: new FormControl(this.datosUsuarioBusqueda.usuario.correo, [Validators.required, Validators.email]),
    });

    this.datosFamiliaresForm = new FormGroup({
      nombreCompletoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.nombreCompleto),
      telefonoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.telefono),
      correoElectronicoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.correoElectronico, [Validators.email]),
      nombreCompletoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.nombreCompleto),
      telefonoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.telefono),
      correoElectronicoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.correoElectronico, [Validators.email]),
      nombreCompletoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.nombreCompleto),
      telefonoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.telefono),
      correoElectronicoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.correoElectronico, [Validators.email]),
    });

    this.datosOcupacionForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuario.usuarioId),
      tipoEmpresaId: new FormControl(this.tipoAccionTipoEmpresa === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.usuario.ocupacion.tipoEmpresaId),
      tipoPuestoEmpresaId: new FormControl(this.tipoAccionTipoPuesto === AccionEnum.CREAR ? 0 : this.datosUsuarioBusqueda.usuario.ocupacion.tipoPuestoEmpresaId),
    });

    this.renderizarForm = true;
    this.datosPersonalesForm.valueChanges.subscribe(val => {
      this.datosPersonalesFormCambio = true;
      this.datosPersonalesFormValid = this.datosPersonalesForm.valid;
      this.datosContactoFormValid = this.datosContactoForm.valid;
      this.datosFamiliaresFormValid = this.datosFamiliaresForm.valid;
      this.datosOcupacionFormValid = this.datosOcupacionForm.valid;
      if (this.datosPersonalesFormValid && this.datosContactoFormValid && this.datosFamiliaresFormValid && this.datosOcupacionFormValid) {
        this.datosUsuarioValid = true;
        this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(true);
        this.solicitudApoyoFinancieroService.setChangesDatosUsuario(true);
      } else {
        this.datosUsuarioValid = false;
        this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(false);
        this.solicitudApoyoFinancieroService.setChangesDatosUsuario(true);
      }
    });
    this.datosContactoForm.valueChanges.subscribe(val => {
      this.datosContactoFormCambio = true;
      this.datosPersonalesFormValid = this.datosPersonalesForm.valid;
      this.datosContactoFormValid = this.datosContactoForm.valid;
      this.datosFamiliaresFormValid = this.datosFamiliaresForm.valid;
      this.datosOcupacionFormValid = this.datosOcupacionForm.valid;
      if (this.datosPersonalesFormValid && this.datosContactoFormValid && this.datosFamiliaresFormValid && this.datosOcupacionFormValid) {
        this.datosUsuarioValid = true;
        this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(true);
        this.solicitudApoyoFinancieroService.setChangesDatosUsuario(true);
      } else {
        this.datosUsuarioValid = false;
        this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(false);
        this.solicitudApoyoFinancieroService.setChangesDatosUsuario(true);
      }
    });
    this.datosFamiliaresForm.valueChanges.subscribe(val => {
      this.datosFamiliaresFormCambio = true;
      this.datosPersonalesFormValid = this.datosPersonalesForm.valid;
      this.datosContactoFormValid = this.datosContactoForm.valid;
      this.datosFamiliaresFormValid = this.datosFamiliaresForm.valid;
      this.datosOcupacionFormValid = this.datosOcupacionForm.valid;
      if (this.datosPersonalesFormValid && this.datosContactoFormValid && this.datosFamiliaresFormValid && this.datosOcupacionFormValid) {
        this.datosUsuarioValid = true;
        this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(true);
      } else {
        this.datosUsuarioValid = false;
        this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(false);
      }
    });
    this.datosOcupacionForm.valueChanges.subscribe(val => {
      this.datosOcupacionFormCambio = true;
      this.datosPersonalesFormValid = this.datosPersonalesForm.valid;
      this.datosContactoFormValid = this.datosContactoForm.valid;
      this.datosFamiliaresFormValid = this.datosFamiliaresForm.valid;
      this.datosOcupacionFormValid = this.datosOcupacionForm.valid;
      if (this.datosPersonalesFormValid && this.datosContactoFormValid && this.datosFamiliaresFormValid && this.datosOcupacionFormValid) {
        this.datosUsuarioValid = true;
        this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(true);
        this.solicitudApoyoFinancieroService.setChangesDatosUsuario(true);
      } else {
        this.datosUsuarioValid = false;
        this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(false);
        this.solicitudApoyoFinancieroService.setChangesDatosUsuario(true);
      }
    });

    let menorEdad = this.calcularEdad(this.datosUsuarioBusqueda.usuario.biografia.fechaNacimiento);
    if (menorEdad) {
      this.nombreCompletoTutor.setValidators([Validators.required]);
      this.telefonoTutor.setValidators([Validators.required]);
      this.correoElectronicoTutor.setValidators([Validators.required]);
    } else {
      this.nombreCompletoTutor.setValidators(null);
      this.telefonoTutor.setValidators(null);
      this.correoElectronicoTutor.setValidators(null);
    }
    this.nombreCompletoTutor.updateValueAndValidity();
    this.telefonoTutor.updateValueAndValidity();
    this.correoElectronicoTutor.updateValueAndValidity();

    this.fechaNacimiento.valueChanges.subscribe(checked => {
      let menorEdad = this.calcularEdad(checked);
      if (menorEdad) {
        this.nombreCompletoTutor.setValidators([Validators.required]);
        this.telefonoTutor.setValidators([Validators.required]);
        this.correoElectronicoTutor.setValidators([Validators.required]);
      } else {
        this.nombreCompletoTutor.setValidators(null);
        this.telefonoTutor.setValidators(null);
        this.correoElectronicoTutor.setValidators(null);
      }
      this.nombreCompletoTutor.updateValueAndValidity();
      this.telefonoTutor.updateValueAndValidity();
      this.correoElectronicoTutor.updateValueAndValidity();
    });
    this.onPaisSelected(484);
  }

  public calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    if (edad < 18) {
      return true
    } else {
      return false;
    }
  } 

  get nombre() { return this.datosPersonalesForm.get('nombre'); }
  get apellidoPaterno() { return this.datosPersonalesForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.datosPersonalesForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.datosPersonalesForm.get('fechaNacimiento'); }

  get tipoDireccionId() { return this.datosContactoForm.get('tipoDireccionId'); }
  get paisId() { return this.datosContactoForm.get('paisId'); }
  get entidadFederativaId() { return this.datosContactoForm.get('entidadFederativaId'); }
  get tipoTelefonoId() { return this.datosContactoForm.get('tipoTelefonoId'); }
  get ciudad() { return this.datosContactoForm.get('ciudad'); }
  get calle() { return this.datosContactoForm.get('calle'); }
  get numeroExterior() { return this.datosContactoForm.get('numeroExterior'); }
  get numeroInterior() { return this.datosContactoForm.get('numeroInterior'); }
  get colonia() { return this.datosContactoForm.get('colonia'); }
  get codigoPostal() { return this.datosContactoForm.get('codigoPostal'); }
  get numero() { return this.datosContactoForm.get('numero'); }
  get extension() { return this.datosContactoForm.get('extension'); }
  get correo() { return this.datosContactoForm.get('correo'); }

  get nombreCompletoMadre() { return this.datosFamiliaresForm.get('nombreCompletoMadre'); }
  get telefonoMadre() { return this.datosFamiliaresForm.get('telefonoMadre'); }
  get correoElectronicoMadre() { return this.datosFamiliaresForm.get('correoElectronicoMadre'); }
  get nombreCompletoPadre() { return this.datosFamiliaresForm.get('nombreCompletoPadre'); }
  get telefonoPadre() { return this.datosFamiliaresForm.get('telefonoPadre'); }
  get correoElectronicoPadre() { return this.datosFamiliaresForm.get('correoElectronicoPadre'); }
  get nombreCompletoTutor() { return this.datosFamiliaresForm.get('nombreCompletoTutor'); }
  get telefonoTutor() { return this.datosFamiliaresForm.get('telefonoTutor'); }
  get correoElectronicoTutor() { return this.datosFamiliaresForm.get('correoElectronicoTutor'); }

  public getErrorFormTipoDireccion(controlName: string): string {
    let error = '';
    const control = this.datosContactoForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Tipo Dirección es requerido.";
    }
    return error;
  }

  public getErrorFormPais(controlName: string): string {
    let error = '';
    const control = this.datosContactoForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "País es requerido.";
    }
    return error;
  }

  public getErrorFormEstado(controlName: string): string {
    let error = '';
    const control = this.datosContactoForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Estado es requerido.";
    }
    return error;
  }

  public getErrorFormTelefono(controlName: string): string {
    let error = '';
    const control = this.datosContactoForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "Tipo de Teléfono es requerido.";
    }
    return error;
  }
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public renderizarFormulario() {
    if (this.datosUsuarioBusqueda.usuario.situacionEconomica != null) {
      this.tipoAccion = AccionEnum.EDITAR;
    } else {
      this.tipoAccion = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.usuario.direccion.length >= 1) {
      this.tipoAccionDireccion = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDireccion = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.usuario.telefono.length >= 1) {
      this.tipoAccionTelefono = AccionEnum.EDITAR;
    } else {
      this.tipoAccionTelefono = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.usuario.personaRelacionadaUsuario.length >= 1) {
      this.tipoAccionFamiliarPapa = this.datosUsuarioBusqueda.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1) === undefined ? null : this.datosUsuarioBusqueda.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1);
      this.tipoAccionFamiliarMama = this.datosUsuarioBusqueda.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2) === undefined ? null : this.datosUsuarioBusqueda.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2);
      this.tipoAccionFamiliarTutor = this.datosUsuarioBusqueda.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3) === undefined ? null : this.datosUsuarioBusqueda.usuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3);
    } else {
      this.tipoAccionFamiliarPapa = null;
      this.tipoAccionFamiliarMama = null;
      this.tipoAccionFamiliarTutor = null;
    }
    if (this.datosUsuarioBusqueda.usuario.ocupacion != null) {
      if (this.datosUsuarioBusqueda.usuario.ocupacion.tipoEmpresaId != null) {
        this.tipoAccionTipoEmpresa = AccionEnum.EDITAR;
      } else {
        this.tipoAccionTipoEmpresa = AccionEnum.CREAR;
      }
    } else {
      this.tipoAccionTipoEmpresa = AccionEnum.CREAR;
    }
    if (this.datosUsuarioBusqueda.usuario.ocupacion != null) {
      if (this.datosUsuarioBusqueda.usuario.ocupacion.tipoPuestoEmpresaId != null) {
        this.tipoAccionTipoPuesto = AccionEnum.EDITAR;
      } else {
        this.tipoAccionTipoPuesto = AccionEnum.CREAR;
      }
    } else {
      this.tipoAccionTipoPuesto = AccionEnum.CREAR;
    }
    this.inicializarForm();
  }

  public getTipoDireccion() {
    this.subscriptionTipoDireccion = this.direccionService.obtenerTipoDireccion().subscribe(
      (tipoDireccion: TipoDireccion[]) => {
        this.tipoDireccionList = tipoDireccion;
      });
  }

  public getPais() {
    this.subscriptionPais = this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisList = pais;
      });
  }

  public onPaisSelected(pais: number) {
    this.estadoList = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estadoList = estados;
        })
    }
  }

  public getTipoTelefono() {
    this.subscriptionTipoTelefono = this.direccionService.obtenerTipoTelefono().subscribe(
      (tipoTelefono: TipoTelefono[]) => {
        this.tipoTelefonoList = tipoTelefono;
      });
  }

  public getTipoEmpresa() {
    this.subscriptionTipoEmpresa = this.empresaService.obtenerTipoEmpresa().subscribe(
      (tipoEmpresa: TipoEmpresa[]) => {
        this.tipoEmpresaList = tipoEmpresa;
      });
  }

  public getTipoPuesto() {
    this.subscriptionPuesto = this.empresaService.obtenerTipoPuestoEmpresa().subscribe(
      (tipoPuestoEmpresa: TipoPuestoEmpresa[]) => {
        this.tipoPuestoEmpresaList = tipoPuestoEmpresa;
      });
  }

  ngOnDestroy(): void {
    if (this.subscriptionTipoDireccion) {
      this.subscriptionTipoDireccion.unsubscribe();
    }
    if (this.subscriptionPais) {
      this.subscriptionPais.unsubscribe();
    }
    if (this.subscriptionEstado) {
      this.subscriptionEstado.unsubscribe();
    }
    if (this.subscriptionTipoTelefono) {
      this.subscriptionTipoTelefono.unsubscribe();
    }
    if (this.subscriptionTipoEmpresa) {
      this.subscriptionTipoEmpresa.unsubscribe();
    }
    if (this.subscriptionPuesto) {
      this.subscriptionPuesto.unsubscribe();
    }
  }

}
