import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { Documento } from '@app/_models/documento';
import { baseUrl, environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { SubProcesoDto } from '../_models/SubProcesoDto';

@Injectable({
  providedIn: 'root'
})
export class SubprocesosService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Servicio para traer los Subprocesos por institucion
   * @returns 
   */
  public subProcesoInstitucion(claveProceso: string): Observable<ApiResponse<SubProcesoDto[]>> {
    return this.httpClient.get<ApiResponse<SubProcesoDto[]>>(`${environment.enves[baseUrl].apiUrl}/SubProceso/SubProcesoInstitucion/${claveProceso}`);
  }

  /**
   * Servicio para traer los subprocesos por institucion y procesoid
   * @returns 
   */
  public subProcesoByProcesoId(claveProceso: string): Observable<ApiResponse<SubProcesoDto[]>> {
    return this.httpClient.get<ApiResponse<SubProcesoDto[]>>(`${environment.enves[baseUrl].apiUrl}/SubProceso/SubProcesoByProcesoId/${claveProceso}`);
  }

  /**
   * Servicio para traer los documentos por institucion y procesoid
   * @returns 
   */
  public obtenerDocumentosBySubProceso(subProcesoId: number): Observable<ApiResponse<Documento[]>> {
    return this.httpClient.get<ApiResponse<Documento[]>>(`${environment.enves[baseUrl].apiUrl}/SubProceso/ObtenerDocumentosBySubProceso/${subProcesoId}`);
  }
}
