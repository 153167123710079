import { StudentChangeService } from './../../../../_services/student-change.service';
import { AdmisionesDTO } from './../../../../_models/AdmisionesDTO';
import { Alumno } from 'src/app/_models';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AdminAcademicaService } from 'src/app/_services/admin-academica.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CambiarDatosProgramaModalComponent } from './cambiar-datos-programa-modal/cambiar-datos-programa-modal.component';
import { StudentChangeDTO } from 'src/app/_models/StudentChangeDTO';
import { MatTableDataSource } from '@angular/material/table';
import { AttributesService } from './../../../../_services/attributes.service';
import { AttributesDto } from '@app/_models/AttributesDto';
import { MatOption } from '@angular/material/core';
import { SubprocesosService } from '@app/_services/subprocesos.service';
import { SubProcesoDto } from '@app/_models/SubProcesoDto';

@Component({
  selector: 'app-programa-academico-seguimiento',
  templateUrl: './programa-academico-seguimiento.component.html',
  styleUrls: ['./programa-academico-seguimiento.component.scss']
})
export class ProgramaAcademicoSeguimientoComponent implements OnInit, OnDestroy {

  //#region Observable
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Propiedades
  @Input() data: { alumno: Alumno, indexTap: number };
  public AdmisionesForm: FormGroup;
  public renderizarFormulario: boolean = false;
  public subProcesoList: SubProcesoDto[] = [];
  public existeVariosProcesos: boolean = false;
  public AdmisionesDTO: AdmisionesDTO[];
  public StudentChangeDTO: StudentChangeDTO[];
  public AdmisionSeleccionado: AdmisionesDTO;

  public IsHistorialStudentChange: boolean = false;
  //#endregion

  //#region MatTable
  dataSource: MatTableDataSource<StudentChangeDTO>;
  displayedColumns: string[] = [
    'periodoClave',
    'campusClave',
    'programaClave',
    'fechaCambio',
    'usuario',
    'estatus'];
  //#endregion

  //#region Ciclo de vida
  public attributes: AttributesDto[];
  public existenAtributos: boolean = false;
  public atributoF = new FormControl();
  @ViewChild('allAtritbuto') private allAtritbuto: MatOption;
  public filtroForm: FormGroup;

  constructor(
    private adminAcademiaService: AdminAcademicaService,
    private studentChangeService: StudentChangeService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public attributesService: AttributesService,
    private subprocesosService: SubprocesosService) { }

  async ngOnInit(): Promise<void> {
    // this.obtenerAtributos();
    await this.inicializarForm().then((_) => {
      this.obtenerAtributos();
    });
    this.ObtenerProgramasAcedmicos();
    this.getSubProcesos();
    this.GetStudentChangedsByAlumnoId();
    this.buildForm();
    console.log("DATA", this.data.alumno)
  }
  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  //#region  Metodos

  /**
  * Metodo que consulta los bloques
  */
  public obtenerAtributos() {
    this.attributesService.attributesInstitucion().subscribe((respuesta: ApiResponse<AttributesDto[]>) => {
      this.attributes = respuesta.data;
      this.existenAtributos = this.attributes.length > 0;
      if (this.existenAtributos) {
        this.atributoF.setValue([...this.data.alumno.studentAttributes.map(item => item.attributeId)]);
        this.filtroForm.patchValue({
          atributoF: this.data.alumno.studentAttributes.map(item => item.attributeId)
        });
      }
    })
  }

  /**
 * Metodo para traer catalogo de subprocesos por institucion
 */
  public getSubProcesos() {
    this.subprocesosService.subProcesoInstitucion("NUEVO").subscribe(
      (response: ApiResponse<SubProcesoDto[]>) => {
        this.subProcesoList = response.data;
        if (this.subProcesoList.length <= 1) {
          this.existeVariosProcesos = false;
        } else {
          this.existeVariosProcesos = true;
        }
      })
  }

  /**
* Metodo cuando se seleccionan todos los atributos
*/
  toggleAllSelectionAtributo() {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.filtroForm.patchValue({
        atributoF: this.attributes.map(item => item.attributeId)
      });
      this.atributoF.patchValue([...this.attributes.map(item => item.attributeId), 0]);
    } else {
      this.filtroForm.patchValue({
        atributoF: []
      });
      this.atributoF.patchValue([]);
    }
  }

  /**
* Verifica si el campo Todos del select atributo esta seleccionados, si lo esta
* quita la seleccion de todos los campos, sino los selecciona todos
* @param AtributoId
* @returns
*/
  tosslePerOneAtributo(AtributoId: number) {
    // this.reiniciarTimer();
    if (this.allAtritbuto.selected) {
      this.allAtritbuto.deselect();
    }
    if (this.atributoF.value.length == this.attributes.length) {
      this.allAtritbuto.select();
    }
    const AtributoSeleccionados = this.filtroForm.get('atributoF').value;
    const existeAtributo = AtributoSeleccionados.find(x => x === AtributoId);
    if (!existeAtributo) {
      const AtributoAnteriores = AtributoSeleccionados;
      AtributoAnteriores.push(AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoAnteriores
      });
    } else {
      const AtributoFiltrados = AtributoSeleccionados.filter(x => x !== AtributoId);
      this.filtroForm.patchValue({
        atributoF: AtributoFiltrados
      });
    }
  }

  public ObtenerProgramasAcedmicos(): void {
    this.Subscription.add(
      this.adminAcademiaService.ObtenerProgramasAcedmicos(this.data.alumno.alumnoId).subscribe((Admisiones: ApiResponse<AdmisionesDTO[]>) => {
        this.AdmisionesDTO = Admisiones.data;
      })
    );
  }

  public GetStudentChangedsByAlumnoId(): void {
    this.Subscription.add(
      this.studentChangeService.GetStudentChangedsByAlumnoId(this.data.alumno.alumnoId).subscribe((StudentChangeDTO: ApiResponse<StudentChangeDTO[]>) => {
        this.StudentChangeDTO = StudentChangeDTO.data;

        if (this.StudentChangeDTO.length > 0) {
          this.IsHistorialStudentChange = true;

          for (let i = 0; i < this.StudentChangeDTO.length; i++) {
            //const element = this.StudentChangeDTO[i];
            if (i === 0) {
              this.StudentChangeDTO[i].estatus = "Alumno";
            } else {
              this.StudentChangeDTO[i].estatus = "Cambio Programa";
            }
          }

          this.dataSource = new MatTableDataSource(this.StudentChangeDTO);
        } else {
          this.IsHistorialStudentChange = false;
        }
      })
    );
  }
  //#endregion

  //#region FormBuilder
  /**
   * Metodo que inicializa el form para los filtros
   */
  public inicializarForm(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.filtroForm = new FormGroup({
        atributoF: new FormControl([])
      });
      this.atributoF.setValue(["-1"]);
      resolve();
    });
  }

  private buildForm(): void {
    this.AdmisionesForm = this.formBuilder.group({
      programaAcademico: [this.data.alumno.programaId],
      periodo: [this.data.alumno.priodoIngresoNavigation.clave + " " + this.data.alumno.priodoIngresoNavigation.nombre],
      tipoCandidato: [this.data.alumno.tipoCandidatoId === 0 ? 0 : this.data.alumno.tipoCandidatoId]
    }
    );
    this.AdmisionesForm.get('periodo').disable();
  }

  //#endregion

  //#region Modal Cambio
  public AdmisionSeleccionadoF(AdmisionSeleccionado: AdmisionesDTO): void {
    AdmisionSeleccionado.alumnoID = this.data.alumno.alumnoId;
    this.AdmisionSeleccionado = AdmisionSeleccionado;
  }

  public AbrirModal(): void {
    const dialogRef = this.dialog.open(CambiarDatosProgramaModalComponent, { data: this.AdmisionSeleccionado, disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
      if (result > 0) {
        this.studentChangeService.invocarMetodoObtenerContenido();
        this.adminAcademiaService.invocarMetodoObtenerHistorial(result);
        this.adminAcademiaService.invocarMetodoObtenerAcreditaciones(result);
        this.adminAcademiaService.actualizarDatosTabla();
        this.ObtenerProgramasAcedmicos();
        this.GetStudentChangedsByAlumnoId();
        this.AdmisionesForm.controls['programaAcademico'].setValue(result);
      }
    });
  }
  //#endregion
}
