import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { RazaDto } from '@app/_models/RazaDto';
import { baseUrl, environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RazaService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Metodo para obtener catalogo de raza
   * @returns 
   */
  public obtenerRaza(): Observable<ApiResponse<RazaDto[]>> {
    return this.httpClient.get<ApiResponse<RazaDto[]>>(`${environment.enves[baseUrl].apiUrl}/Raza/ObtenerRaza`);
  }
}
