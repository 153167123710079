import { Component, OnInit, ViewChild, TemplateRef, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RequestCategoryDTO, RequestTypeDTO } from 'src/app/_models/solicitud-dto';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { thistle } from 'color-name';

@Component({
  selector: 'app-solicitud-tipo-modal-edicion',
  templateUrl: './solicitud-tipo-modal-edicion.component.html',
  styleUrls: ['./solicitud-tipo-modal-edicion.component.scss']
})
export class SolicitudTipoModalEdicionComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public errorActualizaRequest: string = "";
  public requestTypeId: number = 0;
  public dialogoRefAdver: any;
  public boolCambio = false;
  public edicionForm: FormGroup;
  public categoriasList: RequestCategoryDTO[] = [];
  public tipos: RequestTypeDTO;
  public verificarCategoria: number;
  public nuevaCategoria: RequestCategoryDTO;
  public errorCategoria: boolean = false;
  public cambioCategoria: boolean = false;
  public seEdito: boolean = false;
  
  constructor(public dialogRef: MatDialogRef<SolicitudTipoModalEdicionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RequestTypeDTO,
    private solicitudService: SolicitudService,
    private snackService: SnackService,
    private dialog: MatDialog) {
      this.boolCambio = false;
      this.inicializarForm();
      this.obtenerCategorias();
      this.checarCategorias(this.data.requestCategoryId);
    }

  ngOnInit(): void {
    this.inicializarForm();
    this.obtenerCategorias();
    this.checarCategorias(this.data.requestCategoryId);
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      requestTypeId: new FormControl(this.data?.requestTypeId),
      requestCategoryId: new FormControl(this.data?.requestCategoryId, [Validators.required]),
      code: new FormControl(this.data?.code, [Validators.required]),
      name: new FormControl(this.data?.name, [Validators.required]),
      description: new FormControl(this.data?.description, [Validators.required]),
      email: new FormControl(this.data?.email, [Validators.required, Validators.email]),
      status: new FormControl(this.data?.status, [Validators.required])
    });
    this.edicionForm.valueChanges.subscribe((cambios) => {
      this.boolCambio = true;
    });
  }

  get requestCategoryId() { return this.edicionForm.get('requestCategoryId'); }
  get name() { return this.edicionForm.get('name'); }
  get status() { return this.edicionForm.get('status'); }
  get code() { return this.edicionForm.get('code'); }
  get description() { return this.edicionForm.get('description'); }
  get email() { return this.edicionForm.get('email'); }

  public cambiarVistaEditar(requestTypeID: number) {
    this.boolCambio = false;
    this.data = Object.assign(this.edicionForm.value);
    this.data.tipoAccion = 2;
  }

  public checarCategorias(event) {
    this.verificarCategoria = this.edicionForm.get('requestCategoryId').value;
    for(let i = 0; i < this.categoriasList.length; i++) {
      if(this.verificarCategoria == this.categoriasList[i].requestCategoryId) {
        this.nuevaCategoria = this.categoriasList[i];
        if(this.nuevaCategoria.isLetter) {
          if(this.seEdito) {
            this.errorCategoria = false;
          } else {
            this.errorCategoria = true;
          }      
          this.cambioCategoria = true;
        } else {
          this.errorCategoria = false;
          this.cambioCategoria = false;
        }
      }
    }
  }

  public enviar() {
    if (this.errorCategoria == false) {
      switch(this.data.tipoAccion) {
        case AccionEnum.CREAR:
          this.data = Object.assign(this.edicionForm.value);
          this.solicitudService.agregarSolicitudTipo(this.data).subscribe(
            (idType) => {
              if (idType > 0){
                this.solicitudService.invocarMetodoObtenerTipo();
                this.snackService.mostrarSnackBack('Se ha guardado la información.');
                this.boolCambio = false;
                this.cambiarVistaEditar(idType);
                //this.ngOnInit();
                this.inicializarForm();           
              } else {
                this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la categoría de solicitud. Por favor, intente de nuevo más tarde.');
                this.data.tipoAccion = 3;
              }
            }, error => {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la categoría de solicitud. Por favor, intente de nuevo más tarde.');
            }
          );
        break;
        case AccionEnum.EDITAR: 
          this.data = Object.assign(this.edicionForm.value);
          this.data.tipoAccion = 2;
          this.solicitudService.modificarSolicitudTipo(this.data).subscribe(
            (editado: boolean) => {
              if(editado) {
                this.solicitudService.invocarMetodoObtenerTipo();
                this.snackService.mostrarSnackBack('Se ha modificado la información.');
                this.boolCambio = false;
                this.seEdito = true;
                this.checarCategorias(this.data.requestCategoryId);
              } else {
                this.snackService.mostrarSnackBack('Ha ocurrido un error al modificar el tipo de solicitud. Por favor, intente de nuevo más tarde.');
              }      
            }, error => {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al modificar el tipo de solicitud. Por favor, intente de nuevo más tarde.');
            }
          );
        break;  
      }
    } else {
      this.snackService.mostrarSnackBack('La categoría que ha seleccionado, no puede ser usada.');
    }
  }

  public obtenerCategorias() {
    this.solicitudService.obtenerSolicitudCategoria().subscribe((categorias: ApiResponse<RequestCategoryDTO[]>) => {
      this.categoriasList = categorias.data;
    })
  }

  public cerrarModalValidacion() {
    if(this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }
}
