import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntidadFederativa } from 'src/app/_models/entidad-federativa';
import { Pais } from 'src/app/_models/pais';
import { TipoDireccion } from 'src/app/_models/tipo-direccion';
import { DireccionService } from 'src/app/_services/direccion.service';

@Component({
  selector: 'app-lead-personal-information-tab',
  templateUrl: './lead-personal-information-tab.component.html',
  styleUrls: ['./lead-personal-information-tab.component.scss'],
})
export class LeadPersonalInformationTabComponent implements OnInit {
  public tipoDireccionList: TipoDireccion[] = [];
  public paisList: Pais[] = [];
  public estadoList: EntidadFederativa[] = [];
  public personalInForm: FormGroup;
  constructor(private direccionService: DireccionService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.getTipoDireccion();
    this.getPais();
    this.inicializarForm();
  }

  public inicializarForm(): void {
    this.personalInForm = new FormGroup({
      //Datos personales
      nombre: new FormControl(this.data ? this.data.name : '', [Validators.required]),
      nombre2: new FormControl(this.data ? this.data.middleName : ''),
      apellidoPaterno: new FormControl(this.data ? this.data.lastname : '', [Validators.required]),
      apellidoMaterno: new FormControl(this.data ? this.data.lastnameSecondary : ''),
      titulo: new FormControl(this.data ? this.data.title : ''),
      fechaNacimiento: new FormControl(
        this.data ? (this.data.birthDate ? new Date(this.data.birthDate).toISOString().slice(0, 10) : '') : ''
      ),
      //Datos de Contacto
      correo: new FormControl(this.data ? this.data.email : '', [Validators.required, Validators.email]),
      correoalt: new FormControl(this.data ? this.data.altEmail : '', [Validators.email]),
      tipoDireccionId: new FormControl(
        this.data ? (this.data.leadAddress.length > 0 ? this.data.leadAddress[0].addressTypeId : 0) : 0
      ),
      pais: new FormControl(
        this.data ? (this.data.leadAddress.length > 0 ? this.data.leadAddress[0].state.paisId : 0) : 0
      ),
      entidadFederativa: new FormControl(
        this.data ? (this.data.leadAddress.length > 0 ? this.data.leadAddress[0].stateId : 0) : 0,
        [this.validateId]
      ),
      ciudad: new FormControl(this.data ? (this.data.leadAddress.length > 0 ? this.data.leadAddress[0].city : '') : ''),
      calle: new FormControl(
        this.data ? (this.data.leadAddress.length > 0 ? this.data.leadAddress[0].address1 : '') : ''
      ),
      numeroExterior: new FormControl(
        this.data ? (this.data.leadAddress.length > 0 ? this.data.leadAddress[0].address2 : '') : ''
      ),
      numeroInterior: new FormControl(
        this.data ? (this.data.leadAddress.length > 0 ? this.data.leadAddress[0].address3 : '') : ''
      ),
      colonia: new FormControl(
        this.data ? (this.data.leadAddress.length > 0 ? this.data.leadAddress[0].county : '') : ''
      ),
      codigoPostal: new FormControl(
        this.data ? (this.data.leadAddress.length > 0 ? this.data.leadAddress[0].zipCode : '') : ''
      ),
      movil: new FormControl(this.data ? this.data.mobile : '', [
        Validators.required,
        Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/),
      ]),
      telefono: new FormControl(this.data ? this.data.phone : '', [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]),
      //Datos Familiares
      pnombreMadre: new FormControl(
        this.data ? (this.data.leadContact.length > 0 ? this.data.leadContact[0].name : '') : ''
      ),
      snombreMadre: new FormControl(
        this.data ? (this.data.leadContact.length > 0 ? this.data.leadContact[0].middleName : '') : ''
      ),
      apellidoPMadre: new FormControl(
        this.data ? (this.data.leadContact.length > 0 ? this.data.leadContact[0].lastname : '') : ''
      ),
      apellidoMMadre: new FormControl(
        this.data ? (this.data.leadContact.length > 0 ? this.data.leadContact[0].lastnameSecondary : '') : ''
      ),
      tituloM: new FormControl(
        this.data ? (this.data.leadContact.length > 0 ? this.data.leadContact[0].title : '') : ''
      ),
      telefonoMadre: new FormControl(
        this.data ? (this.data.leadContact.length > 0 ? this.data.leadContact[0].phone : '') : '',
        [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]
      ),
      correoMadre: new FormControl(
        this.data ? (this.data.leadContact.length > 1 ? this.data.leadContact[1].email : '') : '',
        [Validators.email]
      ),
      pNombrePadre: new FormControl(
        this.data ? (this.data.leadContact.length > 1 ? this.data.leadContact[1].name : '') : ''
      ),
      sNombrePadre: new FormControl(
        this.data ? (this.data.leadContact.length > 1 ? this.data.leadContact[1].middleName : '') : ''
      ),
      apellidoPPadre: new FormControl(
        this.data ? (this.data.leadContact.length > 1 ? this.data.leadContact[1].lastname : '') : ''
      ),
      apellidoMPadre: new FormControl(
        this.data ? (this.data.leadContact.length > 1 ? this.data.leadContact[1].lastnameSecondary : '') : ''
      ),
      tituloP: new FormControl(
        this.data ? (this.data.leadContact.length > 1 ? this.data.leadContact[1].title : '') : ''
      ),
      telefonoPadre: new FormControl(
        this.data ? (this.data.leadContact.length > 1 ? this.data.leadContact[1].phone : '') : '',
        [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]
      ),
      correoPadre: new FormControl(
        this.data ? (this.data.leadContact.length > 1 ? this.data.leadContact[1].email : '') : '',
        [Validators.email]
      ),
      pNombreTutor: new FormControl(
        this.data ? (this.data.leadContact.length > 2 ? this.data.leadContact[2].name : '') : ''
      ),
      sNombreTutor: new FormControl(
        this.data ? (this.data.leadContact.length > 2 ? this.data.leadContact[2].middleName : '') : ''
      ),
      apellidoPTutor: new FormControl(
        this.data ? (this.data.leadContact.length > 2 ? this.data.leadContact[2].lastname : '') : ''
      ),
      apellidoMTutor: new FormControl(
        this.data ? (this.data.leadContact.length > 2 ? this.data.leadContact[2].lastnameSecondary : '') : ''
      ),
      tituloT: new FormControl(
        this.data ? (this.data.leadContact.length > 2 ? this.data.leadContact[2].title : '') : ''
      ),
      telefonoTutor: new FormControl(
        this.data ? (this.data.leadContact.length > 2 ? this.data.leadContact[2].phone : '') : '',
        [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)]
      ),
      correoTutor: new FormControl(
        this.data ? (this.data.leadContact.length > 2 ? this.data.leadContact[2].email : '') : '',
        [Validators.email]
      ),
    });

    let ob = Object.assign(this.personalInForm.value);
    this.onPaisSelected(this.personalInForm.controls['pais'].value);
  }

  get nombre() {
    return this.personalInForm.get('nombre');
  }
  get apellidoPaterno() {
    return this.personalInForm.get('apellidoPaterno');
  }
  get correo() {
    return this.personalInForm.get('correo');
  }
  get correoalt() {
    return this.personalInForm.get('correoalt');
  }
  get movil() {
    return this.personalInForm.get('movil');
  }
  get entidadFederativa() {
    return this.personalInForm.get('entidadFederativa');
  }
  get telefono() {
    return this.personalInForm.get('telefono');
  }
  get telefonoMadre() {
    return this.personalInForm.get('telefonoMadre');
  }
  get telefonoPadre() {
    return this.personalInForm.get('telefonoPadre');
  }
  get correoPadre() {
    return this.personalInForm.get('correoPadre');
  }
  get telefonoTutor() {
    return this.personalInForm.get('telefonoTutor');
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  public getTipoDireccion() {
    this.direccionService.obtenerTipoDireccion().subscribe((tipoDireccion: TipoDireccion[]) => {
      this.tipoDireccionList = tipoDireccion;
    });
  }

  public getPais() {
    this.direccionService.obtenerPaises().subscribe((pais: Pais[]) => {
      // if (this.data.leadAddress[0].stateId) {
      // }
      this.paisList = pais;
    });
  }
  public onPaisSelected(pais: number) {
    this.estadoList = [];
    if (!!pais) {
      this.direccionService
        .obtenerEntidadesFederativas(pais)
        .subscribe((entidadesFederativas: Array<EntidadFederativa>) => {
          this.estadoList = entidadesFederativas;
        });
    }
  }

  public validateForm(): void {
    if (this.personalInForm.get('correo').value != '') {
      this.personalInForm.get('movil').clearValidators();
      this.personalInForm.get('movil').updateValueAndValidity();
    } else if (this.personalInForm.get('movil').value != '') {
      this.personalInForm.get('correo').clearValidators();
      this.personalInForm.get('correo').updateValueAndValidity();
    }
    if (this.personalInForm.get('pais').value == 0) {
      this.personalInForm.get('entidadFederativa').clearValidators();
      this.personalInForm.get('entidadFederativa').updateValueAndValidity();
    }
    this.personalInForm.updateValueAndValidity();
    this.personalInForm.markAllAsTouched();
  }
}
