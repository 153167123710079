import { TraductorService } from 'src/app/_services/traductor.service';
import { ContenidoParcial } from './../../../_models/ensenanza-aprendizaje-profesor';
import { I18NextPipe } from 'angular-i18next';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { EnsenanzaAprendizajeService } from 'src/app/_services/ensenanza-aprendizaje.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as customBuild from '../../../../app/_librarries/ckeditorCustom/build/ckeditor';
import { ContenidoMiCursoComponent } from '../../contenido/contenido-mi-curso/contenido-mi-curso.component';
import { Examen, Pregunta, Respuesta, Seccion, TipoPreguntaCuestionario } from '../../../_models/ensenanza-aprendizaje-profesor';
import { ModalEnlaceAdjuntoComponent } from '../modal-enlace-adjunto/modal-enlace-adjunto.component';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ModalImportarExamenComponent } from '../modal-importar-examen/modal-importar-examen.component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { TipoRecurso } from 'src/app/_models/tipo-recurso';
import { Grupo } from 'src/app/_models/grupo';
import { Recurso } from 'src/app/_models/recurso';
@Component({
  selector: 'app-modal-tema',
  templateUrl: './modal-tema.component.html',
  styleUrls: ['./modal-tema.component.scss']
})
export class ModalTemaComponent implements OnInit {
  @ViewChild('tituloModulo2') tituloModulo: ElementRef;
  @ViewChild('tituloContenido2') tituloContenido: ElementRef;
  @ViewChild('descripcionTarea2') descripcionTarea: CKEditorComponent;
  @ViewChild('fechaEntregaTarea2') fechaEntregaTarea: ElementRef;
  @ViewChild('horaEntregaExamen2') horaEntregaExamen: ElementRef;
  @ViewChild('fechaInicioExamen2') fechaInicioExamen: ElementRef;
  @ViewChild('horaInicioExamen2') horaInicioExamen: ElementRef;
  @ViewChild('cronometroExamen2') cronometroExamen: ElementRef;
  @ViewChild('ponderacionExamen2') ponderacionExamen: ElementRef;
  constructor(
    public dialogRevisionRef: MatDialogRef<ContenidoMiCursoComponent>,
    private ensenanzaServ: EnsenanzaAprendizajeService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private snackService: SnackService,
    private dialog: MatDialog,
    private msalService: MsalService,
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe,
    private i18nextPipe: I18NextPipe,
    private traductorService: TraductorService
  ) { }

  ocultarRecurso: boolean = false;
  flagGuardardoTema: boolean = false;
  flagGuardarIdModulo: any;
  isShown: boolean = false;
  selected: number;
  totalParciales: any[] = [];
  tipoRecurso: Array<TipoRecurso> = [];
  isVacio: boolean;
  //fecha minima a hoy
  minDate: Date;
  flagDate = false;

  disabledSelect = new FormControl(false);
  parcialSeleccionado: number;

  listaArchivosEnlaceInfo: Array<any> = [];
  listaArchivosEnlace: Array<any> = [];
  listadoEnlaces: Array<any> = [];
  listadoArchivos: Array<any> = [];
  //SelectFile
  selectedFile = null;
  public cargaDocumentoForm: FormGroup;
  //Variables del tema
  nombreModulo: string = '';
  selectedModulo: any;
  public cuestionario: Examen;
  //variables para el recurso en general
  modulo_id: number;
  tipo_recursoId: number;
  titulo: string = '';
  clave: string;
  nombre: string;
  orden: number;
  descripcion: string = '';
  publicado: boolean;
  ponderacion: number;
  reintentos: number;
  fechaInicio: string = ""; //seria el campo para fecha de liberacion
  horaInicio: string = "";
  fechaEntrega: string = ""; //seria el campo para fecha de cierre
  horaEntrega: string = "";
  preguntasAleatorias: boolean;
  respuestasAleatorias: boolean;
  permiteVencida: boolean;
  cronometro: number = 0;
  cronometroSegundos: number;
  cerrado: boolean;
  seleccionPregunta: boolean;
  public tiempoSeleccionado = 1;
  ultimo = false;
  existeParcialFinal:boolean = false;
  //traer datos de la modal de enlace-adjunto
  nombreOriginal: string;
  nombreFisico: string;

  public Editor = customBuild;
  today: string | Date;
  //Variables para examen-encuesta//
  public importarExamenValido: boolean = true;
  public importarEncuestaValido: boolean = true;
  public tiposPreguntas: TipoPreguntaCuestionario[] = [];
  public claveMateria;
  public usuario: any;
  recursoIdExamen: number = 3;
  recursoIdEncuesta: number = 4;
  public puntosPreguntaBase;
  public datosGrupo: any;
  public examenCreado = false;
  public objRecurso;
  listaParciales: any[];
  contenido: ContenidoParcial[] = [];
  contenidoGrupoParcial: any[] = [];
  ngOnInit() {
    this.today = new Date();
    this.today = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    let cuenta = this.msalService.instance.getActiveAccount();
    this.claveMateria = this.ensenanzaServ.clave$;
    let identificador = cuenta.username.substring(0, cuenta.username.indexOf('@'));
    this.authenticationService.obtenerDatosUsuario(identificador).subscribe(dataU => {
      this.usuario = dataU;
    })
    this.listaParciales = this.data.listaParciales;
    this.obtenerTipoRecurso();
    this.cargarContenido();
    this.inicializarForm();
    this.ensenanzaServ.metodoObtenerEstructuraSubscription = this.ensenanzaServ.invocarObtenerEstructuraEmit.subscribe(() => {
      this.obtenerEstructura();
    });
    if (!this.data.esNuevoRegistro) {
      this.cargarInfoEditar();
    }
  }
  inicializarForm() {
    this.cargaDocumentoForm = new FormGroup({
      nombreOriginal: new FormControl(),
      nombreFisico: new FormControl(),
    })
  }

  exit() {
    this.dialogRevisionRef.close();
  }
  obtenerTipoRecurso() {
    this.ensenanzaServ.listaRecursos().subscribe((tipoRecurso: ApiResponse<TipoRecurso[]>) => {
      this.tipoRecurso = tipoRecurso.data;
    })
  }
  //#region Get Parciales
   //#region  MultiLenguaje

   private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

//#endregion
  //#region  Cargar contenidos
  public cargarContenido() {
    this.ensenanzaServ.obtenerContenido(this.data.grupoObj.grupoId).subscribe((res: ApiResponse<any>) => {
      if (res.data) {
        this.contenidoGrupoParcial = res.data.grupoParcial;
        this.cargarParciales(res.data.clave);
      }
    })
  }
  //#endregion
  public cargarParciales(clave: string) {
    this.ensenanzaServ.obtenerParcialesProfesor(clave, this.data.grupoObj.grupoId).subscribe(parciales => {
      if (parciales.exito) {
        if(parciales.dataParcialFinal){
          this.existeParcialFinal = true;
        }

        parciales.dataParciales.forEach(parcial => {

          let parcialText = '_tableroProfesor.parcial';
          if (!!this.existeParcialFinal && parcial == parciales.dataParciales.length) {
            parcialText = '_tableroProfesor.parcial-final';
          };

          if (this.contenidoGrupoParcial.length > 0) {
            let nuevoParcial = this.contenidoGrupoParcial.find(object => {
              return object.parcial == parcial
            });
            if (nuevoParcial) {
              let parcialobj = {
                parcial: nuevoParcial.parcial,
                ponderacion: nuevoParcial.ponderacion,
                nombre: nuevoParcial.nombre,
              }
              if (!parcialobj?.nombre) {
                parcialobj.nombre = this.translate(parcialText)// + ' ' + parcial;
              }
              this.contenido.push(parcialobj);
            }else{
              let _parcialObj = {
                parcial: parcial,
                ponderacion: 0,
                nombre: this.translate(parcialText)// + ' ' + parcial
              }
              this.contenido.push(_parcialObj);
            }
          }
          else {
            let _parcialObj = {
              parcial: parcial,
              ponderacion: 0,
              nombre: this.translate(parcialText) + ' ' + parcial
            }
            this.contenido.push(_parcialObj);
          }
        });
      }
    });
  }

  //#endregion


  GuardarBtnContenido = false;
  guardarBtnTema = false;
  btnSeccion = false;
  activarBtnGuardar() {
    if (this.nombreModulo != '' && this.parcialSeleccionado > 0) {
      this.guardarBtnTema = true;
      if (this.ocultarRecurso) {
        //contenido
        if (this.selected === 1) {
          if (this.titulo !== '') {
            this.GuardarBtnContenido = false;
          } else {
            this.GuardarBtnContenido = false;
            this.guardarBtnTema = false;
          }
        }
        //tarea
        if (this.selected === 2) {
          if (this.titulo !== '' && this.descripcion != '' && this.fechaEntrega !== "") {
            this.GuardarBtnContenido = false;
          } else {
            this.GuardarBtnContenido = false;
            this.guardarBtnTema = false;
          }
        }
        //Examen
        if (this.selected === 3) {
          if (this.titulo !== '' && this.descripcion != '' && this.fechaEntrega !== "" && this.fechaInicio !== ""
            && this.horaEntrega !== "" && this.horaInicio !== "" && this.cronometro > 0 && this.ponderacion > 0 && this.reintentos != 0 && this.reintentos <= 999) {
            this.GuardarBtnContenido = false;
            this.guardarBtnTema = true;
            if (this.btnSeccion) {
              this.GuardarBtnContenido = false;
              this.guardarBtnTema = false;
              this.cuestionario.secciones.forEach(seccion => {
                if (seccion.nombre != "") {
                  this.GuardarBtnContenido = false;
                  this.guardarBtnTema = true;
                  seccion.cuestionarioPregunta.forEach(pregunta => {
                    if (pregunta.titulo != "" && pregunta.tipoPreguntaCuestionarioId !== "") {
                      if (pregunta.tipoPreguntaCuestionarioId.toString() === '1') {
                        if (pregunta.ponderacion > 0) {
                          this.GuardarBtnContenido = false;
                        } else {
                          this.GuardarBtnContenido = false;
                          this.guardarBtnTema = false;
                        }
                      }
                      if (pregunta.tipoPreguntaCuestionarioId.toString() === '2') {
                        var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                        var detectarPonderacion = this.validarPonderacion(pregunta.cuestionarioRespuesta)
                        if (detectarVacio.length == 0 && detectarPonderacion.length > 0) {
                          this.GuardarBtnContenido = false;
                        } else {
                          this.GuardarBtnContenido = false;
                          this.guardarBtnTema = false;
                        }
                      }
                      if (pregunta.tipoPreguntaCuestionarioId.toString() === '3') {
                        var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                        var detectarPonderacion = this.validarPonderacion(pregunta.cuestionarioRespuesta)
                        if (detectarVacio.length == 0 && detectarPonderacion.length > 0) {
                          this.GuardarBtnContenido = false;
                        } else {
                          this.GuardarBtnContenido = false;
                          this.guardarBtnTema = false;
                        }
                      }
                      if (pregunta.tipoPreguntaCuestionarioId.toString() === '4') {
                        pregunta.cuestionarioRespuesta.forEach(respuesta => {
                          if (respuesta.titulo != "" && (pregunta.ponderacion === this.ponderacion)) {
                            this.GuardarBtnContenido = false;
                          }
                        })
                      }
                    } else {
                      this.GuardarBtnContenido = false;
                      this.guardarBtnTema = false;
                    }
                  });
                }
                else {
                  this.GuardarBtnContenido = false;
                  this.guardarBtnTema = false;
                }
              });
              this.btnSeccion = false;
            }
            else {
              this.GuardarBtnContenido = false;
              this.guardarBtnTema = true;
            }
          } else {
            this.GuardarBtnContenido = false;
            this.guardarBtnTema = false;
          }
        }
        //Encuesta
        if (this.selected === 4) {
          if (this.titulo !== '' && this.descripcion != '' && this.fechaEntrega !== "" && this.fechaInicio !== ""
            && this.horaEntrega !== "" && this.horaInicio !== "") {
            this.GuardarBtnContenido = false;
            this.guardarBtnTema = true;
            if (this.btnSeccion) {
              this.GuardarBtnContenido = false;
              this.guardarBtnTema = false;
              this.cuestionario.secciones.forEach(seccion => {
                if (seccion.nombre != "") {
                  this.GuardarBtnContenido = false;
                  this.guardarBtnTema = true;
                  seccion.cuestionarioPregunta.forEach(pregunta => {
                    if (pregunta.titulo != "" && pregunta.tipoPreguntaCuestionarioId !== "") {
                      if (pregunta.tipoPreguntaCuestionarioId.toString() === '1') {
                        this.GuardarBtnContenido = false;
                        this.guardarBtnTema = true;
                      }
                      if (pregunta.tipoPreguntaCuestionarioId.toString() === '2') {
                        var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                        if (detectarVacio.length > 0) {
                          this.GuardarBtnContenido = false;
                          this.guardarBtnTema = false;
                        } else {
                          this.GuardarBtnContenido = false;
                          this.guardarBtnTema = true;
                        }
                      }
                      if (pregunta.tipoPreguntaCuestionarioId.toString() === '3') {
                        var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                        if (detectarVacio.length > 0) {
                          this.GuardarBtnContenido = false;
                          this.guardarBtnTema = false;
                        } else {
                          this.GuardarBtnContenido = false;
                          this.guardarBtnTema = true;
                        }
                      }
                      if (pregunta.tipoPreguntaCuestionarioId.toString() === '4') {
                        var detectarVacio = this.validarEspacioVacioRespuesta(pregunta.cuestionarioRespuesta);
                        if (detectarVacio.length > 0) {
                          this.GuardarBtnContenido = false;
                          this.guardarBtnTema = false;
                        } else {
                          this.GuardarBtnContenido = false;
                          this.guardarBtnTema = true;
                        }
                      }
                    }
                    else {
                      this.GuardarBtnContenido = false;
                      this.guardarBtnTema = false;
                    }
                  });

                } else {
                  this.GuardarBtnContenido = false;
                  this.guardarBtnTema = false;
                }
              });
              this.btnSeccion = false;
            }
          } else {
            this.GuardarBtnContenido = false;
            this.guardarBtnTema = false;
          }
        }
      }
    }
  }

  validarEspacioVacioRespuesta(array: Respuesta[]) {
    //examen
    var detectarVacio = array.filter(function (event) {
      return event.titulo === "";
    });
    return detectarVacio;
  }

  validarPonderacion(array: Respuesta[]) {
    var detectarMayorcero = array.filter(function (event) {
      return event.ponderacion > 0;
    })
    return detectarMayorcero;
  }

  public salir() {
    this.ensenanzaServ.invocarMetodoObtenerContenido();
  }
  agregarEditarTema() {
    if (this.data.esNuevoRegistro) {
      //nuevo registro
      let guardadoTema;
      if (this.flagGuardardoTema) {
        guardadoTema = {
          moduloId: this.flagGuardarIdModulo.moduloId,
          nombre: this.nombreModulo,
          parcial: this.parcialSeleccionado,
          grupoID: this.data.grupoId,
        }
      } else {
        guardadoTema = {
          nombre: this.nombreModulo,
          parcial: this.parcialSeleccionado,
          grupoID: this.data.grupoId,
        }
      }
      this.ensenanzaServ.PostTemaModulo(guardadoTema).subscribe(restema => {
        if (restema.exito) {
          //falta cargarle el recurso
          this.flagGuardardoTema = true;
          this.flagGuardarIdModulo = restema.data;
          if (this.ocultarRecurso) {
            if (this.data.esNuevoRegistro) {
              let tipoRecurso = this.selected;
              //es nuevo registro
              switch (tipoRecurso) {
                case 1: //para Contenido
                  let dataContenido;
                  if (this.objRecurso == undefined) {
                    dataContenido = {
                      moduloId: restema.data.moduloId,
                      tipoRecursoId: this.selected,
                      clave: this.data.grupoObj.clave,
                      nombre: this.titulo,
                      descripcion: this.descripcion
                    }
                  } else {
                    dataContenido = {
                      moduloId: restema.data.moduloId,
                      tipoRecursoId: this.selected,
                      clave: this.data.grupoObj.clave,
                      nombre: this.titulo,
                      descripcion: this.descripcion,
                      recursoId: this.objRecurso.recursoId
                    }
                  }
                  this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, 0, dataContenido.moduloId, dataContenido).subscribe(res => {
                    if (res.exito) {
                      this.objRecurso = res.data;
                      if (this.listaArchivosEnlace.length > 0) {
                        //parte del adjunto llamar la lista concatenada y asignarle el recursoId
                        this.listaArchivosEnlace.forEach(element => {
                          element.recursoId = res.data.recursoId
                        });
                        this.ensenanzaServ.subirArchivoEnlace(this.listaArchivosEnlace).subscribe(res => {
                          if (res.exito) {
                            this.listaArchivosEnlaceInfo = this.listaArchivosEnlaceInfo.concat(res.data);
                            //enlazar listado
                            this.ensenanzaServ.invocarMetodoObtenerContenido();
                          }
                        });
                        this.listaArchivosEnlace = [];
                      } else {
                        this.ensenanzaServ.invocarMetodoObtenerContenido();
                      }
                      let mensaje: string = this.traductorService.translate('guardadoCorrecto');
                      this.snackService.mostrarSnackBack(mensaje);
                    }
                  });
                  break;
                case 2: // para Tarea
                  if ((this.ponderacion == undefined ? 0 : this.ponderacion) <= this.ponderacionParcialActual) {

                    let dataTarea;
                    if (this.objRecurso == undefined) {
                      dataTarea = {
                        moduloId: restema.data.moduloId,
                        tipoRecursoId: this.selected,
                        clave: '', //no se como pasarle la clave del grupo ya se me ocurrirá algo
                        nombre: this.titulo,
                        descripcion: this.descripcion,
                        fechaEntrega: this.fechaEntrega,
                        permiteVencida: this.permiteVencida,
                        ponderacion: this.ponderacion == undefined ? 0 : this.ponderacion,
                      }
                    } else {
                      dataTarea = {
                        moduloId: restema.data.moduloId,
                        tipoRecursoId: this.selected,
                        clave: '', //no se como pasarle la clave del grupo ya se me ocurrirá algo
                        nombre: this.titulo,
                        descripcion: this.descripcion,
                        fechaEntrega: this.fechaEntrega,
                        permiteVencida: this.permiteVencida,
                        ponderacion: this.ponderacion == undefined ? 0 : this.ponderacion,
                        recursoId: this.objRecurso.recursoId
                      }
                    }
                    this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoObj.grupoId, this.ponderacionParcialActual, dataTarea.moduloId, dataTarea).subscribe(res => {
                      if (res.exito) {
                        this.objRecurso = res.data;
                        if (this.listaArchivosEnlace.length > 0) {
                          //parte del adjunto llamar la lista concatenada y asignarle el recursoId
                          this.listaArchivosEnlace.forEach(element => {
                            element.recursoId = res.data.recursoId
                          });
                          this.ensenanzaServ.subirArchivoEnlace(this.listaArchivosEnlace).subscribe(res => {
                            if (res.exito) {
                              this.listaArchivosEnlaceInfo = this.listaArchivosEnlaceInfo.concat(res.data);
                              //enlazar listado
                              this.ensenanzaServ.invocarMetodoObtenerContenido();
                            }
                          });
                          this.listaArchivosEnlace = [];
                        } else {
                          this.ensenanzaServ.invocarMetodoObtenerContenido();
                        }
                        let mensaje: string = this.traductorService.translate('guardadoCorrecto');
                        this.snackService.mostrarSnackBack(mensaje);
                      } else {
                        this.snackService.mostrarSnackBack(res.message);
                      }
                    })
                  } else {
                    this.snackService.mostrarSnackBack(`La ponderacion no puede ser mayor a la ponderacion base ${this.puntosPreguntaBase}.`);
                  }
                  break;
                case 3:
                  let fechaLiberacionEx = this.obtenerFechaHora(this.fechaInicio, this.horaInicio);
                  let fechaCierreEx = this.obtenerFechaHora(this.fechaEntrega, this.horaEntrega);
                  if (fechaLiberacionEx < fechaCierreEx) {
                    if (this.ponderacion <= this.ponderacionParcialActual) {
                      let dataExamen;
                      if (this.objRecurso == undefined) {
                        dataExamen = {
                          moduloId: restema.data.moduloId,
                          tipoRecursoId: this.selected,
                          clave: '',
                          nombre: this.titulo,
                          fechaInicio: this.fechaInicio,
                          horaInicio: this.horaInicio,
                          descripcion: this.descripcion,
                          fechaEntrega: this.fechaEntrega,
                          horaFin: this.horaEntrega,
                          ordenAleatorio: this.preguntasAleatorias,
                          ordenAleatorioRespuestas: this.respuestasAleatorias,
                          ponderacion: this.ponderacion,
                          reintentos: this.reintentos,
                          cronometro: this.cronometroSegundos
                        }
                      } else {
                        dataExamen = {
                          moduloId: restema.data.moduloId,
                          tipoRecursoId: this.selected,
                          clave: '',
                          nombre: this.titulo,
                          fechaInicio: this.fechaInicio,
                          horaInicio: this.horaInicio,
                          descripcion: this.descripcion,
                          fechaEntrega: this.fechaEntrega,
                          horaFin: this.horaEntrega,
                          ordenAleatorio: this.preguntasAleatorias,
                          ordenAleatorioRespuestas: this.respuestasAleatorias,
                          ponderacion: this.ponderacion,
                          reintentos: this.reintentos,
                          cronometro: this.cronometroSegundos,
                          recursoId: this.objRecurso.recursoId

                        }
                      }

                      this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoId, this.ponderacionParcialActual, dataExamen.moduloId, dataExamen).subscribe(res => {
                        if (res.exito) {
                          this.objRecurso = res.data;
                          this.ensenanzaServ.CreacionEdicionExamenEncuesta(res.data.recursoId, this.cuestionario.secciones).subscribe(res => {
                            let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
                            this.snackService.mostrarSnackBack(mensaje);
                            this.ensenanzaServ.invocarMetodoObtenerContenido();
                          });
                        } else {
                          this.snackService.mostrarSnackBack(res.message);
                          this.ensenanzaServ.invocarMetodoObtenerContenido();
                        }
                      });
                    } else {
                      this.snackService.mostrarSnackBack(`La ponderación no puede ser mayor a la ponderación base ${this.puntosPreguntaBase}.`);
                    }
                  } else {
                    this.snackService.mostrarSnackBack('La fecha liberación no puede ser mayor a la fecha cierre.');
                  }
                  break;
                case 4: // para Encuestas
                  let fechaLiberacionEn = this.obtenerFechaHora(this.fechaInicio, this.horaInicio);
                  let fechaCierreEn = this.obtenerFechaHora(this.fechaEntrega, this.horaEntrega);
                  if (fechaLiberacionEn < fechaCierreEn) {
                    let dataEncuesta;
                    if (this.objRecurso == undefined) {
                      dataEncuesta = {
                        moduloId: restema.data.moduloId,
                        tipoRecursoId: this.selected,
                        clave: '',
                        nombre: this.titulo,
                        fechaInicio: this.fechaInicio,
                        horaInicio: this.horaInicio,
                        descripcion: this.descripcion,
                        fechaEntrega: this.fechaEntrega,
                        horaFin: this.horaEntrega,
                        ordenAleatorio: this.preguntasAleatorias,
                        ordenAleatorioRespuesta: this.respuestasAleatorias
                      }
                    } else {
                      dataEncuesta = {
                        moduloId: restema.data.moduloId,
                        tipoRecursoId: this.selected,
                        clave: '',
                        nombre: this.titulo,
                        fechaInicio: this.fechaInicio,
                        horaInicio: this.horaInicio,
                        descripcion: this.descripcion,
                        fechaEntrega: this.fechaEntrega,
                        horaFin: this.horaEntrega,
                        ordenAleatorio: this.preguntasAleatorias,
                        ordenAleatorioRespuesta: this.respuestasAleatorias,
                        recursoId: this.objRecurso.recursoId
                      }
                    }
                    this.ensenanzaServ.CreacionEdicionRecurso(this.data.grupoId, 0, dataEncuesta.moduloId, dataEncuesta).subscribe(res => {
                      if (res.exito) {
                        this.objRecurso = res.data;
                        this.ensenanzaServ.CreacionEdicionExamenEncuesta(res.data.recursoId, this.cuestionario.secciones).subscribe(res => {
                          let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
                          this.snackService.mostrarSnackBack(mensaje);
                          this.ensenanzaServ.invocarMetodoObtenerContenido();
                        });
                      } else {
                        this.snackService.mostrarSnackBack(res.message);
                        this.ensenanzaServ.invocarMetodoObtenerContenido();
                      }
                    });
                  } else {
                    this.snackService.mostrarSnackBack('La fecha liberacion no puede ser mayor a la fecha cierre.');
                  }
                  break;
              }
            }
          } else {
            this.ensenanzaServ.invocarMetodoObtenerContenido();
            this.snackService.mostrarSnackBack('Se agregó el tema correctamente');
          }
        }
      });
    }
    else {
      //editar registro
      let editarTema = {
        grupoID: this.data.modulo.grupoId,
        moduloId: this.data.modulo.moduloId,
        nombre: this.nombreModulo,
        parcial: this.parcialSeleccionado,
      }
      this.ensenanzaServ.PutTemaModulo(editarTema.grupoID, editarTema.moduloId, editarTema).subscribe(res => {
        let mensaje: string = this.traductorService.translate('guardadoCorrecto');
        this.snackService.mostrarSnackBack(mensaje);
        this.ensenanzaServ.invocarMetodoObtenerContenido();
      }, error => {
        this.snackService.mostrarSnackBack('Error al guardar');
        this.ensenanzaServ.invocarMetodoObtenerContenido();
      })
    }
  }

  publicarRecurso() {
    if (!this.data.esNuevoRegistro) {
      if (this.data.recurso.tipoRecursoId == 3) {
        if (this.examenCreado) {
          let puntosActulCuestionario = this.obtenerPuntosActualCuestionario();
          if (!(puntosActulCuestionario < this.puntosPreguntaBase)) {
            this.publicarRecursoServ(this.puntosPreguntaBase, this.objRecurso.recursoId);
          } else {
            this.snackService.mostrarSnackBack('Para publicar un examen, la sumatoria de los puntos debe ser igual al máximo valor numérico.');
          }
        } else {
          this.snackService.mostrarSnackBack('Guarde un examen valido para poder publicar el recurso.');
        }
      } else {
        this.publicarRecursoServ(this.puntosPreguntaBase, this.objRecurso.recursoId);
      }
    } else {
      this.publicarRecursoServ(this.puntosPreguntaBase, this.objRecurso.recursoId);
    }
  }

  publicarRecursoServ(puntosMaximoBase, recursoId) {
    this.ensenanzaServ.PublicarRecurso(puntosMaximoBase, recursoId).subscribe(res => {
      if (res.exito) {
        this.snackService.mostrarSnackBack(res.message);
      } else {
        this.snackService.mostrarSnackBack(res.message);
      }
    })
  }

  obtenerPuntosActualCuestionario() {
    let sumaTotal = 0;
    this.cuestionario.secciones.forEach(seccion => {
      seccion.cuestionarioPregunta.forEach(pregunta => {
        sumaTotal += pregunta.ponderacion;
      })
    })

    return sumaTotal;
  }
  ponderacionParcialActual: number;
  changePonderacionParcial(event) {
    let parcialActual = event.target.value;
    let pondeParcial = this.data.listaParciales.filter(x => x.parcial.toString() === parcialActual);
    this.ponderacionParcialActual = pondeParcial[0].ponderacion;
  }

  public changesPonderacionPregunta(indexSeccion, indexPregunta) {
    let ponderacionTotalActual = 0;
    let ponderacionActual = ponderacionTotalActual + this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion;
    ponderacionTotalActual = ponderacionTotalActual - ponderacionActual;
    this.cuestionario.secciones.forEach(seccion => {
      seccion.cuestionarioPregunta.forEach(pregunta => {
        ponderacionTotalActual = ponderacionTotalActual + pregunta.ponderacion;
        if (ponderacionTotalActual >= this.puntosPreguntaBase) {
          this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = 0;
          let mensaje3: string = this.traductorService.translate("mensajePonderacion3");
          this.snackService.mostrarSnackBack(`${mensaje3} ${this.puntosPreguntaBase}.`);
        }
      })
    })

    ponderacionTotalActual = ponderacionTotalActual + ponderacionActual;
    if (ponderacionTotalActual > this.puntosPreguntaBase) {
      this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = 0;
      let mensaje3: string = this.traductorService.translate("mensajePonderacion3");
      this.snackService.mostrarSnackBack(`${mensaje3} ${this.puntosPreguntaBase}.`);
    }
  }

  mostrarAgregarRecurso() {
    this.isShown = !this.isShown;
    this.ocultarRecurso = !this.ocultarRecurso;
    if (this.ocultarRecurso) {
      this.GuardarBtnContenido = false;
      this.guardarBtnTema = false;
      if (this.titulo == "" || this.titulo != "") {
        setTimeout(() => {
          this.tituloContenido.nativeElement.focus();
        }, 100);
      }
      if (this.descripcion == "") {
        setTimeout(() => {
          this.descripcionTarea.editorInstance.editing.view.focus();
        }, 100);
      }
      if (this.fechaEntrega == "") {
        setTimeout(() => {
          this.fechaEntregaTarea.nativeElement.focus();
        }, 100);
      }
    }
    if (!this.ocultarRecurso) {
      setTimeout(() => {
        this.tituloModulo.nativeElement.focus();
      }, 100);
    }
  }
  onChange(event) {
    //Contenido
    if (this.selected === 1) {
      this.puntosPreguntaBase = 0;
    }

    //Tarea
    if (this.selected === 2) {
      this.ensenanzaServ.getPonderacionGrupo(this.data.grupoId).subscribe((grupo: ApiResponse<any>) => {
        this.datosGrupo = grupo.data;
        this.puntosPreguntaBase = this.datosGrupo.materiaOferta.materia.ciclo.programa.escalaCalificacionNavigation.calificacionNoNumerica[0].valorNumerico;
      })
    }

    //Examen
    if (this.selected === 3) {
      this.obtenerTipoPreguntas();
      this.cuestionario = new Examen();
      this.ensenanzaServ.getPonderacionGrupo(this.data.grupoId).subscribe((grupo: ApiResponse<any>) => {
        this.datosGrupo = grupo.data;
        this.puntosPreguntaBase = this.datosGrupo.materiaOferta.materia.ciclo.programa.escalaCalificacionNavigation.calificacionNoNumerica[0].valorNumerico;
      })
    }

    //Encuesta
    if (this.selected === 4) {
      this.obtenerTipoPreguntas();
      this.cuestionario = new Examen()
      this.puntosPreguntaBase = 0;
    }
  }

  obtenerFechaHora(date, time) {
    let momentObj;
    if (time == undefined) {
      momentObj = moment(date, 'YYYY-MM-DDLT');
    } else {
      momentObj = moment(date + time, 'YYYY-MM-DDLT');
    }
    // conversion
    var dateTime = momentObj.format('YYYY-MM-DDTHH:mm:s');
    return dateTime;
  }

  capturar() {
    this.parcialSeleccionado = this.data.tituloModulo.parcial;
  }

  cargarInfoEditar() {
    this.ocultarRecurso = false;
    this.nombreModulo = this.data.modulo.nombre;
    this.parcialSeleccionado = this.data.modulo.parcial;
    setTimeout(() => {
      this.tituloModulo.nativeElement.focus();
    }, 100);
  }

  puntajeTotalPreguntas = 0.0;
  mostrarTotalPuntajePreguntas(indexSeccion?, indexPregunta?) {
    let ponderacionPregunta = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta;
    let sum: number = 0;
    ponderacionPregunta.forEach(a => sum += a.ponderacion);
    let ponderacionActual = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion;
    this.puntajeTotalPreguntas = sum;

    if (this.puntajeTotalPreguntas == 0) {
      this.cuestionario.secciones.forEach(seccion => {
        seccion.cuestionarioPregunta.forEach(pregunta => {
          this.puntajeTotalPreguntas += pregunta.ponderacion;
        })
      })
    }
  }
  //me mandar doble objeto al hacer el post :/
  meterLiga() {
    const dialogRef = this.dialog.open(ModalEnlaceAdjuntoComponent, {
      data: {
        nombreOriginal: this.nombreOriginal,
        nombreFisico: this.nombreFisico,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.nombreOriginal = result.nombreOriginal;
      this.nombreFisico = result.nombreFisico;
      this.listadoEnlaces.push(result);
      this.listadoEnlaces.forEach(element => {
        element.esArchivo = false;
      });
      this.listaArchivosEnlace = this.listaArchivosEnlace.concat(this.listadoEnlaces);
    })
    //vaciar la lista
    this.listadoEnlaces = [];
  }
  //enlazar button a un inputFile
  showSelectFile() {
    const input = document.getElementById('nombreOriginal');
    input.click();
  }
  //Guardado de archivo...
  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.onload = () => {
        this.cargaDocumentoForm.patchValue({
          nombreOriginal: file.name,
          nombreFisico: reader.result
        });
        this.listadoArchivos.push(Object.assign(this.cargaDocumentoForm.value));
        this.listadoArchivos.forEach(element => {
          element.esArchivo = true;
        });
        this.listaArchivosEnlace = this.listaArchivosEnlace.concat(this.listadoArchivos);
        for (var i = 0; i < this.listaArchivosEnlace.length; i++) {
          var indexDocument = this.listaArchivosEnlace[i].nombreOriginal.indexOf(".");
          var tipoDocument = this.listaArchivosEnlace[i].nombreOriginal.substr(indexDocument);
          this.listaArchivosEnlace[i].tipoDocumento = tipoDocument;
        }
      };
      //vaciar la lista
      this.listadoArchivos = [];
      reader.readAsDataURL(file); //error
    }
  }
  eliminarAdjunto(indice: number) {
    this.listaArchivosEnlace.splice(indice, 1);
  }
  eliminarAdjuntoEditar(recursoAdjunto: any, index: number) {
    this.ensenanzaServ.deleteDocumentoById(recursoAdjunto.recursoAdjuntoId).subscribe((res: SimpleResponse) => {
      this.data.recurso.recursoAdjunto = this.data.recurso.recursoAdjunto
        .filter(x => x.recursoAdjuntoId != recursoAdjunto.recursoAdjuntoId);
      this.snackService.mostrarSnackBack('Archivo eliminado.');
    });
    this.listaArchivosEnlaceInfo.splice(index, 1);
  }

  /////////CODIGO PARA ENCUESTA Y EXAMEN///////////////
  public agregarSeccion() {
    this.btnSeccion = true;
    let seccion = new Seccion();
    seccion.cuestionarioPregunta.push(new Pregunta());
    this.cuestionario.secciones.push(seccion);
    this.importarExamenValido = false;
    this.importarEncuestaValido = false;
  }

  public agregarPregunta(seccion: Seccion) {
    seccion.cuestionarioPregunta.push(new Pregunta())
  }

  cambiarPregunta(seccionIndex: number, indexPregunta: number, pregunta: any) {
    this.GuardarBtnContenido = false;
    this.guardarBtnTema = false;

    if (this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].tipoPreguntaCuestionarioId == "1") {
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].ponderacion = 0;
    }

    if (this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].tipoPreguntaCuestionarioId == "2" ||
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].tipoPreguntaCuestionarioId == "3") {
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].ponderacion = 0;
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.length = 0;
      this.agregarRespuesta(pregunta);
      this.agregarRespuesta(pregunta);
    }

    if (this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].tipoPreguntaCuestionarioId == "4") {
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].ponderacion = 0;
      this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.length = 0;
      if (this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.length == 0) {
        for (let i = 1; i <= 5; i++) {
          this.cuestionario.secciones[seccionIndex].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.push(new Respuesta(i))
        }
      }
    }
  }

  public agregarRespuesta(pregunta: Pregunta) {
    this.GuardarBtnContenido = false;
    this.guardarBtnTema = false;
    pregunta.cuestionarioRespuesta.push(new Respuesta())
  }

  public obtenerTipoPreguntas() {
    this.ensenanzaServ.getTipoPreguntaCuestionario().subscribe((tipoPregunta: ApiResponse<TipoPreguntaCuestionario[]>) => {
      this.tiposPreguntas = tipoPregunta.data;
    })
  }

  public obtenerEstructura() {
    if (this.ensenanzaServ.estructuraExamen$) {
      this.cuestionario.secciones = this.ensenanzaServ.estructuraExamen$;
      this.importarExamenValido = false;
      this.importarEncuestaValido = false;
    }
  }

  importar(tipoRecursoId) {
    if (tipoRecursoId == 3) {
      this.ensenanzaServ.getExamenesPorImportar(this.usuario.usuarioId, this.claveMateria).subscribe((respuestaExamen: ApiResponse<Recurso[]>) => {
        if (respuestaExamen.data.length > 0) {
          this.dialog.open(ModalImportarExamenComponent, { data: respuestaExamen.data, disableClose: true });
        } else {
          this.snackService.mostrarSnackBack('No se encuentra ningun examen para importar.');
        }
      })
    }
    if (tipoRecursoId == 4) {
      this.ensenanzaServ.getEncuestaPorImportar(this.usuario.usuarioId, this.claveMateria).subscribe((respuestaEncuesta: ApiResponse<Recurso[]>) => {
        if (respuestaEncuesta.data.length > 0) {
          this.dialog.open(ModalImportarExamenComponent, { data: respuestaEncuesta.data, disableClose: true });
        } else {
          this.snackService.mostrarSnackBack('No se encuentra ningun examen para importar.');
        }
      })
    }
  }

  public changesPonderacionRespuesta(indexSeccion, indexPregunta, indexRespuesta, event) {
    if (event.keyCode === 190) {
    } else {
      let respuestasPregunta = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta;
      let ponderacionTotalActual = 0.0;
      let valido = true;
      let ponderacionActual = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta[indexRespuesta].ponderacion;
      let sum: number = 0;
      respuestasPregunta.forEach(a => sum += a.ponderacion);
      var nuevoValor = sum - ponderacionActual;
      if (nuevoValor == 0) {
        this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = 0;
      } else {
        this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = nuevoValor;
      }
      this.cuestionario.secciones.forEach(seccion => {
        seccion.cuestionarioPregunta.forEach((pregunta, index) => {
          ponderacionTotalActual += pregunta.ponderacion;
          if (ponderacionTotalActual > this.puntosPreguntaBase) {
            valido = false;
            this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta[indexRespuesta].ponderacion = 0;
            let mensaje3: string = this.traductorService.translate("mensajePonderacion3");
            this.snackService.mostrarSnackBack(`${mensaje3} ${this.puntosPreguntaBase}`);
          }
        })
      })
      ponderacionTotalActual = ponderacionTotalActual + ponderacionActual;
      if (ponderacionTotalActual > this.puntosPreguntaBase) {
        valido = false;
        this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta[indexRespuesta].ponderacion = 0;
        let mensaje3: string = this.traductorService.translate("mensajePonderacion3");
        this.snackService.mostrarSnackBack(`${mensaje3} ${this.puntosPreguntaBase}`);
      }
      if (valido) {
        this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = sum;

      } else {
        this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion = nuevoValor;

      }
    }
  }


  onChangeValorTiempo() {

    if (this.tiempoSeleccionado == 1) {
      this.cronometroSegundos = this.cronometro * 3600;
    }

    if (this.tiempoSeleccionado == 2) {
      this.cronometroSegundos = this.cronometro * 60;
    }
  }

  onChangeTiempo(value) {
    if (value == 1) {
      this.cronometroSegundos = this.cronometro * 3600;
    }

    if (value == 2) {
      this.cronometroSegundos = this.cronometro * 60;
    }
  }

  eliminarSeccion(indexSeccion) {
    this.guardarBtnTema = true;
    this.btnSeccion = false;
    if (this.cuestionario.secciones[indexSeccion].cuestionarioPregunta.length > 0) {
      this.snackService.mostrarSnackBack('No se puede eliminar secciones con preguntas asignadas.');
    } else {
      this.cuestionario.secciones.splice(indexSeccion, 1);
    }
    if (this.cuestionario.secciones.length == 0) {
      this.importarExamenValido = true;
      this.importarEncuestaValido = true;
    }

  }

  eliminarPregunta(indexSeccion, indexPregunta) {
    this.puntajeTotalPreguntas -= this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion;
    this.guardarBtnTema = true;
    this.cuestionario.secciones[indexSeccion].cuestionarioPregunta.splice(indexPregunta, 1);
  }
  eliminarRespuesta(indexSeccion, indexPregunta, indexRespuesta) {
    let ponderacionRespuesta = this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta[indexRespuesta].ponderacion;
    this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].ponderacion -= ponderacionRespuesta;
    this.guardarBtnTema = true;
    this.cuestionario.secciones[indexSeccion].cuestionarioPregunta[indexPregunta].cuestionarioRespuesta.splice(indexRespuesta, 1);
  }
}
