import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { CuestionarioArea, CuestionarioRespuestaDto } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { EnsenanzaAprendizajeAlumnoService } from '../../../../_services/ensenanzaAprendizajeAlumno.service'

@Component({
  selector: 'app-modal-resultado-ex',
  templateUrl: './modal-resultado-ex.component.html',
  styleUrls: ['./modal-resultado-ex.component.scss']
})
export class ModalResultadoExComponent implements OnInit {
  public alumnoId: number;
  public formulario: FormGroup;
  public nombreEncuesta: any;
  public secciones: any[];
  public puntosTotalObtenidos = 0;
  public ponderacionExamen = 0;
  public puntosObtenidosExamen = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalResultadoExComponent>,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService) { }

  ngOnInit(): void {
    this.formulario = new FormGroup({

    });
    this.alumnoId = this.EnsenanzaAprendizajeAlumnoService.alumnoId$;
    if (this.data.tipoRecursoId == 3) {
      this.EnsenanzaAprendizajeAlumnoService.GetConsultarResultadoExamen(this.data.recursoId, this.alumnoId).subscribe((areas: ApiResponse<CuestionarioRespuestaDto[]>) => {
        this.secciones = areas.data;
        console.log("this.secciones",this.secciones);
        this.secciones.forEach(element => {
          element.cuestionarioPreguntaDto.forEach(item => {
            this.puntosTotalObtenidos = this.puntosTotalObtenidos + item.puntosObtenidos;
          })
        })
        this.puntosTotalObtenidos = Math.round(this.puntosTotalObtenidos);
        this.ponderacionExamen = this.secciones[0].recurso.ponderacion;
        this.puntosObtenidosExamen = Math.round((this.puntosTotalObtenidos * this.ponderacionExamen) / 100);
      })
    }
    if (this.data.tipoRecursoId == 4) {
      this.EnsenanzaAprendizajeAlumnoService.GetEncuestaContestar(this.data.recursoId).subscribe((en: ApiResponse<CuestionarioArea[]>) => {
        this.secciones = en.data;
        this.crearFormulario();
      })
    }
  }

  public crearFormulario() {
    this.secciones.forEach(element => {
      element.nombre = this.stringToHtml(element.nombre);
      element.cuestionarioPregunta.forEach(item => {
        var tipoPreguntaCuestionario = item.tipoPreguntaCuestionarioId;
        element.alumnoId = this.alumnoId;
        switch (tipoPreguntaCuestionario) {
          //Pregunta Abierta
          case 1:
            this.formulario.addControl('abierta' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            this.secciones.forEach(element => {
              element.cuestionarioPregunta.forEach(itemSeccion => {
                if (itemSeccion.cuestionarioPreguntaId == item.cuestionarioPreguntaId) {
                  itemSeccion.titulo = this.stringToHtml(item.titulo);
                }
              })
            });
            break;

          //Seleccion Unica
          case 2:
            this.formulario.addControl('unica' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            break;

          //Abierta Multiple
          case 3:
            item.cuestionarioRespuesta.forEach(val => {
              this.formulario.addControl('multiple' + val.cuestionarioRespuestaId, new FormControl({ value: '', disabled: true }));
            })

            break;

          //Escala
          case 4:
            this.formulario.addControl('escala' + item.cuestionarioPreguntaId, new FormControl({ value: '', disabled: true }));
            break;
        }
      });
    });
    this.AsignarRespuestasEncuesta();
  }

  public AsignarRespuestasEncuesta() {
    this.EnsenanzaAprendizajeAlumnoService.GetRespuestasEncuesta(this.data.recursoId, this.alumnoId).subscribe(
      (respuestas: ApiResponse<CuestionarioRespuestaDto[]>) => {
        respuestas.data.forEach(item => {
          switch (item.tipoPreguntaCuestionarioId) {
            //Pregunta Abierta
            case 1:
              this.secciones.forEach(element => {
                element.cuestionarioPregunta.forEach(itemSeccion => {
                  if (itemSeccion.cuestionarioPreguntaId == item.cuestionarioPreguntaId) {
                    itemSeccion.respPreguntaAbierta = this.stringToHtml(item.titulo);
                  }
                })
              });
              break;
            //Seleccion Unica
            case 2:
              this.formulario.controls['unica' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              break;
            //Abierta Multiple
            case 3:
              this.formulario.controls['multiple' + item.cuestionarioRespuestaId].setValue(true);
              break;
            //Escala
            case 4:
              this.formulario.controls['escala' + item.cuestionarioPreguntaId].setValue(item.cuestionarioRespuestaId);
              break;
          }
      });
    })
  }

  public stringToHtml(titulo: string) {
    let xmlString = titulo;
    let doc: Document = new DOMParser().parseFromString(xmlString, "text/html");
    return doc.body.innerHTML;
  }

  public cerrarModal() {
    this.dialogRef.close();
  }
}
