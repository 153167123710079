import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DatosEstudiosAdmisionExpressComponent } from './datos-estudios-admision-express/datos-estudios-admision-express.component';
import { DatosPersonalesAdmisionExpressComponent } from './datos-personales-admision-express/datos-personales-admision-express.component';
import { DatosProgramasAdmisionExpressComponent } from './datos-programas-admision-express/datos-programas-admision-express.component';
import { AdmisionesService } from '../_services/admisiones.service';
import { baseUrl, environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DatosDocumentosAdmisionExpressComponent } from './datos-documentos-admision-express/datos-documentos-admision-express.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'app-admisiones-express',
  templateUrl: './admisiones-express.component.html',
  styleUrls: ['./admisiones-express.component.scss']
})
export class AdmisionesExpressComponent implements OnInit {
  //Componentes hijo contenido
  @ViewChild(DatosPersonalesAdmisionExpressComponent) datosPersonalesAdmision: DatosPersonalesAdmisionExpressComponent;
  @ViewChild(DatosProgramasAdmisionExpressComponent) datosProgramasAdmision: DatosProgramasAdmisionExpressComponent;
  @ViewChild(DatosEstudiosAdmisionExpressComponent) datosEstudiosAdmision: DatosEstudiosAdmisionExpressComponent;
  @ViewChild(DatosDocumentosAdmisionExpressComponent) datosDocumentosAdmision: DatosDocumentosAdmisionExpressComponent;

  //Variable tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;

  @ViewChild('modalFinalizacion') modalFinalizacion: TemplateRef<any>;
  public modalFinalizacionRef: any;
  public finalizoSolicitud: boolean = false;
  public correoUsuario = "";
  public contrasenaUsuario = "Password";


  public progress: number = 0;
  public pasoActual: number = 0;
  public showDatosPersonalesAdmision: boolean = true;
  public showDatosFamiliaresAdmision: boolean = true;
  public showDatosProgramaAdmision: boolean = true;
  public showDatosEstudiosAdmision: boolean = true;
  public showDatosDocumentosAdmision: boolean = true;
  constructor(private admisionService: AdmisionesService, private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
    ) { }

  ngOnInit(): void {
    this.showDatosPersonalesAdmision = false;
  }

  public siguientePaso() {
    switch (this.pasoActual) {
      case 0: {
        this.desactivarComponentesContenido()
        this.showDatosProgramaAdmision = false;
        this.pasoActual++;
        this.progress = 25;
        break;
      }
      case 1: {
        this.desactivarComponentesContenido()
        this.showDatosEstudiosAdmision = false;
        this.pasoActual++;
        this.progress = 50;
        break;
      }
      case 2: {
        this.desactivarComponentesContenido()
        this.showDatosDocumentosAdmision = false;
        this.pasoActual++;
        this.progress = 75;
        break;
      }
    }

  }
  public volverPaso() {
    switch (this.pasoActual) {
      case 1: {
        this.desactivarComponentesContenido()
        this.showDatosPersonalesAdmision = false;
        this.pasoActual--;
        this.progress = 0;
        break;
      }
      case 2: {
        this.desactivarComponentesContenido()
        this.showDatosProgramaAdmision = false;
        this.pasoActual--;
        this.progress = 25;
        break;
      }
      case 3: {
        this.desactivarComponentesContenido()
        this.showDatosEstudiosAdmision = false;
        this.pasoActual--;
        this.progress = 50;
        break;
      }
      case 4: {

        break;
      }
    }

  }



  abrirModalFinalizacion() {
    this.modalFinalizacionRef = this.dialog.open(this.modalFinalizacion, { disableClose: true });
  }



  GuardarInformacion() {
    let datosPersonalesForm = Object.assign(this.datosPersonalesAdmision.datosPersonalesForm.value);

    let datosPersonales: any = {
      nombre: datosPersonalesForm.nombre,
      segundoNombre: datosPersonalesForm.segundoNombre,
      apellidoPaterno: datosPersonalesForm.apellidoPaterno,
      apellidoMaterno: datosPersonalesForm.apellidoMaterno,
      correoElectronico: datosPersonalesForm.correo,
      fechaNacimiento: datosPersonalesForm.fechaNacimiento,
      telefono: datosPersonalesForm.numero,
      password: datosPersonalesForm.password
    }

    let datosProgramaForm = Object.assign(this.datosProgramasAdmision.datosProgramaForm.value);

    let datosPrograma: any = {
      campusId: datosProgramaForm.campus,
      programaId: datosProgramaForm.programaAcademico,
      priodoIngreso: datosProgramaForm.periodo
    }

    let datosEstudiosForm = Object.assign(this.datosEstudiosAdmision.datosEstudiosForm.value);

    let datosEstudios: any = {
      nivelId: datosEstudiosForm.nivel,
      escuela: datosEstudiosForm.academico,
      promedio: datosEstudiosForm.promedio
    }

    let documentos = this.datosDocumentosAdmision.documentosProceso;
    let admisionExpress = {
      InstitucionId: this.tenantId,
      DatosPersonales: datosPersonales,
      ProgramaInteres: datosPrograma,
      EstudiosPrevios: datosEstudios,
      Documentos: documentos
    }
    // if (true) {
    //   this.progress = 100;
    //   this.desactivarComponentesContenido()
    //   this.finalizoSolicitud = true;
    //   this.cerrarModalFinalizacion()
    // } else {
    //   this.cerrarModalFinalizacion()
    // }

    this.admisionService.EnviarResgistroAdmisionExpress(this.tenantId, admisionExpress).subscribe(response => {
      if (response.success) {
        this.progress = 100;
        this.desactivarComponentesContenido()
        this.finalizoSolicitud = true;
        this.correoUsuario = response.message;
        this.contrasenaUsuario = datosPersonalesForm.password;
        this.cerrarModalFinalizacion()
      } else {
        this.snackBar.open(response.message, "OK", { duration: 5000 });
        this.cerrarModalFinalizacion()
      }
    })

  }

  desactivarComponentesContenido() {
    this.showDatosPersonalesAdmision = true;
    this.showDatosProgramaAdmision = true;
    this.showDatosEstudiosAdmision = true;
    this.showDatosDocumentosAdmision = true;
  }

  testFormPersonal() {
    console.log(this.datosEstudiosAdmision.datosEstudiosForm);
  }


  /**
    * Metodo que cierra el modal de finalizacion
    */
  public cerrarModalFinalizacion() {
    this.modalFinalizacionRef.close();
  }

  public login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

}
