import { ColegiaturaCostoDto } from './../../_models/ColegiaturaCosto.model';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { SnackService } from './../../services/snack-service.service';
import { AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AsignacionMasivaBloqueDto } from '@app/_models/AsignacionMasivaBloqueDto';
import { Block } from '@app/_models/block';
import { BlockLabelDto } from '@app/_models/BlockLabelDto';
import { UsuarioNuevoIngresoDto } from '@app/_models/usuarionuevoingresodto';
import { I18NextPipe } from 'angular-i18next';
import { Subscription } from 'rxjs/internal/Subscription';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { BlockService } from '@app/_services/block.service';
import { ApiResponse } from '../../models/api/ApiRespose.model';
import { BlockStudent } from '@app/_models/blockStudent';
import { TraductorService } from '@app/_services/traductor.service';
import { Periodo } from '@app/_models';
import { PeriodoService } from '@app/_services/periodo.service';
import { FormControl } from '@angular/forms';
import { ColegiaturaCosto } from '../../_models/colegiatura-costo';

@Component({
  selector: 'app-nuevo-ingreso-asignar-bloque-modal',
  templateUrl: './nuevo-ingreso-asignar-bloque-modal.component.html',
  styleUrls: ['./nuevo-ingreso-asignar-bloque-modal.component.scss']
})
export class NuevoIngresoAsignarBloqueModalComponent implements OnInit, AfterViewInit {
  @ViewChild('dialogoCerrarModalCargo') dialogoCerrarModalCargo: TemplateRef<'dialogoCerrarModalCargo'>;

  //#region  Propiedades
  public PeriodoClave: string;
  public PeriodoId: number;
  public PeriodoNombre: string;
  public CampusId: number;
  public CampusNombre: string;
  public BloqueNombre: string;
  public BlockLabelId: number;
  public BlockId: number;
  public ProgramaId: number;
  public Capacidad: number;
  public Disponibles: number;
  public NumeroGrupos: number;
  public Existe: boolean;
  public permiteParcialidades: boolean = false;
  public listBlockStudent: BlockStudent[];
  public AsignacionMasivaBloqueDto: AsignacionMasivaBloqueDto;
  //#endregion

  public dialogoRefCerrarModalCargo: MatDialogRef<any>;

  //#region Extras
  private colegiaturaCuotaLista: ColegiaturaCostoDto[] = [];
  private colegiaturaCuota: ColegiaturaCostoDto;
  //#endregion

  //#region  Mat Table
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'id',
    'nombre',
    'programa',
    'ciclo'
  ];
  //#endregion
  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion


  constructor(@Inject(MAT_DIALOG_DATA) public data: { alumnos: any[], blockLabel: BlockLabelDto, block: Block, existe: boolean, nuevoIngreso: boolean },
    private snackServiceService: SnackService,
    private i18nextPipe: I18NextPipe,
    public dialogRef: MatDialogRef<NuevoIngresoAsignarBloqueModalComponent>,
    private _BlockService: BlockService,
    private _NuevoIngresoService: NuevoIngresoService,
    private traductorService: TraductorService,
    private dialog: MatDialog,
    private periodoService: PeriodoService
  ) { }

  ngOnInit(): void {    
    this.dataSource = new MatTableDataSource(this.data.alumnos);
    this.obtenerPeriodo();
  }


  ngAfterViewInit(): void {
    if (this.data.nuevoIngreso) {
       if (this.data.existe) {
      this.PeriodoClave = this.data.block.periodoClave;
      this.PeriodoNombre = this.data.block.periodoNombre;
      this.PeriodoId = this.data.block.periodoId;

      this.CampusId = this.data.block.campusId;
      this.CampusNombre = this.data.block.campusNombre;
      this.BloqueNombre = this.data.block.bloqueNombre;

      this.BlockLabelId = this.data.block.blockLabelId;

      this.Capacidad = this.data.block.capacidad;
      this.Disponibles = this.data.block.disponibles;

      this.NumeroGrupos = this.data.block.numeroGrupos;
      this.BlockId = this.data.block.blockId;

      this.Existe = this.data.existe;
      } else {
      this.PeriodoClave = this.data.alumnos[0].periodoClave ?? this.data.alumnos[0].priodoIngresoNavigation.clave;
      this.PeriodoNombre = this.data.alumnos[0].periodoNombre ?? this.data.alumnos[0].priodoIngresoNavigation.nombre;
      this.PeriodoId = this.data.alumnos[0].periodoId ?? this.data.alumnos[0].priodoIngresoNavigation.periodoId;

      this.CampusNombre = this.data.alumnos[0].campusNombre ?? this.data.alumnos[0].campus.nombre;
      this.CampusId = this.data.alumnos[0].campusId ?? this.data.alumnos[0].campus.campusId;

      this.BloqueNombre = this.data.blockLabel.name;
      this.BlockLabelId = this.data.blockLabel.blockLabelId;

      this.Capacidad = this.data.blockLabel.capacity;
      this.Disponibles = this.data.blockLabel.capacity;

      this.NumeroGrupos =  0;
      this.Existe = this.data.existe;
      }
    } else {

    this.PeriodoClave = this.data.alumnos[0].periodoClave ?? this.data.alumnos[0].priodoIngresoNavigation.clave;
    this.PeriodoNombre = this.data.alumnos[0].periodoNombre ?? this.data.alumnos[0].priodoIngresoNavigation.nombre;
    this.PeriodoId = this.data.alumnos[0].periodoId ?? this.data.alumnos[0].priodoIngresoNavigation.periodoId;

    this.CampusNombre = this.data.alumnos[0].campusNombre ?? this.data.alumnos[0].campus.nombre;
    this.CampusId = this.data.alumnos[0].campusId ?? this.data.alumnos[0].campus.campusId;

    this.BloqueNombre = this.data.blockLabel.name;
    this.BlockLabelId = this.data.blockLabel.blockLabelId;

    this.Capacidad = this.data.blockLabel.capacity;
    this.Disponibles = this.data.blockLabel.capacity;

    this.NumeroGrupos =  0;
    this.Existe = this.data.existe;

    }

    if (this.Disponibles === 0) {
      this.snackServiceService.mostrarSnackBack(this.translate("_grupos.sin-cupo"));
    }
  }

  //#region Translate Method
  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }
  //#endregion


  //#region Proceso

  public guardar(): void {

    this.AsignacionMasivaBloqueDto = new AsignacionMasivaBloqueDto();

    this.AsignacionMasivaBloqueDto.existe = this.Existe;
    this.AsignacionMasivaBloqueDto.blockId = this.data.nuevoIngreso ? this.data.block?.blockId : this.Block.blockId;
    this.AsignacionMasivaBloqueDto.blockLabelId = this.BlockLabelId;
    this.AsignacionMasivaBloqueDto.periodoId = this.PeriodoId;
    this.AsignacionMasivaBloqueDto.campusId = this.CampusId;

    this.AsignacionMasivaBloqueDto.alumnos = this.data.alumnos.map(alumno => alumno.alumnoId);


    this.Subscription.add(
      this._BlockService.AsignacionMasivaBloqueAlumno(this.AsignacionMasivaBloqueDto).subscribe(
        (respuesta: ApiResponse<BlockStudent[]>) => {
          if (respuesta.success) {
            this.snackServiceService.mostrarSnackBack(this.translate("_nuevosIngresos.exito-asignacion-masiva"));
            this._NuevoIngresoService.invocarMetodoObtenerCandidato();
            this.CerrarModal();
          } else {
            this.snackServiceService.mostrarSnackBack(respuesta.message);
          }
        }
      )
    );
  }

  public Inscribir() {

    this.CerrarModal();


    let newBlockStudent: BlockStudent;
    let blockStudents: BlockStudent[] = [];
    this._NuevoIngresoService.ExisteColegiaturaCostoDisponibleByAlumnos(this.data.alumnos).subscribe(
      (cuotaColegiaturaRegistrada: ApiResponse<ColegiaturaCosto[]>) => {
        if (cuotaColegiaturaRegistrada.success) {
          if (cuotaColegiaturaRegistrada.data[0].parcialidades) {
            this.permiteParcialidades = true;
          }
          else {
            if (cuotaColegiaturaRegistrada.message !== null) {
              let mensaje: string = this.traductorService.translate(cuotaColegiaturaRegistrada.message);
              this.snackServiceService.mostrarSnackBack(mensaje);
            }
          }

          //#region Obtener el costoColegiatura de cada Alumno
          this.data.alumnos.forEach(Alumno => {

            cuotaColegiaturaRegistrada.data.every(element => {
              if (
                Alumno.campusId === element.campusId &&
                Alumno.nivelId === element.nivelId &&
                Alumno.periodoId === element.periodoId &&
                Alumno.programaId === element.programaId
                ) {
                this.colegiaturaCuota = new ColegiaturaCostoDto();

                this.colegiaturaCuota.alumnoId = Alumno.alumnoId;
                this.colegiaturaCuota.nivelId = element.nivelId;
                this.colegiaturaCuota.campusId = this.CampusId
                this.colegiaturaCuota.periodoId = this.PeriodoId;
                this.colegiaturaCuota.costo = element.costo;
                this.colegiaturaCuota.claveTipoCobro = element.claveTipoCobro;
                this.colegiaturaCuota.impuesto = element.impuesto;
                this.colegiaturaCuota.intereses = element.intereses;
                this.colegiaturaCuota.fechaInicio = element.fechaInicio;
                this.colegiaturaCuota.fechaFin = element.fechaFin;
                this.colegiaturaCuota.diaTransaccion = element.diaTransaccion;
                this.colegiaturaCuota.diaVencimiento = element.diaVencimiento;
                this.colegiaturaCuota.fechaVencimiento = element.fechaVencimiento;
                this.colegiaturaCuota.esNuevoIngreso = true;

                this.colegiaturaCuotaLista.push(this.colegiaturaCuota);
                return false;
              }
            });

             newBlockStudent = new BlockStudent();
             newBlockStudent.blockId = this.BlockId;
             newBlockStudent.studentId = Alumno.alumnoId;
             blockStudents.push(newBlockStudent);
          });
          //#endregion


          //#region Asignación Masiva
          this.AsignacionMasivaBloqueDto = new AsignacionMasivaBloqueDto();

          this.AsignacionMasivaBloqueDto.existe = this.Existe;
          this.AsignacionMasivaBloqueDto.blockId = this.BlockId;
          this.AsignacionMasivaBloqueDto.blockLabelId = this.BlockLabelId;
          this.AsignacionMasivaBloqueDto.periodoId = this.PeriodoId;
          this.AsignacionMasivaBloqueDto.campusId = this.CampusId;

          this.AsignacionMasivaBloqueDto.alumnos = blockStudents.map(alumno => alumno.studentId);


          this.Subscription.add(
            this._BlockService.AsignacionMasivaBloqueAlumno(this.AsignacionMasivaBloqueDto).subscribe(
              (respuesta: ApiResponse<BlockStudent[]>) => {
                if (respuesta.success) {

                  blockStudents.forEach(element => {
                    element.blockId = respuesta.data[0].blockId;
                    element.institucionId = respuesta.data[0].institucionId;
                  });

                  this._BlockService.InscribirAGruposBloqueAlumnos(blockStudents).subscribe(response => {
                    if (response.success) {
                      this.abrirModalTipoCargo();
                    } else {
                      let mensaje: string = this.traductorService.translate(response.message);
                      this.snackServiceService.mostrarSnackBack(mensaje);
                    }
                  })

                } else {
                  this.snackServiceService.mostrarSnackBack(respuesta.message);
                }
              }
            )
          );
          //#endregion
        }
        else {
          this.snackServiceService.mostrarSnackBack("No es posible realizar la selección, debido a que no hay una cuota de colegiatura registrada.");
        }
      });



  }

  public cargoPorExibicion() {
    this._BlockService.CargoExcibicionBloque(this.colegiaturaCuotaLista).subscribe((cargoExcibicion: SimpleResponse) => {
      if (cargoExcibicion.success) {
        let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
        this.snackServiceService.mostrarSnackBack(mensaje);
        this._NuevoIngresoService.invocarMetodoObtenerCandidato();
      }
      else {
        let mensaje: string = this.traductorService.translate(cargoExcibicion.message);
        this.snackServiceService.mostrarSnackBack(mensaje);
      }
    });

    this.cerarModalCerrarModalCargo();

  }

  public cargoPorParcialidades() {
    this._BlockService.CargoParcialidadesBloque(this.colegiaturaCuotaLista).subscribe((cargoParcialidades: SimpleResponse) => {
      if (cargoParcialidades.success) {
        let mensaje: string = this.traductorService.translate(cargoParcialidades.message);
        this._NuevoIngresoService.invocarMetodoObtenerCandidato();
        this._BlockService.invocarMetodoObtenerBloqueAlumno();
        this.snackServiceService.mostrarSnackBack(mensaje);
      }
      else {
        let mensaje: string = this.traductorService.translate(cargoParcialidades.message);
        this.snackServiceService.mostrarSnackBack(mensaje);
      }
    });

    this.cerarModalCerrarModalCargo();
  }
  //#endregion



  public abrirModalTipoCargo() {
    this.dialogoRefCerrarModalCargo = this.dialog.open(this.dialogoCerrarModalCargo, { disableClose: true });
  }

  public cerarModalCerrarModalCargo() {
    this.dialogoRefCerrarModalCargo.close();
  }



  //#region Modal Close
  public CerrarModal() {
    this.dialogRef.close();
  }
  //#endregion

  //#region Periodo

  public periodos: Periodo[];
  private periodosactivos: any[] = [];
  public periodoF = new FormControl();

/* Obtiene los periodos Activos y Futuros */
  public obtenerPeriodo(): void {
    let today = new Date();
   this.Subscription.add(
    this.periodoService.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        //this.periodos = periodos;
        this.periodos = new Array<Periodo>();
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (fin >= today) {
            this.periodos.push(x);
          }
        }
        this.periodoF.setValue(0);

      })
   );
  }

    tosslePerOnePeriodo(periodoId: number): void {
      this.BuscarBloque(periodoId);
    }

  //#endregion

  get GetPeriodoId(): number{
    return this.periodoF.value;
  }

  //#region  Buscar Bloque Existente

  public Block: Block;

  public BuscarBloque(PeriodoId: number): void{
    this.Subscription.add(
      this._BlockService.GetBlockByPeriodoCampus(this.BlockLabelId, PeriodoId, this.CampusId).subscribe((respuesta: ApiResponse<Block>) => {
        this.Block = respuesta.data;
        this.ExisteBloque(respuesta.success);
      })
    );
  }

  public ExisteBloque(existe: boolean): void {

    this.ProgramaId = this.data.alumnos[0].programaId;

      if (existe) {
      this.PeriodoClave = this.Block.periodoClave;
      this.PeriodoNombre = this.Block.periodoNombre;
      this.PeriodoId = this.Block.periodoId;

      this.CampusId = this.Block.campusId;
      this.CampusNombre = this.Block.campusNombre;
      this.BloqueNombre = this.Block.bloqueNombre;

      this.BlockLabelId = this.Block.blockLabelId;
      this.BlockId = this.Block.blockId;

      this.Capacidad = this.Block.capacidad;
      this.Disponibles = this.Block.disponibles;
      this.NumeroGrupos = this.Block.numeroGrupos;

      this.Existe = existe;
    } else {
    this.PeriodoClave = this.data.alumnos[0].periodoClave ?? this.data.alumnos[0].priodoIngresoNavigation.clave;
    this.PeriodoNombre = this.data.alumnos[0].periodoNombre ?? this.data.alumnos[0].priodoIngresoNavigation.nombre;
    this.PeriodoId = this.data.alumnos[0].periodoId ?? this.data.alumnos[0].priodoIngresoNavigation.periodoId;

    this.CampusNombre = this.data.alumnos[0].campusNombre ?? this.data.alumnos[0].campus.nombre;
    this.CampusId = this.data.alumnos[0].campusId ?? this.data.alumnos[0].campus.campusId;

    this.BloqueNombre = this.data.blockLabel.name;
    this.BlockLabelId = this.data.blockLabel.blockLabelId;
    this.BlockId = 0;
    this.Capacidad = this.data.blockLabel.capacity;
    this.Disponibles = this.data.blockLabel.capacity;

    this.NumeroGrupos =  0;
    this.Existe = existe;
    }
    if (this.Disponibles === 0) {
      this.snackServiceService.mostrarSnackBack(this.translate("_grupos.sin-cupo"));
    }
  }
  //#endregion

}
