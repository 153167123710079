import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiResponse } from '../../../../../models/api/ApiRespose.model';
import { ListaMateriasOferta } from '../../../../../_models/lista-materias-oferta';
import { EnsenanzaAprendizajeAlumnoService } from '../../../../../_services/ensenanzaAprendizajeAlumno.service';
import i18next from 'i18next';
import { MatTableDataSource } from '@angular/material/table';
import { AlumnoGrupoParcial } from '../../../../../_models/ensenanzaAprendizajeAlumno';
import { MatPaginator } from '@angular/material/paginator';
import { EmitterSaveActionService } from '../../../../infrastructure/services/common/emitter-save-action.service';

@Component({
  selector: 'app-grades',
  templateUrl: './grades.component.html',
  styleUrls: ['./grades.component.scss']
})
export class GradesComponent implements OnInit, OnDestroy {

  //#region Input, Ouput, Emitter
  @Input() grupoId: number;
  @Input() usuarioId: number;
  @Input() alumnoId: number;
  @Input() tabId: number;
  @Input() tabIndex: number;
  @Output() formValid = new EventEmitter<boolean>();
  //#endregion

  //#region Observable
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Properties
  public Grupo: ListaMateriasOferta;
  private _curentLanguaje: string;  
  //#endregion  

  //#region Mat Table
  displayedColumns: string[] = ['parcial', 'calificacion', 'faltas'];
  public dataSource: MatTableDataSource<AlumnoGrupoParcial>;
  //#endregion

  //#region ViewChild
  @ViewChild(MatPaginator) paginator: MatPaginator;
  //#endregion

 //#region LifeCycle
 constructor(  
  private _ensenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
  ) {} 

  ngOnInit(): void {    
    this._getGrupoById();
    this._getCalificaciones();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabIndex']) {
      changes['tabIndex'].currentValue === this.tabId ? this.Emit(false) : null;
    }
  }

  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  //#region Methods
  public Emit($event?: boolean):void{
    this.formValid.emit($event);
  }

  private _getGrupoById():void{
    //this._curentLanguaje = i18next.language;
    this.Subscription.add(
      this._ensenanzaAprendizajeAlumnoService.GetMateriaAlumnoByGrupoId(this.grupoId, 'es', this.usuarioId)
        .subscribe((response: ApiResponse<ListaMateriasOferta>) => {          
          this.Grupo = response.data;          
        })
    );
  }
  private _getCalificaciones() {    
   this.Subscription.add(
    this._ensenanzaAprendizajeAlumnoService.obtenerCalificaciones(this.alumnoId, this.grupoId).subscribe(
      (cal: ApiResponse<AlumnoGrupoParcial[]>) => {
        this.dataSource = new MatTableDataSource(cal.data);
        this.dataSource.paginator = this.paginator;
      })
   );
  }
  //#endregion
}
