import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnInit, Input } from '@angular/core';
import { EnsenanzaAprendizajeService } from '../../../_services/ensenanza-aprendizaje.service';
import { EnsenanzaAprendizajeAlumnoService } from '../../../_services/ensenanzaAprendizajeAlumno.service';
import { UtileriasService } from '../../../_services/utilerias.service';
import { ParcialesModalComponent } from '../../../contenido-curso/contenido-curso/modals/parciales-modal/parciales-modal.component';
import { ModalRecursosComponent } from '../../modals-recursos/modal-recursos.component';
import { ModalTemaComponent } from 'src/app/ensenanza-aprendizaje/modals-recursos/modal-tema/modal-tema.component';
import { ContenidoParcial, Modulo, ModuloInterface } from 'src/app/_models/ensenanza-aprendizaje-profesor';
import { ModalDetalleEliminarComponent } from 'src/app/ensenanza-aprendizaje/modals-recursos/modal-detalle-eliminar/modal-detalle-eliminar.component';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { RecursoAdjunto } from 'src/app/_models/recurso-adjunto';
import { ListaMateriasOferta } from 'src/app/_models/lista-materias-oferta';
import { NumberInput } from '@angular/cdk/coercion';
import { Grupo } from 'src/app/_models/grupo';
import { I18NextPipe } from 'angular-i18next';
@Component({
  selector: 'app-contenido-mi-curso',
  templateUrl: './contenido-mi-curso.component.html',
  styleUrls: ['./contenido-mi-curso.component.scss']
})
export class ContenidoMiCursoComponent implements OnInit {

  constructor(private ensenanzaApredServ: EnsenanzaAprendizajeService,
    public util: UtileriasService,
    public EnsenanzaAprendizajeAlumnoService: EnsenanzaAprendizajeAlumnoService,
    private snackService: SnackService,
    private traductorService: TraductorService,
    private i18nextPipe: I18NextPipe,) { }

  public activeIndex: number;
  esArchivo: boolean;
  link: string;
  @Input() grupoID: number;
  @Input() grupoObj: ListaMateriasOferta;
  @Input() lmsProviderId: number;
  contenido: ContenidoParcial[] = [];
  contenidoGrupoParcial: any[] = [];
  modulos: any[] = [];
  modulosTemporal: any[] = [];
  expandirModulo: boolean = false;
  flagButtonDescarga: boolean = false;
  recursoId: number = 0;
  modalRecursos = false;
  btnTema = false;
  indiceModulo: number;
  indiceRecurso: number;
  indiceParcial: number;
  //interface
  moduloInterface: ModuloInterface;
  esNuevoRegistro: boolean;
  btnGuardar: boolean;
  esRecurso: boolean;
  tituloModulo: string;
  idParcialModulo: number;
  public btnElemento: string = '';
  datosGrupo: any;
  puntosBaseParcial: number;
  ponderacionParcial: number;
  nombrePuntosBaseParcial: string;
  btnPuntaje: boolean = false;
  existeParcialFinal: boolean = false;
  ngOnInit() {
    this.cargarContenido();
    this.cargarDatosGrupo();
    if (this.ensenanzaApredServ.invocarObtenerContenidoSubscription == undefined) {
      this.ensenanzaApredServ.invocarObtenerContenidoSubscription = this.ensenanzaApredServ.invocarObtenerContenido.subscribe(() => {
        this.contenido.length = 0;
        this.cargarContenido();
      });
      this.ensenanzaApredServ.invocarObtenerContenidoSubscription = null;
    }
  }

  private translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  public cargarDatosGrupo() {
    this.ensenanzaApredServ.getPonderacionGrupo(this.grupoID).subscribe((grupo: ApiResponse<Grupo>) => {
      this.datosGrupo = grupo.data;
      this.puntosBaseParcial = this.datosGrupo.materiaOferta.materia.ciclo.programa.escalaCalificacionNavigation.calificacionNoNumerica[0].valorNumerico;
      this.nombrePuntosBaseParcial = this.datosGrupo.materiaOferta.materia.ciclo.programa.escalaCalificacionNavigation.calificacionNoNumerica[0].nombre;
    })
  }

  public cargarContenido() {
    this.ensenanzaApredServ.obtenerContenido(this.grupoID).subscribe((res: ApiResponse<any>) => {
      if (res.data) {
        if (res.data.modulo.length > 0) {
          for (let i: number = 0; i < res.data.modulo.length; i++) {
            var recurso = res.data.modulo[i].recurso
            for (let x: number = 0; x < recurso.length; x++) {
              var RecursoAdjunto = recurso[x].recursoAdjunto;
              for (let y: number = 0; y < RecursoAdjunto.length; y++) {
                let splittedDocument: string[] = RecursoAdjunto[y].nombreOriginal.split(".");
                let ultimoValor = splittedDocument.length - 1;
                let tipodocumento: string = `.${splittedDocument[ultimoValor]}`;
                RecursoAdjunto[y].tipoDocumento = tipodocumento;
              }
            }
          }
        }
        this.ensenanzaApredServ.definirClaveMateria(res.data.clave);
        this.contenidoGrupoParcial = res.data.grupoParcial;
        this.cargarParciales(res.data.clave, this.grupoID);
        this.modulos = [];
        this.modulos = res.data.modulo;
        this.modulosTemporal = [...res.data.modulo];
      }
    })
  }

  public showElement(parcial: any) {
    return !this.modulos.some(e => e.parcial === parcial)
  }

  public mostrarDescargar(documento, mostrar, index) {
    this.activeIndex = index;
    this.esArchivo = documento.esArchivo;
    if (!this.esArchivo) {
      this.link = documento.nombreFisico;
    }
    if (this.flagButtonDescarga) {
      this.flagButtonDescarga = false;
      this.recursoId = 0;
    } else {
      this.recursoId = documento.recursoAdjuntoId;
      this.flagButtonDescarga = true;
    }
    this.flagButtonDescarga = false;
  }
  expandir: boolean = true;
  indexRecurso = -1;
  recursoSelect;

  public expandirRecurso(elemento: string, indiceModulo: number, index: number, expande: boolean, recurso: Modulo) {
    this.btnElemento = elemento;
    this.indiceModulo = indiceModulo;
    this.indiceRecurso = index
    this.indexRecurso = index;
    this.expandir = expande;
    if (this.expandir) {
      this.recursoSelect = recurso.recurso[index];
      this.recursoSelect.parcialID = recurso.parcial;
    }
    this.flagButtonDescarga = false;
  }
  public expandirTema(elemento, indiceModulo, expande) {
    this.btnElemento = elemento;
    this.indiceModulo = indiceModulo;
    this.expandir = expande;
  }
  ubicarBoton(elemento: string) {
    this.btnElemento = elemento;
  }
  public descargarRecurso(recursitoId) {
    this.ensenanzaApredServ.obtenerRecursoAdjunto(recursitoId).subscribe(
      (respuestaRecurso: ApiResponse<RecursoAdjunto>) => {
        var documento = this.base64ToArrayBuffer(respuestaRecurso.data.nombreFisico);
        var mime = respuestaRecurso.data.nombreOriginal.indexOf(".pdf") >= 0 ? "application/pdf" : "application/octect-stream";
        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        var blob = new Blob([documento], { type: mime });
        a.href = window.URL.createObjectURL(blob);
        a.download = respuestaRecurso.data.nombreOriginal;
        a.click();
      })
  }
  base64ToArrayBuffer(base64) {
    let primerValor = base64.split(':', 1)
    if (primerValor[0] === "data") {
      base64 = base64.split("base64,")[1];
    }
    let binary_string = base64.replace(/\\n/g, "");
    binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  public cargarParciales(clave: string, grupoId: number) {
    this.ensenanzaApredServ.obtenerParcialesProfesor(clave, grupoId).subscribe(resp => {
      if (resp.exito) {

        if (resp.dataParcialFinal) {
          this.existeParcialFinal = true;
        }
        resp.dataParciales.forEach(parcial => {

          let parcialText = '_tableroProfesor.parcial';
          if (!!this.existeParcialFinal && parcial == resp.dataParciales.length) {
            parcialText = '_tableroProfesor.parcial-final';
          };

          if (this.contenidoGrupoParcial.length > 0) {
            let nuevoParcial = this.contenidoGrupoParcial.find(object => {
              return object.parcial == parcial
            });
            if (nuevoParcial) {
              let parcialobj = {
                parcial: nuevoParcial.parcial,
                ponderacion: nuevoParcial.ponderacion,
                nombre: nuevoParcial.nombre,
              }
              if (!parcialobj?.nombre) {
                parcialobj.nombre = this.translate(parcialText)// + ' ' + parcial;
              }
              this.contenido.push(parcialobj);
            } else {
              let _parcialObj = {
                parcial: parcial,
                ponderacion: 0,
                nombre: this.translate(parcialText)// + ' ' + parcial
              }
              this.contenido.push(_parcialObj);
            }
          }
          else {
            let _parcialObj = {
              parcial: parcial,
              ponderacion: 0,
              nombre: this.translate(parcialText) + ' ' + parcial
            }
            this.contenido.push(_parcialObj);
          }
        });
      }
    })
  }
  noExpandir() {
    this.expandirModulo = false;
  }
  public verDetalle() {
    this.recursoSelect.entregar = false;
  }
  abrirModalTema(grupoId: number, listaParciales: ContenidoParcial[]) {
    this.esNuevoRegistro = true;
    //filtrar lo de parcial
    let datos = {
      grupoId: grupoId,
      grupoObj: this.grupoObj,
      esNuevoRegistro: this.esNuevoRegistro,
      listaParciales: listaParciales
    }
    this.util.abrirDialogoLateral(ModalTemaComponent,
      datos);
  }
  abrirModalParcial() {
    this.util.abrirDialogoLateral(ParcialesModalComponent);
  }
  abrirModalRecurso(parcial?: any) {
    this.esNuevoRegistro = true;
    //filtrar lo de parcial
    this.ponderacionParcial = this.modulos[this.indiceModulo].parcial;
    let ponderParcial = this.contenido.filter(x => x.parcial === this.ponderacionParcial);
    let datos = {
      grupoObj: this.grupoObj,
      recurso: this.modulos[this.indiceModulo].recurso[this.indiceRecurso],
      esNuevoRegistro: this.esNuevoRegistro,
      ponderacion: ponderParcial[0].ponderacion,
      parcial: null
    }

    if (!parcial) {
      datos.parcial = this.modulos[this.indiceModulo].parcial
    } else {
      datos.parcial = parcial.parcial
    }
    this.util.abrirDialogoLateral(ModalRecursosComponent, datos);
  }

  editarModalRecurso(grupoId: number, indiceModulo?: any, indiceRecurso?: any) {
    this.esNuevoRegistro = false;
    //filtrar lo de parcial
    this.ponderacionParcial = this.modulosTemporal[indiceModulo].parcial;
    let ponderParcial = this.contenido.filter(x => x.parcial === this.ponderacionParcial);
    let recurso = this.modulosTemporal[indiceModulo].recurso[indiceRecurso];
    this.btnGuardar = true;
    let datos = {
      grupoObj: this.grupoObj,
      grupoId: grupoId, //mandar la clave de la tabla de grupo,
      recurso: recurso,
      esNuevoRegistro: this.esNuevoRegistro,
      ponderacion: ponderParcial[0].ponderacion,
      parcial: this.modulosTemporal[indiceModulo].parcial
    }
    this.util.abrirDialogoLateral(ModalRecursosComponent, datos);
  }
  editarModalTema(grupoId: number, moduloId: number, moduloData: any, indiceModulo?: any) {
    this.esNuevoRegistro = false;
    //filtrar lo de parcial
    this.ponderacionParcial = this.modulos[indiceModulo].parcial;
    let ponderParcial = this.contenido.filter(x => x.parcial === this.ponderacionParcial);
    let datos = {
      modulo: this.modulos[indiceModulo],
      grupoId: grupoId,
      moduloId: moduloId,
      grupoObj: this.grupoObj,
      esNuevoRegistro: this.esNuevoRegistro,
      tituloModulo: moduloData,
      ponderacion: ponderParcial[0].ponderacion,
      parcial: this.modulos[indiceModulo].parcial
    }
    this.util.abrirDialogoLateral(ModalTemaComponent, datos)
  }
  public abrirUrl() {
    let exists = this.link.indexOf('http');
    let cadenaString = this.link;
    if (exists === -1) {
      cadenaString = 'http://' + cadenaString;
      window.open(cadenaString, "_blank");
    } else {
      window.open(cadenaString, "_blank");
    }
  }
  abrirModalEliminarRecurso() {
    this.esRecurso = true;
    let datos = {
      recurso: this.modulos[this.indiceModulo].recurso[this.indiceRecurso],
      esRecurso: this.esRecurso
    }
    this.util.abrirDialogoLateral(ModalDetalleEliminarComponent, datos);
  }
  abrirModalEliminarTema() {
    this.esRecurso = false;
    let datos = {
      modulo: this.modulos[this.indiceModulo],
      esRecurso: this.esRecurso
    }
    this.util.abrirDialogoLateral(ModalDetalleEliminarComponent, datos);
  }

  abrirModalEliminarRecursoTema(indiceModulo?: number, indiceRecurso?: number, esRecurso?: boolean, elemento?: any) {
    this.esRecurso = esRecurso;
    if (this.esRecurso) {
      let datos = {
        recurso: this.modulos[indiceModulo].recurso[indiceRecurso],
        esRecurso: esRecurso
      }
      this.util.abrirDialogoLateral(ModalDetalleEliminarComponent, datos);
    } else {
      let datos = {
        modulo: this.modulos[indiceModulo],
        esRecurso: esRecurso
      }
      this.util.abrirDialogoLateral(ModalDetalleEliminarComponent, datos);
    }
  }

  activarBtnGuardarPonderacion(index: number, event: any) {
    if (this.contenido[index].ponderacion == event.target.value) {
      if (this.contenido[index].ponderacion > 0 && event.target.value > 0) {
        this.btnPuntaje = true;
      } else {
        this.btnPuntaje = false;
        let mensaje1: string = this.traductorService.translate("mensajePonderacion1");
        let mensaje2: string = this.traductorService.translate("mensajePonderacion2");
        this.snackService.mostrarSnackBack(`${mensaje1} ${this.contenido[index].nombre} ${mensaje2}`);
      }
    }
    else {
      this.btnPuntaje = false;
    }
  }
  activarBtnGuardarNombre(index: any, event: any) {
    if (this.contenido[index].nombre == event.target.value) {
      this.btnPuntaje = true;
    }
    else {
      this.btnPuntaje = false;
    }
  }
  guardarPonderacion() {
    let guardadoValido = false;
    this.contenido.forEach(element => {
      this.modulos.forEach(modulo => {
        let sumaPonderacionRecursos = 0;
        if (element.parcial == modulo.parcial) {
          //suma de las ponderaciones
          modulo.recurso.forEach(function (recurso) {
            sumaPonderacionRecursos += recurso.ponderacion;
          });
          if (sumaPonderacionRecursos > element.ponderacion) {
            guardadoValido = true;
          }
        }
      })
    });
    if (!guardadoValido) {
      let sumaPonderacionParcial = 0;
      this.contenido.forEach(function (contenido) {
        sumaPonderacionParcial += contenido.ponderacion;
      });
      if (sumaPonderacionParcial <= this.puntosBaseParcial) {
        this.contenido.forEach(element => {
          element.grupoId = this.grupoID
        });
        this.ensenanzaApredServ.IngresarPonderacionParcial(this.contenido).subscribe((res: SimpleResponse) => {
          let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
          this.snackService.mostrarSnackBack(mensaje);

          this.ensenanzaApredServ.invocarMetodoPorRevisar();



          this.btnPuntaje = false;
        }, error => {
          this.snackService.mostrarSnackBack('Hubo un error al guardar información.');
        })
      } else {
        this.snackService.mostrarSnackBack('No se puede exceder del valor del parcial.');
        this.btnPuntaje = false;
      }
    } else {
      this.snackService.mostrarSnackBack('El nuevo valor del parcial no puede ser inferior de los recursos.');
      this.btnPuntaje = false;
    }
  }
}
