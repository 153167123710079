export class Periodo {
  /// <summary>
  /// Id del periodo
  /// </summary>
  periodoId?: number;

  /// <summary>
  /// clave del periodo
  /// </summary>
  clave?: string;

  /// <summary>
  /// Tipo de periodo 
  /// </summary>
  tipoPeriodoId?: number;

  /// <summary>
  /// Nombre del periodo
  /// </summary>
  nombre?: string;

  /// <summary>
  /// Fecha de inicio del periodo
  /// </summary>
  fechaInicio?: any;

  /// <summary>
  /// Fecha final del periodo
  /// </summary>
  fechaFin?: any;

  /// <summary>
  /// Define si los datos se editan o crean
  /// </summary>
  tipoAccion?: number;
}

export class PeriodoDto {
  periodoId: number;
  clave: string;
  nombre: string;
  fechaInicio: Date;
  fechaFin: Date;
}
