import { TraductorService } from './../../../_services/traductor.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackService } from 'src/app/services/snack-service.service';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { CartasService } from 'src/app/_services/cartas.service';

@Component({
  selector: 'app-cartas-modal-eliminar',
  templateUrl: './cartas-modal-eliminar.component.html',
  styleUrls: ['./cartas-modal-eliminar.component.scss']
})
export class CartasModalEliminarComponent implements OnInit {
  public numLetterSeleccionados = 0;
  public boolIsArray = false;
  public codeLetter = '';

  constructor(public dialogRef: MatDialogRef<CartasModalEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackService: SnackService,
    private solicitudService: SolicitudService,
    private letterService: CartasService,
    private traductorService: TraductorService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if(this.boolIsArray){
      this.numLetterSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(d => (d.letterId.toString()));
      this.codeLetter = arrayClaves.toString();
    } else {
      this.codeLetter = this.data.letterId.toString();
    }
    this.letterService.eliminarLetter(this.codeLetter).subscribe(
      eliminado => {
        if(eliminado) {
          this.letterService.invocarMetodoObtenerCartas();
          let mensaje: string = this.traductorService.translate('eliminarCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
          this.dialogRef.close(this.data);
        } else {
          let mensaje: string = this.traductorService.translate('errorCartas');
          this.snackService.mostrarSnackBack(mensaje);
        }
      }, error => {
        let mensaje: string = this.traductorService.translate('guardadoCorrecto');
        this.snackService.mostrarSnackBack(mensaje);
      }
    );
  }

}
