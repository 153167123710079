export class Letter {
    letterId: number;
    code: string;
    name: string;
    description: string;
    directDownload: boolean;
    content: string;
    creationDate: Date;
    creationUserId: number;
    tipoAccion?: number;
}
