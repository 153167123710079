import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { Documento } from '../_models/documento';
import { environment, baseUrl } from '@environments/environment';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { Proceso } from '../_models/proceso';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(private httpClient: HttpClient) { }

  // Get documento
  public obtenerDocumentos(): Observable<any> {
    return this.httpClient.get<any>(`${environment.enves[baseUrl].apiUrl}/Documento`);
  }

  // Get Proceso
  public obtenerProceso(): Observable<ApiResponse<Proceso[]>> {
    return this.httpClient.get<ApiResponse<Proceso[]>>(`${environment.enves[baseUrl].apiUrl}/Documento/Proceso`);
  }

  //Get Documento Proceso 
  public ObtenerDocumentosProceso(CLAVE_PROCESO: string): Observable<ApiResponse<Documento[]>> {
    return this.httpClient.get<ApiResponse<Documento[]>>(`${environment.enves[baseUrl].apiUrl}/Documento/ObtenerDocumentosProceso/${CLAVE_PROCESO}`);
  }

  //Get Documento Proceso 
  public ObtenerDocumentosProcesoSubProceso(CLAVE_PROCESO: string, alumnoId: number): Observable<ApiResponse<Documento[]>> {
    return this.httpClient.get<ApiResponse<Documento[]>>(`${environment.enves[baseUrl].apiUrl}/Documento/ObtenerDocumentosProcesoSubProceso/${CLAVE_PROCESO}/${alumnoId}`);
  }

  //Get Documento Proceso 
  public ObtenerDocumentosProcesoSubProcesoApoyo(CLAVE_PROCESO: string): Observable<ApiResponse<Documento[]>> {
    return this.httpClient.get<ApiResponse<Documento[]>>(`${environment.enves[baseUrl].apiUrl}/Documento/ObtenerDocumentosProcesoSubProcesoApoyo/${CLAVE_PROCESO}`);
  }

  //Get Documento Proceso 
  public ObtenerDocumentosProcesoSubProcesoProfesor(CLAVE_PROCESO: string): Observable<ApiResponse<Documento[]>> {
    return this.httpClient.get<ApiResponse<Documento[]>>(`${environment.enves[baseUrl].apiUrl}/Documento/ObtenerDocumentosProcesoSubProcesoProfesor/${CLAVE_PROCESO}`);
  }
}
