import { ModalEnviarCorreoComponent } from './../../../../administracion-academica/modal-enviar-correo/modal-enviar-correo.component';
import { GenericTableModule } from './../../common/components/generic-table/generic-table.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MakeUpExamRoutingModule } from './make-up-exam-routing.module';
import { MakeUpExamTrakingTableComponent } from '../make-up-exam/make-up-exam-traking-table/make-up-exam-traking-table.component'
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { I18NextModule } from 'angular-i18next';
import { MaterialModule } from '../../common/modules/material/material.module';
import { FiltersModule } from '../../common/forms/filters/filters.module'
import { LateralModalModule } from '../../common/modals/lateral-modal/lateral-modal.module';
import { NuevoIngresoAsignarBloqueModalComponent } from '../../../../nuevo-ingreso/nuevo-ingreso-asignar-bloque-modal/nuevo-ingreso-asignar-bloque-modal.component';
import { MassAssignmentMakeUpExamComponent } from '../../common/modals/mass-assignment-make-up-exam/mass-assignment-make-up-exam.component';


@NgModule({
  declarations: [
    MakeUpExamTrakingTableComponent, 
    ModalEnviarCorreoComponent, 
    NuevoIngresoAsignarBloqueModalComponent,
    MassAssignmentMakeUpExamComponent],
  imports: [
    CommonModule,
    MakeUpExamRoutingModule,    
    FlexLayoutModule,    
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    I18NextModule.forRoot(),
    FiltersModule,
    GenericTableModule,
    LateralModalModule
  ],
  exports: [MakeUpExamTrakingTableComponent]
})
export class MakeUpExamModule { }
