import { Component, OnInit, ViewChild, Inject, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormDatosContactoComponent } from '../oferta-profesor-modal/form-datos-contacto/form-datos-contacto.component';

import { FormDatosLaboralesComponent } from '../oferta-profesor-modal/form-datos-laborales/form-datos-laborales.component';
import { FormDatosPersonalesComponent } from '../oferta-profesor-modal/form-datos-personales/form-datos-personales.component';
import { FormCargaDocumentosComponent } from '../oferta-profesor-modal/form-carga-documentos/form-carga-documentos.component';
import { Usuario } from '../../../_models/usuario';
import { FormDatosBiograficosComponent } from '../oferta-profesor-modal/form-datos-biograficos/form-datos-biograficos.component';
import { Direccion } from '../../../_models/direccion';

@Component({
  selector: 'app-oferta-profesor-edicion-modal',
  templateUrl: './oferta-profesor-edicion-modal.component.html',
  styleUrls: ['./oferta-profesor-edicion-modal.component.scss']
})
export class OfertaProfesorEdicionModalComponent implements OnInit {
  //Hijos de parte de Profesor
  @ViewChild(FormDatosPersonalesComponent) formDatosPersonales: FormDatosPersonalesComponent;
  @ViewChild(FormDatosContactoComponent) formDatosContacto: FormDatosContactoComponent;
  @ViewChild(FormDatosBiograficosComponent) formDatosBiograficos: FormDatosBiograficosComponent;
  @ViewChild(FormDatosLaboralesComponent) formDatosLaborales: FormDatosLaboralesComponent;
  @ViewChild(FormCargaDocumentosComponent) formCargaDocumentos: FormCargaDocumentosComponent;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public boolCambio: boolean = false;
  public dialogoRefAdver: any;

  public stepSeleccionado: number = 0;
  public datosDireccion: Direccion = {
    correo: '',
    direccionId: 1,
    usuarioId: 1,
    tipoDireccionId: 1,
    entidadFederativaId: 1,
    ciudad: 'string',
    calle: 'string',
    pais: 1,
    numeroExterior: 'string',
    numeroInterior: 'string',
    colonia: 'string',
    codigoPostal: 'string',
    tipoAccion: 1
  }


  constructor(public dialogRef: MatDialogRef<OfertaProfesorEdicionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Usuario,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  get personal() { return this.formDatosPersonales ? this.formDatosPersonales.datosForm : null; }
  get contacto() { return this.formDatosContacto ? this.formDatosContacto.contactoForm : null;}
  get biografia() { return this.formDatosBiograficos ? this.formDatosBiograficos.biografiaForm : null;}
  get laborales() { return this.formDatosLaborales ? this.formDatosLaborales.laboralForm : null; }
  get documentos() { return this.formCargaDocumentos ? this.formCargaDocumentos.documentoForm : null;}

  public enviar() {
    // this.formCuenta.enviarDatosRegistro();
    this.formDatosContacto.editar();
    this.formDatosBiograficos.editar();
    this.formDatosLaborales.editar();
  }
  public cambioStep(event) {

  }

  public cerrarModal() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(this.data);
    }
  }




}
