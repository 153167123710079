import { Language } from './../../clean-architecture/core.domain/models/language/language.model';
import { LanguageService } from './../../clean-architecture/infrastructure/services/language/language.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { TraductorService } from './../../_services/traductor.service';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { AcademicManagementModel } from './../../_models/AcademicManagementModel';
import { ApiResponse } from './../../models/api/ApiRespose.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Component, Inject, ChangeDetectorRef, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DireccionService } from '@app/_services/direccion.service';
import { InstitucionService } from '@app/_services/institucion.service';
import { LoadingScreenService } from '@app/_services/loading-screen.service';
import { Institucion } from '@app/_models/institucion';
import { Pais } from '@app/_models/pais';
import { EntidadFederativa } from '@app/_models/entidad-federativa';
import { I18NextPipe } from 'angular-i18next';
import { InstitucionEmailAccount } from 'src/app/_models/institucionEmailAccount';
import { Subscription } from 'rxjs';
import { AdministracionOpcion } from '@app/_models/AdministracionOpcion';
import { ThemePalette } from '@angular/material/core';
import { Mapping } from '@app/clean-architecture/core.domain/class/mapping.class';
import { EmitterSaveActionService } from '../../clean-architecture/infrastructure/services/common/emitter-save-action.service';

@Component({
    selector: 'app-institucion-edicion',
    templateUrl: './institucion-edicion.component.html',
    styleUrls: ['./institucion-edicion.component.scss']
})
export class InstitucionEdicionComponent implements OnInit {

    //#region Subscription
    private Subscription: Subscription = new Subscription();
    //#endregion

    //#region ViewChield
    @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
    @ViewChild('dialogEnvioPrueba') dialogEnvioPrueba: TemplateRef<any>;
    //#endregion

    //#region Properties
    public boolCambio: string = "";
    public dialogoRefAdver: any;
    public dialogRefEnvioPrueba: any;
    public esValidoPassword: boolean = true;
    public correoFueEnviado: boolean = false;
    public formActualValido: boolean = false;
    public tabCambio: boolean = false;
    public mostrarCamposConfiguracion: boolean = false;
    public tabSeleccionado: number = 0;
    tipoCifrados = [{ "value": "NO" }, { "value": "SSL" }, { "value": "TLS" }]
    public tabSeleccionadoIndex: FormControl = new FormControl();
    public AcademicManagementModel: AcademicManagementModel[] = [];

    public errorActualizaInstitucion: string = "";
    public paises: Pais[] = [];
    public fiscalPaises: Pais[] = [];
    public estados: EntidadFederativa[] = [];
    public fiscalEstados: EntidadFederativa[] = [];
    public passwordTipoTexto: boolean = false;

    public disabled = false;
    public colorBarra: ThemePalette = 'primary';
    public colorBoton: ThemePalette = 'primary';
    public touchUi = false;
    public touchUi2 = false;
    public colorBar: AbstractControl = new FormControl(this.data.colorBar);
    public colorButton: AbstractControl = new FormControl(this.data.colorButton);

    public telefonoWARequerido: boolean;    
    //#endregion

    //#region LifeCycle
    constructor(
        public dialogRef: MatDialogRef<InstitucionEdicionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Institucion,
        private _cd: ChangeDetectorRef,
        private loadingService: LoadingScreenService,
        private direccionService: DireccionService,
        private institucionService: InstitucionService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private i18nextPipe: I18NextPipe,
        private traductorService: TraductorService,
        private snackService: SnackService,
        private _EmitterSaveActionService : EmitterSaveActionService
    ) {
        this.direccionService.obtenerPaises().subscribe(
            paises => {

                if (!!this.data.fiscalPais) {
                    this.onFiscalPaisSelected(this.data.fiscalPais);
                }
                if (!!this.data.pais) {
                    this.onPaisSelected(this.data.pais);
                }
                this.edicionForm.patchValue(this.data);
                this.edicionForm.markAsUntouched();
                this.paises = paises;
                this.fiscalPaises = paises;
            }
        );

        this.boolCambio = JSON.stringify(this.data);

    }

    ngOnInit(): void {
        // this.edicionForm();
        this.onChangeEnvioCorreo();
        this.onChangeServidorPropio();
        this.onChangeCredenciales()
        this.validarFormActual(0);

        this.edicionForm.valueChanges.subscribe(result => {
            this.validarEdicionForm();
        });
        this.identificadoresForm.valueChanges.subscribe(result => {
            if (this.identificadoresForm.valid) {
                this.formActualValido = true;
            } else {
                this.formActualValido = false;
            }
        });

        this.correoConfiguracionForm.valueChanges.subscribe(result => {
            if (this.correoConfiguracionForm.valid) {
                this.formActualValido = true;
            } else {
                this.formActualValido = false;
            }
        }
        );

        this.academicManagementModelForm.valueChanges.subscribe(result => {
            if (this.academicManagementModelForm.valid) {
                this.formActualValido = true;
            } else {
                this.formActualValido = false;
            }
        }
        );

        this.onChangeTipoIdentificador();

        this.getAcademicManagementModel();        
    }
    //#endregion

    //#region FormGroup

    //Form para los datos de la institucion
    public edicionForm: FormGroup = new FormGroup({
        institucionId: new FormControl(this.data.institucionId),
        clave: new FormControl(this.data.clave, [Validators.required]),
        nombre: new FormControl(this.data.nombre, [Validators.required]),
        logo: new FormControl(this.data.logo, [Validators.required]),
        entidadFederativaId: new FormControl(this.data.entidadFederativaId, [Validators.required]),
        pais: new FormControl(this.data.pais, [Validators.required]),
        ciudad: new FormControl(this.data.ciudad, [Validators.required]),
        calle: new FormControl(this.data.calle, [Validators.required]),
        numeroExterior: new FormControl(this.data.numeroExterior, [Validators.required]),
        numeroInterior: new FormControl(this.data.numeroInterior),
        colonia: new FormControl(this.data.colonia, [Validators.required]),
        codigoPostal: new FormControl(this.data.codigoPostal, [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
        telefono: new FormControl(this.data.telefono, [Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{7,20}$/)]),
        extension: new FormControl(this.data.extension),
        correo: new FormControl(this.data.correo, [Validators.email]),
        responsableLegal: new FormControl(this.data.responsableLegal),
        nombreRector: new FormControl(this.data.nombreRector, [Validators.required]),
        fiscalEntidadFederativaId: new FormControl(this.data.fiscalEntidadFederativaId, [Validators.required]),
        fiscalPais: new FormControl(this.data.fiscalPais, [Validators.required]),
        razonSocial: new FormControl(this.data.razonSocial, [Validators.required]),
        rfc: new FormControl(this.data.rfc, [Validators.required]),
        fiscalCiudad: new FormControl(this.data.fiscalCiudad, [Validators.required]),
        fiscalCalle: new FormControl(this.data.fiscalCalle, [Validators.required]),
        fiscalNumeroExterior: new FormControl(this.data.fiscalNumeroExterior, [Validators.required]),
        fiscalNumeroInterior: new FormControl(this.data.fiscalNumeroInterior),
        fiscalColonia: new FormControl(this.data.fiscalColonia, [Validators.required]),
        fiscalCodigoPostal: new FormControl(this.data.fiscalCodigoPostal, [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)]),
    });

    //Form para los datos de configuracion del envio de correo
    public correoConfiguracionForm: FormGroup = new FormGroup({
        institutionEmailAccountId: new FormControl(this.data.institutionEmailAccount.length > 0 ? this.data.institutionEmailAccount[0].institutionEmailAccountId : 0),
        emailDelivery: new FormControl(this.data.emailDelivery),
        customEmailAccounts: new FormControl(this.data.customEmailAccounts),
        host: new FormControl(this.data.institutionEmailAccount.length > 0 ? this.data.institutionEmailAccount[0].host : ''),
        port: new FormControl(this.data.institutionEmailAccount.length > 0 ? this.data.institutionEmailAccount[0].port : ''),
        security: new FormControl(this.data.institutionEmailAccount.length > 0 ? this.data.institutionEmailAccount[0].security : 0),
        from: new FormControl(this.data.institutionEmailAccount.length > 0 ? this.data.institutionEmailAccount[0].from : ''),
        useCredentials: new FormControl(this.data.institutionEmailAccount.length > 0 ? this.data.institutionEmailAccount[0].useCredentials : false),
        user: new FormControl(this.data.institutionEmailAccount.length > 0 ? this.data.institutionEmailAccount[0].user : ''),
        password: new FormControl(this.data.institutionEmailAccount.length > 0 ? "00000000" : '')
    })

    public identificadoresForm: FormGroup = new FormGroup({
        identificadoTemporalActual: new FormControl(this.data.identificadorTemporal, [Validators.required, Validators.pattern(/^[1-9]+/)]),
        identificadoDefinitivoActual: new FormControl(this.data.identificadorPermanente),
        tipoIdentificador: new FormControl(this.data.identificadorPersonalizado, [Validators.required]),
    })


    public academicManagementModelForm: FormGroup = new FormGroup({
        academicManagementModelId: new FormControl(this.data.academicManagementModelId, [Validators.required, this.validateId])
    })


    public modalForm: FormGroup = new FormGroup({
        paraCorreoPrueba: new FormControl('', [Validators.required, Validators.email])
    })

    public opcionesForm: FormGroup = new FormGroup({
        reinscripcion: new FormControl(this.data.administracionOpcion.reinscripcion),
        historialAcademico: new FormControl(this.data.administracionOpcion.historialAcademico),
        boleta: new FormControl(this.data.administracionOpcion.boleta),
        estadoCuenta: new FormControl(this.data.administracionOpcion.estadoCuenta),
        documentos: new FormControl(this.data.administracionOpcion.documentos),
        solicitudes: new FormControl(this.data.administracionOpcion.solicitudes),
        porHacer: new FormControl(this.data.administracionOpcion.porHacer),
        avancePlan: new FormControl(this.data.administracionOpcion.avancePlan),
        misCursos: new FormControl(this.data.administracionOpcion.misCursos),
        miGrupo: new FormControl(this.data.administracionOpcion.miGrupo),
        extraordinario: new FormControl(this.data.administracionOpcion.extraordinario),
        telefonoWhatsApp: new FormControl(this.data.administracionOpcion.telefonoWhatsApp),
        numeroWhatsApp: new FormControl(this.data.administracionOpcion.numeroWhatsApp),
        lmsProvider: new FormControl(this.data.lmsProviderId)
    })


    //#endregion

    //#region Get Value FormControl
    get institucionId() { return this.edicionForm.get('institucionId'); }
    get clave() { return this.edicionForm.get('clave'); }
    get nombre() { return this.edicionForm.get('nombre'); }
    get logo() { return this.edicionForm.get('logo'); }
    get pais() { return this.edicionForm.get('pais'); }
    get entidadFederativaId() { return this.edicionForm.get('entidadFederativaId'); }
    get ciudad() { return this.edicionForm.get('ciudad'); }
    get calle() { return this.edicionForm.get('calle'); }
    get numeroExterior() { return this.edicionForm.get('numeroExterior'); }
    get numeroInterior() { return this.edicionForm.get('numeroInterior'); }
    get colonia() { return this.edicionForm.get('colonia'); }
    get codigoPostal() { return this.edicionForm.get('codigoPostal'); }
    get telefono() { return this.edicionForm.get('telefono'); }
    get extension() { return this.edicionForm.get('extension'); }
    get correo() { return this.edicionForm.get('correo'); }
    get responsableLegal() { return this.edicionForm.get('responsableLegal'); }
    get nombreRector() { return this.edicionForm.get('nombreRector'); }
    get razonSocial() { return this.edicionForm.get('razonSocial'); }
    get rfc() { return this.edicionForm.get('rfc'); }
    get fiscalPais() { return this.edicionForm.get('fiscalPais'); }
    get fiscalEntidadFederativaId() { return this.edicionForm.get('fiscalEntidadFederativaId'); }
    get fiscalCiudad() { return this.edicionForm.get('fiscalCiudad'); }
    get fiscalCalle() { return this.edicionForm.get('fiscalCalle'); }
    get fiscalNumeroExterior() { return this.edicionForm.get('fiscalNumeroExterior'); }
    get fiscalNumeroInterior() { return this.edicionForm.get('fiscalNumeroInterior'); }
    get fiscalColonia() { return this.edicionForm.get('fiscalColonia'); }
    get fiscalCodigoPostal() { return this.edicionForm.get('fiscalCodigoPostal'); }
    get emailDelivery() { return this.correoConfiguracionForm.get('emailDelivery'); }
    get customEmailAccounts() { return this.correoConfiguracionForm.get('customEmailAccounts'); }

    get identificadoTemporalActual() { return this.identificadoresForm.get('identificadoTemporalActual'); }
    get identificadoDefinitivoActual() { return this.identificadoresForm.get('identificadoDefinitivoActual'); }
    get tipoIdentificador() { return this.identificadoresForm.get('tipoIdentificador'); }

    get institutionEmailAccountId() { return this.correoConfiguracionForm.get('institutionEmailAccountId'); }
    get host() { return this.correoConfiguracionForm.get('host'); }
    get port() { return this.correoConfiguracionForm.get('port'); }
    get security() { return this.correoConfiguracionForm.get('security'); }
    get from() { return this.correoConfiguracionForm.get('from'); }
    get useCredentials() { return this.correoConfiguracionForm.get('useCredentials'); }
    get user() { return this.correoConfiguracionForm.get('user'); }
    get password() { return this.correoConfiguracionForm.get('password'); }
    get paraCorreoPrueba() { return this.modalForm.get('paraCorreoPrueba'); }

    get academicManagementModelId() { return this.academicManagementModelForm.get('academicManagementModelId'); }

    get esNueva() { return !this.clave }
    get numeroWhatsApp() { return this.opcionesForm.get('numeroWhatsApp'); }

    //#endregion

    //#region  OnChange Methods
    public onChangeEnvioCorreo() {
        // if (this.deliveryActive.value) {
        // this.ActualizarRequeridoFormCorreo();
        // } else {
        //     this.LimpiarRequeridoFormCorreo();
        // }
        // this.onChangeCredenciales();

    }


    public onChangeServidorPropio() {
        if (this.customEmailAccounts.value) {
            this.mostrarCamposConfiguracion = true;
            this.ActualizarRequeridoFormCorreo();
            this.formActualValido = false;
        } else {
            this.mostrarCamposConfiguracion = false;
            this.LimpiarRequeridoFormCorreo()
            this.LimpiarRequeridoCredenciales();
            this.formActualValido = true;
        }


    }

    public onChangeTipoIdentificador() {
        if (this.tipoIdentificador.value) {
            //Personalizado
            this.identificadoDefinitivoActual.removeValidators([Validators.required, Validators.pattern(/^[1-9]+/)]);
            this.identificadoDefinitivoActual.updateValueAndValidity();
            this.identificadoDefinitivoActual.disable();
        } else {
            //Consecutivo
            this.identificadoDefinitivoActual.setValidators([Validators.required, Validators.pattern(/^[1-9]+/)]);
            this.identificadoDefinitivoActual.enable();
            this.identificadoDefinitivoActual.updateValueAndValidity();
        }
    }
    //#endregion

    //#region Methods
    public ActualizarRequeridoFormCorreo() {
        this.host.setValidators(Validators.required);
        this.host.updateValueAndValidity();
        this.port.setValidators(Validators.required);
        this.port.updateValueAndValidity();
        this.security.setValidators(Validators.required);
        this.security.updateValueAndValidity();
        this.from.setValidators(Validators.required);
        this.from.updateValueAndValidity();
        this.useCredentials.setValidators(Validators.required);
        this.useCredentials.updateValueAndValidity();
    }

    public LimpiarRequeridoFormCorreo() {
        this.host.clearValidators();
        this.host.setValue('');
        this.host.updateValueAndValidity();
        this.port.clearValidators();
        this.port.setValue('');
        this.port.updateValueAndValidity();
        this.security.clearValidators();
        this.security.setValue(0);
        this.security.updateValueAndValidity();
        this.from.clearValidators();
        this.from.setValue('');
        this.from.updateValueAndValidity();
        this.useCredentials.clearValidators();
        this.useCredentials.setValue(false);
        this.useCredentials.updateValueAndValidity();
    }


    public ActualizarRequeridoCredenciales() {
        this.user.enable();
        this.password.enable();
        this.user.setValidators(Validators.required);
        this.password.setValidators(Validators.required);
        this.user.updateValueAndValidity();
        this.password.updateValueAndValidity();
        this.esValidoPassword = false;
    }

    public LimpiarRequeridoCredenciales() {
        this.user.setValue('');
        this.password.setValue('');
        this.user.disable();
        this.password.disable();
        this.user.removeValidators(Validators.required);
        this.password.removeValidators(Validators.required);
        this.user.updateValueAndValidity();
        this.password.updateValueAndValidity();
        this.esValidoPassword = false;
    }

    public onChangeCredenciales() {
        if (this.useCredentials.value) {
            this.ActualizarRequeridoCredenciales();
        } else {
            this.LimpiarRequeridoCredenciales();
        }
    }

    public verificarPassword(event) {
        this.esValidoPassword = true;
    }

    public onPaisSelected(pais: any) {
        this.estados = [];
        if (!!pais) {
            this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
                estados => {
                    this.estados = estados;
                }
            );
        };
    }

    public onFiscalPaisSelected(pais: any) {
        this.fiscalEstados = [];
        if (!!pais) {
            this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
                estados => {
                    this.fiscalEstados = estados;
                }
            );
        };
    }

    public onFileChange(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;

            this.edicionForm.patchValue({
                logo: file.name
            });

            reader.onload = () => {
                this.data.logoFile = reader.result;

                this._cd.markForCheck();
            };
            reader.readAsDataURL(file);
        }
    }

    private validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    public enviar(): void {
        
        this.Save();

        this.data = Object.assign(this.data, this.edicionForm.value);
        this.data.institutionEmailAccount.length = 0;
        this.data.esValidoPassword = this.esValidoPassword;
        let configuracionCorreo = Object.assign(this.correoConfiguracionForm.value);
        configuracionCorreo.password = configuracionCorreo.password === '00000000' ? '' : configuracionCorreo.password;
        this.data.customEmailAccounts = this.customEmailAccounts.value;
        this.data.emailDelivery = this.emailDelivery.value
        if (this.customEmailAccounts.value) {
            this.data.institutionEmailAccount.push(configuracionCorreo);
        } else {
            let configuracionCorreoVacio: InstitucionEmailAccount = {
                institutionEmailAccountId: configuracionCorreo.institutionEmailAccountId,
                institucionId: this.data.institucionId,
                host: '',
                port: 0,
                security: 'NO',
                from: '',
                to: '',
                subject: '',
                useCredentials: false,
                esValidoPassword: this.esValidoPassword,
            }
            this.data.institutionEmailAccount.push(configuracionCorreoVacio);
        }
        switch (this.tabSeleccionado) {
            case 0: {
                this.institucionService.modificarInstitucion(this.data).subscribe(
                    modificado => {
                        if (!!modificado) {
                            this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
                            this.data.paisNombre = this.paises.find(p => p.paisId == this.data.pais).nombre;
                            this.data.entidadFederativaNombre = this.estados.find(p => p.entidadFederativaId == this.data.entidadFederativaId).nombre;
                            this.boolCambio = JSON.stringify(this.data);
                            this.dialogRef.close(this.data);
                        } else {
                            this.snackBar.open("Ha ocurrido un error al guardar la institución. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                        }
                    },
                    error => {
                        this.snackBar.open("Ha ocurrido un error al guardar la institución. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
                    }
                );
                break;
            }
            case 1: {
                this.data.identificadorTemporal = this.identificadoTemporalActual.value;
                this.data.identificadorPermanente = this.identificadoDefinitivoActual.value;
                this.data.identificadorPersonalizado = this.tipoIdentificador.value;
                this.institucionService.guardarIdentificadores(this.data).subscribe(response => {
                    if (response.success) {
                        this.snackBar.open(response.message, "OK", { duration: 5000 });
                        this.dialogRef.close(this.data);
                    } else {
                        this.snackBar.open(response.message, "OK", { duration: 5000 });
                    }
                })
                break;
            }
            case 2: {
                this.institucionService.guardarConfiguracionCorreo(this.data).subscribe(response => {
                    if (response.success) {
                        this.snackBar.open(response.message, "OK", { duration: 5000 });
                        this.dialogRef.close(this.data);
                    }
                })
                break;
            }
            case 3: {
                this.data.academicManagementModelId = this.academicManagementModelId.value;
                this.institucionService.UpdategetAcademicManagementModelId(this.data).subscribe(response => {
                    if (response.success) {
                        this.snackBar.open(response.message, "OK", { duration: 5000 });
                        this.dialogRef.close(this.data);
                    } else {
                        this.snackBar.open(response.message, "OK", { duration: 5000 });
                    }
                })
                break;
            }
            case 4: {
                let adminBotones: AdministracionOpcion = Object.assign(this.opcionesForm.value);
                this.institucionService.administrarOpciones(adminBotones).subscribe((response: SimpleResponse) => {
                    if (response.success) {
                        let mensaje: string = this.traductorService.translate(response.message);
                        this.snackService.mostrarSnackBack(mensaje);
                        this.data.administracionOpcion = adminBotones;
                        this.data.lmsProviderId = adminBotones.lmsProvider;
                        this.dialogRef.close(this.data);
                    } else {
                        this.snackService.mostrarSnackBack(response.message);
                    }
                });
                break;
            }
            case 5: {
                let colorBar = this.colorBar.value;
                let colorButton = this.colorButton.value;
                if (colorBar === null && colorButton === null) {
                    let mensaje: string = this.traductorService.translate("_institucion.academic-Management-Model.seleccioneColor");
                    this.snackService.mostrarSnackBack(mensaje);
                } else {
                    if (colorBar?.hex?.indexOf("#") == -1) {
                        colorBar = '#' + colorBar.hex;
                    } else {
                        if (colorBar === null) {
                            colorBar = null;
                        }
                    }
                    if (colorButton?.hex?.indexOf('#') == -1) {
                        colorButton = '#' + colorButton.hex;
                    } else {
                        if (colorButton === null) {
                            colorButton = null;
                        }
                    }
                    var colores = { "colorBar": colorBar, "colorButton": colorButton };
                    this.institucionService.agregarEstilos(colores).subscribe((response: ApiResponse<any>) => {
                        if (response.success) {
                            let mensaje: string = this.traductorService.translate(response.message);
                            this.snackService.mostrarSnackBack(mensaje);
                            this.data.colorBar = colorBar;
                            this.data.colorButton = colorButton;
                            this.dialogRef.close(this.data);
                        } else {
                            this.snackService.mostrarSnackBack(response.message);
                        }
                    });
                }
            }
        }
        
    }

    public enviarCorreoPrueba() {
        let datosEdicion = this.correoConfiguracionForm.value;
        let correoEnvio = this.modalForm.value;

        let datosEnvioCorreo: InstitucionEmailAccount = {
            institutionEmailAccountId: this.institutionEmailAccountId.value,
            institucionId: this.institucionId.value,
            host: datosEdicion.host,
            port: datosEdicion.port,
            security: datosEdicion.security,
            from: datosEdicion.from,
            useCredentials: datosEdicion.useCredentials,
            to: correoEnvio.paraCorreoPrueba,
            subject: "Link4Campus-Configuracion para Envio de Correos",
            user: datosEdicion.user,
            password: datosEdicion.password,
            esValidoPassword: this.esValidoPassword
        }


        this.institucionService.enviarCorreoPrueba(datosEnvioCorreo).subscribe(response => {
            if (response.success) {
                this.correoFueEnviado = true;
                this.cerrarModalEnvioPrueba();
            }

        })


    }

    public cerrarModalValidacion() {
        if (this.tabSeleccionadoIndex.value !== 6) {
            if (this.boolCambio != JSON.stringify(Object.assign(this.data, this.edicionForm.value))) {
                this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
            } else {
                this.dialogRef.close(null);
            }
        }else{
            this.dialogRef.close(null);
        }
    }

    public cerrarModalEnvioPrueba() {
        this.dialogRefEnvioPrueba.close(null);
    }


    public abrirModalEnviarCorreoPrueba() {
        this.dialogRefEnvioPrueba = this.dialog.open(this.dialogEnvioPrueba, { disableClose: true });
    }

    public cerrarModalAdvertencia(descartar: boolean) {
        if (descartar) {
            this.dialogoRefAdver.close();
            this.data = JSON.parse(this.boolCambio);
            this.dialogRef.close(this.data);
        } else {
            this.dialogoRefAdver.close();
        }
    }

    public translate(text: string) {
        return this.i18nextPipe.transform(text, { format: 'cap' });
    }


    public mostrarContrasena() {
        this.passwordTipoTexto = !this.passwordTipoTexto;
    }

    public onChangeTab(event: MatTabChangeEvent) {
        this.tabCambio = true;
        let tab: number = event.index;
        this.tabSeleccionado = tab;
        this.validarFormActual(tab);

    }
    //#endregion

    //#region Validations Methods

    public validarFormActual(tab: number) {
        let telefonoWA: boolean = this.opcionesForm.get('telefonoWhatsApp').value;
        if (telefonoWA) {
            this.numeroWhatsApp.setValidators([Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{11,20}$/)]);
            this.telefonoWARequerido = telefonoWA;
        } else {
            this.numeroWhatsApp.setValidators(null);
            this.telefonoWARequerido = telefonoWA;
        }
        this.numeroWhatsApp.updateValueAndValidity();
        this.opcionesForm.get("telefonoWhatsApp").valueChanges.subscribe(checked => {
            if (checked) {
                this.numeroWhatsApp.setValidators([Validators.required, Validators.pattern(/^[0-9\s\-\(\)+]{11,20}$/)]);
                this.telefonoWARequerido = checked;
                this.validarBotonesForm();
            } else {
                this.numeroWhatsApp.setValidators(null);
                this.telefonoWARequerido = checked;
                this.validarBotonesForm();
            }
            this.numeroWhatsApp.updateValueAndValidity();
        });
        this.opcionesForm.get("numeroWhatsApp").valueChanges.subscribe(checked => {
            setTimeout(() => {
                this.validarBotonesForm();
            }, 300);
        });
        switch (tab) {
            case 0: {
                this.validarEdicionForm();
                break;
            }
            case 1: {
                this.validarIdentificadoresForm();
                break;
            }

            case 2: {
                this.validarCorreoConfiguracionForm();
                break;
            }
            case 4: {
                this.validarBotonesForm();
                break;
            }
        }
    }

    public validarEdicionForm() {
        if (this.edicionForm.valid) {
            this.formActualValido = true;
        } else {
            this.formActualValido = false;
        }
    }

    public validarBotonesForm() {
        if (this.opcionesForm.valid) {
            this.formActualValido = true;
        } else {
            this.formActualValido = false;
        }
    }

    public validarIdentificadoresForm() {
        if (this.identificadoresForm.valid) {
            this.formActualValido = true;
        } else {
            this.formActualValido = false;
        }
    }

    public validarCorreoConfiguracionForm() {
        if (this.correoConfiguracionForm.valid) {
            this.formActualValido = true;
        } else {
            this.formActualValido = false;
        }
    }


    public limpiarInputPassword() {
        if (!this.esValidoPassword) {
            this.password.setValue('');
        }
    }
    public setInputPassword() {
        if (!this.esValidoPassword) {
            this.password.setValue('00000000');
        }
    }
    //#endregion

    //#region AcademicManagementModel
    public getAcademicManagementModel(): void {
        this.Subscription.add(
            this.institucionService.getAcademicManagementModel().subscribe((response: ApiResponse<AcademicManagementModel[]>) => {
                if (response.success) {
                    this.AcademicManagementModel = response.data;
                } else {

                }
            })
        );
    }
    //#endregion

    //#region Errors & Validator 0
    //#region extras
    public getErrorForm(controlName: string): string {
        let error = '';
        const control = this.academicManagementModelForm.get(controlName);
        if (control.touched && control.errors != null) {
            error = "El campo es requerido";
        }
        return error;
    }
    private validateId(control: AbstractControl) {
        return control.value === 0 ? { error: 'El campo es requerido' } : null;
    }
    //#endregion
    //#endregion




//#region Methods
public tabValid:boolean     = false;
 /**
  * The function is called from the child component and it sets the value of the variable tabValid to
  * true or false
  * @param {boolean}  - boolean - This is the event that is passed from the child component.
  */
  public enableButtonSave($event: boolean):void{
    this.tabValid = $event;    
  }

  public Save():void{    
    this._EmitterSaveActionService.emitirEvento(this.tabSeleccionadoIndex.value);
  }
  //#endregion
}
