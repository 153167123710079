import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PeriodoService } from '@app/_services/periodo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-periodo-eliminar',
  templateUrl: './periodo-eliminar.component.html',
  styleUrls: ['./periodo-eliminar.component.scss']
})
export class PeriodoEliminarComponent implements OnInit {

  public numPeriodosSeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public clavePeriodo: string = '';

  constructor(public dialogRef: MatDialogRef<PeriodoEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private periodoService: PeriodoService) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numPeriodosSeleccionados = this.data.length;
    }
  }

  public eliminarCampus() {
    if (this.boolIsArray) {
      let arrayClaves = this.data.map(p => (p.periodoId.toString()));
      this.clavePeriodo = arrayClaves.toString();
    } else {
      this.clavePeriodo = this.data.periodoId.toString();
    }
    this.periodoService.eliminarPeriodo(this.clavePeriodo).subscribe(
      eliminado => {
        if (eliminado) {
          this.periodoService.invocarMetodoObtenerPeriodos();
          this.snackBar.open("Se ha eliminado la información.", "OK", { duration: 5000 });
          this.dialogRef.close(this.data);
        } else {
          this.snackBar.open("Ha ocurrido un error al eliminar el periodo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
        }
      },
      error => {
        console.log("error", error);
        this.snackBar.open("Ha ocurrido un error al eliminar el periodo. Por favor, intente de nuevo más tarde.", "OK", { duration: 5000 });
      }
    );
  }

}
