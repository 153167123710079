import { CompraCursoDto } from './../../_models/CompraCursoDto';
import { EstadoCuentaDto } from 'src/app/_models/estado-cuenta-dto';
import { IntentoPagoZumaDTO } from 'src/app/_models/IntentoPagoZumaDTO.model';
import { PagoZumaResponse } from 'src/app/_models/Zuma/LinkPago/PagoZumaResponse';
import { PagoLineaZumaService } from './../../_services/pago-linea-zuma.service';
import { EnsenanzaAprendizajeService } from './../../_services/ensenanza-aprendizaje.service';
import { ModalRegistroComponent } from 'src/app/_components/modal-registro/modal-registro.component';
import { CompraCursoRegistroDto } from './../../_models/CompraCursoRegistroDto';
import { SnackService } from 'src/app/services/snack-service.service';
import { TraductorService } from 'src/app/_services/traductor.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../_services/authentication.service';
import { baseUrl, environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { PagoZuma } from '../../_models/Zuma/LinkPago/PagoZuma';
import { PagoZumaModalComponent } from '../../_components/pago-zuma-modal/pago-zuma-modal.component';
import { UtileriasService } from '../../_services/utilerias.service';
import { CompraCursoService } from '../../_services/compra-curso.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { CuponService } from '../../_services/cupon.service';

@Component({
  selector: 'app-compra-cursos-carrito',
  templateUrl: './compra-cursos-carrito.component.html',
  styleUrls: ['./compra-cursos-carrito.component.scss']
})
export class CompraCursosCarritoComponent implements OnInit, OnDestroy {

  //Variables para las subcripciones
  public subscription: Subscription = new Subscription();
  //Modal iniciar sesion
  @ViewChild('modalInicioSesion') modalInicioSesion: TemplateRef<any>;
  public modalInicioSesionRef: any;
  public isAuthenticated: boolean = false;
  //Variable informacion cursoid
  public cursoId: number;
  public infoCurso: CompraCursoDto = new CompraCursoDto();
  //Variables form
  public registroForm: FormGroup;
  //Variable tenantid
  public tenantId: string = environment.enves[baseUrl].institucion;
  //Variables ver contrasena
  public passwordTipoTexto: boolean = false;
  //Variable Usuario
  public usuario: any = null;
  //Respuesta Zuma
  public pagoZuma: PagoZuma = null;
  public respuestaPagoZuma: PagoZumaResponse = null;
  //Variable para cupon
  public cuponSolicitado: string = "";
  public cuponIdRef: number = 0;
  public cuponActivado: boolean = false;
  public informacionCupon: any = null;
  public valorDescuento: number = 0;
  //Variable cosoto total curso
  public costoTotalCurso: number = 0;

  constructor(private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private utileriasService: UtileriasService,
    private msalService: MsalService,
    private activatedRoute: ActivatedRoute,
    private compraCursoService: CompraCursoService,
    private traductorService: TraductorService,
    private snackService: SnackService,
    public pagoLineaZumaService: PagoLineaZumaService,
    private ensenanza: EnsenanzaAprendizajeService,
    private cuponService: CuponService) { }

  ngOnInit(): void {
    this.isAuthenticated = this.authenticationService.isAuthenticated;
    const cursoIdParam: string = this.activatedRoute.snapshot.paramMap.get('cursoId');
    this.cursoId = +cursoIdParam;
    this.obtenerInfoMateria();
    if (this.isAuthenticated) {
      this.subscription.add(
        this.ensenanza.obtenerUsuarioConectado().subscribe(
          (usuario: any) => {
            this.usuario = usuario;
          })
      );
    }
    if (this.pagoLineaZumaService.invocarMetodoSubscription == undefined) {
      this.pagoLineaZumaService.invocarMetodoSubscription = this.pagoLineaZumaService.invocarMetodo.subscribe((event) => {
        this.guardarIntentoPago(event.pagoZuma, event.url);
      });
    }
  }

  /**
   * Metodo paraa obtener la informacion de la materia
   */
  public obtenerInfoMateria(): void {
    this.subscription.add(
      this.compraCursoService.CursoById(this.tenantId, this.cursoId).subscribe((response: ApiResponse<CompraCursoDto>) => {
        if (response.success) {
          this.infoCurso = response.data;
          this.costoTotalCurso = response.data.costoCurso;
          const cuponSolicitado = localStorage.getItem('cuponSolicitado');
          if (cuponSolicitado !== null) {
            this.cuponSolicitado = cuponSolicitado;
            localStorage.removeItem("cuponSolicitado");
            this.validarCuponSolicitado();
          }
        } else {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    );
  }

  /**
   * Metodo que valida si hay una sesion iniciada
   */
  public validarInicioSesion(): void {
    if (this.isAuthenticated) {
      this.validarAlumnoExistente();
    } else {
      const cursoIdParam: string = this.activatedRoute.snapshot.paramMap.get('cursoId');
      localStorage.setItem('cursoIdCarrito', cursoIdParam);
      localStorage.setItem('rutaCarrito', `compra-cursos/ficha-curso/${cursoIdParam}/carrito`);
      this.inicializarFormRegistro();
    }
  }

  /**
   * Metodo para validar si existe un alumno registrado para ese curso, si no existe se generara,
   * el usuario podra tener mas de un alumno, si no existe el alumno lo genera, 
   * si existe no se hace ninguna accion y continua con el flujo normalmente
   */
  public validarAlumnoExistente(): void {
    this.subscription.add(
      this.compraCursoService.ValidarAlumnoExistente(this.usuario.usuarioId, this.cursoId).subscribe((response: SimpleResponse) => {
        if (response.success) {
          this.enviarPagoZuma();
        } else {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    );
  }

  /**
   * Metodo que inicializa el formulario de registro
   */
  public inicializarFormRegistro(): void {
    this.registroForm = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      segundoNombre: new FormControl(""),
      apellidoPaterno: new FormControl("", [Validators.required]),
      apellidoMaterno: new FormControl(""),
      correo: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()¡¿=|?]/), this.Parameters]),
    });
    this.modalInicioSesionRef = this.dialog.open(this.modalInicioSesion, { disableClose: true });
  }
  get nombre() { return this.registroForm.get('nombre'); }
  get segundoNombre() { return this.registroForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.registroForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.registroForm.get('apellidoMaterno'); }
  get correo() { return this.registroForm.get('correo'); }
  get password() { return this.registroForm.get('password'); }

  /**
   * Metodo para realizar el pago
   */
  public enviarPagoZuma(): void {
    let pagoZuma: PagoZuma = {
      reference: `PagoCurso_${this.cursoId}_${this.usuario.usuarioId}_${this.infoCurso.costoCurso}_${this.tenantId}_${this.cuponIdRef}_${this.valorDescuento}`,
      amount: this.costoTotalCurso,
      promotion: "1",
      productCode: "AWPE_RPVT_XUG",
      expire: 1,
      sendLink: false,
      email: this.usuario.correo,
      paymentChannel: "ZUMA_API"
    }
    this.utileriasService.abrirModalPagoZuma(PagoZumaModalComponent, pagoZuma);
  }

  /**
   * Metodo que sirve para guardar el intento pago
   */
  public guardarIntentoPago(pagoZuma: PagoZuma, respuestaPagoZuma: PagoZumaResponse): void {
    let PagoIntento: IntentoPagoZumaDTO = {
      cargos: new Array<EstadoCuentaDto>(),
      pagoZuma: pagoZuma,
      pagoZumaResponse: respuestaPagoZuma
    }
    this.subscription.add(
      this.pagoLineaZumaService.PostIntentoPago(PagoIntento).subscribe((respuesta: SimpleResponse) => {
        if (respuesta.success) {
          console.log("GUARDADO INTENTO PAGO CORRECTO");
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    );
  }

  /**
   * Metodo para inciar sesion
   */
  public iniciarSesion(): void {
    this.msalService.loginRedirect({
      scopes: [
        environment.enves[baseUrl].AzureAD.resources.link4CampusApi.resourceScope,
      ]
    });
  }

  /**
   * Envio de registro de nuevo usuario
   */
  public registroCompraCurso(): void {
    let infoUsuario: CompraCursoRegistroDto = Object.assign(this.registroForm.value);
    infoUsuario.InstitucionId = this.tenantId;
    infoUsuario.CursoId = this.cursoId;
    this.subscription.add(
      this.compraCursoService.EnviarResgistroCompraCurso(infoUsuario, this.tenantId).subscribe((respuesta: SimpleResponse) => {
        if (respuesta.success) {
          this.utileriasService.abrirModalRegistro(ModalRegistroComponent, respuesta.message);
        } else {
          this.snackService.mostrarSnackBack(respuesta.message);
        }
      })
    );
  }

  /**
   * Metodo para validar el cupon solicitado
   */
  public validarCuponSolicitado(): void {
    this.subscription.add(
      this.cuponService.validarCuponSolicitado(this.tenantId, this.cuponSolicitado).subscribe((response: ApiResponse<any>) => {
        if (response.success) {
          this.informacionCupon = response.data;
          if (this.informacionCupon.couponTypeId == 1) {
            this.valorDescuento = this.informacionCupon.value * this.costoTotalCurso / 100;
            this.costoTotalCurso = this.costoTotalCurso - this.valorDescuento;
          } else if (this.informacionCupon.couponTypeId == 2) {
            this.valorDescuento = this.informacionCupon.value;
            this.costoTotalCurso = this.costoTotalCurso - this.valorDescuento;
          }
          localStorage.setItem('cuponSolicitado', this.cuponSolicitado);
          this.cuponIdRef = this.informacionCupon.couponId;
          this.cuponActivado = true;
        } else {
          let mensaje: string = this.traductorService.translate(response.message);
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    );
  }

  /**
   * Elimina el cupon solicitado por el usuario
   */
  public eliminarCupon(): void {
    this.cuponActivado = false;
    this.cuponSolicitado = "";
    this.costoTotalCurso = this.infoCurso.costoCurso;
    this.cuponIdRef = 0;
    this.valorDescuento = 0;
    localStorage.removeItem('cuponSolicitado');
  }

  /**
   * Cierra el modal de inicio de sesion
   */
  public cerrarModalInicioSesion(): void {
    this.modalInicioSesionRef.close();
  }

  /**
   * Parametros para comporbar contrasena correcta
   * @param control
   * @returns 
   */
  private Parameters(control: AbstractControl): { [key: string]: any } | null {
    let regExp = /[ÑÁÉÍÓÚñáéíóú]/.test(control.value);
    return regExp ? { 'password': { value: control.value } } : null;
  }

  /**
   * Metodo para ver contraseña
   */
  public mostrarContrasena() {
    this.passwordTipoTexto = !this.passwordTipoTexto;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
