import { AvanceCursoService } from './../../../_services/avance-curso.service';
import { ConsultarCalSeguimientoAcadenicoAlumnoComponent } from './../../../seguimiento-academico-alumno/consultar-cal-seguimiento-acadenico-alumno/consultar-cal-seguimiento-acadenico-alumno.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { Alumno } from '../../../_models/inscripcion';
import { MatTableDataSource } from '@angular/material/table';
import { ProgramaAcademicoService } from '../../../_services/programa-academico.service';
import { AdminAcademicaService } from '../../../_services/admin-academica.service';
import { ImprimirServiceService } from '../../../services/imprimir-service.service';
import { Subscription } from 'rxjs';
import { Ciclo } from '../../../_models/ciclo';
import { FormGroup, FormControl } from '@angular/forms';
import { Periodo } from '../../../_models/periodo';
import { Boleta } from 'src/app/_models/boleta.model';
import { AlumnoGrupoParcial } from 'src/app/_models/alumnoGrupoParcial.model';
import { PeriodoService } from '../../../_services/periodo.service';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';

@Component({
  selector: 'app-boleta',
  templateUrl: './boleta.component.html',
  styleUrls: ['./boleta.component.scss']
})
export class BoletaComponent implements OnInit {

  constructor(private programaAcademicoService: ProgramaAcademicoService,
    private adminAcademiaService: AdminAcademicaService,
    public adminAcademicaService: AdminAcademicaService,
    public imprimirService: ImprimirServiceService,
    private periodoService: PeriodoService,
    private generadorExcel: GeneracionExcelServiceService,
    private dialog: MatDialog,
    private avanceCursoService: AvanceCursoService) { }

 //#region Propiedades
 @Input() data: {alumno: Alumno, indexTap:number};
 @Input() opcion: boolean = false;
 public filtroForm: FormGroup;
 periodoF = new FormControl();
 cicloList: any = [];
 public Periodos: Periodo[];
 filtroactual:any;
 selected: any;
 flagBoleta=false;
 cicloListFusion:Array<any>=[];
 Parciales:Array<number>=[];
 materiasHistorial:Array<Boleta>=[];
 displayedColumns: string[] = [
   'Clave',
   'Nombre',
   'Créditos',
   'Calificación',
   'Periodo',
   'Estatus'];
 dataSource: MatTableDataSource<any>;
 ExcelSubscription: Subscription;
 //#endregion

  ngOnInit(): void {
    this.inicializarForm();
    this.LlenarFiltros();
    if (this.data.alumno.priodoIngreso != null || this.data.alumno.priodoIngreso != undefined) {
      this.selected = this.data.alumno.priodoIngreso;
      this.obtenerBoleta();
    }
  }
  public inicializarForm() {
    this.filtroForm = new FormGroup({
      periodoF: new FormControl(null),
    });
  }

  filtrar(){
    this.obtenerBoleta();
  }

  LlenarFiltros(){
    this.adminAcademiaService.obtenerPeriodosByAlumno(this.data.alumno.alumnoId).subscribe(
      (periodos: ApiResponse<any>) => {
        this.Periodos = periodos.data;
    });
  }

  obtenerBoleta() {
    this.adminAcademiaService.GetBoleta(this.data.alumno.alumnoId, this.selected, this.data.alumno.campusId, this.data.alumno.programaId, this.data.alumno.programa.nivelId).subscribe(
      (respuestaBoleta: ApiResponse<any>) => {
      this.materiasHistorial = null;
      this.materiasHistorial = respuestaBoleta.data;
        if(this.materiasHistorial!=null || this.materiasHistorial!=undefined){
          this.Parciales.length = 0;
          for (let i = 1; i <= this.materiasHistorial[0].parciales; i++) {
            this.Parciales.push(i);
          }

          this.materiasHistorial.forEach(boleta => {
            for(let i = boleta.alumnoGrupoParcial.length; i < this.materiasHistorial[0].parciales; i++)
            {
              let calificacionFalsa = new AlumnoGrupoParcial();
              calificacionFalsa.calificacion = -1;
              boleta.alumnoGrupoParcial.push(calificacionFalsa);
            }
          });
          this.flagBoleta = true;
        }
    })
    //que retorne algo
  }

  public descargarExcel() {

    this.ExcelSubscription = this.adminAcademicaService.descargarSheetBoleta(this.materiasHistorial).subscribe((res: Blob) => {
      let filename =`HistorialAcademico${this.data.alumno.usuario.nombre}${this.data.alumno.usuario.apellidoPaterno}${this.data.alumno.usuario.apellidoMaterno}`;
      this.generadorExcel.GenerarExcel(res, filename);
      this.ExcelSubscription.unsubscribe();
    })
  }

  public imprimir(){
     document.getElementById("historialExtra").style.visibility = 'display';
     const printContent:HTMLElement = document.getElementById("historialExtra");
     document.getElementById("historialExtra").style.visibility = 'none';
     let nombreAlumno = `${this.data.alumno.usuario.nombre}${this.data.alumno.usuario.apellidoMaterno}${this.data.alumno.usuario.apellidoMaterno}`
     this.imprimirService.Imprimir(printContent, nombreAlumno);
  }

  //#region Modal
  public AbrirModal(ciclo): void
  {
    this.cargarContenido(ciclo.alumnoId, ciclo.grupoId, ciclo.materiaNombre);

    //const dialogRef = this.dialog.open(ConsultarCalSeguimientoAcadenicoAlumnoComponent, { data: {alumnoId : ciclo.alumnoId, grupoId : ciclo.grupoId, nombre: ciclo.materiaNombre}, disableClose: true });
    // dialogRef.afterClosed().subscribe(result => {
    // });
  }

    /**
   * Trae la informacion del grupo
   * @param grupoId
   */
     public cargarContenido(alumnoId: number, grupoId: number, cicloNombre: string) {
      this.avanceCursoService.obtenerContenidoAlumno(alumnoId, grupoId).subscribe((respuestaContenido: ApiResponse<any>) => {
        let datosCompletos: any = {
          datosPersonales: {
            alumnoId: alumnoId,
            nombre: cicloNombre
          },
          contenidoCurso: respuestaContenido.data
        }
        //this.util.abrirDialogoLateral(AvanceCursoModalComponent, datosCompletos);
        const dialogRef = this.dialog.open(ConsultarCalSeguimientoAcadenicoAlumnoComponent, { data: datosCompletos, disableClose: true });
      });
    }
  //#endregion

}
