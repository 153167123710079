export class EstudiosPrevios {
    /// <summary>
    /// Id del estudio previo
    /// </summary>
    estudioPrevioId: number;

    /// <summary>
    /// Usuario que tendra ese registro
    /// </summary>
    usuarioId: number;

    /// <summary>
    /// Nivel del estudio previo
    /// </summary>
    nivelId: number;

    /// <summary>
    /// Escuela al que pertenecia anteriormente
    /// </summary>
    escuela: string;

    /// <summary>
    /// Programa al que pertenecia anteriormente
    /// </summary>
    programa: string;

    /// <summary>
    /// Promedio que obtuvo en su anterior escuela
    /// </summary>
    promedio: number;

    tipoCandidato: number;

    /// <summary>
    /// Fecha que inicio el curso en su anterior escuela
    /// </summary>
    fechaIngreso: Date ;

    /// <summary>
    /// Fecha que termino en la escuela previa
    /// </summary>
    fechaEgreso: Date ;

}
