export class HistorialCalificacionFinalParametroDTO {

    AlumnoId:           number;
    GrupoId:       number;
    CalificacionNoNumericaId: number;
    TipoMotivoCalificacionId:   number;
    UsuarioCreacion: number;
    MOID: number;

    constructor(
        AlumnoId:           number,
        GrupoId:       number,
        CalificacionNoNumericaId: number,
        TipoMotivoCalificacionId:   number,
        UsuarioCreacion: number,
        MOID: number
    ) {
        this.AlumnoId = AlumnoId;
        this.GrupoId = GrupoId;
        this.CalificacionNoNumericaId = CalificacionNoNumericaId;
        this.TipoMotivoCalificacionId = TipoMotivoCalificacionId;
        this.UsuarioCreacion = UsuarioCreacion;
        this. MOID = MOID;
    }

  
}
