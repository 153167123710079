import { Validators } from "@angular/forms";

export const PERSONAL_CONTACTS_INFORAMTION = {

    usuarioId           : [, { updateOn: 'change' }],    
    tipoRelacionId      : [, { updateOn: 'change' }],
    nombreCompleto      : [, { updateOn: 'change' }],
    telefono            : [, { validators: [Validators.pattern(/^[0-9\s\-\(\)+]{8,20}$/)], updateOn: 'change' }],
    correoElectronico   : [, { validators: [Validators.email], updateOn: 'change' }],
    //Helper
    titulo           : (''),
};

