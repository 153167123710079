import { TraductorService } from './../../_services/traductor.service';
import { SnackService } from './../../services/snack-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { UtileriasService } from '../../_services/utilerias.service'
import { FormGroup, FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ModalInscripcionComponent } from './modal-inscripcion/modal-inscripcion.component';
import { NivelService } from '../../_services/nivel.service';
import { CampusService } from '../../_services/campus.service';
import { ProgramaAcademicoService } from '../../_services/programa-academico.service';
import { PeriodoService } from '../../_services/periodo.service';
import { Campus } from '../../_models/campus';
import { Nivel } from '../../_models/nivel';
import { Programa } from '../../_models/programa';
import { Periodo } from '../../_models/periodo';
import { InscripcionCosto } from '../../_models/inscripcion-costo';
import { ConfiguracionCuotaService } from '../../_services/configuracion-cuota.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-configuracion-inscripcion',
  templateUrl: './configuracion-inscripcion.component.html',
  styleUrls: ['./configuracion-inscripcion.component.scss']
})
export class ConfiguracionInscripcionComponent implements OnInit {
  displayedColumns: string[] = ['select', 'campus', 'nivel', 'programa', 'periodo', 'tipoAlumno', 'fechaInicio', 'fechaFin', 'costo'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);
  @ViewChild('allNivel') private allNivel: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  @ViewChild('allPrograma') private allPrograma: MatOption;

  public filtroForm: FormGroup;
  public numSeleccionado: number;
  public arrOriginal: any[] = [];
  public arrCampus: Campus[] = [];
  public arrNivel: Nivel[] = [];
  public arrPrograma: Programa[] = [];
  public arrPeriodo: Periodo[] = [];
  public arrTipoA: any[] = [{ 'id': 'NI', 'name': 'Nuevo Ingreso' }, { 'id': 'RI', 'name': 'Reingreso' }];
  public infoCargado: boolean = false;

  constructor(
    public util: UtileriasService,
    private paginador: MatPaginatorIntl,
    private campusservice: CampusService,
    private nivelservice: NivelService,
    private programaservice: ProgramaAcademicoService,
    private periodoservice: PeriodoService,
    private servicios: ConfiguracionCuotaService,
    private snackBar: MatSnackBar,
    private snackService: SnackService,
private traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
    this.limpiarFiltros();
    this.cargarInscripciones();
    if(this.servicios.obtenerInscripcionesSubscription == undefined){
      this.servicios.obtenerInscripcionesSubscription = this.servicios.invocarObtenerInscripciones.subscribe( ()=>{
        this.servicios.getInscripcionCosto().subscribe((inscripcion: any[]) => {
          this.arrOriginal = inscripcion;
          // this.dataSource = new MatTableDataSource(inscripcion);
          // this.dataSource.paginator = this.paginator;
          this.filtro();
        })   
      })
    }
    this.llenarFiltros();
  }

  public cargarInscripciones(){
    this.servicios.getInscripcionCosto().subscribe(data=>{
      this.arrOriginal=data;
      this.dataSource = new MatTableDataSource(data);
       this.dataSource.paginator = this.paginator;
       this.selection = new SelectionModel<any>(true, []);
    })
  }

  get nombreConfig() { return this.filtroForm.get('nombre'); }
  get campusConfig() { return this.filtroForm.get('campus'); }
  get nivelConfig() { return this.filtroForm.get('nivel'); }
  get fechaConfig() { return this.filtroForm.get('fecha'); }

  limpiarFiltros() {
    this.filtroForm = new FormGroup({
      tipo: new FormControl(0),
      campus: new FormControl([...this.arrCampus.map(item => item.campusId), 0]),
      nivel: new FormControl([...this.arrNivel.map(item => item.nivelId), 0]),
      programa: new FormControl([...this.arrPrograma.map(item => item.programaId), 0]),
      periodo: new FormControl(0),

    })
  }
  Eliminar() {
    let eliminado: any = this.selection.selected[0];
    let inscripcionC: InscripcionCosto = new InscripcionCosto();
    inscripcionC.CampusId = eliminado.campusId;
    inscripcionC.fechaFin = eliminado.fechaFin;
    inscripcionC.fechaInicio = eliminado.fechaInicio;
    inscripcionC.NivelId = eliminado.nivelId;
    inscripcionC.TipoAlumno = eliminado.tipoAlumno;
    inscripcionC.PeriodoId = eliminado.periodoId;
    inscripcionC.ProgramaId = eliminado.programaId;
    this.servicios.eliminarInscripcionCosto(inscripcionC).subscribe((doto: boolean) => {
      if (doto) {
        this.snackBar.open('Se ha eliminado el costo de inscripcion correctamente.', 'OK', { duration: 5000 });
      } else {
        this.snackBar.open('Ha ocurrido un error al eliminar el costo de inscripcion. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
      }
    })
  }
  llenarFiltros() {
    this.campusservice.obtenerCampus().subscribe((campus: Campus[]) => {
      this.arrCampus = campus;
      this.filtroForm.controls['campus'].patchValue([...this.arrCampus.map(item => item.campusId), 0]);
      this.filtro();
    });
    this.nivelservice.getNivel().subscribe((nivel: Nivel[]) => {
      this.arrNivel = nivel;
      this.filtroForm.controls['nivel'].patchValue([...this.arrNivel.map(item => item.nivelId), 0]);
      this.filtro();
    })
    this.programaservice.obtenerProgramas().subscribe((programa: Programa[]) => {
      this.arrPrograma = programa;
      this.filtroForm.controls['programa'].patchValue([...this.arrPrograma.map(item => item.programaId), 0]);
      this.filtro();
    })
    this.periodoservice.obtenerPeriodos().subscribe((periodo: Periodo[]) => {
      this.arrPeriodo = periodo;
      this.filtro();
    })
    this.servicios.getInscripcionCosto().subscribe((inscripcion: any[]) => {
      this.arrOriginal = inscripcion;
      this.filtro();
    })
  }

  filtro() {
    let campus: any[] = this.filtroForm['value'].campus;
    let nivel: any[] = this.filtroForm['value'].nivel;
    let programa: any[] = this.filtroForm['value'].programa;
    let periodo: number = this.filtroForm['value'].periodo;
    let tipo: string = this.filtroForm['value'].tipo;
    let arrFiltrado: any[];
    arrFiltrado = [...this.arrOriginal];

    arrFiltrado = arrFiltrado.filter(f => { return nivel.indexOf(f.nivelId) > -1 });
    arrFiltrado = arrFiltrado.filter(f => { return campus.indexOf(f.campusId) > -1 });
    arrFiltrado = arrFiltrado.filter(f => { return programa.indexOf(f.programaId) > -1 });
    if (periodo > 0) {
      arrFiltrado = arrFiltrado.filter(f => { return periodo == f.periodoId });
    }
    if (tipo.toString() !== "0") {
      arrFiltrado = arrFiltrado.filter(f => { return tipo === f.tipoAlumno });
    }
    this.dataSource = new MatTableDataSource(arrFiltrado);

  }

  downloadExcel(){
    this.servicios.downloadExcelInscripcionCosto().subscribe((inscripcion: any[]) => {
      let mensaje: string = this.traductorService.translate('descargaArchivo');
      this.snackService.mostrarSnackBack(mensaje);
    });
  }

  isAllSelected() {
    this.numSeleccionado = this.selection.selected.length;
    const numRows = this.arrOriginal.length;
    return this.numSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.UsuarioId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() :
      this.arrOriginal.forEach(row => this.selection.select(row));

  }
  openServicio(elemento: any = null) {
    this.util.abrirDialogoLateral(ModalInscripcionComponent, elemento);
  }
  tosslePerOneNivel(all) {
    if (this.allNivel.selected) {
      this.allNivel.deselect();
      return false;
    }
    if (this.filtroForm['value'].periodo.length == this.arrNivel.length) { this.allNivel.select(); }
    this.filtro();
  }

  tosslePerOnePrograma(all) {
    if (this.allPrograma.selected) {
      this.allPrograma.deselect();
      return false;
    }
    if (this.filtroForm['value'].programa.length == this.arrPrograma.length) { this.allPrograma.select(); }
    this.filtro();
  }

  tosslePerOneCampus(all) {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.filtroForm['value'].campus.length == this.arrCampus.length) { this.allCampus.select(); }
    this.filtro();
  }
  toggleAllSelectionNivel() {
    if (this.allNivel.selected) {
      this.filtroForm.controls['nivel'].patchValue([...this.arrNivel.map(item => item.nivelId), 0]);
    } else {
      this.filtroForm.controls['nivel'].patchValue([]);
    }
    this.filtro();
  }
  toggleAllSelectionPrograma() {
    if (this.allPrograma.selected) {
      this.filtroForm.controls['programa'].patchValue([...this.arrPrograma.map(item => item.programaId), 0]);
    } else {
      this.filtroForm.controls['programa'].patchValue([]);
    }
    this.filtro();
  }
  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.filtroForm.controls['campus'].patchValue([...this.arrCampus.map(item => item.campusId), 0]);
    } else {
      this.filtroForm.controls['campus'].patchValue([]);
    }
    this.filtro();
  }

}
