import { ProgramaDatosGeneralesDto } from './../_models/ProgramaDatosGeneralesDto';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, Subject } from 'rxjs';
import { environment, baseUrl } from '@environments/environment';
import { Programa, ProgramaActivo } from '../_models/programa';
import { InfoPrograma } from '../_models/infoPrograma';
import { Ciclo } from '../_models/ciclo';
import { CoordinadorDto } from '../_models/CoordinadorDto';
import { Nivel } from '../_models/nivel';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramaAcademicoService {

  private idPrograma$ = new Subject<number>();
  private boolTabDisabled$ = new Subject<boolean>();
  private boolCambioPendiente$ = new Subject<boolean>();
  private arrayMaterias$ = new Subject<any>();

  invocarObtenerCiclosByPrograma = new EventEmitter();
  invMetodoCiclosByProgramaSubscription: Subscription;

  invocarObtenerProgramas = new EventEmitter();
  invMetodoSubscription: Subscription;

  invocarObtenerCiclos = new EventEmitter();
  invMetodoCiclosSubscription: Subscription;

  constructor(private httpClient: HttpClient) { }

  // Get Programas
  public obtenerProgramas(): Observable<Programa[]> {
    return this.httpClient.get<Programa[]>(`${environment.enves[baseUrl].apiUrl}/Programa`);
  }
  // Get Ciclos por Programa
  public obtenerProgramasCampus(campusId: number): Observable<Programa[]> {
    return this.httpClient.get<Programa[]>(`${environment.enves[baseUrl].apiUrl}/Programa/GetProgramasByCampusId?campusId=` + campusId);
  }
  //Get Nivel por programa
  public obtenerProgramasNivel(nivelId: number): Observable<Programa[]> {
    return this.httpClient.get<Programa[]>(`${environment.enves[baseUrl].apiUrl}/Programa/GetProgramasByNivelId?nivelId=` + nivelId);
  }
  // Post Programa
  public generarPrograma(programa): Observable<number> {
    return this.httpClient.post<number>(`${environment.enves[baseUrl].apiUrl}/Programa`, programa);
  }

  // Put Programa Datos Generales
  public modificarProgramaDatosGenerales(programa): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Programa/PutProgramaDatosGenerales`, programa);
  }

  // Put Programa Requerimientos Generales
  public modificarProgramaRequerimientosGenerales(programa): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Programa/PutProgramaRequerimientosGenerales`, programa);
  }

  // Put Programa Informacion Acuerdo
  public modificarProgramaInformacionAcuerdo(programa): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/Programa/PutProgramaInformacionAcuerdo`, programa);
  }

  // Delete Programa
  public eliminarPrograma(programaId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.enves[baseUrl].apiUrl}/Programa/DeletePrograma?programaId=` + programaId);
  }

  // Put Info Programa Objetivos
  public modificarInfoProgramaObjetivos(infoPrograma: InfoPrograma): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/InfoPrograma/PutInfoProgramaObjetivos`, infoPrograma);
  }

  // Put Info Programa Perfil
  public modificarInfoProgramaPerfil(infoPrograma: InfoPrograma): Observable<boolean> {
    return this.httpClient.put<boolean>(`${environment.enves[baseUrl].apiUrl}/InfoPrograma/PutInfoProgramaPerfil`, infoPrograma);
  }

  // Post Ciclo Plan de estudios
  public generarCiclo(ciclo): Observable<Ciclo> {
    return this.httpClient.post<Ciclo>(`${environment.enves[baseUrl].apiUrl}/Ciclo/PostCiclo`, ciclo);
  }

  // Put Ciclo
  public editarCiclo(ciclo): Observable<Ciclo> {
    return this.httpClient.put<Ciclo>(`${environment.enves[baseUrl].apiUrl}/Ciclo/PutCiclo`, ciclo);
  }

  // Get Ciclos por Programa
  public obtenerCiclosByPrograma(programaId: number): Observable<Ciclo[]> {
    return this.httpClient.get<Ciclo[]>(`${environment.enves[baseUrl].apiUrl}/Ciclo/GetCicloByProgramaID?programaId=` + programaId);
  }

  // Delete Ciclo
  public eliminarCiclo(cicloId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.enves[baseUrl].apiUrl}/Ciclo/DeleteCiclo?cicloId=` + cicloId);
  }

  // Delete Multiples Ciclos
  public eliminarMultiplesCiclos(cicloId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.enves[baseUrl].apiUrl}/Ciclo/DeleteCiclos?cicloId=` + cicloId);
  }

  // Set idPrograma Observable
  public definirIdPrograma(idPrograma: number) {
    this.idPrograma$.next(idPrograma);
  }

  // Get idPrograma Observable
  public obtenerIdPrograma$(): Observable<number> {
    return this.idPrograma$.asObservable();
  }

  // Set bool tab Observable
  public definirBoolTab(boolDisabled: boolean) {
    this.boolTabDisabled$.next(boolDisabled);
  }

  // Get bool tab Observable
  public obtenerBoolTab$(): Observable<boolean> {
    return this.boolTabDisabled$.asObservable();
  }

  // Set bool cambio Observable
  public definirBoolCambio(boolCambio: boolean) {
    this.boolCambioPendiente$.next(boolCambio);
  }

  // Get bool cambio Observable
  public obtenerBoolCambio$(): Observable<boolean> {
    return this.boolCambioPendiente$.asObservable();
  }

  // Invocar Metodo obtenerProgramas
  invocarMetodo() {
    this.invocarObtenerProgramas.emit();
  }

  // Invocar Metodo obtenerCiclos
  invocarMetodoCiclos() {
    this.invocarObtenerCiclos.emit();
  }

  // Invocar metodo obtener ciclos by programa
  invocarMetodoObtenerCiclos() {
    this.invocarObtenerCiclosByPrograma.emit();
  }

  // Get Clave By Ciclo
  public obtenerCicloByClave(clave: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.enves[baseUrl].apiUrl}/Ciclo/ClaveCiclo?clave=` + clave);
  }
  /**
   * Obtiene una lista de los programas ofertados en un campus y de un Nivel en específico(Lic, Ing, Maestría, etc).
   * @param idCampus
   * @param idNivel
   * @returns
   */
  public obtenerProgramasCampusYNivel(idCampus: number, idNivel: number): Observable<ApiResponse<Array<Programa>>> {
    return this.httpClient.get<ApiResponse<Array<Programa>>>(`${environment.enves[baseUrl].apiUrl}/Programa/ObtenerProgramasDelCampus?IdCampus=${idCampus}&Idnivel=${idNivel}`);
  }

  /**
   * Pasa los datos de programas filtrados para su descarga como archivo de Excel
   * @param programas
   */
  public descargarExcel(programas) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Programa/DescargarExcel`, JSON.stringify(programas),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  // Get Programas DISTINC
  public GetProgramasByInstitucion(): Observable<ApiResponse<ProgramaDatosGeneralesDto[]>> {
    return this.httpClient.get<ApiResponse<ProgramaDatosGeneralesDto[]>>(`${environment.enves[baseUrl].apiUrl}/Programa/GetProgramasByInstitucion`);
  }

  /**
   * Obtiene los programas activos
   */
  public obtenerProgramasActivos(): Observable<ApiResponse<ProgramaActivo[]>> {
    return this.httpClient.get<ApiResponse<ProgramaActivo[]>>(`${environment.enves[baseUrl].apiUrl}/Programa/GetProgramasActivos`);
  }

  /**
  * Metodo para traer los coordinadores del programa por su id
  * @param ProgramaId 
  * @returns 
  */
  public coordinadoresByProgramaId(ProgramaId: number): Observable<ApiResponse<CoordinadorDto[]>> {
    return this.httpClient.get<ApiResponse<CoordinadorDto[]>>(`${environment.enves[baseUrl].apiUrl}/Programa/CoordinadoresByProgramaId/${ProgramaId}`);
  }

  /**
   * Metodo para traer una lista de coordinadores conformada por profesores y admins
   * @returns 
   */
  public listaCoordinadores(): Observable<ApiResponse<CoordinadorDto[]>> {
    return this.httpClient.get<ApiResponse<CoordinadorDto[]>>(`${environment.enves[baseUrl].apiUrl}/Programa/ListaCoordinadores`);
  }

  /**
   * Metodo para editar los coordinadores
   * @param dataEditar 
   * @returns 
   */
  public editarCoordinador(ProgramaId: number, dataEditar: CoordinadorDto[]): Observable<SimpleResponse> {
    return this.httpClient.put<SimpleResponse>(`${environment.enves[baseUrl].apiUrl}/Programa/EditarCoordinador/${ProgramaId}`, dataEditar);
  }
}
