import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AccionEnum } from '../../../../_models/accion.enum';
import { SolicitudApoyoFinancieroService } from '../../../../_services/solicitud-apoyo-financiero.service';

@Component({
  selector: 'app-situacion-economica',
  templateUrl: './situacion-economica.component.html',
  styleUrls: ['./situacion-economica.component.scss']
})
export class SituacionEconomicaComponent implements OnInit {

  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public renderizarForm: boolean = false;
  public situacionEconomicaForm: FormGroup;
  public tipoAccion: number;

  constructor(public dialogEconomicaRef: MatDialogRef<SituacionEconomicaComponent>,
    private solicitudApoyoFinancieroService: SolicitudApoyoFinancieroService) { }

  ngOnInit(): void {
    this.datosUsuarioBusqueda$ = this.solicitudApoyoFinancieroService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
  }

  public inicializarForm() {
    this.situacionEconomicaForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuario.usuarioId),
      numeroDependientesEconomicos: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.situacionEconomica.numeroDependientesEconomicos, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      numeroAutosPropios: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.situacionEconomica.numeroAutosPropios, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      ingresoFamiliarTotal: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.situacionEconomica.ingresoFamiliarTotal, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      gastoMensualServicios: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.situacionEconomica.gastoMensualServicios, [Validators.required, Validators.pattern(/^[0-9]+/)]),
      gastoMensualVivienda: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.usuario.situacionEconomica.gastoMensualVivienda, [Validators.required, Validators.pattern(/^[0-9]+/)]),
    });
    this.renderizarForm = true;
    this.situacionEconomicaForm.valueChanges.subscribe(val => {
      this.solicitudApoyoFinancieroService.definirFormularioValidoNuevaSolicitud(this.situacionEconomicaForm.valid);
      this.solicitudApoyoFinancieroService.setChangesSituacionEconomica(true);
    });
  }

  get numeroDependientesEconomicos() { return this.situacionEconomicaForm.get('numeroDependientesEconomicos'); }
  get numeroAutosPropios() { return this.situacionEconomicaForm.get('numeroAutosPropios'); }
  get ingresoFamiliarTotal() { return this.situacionEconomicaForm.get('ingresoFamiliarTotal'); }
  get gastoMensualServicios() { return this.situacionEconomicaForm.get('gastoMensualServicios'); }
  get gastoMensualVivienda() { return this.situacionEconomicaForm.get('gastoMensualVivienda'); }

  public renderizarFormulario() {
    if (this.datosUsuarioBusqueda.usuario.situacionEconomica != null) {
      this.tipoAccion = AccionEnum.EDITAR;
    } else {
      this.tipoAccion = AccionEnum.CREAR;
    }
    this.inicializarForm();
  }

}
