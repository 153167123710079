import { environment, baseUrl } from './../../environments/environment';
import { ApiResponse } from './../models/api/ApiRespose.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class MovimientoService {

  constructor(private httpClient: HttpClient) { }

  public ObtenerMovimientos(filtros: any): Observable<ApiResponse<any>> {
    return this.httpClient.post<ApiResponse<any>>(`${environment.enves[baseUrl].apiUrl}/Movimiento/ObtenerMovimientos`, filtros);
  }

  public descargarExcel(data: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Movimiento/DownloadExcel`, JSON.stringify(data),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

  public DownloadExcelDetalle(data: any) {
    return this.httpClient.post(`${environment.enves[baseUrl].apiUrl}/Movimiento/DownloadExcelDetalle`, JSON.stringify(data),
      {
        responseType: "blob",
        headers: {
          'Content-Type': 'application/json',
        }
      });
  }

}
