import { EdicionBloqueGrupoEliminarComponent } from './edicion-bloque-grupo-eliminar/edicion-bloque-grupo-eliminar.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BlockService } from 'src/app/_services/block.service';
import i18next from 'i18next';
import { Block } from 'src/app/_models/block';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { EdicionBloqueGrupoAgregarComponent } from './edicion-bloque-grupo-agregar/edicion-bloque-grupo-agregar.component';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edicion-bloque-grupo',
  templateUrl: './edicion-bloque-grupo.component.html',
  styleUrls: ['./edicion-bloque-grupo.component.scss']
})
export class EdicionBloqueGrupoComponent implements OnInit, OnDestroy {
  public Subscription: Subscription = new Subscription();
  //Variables Obs
  public infoGrupos$: Observable<any>;
  public infoGrupos: any = null;
  public infoCargado: boolean = false;

  @Input() data: Block;
  displayedColumns: string[] = ['select', 'grupo', 'materia', 'profe', 'aula', 'frecuencia', 'horario'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private curentLanguaje: string;
  public arrOriginal: any[] = [];
  public arrTemporales: any[] = [];
  public arrOriginalTemporales: any[] = [];

  public datafilter: any[] = [];
  public arregloFiltroOriginal: any[] = [];



  public numGrupoSeleccionado = 0;
  selection = new SelectionModel<any>(true, []);
  public dataSource: MatTableDataSource<any>;


  constructor(public blockService: BlockService,
    private paginador: MatPaginatorIntl,
    private dialog: MatDialog,
    private contador: MatPaginatorIntl
  ) { }

  ngOnInit(): void {


    // if (this.blockService.invMetodoObtenerBloqueGrupoSubscription == undefined) {
      this.blockService.invMetodoObtenerBloqueGrupoSubscription = this.blockService.invocarObtenerBloqueGrupo.subscribe(() => {
        this.ObtenerGruposBloque();
      });
    // }
    this.blockService.invMetodoAñadirBloqueGrupoSubscription = this.blockService.invocarAñadirBloqueGrupo.subscribe(() => {
      this.añadirGruposBloque();
    });


    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };


    this.ObtenerGruposBloque();

  }


  public añadirGruposBloque() {


    this.infoGrupos$ = this.blockService.GetGruposABloque$();
    this.Subscription.add(
      this.infoGrupos$.subscribe(
        (info: any) => {
          this.infoGrupos = info;
          if (this.infoGrupos.length > 1) {
            this.infoGrupos.forEach(value => {
              this.data.gruposId.push(value.grupoId)
              this.arrTemporales.push(value)
            });
          } else {
            this.arrTemporales.push(this.infoGrupos);
            this.data.gruposId.push(this.infoGrupos.grupoId)
          }


          this.actualizarTablaGrupo();

        })
    );

    this.eliminarSubscripcion();

  }


  public ObtenerGruposBloque() {
    console.log(this.data);
    this.curentLanguaje = i18next.language;

    this.blockService.ObtenerBloqueGrupo(this.data.blockId, this.curentLanguaje).subscribe(response => {
      if (response.success) {
        let blockEncontrados: any[] = [];
        blockEncontrados = response.data;
        this.arrOriginal = blockEncontrados;
        this.dataSource = new MatTableDataSource(blockEncontrados);
        this.datafilter = blockEncontrados;
        this.dataSource.paginator = this.paginator;
        this.arregloFiltroOriginal = response.data;
        this.selection = new SelectionModel<any>(true, []);
        this.infoCargado = true;

        let arraygrupoIds = response.data.map(c => (c.grupoId.toString()));

        this.data.gruposId = [];

        arraygrupoIds.forEach(value => {
          this.data.gruposId.push(value)
        });

      }
    })
  }







  isAllSelected() {
    this.numGrupoSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numGrupoSeleccionado === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.grupoId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }



  public borrar(btnDataTable: boolean, elemento?: any) {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }

    //seccion para remover los temporales 

    let arraygrupoIds = [];
    if (datos.length) {
      //VIENE MAS DE UN DATO

      //Removimiendo en el array temporales
      datos.forEach(dato => {
        this.arrTemporales.forEach((value, index) => {
          if (value.grupoId == dato.grupoId) {
            this.arrTemporales.splice(index, 1);
            arraygrupoIds.push(value.grupoId);
          }

        });
      })

      //Removiendo para que vuelvan aparecer en el modal agregar grupo
      datos.forEach(dato => {
        this.data.gruposId.forEach((value, index) => {
          if (value == dato.grupoId) this.data.gruposId.splice(index, 1);
        });
      })

      arraygrupoIds.forEach(value => {
        datos.forEach((dato, index) => {
          if (dato.grupoId == value) datos.splice(index, 1);
        });
      })


      if (datos.length > 0) {
        this.abrirModalBorrar(datos);
      }


      // arraygrupoIds = datos.map(c => (c.grupoId.toString()));







    } else {
      //VIENE UN SOLO DATO

      if (this.arrOriginal.some(e => e.grupoId == datos.grupoId)) {

        this.abrirModalBorrar(datos);
      } else {
        //Removimiendo en el array temporales
        this.arrTemporales.forEach((value, index) => {
          if (value.grupoId == datos.grupoId) this.arrTemporales.splice(index, 1);
        });

        //Removiendo para que vuelvan aparecer en el modal agregar grupo
        this.data.gruposId.forEach((value, index) => {
          if (value == datos.grupoId) this.data.gruposId.splice(index, 1);
        });

      }






    }

    this.actualizarTablaGrupo();










  }


  public actualizarTablaGrupo() {
    this.arrOriginalTemporales = this.arrOriginal;
    this.arrOriginalTemporales = this.arrOriginalTemporales.concat(this.arrTemporales);
    this.dataSource = new MatTableDataSource(this.arrOriginalTemporales);
    this.dataSource.paginator = this.paginator;
    this.datafilter = this.arrOriginalTemporales;
    this.selection = new SelectionModel<any>(true, []);
    this.dataSource._updateChangeSubscription();
  }


  public abrirModalBorrar(datos) {
    const dialogoEliminar = this.dialog.open(EdicionBloqueGrupoEliminarComponent, {
      data: datos,
      panelClass: "dialogo-eliminar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  public agregarGrupo(): void {

    const dialogoEliminar = this.dialog.open(EdicionBloqueGrupoAgregarComponent, {
      data: this.data,
      panelClass: "dialogo-agregar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });

  }



  eliminarSubscripcion() {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }


  ngOnDestroy(): void {
    this.arrTemporales = [];
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }



}
