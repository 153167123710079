import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ProfesorService } from '../../_services/profesor.service';
import { Profesor } from '../../_models/profesor';
import { Usuario } from '../../_models/usuario';
import { UtileriasService } from '../../_services/utilerias.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { OfertaProfesorModalComponent } from './oferta-profesor-modal/oferta-profesor-modal.component'
import { AccionEnum } from '../../_models/accion.enum';
import { Direccion } from '../../_models/direccion';
import { Biografia } from '../../_models/biografia';
import { PersonaRelacionada } from '../../_models/persona-relacionada';
import { NuevoIngreso } from '../../_models/nuevo-ingreso';
import { OfertaProfesorEdicionModalComponent } from './oferta-profesor-edicion-modal/oferta-profesor-edicion-modal.component';
import { DireccionService } from '../../_services/direccion.service';
import { OfertaProfesorEliminarComponent } from './oferta-profesor-eliminar/oferta-profesor-eliminar.component';
import { OfertaProfesorCorreoComponent } from './oferta-profesor-correo/oferta-profesor-correo.component';
import { SelectionModel } from '@angular/cdk/collections';
import { OfertaProfesorBusquedaCorreoComponent } from './oferta-profesor-busqueda-correo/oferta-profesor-busqueda-correo.component';
import { Departamento } from '../../_models/departamento';
import { FormacionAcademicaProfesorComponent } from './oferta-profesor-busqueda-correo/formacion-academica-profesor/formacion-academica-profesor.component';
import { TipoContrato } from '../../_models/tipo-contrato';
import { FormGroup, FormControl } from '@angular/forms';
import { TipoContratoService } from '../../_services/tipo-contrato.service';
import { DepartamentoService } from '../../_services/departamento.service';
import { MatDialog } from '@angular/material/dialog';
import { element } from 'protractor';

@Component({
  selector: 'app-oferta-academica-profesor',
  templateUrl: './oferta-academica-profesor.component.html',
  styleUrls: ['./oferta-academica-profesor.component.scss']
})
export class OfertaAcademicaProfesorComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['select', 'ID', 'Nombre', 'tipoContrato', 'departamento', 'documentos', 'estatus'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(OfertaProfesorBusquedaCorreoComponent) profesorBusqueda: OfertaProfesorBusquedaCorreoComponent;
  selection = new SelectionModel<any>(true, [])
  private subscripcionProfesor: Subscription;
  public usuario: Usuario;
  public usuarioList: Usuario[] = [];
  public nuevoIngreso: NuevoIngreso;
  public profesor: Profesor;
  public profesoresList: Profesor[] = [];
  public valorSeleccionado: Profesor[] = [];
  public direccion: Direccion;
  public biografia: Biografia;
  public personaRelacionada: PersonaRelacionada;
  public datafilter: any;
  public datosFiltrados: any[];
  public profesorList: any[] = [];
  public contratoList: TipoContrato[] = [];
  public departamentoList: Departamento[] = [];
  public filtroForm: FormGroup;
  public arregloFiltroOriginal: Profesor[] = [];
  public arregloFiltroModificado: any[] = [];
  public subscriptionProfesor: Subscription;
  public subscriptionContrato: Subscription;
  public subscriptionDepartamento: Subscription;
  public departamentoSeleccionado: number = 0;
  public contratoSeleccionado: number = 0;
  public profesorSeleccionado: number = 0;
  public infoCargada: boolean = false;
  public estatusCargado: boolean = false;
  public actaCargado: boolean = false;
  public estudiosCargado: boolean = false;
  public fotoCargado: boolean = false;
  public numProfesorSeleccionado: number = 0;
  public recursos = [];

  constructor(
    public util: UtileriasService,
    private profesorService: ProfesorService,
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    private contratoService: TipoContratoService,
    private departamentoService: DepartamentoService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.estatusCargado = true;
    if (this.profesorService.invMetodoSubscription == undefined) {
      this.profesorService.invMetodoSubscription = this.profesorService.invocarObtenerProfesor.subscribe(() => {
        this.obtenerProfesor();
        this.estatusCargado = false;
        this.paginador.itemsPerPageLabel = "Registros por página";
      });
    }
    if (this.profesorService.limpiarFiltrosSubscription == undefined) {
      this.profesorService.limpiarFiltrosSubscription = this.profesorService.limpiarFiltros.subscribe(() => {
        this.refrescarDatos();
      });
    }

    this.obtenerProfesor();
    this.obtenerContrato();
    this.obtenerDepartamento();
    this.inicializarForm();
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  public inicializarForm() {
    this.filtroForm = new FormGroup({
      idNombre: new FormControl(),
      tipoContrato: new FormControl(0),
      departamento: new FormControl(0),
      estatus: new FormControl(1)
    });
  }

  get idNombre() { return this.filtroForm.get('idNombre'); }
  get tipoContrato() { return this.filtroForm.get('tipoContrato'); }
  get departamento() { return this.filtroForm.get('departamento'); }
  get estatus() { return this.filtroForm.get('estatus'); }

  //Refrescar button
  public refrescarDatos() {
    this.inicializarForm();
    this.vaciarArregloModificado();
    this.profesorService.invocarMetodoObtenerProfesor();
  }

  public vaciarArregloModificado() {
    //empty your array
    this.arregloFiltroModificado.length = 0;
  }

  public filtrar() {
    var nombre = this.filtroForm['value'].idNombre;
    var contrato = this.filtroForm['value'].tipoContrato;
    var departamento = this.filtroForm['value'].departamento;
    var estatus = this.filtroForm['value'].estatus;
    var filtro: any;
    //Condicion por si todos los filtros los regresan a "Seleccionar"
    if (nombre == '' && contrato == 0 && departamento == 0 && estatus == 1) {
      this.refrescarDatos();
    }
    if (this.arregloFiltroModificado.length == 0 || this.contratoSeleccionado != 0
      || this.departamentoSeleccionado != 0 || this.profesorSeleccionado != 0) {
      this.arregloFiltroModificado = this.arregloFiltroOriginal;
    }
    //Condicion si el filtro de nombre tiene valor
    if (nombre != null) {
      filtro = this.arregloFiltroModificado.filter(row => { return row.nombreCompleto.toLowerCase().search(nombre.toLowerCase()) > -1 || row.identificador.toString().search(nombre) > -1 });
      this.arregloFiltroModificado = this.arregloFiltroModificado.filter(row => { return row.nombreCompleto.toLowerCase().search(nombre.toLowerCase()) > -1 || row.identificador.toString().search(nombre) > -1 });
    }
    //Condicion si el filtro de contrato tiene un valor
    if (contrato > 0) {
      filtro = this.arregloFiltroModificado.filter(row => row.profesor.tipoContratoId == contrato);
      this.arregloFiltroModificado = this.arregloFiltroModificado.filter(row => row.profesor.tipoContratoId == contrato);
      this.contratoSeleccionado = contrato;
    }
    //Condicion si el departamento  tiene un valor
    if (departamento > 0) {
      filtro = this.arregloFiltroModificado.filter(row => row.profesor.departamentoId == departamento);
      this.arregloFiltroModificado = this.arregloFiltroModificado.filter(row => row.profesor.departamentoId == departamento);
      this.departamentoSeleccionado = departamento;
    }
    if (estatus == 0) {
      filtro = this.arregloFiltroModificado;

    }
    this.dataSource = new MatTableDataSource(filtro);
    this.datafilter = this.dataSource.filteredData;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.selection = new SelectionModel<any>(true, []);
  }

  public filtrarInactivos() {
    var estatus = this.filtroForm['value'].estatus;

    if (estatus == 0) {
      this.subscripcionProfesor = this.profesorService.obtenerProfesorInactivos().subscribe(
        (profesoresInactivos: any[]) => {
          this.dataSource = new MatTableDataSource(profesoresInactivos);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.arregloFiltroOriginal = profesoresInactivos;
          let primerFiltro = profesoresInactivos;
          this.datafilter = primerFiltro;
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargada = true;
          this.profesorList = profesoresInactivos;
          this.filtroForm.controls['nombreProfesor'].setValue(0);
          this.filtroForm.controls['tipoContrato'].setValue(0);
          this.filtroForm.controls['departamento'].setValue(0);
          if (this.infoCargada) {
            this.dataSource.filterPredicate = function (data: any, filter: string): boolean {
              return `${data.nombre} ${data.apellidoPaterno} ${data.apellidoMaterno}`.toLowerCase().includes(filter)
                || data.profesor.tipoContrato.nombre.toLowerCase().includes(filter)
                || data.profesor.departamento.nombre.toLowerCase().includes(filter);
            };
          }
        });
    } else {
      this.filtroForm.controls['nombreProfesor'].setValue(0);
      this.filtroForm.controls['tipoContrato'].setValue(0);
      this.filtroForm.controls['departamento'].setValue(0);
      this.obtenerProfesor();
    }
  }

  public obtenerContrato() {
    this.subscriptionContrato = this.contratoService.obtenerTipoContrato().subscribe(
      (contrato: any[]) => {
        this.contratoList = contrato;
      });
  }

  public obtenerDepartamento() {
    this.subscriptionDepartamento = this.departamentoService.obtenerDepartamentos().subscribe(departamento => {
      this.departamentoList = departamento.data;
    });
  }

  public obtenerProfesor() {
    this.subscripcionProfesor = this.profesorService.obtenerProfesor().subscribe(
      (profesor: any[]) => {
        profesor = profesor.map(obj =>
        ({
          ...obj,
          nombreCompleto: obj.nombre + ' ' + obj.apellidoPaterno + ' ' + obj.apellidoMaterno
        })
        )

        this.dataSource = new MatTableDataSource(profesor);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.arregloFiltroOriginal = profesor;
        // let primerFiltro = profesor.filter(x => x.profesor.length >= 1);
        let primerFiltro = profesor;
        this.datafilter = primerFiltro;
        this.datosFiltrados = profesor;
        this.selection = new SelectionModel<any>(true, []);
        this.infoCargada = true;
        this.profesorList = profesor;
        if (this.infoCargada) {
          this.dataSource.filterPredicate = function (data: any, filter: string): boolean {

            return `${data.nombre} ${data.apellidoPaterno} ${data.apellidoMaterno}`.toLowerCase().includes(filter)
              || data.profesor.tipoContrato.nombre.toLowerCase().includes(filter)
              || data.profesor.departamento.nombre.toLowerCase().includes(filter);
          };
        }


      }
    );
  }

  public findNombreProfesor(ID: number) {
    return this.profesorList.find(id => id.UsuarioId == ID);
  }

  public findTipoContrato(TipoContratoID: number) {
    return this.profesorList.find(contrato => contrato.TipoContratoId == TipoContratoID);
  }

  public findDepartamento(DepartamentoID: number) {
    return this.profesorList.find(departamento => departamento.DepartamentoId == DepartamentoID);
  }

  public findEstatus(EstatusID: boolean) {
    return this.profesorList.find(estatus => estatus.EsActivo == EstatusID);
  }

  public crear(): void {
    let datos: NuevoIngreso = {
      /* NuevoIngreso */
      Id: 0,
      nombre: '',
      ciclo: null,
      programaAcademico: null,
      completo: false,
      datosPersonales: false,
      comprobanteIdentidad: false,
      ine: false,
      estudiosPrevios: false,
      foto: false,
      beca: false,
      seleccionMaterias: false,
      pago: false,
      tipoAccion: AccionEnum.CREAR
    }
    const dialogo = this.util.abrirDialogoLateral(OfertaProfesorModalComponent, datos);
    dialogo.afterClosed().subscribe(result => {
      if (!!result) {
        this.nuevoIngreso = result;
      }
    })
  }

  public borrar(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogo = this.util.abrirDialogoLateral(OfertaProfesorEliminarComponent, datos);
  }

  public crearProfesor(): void {
    this.util.abrirDialogoLateral(OfertaProfesorBusquedaCorreoComponent);
  }

  public abrirFormacion(): void {
    this.util.abrirDialogoLateral(FormacionAcademicaProfesorComponent);
  }

  public editar(elemento?): void {
    let datos: Usuario = {
      usuarioID: elemento.usuario.usuarioId,
      identificador: elemento.usuario.identificador,
      Correo: elemento.usuario.correo,
      nombre: elemento.usuario.nombre,
      segundoNombre: elemento.usuario.segundoNombre,
      apellidoPaterno: elemento.usuario.apellidoMaterno,
      apellidoMaterno: elemento.usuario.apellidoMaterno,
      fechaNacimiento: null,
      estatus: null,
      perfil: null,
      Roles: null,
      Oficinas: null,
      IsAdmin: false,
      IsProfesor: true,
      IsAlumno: false,
      Token: '',
      Expiracion: 0,
      tipoAccion: AccionEnum.EDITAR
    }
    const dialogo = this.util.abrirDialogoLateral(OfertaProfesorEdicionModalComponent, datos);
  }

  public validarActaNacimientoId(element) {
    let primerFiltro = element.usuarioDocumento.find(x => x.documentoId === 1);
    if (primerFiltro !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  public validarIndentFotoId(element) {
    let primerFiltro = element.usuarioDocumento.find(x => x.documentoId === 6);
    if (primerFiltro !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  public validarEstudiosPreviosoId(element) {
    let primerFiltro = element.usuarioDocumento.find(x => x.documentoId === 7);
    if (primerFiltro !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  public onChangeActivo(value) {
    //this.inputFiltro.nativeElement.value = "";
    this.numProfesorSeleccionado = 0;
    this.selection = new SelectionModel<any>(true, []);
    if (value === 'Todos') {
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
    } else if (value === 'Activo') {
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
    } else if (value === 'Inactivo') {
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
    }
  }

  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }

    const dialogo = this.dialog.open(OfertaProfesorCorreoComponent, {
      data: datos,
      panelClass: "dialogo-enviar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  //Filtro

  applyFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase();
    if (filterValue != "") {
      this.dataSource.filter = filterValue;
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        var nombre = data.nombre + " " + data.segundoNombre + " " +data.apellidoPaterno + " " + data.apellidoMaterno;
        return nombre.toLowerCase().includes(filter) || data.identificador.toString().includes(filter);
      };
      this.dataSource.paginator = this.paginator;
    }
  }

 /*  public applyFilter() {
    this.selection.clear();
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.datafilter = this.dataSource.filteredData;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  } */

  isAllSelected() {
    this.numProfesorSeleccionado = this.selection.selected.length;
    const numRows = this.datafilter.length;
    return this.numProfesorSeleccionado === numRows;
  }

  checkboxLabel(row?: Profesor): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.UsuarioId + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() :
      this.datafilter.forEach(row => this.selection.select(row));

  }

  ngOnDestroy(): void {
    if (this.subscripcionProfesor) {
      this.subscripcionProfesor.unsubscribe();
    }
    if (this.subscriptionContrato) {
      this.subscriptionContrato.unsubscribe();
    }
    if (this.subscriptionDepartamento) {
      this.subscriptionDepartamento.unsubscribe();
    }
    if (this.ExcelSubscription != null) {
      this.ExcelSubscription.unsubscribe();
    }
  }


  public editarSolicitud(solicitud: any, solicitudCargaDoc: boolean) {
    solicitud.solicitudCargaDoc = solicitudCargaDoc;
    this.util.abrirDialogoLateral(OfertaProfesorBusquedaCorreoComponent, solicitud);
  }

  ExcelSubscription: Subscription;

  public exportExcel(elemento?) {
    if (elemento != null && elemento.length > 0) {
      this.ExcelSubscription = this.profesorService.descargarExcel(elemento).subscribe((res: Blob) => {
        let filename: string = "Profesores.xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
          window.navigator.msSaveOrOpenBlob(res, filename);
        } else {
          let a: HTMLAnchorElement = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);
          let blob: Blob = new Blob([res], { type: 'application/xlsx' });
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
        }
        this.ExcelSubscription.unsubscribe();
        });
    } else {
      this.ExcelSubscription = this.profesorService.descargarExcel(this.datafilter).subscribe((res: Blob) => {
      let filename: string = "Profesores.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { //Para internet explorer
        window.navigator.msSaveOrOpenBlob(res, filename);
      } else {
        let a: HTMLAnchorElement = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        let blob: Blob = new Blob([res], { type: 'application/xlsx' });
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
      this.ExcelSubscription.unsubscribe();
      });
    }

  }

}
