import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { Campus } from 'src/app/_models/campus';
import { Periodo } from 'src/app/_models/periodo';
import { Programa } from 'src/app/_models/programa';
import { CampusService } from 'src/app/_services/campus.service';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-usuario-cuenta-nueva',
  templateUrl: './usuario-cuenta-nueva.component.html',
  styleUrls: ['./usuario-cuenta-nueva.component.scss']
})
export class UsuarioCuentaNuevaComponent implements OnInit {

  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  public nuevoSolicitudForm: FormGroup;
  public nuevoSolicitudUsuarioForm: FormGroup;
  public renderizarFormulario: boolean = false;
  public correoRegistro: any;
  public nombreCompletoUsuario: string;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public subscritpionCampus: Subscription;
  public subscriptionPeriodo: Subscription;
  public subscriptionPrograma: Subscription;
  public campusList: Campus[] = [];
  public periodoList: Periodo[] = [];
  public programaList: Programa[] = [];
  public tipoAccion: number;
  public utileriasService: any;
  public formularioProfesor: boolean = false;
  public passwordTipoTexto: boolean = false;
  public passwordCTipoTexto: boolean = false;
  public usuarioId: number;
  public dialogoRefAdver: any;
  public boolCambio: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UsuarioCuentaNuevaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private campusService: CampusService,
    private periodoService: PeriodoService,
    private usuarioService: UsuarioService,
    private dialog: MatDialog,
    private utileria: UtileriasService,
  ) { }

  ngOnInit(): void {
    this.inicializarForm();
    this.datosUsuarioBusqueda$ = this.usuarioService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos)
  }

  public recibirInfo(correo: any) {
    this.tipoAccion = AccionEnum.CREAR;
    this.correoRegistro = correo;
    this.inicializarForm();
  }


  public inicializarForm() {
    this.nuevoSolicitudForm = new FormGroup({
      usuarioId: new FormControl(this.data?.usuarioId),
      correo: new FormControl(this.data?.correo),
      nombre: new FormControl(this.data?.nombre, [Validators.required]),
      apellidoPaterno: new FormControl(this.data?.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(this.data?.apellidoMaterno),
      fechaNacimiento: new FormControl(this.utileria.formatearFecha(this.data?.fechaNacimiento, 'YYYY[-]MM[-]DD')),
      password: new FormControl(this.data?.password ? this.data?.password : ''),
      passwordConfirm: new FormControl(this.data?.passwordConfirm ? this.data?.passwordConfirm : ''),
      estatus: new FormControl(this.data?.estatus === true ? true : false),
     // aceptaTerminos: new FormControl(false, [Validators.required]),
    });
    this.renderizarFormulario = true;
  
    this.nuevoSolicitudForm.valueChanges.subscribe(val => {
      this.boolCambio = true;
    });
  }


  get correo() { return this.nuevoSolicitudForm.get('correo'); }
  get nombre() { return this.nuevoSolicitudForm.get('nombre'); }
  get apellidoPaterno() { return this.nuevoSolicitudForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.nuevoSolicitudForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.nuevoSolicitudForm.get('fechaNacimiento'); }
  get password() { return this.nuevoSolicitudForm.get('password'); }
  get passwordConfirm() { return this.nuevoSolicitudForm.get('passwordConfirm'); }
  get programaAcademico() { return this.nuevoSolicitudForm.get('programaAcademico'); }
  get periodo() { return this.nuevoSolicitudForm.get('periodo'); }
  get campus() { return this.nuevoSolicitudForm.get('campus'); }

  public enviar(): void {

  }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  public getErrorFormUsuario(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudUsuarioForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  private parameters(control: AbstractControl): { [key: string]: any } | null {
    let regExp = /[ÑÁÉÍÓÚñáéíóú]/.test(control.value);

    return regExp ? { 'password': { value: control.value } } : null;
  }

  public mostrarContrasena() {
    this.passwordTipoTexto = !this.passwordTipoTexto;
  }

  public mostrarContrasenaC() {
    this.passwordCTipoTexto = !this.passwordCTipoTexto;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  private compararPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('passwordConfirm').value;
    return pass === confirmPass ? null : { notSame: true }
  }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }

}
