import { MsalGuard } from '@azure/msal-angular';
import { ServicioPaqueteSeguimientoComponent } from './servicio-paquete-seguimiento/servicio-paquete-seguimiento.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'servicio-paquete', component: ServicioPaqueteSeguimientoComponent, canActivate: [MsalGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServicioPaqueteRoutingModule { }
