import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { SnackService } from 'src/app/services/snack-service.service';

@Component({
  selector: 'app-leads-import-modal',
  templateUrl: './leads-import-modal.component.html',
  styleUrls: ['./leads-import-modal.component.scss']
})
export class LeadsImportModalComponent implements OnInit {
  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  @ViewChild('stepper') stepper: MatStepper;

  public dialogoRefAdver: any;
  public boolCambio: boolean = false;
  public stepSeleccionado: number = 0;

  constructor(private snackService: SnackService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  public cerrarModal() {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertenia, { disableClose: true });
    } else {

    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.stepper.reset();
    } else {
      this.dialogoRefAdver.close();
    }
  }
}
