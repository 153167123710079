import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { SnackService } from 'src/app/services/snack-service.service';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { DatoFiscal } from 'src/app/_models/dato-fiscal';
import { EntidadFederativa } from 'src/app/_models/entidad-federativa';
import { Pais } from 'src/app/_models/pais';
import { TipoDireccion } from 'src/app/_models/tipo-direccion';
import { TipoTelefono } from 'src/app/_models/tipo-telefono';
import { DireccionService } from 'src/app/_services/direccion.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { SeguimientoCarteraService } from 'src/app/_services/seguimiento-cartera.service';
import { SolicitudApoyoFinancieroService } from 'src/app/_services/solicitud-apoyo-financiero.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';

@Component({
  selector: 'app-datos-personales-inscripcion',
  templateUrl: './datos-personales-inscripcion.component.html',
  styleUrls: ['./datos-personales-inscripcion.component.scss']
})
export class DatosPersonalesInscripcionComponent implements OnInit {

  //DATOS INICIALES
  public subscriptionDatosSeguimiento: Subscription;
  public datosSeguimientoUsuario$: Observable<any>;
  public datosSeguimientoUsuario: any;
  //CATALOGOS
  public subscriptionTipoDireccion: Subscription;
  public tipoDireccionList: TipoDireccion[] = [];
  public subscriptionPais: Subscription;
  public paisList: Pais[] = [];
  public subscriptionEstado: Subscription;
  public estadoList: EntidadFederativa[] = [];
  public subscriptionTipoTelefono: Subscription;
  public tipoTelefonoList: TipoTelefono[] = [];
  //VARIABLES FORM VALIDACION
  public tipoAccionDireccion: number;
  public tipoAccionTelefono: number;
  public tipoAccionFamiliarPapa: any;
  public tipoAccionFamiliarMama: any;
  public tipoAccionFamiliarTutor: any;
  public tipoAccionDatoFiscal: number;
  //VARIABLES FORM
  public datosPersonalesForm: FormGroup;
  public datosContactoForm: FormGroup;
  public datosFamiliaresForm: FormGroup;
  public datosFacturacionForm: FormGroup;
  //BANDERA RENDERIZAR HTML
  public renderizarForm: boolean = false;
  //BANDERA CAMBIOS FORM
  public datosContactoFormCambio: boolean = false;
  public datosFacturacionFormCambio: boolean = false;
  //VARIABLES DATOS PERSONALES
  public datosContactoGuardado: boolean = true;
  public datosFacturacionGuardado: boolean = true;
  public datosPersonalesFormValid: boolean = false;
  public datosFacturacionFormValid: boolean = false;

  constructor(private seguimientoService: SeguimientoCarteraService,
    private direccionService: DireccionService,
    private utileriasService: UtileriasService,
    public nuevoIngresoService: NuevoIngresoService,
    private snackService: SnackService,
    private solicitudApoyoFinancieroService: SolicitudApoyoFinancieroService
  ) { }

  ngOnInit(): void {
    this.datosSeguimientoUsuario$ = this.seguimientoService.getDatosSeguimientoUsuario$();
    this.subscriptionDatosSeguimiento = this.datosSeguimientoUsuario$.subscribe(datosSeguimiento => this.datosSeguimientoUsuario = datosSeguimiento);
    this.getTipoDireccion();
    this.getPais();
    this.getTipoTelefono();
  }

  /**
   * Obtiene el catalogo de tipos de direccion
   */
  public getTipoDireccion(): void {
    this.subscriptionTipoDireccion = this.direccionService.obtenerTipoDireccion().subscribe(
      (tipoDireccion: TipoDireccion[]) => {
        this.tipoDireccionList = tipoDireccion;
      });
  }

  /**
   * Obtiene el catalogo de paises
   */
  public getPais(): void {
    this.subscriptionPais = this.direccionService.obtenerPaises().subscribe(
      (pais: Pais[]) => {
        this.paisList = pais;
      });
  }

  /**
   * Detecta el pais elegido para mostrar los estados que pertenecen a ese pais
   * @param pais 
   */
  public onPaisSelected(pais: number): void {
    this.estadoList = [];
    if (!!pais) {
      this.direccionService.obtenerEntidadesFederativas(pais).subscribe(
        estados => {
          this.estadoList = estados;
        })
    }
  }

  /**
   * Obtiene el catalogo tipo de telefono
   */
  public getTipoTelefono(): void {
    this.subscriptionTipoTelefono = this.direccionService.obtenerTipoTelefono().subscribe(
      (tipoTelefono: TipoTelefono[]) => {
        this.tipoTelefonoList = tipoTelefono;
      });
  }

  /**
   * Renderiza el formulario, y carga los datos del usuario segun la longitud
   */
  public renderizarFormulario(): void {
    if (this.datosSeguimientoUsuario.direccion.length >= 1) {
      this.tipoAccionDireccion = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDireccion = AccionEnum.CREAR;
    }
    if (this.datosSeguimientoUsuario.telefono.length >= 1) {
      this.tipoAccionTelefono = AccionEnum.EDITAR;
    } else {
      this.tipoAccionTelefono = AccionEnum.CREAR;
    }
    if (this.datosSeguimientoUsuario.personaRelacionadaUsuario.length >= 1) {
      this.tipoAccionFamiliarPapa = this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1) === undefined ? null : this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 1);
      this.tipoAccionFamiliarMama = this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2) === undefined ? null : this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 2);
      this.tipoAccionFamiliarTutor = this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3) === undefined ? null : this.datosSeguimientoUsuario.personaRelacionadaUsuario.find(x => x.tipoRelacionId === 3);
    } else {
      this.tipoAccionFamiliarPapa = null;
      this.tipoAccionFamiliarMama = null;
      this.tipoAccionFamiliarTutor = null;
    }
    if (this.datosSeguimientoUsuario.datoFiscal != null) {
      this.tipoAccionDatoFiscal = AccionEnum.EDITAR;
    } else {
      this.tipoAccionDatoFiscal = AccionEnum.CREAR;
    }
    this.inicializarForm();
  }

  /**
   * Inicia los formularios, checa si hay cambios en alguno de ellos 
   */
  public inicializarForm(): void {
    this.datosPersonalesForm = new FormGroup({
      usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
      nombre: new FormControl(this.datosSeguimientoUsuario.nombre),
      segundoNombre: new FormControl(this.datosSeguimientoUsuario.segundoNombre),
      apellidoPaterno: new FormControl(this.datosSeguimientoUsuario.apellidoPaterno),
      apellidoMaterno: new FormControl(this.datosSeguimientoUsuario.apellidoMaterno),
      fechaNacimiento: new FormControl(this.utileriasService.formatearFecha(this.datosSeguimientoUsuario.biografia.fechaNacimiento, 'YYYY[-]MM[-]DD')),
    });

    this.datosContactoForm = new FormGroup({
      usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
      tipoDireccionId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.direccion[0].tipoDireccionId, [Validators.required, this.validateId]),
      paisId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : 484, [Validators.required, this.validateId]),
      entidadFederativaId: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.direccion[0].entidadFederativaId, [Validators.required, this.validateId]),
      calle: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].calle, [Validators.required]),
      ciudad: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].ciudad, [Validators.required]),
      numeroExterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].numeroExterior, [Validators.required]),
      numeroInterior: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].numeroInterior),
      colonia: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].colonia, [Validators.required]),
      codigoPostal: new FormControl(this.tipoAccionDireccion === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.direccion[0].codigoPostal, [Validators.required]),
      tipoTelefonoId: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.telefono[0].tipoTelefonoId, [Validators.required, this.validateId]),
      numero: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.telefono[0].numero, [Validators.required]),
      extension: new FormControl(this.tipoAccionTelefono === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.telefono[0].extension, [Validators.required]),
      correo: new FormControl(this.datosSeguimientoUsuario.correo, [Validators.required, Validators.email]),
    });

    this.datosFamiliaresForm = new FormGroup({
      usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
      nombreCompletoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.nombreCompleto),
      telefonoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.telefono),
      correoElectronicoMadre: new FormControl(this.tipoAccionFamiliarMama === null ? '' : this.tipoAccionFamiliarMama.correoElectronico),
      nombreCompletoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.nombreCompleto),
      telefonoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.telefono),
      correoElectronicoPadre: new FormControl(this.tipoAccionFamiliarPapa === null ? '' : this.tipoAccionFamiliarPapa.correoElectronico),
      nombreCompletoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.nombreCompleto),
      telefonoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.telefono),
      correoElectronicoTutor: new FormControl(this.tipoAccionFamiliarTutor === null ? '' : this.tipoAccionFamiliarTutor.correoElectronico),
    });

    this.datosFacturacionForm = new FormGroup({
      usuarioId: new FormControl(this.datosSeguimientoUsuario.usuarioId),
      rfc: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.rfc),
      razonSocial: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.razonSocial),
      tipoDireccionFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.datoFiscal.tipoDireccionId),
      paisFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : 484),
      entidadFederativaFacturacionId: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? 0 : this.datosSeguimientoUsuario.datoFiscal.entidadFederativaId),
      calleFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.calle),
      ciudadFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.ciudad),
      numeroExteriorFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.numeroExterior),
      numeroInteriorFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.numeroInterior),
      coloniaFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.colonia),
      codigoPostalFacturacion: new FormControl(this.tipoAccionDatoFiscal === AccionEnum.CREAR ? '' : this.datosSeguimientoUsuario.datoFiscal.codigoPostal),
    });

    this.renderizarForm = true;
    this.datosContactoForm.valueChanges.subscribe(val => {
      this.datosContactoFormCambio = true;
      this.datosPersonalesFormValid = this.datosPersonalesForm.valid;
      this.datosFacturacionFormValid = this.datosFacturacionForm.valid;
      if (this.datosPersonalesFormValid && this.datosFacturacionFormValid) {
        this.nuevoIngresoService.definirFormularioValidoInscripcion(true);
      } else {
        this.nuevoIngresoService.definirFormularioValidoInscripcion(false);
      }
    });

    this.datosFacturacionForm.valueChanges.subscribe(val => {
      this.datosFacturacionFormCambio = true;
      this.datosPersonalesFormValid = this.datosPersonalesForm.valid;
      this.datosFacturacionFormValid = this.datosFacturacionForm.valid;
      if (this.datosPersonalesFormValid && this.datosFacturacionFormValid) {
        this.nuevoIngresoService.definirFormularioValidoInscripcion(true);
      } else {
        this.nuevoIngresoService.definirFormularioValidoInscripcion(false);
      }
    });

    this.onPaisSelected(484);
  }

  get tipoDireccionId() { return this.datosContactoForm.get('tipoDireccionId'); }
  get paisId() { return this.datosContactoForm.get('paisId'); }
  get entidadFederativaId() { return this.datosContactoForm.get('entidadFederativaId'); }
  get tipoTelefonoId() { return this.datosContactoForm.get('tipoTelefonoId'); }
  get ciudad() { return this.datosContactoForm.get('ciudad'); }
  get calle() { return this.datosContactoForm.get('calle'); }
  get numeroExterior() { return this.datosContactoForm.get('numeroExterior'); }
  get numeroInterior() { return this.datosContactoForm.get('numeroInterior'); }
  get colonia() { return this.datosContactoForm.get('colonia'); }
  get codigoPostal() { return this.datosContactoForm.get('codigoPostal'); }
  get numero() { return this.datosContactoForm.get('numero'); }
  get extension() { return this.datosContactoForm.get('extension'); }
  get correo() { return this.datosContactoForm.get('correo'); }

  get tipoDireccionFacturacionId() { return this.datosPersonalesForm.get('tipoDireccionFacturacionId'); }
  get paisFacturacionId() { return this.datosPersonalesForm.get('paisFacturacionId'); }
  get entidadFederativaFacturacionId() { return this.datosPersonalesForm.get('entidadFederativaFacturacionId'); }
  get ciudadFacturacion() { return this.datosPersonalesForm.get('ciudadFacturacion'); }
  get calleFacturacion() { return this.datosPersonalesForm.get('calleFacturacion'); }
  get numeroExteriorFacturacion() { return this.datosPersonalesForm.get('numeroExteriorFacturacion'); }
  get numeroInteriorFacturacion() { return this.datosPersonalesForm.get('numeroInteriorFacturacion'); }
  get coloniaFacturacion() { return this.datosPersonalesForm.get('coloniaFacturacion'); }
  get codigoPostalFacturacion() { return this.datosPersonalesForm.get('codigoPostalFacturacion'); }

  /**
   * Valida que los select no esten con valores de 0
   * @param control 
   * @returns 
   */
  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  /**
   * Funcion que detecta si los input tienen errores o son "tocados" para mandar un mensaje de que es requerido
   * @param controlName 
   * @param formName 
   * @returns 
   */
  public getErrorForm(controlName: string, formName: FormGroup): string {
    let error = '';
    const control = formName.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido.";
    }
    return error;
  }

  /**
   * Envia los datos encontrados en los formularios 
   */
  public enviarDatosPersonales(): void {
    let validarDatosContacto = Object.assign(this.datosContactoForm.value);
    this.nuevoIngresoService.obtenerCorreoOcupado(validarDatosContacto.correo, validarDatosContacto.usuarioId).subscribe(
      (respuestaCorreo: SimpleResponse) => {
        if (respuestaCorreo.success) {
          this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos personales. El correo ya se encuentra ocupado, por favor introduzca otro correo.');
        } else {
          let datosDatoFiscalForm = Object.assign(this.datosFacturacionForm.value);
          this.nuevoIngresoService.GetDatoFiscalByRFC(datosDatoFiscalForm.rfc, datosDatoFiscalForm.usuarioId).subscribe(
            (datoFiscal: ApiResponse<DatoFiscal>) => {
              if (datoFiscal.data != null) {
                this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos de facturacion. El RFC ingresado ya esta registrado, ingrese un RFC diferente.');
              } else {
                if (this.datosContactoFormCambio) {
                  let datosContactoForm = Object.assign(this.datosContactoForm.value);
                  let datosContacto: any = {
                    usuarioId: datosContactoForm.usuarioId,
                    correo: datosContactoForm.correo,
                    direccion: [
                      {
                        tipoDireccionId: datosContactoForm.tipoDireccionId,
                        entidadFederativaId: datosContactoForm.entidadFederativaId,
                        ciudad: datosContactoForm.ciudad,
                        calle: datosContactoForm.calle,
                        numeroExterior: datosContactoForm.numeroExterior,
                        numeroInterior: datosContactoForm.numeroInterior,
                        colonia: datosContactoForm.colonia,
                        codigoPostal: datosContactoForm.codigoPostal
                      }
                    ],
                    telefono: [
                      {
                        tipoTelefonoId: datosContactoForm.tipoTelefonoId,
                        paisId: datosContactoForm.paisId,
                        numero: datosContactoForm.numero,
                        extension: datosContactoForm.extension
                      }
                    ]
                  }
                  this.solicitudApoyoFinancieroService.registrarDatosContacto(datosContacto).subscribe(
                    (datosContacto: SimpleResponse) => {
                      if (datosContacto.success) {
                        this.datosContactoGuardado = true;
                        this.datosContactoFormCambio = false;
                      } else {
                        this.datosContactoGuardado = false;
                      }
                    }
                  )
                }
                if (this.datosFacturacionFormCambio) {
                  let datosDatoFiscalForm = Object.assign(this.datosFacturacionForm.value);
                  let datosDatoFiscal: any = {
                    UsuarioId: datosDatoFiscalForm.usuarioId,
                    Rfc: datosDatoFiscalForm.rfc,
                    RazonSocial: datosDatoFiscalForm.razonSocial,
                    EntidadFederativaId: datosDatoFiscalForm.entidadFederativaFacturacionId,
                    Calle: datosDatoFiscalForm.calleFacturacion,
                    Ciudad: datosDatoFiscalForm.ciudadFacturacion,
                    NumeroExterior: datosDatoFiscalForm.numeroExteriorFacturacion,
                    NumeroInterior: datosDatoFiscalForm.numeroInteriorFacturacion,
                    Colonia: datosDatoFiscalForm.coloniaFacturacion,
                    CodigoPostal: datosDatoFiscalForm.codigoPostalFacturacion,
                    Nombre: datosDatoFiscalForm.nombre,
                    ApellidoPaterno: datosDatoFiscalForm.apellidoPaterno,
                    ApellidoMaterno: datosDatoFiscalForm.apellidoMaterno
                  }
                  this.nuevoIngresoService.agregarDatosFiscal(datosDatoFiscal).subscribe(
                    (datoFiscal: SimpleResponse) => {
                      if (datoFiscal) {
                        this.datosFacturacionGuardado = true;
                        this.datosFacturacionFormCambio = false;
                      } else {
                        this.datosFacturacionGuardado = false;
                      }
                    });
                }
                if (this.datosContactoGuardado && this.datosFacturacionGuardado) {
                  this.snackService.mostrarSnackBack('Se ha guardado los datos del usuario.');
                  this.nuevoIngresoService.definirFormularioValidoInscripcion(false);
                } else {
                  this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar los datos del usuario. Por favor, intente de nuevo más tarde.');
                }
              }
            }
          );
        }
      });
  }

  /**
   * desuscribe el valor de todos los select encontrados en el formulario
   */
  ngOnDestroy(): void {
    if (this.subscriptionDatosSeguimiento) {
      this.subscriptionDatosSeguimiento.unsubscribe();
    }
    if (this.subscriptionTipoDireccion) {
      this.subscriptionTipoDireccion.unsubscribe();
    }
    if (this.subscriptionPais) {
      this.subscriptionPais.unsubscribe();
    }
    if (this.subscriptionEstado) {
      this.subscriptionEstado.unsubscribe();
    }
    if (this.subscriptionTipoTelefono) {
      this.subscriptionTipoTelefono.unsubscribe();
    }
  }
}
