import { Component, OnInit, ViewChild, TemplateRef, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { RequestCategoryDTO } from 'src/app/_models/solicitud-dto';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { AccionEnum } from 'src/app/_models/accion.enum';

@Component({
  selector: 'app-solicitud-categoria-modal-edicion',
  templateUrl: './solicitud-categoria-modal-edicion.component.html',
  styleUrls: ['./solicitud-categoria-modal-edicion.component.scss']
})
export class SolicitudCategoriaModalEdicionComponent implements OnInit {
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<'dialogAdvertencia'>;
  public errorActualizaRequest: string = "";
  public requestCategoryId: number = 0;
  public dialogoRefAdver: any;
  public boolCambio = false;
  public edicionForm: FormGroup;
  public categorias: RequestCategoryDTO;

  constructor(public dialogRef: MatDialogRef<SolicitudCategoriaModalEdicionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RequestCategoryDTO,
    private solicitudService: SolicitudService,
    private snackService: SnackService,
    private dialog: MatDialog) { 
      this.boolCambio = false;
      this.inicializarForm();
    }

  ngOnInit(): void {
  }

  public inicializarForm() {
    this.edicionForm = new FormGroup({
      requestCategoryID: new FormControl(this.data?.requestCategoryId),
      code: new FormControl(this.data?.code, [Validators.required]),
      name: new FormControl(this.data?.name, [Validators.required]),
      status: new FormControl(this.data?.status, [Validators.required])
    });
    this.edicionForm.valueChanges.subscribe((cambios) => {
      this.boolCambio = true;
    });
  }

  get code() { return this.edicionForm.get('code'); }
  get name() { return this.edicionForm.get('name'); }
  get status() { return this.edicionForm.get('status'); }

  public cambiarVistaEditar(requestCategoryID: number) {
    this.boolCambio = false;
    this.data = Object.assign(this.edicionForm.value);
    this.data.tipoAccion = 2;
  }

  public enviar(): void {
    switch(this.data.tipoAccion) {
      case AccionEnum.CREAR:
        this.data = Object.assign(this.edicionForm.value);
        this.solicitudService.agregarSolicitudCategoria(this.data).subscribe(
          (idRequest) => {
            if (idRequest > 0) {
              this.solicitudService.invocarMetodoObtenerCategoria();
              this.snackService.mostrarSnackBack('Se ha guardado la información');
              this.boolCambio = false;
              this.cambiarVistaEditar(idRequest);
              this.inicializarForm();
            } else {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la categoría de solicitud. Por favor, intente de nuevo más tarde.')
              this.data.tipoAccion = 3;
            }
          }, error => {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la categoría de solicitud. Por favor, intente de nuevo más tarde.');
          }
        );
      break;
      case AccionEnum.EDITAR:
        this.data = Object.assign(this.edicionForm.value);
        this.data.tipoAccion = 2;
        this.solicitudService.modificarSolicitudCategoria(this.data).subscribe(
          (editado: boolean) => {
            if (editado) {
              this.solicitudService.invocarMetodoObtenerCategoria();
              this.snackService.mostrarSnackBack('Se ha editado la información');
              this.boolCambio = false;
            } else {
              this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la categoría de solicitud. Por favor, intente de nuevo más tarde.');
            }
          }, error => {
            this.snackService.mostrarSnackBack('Ha ocurrido un error al guardar la categoría de solicitud. Por favor, intente de nuevo más tarde.');
          }
        );
      break;
    }
  }

  public cerrarModalValidacion() {
    if(this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close(null);
    }
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(this.data);
    } else {
      this.dialogoRefAdver.close();
    }
  }
}
