import { Component, OnInit, Inject, TemplateRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Profesor } from '../../../../_models/profesor';
import { TipoContrato } from '../../../../_models/tipo-contrato';
import { ProfesorService } from '../../../../_services/profesor.service';
import { TipoContratoService } from '../../../../_services/tipo-contrato.service';
import { UsuarioService } from '../../../../_services/usuario.service';
import { Departamento } from '../../../../_models/departamento';
import { DepartamentoService } from '../../../../_services/departamento.service';
import { DatoFiscal } from '../../../../_models/dato-fiscal';
import { DatoFiscalService } from '../../../../_services/dato-fiscal.service';


@Component({
  selector: 'app-form-datos-laborales',
  templateUrl: './form-datos-laborales.component.html',
  styleUrls: ['./form-datos-laborales.component.scss']
})
export class FormDatosLaboralesComponent implements OnInit {
  @Input() datosUsuarioChild: any;
  @Input() usuarioId: number;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>
  public errorActualizaProfesor = '';
  public profesorId = 0;
  public objValidacionCancelar: Profesor = null;
  public dialogRefAdver: any;
  public laboralForm: FormGroup;
  public boolCambio = false;
  public tipoAccion: number;
  public tipoContratoList: TipoContrato[] = [];
  public departamentosList: Departamento[] = [];
  public RFCencontrado: boolean = false;

  constructor(public dialogProfesorRef: MatDialogRef<FormDatosLaboralesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Profesor,
    private profesorService: ProfesorService,
    private departamentoService: DepartamentoService,
    private datoFiscalService: DatoFiscalService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioService,
    private tipoContratoService: TipoContratoService
  ) {
    //this.profesorId = this.data.UsuarioId;
  }

  ngOnInit(): void {
    this.inicializarForm();
    if (this.usuarioId) {
      this.profesorService.obtenerProfesoresByUsuario(this.usuarioId).subscribe(datosUsuarioLaboral => {
        this.laboralForm.get('usuarioID').setValue(datosUsuarioLaboral.usuarioId);
        this.laboralForm.get('DepartamentoId').setValue(datosUsuarioLaboral.departamentoId);
        this.laboralForm.get('TipoContratoId').setValue(datosUsuarioLaboral.departamentoId);
        this.laboralForm.get('CuentaDeposito').setValue(datosUsuarioLaboral.cuentaDeposito);
        this.datoFiscalService.GetDatoFiscalByUsuarioId(this.usuarioId).subscribe(datosUsuarioDatoFiscal =>{
          this.laboralForm.get('rfc').setValue(datosUsuarioDatoFiscal.rfc);
        })
      })
    }
  }

  public cambiarVistaEditar(idProfesor: number) {
    this.data.tipoAccion = 2;
    this.profesorId = idProfesor;
  }

  public cambiarVistaEliminar() {
    this.data.tipoAccion = 4;
  }

  public inicializarForm() {
    this.getDepartamentos();
    this.getTipoContrato();
    this.laboralForm = new FormGroup({
      usuarioID: new FormControl(null),
      TipoContratoId: new FormControl(null, [Validators.required]),
      DepartamentoId: new FormControl('', [Validators.required]),
      //contratacion: new FormControl(null, [Validators.required]),
      rfc: new FormControl('', [Validators.required]),
      CuentaDeposito: new FormControl('', [Validators.required])
    });

    this.laboralForm.valueChanges.subscribe(() => {
      this.boolCambio = true;
    })
  }

  public getTipoContrato() {
    this.tipoContratoService.obtenerTipoContrato().subscribe(
      (tipoContrato: TipoContrato[]) => {
        this.tipoContratoList = tipoContrato;
      }
    );
  }

  public getDepartamentos() {
    this.departamentoService.obtenerDepartamentos().subscribe(
      (departamentos: Departamento[]) => {
        this.departamentosList = departamentos;
      }
    );
  }

  //Validar si el RFC ya esta relacionado
  public RFCInsert(rfc: string) {
    if (rfc.trim().length == 13) {
      let usuarioId = this.usuarioId;
      if(!usuarioId){
        usuarioId = 0;
      }

      this.datoFiscalService.GetDatoFiscalByRFC(rfc,usuarioId).subscribe(
        datoFiscal => {
          if (datoFiscal == null) {
            return this.RFCencontrado = false;
          } else {
            return this.RFCencontrado = true;
          }
        }
      )
    }
  }

  get contrato() { return this.laboralForm.get('TipoContratoId'); }
  get departamento() { return this.laboralForm.get('DepartamentoId'); }
  //get contratacion() { return this.laboralForm.get('contratacion'); }
  get rfc() { return this.laboralForm.get('rfc'); }
  get cuenta() { return this.laboralForm.get('CuentaDeposito'); }

  public cerrarModalValidacion() {
    if (this.boolCambio) {
      this.dialogRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogProfesorRef.close(null);
    }
  }

  public enviar(): void {
    const datoFiscal: DatoFiscal = new DatoFiscal();
    datoFiscal.usuarioId = this.datosUsuarioChild.usuarioId;
    datoFiscal.rfc = this.rfc.value;

    this.laboralForm.controls.usuarioID.setValue(this.datosUsuarioChild.usuarioId);
    this.data = Object.assign(this.laboralForm.value);
    this.profesorService.agregarProfesor(this.data).subscribe(
      _profesor => {
        if (_profesor) {
          this.datoFiscalService.agregarDatoFiscal(datoFiscal).subscribe(_datoFiscal => {
            if (_datoFiscal) {
              this.snackBar.open("Se ha guardado la información.", "OK", { duration: 5000 });
            } else {
              this.snackBar.open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo m�s tarde.", "OK", { duration: 5000 });
            }
          })
        } else {
          this.snackBar.open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo m�s tarde.", "OK", { duration: 5000 });
        }
      }, error => {
        this.snackBar.open("Ha ocurrido un error al guardar la información. Por favor, intente de nuevo m�s tarde.", "OK", { duration: 5000 });
      }
    );
  }

  public editar(): void {
    const datoFiscal: DatoFiscal = new DatoFiscal();
    datoFiscal.usuarioId = this.usuarioId;
    datoFiscal.rfc = this.rfc.value;

    this.data = Object.assign(this.laboralForm.value);
    this.profesorService.modificarProfesor(this.data).subscribe(
      _profesor => {
        if (_profesor) {
          this.datoFiscalService.modificarDatoFiscal(datoFiscal).subscribe(_datoFiscal => {
            if (_datoFiscal) {
              this.snackBar.open("Se ha guardado la informaci�n.", "OK", { duration: 5000 });
            } else {
              this.snackBar.open("Ha ocurrido un error al guardar la informaci�n. Por favor, intente de nuevo m�s tarde.", "OK", { duration: 5000 });

            }
          });

        } else {
          this.snackBar.open("Ha ocurrido un error al guardar la informaci�n. Por favor, intente de nuevo m�s tarde.", "OK", { duration: 5000 });
        }
      }, error => {
        this.snackBar.open("Ha ocurrido un error al guardar la informaci�n. Por favor, intente de nuevo m�s tarde.", "OK", { duration: 5000 });
      }
    );
  }



}
