import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { MakeUpExamTrakingTableComponent } from '../make-up-exam/make-up-exam-traking-table/make-up-exam-traking-table.component'

const routes: Routes = [
  { path: 'extraordinario', component: MakeUpExamTrakingTableComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MakeUpExamRoutingModule { }
