import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { UtileriasService } from '../_services/utilerias.service';
import { DetalleCarteraComponent } from './detalle-cartera/detalle-cartera.component';
import { PeriodoService } from '../_services/periodo.service';
import { ProgramaAcademicoService } from '../_services/programa-academico.service';
import { CampusService } from '../_services/campus.service';
import { SeguimientoCarteraService } from '../_services/seguimiento-cartera.service';
import { Periodo } from '../_models/periodo';
import { Programa, ProgramaActivo } from '../_models/programa';
import { Campus } from '../_models/campus';
import { MatOption } from '@angular/material/core';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from '../services/generacion-excel-service.service';
import { EstatusSeguimiento, SeguimientoCartera } from '../_models/seguimiento-cartera';
import { EnviarCorreoComponent } from '../_components/enviar-correo/enviar-correo.component';
import { MatDialog } from '@angular/material/dialog';
import { TraductorService } from '../_services/traductor.service';

@Component({
  selector: 'app-seguimiento-cartera',
  templateUrl: './seguimiento-cartera.component.html',
  styleUrls: ['./seguimiento-cartera.component.scss']
})
export class SeguimientoCarteraComponent implements OnInit {

  public datasourseSeguimiento = new MatTableDataSource();
  private periodosactivos: number[] = [];
  public datafilter : any[] = [];
  private arrOriginal: SeguimientoCartera[] = [];
  public periodoF = new FormControl();
  public programasF = new FormControl();
  public campusF = new FormControl();
  public idnombre = new FormControl();
  public estatusF = new FormControl();
  public numselectedAlumnos = 0;
  public Periodos: Periodo[] = [];
  public Programas: ProgramaActivo[] = [];
  public ArCampus: Campus[] = [];
  public arrFiltrado: any[] = [];
  public EstatusC: Array<EstatusSeguimiento> = [{ nombre: 'Vencida', valor: 1 }, { nombre: 'Vigente', valor: 2 }];
  public ColumnasSeguimiento: string[] = ['select', 'ID', 'Nombre', 'Periodo','Programa', 'Campus', 'Estatus', 'Saldo_Vencido', 'Saldo_PorVencer', 'Saldo_Total'];
  public selectionSeguimiento = new SelectionModel(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo:MatOption;
  @ViewChild('allPrograma') private allPrograma:MatOption;
  @ViewChild('allCampus') private allCampus:MatOption;

  constructor(
    private paginador: MatPaginatorIntl,
    private contador: MatPaginatorIntl,
    public util: UtileriasService,
    public periodoservice: PeriodoService,
    public programaservice: ProgramaAcademicoService,
    public campusservice: CampusService,
    public seguimientocarteraservice: SeguimientoCarteraService,
    private generadorExcel: GeneracionExcelServiceService,
    private dialog: MatDialog,
    private traductorService: TraductorService) {
  }

  /**
   * Inicia el componente, el paginador y los filtros de la tabla principal
   */
  ngOnInit(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";

    this.contador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };

    this.LlenarFiltros();
    this.LimpiarFiltros();
    if(this.seguimientocarteraservice.invMetodoSubscription == undefined){
      this.seguimientocarteraservice.invMetodoSubscription = this.seguimientocarteraservice.invocarObtenerSeguimientoCartera.subscribe(()=>{
        this.InicializarTabla();
      })
    }
    this.InicializarTabla();

  }

  /**
   * Abre el modal segun el tab que se le mande
   * @param elemento 
   * @param tabRequerido 
   */
  public detalleCartera(elemento: SeguimientoCartera, tabRequerido: string): void {
    elemento.tabRequerido = tabRequerido;
    this.util.abrirDialogoLateral(DetalleCarteraComponent, elemento);
  }

  /**
   * Llena los select de filtros y selecciona los periodos que estan activos
   * para mostrar esos en la tabla
   */
  public LlenarFiltros(): void {
    let today = new Date();
    this.periodoservice.obtenerPeriodos().subscribe(
      (periodos: Periodo[]) => {
        this.Periodos = periodos;
        for (let x of periodos) {
          let inicio = new Date(x.fechaInicio);
          let fin = new Date(x.fechaFin);
          if (inicio < today && fin > today) {
            this.periodosactivos.push(x.periodoId);
          }
        }
        if(this.periodosactivos.length > 0){
          this.periodoF.setValue([...this.periodosactivos]);
        }
        this.filtro();
      }
    );
    this.programaservice.obtenerProgramasActivos().subscribe(
      (programas: ApiResponse<ProgramaActivo[]>) => {
        this.Programas = programas.data;
        this.programasF.setValue([...this.Programas.map(item => item.programaId), 0]);
        this.filtro();
      }
    );
    this.campusservice.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.ArCampus = campus;
        this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
        this.filtro();
      }
    )
  }

  /**
   * Inicia la tabla principal agregando la informacion y el paginador 
   */
  public InicializarTabla(): void {
    this.seguimientocarteraservice.getSeguimientoCartera().subscribe(
      (seguimiento: ApiResponse<SeguimientoCartera[]>) => {
        this.arrOriginal = seguimiento.data;
        this.datasourseSeguimiento.paginator = this.paginator;
        this.filtro();
      }
    )
  }

  /**
   * Funcion que sirve para enviar correos a los alumnos y checando si es un solo candidato 
   * o son varios segun la seleccion 
   * @param btnDataTable 
   * @param elemento 
   */
  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }
    const dialogo = this.dialog.open(EnviarCorreoComponent, {
      data: datos,
      panelClass: "dialogo-enviar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }

  /**
   * Funcion que busca de la tabla mediante el nombre o el identificador del alumno
   * @param filterValue 
   */
  buscarNombreIdentificador(filterValue: string): void {
    filterValue = filterValue.toLowerCase();
    if(filterValue != ""){            
      this.datasourseSeguimiento.filter = filterValue.trim().toLowerCase();
      
      this.datasourseSeguimiento.paginator = this.paginator;            
    }
  }

  /**
   * Funcion que busca de la tabla principal aquellos datos que concuerden con
   * el estatus, el periodo, el nombre, el campus o el programa segun lo
   * requiera el usuario administrador
   */
  public filtro(): void {
    let estatus: number = this.estatusF.value == null?0:this.estatusF.value;
    let periodo: Periodo[] = this.periodoF.value == null?[]:this.periodoF.value;
    let nombre:string = this.idnombre.value == null?"":this.idnombre.value;
    let campus: Campus[] = this.campusF.value == null?[]:this.campusF.value;
    let programa: Programa[] = this.programasF.value == null?[]:this.programasF.value;
    let filtroModificado:any = [];
    filtroModificado = [...this.arrOriginal];
    this.arrFiltrado = filtroModificado;
    if (estatus !== 0) {
      let estatusV;
      if(estatus == 1){
        let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-1');
        estatusV = mensaje;
      } else {
        let mensaje: string = this.traductorService.translate('_catalogos.cartera.nombre-2');
        estatusV = mensaje;
      }
      filtroModificado = filtroModificado.filter(f => { return f.estatus.toLowerCase() === estatusV });
    }
    if (periodo.length > 0) {
      filtroModificado = filtroModificado.filter(f => { return periodo.indexOf(f.id_Periodo) > -1 });
    }
    if (nombre.length > 0 || nombre !== '') {
      filtroModificado = filtroModificado.filter(el => {
        return el.nombre.toLowerCase().search(nombre.toLowerCase()) > -1 || el.data.identificador.toString().search(nombre) > -1;
      })
    }
    if (campus.length > 0) {
      filtroModificado = filtroModificado.filter(f => { return campus.indexOf(f.id_Campus) > -1 });
    }
    if (programa.length > 0) {
      filtroModificado = filtroModificado.filter(f => { return programa.indexOf(f.id_Programa) > -1 });
    }
    this.datasourseSeguimiento = new MatTableDataSource(filtroModificado);
    this.datasourseSeguimiento.paginator = this.paginator;
    this.datafilter = filtroModificado;
  }

  /**
   * Funcion que limpia o vuelve a los select a su estado original 
   */
  LimpiarFiltros(): void {
    this.datasourseSeguimiento = new MatTableDataSource(this.arrFiltrado);
    this.datasourseSeguimiento.paginator = this.paginator;
    this.periodoF.setValue([...this.periodosactivos]);
    this.programasF.setValue([...this.Programas.map(item => item.programaId), 0]);
    this.campusF.setValue([...this.ArCampus.map(item => item.campusId), 0]);
    this.estatusF.setValue(0)
    this.idnombre.setValue("");
    this.datafilter = this.arrFiltrado;
  }

  /**
   * Funcion que detecta la seleccion del usuario con los datos registrados y verifica si todos estan
   * seleccionados para modificar el check
   * @returns boolean para conocer si todos estan seleccionados
   */
  isAllSelected(): boolean {
    const numSelected = this.selectionSeguimiento.selected.length;
    const numRows = this.datasourseSeguimiento.data.length;
    return numSelected === numRows;
  }

  /** 
   * Selecciona todas las columnas si no lo estan, o bien limpia la seleccion
   */
  masterToggle(): void {
    this.isAllSelected() ?
      this.selectionSeguimiento.clear() :
      this.datasourseSeguimiento.data.forEach(row => this.selectionSeguimiento.select(row));
  }

  /** 
   * La etiqueta de la casilla de verificación de la fila pasada
  */
  checkboxLabel(row?: SeguimientoCartera): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    this.numselectedAlumnos = this.selectionSeguimiento.selected.length;
    return `${this.selectionSeguimiento.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

    /**
     * Verifica si el campo Todos del select Periodo esta seleccionados, si lo esta 
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param all 
     * @returns 
     */
    tosslePerOnePeriodo(all): boolean { 
      if (this.allPeriodo.selected) {  
        this.allPeriodo.deselect();
        return false;
      }
      if(this.periodoF.value.length == this.Periodos.length){this.allPeriodo.select();}
      this.filtro();
    }
 
    /**
     * Verifica si el campo Todos del select Programa esta seleccionados, si lo esta 
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param all 
     * @returns 
     */
    tosslePerOnePrograma(all): boolean{ 
      if (this.allPrograma.selected) {  
        this.allPrograma.deselect();
        return false;
      }
      if(this.programasF.value.length == this.Programas.length){this.allPrograma.select();}
      this.filtro();
    }

    /**
     * Verifica si el campo Todos del select Campus esta seleccionados, si lo esta 
     * quita la seleccion de todos los campos, sino los selecciona todos
     * @param all 
     * @returns 
     */
    tosslePerOneCampus(all): boolean{ 
      if (this.allCampus.selected) {  
        this.allCampus.deselect();
        return false;
      }
      if(this.campusF.value.length == this.ArCampus.length){this.allCampus.select();}
      this.filtro();
    }

    /**
     * Si solo un dato del select Periodo esta seleccionado busca solo esa informacion y la 
     * muestra en la tabla principal
     */
    toggleAllSelectionPeriodo(): void {
      if (this.allPeriodo.selected) {
        this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
      } else {
        this.periodoF.patchValue([]);
      }
      this.filtro();
    }

    /**
     * Si solo un dato del select Programa esta seleccionado busca solo esa informacion y la 
     * muestra en la tabla principal
     */
    toggleAllSelectionPrograma(): void {
      if (this.allPrograma.selected) {
      this.programasF.patchValue([...this.Programas.map(item => item.programaId), 0]);
    } else {
      this.programasF.patchValue([]);
    }
    this.filtro();
    }

    /**
     * Si solo un dato del select Campus esta seleccionado busca solo esa informacion y la 
     * muestra en la tabla principal
     */
    toggleAllSelectionCampus(): void {
    if (this.allCampus.selected) {
      this.campusF.patchValue([...this.ArCampus.map(item => item.campusId), 0]);
    } else {
      this.campusF.patchValue([]);
    }
    this.filtro();
    }

    /**
     * Funcion que obtiene los registros filtrados de la tabla principal y los pasa al back
     * para convertir esos datos a un archivo Excel
     * @param elemento 
     */
    public descargarExcel(elemento?: SeguimientoCartera[]): void {
      let IdAlumnos;   
      if (elemento != null && elemento.length > 0) {      
        IdAlumnos = elemento.map(m => m.identificador.toString());
      }else
      {
        IdAlumnos = this.datafilter.map(m => m.identificador.toString());
      }
      this.seguimientocarteraservice.descargarExcel(IdAlumnos).subscribe((blob: Blob) => {       
        this.generadorExcel.GenerarExcel(blob, "SeguimientoCartera");    
      });
    }
}
