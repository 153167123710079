import { MaterialModule } from './../../../modules/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviousStudiesInformationFormComponent } from './previous-studies-information-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { I18NextModule } from 'angular-i18next';



@NgModule({
  declarations: [
    PreviousStudiesInformationFormComponent
  ],
  imports: [
    CommonModule, 
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    I18NextModule.forRoot(),
  ],
  exports:[
    PreviousStudiesInformationFormComponent
  ]
})
export class PreviousStudiesInformationFormModule { }
