import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { OfertaProfesorCorreoComponent } from './../../../oferta-academica/oferta-academica-profesor/oferta-profesor-correo/oferta-profesor-correo.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { EnviarCorreoComponent } from 'src/app/_components/enviar-correo/enviar-correo.component';
import { Campus } from 'src/app/_models/campus';
import { Periodo } from 'src/app/_models/periodo';
import { ProfesotDTO } from 'src/app/_models/ProfesotDTO';
import { CampusService } from 'src/app/_services/campus.service';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { ProfesorService } from 'src/app/_services/profesor.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { ProfesorModalComponent } from '../profesor-modal/profesor-modal.component';

@Component({
  selector: 'app-profesor-seguimiento',
  templateUrl: './profesor-seguimiento.component.html',
  styleUrls: ['./profesor-seguimiento.component.scss']
})
export class ProfesorSeguimientoComponent implements OnInit, OnDestroy {

  //#region Ciclo de vida
  constructor(
    private _ProfesorService: ProfesorService,
    private _MatDialog: MatDialog,
    public _UtileriasService: UtileriasService,
    private periodoService: PeriodoService,
    private campusService: CampusService,
    private generadorExcel: GeneracionExcelServiceService,
    ) { }

  ngOnInit(): void {
    this.LlenarFiltros();
  }

  ngOnDestroy(): void {
    if ( this.Subscription ) {
      this.Subscription.unsubscribe();
    }
  }
  //#endregion

  //#region Subscription
  private Subscription: Subscription = new Subscription();
  //#endregion

  //#region Propiedades
  public Profesores: ProfesotDTO[];
  public filtroForm: FormGroup;
  public today = new Date();
  public Periodos: Periodo[];
  public Campus: Campus[] = [];
  public numProfesorSeleccionado: number = 0;
  public datafilter: any;
  private arrOriginal: ProfesotDTO[] = [];
  private periodosactivos: any[] = [];
  //#endregion

  //#region MatTable
  public dataSource: MatTableDataSource<ProfesotDTO>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    'select',
    'identificador',
    'nombre',
    'periodoClave',
    'campusClave',
    'noGrupos',
    'noAlumnos'
  ];
  //#endregion

  //#region ViewChild
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPeriodo') private allPeriodo: MatOption;
  @ViewChild('allCampus') private allCampus: MatOption;
  //#endregion

  //#region  FormControls
  periodoF = new FormControl();
  NombreF = new FormControl();
  CampusF = new FormControl();
  //#endregion

  //#region FormFiltros
  public inicializarForm() {
    this.filtroForm = new FormGroup({
      periodoF: new FormControl([]),
      CampusF: new FormControl([])
    });
    this.periodoF.setValue(["-1"]);
    this.CampusF.setValue(["-1"]);
    this.NombreF.setValue("");
  }

  /************************************************* */
  toggleAllSelectionPeriodo() {
    if (this.allPeriodo.selected) {
      this.periodoF.patchValue([...this.Periodos.map(item => item.periodoId), 0]);
    } else {
      this.periodoF.patchValue([]);
    }
    this.filtro();
  }
  tosslePerOnePeriodo(all) {
    if (this.allPeriodo.selected) {
      this.allPeriodo.deselect();
      return false;
    }
    if (this.periodoF.value.length == this.Periodos.length) { this.allPeriodo.select(); }
    this.filtro();
  }
/************************************************ */
  toggleAllSelectionCampus() {
    if (this.allCampus.selected) {
      this.CampusF.patchValue([...this.Campus.map(item => item.campusId), 0]);
    } else {
      this.CampusF.patchValue([]);
    }
    this.filtro();
  }
  tosslePerOneCampus(all) {
    if (this.allCampus.selected) {
      this.allCampus.deselect();
      return false;
    }
    if (this.CampusF.value.length == this.Campus.length) { this.allCampus.select(); }
    this.filtro();
  }

  filtro(){

    let periodo: any[] = this.periodoF.value == null ? [] : this.periodoF.value;
    let campus: any[] = this.CampusF.value == null ? [] : this.CampusF.value;


    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];


    filtroModificado = filtroModificado.filter(f => { return periodo.indexOf(f.periodoId) > -1 });
    filtroModificado = filtroModificado.filter(f => { return campus.indexOf(f.campusId) > -1 });

    this.dataSource = new MatTableDataSource(filtroModificado);
    this.dataSource.paginator = this.paginator;
    this.datafilter = filtroModificado;
    this.selection = new SelectionModel<any>(true, []);
  }

  buscarNombreId(filterValue: string) {
    filterValue = filterValue.toLowerCase();
    if(filterValue != ""){
      this.dataSource.filter = filterValue;
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
        var nombre = data.nombre;
        return nombre.toLowerCase().includes(filter) || data.identificador.toString().includes(filter);
      };
      this.dataSource.paginator = this.paginator;
    }
  }
  public LimpiarFiltros() {
    this.inicializarForm();
    this.LlenarFiltros();
    this.dataSource = new MatTableDataSource(this.arrOriginal);
    this.dataSource.paginator = this.paginator;
  }
  //#endregion

  //#region LlenarFiltros
  public LlenarFiltros(): void
  {
    this.ObtenerProfesoresInfo();
    this.inicializarForm();
    this.ObtenerPeriodos();
    this.ObtenerCampus();
    this.filtro();
  }
  public ObtenerPeriodos(): void
  {
    this.Subscription.add(
      this.periodoService.obtenerPeriodos().subscribe(
        (periodos: Periodo[]) => {
          this.Periodos = periodos;
          for (let x of periodos) {
            let inicio = new Date(x.fechaInicio);
            let fin = new Date(x.fechaFin);
            if (inicio < this.today && fin > this.today) {
              this.periodosactivos.push(x.periodoId);
            }
          }
          if(this.periodosactivos.length > 0){
            this.periodoF.setValue([...this.periodosactivos]);
          }
        }
      )
    );

  }
  public ObtenerCampus():void
  {
    this.Subscription.add(
      this.campusService.obtenerCampus().subscribe(
        (campus : Campus[]) => {
          this.Campus = campus;
          this.CampusF.setValue([...this.Campus.map(item => item.campusId), 0]);
        }
      )
    );
  }
  //#endregion

 //#region Metodos
 public ObtenerProfesoresInfo():void{
  this.Subscription.add(
    this._ProfesorService.ObtenerProfesoresInfo().subscribe(
      (respuesta: ApiResponse<ProfesotDTO[]>) => {
        if (respuesta.success) {
          this.arrOriginal = [...respuesta.data];
          this.Profesores = respuesta.data;
          this.dataSource = new MatTableDataSource(this.Profesores);
          this.dataSource.paginator = this.paginator;
          this.datafilter = this.Profesores;
         } else {
          console.log(respuesta.message);
         }
      }
    )
  );
}
 //#endregion

 //#region  Selecction

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }
  isAllSelected() {
    if (this.datafilter) {
    this.numProfesorSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numProfesorSeleccionado === numRows;
    }
  }

  checkboxLabel(row?: ProfesotDTO): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.usuarioId + 1}`;
  }
//#endregion

 //#region Extras

 public descargarExcel(elemento?: any) {
  let Grupo;
  if (elemento != null && elemento.length > 0) {
    Grupo = elemento;
  }else
  {
    Grupo = this.datafilter;
  }
  this.Subscription.add(
    this._ProfesorService.DownloadExcelProfesorInfo(Grupo).subscribe((blob: Blob) => {
    this.generadorExcel.GenerarExcel(blob, "Profesores");
  }));
}

  public enviarCorreo(btnDataTable: boolean, elemento?: any): void {
    if (btnDataTable) {
      var datos = elemento;
    } else {
      if (elemento.length === 1) {
        var datos = elemento[0];
      } else {
        var datos = elemento;
      }
    }

    const dialogo = this._MatDialog.open(OfertaProfesorCorreoComponent, {
      data: datos,
      panelClass: "dialogo-enviar",
      width: '50%',
      height: 'auto',
      maxHeight: '80vh',
      disableClose: true
    });
  }
 //#endregion

 //#region AbrirModalLateral

 public AbrirModalLateral(solicitud: any, solicitudCargaDoc: boolean) {

  this.Subscription.add(
    this._ProfesorService.obtenerProfesoresByUsuario(solicitud.usuarioId).subscribe(
      (respuesta: any) => {
        respuesta.solicitudCargaDoc = solicitudCargaDoc;
        this._UtileriasService.abrirDialogoLateral(ProfesorModalComponent, respuesta);
      }
    )
  );
}
 //#endregion
}
