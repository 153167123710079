import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { Campus } from 'src/app/_models/campus';
import { Periodo } from 'src/app/_models/periodo';
import { Programa } from 'src/app/_models/programa';
import { CampusService } from 'src/app/_services/campus.service';
import { PeriodoService } from 'src/app/_services/periodo.service';
import { UsuarioService } from 'src/app/_services/usuario.service';

@Component({
  selector: 'app-usuarios-registrar',
  templateUrl: './usuarios-registrar.component.html',
  styleUrls: ['./usuarios-registrar.component.scss']
})
export class UsuariosRegistrarComponent implements OnInit {

  public nuevoSolicitudForm: FormGroup;
  public nuevoSolicitudUsuarioForm: FormGroup;
  public renderizarFormulario: boolean = false;
  public correoRegistro: any;
  public nombreCompletoUsuario: string;
  public datosUsuarioBusqueda$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public subscritpionCampus: Subscription;
  public subscriptionPeriodo: Subscription;
  public subscriptionPrograma: Subscription;
  public campusList: Campus[] = [];
  public periodoList: Periodo[] = [];
  public programaList: Programa[] = [];
  public tipoAccion: number;
  public utileriasService: any;
  public formularioProfesor: boolean = false;
  public passwordTipoTexto: boolean = false;
  public passwordCTipoTexto: boolean = false;
  public usuarioId: number;

  constructor(
    public dialogRef: MatDialogRef<UsuariosRegistrarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private campusService: CampusService,
    private periodoService: PeriodoService,
    private usuarioService: UsuarioService,
  ) { }

  ngOnInit(): void {
      this.getTipoCampus();
      this.getTipoPeriodo();
      this.datosUsuarioBusqueda$ = this.usuarioService.obtenerDatosUsuarioBusqueda$();
      this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
  }

  public recibirInfo(correo: any) {
    this.tipoAccion = AccionEnum.CREAR;
    this.correoRegistro = correo;
    this.inicializarForm();
  }

  public recibirInfoProfesor(correo: any, profesor: boolean) {
    this.tipoAccion = AccionEnum.EDITAR;
    this.correoRegistro = correo;
    this.formularioProfesor = profesor;
    this.nombreCompletoUsuario = this.datosUsuarioBusqueda.nombre + " " +this.datosUsuarioBusqueda.apellidoPaterno + " " + this.datosUsuarioBusqueda.apellidoMaterno;
    this.inicializarFormProfesor();
  }

  public inicializarForm() {
    this.nuevoSolicitudForm = new FormGroup({
      correo: new FormControl(this.correoRegistro, [Validators.required, Validators.email]),
      nombre: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.nombre, [Validators.required]),
      segundoNombre: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.segundoNombre, [Validators.required]),
      apellidoPaterno: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : this.datosUsuarioBusqueda.apellidoMaterno),
      fechaNacimiento: new FormControl(this.tipoAccion === AccionEnum.CREAR ? '' : '', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()¡¿=|?]/), this.parameters]),
      passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/), Validators.pattern(/[!.@#$%^&*()¡¿=|?]/), this.parameters]),
      estatus: new FormControl(this.data?.estatus === true ? true : false),
      aceptaTerminos: new FormControl(false, [Validators.required]),
    });
    this.renderizarFormulario = true;
    this.nuevoSolicitudForm.valueChanges.subscribe(val => {
      this.usuarioService.definirFormularioValidoNuevaSolicitud(this.nuevoSolicitudForm.valid);
    });
  }
  
  public inicializarFormProfesor() {
    this.nuevoSolicitudUsuarioForm = new FormGroup({
      usuarioId: new FormControl(this.datosUsuarioBusqueda.usuarioId),
      nombreCompleto: new FormControl(this.nombreCompletoUsuario),
      correoUsuario: new FormControl(this.correoRegistro),
      programaAcademicoUsuario: new FormControl(0, [Validators.required, this.validateId]),
      periodoUsuario: new FormControl(0, [Validators.required, this.validateId]),
      campusUsuario: new FormControl(0, [Validators.required, this.validateId]),
    });
    this.renderizarFormulario = true;

    this.nuevoSolicitudUsuarioForm.valueChanges.subscribe(val => {
     this.usuarioService.definirFormularioValidoNuevaSolicitud(this.nuevoSolicitudUsuarioForm.valid);
    });
  }


  get correo() { return this.nuevoSolicitudForm.get('correo'); }
  get nombre() { return this.nuevoSolicitudForm.get('nombre'); }
  get segundoNombre() { return this.nuevoSolicitudForm.get('segundoNombre'); }
  get apellidoPaterno() { return this.nuevoSolicitudForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.nuevoSolicitudForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.nuevoSolicitudForm.get('fechaNacimiento'); }
  get password() { return this.nuevoSolicitudForm.get('password'); }
  get passwordConfirm() { return this.nuevoSolicitudForm.get('passwordConfirm'); }
  get estatus() { return this.nuevoSolicitudForm.get('estatus'); }
  get programaAcademico() { return this.nuevoSolicitudForm.get('programaAcademico'); }
  get periodo() { return this.nuevoSolicitudForm.get('periodo'); }
  get campus() { return this.nuevoSolicitudForm.get('campus'); }

 // get usuarioId() { return this.nuevoSolicitudForm.get('usuarioId'); }
  get nombreCompleto() { return this.nuevoSolicitudUsuarioForm.get('nombreCompleto'); }
  get correoUsuario() { return this.nuevoSolicitudUsuarioForm.get('correoUsuario'); }
  get programaAcademicoUsuario() { return this.nuevoSolicitudUsuarioForm.get('programaAcademicoUsuario'); }
  get campusUsuario() { return this.nuevoSolicitudUsuarioForm.get('campusUsuario'); }
  get periodoUsuario() { return this.nuevoSolicitudUsuarioForm.get('periodoUsuario'); }
  get aceptaTerminos() { return this.nuevoSolicitudUsuarioForm.get('aceptaTerminos'); }

  public getErrorForm(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  public getErrorFormUsuario(controlName: string): string {
    let error = '';
    const control = this.nuevoSolicitudUsuarioForm.get(controlName);
    if (control.touched && control.errors != null) {
      error = "El campo es requerido";
    }
    return error;
  }

  private parameters(control: AbstractControl): { [key: string]: any } | null {
    let regExp = /[ÑÁÉÍÓÚñáéíóú]/.test(control.value);

    return regExp ? { 'password': { value: control.value } } : null;
  }

  public mostrarContrasena() {
    this.passwordTipoTexto = !this.passwordTipoTexto;
  }

  public mostrarContrasenaC() {
    this.passwordCTipoTexto = !this.passwordCTipoTexto;
  }

  private validateId(control: AbstractControl) {
    return control.value === 0 ? { error: 'El campo es requerido' } : null;
  }

  private compararPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('passwordConfirm').value;
    return pass === confirmPass ? null : { notSame: true }
  }


  // public getTipoPrograma() {
  //   this.subscriptionPrograma = this.programaService.obtenerProgramas().subscribe(
  //     (programa: Programa[]) => {
  //       this.programaList = programa;
  //     });
  // }

  public onCampusSelected(campus: number) {
    this.programaList = [];
    if (!!campus) {
      this.campusService.obtenerProgramas(campus).subscribe(
        programas => {
          this.programaList = programas;
        })
    }
  }

  public getTipoCampus() {
    this.subscritpionCampus = this.campusService.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.campusList = campus;
      });
  }

  public getTipoPeriodo() {
    this.subscriptionPeriodo = this.periodoService.obtenerPeriodos().subscribe(
      (periodo: Periodo[]) => {
        this.periodoList = periodo;
      });
  }

  ngOnDestroy(): void {
    if (this.subscritpionCampus) {
      this.subscritpionCampus.unsubscribe();
    }
    if (this.subscriptionPeriodo) {
      this.subscriptionPeriodo.unsubscribe();
    }
    if (this.subscriptionPrograma) {
      this.subscriptionPrograma.unsubscribe();
    }
  }
}
