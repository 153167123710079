import { BlockService } from 'src/app/_services/block.service';
import { I18NextPipe } from 'angular-i18next';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { GruposDisponiblesParaMateriaDTO } from 'src/app/models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { SeleccionDeMateriasDTO } from 'src/app/models/Inscripcion/SeleccionDeMateriasDTO.model';
import { AlumnoReingresoDTO } from 'src/app/_models/alumnoReingresoDTO.model';
import { InscripcionService } from 'src/app/_services/inscripcion.service';
import i18next from 'node_modules/i18next';
import { ConfirmationDialogServiceService } from 'src/app/services/confirmation-dialog-service.service';
import { ImprimirServiceService } from 'src/app/services/imprimir-service.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { Periodo } from 'src/app/_models/periodo';
import { ReinscripcionService } from 'src/app/_services/reinscripcion.service';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { CalendarEvents } from 'src/app/models/customEntities/calendarEvents.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelectValidator } from 'src/app/validator/SelectValidator';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { CalendarInfoDTO } from 'src/app/models/Inscripcion/CalendarInfoDTO.model';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { InscripcionCosto } from 'src/app/_models/inscripcion-costo';
import { Block } from '../../_models/block';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-reingreso-horario-materias',
  templateUrl: './reingreso-horario-materias.component.html',
  styleUrls: ['./reingreso-horario-materias.component.scss']
})
export class ReingresoHorarioMateriasComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  constructor(private inscripcion: InscripcionService,
    private confirmacionDialogService: ConfirmationDialogServiceService,
    public imprimirService: ImprimirServiceService,
    private snackService: SnackService,
    private reinscripcion: ReinscripcionService,
    private formBuilder: FormBuilder,
    private reinscripcionService: ReinscripcionService,
    public nuevoIngresoService: NuevoIngresoService,
    private i18nextPipe: I18NextPipe,
    public blockService: BlockService,
  ) {
    this.generarFormularioVacio();
  }


  //#region  Propiedades
  public activarTablaDeGruposDeLaMateria: boolean = false;
  public activarTablaDeMateriasDelPeriodo: boolean = true;
  private cambiosTemporales: boolean;
  private radioButtonCheckStaus: boolean;
  private idiomaActual: string;
  public bloqueList: Block[] = [];
  public bloqueForm: FormGroup;
  public gruposDisponiblesBloque = Array<SeleccionDeMateriasDTO>();
  public dataSource: MatTableDataSource<any>;
  public infoCargado: boolean = false;
  displayedColumns: string[] = ['clave', 'materia', 'creditos', 'ciclo', 'inscrita'];
  public estaEnBloque: boolean = false;
  public bloqueSeleccionado: boolean = false;
  public materiasDisponibles = new Array<SeleccionDeMateriasDTO>();
  public materiaDisponibleSeleccionada = new SeleccionDeMateriasDTO();
  private materiasDisponiblesEnBaseDeDatos = new Array<SeleccionDeMateriasDTO>();

  private gruposInscritosLocalmenteTemporal = new Array<GruposDisponiblesParaMateriaDTO>();
  private gruposDesuscritoLocalmenteTemporal = new Array<GruposDisponiblesParaMateriaDTO>();

  public gruposDisponibles = new Array<GruposDisponiblesParaMateriaDTO>();
  private gruposDisponiblesEnBaseDeDatos = new Array<GruposDisponiblesParaMateriaDTO>();

  public gruposInscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
  public gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();


  public gruposInscritosBloque = new Array<GruposDisponiblesParaMateriaDTO>();

  public cantidadDeCreditosAntesDeBaja: number;
  public cantidadDeMateriasAntesDeBaja: number;

  private eventData: Array<CalendarEvents> = new Array<CalendarEvents>();
  private suscripciones: Subscription = new Subscription();
  public calendarOptions: CalendarOptions = {
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    resourceAreaWidth: '200px',
    height: 450,
    locale: esLocale,
    plugins: [timeGridPlugin],
    initialView: 'timeGridWeek',
    editable: false,
    eventColor: '#43b9fe',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth, timeGridWeek, timeGridDay'
    }
  };
  public mostrarMensajeFaltaInscripcion: boolean = false;
  public existeCuotaColegiatura: boolean = false;
  public tabSeleccionadoIndex: FormControl = new FormControl();
  public periodosForm: FormGroup;
  private ultimoPeriodoValidoSeleccionado: number;
  //#endregion
  //#endregion ViewChildren
  @ViewChild('matGroup') tabGroup: MatTabGroup;
  //#endregion
  //#regions Out puts
  @Output()
  valueResponse: EventEmitter<Array<GruposDisponiblesParaMateriaDTO>> = new EventEmitter<Array<GruposDisponiblesParaMateriaDTO>>();
  @Output()
  valueLenghtResponse: EventEmitter<number> = new EventEmitter<number>();

  @Output() valueBloqueSeleccionado: EventEmitter<boolean> = new EventEmitter<boolean>();

  //#endregion

  //#regions Inputs
  @Input()
  public alumno: AlumnoReingresoDTO;
  @Input()
  public actualizar: boolean;
  @Input()
  public periodos: Array<Periodo> = new Array<Periodo>();
  public ExisteBlockStudent: boolean;
  public selectedTab: number;
  //#endregion

  //#region
  //#region Métodos

  ngOnInit(): void {
    this.idiomaActual = i18next.language;
    this.suscripciones.add(
      this.reinscripcionService.obtenerPeriodoSeleccionado().subscribe((pagoYPeriodo: { inscripcionExistente: boolean, inscripcionPagada: boolean, periodoId: number, colegiaturaExistente: boolean }) => {
        this.cambiarSeleccionDePeriodo(pagoYPeriodo.inscripcionExistente, pagoYPeriodo.inscripcionPagada, pagoYPeriodo.periodoId, pagoYPeriodo.colegiaturaExistente);
      })
    );
    this.suscripciones.add(
      this.reinscripcionService.obtenerTab().subscribe((actualziarHeader) => {
        if (actualziarHeader)
          this.tabGroup.realignInkBar();

        this.inicializarBloqueForm();

      })
    );
  }


  ngAfterViewInit(): void {

  }




  public inicializarBloqueForm() {
    this.bloqueForm = new FormGroup({
      nombreBloque: new FormControl(0),
    });
    this.validarExisteAlumnoEnBloque();
  }

  public obtenerBloques() {
    if (this.ExisteBlockStudent) {

      this.blockService.obtenerBlocksSeleccionMateriasByAlumno(this.alumno.idAlumno, this.alumno.idCampus, this.periodoActualFiel.value).subscribe(response => {
        if (response.success) {
          this.bloqueList = response.data;
          this.bloqueForm.patchValue({
            nombreBloque: this.blockId
          });
        this.bloqueForm.controls['nombreBloque'].updateValueAndValidity();

          console.log("this.blockId)",this.blockId);
          this.nombreBloque.setValue(this.blockId);
          this.nombreBloque.updateValueAndValidity();
        }
      });
    } else {
      this.blockService.obtenerBlocksSeleccionMaterias(this.alumno.idCampus, this.periodoActualFiel.value).subscribe(response => {
        if (response.success) {
          this.bloqueList = response.data;
        }
      });
    }
  }
  public blockId: number;
  public validarExisteAlumnoEnBloque() {
    this.blockService.ValidarExisteAlumnoEnBloque(this.periodoActualFiel.value, this.alumno.idCampus, this.alumno.idAlumno).subscribe(response => {
      if (response.success) {
        //this.nombreBloque.setValue(response.data);
       this.blockId = response.data;
        this.onNombreBloqueSelected(response.data);
        this.ExisteBlockStudent = true;
        this.selectedTab = 1;
      } else {
        this.ExisteBlockStudent = false;
        this.selectedTab = 0;
      }

      this.obtenerBloques();
    });
  }

  get nombreBloque() { return this.bloqueForm.get('nombreBloque'); }


  ngOnDestroy(): void {
    if (this.suscripciones) {
      this.suscripciones.unsubscribe();
    }
  }
  /**
   * Detecta el cambio de valor en la propiedad input
   * una vez el padre setea el valor
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (!changes.actualizar.firstChange && changes.actualizar.currentValue == true) {
      this.cargarMateriasDisponibles();
    }
  }

  /**
 * Se genera el formulario vacío con el que trabaja el select
 */
  private generarFormularioVacio(): void {
    this.periodosForm = this.formBuilder.group({
      periodoInscrito: [0]
    },
      {
        validators: [SelectValidator('periodoInscrito')]
      }
    );
  }
  /**
   * Actualiza el select con base a lo recibido por el observable ejecutado
   * desde pagos
   */
  private cambiarSeleccionDePeriodo(inscripcionExistente: boolean, inscripcionPagada: boolean, periodoId: number, colegiaturaExistente: boolean): void {
    this.periodosForm.patchValue({
      periodoInscrito: periodoId
    });
    this.periodoActualFiel.disable();
    if (inscripcionExistente) {
      if (inscripcionPagada) {
        if (colegiaturaExistente) {
          this.existeCuotaColegiatura = true;
          this.mostrarMensajeFaltaInscripcion = false;
          console.log("1");
          this.cargarMateriasDisponibles();
        } else {
          this.existeCuotaColegiatura = false;
          this.mostrarMensajeFaltaInscripcion = false;
          console.log("2");
        }
      } else {
        this.mostrarMensajeFaltaInscripcion = true;
        console.log("3");
      }
    } else {
      if (colegiaturaExistente) {
        this.existeCuotaColegiatura = true;
        this.mostrarMensajeFaltaInscripcion = false;
        console.log("4");
        this.cargarMateriasDisponibles();
      } else {
        this.existeCuotaColegiatura = false;
        this.mostrarMensajeFaltaInscripcion = false;
        console.log("5");
      }
    }
  }

  /**
   * Obtiene las materias disponibles para poder inscribir
   * @returns
   */
  public async cargarMateriasDisponibles(): Promise<void> {
    if (this.alumno.identificador == null) return;
    if (this.periodos == null) return;
    if (this.periodoActualFiel.invalid) return;
    if (this.ultimoPeriodoValidoSeleccionado == this.periodoActualFiel.value) return;

    this.ultimoPeriodoValidoSeleccionado = this.periodoActualFiel.value;

    let apiMateriasResponse: ApiResponse<Array<SeleccionDeMateriasDTO>> = await this.inscripcion.ObtenerMateriasProgramadasParaElPlanDeEstudiosEnPeriodoEspecifico(this.alumno.idAlumno, this.alumno.idPrograma, this.periodoActualFiel.value, this.alumno.identificador).toPromise();
    if (apiMateriasResponse.success) {
      this.materiasDisponibles = apiMateriasResponse.data;
      this.materiasDisponiblesEnBaseDeDatos = apiMateriasResponse.data.map(g => Object.assign({}, g));
      this.cantidadDeCreditosAntesDeBaja = this.materiasDisponibles.filter(m => m.inscrito).reduce((a, b) => a + b.creditos, 0);
      this.cantidadDeMateriasAntesDeBaja = this.materiasDisponibles.filter(m => m.inscrito).length;
    }
    else {
      this.snackService.mostrarSnackBack(apiMateriasResponse.message);
      return;
    }
  }
  /**
 * Busca entre todos los grupos registrados localmente si alguno hace match con
 * la materia seleccionada. Si es así, lo retorna, en caso contrario regresa NULL
 * @param claveMateria
 * @returns Objeto GrupoDisponible si lo encuentra, en caso contrario retorna un Null
 */
  private grupoRegistradoLocalmente(claveMateria: string): GruposDisponiblesParaMateriaDTO {
    return this.gruposInscritosLocalmente.find(g => g.claveMateria == claveMateria);
  }
  /**
   * Busca entre todos los grupos desuscritos localmente si alguno hace match con
   * la materia seleccionada. Si es así, lo retorna, en caso contrario regresa NULL
   * @param claveMateria
   * @returns Objeto GrupoDisponible si lo encuentra, en caso contrario retorna un Null
   */
  private grupoDesuscritoLocalmente(claveMateria: string): GruposDisponiblesParaMateriaDTO {
    return this.gruposDesuscritosLocalmente.find(g => g.claveMateria == claveMateria);
  }
  /**
* Este método valida si se han inscrito grupos de forma local.
* Si es así, preselecciona nuevamente el grupo escodigo a la hora de cargar los datos
* @param claveMateria
*/
  private preSeleccionarGrupo(claveMateria: string): void {
    const grupoEncontradoLocalmente = this.grupoRegistradoLocalmente(claveMateria);
    if (this.gruposInscritosLocalmente.length <= 0 || !grupoEncontradoLocalmente)
      return;
    this.gruposDisponibles.find(g => g.grupo == grupoEncontradoLocalmente.grupo).cursando = true;
  }
  /*
  * Este método valida si se han desuscrito eñ grupos de forma local.
  * Si es así, deselecciona el grupo a la hora de cargar los datos
  * @param claveMateria
  */
  private deseleccionarGrupoLocalmente(claveMateria: string): void {
    const grupoEncontradoLocalmente = this.grupoDesuscritoLocalmente(claveMateria);
    if (this.gruposDesuscritosLocalmente.length <= 0 || !grupoEncontradoLocalmente)
      return;
    this.gruposDisponibles.find(g => g.grupo == grupoEncontradoLocalmente.grupo).cursando = false;
  }

  /**
 * Obtiene un listado de grupos en los que se imparte la materia indicada
 */
  public async obtenerGruposParaLaMateria(materia: SeleccionDeMateriasDTO): Promise<void> {
    this.materiaDisponibleSeleccionada = this.materiasDisponiblesEnBaseDeDatos.find(m => m.clave == materia.clave);
    let apiGruposReponse: ApiResponse<Array<GruposDisponiblesParaMateriaDTO>> = await this.inscripcion.ObtenerGruposDeUnaMateriaEnPeriodoEspecifico(materia.idMateria, this.periodoActualFiel.value, this.alumno.identificador, this.idiomaActual).toPromise();
    if (apiGruposReponse.success) {
      this.gruposDisponibles = apiGruposReponse.data;
      this.gruposDisponiblesEnBaseDeDatos = apiGruposReponse.data.map(g => Object.assign({}, g));
      this.gruposDesuscritoLocalmenteTemporal = this.gruposDesuscritosLocalmente.map(g => Object.assign({}, g));
      this.gruposInscritosLocalmenteTemporal = this.gruposInscritosLocalmente.map(g => Object.assign({}, g));
    }
    this.preSeleccionarGrupo(materia.clave);
    this.deseleccionarGrupoLocalmente(materia.clave);
    this.activarTablaDeGruposDeLaMateria = true;
    this.activarTablaDeMateriasDelPeriodo = false;
  }
  /**
   * Ayuda al rendimiento en el renderizado
   * @param index
   * @returns
   */
  public trackByIndex(index: number): number {
    return index;
  }
  /**
 * Cambia el estado de las variables banderaas para ocultas la tabla en la que se muestran los grupo disponinibles
 */
  public ocultarTablaSeleccionDeGrupos(): void {
    this.activarTablaDeMateriasDelPeriodo = true;
    this.activarTablaDeGruposDeLaMateria = false;
  }
  /**
 * Retorna el grupo que se tiene almacenado en la base de datos
 * como el grupo en el que se cursa dicha materia
 * @returns Un objeto de tipo Grupo
 */
  private obtenerGrupoInscritoEnLaBaseDeDatos(): GruposDisponiblesParaMateriaDTO {
    return this.gruposDisponiblesEnBaseDeDatos.find(g => g.cursando);
  }

  /**
 * Busca entre los grupos Inscritos localmente alguno que haga match con la clave de materia
 * del grupo seleccionado, y si existe, lo elimina.
 * @param claveDeLaMateria
 */
  private eliminarGrupoDeLosGruposInscritosLocalmente(claveDeLaMateria: string): void {
    const indexGrupoAEliminarLocalmente = this.gruposInscritosLocalmente.findIndex(g => g.claveMateria == claveDeLaMateria);
    if (indexGrupoAEliminarLocalmente < 0)
      return;
    this.gruposInscritosLocalmente.splice(indexGrupoAEliminarLocalmente, 1);
  }
  /**
 * Busca entre los grupos que se han desuscrito de forma local con base a la clave materia indicada
 * Si lo encuentra lo elimina
 * @param claveDeLaMateria
 */
  private eliminarGrupoDeLosGruposDesuscritosLocalmente(claveDeLaMateria: string): void {
    const indexGrupoAEliminarLocalmente = this.gruposDesuscritosLocalmente.findIndex(g => g.claveMateria == claveDeLaMateria);
    if (indexGrupoAEliminarLocalmente < 0)
      return;
    this.gruposDesuscritosLocalmente.splice(indexGrupoAEliminarLocalmente, 1);
  }

  /**
 * Agrega al arreglo local el grupo indicado, lo cual indica que dicho grupo se debe
 * desuscribir en el API
 * @param grupo
 */
  private desuscribirGrupoLocalmente(grupo: GruposDisponiblesParaMateriaDTO): void {
    this.gruposDesuscritosLocalmente.push(grupo);
  }
  /**
 * Agrega el grupo recibio como parámetro al arreglo de grupos que están inscritos de forma local
 * @param grupo
 */
  private inscribirGrupoLocalmente(grupo: GruposDisponiblesParaMateriaDTO): void {
    this.gruposInscritosLocalmente.push(grupo);
  }
  /**
   *
   * @param event Cambia el grupo en el que se ha inscrito el alumno
   * @param grupoRadioButton
   * @param grupo
   */
  public cambiarSeleccionDeGrupo(event: MouseEvent, grupoRadioButton: MatRadioButton, grupo: GruposDisponiblesParaMateriaDTO): void {
    if (this.materiaDisponibleSeleccionada.tieneFalificacionFinal) return;

    if (grupo.cupoDisponible <= 0) {
      event.preventDefault();
      grupoRadioButton.checked = false;
      return;
    }
    this.radioButtonCheckStaus = grupoRadioButton.checked;
    const grupoInscritoEnLaBaseDedatos = this.obtenerGrupoInscritoEnLaBaseDeDatos();

    if (this.materiaDisponibleSeleccionada.inscrito) {
      this.eliminarGrupoDeLosGruposInscritosLocalmente(grupo.claveMateria);
      this.eliminarGrupoDeLosGruposDesuscritosLocalmente(grupo.claveMateria);
      if (grupoRadioButton.checked) {
        this.cambiosTemporales = grupoInscritoEnLaBaseDedatos.grupo == grupo.grupo;
        event.preventDefault();
        grupoRadioButton.checked = false;
        this.desuscribirGrupoLocalmente(grupoInscritoEnLaBaseDedatos);
      }
      else {
        this.cambiosTemporales = grupoInscritoEnLaBaseDedatos.grupo != grupo.grupo;
        if (grupoInscritoEnLaBaseDedatos.grupo != grupo.grupo) {
          this.inscribirGrupoLocalmente(grupo);
          this.desuscribirGrupoLocalmente(grupoInscritoEnLaBaseDedatos);
        }
      }
    }
    else {
      this.cambiosTemporales = true;
      this.eliminarGrupoDeLosGruposInscritosLocalmente(grupo.claveMateria);
      if (grupoRadioButton.checked) {
        event.preventDefault();
        grupoRadioButton.checked = false;
      }
      else {
        this.inscribirGrupoLocalmente(grupo);
      }
    }
  }
  /**
   * Si detecta que se han hecho cambios temporales, notifica al usuario si desea perder su selección
   */
  public regresaraTablaMateriasObtenidas(): void {
    if (this.cambiosTemporales) {
      this.confirmacionDialogService.abrirModalDescartarCambios('Quieres confirmar su cambio de selección', 'Si vuelve a la pantalla anterior sin confirmar sus cambios los perderá.').afterClosed().subscribe((descartarCambios: boolean) => {
        if (descartarCambios) {
          this.gruposDesuscritosLocalmente = this.gruposDesuscritoLocalmenteTemporal.map(g => Object.assign({}, g));
          this.gruposInscritosLocalmente = this.gruposInscritosLocalmenteTemporal.map(g => Object.assign({}, g));
          this.ocultarTablaSeleccionDeGrupos();
        }
      });
    }
    else {
      this.ocultarTablaSeleccionDeGrupos();
    }
  }
  /**
 * Actualiza localmente el estado de la suscripción de la materia al estado que se le indica
 */
  private actualizarEstadoDeMateriaEnLaVista(claveMateria: string, estadoInscrito: boolean): void {
    this.materiasDisponibles.find(m => m.clave == claveMateria).inscrito = estadoInscrito;
  }
  /**
 * Método que inscribe localmente las materias y actualiza la vista
 */
  public guardarCambiosTemporalmente(): void {
    this.modificarEventosDelCalendario();
    this.actualizarEstadoDeMateriaEnLaVista(this.materiaDisponibleSeleccionada.clave, !this.radioButtonCheckStaus);
    if (this.cambiosTemporales) {
      this.cambiosTemporales = false;
      this.regresaraTablaMateriasObtenidas();
      this.valueResponse.emit(this.gruposInscritosLocalmente);
      this.valueLenghtResponse.emit(this.gruposInscritosLocalmente.length);
    }
    else {
      this.regresaraTablaMateriasObtenidas();
    }
  }
  /**
   * Método que actualiza la vista a su estado anterior en caso de que el usuario cancele su operación
   */
  public regresar(): void {
    this.actualizarEstadoDeMateriaEnLaVista(this.materiaDisponibleSeleccionada.clave, this.radioButtonCheckStaus);
    this.regresaraTablaMateriasObtenidas();
  }
  /**
   * Método que ejecuta el servicio para imprimir
   */
  public imprimir(): void {
    if (!this.gruposSeleccionadoLocalmente) return;
    const printContent: HTMLElement = document.getElementById("horario");
    this.imprimirService.Imprimir(printContent, "horario");
  }

  /**
  * Cambiar el index del objeto data cada vez que se cambia de tap en la vista y setea un nuevo valor
  * @param event
  */
  onTabClick(event: MatTabChangeEvent) {
    if (event.index == 1) {
      let calendarEl: HTMLElement = document.getElementById('calendar');
      let calendar = new Calendar(calendarEl, this.calendarOptions);
      calendar.addEventSource(this.eventData);
      calendar.gotoDate(this.periodos.find(p => p.periodoId == this.periodoActualFiel.value).fechaInicio);
      calendar.render();
    }

  }
  /**
   * Toma la lista de Materias seleccionadas y las mapea en un aray de eventos
   * legible para el horario
   */
  private modificarEventosDelCalendario(): void {
    this.eventData = new Array<CalendarEvents>();
    this.gruposInscritosLocalmente.map(m => {
      const evento = new CalendarEvents();
      evento.title = m.claveMateria;
      evento.allDay = false;
      evento.startRecur = m.fechaInicio;
      evento.endRecur = m.fechaFin;
      m.calendario.map(c => {
        evento.daysOfWeek = c.dias;
        evento.startTime = c.horaInicio;
        evento.endTime = c.horaFin;
        this.eventData.push(evento);
      });
    });
  }
  //#endregion

  //#region Gets
  get gruposSeleccionadoLocalmente(): boolean {
    return this.gruposInscritosLocalmente.length > 0;
  }

  get periodoActualFiel() {
    return this.periodosForm.get('periodoInscrito');
  }
  //#region

  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }

  public onNombreBloqueSelected(blockId: number) {
    if (blockId != 0) {
      this.blockService.obtenerMateriasBloque(blockId, this.alumno.idAlumno).subscribe(response => {
        if (response.success) {
          this.gruposDisponiblesBloque = response.data;
          this.dataSource = new MatTableDataSource(this.gruposDisponiblesBloque);
          this.infoCargado = true;
          this.bloqueSeleccionado = true;

          this.gruposDisponiblesBloque.forEach(element => {
            let grupo: GruposDisponiblesParaMateriaDTO = new GruposDisponiblesParaMateriaDTO();
            grupo.creditos = element.creditos;
            grupo.claveMateria = element.clave;
            grupo.nombreMateria = element.nombre;
            grupo.horario = element.horario;
            grupo.grupo = element.grupoId;
            this.gruposInscritosBloque.push(grupo);
          });

          this.valueResponse.emit(this.gruposInscritosBloque);


        }
      })
    } else {
      this.dataSource = new MatTableDataSource();
      this.bloqueSeleccionado = false;
      this.gruposInscritosBloque = [];
    }
    this.valueBloqueSeleccionado.emit(this.bloqueSeleccionado);
  }


}
