import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { BlockService } from 'src/app/_services/block.service';
import { TraductorService } from './../../../../_services/traductor.service';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import { Calendar, CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import i18next from 'i18next';
import { interval, Observable, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { ExisteMateriaSeriada } from 'src/app/models/customEntities/existeMateriaSeriada.mode';
import { GruposDisponiblesParaMateriaDTO } from 'src/app/models/Inscripcion/GruposDisponiblesParaMateriaDTO.model';
import { SeleccionDeMateriasDTO } from 'src/app/models/Inscripcion/SeleccionDeMateriasDTO.model';
import { ConfirmationDialogServiceService } from 'src/app/services/confirmation-dialog-service.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { InscripcionService } from 'src/app/_services/inscripcion.service';
import * as moment from 'moment';
import esLocale from '@fullcalendar/core/locales/es';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { AdmisionesService } from 'src/app/_services/admisiones.service';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GeneracionExcelServiceService } from 'src/app/services/generacion-excel-service.service';
import { ColegiaturaCosto } from 'src/app/_models/colegiatura-costo';
import { CalendarEvents } from 'src/app/models/customEntities/calendarEvents.model';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { InscripcionCosto } from 'src/app/_models/inscripcion-costo';
import { Nomnenclatura } from 'src/app/_models/nomenclatura';
import { Block } from '../../../../_models/block';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { I18NextPipe } from 'angular-i18next';
import { EstatusAcademicos } from '../../../../_models/estatus-academico.enum';

@Component({
  selector: 'app-seleccion-materias-admision',
  templateUrl: './seleccion-materias-admision.component.html',
  styleUrls: ['./seleccion-materias-admision.component.scss']
})
export class SeleccionMateriasAdmisionComponent implements OnInit, OnDestroy {

  public identificador: string;
  private campusId: number;
  private nivelProgramaId: number;
  private idPrograma: number;
  private idPeriodo: number;
  private nivelId: number;
  private curentLanguaje: string;
  private idCampus: number;
  private idAlumno: number;
  private idPeriodoIngreso: number;
  private tipoAlumno: string = 'NI';
  public estatusAcadico: number;
  public materiasSeriadas: string;
  public cantidadDeCreditosAntesDeBaja: number;
  public cantidadDeMateriasAntesDeBaja: number;
  public estatusCandidato = EstatusAcademicos.Candidato;
  public estatusAdminitido = EstatusAcademicos.Admitido;
  public tieneAsignacionBloque: boolean = false;
  public tabSeleccionado: number = 0;

  public materiasDisponibles = new Array<SeleccionDeMateriasDTO>();
  private materiasDisponiblesEnBaseDeDatos = new Array<SeleccionDeMateriasDTO>();
  public materiaDisponibleSeleccionada = new SeleccionDeMateriasDTO();

  public gruposDisponibles = Array<GruposDisponiblesParaMateriaDTO>();
  private gruposDisponiblesEnBaseDeDatos = Array<GruposDisponiblesParaMateriaDTO>();

  private gruposInscritosLocalmenteTemporal = new Array<GruposDisponiblesParaMateriaDTO>();
  private gruposDesuscritoLocalmenteTemporal = new Array<GruposDisponiblesParaMateriaDTO>();

  public gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
  public gruposInscritosLocalmente = new Array<any>();

  public gruposNuevosInscritos: any;

  public alumnoNoCubrioCuentaExistente: boolean;

  private cambiosTemporales: boolean;
  public activarTablaDeMateriasDelPeriodo = false;
  public activarTablaDeGruposDeLaMateria = false;

  private radioButtonCheckStaus: boolean;
  public existeSeriacionDeMaterias: boolean;
  private datosUsuarioSubscription: Subscription;
  public datosUsuarioBusqueda: any;
  //Variables Obs
  public infoUsuario$: Observable<any>;
  public infoUsuario: any = null;
  //Subscripciones
  private subscription: Subscription = new Subscription();
  public segundaValidacion: boolean;
  //Validacion materias existentes o seleccionadas
  public materiaExistente: boolean = false;
  //Variable para guardar la colegiatura costo
  public colegiaturaCosto: ColegiaturaCosto = new ColegiaturaCosto();
  public nombreBloqueAlumno: string = "";

  @Output() valueBloqueSeleccionado: EventEmitter<boolean> = new EventEmitter<boolean>();

  private eventData: Array<CalendarEvents> = new Array<CalendarEvents>();

  public calendarOptions: CalendarOptions = {
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    resourceAreaWidth: '200px',
    height: 450,
    locale: esLocale,
    plugins: [timeGridPlugin],
    initialView: 'timeGridWeek',
    editable: false,
    eventColor: '#43b9fe',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth, timeGridWeek, timeGridDay'
    }
  };



  public bloqueList: Block[] = [];
  public bloqueForm: FormGroup;
  public gruposDisponiblesBloque = Array<SeleccionDeMateriasDTO>();
  public dataSource: MatTableDataSource<any>;
  public infoCargado: boolean = false;
  displayedColumns: string[] = ['clave', 'materia', 'creditos', 'ciclo', 'inscrita'];

  public bloqueSeleccionado: boolean = false;



  // calendarOptions: CalendarOptions;
  localeCalendario = esLocale;
  @ViewChild('fullcalendar', { static: true }) fullcalendar: FullCalendarComponent;
  public datosCalendario: any[] = [];
  public resources: any[] = [];
  public nomenclatura: Array<Nomnenclatura> = new Array<Nomnenclatura>();

  constructor(public nuevoIngresoService: NuevoIngresoService,
    public inscripcion: InscripcionService,
    public snackService: SnackService,
    private confirmacionDialogService: ConfirmationDialogServiceService,
    private admisionesService: AdmisionesService,
    private generadorExcel: GeneracionExcelServiceService,
    private traductorService: TraductorService,
    public blockService: BlockService,
    private i18nextPipe: I18NextPipe,
  ) {
  }

  ngOnInit(): void {
  }


  public inicializarBloqueForm() {
    // this.bloqueForm = new FormGroup({
    //   nombreBloque: new FormControl(0),
    // });

    this.obtenerBloques();
    this.validarExisteAlumnoEnBloque();
  }

  public obtenerBloques() {
    this.blockService.obtenerBlocksSeleccionMaterias(this.campusId, this.idPeriodoIngreso).subscribe(response => {
      if (response.success) {
        this.bloqueList = response.data;
      }
    })
  }

  public validarExisteAlumnoEnBloque() {
    this.blockService.ValidarExisteAlumnoEnBloque(this.idPeriodoIngreso, this.campusId, this.idAlumno).subscribe(response => {
      this.tieneAsignacionBloque = response.success;
      if (this.tieneAsignacionBloque) {
        this.tabSeleccionado = 1;
        this.onNombreBloqueSelected(response.data);
        let nombre: string = this.bloqueList.find(x => x.blockId == response.data).blockLabel.name;
        this.nombreBloqueAlumno = nombre;
      }
    })
  }

  // get nombreBloque() { return this.bloqueForm.get('nombreBloque'); }

  public onNombreBloqueSelected(blockId: number) {
    if (blockId != 0) {
      this.blockService.obtenerMateriasBloque(blockId, this.idAlumno).subscribe(response => {
        if (response.success) {
          this.gruposDisponiblesBloque = response.data;
          this.dataSource = new MatTableDataSource(this.gruposDisponiblesBloque);
          this.infoCargado = true;
          this.bloqueSeleccionado = true;

          this.gruposDisponiblesBloque.forEach(element => {
            let grupo: GruposDisponiblesParaMateriaDTO = new GruposDisponiblesParaMateriaDTO();
            grupo.creditos = element.creditos;
            grupo.claveMateria = element.clave;
            grupo.nombreMateria = element.nombre;
            grupo.horario = element.horario;
            grupo.grupo = element.grupoId;
            this.inscribirGrupoLocalmente(grupo);
          });
        }
      })
    } else {
      this.dataSource = new MatTableDataSource();
      this.gruposInscritosLocalmente = [];
      this.bloqueSeleccionado = false;
    }
    this.valueBloqueSeleccionado.emit(this.bloqueSeleccionado);

  }


  public renderCalendar() {
    if (!this.segundaValidacion) {
      const calendarApi = this.fullcalendar.getApi();
      calendarApi.changeView('timeGridWeek');
      this.segundaValidacion = true;
    }
  }

  public translate(text: string) {
    return this.i18nextPipe.transform(text, { format: 'cap' });
  }


  public renderizarSeleccionMaterias(): void {
    this.infoUsuario$ = this.admisionesService.GetInfoUsuario$();
    this.subscription.add(
      this.infoUsuario$.subscribe(
        (info: any) => {
          this.infoUsuario = info;
          this.obtenerInformacionUsuario();
        })
    );
  }


  public obtenerInformacionUsuario(): void {
    this.curentLanguaje = i18next.language;
    this.nuevoIngresoService.candidatoById(this.infoUsuario.alumnoRegistrado[0].alumnoId, this.infoUsuario.usuarioId).subscribe(
      (alumnos: ApiResponse<UsuarioIngreso>) => {
        this.datosUsuarioBusqueda = alumnos.data;
        this.nuevoIngresoService.definirDatosUsuarioBusqueda(this.datosUsuarioBusqueda);
        this.identificador = this.datosUsuarioBusqueda.identificador;
        this.idPeriodo = this.datosUsuarioBusqueda.alumno[0].priodoIngresoNavigation.periodoId;
        this.idPrograma = this.datosUsuarioBusqueda.alumno[0].programaId;
        this.nivelId = this.datosUsuarioBusqueda.alumno[0].programa.nivelId;
        this.idPeriodoIngreso = this.datosUsuarioBusqueda.alumno[0].priodoIngreso;
        this.idAlumno = this.datosUsuarioBusqueda.alumno[0].alumnoId;
        this.campusId = this.datosUsuarioBusqueda.alumno[0].campusId;
        this.nivelProgramaId = this.datosUsuarioBusqueda.alumno[0].programa.nivelId;
        this.estatusAcadico = this.datosUsuarioBusqueda.alumno[0].estatusAcademico;
        this.consultarMateriasProgramasParaElPlanDeEstudios();
        this.segundaValidacion = false;
        const source = interval(1000);
        this.subscription = source.subscribe(val => this.renderCalendar());
        this.ObtenerHorarioGrupos(this.datosUsuarioBusqueda.alumno[0].alumnoId);
        this.nuevoIngresoService.getSeriacionSubjet().subscribe((existeSeriacion: ExisteMateriaSeriada) => {
          this.existeSeriacionDeMaterias = existeSeriacion.existeMateriaSeriada;
          this.materiasSeriadas = existeSeriacion.materiasSeriadas;
        });
        this.inicializarBloqueForm();


      });
  }

  public async ObtenerHorarioGrupos(alumnoId) {
    this.inscripcion.getHorarioGrupos(alumnoId).subscribe((data: ApiResponse<any>) => {
      this.datosCalendario = data.data;
      if (data.data.length > 0) {
        this.materiaExistente = true;
      } else {
        this.materiaExistente = false;
      }
      this.modificarEventosDelCalendario();
      this.eventosNomenclatura();
      this.renderCalendar()
    });
  }


  public getEvents() {
    let eventosProcesados: any[] = [];
    this.datosCalendario.forEach(alumnoGrupo => {
      alumnoGrupo.grupo.horario.forEach(horario => {
        let dias: number[] = [];
        if (horario.esLunes) {
          dias.push(1);
        };
        if (horario.esMartes) {
          dias.push(2);
        };
        if (horario.esMiercoles) {
          dias.push(3);
        };
        if (horario.esJueves) {
          dias.push(4);
        };
        if (horario.esViernes) {
          dias.push(5);
        };
        if (horario.esSabado) {
          dias.push(6);
        };
        if (horario.esDomingo) {
          dias.push(7);
        };
        eventosProcesados.push({
          id: horario.horarioId,
          title: alumnoGrupo.grupo.materiaOferta.clave,
          start: horario.fechaInicio + 'T' + horario.horaInicio + ":00",
          end: horario.fechaFin + horario.horaFin + ":00",
          daysOfWeek: dias,
          startRecur: horario.fechaInicio,
          endRecur: horario.fechaFin,
          startTime: horario.horaInicio,
          endTime: horario.horaFin,
          resourceId: horario.nombre + horario.grupoId,
          allDay: false
        })
      });
    });
    return eventosProcesados;
  }

  public onChangeTab(event: MatTabChangeEvent): void {
    if (event.index == 2) {
      let calendarEl: HTMLElement = document.getElementById('fullcalendar');
      let calendar = new Calendar(calendarEl, this.calendarOptions);
      this.modificarEventosDelCalendario();
      calendar.addEventSource(this.eventData);
      calendar.render();
    }
  }

  private consultarMateriasProgramasParaElPlanDeEstudios(): void {
    this.alumnoNoCubrioCuentaExistente = false;
    this.nuevoIngresoService.ExisteInscripcionCosto(this.campusId, this.nivelProgramaId, this.idPeriodoIngreso, this.idPrograma, this.tipoAlumno).subscribe(
      (InscripcionCosto: ApiResponse<InscripcionCosto>) => {
        if (InscripcionCosto.success) {
          this.nuevoIngresoService.ValidarInscripcionPagada(this.idAlumno, this.idPeriodoIngreso).subscribe((inscripcionPagada) => {
            if (!inscripcionPagada) {
              this.alumnoNoCubrioCuentaExistente = true;
            }
          });
        }
      });
    this.nuevoIngresoService.ExisteColegiaturaCostoDisponible(this.campusId, this.nivelProgramaId, this.idPeriodoIngreso, this.idPrograma,).subscribe((respuesta: ApiResponse<ColegiaturaCosto>) => {
      if (respuesta.success) {
        if (respuesta.data) {
          this.colegiaturaCosto = respuesta.data;
          this.colegiaturaCosto.diaTransaccion = respuesta.data.diaTransaccion;
        } else {
          if (respuesta.message !== null) {
            let mensaje: string = this.traductorService.translate(respuesta.message);
            this.snackService.mostrarSnackBack(mensaje);
          }
        }
      }
      else {
        this.snackService.mostrarSnackBack(respuesta.message);
      }
    });
    this.cargarMaterias();
  }

  private cargarMaterias(): void {
    this.inscripcion.ObtenerMateriasProgramadasParaElPlanDeEstudiosEnPeriodoEspecifico(this.infoUsuario.alumnoRegistrado[0].alumnoId, this.idPrograma, this.idPeriodo, this.identificador).subscribe((materiasDisponiblesApiResponse: ApiResponse<Array<SeleccionDeMateriasDTO>>) => {
      if (materiasDisponiblesApiResponse.success) {
        this.materiasDisponibles = materiasDisponiblesApiResponse.data;
        this.materiasDisponiblesEnBaseDeDatos = materiasDisponiblesApiResponse.data.map(m => Object.assign({}, m));
        this.activarTablaDeMateriasDelPeriodo = true;
        this.cantidadDeCreditosAntesDeBaja = this.materiasDisponibles.filter(m => m.inscrito).reduce((a, b) => a + b.creditos, 0);
        this.cantidadDeMateriasAntesDeBaja = this.materiasDisponibles.filter(m => m.inscrito).length;
      }
      else {
        this.snackService.mostrarSnackBack(materiasDisponiblesApiResponse.message);
      }
    });
  }

  /**
   * Busca entre todos los grupos registrados localmente si alguno hace match con
   * la materia seleccionada. Si es así, lo retorna, en caso contrario regresa NULL
   * @param claveMateria
   * @returns Objeto GrupoDisponible si lo encuentra, en caso contrario retorna un Null
   */
  private grupoRegistradoLocalmente(claveMateria: string): GruposDisponiblesParaMateriaDTO {
    return this.gruposInscritosLocalmente.find(g => g.claveMateria == claveMateria);
  }
  /**
  * Este método valida si se han inscrito grupos de forma local.
  * Si es así, preselecciona nuevamente el grupo escodigo a la hora de cargar los datos
  * @param claveMateria
  */
  private preSeleccionarGrupo(claveMateria: string): void {
    const grupoEncontradoLocalmente = this.grupoRegistradoLocalmente(claveMateria);
    if (this.gruposInscritosLocalmente.length <= 0 || !grupoEncontradoLocalmente)
      return;
    this.gruposDisponibles.find(g => g.grupo == grupoEncontradoLocalmente.grupo).cursando = true;
  }
  /**
   * Busca entre todos los grupos desuscritos localmente si alguno hace match con
   * la materia seleccionada. Si es así, lo retorna, en caso contrario regresa NULL
   * @param claveMateria
   * @returns Objeto GrupoDisponible si lo encuentra, en caso contrario retorna un Null
   */
  private grupoDesuscritoLocalmente(claveMateria: string): GruposDisponiblesParaMateriaDTO {
    return this.gruposDesuscritosLocalmente.find(g => g.claveMateria == claveMateria);
  }
  /*
  * Este método valida si se han desuscrito eñ grupos de forma local.
  * Si es así, deselecciona el grupo a la hora de cargar los datos
  * @param claveMateria
  */
  private deseleccionarGrupoLocalmente(claveMateria: string): void {
    const grupoEncontradoLocalmente = this.grupoDesuscritoLocalmente(claveMateria);
    if (this.gruposDesuscritosLocalmente.length <= 0 || !grupoEncontradoLocalmente)
      return;
    this.gruposDisponibles.find(g => g.grupo == grupoEncontradoLocalmente.grupo).cursando = false;
  }
  /**
   * Obtiene un listado de grupos que ofertan la materia seleccionada
   * @param materia
   */
  public obtenerGruposParaLaMateria(materia: SeleccionDeMateriasDTO): void {
    this.materiaDisponibleSeleccionada = this.materiasDisponiblesEnBaseDeDatos.find(m => m.clave == materia.clave);

    this.activarTablaDeGruposDeLaMateria = true;
    this.activarTablaDeMateriasDelPeriodo = false;

    this.inscripcion.ObtenerGruposDeUnaMateriaEnPeriodoEspecifico(this.materiaDisponibleSeleccionada.idMateria, this.idPeriodo, this.identificador, this.curentLanguaje).subscribe((apiGruposReponse: ApiResponse<Array<GruposDisponiblesParaMateriaDTO>>) => {
      if (apiGruposReponse.success) {
        this.gruposDisponibles = apiGruposReponse.data;
        this.gruposDisponiblesEnBaseDeDatos = apiGruposReponse.data.map(g => Object.assign({}, g));
        this.cambiosTemporales = false;

        //Copia del estado anterior de grupos
        this.gruposDesuscritoLocalmenteTemporal = this.gruposDesuscritosLocalmente.map(g => Object.assign({}, g));
        this.gruposInscritosLocalmenteTemporal = this.gruposInscritosLocalmente.map(g => Object.assign({}, g));

        this.preSeleccionarGrupo(materia.clave);
        this.deseleccionarGrupoLocalmente(materia.clave);
      }
    });
  }

  /**
 * Ayuda con el rendimiento de la app en los NgFor cuando haya cambios
 * @param index
 * @returns
 */
  public trackByIndex(index: number): number {
    return index;
  }
  /**
   * Cambia el estado de las variables banderaas para ocultas la tabla en la que se muestran los grupo disponinibles
   */
  public ocultarTablaSeleccionDeGrupos(): void {
    this.activarTablaDeMateriasDelPeriodo = true;
    this.activarTablaDeGruposDeLaMateria = false;
  }

  /**
   * Oculta la tabla actual en la que se ven los grupos que impoarten la materia indicada
   * y muestra nuevamente la tabla en la que se muestran las materias programadas para el plan académico
   */
  public regresaraTablaMateriasObtenidas(): void {
    if (this.cambiosTemporales) {
      this.confirmacionDialogService.abrirModalDescartarCambios('Quieres confirmar su cambio de selección', 'Si vuelve a la pantalla anterior sin confirmar sus cambios los perderá.').afterClosed().subscribe((descartarCambios: boolean) => {
        if (descartarCambios) {
          this.gruposDesuscritosLocalmente = this.gruposDesuscritoLocalmenteTemporal.map(g => Object.assign({}, g));
          this.gruposInscritosLocalmente = this.gruposInscritosLocalmenteTemporal.map(g => Object.assign({}, g));
          this.ocultarTablaSeleccionDeGrupos();
        }
      });
    }
    else {
      this.ocultarTablaSeleccionDeGrupos();
    }
  }

  /**
   * Retorna el grupo que se tiene almacenado en la base de datos
   * como el grupo en el que se cursa dicha materia
   * @returns Un objeto de tipo Grupo
   */
  private obtenerGrupoInscritoEnLaBaseDeDatos(): GruposDisponiblesParaMateriaDTO {
    return this.gruposDisponiblesEnBaseDeDatos.find(g => g.cursando);
  }
  /**
   * Busca entre los grupos Inscritos localmente alguno que haga match con la clave de materia
   * del grupo seleccionado, y si existe, lo elimina.
   * @param claveDeLaMateria
   */
  private eliminarGrupoDeLosGruposInscritosLocalmente(claveDeLaMateria: string): void {
    const indexGrupoAEliminarLocalmente = this.gruposInscritosLocalmente.findIndex(g => g.claveMateria == claveDeLaMateria);
    if (indexGrupoAEliminarLocalmente < 0)
      return;
    this.gruposInscritosLocalmente.splice(indexGrupoAEliminarLocalmente, 1);
  }
  /**
   * Busca entre los grupos que se han desuscrito de forma local con base a la clave materia indicada
   * Si lo encuentra lo elimina
   * @param claveDeLaMateria
   */
  private eliminarGrupoDeLosGruposDesuscritosLocalmente(claveDeLaMateria: string): void {
    const indexGrupoAEliminarLocalmente = this.gruposDesuscritosLocalmente.findIndex(g => g.claveMateria == claveDeLaMateria);
    if (indexGrupoAEliminarLocalmente < 0)
      return;
    this.gruposDesuscritosLocalmente.splice(indexGrupoAEliminarLocalmente, 1);
  }
  /**
   * Agrega al arreglo local el grupo indicado, lo cual indica que dicho grupo se debe
   * desuscribir en el API
   * @param grupo
   */
  private desuscribirGrupoLocalmente(grupo: GruposDisponiblesParaMateriaDTO): void {
    this.gruposDesuscritosLocalmente.push(grupo);
  }
  /**
   * Agrega el grupo recibio como parámetro al arreglo de grupos que están inscritos de forma local
   * @param grupo
   */
  private inscribirGrupoLocalmente(grupo: GruposDisponiblesParaMateriaDTO): void {
    this.gruposInscritosLocalmente.push(grupo);
  }
  /**
   * Actualiza localmente el estado de la suscripción de la materia al estado que se le indica
   */
  private actualizarEstadoDeMateriaEnLaVista(claveMateria: string, estadoInscrito: boolean): void {
    this.materiasDisponibles.find(m => m.clave == claveMateria).inscrito = estadoInscrito;
  }
  /**
   * Si el radio buton está marcado en la vista, el método lo desmarca y guarda en pantalla la nueva selección del usuario
   * @param event
   * @param grupoRadioButton
   */
  public cambiarSeleccionDeGrupo(event: MouseEvent, grupoRadioButton: MatRadioButton, grupo: GruposDisponiblesParaMateriaDTO): void {
    this.gruposNuevosInscritos = grupo;
    if (this.materiaDisponibleSeleccionada.tieneFalificacionFinal) return;

    if (grupo.cupoDisponible <= 0) {
      event.preventDefault();
      grupoRadioButton.checked = false;
      return;
    }

    this.radioButtonCheckStaus = grupoRadioButton.checked;
    const grupoInscritoEnLaBaseDedatos = this.obtenerGrupoInscritoEnLaBaseDeDatos();
    if (this.materiaDisponibleSeleccionada.inscrito) {
      const grupoInscritoEnLaBaseDedatos = this.obtenerGrupoInscritoEnLaBaseDeDatos();
      this.eliminarGrupoDeLosGruposInscritosLocalmente(grupo.claveMateria);
      this.eliminarGrupoDeLosGruposDesuscritosLocalmente(grupo.claveMateria);
      if (grupoRadioButton.checked) {
        this.cambiosTemporales = grupoInscritoEnLaBaseDedatos.grupo == grupo.grupo;
        event.preventDefault();
        grupoRadioButton.checked = false;
        this.desuscribirGrupoLocalmente(grupoInscritoEnLaBaseDedatos);
      }
      else {
        this.cambiosTemporales = grupoInscritoEnLaBaseDedatos.grupo != grupo.grupo;
        if (grupoInscritoEnLaBaseDedatos.grupo != grupo.grupo) {
          this.inscribirGrupoLocalmente(grupo);
          this.desuscribirGrupoLocalmente(grupoInscritoEnLaBaseDedatos);
        }
      }
    }
    else {
      this.cambiosTemporales = !grupoRadioButton.checked;
      this.eliminarGrupoDeLosGruposInscritosLocalmente(grupo.claveMateria);
      if (grupoRadioButton.checked) {
        event.preventDefault();
        grupoRadioButton.checked = false;
      }
      else {
        this.inscribirGrupoLocalmente(grupo);
      }
    }
    this.modificarEventosDelCalendario();
    this.eventosNomenclatura();
  }
  /**
   * Guarda todos los cambios temporales con los cambios
   *  permanentes que se agregarán a a la base de datos
   */
  public guardarCambiosTemporalmente(): void {
    this.actualizarEstadoDeMateriaEnLaVista(this.materiaDisponibleSeleccionada.clave, !this.radioButtonCheckStaus);
    if (this.cambiosTemporales) {
      this.nuevoIngresoService.cambiosRealziadosEnSeleccionDeMaterias(this.cambiosHechos);
      this.cambiosTemporales = false;
      this.regresaraTablaMateriasObtenidas();
    }
    else {
      this.regresaraTablaMateriasObtenidas();
    }
  }

  /** 
   * Modificar la vista del calendario al momento de suscribir una materia
  */
  public modificarVistaCalendario() {
    this.eventData = new Array<CalendarEvents>();
    this.eventData = this.gruposInscritosLocalmente.map(m => {
      let evento = new CalendarEvents();
      evento.title = m.claveMateria;
      evento.daysOfWeek = m.dias;
      evento.allDay = false;
      evento.startTime = m.horario.horaInicio;
      evento.endTime = m.horario.horaFin;
      evento.startTime = m.horario.horaInicio;
      evento.endTime = m.horario.horaFin;
      return evento;
    });

    this.gruposInscritosLocalmente.forEach(alumnoGrupo => {
      alumnoGrupo.horario.forEach(horario => {
        let dias: number[] = [];
        if (horario.esLunes) {
          dias.push(1);
        };
        if (horario.esMartes) {
          dias.push(2);
        };
        if (horario.esMiercoles) {
          dias.push(3);
        };
        if (horario.esJueves) {
          dias.push(4);
        };
        if (horario.esViernes) {
          dias.push(5);
        };
        if (horario.esSabado) {
          dias.push(6);
        };
        if (horario.esDomingo) {
          dias.push(7);
        };
        this.eventData.push({
          title: alumnoGrupo.claveMateria,
          daysOfWeek: dias,
          allDay: false,
          startTime: horario.horaInicio,
          endTime: horario.horaFin,
          startRecur: alumnoGrupo.fechaInicio,
          endRecur: alumnoGrupo.fechaFin
        })
      });
    });
    return this.eventData;
  }

  /**
   * Toma la lista de Materias seleccionadas y las mapea en un aray de eventos
   * legible para el horario
   */
  private modificarEventosDelCalendario() {
    this.eventData = new Array<CalendarEvents>();
    this.eventData = this.gruposInscritosLocalmente.map(m => {
      let evento = new CalendarEvents();
      evento.title = m.claveMateria;
      evento.daysOfWeek = m.dias;
      evento.allDay = false;
      evento.startTime = m.horaInicio;
      evento.endTime = m.horaFin;
      evento.startTime = m.horaInicio;
      evento.endTime = m.horaFin;
      return evento;
    });
    this.datosCalendario.forEach(alumnoGrupo => {
      alumnoGrupo.grupo.horario.forEach(horario => {
        let dias: number[] = [];
        if (horario.esLunes) {
          dias.push(1);
        };
        if (horario.esMartes) {
          dias.push(2);
        };
        if (horario.esMiercoles) {
          dias.push(3);
        };
        if (horario.esJueves) {
          dias.push(4);
        };
        if (horario.esViernes) {
          dias.push(5);
        };
        if (horario.esSabado) {
          dias.push(6);
        };
        if (horario.esDomingo) {
          dias.push(7);
        };
        this.eventData.push({
          title: alumnoGrupo.grupo.materiaOferta.clave,
          daysOfWeek: dias,
          allDay: false,
          startTime: horario.horaInicio,
          endTime: horario.horaFin,
          startRecur: horario.fechaInicio,
          endRecur: horario.fechaFin
        })
      });
    });
    this.modificarVistaCalendario();
    return this.eventData;
  }

  public eventosNomenclatura(): void {
    this.nomenclatura = new Array<Nomnenclatura>();
    this.nomenclatura = this.gruposInscritosLocalmente.map(m => {
      let eventos: Nomnenclatura = new Nomnenclatura();
      eventos.claveMateria = m.claveMateria;
      eventos.nombreMateria = m.nombreMateria;
      eventos.grupo = m.grupo;
      //eventos.aula = m.aula;
      return eventos;
    });
    this.datosCalendario.map((m: any) => {
      this.nomenclatura.push({
        claveMateria: m.grupo.clave,
        nombreMateria: m.grupo.materiaOferta.nombre,
        grupo: m.grupoId,
        aula: m.grupo.horario[0].salon.nombre
      })
    });
  }

  /**
  * Este método se ejecuta una vez se han guardado los cambios en la base de datos
  * Reiniciando el mecanismo para regresarlos a su estado inicial
  */
  public resetSeleccionDeMaterias(): void {
    if (this.datosCalendario.length > 1) {
      this.materiaExistente = true;
    } else {
      this.materiaExistente = false;
    }
    this.gruposInscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
    this.gruposDesuscritosLocalmente = new Array<GruposDisponiblesParaMateriaDTO>();
    this.cambiosTemporales = false;
    this.consultarMateriasProgramasParaElPlanDeEstudios();
  }
  //#endregion

  //#region  Gets
  get cambiosHechos(): boolean {
    return this.gruposInscritosLocalmente.length > 0 || this.gruposDesuscritosLocalmente.length > 0;
  }

  get cantidadCambios(): number {
    return this.gruposInscritosLocalmente.length + this.gruposDesuscritosLocalmente.length;
  }

  public get isSoloGruposInscritosPendientes(): boolean {
    return this.gruposInscritosLocalmente.length > 0 && this.gruposDesuscritosLocalmente.length <= 0;
  }

  public get isSoloGruposDesuscritosPendientes(): boolean {
    return this.gruposDesuscritosLocalmente.length > 0 && this.gruposInscritosLocalmente.length <= 0;
  }

  public get isGruposDesuscritosEInscritosPendientes(): boolean {
    return this.gruposInscritosLocalmente.length > 0 && this.gruposDesuscritosLocalmente.length > 0;
  }

  public get alumnoCubrioRequisitos(): boolean {
    return this.estatusAcadico == 3 || this.estatusAcadico == 8;
  }
  //#endregion


  public descargarExcelNomenclatura() {
    let datosGrupos = this.nomenclatura.map(obj =>
    ({
      clave: obj.claveMateria,
      materia: obj.nombreMateria,
      grupo: obj.grupo,
      salon: obj.aula
    })
    );
    this.admisionesService.descargarExcelNomenclatura(datosGrupos).subscribe((blob: Blob) => {
      this.generadorExcel.GenerarExcel(blob, "Nomenclatura");
    })
  }

  ngOnDestroy(): void {
  }

}
