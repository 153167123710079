import { TraductorService } from "./../../_services/traductor.service";
import { InstitucionService } from "@app/_services/institucion.service";
import { IntentoPagoPaypalDTO } from "./../../_models/Paypal/IntentoPagoPaypalDto";
import { PagoPaypal } from "./../../_models/Paypal/PagoPaypal";
import { PagoLineaPaypalServiceService } from "./../../_services/pago-linea-paypal-service.service";
import { Router } from "@angular/router";
import { LinkEstatus } from "./../../_models/Zuma/LinkEstatus/LinkEstatus";
import { PagoZumaResponse } from "./../../_models/Zuma/LinkPago/PagoZumaResponse";
import { ApiResponse } from "./../../models/api/ApiRespose.model";
import { PagoLineaZumaService } from "./../../_services/pago-linea-zuma.service";
import { PagoZuma } from "./../../_models/Zuma/LinkPago/PagoZuma";
import { SnackService } from "./../../services/snack-service.service";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OrderCreate } from "@app/_models/Paypal/OrderCreate";
import { EstadoCuentaDto } from "@app/_models/estado-cuenta-dto";
import { SimpleResponse } from "@app/models/api/SimpleResponse.model";
import {
  IPayPalConfig,
  ICreateOrderRequest,
  ITransactionItem,
} from "ngx-paypal";
import { Institucion } from "@app/_models/institucion";
import { PaypalCredentials } from "@app/_models/Paypal/PaypalCredentials";
import { TransactionRequest, TransactionResponseCreditCard } from "@app/_models/Authorize/Charge";
import { AuthorizeCredentials } from "@app/_models/Authorize/AuthorizeCredentials";
import { PagoLineaAuthorizeService } from "@app/_services/pago-linea-authorize-service";
@Component({
  selector: "app-pago-zuma-modal",
  templateUrl: "./pago-zuma-modal.component.html",
  styleUrls: ["./pago-zuma-modal.component.scss"],
})
export class PagoZumaModalComponent implements OnInit, OnDestroy {
  //#region Properties ZUMA
  //Subscripciones
  private subscription: Subscription = new Subscription();
  //Variable url string de ZUMA
  public url: SafeResourceUrl;
  public infoCargada: boolean = false;
  //Variables Form
  public formaPagoForm: FormGroup;
  //Variable tipo Tarjeta
  public tipoTarjetaDebito = true;
  //Variable tipo PayPal
  public tipoPayPal = false;
  public showPayPal = false;
  //Respuesta Zuma
  public respuestaZuma: PagoZumaResponse = null;
  //Respuesta Paypal
  public respuestaPaypal: OrderCreate = null;
  //Respuesta Authorize
  public respuestaAuthorize: TransactionResponseCreditCard = null;
  //#endregion

  //#region Properties Paypal
  //VARIABLE CONFIGURACION PAYPAL
  public payPalConfig?: IPayPalConfig;
  //VARIABLE MONTO FINAL PAYPAL
  public montoFinal: string = "";
  //VARIABLE PARA LOS ITEMS DE PAYPAL
  public itemsPayPal: ITransactionItem[] = [];
  //VARIABLE QUE ALMACENA EL CLIENTID DEL PAYPAL TRAAIDO DESDE BASE CON BASE A LA INSTITUCIONID
  public PagoCompletado: boolean = false;
  public Integracion: boolean = false;
  public PaypalCredentials: PaypalCredentials;
  //#endregion

  //#region Properties Authorize
  //VARIABLE CONFIGURACION AUTHORIZE
  public amount: string = "";
  //VARIABLE OBJETO DE TRANSACCION
  public transactionRequest: TransactionRequest;
  //VARIABLE CREDENCIALES AUTHORIZE
  public AuthorizeCredentials: AuthorizeCredentials;

  //#region  Constructor
  constructor(
    public dialogRef: MatDialogRef<PagoZumaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer,
    private snackService: SnackService,
    public pagoLineaZumaService: PagoLineaZumaService,
    public PagoLineaPaypalServiceService: PagoLineaPaypalServiceService,
    public PagoLineaAuthorizeService: PagoLineaAuthorizeService,
    private institucionService: InstitucionService,
    private traductorService: TraductorService,
    private router: Router
  ) {}
  //#endregion

  //#region  LifeCycle
  ngOnInit(): void {
    console.log("this.data", this.data);
    this.inicializarForm();
    this.GetIntegracion();
    this.obtenerClientIdPaypal();
    this.obtenerAuthorizeCredentials();
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  //#endregion

  //#region Form
  /**
   * Inicializa el formulario para escoger metodo de paago
   */
  public inicializarForm() {
    this.formaPagoForm = new FormGroup({
      tipoTarjeta: new FormControl(1, [Validators.required]),
      formaPago: new FormControl(1, [Validators.required]),
    });
    this.tipoTarjeta.valueChanges.subscribe((value) => {
      this.formaPagoForm.patchValue({
        formaPago: 1,
      });
      if (value === 1) {
        this.tipoTarjetaDebito = true;
      } else {
        this.tipoTarjetaDebito = false;
      }
      if (value === 3) {
        this.tipoPayPal = true;
      } else {
        this.tipoPayPal = false;
      }
    });
  }
  get tipoTarjeta() {
    return this.formaPagoForm.get("tipoTarjeta");
  }
  get formaPago() {
    return this.formaPagoForm.get("formaPago");
  }

  //#endregion

  //#region Zuma
  /**
   * Metodo para guardar la referencia del pago en zuma
   */
  public enviarReferenciaPago(): void {
    if (this.tipoPayPal) {
      let Referencia: any = {
        Referencia: this.data.reference,
      };
      this.subscription.add(
        this.PagoLineaPaypalServiceService.EnviarReferenciaPaypal(
          Referencia
        ).subscribe((respuesta: ApiResponse<string>) => {
          if (respuesta.success) {
            //this.enviarPagoPaypal(respuesta.data);
            let _PagoPaypal: PagoPaypal = {
              referencia: respuesta.data,
              monto: this.data.amount,
              email: this.data.email,
              descripcion: "Curso",
              paymentChannel: "Paypal_API",
            };
            this.guardarIntentoPago(_PagoPaypal);
            this.initConfig(respuesta.data);
            this.showPayPal = true;
          } else {
            this.snackService.mostrarSnackBack(respuesta.message);
          }
        })
      );
    } else {
      this.showPayPal = false;
      let Referencia: any = {
        referenciaZuma: this.data.reference,
      };
      this.subscription.add(
        this.pagoLineaZumaService
          .enviarReferenciaZuma(Referencia)
          .subscribe((respuesta: ApiResponse<string>) => {
            if (respuesta.success) {
              this.enviarPagoZuma(respuesta.data);
            } else {
              let mensaje: string =
                this.traductorService.translate("errorGuardar");
              this.snackService.mostrarSnackBack(mensaje);
            }
          })
      );
    }
  }

  /**
   * Metodo que llama el api de zuma y genera el folio para hacer el pago
   */
  public enviarPagoZuma(referencia: string): void {
    let dataForm: any = Object.assign(this.formaPagoForm.value);
    let pagoZuma: PagoZuma = {
      reference: referencia,
      amount: this.data.amount,
      promotion: dataForm.formaPago,
      productCode: "AWPE_RPVT_XUG",
      expire: 1,
      sendLink: false,
      email: this.data.email,
      paymentChannel: "ZUMA_API",
    };
    this.subscription.add(
      this.pagoLineaZumaService
        .PostPagoZuma(pagoZuma)
        .subscribe((respuesta: ApiResponse<PagoZumaResponse>) => {
          if (respuesta.success) {
            this.pagoLineaZumaService.metodoInvocarMetodo(
              pagoZuma,
              respuesta.data
            );
            this.respuestaZuma = respuesta.data;
            this.mostrarLink(respuesta.data.result.code);
          } else {
            let mensaje: string =
              this.traductorService.translate("errorGuardar");
            this.snackService.mostrarSnackBack(mensaje);
          }
        })
    );
  }

  /**
   * Metodo para usar el sanitizer con el link de zuma y mostrarlo en el iframe
   * @param link
   */
  public mostrarLink(link: string): void {
    if (!link || link == "") {
      let mensaje: string = this.traductorService.translate("errorGuardar");
      this.snackService.mostrarSnackBack(mensaje);
    } else {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(link);
      this.infoCargada = true;
    }
  }

  //#endregion

  //#region Paypal

  /**
   * Metodo para verificar si la institución tiene registros en tabla integración
   */
  public GetIntegracion(): void {
    this.subscription.add(
      this.PagoLineaPaypalServiceService.GetIntegracion().subscribe(
        (_Integracion: ApiResponse<boolean>) => {
          if (_Integracion.success) {
            this.Integracion = _Integracion.data;
            //console.log(" this.clientIdPayPal", this.clientIdPayPal);
          } else {
            console.log(_Integracion.message);
          }
        }
      )
    );
  }

  /**
   * Metodo que obtiene el clientId para hacer
   * la conexion con Paypal
   */
  public obtenerClientIdPaypal(): void {
    this.subscription.add(
      this.PagoLineaPaypalServiceService.GetCredendialsPaypal().subscribe(
        (_PaypalCredentials: ApiResponse<PaypalCredentials>) => {
          if (_PaypalCredentials !== null) {
            this.PaypalCredentials = _PaypalCredentials.data;
            //console.log(" this.clientIdPayPal", this.clientIdPayPal);
          } else {
            //console.log("ERROR, DONT FOUND ANY CLIENTID PAYPAL ON INSTITUCION.")
          }
        }
      )
    );
  }

  /**
   * Metodo que obtiene credenciales para hacer
   * la conexion con Authorize
   */
  public obtenerAuthorizeCredentials(): void {
    this.subscription.add(
      this.PagoLineaAuthorizeService.GetCredentialsAuthorize().subscribe(
        (_AuthorizeCredentials: ApiResponse<AuthorizeCredentials>) => {
          if (_AuthorizeCredentials !== null) {
            // console.log(_AuthorizeCredentials);
            this.AuthorizeCredentials = _AuthorizeCredentials.data;
            //console.log(" this.clientIdPayPal", this.clientIdPayPal);
          } else {
            //console.log("ERROR, DONT FOUND ANY CLIENTID PAYPAL ON INSTITUCION.")
          }
        }
      )
    );
  }

  /**
   * Metodo que incializa el pago que se debe
   * hacer por medio de Paypal
   */
  private initConfig(ReferenciaPaypalId: string): void {
    this.payPalConfig = {
      currency: this.PaypalCredentials.currency,
      clientId: this.PaypalCredentials.clientId,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: "CAPTURE",
          purchase_units: [
            {
              reference_id: ReferenciaPaypalId /*FALTA*/,
              amount: {
                currency_code: this.PaypalCredentials.currency,
                value: this.data.amount.toString() /*FALTA*/,
              },
            },
          ],
          application_context: {
            brand_name: this.PaypalCredentials.brandName,
            user_action: "PAY_NOW",
          },
        },
      advanced: {
        commit: "true",
      },
      style: {
        label: "paypal",
        layout: "vertical",
      },
      onApprove: async (data, actions) => {
        //console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details) => {
          //console.log('onApprove - you can get full order details inside onApprove: ', details);
        });

        const order = await actions.order.capture();
        //console.log("order", order);
      },
      onClientAuthorization: (data) => {
        //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.PagoCompletedFront(data);
      },
      onCancel: (data, actions) => {
        //console.log('OnCancel', data, actions);
      },
      onError: (err) => {
        //console.log('OnError', err);
      },
      onClick: (data, actions) => {
        //console.log('onClick', data, actions);
      },
    };
  }

  public PagoCompletedFront(_PagoPaypal: any): void {
    this.subscription.add(
      this.PagoLineaPaypalServiceService.PagoCompletedFront(
        _PagoPaypal
      ).subscribe((respuesta: SimpleResponse) => {
        if (respuesta.success) {
          this.PagoCompletado = respuesta.success;
          this.dialogRef.close();
          this.router.navigate(["/ensenanza-aprendizaje-Alumno"]);
        } else {
          this.snackService.mostrarSnackBack(respuesta.message);
        }
      })
    );
  }

  /**
   * Metodo que llama el api de Paypal y genera el folio para hacer el pago
   */
  public enviarPagoPaypal(referencia: string): void {
    let dataForm: any = Object.assign(this.formaPagoForm.value);
    let _PagoPaypal: PagoPaypal = {
      referencia: referencia,
      monto: this.data.amount,
      email: this.data.email,
      descripcion: "Curso",
      paymentChannel: "Paypal_API",
    };
    this.subscription.add(
      this.PagoLineaPaypalServiceService.CreateOrder(_PagoPaypal).subscribe(
        (respuesta: ApiResponse<OrderCreate>) => {
          if (respuesta.success) {
            this.respuestaPaypal = respuesta.data;
            let _Url = respuesta.data.links.find(
              (x) => x.rel === "approve"
            ).href;
            _PagoPaypal.url = _Url;

            let _Token = respuesta.data.id;
            _PagoPaypal.token = _Token;

            let _Status = respuesta.data.status;
            _PagoPaypal.estatus = _Status;

            this.guardarIntentoPago(_PagoPaypal);

            this.mostrarLink(_Url);
          } else {
            let mensaje: string =
              this.traductorService.translate("errorGuardar");
            this.snackService.mostrarSnackBack(mensaje);
          }
        }
      )
    );
  }

  /**
   * Metodo que sirve para guardar el intento pago
   */
  public guardarIntentoPago(_PagoPaypal: PagoPaypal): void {
    let PagoIntento: IntentoPagoPaypalDTO = {
      cargos: new Array<EstadoCuentaDto>(),
      pagoPaypal: _PagoPaypal,
    };
    this.subscription.add(
      this.PagoLineaPaypalServiceService.PostIntentoPago(PagoIntento).subscribe(
        (respuesta: SimpleResponse) => {
          if (respuesta.success) {
            //console.log("GUARDADO INTENTO PAGO CORRECTO");
          } else {
            let mensaje: string =
              this.traductorService.translate("errorGuardar");
            this.snackService.mostrarSnackBack(mensaje);
          }
        }
      )
    );
  }
  //#endregion

  //#region Extras
  public cerrarModal() {
    if (this.infoCargada) {
      let link: LinkEstatus = {
        longCode: this.respuestaZuma.result.code,
      };
      this.subscription.add(
        this.pagoLineaZumaService
          .PostLinkEstatus(link)
          .subscribe((response: ApiResponse<any>) => {
            if (response.success) {
              if (response.data.result.status === "approved") {
                this.dialogRef.close();
                this.router.navigate(["/ensenanza-aprendizaje-Alumno"]);
              } else {
                this.dialogRef.close();
              }
            } else {
              let mensaje: string =
                this.traductorService.translate("errorGuardar");
              this.snackService.mostrarSnackBack(mensaje);
            }
          })
      );
    } else {
      this.dialogRef.close();
    }
  }
  //#endregion
}
