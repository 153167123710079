import { TraductorService } from './../_services/traductor.service';
import { map } from 'rxjs/operators';
import { BlockService } from '@app/_services/block.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { GeneracionExcelServiceService } from '../services/generacion-excel-service.service';
import { ImprimirServiceService } from '../services/imprimir-service.service';
import { HistorialAcademico } from '../_models/HistorialAcademico.model';
import { UsuarioIngreso } from '../_models/usuarionuevoingresodto';
import { AdminAcademicaService } from '../_services/admin-academica.service';
import { NuevoIngresoService } from '../_services/nuevo-ingreso.service';
import { ProgramaAcademicoService } from '../_services/programa-academico.service';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Ciclo } from '../_models/ciclo';
import { AuthenticationService } from '../_services/authentication.service';
import { CampusService, PeriodoService, InscripcionService } from '../_services';
import { Campus, Periodo } from '../_models';
import { Programa } from '../_models/programa';
import { EstatusAcademico } from '../_models/inscripcion';
import { EnsenanzaAprendizajeService } from '../_services/ensenanza-aprendizaje.service';

@Component({
  selector: 'app-transcript',
  templateUrl: './transcript.component.html',
  styleUrls: ['./transcript.component.scss']
})
export class TranscriptComponent implements OnInit {
  public base64Image: string;
  public imagePath: SafeResourceUrl;
  public cicloList: any[] = [];
  public estatusList: EstatusAcademico[] = [];
  public historial: HistorialAcademico[] = [];
  public cicloListFusion: any[] = [];
  public materiasHistorial: any;
  public displayedColumns: string[] = ['Clave', 'Nombre', 'Créditos', 'Calificación', 'Periodo', 'Estatus'];
  dataSource: MatTableDataSource<any>;
  ExcelSubscription: Subscription;
  public infoCargado: boolean = false;
  public datosUsuario: any;
  public datosCompletoUsuario: any;
  public periodos: Periodo[] = [];
  public programas: Programa[] = [];
  public arCampus: Campus[] = [];
  public identificadorAlumno: string = "";
  public promedios: any;
  public blockName: string = null;
  public primerPeriodo: any;
  public ultimoPeriodo: any;

  constructor(private nuevoIngresoService: NuevoIngresoService,
    private sanitizer: DomSanitizer,
    private programaAcademicoService: ProgramaAcademicoService,
    private adminAcademiaService: AdminAcademicaService,
    public adminAcademicaService: AdminAcademicaService,
    public imprimirService: ImprimirServiceService,
    public generadorExcel: GeneracionExcelServiceService,
    public usuarioService: AuthenticationService,
    public periodoservice: PeriodoService,
    public inscripcionService: InscripcionService,
    private enseñanzaAprendizajeService: EnsenanzaAprendizajeService,
    public campusservice: CampusService,
    public blockLabelService: BlockService,
    private traductorService: TraductorService) {
    if (!!this.usuarioService.isAuthenticated) {
      this.enseñanzaAprendizajeService.obtenerUsuarioConectado().subscribe((datos) => {
        this.identificadorAlumno = sessionStorage.getItem('identificador');
        this.datosUsuario = this.usuarioService.obtenerDatosUsuario(this.identificadorAlumno).subscribe((data) => {
          this.datosCompletoUsuario = data;
          this.obtenerUltimoPeriodo();
          this.obtenerBloqueNombre();
          this.cargarImagen();
          this.obtenerInfoParaCiclos();
          this.getPromedios(this.identificadorAlumno);
        });
      });
    }
  }

  ngOnInit(): void {
    this.obtenerDatos();
  }

  /**
   * Obtenemos el nombre del bloque si esta asignado a alguno
   */
  public obtenerBloqueNombre(): void {
    this.blockLabelService.ObtenerBlockLabelName(this.datosCompletoUsuario.alumno[0].alumnoId).subscribe((response: ApiResponse<string>) => {
      this.blockName = response.data;
    });
  }

  /**
   * Metodo para obtener el identificador
   * @param identificador 
   */
  public getPromedios(identificador: string): void {
    this.adminAcademicaService.getPromedios(identificador).subscribe((response: ApiResponse<any>) => {
      this.promedios = response.data;
    });
  }

  /**
   * Funcion que carga la imagen del usuario si tiene
   */
  public cargarImagen(): void {
    this.nuevoIngresoService.imagenUsuario(this.datosCompletoUsuario.usuarioId).subscribe(
      (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
        if (datosUsuarioImagen.success) {
          this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
        }
      }
    );
  }

  public obtenerDatos() {
    this.periodoservice.ObtenerPeriodoFechaInicio().subscribe(
      (periodos: Periodo[]) => {
        this.periodos = periodos;
      }
    );
    this.campusservice.obtenerCampus().subscribe(
      (campus: Campus[]) => {
        this.arCampus = campus;
      }
    );
    this.inscripcionService.GetEstatusAcademicos().subscribe(
      (status: ApiResponse<EstatusAcademico[]>) => {
        this.estatusList = status.data;
      }
    );
  }

  public findNombrePeriodo(idPeriodo?: number) {
    return this.periodos.find(id => id.periodoId === idPeriodo).nombre;
  }

  public findNombreCampus(idCampus?: number) {
    return this.arCampus.find(id => id.campusId === idCampus).nombre;
  }

  public findNombreEstatus(estatus?: string) {
    if (estatus !== "") {
      let mensaje: string = this.traductorService.translate(estatus);
      return mensaje;
    } else {
      return "";
    }
  }
  /**
   * Funcion que obtiene los ciclos en los que ha estado inscrito el alumno
   */
  obtenerInfoParaCiclos() {
    this.programaAcademicoService.obtenerCiclosByPrograma(this.datosCompletoUsuario.alumno[0].programaId).subscribe((ciclo: Ciclo[]) => {
      this.cicloList = ciclo;
      for (let i = 0; i < this.cicloList.length; i++) {
        this.obtenerMateriasHistorialAcademico(this.cicloList[i]);
      }
    });
  }

  public obtenerUltimoPeriodo(): void {
    this.periodoservice.obtenerUltimoPeriodo(this.datosCompletoUsuario.alumno[0].alumnoId).subscribe((response: ApiResponse<any>) => {
      if (response.success) {
        console.log("DATA", response.data)
        this.primerPeriodo = response.data[1];
        this.ultimoPeriodo = response.data[0];
      } else {
        console.log("ERROR");
      }
    });
  }

  public obtenerMateriasHistorialAcademico(cicloList: any) {
    this.adminAcademiaService.getHistorialAcademico(this.datosCompletoUsuario.alumno[0].alumnoId, this.datosCompletoUsuario.alumno[0].priodoIngreso, cicloList.cicloId).subscribe(
      (respuestaHistorial: ApiResponse<any>) => {
        this.materiasHistorial = respuestaHistorial.data;
        let prueba = cicloList.materias = respuestaHistorial.data;

        /** Descomentar esta sección cuando ya existan varios registros */
        let promedio = this.materiasHistorial[0].promedio;

        this.cicloListFusion.push(prueba);

        this.datosCompletoUsuario.alumno[0].promedio = promedio;
        this.datosCompletoUsuario.alumno[0].promedioPeriodo = promedio;
        this.dataSource = new MatTableDataSource(prueba);
        this.infoCargado = true;
      }
    );
  }

  public imprimirDocumento(): void {
    window.print();
  }

  public descargarExcel(): void {
    for (let i = 0; i < this.cicloList.length; i++) {
      this.cicloList[i].materias = this.cicloList[i].materias.map(obj => ({
        ...obj,
        blockCode: this.blockName
      }));
    }
    this.ExcelSubscription = this.adminAcademicaService.descargarSheetDocumentHistorial(this.cicloList).subscribe((res: Blob) => {
      let filename: string = `HistorialAcademico${this.datosCompletoUsuario.nombre}${this.datosCompletoUsuario.apellidoPaterno}${this.datosCompletoUsuario.apellidoMaterno}`;
      this.generadorExcel.GenerarExcel(res, filename);
      this.ExcelSubscription.unsubscribe();
    });
  }

  public imprimir() {
    const printContent: HTMLElement = document.getElementById("historial");
    const encabezadoContent = `<div class="encabezado-flex">
                                <p><strong>ID:</strong>${this.datosCompletoUsuario.identificador}
                                <strong style="margin-left: 40px;">Campus:</strong>${this.datosCompletoUsuario.alumno[0].campusId}
                                <strong style="margin-left: 40px;">Alumno:</strong>${this.datosCompletoUsuario.nombre + ' ' + (this.datosCompletoUsuario.segundoNombre === null ? ' ' : this.datosCompletoUsuario.segundoNombre) + ' ' + this.datosCompletoUsuario.apellidoPaterno + ' ' + this.datosCompletoUsuario.apellidoMaterno}</<strong>
                                <strong style="margin-left: 40px;">Programa:</strong>${this.datosCompletoUsuario.alumno[0].programaId}</<strong>
                                <strong style="margin-left: 40px;">Bloque:</strong>${this.blockName === null ? 'Sin Bloque' : this.blockName}</<strong></p>
                              </div>`;
    let nombreAlumno = `${this.datosCompletoUsuario.nombre + ' '}${this.datosCompletoUsuario.segundoNombre === null ? ' ' : this.datosCompletoUsuario.segundoNombre}${this.datosCompletoUsuario.apellidoMaterno + ' '}${this.datosCompletoUsuario.apellidoMaterno}`
    this.imprimirService.Imprimir(printContent, nombreAlumno, encabezadoContent);
  }

  public findNombreEstatusMateria(estatus?: string) {
    if (estatus !== "") {
      let mensaje: string = this.traductorService.translate(estatus);
      return mensaje;
    } else {
      return "";
    }
  }

}
