import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { ServicioPaqueteService } from './../../_services/servicio-paquete.service';
import { TraductorService } from 'src/app/_services/traductor.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { ServicePackageSeguimientoDto, ServiciosPaquete } from './../../_models/ServicePackageSeguimientoDto';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'app-servicio-paquete-modal',
  templateUrl: './servicio-paquete-modal.component.html',
  styleUrls: ['./servicio-paquete-modal.component.scss']
})
export class ServicioPaqueteModalComponent implements OnInit, OnDestroy {
  @ViewChild('dialogoAgregarServicioModal') dialogoAgregarServicioModal: TemplateRef<any>;
  @ViewChild('dialogoListaServicioModal') dialogoListaServicioModal: TemplateRef<any>;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginatorLista') paginatorLista: MatPaginator;
  // @ViewChild('paginatorLista', { static: true }) paginatorLista: MatPaginator;
  public displayedColumns: string[] = ['select', 'code', 'name', 'description', 'status'];
  public displayedColumnsListaServicio: string[] = ['select', 'code', 'name', 'description', 'status'];
  public agregarPaqueteServicioFrom: FormGroup;
  public editarPaqueteServicioFrom: FormGroup;
  public esNuevoPaquete: boolean = true;
  public dialogoRefAgregarServiciosModal: any;
  public dialogoRefAgregarServicioModal: any;
  public dialogoRefListaServiciosModal: any;
  public boolCambio: boolean = false;
  public dialogoRefAdver: any;
  public nuevoPaquete: boolean = false;
  public numServicioSeleccionado = 0;
  public numServicioSeleccionadoLista = 0;
  selection = new SelectionModel<any>(true, []);
  selectionListaServicio = new SelectionModel<any>(true, []);
  public dataSource: MatTableDataSource<any>;
  public dataSourceListaServicios: MatTableDataSource<any>;
  public infoCargado: boolean = false;
  private arrOriginal: any[] = [];
  private arrOriginalServicioAsignado: any[] = [];
  public datafilter: any;
  public infoCargadoLista: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ServicioPaqueteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ServicePackageSeguimientoDto,
    private traductorService: TraductorService,
    private snackService: SnackService,
    private dialog: MatDialog,
    private servicioPaqueteService: ServicioPaqueteService,
    public nuevoIngresoService: NuevoIngresoService,
    private paginador: MatPaginatorIntl) { }

  ngOnInit(): void {
    this.nuevoPaquete = this.data.nuevoPaquete;
    if (this.nuevoPaquete) {
      this.inicializarAgregarForm();
      this.inicializarPaginador();
      this.dataSource = new MatTableDataSource([]);
      this.selection = new SelectionModel<any>(true, []);
      this.infoCargado = true;
    } else {
      this.inicializarEditarForm();
      this.inicializarPaginador();
      this.obtenerPaqueteById();
    }
  }

  /**
   * Metodo para generar formulario para agregar un nuevo paquete
   */
  public inicializarAgregarForm(): void {
    this.agregarPaqueteServicioFrom = new FormGroup({
      code: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      status: new FormControl(1, [Validators.required])
    });
    this.agregarPaqueteServicioFrom.valueChanges.subscribe(_ => {
      this.boolCambio = true;
    });
  }
  get code() { return this.agregarPaqueteServicioFrom.get('code'); }
  get name() { return this.agregarPaqueteServicioFrom.get('name'); }
  get status() { return this.agregarPaqueteServicioFrom.get('status'); }

  /**
   * Metodo para agregar un nuevo paquete
   */
  public agregarPaquete(): void {
    let dataGuardar = this.agregarPaqueteServicioFrom.value;
    this.servicioPaqueteService.crearPaquetesServicio(dataGuardar).subscribe((response: ApiResponse<ServicePackageSeguimientoDto>) => {
      if (response.success) {
        this.boolCambio = false;
        this.data = response.data;
        this.data.nuevoPaquete = false;
        this.nuevoIngresoService.invocarMetodoObtenerCandidato();
        this.inicializarEditarForm();
        this.inicializarPaginador();
        let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
        this.snackService.mostrarSnackBack(mensaje);
        this.abrirModalAgregarGrupos();
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
   * Metodo para generar formulario de edicion
   */
  public inicializarEditarForm(): void {
    this.editarPaqueteServicioFrom = new FormGroup({
      servicePackageId: new FormControl(this.data.servicePackageId),
      name: new FormControl(this.data.name, [Validators.required]),
      status: new FormControl(this.data.status, [Validators.required])
    });
    this.editarPaqueteServicioFrom.valueChanges.subscribe(_ => {
      this.boolCambio = true;
    });
  }
  get nameEdit() { return this.editarPaqueteServicioFrom.get('name'); }

  /**
   * Metodo para iniciar el paginador de la tabla
   */
  public inicializarPaginador(): void {
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.itemsPerPageLabel = "Registros por página";
    this.paginador.nextPageLabel = "Página siguiente";
    this.paginador.previousPageLabel = "Página anterior";
    this.paginador.firstPageLabel = "Primera página";
    this.paginador.lastPageLabel = "Última página";
    this.paginador.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  /**
   * Metodo para obtener la informacion del paquete a editar
   */
  public obtenerPaqueteById() {
    this.servicioPaqueteService.paquetesServicioById(this.data.servicePackageId).subscribe((response: ApiResponse<ServicePackageSeguimientoDto>) => {
      if (response.success) {
        if (response.data.serviciosPaquete !== null) {
          this.data.serviciosPaquete = response.data.serviciosPaquete
          this.dataSource = new MatTableDataSource(this.data.serviciosPaquete);
          this.arrOriginalServicioAsignado = [...response.data.serviciosPaquete];
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargado = true;
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 100);
        } else {
          this.dataSource = new MatTableDataSource([]);
          this.selection = new SelectionModel<any>(true, []);
          this.infoCargado = true;
        }
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
   * Metodo para agregar un nuevo paquete
   */
  public EditarPaquete(): void {
    let dataEditar = this.editarPaqueteServicioFrom.value;
    dataEditar.serviciosPaquete = this.dataSource.data
    this.servicioPaqueteService.editarPaquetesServicio(dataEditar).subscribe((response: ApiResponse<ServicePackageSeguimientoDto>) => {
      if (response.success) {
        this.boolCambio = false;
        this.data = response.data;
        this.data.nuevoPaquete = false;
        this.nuevoPaquete = this.data.nuevoPaquete;
        this.nuevoIngresoService.invocarMetodoObtenerCandidato();
        let mensaje: string = this.traductorService.translate("_area-materia.guardado-correcto");
        this.snackService.mostrarSnackBack(mensaje);
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
   * Metodo que abre el modal y muestra los servicios disponibles
   */
  public agregarServicio(): void {
    this.servicioPaqueteService.obtenerServiciosPaquete().subscribe((response: ApiResponse<ServiciosPaquete[]>) => {
      if (response.success) {
        if (response.data !== null) {
          let result = this.arrOriginalServicioAsignado.map(obj => ({
            servicioId: obj.servicioId
          }));
          let gruposDisponibles = response.data.filter(function (objFromA) {
            return !result.find(function (objFromB) {
              return objFromA.servicioId == objFromB.servicioId
            })
          })
          this.arrOriginal = [...gruposDisponibles];
          this.dataSourceListaServicios = new MatTableDataSource(gruposDisponibles);
          this.datafilter = this.arrOriginal;
          this.selectionListaServicio = new SelectionModel<any>(true, []);
          this.infoCargadoLista = true;
          setTimeout(() => {
            this.dataSourceListaServicios.paginator = this.paginatorLista;
          }, 100);
        } else {
          this.dataSourceListaServicios = new MatTableDataSource([]);
          this.selectionListaServicio = new SelectionModel<any>(true, []);
          this.infoCargadoLista = true;
        }
        this.dialogoRefListaServiciosModal = this.dialog.open(this.dialogoListaServicioModal, {
          data: this.data,
          panelClass: "dialogo-agregar",
          width: '50%',
          height: 'auto',
          maxHeight: '80vh',
          disableClose: true
        });
      } else {
        this.snackService.mostrarSnackBack(response.message);
      }
    });
  }

  /**
   * Metodo para abrir el modal para saber si quiere agregar servicios
   */
  public abrirModalAgregarGrupos() {
    this.dialogoRefAgregarServicioModal = this.dialog.open(this.dialogoAgregarServicioModal, { disableClose: true });
  }

  /**
   * Metodo para cerrar o el modal segun la desicion del usuario
   * @param agregar 
   */
  public agregarServicios(agregar: boolean) {
    if (agregar) {
      this.nuevoPaquete = this.data.nuevoPaquete;
    } else {
      this.dialogRef.close();
    }
    this.dialogoRefAgregarServicioModal.close();
  }

  /**
   * Metodo para abrir el modal de cambios pendientes
   */
  public cerrarModalValidacion(): void {
    if (this.boolCambio) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Metodo para cerrar todos los modales y solo el de validacion de cambios pendientes
   * @param descartar 
   */
  public cerrarModalAdvertencia(descartar: boolean): void {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close();
    } else {
      this.dialogoRefAdver.close();
    }
  }

  /**
   * Metodo para cerrar el modal de la lista de servicios
   */
  public cerrarModalListaServicios() {
    this.dialogoRefListaServiciosModal.close();
  }

  /**
   * Metodo para agregar los servicios 
   * @param elemento 
   */
  public anadirServicios(elemento: ServiciosPaquete[]) {
    this.arrOriginalServicioAsignado = this.arrOriginalServicioAsignado.concat(elemento);
    this.dataSource = new MatTableDataSource(this.arrOriginalServicioAsignado);
    this.selection = new SelectionModel<any>(true, []);
    this.dataSource.paginator = this.paginator;
    this.dialogoRefListaServiciosModal.close();
  }

  /**
   * Metodo para eliminar los servicios 
   * @param elemento 
   */
  public borrarServicios(elemento: ServiciosPaquete[]) {
    let result = elemento.map(obj => ({
      servicioId: obj.servicioId
    }));
    this.arrOriginalServicioAsignado = this.arrOriginalServicioAsignado.filter(function (objFromA) {
      return !result.find(function (objFromB) {
        return objFromA.servicioId == objFromB.servicioId
      })
    })
    this.dataSource = new MatTableDataSource(this.arrOriginalServicioAsignado);
    this.selection = new SelectionModel<any>(true, []);
    this.dataSource.paginator = this.paginator;
  }

  /**
    * Metodo Selecion data table
    * @returns 
    */
  isAllSelectedListaServicio() {
    this.numServicioSeleccionadoLista = this.selectionListaServicio.selected.length;
    const numRows = this.dataSourceListaServicios.data.length;
    return this.numServicioSeleccionadoLista === numRows;
  }

  /**
    * Metodo Selecion data table
    * @returns 
    */
  checkboxLabelListaServicio(row?: any): string {
    if (!row) {
      return `${this.isAllSelectedListaServicio() ? 'select' : 'deselect'} all`;
    }
    this.numServicioSeleccionadoLista = this.selectionListaServicio.selected.length;
    return `${this.selectionListaServicio.isSelected(row) ? 'deselect' : 'select'} row ${row.Clave + 1}`;
  }

  /**
    * Metodo Selecion data table
    * @returns 
    */
  masterToggleListaServicio() {
    this.isAllSelectedListaServicio() ?
      this.selectionListaServicio.clear() :
      this.dataSourceListaServicios.data.forEach(row => this.selectionListaServicio.select(row));
  }

  /**
   * Filtro
   * @param filterValue 
   */
  public buscarNombreClave(filterValue: string) {
    filterValue = filterValue.toLowerCase();
    let filtroModificado: any = [];
    filtroModificado = [...this.arrOriginal];
    if (filterValue != "") {
      filtroModificado = filtroModificado.filter(el => {
        return el.code.toLowerCase().search(filterValue) > -1 || el.name.toLowerCase().search(filterValue) > -1;
      })
    }
    this.dataSourceListaServicios = new MatTableDataSource(filtroModificado);
    this.dataSourceListaServicios.paginator = this.paginatorLista;
    this.datafilter = filtroModificado;
  }

  /**
   * Metodo Selecion data table
   * @returns 
   */
  isAllSelected() {
    this.numServicioSeleccionado = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return this.numServicioSeleccionado === numRows;
  }

  /**
   * Metodo Selecion data table
   * @param row 
   * @returns 
   */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.grupoId + 1}`;
  }

  /**
   * Metodo Selecion data table
   */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  ngOnDestroy(): void {
  }
}
