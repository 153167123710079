import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ConfiguracionCuotaService } from '../../../_services/configuracion-cuota.service';
import { CampusService } from '../../../_services/campus.service';
import { NivelService } from '../../../_services/nivel.service';
import { Campus } from '../../../_models/campus';
import { Nivel } from '../../../_models/nivel';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Service } from '../../../_models/service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtileriasService } from '../../../_services/utilerias.service';
import { SelectValidator } from 'src/app/validator/SelectValidator';
import { Subscription } from 'rxjs';
import { SnackService } from 'src/app/services/snack-service.service';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';

@Component({
  selector: 'app-modal-servicios',
  templateUrl: './modal-servicios.component.html',
  styleUrls: ['./modal-servicios.component.scss']
})
export class ModalServiciosComponent implements OnInit, OnDestroy {
  constructor(private cuota: ConfiguracionCuotaService,
    private campus: CampusService,
    private nivel: NivelService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private snackService: SnackService,
    private utileria: UtileriasService,
    public dialogRef: MatDialogRef<ModalServiciosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }


  //#region Propiedades
  public arrNombre: any[];
  public arrCampus: Campus[];
  public arrNivel: Nivel[];
  public agregar: Boolean = true;
  public formCampos: FormGroup;
  private suscripcion = new Subscription();
  //#endregion

  //#region  Métodos
  ngOnInit(): void {
    this.agregar = this.data == null;
    if (this.agregar) {
      this.generarFormularioParaCreacion();
    }
    else {
      this.generarFormularioEdicion();
      this.obtenerNiveles();
    }
    this.llenarCampos();
  }
  ngOnDestroy(): void {
    if (this.suscripcion) {
      this.suscripcion.unsubscribe();
    }
  }
  /**
   * Genera un formulario reactivo para crear un servico nuevo
   */
  private generarFormularioParaCreacion(): void {
    this.formCampos = this.formBuilder.group({
      nombre: [0, Validators.required],
      campus: [0, Validators.required],
      nivel: [0, Validators.required],
      fecha: [this.utileria.formatearFecha(new Date(), 'YYYY[-]MM[-]DD'), Validators.required],
      costo: ['', [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      impuesto: ['', [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      parcialidades: [0, Validators.required],
    },
      {
        validators: [SelectValidator('nombre'), SelectValidator('campus'),
        SelectValidator('nivel')]
      }
    );
  }
  /**
   * Genera un formulario reactivo para Editar un servicio
   */
  private generarFormularioEdicion(): void {
    this.formCampos = this.formBuilder.group({
      nombre: [this.data?.servicio_Id, Validators.required],
      campus: [this.data?.campus_Id, Validators.required],
      nivel: [this.data?.nivel_Id, Validators.required],
      fecha: [this.utileria.formatearFecha(this.data?.fecha_Inicio, 'YYYY[-]MM[-]DD'), Validators.required],
      costo: [this.data?.monto, [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      impuesto: [this.data?.impuesto, [Validators.required, Validators.pattern(/^[0-9\.]/)]],
      parcialidades: [this.data?.parcialidades, Validators.required],
    },
      {
        validators: [SelectValidator('nombre'), SelectValidator('campus'),
        SelectValidator('nivel')]
      });
  }
  /**
   * Consumo los servicios para obtener los campus y los Servicios disponibles
   */
  private llenarCampos(): void {
    this.suscripcion.add(
      this.cuota.getCatalogoServiciosNombres().subscribe((nombres: ApiResponse<any[]>) => {
        this.arrNombre = nombres.data;
      })
    );
    this.suscripcion.add(
      this.campus.obtenerCampus().subscribe((camp: Campus[]) => {
        this.arrCampus = camp;
      })
    );
  }

  public guardar(): void {
    let datos: Service = {
      Servicio_Id: parseInt(this.formCampos.value.nombre),
      Nombre: '',
      Monto: parseFloat(this.formCampos.value.costo),
      Parcialidades: isNaN(parseFloat(this.formCampos.value.parcialidades)) ? 0 : parseFloat(this.formCampos.value.parcialidades) ,
      Campus: '',
      Campus_Id: parseInt(this.formCampos.value.campus),
      Nivel_Id: parseInt(this.formCampos.value.nivel),
      Nivel: '',
      Fecha_Inicio: this.formCampos.value.fecha,
      Impuesto: isNaN(parseFloat(this.formCampos.value.impuesto)) ? 0 : parseFloat(this.formCampos.value.impuesto)
    }
    if (this.agregar) {
      this.cuota.guardarServicio(datos).subscribe(datosEnviados => {
        if (datosEnviados) {
          this.snackBar.open('Se ha guardado el servicio correctamente.', 'OK', { duration: 5000 });
          this.cuota.metodoObtenerServicios();
        } else {
          this.snackBar.open('Ha ocurrido un error al guardar el servicio. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
        }
      });
    }
    else {
      this.cuota.editarServicio(datos).subscribe(datosEnviados => {
        if (datosEnviados) {
          this.snackBar.open('Se ha editado el servicio correctamente.', 'OK', { duration: 5000 });
          this.cuota.metodoObtenerServicios();
        } else {
          this.snackBar.open('Ha ocurrido un error al editar el servicio. Por favor, intente de nuevo más tarde.', 'OK', { duration: 5000 });
        }
      });
    }
  }
  /**
 * Obtiene un listado de niveles que están disponibles para un campus
 */
  public obtenerNiveles(): void {
    if (this.campusConfig.invalid) return;
    this.suscripcion.add(
      this.nivel.obtenerNivelesDelCampus(this.campusConfig.value).subscribe((nivelesResponse: ApiResponse<Array<Nivel>>) => {
        if (nivelesResponse.success) {
          this.arrNivel = nivelesResponse.data;
        }
        else {
          this.snackService.mostrarSnackBack(nivelesResponse.message);
        }
      })
    );
  }
  //#endregion






  //#region Gets
  get nombreConfig() {
    return this.formCampos.get('nombre');
  }
  get campusConfig() {
    return this.formCampos.get('campus');
  }
  get nivelConfig() {
    return this.formCampos.get('nivel');
  }
  get fechaConfig() {
    return this.formCampos.get('fecha');
  }
  get costoConfig() {
    return this.formCampos.get('costo')
  };
  get impuestoConfig() {
    return this.formCampos.get('impuesto')
  };
  // get parcialidadConfig() {return this.formCampos.get('parcialidades')};
  //#endregion
}
