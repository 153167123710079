import { TraductorService } from './../../_services/traductor.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackService } from 'src/app/services/snack-service.service';
import { Aula } from 'src/app/_models/aula';
import { AulaService } from 'src/app/_services/aula.service';

@Component({
  selector: 'app-aula-eliminar',
  templateUrl: './aula-eliminar.component.html',
  styleUrls: ['./aula-eliminar.component.scss']
})
export class AulaEliminarComponent implements OnInit {

  public numAulasSeleccionados: number = 0;
  public boolIsArray: boolean = false;
  public claveAulas = '';

  constructor(
    public dialogRef: MatDialogRef<AulaEliminarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: SnackService,
    private aulaService: AulaService,
    private snackService: SnackService,
    private traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.validarDataRecibida();
  }

  public validarDataRecibida() {
    this.boolIsArray = Array.isArray(this.data);
    if (this.boolIsArray) {
      this.numAulasSeleccionados = this.data.length;
    }
  }

  public eliminar() {
    if (this.boolIsArray) {
      let arrayAulas = this.data.map(a => (a.salondId.toString()));
      this.claveAulas = arrayAulas.toString();
    } else {
      this.claveAulas = this.data.salonId.toString();
    }
    this.aulaService.eliminarAula(this.claveAulas).subscribe(
      eliminado => {
        if (eliminado) {
          this.aulaService.invocarMetodoObtenerAula();
          let mensaje: string = this.traductorService.translate('eliminarCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
          this.dialogRef.close(this.data);
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      },
      error => {
        console.log("error", error);
        let mensaje: string = this.traductorService.translate('errorGuardar');
        this.snackService.mostrarSnackBack(mensaje);
      }
    );
  }

}
