import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment,baseUrl} from '@environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ApiResponse } from '../models/api/ApiRespose.model';
import { TipoAdmision } from '../_models/tipo-admision';

@Injectable({
  providedIn: 'root'
})
export class TipoAdmisionService {

  constructor(private httpClient: HttpClient) { }

  public obtenerTipoAdmision(): Observable<ApiResponse<TipoAdmision[]>> {
    return this.httpClient.get<ApiResponse<TipoAdmision[]>>(`${environment.enves[baseUrl].apiUrl}/TipoAdmision`);
  }
}
