import { TraductorService } from './../../_services/traductor.service';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { forkJoin, Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/api/ApiRespose.model';
import { SimpleResponse } from 'src/app/models/api/SimpleResponse.model';
import { ConfirmationDialogServiceService } from 'src/app/services/confirmation-dialog-service.service';
import { SnackService } from 'src/app/services/snack-service.service';
import { Biografia } from 'src/app/_models/biografia';
import { DatoFiscal } from 'src/app/_models/dato-fiscal';
import { EstudiosPrevios } from 'src/app/_models/estudios-previos';
import { Alumno } from 'src/app/_models/inscripcion';
import { Ocupacion } from 'src/app/_models/Ocupacion.model';
import { PersonaRelacionada } from 'src/app/_models/persona-relacionada';
import { UsuarioIngreso } from 'src/app/_models/usuarionuevoingresodto';
import { AdminAcademicaService } from 'src/app/_services/admin-academica.service';
import { BiografiaService } from 'src/app/_services/biografia.service';
import { NuevoIngresoService } from 'src/app/_services/nuevo-ingreso.service';
import { StudentChangeService } from '../../_services/student-change.service';
import { DatosPersonalesAdminAcademicaComponent } from './datos-personales-admin-academica/datos-personales-admin-academica.component';
import { ProfesorService } from 'src/app/_services/profesor.service';
import { ProgramaAcademicoSeguimientoComponent } from './programa-academico/programa-academico-seguimiento/programa-academico-seguimiento.component';
import { AttributesService } from '../../_services/attributes.service';
import { AttributesGuardarDto } from '../../_models/AttributesDto';
import { DocumentosAdminAcademicaComponent } from './documentos-admin-academica/documentos-admin-academica.component';

interface validarForm {
  renderizado: boolean;
  estado: boolean;
}

@Component({
  selector: 'app-avance-academico-modal',
  templateUrl: './avance-academico-modal.component.html',
  styleUrls: ['./avance-academico-modal.component.scss']
})
export class AvanceAcademicoModalComponent implements OnInit, OnDestroy {

  //#region  Constructor
  constructor(public dialogRef: MatDialogRef<AvanceAcademicoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { alumno: any, indexTap: number },
    public nuevoIngresoService: NuevoIngresoService,
    private administracionAcademcica: AdminAcademicaService,
    private sanitizer: DomSanitizer,
    private snackServiceService: SnackService,
    private biografiaService: BiografiaService,
    private confirmacionService: ConfirmationDialogServiceService,
    private studentChangeService: StudentChangeService,
    private _cd: ChangeDetectorRef,
    private profesorService: ProfesorService,
    private dialog: MatDialog,
    private snackService: SnackService,
    private traductorService: TraductorService,
    public attributesService: AttributesService) {
    this.alumno = data.alumno;
  }
  //#endregion

  //#region ViewChildren
  @ViewChild(DocumentosAdminAcademicaComponent) cargaDocumentos: DocumentosAdminAcademicaComponent;
  @ViewChild(DatosPersonalesAdminAcademicaComponent) datosPersonalesComponent: DatosPersonalesAdminAcademicaComponent;
  //#endregion
  //#region Propiedades
  indexTap: FormControl = new FormControl();
  alumno: any;
  base64Image: string;
  imagePath: SafeResourceUrl;
  private cambiosSinGuardar: boolean;
  public estadoFormularioDatos: boolean;
  private errorAlActualizar: boolean;
  datosUsuarioFrom: any;
  private Subscription: Subscription = new Subscription();
  public imagenUsuarioForm: FormGroup;
  public imageUsuarioEditar: any;
  public imagePath2: any;
  public imagePath3;
  public imgURL: any;
  public message: string;
  public eventImage: any;
  public eventoImagenPreview: any;
  public boolImagen: boolean = false;
  public dialogoRefAdver: any;
  public cambioAnterior: boolean;
  @ViewChild('dialogAdvertencia') dialogAdvertencia: TemplateRef<any>;
  @ViewChild(ProgramaAcademicoSeguimientoComponent) programaAcademico: ProgramaAcademicoSeguimientoComponent;
  //#endregion

  //#region  Métodos
  ngOnInit(): void {
    this.indexTap.setValue(this.data.indexTap);
    this.cargarImagen();
    this.renderizarFoto();
    this.Subscription.add(
      this.administracionAcademcica.getSubjetEstadoFormularioDatosUsuario().subscribe((cambio: validarForm) => {
        if (cambio.renderizado) {
          this.estadoFormularioDatos = cambio.estado;
          this.cambioAnterior = cambio.estado;
          this.cambiosSinGuardar = false;
        } else {
          this.estadoFormularioDatos = cambio.estado;
          this.cambiosSinGuardar = true;
        }
      })
    );

    if (this.studentChangeService.invocarObtenerContenidoSubscription == undefined) {
      this.studentChangeService.invocarObtenerContenidoSubscription = this.studentChangeService.invocarObtenerContenido.subscribe(() => {
        this.recargarDatosEncabezado(this.data.alumno.alumnoId);
      });

      this.studentChangeService.invocarObtenerContenidoSubscription = undefined;
    }
  }

  /**
   * Cierra el modal actual que se ve en pantalla
  */
  public CerrarModal(): void {
    if (this.cambiosSinGuardar) {
      this.mostrarModalCambiosSinGuardar();
    }
    else {
      this.dialogRef.close();
    }
  }
  /**
   * consume el servicio que consulta la imagen del usuario con base al Id del mismo
   */
  cargarImagen(): void {
    this.Subscription.add(
      this.nuevoIngresoService.imagenUsuario(this.alumno.usuarioId).subscribe(
        (datosUsuarioImagen: ApiResponse<UsuarioIngreso>) => {
          if (datosUsuarioImagen.success) {
            this.base64Image = 'data:image/jpg;base64,' + datosUsuarioImagen.data.fotografia;
            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
          }
        }
      )
    );
  }
  /**
   * Envía al servicio los datos del formulario
   */
  public async guardar() {
    if (this.indexTap.value === 1) {
      let atributosGuardar: AttributesGuardarDto = {
        alumnoId: this.data.alumno.alumnoId,
        attributes: this.programaAcademico.filtroForm.value.atributoF,
        tipoCandidato: this.programaAcademico.AdmisionesForm.value.tipoCandidato
      }
      this.attributesService.guardarAtributosAlumno(atributosGuardar).subscribe((response: SimpleResponse) => {
        if (response.success) {
          if (response.message != "") {
            if (this.data.alumno.subProcesoAlumno == null) {
              this.data.alumno.subProcesoAlumno = {
                tipo: response.message
              }
            } else {
              this.data.alumno.subProcesoAlumno.tipo = response.message;
            }
            this.cargaDocumentos.existeSubProceso = true;
          } else {
            this.cargaDocumentos.existeSubProceso = false;
          }
          this.administracionAcademcica.actualizarDatosTabla();
          let mensaje: string = this.traductorService.translate('guardadoCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      });
    } else {
      this.datosUsuarioFrom = this.datosPersonalesComponent.datosPeronalesForm.value;
      this.comprobarDisponibilidadCorreo();
      this.cambiosSinGuardar = false;
    }
  }
  /**
   * Corrobora que el el correo que es está intentando guardar no está siendo usado por otro usuario
   */
  private comprobarDisponibilidadCorreo(): void {
    this.Subscription.add(
      this.nuevoIngresoService.obtenerCorreoOcupado(this.datosUsuarioFrom.correo, this.alumno.usuario.usuarioId).subscribe(
        (ocupado: SimpleResponse) => {
          if (ocupado.success) {
            let mensaje: string = this.traductorService.translate('errorCorreo');
            this.snackService.mostrarSnackBack(mensaje);
            this.errorAlActualizar = true;
          }
          else {
            this.editarDatosPeronales();
          }
        })
    );
  }
  /**
   * Ejecuta el método del Api que cambia los datos del Usuario así como de la biografía
   */
  private editarDatosPeronales(): void {
    let datosPersonales = {
      correo: this.datosUsuarioFrom.correo,
      nombre: this.datosUsuarioFrom.nombre,
      segundoNombre: this.datosUsuarioFrom.segundoNombre,
      usuarioId: this.alumno.usuarioId,
      apellidoPaterno: this.datosUsuarioFrom.apellidoPaterno,
      apellidoMaterno: this.datosUsuarioFrom.apellidoMaterno,
      biografia: {
        fechaNacimiento: this.datosUsuarioFrom.fechaNacimiento
      }
    };
    this.Subscription.add(
      this.nuevoIngresoService.editarDatosPersonales(datosPersonales).subscribe((exito: SimpleResponse) => {
        if (exito.success) {
          this.editarDatosDeContacto();
        }
        else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
          this.errorAlActualizar = true;
        }
      })
    );
  }
  /**
   * Ejecuta el método del api que edita los datos de contacto del usuario
   */
  private editarDatosDeContacto(): void {
    let datosContacto = {
      usuarioId: this.alumno.usuario.usuarioId,
      correo: this.datosUsuarioFrom.correo,
      direccion: [
        {
          tipoDireccionId: this.datosUsuarioFrom.tipoDireccion,
          paisId: this.datosUsuarioFrom.pais,
          entidadFederativaId: this.datosUsuarioFrom.entidadFederativa,
          calle: this.datosUsuarioFrom.calle,
          ciudad: this.datosUsuarioFrom.ciudad,
          numeroExterior: this.datosUsuarioFrom.numeroExterior,
          numeroInterior: this.datosUsuarioFrom.numeroInterior,
          colonia: this.datosUsuarioFrom.ciudad,
          codigoPostal: this.datosUsuarioFrom.codigoPostal
        }
      ],
      telefono: [
        {
          tipoTelefonoId: this.datosUsuarioFrom.tipoTelefono,
          numero: this.datosUsuarioFrom.telefono,
          paisId: this.datosUsuarioFrom.pais,
          extension: this.datosUsuarioFrom.extensionTelefono
        }
      ]
    }
    this.Subscription.add(
      this.nuevoIngresoService.registrarDatosContacto(datosContacto).subscribe((datosActualizados: SimpleResponse) => {
        if (datosActualizados.success) {
          this.validarCurpIngresada();
        }
        else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
          this.errorAlActualizar = true;
        }
      })
    );
  }
  /**
   * Ejecuta el método del api que que valida si la curp ingresada está disponible
   */
  private validarCurpIngresada(): void {
    this.Subscription.add(
      this.biografiaService.obtenerBiografiaByCurp(this.datosUsuarioFrom.curp, this.alumno.usuario.usuarioId).subscribe((CurpEncontrada: Biografia) => {
        if (CurpEncontrada) {
          let mensaje: string = this.traductorService.translate('errorCRUP');
          this.snackService.mostrarSnackBack(mensaje);
          this.errorAlActualizar = true;
        }
        else {
          this.modificarBiografia();
        }
      })
    );
  }
  /**
   * Ejecuta el método del api que modifica los datos biográficos del usuario
   */
  private modificarBiografia(): void {
    let datosBiografia: Biografia = new Biografia();
    datosBiografia.usuarioId = this.alumno.usuario.usuarioId;
    datosBiografia.legalGeneroId = this.datosUsuarioFrom.sexo;
    datosBiografia.generoId = this.datosUsuarioFrom.genero;
    datosBiografia.entidadNacimiento = this.datosUsuarioFrom.entidadFederativaNacimiento;
    datosBiografia.ciudadNacimiento = this.datosUsuarioFrom.ciudadNacimiento;
    datosBiografia.estadoCivilId = this.datosUsuarioFrom.estadoCivil;
    datosBiografia.curp = this.datosUsuarioFrom.curp;
    datosBiografia.grupoSanguineoId = this.datosUsuarioFrom.tipoDeSangre;
    datosBiografia.alergias = this.datosUsuarioFrom.alergias;
    datosBiografia.enfermedades = this.datosUsuarioFrom.enfermedades;
    datosBiografia.ciudadaniaPaisId = this.datosUsuarioFrom.paisNacimiento;

    this.Subscription.add(
      this.nuevoIngresoService.modificarBiografia(datosBiografia).subscribe((datosActualizados: SimpleResponse) => {
        if (datosActualizados.success) {
          this.actualizarDatosPersonasRelacionadas();
        }
        else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
          this.errorAlActualizar = true;
        }
      })
    );
  }
  /**
   *  Actualizar los datos de las personas relacionadas del usuario
   * Datos familiares: (Madre, padre, tutor, conyuge, etc)
   */
  private actualizarDatosPersonasRelacionadas(): void {
    let personasRelacionadas = new Array<PersonaRelacionada>();
    if (this.datosUsuarioFrom.nombrePadre) {
      let padre = new PersonaRelacionada();
      padre.usuarioId = this.alumno.usuario.usuarioId;
      padre.tipoRelacionId = this.datosUsuarioFrom.tipoRelacionPadre;
      padre.nombreCompleto = this.datosUsuarioFrom.nombrePadre;
      padre.telefono = this.datosUsuarioFrom.telefonoPadre;
      padre.correoElectronico = this.datosUsuarioFrom.correoPadre;
      personasRelacionadas.push(padre);
    }
    if (this.datosUsuarioFrom.nombreMadre) {
      let madre = new PersonaRelacionada();
      madre.usuarioId = this.alumno.usuario.usuarioId;
      madre.tipoRelacionId = this.datosUsuarioFrom.tipoRelacionMadre;
      madre.telefono = this.datosUsuarioFrom.telefonoMadre;
      madre.nombreCompleto = this.datosUsuarioFrom.nombreMadre;
      madre.correoElectronico = this.datosUsuarioFrom.correoMadre;
      personasRelacionadas.push(madre);
    }
    if (this.datosUsuarioFrom.nombreTutor) {
      let tutor = new PersonaRelacionada();
      tutor.usuarioId = this.alumno.usuario.usuarioId;
      tutor.tipoRelacionId = this.datosUsuarioFrom.tipoRelacionTutor;
      tutor.nombreCompleto = this.datosUsuarioFrom.nombreTutor;
      tutor.telefono = this.datosUsuarioFrom.telefonoTutor;
      tutor.correoElectronico = this.datosUsuarioFrom.correoTutor;
      personasRelacionadas.push(tutor);
    }
    this.Subscription.add(
      this.nuevoIngresoService.registrarDatosFamilia(personasRelacionadas).subscribe((datosFamilairesActualziados: SimpleResponse) => {
        if (datosFamilairesActualziados.success) {
          this.actualizarDatosDeEmergencia();
        }
        else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
          this.errorAlActualizar = true;
        }
      })
    );
  }

  private actualizarDatosDeEmergencia(): void {
    let datosEmergencia = new PersonaRelacionada();
    datosEmergencia.usuarioId = this.alumno.usuario.usuarioId;
    datosEmergencia.tipoRelacionId = this.datosUsuarioFrom.tipoRelacionEmergencia;
    datosEmergencia.nombreCompleto = this.datosUsuarioFrom.nombreEmergencia;
    datosEmergencia.telefono = this.datosUsuarioFrom.telefonoEmergencia;
    datosEmergencia.correoElectronico = this.datosUsuarioFrom.correoEmergencia;
    this.Subscription.add(
      this.nuevoIngresoService.registrarDatosEmergencia(datosEmergencia).subscribe((datosActualizados: SimpleResponse) => {
        if (datosActualizados.success) {
          this.actualizarEstudiosPrevios();
        }
        else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
          this.errorAlActualizar = true;
        }
      })
    );
  }
  /**
   * Ejecuta el método del Api que actualiza los estuiso previso del usuarios
   */
  private actualizarEstudiosPrevios(): void {
    let datosEstudiosPrevios = new EstudiosPrevios();
    datosEstudiosPrevios.usuarioId = this.alumno.usuario.usuarioId;
    datosEstudiosPrevios.nivelId = this.datosUsuarioFrom.nivelAcademicoPrevio;
    datosEstudiosPrevios.escuela = this.datosUsuarioFrom.escuelaDeProcedencia;
    datosEstudiosPrevios.promedio = this.datosUsuarioFrom.promedioPrevio;
    this.nuevoIngresoService.agregarEstudiosPrevios(datosEstudiosPrevios).subscribe((datosActualizados: SimpleResponse) => {
      if (datosActualizados.success) {
        this.comprobarDisponibilidadRFC();
      }
      else {
        let mensaje: string = this.traductorService.translate('errorGuardar');
        this.snackService.mostrarSnackBack(mensaje);
        this.errorAlActualizar = true;
      }
    });
  }
  /**
   * Comprueba que ele RFC ingresado no esté siendo usado por otro usuario
   */
  private comprobarDisponibilidadRFC(): void {
    this.Subscription.add(
      this.nuevoIngresoService.GetDatoFiscalByRFC(this.datosUsuarioFrom.rfc, this.alumno.usuario.usuarioId).subscribe(
        (datoFiscalEncontrado: ApiResponse<DatoFiscal>) => {
          if (datoFiscalEncontrado.data) {
            this.errorAlActualizar = true;
            let mensaje: string = this.traductorService.translate('errorRFC');
            this.snackService.mostrarSnackBack(mensaje);
          }
          else {
            this.actualizarDatosFiscales();
          }
        })
    );
  }
  /**
   * Ejecuta el método del Api que actualualiza los datos fiscales del usuario
   */
  private actualizarDatosFiscales(): void {
    let datosFiscales = new DatoFiscal();
    datosFiscales.usuarioId = this.alumno.usuario.usuarioId;
    datosFiscales.rfc = this.datosUsuarioFrom.rfc;
    datosFiscales.razonSocial = this.datosUsuarioFrom.nombreRazonSocial;
    datosFiscales.entidadFederativaId = this.datosUsuarioFrom.estadoFacturacion;
    datosFiscales.calle = this.datosUsuarioFrom.calleFacturacion;
    datosFiscales.ciudad = this.datosUsuarioFrom.ciudadFacturacion;
    datosFiscales.numeroExterior = this.datosUsuarioFrom.numeroExteriorFacturacion;
    datosFiscales.numeroInterior = this.datosUsuarioFrom.numeroInteriorFacturacion;
    datosFiscales.colonia = this.datosUsuarioFrom.coloniaFacturacion;
    datosFiscales.codigoPostal = this.datosUsuarioFrom.codigoPostalFacturacion;
    datosFiscales.nombre = this.datosUsuarioFrom.nombre;
    datosFiscales.apellidoPaterno = this.datosUsuarioFrom.apellidoPaterno;
    datosFiscales.apellidoMaterno = this.datosUsuarioFrom.apellidoMaterno;
    this.Subscription.add(
      this.nuevoIngresoService.agregarDatosFiscal(datosFiscales).subscribe((datosFiscalesActualizados: SimpleResponse) => {
        if (datosFiscalesActualizados.success) {
          this.actualizarDatosDeOcupacion();
        }
        else {
          let mensaje: string = this.traductorService.translate('errorRFC');
          this.snackService.mostrarSnackBack(mensaje);
          this.errorAlActualizar = true;
        }
      })
    );
  }

  private actualizarDatosDeOcupacion(): void {
    let ocupacion = new Ocupacion();
    ocupacion.usuarioId = this.alumno.usuario.usuarioId;
    ocupacion.tipoEmpresaId = this.datosUsuarioFrom.tipoDeEmpresaOcupacion;
    ocupacion.tipoPuestoEmpresaId = this.datosUsuarioFrom.tipoDePuestoOcupacion;
    this.Subscription.add(
      this.nuevoIngresoService.registrarOcupacion(ocupacion).subscribe((datosOcupacionActualizados: SimpleResponse) => {
        if (datosOcupacionActualizados.success) {
          this.snackServiceService.mostrarSnackBack('Se han guardado los datos correctamente.');
          this.administracionAcademcica.actualizarDatosTabla();
        }
        else {
          let mensaje: string = this.traductorService.translate('errorRFC');
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    );
  }
  /**
   * Pone en pantalla el mensaje de descargar cambios
   */
  public mostrarModalCambiosSinGuardar(): void {
    this.Subscription.add(
      this.confirmacionService.abrirModal(["Datos personales"]).afterClosed().subscribe((descargar: boolean) => {
        if (descargar) {
          this.dialogRef.close();
        }
      })
    );
  }

  /**
  * Cambiar el index del objeto data cada vez que se cambia de tap en la vista y setea un nuevo valor
  * @param event
  */
  onTabClick(event: MatTabChangeEvent) {
    this.indexTap.setValue(event.index);
    if (this.indexTap.value === 1) {
      this.estadoFormularioDatos = false;
    } else {
      this.estadoFormularioDatos = this.cambioAnterior;
    }
  }
  //#endregion

  ngOnDestroy(): void {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
  }

  //#region ReloadData
  public recargarDatosEncabezado(alumnoId: number) {
    this.studentChangeService.GetAlumnobyAlumno(alumnoId).subscribe((alumno: ApiResponse<any>) => {
      if (alumno.data) {
        this.data.alumno = alumno.data[0];
        this.alumno = this.data.alumno;

      } else {

      }
    }, (err => {

    }))
  }
  //#endregion

  //#region Cambio de imagenes
  preview(event) {
    if (event.length === 0) {
      return;
    }
    var mimeType = event[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    let reader: FileReader = new FileReader();
    this.imagePath3 = event;
    reader.readAsDataURL(event[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
    this.eventImage = event;
  }

  public onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.onload = () => {
        this.data.alumno.fotografia = reader.result;
        this._cd.markForCheck();
        this.imagenUsuarioForm.patchValue({
          fotografia: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  }

  public imagenModal() {
    if (!this.boolImagen) {
      this.dialogoRefAdver = this.dialog.open(this.dialogAdvertencia, { disableClose: true });
    } else {
      this.dialogRef.close();
    }
  }

  public cerrarModalImagen(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
    } else {
      this.dialogoRefAdver.close();
      this.onFileChangePerfil(this.eventImage);
      this.enviarImagen();
      this.imgURL = "";
    }
  }

  public onFileChangePerfil(event) {
    const reader = new FileReader();
    if (event && event.length > 0) {
      this.imagePath2 = event;
      this.imagePath = event;
      const [file] = event;

      this.imagenUsuarioForm.patchValue({
        fotografiaFile: file.name
      });
      reader.readAsDataURL(event[0]);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
        this.imagePath2 = reader.result;
      }
      this.eventImage = event;
    }
  }

  public renderizarFoto() {
    this.imagenUsuarioForm = new FormGroup
      ({
        usuarioId: new FormControl(this.data.alumno.usuarioId),
        fotografia: new FormControl(''),
        fotografiaFile: new FormControl(''),
      });
  }

  public enviarImagen() {
    let imagenForm = Object.assign(this.imagenUsuarioForm.value);
    let imagen: any = {
      usuarioId: this.data.alumno.usuarioId,
      fotografia: imagenForm.fotografia,
      fotografiaFile: imagenForm.fotografiaFile,
    }
    this.profesorService.editarImagen(imagen).subscribe(
      imagenData => {
        if (imagenData) {
          this.cargarImagen();
          this.administracionAcademcica.actualizarDatosTabla();
          this.recargarDatosEncabezado(this.data.alumno.alumnoId);
          this.snackServiceService.mostrarSnackBack("Se ha guardado la imagen correctamente.");
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      }
    );
  }
  //#endregion
}
