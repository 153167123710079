import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiResponse } from '@app/models/api/ApiRespose.model';
import { SimpleResponse } from '@app/models/api/SimpleResponse.model';
import { UserRolPermissionDto } from '@app/_models/UserRolPermissionDto';
import { AuthenticationService } from '@app/_services/authentication.service';
import { TraductorService } from '@app/_services/traductor.service';
import { Observable, Subscription } from 'rxjs';
import { ProgramaAcademicoComponent } from 'src/app/programa-academico/programa-academico.component';
import { SnackService } from 'src/app/services/snack-service.service';
import { AccionEnum } from 'src/app/_models/accion.enum';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { UtileriasService } from 'src/app/_services/utilerias.service';
import { UsuariosRegistrarComponent } from './usuarios-registrar/usuarios-registrar.component';
@Component({
  selector: 'app-usuarios-modal',
  templateUrl: './usuarios-modal.component.html',
  styleUrls: ['./usuarios-modal.component.scss']
})
export class UsuariosModalComponent implements OnInit, OnDestroy {

  @ViewChild(UsuariosRegistrarComponent) registrarUsuario: UsuariosRegistrarComponent;
  @ViewChild('dialogAdvertenia') dialogAdvertenia: TemplateRef<any>;
  @ViewChild('dialogoCandidatoCancelado') dialogoCandidatoCancelado: TemplateRef<any>;
  @ViewChild('dialogoCerrarModal') dialogoCerrarModal: TemplateRef<any>;
  public datosUsuarioBusqueda$: Observable<any>;
  public usuarioMenorEdad$: Observable<any>;
  public datosUsuarioBusqueda: any;
  public usuarioMenorEdad: boolean;
  public menorEdad: boolean;
  public formValidoNuevaSolicitud: boolean;
  public tipoUsuarioEncontrado: string = "";
  public nombreUsuario: string = "";
  public busquedaCorreoForm: FormGroup;
  public correoBusqueda: string = '';
  public usuarioEncontrado: boolean = true;
  public ocultarNuevoUsuario: boolean = true;
  public registrarUsuarioBandera: boolean = false;
  public mensajeUsuarioEncontrado: boolean = false;
  public mensajeUsuarioNoEncontrado: boolean = false;
  public reactivarCandidatoId: any;
  public tipoAccion: number;
  public ocultarBuscador: boolean = false;
  public datosUsuarioActual: any;
  public edicionSolicitudForm: FormGroup;
  public rolesForm: FormGroup;
  public nuevoSolicitudUsuarioForm: FormGroup;
  public renderizarFormulario: boolean = false;
  public renderizarFormularioRoles: boolean = false;
  public correoRegistro: any;
  public nombreCompletoUsuario: string;
  public subscriptionPeriodo: Subscription;
  public subscriptionPrograma: Subscription;
  public utileriasService: any;
  public formularioProfesor: boolean = false;
  public passwordTipoTexto: boolean = false;
  public passwordCTipoTexto: boolean = false;
  public usuarioId: number;
  public datosNuevoUsuario: any;
  public formValidoNuevaSolicitud$: Observable<boolean>;
  public changesAcademicProgram: boolean = false;
  public changesPersonalData: boolean = false;
  public changesDocuments: boolean = false;
  public deleteDocuments: boolean = true;
  public changesFinancialSupport: boolean = false;
  public changesSubjects: boolean = false;
  public changesPayment: boolean = false;
  public cambioForm: boolean;
  public boolCambio: boolean = false;
  public userPermision: UserRolPermissionDto;
  public dialogoRefAdver: any;
  public dialogoRefAdverCancelado: any;
  public dialogoRefCerrarModal: any;
  indexTap: FormControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<UsuariosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public usuarioService: UsuarioService,
    private snackBar: SnackService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private _cd: ChangeDetectorRef,
    private utileria: UtileriasService,
    private authenticationService: AuthenticationService,
    private snackService: SnackService,
    private traductorService: TraductorService
  ) { }

  ngOnInit(): void {
    this.indexTap.setValue(0);
    this.datosUsuarioBusqueda$ = this.usuarioService.obtenerDatosUsuarioBusqueda$();
    this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos)
    if (this.data) {
      this.usuarioEncontrado = false;
      this.registrarUsuarioBandera = false;
      this.mensajeUsuarioEncontrado = true;
      this.usuarioService.candidatoById(this.data.usuarioID).subscribe(alumnos => {
        this.datosUsuarioBusqueda = alumnos;
        this.usuarioService.definirDatosUsuarioBusqueda(this.datosUsuarioBusqueda);
        if (this.usuarioService.updateTabsSubscription == undefined) {
          this.usuarioService.updateTabsSubscription = this.usuarioService.updateTabsEmitter.subscribe(() => {
            this.usuarioService.candidatoById(this.data?.usuarioID).subscribe(alumnos => { this.datosUsuarioBusqueda = alumnos; this.usuarioService.definirDatosUsuarioBusqueda(this.datosUsuarioBusqueda); });
            this.datosUsuarioBusqueda$ = this.usuarioService.obtenerDatosUsuarioBusqueda$();
            this.datosUsuarioBusqueda$.subscribe(datos => { this.datosUsuarioBusqueda = datos; })
          });
        }
        this.datosUsuarioBusqueda$ = this.usuarioService.obtenerDatosUsuarioBusqueda$();
        this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos);
      });
    }
    if (this.datosNuevoUsuario) {
      this.usuarioService.obtenerUsuarioCorreo(this.correoBusqueda).subscribe(
        usuarioBusuqueda => {
          this.datosUsuarioBusqueda = usuarioBusuqueda;
          this.datosUsuarioBusqueda$ = this.usuarioService.obtenerDatosUsuarioBusqueda$();
          this.datosUsuarioBusqueda$.subscribe(datos => this.datosUsuarioBusqueda = datos)
        });
    }
    this.formValidoNuevaSolicitud$ = this.usuarioService.obtenerFormularioValidoNuevaSolicitud$();
    this.formValidoNuevaSolicitud$.subscribe(bool => this.formValidoNuevaSolicitud = bool);
    this.inicializarForm();
  }

  ngAfterViewInit(): void {
    if (!this.data) {
      this.tipoAccion = AccionEnum.CREAR;
      this.ocultarBuscador = false;
    } else {
      this.tipoAccion = AccionEnum.EDITAR;
      this.datosUsuarioActual = this.data;
      this.datosUsuarioActual.usuarioId = this.datosUsuarioActual.usuarioID
      this.ocultarBuscador = true;
      // this.usuarioService.definirDatosUsuarioBusqueda(this.data);
      this.usuarioEncontrado = false;
      this.mensajeUsuarioEncontrado = true;
      this.inicializarFormEdicion(this.datosUsuarioActual);
      this.inicializarFormRoles(this.datosUsuarioActual.identificador, this.datosUsuarioActual.usuarioID);
    }
  }

  public inicializarForm() {
    this.busquedaCorreoForm = new FormGroup
      ({
        correo: new FormControl('', [Validators.required, Validators.email])
      });
    this.busquedaCorreoForm.valueChanges.subscribe(val => {
      this.mensajeUsuarioEncontrado = false;
      this.mensajeUsuarioNoEncontrado = false;
      this.ocultarNuevoUsuario = true;
      this.usuarioEncontrado = true;
    });
  }
  get correo() { return this.busquedaCorreoForm.get('correo'); }

  public enviarBusquedaCorreo() {
    this.correoBusqueda = this.busquedaCorreoForm.controls['correo'].value;
    if (this.correoBusqueda === '') {
      this.snackBar.mostrarSnackBack('Debes ingresar un correo');
    } else {
      this.usuarioService.obtenerUsuarioCorreo(this.correoBusqueda).subscribe(
        usuarioBusuqueda => {
          if (usuarioBusuqueda != null) {
            this.mensajeUsuarioEncontrado = true;
            this.usuarioEncontrado = false;
            this.registrarUsuarioBandera = false;
            this.ocultarBuscador = true;
            this.inicializarFormEdicion(usuarioBusuqueda);
            this.inicializarFormRoles(usuarioBusuqueda.identificador, usuarioBusuqueda.usuarioId);
          } else {
            this.usuarioService.definirDatosUsuarioBusqueda(usuarioBusuqueda);
            this.ocultarBuscador = true;
            this.snackBar.mostrarSnackBack("No se encontró a un usuario registrado. Por favor llene el formulario para registrar al usuario. ");
            this.registrarUsuarioBandera = true;
            this.ocultarNuevoUsuario = false;
            this.mensajeUsuarioNoEncontrado = true;
            this.registrarUsuario.recibirInfo(this.busquedaCorreoForm.get('correo').value);
          }
        }
      );
    }
  }

  public inicializarFormEdicion(datosUsuario: any) {
    this.edicionSolicitudForm = new FormGroup({
      usuarioId: new FormControl(datosUsuario.usuarioId),
      nombre: new FormControl(datosUsuario.nombre, [Validators.required]),
      segundoNombre: new FormControl(datosUsuario.segundoNombre),
      apellidoPaterno: new FormControl(datosUsuario.apellidoPaterno, [Validators.required]),
      apellidoMaterno: new FormControl(datosUsuario?.apellidoMaterno ? datosUsuario?.apellidoMaterno : ''),
      fechaNacimiento: new FormControl(this.utileria.formatearFecha(datosUsuario.fechaNacimiento, 'YYYY[-]MM[-]DD')),
      // fechaNacimiento: new FormControl(datosUsuario?.fechaNacimiento ? datosUsuario?.fechaNacimiento: '' ),
      correo: new FormControl(datosUsuario.correo),
      password: new FormControl(datosUsuario?.password ? datosUsuario?.password : ''),
      passwordConfirm: new FormControl(datosUsuario?.passwordConfirm ? datosUsuario?.passwordConfirm : ''),
      estatus: new FormControl(datosUsuario?.estatus === true ? true : false),
      // estatus: new FormControl(datosUsuario.estatus)
    });
    this.renderizarFormulario = true;
    this.edicionSolicitudForm.valueChanges.subscribe(val => {
      this.boolCambio = true;
      //AGREGAR MODAL CAMBIOS PENDIENTES
    });

  }

  public inicializarFormRoles(identificador: string, usuarioId: number) {
    console.log("identificador", identificador)
    console.log("usuarioId", usuarioId)
    this.authenticationService.obtainPermission(identificador).subscribe((response: ApiResponse<UserRolPermissionDto>) => {
      this.userPermision = response.data;
      let rolesUsuario = {
        usuarioId: usuarioId,
        administrador: false,
        alumno: false,
        profesor: false
      }
      if (this.userPermision.roles.length > 0) {
        this.userPermision.roles.forEach(rol => {
          if (rol.roleID === 1) {
            rolesUsuario.administrador = true;
          }
          if (rol.roleID === 3) {
            rolesUsuario.profesor = true;
          }
          if (rol.roleID === 4) {
            rolesUsuario.alumno = true;
          }
        });
      }
      this.rolesForm = new FormGroup({
        usuarioId: new FormControl(rolesUsuario.usuarioId),
        administrador: new FormControl(rolesUsuario.administrador),
        profesor: new FormControl(rolesUsuario.profesor),
        alumno: new FormControl(rolesUsuario.alumno)
      });
      this.renderizarFormularioRoles = true;
    });
  }

  get nombre() { return this.edicionSolicitudForm.get('nombre'); }
  get apellidoPaterno() { return this.edicionSolicitudForm.get('apellidoPaterno'); }
  get apellidoMaterno() { return this.edicionSolicitudForm.get('apellidoMaterno'); }
  get fechaNacimiento() { return this.edicionSolicitudForm.get('fechaNacimiento'); }
  get password() { return this.edicionSolicitudForm.get('password'); }
  get passwordConfirm() { return this.edicionSolicitudForm.get('passwordConfirm'); }
  get estatus() { return this.edicionSolicitudForm.get('estatus'); }

  private parameters(control: AbstractControl): { [key: string]: any } | null {
    let regExp = /[ÑÁÉÍÓÚñáéíóú]/.test(control.value);

    return regExp ? { 'password': { value: control.value } } : null;
  }

  public mostrarContrasena() {
    this.passwordTipoTexto = !this.passwordTipoTexto;
  }

  public mostrarContrasenaC() {
    this.passwordCTipoTexto = !this.passwordCTipoTexto;
  }

  public enviarRegistroUsuario() {
    this.datosNuevoUsuario = Object.assign(this.registrarUsuario.nuevoSolicitudForm.value);
    let registro: any = {
      correo: this.datosNuevoUsuario.correo,
      nombre: this.datosNuevoUsuario.nombre,
      segundoNombre: this.datosNuevoUsuario.segundoNombre,
      apellidoPaterno: this.datosNuevoUsuario.apellidoPaterno,
      apellidoMaterno: this.datosNuevoUsuario.apellidoMaterno,
      password: this.datosNuevoUsuario.password,
      estatusCuentaId: this.datosNuevoUsuario.estatus == true ? 1 : 0,
      creacionUsario: true,
      biografia: {
        fechaNacimiento: this.datosNuevoUsuario.fechaNacimiento
      }
    };
    if (this.datosNuevoUsuario.password === this.datosNuevoUsuario.passwordConfirm) {
      this.usuarioService.registrarNuevoCandidato(registro).subscribe(
        usuarioGuardado => {
          if (usuarioGuardado) {
            this.snackBar.mostrarSnackBack("Se ha registrado el nuevo usuario.");
            this.registrarUsuarioBandera = false;
            this.ocultarNuevoUsuario = false;
            this.obtenerInfoUsuario(this.datosNuevoUsuario.correo);
            this.usuarioService.invocarMetodoObtenerUsuarios();
            this.usuarioService.refrescarTabla();
            this.usuarioService.updateTabs();
            this.ngOnInit();
          } else {
            this.snackBar.mostrarSnackBack("Ha ocurrido un error al guardar el usuario. Por favor, intente de nuevo más tarde.");
          }
        }
      );
    } else {
      this.snackBar.mostrarSnackBack("Las contraseñas deben de ser iguales.");
    }
  }

  public obtenerInfoUsuario(correo: string) {
    this.usuarioService.obtenerUsuarioCorreo(correo).subscribe(
      usuarioBusuqueda => {
        if (usuarioBusuqueda != null) {
          this.usuarioService.definirDatosUsuarioBusquedaCorreo(usuarioBusuqueda);
          this.usuarioEncontrado = false;
          this.indexTap.setValue(0);
          this.inicializarFormEdicion(usuarioBusuqueda);
          this.inicializarFormRoles(usuarioBusuqueda.identificador, usuarioBusuqueda.usuarioId);
          this.ocultarNuevoUsuario = true;
          this.mensajeUsuarioEncontrado = true;
          this.mensajeUsuarioNoEncontrado = false;
          this.datosUsuarioActual = usuarioBusuqueda;
        } else {

        }
      });
  }

  public enviarFormularioEdicion() {
    if (this.indexTap.value == 0) {
      this.datosNuevoUsuario = Object.assign(this.edicionSolicitudForm.value);
      let datosEdicion: any = {
        usuarioId: this.datosNuevoUsuario.usuarioId,
        nombre: this.datosNuevoUsuario.nombre,
        segundoNombre: this.datosNuevoUsuario.segundoNombre,
        apellidoPaterno: this.datosNuevoUsuario.apellidoPaterno,
        apellidoMaterno: this.datosNuevoUsuario.apellidoMaterno,
      }
      this.usuarioService.editarUsuario(datosEdicion).subscribe(
        guardarEdicion => {
          if (guardarEdicion) {
            this.snackBar.mostrarSnackBack("Se ha modificado los datos correctamente");
            this.usuarioService.invocarMetodoObtenerUsuarios();
            this.usuarioService.refrescarTabla();
            this.usuarioService.updateTabs();
          } else {
            this.snackBar.mostrarSnackBack("Ha ocurrido un error al guardar los datos del usuario. Por favor, intente de nuevo más tarde.");
          }
        }
      );
    }
    if (this.indexTap.value == 1) {
      let roles: any = this.rolesForm.value
      this.authenticationService.editarRolesUsuario(roles).subscribe((response: SimpleResponse) => {
        if (response.success) {
          this.usuarioService.invocarMetodoObtenerUsuarios();
          this.usuarioService.refrescarTabla();
          this.usuarioService.updateTabs();
          let mensaje: string = this.traductorService.translate('guardadoCorrecto');
          this.snackService.mostrarSnackBack(mensaje);
        } else {
          let mensaje: string = this.traductorService.translate('errorGuardar');
          this.snackService.mostrarSnackBack(mensaje);
        }
      })
    }
  }

  onTabClick(event: MatTabChangeEvent) {
    this.indexTap.setValue(event.index);
  }

  public cerrarModalAdvertencia(descartar: boolean) {
    if (descartar) {
      this.dialogoRefAdver.close();
      this.dialogRef.close(null);
    } else {
      this.dialogoRefAdver.close();
    }
  }

  ngOnDestroy(): void {
    if (this.usuarioService.updateTabsSubscription != null) {
      this.usuarioService.updateTabsSubscription.unsubscribe();
    }
  }

}
